import React, { useRef, useEffect } from 'react';
import './styles.css';



export default ({
  imageName,
  icon,
  buttonText,
  t,
  activeHexButtonIndex,
  hexIndex,
  setActiveHexButtonIndex
}) => {
  const imageRef = useRef();
  // useEffect(() => {
  //   if (imageRef && imageRef.current) {
  //     imageRef.current.style[
  //       'background-image'
  //     ] = `url(${imageName})`;
  //   }
  // }, [imageName]);
  return (
    <ul onMouseLeave={() => setActiveHexButtonIndex(null)}>
      <li>
        <div
          onMouseOver={() => {
            setActiveHexButtonIndex(hexIndex);
          }}
          // ref={imageRef}
          style={{ backgroundImage: `url(${imageName})` }}
          className="bg-image">
          <div
            className={`hex-overlay ${
              activeHexButtonIndex === hexIndex
                ? 'hex-button-index-active'
                : 'hex-button-index-inactive'
            }`}></div>
          <div className="hex-text">
            <i
              className={`${icon} fa-2x icon-with-shadow ${
                activeHexButtonIndex === hexIndex
                  ? 'text-white'
                  : 'hex-icon-color'
              }`}
            />
            <h6 className="custom-text-shadow">{t(`front:${buttonText}`)}</h6>
          </div>
        </div>
      </li>
    </ul>
  );
};
