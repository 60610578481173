/**  Custom message notification using rc-notification
 http://react-component.github.io/notification/

  How to use : 

  import Message from './path/to/this/file'

  Message.[succes | error | info | warning ]('your message', *duration in second*, *onClose() function*)

  Recomendation is to use promise instead of the onClose function

  */
import './message.css';
import Notification from 'rc-notification';
import React from 'react';

let defaultDuration = 3;
let defaultTop = 16;
let messageInstance;
let key = 1;
let prefixCls = 'custom-message';
let transitionName = 'move-up';
let getContainer;
let maxCount;

const getMessageInstance = callback => {
  if (messageInstance) {
    callback(messageInstance);
    return;
  }
  Notification.newInstance(
    {
      prefixCls,
      transitionName,
      style: { top: defaultTop },
      getContainer,
      maxCount
    },
    instance => {
      if (messageInstance) {
        callback(messageInstance);
        return;
      }
      messageInstance = instance;
      callback(instance);
    }
  );
};

const notice = ({ content, duration, type, onClose, icon }) => {
  const iconType = {
    info: 'fas fa-info-circle',
    success: 'fas fa-check-circle',
    error: 'fas fa-times-circle',
    warning: 'fas fa-exclamation-circle'
  }[type];

  const alertClass = {
    info: 'alert alert-info',
    success: 'alert alert-success',
    error: 'alert alert-danger',
    warning: 'alert alert-warning'
  }[type];

  const target = key++;
  const closePromise = new Promise(resolve => {
    const callback = () => {
      if (typeof onClose === 'function') {
        onClose();
      }
      return resolve(true);
    };
    getMessageInstance(instance => {
      const iconNode = <i className={iconType} />;
      instance.notice({
        key: target,
        duration,
        style: {},
        content: (
          <div className={`${alertClass} p-2 `} role="alert">
            {icon ? icon : iconType ? iconNode : ''}
            <span className={'ml-2'}>{content}</span>
          </div>
        ),
        onClose: callback
      });
    });
  });
  const result = () => {
    if (messageInstance) {
      messageInstance.removeNotice(target);
    }
  };
  result.then = (filled, rejected) => closePromise.then(filled, rejected);
  result.promise = closePromise;
  return result;
};

const api = {
  open: (content, config) => {
    return notice({ content, ...config });
  }
};

['success', 'info', 'warning', 'error'].forEach(type => {
  api[type] = (content, duration, onClose) => {
    return api.open(content, {
      duration: duration || defaultDuration,
      onClose,
      type
    });
  };
});

export default api;
