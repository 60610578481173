import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Notifications from '../../../Main/Notifications';
import NavigatorBar from '../dist/NavBar';
import { withSocketIO } from '../../../../api/sockets/withSocketIO';
import './styles.css';

const NavigationBar = props => (
  <NavigatorBar {...props} Notifications={Notifications} />
);

export default compose(
  withRouter,
  withTranslation(),
  withSocketIO
)(NavigationBar);
