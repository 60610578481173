import React, { Component } from 'react';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../../UI/Input/customInput';
import { FormGroup, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import Message from '../../../UI/Message';
import { withTranslation } from 'react-i18next';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import CorrespondenceList from './CorrespondenceList';
import CorrespondenceEditor from './Editor';

const formikConfig = (props, clearEditorState) => {
  return {
    initialValues: { correspondence: { subject: '', content: '' } },
    validationSchema: Yup.object().shape({
      results: Yup.array().min(1, props.t('error_select_result')),
      correspondence: Yup.object().shape({
        subject: Yup.string(props.t('error:message_subject_required')).required(
          props.t('error:message_subject_required')
        )
      })
    }),
    validate: values => {
      const editorState = values.correspondence.content;
      if (
        !editorState.blocks ||
        editorState.blocks.filter(b => b.text !== '').length === 0
      ) {
        return {
          correspondence: { content: props.t('error:message_content_required') }
        };
      }
    },
    validateOnChange: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      // const updatedData = Object.keys(
      //   values.correspondence.content.entityMap
      // ).reduce((acc, currentKey) => {
      //   if (
      //     values.correspondence.content.entityMap[currentKey].type ===
      //     '^mention'
      //   ) {
      //     return {
      //       ...acc,
      //       ...values.correspondence.content,
      //       ['entityMap']: {
      //         ...values.correspondence.content.entityMap,
      //         [currentKey]: {
      //           ...values.correspondence.content.entityMap[currentKey],
      //           type: 'mention'
      //         }
      //       }
      //     };
      //   }
      //   return {
      //     ...acc,
      //     ...values.correspondence.content,
      //     ['entityMap']: {
      //       ...values.correspondence.content.entityMap,
      //       [currentKey]: {
      //         ...values.correspondence.content.entityMap[currentKey],
      //         type: 'mention'
      //       }
      //     }
      //   };
      // }, {});

      const formattedValues = {
        ...values,
        correspondence: {
          subject: values.correspondence.subject,
          content: JSON.stringify(values.correspondence.content)
        }
      };

      props
        .createNewCorrespondence(props, formattedValues)
        .then(() => {
          Message.success(props.t('new_correspondence_message'));
          resetForm();
          clearEditorState();
        })
        .catch(err => {
          Message.error(
            props.t(`error:${err.message}`, {
              entity: 'submission'
            })
          );
        });
      setSubmitting(false);
    }
  };
};

class CorrespondencePresenter extends Component {
  constructor(props) {
    super(props);
    this.state = { editorState: null, clearEditorStateFunc: null };
  }

  clearEditorState = () => {
    this.state.clearEditorStateFunc();
  };

  onInit = clearEditorStateFunc => {
    this.setState({ clearEditorStateFunc });
  };

  render() {
    const {
      t,
      writeMode,
      editorOnly,
      projectId,
      stageName,
      submissionId
    } = this.props;
    return (
      <>
        {!editorOnly && (
          <CorrespondenceList
            projectId={projectId}
            stageName={stageName}
            translate={this.props.t}
            submissionId={submissionId}
          />
        )}
        {writeMode && (
          <Formik {...formikConfig(this.props, this.clearEditorState)}>
            {formikProps => {
              const {
                setFieldValue,
                setFieldTouched,
                handleBlur
              } = formikProps;
              const onChange = editorState => {
                setFieldValue(
                  'correspondence.content',
                  convertToRaw(editorState.getCurrentContent())
                );
              };
              const onBlur = () => {
                setFieldTouched('correspondence.content', true);
                handleBlur('correspondence.content');
              };
              return (
                <Form>
                  <FormGroup>
                    <Field
                      name="correspondence.subject"
                      placeholder="Subject"
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup onClick={this.focus}>
                    <Field
                      name="correspondence.content"
                      render={() => {
                        const { touched, errors } = formikProps;
                        const isValid =
                          getIn(touched, 'correspondence.content') &&
                          !getIn(errors, 'correspondence.content');
                        const isInvalid = !!(
                          getIn(touched, 'correspondence.content') &&
                          getIn(errors, 'correspondence.content')
                        );
                        return (
                          <>
                            <CorrespondenceEditor
                              onInit={this.onInit}
                              isValid={isValid}
                              isInvalid={isInvalid}
                              onChange={onChange}
                              invalidFeedback={getIn(
                                errors,
                                'correspondence.content'
                              )}
                              onBlur={onBlur}
                              projectId={projectId}
                              stageName={stageName}
                            />
                          </>
                        );
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="text-right">
                    <Button color="primary" type="submit">
                      {t('send')}
                    </Button>
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
}

export default compose(withTranslation(['submission']))(
  CorrespondencePresenter
);
