import { format, distanceInWordsToNow, distanceInWords } from 'date-fns';
const en = require('date-fns/locale/en');

const formatValues = {
  default: 'DD/MM/YYYY',
  defaultHour: 'DD/MM/YYYY HH:mm',
  inputFormat: 'YYYY-MM-DD'
};

const defaultDateInputFormat = {
  default: 'YYYY-MM-DD',
  defaultHour: 'YYYY-MM-DD HH:mm'
};

const inWordsByTime = {
  default: 'MMMM D GGGG',
  month: 'MMMM GGGG',
  year: 'GGGG',
  day: 'MMMM D GGGG'
};

export const dateFormatting = (value, formatKey) =>
  format(value, formatValues[formatKey || 'default']);

// Add locale-specific relative date/time formatting rules.
// Create relative date/time formatter.
export const timeAgoFormatting = date => {
  return distanceInWordsToNow(new Date(parseInt(date)), {
    locale: en,
    includeSeconds: true
  });
};

//Added this to display the the date in the Field with input type date
export const dateInputTypeFormatting = (value, formatKey) =>
  format(parseInt(value), defaultDateInputFormat[formatKey || 'default']);

export const timeAgoBetweenTwoDates = (startDate, endDate) => {
  return distanceInWords(startDate, endDate);
};

export const dateInWords = (date, formatKey) => {
  const input = new Date(date);
  return format(input, inWordsByTime[formatKey || 'default']);
};
