import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { connect } from 'react-redux';
import { marked } from 'marked';
import { compose } from 'recompose';

import {
  fetchOrganizationDetails,
  updateGroup
} from '../../../../store/actions';

import Message from '../../../UI/Message';
import { withTranslation } from 'react-i18next';
import './style.css';

const OrganizationalDetailPage = props => {
  const { organization, updateGroup, fetchOrganizationDetails, t } = props;
  const [editMode, setEditMode] = useState(false);
  const [markdownDetails, setMarkdownDetails] = useState(
    organization.organization_detail
  );
  const setTextAreaContent = e => setMarkdownDetails(e.target.value);

  const onSave = () => {
    setEditMode(false);
    updateGroup('organization', organization.organization_id, {
      organization_detail: encodeURIComponent(markdownDetails).replace(
        /'/g,
        '%27'
      )
    })
      .then(() => {
        Message.success(t('main:saved_successfully'));
        fetchOrganizationDetails(organization.organization_id);
      })
      .catch(() => {
        Message.error(t('error:default_message'));
      });
  };
  const onCancel = () => {
    setEditMode(false);
    setMarkdownDetails(organization.organization_detail);
  };

  return (
    <>
      {!editMode && (
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col xs={{ size: 1, offset: 7 }}>
              <Button
                className="px-3"
                color="dark"
                onClick={() => {
                  setEditMode(true);
                  fetchOrganizationDetails(organization.organization_id);
                }}
                size="sm">
                {t('main:edit')}
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center my-1">
            <Col
              sm="12"
              md="10"
              lg="8"
              className="organizationDetailMainPreview">
              <div
                id="preview"
                dangerouslySetInnerHTML={{
                  __html: marked(markdownDetails || '')
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
      {editMode && (
        <Container>
          <Row>
            <Col sm={{ size: 2, offset: 8 }} md={{ size: 1, offset: 10 }}>
              <Button
                size="sm"
                className="my-1 px-3 "
                color="success"
                onClick={onSave}>
                {t('main:save')}
              </Button>
            </Col>
            <Col sm="2" md="1">
              <Button
                size="sm"
                className="my-1"
                color="danger"
                onClick={onCancel}>
                {t('main:cancel')}
              </Button>
            </Col>
          </Row>
          <ScrollSync>
            <Row className="justify-content-center">
              <Col sm="6">
                <ScrollSyncPane>
                  <textarea
                    autoFocus={editMode}
                    className="organizationDetailTextArea"
                    onChange={e => setTextAreaContent(e)}
                    value={markdownDetails}></textarea>
                </ScrollSyncPane>
              </Col>

              <Col sm="6">
                <ScrollSyncPane>
                  <div className="organizationDetailTextAreaEditPreview">
                    <div
                      id="preview"
                      dangerouslySetInnerHTML={{
                        __html: marked(markdownDetails || '')
                      }}
                    />
                  </div>
                </ScrollSyncPane>
              </Col>
            </Row>
          </ScrollSync>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationDetails: organizationId =>
      dispatch(fetchOrganizationDetails(organizationId)),
    updateGroup: (groupType, groupId, values) =>
      dispatch(updateGroup(groupType, groupId, values))
  };
};

const mapStateToProps = state => {
  return {
    organization: state.organization.current.details
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(OrganizationalDetailPage);
