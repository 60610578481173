import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  CustomInput,
  UncontrolledTooltip,
  Spinner
} from 'reactstrap';
import classnames from 'classnames';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Message from '../../UI/Message';
import FavoriteRulesList from './favoriteRulesList';
import UserRuleManagement from './ruleManagement';
import styles from './userPreference.module.css'
// import RulesGroups from './rulesGroup';

const user_preference = (dashboard_defaults, t) => {
  const { sort, view } = dashboard_defaults;
  return [
    {
      field_label: t('view_mode_field_label'),
      field_description: t('view_mode_description'),
      inputs: [
        {
          id: 1,
          value: 'grid',
          label: t('grid_label'),
          name: 'view',
          isCheck: view === 'grid'
        },
        {
          id: 2,
          value: 'list',
          label: t('list_label'),
          name: 'view',
          isCheck: view === 'list'
        }
      ]
    },
    {
      field_label: t('sorting_mode_field_label'),
      field_description: t('sort_mode_description'),
      inputs: [
        {
          id: 3,
          value: 'project_name',
          label: t('project_name_label'),
          name: 'sort.field_name',
          isCheck: sort.field_name === 'project_name'
        },
        {
          id: 4,
          value: 'date_updated',
          label: t('date_updated_label'),
          name: 'sort.field_name',
          isCheck: sort.field_name === 'date_updated'
        }
      ]
    },
    {
      field_label: t('sorting_direction_mode_field_label'),
      field_description: t('sorting_direction_mode_description'),
      inputs: [
        {
          id: 5,
          value: 'ascending',
          label: t('ascending_label'),
          name: 'sort.direction',
          isCheck: sort.direction === 'ascending'
        },
        {
          id: 6,
          value: 'descending',
          label: t('descending_label'),
          name: 'sort.direction',
          isCheck: sort.direction === 'descending'
        }
      ]
    }
  ];
};
const formikConfig = props => {
  const { t } = props;
  const { dashboard_defaults } = props.userPreference;
  return {
    initialValues: {
      sort: {
        field_name: dashboard_defaults.sort.field_name,
        direction: dashboard_defaults.sort.direction
      },
      view: dashboard_defaults.view
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { setSubmitting }) => {
      const newPreferenceData = { ...values };

      setSubmitting(true);
      props
        .updateUserPreference({
          settingsToSet: 'dashboard_defaults',
          newPreferenceData,
          operation: 'update'
        })
        .then(() => {
          Message.success(t('user_preferences:reference_edit_success'));
        })
        .catch(err => {
          Message.error(t('error:default_message'));
        });
    }
  };
};
class UserPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      activeTab: 'Dashboard',
      handleSettingsUpdate: false,
      grid: true,
      list: false,
      project_name: true,
      date_updated: false,
      ascending: true,
      descending: false
    };
  }

  componentDidMount() {
    const { fetchAllUserPreference } = this.props;
    fetchAllUserPreference()
      .then(() => {
        const { view, sort } = this.props.userPreference.dashboard_defaults;
        this.setState({
          isLoaded: true,
          activeTab: 'Dashboard',
          handleSettingsUpdate: false,
          grid: view === 'grid',
          list: view === 'list',
          project_name: sort.field_name === 'project_name',
          date_updated: sort.field_name === 'date_updated',
          ascending: sort.direction === 'ascending',
          descending: sort.direction === 'descending'
        });
      })
      .catch(err => {
        // console.log(err);
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        activeTab: 'Dashboard'
      });
    }
  }
  componentWillUnmount() {}

  handleInput = event => {};

  handleFormSubmit = e => {
    e.preventDefault();
  };

  toggleTab = tab => {
    this.setState({ activeTab: tab });
    this.props.history.push(tab);
  };
  handleRadioInputChange = (formikValues, e) => {
    const name = e.target.name;
    if (name === 'sort.field_name' || name === 'sort.direction') {
      const arr = name.split('.');
      formikValues[arr[0]][arr[1]] = e.target.value;
    } else {
      formikValues[e.target.name] = e.target.value;
    }
    this.setState({
      handleSettingsUpdate: true
    });
  };
  setChecked = value => {
    switch (value) {
      case 'grid':
        this.setState({ grid: true, list: false });
        break;
      case 'list':
        this.setState({ list: true, grid: false });
        break;
      case 'project_name':
        this.setState({ project_name: true, date_updated: false });
        break;
      case 'date_updated':
        this.setState({ date_updated: true, project_name: false });
        break;
      case 'ascending':
        this.setState({ ascending: true, descending: false });
        break;
      case 'descending':
        this.setState({ descending: true, ascending: false });
        break;
    }
  };

  onResetRuleToDefaults = async () => {
    const { t } = this.props;

    this.setState({
      isLoaded: false
    });
    await this.props
      .resetUserPreference({
        settingsToReset: 'dashboard_defaults'
      })
      .then(() => {
        const { view, sort } = this.props.userPreference.dashboard_defaults;

        this.setState(
          {
            activeTab: 'Dashboard',
            handleSettingsUpdate: false,
            grid: view === 'grid',
            list: view === 'list',
            project_name: sort.field_name === 'project_name',
            date_updated: sort.field_name === 'date_updated',
            ascending: sort.direction === 'ascending',
            descending: sort.direction === 'descending'
          },
          () => {
            this.setState({
              isLoaded: true
            });
          }
        );
        Message.success(t('user_preferences:reference_edit_success'));
      })
      .catch(err => {
        Message.error(t('error:default_message'));
      });
  };
  render() {
    const { t, userPreference } = this.props;
    const { isLoaded, activeTab, handleSettingsUpdate } = this.state;
    const { dashboard_defaults } = userPreference;

    return (
      <>
        {isLoaded ?  (
          <Container fluid className="shadow my-4 p-3 mb-5 rounded">
            <Row>
              <Col sm={12}>
                <div className="mt-2">
                  <h3 className="text-primary">
                    {t('user_preferences:title')}
                  </h3>
                </div>
                <Nav tabs>
                  <NavItem
                    className={classnames({
                      'border-left border-primary rounded border-sz-3':
                        activeTab === 'Dashboard'
                    })}>
                    <NavLink
                      className={classnames('rounded', {
                        'active font-weight-bold': activeTab
                          ? activeTab === 'Dashboard'
                          : false
                      })}
                      onClick={() => {
                        this.toggleTab('Dashboard');
                      }}>
                      {t('user_preferences:dashboard')}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem
                    className={classnames({
                      'border-left border-primary rounded border-sz-3':
                        activeTab === '2'
                    })}>
                    <NavLink
                      className={classnames('rounded', {
                        'active font-weight-bold': activeTab
                          ? activeTab === 'rule_management'
                          : false
                      })}
                      onClick={() => {
                        this.toggleTab('rule_management');
                      }}>
                      {t('user_preferences:rule_management')}
                    </NavLink>
                  </NavItem> */}
                  {/* TO REMOVE ? */}
                  {/* <NavItem
                    className={classnames({
                      'border-left border-primary rounded border-sz-3':
                        activeTab === '2'
                    })}>
                    <NavLink
                      className={classnames('rounded', {
                        'active font-weight-bold': activeTab
                          ? activeTab === 'favorite_rules'
                          : false
                      })}
                      onClick={() => {
                        this.toggleTab('favorite_rules');
                      }}>
                      {t('user_preferences:rule_settings')}
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="Dashboard">
                    {activeTab === 'Dashboard' && (
                      <Formik {...formikConfig(this.props)}>
                        {props => {
                          const { isSubmitting, dirty, values } = props;

                          return (
                            <Form>
                              <Container>
                                <div className="p-2">
                                  <Row className="justify-content-between px-3 ">
                                    <h5 className="text-primary">
                                      {' '}
                                      {/* {t('user_preferences:dashboard')} */}
                                    </h5>
                                    <div>
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="mx-1"
                                        disabled={!handleSettingsUpdate}
                                        id="formSaveButtonDashboard">
                                        <i className="fas fa-save fs-1-2x" />
                                      </Button>
                                      <Button
                                        color="info"
                                        type="button"
                                        className="mx-1"
                                        id="formResetButtonDashboard"
                                        onClick={this.onResetRuleToDefaults}>
                                        <i className="fas fa-undo fs-1-2x" />
                                      </Button>

                                      <UncontrolledTooltip
                                        fade={false}
                                        target="formSaveButtonDashboard"
                                        modifiers={{
                                          computeStyle: {
                                            gpuAcceleration: false
                                          }
                                        }}>
                                        {t('user_preferences:save')}
                                      </UncontrolledTooltip>

                                      <UncontrolledTooltip
                                        fade={false}
                                        target="formResetButtonDashboard"
                                        modifiers={{
                                          computeStyle: {
                                            gpuAcceleration: false
                                          }
                                        }}>
                                        {t('user_preferences:reset')}
                                      </UncontrolledTooltip>
                                    </div>
                                  </Row>
                                </div>

                                <Row>
                                  <Col sm="12" md={{ size: 6, offset: 3 }}>
                                    <FormGroup tag="fieldset" />
                                    {user_preference(dashboard_defaults, t).map(
                                      (item, index) => {
                                        return (
                                          <FormGroup key={`input-${index}`}>
                                            <p>
                                              <span className="font-weight-bold">
                                                {item.field_label}
                                              </span>
                                              <FormText color="muted">
                                                {item.field_description}
                                              </FormText>
                                            </p>
                                            <div>
                                              {item.inputs.map(
                                                (val, index2) => {
                                                  return (
                                                    <CustomInput
                                                      key={`input-${index2}-${val.name}`}
                                                      id={`${index2}${val.name}`}
                                                      type="radio"
                                                      label={val.label}
                                                      name={val.name}
                                                      inline
                                                      value={val.value}
                                                      checked={
                                                        this.state[val.value]
                                                      }
                                                      onChange={e => {
                                                        this.setChecked(
                                                          val.value
                                                        );
                                                        this.handleRadioInputChange(
                                                          values,
                                                          e
                                                        );
                                                      }}
                                                    />
                                                  );
                                                }
                                              )}
                                            </div>
                                            <hr />
                                          </FormGroup>
                                        );
                                      }
                                    )}

                                    <FormGroup />
                                  </Col>
                                </Row>
                              </Container>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                  </TabPane>
                  {/* <TabPane tabId="rule_management">
                    {activeTab === 'rule_management' && (
                      <UserRuleManagement {...this.props} />
                    )}
                  </TabPane> */}
                  {/* TO REMOVE  ?  */}
                  {/* <TabPane tabId="favorite_rules">
                    <RulesGroups {...this.props} />
                  </TabPane> */}
                </TabContent>
              </Col>
            </Row>
          </Container>
        ) : (
          <div
            className={`d-flex align-items-center justify-content-center ${styles.spinnerParentDiv}`}>
            <Spinner
              type="grow"
              className={styles.spinnerDimention}
              color="primary"
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation(['user_preferences'])(UserPreference);
