import React, { Component, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Field, Form, getIn, FieldArray } from 'formik';
import { compose } from 'recompose';
import {
  Container,
  Row,
  Col,
  Badge,
  FormGroup,
  Button,
  ListGroup,
  ListGroupItem,
  Input,
  InputGroup,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  Progress,
  Modal
} from 'reactstrap';
import classnames from 'classnames';
import { convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import prettyBytes from 'pretty-bytes';

import { completeTaskAction, addFileToTask } from '../../../store/actions';
import { dateFormatting } from '../../../locales/dateFormat';

import CorrespondenceEditor from '../Submission/Correspondence/Editor';
import DisplayMessage from '../Submission/Correspondence/DisplayMessage';
import Message from '../../UI/Message';
import styles from './taskManagement.module.css';

const statusToPillColor = {
  ACTIVE: 'warning',
  COMPLETED: 'success ',
  PENDING: 'info'
};

const ActionDetail = ({
  action,
  t,
  projectId,
  isHighlighted,
  taskId,
  taskDocuments,
  displayMode = 'disabled',
  actionList,
  taskDetailBlock,
  setDuplicatedFiles,
  setIsDuplicatedFilesModalOpen,
  ...props
}) => {
  const actor = action.actors[0];

  const previousActions = action.dependencies.filter(d => d.action_id);
  const nextActions = actionList.filter(a =>
    a.dependencies.some(d => d.action_id === action.action_id)
  );

  const [fileUploadProgresses, setFileUploadProgresses] = useState([]);
  const [messageContent, setMessageContent] = useState(
    ContentState.createFromText('')
  );
  const [isMessageLoaded, setMessageLoaded] = useState(false);
  useEffect(() => {
    if (action.status === 'COMPLETED') {
      try {
        const messageContentVar = convertFromRaw(
          JSON.parse(decodeURI(action.message))
        );
        setMessageContent(messageContentVar);
        setMessageLoaded(true);
      } catch (err) {
        setMessageLoaded(false);
      }
    }
  }, [action.message, action.status]);

  const formikConfig = {
    initialValues: { message: '', files: [] },
    validate: values => {
      const editorState = values.message;
      if (
        !editorState.blocks ||
        editorState.blocks.filter(b => b.text !== '').length === 0
      ) {
        return {
          message: t('error:message_content_required')
        };
      }
    },
    validateOnChange: false,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      // We have to add the file sequencially due to concurent update in the db otherwise
      async function* generateAddFileSequence(files, taskId) {
        for (let i in files) {
          const file = files[i];
          const onUploadProgress = progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const newProgresses = fileUploadProgresses;
            newProgresses[i] = percentCompleted;
            setFileUploadProgresses(newProgresses);
          };
          const documentId = await props.addFileToTask(
            taskId,
            projectId,
            file,
            onUploadProgress
          );
          yield documentId;
        }
      }
      try {
        const formattedValues = {
          ...values,
          message: JSON.stringify(values.message)
        };
        setFileUploadProgresses(formattedValues.files.map(() => 0));

        let generator = generateAddFileSequence(formattedValues.files, taskId);
        const documentIds = [];

        let next = await generator.next();
        while (!next.done) {
          const documentId = next.value;
          documentIds.push(documentId);
          next = await generator.next();
        }
        // for await ... of not supported on edge
        // for await (let documentId of generator) {
        //   documentIds.push(documentId);
        // }
        await props.completeTaskAction(action.action_id, taskId, projectId, {
          message: formattedValues.message,
          document_added: documentIds
        });
        if (props.onSubmitCallback) {
          await props.onSubmitCallback();
        }
        setSubmitting(false);
      } catch (err) {
        Message.error(t(`error:${err.message}`));
        setSubmitting(false);
      }
    }
  };

  return (
    <Row
      className={`rounded mt-4 py-2 ${
        isHighlighted ? styles.actionDetailContainerHighlighted : 'shadow'
      }`}>
      <Container>
        <div className="d-flex justify-content-between">
          <div className={styles.actionName}>
            <h5 className="text-primary py-1">{action.name} </h5>
          </div>
          <div className="d-flex">
            <div>{`${actor.first_name || ''} ${actor.last_name || ''} (${
              actor.email
            })`}</div>
            <div className="ml-2">
              <Badge color={statusToPillColor[action.status] || 'secondary'}>
                {t(`${action.status}`)}
              </Badge>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {(previousActions.length > 0 || nextActions.length > 0) && (
            <div>
              (
              {previousActions.length > 0 && (
                <>
                  <Button
                    color="link"
                    className="p-0"
                    id={`previousActionsPopoverButton-${action.action_id}`}>
                    {previousActions.length} previous
                  </Button>
                  <UncontrolledPopover
                    boundariesElement="scrollParent"
                    placement="bottom"
                    trigger="hover"
                    delay={{ show: 0, hide: 500 }}
                    target={`previousActionsPopoverButton-${action.action_id}`}>
                    <PopoverBody>
                      <ListGroup className={styles.actionsListGroup}>
                        {previousActions.map(pa => (
                          <ListGroupItem
                            className="p-1"
                            key={`na-${pa.action_id}`}>
                            <Button
                              color="link"
                              className="p-1"
                              onClick={() => {
                                document
                                  .getElementById('scroll-container')
                                  .scrollTo({
                                    top:
                                      document.getElementById(
                                        `action-${pa.action_id}`
                                      ).offsetTop -
                                      (taskDetailBlock.current
                                        ? taskDetailBlock.current.clientHeight
                                        : 0),
                                    behavior: 'smooth'
                                  });
                              }}>
                              {pa.name}
                            </Button>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
              {previousActions.length > 0 && nextActions.length > 0 && <>, </>}
              {nextActions.length > 0 && (
                <>
                  <Button
                    color="link"
                    className="p-0"
                    id={`nextActionsPopoverButton-${action.action_id}`}>
                    {nextActions.length} next
                  </Button>
                  <UncontrolledPopover
                    boundariesElement="scrollParent"
                    placement="bottom"
                    trigger="hover"
                    delay={{ show: 0, hide: 300 }}
                    target={`nextActionsPopoverButton-${action.action_id}`}>
                    <PopoverBody>
                      <ListGroup className={styles.actionsListGroup}>
                        {nextActions.map(na => (
                          <ListGroupItem
                            className="p-1"
                            key={`na-${na.action_id}`}>
                            <Button
                              color="link"
                              className="p-1"
                              onClick={() => {
                                document
                                  .getElementById('scroll-container')
                                  .scrollTo({
                                    top:
                                      document.getElementById(
                                        `action-${na.action_id}`
                                      ).offsetTop -
                                      (taskDetailBlock.current
                                        ? taskDetailBlock.current.clientHeight
                                        : 0),
                                    behavior: 'smooth'
                                  });
                              }}>
                              {na.name}
                            </Button>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
              )
            </div>
          )}
        </div>
        <Row>
          <Col>
            <Formik {...formikConfig}>
              {formikProps => {
                const { setFieldValue, setFieldTouched, handleBlur } =
                  formikProps;
                const onChange = editorState => {
                  setFieldValue(
                    'message',
                    convertToRaw(editorState.getCurrentContent())
                  );
                };
                const onBlur = () => {
                  setFieldTouched('message', true);
                  handleBlur('message');
                };
                return (
                  <Form className="w-100">
                    <Container>
                      {displayMode !== 'readonly' ? (
                        <FormGroup row className="p-2">
                          <Field
                            name="message"
                            render={() => {
                              const { touched, errors } = formikProps;
                              const isValid =
                                getIn(touched, 'message') &&
                                !getIn(errors, 'message');
                              const isInvalid = !!(
                                getIn(touched, 'message') &&
                                getIn(errors, 'message')
                              );
                              return (
                                <>
                                  <CorrespondenceEditor
                                    isValid={isValid}
                                    isInvalid={isInvalid}
                                    onChange={onChange}
                                    invalidFeedback={getIn(errors, 'message')}
                                    onBlur={onBlur}
                                    projectId={projectId}
                                    stageName={''}
                                    disabled={displayMode !== 'edit'}
                                    withUserMention={true}
                                    withIssueMention={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </FormGroup>
                      ) : (
                        <Row
                          className={`p-2 rounded ${styles.displayMessageContainer}`}>
                          {isMessageLoaded && (
                            <DisplayMessage
                              stageName={''}
                              projectId={projectId}
                              content={messageContent}
                            />
                          )}
                        </Row>
                      )}
                      {displayMode === 'edit' && (
                        <FormGroup>
                          <InputGroup>
                            <div className="custom-file">
                              <label
                                className="custom-file-label"
                                htmlFor="customFile">
                                {t('task_management:upload_file')}
                              </label>
                              <FieldArray
                                name="files"
                                component={() => (
                                  <Input
                                    type="file"
                                    id="customFile"
                                    className="custom-file-input"
                                    // accept=".docx,.pdf"
                                    onChange={e => {
                                      let files = formikProps.values.files;
                                      for (
                                        let index = 0;
                                        index < e.target.files.length;
                                        index++
                                      ) {
                                        files.push(e.target.files[index]);
                                      }
                                      setFieldValue('files', files);
                                      const duplicatedFiles = files
                                        .filter(file =>
                                          taskDocuments.some(
                                            taskDoc =>
                                              taskDoc.name === file.name
                                          )
                                        )
                                        .map(f => f.name);
                                      if (duplicatedFiles.length) {
                                        setDuplicatedFiles(duplicatedFiles);
                                        setIsDuplicatedFilesModalOpen(true);
                                      }
                                    }}
                                    multiple={true}
                                  />
                                )}
                              />
                            </div>
                          </InputGroup>
                          {formikProps.values.files.length > 0 && (
                            <ListGroup>
                              {formikProps.values.files.map((file, index) => {
                                return (
                                  <ListGroupItem key={`files-${index}`}>
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <Row>
                                          {file.name}
                                          {file.size &&
                                            ` (${prettyBytes(file.size)})`}
                                        </Row>
                                        <Row>
                                          {file.lastModified && (
                                            <div className="text-secondary">
                                              {dateFormatting(
                                                file.lastModified
                                              )}
                                            </div>
                                          )}
                                        </Row>
                                      </div>
                                      <div>
                                        <Button
                                          id={`file-remove-${index}`}
                                          color="light"
                                          outline
                                          onClick={e => {
                                            e.stopPropagation();
                                            setFieldValue(
                                              'files',
                                              formikProps.values.files.filter(
                                                f => f.name !== file.name
                                              )
                                            );
                                          }}>
                                          <span>
                                            <i className="fas fa-trash text-danger" />
                                          </span>
                                        </Button>
                                        <UncontrolledTooltip
                                          fade={false}
                                          placement="auto"
                                          target={`file-remove-${index}`}>
                                          {t('task_management:remove')}
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                    {formikProps.isSubmitting && (
                                      <Progress
                                        className="mt-2 w-100"
                                        color={
                                          fileUploadProgresses[index] === 100
                                            ? 'success'
                                            : 'info'
                                        }
                                        value={fileUploadProgresses[index]}>
                                        {fileUploadProgresses[index]} %
                                      </Progress>
                                    )}
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          )}
                        </FormGroup>
                      )}
                      {displayMode === 'edit' && (
                        <FormGroup row className="justify-content-end">
                          <Button
                            color="success"
                            type="submit"
                            disabled={formikProps.isSubmitting}>
                            {t('task_management:submit')}
                          </Button>
                        </FormGroup>
                      )}
                    </Container>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    completeTaskAction: (actionId, taskId, projectId, data) =>
      dispatch(completeTaskAction(actionId, taskId, projectId, data)),
    addFileToTask: (taskId, projectId, file, onUplodaProgress) =>
      dispatch(addFileToTask(taskId, projectId, file, onUplodaProgress))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['main', 'task_management'])
)(ActionDetail);
