import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput as CustomInputFromReact
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';

import CustomInput from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';
import OrganizationStructure from './OrganizationTreeForProjectDetail';

const modalFormikConfig = props => {
  return {
    initialValues: {
      // email: props.member ? props.member.email : '',
      // role: props.member ? props.member.role : ''
      includeSubgroup: false,
      operation: 'add',
      selected_group: {},
      role: props.member ? props.member.role : ''
    },
    validationSchema: Yup.object().shape({
      role: Yup.string().required('Required')
    }),
    onSubmit: values => {
      props.onSubmit(values);
    }
  };
};

class ModalAddProjectToOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sharingOption: true
    };
  }

  toggleSharingOption = status => {
    this.setState({
      sharingOption: status
    });
  };

  render() {
    const {
      toggle,
      index,
      className,
      open,
      member,
      t,
      onSubmit,
      roles,
      mode,
      existingMemberEmail,
      userOrganizationId,
      disabled,
      relatedGroups,
      allowToShare
    } = this.props;

    return (
      <Modal isOpen={open} className={className} toggle={toggle} size="lg">
        <Formik
          {...modalFormikConfig({
            ...{ member, index, onSubmit, t, mode, existingMemberEmail }
          })}>
          {formikProps => {
            const {
              setFieldValue,
              values,
              submitForm,
              isSubmitting
            } = formikProps;

            return (
              <Form>
                <ModalHeader>{t('project:project_sharing')}</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col xs={12} sm={6}>
                      <p>
                        <span>Group</span>
                      </p>
                      <Field
                        name="selected_group"
                        label="Select group"
                        render={e => (
                          <OrganizationStructure
                            organizationId={userOrganizationId}
                            setFieldValue={setFieldValue}
                            relatedGroups={relatedGroups}
                            toggleSharingOption={this.toggleSharingOption}
                          />
                        )}
                      />
                    </Col>

                    <Col xs={12} sm={6}>
                      <Field
                        name="role"
                        disabled={member && member.role === 'owner'}
                        size="md"
                        placeholder={t('project:role')}
                        label={t('project:role')}
                        options={roles.map(r => ({
                          value: r,
                          label: t(`main:role_${r}`)
                        }))}
                        component={CustomSelectInput}
                      />
                      <Field
                        name="operation"
                        size="md"
                        label="Operation"
                        render={e => (
                          <div>
                            <p>
                              <span>Operation</span>
                            </p>
                            <CustomInputFromReact
                              type="radio"
                              id="exampleCustomRadio"
                              label="share"
                              inline
                              value="isShared"
                              defaultChecked
                              onChange={e => {
                                setFieldValue('operation', 'add');
                              }}
                              checked={values.operation === 'add'}
                            />
                            {/* <CustomInputFromReact
                                type="radio"
                                id="exampleCustomRadio2"
                                label="unshare"
                                inline
                                value="unShared"
                                checked={values.operation === 'remove'}
                                onChange={e => {
                                  setFieldValue('operation', 'remove');
                                }}
                              /> */}
                          </div>
                        )}
                      />
                      <hr></hr>
                      {this.state.sharingOption && (
                        <Field
                          name="includeSubgroup"
                          render={e => (
                            <div>
                              <p>
                                <span>Sharing option</span>
                              </p>
                              <CustomInputFromReact
                                type="checkbox"
                                label="share with subgroup"
                                checked={formikProps.values.includeSubgroup}
                                id="includeSubgroup"
                                onChange={el => {
                                  setFieldValue(
                                    'includeSubgroup',
                                    el.target.checked
                                  );
                                }}
                              />
                            </div>
                          )}
                        />
                      )}

                      {/* <Field
                          name="role"
                          disabled={member && member.role === 'owner'}
                          size="md"
                          placeholder={t('project:role')}
                          label={t('project:role')}
                          options={roles.map(r => ({
                            value: r,
                            label: t(`main:role_${r}`)
                          }))}
                          component={CustomSelectInput}
                        /> */}
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    color="primary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();

                      if (formikProps.isValid) {
                        toggle();
                      }
                    }}>
                    {t('share')}
                  </Button>
                  <Button type="button" color="secondary" onClick={toggle}>
                    {t('main:cancel')}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ModalAddProjectToOrg.propTypes = {
  toggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string
  }),
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default compose(withTranslation(['project', 'main', 'error']))(
  ModalAddProjectToOrg
);
