import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  CustomInput as CustomReactstrapInput
} from 'reactstrap';

import MaintenanceCreationEditor from './Editor';
import MaintenanceAnnouncmentListing from './MaintenanceAnnouncementListing';

import { createMaintenanceAnnouncement } from '../../../../../store/actions';

import Message from '../../../../UI/Message';
import CustomInput from '../../../../UI/Input/customInput';

import { format, compareAsc, parse } from 'date-fns';
const en = require('date-fns/locale/en');

const getDateTime = advanceIn => {
  let now = new Date();
  if (advanceIn === 'advanceInMinutes') {
    now.setMinutes(now.getMinutes() + 5); // 5 minutes advance
  } else if (advanceIn === 'advanceInHours') {
    now.setHours(now.getHours() + 1);
  }

  const current_date = format(now, 'YYYY-MM-DD');
  const current_time = format(now, 'HH:mm');
  return current_date + 'T' + current_time;
};

const formatDate = date => {
  return format(date, 'DD-MM-YYYY hh:mm A');
};

const checkIfDateisAfterCurrentDate = date => {
  const selectedDate = Date.parse(new Date(date));
  const now = Date.now();

  return !(selectedDate < now);
};

class MaintenanceAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      itemRelatedTab: 'rel-items-tab-documents',
      fileUploadProgresses: []
    };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };
  formikConfig = {
    initialValues: {
      body: '',
      maintenance_date_start_date: getDateTime('advanceInMinutes'),
      maintenance_date_end_date: getDateTime('advanceInHours'),
      display_start_date: '',
      display_end_date: '',
      enable_display: false,
      generate_message: false,
      use_template_message: false
    },
    validationSchema: Yup.object().shape(
      {
        maintenance_date_start_date: Yup.date()
          .required('Required')
          .test(
            'time',
            'Date must be later than current date-time.',
            function test(currentValue) {
              return checkIfDateisAfterCurrentDate(currentValue);
            }
          ),

        maintenance_date_end_date: Yup.date()
          .required('Required')
          .test(
            'maintenance_date_end_date',
            'Date must be later than maintenance start date',
            function testDisplayStartDate(currentValue) {
              const maintenance_date_start_date = Date.parse(
                this.parent.maintenance_date_start_date
              );
              const current = Date.parse(currentValue);

              if (maintenance_date_start_date < current) {
                return true;
              } else {
                return false;
              }
            }
          ),
        enable_display: Yup.boolean(),
        display_start_date: Yup.date().when('enable_display', {
          is: false,
          then: Yup.date()
            .required('Required')

            .test(
              'display_start_date',
              'Date must be before the actual maintenance and later than current date time',
              function testDisplayStartDate(currentValue) {
                const maintenance_actual_date = Date.parse(
                  this.parent.maintenance_date_start_date
                );
                const current = Date.parse(currentValue);
                const now = Date.now();

                if (current <= now) {
                  return false;
                } else {
                  if (current > maintenance_actual_date) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            )
            .when(
              'display_end_date',
              (endDate, schema) =>
                endDate &&
                schema.max(endDate, 'End date must be earlier than Start date')
            )
        }),
        display_end_date: Yup.date().when('enable_display', {
          is: false,
          then: Yup.date()
            .required('Required')
            .test(
              'display_end_date',
              'Date must be before the actual maintenance and later than display start date',
              function testDisplayStartDate(currentValue) {
                const maintenance_actual_date = Date.parse(
                  this.parent.maintenance_date_start_date
                );

                const display_start_date = Date.parse(
                  this.parent.display_start_date
                );

                const current = Date.parse(currentValue);
                const now = Date.now();
                if (current <= now) {
                  return false;
                } else {
                  if (current > maintenance_actual_date) {
                    return false;
                  } else if (current < display_start_date) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            )
            .when(
              'display_start_date',
              (startDate, schema) =>
                startDate &&
                schema.min(startDate, 'End date must be later than End date')
            )
        })
      },
      ['display_start_date', 'display_end_date']
    ),
    validate: values => {
      const editorState = values.body;

      if (!editorState) {
        return {
          body: 'Required'
        };
      } else {
        if (editorState.blocks.filter(b => b.text !== '').length === 0) {
          return {
            body: 'Required'
          };
        }
      }
    },
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldTouched }) => {
      const { t } = this.props;
      setSubmitting(true);

      const formattedValues = {
        ...values,
        body: JSON.stringify(values.body)
      };

      try {
        await this.props.createMaintenanceAnnouncement(formattedValues);
        // reset the form and fix the display message body showing required after submit
        resetForm();
        setFieldTouched('body', false, false);
        Message.success(t('maintenance_create_success'));
      } catch (error) {
        Message.error(this.props.t('error:default_message'));
      }
    }
  };

  render() {
    const { t } = this.props;

    let current_date = getDateTime();

    return (
      <Formik {...this.formikConfig}>
        {formikProps => {
          const {
            isSubmitting,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            onSubmit,
            validateForm,
            setFieldTouched,
            registerField
          } = formikProps;

          return (
            <Form>
              <Row className="justify-content-end px-3 "></Row>
              <Container>
                {/* <Field
                name="body"
                type="textarea"
                label="Body"
                placeholder={t('body')}
                component={CustomInput}
              /> */}
                <h5 className="text-primary py-1">{t('maintenance_date')}</h5>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        name="maintenance_date_start_date"
                        label="Maintenance date"
                        placeholder={t('start_date')}
                        render={props => (
                          <div>
                            <CustomInput
                              type="datetime-local"
                              {...props}
                              name="maintenance_date_start_date"
                              min={current_date}
                              value={values.maintenance_date_start_date}
                              onChange={e => {
                                formikProps.setFieldValue(
                                  'maintenance_date_start_date',
                                  e.target.value
                                );
                              }}
                              label="From"
                            />
                          </div>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Field
                        name="maintenance_date_end_date"
                        label="Maintenance date"
                        placeholder={t('start_date')}
                        render={props => (
                          <div>
                            <CustomInput
                              type="datetime-local"
                              {...props}
                              name="maintenance_date_end_date"
                              min={values.maintenance_date_start_date}
                              value={values.maintenance_date_end_date}
                              onChange={e => {
                                formikProps.setFieldValue(
                                  'maintenance_date_end_date',
                                  e.target.value
                                );
                              }}
                              label="To"
                            />
                          </div>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="text-primary py-1">{t('display_message')}</h5>
                <Field
                  name="enable_display"
                  type="checkbox"
                  render={() => (
                    <FormGroup>
                      <InputGroup>
                        <CustomReactstrapInput
                          type="checkbox"
                          label={t('use_template_message')}
                          id="use_template_message"
                          onChange={e => {
                            formikProps.setFieldValue(
                              'use_template_message',
                              e.target.checked
                            );
                          }}
                          checked={formikProps.values.use_template_message}
                        />
                      </InputGroup>
                    </FormGroup>
                  )}
                />
                <Field
                  name="body"
                  render={() => {
                    const {
                      touched,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                      handleBlur
                    } = formikProps;
                    const fieldName = 'body';

                    const isValid =
                      getIn(touched, fieldName) && !getIn(errors, fieldName);
                    const isInvalid = !!(
                      getIn(touched, fieldName) && getIn(errors, fieldName)
                    );

                    const onChange = editorState => {
                      // if (this.state.defaultMessage) {
                      //   //setFieldTouched('body', true, true);
                      //   console.log('Dex');
                      // }

                      setFieldValue(
                        fieldName,
                        convertToRaw(editorState.getCurrentContent())
                      );
                    };
                    const onFocus = () => {};
                    const onBlur = () => {
                      setFieldTouched(fieldName, true);
                      handleBlur(fieldName);
                    };

                    return (
                      <MaintenanceCreationEditor
                        isValid={isValid}
                        isInvalid={isInvalid}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        invalidFeedback={getIn(errors, fieldName)}
                        defaultMessage={formikProps.values.use_template_message}
                        setFieldValue={setFieldValue}
                        values={values}
                        setFieldTouched={setFieldTouched}
                        isEditorTouched={getIn(touched, fieldName)}
                      />
                    );
                  }}
                />
                <hr />
                <h5 className="text-primary py-1">
                  {t('date_range_before_actual_maintenance')}
                </h5>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        name="display_start_date"
                        type="date"
                        label="Start Date"
                        placeholder={t('start_date')}
                        render={props => {
                          return (
                            <div>
                              <CustomInput
                                type="datetime-local"
                                {...props}
                                name="display_start_date"
                                max={values.maintenance_date_start_date}
                                label="From"
                              />
                            </div>
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Field
                        name="display_end_date"
                        type="date"
                        label="End Date"
                        render={props => (
                          <div>
                            <CustomInput
                              type="datetime-local"
                              {...props}
                              name="display_end_date"
                              max={values.maintenance_date_end_date}
                              min={values.display_start_date}
                              label="To"
                            />
                          </div>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Field
                  name="enable_display"
                  type="checkbox"
                  render={() => (
                    <FormGroup>
                      <InputGroup>
                        <CustomReactstrapInput
                          type="checkbox"
                          label={t('enable_display')}
                          id="enable_display"
                          onChange={e => {
                            formikProps.setFieldValue(
                              'enable_display',
                              e.target.checked
                            );
                            if (e.target.checked) {
                              formikProps.setFieldValue(
                                'display_start_date',
                                getDateTime()
                              );
                              formikProps.setFieldValue(
                                'display_end_date',
                                formikProps.values.maintenance_date_end_date
                              );
                            }
                          }}
                          checked={formikProps.values.enable_display}
                        />
                      </InputGroup>
                    </FormGroup>
                  )}
                />
                <Button
                  type="submit"
                  color="success"
                  // disabled={isSubmitting}
                  onClick={validateForm}>
                  {t('create')}
                </Button>{' '}
              </Container>

              <hr />
              <div>
                <MaintenanceAnnouncmentListing {...this.props} />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createMaintenanceAnnouncement: data =>
      dispatch(createMaintenanceAnnouncement(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['adminConfigurations', 'error'])
)(MaintenanceAnnouncement);
