import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import styles from './Styles.module.css';

import SubmissionCreation from '../Submission/SubmissionCreationContainer';
import SubmissionDetail from '../Submission/SubmissionDetailContainer';

export class ProjectSubmissionPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      stageDropDownValue:
        props.project.stagesavailable.find(s => s.name === props.stageName) ||
        props.project.stagesavailable[0],

      dropdownOpen: false
    };
  }

  componentDidMount() {
    const projectId = this.props.projectId;
    const stageName = this.props.stageName;
    this.props.history.replace(`/project/${projectId}/submission/${stageName}`);
    this.props.fetchProjectStage(projectId, stageName).then(() => {
      this.setState({ isLoaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const projectId = this.props.projectId;
    const stageName = this.props.stageName;
    if (
      prevProps.stageName !== stageName ||
      prevProps.projectId !== projectId
    ) {
      this.setState({ isLoaded: false });
      this.props.fetchProjectStage(projectId, stageName).then(() => {
        this.setState({ isLoaded: true });
      });
    }
  }

  stageChangeValue = stageData => {
    const projectId = this.props.projectId;
    this.props.history.push(
      `/project/${projectId}/submission/${stageData.name}`
    );
    this.props.stageChangeValue(stageData);
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const {
      stage,
      t,
      project,
      projectId,
      stageName,
      sidebarRef,
      stageDropDownValue
    } = this.props;
    const { dropdownOpen, isLoaded } = this.state;
    const projectStages = project.stagesavailable || [];
    return (
      <Container>
        <Row>
          <Col>
            <div className="shadow p-3 mt-3 rounded">
              <Row className="justify-content-between px-3">
                <h5 className="text-primary">{t('submission')}</h5>
              </Row>
              <Row className="px-3 d-flex justify-content-between mt-1">
                <div>
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle
                      caret
                      color="primary"
                      className={styles.maxWidth400}>
                      <em>{t('stage')}: </em>
                      {stageDropDownValue.display_name}
                    </DropdownToggle>
                    <DropdownMenu className={`${styles.maxWidth400} ${styles.maxWidth400} ${styles.overflowAuto}`} >
                      <DropdownItem header>{t('stages')}</DropdownItem>
                      {projectStages.map((stage, key) => {
                        return (
                          <DropdownItem
                            key={`stage-${key}`}
                            onClick={() =>
                              this.stageChangeValue({
                                name: stage.name,
                                display_name: stage.display_name
                              })
                            }>
                            {stage.display_name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {isLoaded && (
                  <div>
                    {stage &&
                      stage.submissions &&
                      stage.submissions.length > 0 && (
                        <>
                          <a href="#correspondence-section">
                            {t('scroll_correspondence')}
                          </a>{' '}
                          |{' '}
                          <a href="#attachments-section">
                            {t('scroll_attachments')}
                          </a>
                        </>
                      )}
                  </div>
                )}
              </Row>
            </div>
            {isLoaded && (
              <>
                {stage && stage.submissions && stage.submissions.length > 0 ? (
                  <SubmissionDetail
                    projectId={projectId}
                    stageName={stageName}
                    submissionId={stage.submissions[0].submission_id}
                    sidebarRef={sidebarRef}
                  />
                ) : (
                  <SubmissionCreation
                    projectId={projectId}
                    stageName={stageName}
                  />
                )}
              </>
            )}
            {!isLoaded && (
              <div className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
                <Spinner
                  type="grow"
                  className={styles.spinnerDimention}
                  color="primary"
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation(['project', 'main', 'error'])(
  ProjectSubmissionPresenter
);
