import {
  statActionType,
  adminStatsChecksSummary
} from '../../constants/actionType';
import { performRequest } from '../requests';

export const fetchAllStats = filterData => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: statActionType.RESET_STATS });
  return dispatch(
    performRequest(
      'post',
      '/api/admin/statistics/all',
      { ...filterData },
      requestConfig,
      'GET_ALL_STATS'
    )
  )
    .then(res => res.data)
    .then(data => {
      dispatch({ type: statActionType.MERGE_STATS, data });
    });
};

export const fetchProjectStats = filterData => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: statActionType.RESET_STATS });
  return dispatch(
    performRequest(
      'post',
      '/api/admin/statistics/project',
      { ...filterData },
      requestConfig,
      'GET_ALL_STATS'
    )
  )
    .then(res => res.data)
    .then(data => {
      dispatch({ type: statActionType.MERGE_STATS, data });
    });
};

export const fetchPortalStats =
  (checkType, isClearCache) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    dispatch({ type: statActionType.RESET_STATS });
    return dispatch(
      performRequest(
        'post',
        '/api/admin/statistics/portal_usage',
        {
          criteria: checkType,
          isClearCache: isClearCache
        },
        requestConfig,
        'GET_ALL_STATS'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error(data.message || 'default_message');
        }
      })

      .then(data => {
        dispatch({ type: adminStatsChecksSummary.FETCH_STATS_SUMMARY, data });
      });
  };

export const fetchSelectedCheckType = checkType => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: statActionType.RESET_STATS });
  return dispatch(
    performRequest(
      'post',
      `/api/admin/statistics/portal_usage/${checkType}`,
      {
        isClearCache: true
      },
      requestConfig,
      'GET_ALL_STATS'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        return data.data;
      } else {
        throw new Error(data.message || 'default_message');
      }
    })

    .then(data => {
      dispatch({ type: adminStatsChecksSummary.SET_STATS_SUMMARY, data });
    });
};

export const fetchPortalStatsAllEntity =
  (criteria = 'all') =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    dispatch({ type: statActionType.RESET_STATS });
    return dispatch(
      performRequest(
        'get',
        '/api/admin/portal/statistics/all',
        {
          criteria: criteria
        },
        requestConfig,
        'GET_ALL_STATS'
      )
    )
      .then(res => res.data)
      .then(data => {
        return data;
      });
  };

export const fetchTopNC =
  ({ pagination }) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        '/api/admin/statistics/portal_usage/list/topNC',
        {
          pagination: pagination
        },
        requestConfig,
        'GET_ALL_STATS'
      )
    )
      .then(res => res.data)
      .then(data => {
        return data.data;
      });
  };

export const fetchProjectRulesChecked =
  ({ pagination }) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        '/api/admin/statistics/portal_usage/list/projectRulesChecked',
        {
          pagination: pagination
        },
        requestConfig,
        'GET_ALL_STATS'
      )
    )
      .then(res => res.data)
      .then(data => {
        return data.data;
      });
  };
