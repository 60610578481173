import React, { useContext, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import knowledgehubContext from '../store/config/context';
import {
  setSelectedCategory,
  setIsCategoryModalOpen,
  setIsAddingItemsInExistingCat,
  setIsImportFileModalOpen
} from '../store/actions';
import './knowledgehub.css';
import { withTranslation } from 'react-i18next';
import AddNewCategoryModal from './AddNewCategoryModal';
import { useSelector } from 'react-redux';
import ConfirmDeleteCatModal from './ConfirmDeleteCatModal';
import BatchUpdateContentModal from './BatchUpdateContentModal';

const sortCategories = (isAsc, list) => {
  const keys = Object.keys(list);
  return isAsc
    ? keys.sort((a, b) => a.localeCompare(b))
    : keys.sort((a, b) => b.localeCompare(a));
};

const Sidebar = props => {
  const { state, dispatch, isWebviewerMode } = useContext(knowledgehubContext);
  const { t } = props;
  const onSelectCategory = (category, selectedCategoryKey) => {
    dispatch(setSelectedCategory(category, selectedCategoryKey));
  };

  const { authUser } = useSelector(state => state);
  const [isAscending, setIsAcsending] = useState(true);

  const onToggleSort = isAsc => {
    setIsAcsending(!isAsc);
  };

  const sortedResultList = sortCategories(isAscending, state.resultList);
  const sortedFavResultList = sortCategories(
    isAscending,
    state.favoriteResultList
  );

  const handleIsAddingItemsInExistingCat = isAdding =>
    dispatch(setIsAddingItemsInExistingCat(isAdding));

  const handleAddCategory = () => {
    handleIsAddingItemsInExistingCat(false);
    dispatch(setIsCategoryModalOpen(!state.isAddCategoryModalOpen));
  };

  const handleImportFileModal = () =>
    dispatch(setIsImportFileModalOpen(!state.isImportFileModalOpen));

  const [deleteCatModalOpened, setDeleteCatModalOpened] = useState(false);
  return (
    <div className="h-100 border-top sidebarContainer">
      <div className="d-flex bg-primary">
        <div className="mr-auto text-white">
          <h5 className="m-0 p-2">{t('knowledgehub')}</h5>
        </div>
        {authUser.function === 'ADMIN' && !isWebviewerMode ? (
          <div>
            <Button
              color="primary"
              className="px-0"
              onClick={handleImportFileModal}>
              <div className="p-2">
                <i className="fas fa-file-import"></i>
              </div>
            </Button>
            <BatchUpdateContentModal />
            <Button
              color="primary"
              className="px-0"
              onClick={handleAddCategory}>
              <div className="p-2">
                <i className="fas fa-plus-circle"></i>
              </div>
            </Button>
            <AddNewCategoryModal />
          </div>
        ) : null}

        <div>
          <Button
            color="primary"
            className="px-2"
            onClick={() => onToggleSort(isAscending)}>
            <i
              className={`fas fa-sort-alpha-${
                isAscending ? 'up' : 'down-alt'
              }`}></i>
          </Button>
        </div>
      </div>

      {!!Object.keys(state.resultList).length &&
        !!sortedFavResultList.length &&
        !!Object.keys(state.favoriteResultList).find(
          liKey => !!state.favoriteResultList[liKey].list.length
        ) && (
          <div className="text-dark py-1 px-3 font-weight-bold">
            {props.t('favorites')}
          </div>
        )}
      <div>
        {!!Object.keys(state.resultList).length &&
          sortedFavResultList.map(category => {
            if (!!!state.favoriteResultList[category].list.length) return null;
            return (
              <Row
                noGutters
                key={category}
                id={category}
                className={`border category ${
                  category === state.selectedCategory &&
                  state.selectedCategoryKey === 'favoriteResultList'
                    ? 'selectCategory'
                    : ''
                } `}
                onClick={() => {
                  onSelectCategory(category, 'favoriteResultList');
                }}>
                <Col className="p-3 " xs="7" sm="8" md="9" lg="10">
                  <div>
                    <i className="fas fa-book text-muted"></i> {category}
                  </div>
                </Col>
              </Row>
            );
          })}
      </div>
      {
        // !!sortedResultList.length ||
        !!Object.keys(state.resultList).find(
          liKey =>
            state.resultList[liKey] &&
            state.resultList[liKey].list &&
            state.resultList[liKey].list.length
        ) && (
          <div className="text-dark py-1 px-3 font-weight-bold">
            {props.t(`${state.searchText ? 'search_result' : 'default'}`)}
          </div>
        )
      }
      <div>
        {sortedResultList.map(category => {
          if (state.resultList[category] && !!!state.resultList[category].list)
            return null;
          return (
            <Row
              noGutters
              key={category}
              id={category}
              className={`border category ${
                category === state.selectedCategory &&
                state.selectedCategoryKey === 'resultList'
                  ? 'selectCategory'
                  : ''
              } `}
              onClick={() => {
                onSelectCategory(category, 'resultList');
              }}>
              <Col className="p-3 text-break" xs="7" sm="8" md="9" lg="10">
                <div>
                  <i className="fas fa-book text-muted"></i> {category}
                </div>
              </Col>
              {authUser.function === 'ADMIN' && !isWebviewerMode ? (
                <Col>
                  <Button
                    size="sm"
                    onClick={() =>
                      setDeleteCatModalOpened(!deleteCatModalOpened)
                    }
                    color="transparent"
                    className="p-3 bg-transparent">
                    <i className="fas fa-trash-alt text-danger"></i>
                  </Button>
                </Col>
              ) : null}
            </Row>
          );
        })}
      </div>
      {authUser.function === 'ADMIN' && !isWebviewerMode ? (
        <ConfirmDeleteCatModal
          deleteCatModalOpened={deleteCatModalOpened}
          setDeleteCatModalOpened={setDeleteCatModalOpened}
          categoryData={state.resultList[state.selectedCategory]}
          t={t}
        />
      ) : null}
    </div>
  );
};

export default withTranslation(['knowledgeHub'])(Sidebar);
