import { performRequest } from '../requests';
import { resultActionType } from '../../constants/actionType';

const { RESULT_ALL, RESULT_LAST, RESET_RESULTS } = resultActionType;

export const getAllResults = (projectId, stageName) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({
    type: RESET_RESULTS
  });
  return dispatch(
    performRequest(
      'get',
      `api/project/${projectId}/${stageName}`,
      {},
      requestConfig,
      'RESULT_ALL',
      true
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(resultList => {
      const { jobs, models } = resultList;
      dispatch({
        type: RESULT_ALL,
        results: jobs,
        models
      });
    });
};

export const getResultLast = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({
    type: RESET_RESULTS
  });
  return dispatch(
    performRequest(
      'get',
      `/api/project/${projectId}/result/last`,
      {},
      requestConfig,
      'RESULT_LAST'
    )
  ).then(lastResult => {
    dispatch({
      type: RESULT_LAST,
      results: lastResult.data
    });
  });
};

export const resetResults = () => ({
  type: RESET_RESULTS
});
