export const newsActionType = {
  MERGE_NEWS: 'MERGE_NEWS',
  ADD_NEWS: 'ADD_NEWS',
  UPDATE_NEWS: 'UPDATE_NEWS',
  REMOVE_NEWS: 'REMOVE_NEWS',
  RESET_NEWS: 'RESET_NEWS',
  EXPAND_NEWS: 'EXPAND_NEWS',
  FRONTPAGE_MERGE_NEWS: 'FRONTPAGE_MERGE_NEWS'
};

export const projectActionType = {
  MERGE_PROJECTS: 'MERGE_PROJECTS',
  ADD_PROJECT: 'ADD_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  REPLACE_PROJECTS: 'REPLACE_PROJECTS',
  ADD_STAGE: 'ADD_STAGE',
  ADD_STAGE_AVAILABLE: 'ADD_STAGE_AVAILABLE',
  RESET_PROJECTS: 'RESET_PROJECTS',
  ADD_PROJECT_NOTIFICATIONS: 'ADD_PROJECT_NOTIFICATIONS',
  REMOVE_PROJECT_STAGE: 'REMOVE_PROJECT_STAGE'
};

export const rolesActionType = {
  RESET_ROLES: 'RESET_ROLES',
  MERGE_ROLES: 'MERGE_ROLES'
};

export const userFunctionActionType = {
  RESET_USER_FUNCTION: 'RESET_USER_FUNCTION',
  MERGE_USER_FUNCTION: 'MERGE_USER_FUNCTION'
};

export const rulesActionType = {
  RESET_RULES: 'RESET_RULES',
  MERGE_RULES: 'MERGE_RULES',
  SET_RULE_PARAMS: 'SET_ADMIN_RULE_PARAMS',
  UPDATE_RULE_PARAMETER: 'UPDATE_RULE_PARAMETER',
  UPDATE_MULTIPLE_RULE_PARAMETER: 'UPDATE_MULTIPLE_RULE_PARAMETER',
  UPDATE_RULE_SET: 'UPDATE_RULE_SET',
  SET_ENTITY_VERSION_ID: 'SET_ENTITY_VERSION_ID'
};

export const rulesAdminActionType = {
  RESET_RULES_ADMIN: 'RESET_RULES_ADMIN',
  MERGE_RULES_ADMIN: 'MERGE_RULES_ADMIN',
  SET_ADMIN_RULE_PARAMS: 'SET_ADMIN_RULE_PARAMS',
  UPDATE_MULTIPLE_ADMIN_RULE_PARAMETER: 'UPDATE_MULTIPLE_ADMIN_RULE_PARAMETER',
  UPDATE_ADMIN_RULE_PARAMETER: 'UPDATE_ADMIN_RULE_PARAMETER',
  UPDATE_ADMIN_RULE_SET: 'UPDATE_ADMIN_RULE_SET',
  UPDATE_ADMIN_BATCH_RULES: 'UPDATE_ADMIN_BATCH_RULES',
  SET_ENTITY_VERSION_ID: 'SET_ENTITY_VERSION_ID'
};

export const ruleHistoryAdminActionType = {
  MERGE_RULE_HISTORY_ADMIN: 'MERGE_RULE_HISTORY_ADMIN'
};

export const authUserActionType = {
  SET_ME: 'SET_ME',
  UNSET_ME: ' UNSET_ME',
  REGISTER_USER: 'REGISTER_USER',
  UPDATE_USER: 'UPDATE_USER',
  RESEND_TOKEN: 'RESEND_TOKEN',
  SET_MY_ORGANIZATION: 'SET_MY_ORGANIZATION',
  SET_USER_SUBSCRIPTION_ID: 'SET_USER_SUBSCRIPTION_ID'
};

export const requestActionType = {
  PUSH_REQUEST_IN_PROCESS: 'PUSH_REQUEST_IN_PROCESS',
  REMOVE_REQUEST_IN_PROCESS: 'REMOVE_REQUEST_IN_PROCESS'
};

export const appActionType = {
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_REDIRECT: 'SET_REDIRECT',
  UPDATE_FILE_UPLOAD_PROGRESS: 'UPDATE_FILE_UPLOAD_PROGRESS',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  SET_PREDEFINED_LIST_OF_STAGES: 'SET_PREDEFINED_LIST_OF_STAGES',
  SET_IS_UPLOADING_FILE: 'SET_IS_UPLOADING_FILE',
  SET_PROJECT_ADDITIONAL_FIELDS: 'SET_PROJECT_ADDITIONAL_FIELDS',
  SET_FOOTER_DATA: 'SET_FOOTER_DATA',
  SET_ALLOWED_IFC_VERSION: 'SET_ALLOWED_IFC_VERSION',
  SET_MODEL_COMPARISON_LIST: 'SET_MODEL_COMPARISON_LIST',
  SET_PROJECT_NOTIFICATION_COUNT: 'SET_PROJECT_NOTIFICATION_COUNT',
  SET_LIST_NOTIFICATION_COUNT: 'SET_LIST_NOTIFICATION_COUNT',
  FETCH_FEATURE_LIST: 'FETCH_FEATURE_LIST',
  MERGE_NAVLINKS: 'MERGE_NAVLINKS',
  SET_PROJECT_THUMBNAIL_SETTINGS: 'SET_PROJECT_THUMBNAIL_SETTINGS',
  SET_REPORT_TEMPLATE_LIST: 'SET_REPORT_TEMPLATE_LIST',
  MERGE_REPORT_TEMPLATE_LIST: 'MERGE_REPORT_TEMPLATE_LIST',
  CUSTOM_REPORT_TEMPLATE_DELETION: 'CUSTOM_REPORT_TEMPLATE_DELETION',
  ADD_FILE_TO_CUSTOM_REPORT_TEMPLATE: 'ADD_FILE_TO_CUSTOM_REPORT_TEMPLATE',
  SET_ALLOWED_FILE_SIZE_PER_PROJECT: 'SET_ALLOWED_FILE_SIZE_PER_PROJECT'
};

export const modelFilesActionType = {
  RESET_MODELFILE: 'RESET_MODELFILE',
  ADD_MODELFILE: 'ADD_MODELFILE',
  REPLACE_MODELFILE: 'REPLACE_MODELFILE',
  REMOVE_MODELFILE: 'REMOVE_MODELFILE',
  DELETE_MODELFILE: 'DELETE_MODELFILE',
  SET_MODEL_DIRTY: 'SET_MODEL_DIRTY',
  SET_MODEL_ERROR: 'SET_MODEL_ERROR',
  SET_MODEL_UPLOAD_STATE: 'SET_MODEL_UPLOAD_STATE'
};

export const projectSubmissionType = {
  CREATE_SUBMISSION: 'CREATE_SUBMISSION',
  FETCH_SUBMISSION: 'FETCH_SUBMISSION',
  FETCH_CORRESPONDENCE: 'FETCH_CORRESPONDENCE',
  CREATE_NEW_CORRESPONDENCE: 'CREATE_NEW_CORRESPONDENCE',
  RESET_SUBMISSION: 'RESET_SUBMISSION',
  ADMIN_FETCH_SUMISSIONS: 'ADMIN_FETCH_SUMISSIONS',
  ADMIN_FETCH_SUBMISSION_DETAIL: 'ADMIN_FETCH_SUBMISSION_DETAIL',
  ADMIN_UPDATE_ONE_SUBMISSION: 'ADMIN_UPDATE_ONE_SUBMISSION',
  ADMIN_UPDATE_MULTIPLE_SUBMISSION: 'ADMIN_UPDATE_MULTIPLE_SUBMISSION',
  UPDATE_SUBMISSION: 'UPDATE_SUBMISSION'
};

export const feedbackActionType = {
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  RESET_ATTACHMENTS: 'RESET_ATTACHMENTS'
};

export const userActionType = {
  UPDATE_USER: 'UPDATE_USER',
  ADD_USER: 'ADD_USER',
  MERGE_USERS: 'MERGE_USERS',
  REPLACE_USER: 'REPLACE_USER',
  RESET_USER: 'RESET_USER',
  UPDATE_USER_AS_ADMIN: 'UPDATE_USER_AS_ADMIN',
  VERIFY_USER_AS_ADMIN: 'VERIFY_USER_AS_ADMIN',
  VERIFY_MULTIPLE_USER_AS_ADMIN: 'VERIFY_MULTIPLE_USER_AS_ADMIN',
  MERGE_USER_USAGE: 'MERGE_USER_USAGE'
};

export const resultActionType = {
  RESULT_ALL: 'RESULT_ALL',
  RESULT_LAST: 'RESULT_LAST',
  RESET_RESULTS: 'RESET_RESULTS',
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS'
};

export const projectStageActionType = {
  ADD_STAGE: 'ADD_STAGE',
  UPDATE_STAGE: 'UPDATE_STAGE',
  REMOVE_STAGE: 'REMOVE_STAGE',
  RESET_STAGES: 'RESET_STAGES'
};

export const projectStageResultActionType = {
  FETCH_STAGE_JOBS: 'FETCH_STAGE_JOBS'
};

export const attachmentFileActionType = {
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  RESET_ATTACHMENTS: 'RESET_ATTACHMENTS',
  UPLOAD_ATTACHMENTS: 'UPLOAD_ATTACHMENTS',
  FETCH_ATTACHMENT: 'FETCH_ATTACHMENT',
  MERGE_ATTACHMENTS: 'MERGE_ATTACHMENTS'
};

export const notificationsActionType = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  FETCH_ALL_NOTIFICATIONS: 'FETCH_ALL_NOTIFICATIONS',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',
  DISMISS_ALL_NOTIFICATION: 'DISMISS_ALL_NOTIFICATION',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ'
};

export const referenceDocumentsActionType = {
  ADD_REFERENCE_DOCUMENTS: 'ADD_REFERENCE_DOCUMENTS',
  REMOVE_REFERENCE_DOCUMENT: 'REMOVE_REFERENCE_DOCUMENT',
  UPLOAD_REFERENCE_DOCUMENTS: 'UPLOAD_REFERENCE_DOCUMENTS',
  RESET_REFERENCE_DOCUMENT: 'RESET_REFERENCE_DOCUMENT',
  FETCH_REFERENCE_DOCUMENTS: 'FETCH_REFERENCE_DOCUMENTS',
  FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER:
    'FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER',
  REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER:
    'REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER',
  REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER:
    'REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER',
  REFERENCE_DOCUMENTS_DOWNLOAD_FILE_OR_FOLDER:
    'REFERENCE_DOCUMENTS_DOWNLOAD_FILE_OR_FOLDER',
  SOFT_REMOVE_REFERENCE_DOCUMENT: 'SOFT_REMOVE_REFERENCE_DOCUMENT',
  ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT:
    'ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT',
  REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER:
    'REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER',
  REPLACE_REFERENCE_DOCUMENT: 'REPLACE_REFERENCE_DOCUMENT',
  REFERENCE_DOCUMENTS_CUT_FILE_OR_FOLDER:
    'REFERENCE_DOCUMENTS_CUT_FILE_OR_FOLDER',
  SET_REFERENCE_DOCUMENT_ROOT: 'SET_REFERENCE_DOCUMENT_ROOT'
};

export const statActionType = {
  MERGE_STATS: 'MERGE_STATS',
  RESET_STATS: 'RESET_STATS'
};

export const knowledgeHubActionType = {
  SEARCH_KNOWLEDGEHUB: 'SEARCH_KNOWLEDGEHUB'
};

//IN-PROGRESS
export const modelIssueActionType = {
  FETCH_ALL_ISSUES: 'FETCH_ALL_ISSUES',
  FETCH_ISSUE: 'FETCH_ISSUE',
  RESET_ISSUE_LIST: 'RESET_ISSUE_LIST',
  ADD_COMMENT: 'ADD_COMMENT',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  REMOVE_ATTACHMENTS: 'REMOVE_ATTACHMENTS',
  RESET_ATTACHMENTS: 'RESET_ATTACHMENTS',
  REMOVE_ISSUE: 'REMOVE_ISSUE'
};

export const model2DFilesActionType = {
  RESET_2DMODELFILE: 'RESET_2DMODELFILE',
  ADD_2DMODELFILE: 'ADD_2DMODELFILE',
  REPLACE_2DMODELFILE: 'REPLACE_2DMODELFILE',
  REMOVE_2DMODELFILE: 'REMOVE_2DMODELFILE',
  DELETE_2DMODELFILE: 'DELETE_2DMODELFILE',
  MERGE_ALL_2DMODELFILE: 'MERGE_ALL_2DMODELFILE',
  SET_MODEL_PLAN_TYPE: 'SET_MODEL_PLAN_TYPE'
};

export const bimrlDataActionType = {
  ADD_SAVED_QUERIES: 'FETCH_SAVED_QUERIES',
  ADD_RESULT_ENTRY: 'ADD_RESULT_ENTRY',
  RESET_BIMRL_RESULT: 'RESET_BIMRL_RESULT',
  ADD_LIST_RESULT_ENTRY: 'ADD_LIST_RESULT_ENTRY'
};

export const adminConfigurationActionType = {
  FETCH_CONFIG_SETTINGS: 'FETCH_CONFIG_SETTINGS',
  SET_CONFIG_SETTINGS: 'SET_CONFIG_SETTINGS'
};

export const adminStatsChecksSummary = {
  FETCH_STATS_SUMMARY: 'FETCH_STATS_SUMMARY',
  SET_STATS_SUMMARY: 'SET_STATS_SUMMARY'
};

export const userPreferenceActionType = {
  MERGE_PREFERENCE: 'MERGE_PREFERENCE',
  ADD_PREFERENCE: 'ADD_PREFERENCE',
  UPDATE_PREFERENCE: 'UPDATE_PREFERENCE',
  REMOVE_PREFERENCE: 'REMOVE_PREFERENCE',
  RESET_PREFERENCE: 'RESET_PREFERENCE',
  REMOVE_FAVORITE_RULES_PREFERENCE: 'REMOVE_FAVORITE_RULES_PREFERENCE',
  SET_DASHBOARD_TILES: 'SET_DASHBOARD_TILES'
};

export const projectPreferenceActionType = {
  MERGE_PROJECT_PREFERENCE: 'MERGE_PROJECT_PREFERENCE'
};

export const jobsActionType = {
  LIST_JOBS_AS_ADMIN: 'LIST_JOBS_AS_ADMIN',
  FETCH_JOB_QUEUE_METRICS: 'FETCH_JOB_QUEUE_METRICS',
  UPDATE_JOB: 'UPDATE_JOB'
};

export const tasksActionType = {
  MERGE_TASKS: 'MERGE_TASKS',
  RESET_TASKS: 'RESET_TASKS',
  REPLACE_TASK: 'REPLACE_TASK'
};

export const professionType = {
  FETCH_ALL_PROFESSION: 'FETCH_ALL_PROFESSION',
  RESET_PROFESSION: 'RESET_PROFESSION'
};

export const organizationActionType = {
  ADD_CURRENT_ORGANIZATION: 'ADD_CURRENT_ORGANIZATION',
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  ADD_GROUP_MEMBERS: 'ADD_GROUP_MEMBERS',
  UPDATE_GROUP: 'UPDATE_GROUP',
  ADD_ORGANIZATION_MEMBERS: 'ADD_ORGANIZATION_MEMBERS',
  ADD_TEAM: 'ADD_TEAM',
  ADD_DEPARTMENT: 'ADD_DEPARTMENT'
};

export const maintenanceAnnouncement = {
  SET_MAINTENANCE_ANNOUNCEMENT: 'SET_MAINTENANCE_ANNOUNCEMENT',
  FETCH_MAINTENANCE_ANNOUNCEMENT: 'FETCH_MAINTENANCE_ANNOUNCEMENT',
  FETCH_ALL_MAINTENANCE_AS_ADMIN: 'FETCH_ALL_MAINTENANCE_AS_ADMIN',
  DELETE_MAINTENANCE_ANNOUNCEMENT: 'DELETE_MAINTENANCE_ANNOUNCEMENT',
  UPDATE_MAINTENANCE_ANNOUNCEMENT: 'UPDATE_MAINTENANCE_ANNOUNCEMENT'
};

export const subscriptionActionType = {
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  RESET_SUBSCRIPTION: 'RESET_SUBSCRIPTION'
};

export const rulePackageUpdateActionType = {
  UPLOAD_ZIP_FILE: 'UPLOAD_ZIP_FILE',
  ADD_ZIP_FILE: 'ADD_ZIP_FILE',
  REPLACE_ZIP_FILE: 'REPLACE_ZIP_FILE',
  MERGE_PACKAGE: 'MERGE_PACKAGE'
};
