import { appActionType } from '../../constants/actionType';
import navLinks from '../../constants/navLinks';

import { unionBy, flatten } from 'lodash';

const initialState = {
  isAuthenticated: false,
  shouldRedirect: false,
  errorMessage: null,
  firstVisit: true,
  dashboardView: 'grid',
  notifications: [],
  filters: [{}],
  navLinks: {},
  featureList: {},
  fileUploadProgress: 0,
  language: 'en',
  predefined_list_of_stages: {},
  isUploadingFile: false,
  projectAdditionalFields: [],
  footerData: {
    categories: {},
    concurrencyControlVersionId: ''
  },
  maintenance_announcement: [],
  allowed_ifc_version: [],
  model_comparison_list: [],
  notificationCount: {},
  report_template_list: [],
  allowed_project_file_size: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case appActionType.SET_AUTHENTICATED:
      return { ...state, isAuthenticated: action.isAuthenticated };
    case appActionType.UPDATE_FILE_UPLOAD_PROGRESS:
      return { ...state, fileUploadProgress: action.progressValue };
    case appActionType.CHANGE_LANGUAGE:
      return { ...state, language: action.language };
    case appActionType.SET_PREDEFINED_LIST_OF_STAGES:
      return { ...state, predefined_list_of_stages: action.payload };
    case appActionType.SET_IS_UPLOADING_FILE:
      return { ...state, isUploadingFile: action.isUploading };
    case appActionType.SET_PROJECT_ADDITIONAL_FIELDS:
      return { ...state, projectAdditionalFields: action.additionalFields };
    case appActionType.SET_FOOTER_DATA: {
      return {
        ...state,
        footerData: {
          categories: action.footerData,
          concurrencyControlVersionId: action.concurrencyControlVersionId
        }
      };
    }
    case appActionType.SET_ALLOWED_IFC_VERSION:
      return { ...state, allowed_ifc_version: action.payload };
    case appActionType.SET_MODEL_COMPARISON_LIST:
      return { ...state, model_comparison_list: action.payload };
    case appActionType.SET_PROJECT_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: { ...state.notificationCount, project: action.count }
      };
    case appActionType.SET_LIST_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: { ...state.notificationCount, list: action.count }
      };
    case appActionType.FETCH_FEATURE_LIST:
      return { ...state, featureList: action.featureList };
    case appActionType.MERGE_NAVLINKS:
      return { ...state, navLinks: action.navLinksData };
    case appActionType.SET_PROJECT_THUMBNAIL_SETTINGS:
      return { ...state, thumbnailSettings: action.payload };

    case appActionType.SET_REPORT_TEMPLATE_LIST:
      return { ...state, report_template_list: action.payload };
    case appActionType.MERGE_REPORT_TEMPLATE_LIST:
      return {
        ...state,
        report_template_list: [...state.report_template_list, action.payload]
      };
    case appActionType.CUSTOM_REPORT_TEMPLATE_DELETION:
      return {
        ...state,
        report_template_list: [...state.report_template_list].filter(
          ({ custom_report_template_id }) => {
            return custom_report_template_id !== action.payload;
          }
        )
      };

    case appActionType.ADD_FILE_TO_CUSTOM_REPORT_TEMPLATE:
      const updatedData = [...state.report_template_list].map(rt =>
        rt.custom_report_template_id === action.payload.customReportTemplatedId
          ? {
              ...rt,
              related_files: [...rt.related_files, action.payload.file]
            }
          : rt
      );

      return {
        ...state,
        report_template_list: updatedData
      };

    case appActionType.SET_ALLOWED_FILE_SIZE_PER_PROJECT:
      return { ...state, allowed_project_file_size: action.payload };

    default:
      return state;
  }
};

export default reducer;
