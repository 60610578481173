import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import config from '../config/landingPageConfig';
import { withTranslation } from 'react-i18next';

const solutions = config.solutions;

const FeatureSection = ({ t }) => {
  const { gifImages, headerText } = solutions.features;
  return (
    <Container fluid className="px-0 text-center">
      <h2 className="custom-h2">{t(`front:${headerText}`)}</h2>
      <hr className="fornax-custom-hr pb-3" />
      <Row noGutters>
        {gifImages.map((item, index) => {
          return (
            <Col
              key={`key-${index}`}
              sm={{ size: 6 }}
              lg={{ size: 4 }}
              className="p-0 m-0 gifs-container">
              <div className="gif-box m-2 position-relative">
                <img src={item.gifName} className="img-fluid" />
                <div className="gif-overlay p-2"></div>
                <div className="gif-text">
                  <div className="text-uppercase custom-text-shadow">
                    {t(`front:${item.title}`)}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default withTranslation(['front'])(FeatureSection);
