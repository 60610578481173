import React, { Component } from 'react';
import { Button, Toast, ToastBody, Container, Row, Col } from 'reactstrap';
import './Cookie.css';
import { withTranslation } from 'react-i18next';

const CookiePresenter = (open, toggle, t) => {
  return (
    <Toast
      isOpen={open}
      className="m-4 shadow bg-light cookiePresenter">
      <ToastBody>
        <Container className="my-3">
          <Row>
            <Col>
              <h4>{t('main:cookie_title')}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{t('main:cookie_body')}</p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <Button
                className="btn-square"
                color="primary"
                size="lg"
                block
                onClick={toggle}>
                {t('main:cookie_button')}
              </Button>
            </Col>
          </Row>
        </Container>
      </ToastBody>
    </Toast>
  );
};
class Cookie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    localStorage.setItem('accept-cookie', true)
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  render() {
    const { t } = this.props;
    const acceptCookies = localStorage.getItem('accept-cookie')
    return (
      <>
        {acceptCookies
          ? ''
          : CookiePresenter(this.state.modal, this.toggle, t)}
      </>
    );
  }
}

export default withTranslation(['main'])(Cookie);
