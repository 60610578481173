import React from 'react';
import {
  Row,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Button,
  Progress
} from 'reactstrap';
import path from 'path';
import { dateFormatting } from '../../../../locales/dateFormat';
import { timeAgoFormatting } from '../../../../locales/dateFormat';
import prettyBytes from 'pretty-bytes';
import styles from './fileExplorer.module.css';

// use this 2 function to correspond the react keyed file size
const floatPrecision = (floatValue, precision) => {
  floatValue = parseFloat(floatValue);
  if (isNaN(floatValue)) {
    return parseFloat('0').toFixed(precision);
  } else {
    const power = Math.pow(10, precision);
    floatValue = (Math.round(floatValue * power) / power).toFixed(precision);
    return floatValue.toString();
  }
};

const fileSize = size => {
  if (size > 1024) {
    const kbSize = size / 1024;
    if (kbSize > 1024) {
      const mbSize = kbSize / 1024;
      return `${floatPrecision(mbSize, 2)} MB`;
    }
    return `${Math.round(kbSize)} kB`;
  }
  return `${size} B`;
};
const DocumentsListing = props => {
  const {
    filteredDocuments,
    t,
    referenceDocumentActions,
    onUpdateDocuments,
    _isMounted,
    fileUploadProgresses,
    isSubmitting,
    disabled
  } = props;

  // disabled here  means that the origin of the file is not user upload can be o submission,issue etc

  return (
    <div>
      {filteredDocuments.length > 0 && (
        <ListGroup className="border-bottom">
          <ListGroupItem
            className={`font-weight-bold shadow-sm text-primary ${styles.zIndex900}`}>
            File/s to upload
          </ListGroupItem>
          <div className={styles.overFlowandMaxheight}>
            {filteredDocuments.map((f, index) => {
              return (
                <ListGroupItem
                  key={`${f.name}-${index}}`}
                  className="shadow-sm">
                  <Row className="d-flex justify-content-between px-2">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        {f.name} - {fileSize(f.size || 0)}{' '}
                      </div>
                      {f.date && (
                        <div className="text-secondary">
                          last updated: {timeAgoFormatting(f.date_updated)} ago
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-end flex-grow-1 mr-2">
                      {isSubmitting && (
                        <Progress
                          className="mt-2 w-100"
                          color={
                            fileUploadProgresses[index] === 100
                              ? 'success'
                              : 'info'
                          }
                          value={fileUploadProgresses[index]}>
                          {fileUploadProgresses[index]} %
                        </Progress>
                      )}
                      <div>
                        {f.downloadLink && (
                          <>
                            <a
                              download
                              id={`downloaddocument-${index}`}
                              onClick={e => e.stopPropagation()}
                              className="pointer mr-2"
                              href={`${
                                process.env.REACT_APP_API_ENDPOINT[
                                  process.env.REACT_APP_API_ENDPOINT.length - 1
                                ] == '/'
                                  ? process.env.REACT_APP_API_ENDPOINT.slice(
                                      0,
                                      -1
                                    )
                                  : process.env.REACT_APP_API_ENDPOINT
                              }/${f.downloadLink}`}>
                              <span>
                                <i className="fas fa-download fs-1-2x text-primary" />
                              </span>
                            </a>
                            {_isMounted && (
                              <UncontrolledTooltip
                                fade={false}
                                boundariesElement="window"
                                target={`downloaddocument-${index}`}>
                                {t('main:download')}
                              </UncontrolledTooltip>
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <Button
                          color="link"
                          disabled={isSubmitting}
                          id={`deletedocument-${index}`}
                          className="pointer mr-2 p-0"
                          onClick={() => {
                            if (!disabled) {
                              referenceDocumentActions.softRemoveReferenceDocument(
                                f
                              );
                            }
                          }}>
                          <i
                            className={`fas fa-trash-alt fs-1-2x ${
                              isSubmitting ? 'text-muted' : 'text-danger'
                            }`}
                          />
                        </Button>
                        {_isMounted && (
                          <UncontrolledTooltip
                            fade={false}
                            boundariesElement="window"
                            target={`deletedocument-${index}`}>
                            {t('delete')}
                          </UncontrolledTooltip>
                        )}
                      </div>
                    </div>
                  </Row>
                </ListGroupItem>
              );
            })}
          </div>
        </ListGroup>
      )}
      {filteredDocuments.length > 0 && (
        <Button
          color="success"
          outline
          type="submit"
          className="mt-3 text-right"
          onClick={onUpdateDocuments}>
          Upload Documents
        </Button>
      )}
    </div>
  );
};

export default DocumentsListing;
