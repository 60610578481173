import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CustomInputFromFile from '../../../UI/Input/customInput';
import EditableInput from '../../../UI/Input/editableInput';
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import prettyBytes from 'pretty-bytes';
import * as Yup from 'yup';
import Message from '../../../UI/Message';
import styles from './Styles.module.css';

class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddActive: false
    };
  }

  componentDidMount() {
    this.setState({});
  }

  handleValidationchanges = (toChange, setFieldValue) => {
    this.setState({
      isAddActive: true
    });
    if (toChange === 'add_email_to_list') {
      setFieldValue('add_email_to_list_isActive', true);
      setFieldValue('add_email_to_list', '');
    } else if (toChange === 'add_user_created_stage') {
      setFieldValue('add_user_created_stage_isActive', true);
      setFieldValue('add_user_created_stage', '');
    } else if (toChange === 'add_emails_to_send_admin_activities_report') {
      setFieldValue(
        'add_emails_to_send_admin_activities_report_isActive',
        true
      );
      setFieldValue('add_emails_to_send_admin_activities_report', '');
    }
  };

  handleElementToRender = (toChange, translate) => {
    if (toChange === 'add_email_to_list') {
      return (
        <div>
          <i className="fa fa-user-plus text-warning" />
          <span className="ml-1 text-muted">{translate('add_email')}</span>
        </div>
      );
    } else if (toChange === 'add_user_created_stage') {
      return (
        <div>
          <i className="fa fa-tag text-info" />
          <span className="ml-1 text-muted">{translate('add_stage')}</span>
        </div>
      );
    } else if (toChange === 'add_emails_to_send_admin_activities_report') {
      return (
        <div>
          <i className="fa fa-user-plus text-warning" />
          <span className="ml-1 text-muted">{translate('add_email')}</span>
        </div>
      );
    }
  };

  handleAddButtonAction = (name, arrayHelpers, values, setFieldValue) => {
    if (name === 'add_email_to_list') {
      arrayHelpers.push(values[name]);
      setFieldValue('add_email_to_list_isActive', false);
    } else if (name === 'add_user_created_stage') {
      setFieldValue('add_user_created_stage_isActive', false);
      arrayHelpers.push({
        name: values[name],
        display_name: values[name]
      });
    } else if (name === 'add_emails_to_send_admin_activities_report') {
      arrayHelpers.push(values[name]);
      setFieldValue(
        'add_emails_to_send_admin_activities_report_isActive',
        false
      );
    }

    this.setState({
      isAddActive: false
    });
  };

  render() {
    const { isAddActive } = this.state;
    const { values, errors, arrayHelpers, name, inputType, setFieldValue, t } =
      this.props;

    const isInputActive =
      (this.state.isAddActive && values.add_email_to_list_isActive) ||
      (this.state.isAddActive && !!values.add_user_created_stage_isActive) ||
      (this.state.isAddActive &&
        !!values.add_emails_to_send_admin_activities_report_isActive);
    return (
      <Row className={styles.centerAlignItems}>
        <Col sm="8" className={styles.centerAlignItems}>
          {isInputActive && (
            <Field
              type={inputType}
              name={name}
              className={styles.inputMargin}
              component={CustomInputFromFile}
              bsSize="sm"
              autofocus="true"
            />
          )}
        </Col>

        {!isInputActive ? (
          <Col sm="4" className="text-right">
            <Button
              color="light"
              size="sm"
              onClick={el => {
                this.handleValidationchanges(name, setFieldValue, values);
              }}>
              {this.handleElementToRender(name, t)}
            </Button>
          </Col>
        ) : (
          <div>
            <Button
              color="link"
              onClick={el => {
                this.handleAddButtonAction(
                  name,
                  arrayHelpers,
                  values,
                  setFieldValue
                );
              }}
              disabled={errors[name] !== undefined}>
              {t('proceed_to_add')}
            </Button>
            <Button
              color="link"
              size="sm"
              onClick={el => {
                this.setState({
                  isAddActive: false
                });
                if (name === 'add_email_to_list') {
                  setFieldValue('add_email_to_list_isActive', false);
                } else if (name === 'add_user_created_stage') {
                } else if (
                  name === 'add_emails_to_send_admin_activities_report'
                ) {
                  setFieldValue(
                    'add_emails_to_send_admin_activities_report_isActive',
                    false
                  );
                }
                setFieldValue('add_user_created_stage_isActive', false);
              }}>
              {t('proceed_to_cancel')}
            </Button>
          </div>
        )}
      </Row>
    );
  }
}

export default withTranslation(['adminConfigurations'])(Listing);
