import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import defaultEN from './locales/en/defaultTranslation.json';
import customEN from './locales/en/dist/translation.json';

// Ressources of the translations per namespaces
const resources = {
  en: {
    main: { ...defaultEN.main, ...customEN.main },
    autochecker: { ...defaultEN.autochecker, ...customEN.autochecker },
    login: { ...defaultEN.loginPage, ...customEN.loginPage },
    front: { ...defaultEN.frontPage, ...customEN.frontPage },
    validation: {
      ...defaultEN.validation,
      ...customEN.validation_message
    },
    // Becareful to use the 'error' package here and not the 'error_message'
    error: { ...defaultEN.error_message, ...customEN.error_message },
    register: { ...defaultEN.registerPage, ...customEN.registerPage },
    dashboard: { ...defaultEN.dashboard, ...customEN.dashboard },
    forgotPassword: {
      ...defaultEN.forgotPassword,
      ...customEN.forgotPasswordPage
    },
    project: { ...defaultEN.project, ...customEN.project },
    changePassword: {
      ...defaultEN.changePassword,
      ...customEN.changePassword
    },
    newPassword: { ...defaultEN.newPassword, ...customEN.newPassword },
    editProfile: { ...defaultEN.editProfile, ...customEN.editProfile },
    userPermission: { ...defaultEN.userPermission, ...customEN.userPermission },
    feedback: { ...defaultEN.feedback, ...customEN.feedback },
    profile: { ...defaultEN.profile, ...customEN.profile },
    submission: { ...defaultEN.submission, ...customEN.submission },
    results: { ...defaultEN.results, ...customEN.results },
    admin_project: {
      ...defaultEN.admin_project,
      ...customEN.admin_project
    },
    admin_user: { ...defaultEN.admin_user, ...customEN.admin_user },
    news: { ...defaultEN.news, ...customEN.news },
    registration_confirmation: {
      ...defaultEN.registration_confirmation,
      ...customEN.registration_confirmation
    },
    notifications: {
      ...defaultEN.notifications,
      ...customEN.notifications
    },
    statistics: { ...defaultEN.statistics, ...customEN.statistics },
    rules: { ...defaultEN.rules, ...customEN.rules },
    knowledgeHub: { ...defaultEN.knowledgeHub, ...customEN.knowledgeHub },
    bimrlData: { ...defaultEN.bimrlData, ...customEN.bimrlData },
    reference_documents: {
      ...defaultEN.reference_documents,
      ...customEN.reference_documents
    },
    adminConfigurations: {
      ...defaultEN.adminConfigurations,
      ...customEN.adminConfigurations
    },
    user_preferences: {
      ...defaultEN.user_preferences,
      ...customEN.user_preferences
    },
    project_preferences: {
      ...defaultEN.project_preferences,
      ...customEN.project_preferences
    },
    adminUserVerification: {
      ...defaultEN.adminUserVerification,
      ...customEN.admin_user_verfication
    },
    adminJobManagement: {
      ...defaultEN.admin_job_management,
      ...customEN.admin_job_management
    },
    resendToken: { ...defaultEN.resendToken, ...customEN.resendToken },
    task_management: {
      ...defaultEN.task_management,
      ...customEN.task_management
    },
    organization: {
      ...defaultEN.organization,
      ...customEN.organization
    },
    rule_history: {
      ...defaultEN.rule_history,
      ...customEN.rule_history
    },
    admin_footer_management: {
      ...defaultEN.admin_footer_management,
      ...customEN.admin_footer_management
    },
    subscription: {
      ...defaultEN.subscription,
      ...customEN.subscription
    },
    queryUI: {
      ...defaultEN.queryUI,
      ...customEN.queryUI
    },
    features_enabled: {
      ...defaultEN.features_enabled,
      ...customEN.features_enabled
    },
    rule_package_management: {
      ...defaultEN.rule_package_management,
      ...customEN.rule_package_management
    }
  }
};

i18n
  // .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug:true,
    ns: [
      'main',
      'autochecker',
      'login',
      'front',
      'validation',
      'error',
      'register',
      'dashboard',
      'project',
      'forgotPassword',
      'changePassword',
      'newPassword',
      'editProfile',
      'userPermission',
      'feedback',
      'profile',
      'submission',
      'results',
      'admin_user',
      'news',
      'registration_confirmation',
      'statistics',
      'rules',
      'knowledgeHub',
      'bimrlData',
      'reference_documents',
      'adminConfigurations',
      'user_preferences',
      'project_preferences',
      'adminUserVerification',
      'adminJobManagement',
      'resendToken',
      'task_management',
      'organization',
      'admin_footer_management',
      'rule_package_management'
    ],
    defaultNS: 'main',
    resources,
    lng: 'en',
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
