import {
  rulesAdminActionType,
  ruleHistoryAdminActionType
} from '../../constants/actionType';
import { performRequest } from '../requests';
import { buildRulesTree } from '../ruleUtils';
import { RULES_KEY_SEPARTOR } from '../../constants/misc';

export const fetchRulesAdmin = ({ type, reset }) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  if (reset) {
    dispatch({ type: rulesAdminActionType.RESET_RULES_ADMIN });
  }
  return dispatch(
    performRequest(
      'get',
      `/api/admin/rule_management/list?rule_type=${type}`,
      {},
      requestConfig,
      'RULES'
    )
  )
    .then(res => res.data)
    .then(
      ({
        packages,
        rules,
        domainDefinedPackages,
        entityTargetId,
        concurrencyControlVersionId
      }) => {
        const formattedData = buildRulesTree({ packages, rules });

        dispatch({
          type: rulesAdminActionType.MERGE_RULES_ADMIN,
          rulesTree: formattedData,
          rules,
          concurrencyControlVersionId,
          entityTargetId
          // hiddenRules: res.data.hiddenRules
        });
      }
    )
    .catch(err => {
      console.log(err);
    });
};
// export const fetchRulesAdmin = ({reset}) => (dispatch /* getState */) => {
//   const requestConfig = {
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     withCredentials: true
//   };
//   if(reset){
//     dispatch({ type: rulesAdminActionType.RESET_RULES_ADMIN });
//   }
//   return dispatch(
//     performRequest('get', '/api/admin/rule_management/list', {}, requestConfig, 'RULES')
//   ).then(res => {
//     const formattedData = buildRulesTree(res.data);
//     dispatch({
//       type: rulesAdminActionType.MERGE_RULES_ADMIN,
//       rulesTree: formattedData,
//       rules: res.data.rules,
//       hiddenRules : res.data.hiddenRules
//     });
//   });
// };

export const setHiddenRules = hiddenRuleList => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/admin/rule_management/hideRules',
      { hiddenRuleList },
      requestConfig,
      'HIDE_RULES'
    )
  );
};

export const cloneMultipleRules = (ruleList, ruleType, cloneLevel) => (
  dispatch,
  getState
) => {
  let concurrencyControlVersionId;
  let entityTargetId;
  if (cloneLevel == 'domain') {
    concurrencyControlVersionId = getState().rules_admin
      .concurrencyControlVersionId;
    entityTargetId = getState().rules_admin.entityTargetId;
  } else {
    concurrencyControlVersionId = getState().rules.concurrencyControlVersionId;
    entityTargetId = getState().rules.entityTargetId;
  }

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/admin/rule_management/cloneMultiple',
      {
        ruleList,
        ruleType,
        cloneLevel,
        entityTargetId,
        concurrencyControlVersionId
      },
      requestConfig,
      'CLONE_MULTIPLE_RULES'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: rulesAdminActionType.SET_ENTITY_VERSION_ID,

          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
        return res.newRuleMapping;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const fetchRuleHistoryList = ({ ruleLevel, ruleType, ruleId }) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `/api/admin/rule_history/list?rule_level=${ruleLevel}&rule_type=${ruleType}&rule_id=${ruleId}`,
      {},
      requestConfig,
      'RULES'
    )
  )
    .then(res => res.data)
    .then(({ rule }) => {
      dispatch({
        type: ruleHistoryAdminActionType.MERGE_RULE_HISTORY_ADMIN,
        rule: rule
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const revertToRuleHistoryAction = ({
  rule_level,
  rule_id,
  rule_type,
  rule_history_id
}) => (dispatch, getState) => {
  const concurrencyControlVersionId = getState().rule_history_admin[rule_id]
    .concurrencyControlVersionId;
  const entityTargetId = rule_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      '/api/admin/rule_history/revert',
      {
        rule_level,
        rule_id,
        rule_type,
        rule_history_id,
        entityTargetId,
        concurrencyControlVersionId
      },
      requestConfig,
      'SAVE_RULE_PARAMETER'
    )
  )
    .then(res => res.data)
    .then(({ rule }) => {
      dispatch({
        type: ruleHistoryAdminActionType.MERGE_RULE_HISTORY_ADMIN,
        rule: rule
      });
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};
