import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  FormGroup,
  Row,
  Col,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useState } from 'react';
import STATCONFIG from './settings';

import AutoCheckGraphs from './AutoCheckGraphs';

const criteriaDropDown = props => {
  const { t, criteria, setCriteria, CRITERIA } = props;
  if (CRITERIA.default) {
    return (
      <>
        <UncontrolledDropdown width="100%">
          <DropdownToggle size="sm" color="primary" caret>
            {t(criteria)}
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(CRITERIA.child).map(res => {
              if (CRITERIA.child[res].child) {
                return (
                  <DropdownItem header className="py-0 px-0">
                    {criteriaDropDown({
                      t,
                      criteria,
                      setCriteria,
                      CRITERIA: CRITERIA.child[res],
                      res
                    })}
                  </DropdownItem>
                );
              } else {
                return (
                  <DropdownItem
                    active={res === criteria ? true : false}
                    onClick={() => setCriteria(res)}>
                    {t(res)}
                  </DropdownItem>
                );
              }
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  } else {
    return (
      <UncontrolledDropdown className="py-0" direction="right">
        <DropdownToggle
          color="primary"
          className="bg-transparent w-100 border-0 pl-4 text-dark d-flex justify-content-between">
          {t(props.res)}
          <div className="pr-3">

          <i className="fas fa-caret-right" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            {Object.keys(CRITERIA.child).map(res => {
              if (CRITERIA.child[res].child) {
                return (
                  <DropdownItem header className="px-0">
                    {criteriaDropDown({
                      t,
                      criteria,
                      setCriteria,
                      CRITERIA: CRITERIA.child[res],
                      res
                    })}
                  </DropdownItem>
                );
              } else {
                return (
                  <DropdownItem
                    active={res === criteria ? true : false}
                    onClick={() => setCriteria(res)}>
                    {t(res)}
                  </DropdownItem>
                );
              }
            })}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
};

const CheckStatsComponent = props => {
  const {
    t,
    statSettings,
    setStatSettings,
    statType,
    flatStatType,
    data
  } = props;
  const { nbCheck } = data;
  const [count, setCount] = useState(statSettings.count);
  const [criteria, setCriteria] = useState(statSettings.criteria);
  const [minDate, setMinDate] = useState(statSettings.minDate);
  const [maxDate, setMaxDate] = useState(statSettings.maxDate);
  const [graph, setGraph] = useState(statSettings.graph);
  const [timeEvolution, setTimeEvolution] = useState(
    statSettings.time_evolution
  );

  return (
    <>
      <Row>
        <Col className="d-flex flex-wrap mt-3">
          <FormGroup className="flex-fill mr-3 mb-0">
            <Label className="mb-0">Graphs</Label>
            <UncontrolledDropdown width="100%">
              <DropdownToggle size="sm" color="primary" caret>
                {t(graph)}
              </DropdownToggle>
              <DropdownMenu>
                {STATCONFIG.graph.map(res => (
                  <DropdownItem
                    active={res === graph ? true : false}
                    onClick={() => setGraph(res)}
                    disabled={STATCONFIG[flatStatType][
                      statSettings.criteria
                    ].unrecommendedGraph.includes(res)}>
                    {t(res)}{' '}
                    <span className="text-muted">
                      {res ===
                      STATCONFIG[flatStatType][criteria].recommendedGraph
                        ? '(Recommended)'
                        : ''}
                    </span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>

          <FormGroup className="flex-fill mr-3 mb-0">
            <Label className="mb-0">Criteria</Label>
            {criteriaDropDown({
              t,
              criteria,
              setCriteria,
              CRITERIA: STATCONFIG[statType]
            })}
          </FormGroup>

          {STATCONFIG[flatStatType][criteria].time_evolution ? (
            <>
              <FormGroup className="flex-fill mr-3 mb-0">
                <Label className="mb-0">Time</Label>
                <UncontrolledDropdown width="100%">
                  <DropdownToggle size="sm" color="primary" caret>
                    {t(timeEvolution)}
                  </DropdownToggle>
                  <DropdownMenu>
                    {STATCONFIG.time_evolution.child.map(res => (
                      <DropdownItem
                        active={res === timeEvolution ? true : false}
                        onClick={() => setTimeEvolution(res)}>
                        {t(res)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </>
          ) : (
            ''
          )}

          <FormGroup className="flex-fill mr-3 mb-0">
            <Label className="mb-0">Results</Label>
            <UncontrolledDropdown width="100%">
              <DropdownToggle
                size="sm"
                color="primary"
                disabled={
                  STATCONFIG[flatStatType][criteria].time_evolution
                    ? minDate
                      ? true
                      : false
                    : false
                }
                caret>
                {t(count)}
              </DropdownToggle>
              <DropdownMenu>
                {STATCONFIG.count.child.map(res => (
                  <DropdownItem
                    active={res === count ? true : false}
                    onClick={() => setCount(res)}>
                    {t(res)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>

          <FormGroup className="flex-fill mr-3 mb-0 d-flex flex-column">
            <Label className="mb-0">Start Date</Label>
            <DatePicker
              selected={minDate}
              onChange={date => setMinDate(Date.parse(date))}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
            />
          </FormGroup>
          <FormGroup className="flex-fill mr-3 mb-0 d-flex flex-column">
            <Label className="mb-0">End Date</Label>
            <DatePicker
              width="100%"
              selected={maxDate}
              onChange={date => setMaxDate(Date.parse(date))}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
            />
          </FormGroup>

          <FormGroup className="flex-fill mr-3 mb-0 d-flex flex-column">
            <Label className="mb-0">{t('filters')}</Label>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                setStatSettings({
                  ...statSettings,
                  count: count,
                  criteria: criteria,
                  minDate: minDate,
                  maxDate: maxDate,
                  time_evolution: timeEvolution,
                  graph:
                    statSettings.criteria === criteria
                      ? graph
                      : STATCONFIG[flatStatType][criteria].recommendedGraph
                });
                setGraph(
                  statSettings.criteria === criteria
                    ? graph
                    : STATCONFIG[flatStatType][criteria].recommendedGraph
                );
              }}>
              {t('apply_filters')}
            </Button>
          </FormGroup>
        </Col>
      </Row>

      <hr className="mt-2" />

      <Row>
        <Col>
          <h2 className="text-center mb-3">
            {t(statSettings.count)} {t(criteria)}
          </h2>
          <AutoCheckGraphs {...props} graph={graph} />
          <h5 className="text-center mt-3">
            {t('nb_check')}: {nbCheck}
          </h5>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation(['statistics'])(CheckStatsComponent);
