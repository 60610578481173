import { appActionType } from '../../constants/actionType';
import { performRequest, handleErrorMessageStatus } from '../requests';

const {
  SET_PREDEFINED_LIST_OF_STAGES,
  SET_PROJECT_ADDITIONAL_FIELDS,
  SET_FOOTER_DATA,
  SET_ALLOWED_IFC_VERSION,
  SET_MODEL_COMPARISON_LIST,
  FETCH_FEATURE_LIST,
  SET_PROJECT_THUMBNAIL_SETTINGS,
  SET_REPORT_TEMPLATE_LIST,
  MERGE_REPORT_TEMPLATE_LIST,
  CUSTOM_REPORT_TEMPLATE_DELETION,
  SET_ALLOWED_FILE_SIZE_PER_PROJECT
} = appActionType;

export const fetchListProjectStageName = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/project/predefined_list_of_stages',
      {},
      requestConfig,
      'FETCH_LIST_PROJECT_STAGENAME'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: SET_PREDEFINED_LIST_OF_STAGES, payload: res.data });
      } else {
        throw Error('error:default_message');
      }
    });
};

export const fetchProjectAdditionalFields = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/project/additionalFields',
      {},
      requestConfig,
      'FETCH_LIST_PROJECT_ADDITIONAL_FILEDS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_PROJECT_ADDITIONAL_FIELDS,
          additionalFields: res.data
        });
      } else {
        throw Error('error:default_message');
      }
    });
};

export const fetchFooterData = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/footer_documents/footerConfig/get',
      {},
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,

          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const fetchFooterPerDocumentType = documentType => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: {
      documentType: documentType
    }
  };
  return dispatch(
    performRequest(
      'get',
      '/api/footer_documents/footerConfig/get',
      {},
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        return res.data;
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const fetchAllowedIFcVersionList = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/project/allowed_ifc_version_list',
      {},
      requestConfig,
      'FETCH_ALLOWED_IFC_VERSION'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: SET_ALLOWED_IFC_VERSION, payload: res.data });
      } else {
        throw Error('error:default_message');
      }
    });
};

// project thumnail
export const fetchProjectThumbailSettings = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/project/thumbnailSettings',
      {},
      requestConfig,
      'SET_PROJECT_THUMBNAIL_SETTINGS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: SET_PROJECT_THUMBNAIL_SETTINGS, payload: res.data });
      } else {
        throw Error('error:default_message');
      }
    });
};

// FOOTER ADMIN ACTION

export const createNewCategory = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      '/api/admin/footer_management/create/new_entry',
      { ...values, concurrencyControlVersionId },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw Error(err.message);
    });
};

export const createNewEntry = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      '/api/admin/footer_management/create/new_entry',
      { ...values, concurrencyControlVersionId },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw Error(err.message);
    });
};

export const uploadFooterDocuments =
  (main_category_key, documentsAdded, documentType) => (dispatch, getState) => {
    let formData = new FormData();
    formData.append('documentsAdded', documentsAdded.file);

    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type':
          'multipart/form-data; boundary=---------------------------199619018751551354576510119'
      },
      params: {
        main_category_key: main_category_key,
        documentType: documentType || 'download'
      }
    };

    return dispatch(
      performRequest(
        'post',
        `/api/admin/footer_management/footer_documents/upload`,
        formData,
        requestConfig,
        'UPLOAD_DOCUMENT_REQUEST'
      )
    )
      .then(res => res.data)
      .then(res => {
        if (res.success) {
          // dispatch({
          //   type: SET_FOOTER_DATA,
          //   footerData: res.data,
          //   concurrencyControlVersionId: res.concurrencyControlVersionId
          // });
          // dispatch({
          //   type: SET_FOOTER_DATA,
          //   footerData: res.data,
          //   concurrencyControlVersionId: res.concurrencyControlVersionId
          // });
        } else {
          throw Error('error:default_message');
        }
      })
      .catch(err => {
        throw Error(err.message);
      });
  };

export const editMainCategory = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/admin/footer_management/category/${values.main_category_key}/edit`,
      {
        ...values,
        entityTargetId: 'admin_config_footer_documents',
        concurrencyControlVersionId
      },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const editEntry = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/admin/footer_management/category/${values.main_category_key}/${values.sub_category_title}/edit`,
      {
        ...values,
        entityTargetId: 'admin_config_footer_documents',
        concurrencyControlVersionId
      },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const deleteEntry =
  (main_category_key, sub_category_title, versionIndex) =>
  (dispatch, getState) => {
    const concurrencyControlVersionId =
      getState().app.footerData.concurrencyControlVersionId ||
      getState().app.footerData.concurrency_control_version_id;

    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'delete',
        `/api/admin/footer_management/category/${main_category_key}/${sub_category_title}/delete`,
        {
          versionIndex,
          entityTargetId: 'admin_config_footer_documents',
          concurrencyControlVersionId
        },

        requestConfig,
        'FETCH_FOOTER_LINKS'
      )
    )
      .then(res => res.data)
      .then(res => {
        if (res.success) {
          dispatch({
            type: SET_FOOTER_DATA,
            footerData: res.data,
            concurrencyControlVersionId: res.concurrencyControlVersionId
          });
        } else {
          throw Error('error:default_message');
        }
      })
      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };

export const deleteMainCategory = main_category_key => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'delete',
      `/api/admin/footer_management/category/${main_category_key}/delete`,
      {
        entityTargetId: 'admin_config_footer_documents',
        concurrencyControlVersionId
      },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
        return res.data;
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const addNewVersion = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/admin/footer_management/category/${values.main_category_key}/${values.sub_category_title}/add_version`,
      {
        ...values,
        entityTargetId: 'admin_config_footer_documents',
        concurrencyControlVersionId
      },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const editRelatedLinks = values => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().app.footerData.concurrencyControlVersionId ||
    getState().app.footerData.concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/admin/footer_management/category/${values.main_category_key}/edit/related_links`,
      {
        ...values,
        entityTargetId: 'admin_config_footer_documents',
        concurrencyControlVersionId
      },
      requestConfig,
      'FETCH_FOOTER_LINKS'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_FOOTER_DATA,
          footerData: res.data,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const fetchModelComparisonList = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/project/model_comparison_list',
      {},
      requestConfig,
      'FETCH_MODEL_COMPARISON_LIST'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: SET_MODEL_COMPARISON_LIST, payload: res.data });
      } else {
        throw Error('error:default_message');
      }
    });
};

export const fetchReportTemplateList = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/rule_package/availableReportTemplateList',
      {},
      requestConfig,
      'FETCH_REPORT_TEMPLATE_LIST'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: SET_REPORT_TEMPLATE_LIST, payload: res.data });
      } else {
        throw Error('error:default_message');
      }
    });
};

export const requestCustomReportTemplateDeletion =
  customReportTemplatedId => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/rule_package/customReportTemplate/${customReportTemplatedId}/delete`,
        {},
        requestConfig,
        'CUSTOM_REPORT_TEMPLATE_DELETION'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          dispatch({
            type: CUSTOM_REPORT_TEMPLATE_DELETION,
            payload: customReportTemplatedId
          });
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const fetchFeatureList = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/feature/list',
      {},
      requestConfig,
      'FETCH_FEATURE_LIST'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({ type: FETCH_FEATURE_LIST, featureList: res.data });
        return res.data;
      } else {
        throw Error('error:default_message');
      }
    });
};

export const mergeNavLinks = navLinksData => dispatch => {
  dispatch({ type: 'MERGE_NAVLINKS', navLinksData });
};
export const fetchAllowedProjectSizeConfig = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/project/config/allowedSizePerProject/settings`,
      {},
      requestConfig,
      'FETCH_ALLOWED_IFC_VERSION'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: SET_ALLOWED_FILE_SIZE_PER_PROJECT,
          payload: res.allowedSizePerProject
        });
      } else {
        throw Error('error:default_message');
      }
    })
    .catch(err => {
      throw Error(err.message);
    });
};
