import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button  } from 'reactstrap';
import classnames from 'classnames';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import { dateFormatting } from '../../../../locales/dateFormat';

import {
  fetchOrganizationInvitation,
  acceptJoinRequest,
  rejectJoinRequest,
  cancelOrganizationInvitation
} from '../../../../store/actions';

const ViewOrganizationInvitation = ({
  t,
  organizationId,
  organizationInvitations,
  setRefetch,
  acceptJoinRequest,
  rejectJoinRequest,
  cancelOrganizationInvitation
}) => {
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  return (
    <Container fluid>
      <div className="shadow p-2 mt-3 mb-5 rounded">
        {/* <Row className="d-flex align-items-stretch">
          <Col>
            <h2 className="text-primary">{t('organization:invite_people')}</h2>
          </Col>
        </Row> */}

        {/* <Row className="d-flex">
          <Col className="d-flex justify-content-end">
            <Button
              type="button"
              color="primary"
              outline
              onClick={() =>
                setIsInviteMemberModalOpen(!isInviteMemberModalOpen)
              }>
              {t('organization:invite_more')}
            </Button>
          </Col>
        </Row> */}
        <Row className="d-flex">
          <Col>
            <h2 className="text-primary">
              {t('organization:pending_invitation_and_request')}
            </h2>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className="border-top-0" scope="col">
                    {t('organization:invitation_type')}
                  </th>
                  <th className="border-top-0" scope="col">
                    {t('organization:user_invited')}
                  </th>
                  <th className="border-top-0" scope="col">
                    {t('organization:date_invited')}
                  </th>
                  <th className="border-top-0" scope="col">
                    {t('organization:invitation_status')}
                  </th>
                  <th className="border-top-0" scope="col">
                    {t('organization:action_invitation')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {organizationInvitations &&
                organizationInvitations.length > 0 ? (
                    organizationInvitations.map((inv, index) => {
                      let invitationStatus = 'unknown';
                      if (
                        inv.type === 'invitation' &&
                      !inv.relation.acceted &&
                      !inv.relation.rejected
                      ) {
                        invitationStatus = 'pending';
                      } else if (
                        inv.type === 'invitation' &&
                      !inv.relation.acceted &&
                      inv.relation.rejected
                      ) {
                        invitationStatus = 'rejected_by_user';
                      } else if (
                        inv.type === 'request' &&
                      !inv.relation.acceted &&
                      !inv.relation.rejected
                      ) {
                        invitationStatus = 'waiting_for_you';
                      } else if (
                        inv.type === 'request' &&
                      !inv.relation.acceted &&
                      inv.relation.rejected
                      ) {
                        invitationStatus = 'rejected_by_you';
                      }
                      return (
                        <tr key={`user-invitation-${index}`}>
                          <td className="align-middle">{inv.type}</td>
                          <td className="align-middle">
                            {inv.usr.first_name
                              ? `${inv.usr.first_name} ${inv.usr.last_name} (
                            ${inv.usr.email})`
                              : `Not registered user (${inv.usr.email})`}
                          </td>
                          <td className="align-middle">
                            {dateFormatting(inv.relation.date_created)}
                          </td>
                          <td className="align-middle">
                            {t(
                              `organization:invitation_status_${invitationStatus}`
                            )}
                          </td>
                          <td className="align-middle">
                            {inv.type === 'request' &&
                            !inv.relation.accepted &&
                            !inv.relation.rejected && (
                              <>
                                <Button
                                  color="link"
                                  onClick={async () => {
                                    try {
                                      await acceptJoinRequest(inv.usr.user_id);
                                      setRefetch(true);
                                    } catch (err) {
                                      Message.error(t('err.message'));
                                    }
                                  }}>
                                  {t('main:accept')}
                                </Button>
                                <Button
                                  color="link"
                                  onClick={async () => {
                                    try {
                                      await rejectJoinRequest(inv.usr.user_id);
                                      setRefetch(true);
                                    } catch (err) {
                                      Message.error(t('err.message'));
                                    }
                                  }}>
                                  {t('main:reject')}
                                </Button>
                              </>
                            )}
                            {inv.type === 'invitation' && !inv.relation.accepted && (
                              <Button
                                color="link"
                                onClick={async () => {
                                  try {
                                    await cancelOrganizationInvitation(
                                      inv.usr.user_id
                                    );
                                    setRefetch(true);
                                  } catch (err) {
                                    Message.error(t('err.message'));
                                  }
                                }}>
                                {t('main:cancel')}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        {t('organization:no_invitation')}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </Col>
          <Col>
            <h2 className="text-primary">{t('organization:recently_join')}</h2>
          </Col>
        </Row>

      </div>
    </Container>
  );
};

const ViewOrganizationinvitationContainer = props => {
  const { fetchOrganizationInvitation, organizationId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [reFetch, setRefetch] = useState(true);
  const [pendingInvitations, setPendingInvitation] = useState([]);
  useEffect(() => {
    (async () => {
      if (reFetch) {
        try {
          const invitations = await fetchOrganizationInvitation();
          setPendingInvitation(invitations);
          setIsLoaded(true);
        } catch (err) {
          setPendingInvitation([]);
          setIsLoaded(true);
        }
        setRefetch(false);
      }
    })();
  }, [organizationId, reFetch]);
  return (
    <>
      {isLoaded && !hasError && (
        <ViewOrganizationInvitation
          {...props}
          organizationInvitations={pendingInvitations}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch, { organizationId }) => {
  return {
    fetchOrganizationInvitation: () =>
      dispatch(fetchOrganizationInvitation(organizationId)),
    acceptJoinRequest: userId =>
      dispatch(acceptJoinRequest(userId, organizationId)),
    rejectJoinRequest: userId =>
      dispatch(rejectJoinRequest(userId, organizationId)),
    cancelOrganizationInvitation: userId =>
      dispatch(cancelOrganizationInvitation(userId, organizationId))
  };
};

const mapStateToProps = state => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(ViewOrganizationinvitationContainer);
