import { connect } from 'react-redux';
import SubmissionDetail from './SubmissionDetailPresenter';
import { fetchProject } from '../../../store/actions/projects';
import {
  fetchSubmission,
  createNewCorrespondence,
  fetchCorrespondence,
  resetSubmission,
  adminFetchSubmission,
  updateSubmission,
  resetAttachmentFiles
} from '../../../store/actions';
import { fetchProjectStage } from '../../../store/actions/project_stages';
import { getAllResults } from '../../../store/actions/results';

const mapStateToProps = (state, { projectId, stageName, project }) => {
  const projectForSubmission = project || state.projects[projectId];
  return {
    project: projectForSubmission,
    submission: state.submission,
    models: state.modelFiles,
    userFunction: state.authUser.function,
    submittedResults:
      state.submission.job_related &&
      state.submission.job_related.filter(j => !!j && Object.keys(j).length > 0)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetAttachmentFiles: () => dispatch(resetAttachmentFiles()),
    fetchProject: projectId => dispatch(fetchProject(projectId)),
    fetchProjectStage: (projectId, stageName) =>
      dispatch(fetchProjectStage(projectId, stageName)),
    getAllResults: (projectId, stageName) =>
      dispatch(getAllResults(projectId, stageName)),
    createNewCorrespondence: (params, correspondenceData) =>
      dispatch(createNewCorrespondence(params, correspondenceData)),
    fetchCorrespondence: params => dispatch(fetchCorrespondence(params)),
    resetSubmission: () => dispatch(resetSubmission()),
    fetchSubmissionAsQP: (projectId, stageName, submissionId) =>
      dispatch(fetchSubmission(projectId, stageName, submissionId)),
    fetchSubmissionAsAdminOrPO: (projectId, stageName, submissionId) =>
      dispatch(adminFetchSubmission(projectId, stageName, submissionId)),
    updateSubmission: (projectId, stageName, submissionId, changedData) =>
      dispatch(
        updateSubmission(projectId, stageName, submissionId, changedData)
      ),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionDetail);
