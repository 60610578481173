import {
  registerUser,
  registerInvitedUser,
  checkTokenForInvitation
} from '../../../store/actions/authUser';
import { fetchProfession, resetProfession } from '../../../store/actions';
import { connect } from 'react-redux';
import RegistrationPresenter from './RegistrationPresenter';

const mapStateToProps = state => {
  return {
    profession: state.profession,
    featureList: state.app.featureList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetProfession: () => dispatch(resetProfession()),
    fetchProfession: () => dispatch(fetchProfession()),
    registerUser: values => dispatch(registerUser(values)),
    registerInvitedUser: values => dispatch(registerInvitedUser(values)),
    checkTokenForInvitation: token => dispatch(checkTokenForInvitation(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationPresenter);
