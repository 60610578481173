import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
  FormGroup,
  Label,
  CustomInput
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import Message from './shared/Message';
import knowledgehubContext from '../store/config/context';
import {
  setIsImportFileModalOpen,
  batchUpdateContent,
  searchByTitle
} from '../store/actions';

import styles from './styles.module.css';

import ReactTable from '../../../Shared/ReactTable/index.jsx';

// ========================= FILE INPUT ========================
const UploadFileInput = props => {
  const { t, setIsLoaded, uploadFileInputRef, setExtractedDataFromCsv } = props;

  return (
    <FormGroup>
      <Label for="customInputFile">{t('upload_csv_file')}</Label>
      <CustomInput
        innerRef={inRef => {
          uploadFileInputRef.current = inRef;
        }}
        type="file"
        id="customInputFile"
        name="customFile"
        accept=".csv"
        onChange={e => {
          setIsLoaded(false);
          const file = e.target.files[0];
          if (!file) return;
          const reader = new FileReader();
          reader.onload = function (e) {
            const bufferString = e.target.result;
            const lines = bufferString.split('\n');
            const result = [];
            const csvHeaderToKey = {
              'Category name': 'name',
              'Item title': 'title'
            };
            const headers = lines[0]
              .split(',')
              .map(h => csvHeaderToKey[h] || h);

            if (
              !headers.includes('name') ||
              !headers.includes('title') ||
              !headers.includes('description')
            ) {
              setExtractedDataFromCsv([]);
              return Message.error(t('incorrect_imported_file'));
            }

            for (let i = 1; i < lines.length; i++) {
              let obj = {};

              let row = lines[i],
                queryIdx = 0,
                startValueIdx = 0,
                idx = 0;

              if (row.trim() === '') {
                continue;
              }

              while (idx < row.length) {
                /* if we meet a double quote we skip until the next one */
                let c = row[idx];

                if (c === '"') {
                  do {
                    c = row[++idx];
                  } while (c !== '"' && idx < row.length - 1);
                }

                if (
                  c === ',' ||
                  /* handle end of line with no comma */ idx === row.length - 1
                ) {
                  /* we've got a value */
                  let value = row
                    .substr(startValueIdx, idx - startValueIdx)
                    .trim();

                  /* skip first double quote */
                  if (value[0] === '"') {
                    value = value.substr(1);
                  }
                  /* skip last comma */
                  if (value[value.length - 1] === ',') {
                    value = value.substr(0, value.length - 1);
                  }
                  /* skip last double quote */
                  if (value[value.length - 1] === '"') {
                    value = value.substr(0, value.length - 1);
                  }

                  let key = headers[queryIdx++];
                  obj[key] = value;
                  startValueIdx = idx + 1;
                }

                ++idx;
              }
              result.push(obj);
            }

            const newResult = result.map(res => {
              const newObj = Object.keys(res).reduce((a, c) => {
                return { ...a, [c.replace(/\s+/g, '')]: res[c] };
              }, {});
              return newObj;
            });

            setExtractedDataFromCsv(
              newResult.map((obj, idx) => {
                return { ...obj, id: idx };
              })
            );
          };
          reader.readAsText(file);
          setIsLoaded(true);
        }}
      />
    </FormGroup>
  );
};

// =================== IMPORTED FILE PREVIE (TABLE) ====================
const ImportedFilePreview = props => {
  const {
    t,
    isLoaded,
    extractedDataFromCsv,
    checkedRowsId,
    setCheckedRowsId,
    tableRef
  } = props;

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const totalSize = extractedDataFromCsv.length;

  const customTotal = (from, to, size) => (
    <span className="ml-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const handleOnSelect = rows => {
    const rowIds = rows.map(r => r.original.id);
    setCheckedRowsId(rowIds);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      setCheckedRowsId([...checkedRowsId, ...ids]);
    } else {
      setCheckedRowsId(
        checkedRowsId.filter(el => {
          return !ids.includes(el);
        })
      );
    }
  };

  const nonSelectableRows = row => {
    return row.name == '' || row.title == '' || row.description == '';
  };

  const selectRow = {
    onSelect: handleOnSelect,
    onNonSelectable: nonSelectableRows
  };

  const NoData = () => {
    if (isLoaded) {
      return <h1>NO DATA</h1>;
    }
    return <></>;
  };

  const reactTableColumns = t => [
    {
      id: 'name',
      accessor: 'name',
      Header: t('category_name'),
      disableFilters: true,
      // disableSortBy: true,
      Cell: ({ row, value }) => {
        return row.original.name;
      }
    },
    {
      id: 'title',
      accessor: 'title',
      Header: t('category_item_title'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return row.original.title;
      }
    },
    {
      id: 'description',
      accessor: 'description',
      Header: t('description'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return row.original.description;
      }
    },
    {
      id: 'is_default',
      accessor: 'is_default',
      Header: t('category_item_is_default'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const isDefault = row.original.is_default;
        if (isDefault === 'TRUE' || isDefault === 'FALSE') {
          return isDefault;
        } else {
          return 'FALSE';
        }
      }
    }
  ];

  const paginationOptions = {
    page,
    sizePerPage,
    totalSize,
    // paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Prev',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'First page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '5',
        value: 5
      },
      {
        text: '10',
        value: 10
      },
      {
        text: '20',
        value: 20
      },
      {
        text: '100',
        value: 100
      }
    ],
    onPageChange: (newPage, newSizePerPage) => {
      setPage(newPage);
      setSizePerPage(newSizePerPage);
    },
    onSizePerPageChange: (newSizePerPage, newPage) => {
      setPage(newPage);
      setSizePerPage(newSizePerPage);
    }
  };

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'asc'
    }
  ];

  return (
    <ReactTable
      ref={tableRef}
      bootstrap4
      hover
      bordered={false}
      keyField="id"
      data={extractedDataFromCsv}
      columns={reactTableColumns(t)}
      defaultSorted={defaultSorted}
      pagination={paginationOptions}
      loading={!isLoaded}
      overlay={loading =>
        ({ children }) => {
          return (
            <div className="position-relative">
              {children}
              {loading && (
                <div
                  className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                  <div
                    className={`spinner-border text-primary ${styles.tableOverlayDimension}`}
                    role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      noDataIndication={NoData()}
      selectRow={selectRow}
      remote={{ pagination: false, filter: true, sort: false }}
    />
  );
};

// =================== BATCH UPDATE CONTENT MODAL ====================
const BatchUpdateContentModal = props => {
  const { state, dispatch } = useContext(knowledgehubContext);
  const { t } = props;

  const [checkedRowsId, setCheckedRowsId] = useState([]);
  const [extractedDataFromCsv, setExtractedDataFromCsv] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);

  const selectedRows = extractedDataFromCsv.filter(obj => {
    return checkedRowsId.includes(obj.id);
  });

  const handleImportFileModal = () => {
    dispatch(setIsImportFileModalOpen(!state.isImportFileModalOpen));
    setExtractedDataFromCsv([]);
    setCheckedRowsId([]);
  };

  const uploadFileInputRef = useRef();
  const tableRef = useRef();

  const btnDisabled = !(
    uploadFileInputRef.current &&
    uploadFileInputRef.current.value &&
    selectedRows.length > 0
  );

  const handleSubmitData = async () => {
    await dispatch(batchUpdateContent(selectedRows)).then(res => {
      if (res.success) {
        Message.success(t('import_successful'));
        handleImportFileModal();
        dispatch(searchByTitle(state.searchText));
      } else {
        Message.error(t('import_failed'));
      }
    });
  };

  return (
    <>
      <Modal backdrop="static" size="xl" isOpen={state.isImportFileModalOpen}>
        <ModalHeader toggle={handleImportFileModal}>
          {t('batch_update_modal_title')}
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            {/* ====== FILE INPUT ===== */}
            <UploadFileInput
              {...props}
              setIsLoaded={setIsLoaded}
              uploadFileInputRef={uploadFileInputRef}
              setExtractedDataFromCsv={setExtractedDataFromCsv}
            />
            <Button
              color="success"
              tag="a"
              download
              href={`${
                process.env.REACT_APP_API_ENDPOINT[
                  process.env.REACT_APP_API_ENDPOINT.length - 1
                ] == '/'
                  ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                  : process.env.REACT_APP_API_ENDPOINT
              }/api/knowledgeHub/templateFile/download`}>
              <i className="fa fa-download p-2" aria-hidden="true"></i>
              {t('download_template')}
            </Button>
          </div>
          {/* ========== PREVIEW ========= */}
          <ImportedFilePreview
            {...props}
            tableRef={tableRef}
            isLoaded={isLoaded}
            extractedDataFromCsv={extractedDataFromCsv}
            checkedRowsId={checkedRowsId}
            setCheckedRowsId={setCheckedRowsId}
          />
          {extractedDataFromCsv.length > 0 && (
            <Alert color="info" className="mt-4">
              {' '}
              <b>Note : </b>
              {t('disabled_checkbox_info')}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmitData}
            disabled={btnDisabled}>
            {t('import')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withTranslation(['knowledgeHub'])(BatchUpdateContentModal);
