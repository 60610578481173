import React, { useState, useEffect } from 'react';

import {
  Label,
  ListGroupItem,
  Row,
  Col,
  Input,
  Button,
  Container
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Message from '../../../UI/Message';
import { withTranslation } from 'react-i18next';
import CustomInputFromFile from '../../../UI/Input/customInput';
import ConfirmationModal from './ConfirmationModal';
import prettyBytes from 'pretty-bytes';

// Only allow number input
const preventStringInput = evt => {
  if (evt.which < 48 || evt.which > 57) {
    evt.preventDefault();
  }
};

const formikConfig = props => {
  const { userData } = props;
  return {
    initialValues: {
      total_cu: userData.userUsage.total_cu,
      // used_cu: userData.userUsage.used_cu, //read-only
      reserved_cu: userData.userUsage.reserved_cu,
      // available_cu: userData.userUsage.available_cu, //read-only
      total_model_storage: userData.userUsage.total_model_storage,
      // used_model_storage: userData.userUsage.used_model_storage, //read-only
      reserved_model_storage: userData.userUsage.reserved_model_storage
      // available_model_storage: userData.userUsage.available_model_storage //read-only
    },
    validationSchema: Yup.object().shape({
      total_cu: Yup.number().required('Required'),
      reserved_cu: Yup.number().required('Required'),
      total_model_storage: Yup.number().required('Required'),
      reserved_model_storage: Yup.number().required('Required')
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      props
        .updateUserUsage(props.userId, {
          [props.fieldEdited]: values[props.fieldEdited]
        })
        .then(res => {
          if (!!res.success) Message.success('Saved');
          props.setModal(false);
          setSubmitting(false);
        })
        .catch(err => {
          Message.error(props.t(`error:${err.message}`));
        });
    }
  };
};

const UserUsagePresenter = props => {
  const { t, userId, userData, getUserUsage } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [fieldEdited, setFieldEdited] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const editReservedCU = fieldEdited === 'reserved_cu';
  const editTotalCU = fieldEdited === 'total_cu';
  const editTotalModelStorage = fieldEdited === 'total_model_storage';
  const editReservedModelStorage = fieldEdited === 'reserved_model_storage';

  useEffect(() => {
    getUserUsage(userId)
      .then(res => {
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
  }, []);

  return (
    <>
      {isLoaded && userData.userUsage ? (
        <div>
          <Formik {...formikConfig({ ...props, setModal, fieldEdited })}>
            {formikProps => {
              const { values, setFieldValue } = formikProps;
              const handleEditUserUsage = fieldname => () => {
                formikProps.resetForm({
                  values: {
                    total_cu: userData.userUsage.total_cu,
                    reserved_cu: userData.userUsage.reserved_cu,
                    total_model_storage: userData.userUsage.total_model_storage,
                    reserved_model_storage:
                      userData.userUsage.reserved_model_storage
                  }
                });

                setFieldEdited(fieldname);
              };

              return (
                <div>
                  <Form>
                    {/* ========================= MODEL STORAGE ========================== */}
                    <div className="bg-secondary text-center font-weight-bold text-white py-1">
                      {t(`subscription:computing_unit`)}
                    </div>
                    {/* ================Total Computing Unit================ */}
                    <ListGroupItem className={editTotalCU ? 'bg-light' : ''}>
                      <Row>
                        <Col>
                          {editTotalCU ? (
                            <>
                              <Row>
                                <Col>
                                  <Label
                                    for="editTotalCU"
                                    className="font-weight-bold">
                                    {t('total_cu')}
                                  </Label>
                                  <Field
                                    type="number"
                                    name="total_cu"
                                    component={CustomInputFromFile}
                                  />
                                </Col>
                              </Row>
                              <Row className=" mt-2 ">
                                <Col className="d-flex align-items-end justify-content-end">
                                  <Button
                                    disabled={
                                      userData.userUsage.total_cu ===
                                      values.total_cu
                                    }
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={e => {
                                      if (values.total_cu !== '')
                                        return toggle();
                                    }}>
                                    {t('save')}
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                    onClick={handleEditUserUsage('')}>
                                    {t('cancel')}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <div>
                              <Row>
                                <Col>
                                  <strong>{t('total_cu')}: </strong>
                                </Col>
                                <Col>{values.total_cu}</Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        {!editTotalCU && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              outline
                              color="secondary"
                              onClick={handleEditUserUsage('total_cu')}>
                              <i className="fas fa-pencil-alt" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                    {/* ================Used Computing Unit================ */}
                    <ListGroupItem>
                      <Row>
                        <Col>
                          <div>
                            <Row>
                              <Col>
                                <strong>{t('used_cu')}: </strong>
                              </Col>
                              <Col>{userData.userUsage.used_cu}</Col>
                            </Row>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </ListGroupItem>
                    {/* ================Reserved Computing Unit================ */}
                    <ListGroupItem className={editReservedCU ? 'bg-light' : ''}>
                      <Row>
                        <Col>
                          {editReservedCU ? (
                            <>
                              <Row>
                                <Col>
                                  <Label
                                    for="editReservedCU"
                                    className="font-weight-bold">
                                    {t('reserved_cu')}
                                  </Label>
                                  <Field
                                    type="number"
                                    name="reserved_cu"
                                    component={CustomInputFromFile}
                                  />
                                </Col>
                              </Row>
                              <Row className=" mt-2 ">
                                <Col className="d-flex align-items-end justify-content-end">
                                  <Button
                                    disabled={
                                      userData.userUsage.reserved_cu ===
                                      values.reserved_cu
                                    }
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={e => {
                                      if (values.reserved_cu !== '')
                                        return toggle();
                                    }}>
                                    {t('save')}
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                    onClick={handleEditUserUsage('')}>
                                    {t('cancel')}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <div>
                              <Row>
                                <Col>
                                  <strong>{t('reserved_cu')}: </strong>
                                </Col>
                                <Col>{values.reserved_cu}</Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        {!editReservedCU && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              outline
                              color="secondary"
                              onClick={handleEditUserUsage('reserved_cu')}>
                              <i className="fas fa-pencil-alt" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                    {/* ================Available Computing Unit================ */}
                    <ListGroupItem>
                      <Row>
                        <Col>
                          <div>
                            <Row>
                              <Col>
                                <strong>{t('available_cu')}: </strong>
                              </Col>
                              <Col>{userData.userUsage.available_cu}</Col>
                            </Row>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </ListGroupItem>
                    {/* ========================= MODEL STORAGE ========================== */}
                    <div className="bg-secondary text-center font-weight-bold text-white py-1">
                      {t(`subscription:model_storage`)}
                    </div>
                    {/* ================Total Model Storage================ */}
                    <ListGroupItem
                      className={editTotalModelStorage ? 'bg-light' : ''}>
                      <Row>
                        <Col>
                          {editTotalModelStorage ? (
                            <>
                              <Row>
                                <Col>
                                  <Label
                                    for="editTotalModelStorage"
                                    className="font-weight-bold">
                                    {t('total_model_storage')}
                                  </Label>
                                  <Field
                                    type="number"
                                    name="total_model_storage"
                                    component={CustomInputFromFile}
                                    onKeyPress={preventStringInput}
                                  />
                                  <div className="text-muted">
                                    {values.total_model_storage !== '' &&
                                      prettyBytes(values.total_model_storage)}
                                  </div>
                                </Col>
                              </Row>
                              <Row className=" mt-2 ">
                                <Col className="d-flex align-items-end justify-content-end">
                                  <Button
                                    disabled={
                                      userData.userUsage.total_model_storage ===
                                      values.total_model_storage
                                    }
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={e => {
                                      if (values.total_model_storage !== '')
                                        return toggle();
                                    }}>
                                    {t('save')}
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                    onClick={handleEditUserUsage('')}>
                                    {t('cancel')}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <div>
                              <Row>
                                <Col>
                                  <strong>{t('total_model_storage')}: </strong>
                                </Col>
                                <Col>
                                  <div>
                                    {userData.userUsage.total_model_storage}
                                  </div>
                                  <div className="text-muted">
                                    {userData.userUsage.total_model_storage !==
                                      '' &&
                                      prettyBytes(
                                        userData.userUsage.total_model_storage
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        {!editTotalModelStorage && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              outline
                              color="secondary"
                              onClick={handleEditUserUsage(
                                'total_model_storage'
                              )}>
                              <i className="fas fa-pencil-alt" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                    {/* ================Used Model Storage================ */}
                    <ListGroupItem>
                      <Row>
                        <Col>
                          <div>
                            <Row>
                              <Col>
                                <strong>{t('used_model_storage')}: </strong>
                              </Col>
                              <Col>
                                <div>
                                  {userData.userUsage.used_model_storage}
                                </div>
                                <div className="text-muted">
                                  {userData.userUsage.used_model_storage !==
                                    '' &&
                                    prettyBytes(
                                      userData.userUsage.used_model_storage
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </ListGroupItem>
                    {/* ================Reserved Model Storage================ */}
                    <ListGroupItem
                      className={editReservedModelStorage ? 'bg-light' : ''}>
                      <Row>
                        <Col>
                          {editReservedModelStorage ? (
                            <>
                              <Row>
                                <Col>
                                  <Label
                                    for="editReservedModelStorage"
                                    className="font-weight-bold">
                                    {t('reserved_model_storage')}
                                  </Label>
                                  <Field
                                    type="number"
                                    name="reserved_model_storage"
                                    component={CustomInputFromFile}
                                    onKeyPress={preventStringInput}
                                  />
                                  <span className="text-muted">
                                    {values.reserved_model_storage !== '' &&
                                      prettyBytes(
                                        values.reserved_model_storage
                                      )}
                                  </span>
                                </Col>
                              </Row>
                              <Row className=" mt-2 ">
                                <Col className="d-flex align-items-end justify-content-end">
                                  <Button
                                    disabled={
                                      userData.userUsage
                                        .reserved_model_storage ===
                                      values.reserved_model_storage
                                    }
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={e => {
                                      if (values.reserved_model_storage !== '')
                                        return toggle();
                                    }}>
                                    {t('save')}
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                    onClick={handleEditUserUsage('')}>
                                    {t('cancel')}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <div>
                              <Row>
                                <Col>
                                  <strong>
                                    {t('reserved_model_storage')}:{' '}
                                  </strong>
                                </Col>
                                <Col>
                                  <div>{values.reserved_model_storage}</div>
                                  <div className="text-muted">
                                    {userData.userUsage
                                      .reserved_model_storage !== '' &&
                                      prettyBytes(
                                        userData.userUsage
                                          .reserved_model_storage
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        {!editReservedModelStorage && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              outline
                              color="secondary"
                              onClick={handleEditUserUsage(
                                'reserved_model_storage'
                              )}>
                              <i className="fas fa-pencil-alt" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                    {/* ================Available Model Storage================ */}
                    <ListGroupItem>
                      <Row>
                        <Col>
                          <div>
                            <Row>
                              <Col>
                                <strong>
                                  {t('available_model_storage')}:{' '}
                                </strong>
                              </Col>
                              <Col>
                                <div>
                                  {userData.userUsage.available_model_storage}
                                </div>
                                <div className="text-muted">
                                  {userData.userUsage
                                    .available_model_storage !== '' &&
                                    prettyBytes(
                                      userData.userUsage.available_model_storage
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </ListGroupItem>
                  </Form>
                  <ConfirmationModal
                    t={t}
                    isModalOpen={modal}
                    toggle={toggle}
                    modalTitle={t(`subscription:confirm_save_title`)}
                    modalBody={t(`subscription:confirm_save_body`)}
                    saveAction={formikProps.handleSubmit}
                  />
                </div>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className="text-center border-bottom text-secondary p-4 bg-white">
          <h3> {t(`subscription:no_data`)}</h3>
        </div>
      )}
    </>
  );
};

export default withTranslation(['subscription'])(UserUsagePresenter);
