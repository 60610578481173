import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
// import PropTypes from 'prop-types';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import { timeAgoFormatting } from '../../../../locales/dateFormat';
import Message from '../../../UI/Message';
const dropzoneStyle = {
  position: 'relative',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: '5px',
  height: '118px'
};

const disableDropzoneStyle = {
  ...dropzoneStyle,
  pointerEvents: 'none',
  opacity: '0.5',
  background: '#CCC'
};

// const MAX_UPLOAD_FILE = 2143648;
const MAX_UPLOAD_FILE =
  parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE) || 2147483648;

class DocumentList extends Component {
  constructor(props) {
    super(props)
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false.valueOf,
      reference_documents_to_push: []
    };
  }

  render() {
    const {
      t,
      disabled,
      progressVisible,
      progressBarValue,
      referenceDocuments,
      referenceDocumentActions
    } = this.props;
    const { isDisabled } = this.state;

    const filteredDocuments = referenceDocuments.filter(document => {
      return !document.removed && document.dirty;
    });

    return (
      <>
        {/* <div
          className="dropzone bg-light"
          style={{ height: '120px', cursor: 'pointer' }}>
          <Dropzone
            multiple={true}
            className="p-4 d-flex flex-column justify-content-center align-items-center"
            style={disabled ? disableDropzoneStyle : dropzoneStyle}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.setFormikValue}>
            <h2>
              <i className="fas fa-upload " />
            </h2>
            <div className="text-center">Upload File/s</div>
          </Dropzone>
        </div> */}
        <hr></hr>
        {/* {filteredDocuments.length > 0 && (
          <ListGroup className="border-bottom">
            <ListGroupItem
              className="font-weight-bold shadow-sm text-primary"
              style={{ zIndex: 900 }}>
              File/s to upload
            </ListGroupItem>
            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {filteredDocuments.map((f, index) => {
                return (
                  <ListGroupItem
                    key={`${f.name}-${index}}`}
                    className="shadow-sm">
                    <Row className="d-flex justify-content-between px-2 ">
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          {f.name} - {prettyBytes(f.size || 0)}{' '}
                        </div>
                        {f.date && (
                          <div className="text-secondary">
                            last updated: {timeAgoFormatting(f.date_updated)}{' '}
                            ago
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-end flex-grow-1 mr-2">
                        <div>
                          {f.downloadLink && (
                            <>
                              <a
                                download
                                id={`downloaddocument-${index}`}
                                onClick={e => e.stopPropagation()}
                                className="pointer mr-2"
                                href={`${process.env.REACT_APP_API_ENDPOINT}/${f.downloadLink}`}>
                                <span>
                                  <i className="fas fa-download fs-1-2x text-primary" />
                                </span>
                              </a>
                              {this._isMounted && (
                                <UncontrolledTooltip
                                  fade={false}
                                  boundariesElement="window"
                                  target={`downloaddocument-${index}`}>
                                  {t('main:download')}
                                </UncontrolledTooltip>
                              )}
                            </>
                          )}
                        </div>
                        {!disabled && (
                          <>
                            {disabled ? null : (
                              <div>
                                <a
                                  id={`deletedocument-${index}`}
                                  className="pointer mr-2"
                                  onClick={() => {
                                    referenceDocumentActions.softRemoveReferenceDocument(
                                      f
                                    );
                                  }}>
                                  <i className="fas fa-trash-alt fs-1-2x text-danger" />
                                </a>
                                {this._isMounted && (
                                  <UncontrolledTooltip
                                    fade={false}
                                    boundariesElement="window"
                                    target={`deletedocument-${index}`}>
                                    {t('delete')}
                                  </UncontrolledTooltip>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </div>
          </ListGroup>
        )} */}
        {/* Confirmation modal for the file replacement */}
        {/* <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
          <ModalHeader> {t('project:want_to_replace_document')} </ModalHeader>
          <ModalBody>
            {this.state.fileToConfirm.file
              ? this.state.fileToConfirm.file.name
              : ''}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.acceptFileReplace}>
              {t('main:yes')}
            </Button>
            <Button type="button" color="secondary" onClick={this.closeModal}>
              {t('main:no')}
            </Button>
          </ModalFooter>
        </Modal> */}
      </>
    );
  }
}
export default DocumentList;
