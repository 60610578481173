import { performRequest } from '../requests';
import {
  projectSubmissionType,
  attachmentFileActionType
} from '../../constants/actionType';

const {
  CREATE_SUBMISSION,
  FETCH_SUBMISSION,
  CREATE_NEW_CORRESPONDENCE,
  FETCH_CORRESPONDENCE,
  RESET_SUBMISSION,
  ADMIN_FETCH_SUMISSIONS,
  ADMIN_FETCH_SUBMISSION_DETAIL,
  ADMIN_UPDATE_ONE_SUBMISSION,
  ADMIN_UPDATE_MULTIPLE_SUBMISSION,
  UPDATE_SUBMISSION
} = projectSubmissionType;

const { ADD_ATTACHMENTS, FETCH_ATTACHMENT } = attachmentFileActionType;

//project sumbmission action
export const createSubmission = (projectId, stageName, submissionData) => (
  dispatch,
  getState
) => {
  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
    params: {
      totalSizeAdded: getState().attachment_files.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.size;
        },
        0
      )
    }
  };
  const formData = new FormData();
  for (let val in submissionData) {
    if (val === 'jobIds') {
      formData.append('jobIds', JSON.stringify(submissionData.jobIds));
    } else {
      formData.append(val, submissionData[val]);
    }
  }

  getState().attachment_files.forEach(file => {
    formData.append('files[]', file);
  });

  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/stage/${stageName}/submission/create`,
      formData,
      requestConfig,
      'CREATE_SUBMISSION'
    )
  ).then(response => {
    if (!response.data.success)
      throw new Error(response.data.error || 'default_message');
    const submissionId = response.data.submission_id;
    dispatch({
      type: CREATE_SUBMISSION,
      submissionId,
      submissionData
    });
    return submissionId;
  });
};

export const fetchSubmission = (
  projectId,
  stageName,
  submissionId
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/project/${projectId}/stage/${stageName}/submission/${submissionId}/detail`,
      {},
      requestConfig,
      'FETCH_SUBMISSION_DETAIL'
    )
  )
    .then(res => res.data)
    .then(submissionDetail => {
      const {
        submission_details,
        po_assigned_set,
        created_by,
        job_related,
        ...other
      } = submissionDetail;
      dispatch({
        type: FETCH_SUBMISSION,
        submission_details: { ...submission_details, job_related },
        po_assigned_set,
        created_by,
        ...other
      });
      submissionDetail.submission_details.list_of_file_attachments.forEach(
        attachment => {
          dispatch({
            type: ADD_ATTACHMENTS,
            file: {
              name: attachment.file_name,
              downloadLink: attachment.download_link,
              size: attachment.size,
              date: attachment.date
            }
          });
        }
      );
    });
};

//Create new comment action
export const createNewCorrespondence = (
  { projectId, stageName, submissionId },
  correspondenceData
) => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().submission.concurrency_control_version_id ||
    getState().submission[submissionId].concurrency_control_version_id;
  const entityTargetId = submissionId;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/project/${projectId}/stage/${stageName}/submission/${submissionId}/new/message`,
      { ...correspondenceData, concurrencyControlVersionId, entityTargetId },
      requestConfig,
      'CREATE_NEW_CORRESPONDENCE'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(res => {
      dispatch({
        type: CREATE_NEW_CORRESPONDENCE,
        message: res.data[0]
      });

      dispatch({
        type: ADMIN_UPDATE_ONE_SUBMISSION,
        data: {
          concurrency_control_version_id: res.concurrencyControlVersionId
        },
        isFromAdminListing: false,
        submissionId
      });
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

//fetchCorrespondence by submissionId
export const fetchCorrespondence = ({
  projectId,
  stageName,
  submissionId
}) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/project/${projectId}/stage/${stageName}/submission/${submissionId}/message/list`,
      {},
      requestConfig,
      'FETCH_CORRESPONDENCE'
    )
  )
    .then(res => res.data)
    .then(messageList => {
      dispatch({
        type: FETCH_CORRESPONDENCE,
        submissionId,
        messageList
      });
    });
};

export const resetSubmission = () => {
  return {
    type: RESET_SUBMISSION
  };
};

export const adminFetchSubmissionList = (
  filters,
  order,
  pagination,
  toDispatch = true
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/admin/submission/list',
      { filters, order, pagination },
      requestConfig,
      'ADMIN_FETCH_SUBMISION_LIST',
      true
    )
  )
    .then(res => {
      return res.data;
    })
    .then(submissionList => {
      const submissionMap = submissionList.data.reduce(
        (map, currentSubmission) => {
          return {
            ...map,
            [currentSubmission.submission_id]: currentSubmission
          };
        },
        {}
      );
      if (toDispatch) {
        dispatch({
          type: ADMIN_FETCH_SUMISSIONS,
          submissionMap
        });
        return submissionList.total;
      } else {
        return submissionMap;
      }
    });
};

export const adminFetchSubmission = (
  projectId,
  stageName,
  submissionId
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/admin/project/${projectId}/stage/${stageName}/submission/${submissionId}/detail`,
      {},
      requestConfig,
      'ADMIN_FETCH_SUBMISSION_DETAIL'
    )
  )
    .then(res => res.data)
    .then(submissionData => {
      const {
        submission_details,
        po_assigned_set,
        created_by,
        job_related,
        ...other
      } = submissionData;
      dispatch({
        type: ADMIN_FETCH_SUBMISSION_DETAIL,
        submission_details: { ...submission_details, job_related },
        po_assigned_set,
        created_by,
        ...other
      });
      submissionData.submission_details.list_of_file_attachments.forEach(
        attachment => {
          dispatch({
            type: FETCH_ATTACHMENT,
            file: {
              name: attachment.file_name,
              downloadLink: attachment.download_link,
              size: attachment.size,
              date: attachment.date,
              id: attachment.id,
              path: attachment.path
            }
          });
        }
      );
    });
};

export const adminUpdateOneSubmissionWithPO = (
  projectId,
  stageName,
  submissionId,
  POAssigned
) => (dispatch, getState) => {
  const isFromAdminListing =
    getState().submission[submissionId] &&
    getState().submission[submissionId].concurrency_control_version_id;

  const concurrencyControlVersionId =
    getState().submission.concurrency_control_version_id ||
    getState().submission[submissionId].concurrency_control_version_id;
  const entityTargetId = submissionId;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      `api/admin/project/${projectId}/stage/${stageName}/submission/${submissionId}/assign_PO`,
      {
        ...POAssigned,
        concurrencyControlVersionId,
        entityTargetId
      },
      requestConfig,
      'ADMIN_UPDATE_ONE_SUBMISSION'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(res => {
      const { data } = res.data;

      dispatch({
        type: ADMIN_UPDATE_ONE_SUBMISSION,
        data: {
          po_assigned: res.data.po_assigned_set,
          po_assigned_set: res.data.po_assigned_set,
          concurrency_control_version_id:
            res.data.concurrency_control_version_id
        },
        isFromAdminListing,
        submissionId
      });
      return res;
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

//Preparation for multiple update
export const adminUpdateMultipleSubmissionWithPO = (
  submissions,
  excludeConflicts = false
) => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  // const submissionWithVersionIds = {};
  // submissionIds.map(id => {
  //   submissionsToUpdate[id] = values;
  // });

  const entityList = Object.keys(submissions).reduce(
    (returnedObj, id, index) => {
      return {
        ...returnedObj,
        [id]: {
          ...submissions[id],
          concurrency_control_version_id: getState().submission[id]
            .concurrency_control_version_id
        }
      };
    },
    {}
  );

  return dispatch(
    performRequest(
      'post',
      'api/admin/submission/update/multiple/assign_PO',
      { entityList, excludeConflicts },
      requestConfig,
      'ADMIN_UPDATE_MULTIPLE_SUBMISSION'
    )
  )
    .then(res => res.data)
    .then(({ success, data, conflictSubmissions, conflictIds }) => {
      if (success) {
        dispatch({
          type: ADMIN_UPDATE_MULTIPLE_SUBMISSION,
          data,
          isFromAdminListing: true
        });
        return {
          conflictSubmissions,
          data
        };
      } else {
        return conflictIds;
      }
    })
    .catch(err => {
      if (err.response.statusText === 'Conflict') {
        throw {
          conflictIds: err.response.data.conflictIds.map(id => {
            const { project_name } = getState().submission[id];
            return {
              project_name
            };
          }),
          error: err.response && err.response.data && err.response.data.error,
          message: err.response && err.response.data && err.response.data.error
        };
      } else {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};

export const updateSubmission = (
  projectId,
  stageName,
  submissionId,
  changedData
) => (dispatch, getState) => {
  const concurrency_control_version_id = getState().submission
    .concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      `api/project/${projectId}/stage/${stageName}/submission/${submissionId}`,
      {
        ...changedData,
        entityTargetId: submissionId,
        concurrencyControlVersionId: concurrency_control_version_id
      },
      requestConfig,
      'UPDATE_SUBMISSION'
    )
  )
    .then(res => res.data)
    .then(() => {
      dispatch({
        type: UPDATE_SUBMISSION,
        changedData
      });
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};
