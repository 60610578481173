import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, NavLink as BootstrapNavLink } from 'reactstrap';
import config from '../config/landingPageConfig';
import { withTranslation } from 'react-i18next';

const about = config.about;
const AboutSection = ({ t }) => {
  const { headerText, paragraphList } = about;
  return (
    <div className="about-background-color about-container text-center text-white">
      <Container>
        <h2 className="custom-h2">{t(`front:${headerText}`)}</h2>
        <hr className="about-custom-hr" />
        {paragraphList.map((p, i) => (
          <div key={`key-${i}`} className="py-2 text-faded m-3">
            <p>{t(`front:${p.text}`)}</p>
          </div>
        ))}
        <div className="d-flex justify-content-center py-4">
          <BootstrapNavLink
            className="about-custom-btn rounded-pill py-3"
            tag={NavLink}
            to="/register">
            {t(`front:aboutGetStarted`)}
          </BootstrapNavLink>
        </div>
      </Container>
    </div>
  );
};
export default withTranslation(['front'])(AboutSection);
