import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Col,
  Row
} from 'reactstrap';
import classnames from 'classnames';

import AdminRuleHistoryManagement from '../Admin/RuleHistory';
// import UserRuleManagement from '../UserPreference/ruleManagement';

import {
  revertToRuleHistoryAction,
  getConcurrencyControlVersionId
} from '../../../store/actions';

const RuleManagementIndex = props => {
  const { isAdmin, match, t } = props;

  const { cloneLevel, ruleLevel, ruleType, ruleId } = match.params;

  const ruleManagementLevel =
    match && match.params && match.params.ruleManagementLevel;

  return (
    <Container>
      <Row>
        <Col sm="12">
          <div className="shadow p-3 mt-3 mb-5 rounded">
            <h3 className="text-primary">
              {t('rules:rule_history_management')}
            </h3>
            <AdminRuleHistoryManagement {...props} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.authUser.function === 'ADMIN',
    userId: state.authUser.userId,

    rule_history: state.rule_history_admin
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    revertToRuleHistoryAction: ({
      rule_level,
      rule_id,
      rule_type,
      rule_history_id
    }) =>
      dispatch(
        revertToRuleHistoryAction({
          rule_level,
          rule_id,
          rule_type,
          rule_history_id
        })
      ),
    getConcurrencyControlVersionId: (entityType, entityTargetId) =>
      dispatch(getConcurrencyControlVersionId(entityType, entityTargetId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['rules'])
)(RuleManagementIndex);
