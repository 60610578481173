import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { createPortal } from 'react-dom';
import { withTranslation } from 'react-i18next';

import { Container } from 'reactstrap';
import styles from './styles.module.css';

import {
  getConcurrencyControlVersionId,
  getRuleById
} from '../../../../store/actions';

const IFrame = props => {
  const { ruleId, cloneLevel, ruleType, ruleLevel, ruleHistoryId } =
    props.match.params;

  const {
    isAdmin,
    isRuleManager,
    getConcurrencyControlVersionId,
    getRuleById
  } = props;

  const [concurrencyControlVersionId, setConcurrencyControlVersionId] =
    useState('');

  const [isLoaded, setIsLoaded] = useState(false);
  const [ruleTitle, setRuleTitle] = useState('');
  const [showExitConfirmation, setShowExitConfirmation] = useState(true);

  const handleTabClosing = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (showExitConfirmation) {
      window.addEventListener('beforeunload', handleTabClosing);
      window.addEventListener('unload', handleTabClosing);
      return () => {
        window.removeEventListener('beforeunload', handleTabClosing);
        window.removeEventListener('unload', handleTabClosing);
      };
    }
  }, [showExitConfirmation]);

  useEffect(() => {
    Promise.all([
      getRuleById(ruleId).then(ruleData => {
        setRuleTitle(
          (ruleData && ruleData.data && ruleData.data.rule_name) || ''
        );
      }),
      getConcurrencyControlVersionId('rule', ruleId).then(
        concurrencyControlVersionId => {
          setIsLoaded(true);
          setConcurrencyControlVersionId(concurrencyControlVersionId);
        }
      )
    ]);
  }, []);

  window.toogleCloseTabs = function (value) {
    setShowExitConfirmation(!showExitConfirmation);
  };

  return (
    isLoaded && (
      <Container
        fluid
        className="shadow my-4 mb-5 bg-light rounded flex-grow-1">
        <iframe
          title="UniversalTemplate"
          className={styles.UTiframeDimention}
          id="UTIframe"
          src={
            process.env.PUBLIC_URL +
            `/Universal_Template/home.html?cloneLevel=${cloneLevel}&ruleLevel=${ruleLevel}&ruleType=${ruleType}&ruleId=${ruleId}&ruleHistoryId=${ruleHistoryId}&concurrencyControlVersionId=${concurrencyControlVersionId}&isAdmin=${isAdmin}&isRuleManager=${isRuleManager}&ruleTitle=${ruleTitle}`
          }></iframe>
      </Container>
    )
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.authUser.function === 'ADMIN',
    isRuleManager: state.authUser.function === 'RM'
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConcurrencyControlVersionId: (entityType, entityTargetId) =>
      dispatch(getConcurrencyControlVersionId(entityType, entityTargetId)),
    getRuleById: ruleId => dispatch(getRuleById(ruleId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['error'])
)(IFrame);
