import { rolesActionType } from '../../constants/actionType';
import { performRequest } from '../requests';

export const fetchRoles = () => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: rolesActionType.RESET_ROLES });
  return dispatch(
    performRequest('get', '/api/roles', {}, requestConfig, 'ROLES')
  )
    .then(res => res.data)
    .then(roles => {
      dispatch({ type: rolesActionType.MERGE_ROLES, roles });
    });
};
