import React, { useState, useEffect, useRef, useMemo } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Col,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner,
  Badge
} from 'reactstrap';

import RuleParameterModal from './ModalRuleParameter';

import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { dateFormatting } from '../../../locales/dateFormat';

import Message from '../../UI/Message';

import ReactTable from '../ReactTable/index.jsx';


import './styles.css';

const reactTableColumns = (
  t,
  toggleRuleHistoryModal,
  revertToRuleHistoryAction,
  rule_data,
  setIsLoaded,
  concurrencyControlVersionId,
  ruleLevel
) => [
  // {
  //   dataField: 'title',
  //   text: 'Name'
  //   // headerFormatter: headerFormatter
  // },
  // {
  //   dataField: 'level',
  //   text: 'Rule level'
  // },
  {
    id: 'performer_email',
    accessor: 'performer_email',
    Header: t('rule_history:updated_by'),
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row, value }) => {
      return (
        <div className="formatterFontSize">
          {!!row.original.performer_email && (
            <span className="pr-3">
              {<i className="far fa-user text-success mr-2" />}
              {row.original.performer_email}
            </span>
          )}
        </div>
      );
    }
  },
  {
    id: 'date_updated',
    accessor: 'date_updated',
    Header: t('rule_history:version_date'),
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row, value }) => {
      const isActive =
        row.original.rule_history_id === rule_data.activeHistoryId ||
        (rule_data.activeHistoryId === undefined && row.index === 0);

      if (value) {
        return (
          <div className="formatterFontSize">
            <span className="pr-3">
              {<i className="far fa-calendar text-info mr-2" />}
              {dateFormatting(value, 'defaultHour')}
            </span>
            {!!isActive && <Badge color="success">Current version</Badge>}
          </div>
        );
      }
    }
  },
  {
    id: 'reason_for_update',
    accessor: 'reason_for_update',
    Header: t('rule_history:reason'),
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row, value }) => {
      if (value) {
        return (
          <div className="formatterFontSize">
            <span className="pr-3">{value}</span>
          </div>
        );
      } else {
        return <></>;
      }
    }
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: t('rule_history:action'),
    width: 50,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row, value }) => {
      let isHistoryInitalRule =
        rule_data.ruleId === row.original.rule_history_id;

      return (
        <UncontrolledDropdown>
          <DropdownToggle size="sm" color="muted" className="w-100">
            <i className="fas fa-ellipsis-h" />
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                toggleRuleHistoryModal(
                  row.original.rule_history_id,
                  false,
                  true
                );
              }}>
              <div>
                <span className="pr-3">
                  {<i className="fas fa-eye text-primary mr-2" />}
                  {t('rule_history:view')}
                </span>
              </div>
            </DropdownItem>
            <DropdownItem
              tag={Link}
              target="_blank"
              to={`/admin/rules/${ruleLevel}/${rule_data.ruleLevel}/${rule_data.ruleType}/${rule_data.ruleId}/rule_history/${row.original.rule_history_id}`}>
              <div>
                <span className="pr-3">
                  {<i className="fas fa-external-link-alt text-info mr-2" />}
                  {t('rule_history:edit')}
                </span>
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={async () => {
                setIsLoaded(false);

                try {
                  await revertToRuleHistoryAction({
                    rule_level: rule_data.ruleLevel,
                    rule_id: rule_data.ruleId,
                    rule_type: rule_data.ruleType,
                    rule_history_id: row.original.rule_history_id,
                    concurrencyControlVersionId
                  });
                  Message.success(t('rule_history:rule_successfully_reverted'));
                } catch (err) {
                  Message.error(
                    t(`error:${err.message}` || 'error:default_message', {
                      entity: 'rule'
                    })
                  );
                } finally {
                  setIsLoaded(true);
                }
              }}>
              <div>
                {!isHistoryInitalRule && (
                  <span className="pr-3">
                    {<i className="fa fa-history text-warning mr-2" />}
                    {t('rule_history:revert_to_this_version')}
                  </span>
                )}
              </div>
            </DropdownItem>{' '}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  }
];

const NoData = isLoaded => {
  if (isLoaded) {
    return <h1>NO DATA</h1>;
  }
};

const RuleHistoryContainer = props => {
  const { t, revertToRuleHistoryAction, getConcurrencyControlVersionId } =
    props;

  const { ruleLevel, ruleType, ruleId } = props.match.params;
  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize] = useState(1);

  const [ruleHistory, setRuleHistory] = useState([]);
  const [ruleHistoryModal, setRuleHistoryModal] = useState(false);

  const [activeHistoryId, setactiveHistoryId] = useState(false);

  const [ruleDetails, setRuleDetails] = useState('');
  const [activeHistory, setactiveHistory] = useState('');

  const [ruleIdAsHistoryId, setRuleIdAsHistoryId] = useState(false);

  const [current_version, setCurrentVersion] = useState('');

  const [concurrencyControlVersionId, setConcurrencyControlVersionId] =
    useState('');

  useEffect(() => {
    getConcurrencyControlVersionId('rule', ruleId).then(
      concurrencyControlVersionId => {
        setConcurrencyControlVersionId(concurrencyControlVersionId);
      }
    );
  }, []);

  const toggleRuleHistoryModal = (
    id = '',
    ruleIdAsHistoryId = false,
    fromTable = true
  ) => {
    if (!!ruleDetails.active_rule_history_id && !fromTable) {
      setRuleIdAsHistoryId(true);
    } else {
      setRuleIdAsHistoryId(false);
    }
    if (id) {
      setactiveHistoryId(id);
    }

    setRuleHistoryModal(!ruleHistoryModal);

    // if (isFromSubmitForm) {
    //   const history = ruleDetails.rule_history.filter(el => {
    //     return el.rule_history_id === id;
    //   });

    //   setactiveHistory(history);
    // }
  };

  // Prevent to re-render the modal for each state changes

  const RuleModal = useMemo(() => {
    return (
      <RuleParameterModal
        ruleId={ruleId}
        toggle={toggleRuleHistoryModal}
        open={ruleHistoryModal}
        t={t}
        ruleType={ruleType}
        level={ruleLevel}
        ruleHistoryId={activeHistoryId}
        ruleIdAsHistoryId={ruleIdAsHistoryId}
        setContentIsLoaded={setIsLoaded}
      />
    );
  }, [ruleHistoryModal]);

  useEffect(() => {
    props.fetchRules({ type: ruleType, reset: false });
  }, [ruleType]);

  useEffect(() => {
    props
      .fetchRuleHistoryList({ ruleLevel, ruleType, ruleId })
      .then(() => {
        setIsLoaded(true);
        const details = props.rule_history[Object.keys(props.rule_history)[0]];

        setRuleDetails(details);
      })
      .catch(err => setOnError(true));
  }, [ruleLevel, ruleType, ruleId]);

  useEffect(() => {
    if (isLoaded) {
      setRuleHistory(
        Object.keys(props.rule_history).map(key => {
          const history = props.rule_history[key].rule_history;
          return history;
        })[0]
      );

      const details = props.rule_history[Object.keys(props.rule_history)[0]];

      setRuleDetails(details);
    }
  }, [isLoaded, props.rule_history]);

  useEffect(() => {
    if (ruleDetails) {
      let history = [];

      if (ruleDetails.active_rule_history_id) {
        history = ruleDetails.rule_history.filter(el => {
          return el.rule_history_id === ruleDetails.active_rule_history_id;
        });
      } else {
        history = ruleDetails.rule_history.filter((el, index) => {
          return index === 0;
        });
      }

      if (history.filter(i => i.active_rule_history_id).length === 0) {
        setRuleIdAsHistoryId(true);
        setactiveHistoryId(ruleDetails.ruleId);
      }

      if (ruleDetails.active_rule_history_id) {
        setRuleIdAsHistoryId(true);
        setCurrentVersion(ruleDetails.active_rule_history_id);
      } else {
        setCurrentVersion(ruleDetails.ruleId);
      }

      setactiveHistory(history);
    }
  }, [isLoaded, ruleDetails]);

  const rowStyle = (row, index) => {
    if (
      ruleDetails.active_rule_history_id === row.rule_history_id ||
      (ruleDetails.active_rule_history_id === undefined && index === 0)
    ) {
      return {
        backgroundColor: '#e5e5f5'
      };
    }
  };

  return (
    <>
      {isLoaded && (
        <Container className="pt-3">
          {/* <Alert color="warning">
            <div className="d-flex ">
              <div className="mr-2">
                <i className="fas fa-info circle"></i>
              </div>
              <div className="flex-grow-1">
                {t('rules:rule_history_management_info')}
              </div>
            </div>
          </Alert> */}

          <Col sm="6">
            <Card body className="rounded bg-light ">
              <h5 className="text-primary">Details</h5>
              <div className="pt-1 formatterFontSize">
                <div className="pr-3">
                  <strong className="text-muted mr-2">Title:</strong>
                  {ruleDetails.title}
                </div>

                <div className="pt-1">
                  <strong className="text-muted mr-2">Level:</strong>
                  {ruleDetails.level}
                </div>
              </div>
            </Card>
          </Col>

          <div className="pt-3">
            <h5 className="text-primary">All version</h5>
            <ReactTable
              hover
              bordered={false}
              data={ruleHistory.filter(i => i.rule_history_id) || []}
              columns={reactTableColumns(
                t,
                toggleRuleHistoryModal,
                revertToRuleHistoryAction,
                {
                  ruleLevel,
                  ruleType,
                  ruleId,
                  activeHistoryId: ruleDetails.active_rule_history_id
                },
                setIsLoaded,
                concurrencyControlVersionId,
                ruleLevel
              )}
              loading={!isLoaded}
              noDataIndication={() => (
                <h3 className="text-muted">No data to display</h3>
              )}
            />
          </div>

          {RuleModal}
        </Container>
      )}

      {!isLoaded && (
        <div className="d-flex align-items-center justify-content-center isLoadedContainer">
          <Spinner type="grow" className="isLoadedSpinner" color="primary" />
        </div>
      )}
    </>
  );
};

export default compose(
  withTranslation([
    'main',
    'rules',
    'error',
    'user_preferences',
    'rule_history'
  ])
)(RuleHistoryContainer);
