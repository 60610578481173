import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  Progress,
  UncontrolledCollapse
} from 'reactstrap';
import { convertFromRaw } from 'draft-js';
import { Link } from 'react-router-dom';
import filter from 'lodash/filter';

import TaskCreation from './TaskCreation';
import { getProjectTasks } from '../../../store/actions';
import { dateFormatting } from '../../../locales/dateFormat';
import DisplayMessage from '../Submission/Correspondence/DisplayMessage';
import styles from './taskManagement.module.css';

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      createModalOpen: false
    };
  }

  componentDidMount() {
    this.props.getProjectTasks(this.props.projectId).then(() => {
      this.setState({ isLoaded: true });
    });
  }

  toggleCreateModal = () => {
    this.setState(prevState => ({
      createModalOpen: !prevState.createModalOpen
    }));
  };

  onCreateTaskSuccess = () => {
    this.toggleCreateModal();
    this.setState({ isLoaded: false });
    this.props.getProjectTasks(this.props.projectId).then(() => {
      this.setState({ isLoaded: true });
    });
  };

  render() {
    const { tasks, t, projectId, project } = this.props;
    const { isLoaded, createModalOpen } = this.state;
    return (
      <>
        {!createModalOpen && (
          <Container>
            <Row>
              <Col>
                <div className="shadow p-3 mt-3 mb-5 rounded">
                  <Row className="px-3 justify-content-between">
                    <h5 className="text-primary">
                      {t('task_management:task_management')}
                    </h5>
                    <div>
                      <Button color="warning" onClick={this.toggleCreateModal}>
                        <i className="fas fa-plus" /> {t('task_management:new')}
                      </Button>
                    </div>
                  </Row>
                  {isLoaded && (
                    <Row>
                      <ListGroup className="w-100 p-2">
                        <Container>
                          {tasks.map(task => {
                            const actionsCompleted = task.actions.filter(
                              a => a && a.status && a.status === 'COMPLETED'
                            ).length;
                            let messageContent;
                            try {
                              messageContent = convertFromRaw(
                                JSON.parse(decodeURI(task.message))
                              );
                            } catch (err) {
                              // console.log(err);
                            }
                            return (
                              <ListGroupItem key={`task-item-${task.task_id}`}>
                                <Row>
                                  <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                      <Col sm="4" className="pr-0">
                                        <Link
                                          className={styles.wordWrapBreakWord}
                                          to={`/project/${projectId}/task/detail/${task.task_id}`}>
                                          {task.topic}
                                        </Link>
                                      </Col>
                                      <Col
                                        sm="1"
                                        className="border-right d-flex justify-content-end">
                                        <Link
                                          to={`/project/${projectId}/task/detail/${task.task_id}`}>
                                          <i className="ml-1 fas fa-external-link-alt"></i>
                                        </Link>
                                      </Col>
                                      <Col
                                        sm="3"
                                        className="border-right text-center">
                                        <div className="flex-grow-1">
                                          <span className="font-italic">
                                            {t('task_management:creator')}:{' '}
                                          </span>
                                          {task.creator &&
                                            task.creator.first_name}{' '}
                                          {task.creator &&
                                            task.creator.last_name}
                                        </div>
                                      </Col>
                                      <Col sm="3" className="text-center">
                                        <div className="text-muted">
                                          <span className="font-italic">
                                            {t('task_management:date_created')}:{' '}
                                          </span>
                                          {dateFormatting(
                                            task.created_date,
                                            'defaultHour'
                                          )}
                                        </div>
                                      </Col>

                                      <Button
                                        color="link"
                                        id={`toggler-${task.task_id}`}>
                                        <i className="fas fa-chevron-down"></i>
                                      </Button>
                                    </div>
                                    <UncontrolledCollapse
                                      toggler={`toggler-${task.task_id}`}>
                                      {messageContent && (
                                        <div
                                          className={`p-1 rounded ${styles.displayMessageContainer}`}>
                                          <DisplayMessage
                                            stageName={''}
                                            projectId={projectId}
                                            content={messageContent}
                                          />
                                        </div>
                                      )}
                                    </UncontrolledCollapse>
                                  </div>
                                </Row>
                                <Row>
                                  <Progress
                                    className="mt-2 w-100"
                                    color={
                                      actionsCompleted === task.actions.length
                                        ? 'success'
                                        : 'info'
                                    }
                                    value={
                                      (actionsCompleted / task.actions.length) *
                                      100
                                    }>
                                    {actionsCompleted} /{task.actions.length}{' '}
                                    {t('task_management:actions')}
                                  </Progress>
                                </Row>
                              </ListGroupItem>
                            );
                          })}
                        </Container>
                      </ListGroup>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}

        {createModalOpen && (
          <Container>
            <div className="shadow p-3 mt-3 mb-5 rounded">
              <div className="overflow-auto">
                <TaskCreation
                  {...this.props}
                  toggle={this.toggleCreateModal}
                  onSubmitSuccess={this.onCreateTaskSuccess}
                />
              </div>
            </div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const projectId = props.projectId;
  const tasks = filter(state.tasks, task => task.project_related === projectId);
  tasks.sort((taskA, taskB) => taskB.created_date - taskA.created_date);
  return {
    tasks
  };
};

const mapDispatchToProps = dispatch => {
  return { getProjectTasks: projectId => dispatch(getProjectTasks(projectId)) };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['main', 'task_management'])
)(TaskList);
