import React, { Component } from 'react';
import ImageCropper from './imageCropper';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import styles from './styles.module.css';

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: this.props.type === 'URL' ? this.props.file : undefined,
    isModelImageCropOpen: false,
    croppedImage: undefined
  };

  componentDidUpdate(prevProps) {
    if (this.props.type === 'file' && prevProps.file !== this.props.file) {
      if (!this.props.file) {
        return;
      }

      this.setState({ loading: true }, () => {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({
            loading: false,
            thumb: reader.result,
            isModelImageCropOpen: !this.props.hasValidationError ? true : false
          });
        };

        reader.readAsDataURL(this.props.file);
      });
    }
  }

  render() {
    const { file, type, defaultImage, hasValidationError, setThumbnail } =
      this.props;
    const { loading, thumb, isModelImageCropOpen, croppedImage } = this.state;

    if (!file && type === 'file') {
      return null;
    }

    if (loading && type === 'file') {
      return <p>loading...</p>;
    }

    const onModelImageCropClose = () => {
      this.setState({ isModelImageCropOpen: !isModelImageCropOpen });
    };

    const setCroppedImage = croppedImage => {
      this.setState({ croppedImage });
    };

    return (
      <>
        {!hasValidationError && (
          <img
            src={croppedImage || thumb}
            alt={file.name}
            onError={e => {
              e.target.onerror = null;
              e.target.src = defaultImage || '';
            }}
            className="mt-2"
            height="250"
            width="100%"
          />
        )}

        <Modal isOpen={isModelImageCropOpen}>
          <ModalHeader>Crop Image</ModalHeader>
          <ModalBody className={styles.modalBodyImageCropper}>
            <ImageCropper
              imageFile={file}
              thumb={thumb}
              setCroppedImage={setCroppedImage}
              setThumbnail={setThumbnail}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onModelImageCropClose}>
              Done
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Thumb;
