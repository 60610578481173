import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import NotificationList from './NotificationList';
import styles from './Notifications.module.css';

class NotificationBlock extends Component {
  render() {
    return (
      <div className={`${styles.scrollableStyles}`}>
        <NotificationList {...this.props} />
      </div>
    );
  }
}

export default withTranslation(['notifications'])(NotificationBlock);
