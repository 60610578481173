import React, { Component } from 'react';
import XLSX from 'xlsx';

const withXLSExport = ComposedComponent => {
  class WithXLSExport extends Component {
    exportToXLS = (
      data,
      dataFormat = 'json',
      { fileName, type, bookType, sheet, headers, skipHeader }
    ) => {
      if (dataFormat === 'json') {
        const  wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { headers, skipHeader });
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, fileName, { type, bookType, sheet });
      }
      else if (dataFormat === 'array') {
        const  wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, fileName, { type, bookType, sheet });
      }
    };
    render() {
      return (
        <ComposedComponent {...this.props} exportToXLS={this.exportToXLS} />
      );
    }
  }
  WithXLSExport.displayName = `withXLSExport(${ComposedComponent.displayName ||
    ComposedComponent.name ||
    'Unknown'})`;
  return WithXLSExport;
};

export default withXLSExport;
