import { connect } from 'react-redux';
import UserPreferencePresenter from './UserPreferencePresenter';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  updateUserPreference,
  fetchAllUserPreference,
  removeFavoriteRulePreference,
  resetUserPreference
} from '../../../store/actions/user_preference';

const mapStateToProps = state => {
  return {
    userPreference: state.userPreference,
    userId: state.authUser.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateUserPreference: data => dispatch(updateUserPreference(data)),
    fetchAllUserPreference: () => dispatch(fetchAllUserPreference('ALL')),
    // removeFavoriteRulePreference: rule =>
    //   dispatch(removeFavoriteRulePreference(rule)),
    resetUserPreference: rule => dispatch(resetUserPreference(rule))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(UserPreferencePresenter);
