import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

const UserUsagePresenter = props => {
  const { t, getUserUsage, authUser, mergeAuthUserData } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getUserUsage(authUser.userId, true)
      .then(res => {
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
  }, []);

  const sortedUserUsage = {
    computing_unit: {
      total_cu: !!authUser.userUsage && authUser.userUsage.total_cu,
      used_cu: !!authUser.userUsage && authUser.userUsage.used_cu,
      reserved_cu: !!authUser.userUsage && authUser.userUsage.reserved_cu,
      available_cu: !!authUser.userUsage && authUser.userUsage.available_cu
    },
    model_storage: {
      total_model_storage:
        !!authUser.userUsage && authUser.userUsage.total_model_storage,
      used_model_storage:
        !!authUser.userUsage && authUser.userUsage.used_model_storage,
      reserved_model_storage:
        !!authUser.userUsage && authUser.userUsage.reserved_model_storage,
      available_model_storage:
        !!authUser.userUsage && authUser.userUsage.available_model_storage
    }
  };

  return (
    <>
      {isLoaded && (
        <Container className="mb-3">
          <Row className="px-3 mb-2">
            {authUser.userUsage && sortedUserUsage ? (
              Object.keys(sortedUserUsage).map((category, index) => (
                <Col
                  key={`sorted-usage-${index}`}
                  className="text-center"
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}>
                  <h5 className="my-4">{t(`subscription:${category}`)}</h5>
                  {Object.keys(sortedUserUsage[category]).map(
                    (type, typeIdx) => (
                      <div key={`usage-type-${typeIdx}`}>
                        <Row>
                          <Col className="m-2">
                            <Row>
                              <Col>{t(`subscription:${type}`)}</Col>
                              <Col>
                                {category === 'model_storage'
                                  ? prettyBytes(
                                      sortedUserUsage[category][type] || 0
                                    )
                                  : sortedUserUsage[category][type]}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )
                  )}
                </Col>
              ))
            ) : (
              <Col>
                <div className="text-center text-secondary p-4">
                  <h1>{t(`subscription:no_data`)}</h1>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default withTranslation(['subscription'])(UserUsagePresenter);
