import { connect } from 'react-redux';
import { compose } from 'recompose';
import NewsListPresenter from './NewsListPresenter';
import {
  adminCreateNews,
  adminFetchAllNews,
  resetNews
} from '../../../../store/actions/news';
import withXLSExport from '../../../Shared/WithXLSExport';
const mapToTable = news => {
  return Object.keys(news).map(key => {
    return news[key];
  });
};

const mapStateToProps = state => {
  return {
    newsList: mapToTable(state.news)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNews: newsData => dispatch(adminCreateNews(newsData)),
    fetchAllNews: (filters, order, pagination, toDispatch ) =>
      dispatch(adminFetchAllNews(filters, order, pagination, toDispatch)),
    resetNews: () => dispatch(resetNews())
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withXLSExport
)(NewsListPresenter);
