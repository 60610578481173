import React, { Component, useState, useRef, useEffect } from 'react';
import {
  Container,
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Dropdown,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';

import Slider from 'rc-slider';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash';
import { withSocketIO } from '../../../../api/sockets/withSocketIO';

import { dateFormatting } from '../../../../locales/dateFormat';

import styles from './JobsPresenter.module.css';
import 'rc-slider/assets/index.css';

import JobStatusPopUpInfo from '../../../Shared/RuleCheckStatusReport';
import { getURLParameters } from '../../../../utils/url';
import { isEqual } from 'lodash';
import Message from '../../../UI/Message';
import { createMarks } from '../exportToFileUtil';

import AzureQueueMonitoringModal from './AzureQueueMonitoring';

import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const selectOptionsStatus = t => {
  return {
    PARTIALLY_COMPLETED: t('results:status_partial'),
    PENDING: t('results:status_pending'),
    IN_PROCESS: t('results:status_process'),
    DONE: t('results:status_completed'),
    GENERATING_REPORT: t('results:status_generating_report'),
    ERROR: t('results:status_error'),
    USER_CANCELLED: t('results:status_user_cancelled')
  };
};

const statusIcon = status => {
  return {
    PENDING: (
      <span className="text-info">
        <i className="fas fa-spinner fa-spin mr-2" />
        <span className="text-secondary">PENDING</span>
      </span>
    ),
    IN_PROCESS: (
      <span className="text-info">
        <i className="fas fa-spinner fa-spin mr-2" />
        <span className="text-secondary">IN_PROCESS</span>
      </span>
    ),
    DONE: (
      <span className="text-success">
        <i className="fas fa-check-circle mr-2" />
        <span className="text-secondary">DONE</span>
      </span>
    ),
    ON_ERROR: (
      <span className="text-danger">
        <i className="fas fa-exclamation-circle mr-2" />
        <span className="text-secondary">ERROR</span>
      </span>
    ),
    ERROR: (
      <span className="text-danger">
        <i className="fas fa-exclamation-circle mr-2" />
        <span className="text-secondary">ERROR</span>
      </span>
    )
  };
};

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'date_created',
      order: d || 'desc'
    }
  ];
};

const RulesCheck = ({ row }) => {
  const progressBarRef = useRef();
  const rulesCompleted = row.rules_completed || [];
  const rulesChecked = row.rules_check || [];

  useEffect(() => {
    progressBarRef.current.style['width'] = `${
      (rulesCompleted.length / rulesChecked.length) * 100
    }%`;
  }, [rulesCompleted, rulesChecked]);

  return (
    <div className="progress position-relative">
      <div
        ref={progressBarRef}
        className={`progress-bar ${
          row.status === 'IN_PROCESS' ? 'bg-info' : 'bg-danger'
        } progress-bar-striped ${
          row.status === 'IN_PROCESS' ? 'progress-bar-animated' : ''
        }`}
        role="progressbar"
        animated={row.status === 'IN_PROCESS'}
        aria-valuenow={Math.floor(
          (rulesCompleted.length / rulesChecked.length) * 100
        )}
        aria-valuemin="0"
        aria-valuemax="100">
        <div className="justify-content-center d-flex position-absolute w-100 text-dark">
          {rulesCompleted.length} / {rulesChecked.length}
        </div>
      </div>
    </div>
  );
};

class JobListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      collapse: {},
      filters: {},
      formats: ['xlsx', 'csv'],
      selectedJobIds: [],
      dropdownOpen: false,
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFileRange: [0, 0],
      exportFormat: 'csv',
      order: { dataField: 'date_created', order: 'desc' },
      loadingItemToExport: false,
      modalMessageQueueOpen: false
    };
  }

  componentDidMount() {
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'date_created') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'date_created', direction: d || 'desc' }
      },
      () => {
        this.fetchJobList();
      }
    );
    if (this.props.userId) {
      const eventsToSubscribeTo = [
        {
          eventName: 'RULE_CHECK_RESULT_STATUS_UPDATED',
          handler: data => {
            this.props.fetchJobListAsAdmin(
              this.state.filters,
              this.state.order,
              {
                from: this.state.defaultExportRange[0],
                to: this.state.defaultExportRange[1]
              },
              true
            );
          }
        },
        {
          eventName: 'RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE',
          handler: throttle(data => {
            if (
              this.props.jobs &&
              this.props.jobs.find(j => j.job_id === data.jobId)
            ) {
              this.props.updateJobStatus(data);
            } else {
              this.props.fetchJobListAsAdmin(
                this.state.filters,
                this.state.order,
                {
                  from: this.state.defaultExportRange[0],
                  to: this.state.defaultExportRange[1]
                },
                true
              );
            }
          }, 1000)
        }
      ];
      try{
        this.props.subscribeToEvents(eventsToSubscribeTo);
      }catch(err){
        console.log(err)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchJobList();
    }
  }
  fetchJobList() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .fetchJobListAsAdmin(filters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,
            exportRange: [from + 1, to >= totalSize ? totalSize : to],
            exportFileRange: [from, to >= totalSize ? totalSize : to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString(filters)}`
            );
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  filterToURLString(filters) {
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'date_created' || res === 'date_completed') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'date_created', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (
                cur === 'date_created' ||
                cur === 'status' ||
                cur === 'date_completed'
              ) {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'date_created' || cur === 'date_completed') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              if (cur === 'status') {
                return { ...acc, [cur]: newState.filters[cur].filterVal };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };
  exportToXLS = async (format, range) => {
    const filtered_by_selected = await this.props.fetchJobListAsAdmin(
      this.state.filters,
      this.state.order,
      {
        from: range[0],
        to: range[1]
      },
      false
    );

    const finalData = filtered_by_selected.map(item => {
      return {
        'Project name': item.project_name,
        'Stage name': item.stage_name,
        'Date submitted': dateFormatting(item.date_created, 'defaultHour'),
        'Date started': item.check_actual_start_date
          ? dateFormatting(item.check_actual_start_date, 'defaultHour')
          : 'Undefined',
        Status: item.status,
        'Date completed': item.date_completed
          ? dateFormatting(item.date_completed, 'defaultHour')
          : 'Undefined',
        'Submitted by': item.requested_by,
        'Rules Checked': `Total: ${item.rules_check.length} (${item.rules_check
          .reduce(
            (prev, entry) => [
              ...prev,
              typeof entry === 'string' ? entry : Object.entries(entry)[0][1]
            ],
            []
          )
          .join(' | ')})`
      };
    });

    this.props.exportToXLS(finalData, 'json', {
      fileName: `rule_checks_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  toggleMessageQueueModal = () => {
    this.setState(prevState => ({
      modalMessageQueueOpen: !prevState.modalMessageQueueOpen
    }));
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      collapse,
      filters,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport,
      modalMessageQueueOpen
    } = this.state;

    const {
      jobs,
      t,
      featureList,
      fetchJobQueueStatusMetrics,
      requestAdminJobCancellation
    } = this.props;

    const isJobCancellationEnabled = featureList.jobCancellation;
    const isJobQueueMonitoringEnabled = featureList.jobQueueMonitoring;

    const CancelButton = ({
      jobId,
      translate,
      requestAdminJobCancellation
    }) => {
      const [isRequestRunning, setRequestRunning] = useState(false);
      const handleClick = async () => {
        try {
          setRequestRunning(true);
          await requestAdminJobCancellation(jobId);
          Message.success(translate('results:successfully_cancelled'));
          setRequestRunning(false);
        } catch (err) {
          Message.error(translate(err.message));
          setRequestRunning(false);
        }
      };
      return (
        <>
          {!isRequestRunning ? (
            <>
              <Button
                id={`cancel-${jobId}`}
                color="link"
                size="md"
                onClick={handleClick}>
                <i className="fas fa-ban text-danger"></i>
              </Button>
              <UncontrolledTooltip
                boundariesElement="window"
                fade={false}
                target={`cancel-${jobId}`}
                // delay={0}
              >
                {translate('cancel')}
              </UncontrolledTooltip>
            </>
          ) : (
            <div className="px-3 py-2">
              <span
                className="text-danger  spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
        </>
      );
    };

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const NoData = () => {
      if (isLoaded) {
        return <h1 className="text-center">NO DATA</h1>;
      }
      return <></>;
    };

    // ============================================ REACT TABLE COLUMN ===============================
    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = () => {
      const cols = [
        {
          id: 'project_name',
          Header: 'Project name',
          accessor: 'project_name',
          Cell: ({ row, value }) => {
            return (
              <div className={styles.table_header_overflow}>
                <Link
                  to={`/project/${row.original.project_id}/models/${row.original.stage_name}`}>
                  {value}
                </Link>
              </div>
            );
          },
          Filter: ({ column, ...props }) => {
            return (
              <TextColumnFilter
                placeholder={t('project_name')}
                column={column}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        },
        {
          id: 'stage_display_name',
          Header: 'Stage name',
          accessor: 'stage_display_name',
          Filter: ({ column }) => {
            return (
              <TextColumnFilter
                placeholder={t('stage_name')}
                column={column}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        },
        {
          id: 'date_created',
          Header: 'Date created',
          accessor: 'date_created',

          Cell: ({ value }) => {
            return dateFormatting(value, 'defaultHour');
          },
          Filter: ({ column }) => {
            return (
              <DateRangeColumnFilter
                column={column}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        },
        {
          id: 'status',
          Header: 'Status',
          accessor: 'status',
          // Filter: SelectColumnFilter,
          Cell: ({ value, row }) => {
            return <JobStatusPopUpInfo jobInfo={row.original} t={t} />;
          },
          Filter: ({ column }) => {
            return (
              <SelectColumnFilter
                column={column}
                options={selectOptionsStatus(t)}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        },
        {
          id: 'date_completed',
          Header: 'Date completed',
          accessor: 'date_completed',
          // filter: 'between',
          Cell: ({ value }) => {
            if (value) {
              return dateFormatting(value, 'defaultHour');
            } else {
              return ' - ';
            }
          },
          Filter: ({ column }) => {
            return (
              <DateRangeColumnFilter
                column={column}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        },
        {
          id: 'rules_check',
          Header: 'Rules checked',
          accessor: 'rules_check',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => {
            const rulesCompleted = row.original.rules_completed || [];
            const rulesChecked = row.original.rules_check || [];
            return (
              <>
                <div id={`jobId-${row.original.job_id}`} type="link">
                  {`${t('total_rules')}: `}
                  <span className="font-weight-bold">
                    {rulesChecked.length}
                  </span>
                  {!['PENDING', 'DONE'].includes(row.original.status) &&
                    row.original.rules_completed && (
                      <RulesCheck row={row.original} />
                    )}
                </div>
                <UncontrolledPopover
                  boundariesElement="window"
                  trigger="hover"
                  className="rule-popover-container"
                  target={`jobId-${row.original.job_id}`}>
                  <PopoverHeader>{`${t('rules')} (${
                    rulesChecked.length
                  })`}</PopoverHeader>
                  <PopoverBody className={styles.scrollableStyles}>
                    {rulesChecked.map((entry, key) => {
                      const ruleId = Object.keys(entry)[0];
                      const ruleName = Object.values(entry)[0];
                      const ruleCompleted = rulesCompleted.find(
                        r => r.rule_id === ruleId
                      );
                      return (
                        <div key={key} className="mb-2">
                          {(ruleCompleted && ruleCompleted.completed) ||
                          row.original.status === 'DONE' ? (
                            <i className="fas fa-check text-success" />
                          ) : (ruleCompleted &&
                              ruleCompleted.completed === false) ||
                            ['ON_ERROR', 'USER_CANCELLED'].includes(
                              row.original.status
                            ) ? (
                            <i className="fas fa-times text-danger" />
                          ) : (
                            <i className="fas fa-spinner fa-spin mr-2" />
                          )}
                          {ruleName}
                        </div>
                      );
                    })}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            );
          }
        },
        {
          id: 'requested_by',
          Header: 'Submitted by',
          accessor: 'requested_by',
          Cell: ({ value }) => {
            return (
              <div className={styles.requested_by_font_size}>
                <span className="pr-3">
                  {<i className="far fa-user text-success mr-2" />}
                  {value}
                </span>
              </div>
            );
          },
          Filter: ({ column }) => {
            return (
              <TextColumnFilter
                placeholder={t('requested_by')}
                column={column}
                filters={this.state.filters}
                filterToState={this.filterToState}
              />
            );
          }
        }
      ];

      if (isJobCancellationEnabled) {
        cols.push({
          id: 'actions',
          Header: 'Actions',
          accessor: 'actions',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value, row }) => {
            switch (row.original.status) {
              case 'IN_PROCESS':
                return (
                  <div className="d-flex justify-content-center">
                    {isJobCancellationEnabled && (
                      <>
                        <CancelButton
                          jobId={row.original.job_id}
                          translate={t}
                          requestAdminJobCancellation={
                            requestAdminJobCancellation
                          }
                        />
                        {/* <DeleteButton
                      projectId={this.props.projectId}
                      stageName={this.props.stageName}
                      jobId={row.job_id}
                      translate={translate}
                    /> */}
                      </>
                    )}
                  </div>
                );
              case 'PENDING':
                return (
                  <div className="d-flex justify-content-center">
                    {isJobCancellationEnabled && (
                      <>
                        <CancelButton
                          jobId={row.original.job_id}
                          translate={t}
                          requestAdminJobCancellation={
                            requestAdminJobCancellation
                          }
                        />
                        {/* <DeleteButton jobId={row.job_id} translate={translate} /> */}
                      </>
                    )}
                  </div>
                );
              default:
                return <> </>;
            }
          }
        });
      }

      return cols;
    };
    // =============================================================================================

    return (
        <Container className="mx-3 mt-4" fluid>
          <div className="shadow p-3 mb-5 bg-light rounded">
            <div className="mt-2">
              <h3 className="text-primary">{t('job_management')}</h3>
            </div>
            <div className="text-right pb-2">
              <div>
                <Button
                  className="mr-1"
                  hidden={!(Object.keys(filters).length > 0)}
                  outline
                  size="md"
                  color="secondary"
                  onClick={() => this.clearFilter()}>
                  <i className="fas fa-times"></i> Clear Filters
                </Button>

                {isJobQueueMonitoringEnabled && (
                  <Button
                    onClick={this.toggleMessageQueueModal}
                    color="primary"
                    outline
                    className="mr-1">
                    <i className="fas fa-tasks mr-1"></i>
                    {t('job_queue_status')}
                  </Button>
                )}

                <Dropdown
                  className="float-right"
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}>
                  <DropdownToggle
                    color="success"
                    outline
                    disabled={jobs.length === 0}>
                    <i className="fas fa-file-export mr-1" />
                    {t('main:export')}
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.formats.map((format, i) => {
                      return (
                        <DropdownItem
                          key={`export-option-${i}`}
                          onClick={() => {
                            this.openExportModal(format);
                            // this.exportToXLS(format);
                          }}>
                          <i
                            className={`far fa-file-excel mr-2 ${styles.export_dropdown_color}`}
                            color="primary"
                          />
                          {format}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            {/* TABLE WITH REACT TABLE LIBRARY */}
            <ReactTable
              columns={reactTableColumns(t)}
              data={jobs}
              hover
              bordered={false}
              pagination={paginationOptions}
              sortByFunc={setOrderBy}
              defaultSorted={defaultSorted()}
              noDataIndication={NoData()}
              loading={!isLoaded}
              remote={{ pagination: true, filter: true, sort: true }}
              overlay={loading =>
                ({ children }) => {
                  return (
                    <div className="position-relative">
                      {children}
                      {loading && (
                        <div
                          className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.table_overlay_position}`}>
                          <div
                            className={`spinner-border text-primary ${styles.table_overlay_dimension}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
            />
          </div>
          <Modal
            isOpen={exportModalOpen}
            size="lg"
            toggle={() =>
              this.setState(prevState => ({
                exportModalOpen: !prevState.exportModalOpen
              }))
            }
            className="">
            <ModalHeader
              toggle={() =>
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }))
              }>
              {t('main:export_records')}
            </ModalHeader>
            <ModalBody>
              <div>{t('main:choose_records_to_export')}</div>
              <div className={styles.modal_size_and_margin}>
                <Range
                  min={1}
                  max={totalSize}
                  marks={createMarks(totalSize)}
                  // allowCross={false}
                  defaultValue={defaultExportRange}
                  value={exportRange}
                  pushable={1}
                  onChange={newRange => {
                    this.setState({
                      exportRange: newRange,
                      exportFileRange: [newRange[0] - 1, newRange[1]]
                    });
                  }}
                />
              </div>
              <div className="text-muted font-italic">
                *Note : only filtered items are included
              </div>
              <div>
                Export items {exportRange[0]} to {exportRange[1]} to a{' '}
                {exportFormat} file.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loadingItemToExport}
                onClick={async () => {
                  this.setState({ loadingItemToExport: true });
                  await this.exportToXLS(exportFormat, exportFileRange);
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }));
                  this.setState({ loadingItemToExport: false });
                }}>
                {loadingItemToExport && (
                  <span
                    className="mr-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t('main:export')}
              </Button>{' '}
              <Button
                color="secondary"
                disabled={loadingItemToExport}
                onClick={() =>
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }))
                }>
                {t('main:cancel')}
              </Button>
            </ModalFooter>
          </Modal>
          {isJobQueueMonitoringEnabled && (
            <AzureQueueMonitoringModal
              isOpen={modalMessageQueueOpen}
              toggle={this.toggleMessageQueueModal}
              t={t}
              fetchJobQueueStatusMetrics={fetchJobQueueStatusMetrics}
            />
          )}
        </Container>
    );
  }
}

export default withSocketIO(JobListingPresenter);
