import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link /* , withRouter */ } from 'react-router-dom';
import {
  Container,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledCollapse,
  Badge,
  Row,
  Col
} from 'reactstrap';
import classnames from 'classnames';

import ReactTable from '../../../../Shared/ReactTable/index';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../../Shared/ReactTable/columnFilters';

import { isEqual } from 'lodash';
import { getURLParameters } from '../../../../../utils/url';
import styles from './mentionsStyles.module.css';

import {
  fetchAllMaintenanceMessage,
  deleteMaintenanceMessage,
  updateMaintenanceMessage
} from '../../../../../store/actions';

import {
  dateFormatting,
  timeAgoBetweenTwoDates
} from '../../../../../locales/dateFormat';

const selectOptionsDisplayStatus = t => {
  return {
    ENABLED: t('enabled'),
    DISABLED: t('disabled')
  };
};

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'created_at',
      order: d || 'desc'
    }
  ];
};

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);
class ListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createUserModal: false,
      selectedUserIds: [],
      dropdownOpen: false,
      maintenanceAnnouncementlist: [],
      selectedIds: [],
      modal: false,
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      collapse: {},
      filters: {},
      formats: ['xlsx', 'csv'],
      selectedNewsIds: [],
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFormat: 'csv',
      order: { dataField: 'created_at', order: 'desc' },
      loadingItemToExport: false
    };
  }

  // toggleCreateUserModal = () => {
  //   this.setState({ createUserModal: !this.state.createUserModal });
  // };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  componentDidMount() {
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);

    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (
        cur === 'created_at' ||
        cur === 'maintenance_date_start_date' ||
        cur === 'maintenance_date_end_date' ||
        cur === 'display_start_date' ||
        cur === 'display_end_date'
      ) {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'created_at', direction: d || 'desc' }
      },
      () => {
        this.fetchMaintenance();
      }
    );
    // this.props.fetchAllMaintenanceMessage().then(data => {
    //   this.setState({
    //     isLoaded: true
    //   });
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;

    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchMaintenance();
    }
  }

  fetchMaintenance() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .fetchAllMaintenanceMessage(filters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,
            exportRange: [from, to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString()}`
            );
          }
        );
      });
  }
  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  filterToURLString = () => {
    const { filters } = this.state;
    const URLArray = Object.keys(filters).map(res => {
      if (
        res === 'created_at' ||
        res === 'maintenance_date_start_date' ||
        res === 'maintenance_date_end_date' ||
        res === 'display_start_date' ||
        res === 'display_end_date'
      ) {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'created_at', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      filters,
      modal,
      maintenanceAnnouncementlist,
      collapse,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFormat,
      loadingItemToExport
    } = this.state;
    const { t, maintenanceList } = this.props;

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };
    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      } else {
        return (
          <div
            className={`spinner-grow text-primary ${styles.tableOverlayDimension}`}
            role="status">
            <span className="sr-only">Loading...</span>
          </div>
        );
      }
    };

    const columns = t => [
      {
        id: 'maintenance_date_start_date',
        accessor: 'maintenance_date_start_date',
        Header: t('maintenance_start_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'maintenance_date_end_date',
        accessor: 'maintenance_date_end_date',
        Header: t('maintenance_end_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'display_start_date',
        accessor: 'display_start_date',
        Header: t('display_start_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'display_end_date',
        accessor: 'display_end_date',
        Header: t('display_end_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },

      {
        id: 'enable_display',
        accessor: 'enable_display',
        Header: t('display_status'),
        Cell: ({ row, value }) => {
          let message = {};
          const now = Date.now();

          if (
            row.original.display_start_date < now &&
            row.original.display_end_date >= now
          ) {
            if (row.original.enable_display) {
              message.text = `will end displaying ${timeAgoBetweenTwoDates(
                Date.now(),
                row.original.display_end_date
              )}`;
              message.color = 'warning';
            } else {
              message.text = 'disabled';
              message.color = 'danger';
            }
          } else if (row.original.display_end_date < now) {
            message.text = `Displayed ${timeAgoBetweenTwoDates(
              row.original.display_end_date,
              Date.now()
            )}`;
            message.color = 'success';
          } else if (now < row.original.display_start_date) {
            message.text = `will be displaying in ${timeAgoBetweenTwoDates(
              Date.now(),
              row.original.display_start_date
            )}`;
            message.color = 'info';
          }
          return (
            <>
              <div>
                <h6>
                  {' '}
                  {row.original.enable_display ? t('enabled') : t('disabled')}
                  <Badge color={message.color}>{message.text}</Badge>{' '}
                </h6>
              </div>
            </>
          );
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={selectOptionsDisplayStatus(t)}
              filters={this.state.filters}
              filterToState={this.filterToState}
              className="form-control-sm"
              defaultValue={filters.enable_display || ''}
            />
          );
        },
        hidden: false
      },
      {
        id: 'actions',
        Cell: ({ row, value }) => {
          const action = row.original.enable_display ? 'Disable' : 'Enable';

          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="muted" className="w-100">
                  <i className="fas fa-ellipsis-h" />
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem
                    className="text-decoration-none"
                    onClick={() => {
                      this.setState({
                        isLoaded: false
                      });
                      this.props
                        .updateMaintenanceMessage(row.original.id, {
                          enable_display: !row.original.enable_display
                        })
                        .then(() => {
                          this.setState({
                            isLoaded: true
                          });
                        });
                    }}>
                    {action}
                  </DropdownItem>{' '}
                  <DropdownItem
                    className="text-decoration-none"
                    onClick={() => {
                      this.props.deleteMaintenanceMessage(row.original.id);
                    }}>
                    {t('Delete')}
                  </DropdownItem>{' '}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      }
    ];
    return (
      <Container>
        <div className="d-flex">
          <h4 className="text-primary py-1 mr-auto">
            {t('maintenance_listing')}
          </h4>
          <Button
            className="mr-1 align-self-center"
            hidden={!(Object.keys(filters).length > 0)}
            outline
            size="md"
            color="secondary"
            onClick={() => this.clearFilter()}>
            <i className="fas fa-times"></i> Clear Filters
          </Button>
        </div>
        <ReactTable
          overlay={loading =>
            ({ children }) => {
              return (
                <div className="position-relative">
                  {children}

                  {loading && (
                    <div
                      className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                      <div
                        className={`spinner-border text-primary ${styles.tableOverlayDimension}`}
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          hover
          bordered={false}
          keyField="id"
          data={maintenanceList || []}
          columns={columns(t)}
          defaultSorted={defaultSorted()}
          filter={filters}
          pagination={paginationOptions}
          loading={!isLoaded}
          noDataIndication={NoData}
          remote={{ pagination: true, filter: true, sort: true }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceList: state.maintenance_announcement
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllMaintenanceMessage: (filters, order, pagination, toDispatch) =>
      dispatch(
        fetchAllMaintenanceMessage(filters, order, pagination, toDispatch)
      ),
    deleteMaintenanceMessage: id => dispatch(deleteMaintenanceMessage(id)),
    updateMaintenanceMessage: (id, data) =>
      dispatch(updateMaintenanceMessage(id, data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['adminConfigurations', 'error'])
)(ListingPresenter);
