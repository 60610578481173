import { professionType } from '../../constants/actionType';
const { RESET_PROFESSION, FETCH_ALL_PROFESSION } = professionType;

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RESET_PROFESSION:
    return initialState;
  case FETCH_ALL_PROFESSION:
    return [...state, ...action.list];
  default:
    return state;
  }
};

export default reducer;
