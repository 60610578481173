import React, { useState } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';
import { timeAgoFormatting } from '../../../locales/dateFormat';
import { Link } from 'react-router-dom';
import Message from '../../UI/Message';
import styles from './Notifications.module.css';

import NotificationsModal from './NotificationsModal';

const NotificationList = props => {
  const {
    t,
    notifications,
    readNotification,
    dismissNotification,
    fetchMore
  } = props;
  const [modal, setModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');

  const toggleModal = key => {
    setModal(!modal);
    setSelectedIndex(key);
  };

  return (
    <>
      <ListGroup flush>
        {notifications.length !== 0 ? (
          <>
            {notifications.map((notification, key) => {
              return (
                <ListGroupItem
                  className={`py-1 px-2 ${
                    notification.is_read
                      ? null
                      : styles.unreadNotificationBGColor
                  }`}
                  key={`notification-${key}`}>
                  <div
                    className="media"
                    onClick={() => {
                      return !notification.is_read
                        ? readNotification(notification.notification_id)
                        : null;
                    }}>
                    <div
                      className={`d-flex align-items-center justify-content-center align-self-center rounded-circle mr-2 text-light bg-${
                        notification.bg_color || 'default_notif_bgcolor'
                      } ${styles.nameAcronymSize}`}
                      dangerouslySetInnerHTML={{
                        __html: notification.name_acronym || 'BIM'
                      }}
                    />

                    <div className="media-body">
                      <Link
                        to={`${notification.url_link}`}
                        className="text-decoration-none">
                        <p
                          className="mb-1 text-muted"
                          dangerouslySetInnerHTML={{
                            __html: notification.content
                          }}
                        />
                      </Link>

                      <div className="d-flex justify-content-between">
                        <small className="text-muted">
                          {timeAgoFormatting(notification.date)}
                        </small>

                        <small>
                          {notification.is_expand ? (
                            <>
                              <span
                                className="mr-1"
                                onClick={() => {
                                  toggleModal(key);
                                  return !notification.is_read
                                    ? readNotification(
                                        notification.notification_id
                                      )
                                    : null;
                                }}
                                id={`expand-${notification.notification_id}`}>
                                <i className="fas fa-external-link-alt" />
                              </span>
                              <UncontrolledTooltip
                                fade={false}
                                boundariesElement="window"
                                placement="top"
                                target={`expand-${notification.notification_id}`}>
                                {t('expand')}
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            ''
                          )}

                          <span
                            className="pointer"
                            onClick={() => {
                              dismissNotification(
                                notification.notification_id
                              ).then(res => {
                                return res.is_dismissed
                                  ? Message.info(t('notification_dismissed'))
                                  : null;
                              });
                            }}
                            id={`dismiss-${notification.notification_id}`}>
                            <i className="far fa-bell-slash" />
                          </span>
                          <UncontrolledTooltip
                            fade={false}
                            boundariesElement="window"
                            placement="top"
                            target={`dismiss-${notification.notification_id}`}>
                            {t('dismiss')}
                          </UncontrolledTooltip>
                        </small>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              );
            })}
            <ListGroupItem
              className="text-center pointer"
              onClick={() => {
                fetchMore();
              }}>
              <h6 className="m-0">{t('see_more')}</h6>
            </ListGroupItem>
          </>
        ) : (
          <ListGroupItem className="text-center">
            <h6>{t('no_notifications')}</h6>
          </ListGroupItem>
        )}
      </ListGroup>
      <NotificationsModal
        selectedNotificationIndex={selectedIndex}
        notifications={notifications}
        modal={modal}
        toggle={toggleModal}
      />
    </>
  );
};

export default NotificationList;
