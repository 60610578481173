import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Collapse,
  Badge
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import EditProfile from '../EditProfile';
import { MainSubscription } from '../Subscription';
import UserPreference from '../UserPreference';
import { useSelector } from 'react-redux';

const profileTabs = [
  { name: 'user-details', label: 'my_account', component: EditProfile },
  {
    name: 'user-preferences',
    label: 'user_preference',
    component: UserPreference
  }
];

const UserProfile = props => {
  const featureList = useSelector(state => state.app.featureList);
  const isPaymentModuleEnabled = featureList.paymentModule;
  const isSubscriptionTabExist = !!profileTabs.find(
    tab => tab.name == 'subscription'
  );

  if (!isSubscriptionTabExist && isPaymentModuleEnabled) {
    profileTabs.push({
      name: 'subscription',
      label: 'my_subscription',
      component: MainSubscription
    });
  }

  const { t, match } = props;
  const [activeTab, setActiveTab] = useState(
    match.params.tab || profileTabs[0].name
  );

  useEffect(() => {
    props.history.replace(`/profile/${activeTab}`);
  }, [activeTab]);

  return (
    <Container fluid className="w-100 d-flex flex-row justify-content-center">
      {/* <h1 className="text-primary">{project.name}</h1> */}

      <Row className="d-flex flex-row w-100">
        <Col md={4} sm={12} lg={2} className=" ">
          <div className="h-100">
            <Nav className="shadow mt-3 rounded" tabs vertical>
              {profileTabs.map((tab, index) => (
                <NavItem
                  key={`profile-tab-${tab.name}`}
                  className={classnames('rounded border border-white ')}>
                  <NavLink
                    className={classnames(
                      'rounded bg-primary text-white border-0',
                      {
                        'active font-weight-bold bg-light text-primary':
                          activeTab === tab.name
                      }
                    )}
                    onClick={() => {
                      setActiveTab(tab.name);
                    }}>
                    {t(tab.label)}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        </Col>
        <Col className="flex-grow-1">
          <TabContent activeTab={activeTab} className="w-100">
            {profileTabs.map((tab, index) => (
              <TabPane key={`profile-pane-${tab.name}`} tabId={tab.name}>
                {tab.name === activeTab && <tab.component />}
                {/* {tab.name === activeTab && <tab.component />} */}
              </TabPane>
            ))}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(['main'])(UserProfile);
