import { projectSubmissionType } from '../../constants/actionType';

const {
  CREATE_SUBMISSION,
  FETCH_CORRESPONDENCE,
  FETCH_SUBMISSION,
  CREATE_NEW_CORRESPONDENCE,
  RESET_SUBMISSION,
  ADMIN_FETCH_SUMISSIONS,
  ADMIN_FETCH_SUBMISSION_DETAIL,
  ADMIN_UPDATE_ONE_SUBMISSION,
  ADMIN_UPDATE_MULTIPLE_SUBMISSION
} = projectSubmissionType;
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBMISSION: {
      const { submissionId, submissionData } = action;
      return { ...state, [submissionId]: submissionData };
    }
    case FETCH_SUBMISSION: {
      const {
        submission_details,
        po_assigned_set,
        created_by,
        ...other
      } = action;
      return {
        ...state,
        ...submission_details,
        po_assigned_set,
        created_by,
        ...other
      };
    }
    case RESET_SUBMISSION: {
      return initialState;
    }
    case FETCH_CORRESPONDENCE: {
      const { messageList } = action;
      return {
        ...state,
        messageList
      };
    }
    case CREATE_NEW_CORRESPONDENCE: {
      const { message } = action;
      return { ...state, messageList: [...state.messageList, message] };
    }
    case ADMIN_FETCH_SUMISSIONS: {
      const { submissionMap } = action;
      return submissionMap;
    }
    case ADMIN_FETCH_SUBMISSION_DETAIL: {
      const {
        submission_details,
        po_assigned_set,
        created_by,
        ...other
      } = action;
      return {
        ...state,
        ...submission_details,
        po_assigned_set,
        created_by,
        ...other
      };
    }
    case ADMIN_UPDATE_ONE_SUBMISSION: {
      const { data, isFromAdminListing, submissionId } = action;

      if (isFromAdminListing) {
        return {
          ...state,
          [submissionId]: {
            ...state[submissionId],
            ...data
          }
        };
      } else {
        return {
          ...state,
          ...data
        };
      }
    }

    case ADMIN_UPDATE_MULTIPLE_SUBMISSION: {
      const { data, isFromAdminListing } = action;

      const newState = Object.keys(state).reduce((returnData, id) => {
        const findData =
          data.find(sub => {
            return sub.submission_id === id;
          }) || {};

        if (Object.keys(findData).length > 0) {
          return {
            ...returnData,
            [id]: {
              ...state[id],
              ...findData,
              po_assigned: findData.po_assigned_set,
              concurrency_control_version_id:
                findData.concurrencyControlVersionId
            }
          };
        } else {
          return {
            ...returnData,
            [id]: {
              ...state[id]
            }
          };
        }
      }, {});

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
