import { rolesActionType } from '../../constants/actionType';

const initialState = [];

const { MERGE_ROLES, RESET_ROLES } = rolesActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ROLES:
      return initialState;
    case MERGE_ROLES:
      return [...state, ...action.roles];
    default:
      return state;
  }
};

export default reducer;
