import { statActionType } from '../../constants/actionType';

const initialState = {};

const { MERGE_STATS, RESET_STATS } = statActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RESET_STATS:
    return initialState;
  case MERGE_STATS:
    return { ...state, ...action.data };
  default:
    return state;
  }
};

export default reducer;
