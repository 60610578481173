import React, { Component } from 'react';
import { Container } from 'reactstrap';

import { ProjectDetailPage } from '../../Project';

class DetailPresenter extends Component {
  render() {
    return (
      <>
        <ProjectDetailPage />
        <Container>
          <div>ADMIN ONLY</div>
        </Container>
      </>
    );
  }
}

export default DetailPresenter;
