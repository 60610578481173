import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Header from './components/UI/Header';
import MaintenanceMessage from './components/UI/MaintenanceMessage';
import Footer from './components/UI/Footer';
// import Footer from './components/UI/Footer/dist';
import Routes from './Routes';
import { withStore } from './store';
import ScrollToTop from './components/Shared/ScrollTop';
import ErrorBoundary from './components/Shared/ErrorBoundary';
import i18n from './i18n';
import Cookie from './components/UI/Disclaimer/Cookie';
import { SocketIoProvider } from './api/sockets/withSocketIO';
import { Helmet } from 'react-helmet';
import './App.scss';

// fetch feature list from server
import { fetchFeatureList, mergeNavLinks } from './store/actions';

import navLinks from './store/constants/navLinks';

// Material Icons
import 'material-design-icons-iconfont';

// Font awesome icons
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoReplaceSvg = 'nest';
config.autoAddCss = false;
require('@fortawesome/fontawesome-free/js/all');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuresIsLoaded: false
    };
  }
  componentDidMount() {
    this.props
      .fetchFeatureList()
      .then(() => this.setState({ featuresIsLoaded: true }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authUser.function !== this.props.authUser.function ||
      prevProps.app.isAuthenticated !== this.props.app.isAuthenticated ||
      prevState.featuresIsLoaded !== this.state.featuresIsLoaded
    ) {
      this.props.mergeNavLinks(navLinks(this.props.app.featureList));
    }
    if (prevProps.app.language !== this.props.app.language) {
      i18n.changeLanguage(this.props.app.language);
    }
  }
  render() {
    return (
      <>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <div className="App d-flex flex-column">
              <Header />
              <div id="scroll-container" className="d-flex flex-column">
                <MaintenanceMessage />
                <ErrorBoundary>
                  <div className="content-wrapper flex-grow-1 d-flex flex-column w-100 position-absolute">
                    <div className="flex-grow-1 d-flex">
                      <ScrollToTop>
                        {this.state.featuresIsLoaded && <Routes />}
                      </ScrollToTop>
                    </div>
                    <Footer
                      isLoggedIn={this.props.app.isAuthenticated}
                      userAccountType={this.props.authUser.function}
                    />
                  </div>
                </ErrorBoundary>
                <Cookie />
              </div>
            </div>
          </BrowserRouter>
        </I18nextProvider>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { app: state.app, authUser: state.authUser };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeatureList: () => dispatch(fetchFeatureList()),
    mergeNavLinks: navLinksData => dispatch(mergeNavLinks(navLinksData))
  };
};

export default compose(
  withStore,
  connect(mapStateToProps, mapDispatchToProps),
  SocketIoProvider
)(App);
