import React, { Component, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  FormGroup
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldArray, getIn } from 'formik';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { compose } from 'recompose';

import Attachment from './AttachmentList';
import Message from '../../UI/Message';
import CustomInput from '../../UI/Input/customInput';
import InfoAlert from '../../UI/InformationAlert';
import SubmissionResultList from './SubmissionResultList';
import CorrespondenceEditor from './Correspondence/Editor';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import prettyBytes from 'pretty-bytes';
import { timeAgoFormatting } from '../../../locales/dateFormat';

const selectRow = (resultsToSubmit, setFieldTouched) => ({
  mode: 'checkbox',
  clickToSelect: true,
  onSelect: (rows, isSelect) => {
    resultsToSubmit = rows.map(r => r.original.job_id);
    setFieldTouched();
  },
  selected: resultsToSubmit
});

const formikConfig = props => {
  return {
    initialValues: {
      reference: '',
      pinCorrespondenceSubject: '',
      pinCorrespondenceContent: '',
      jobIds: []
    },
    validationSchema: Yup.object().shape({
      jobIds: Yup.array().min(1, props.t('error_select_result')),
      pinCorrespondenceSubject: Yup.string(
        props.t('error:message_subject_required')
      ).required(props.t('error:message_subject_required'))
    }),
    validate: values => {
      const editorState = values.pinCorrespondenceContent;
      if (
        !editorState.blocks ||
        editorState.blocks.filter(b => b.text !== '').length === 0
      ) {
        return {
          pinCorrespondenceContent: props.t('error:message_content_required')
        };
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const { stageName, projectId } = props;
      try {
        const formattedValues = {
          ...values,
          pinCorrespondenceContent: JSON.stringify(
            values.pinCorrespondenceContent
          )
        };
        await props.createSubmission(projectId, stageName, formattedValues);
        props.resetAttachmentFiles();
        await props.fetchProjectStage(props.projectId, props.stageName);

        Message.success(props.t(`consultation_created_successfully`));
      } catch (err) {
        // TODO HANDLE ERR
        Message.error(props.t(`error:${err.message}`));
      } finally {
        props.toggleModalConfirm(false);
        setSubmitting(false);
      }
    }
  };
};

export class SubmissionPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      modal: false,
      editorState: EditorState.createEmpty()
    };
  }
  componentDidMount() {
    Promise.all([
      !this.props.project
        ? this.props.fetchProject(this.props.projectId)
        : Promise.resolve(),
      !this.props.stage
        ? this.props.fetchProjectStage(
            this.props.projectId,
            this.props.stageName
          )
        : Promise.resolve()
    ]).then(() => {
      this.setState({ isLoaded: true });
    });
  }
  componentWillUnmount() {
    Promise.all([this.props.resetAttachmentFiles(), this.props.resetResults()]);
  }

  toggle = value => {
    if (value) {
      this.setState({
        modal: value
      });
    } else {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }
  };

  openConfirmIfValid = formikProps => {
    // Set all the field to touched
    Object.keys(formikProps.values).forEach(k => {
      formikProps.setFieldTouched(k, true, false);
    });
    formikProps.validateForm().then(errors => {
      const errorsTab = Object.values(errors);
      if (errorsTab.length > 0) {
        Message.error(this.props.t('error:form_not_valid'));
      } else {
        this.setState({ modal: true });
      }
    });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };
  render() {
    const { project, stage, t, models, projectId, stageName } = this.props;
    const { isLoaded } = this.state;
    const stageDisplayName = stage && stage.display_name;
    const projectName = project.name;
    return (
      <>
        {isLoaded && stage && (
          <Container className="shadow mt-4 p-3 mb-5 rounded">
            {!stage.is_default ? (
              <Formik
                {...formikConfig({
                  ...this.props,
                  toggleModalConfirm: this.toggle
                })}>
                {formikProps => {
                  return (
                    <Form>
                      <div>
                        <Row className={'p-3'}>
                          <Col>
                            <h4>
                              <Trans i18nKey="create_submission">
                                Open consultation for the stage{' '}
                                <strong>
                                  {{ stageName: stageDisplayName }}
                                </strong>{' '}
                                of the project
                                <strong> {{ projectName }}</strong>
                              </Trans>
                            </h4>
                          </Col>
                        </Row>
                        <Row className={'p-3'}>
                          <Col>
                            <Card>
                              <Container className="p-3">
                                <Field
                                  labelClassName="text-primary fs-1-25rx"
                                  name="reference"
                                  type="text"
                                  label="Reference Number"
                                  placeholder="Reference Number"
                                  component={CustomInput}
                                />
                              </Container>
                            </Card>
                          </Col>
                        </Row>
                        <Row className={'p-3'}>
                          <Col>
                            <Card>
                              <Container className="p-3">
                                <Attachment isFormikForm />
                              </Container>
                            </Card>
                          </Col>
                        </Row>
                        <Row className={'p-3'}>
                          <Col>
                            <Card
                              className={classNames({
                                'border-danger border-thick':
                                  formikProps.touched.jobIds &&
                                  formikProps.errors.jobIds
                              })}>
                              <Container className="p-3">
                                <h5 className="text-primary mb-3">
                                  {t('results_title')}
                                </h5>

                                <Field name="results">
                                  {({
                                    field, // { name, value, onChange, onBlur }

                                    form: {
                                      touched,
                                      errors,
                                      setFieldValue,
                                      setFieldTouched,
                                      values
                                    }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

                                    meta
                                  }) => {
                                    return (
                                      <SubmissionResultList
                                        data={Object.keys(stage.jobs)
                                          .reduce(
                                            (jobList, jobType) => [
                                              ...jobList,
                                              ...stage.jobs[jobType]
                                            ],
                                            []
                                          )
                                          .filter(
                                            job =>
                                              job.status === 'DONE' &&
                                              !job.is_obsolete
                                          )}
                                        isValid={!formikProps.errors.jobIds}
                                        selectRow={{
                                          mode: 'checkbox',
                                          clickToSelect: true,
                                          onSelect: (rows, isSelect) => {
                                            const nextRows = rows.map(
                                              r => r.original.job_id
                                            );
                                            if (
                                              nextRows.length !==
                                              values.jobIds.length
                                            ) {
                                              setFieldTouched(
                                                'jobIds',
                                                true,
                                                false
                                              );
                                              setFieldValue(
                                                'jobIds',
                                                nextRows,
                                                true
                                              );
                                            }
                                          },
                                          selected: formikProps.values.jobIds
                                        }}
                                        projectName={project.name}
                                        projectId={projectId}
                                        stageName={stageName}
                                      />
                                    );
                                  }}
                                </Field>
                                <p>{t('note_select_result')}</p>
                                {formikProps.touched.jobIds &&
                                  formikProps.errors.jobIds && (
                                    <Alert color="danger">
                                      {formikProps.errors.jobIds}
                                    </Alert>
                                  )}
                              </Container>
                            </Card>
                          </Col>
                        </Row>
                        <Row className={'p-3'}>
                          <Col>
                            <Card>
                              <Container className="p-3">
                                <ListGroup>
                                  <h5 className="text-primary mb-3">
                                    {t('model_list')}
                                  </h5>
                                  {models.map((m, key) => {
                                    return (
                                      <ListGroupItem key={key}>
                                        <Row className="d-flex justify-content-between">
                                          <div className="d-flex flex-column justify-content-between">
                                            <div>
                                              {m.name} - {prettyBytes(m.size)}
                                            </div>
                                            {m.date && (
                                              <div className="text-secondary">
                                                last updated:{' '}
                                                {timeAgoFormatting(m.date)} ago
                                              </div>
                                            )}
                                          </div>
                                        </Row>
                                      </ListGroupItem>
                                    );
                                  })}
                                </ListGroup>
                              </Container>
                            </Card>
                          </Col>
                        </Row>
                        <Row className={'p-3'}>
                          <Col>
                            <div className="border p-3 mb-3 bg-white rounded">
                              <h5 className="text-primary py-3">
                                {t('correspondence')}
                              </h5>
                              <Field
                                name="pinCorrespondenceSubject"
                                placeholder="Subject"
                                component={CustomInput}
                              />
                              <Field name="pinCorrespondenceContent">
                                {() => {
                                  const {
                                    touched,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched
                                  } = formikProps;
                                  const isValid =
                                    getIn(
                                      touched,
                                      'pinCorrespondenceContent'
                                    ) &&
                                    !getIn(errors, 'pinCorrespondenceContent');
                                  const isInvalid = !!(
                                    getIn(
                                      touched,
                                      'pinCorrespondenceContent'
                                    ) &&
                                    getIn(errors, 'pinCorrespondenceContent')
                                  );
                                  const onChange = editorState => {
                                    setFieldValue(
                                      'pinCorrespondenceContent',
                                      convertToRaw(
                                        editorState.getCurrentContent()
                                      )
                                    );
                                  };
                                  const onFocus = () => {};
                                  const onBlur = () => {
                                    setFieldTouched(
                                      'pinCorrespondenceContent',
                                      true
                                    );
                                    formikProps.handleBlur(
                                      'pinCorrespondenceContent'
                                    );
                                  };

                                  return (
                                    <FormGroup>
                                      <CorrespondenceEditor
                                        onInit={this.onInit}
                                        isValid={isValid}
                                        isInvalid={isInvalid}
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        projectId={projectId}
                                        stageName={stageName}
                                        invalidFeedback={getIn(
                                          errors,
                                          'pinCorrespondenceContent'
                                        )}
                                      />
                                    </FormGroup>
                                  );
                                }}
                              </Field>
                            </div>
                          </Col>
                        </Row>

                        <Row className={'p-5 sticky-top'} align="center">
                          <Col sm={6}>
                            <Button
                              color="primary"
                              type="button"
                              block
                              className="mx-1"
                              disabled={formikProps.isSubmitting}
                              onClick={() =>
                                this.openConfirmIfValid(formikProps)
                              }>
                              {t('submit_submission')}
                            </Button>
                          </Col>
                          <Col sm={6}>
                            <Button
                              type="button"
                              color="secondary"
                              block
                              disabled={formikProps.isSubmitting}
                              onClick={() => this.props.history.goBack()}>
                              {t('cancel')}
                            </Button>
                          </Col>
                        </Row>
                        <Modal
                          isOpen={this.state.modal}
                          toggle={this.toggle}
                          className={this.props.className}
                          backdrop="static">
                          <ModalHeader toggle={() => this.toggle()}>
                            {t('confirmation')}
                          </ModalHeader>
                          <ModalBody>
                            <p>{t('confirmation_p1')}</p>
                            <p>{t('confirmation_p2')}</p>
                            <p>{t('confirmation_p3')}</p>
                            <p>{t('confirmation_p4')}</p>
                            <p>{t('confirmation_p5')}</p>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={formikProps.isSubmitting}
                              onClick={formikProps.handleSubmit}>
                              {t('yes')}
                            </Button>{' '}
                            <Button
                              color="secondary"
                              onClick={() => this.toggle()}>
                              {t('no')}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <InfoAlert className="mt-4">
                <Trans i18nKey="submission:sandbox_not_submittable_info">
                  <h4 className="alert-heading">
                    This stage is not available for submission
                  </h4>
                  <p>
                    To open a submission, please create and/or choose another
                    stage.
                  </p>
                </Trans>
              </InfoAlert>
            )}
          </Container>
        )}
      </>
    );
  }
}

export default compose(
  withTranslation(['submission']),
  withRouter
)(SubmissionPresenter);
