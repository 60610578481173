import React, { Component } from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { getIn } from 'formik';
import styles from './styles.module.css';

const CustomInput = ({ children, ...otherProps }) => {
  return (
    <>
      <Input {...otherProps} />
      {children}
    </>
  );
};

class CustomInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isMenuOpen: false,
      objects: [],
      filterValue: ''
    };
  }

  componentDidMount() {
    if (
      this.props.fetchObject &&
      typeof this.props.fetchObject === 'function'
    ) {
      this.props.fetchObject().then(objects => {
        this.setState({
          isLoaded: true,
          objects: objects || []
          // isMenuOpen: this.props.objects.length > 0
        });
      });
    }
  }

  filterObjects = value => {
    this.setState({
      objects:
        this.props.objects && this.props.objects.length > 0
          ? this.props.objects.filter(o => o.toLowerCase().contains(value))
          : []
    });
  };
  resetObjects = () => {
    this.setState({ objects: this.props.objects });
  };

  toggle = () => {
    this.setState(prevState => {
      if (!prevState.isMenuOpen/*  && this.state.filterValue.length > 3 */) {
        return { isMenuOpen: true };
      } else {
        return { isMenuOpen: false };
      }
    });
  };

  onSelect = entry => {
    this.props.onSelectionChange(entry);
  };

  render() {
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      prefix,
      label,
      id,
      noValidation,
      labelClassName,
      fetchObject,
      onFilter,
      onSelectionChange,
      objects,
      placeholder,
      ...otherProps
    } = this.props;
    const { isMenuOpen, isLoaded /*  objects  */ } = this.state;

    const isValid =
      !noValidation && getIn(touched, field.name) && !getIn(errors, field.name);
    const isInvalid =
      !noValidation &&
      !!(getIn(touched, field.name) && getIn(errors, field.name));

    const onChange = e => {
      if (this.props.onChange) {
        this.props.onChange(e);
      }
      this.props.onFilter(e.target.value);
      if (this.props.objects.length > 0) {
        this.setState({ /* filterValue: e.target.value, */ isMenuOpen: true });
      }
    };
    return (
      <FormGroup>
        {!!label && (
          <Label className={labelClassName} for={id || field.name}>
            {label}
          </Label>
        )}

        <Dropdown
          isOpen={isLoaded && isMenuOpen}
          toggle={this.toggle}
          direction="down">
          <InputGroup>
            {!!prefix && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{prefix}</InputGroupText>
              </InputGroupAddon>
            )}
            <DropdownToggle
              tag={CustomInput}
              {...{ ...field, placeholder }}
              ref={elem => (this.inputElem = elem)}
              // onClick={e => {
              //   e.preventDefault();
              //   e.stopPropagation();
              // }}
              onChange={onChange}
              id={id || field.name}
              invalid={isInvalid}
              valid={isValid}
            />
            {getIn(touched, field.name) && getIn(errors, field.name) && (
              <div className="invalid-feedback">
                {getIn(errors, field.name)}
              </div>
            )}

            <DropdownMenu
              className={styles.autoCompleteDropdownWidth}
              modifiers={
                {
                  // setMaxHeight: {
                  //   enabled: true,
                  //   order: 890,
                  //   fn: data => {
                  //     return {
                  //       ...data,
                  //       styles: {
                  //         ...data.styles,
                  //         overflow: 'auto',
                  //         maxHeight: 100
                  //       }
                  //     };
                  //   }
                  // }
                }
              }>
              {objects.map((o, i) => {
                return (
                  <DropdownItem
                    key={`autocomplete-${id || field.name}-${i}`}
                    onClick={() => this.onSelect(o)}>
                    {o}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </InputGroup>
        </Dropdown>
      </FormGroup>
    );
  }
}
export default CustomInputComponent;
