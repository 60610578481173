import React, { useState, useEffect } from 'react';
import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem,
  Input
} from 'reactstrap';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import filter from 'lodash/filter';
import * as Yup from 'yup';

import Switch from '../../UI/Switch';
import Message from '../../UI/Message';

import RuleVisibilty from './RuleVisibilty';
import CompliantMessageLocking from './CompliantMessageLocking/ModeSelection';

import ReportTemplate from './ReportTemplate';

import {
  fetchRules,
  fetchRulesAdmin,
  savePackageRuleParameter
} from '../../../store/actions';

const getChildRules = item => {
  if (item.children) {
    return item.children.reduce((previous, current) => {
      if (current.children) {
        return Array.from(new Set([...previous, ...getChildRules(current)]));
      } else if (current.initialKey) {
        return Array.from(new Set([...previous, current.initialKey]));
      } else {
        return previous;
      }
    }, []);
  } else if (item.initialKey) {
    return [item.initialKey];
  } else {
    return [];
  }
};

const ModalPackageParameter = ({
  open,
  toggle,
  title,
  treeChildren,
  rules,
  savePackageRuleParameter,
  t,
  ruleType,
  level,
  fetchRulesAdmin,
  fetchRules
}) => {
  const featureList = useSelector(state => state.app.featureList);
  const reportTemplateList = useSelector(
    state => state.app.report_template_list
  );
  const isWaiverEnabled = featureList.ruleWaiving;
  const isPrecheckEnabled = featureList.rulePrecheck;

  const allPackageRuleId = treeChildren.reduce((previous, current) => {
    return Array.from(new Set([...previous, ...getChildRules(current)]));
  }, []);

  const allPackageRules = allPackageRuleId.reduce((previous, ruleId) => {
    if (rules && rules[ruleId]) {
      return { ...previous, [ruleId]: { ...rules[ruleId] } };
    } else {
      return previous;
    }
  }, {});

  const rulesShowedInResult = filter(
    allPackageRules,
    rule => rule.params && rule.params.show_in_result !== false
  );
  const rulesPublished = filter(
    allPackageRules,
    rule => rule.params && rule.params.published !== false
  );
  const rulesInPrecheck = filter(
    allPackageRules,
    rule => rule.params && rule.params.in_precheck
  );
  const rulesWaivable = filter(
    allPackageRules,
    rule => rule.params && rule.params.waivable
  );
  //

  const rulesAdminVisibility = filter(
    allPackageRules,
    rule =>
      rule.params && !!rule.params.visibility && !!rule.params.visibility.ADMIN
  );

  const rulesPOVisibility = filter(
    allPackageRules,
    rule =>
      rule.params && !!rule.params.visibility && !!rule.params.visibility.PO
  );

  const rulesQPVisibility = filter(
    allPackageRules,
    rule =>
      rule.params && !!rule.params.visibility && !!rule.params.visibility.QP
  );

  let usersVisibilityResults = [];

  // array of users visibility
  const usersVisibility =
    Object.keys(allPackageRules).length !== 0 &&
    Object.keys(allPackageRules).map(rule => {
      if (
        !!allPackageRules[rule] &&
        !!allPackageRules[rule].params &&
        !!allPackageRules[rule].params.users_visibility &&
        allPackageRules[rule].params.users_visibility.length > 0
      ) {
        usersVisibilityResults.push(
          allPackageRules[rule].params.users_visibility
        );
      } else if (
        !!allPackageRules[rule] &&
        !!allPackageRules[rule].users_visibility &&
        allPackageRules[rule].users_visibility.length > 0
      ) {
        usersVisibilityResults.push(allPackageRules[rule].users_visibility);
      } else {
        usersVisibilityResults.push([]);
      }
    });

  // getting common user visibility
  const allUserVisibility =
    usersVisibilityResults.length > 0 &&
    usersVisibilityResults.shift().reduce(function (res, email) {
      if (
        res.indexOf(email) === -1 &&
        usersVisibilityResults.every(function (emailArray) {
          return emailArray.indexOf(email) !== -1;
        })
      )
        res.push(email);
      return res;
    }, []);

  // for Compliant Message mode
  let compliant_message_mode = '';

  const allCAHideMode = filter(
    allPackageRules,
    rule => rule.params && rule.params.compliant_message_mode === 'HIDE'
  );

  const allCASUMMARYMode = filter(
    allPackageRules,
    rule => rule.params && rule.params.compliant_message_mode === 'SUMMARY'
  );

  const allCADETAILEDMode = filter(
    allPackageRules,
    rule => rule.params && rule.params.compliant_message_mode === 'DETAILED'
  );

  if (allCAHideMode.length === allPackageRuleId.length) {
    compliant_message_mode = 'HIDE';
  } else if (allCASUMMARYMode.length === allPackageRuleId.length) {
    compliant_message_mode = 'SUMMARY';
  } else if (allCADETAILEDMode.length === allPackageRuleId.length) {
    compliant_message_mode = 'DETAILED';
  } else {
    compliant_message_mode = '';
  }

  // For report template

  const availableReportTemplate = reportTemplateList
    .filter(rt => {
      return rt.name === ruleType || rt.custom_template_for === ruleType;
    })
    .map(rt => rt.name)
    .filter(i => i !== 'comparison');

  const rulePerReportTemplate = availableReportTemplate.map(keyType => {
    let filteredRules = filter(allPackageRules, rule => {
      if (
        rule.params &&
        rule.params.report_template &&
        rule.params.report_template.includes(keyType)
      ) {
        return true;
      } else if (!rule.params.report_template) {
        return keyType === ruleType;
      } else {
        return false;
      }
    });

    return {
      name: keyType,
      rules: filteredRules,
      isSelected: filteredRules.length === allPackageRuleId.length
    };
  });

  const initialValues = {
    show_in_result: rulesShowedInResult.length === allPackageRuleId.length,
    published: rulesPublished.length === allPackageRuleId.length,
    in_precheck: rulesInPrecheck.length === allPackageRuleId.length,
    waivable: rulesWaivable.length === allPackageRuleId.length,
    compliant_message_mode: compliant_message_mode,
    visibility: {
      ADMIN: rulesAdminVisibility.length === allPackageRuleId.length,
      PO: rulesPOVisibility.length === allPackageRuleId.length,
      QP: rulesQPVisibility.length === allPackageRuleId.length
    },
    users_visibility: allUserVisibility || [],
    report_template: rulePerReportTemplate
  };

  useEffect(() => {
    setValues(initialValues);
  }, [rules, treeChildren]);

  const onSubmit = async () => {
    setSubmitting(true);
    const data = Object.keys(values).reduce((previous, key) => {
      if (touched[key]) {
        if (key === 'report_template') {
          return {
            ...previous,
            [key]: values[key].filter(rp => rp.isSelected).map(rp => rp.name)
          };
        } else {
          return { ...previous, [key]: values[key] };
        }
      } else {
        return previous;
      }
    }, {});

    try {
      if (
        featureList.isRulePackageV2Enabled &&
        data.report_template &&
        data.report_template.length > 0 &&
        data.report_template.includes('bimdata')
      ) {
        throw Error('error_bimdata_included_on_list');
      } else if (data.report_template && data.report_template.length === 0) {
        throw Error('minimum_report_template_error');
      }
      await savePackageRuleParameter(allPackageRuleId, data).then(() => {
        if (level === 'domain') {
          fetchRulesAdmin({ type: ruleType });
        } else if (level === 'user') {
          fetchRules(ruleType);
        }
      });
      setTouched({});
      Message.success(t('main:saved'));
    } catch (err) {
      console.log(err.stack);
      Message.error(t(`error:${err.message}` || 'error:default_message'));
    } finally {
      setSubmitting(false);
    }
  };

  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  const setVisibility = (role, isSelected) => {
    setValues({
      ...values,
      visibility: {
        ...values.visibility,
        [role]: isSelected
      }
    });
    setTouched({
      ...touched,
      visibility: true
    });
  };

  const { authUser } = useSelector(state => state);

  const emailArrayToString = values.users_visibility
    .toString()
    .replace(/,/g, '; ');

  return (
    <Modal isOpen={open} toggle={toggle} size="xl">
      <>
        {open && (
          <>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
              <Alert color="warning">
                <div className="d-flex ">
                  <div className="mr-2">
                    <i className="fas fa-exclamation-triangle"></i>
                  </div>
                  <div className="flex-grow-1">
                    {t('rules:package_param_warning')}
                  </div>
                </div>
              </Alert>
              <div className="d-flex flex-row">
                <Container>
                  <FormGroup row className="px-3">
                    <Col xs="" className="px-0">
                      <Label className="text-secondary font-weight-bold flex-grow-1">
                        {t('rules:rule_visibility')}
                      </Label>
                      <div className="text-muted">
                        {rulesAdminVisibility.length} /{' '}
                        {allPackageRuleId.length} {t('rules:admin_visible')}
                      </div>
                      <div className="text-muted">
                        {rulesPOVisibility.length} / {allPackageRuleId.length}{' '}
                        {t('rules:po_visible')}
                      </div>
                      <div className="text-muted">
                        {rulesQPVisibility.length} / {allPackageRuleId.length}{' '}
                        {t('rules:qp_visible')}
                      </div>
                    </Col>
                    <Col xs="6" className="">
                      <div className="d-flex justify-content-end py-4">
                        <div>
                          <RuleVisibilty
                            values={values}
                            setVisibility={setVisibility}
                            t={t}
                          />
                        </div>
                      </div>
                    </Col>
                  </FormGroup>

                  {authUser.function === 'ADMIN' && (
                    <>
                      <FormGroup row className="px-3">
                        <Col>
                          <Label className="text-secondary font-weight-bold flex-grow-1">
                            {t('rules:specific_users')}
                          </Label>
                          <Input
                            id="user-visibility-email"
                            type="textarea"
                            name="email"
                            placeholder="Enter email"
                            rows={4}
                            defaultValue={emailArrayToString}
                          />
                          <Button
                            className="mt-1"
                            block
                            size="sm"
                            color="success"
                            onClick={() => {
                              const addItem = document.getElementById(
                                'user-visibility-email'
                              ).value;

                              let emailSchema = Yup.array()
                                .transform((value, original) => {
                                  return original
                                    .split(';')
                                    .map(email => email.trim());
                                })
                                .of(Yup.string().email());

                              const validateEmail = emailSchema
                                .isValid(addItem)
                                .then(result => {
                                  if (result) {
                                    const splitEmails = addItem
                                      .split(';')
                                      .map(email => email.trim().toLowerCase())
                                      .filter(email => !!email);

                                    if (splitEmails.length > 0) {
                                      let uniqueEmails = [
                                        ...new Set([
                                          ...values.users_visibility,
                                          ...splitEmails
                                        ])
                                      ];

                                      setValues({
                                        ...values,
                                        users_visibility: [...uniqueEmails]
                                      });

                                      setTouched({
                                        ...touched,
                                        users_visibility: [...uniqueEmails]
                                      });

                                      document.getElementById(
                                        'user-visibility-email'
                                      ).value = '';
                                    } else {
                                      Message.error(
                                        t('error:please_enter_email')
                                      );
                                    }
                                  } else {
                                    Message.error(t('error:invalid_email'));
                                  }
                                });
                            }}>
                            <i className="fas fa-user-plus fa-lg"></i>
                          </Button>
                          <Alert color="light" className="mt-2">
                            {t('rules:email_input_note')}
                          </Alert>
                        </Col>
                        <Col>
                          <div>
                            {values.users_visibility !== 0 && (
                              <ListGroup className="mt-2">
                                {values.users_visibility.map((email, index) => (
                                  <ListGroupItem
                                    key={`users-visibility-${index}`}
                                    className="m-0 p-0 d-flex justify-content-between">
                                    <div className="p-2">{email}</div>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() => {
                                        const removeItem =
                                          values.users_visibility.splice(
                                            index,
                                            1
                                          );

                                        setValues({
                                          ...values,
                                          users_visibility:
                                            values.users_visibility
                                        });

                                        setTouched({
                                          ...touched,
                                          users_visibility:
                                            values.users_visibility
                                        });
                                      }}>
                                      <i className="fas fa-user-times"></i>
                                    </Button>
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            )}
                          </div>
                        </Col>
                      </FormGroup>

                      <Alert color="info" className="">
                        {t('rules:visibility_settings_note')}
                      </Alert>
                    </>
                  )}

                  <hr />
                  <FormGroup row className="px-3">
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex">
                        <Label className="text-secondary font-weight-bold flex-grow-1">
                          {t('rules:group_show_in_result')}
                        </Label>
                        <Switch
                          checked={values.show_in_result}
                          onChange={val => {
                            setValues({ ...values, show_in_result: val });
                            setTouched({ ...touched, show_in_result: true });
                          }}
                          disabled={isSubmitting}
                          checkedColor="customInfo"
                          // unCheckedColor="var(--danger)"
                          checkedChildren="Show"
                          unCheckedChildren="Hide"
                          className=""
                        />
                      </div>
                      <div className="text-muted">
                        {rulesShowedInResult.length} / {allPackageRuleId.length}{' '}
                        {t('rules:rules_showed_in_result')}
                      </div>
                    </div>
                  </FormGroup>

                  {isPrecheckEnabled && (
                    <>
                      <hr />
                      <FormGroup row className="px-3">
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex">
                            <Label className="text-secondary font-weight-bold flex-grow-1">
                              {t('rules:group_include_to_precheck')}
                            </Label>
                            <Switch
                              checked={values.in_precheck}
                              onChange={val => {
                                setValues({ ...values, in_precheck: val });
                                setTouched({ ...touched, in_precheck: true });
                              }}
                              disabled={isSubmitting}
                              checkedColor="customSuccess"
                              unCheckedColor="customDanger"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className=""
                            />
                          </div>
                          <div className="text-muted">
                            {rulesInPrecheck.length} / {allPackageRuleId.length}{' '}
                            {t('rules:rules_included_to_precheck')}
                          </div>
                        </div>
                      </FormGroup>
                      <hr />
                    </>
                  )}
                  {isWaiverEnabled && (
                    <>
                      <FormGroup row className="px-3">
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex">
                            <Label className="text-secondary font-weight-bold flex-grow-1">
                              {t('rules:group_waivable')}
                            </Label>
                            <Switch
                              checked={values.waivable}
                              onChange={val => {
                                setValues({ ...values, waivable: val });
                                setTouched({ ...touched, waivable: true });
                              }}
                              disabled={isSubmitting}
                              checkedColor="customSuccess"
                              unCheckedColor="customDanger"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className=""
                            />
                          </div>
                          <div className="text-muted">
                            {rulesWaivable.length} / {allPackageRuleId.length}{' '}
                            {t('rules:rules_waivable')}
                          </div>
                        </div>
                      </FormGroup>
                      <hr />
                    </>
                  )}
                  <FormGroup row className="px-3">
                    <Col xs="" className="px-0">
                      <Label className="text-secondary font-weight-bold flex-grow-1">
                        {t('rules:compliant_messages')}
                      </Label>

                      <div className="text-muted">
                        {allCAHideMode.length} / {allPackageRuleId.length}{' '}
                        {t('rules:cm_hidden')}
                      </div>
                      <div className="text-muted">
                        {allCASUMMARYMode.length} / {allPackageRuleId.length}{' '}
                        {t('rules:cm_summarize')}
                      </div>
                      <div className="text-muted">
                        {allCADETAILEDMode.length} / {allPackageRuleId.length}{' '}
                        {t('rules:cm_detailed')}
                      </div>
                    </Col>
                    <Col xs="6" className="">
                      <div className="d-flex justify-content-end py-4">
                        <div>
                          <CompliantMessageLocking
                            values={values}
                            t={t}
                            setTouched={setTouched}
                            touched={touched}
                            setValues={setValues}
                          />
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                  <hr />
                  <FormGroup row className="px-3">
                    <Col xs="" className="px-0">
                      <Label className="text-secondary font-weight-bold flex-grow-1">
                        {t('rule_package_management:set_report_template')}
                      </Label>

                      {rulePerReportTemplate.map(entry => {
                        return (
                          <div className="text-muted">
                            {entry.rules.length} / {allPackageRuleId.length}{' '}
                            {t(`rule_package_management:using_template`, {
                              type: entry.name
                            })}
                          </div>
                        );
                      })}
                    </Col>
                    <Col xs="6" className="">
                      <div className="d-flex justify-content-end py-4">
                        <div>
                          <ReportTemplate
                            values={values}
                            t={t}
                            setTouched={setTouched}
                            touched={touched}
                            setValues={setValues}
                            rulePerReportTemplate={rulePerReportTemplate}
                            allPackageRuleId={allPackageRuleId}
                            ruleType={ruleType}
                          />
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                  <Row>
                    <Button
                      color="success"
                      className="mx-1"
                      disabled={
                        Object.keys(touched).length === 0 || isSubmitting
                      }
                      onClick={onSubmit}
                      id="saveRuleTreeButton">
                      <i className="fas fa-save fs-1-2x" />
                    </Button>
                  </Row>
                </Container>
              </div>
            </ModalBody>
          </>
        )}
      </>
    </Modal>
  );
};

const ModalPackageParameterContainer = props => {
  return <ModalPackageParameter {...props} />;
};

const mapStateToProps = (state, props) => {
  if (props.level === 'domain') {
    return {
      rules: state.rules_admin.rules
    };
  } else if (props.level === 'user') {
    return {
      rules: state.rules.rulesSet
    };
  } else if (props.level === 'organization') {
    // TODO
  }
};

const mapDispatchToProps = (dispatch, props) => {
  const saveDestination = { type: props.level };
  return {
    savePackageRuleParameter: (listRuleId, data) =>
      dispatch(
        savePackageRuleParameter(
          listRuleId,
          props.ruleType,
          data,
          saveDestination
        )
      ),
    fetchRulesAdmin: ({ type, reset }) =>
      dispatch(fetchRulesAdmin({ type, reset })),
    fetchRules: type => fetchRules(type)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ModalPackageParameterContainer
);
