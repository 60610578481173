// temporary store for the model file management
import { performRequest, handleErrorMessageStatus } from '../requests';
import {
  model2DFilesActionType,
  appActionType
} from '../../constants/actionType';

const {
  ADD_2DMODELFILE,
  REPLACE_2DMODELFILE,
  REMOVE_2DMODELFILE,
  RESET_2DMODELFILE,
  DELETE_2DMODELFILE,
  MERGE_ALL_2DMODELFILE,
  SET_MODEL_PLAN_TYPE
} = model2DFilesActionType;

const { UPDATE_FILE_UPLOAD_PROGRESS, SET_IS_UPLOADING_FILE } = appActionType;

export const add2DModelFile = model => (dispatch, getState) => {
  if (getState().model2DFiles.find(m => m.name === model.name)) {
    dispatch({
      type: REPLACE_2DMODELFILE,
      model: {
        ...model,
        progress: 0,
        dirty: true,
        removed: false,
        plan_type: ''
      }
    });
  } else {
    dispatch({
      type: ADD_2DMODELFILE,
      model: {
        ...model,
        progress: 0,
        dirty: true,
        removed: false,
        plan_type: ''
      }
    });
  }
  return;
};

export const addCompleted2DModelFile = model => ({
  type: ADD_2DMODELFILE,
  model: { ...model, progress: true, dirty: false, removed: false }
});

export const replaceModelFile = model => ({
  type: REPLACE_2DMODELFILE,
  model: {
    ...model,
    progress: 0,
    dirty: true,
    removed: false,
    plan_type: model.plan_type || ''
  }
});
export const updateModelfile = model => ({
  type: REPLACE_2DMODELFILE,
  model: {
    ...model,
    progress: 0,
    dirty: true,
    removed: false,
    plan_type: model.plan_type || ''
  }
});

export const remove2DModelFile = model => (dispatch, getState) => {
  dispatch({
    type: REMOVE_2DMODELFILE,
    model
  });
};

export const reset2DModelFile = () => (dispatch, getState) => {
  const state = getState();

  if (!state.app.isUploadingFile) {
    dispatch({
      type: RESET_2DMODELFILE
    });
  }
};

export const setNew2DModelFiles = (newFiles, existingFiles) => dispatch => {
  for (let i in existingFiles) {
    const fileToReplace = existingFiles[i];
    const { file } = fileToReplace;
    // replace in redux store
    dispatch(
      replaceModelFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }
  for (let index in newFiles) {
    const file = newFiles[index];
    // push model to the redux store
    dispatch(
      add2DModelFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }
};

export const update2DModelList =
  (projectId, stageName, filesAdded, filesRemoved, models2dExcludeFile = []) =>
  (dispatch, getState) => {
    let sizeAdded = 0;

    const nbFilesAdded = filesAdded.length || 0;
    const nbFilesRemoved = filesRemoved.length || 0;

    let formData = new FormData();
    for (let i = 0; i < filesAdded.length; i++) {
      formData.append(
        'modelsAdded',
        filesAdded[i].fileInput,
        filesAdded[i]['name']
      );
    }
    formData.append('modelsRemoved', JSON.stringify(filesRemoved));

    formData.append('models2dExcludeFile', JSON.stringify(models2dExcludeFile));

    filesAdded.forEach(file => {
      sizeAdded += file.size;
    });

    filesRemoved.forEach(file => {
      sizeAdded -= file.size;
    });

    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: UPDATE_FILE_UPLOAD_PROGRESS,
          progressValue: percentCompleted
        });
      },

      params: { sizeAdded, nbFilesAdded, nbFilesRemoved }
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/model2D/update`,
        formData,
        requestConfig,
        'UPLOAD_FILE'
      )
    )
      .then(res => {
        if (!res.data.success) {
          throw new Error(res.data.error || 'default_message');
        }

        const models = res.data.data || [];

        const addedModels = models
          .filter(model => {
            return !!model.success && model.operation !== 'delete';
          })
          .map(model => {
            return {
              ...model.modelInfo
            };
          });

        dispatch({
          type: MERGE_ALL_2DMODELFILE,
          models: filesAdded.map(model => {
            const findModel = addedModels.find(resultModel => {
              return resultModel.name === model.name;
            });
            if (findModel) {
              return findModel;
            } else {
              return model;
            }
          })
        });
        dispatch({ type: SET_IS_UPLOADING_FILE, isUploading: false });
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const setPlanType = (modelName, value) => (dispatch, getState) => {
  const model = getState().model2DFiles.find(m => m.name === modelName);

  if (model) {
    dispatch({
      type: SET_MODEL_PLAN_TYPE,
      model: {
        ...model,
        dirty: true,
        plan_type: value
      }
    });
  }
};
