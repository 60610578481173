const { addMonths, addQuarters, addYears, addDays } = require('date-fns');

const currencyCodeToSign = {
  SGD: '&dollar;',
  USD: '&dollar;',
  EUR: '&euro',
  GBP: '&pound;'
};

const defaultFeatureList = {
  freePlan: [
    'Access to all FORNAX Cloud features subject to available Computing Units (CU) and Data Access (DA) where applicable',
    'DA included is 50MB',
    'CU is not included in this plan except for one time Bonus CU added upon first time registration',
    'CU can be added by selecting one of the Add CU options below',
    'Refer to Help Centre for DA is available complete details and terms of use'
  ],
  dcp: [
    'Access to all FORNAX Cloud features subject to available Computing Units (CU) and Data Access (DA) where applicable',
    'DA and CU combination are included according to available options',
    'Entitled monthly CU are added at the beginning of each month’s subscription billing cycle',
    'CU can be added by selecting one of the Add CU options below',
    'Refer to Help Centre for complete details and terms of use'
  ],
  dap: [
    'Access to all FORNAX Cloud features subject to available Computing Units (CU) and Data Access (DA) where applicable',
    'DA is included according to available options',
    'CU in not included in this plan',
    'CU can be added by selecting one of the Add CU options below',
    'Refer to Help Centre for complete details and terms of use',
  ],
  cu_addon: [
    'Add CU one time',
  ]
};

const defaultCurrencyCode = 'SGD';

module.exports = {
  currencyCodeToSign,
  defaultFeatureList,
  defaultCurrencyCode
};
