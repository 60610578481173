import { connect } from 'react-redux';
import {
  editUserProfile,
  fetchUserFunction,
  getUserUsageAction,
  fetchUserGroups,
  fetchProfession
} from '../../../store/actions/';
import EditProfile from './EditProfile';

const mapStateToProps = state => {
  return {
    userFunction: state.userFunction,
    authUser: state.authUser,
    isAccessControlV2Enabled: state.app.featureList.accessRightV2,
    isThirdPartyModeEnabled: state.app.featureList.thirdPartyMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editUserProfile: (userId, updatedUserProfile) =>
      dispatch(editUserProfile(userId, updatedUserProfile)),
    fetchUserFunction: () => dispatch(fetchUserFunction()),
    getUserUsage: (userId, isAuthUser) =>
      dispatch(getUserUsageAction(userId, isAuthUser)),
    fetchUserGroups: userId => dispatch(fetchUserGroups(userId)),
    fetchProfession: () => dispatch(fetchProfession())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
