import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import 'rc-tree/assets/index.css';
import './Tree.css';

// follow
class TreeComponent extends Component {
  constructor(props) {
    super(props);
    this.treeRef = React.createRef();
    this.state = {
      expandedKeys: props.expandedKeys || [],
      autoExpandParent: false,
      treeData: []
    };
  }
  static defaultProps = {
    visible: false,
    multiple: true
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expandedKeys !== this.props.expandedKeys) {
      this.setState({ expandedKeys: this.props.expandedKeys });
    }
  }

  onExpand = expandedKeys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded chilren keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    const gData = this.props.data;

    const renderSwitcherIcon = ({ isLeaf, expanded, loading }) => {
      if (isLeaf) {
        return null;
      }
      return (
        <div>
          <i
            className={`fas fa-angle-right  ${expanded ? 'fa-rotate-90' : ''}`}
          />
        </div>
      );
    };

    const loop = data => {
      return data.map(item => {
        if (item.children) {
          return (
            <TreeNode
              key={item.key}
              className={item.show === false ? 'd-none' : 'has-children'}
              title={item.title}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        if (
          this.props.ruleErrorMapping &&
          this.props.ruleErrorMapping[item.ruleId]
        ) {
          const title = (
            <span>
              <span>{item.title}</span>
              <span id={`warning-${item.ruleId}`} className="ml-1">
                <i className="fas fa-exclamation-circle text-warning"></i>
              </span>
              <UncontrolledTooltip
                placement="right"
                target={`warning-${item.ruleId}`}>
                {this.props.t('autochecker:rule_not_passing_validation')}
              </UncontrolledTooltip>
            </span>
          );
          return (
            <TreeNode
              key={item.key}
              title={title}
              className={item.show === false ? 'd-none' : ''}
              description={item.descriptionLink}
              ruleId={item.ruleId}
              level={item.level}
            />
          );
        } else {
          return (
            <TreeNode
              key={item.key}
              title={item.title}
              className={item.show === false ? 'd-none' : ''}
              description={item.descriptionLink}
              ruleId={item.ruleId}
              level={item.level}
            />
          );
        }
      });
    };

    return (
      <Tree
        ref={this.treeRef}
        checkable={<span className="custom-tree-checkbox-inner" />}
        prefixCls="custom-tree"
        switcherIcon={renderSwitcherIcon}
        defaultExpandAll={true}
        onCheck={this.props.onChecked}
        checkedKeys={this.props.checkedKeys}>
        {loop(gData)}
      </Tree>
    );
  }
}

TreeComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  onChecked: PropTypes.func.isRequired,
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  checkedKeys: PropTypes.arrayOf(PropTypes.string)
};

export default TreeComponent;
