import { adminConfigurationActionType } from '../../constants/actionType';
const {
  FETCH_CONFIG_SETTINGS,
  SET_CONFIG_SETTINGS
} = adminConfigurationActionType;
const initialState = {};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIG_SETTINGS: {
      const { configList } = action;
      return { ...configList };
    }
    case SET_CONFIG_SETTINGS: {
      const { configList } = action;
      return { ...state, ...configList };
    }

    default:
      return state;
  }
};

export default reducer;
