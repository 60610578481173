import { projectPreferenceActionType } from '../../constants/actionType';
const initialState = {};

const { MERGE_PROJECT_PREFERENCE } = projectPreferenceActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case MERGE_PROJECT_PREFERENCE:
    return { ...action.project_preference_data };
  default:
    return state;
  }
};

export default reducer;
