import { userFunctionActionType } from '../../constants/actionType';
import { performRequest } from '../requests';

export const fetchUserFunction = () => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: userFunctionActionType.RESET_USER_FUNCTION });
  return dispatch(
    performRequest('get', '/api/roles/user', {}, requestConfig, 'ROLES')
  )
    .then(res => res.data)
    .then(roles => {
      dispatch({ type: userFunctionActionType.MERGE_USER_FUNCTION, roles });
    });
};
