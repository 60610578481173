import React, { Component, createRef } from 'react';
import { uniqBy, xorBy } from 'lodash';
import prettyBytes from 'pretty-bytes';
import {
  Col,
  Toast,
  ToastBody,
  ToastHeader,
  Button,
  Row,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip,
  Table,
  Input,
  Container
} from 'reactstrap';
import FileBrowser from 'react-keyed-file-browser';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import Message from '../../../UI/Message';
import pathModule from 'path';
import Dropzone from 'react-dropzone';
import CustomFileRenderer from './CustomFileRenderer';
import CustomFolderRenderer from './CustomFolderRenderer';
import Details from './Details';
import DocumentsListing from './DocumentsListing';
import styles from './fileExplorer.module.css';

const util = require('util');

const origin_of_the_file_check = [
  'submission',
  'issue',
  'task',
  'stage',
  'model'
];

const message = {
  handleCreateFolder: 'create this folder',
  handleOnMoveFile: 'move the following folder/files(s)',
  referenceDocumentPasteAction: 'merge the following folder/files(s)'
};

class CustomHeaderRenderer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.browserProps.selection !== this.props.browserProps.selection
    ) {
      if (this.props.browserProps.selection === null) {
        // this.props.setActiveDirectoryToUpload('');
        // this.props.createBreadCrumbItem([]);
        // this.props.setIsUserUpload();
        // this.props.setContextMenu('', false);
      }
    }
  }
  componentDidMount() {
    // if (!this.props.browserProps.selection) {
    //   this.props.setActiveDirectoryToUpload('');
    // }
  }
  render() {
    return (
      <tr>
        <th className="text-center"></th>
        <th className="text-center">File</th>

        <th className="size">
          <div className={styles.marginLeft190}>Size</div>
        </th>

        <th className="modified">Last Modified</th>
      </tr>
    );
  }
}

class FileExplorer extends Component {
  constructor(props) {
    super(props);
    this.contextMenuRef = createRef();
    this.state = {
      files: [],
      isLoaded: '',
      visible: false,
      activeItemId: '',
      activeItemdownload_link: '',
      activeFilePathToDelete: '',
      contextMenuIsVisible: false,
      contextMenuStyle: {
        is_visible: false,
        style: {
          display: 'block',
          width: '200px',
          position: 'absolute',
          left: 200,
          top: 200
        }
      },
      activeItemToDisplay: {},
      activeItemToPaste: {},
      activeFolderPathToPasteIn: {},
      activeOriginOfDocument: 'stage',
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false.valueOf,
      reference_documents_to_push: [],
      showTextDescription: false,
      displayItemDetails: false,
      checkedItems: [],
      parentFolderToBeChecked: [],
      is_copy_or_cut: false,
      final_selected_documents: [],
      focus_stage: '',
      replaceCustom: {
        origin: 'client',
        key: '',
        pathToUpload: ''
      },
      docListToConfirm: [],
      active_operation: 'none',
      shouldValidateConflicts: true
    };
    this.gen = null;

    this.react_keyed_file_browser = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;

    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    if (stageName) {
      this.props.history.replace(
        `/project/${projectId}/documents/${stageName}/`
      );
      const focus_stage_details = this.props.reference_documents.find(
        i => i.base_url_to_display_in_file_explorer === `${stageName}/`
      );

      this.setState({ selectedKey: `Documents/${this.props.stageName}/` });
      // add this to have default details view
      this.props.reference_documents.length > 0
        ? this.toggledisplayItemDetails(true, focus_stage_details)
        : this.toggledisplayItemDetails(false, []);
    }

    if (this.state.contextMenuStyle.is_visible) {
      const contextMenuInlineStyle = this.state.contextMenuStyle.style;
      Object.keys(contextMenuInlineStyle).forEach(res => {
        this.contextMenuRef.current.style[res] = contextMenuInlineStyle[res];
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeItem !== this.props.activeItem) {
      this.setActiveItem(this.props.activeItem);
    }
    if (this.props.stageName && prevProps.stageName !== this.props.stageName) {
      this.props.history.replace(
        `/project/${this.props.projectId}/documents/${this.props.stageName}/`
      );
      const focus_stage_details = this.props.reference_documents.find(
        i =>
          i.base_url_to_display_in_file_explorer === `${this.props.stageName}/`
      );
      this.setState({ selectedKey: `Documents/${this.props.stageName}/` });

      // add this to have default details view
      this.props.reference_documents.length > 0
        ? this.toggledisplayItemDetails(true, focus_stage_details)
        : this.toggledisplayItemDetails(false, []);
    }
    if (
      (prevState.checkedItems && prevState.checkedItems.length) !==
      this.state.checkedItems.length
    ) {
      const list_of_parent = this.state.checkedItems.map(
        i => i.base_url_to_display_in_file_explorer
      );

      // this will select the root folder in case all file/folder inside the selected folder
      const filtered = this.state.checkedItems.filter(i => {
        const to_checked = `${pathModule.dirname(
          i.base_url_to_display_in_file_explorer
        )}/`;

        return !list_of_parent.includes(to_checked);
      });

      this.setState({
        final_selected_documents: filtered
      });
    }

    if (
      this.state.contextMenuStyle.is_visible &&
      prevState.contextMenuStyle !== this.state.contextMenuStyle
    ) {
      const contextMenuInlineStyle = this.state.contextMenuStyle.style;
      Object.keys(contextMenuInlineStyle).forEach(res => {
        this.contextMenuRef.current.style[res] = contextMenuInlineStyle[res];
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCancel = () => {
    this.setState({
      files: []
    });
  };

  closeModal = async (fromReplace = false) => {
    this.setState({
      modalOpen: false,
      shouldValidateConflicts: true
    });
    if (!fromReplace) {
      await this.gen.next();
    }
  };

  openModal = (file, index, id, op) => {
    if (this._isMounted) {
      this.setState(() => {
        return {
          modalOpen: true,
          fileToConfirm: {
            file,
            index,
            id
          },
          active_operation: op
        };
      });
    }
  };

  acceptFileReplace = () => {
    if (this._isMounted) {
      this.setState(
        prevState => ({
          fileToConfirm: {},
          filesToReplace: [...prevState.filesToReplace, prevState.fileToConfirm]
        }),
        () => this.closeModal()
      );
    }
  };

  // Generator for the modal, so once we close one the system open the next one
  modalOpeningGenerator = async function* (
    filesToPush,
    filesInProcessToReplace
  ) {
    for (let i in filesInProcessToReplace) {
      const { file, index, id } = filesInProcessToReplace[i];
      yield this.openModal(file, index, id);
    }
    // Once the last modal is closed we replace the formik values with the confirmed files
    const { filesToReplace } = this.state;

    for (let i in filesToReplace) {
      const fileToReplace = filesToReplace[i];
      const { file, index } = fileToReplace;
    }

    for (let index in filesToPush) {
      const file = filesToPush[index];
    }

    this.props.referenceDocumentActions.setNewReferenceDocumentFiles(
      filesToPush,
      filesToReplace
    );
  };
  setFormikValue = async (files, path) => {
    let destination;
    if (util.isString(path)) {
      destination = path;
      this.props.setActiveDirectoryToUpload(path);
    }

    destination = destination
      ? destination
      : this.props.activeDirectoryToUpload;
    const { filesToPush, filesInProcessToReplace } = files.reduce(
      (returnedValue, file) => {
        const filename = file.name;

        const indexFile = this.props.reference_documents.findIndex(
          document =>
            document.base_url_to_display_in_file_explorer ===
            `${destination}${filename}`
        );

        if (indexFile !== -1) {
          returnedValue.filesInProcessToReplace.push({
            file,
            index: indexFile,
            id: this.props.reference_documents[indexFile].id
          });
        } else if (indexFile === -1) {
          const index = this.props.reference_documents.findIndex(
            document => document.dirty && document.name === filename
          );
          if (index === -1) {
            returnedValue.filesToPush.push(file);
          } else {
            returnedValue.filesInProcessToReplace.push({
              file,
              index: index,
              id: this.props.reference_documents[index].id
            });
          }
        }
        return returnedValue;
      },
      { filesToPush: [], filesInProcessToReplace: [] }
    );

    this.setState({ filesToPush });

    this.gen = this.modalOpeningGenerator(filesToPush, filesInProcessToReplace);
    this.gen.next();
  };

  referenceDocumentPasteAction = async () => {
    const {
      activeItemToPaste,
      activeFolderPathToPasteIn,
      activeItemToDisplay,
      checkedItems,
      final_selected_documents,
      shouldValidateConflicts
    } = this.state;
    const projectId = this.props.match.params.id;

    const {
      multipleItemActions,
      referenceDocumentPasteAction,
      dispatchAllUploads
    } = this.props.referenceDocumentActions;

    const check = this.is_direction_valid_to_do_operation(
      activeFolderPathToPasteIn.base_url_to_display_in_file_explorer
    );
    if (check === false) {
      Message.error(this.props.t('reference_documents:not_allowed'));
      return false;
    }

    if (final_selected_documents.length > 0) {
      await multipleItemActions({
        projectId,
        items: final_selected_documents,
        operation: 'copy',
        destination: activeFolderPathToPasteIn,
        shouldValidateConflicts
      })
        .then(() => {
          this.setCheckedItems();
          this.props.setisActionDirectlyFromFileExplorer(true);
          this.props.getDocumentSize();
          Message.success(
            this.props.t('reference_documents:copied_successfuly')
          );
        })
        .catch(err => {
          if (err.message === 'file_path_exists') {
            this.setState(
              {
                replaceCustom: {
                  origin: 'server',
                  key: false,
                  pathToUpload: false
                },
                docListToConfirm: err.documents
              },
              () => {
                this.openModal(
                  {
                    name: false
                  },
                  '',
                  '',
                  'referenceDocumentPasteAction'
                );
              }
            );
          } else {
            Message.error(this.props.t(`error:${err.message}`));
          }
        });
    } else {
      await referenceDocumentPasteAction(
        this.props.match.params.id,
        activeItemToPaste,
        activeFolderPathToPasteIn,
        shouldValidateConflicts
      )
        .then(() => {
          this.setCheckedItems();
          this.props.setisActionDirectlyFromFileExplorer(true);
          this.props.getDocumentSize();

          Message.success(
            this.props.t('reference_documents:copied_successfuly')
          );
        })
        .catch(err => {
          if (err.message === 'file_path_exists') {
            this.setState(
              {
                replaceCustom: {
                  origin: 'server',
                  key: false,
                  pathToUpload: false
                },
                docListToConfirm: err.documents
              },
              () => {
                this.openModal(
                  {
                    name: false
                  },
                  '',
                  '',
                  'referenceDocumentPasteAction'
                );
              }
            );
          } else {
            Message.error(this.props.t(`error:${err.message}`));
          }
        });
    }
  };

  handleCreateFolder = (key, id, path, replaceValidation) => {
    const { addFolderAction } = this.props.referenceDocumentActions;
    const projectId = this.props.match.params.id;
    const { activeItemToDisplay, activeOriginOfDocument } = this.state;
    let pathToUpload = activeItemToDisplay || path;

    if (activeOriginOfDocument === 'user_upload') {
      pathToUpload = {};
    }

    return addFolderAction(projectId, key, pathToUpload, replaceValidation)
      .then(res => {
        this.props.setisActionDirectlyFromFileExplorer(true);
        this.props.createBreadCrumbItem(key);
        Message.success(
          this.props.t('reference_documents:folder_created_successfully')
        );
        this.closeModal(true);
      })
      .catch(err => {
        if (err.message === 'file_path_exists') {
          this.setState(
            {
              replaceCustom: {
                origin: 'server',
                key,
                pathToUpload
              },
              docListToConfirm: err.documents
            },
            () => {
              this.openModal(
                {
                  name: false
                },
                '',
                '',
                'handleCreateFolder'
              );
            }
          );
        } else {
          Message.error(this.props.t(`error:${err.message}`));
        }
      });
  };

  handleRenameFileOrFolder = (oldName, newName) => {
    const { renameReferenceDocument } = this.props.referenceDocumentActions;
    const projectId = this.props.match.params.id;
    const { isFolder } = this.state;
    this.setState({ isLoaded: false });
    const { activeItemId } = this.state;
    const id = activeItemId;
    renameReferenceDocument(projectId, oldName, newName, isFolder, id)
      .then(result => {
        this.setState({ isLoaded: true });
        this.props.setisActionDirectlyFromFileExplorer(true);
        this.toggledisplayItemDetails(true, result[0]);
        Message.success(
          this.props.t('reference_documents:rename_successfully')
        );
      })
      .catch(err => {
        Message.error(this.props.t(`error:${err.message}`));
      });
  };
  handleDeleteFileOrFolder = fileKey => {
    const { removeReferenceDocument, multipleItemActions } =
      this.props.referenceDocumentActions;
    const projectId = this.props.match.params.id;
    const {
      activeItemId,
      activeFilePathToDelete,
      isFolder,
      checkedItems,
      final_selected_documents
    } = this.state;

    if (final_selected_documents.length > 0) {
      multipleItemActions({
        projectId,
        items: final_selected_documents,
        operation: 'delete'
      })
        .then(() => {
          this.setCheckedItems();

          Message.success(
            this.props.t('reference_documents:deleted_successfully')
          );
        })
        .catch(err => {
          Message.error(this.props.t(`error:${err.message}`));
        })
        .finally(() => {
          this.props.getDocumentSize();
        });
    } else {
      removeReferenceDocument(
        projectId,
        activeItemId,
        activeFilePathToDelete,
        isFolder
      )
        .then(() => {
          this.setCheckedItems();
          this.props.setisActionDirectlyFromFileExplorer(true);
          const fileKeyInString = fileKey.toString();
          const newPath = fileKeyInString.split('/').slice(0, -2).join('/');
          this.props.setisActionDirectlyFromFileExplorer(newPath);
          this.props.setActiveDirectoryToUpload(newPath);
          this.props.createBreadCrumbItem(newPath);
          this.toggledisplayItemDetails(false);
          Message.success(
            this.props.t('reference_documents:deleted_successfully')
          );
        })
        .catch(err => {
          Message.error(this.props.t(`error:${err.message}`));
        })
        .finally(() => {
          this.props.getDocumentSize();
        });
    }
  };

  setActiveItem = item => {
    if (!item) {
      this.setState({ activeItemToDisplay: item });
      this.setIsUserUpload();
      // this.setContextMenu('', true);
    } else {
      this.setState({
        activeItemId: item.id,
        activeItemdownload_link: item.download_link,
        activeFilePathToDelete: item.base_url_to_display_in_file_explorer,
        isFolder: item.isFolder,
        activeItemToDisplay: item,
        activeOriginOfDocument: item.origin_of_the_file
      });
      this.props.setSelectedKey(`Documents/${item.key}`);
    }
    this.props.setActiveItem(item);
  };

  handleDownloadAction = e => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_API_ENDPOINT}/${this.state.activeItemdownload_link}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  EmptyRenderer = () => {
    return <div></div>;
  };

  is_direction_valid_to_do_operation = destination => {
    const type = this.props.reference_documents.find(
      document => document.base_url_to_display_in_file_explorer === destination
    ).origin_of_the_file;

    const check = origin_of_the_file_check.some(val => val === type);

    return !check; // if false then it is valid to do operation
  };
  handleOnMoveFile = (itemToMove, destination, props) => {
    const { multipleItemActions, moveReferenceDocument, dispatchAllUploads } =
      this.props.referenceDocumentActions;

    const projectId = this.props.match.params.id;
    const {
      isFolder,
      activeItemId,
      checkedItems,
      activeFolderPathToPasteIn,
      final_selected_documents,
      activeItemToPaste,
      shouldValidateConflicts
    } = this.state;

    const id = activeItemId;

    let check;
    let active_documents_to_paste = activeItemToPaste;
    let active_documents_to_paste_in = activeFolderPathToPasteIn;

    if (Object.keys(activeFolderPathToPasteIn).length > 0) {
      check = this.is_direction_valid_to_do_operation(
        activeFolderPathToPasteIn.base_url_to_display_in_file_explorer
      );
    } else {
      let formatted_path = pathModule.normalize(
        `${pathModule.dirname(destination)}/`
      );
      check = this.is_direction_valid_to_do_operation(formatted_path);
      active_documents_to_paste = this.props.reference_documents.find(
        document => document.base_url_to_display_in_file_explorer === itemToMove
      );

      active_documents_to_paste_in = this.props.reference_documents.find(
        document =>
          document.base_url_to_display_in_file_explorer === formatted_path
      );
    }

    if (check === false) {
      Message.error(this.props.t('reference_documents:not_allowed'));
      return false;
    }

    if (final_selected_documents.length > 0) {
      multipleItemActions({
        projectId,
        items: final_selected_documents,
        operation: 'cut',
        destination: activeFolderPathToPasteIn,
        shouldValidateConflicts
      })
        .then(() => {
          this.setCheckedItems();
          this.props.setisActionDirectlyFromFileExplorer(true);
          this.props.getDocumentSize();
          Message.success(
            this.props.t('reference_documents:move_successfully')
          );
        })
        .catch(err => {
          if (err.message === 'file_path_exists') {
            this.setState(
              {
                replaceCustom: {
                  origin: 'server',
                  key: false,
                  pathToUpload: false
                },
                docListToConfirm: err.documents
              },
              () => {
                this.openModal(
                  {
                    name: false
                  },
                  '',
                  '',
                  'handleOnMoveFile'
                );
              }
            );
          } else {
            Message.error(this.props.t(`error:${err.message}`));
          }
        });
    } else {
      multipleItemActions({
        projectId,
        items: [active_documents_to_paste],
        operation: 'cut',
        destination: active_documents_to_paste_in,
        shouldValidateConflicts
      })
        .then(() => {
          this.setCheckedItems();
          this.props.setisActionDirectlyFromFileExplorer(true);
          this.props.getDocumentSize();

          Message.success(
            this.props.t('reference_documents:move_successfully')
          );
        })
        .catch(err => {
          if (err.message === 'file_path_exists') {
            this.setState(
              {
                replaceCustom: {
                  origin: 'server'
                },
                docListToConfirm: err.documents
              },
              () => {
                this.openModal(
                  {
                    name: false
                  },
                  '',
                  '',
                  'handleOnMoveFile'
                );
              }
            );
          } else {
            Message.error(this.props.t(`error:${err.message}`));
          }
        });
    }
  };

  handleCreateFiles = (files, path) => {
    const check = this.is_direction_valid_to_do_operation(path);
    if (check === false) {
      Message.error(this.props.t('reference_documents:not_allowed'));
      return false;
    }
    this.setFormikValue(files, path);
  };

  setContextMenu = (
    position,
    disable = false,
    activeItem = this.state.activeItemToDisplay
  ) => {
    if (disable) {
      this.setState({
        contextMenuStyle: {
          ...this.state.contextMenuStyle,
          is_visible: false
        }
      });
    } else {
      this.setState(
        {
          contextMenuStyle: {
            ...this.state.contextMenuStyle,
            style: {
              ...this.state.contextMenuStyle.style,
              left: position.left,
              top: position.top
            },
            is_visible:
              activeItem.origin_of_the_file === 'user_upload'
                ? !this.state.contextMenuStyle.is_visible
                : false
          },
          activeItemToDisplay: activeItem
        },
        () => {}
      );
    }
  };

  setIsUserUpload = () => {
    this.setState({ activeOriginOfDocument: 'user_upload' });
  };

  setActiveItemToDisplay = () => {
    this.setState({ activeItemToDisplay: {} });
  };

  toggledisplayItemDetails = (value, item) => {
    this.setState({
      displayItemDetails: value,
      activeItemToDisplay: item
    });
  };
  handleSaveDescription = (documentId, value) => {
    const { addDescriptionOnReferenceDocuments } =
      this.props.referenceDocumentActions;
    addDescriptionOnReferenceDocuments(
      this.props.match.params.id,
      documentId,
      value
    )
      .then(result => {
        this.toogleTextDescription(false);
        if (result[0]) {
          this.setState({
            activeItemToDisplay: {
              ...this.state.activeItemToDisplay,
              ...result[0]
            }
          });
          Message.success(
            this.props.t('reference_documents:added_description_successfully')
          );
        }
      })
      .catch(err => {
        Message.error(this.props.t(`error:${err.message}`));
      });
  };

  toogleTextDescription = status => {
    this.setState({
      showTextDescription: status
    });
  };

  toogleParentFolder = item => {
    const list_of_parent_folder =
      item.base_url_to_display_in_file_explorer.split('/');

    const list = list_of_parent_folder
      .filter(
        name =>
          name !==
            pathModule.basename(item.base_url_to_display_in_file_explorer) &&
          name
      )
      .reduce((acc, currentValue, index, arr) => {
        const concat = acc.length > 0 ? `${acc[index - 1]}/` : '';
        const path = pathModule.normalize(`${concat}${currentValue}/`);
        return [...acc, path];
      }, []);

    return list;
  };
  collectCheckedItems = item => {
    if (item.isChecked && item.isFolder === false) {
      this.setState(
        {
          checkedItems: uniqBy(this.state.checkedItems.concat(item), 'id')
        },
        () => {}
      );
    } else if (item.isChecked === false && item.isFolder === false) {
      // for folder

      this.setState(
        {
          checkedItems: this.state.checkedItems.filter(
            i =>
              i.id !== item.id &&
              !this.toogleParentFolder(item).includes(
                i.base_url_to_display_in_file_explorer
              )
          )
        },
        () => {}
      );
    }

    // let newItems = this.state.checkedItems;

    // this.setState(
    //   {
    //     checkedItems: newItems
    //   },
    //   () => {
    //     const merge = uniqBy(
    //       [...this.state.checkedItems, ...this.state.checkedItems],
    //       'id'
    //     );
    //     this.setState(
    //       {
    //         checkedItems: merge
    //       },
    //       () => {}
    //     );
    //   }
    // );
  };

  collectcheckedItems = (
    item,
    isChecked,
    base_url_to_display_in_file_explorer
  ) => {
    // for folder
    const searchPattern = new RegExp(
      '^' + base_url_to_display_in_file_explorer + '+'
    );

    const new_arr = this.props.referenceDocuments.filter(e => {
      return searchPattern.test(e.base_url_to_display_in_file_explorer);
    });

    // .filter(
    //   i =>
    //     i.base_url_to_display_in_file_explorer !==
    //     base_url_to_display_in_file_explorer
    // );

    if (isChecked) {
      this.setState({
        checkedItems: uniqBy(this.state.checkedItems.concat(...new_arr), 'id')
      });
    } else {
      // will exclude item that from item and inside of the checkedItems

      // this function will exluce all parent folder of unselected checkbox
      const filtered_arr = this.state.checkedItems.filter(curr => {
        return !this.toogleParentFolder(item).includes(
          curr.base_url_to_display_in_file_explorer
        );
      });

      const filtered = xorBy(filtered_arr, new_arr, 'id');

      this.setState({
        checkedItems: filtered
      });
    }
  };

  setCheckedItems = () => {
    this.setState({
      checkedItems: []
    });
  };

  handleDragOver = event => {
    event.stopPropagation();
  };

  render() {
    const {
      uploadedDocuments,
      toggledisplayItemDetails,
      referenceDocumentActions,
      setActiveDirectoryToUpload,
      isLoaded,
      createBreadCrumbItem,
      setisActionDirectlyFromFileExplorer,
      referenceDocuments,
      t,
      activeItemToDisplay,
      fileUploadProgresses,
      totalDocumentSize,
      config_limit_size
    } = this.props;

    const excludeRootDocumentTree = uploadedDocuments.filter(
      d => !d.is_document_root_tree
    );

    const filteredDocuments = referenceDocuments.filter(document => {
      return !document.removed && document.dirty;
    });

    const { contextMenuIsVisible, contextMenuStyle, activeOriginOfDocument } =
      this.state;
    const isUserUpload = this.state.activeOriginOfDocument === 'user_upload';

    let disabled = false;
    disabled = origin_of_the_file_check.includes(activeOriginOfDocument);

    const size_remaining = parseInt(config_limit_size - totalDocumentSize);

    const progress_file_size = (totalDocumentSize * 100) / config_limit_size;

    return (
      <Container>
        <Row onDragOver={this.handleDragOver}>
          <Col xs={12} sm={8} className={styles.indexBorderRight}>
            <div className={styles.widthOverflow}>
              <FileBrowser
                ref={this.react_keyed_file_browser}
                files={excludeRootDocumentTree.map(obj => {
                  const filteredName =
                    obj.name.length > 30
                      ? `${obj.name.slice(0, 30)}...`
                      : obj.name;
                  return {
                    key: `${obj.base_url_to_display_in_file_explorer}`,
                    id: obj.id,
                    stage_name: obj.stage_name,
                    modified: obj.date_updated || obj.date_uploaded,
                    size: obj.size || 0,
                    download_link: obj.download_link,
                    date_uploaded: obj.date_uploaded,
                    path: obj.path,
                    // name: filteredName,
                    isFolder: obj.isfolder,
                    base_url_to_display_in_file_explorer:
                      obj.base_url_to_display_in_file_explorer,
                    origin_of_the_file: obj.origin_of_the_file,
                    date_updated: obj.date_updated,
                    description: obj.description,
                    stage_name: obj.stage_name,
                    allowedActions: obj.allowedActions
                  };
                })}
                icons={{
                  File: (
                    <span className="px-2">
                      <i className="far fa-file text-secondary" />
                    </span>
                  ),
                  Text: (
                    <span className="px-2">
                      <i className="far fa-file-alt text-secondary" />
                    </span>
                  ),
                  Word: (
                    <span className="px-2">
                      <i className="far fa-file-word text-secondary" />
                    </span>
                  ),
                  PowerPoint: (
                    <span className="px-2">
                      <i className="far fa-file-powerpoint text-secondary" />
                    </span>
                  ),
                  Image: (
                    <span className="px-2">
                      <i className="far fa-file-image text-secondary" />
                    </span>
                  ),
                  PDF: (
                    <span className="px-2">
                      <i className="far fa-file-pdf text-secondary" />
                    </span>
                  ),
                  Rename: (
                    <span className="px-2">
                      <i className="far fa-edit text-info" />
                    </span>
                  ),
                  Folder: (
                    <span className="px-2">
                      <i className="far fa-folder text-secondary" />
                    </span>
                  ),
                  FolderOpen: (
                    <i className="px-2">
                      <i className="far fa-folder-open"></i>
                    </i>
                  ),
                  Download: (
                    <span className="px-2">
                      <i className="fa fa-file-download text-success" />
                    </span>
                  ),
                  Delete: (
                    <span className="px-2">
                      <i className="far fa-trash-alt text-danger" />
                    </span>
                  )
                }}
                onCreateFolder={isUserUpload ? this.handleCreateFolder : false}
                onRenameFolder={
                  isUserUpload ? this.handleRenameFileOrFolder : false
                }
                onRenameFile={
                  isUserUpload ? this.handleRenameFileOrFolder : false
                }
                onDownloadFile={e => {
                  this.handleDownloadAction(e);
                }}
                onSelectFolder={item => {
                  if (item) {
                    this.toggledisplayItemDetails(true, item);
                    setActiveDirectoryToUpload(item.key);
                    this.setActiveItem(item);
                    createBreadCrumbItem(
                      item.base_url_to_display_in_file_explorer
                    );
                    this.props.toogleFocusState(
                      item.base_url_to_display_in_file_explorer
                    );
                  }
                }}
                onSelectFile={item => {
                  if (item) {
                    this.toggledisplayItemDetails(true, item);
                    this.setActiveItem(item);
                    createBreadCrumbItem(
                      item.base_url_to_display_in_file_explorer
                    );
                    this.props.toogleFocusState(
                      item.base_url_to_display_in_file_explorer
                    );
                  }
                }}
                onSelect={item => {
                  if (item) {
                    this.setActiveItem(item);

                    this.setContextMenu('', true);
                    this.props.toogleFocusState(
                      item.base_url_to_display_in_file_explorer
                    );
                  }
                }}
                detailRenderer={this.EmptyRenderer}
                onDeleteFolder={
                  isUserUpload ? this.handleDeleteFileOrFolder : false
                }
                onDeleteFile={
                  isUserUpload ? this.handleDeleteFileOrFolder : false
                }
                onMoveFile={isUserUpload ? this.handleOnMoveFile : false}
                onMoveFolder={isUserUpload ? this.handleOnMoveFile : false}
                // showActionBar={this.state.activeOriginOfDocument === 'user_upload'}
                fileRenderer={CustomFileRenderer}
                fileRendererProps={{
                  setContextMenu: this.setContextMenu,
                  setActiveItemToDisplay: this.setActiveItemToDisplay,
                  collectCheckedItems: this.collectCheckedItems,
                  checkedItems: this.state.checkedItems,
                  collectcheckedItems: this.collectcheckedItems,
                  selectedKey: this.props.selectedKey
                  // focusStage: this.props.focus_stage
                }}
                folderRenderer={CustomFolderRenderer}
                folderRendererProps={{
                  setContextMenu: this.setContextMenu,
                  collectCheckedItems: this.collectCheckedItems,
                  checkedItems: this.state.checkedItems,
                  collectcheckedItems: this.collectcheckedItems,
                  selectedKey: this.props.selectedKey
                  // focusStage: this.props.focus_stage
                }}
                headerRenderer={CustomHeaderRenderer}
                headerRendererProps={{
                  setActiveDirectoryToUpload:
                    this.props.setActiveDirectoryToUpload,
                  createBreadCrumbItem: this.props.createBreadCrumbItem,
                  setIsUserUpload: this.setIsUserUpload,
                  setActiveItemToDisplay: this.setActiveItemToDisplay,
                  setContextMenu: this.setContextMenu,
                  toggledisplayItemDetails: this.toggledisplayItemDetails
                }}

                // onCreateFiles={this.handleCreateFiles}
              />
            </div>
          </Col>

          {contextMenuStyle.is_visible && (
            <div
              ref={this.contextMenuRef}
              className="cFileRDivParent"
              style={{
                top: this.state.contextMenuStyle.style.top,
                left: this.state.contextMenuStyle.style.left
              }}>
              <Toast>
                <ToastHeader>Actions</ToastHeader>
                <ToastBody>
                  <ListGroup flush>
                    <ListGroupItem
                      tag="a"
                      action
                      onClick={e => {
                        //activeFolderPathToPasteIn is the current active item
                        this.setState({
                          is_copy_or_cut: 'copy'
                        });
                        this.setContextMenu('', false);
                        if (this.state.checkedItems.length > 0) {
                          // this.referenceDocumentPasteAction();
                        } else {
                          this.setState({
                            activeItemToPaste: this.state.activeItemToDisplay,
                            contextMenuStyle: {
                              ...this.state.contextMenuStyle,
                              is_visible: false
                            }
                          });
                        }
                      }}
                      className="justify-content-between">
                      <i className="fas fa-copy text-info mx-2"></i>
                      Copy
                    </ListGroupItem>

                    <ListGroupItem
                      tag="a"
                      action
                      onClick={e => {
                        //activeFolderPathToPasteIn is the current active item
                        this.setContextMenu('', false);
                        this.setState({
                          is_copy_or_cut: 'cut'
                        });
                        if (this.state.checkedItems.length > 0) {
                          //this.referenceDocumentPasteAction();
                        } else {
                          this.setState({
                            activeItemToPaste: this.state.activeItemToDisplay,
                            contextMenuStyle: {
                              ...this.state.contextMenuStyle,
                              is_visible: false
                            }
                          });
                        }
                      }}
                      className="justify-content-between">
                      <i className="fas fa-cut text-success mx-2"></i>
                      Cut
                    </ListGroupItem>

                    {(Object.keys(this.state.activeItemToPaste).length > 0 ||
                      this.state.checkedItems.length > 0) &&
                      this.state.is_copy_or_cut &&
                      this.state.activeItemToDisplay.isFolder && (
                        <ListGroupItem
                          tag="a"
                          action
                          onClick={e => {
                            //activeFolderPathToPasteIn is the current active item
                            this.setContextMenu('', false);

                            this.setState(
                              {
                                activeFolderPathToPasteIn:
                                  this.state.activeItemToDisplay
                              },
                              () => {
                                if (this.state.is_copy_or_cut === 'copy') {
                                  this.referenceDocumentPasteAction();
                                } else {
                                  this.handleOnMoveFile();
                                }
                              }
                            );
                          }}
                          className="justify-content-between">
                          <i className="fas fa-paste text-warning mx-2"></i>
                          Paste
                        </ListGroupItem>
                      )}

                    <ListGroupItem
                      tag="a"
                      action
                      onClick={e => {
                        const base_url =
                          this.state.activeItemToDisplay
                            .base_url_to_display_in_file_explorer;

                        this.handleDeleteFileOrFolder(base_url);
                        this.setContextMenu('', true);
                      }}
                      className="justify-content-between">
                      <i className="fas fa-trash text-danger mx-2"></i>
                      Delete
                    </ListGroupItem>

                    {(this.state.checkedItems.length === 1 ||
                      this.state.activeItemToDisplay) &&
                      !this.state.activeItemToDisplay.isFolder && (
                        <ListGroupItem
                          tag="a"
                          action
                          href={`${
                            process.env.REACT_APP_API_ENDPOINT[
                              process.env.REACT_APP_API_ENDPOINT.length - 1
                            ] == '/'
                              ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                              : process.env.REACT_APP_API_ENDPOINT
                          }/${this.state.activeItemToDisplay.download_link}`}
                          className="justify-content-between">
                          <i className="fas fa-download text-primary mx-2"></i>
                          Download
                        </ListGroupItem>
                      )}
                  </ListGroup>
                </ToastBody>
              </Toast>
            </div>
          )}

          <Col xs={12} sm={4} className={'order-last flex-grow-1'}>
            <h6 className="text-primary">Disk Usage</h6>
            <Row>
              <Col sm={2}>
                <h2>
                  <i className="far fa-hdd text-info"></i>
                </h2>
              </Col>
              <Col sm={10}>
                <Progress
                  color={progress_file_size >= 70 ? 'danger' : 'info'}
                  value={progress_file_size}
                />
                <div className="text-left text-muted">
                  {prettyBytes(totalDocumentSize)} of{' '}
                  {prettyBytes(config_limit_size)} used
                </div>
              </Col>
            </Row>

            <hr></hr>
            <h6 className="text-primary">Select or Drop file/s here</h6>
            <div className={`dropzone bg-light ${styles.dropzonePointer}`}>
              <Dropzone
                multiple={true}
                className={`p-4 d-flex flex-column justify-content-center align-items-center ${
                  disabled ? styles.disableDropzoneStyle : styles.dropzoneStyle
                }`}
                onDrop={this.onDrop}
                onDropRejected={this.onDropRejected}
                onDropAccepted={this.setFormikValue}>
                <h2>
                  <i className="fas fa-upload " />
                </h2>
                <div className="text-center">Upload file/s</div>
              </Dropzone>
            </div>

            <hr></hr>

            {/* For Item Details */}
            <Details
              filteredDocuments={filteredDocuments}
              activeItemToDisplay={this.state.activeItemToDisplay || false}
              displayItemDetails={this.state.displayItemDetails}
              showTextDescription={this.state.showTextDescription}
              handleSaveDescription={this.handleSaveDescription}
              toogleTextDescription={this.toogleTextDescription}
              _isMounted={this._isMounted}
              disabled={disabled}
              {...this.props}
            />

            {/* Listing Documents to  Upload */}
            <DocumentsListing
              filteredDocuments={filteredDocuments}
              {...this.props}
              disabled={disabled}
            />
          </Col>

          {/* Confirmation modal for the file replacement */}
          <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
            <ModalHeader>
              {t('reference_documents:want_to_replace_document')}
            </ModalHeader>
            <ModalBody>
              {t('reference_documents:confirmation_message')}
              {this.state.fileToConfirm.file &&
                this.state.fileToConfirm.file.name && (
                  <ListGroup className="mt-2">
                    <ListGroupItem className={styles.overflowAuto}>
                      {this.state.fileToConfirm.file.name}
                    </ListGroupItem>
                  </ListGroup>
                )}

              {this.state.docListToConfirm.length > 0 && (
                <ListGroup className="mt-2">
                  {this.state.docListToConfirm.map(doc => {
                    return (
                      <ListGroupItem className={styles.overflowAuto}>
                        {' '}
                        {doc.name}
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="primary"
                onClick={async () => {
                  if (this.state.replaceCustom.origin === 'server') {
                    this.setState(
                      {
                        shouldValidateConflicts: false
                      },
                      async () => {
                        switch (this.state.active_operation) {
                          case 'handleCreateFolder':
                            await this[this.state.active_operation](
                              this.state.replaceCustom.key,
                              '',
                              this.state.replaceCustom.pathToUpload,
                              false
                            );

                            break;

                          default:
                            await (this[this.state.active_operation] &&
                              this[this.state.active_operation](false));
                        }

                        this.closeModal(true);
                      }
                    );
                  } else {
                    this.acceptFileReplace();
                  }
                }}>
                {t('main:yes')}
              </Button>
              <Button type="button" color="secondary" onClick={this.closeModal}>
                {t('main:no')}
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

export default FileExplorer;
