import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from 'draft-js-mention-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import editorStyles from './editorStyles.module.css';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  UncontrolledPopover,
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table
} from 'reactstrap';

import {
  dateFormatting,
  timeAgoFormatting
} from '../../../../locales/dateFormat';
import 'draft-js-mention-plugin/lib/plugin.css';
import styles from './displayMessage.css';

const linkPlugin = createLinkPlugin({
  theme: editorStyles,
  placeholder: 'http://…'
});

class SimpleMentionEditor extends Component {
  constructor(props) {
    super(props);
    let editorState;
    editorState = EditorState.createWithContent(this.props.content);

    this.state = {
      editorState
      // suggestions: mentions
    };

    this.userMentionPlugin = createMentionPlugin({
      // mentions: this.state.suggestions,
      mutability: 'IMMUTABLE',
      mentionPrefix: '@',
      mentionComponent: mentionProps => {
        let id, label, popOverBody;
        if (mentionProps.mention.type && mentionProps.mention.type === 'user') {
          id = mentionProps.mention.user_id;
        } else if (mentionProps.mention.group_type) {
          id = mentionProps.mention[`${mentionProps.mention.group_type}_id`];
        }

        return (
          <>
            {(mentionProps.mention.type === 'user' ||
              mentionProps.mention.group_type) && (
              <>
                <span
                  id={`key-${mentionProps.entityKey}-${id}`}
                  className={`${mentionProps.className}`}>
                  {mentionProps.children}
                </span>
                <UncontrolledPopover
                  placement="bottom"
                  className="popover-container"
                  boundariesElement="window"
                  trigger="legacy"
                  fade={false}
                  target={`key-${mentionProps.entityKey}-${id}`}>
                  <PopoverHeader>{mentionProps.mention.name}</PopoverHeader>
                  <PopoverBody>
                    <div className="py-2">
                      {mentionProps.mention.type === 'user' ? (
                        <div>
                          {' '}
                          <div>
                            <i className="far fa-envelope" />{' '}
                            <span>{mentionProps.mention.email}</span>
                          </div>
                          <div>
                            <i className="far fa-id-badge" />{' '}
                            <span>{mentionProps.mention.role}</span>
                          </div>{' '}
                        </div>
                      ) : (
                        <div>
                          <div>
                            <i className="fa fa-calendar" /> Date created:
                            <span className="px-1">
                              {dateFormatting(
                                mentionProps.mention.created_date,
                                'defaultHour'
                              )}
                            </span>
                          </div>
                          <div>
                            <i className="fa fa-users" /> Type:
                            <span className="px-1">
                              {mentionProps.mention.group_type}
                            </span>
                          </div>
                          <div>
                            <i className="fa fa-info-circle" /> Description:
                            <span className="px-1">
                              {mentionProps.mention.description}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </>
        );
      }
    });

    this.issueMentionPlugin = createMentionPlugin({
      mutability: 'IMMUTABLE',
      mentionPrefix: '#',
      mentionTrigger: '#',
      mentionComponent: mentionProps => {
        return (
          <>
            {mentionProps.mention.type === 'issue' && (
              <span
                id={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}`}
                className={`${mentionProps.className} ${styles.issueBGColor}`}>
                {mentionProps.children}
              </span>
            )}
            <UncontrolledPopover
              // placement="bottom"
              className="issue-popover-container"
              boundariesElement="window"
              trigger="legacy"
              fade={false}
              target={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}`}>
              <PopoverHeader>{mentionProps.mention.name}</PopoverHeader>
              <div>
                <Row className=" m-0 px-0 py-2 d-flex justify-content-between">
                  <Col sm={6}>
                    <img
                      className={styles.imgMention}
                      src={mentionProps.mention.imageSrc}
                      alt={mentionProps.mention.name}
                    />
                  </Col>

                  <Col sm={6}>
                    <div>
                      <span>{props.t('topic')}: </span>
                      <span className="font-weight-bold">
                        {mentionProps.mention.name}
                      </span>
                    </div>
                    <div>
                      <span>{props.t('date_created')}: </span>
                      <span className="font-weight-bold">
                        {dateFormatting(
                          mentionProps.mention.dateCreated,
                          'defaultHour'
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div
                  className={`d-flex p-2 justify-content-end ${styles.borderTopDiv}`}>
                  <div>
                    <ButtonGroup>
                      <Button
                        tag={Link}
                        outline
                        id={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}-link`}
                        to={mentionProps.mention.link}>
                        <i className="material-icons">link</i>
                      </Button>
                      <UncontrolledTooltip
                        fade={false}
                        target={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}-link`}
                        boundariesElement="window">
                        {props.t('go_to_issue')}
                      </UncontrolledTooltip>
                      <Button
                        tag="a"
                        download
                        href={`${
                          process.env.REACT_APP_API_ENDPOINT[
                            process.env.REACT_APP_API_ENDPOINT.length - 1
                          ] == '/'
                            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                            : process.env.REACT_APP_API_ENDPOINT
                        }/api/issue/${props.projectId}/${
                          props.stageName
                        }/exportIssue?issueIdList=${
                          mentionProps.mention.issueId
                        }`}
                        outline
                        id={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}-export`}>
                        <i className="material-icons">archive</i>
                      </Button>
                      <UncontrolledTooltip
                        fade={false}
                        target={`key-${mentionProps.entityKey}-${mentionProps.mention.issueId}-export`}
                        boundariesElement="window">
                        {props.t('export_to_bcf')}
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </UncontrolledPopover>
          </>
        );
      }
    });

    this.projectDocumentMentionPlugin = createMentionPlugin({
      // mentions: this.state.suggestions,
      mutability: 'IMMUTABLE',
      mentionPrefix: '$',
      mentionTrigger: '$',
      mentionComponent: mentionProps => {
        return (
          <>
            {!mentionProps.mention.isfolder && (
              <>
                <span
                  id={`key-${mentionProps.entityKey}-${mentionProps.mention.id}`}
                  className={`${mentionProps.className} ${styles.mentionColors}`}>
                  {mentionProps.children}
                </span>
                <UncontrolledPopover
                  placement="bottom"
                  className="popover-container"
                  boundariesElement="window"
                  trigger="legacy"
                  fade={false}
                  target={`key-${mentionProps.entityKey}-${mentionProps.mention.id}`}>
                  <PopoverHeader>
                    {mentionProps.mention.base_url_to_display_in_file_explorer}
                  </PopoverHeader>
                  <PopoverBody>
                    <Table borderless size="sm" responsive>
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Path</th>
                          <td>
                            {'Documents/' +
                              mentionProps.mention
                                .base_url_to_display_in_file_explorer}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Date Created</th>
                          <td>
                            {dateFormatting(mentionProps.mention.date_uploaded)}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">File origin</th>
                          <td>{mentionProps.mention.origin_of_the_file}</td>
                        </tr>
                        <tr>
                          <th scope="row">Last modified</th>
                          <td>
                            {timeAgoFormatting(
                              mentionProps.mention.date_updated ||
                                mentionProps.mention.date_uploaded
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Description</th>
                          <td>{mentionProps.mention.description}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr></hr>
                    <Button
                      tag="a"
                      download
                      href={`${
                        process.env.REACT_APP_API_ENDPOINT[
                          process.env.REACT_APP_API_ENDPOINT.length - 1
                        ] == '/'
                          ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                          : process.env.REACT_APP_API_ENDPOINT
                      }/${mentionProps.mention.download_link}`}
                      outline
                      color="primary"
                      id={`key-${mentionProps.entityKey}-${mentionProps.mention.id}-download`}
                      size="sm">
                      <span>
                        <i className="mr-2 fas fa-download fs-1-2x text-primary" />
                      </span>
                      Download
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </>
        );
      }
    });
  }

  onChange = editorState => {
    this.setState({
      editorState
    });
  };

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const plugins = [
      this.userMentionPlugin,
      this.issueMentionPlugin,
      this.projectDocumentMentionPlugin,
      linkPlugin
    ];

    return (
      <div onClick={this.focus} className="w-100 text-break">
        <div>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            readOnly={true}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation(['submission'])(SimpleMentionEditor);
