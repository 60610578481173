import React, { Component } from 'react';
import {
  Badge,
  Container,
  Collapse,
  Row,
  UncontrolledTooltip,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  NavLink as BootstrapNavLink
} from 'reactstrap';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import styles from './navbar.module.scss';

import Message from '../../../Message';
import TextOverflowElipsis from '../../../TextOverflowElipsis';

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      unReadNotificationCount: 0
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  updateUnreadNotificationCount = unReadNotificationCount => {
    this.setState({ unReadNotificationCount });
  };

  logout = () => {
    const { logout, Socket, t } = this.props;

    logout().then(() => {
      Message.success(t('logout'));
      this.props.disconnectSocketIO();
    });
  };

  render() {
    const { navLinks, t, authUser, isLoggedIn, location, Notifications } =
      this.props;
    const { first_name, full_name, last_name, company, email } = authUser;
    const { unReadNotificationCount } = this.state;
    const authUserProfile = [
      'first_name',
      'last_name',
      'company',
      'email',
      'function'
    ];

    const LinksIncludedToPopUp = ['profile', 'logout'];
    const linksForEmptyNavbarItem = [
      'login',
      'register',
      'feedback',
      'term-of-use',
      'privacy-statement',
      'pubResources',
      'videotutorials'
    ];
    if (location.pathname === '/') return null;
    if (
      !isLoggedIn &&
      linksForEmptyNavbarItem.includes(location.pathname.slice(1))
    )
      return (
        <Navbar
          dark
          expand="lg"
          className={'sticky-top d-flex flex-columnx p-0 bg-primary'}>
          <Container>
            <Row className="flex-grow-1 px-4">
              <NavbarBrand
                tag={NavLink}
                to="/"
                className={`mr-auto ${styles.whiteSpaceNormal}`}>
                {t('navbar_label')}
              </NavbarBrand>
            </Row>
          </Container>
        </Navbar>
      );

    return (
      <>
        {/* NAVBAR LINKS */}
        {isLoggedIn ? (
          <Navbar
            dark
            expand="lg"
            className={'sticky-top d-flex flex-columnx p-0 bg-primary'}>
            <Container>
              <Row className="flex-grow-1 px-4">
                <MediaQuery query="(min-width: 992px)">
                  <NavbarBrand
                    tag={NavLink}
                    to="/"
                    className={`m-auto ${styles.whiteSpaceNormal}`}>
                    {t('navbar_label')}
                  </NavbarBrand>
                </MediaQuery>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {navLinks
                      .filter(l => !LinksIncludedToPopUp.includes(l.id))
                      .map((link, index) => {
                        return (
                          <div key={`navLink-${index}`}>
                            {link.icon ? (
                              link.label === 'Notifications' ? (
                                <>
                                  <BootstrapNavLink
                                    id={'navbarLink-notification'}
                                    className={styles.navLink}
                                    onClick={e => {
                                      e.preventDefault();
                                    }}>
                                    <NavItem>
                                      <span>{link.icon}</span>
                                      {unReadNotificationCount > 0 && (
                                        <Badge
                                          pill
                                          color="danger"
                                          className={`position-relative ${styles.unreadNotificationBadge}`}>
                                          {unReadNotificationCount}
                                        </Badge>
                                      )}
                                      <MediaQuery query="(max-width: 991px)">
                                        <span className="ml-2">
                                          {link.label}
                                        </span>
                                      </MediaQuery>
                                    </NavItem>
                                    <UncontrolledTooltip
                                      fade={false}
                                      target={'navbarLink-notification'}
                                      boundariesElement="window">
                                      {link.label}
                                    </UncontrolledTooltip>
                                  </BootstrapNavLink>
                                  <Notifications
                                    {...this.props}
                                    updateUnreadNotificationCount={
                                      this.updateUnreadNotificationCount
                                    }
                                    target="navbarLink-notification"
                                  />
                                </>
                              ) : (
                                <span className={`${styles.navLink}`}>
                                  <BootstrapNavLink
                                    id={`navbarLink-${index}`}
                                    className={styles.navLink}
                                    onClick={e => {
                                      link.action &&
                                        link.action({
                                          ...this.props,
                                          event: e
                                        });
                                    }}
                                    {...(link.isLink
                                      ? {
                                          activeClassName: styles.navLinkActive,
                                          to: link.to,
                                          tag: NavLink
                                        }
                                      : {
                                          tag: 'a'
                                        })}>
                                    <NavItem>
                                      <span>
                                        {link.icon && <span>{link.icon}</span>}
                                      </span>
                                      <MediaQuery query="(max-width: 991px)">
                                        <span className="ml-2">
                                          {link.label}
                                        </span>
                                      </MediaQuery>
                                    </NavItem>
                                  </BootstrapNavLink>
                                  <UncontrolledTooltip
                                    fade={false}
                                    target={`navbarLink-${index}`}
                                    boundariesElement="window">
                                    {link.label}
                                  </UncontrolledTooltip>
                                </span>
                              )
                            ) : (
                              <BootstrapNavLink
                                className={styles.navLink}
                                onClick={e => {
                                  link.action &&
                                    link.action({ ...this.props, event: e });
                                }}
                                {...(link.isLink
                                  ? {
                                      activeClassName: styles.navLinkActive,
                                      to: link.to,
                                      tag: NavLink
                                    }
                                  : {
                                      tag: 'a'
                                    })}>
                                <NavItem id={`navbarLink-${index}`}>
                                  <span>{link.label}</span>
                                </NavItem>
                              </BootstrapNavLink>
                            )}
                          </div>
                        );
                      })}
                    {/* PROFILE POP-UP */}
                    {isLoggedIn && (
                      // <MediaQuery query="(min-width: 988px)">
                      <div>
                        <BootstrapNavLink
                          id={'navbarLink-profile-popup'}
                          className={styles.navLink}
                          onClick={e => {
                            e.preventDefault();
                          }}>
                          <NavItem>
                            <i
                              className={`fas fa-user-circle fa-lg ${styles.navBarNavItem}`}
                            />
                            {/* <MediaQuery query="(min-width: 1198px)"> */}
                            <TextOverflowElipsis text={full_name} />
                            {/* </MediaQuery> */}
                          </NavItem>
                        </BootstrapNavLink>
                        <UncontrolledPopover
                          placement="bottom"
                          target="navbarLink-profile-popup"
                          trigger="legacy"
                          className={styles.navBarUncontrolledPopover}>
                          <PopoverBody className="d-flex flex-column">
                            {authUserProfile.map((content, index) => (
                              <div
                                key={`profile-content-${index}`}
                                className="d-flex justify-content-between border-bottom flex-wrap p-1">
                                <div className="font-weight-bold mr-2">
                                  {t(`nav_${content}`)}
                                </div>
                                <div className={styles.navBarAuthUserContent}>
                                  {authUser[content]}
                                </div>
                              </div>
                            ))}
                            <div className="d-flex justify-content-around p-2">
                              {navLinks
                                .filter(link => {
                                  return LinksIncludedToPopUp.includes(link.id);
                                })
                                .map((link, index) => {
                                  if (link.id === 'logout') {
                                    return (
                                      <>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`popover-${index}`}>
                                          {link.label}
                                        </UncontrolledTooltip>
                                        <Button
                                          size="sm"
                                          outline
                                          id={`popover-${index}`}
                                          tag={Link}
                                          to={link.to}
                                          onClick={() => this.logout(link.to)}
                                          color="primary">
                                          {link.icon}
                                        </Button>
                                      </>
                                    );
                                  }
                                  return (
                                    <>
                                      <UncontrolledTooltip
                                        // fade={false}
                                        placement="top"
                                        target={`popover-${index}`}>
                                        {link.label}
                                      </UncontrolledTooltip>
                                      <Button
                                        size="sm"
                                        outline
                                        id={`popover-${index}`}
                                        tag={Link}
                                        to={link.to}
                                        color="primary">
                                        {link.icon}
                                      </Button>
                                    </>
                                  );
                                })}
                            </div>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                      // </MediaQuery>
                    )}
                  </Nav>
                </Collapse>
              </Row>
            </Container>
          </Navbar>
        ) : null}
      </>
    );
  }
}

export default compose(withRouter, withTranslation())(NavigationBar);
