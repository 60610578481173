import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class NotFoundPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <h1>{t('error:page_not_found')}</h1>
        <p>
          <Link to="/">{t('main:back_home')}</Link>
        </p>
      </div>
    );
  }
}

export default withTranslation(['error', 'main'])(NotFoundPage);
