import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area
} from 'recharts';

import { Toast, ToastHeader, ToastBody, Row, Col } from 'reactstrap';

import { dateInWords } from '../../../../../../locales/dateFormat';

const CustomTooltip = ({ active, payload, t }) => {
  if (active) {
    const total = payload && payload[0].payload.total;
    const date = payload && payload[0].payload.timeStamp;

    return (
      <div className="p-3 my-2 rounded">
        <Toast>
          <ToastHeader className="toastHeaderBGColor">
            {' '}
            {dateInWords(date, '')}
          </ToastHeader>

          <ToastBody>
            {t('total_jobs_completed')} {total}
          </ToastBody>
        </Toast>
      </div>
    );
  }

  return null;
};

const Chart = ({ chartData, t }) => {
  const mappedData = (chartData || []).map(entry => {
    return {
      ...entry,
      timeStamp: Date.parse(new Date(entry.timeStamp))
    };
  });

  return (
    <ResponsiveContainer width="95%" height={400}>
      <AreaChart
        width={500}
        height={300}
        data={mappedData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timeStamp"
          domain={['auto', 'auto']}
          name="Time"
          tickFormatter={date => {
            return dateInWords(date, '');
          }}
          // label={{ value: 'Date Time', position: 'insideBottomRight', dy: 10 }}
          type="number"
        />
        <YAxis
          domain={[dataMin => 0 - Math.abs(dataMin), dataMax => dataMax * 2]}
        />

        {/* <Legend /> */}

        <Area type="monotone" dataKey="total" stroke="#82ca9d" />
        <Tooltip content={<CustomTooltip t={t} />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Chart;
