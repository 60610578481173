import { connect } from 'react-redux';
import NewsDetailPresenter from './NewsDetailPresenter';
import {
  adminFetchNews,
  adminEditNews,
  resetNews
} from '../../../../store/actions';
const mapStateToProps = state => {
  return {
    news: state.news
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNews: newsId => dispatch(adminFetchNews(newsId)),
    editNews: (newsId, newsData) => dispatch(adminEditNews(newsId, newsData)),
    resetNews: () => dispatch(resetNews())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsDetailPresenter);
