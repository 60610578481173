import { modelIssueActionType } from '../../constants/actionType';

const {
  FETCH_ALL_ISSUES,
  RESET_ISSUE_LIST,
  FETCH_ISSUE,
  REMOVE_ISSUE
} = modelIssueActionType;
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ISSUES: {
      const { issueList } = action;
      const issues = issueList.reduce((currentValue, issue) => {
        currentValue[issue.properties.issue_id] = issue.properties;
        return currentValue;
      }, {});
      return {
        ...state,
        ...issues
      };
    }
    case FETCH_ISSUE: {
      const { issueData, issueId } = action;
      const { attachments, comments, issue } = issueData;
      return {
        ...state,
        [issueId]: {
          ...issue,
          attachments: attachments,
          comments
        }
      };
    }
    case RESET_ISSUE_LIST: {
      return initialState;
    }
    case REMOVE_ISSUE: {
      const { issueData } = action;
      const allowed = issueData.map(i => i.issue_id);
      const filtered = Object.keys(state)
        .filter(key => !allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {});
      return filtered;
    }
    default:
      return state;
  }
};

export default reducer;
