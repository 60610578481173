import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Alert
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import Message from '../../UI/Message';

import CustomInput from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';

const modalFormikConfig = props => {
  return {
    initialValues: {
      email: props.member ? props.member.email : '',
      role: props.member ? props.member.role : '',
      multiple_emails: []
    },
    validationSchema: Yup.object().shape({
      ...(props.mode === 'edit' && {
        email: Yup.string()
          .email(props.t('validation:invalid_email'))
          .required('Required')
          .test({
            name: 'unique_email',
            test: value => {
              if (props.mode && props.mode === 'edit') {
                if (
                  value &&
                  typeof value === 'string' &&
                  value !== props.member.email &&
                  props.existingMemberEmail.includes(value.toLowerCase())
                ) {
                  return false;
                }
              } else if (
                value &&
                typeof value === 'string' &&
                props.existingMemberEmail.includes(value.toLowerCase())
              ) {
                return false;
              }
              return true;
            },
            message: props.t('error:member_must_be_unique')
          })
      }),
      role: Yup.string().required('Required')
    }),
    onSubmit: values => {
      props.onSubmit(values, props.index);
    }
  };
};

class ModalMember extends Component {
  render() {
    const {
      toggle,
      index,
      className,
      open,
      member,
      t,
      onSubmit,
      roles,
      mode,
      existingMemberEmail
    } = this.props;

    return (
      <Modal isOpen={open} className={className} toggle={toggle}>
        <Formik
          {...modalFormikConfig({
            ...{ member, index, onSubmit, t, mode, existingMemberEmail }
          })}>
          {formikProps => {
            return (
              <Form>
                <ModalHeader>
                  {member ? member.email : t('project:modal_add_member_title')}
                </ModalHeader>
                <ModalBody>
                  {mode === 'edit' ? (
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                          name="email"
                          disabled={mode === 'edit'}
                          placeholder={t('main:email')}
                          label={t('main:email')}
                          component={CustomInput}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Field
                          name="role"
                          disabled={member && member.role === 'owner'}
                          size="md"
                          placeholder={t('project:role')}
                          label={t('project:role')}
                          options={roles.map(r => ({
                            value: r,
                            label: t(`main:role_${r}`)
                          }))}
                          component={CustomSelectInput}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} sm={7}>
                        <Field
                          name="multiple_emails"
                          render={() => {
                            return (
                              <>
                                <Label>Emails</Label>
                                <Input
                                  id="multiple_emails"
                                  disabled={mode === 'edit'}
                                  type="textarea"
                                  placeholder="Enter email"
                                  rows={6}
                                />
                              </>
                            );
                          }}
                        />
                      </Col>

                      <Col xs={12} sm={5}>
                        <Field
                          name="role"
                          disabled={member && member.role === 'owner'}
                          size="md"
                          placeholder={t('project:role')}
                          label={t('project:role')}
                          options={roles.map(r => ({
                            value: r,
                            label: t(`main:role_${r}`)
                          }))}
                          component={CustomSelectInput}
                        />
                      </Col>
                      <Alert color="light">{t('rules:email_input_note')}</Alert>
                    </Row>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    type="button"
                    disabled={formikProps.isSubmitting}
                    onClick={() => {
                      if (mode === 'add') {
                        const addMemberMultipleEmail =
                          document.getElementById('multiple_emails').value;

                        let emailSchema = Yup.array()
                          .transform((value, original) => {
                            return original
                              .split(';')
                              .map(email => email.trim());
                          })
                          .of(Yup.string().email());

                        const validateEmail = emailSchema
                          .isValid(addMemberMultipleEmail)
                          .then(result => {
                            if (result) {
                              const splitEmails = addMemberMultipleEmail
                                .split(';')
                                .map(email => email.trim().toLowerCase())
                                .filter(email => !!email);

                              if (splitEmails.length > 0) {
                                // remove duplicates
                                const uniqueEmails = [
                                  ...new Set([...splitEmails])
                                ];

                                // check if it contains existing email
                                const containExistingEmail = uniqueEmails.some(
                                  r => existingMemberEmail.indexOf(r) >= 0
                                );

                                if (containExistingEmail) {
                                  Message.error(
                                    t('error:emails_must_be_unique')
                                  );
                                } else {
                                  formikProps.setFieldValue('multiple_emails', [
                                    ...uniqueEmails
                                  ]);

                                  formikProps.submitForm();
                                  toggle();
                                }
                              } else {
                                Message.error(t('error:please_enter_email'));
                              }
                            } else {
                              Message.error(t('error:invalid_email'));
                            }
                          });
                      } else {
                        formikProps.submitForm();
                        toggle();
                      }
                    }}>
                    {member
                      ? t('project:edit_member')
                      : t('project:add_member')}
                  </Button>
                  <Button type="button" color="secondary" onClick={toggle}>
                    {t('main:cancel')}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ModalMember.propTypes = {
  toggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string
  }),
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default compose(
  withTranslation(['project', 'main', 'error', 'validation'])
)(ModalMember);
