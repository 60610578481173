// Connect redux to the presenter
import { connect } from 'react-redux';
import React from 'react';

// Import the actions needed from the store
import { Authenticate } from '../../../store/actions';
import AcitveDirectoryLogin from './ActiveDirectoryLogin';
import LocalLogin from './LocalLogin';

// const Container = props => <Presenter {...props} />;

// Pass the selected subset of the state to the presentational component as props
// const mapStateToProps = (state /*, ownProps*/) => {
//   return {
//     todos: state.todos
//   };
// };

// Pass the selected state action to the presentational component as props
const mapDispatchToProps = dispatch => {
  return {
    login: values => dispatch(Authenticate(values))
    // someActionProps:(args) => dispatch(action(args)),
  };
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.app.isAuthenticated,
    featureList: state.app.featureList
  };
};

// let LoginComponent;
// if (authenticationMethod === 'active_directory') {
//   LoginComponent = AcitveDirectoryLogin;
// } else if (authenticationMethod === 'local') {
//   LoginComponent = LocalLogin;
// } else {
//   LoginComponent = LocalLogin;
// }

const LoginComponent = props => {
  const { featureList } = props;
  const authenticationMethod = featureList.authenticationMethod;

  if (authenticationMethod === 'active_directory') {
    return <AcitveDirectoryLogin {...props} />;
  } else if (authenticationMethod === 'local') {
    return <LocalLogin {...props} />;
  } else {
    return <LocalLogin {...props} />;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
