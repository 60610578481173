import { rulesAdminActionType } from '../../constants/actionType';

const initialState = { rulesTree: [], rulesSet: {}, hiddenRules: [] };

const {
  MERGE_RULES_ADMIN,
  RESET_RULES_ADMIN,
  SET_ADMIN_RULE_PARAMS,
  UPDATE_ADMIN_RULE_PARAMETER,
  UPDATE_MULTIPLE_ADMIN_RULE_PARAMETER,
  UPDATE_ADMIN_RULE_SET,
  UPDATE_ADMIN_BATCH_RULES,
  SET_ENTITY_VERSION_ID
} = rulesAdminActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_RULES_ADMIN:
      return initialState;
    case MERGE_RULES_ADMIN:
      return {
        ...state,
        rulesTree: action.rulesTree,
        hiddenRules: action.hiddenRules || [],
        rules: action.rules || {},
        concurrencyControlVersionId: action.concurrencyControlVersionId,
        entityTargetId: action.entityTargetId
      };
    case SET_ADMIN_RULE_PARAMS:
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.ruleId]: {
            ...(state.rules[action.ruleId] || {}),
            ...action.data
          }
        }
      };
    case UPDATE_ADMIN_RULE_PARAMETER:
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.ruleId]: {
            ...(state.rules[action.ruleId] || {}),
            params: {
              ...((state.rules[action.ruleId] &&
                state.rules[action.ruleId].params) ||
                {}),
              ...action.data
            }
          }
        }
      };
    case UPDATE_ADMIN_RULE_SET:
      return {
        ...state,
        rules: { ...state.rules, ...action.rules }
      };
    case UPDATE_MULTIPLE_ADMIN_RULE_PARAMETER: {
      const nextRules = action.listRuleId.reduce((previous, ruleId) => {
        return {
          ...previous,
          [ruleId]: {
            ...(previous[ruleId] || {}),
            params: {
              ...((previous[ruleId] && previous[ruleId].params) || {}),
              ...action.data
            }
          }
        };
      }, state.rules);
      return { ...state, rules: nextRules };
    }

    case UPDATE_ADMIN_BATCH_RULES: {
      const nextRules = action.rules.reduce((previous, rule) => {
        const { rule_id, ...rest } = rule;
        return {
          ...previous,
          [rule.rule_id]: {
            ...(previous[rule.rule_id] || {}),
            params: {
              ...((previous[rule.rule_id] && previous[rule.rule_id].params) ||
                {}),
              ...rest
            }
          }
        };
      }, state.rules);

      return { ...state, rules: nextRules };
    }

    case SET_ENTITY_VERSION_ID: {
      return {
        ...state,

        concurrencyControlVersionId: action.concurrencyControlVersionId
      };
    }
    default:
      return state;
  }
};

export default reducer;
