import React from 'react';
import {
  CustomInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import styles from './styles.module.css';

const CustomInputCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  id,
  noValidation,
  labelClassName,
  ...props
}) => {
  const isValid = !noValidation && touched[field.name] && !errors[field.name];
  const isInvalid =
    !noValidation && !!(touched[field.name] && errors[field.name]);
  return (
    <FormGroup>
      <InputGroup>
        <div className="d-flex flex-column flex-grow-1">
          <CustomInput
            {...field}
            {...props}
            type="checkbox"
            id={id || field.name}
            label={label}
            invalid={isInvalid}
            // valid={isValid}
          />
          {touched[field.name] && errors[field.name] && (
            <div className={`text-danger mt-0 ${styles.customInputCheckboxTextSize}`}>
              {errors[field.name]}
            </div>
          )}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

export default CustomInputCheckboxComponent;
