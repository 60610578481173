import React from 'react';
import { Container, Button, FormGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import ReCAPTCHA from 'react-google-recaptcha';

import CustomInput from '../../../UI/Input/customInput';
import { userFeedback } from '../../../../store/actions/userFeedback';
import Message from '../../Message';
import styles from './Feedback.module.css';

const formikConfig = props => {
  const { t, isRecaptchaEnabled } = props;
  return {
    initialValues: {
      name: '',
      email: '',
      message: '',
      recaptcha: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string()
        .email(t('validation:invalid_email'))
        .required('Required'),
      message: Yup.string().required('Required'),
      ...(isRecaptchaEnabled
        ? {
            recaptcha: Yup.string().required(
              'Please verify that you are a human.'
            )
          }
        : {})
    }),
    onSubmit: (values, { setSubmitting }) => {
      props.userFeedback(values).then(({ success, message }) => {
        if (success) {
          Message.success(message);
          props.history.push('/');
        }
      });
      setSubmitting(false);
    }
  };
};

const handleVerify = (setFieldValue, name) => value => {
  setFieldValue(name, value);
};
const Feedback = props => {
  const featureList = useSelector(state => state.app.featureList);
  const isRecaptchaEnabled = featureList.recaptcha;

  const { t } = props;
  return (
    <>
      <Formik {...formikConfig({ ...props, isRecaptchaEnabled })}>
        {formikProps => {
          const {
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched
          } = formikProps;
          return (
            <Container className="d-flex justify-content-center">
              <div
                className={`${styles.containerWidth} shadow p-3 my-5 bg-white rounded`}>
                <h3
                  className={
                    'border-bottom border-white py-3 text-center text-muted'
                  }>
                  Send us your feedback
                </h3>
                <Form>
                  <FormGroup>
                    <Field
                      type="text"
                      name="name"
                      label={t('main:name')}
                      placeholder={t('main:name_placeholder')}
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="email"
                      name="email"
                      label={t('main:email')}
                      placeholder={t('main:email_placeholder')}
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="textarea"
                      rows="5"
                      name="message"
                      label={t('main:message')}
                      placeholder={t('main:message_placeholder')}
                      component={CustomInput}
                    />
                  </FormGroup>
                  {isRecaptchaEnabled && (
                    <div className="d-flex flex-column align-items-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                        onChange={handleVerify(setFieldValue, 'recaptcha')}
                        onExpired={() =>
                          handleVerify(setFieldValue, 'recaptcha')('')
                        }
                      />
                      {touched.recaptcha && errors.recaptcha && (
                        <div className="text-danger">{errors.recaptcha}</div>
                      )}
                    </div>
                  )}
                  <FormGroup>
                    <Button
                      block
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={handleSubmit}>
                      {t('main:submit')}
                    </Button>
                  </FormGroup>
                </Form>
              </div>
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    userFeedback: feedbackData => dispatch(userFeedback(feedbackData))
  };
};
export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['main'])
)(Feedback);
