import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Button } from 'reactstrap';
import classnames from 'classnames';

import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import { createSubgroup } from '../../../../store/actions';

const CreateSubgroupComponent = ({
  t,
  createSubgroup,
  groupType,
  parentGroupId = '',
  organizationId,
  onSubmitSuccess
}) => {
  const formikConfig = {
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required()
    }),
    // validate: values => {},
    // validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await createSubgroup({
          ...values,
          organizationId,
          ...(groupType === 'team' ? { departmentId: parentGroupId } : {})
        });
        Message.success(t(`organization:${groupType}_created`));
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
      } catch (err) {
        Message.error(t('error:default_message'));
      }
      setSubmitting(false);
    }
  };
  return (
    <Container>
      <div className="p-3">
        <Row className="px-3">
          <h2 className="text-primary">
            {t(`organization:create_${groupType}_title`)}
          </h2>
        </Row>
        <Formik {...formikConfig}>
          {formikProps => {
            const { isSubmitting, errors } = formikProps;
            return (
              <Form>
                <Field
                  name="name"
                  placeholder={t('organization:name')}
                  component={CustomInput}
                />
                <Field
                  name="description"
                  type="textarea"
                  placeholder={t('organization:description')}
                  component={CustomInput}
                />
                <Button color="primary" type="submit" disabled={isSubmitting}>
                  {t('main:submit')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

const CreateSubgroupContainer = props => {
  return <CreateSubgroupComponent {...props} />;
};

const mapDispatchToProps = (dispatch, { groupType }) => {
  return {
    createSubgroup: values => dispatch(createSubgroup(groupType, values))
  };
};

const mapStateToProps = () => {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(CreateSubgroupContainer);
