import { rulePackageUpdateActionType } from '../../constants/actionType';
import { merge, union, unionBy, has } from 'lodash';
const { UPLOAD_ZIP_FILE, ADD_ZIP_FILE, REPLACE_ZIP_FILE } =
  rulePackageUpdateActionType;
const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ZIP_FILE: {
      const { document } = action;
      return [document];
    }
    case REPLACE_ZIP_FILE: {
      const { document } = action;
      const index = state.findIndex(
        item => item.name === document.name && item.dirty
      );
      if (index === -1) {
        state.push(document);
      } else {
        state[index] = document;
      }
    }

    default:
      return state;
  }
};

export default reducer;
