import { performRequest, handleErrorMessageStatus } from '../requests';
import { tasksActionType } from '../../constants/actionType';

const { MERGE_TASKS, REPLACE_TASK, RESET_TASKS } = tasksActionType;

export const getProjectRelatedItems = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/task_management/projectRelatedItems/${projectId}`,
      {},
      requestConfig,
      'projectRelatedItems'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        return data.itemsRelated;
      } else {
        throw new Error('default_message');
      }
    });
};

export const createTask = (projectId, taskData) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/task_management/createTask/${projectId}`,
      { ...taskData },
      requestConfig,
      'projectRelatedItems'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        return data.task;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      handleErrorMessageStatus(err);
    });
};

export const getProjectTasks = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/task_management/listProjectTask/${projectId}`,
      {},
      requestConfig,
      'projectTasks'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        dispatch({
          type: MERGE_TASKS,
          taskList: data
        });
        return data;
      } else {
        throw new Error('default_message');
      }
    });
};

export const getUserTasks = offset => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: {
      offset
    }
  };
  return dispatch(
    performRequest(
      'get',
      '/api/task_management/listUserTask',
      {},
      requestConfig,
      'userTasks'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        dispatch({
          type: MERGE_TASKS,
          taskList: data
        });
        return data.length;
      } else {
        throw new Error('default_message');
      }
    });
};

export const getTask = taskId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({
    type: RESET_TASKS
  });
  return dispatch(
    performRequest(
      'get',
      `/api/task_management/task/${taskId}`,
      {},
      requestConfig,
      'fetchTask'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        dispatch({
          type: REPLACE_TASK,
          task: data
        });
        return data;
      } else {
        throw new Error('default_message');
      }
    });
};

export const addFileToTask = (
  taskId,
  projectId,
  file,
  onUploadProgress
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: onUploadProgress,
    params: {
      totalSizeAdded: file.size
    }
  };
  const formData = new FormData();
  formData.append('file', file);
  return dispatch(
    performRequest(
      'post',
      `api/task_management/add_file/${projectId}/${taskId}`,
      formData,
      requestConfig,
      'UPLOAD_FILE_TASK'
    )
  )
    .then(res => res.data)
    .then(({ success, documentId, error }) => {
      if (success) {
        return documentId;
      } else {
        throw new Error(error || 'default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};

export const completeTaskAction = (actionId, taskId, projectId, data) => (
  dispatch,
  getState
) => {
  const concurrency_control_version_id = getState().tasks[taskId]
    .concurrency_control_version_id;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: {
      projectId,
      taskId
    }
  };
  const { message } = data;

  return dispatch(
    performRequest(
      'post',
      `/api/task_management/completeAction/${actionId}`,
      {
        data,
        entityTargetId: taskId,
        concurrencyControlVersionId: concurrency_control_version_id
      },
      requestConfig,
      'updateAction'
    )
  )
    .then(res => res.data)
    .then(({ success }) => {
      if (!success) {
        throw 'default_message';
      }
      return success;
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};
