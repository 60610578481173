import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = props => {
  const { toggle, modalTitle, modalBody, isModalOpen, saveAction, t } = props;
  return (
    <div>
      <Modal isOpen={isModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveAction}>
            {t(`confirm`)}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {t(`cancel`)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
