import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Jumbotron,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Modal
} from 'reactstrap';
import classnames from 'classnames';
import { useLocation, useHistory, Link } from 'react-router-dom';
import InviteToOrganization from '../modify/inviteToOrganization';
import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import OrganizationStructure from './viewOrganizationStructure';
import OrganizationInvitation from './viewInvitations';
import OrganizationalDetailPage from './viewOrganizationalDetailPage';
// import OrganizationRules from '../modify/ruleManagement';
import {
  fetchOrganizationDetails,
  fetchUserOrganizationInvitation,
  acceptOrganizationInvitation,
  rejectOrganizationInvitation,
  cancelJoinRequest
} from '../../../../store/actions';

import { dateFormatting } from '../../../../locales/dateFormat';
import './style.css';

const organizationTabs = [
  'my_organization',
  'structure',
  'invitation'
  // 'rules'
];

const ViewOrganization = ({
  organizationId,
  organizationDetails,
  userInvitations,
  setRefetch,
  acceptOrganizationInvitation,
  rejectOrganizationInvitation,
  cancelJoinRequest,
  t,
  isLoaded
}) => {
  // const location = useLocation();
  // const organizationId = location.match.params.organizationId;
  // useEffect(() => {}, [organizationId]);

  const [activeTab, setActiveTab] = useState('structure');
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const sidebarRef = useRef();

  return (
    <>
      {organizationId ? (
        <Container>
          <h1 className="text-primary">{organizationDetails.name}</h1>

          <Row>
            <Col lg={2}>
              <div ref={sidebarRef} className="organizationSidebar">
                <Nav className="shadow mt-3 rounded" tabs vertical>
                  {organizationTabs.map(tab => (
                    <NavItem
                      key={`project-tab-${tab}`}
                      className={classnames({
                        'border-right border-primary rounded border-sz-3 ':
                          activeTab === tab
                      })}>
                      <NavLink
                        className={classnames('bg-light rounded border-0', {
                          'active font-weight-bold': activeTab === tab
                        })}
                        onClick={() => {
                          setActiveTab(tab);
                        }}>
                        {t(`organization:tab_${tab}`)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <Nav className="shadow mt-3 rounded" tabs vertical>
                  <NavItem key={'project-tab-invite-more'}>
                    <NavLink
                      className={classnames('bg-light rounded border-0')}
                      onClick={() => {
                        setIsInviteMemberModalOpen(!isInviteMemberModalOpen);
                      }}>
                      {t('organization:invite_more')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <Col>
              {organizationId && (
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="structure">
                    {activeTab === 'structure' && (
                      <>
                        <OrganizationStructure
                          organizationId={organizationId}
                        />
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="invitation">
                    {activeTab === 'invitation' && (
                      <>
                        <OrganizationInvitation
                          organizationId={organizationId}
                        />
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="my_organization">
                    {activeTab === 'my_organization' && (
                      <>
                        <OrganizationalDetailPage
                          organizationId={organizationId}
                        />
                      </>
                    )}
                  </TabPane>
                  {/* <TabPane tabId="rules">
                    {activeTab === 'rules' && (
                      <>
                        <OrganizationRules
                          sidebarRef={sidebarRef}
                          organizationId={organizationId}
                        />
                      </>
                    )}
                  </TabPane> */}
                </TabContent>
              )}
            </Col>
          </Row>
          <Modal
            isOpen={isInviteMemberModalOpen}
            toggle={() => setIsInviteMemberModalOpen(!isInviteMemberModalOpen)}>
            <InviteToOrganization
              toggle={() =>
                setIsInviteMemberModalOpen(!isInviteMemberModalOpen)
              }
              onSubmitSuccess={() => {
                setRefetch(true);
                setIsInviteMemberModalOpen(!isInviteMemberModalOpen);
              }}
              organizationId={organizationId}
            />
          </Modal>
        </Container>
      ) : (
        <Container>
          <Jumbotron className="my-3 shadow">
            <h1 className="display-3">{t('organization:intro_title')}</h1>
            <p className="lead">{t('organization:intro_lead1')}</p>
            <hr className="my-2" />
            <p>{t('organization:intro_lead2')}</p>
            <div className="lead">
              <Button tag={Link} to="/organization/create" color="primary">
                {t('organization:create_one')}
              </Button>
            </div>
            <hr className="my-4" />
            <div className="lead">
              <InputGroup id="request-joining">
                <Input
                  type="text"
                  placeholder={t('organization:request_to_join_placeholder')}
                  disabled
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    disabled
                    onClick={() => {
                      alert('TODO!!');
                    }}>
                    {t('organization:request_to_join')}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <Label for="request-joining">Coming soon</Label>
            </div>
            <hr className="my-4" />
            <div className="lead">
              <h4 className="display-6">{t('organization:your_invitation')}</h4>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th className="border-top-0" scope="col">
                      {t('organization:invitation_type')}
                    </th>
                    <th className="border-top-0" scope="col">
                      {t('organization:organization_name')}
                    </th>
                    <th className="border-top-0" scope="col">
                      {t('organization:date_invited')}
                    </th>
                    <th className="border-top-0" scope="col">
                      {t('organization:invitation_status')}
                    </th>
                    <th className="border-top-0" scope="col">
                      {t('organization:action_invitation')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userInvitations && userInvitations.length > 0 ? (
                    userInvitations.map((inv, index) => {
                      let invitationStatus = 'unknown';
                      if (
                        inv.type === 'request' &&
                        !inv.relation.acceted &&
                        !inv.relation.rejected
                      ) {
                        invitationStatus = 'pending';
                      } else if (
                        inv.type === 'request' &&
                        !inv.relation.acceted &&
                        inv.relation.rejected
                      ) {
                        invitationStatus = 'rejected_by_orga';
                      } else if (
                        inv.type === 'invitation' &&
                        !inv.relation.acceted &&
                        !inv.relation.rejected
                      ) {
                        invitationStatus = 'waiting_for_you';
                      } else if (
                        inv.type === 'invitation' &&
                        !inv.relation.acceted &&
                        inv.relation.rejected
                      ) {
                        invitationStatus = 'rejected_by_you';
                      }
                      return (
                        <tr key={`user-invitation-${index}`}>
                          <td className="align-middle">{inv.type}</td>
                          <td className="align-middle">
                            {inv.organization.name}
                          </td>
                          <td className="align-middle">
                            {dateFormatting(inv.relation.date_created)}
                          </td>
                          <td className="align-middle">
                            {t(
                              `organization:invitation_status_${invitationStatus}`
                            )}
                          </td>
                          <td className="align-middle">
                            {inv.type === 'invitation' &&
                              !inv.relation.accepted &&
                              !inv.relation.rejected && (
                                <>
                                  <Button
                                    color="link"
                                    onClick={async () => {
                                      try {
                                        await acceptOrganizationInvitation(
                                          inv.organization.organization_id
                                        );
                                        setRefetch(true);
                                      } catch (err) {
                                        Message.error(t('err.message'));
                                      }
                                    }}>
                                    {t('main:accept')}
                                  </Button>
                                  <Button
                                    color="link"
                                    onClick={async () => {
                                      try {
                                        await rejectOrganizationInvitation(
                                          inv.organization.organization_id
                                        );
                                        setRefetch(true);
                                      } catch (err) {
                                        Message.error(t('err.message'));
                                      }
                                    }}>
                                    {t('main:reject')}
                                  </Button>
                                </>
                              )}
                            {inv.type === 'request' && !inv.relation.accepted && (
                              <Button
                                color="link"
                                onClick={async () => {
                                  try {
                                    await cancelJoinRequest(
                                      inv.organization.organization_id
                                    );
                                    setRefetch(true);
                                  } catch (err) {
                                    Message.error(t('err.message'));
                                  }
                                }}>
                                {t('main:cancel')}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        {t('organization:no_invitation')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* <span className="mx-2"> {t('main:or')}</span>
              <Button color="info">{t('organization:see_your_invitations')}</Button> */}
          </Jumbotron>
        </Container>
      )}
    </>
  );
};

const ViewOrganizationContainer = props => {
  const {
    userOrganizationId,
    fetchOrganizationDetails,
    fetchUserOrganizationInvitation
  } = props;
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [reFetch, setRefetch] = useState(true);
  const [userInvitations, setUserInvitation] = useState([]);

  useEffect(() => {
    // if (!userOrganizationId) {
    // IF NO ORGANIZATION REDIRECT TO ORGANIZATION CREATION PAGE
    // history.replace('/organization/create');
    // } else {
    // FETCH ORGANIZATION
    if (userOrganizationId) {
      (async () => {
        try {
          await fetchOrganizationDetails(userOrganizationId);
          setIsLoaded(true);
        } catch (err) {
          setHasError(true);
        }
      })();
    } else {
      (async () => {
        if (reFetch) {
          try {
            const invitations = await fetchUserOrganizationInvitation();
            setUserInvitation(invitations);
            setIsLoaded(true);
          } catch (err) {
            setUserInvitation([]);
            setIsLoaded(true);
          }
          setRefetch(false);
        }
      })();
    }
    // }
  }, [userOrganizationId, reFetch]);

  return (
    <>
      {!hasError && isLoaded && (
        <ViewOrganization
          {...props}
          organizationId={userOrganizationId}
          userInvitations={userInvitations}
          setRefetch={setRefetch}
        />
      )}{' '}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationDetails: organizationId =>
      dispatch(fetchOrganizationDetails(organizationId)),
    fetchUserOrganizationInvitation: () =>
      dispatch(fetchUserOrganizationInvitation()),
    acceptOrganizationInvitation: organizationId =>
      dispatch(acceptOrganizationInvitation(organizationId)),
    rejectOrganizationInvitation: organizationId =>
      dispatch(rejectOrganizationInvitation(organizationId)),
    cancelJoinRequest: organizationId =>
      dispatch(cancelJoinRequest(organizationId))
  };
};

const mapStateToProps = state => {
  return {
    userOrganizationId: state.authUser.organizationId,
    organizationDetails:
      state.organization &&
      state.organization.current &&
      state.organization.current.details
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(ViewOrganizationContainer);
