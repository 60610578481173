import React from 'react';
import { Alert, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import styles from './registration.module.css';

const SuccedMessage = props => {
  const { t } = props;
  return (
    <Container>
      <div className="mt-5">
        <Alert color="light" className="text-center pt-5">
          <i
            className={`far fa-check-circle text-success ${styles.successMessageAlertLogo}`}
          />
          <h1 className="alert-heading text-primary pt-4">
            {t('success_header1')}
          </h1>
          <hr />
          <h4 className="pt-3 pb-3">{t('success_header2')}</h4>
          <p>{t('success_header3')}</p>
        </Alert>
      </div>
    </Container>
  );
};

export default withTranslation(['register'])(SuccedMessage);
