import { maintenanceAnnouncement } from '../../constants/actionType';
import { unionBy, flatten, uniqBy } from 'lodash';

const {
  SET_MAINTENANCE_ANNOUNCEMENT,
  FETCH_ALL_MAINTENANCE_AS_ADMIN,
  DELETE_MAINTENANCE_ANNOUNCEMENT,
  UPDATE_MAINTENANCE_ANNOUNCEMENT
} = maintenanceAnnouncement;

const initialState = [];
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_MAINTENANCE_ANNOUNCEMENT: {
    const { announcement } = action;

    const newState = flatten(unionBy([announcement], state, 'id'));
    return newState;
  }
  case maintenanceAnnouncement.FETCH_MAINTENANCE_ANNOUNCEMENT: {
    const { announcement } = action;
    const newState = flatten(
      unionBy([announcement], state.maintenance_announcement, 'id')
    );
    return newState;
  }
  case FETCH_ALL_MAINTENANCE_AS_ADMIN: {
    const { announcement } = action;

    const newState = announcement;
    return newState;
  }
  case DELETE_MAINTENANCE_ANNOUNCEMENT: {
    const { announcementId } = action;

    return state.filter(m => m.id !== announcementId);
  }
  case UPDATE_MAINTENANCE_ANNOUNCEMENT: {
    const { announcement } = action;
    const index = state.findIndex(a => a.id === announcement.id);
    if (index) {
      state.splice(index, 1, announcement);
      return state;
    } else {
      return uniqBy(flatten(unionBy([announcement], state, 'id')), 'id');
    }
  }
  default:
    return state;
  }
};

export default reducer;
