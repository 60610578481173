// temporary store for the model file management
import { performRequest, handleErrorMessageStatus } from '../requests';
import {
  modelFilesActionType,
  appActionType
} from '../../constants/actionType';

const {
  ADD_MODELFILE,
  REPLACE_MODELFILE,
  REMOVE_MODELFILE,
  RESET_MODELFILE,
  DELETE_MODELFILE,
  SET_MODEL_DIRTY,
  SET_MODEL_ERROR,
  SET_MODEL_UPLOAD_STATE
} = modelFilesActionType;

const MAX_UPLOAD_FILE =
  parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE) || 2147483648;

const { UPDATE_FILE_UPLOAD_PROGRESS, SET_IS_UPLOADING_FILE } = appActionType;

export const addModelFile = model => (dispatch, getState) => {
  if (getState().modelFiles.find(m => m.name === model.name)) {
    dispatch({
      type: REPLACE_MODELFILE,
      model: { ...model, progress: 0, dirty: true }
    });
  } else {
    dispatch({
      type: ADD_MODELFILE,
      model: { ...model, progress: 0, dirty: true, removed: false }
    });
  }
  return;
};

export const addCompletedModelFile = model => ({
  type: ADD_MODELFILE,
  model: { ...model, progress: true, dirty: false, removed: false }
});

export const replaceModelFile = model => ({
  type: REPLACE_MODELFILE,
  model: { ...model, progress: 0, dirty: true, removed: false }
});
export const removeModelFile = model => ({
  type: REMOVE_MODELFILE,
  model
});
export const resetModelFile = () => (dispatch, getState) => {
  const state = getState();

  if (!state.app.isUploadingFile) {
    dispatch({
      type: RESET_MODELFILE
    });
  }
};

export const setNewModelFiles = (newFiles, existingFiles) => dispatch => {
  for (let i in existingFiles) {
    const fileToReplace = existingFiles[i];
    const { file } = fileToReplace;
    // replace in redux store
    dispatch(
      replaceModelFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }

  for (let index in newFiles) {
    const file = newFiles[index];
    // push model to the redux store
    dispatch(
      addModelFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }
};

export const updateModelList =
  (projectId, stageName, filesAdded, filesRemoved, projectAction) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_IS_UPLOADING_FILE, isUploading: true });
    dispatch({
      type: 'UPDATE_STAGE',
      projectId,
      stageName,
      stageData: {
        precheck_completed: false,
        web_viewer_status: 'PENDING'
      }
    });
    let nbFilesAdded = filesAdded.length || 0;
    let requestSize = 0;
    // Create a list of request data wih form data to control the total size of the request
    const requestList = [];
    let formData = new FormData();

    let totalSizeAdded = (filesAdded || []).reduce(
      (sum, file) => sum + file.size,
      0
    );

    // Add the files removed to the first request
    let nbFilesRemoved = filesRemoved.length || 0;
    formData.append('modelsRemoved', JSON.stringify(filesRemoved));
    filesRemoved.forEach(file => {
      requestSize -= file.size;
      totalSizeAdded -= file.size;
    });

    let filenameAdded = [];
    let requestSequence = 0;
    requestList.push({
      firstRequest: true,
      requestSequence,
      formData,
      nbFilesAdded,
      sizeAdded: requestSize,
      nbFilesRemoved,
      filenameAdded,
      startAnalyzeProcess: !filesAdded.length > 0,
      projectAction
    });
    requestSequence++;
    requestSize = 0;
    formData = new FormData();
    filesAdded.forEach(file => {
      dispatch({
        type: SET_MODEL_UPLOAD_STATE,
        modelname: file.name,
        uploadState: 'PENDING'
      });
    });
    for (let i = 0; i < filesAdded.length; i++) {
      // nbFilesAdded += 1;

      if (filesAdded[i].size >= MAX_UPLOAD_FILE) {
        const file = filesAdded[i];
        const total = file.size;
        const nbParts = Math.ceil(filesAdded[i].size / MAX_UPLOAD_FILE);
        let sizePartsAdded = 0;
        for (let j = 0; j < nbParts; j++) {
          const isSplitFile = file.fileInput.slice(
            sizePartsAdded,
            sizePartsAdded + MAX_UPLOAD_FILE > total
              ? total
              : sizePartsAdded + MAX_UPLOAD_FILE - 1
          );
          requestSize += isSplitFile.size;
          sizePartsAdded = sizePartsAdded + isSplitFile.size;
          filenameAdded.push(filesAdded[i].name);
          if (requestSize > MAX_UPLOAD_FILE) {
            requestList.push({
              requestSequence,
              formData,
              nbFilesAdded,
              sizeAdded: requestSize,
              nbFilesRemoved: 0,
              filenameAdded,
              startAnalyzeProcess: false,
              projectAction
            });
            requestSequence++;
            formData = new FormData();
            nbFilesAdded = 0;
            nbFilesRemoved = 0;
            filenameAdded = [];
            requestSize = isSplitFile.size;
          }

          formData.append('modelsAdded', isSplitFile, file['name']);
          requestList.push({
            requestSequence,
            formData,
            nbFilesAdded,
            sizeAdded: requestSize,
            nbFilesRemoved: 0,
            filenameAdded,
            startAnalyzeProcess:
              i === filesAdded.length - 1 && j === nbParts - 1,
            isSplit: true,
            lastPart: j === nbParts - 1,
            splitPosition: j,
            projectAction
          });
          requestSequence++;
          formData = new FormData();
          nbFilesAdded = 0;
          nbFilesRemoved = 0;
          filenameAdded = [];
          requestSize = 0;
        }
      } else {
        formData = new FormData();
        nbFilesAdded = 0;
        nbFilesRemoved = 0;
        filenameAdded.push(filesAdded[i].name);
        requestSize = filesAdded[i].size;
        formData.append(
          'modelsAdded',
          filesAdded[i].fileInput,
          filesAdded[i]['name']
        );
        requestList.push({
          requestSequence,
          formData,
          nbFilesAdded,
          sizeAdded: requestSize,
          nbFilesRemoved: 0,
          filenameAdded,
          startAnalyzeProcess: i === filesAdded.length - 1,
          projectAction
        });
        requestSequence++;
      }
    }

    async function* generateRequestSequence() {
      let reqIndex = 0;
      for (let reqData of requestList) {
        const requestConfig = {
          headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch({
              type: UPDATE_FILE_UPLOAD_PROGRESS,
              progressValue:
                (percentCompleted + 100 * reqIndex) / requestList.length
            });
          },

          params: {
            totalSizeAdded,
            sizeAdded: reqData.sizeAdded,
            nbFilesAdded: reqData.nbFilesAdded,
            nbFilesRemoved: reqData.nbFilesRemoved,
            startAnalyzeProcess: reqData.startAnalyzeProcess,
            isSplit: !!reqData.isSplit,
            lastPart: !!reqData.lastPart,
            splitPosition: reqData.splitPosition,
            projectAction: reqData.projectAction,
            lastRequest: reqIndex === requestList.length - 1,
            firstRequest: !!reqData.firstRequest
          }
        };
        const filenameAdded = reqData.filenameAdded;
        filenameAdded.forEach(fileName => {
          dispatch({
            type: SET_MODEL_UPLOAD_STATE,
            modelname: fileName,
            uploadState: 'IN_PROGRESS'
          });
        });
        const res = await dispatch(
          performRequest(
            'post',
            `api/project/${projectId}/${stageName}/model/update`,
            reqData.formData,
            requestConfig,
            'UPLOAD_FILE'
          )
        )
          .then(res => {
            if (!res.data.success) {
              filenameAdded.forEach(fileName => {
                dispatch({
                  type: SET_MODEL_UPLOAD_STATE,
                  modelname: fileName,
                  uploadState: 'ERROR'
                });
                dispatch({
                  type: SET_MODEL_ERROR,
                  modelname: fileName,
                  error: res.data.error || 'default_message'
                });
              });
              throw new Error(res.data.error || 'default_message');
            }
            if (!reqData.isSplit || reqData.lastPart) {
              filenameAdded.forEach(fileName => {
                dispatch({
                  type: SET_MODEL_UPLOAD_STATE,
                  modelname: fileName,
                  uploadState: 'DONE'
                });
              });
            }
            return reqData.filenameAdded;
          })
          .catch(err => {
            filenameAdded.forEach(fileName => {
              dispatch({
                type: SET_MODEL_UPLOAD_STATE,
                modelname: fileName,
                uploadState: 'ERROR'
              });
              dispatch({
                type: SET_MODEL_ERROR,
                modelname: fileName,
                error:
                  (err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : err.message) || 'default_message'
              });
            });
            throw {
              message:
                err.response && err.response.data && err.response.data.error
                  ? err.response.data.error
                  : err.message
            };
          });
        reqIndex += 1;
        yield res;
      }
    }

    let generator = generateRequestSequence();
    try {
      let next = await generator.next();
      while (!next.done) {
        const filesName = next.value;
        filesName.forEach(modelname => {
          dispatch({ type: SET_MODEL_DIRTY, modelname, isDirty: false });
        });
        next = await generator.next();
      }

      // for await ... of not supported on edge
      // for await (let filesName of generator) {
      //   filesName.forEach(modelname => {
      //     dispatch({ type: SET_MODEL_DIRTY, modelname, isDirty: false });
      //   });
      // }
    } catch (err) {
      handleErrorMessageStatus(err);
    } finally {
      dispatch({ type: SET_IS_UPLOADING_FILE, isUploading: false });
    }
  };
