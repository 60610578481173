import React, { Component } from 'react';
import { Alert, Row, Container, Col, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { fetchMaintenanceAnnouncement } from '../../../store/actions';
import {
  EditorState,
  convertFromRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import styles from './styles.module.css';
class MaintenanceAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isNotificationOpen: true
    };
  }
  componentDidMount() {
    this.props.fetchMaintenanceAnnouncement().then(() => {
      this.setState({
        isLoaded: true
      });
    });
  }

  focus = () => {
    this.editor.focus();
  };

  toggleNotificationPanel = () => {
    this.setState(prevState => ({
      isNotificationOpen: !prevState.isNotificationOpen
    }));
  };

  render() {
    const announcement = (this.props.maintenance_announcement || []).filter(
      row => {
        return row.enable_display === true;
      }
    );
    const { isLoaded } = this.state;

    return (
      isLoaded &&
      announcement.length > 0 &&
      announcement[0] &&
      !!announcement[0].enable_display && (
        <div className={`position-absolute ${styles.parentDivDimention}`}>
          <Collapse
            isOpen={this.state.isNotificationOpen}
            toggler={'toggler-notifications'}>
            <Alert
              color="warning"
              className={`shadow  w-100 ${styles.alertIndex}`}>
              <Row>
                <Col sm={1}>
                  <i className={`fas fa-cogs ${styles.iconSize}`} />
                </Col>
                <Col sm={10}>
                  <div
                    onClick={() => this.toggleNotificationPanel()}
                    id={'toggler-notifications'}
                    className="d-flex justify-content-between mb-2 pointer">
                    <div className="d-flex">
                      <h4 className="alert-heading">
                        {this.props.t('scheduled_service_maintenance')}
                      </h4>
                      <div className="ml-2"></div>
                    </div>
                    <div>
                      <i className="fas fa-times mr-2"></i>
                    </div>
                  </div>

                  <div className={`w-100 ${styles.messageHeight}`}>
                    {announcement.map((message, index) => {
                      let messageContent;
                      try {
                        messageContent = convertFromRaw(
                          JSON.parse(
                            decodeURI(
                              this.props.maintenance_announcement[index].body
                            )
                          )
                        );
                      } catch (err) {
                        const blocksFromHTML = convertFromHTML(
                          decodeURIComponent(
                            this.props.maintenance_announcement[index].body ||
                              '<p> Empty </p>'
                          )
                        );
                        try {
                          messageContent = ContentState.createFromBlockArray(
                            blocksFromHTML.contentBlocks,
                            blocksFromHTML.entityMap
                          );
                        } catch (err) {
                          const defaultContent = convertFromHTML(
                            decodeURIComponent('<p> Empty </p>')
                          );
                          messageContent = ContentState.createFromBlockArray(
                            defaultContent.contentBlocks,
                            defaultContent.entityMap
                          );
                        }
                      }

                      return (
                        <div key={`announcement-part-${index}`}>
                          <div>
                            <Editor
                              editorState={EditorState.createWithContent(
                                messageContent
                              )}
                              ref={element => {
                                this.editor = element;
                              }}
                              readOnly={true}
                            />
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>

                  {/* <p className="mb-0">Regards, Fornax Team</p> */}
                </Col>
              </Row>
            </Alert>
          </Collapse>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenance_announcement: state.maintenance_announcement
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMaintenanceAnnouncement: () => dispatch(fetchMaintenanceAnnouncement())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('adminConfigurations')
)(MaintenanceAnnouncement);
