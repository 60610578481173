import { connect } from 'react-redux';
import {
  createNewPassword,
  checkResetPasswordToken,
  setNewPassword
} from '../../../store/actions/authUser';
import NewPassword from './NewPasswordPresenter';

// const mapStateToProps = state => {
//   return {};
// };

const mapDispatchToProps = dispatch => {
  return {
    createNewPassword: (password, token) =>
      dispatch(createNewPassword(password, token)),
    checkResetPasswordToken: token => dispatch(checkResetPasswordToken(token)),
    setNewPassword: (newSetPassword, token) =>
      dispatch(setNewPassword(newSetPassword, token))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NewPassword);
