import React, { useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import knowledgehubContext from '../store/config/context';
import {
  deleteCategory,
  fetchFavoriteCategoryItem,
  setSelectedCategory
} from '../store/actions';
import Message from '../components/shared/Message';

const ConfirmDeleteCatModal = props => {
  const {
    deleteCatModalOpened,
    setDeleteCatModalOpened,
    categoryData,
    t
  } = props;
  const toggle = () => setDeleteCatModalOpened(!deleteCatModalOpened);
  const { dispatch } = useContext(knowledgehubContext);
  const handleDeleteCategory = () => {
    const { categoryId } = categoryData;
    dispatch(deleteCategory(categoryId)).then(res => {
      const { success } = res;
      if (success) {
        dispatch(fetchFavoriteCategoryItem());
        dispatch(setSelectedCategory(null, null));
        Message.success(
          t('delete_successfully', { categoryName: categoryData.categoryName })
        );
      } else {
        Message.error(
          t('failed_to_delete', { categoryName: categoryData.categoryName })
        );
      }
      toggle();
    });
  };

  return (
    <Modal isOpen={deleteCatModalOpened} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete</ModalHeader>
      <ModalBody>Would you like to delete?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleDeleteCategory}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDeleteCatModal;
