import React from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { getIn } from 'formik';

const CustomSelectInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  prefix,
  label,
  id,
  options,
  size = 'lg',
  row = false,
  ...props
}) => {
  const handleChange = value => {
    if (props.onChange) {
      props.onChange(value);
    }
    if (value) setFieldValue(field.name, value);
  };
  return (
    <FormGroup row={row}>
      {!!label && <Label for={id || field.name}>{label}</Label>}
      <InputGroup>
        {!!prefix && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prefix}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          {...field}
          {...props}
          type="select"
          id={id || field.name}
          onChange={e => {
            handleChange(e.target.value);
          }}
          value={field.value || ''}
          className={`custom-select custom-select-${size}`}
          invalid={!!(getIn(touched, field.name) && getIn(errors, field.name))}
          valid={getIn(touched, field.name) && !getIn(errors, field.name)}>
          {props.placeholder && (
            <option
              className="d-none"
              value=""
              disabled
              key={'role-placeholder'}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              {props.placeholder}
            </option>
          )}
          {options.map((opt, index) => {
            return (
              <option key={`role-${index}`} value={opt.value ? opt.value : opt}>
                {opt.label
                  ? opt.label
                  : typeof opt === 'string'
                    ? opt
                    : 'undefined label'}
              </option>
            );
          })}
        </Input>
        {getIn(touched, field.name) && getIn(errors, field.name) && (
          <div className="invalid-feedback">{getIn(errors, field.name)}</div>
        )}
      </InputGroup>
    </FormGroup>
  );
};

export default CustomSelectInputComponent;
