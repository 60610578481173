import {
  search,
  upload,
  uploadImageToServer,
  saveCategoryAPI,
  saveCategoryItemsAPI,
  deleteCategoryItemAPI,
  updateCategoryItemAPI,
  updateCategoryAPI,
  categoryItemAddToFavoriteAPI,
  fetchFavoriteCategoryItemAPI,
  deleteCategoryAPI,
  batchUpdateContentAPI
} from '../api';

export const deleteCategory = categoryId => dispatch => {
  return deleteCategoryAPI(categoryId)
    .then(res => res.data)
    .then(data => {
      dispatch(setIsSearching(false));
      dispatch({ type: 'DELETE_CATEGORY', categoryId: data.categoryId });
      return data;
    });
};

export const fetchFavoriteCategoryItem = () => dispatch => {
  fetchFavoriteCategoryItemAPI().then(res => {
    if (res.success)
      dispatch({ type: 'FETCH_FAVORITE_CATEGORY_ITEMS', favorites: res.data });
    dispatch(setIsSearching(false));
  });
};

export const categoryItemAddToFavorite = (
  categoryId,
  categoryItemId,
  isFavorite,
  categoryName
) => dispatch => {
  categoryItemAddToFavoriteAPI(categoryId, categoryItemId, isFavorite).then(
    res => {
      dispatch({
        type: 'TOGGLE_FAVORITE',
        categoryId: res.data.categoryId,
        categoryItemId: res.data.category_item_id,
        categoryName,
        isFavorite,
        newFavorite: res.data
      });
    }
  );
};

export const updateCategory = (
  categoryId,
  newCategoryData,
  selectedCategoryKey
) => dispatch => {
  return updateCategoryAPI(categoryId, newCategoryData).then(res => {
    dispatch(setIsSearching(false));
    if (res.success) {
      dispatch(
        setSelectedCategory(newCategoryData.categoryName, selectedCategoryKey)
      );
      dispatch({
        type: 'UPDATE_CATEGORY',
        newCategoryData: res.data
      });
    }
    return res;
  });
};

export const updateCategoryItem = (
  categoryId,
  categoryItemId,
  newCategoryItemData,
  groupname,
  selectedCategoryKey
) => (dispatch, getState) => {
  const isExistInResultList = !!getState().resultList[groupname];
  return updateCategoryItemAPI(
    categoryId,
    categoryItemId,
    newCategoryItemData
  ).then(res => {
    dispatch(setIsSearching(false));
    if (res.success && isExistInResultList) {
      dispatch({
        type: 'UPDATE_CATEGORY_ITEM',
        newCategoryItemData: res.data,
        groupname,
        selectedCategoryKey
      });
    }
    return res;
  });
};

export const openComfirmDeleteModal = (
  isOpen,
  categoryId,
  categoryItemId,
  title
) => dispatch => {
  dispatch({
    type: 'OPEN_CONFIRM_DELETE_ITEM_MODAL',
    isOpen,
    categoryId,
    categoryItemId,
    title
  });
};

export const deleteCategoryItem = (categoryId, categoryItemId) => dispatch => {
  return deleteCategoryItemAPI(categoryId, categoryItemId)
    .then(res => res.data)
    .then(res => {
      const { success, ...others } = res;
      dispatch({ type: 'DELETE_CATEGORY_ITEM', ...others });
      return res;
    });
};

export const filesUploadNewCatItems = (
  files,
  categoryName,
  categoryItemId,
  categoryId
) => dispatch => {
  upload(files, categoryName, categoryItemId, categoryId).then(res => {
    dispatch({
      type: 'FILES_UPLOADED',
      newFilesUploaded: res,
      categoryName,
      categoryItemId
    });
  });
};

export const uploadImageServerNewCatItems = (
  categoryId,
  categoryItemId,
  files
) => () => {
  return Promise.all(
    files.map(file => uploadImageToServer(categoryId, categoryItemId, file))
  )
    .then(res => {
      return res;
    })
    .catch(error => {
      return { success: false, error };
    });
};

export const removeFileFromNewCatItems = (itemId, fileIndex) => dispatch => {
  dispatch({
    type: 'REMOVE_FILE_FROM_NEW_CAT_ITEMS',
    itemId,
    fileIndex
  });
};

export const setFilestoUploadForNewCategoryItem = (
  files,
  itemId
) => dispatch => {
  dispatch({
    type: 'SET_FILES_UPLOAD_FOR_NEW_CAT_ITEM',
    files,
    itemId
  });
};

export const setIsAddingItemsInExistingCat = isAdding => dispatch => {
  dispatch({ type: 'SET_IS_ADDING_ITEMS_IN_EXISTING_CAT', isAdding });
};

export const resetNewCategoryItems = () => dispatch => {
  dispatch({ type: 'RESET_NEW_CATEGORY_ITEM_LIST' });
};

export const saveCategoryItems = (
  categoryId,
  categoryItemData,
  groupname
) => dispatch => {
  return saveCategoryItemsAPI(categoryId, categoryItemData, groupname)
    .then(res => {
      if (res.data.success)
        dispatch({
          type: 'ADD_CATEGORY_ITEMS',
          newCategoryItems: res.data.data,
          groupname
        });
      return res.data;
    })
    .catch(error => {
      return { success: false, error };
    });
};

export const saveCategory = categoryName => dispatch => {
  return saveCategoryAPI(categoryName).then(res => {
    if (res.data.success)
      dispatch({
        type: 'ADD_CATEGORY',
        newCategoryName: res.data.data.name,
        categoryId: res.data.data.category_id
      });
    return res.data;
  });
};

export const removeNewCategoryItem = itemId => dispatch => {
  dispatch({
    type: 'REMOVE_NEW_CATEGORY_ITEM',
    itemId
  });
};

export const addNewCategoryItem = newItem => dispatch => {
  dispatch({
    type: 'ADD_CATEGORY_ITEM',
    newItem
  });
};

const setIsSearching = isSearching => dispatch => {
  dispatch({
    type: 'SET_IS_SEARCHING',
    isSearching
  });
};

export const setIsCategoryModalOpen = isOpen => dispatch => {
  dispatch({ type: 'SET_IS_CATEGORY_MODAL_OPEN', isOpen });
};

export const searchByTitle = title => dispatch => {
  dispatch({
    type: 'SET_SEARCH_TEXT',
    search: title
  });
  dispatch(setIsSearching(true));
  search(title)
    .then(res => {
      dispatch({
        type: 'SEARCH_BY_TITLE',
        newResultList: res
      });
      dispatch(setIsSearching(false));
    })
    .catch(() => dispatch(setIsSearching(false)));
};

export const setSelectedCategory = (
  category,
  selectedCategoryKey
) => dispatch => {
  dispatch({
    type: 'SET_SELECTED_CATEGORY',
    category,
    selectedCategoryKey
  });
};
export const setTextCopied = text => dispatch => {
  dispatch({
    type: 'SET_TEXT_COPIED',
    text
  });
};
export const setFilestoUpload = (
  category,
  key,
  files,
  uploaded_by
) => dispatch => {
  dispatch({
    type: 'SET_FILES_UPLOAD',
    category,
    key,
    files,
    uploaded_by
  });
};

export const filesUpload = (
  files,
  categoryName,
  categoryItemId,
  categoryId
) => dispatch => {
  const isNewFiles = files.filter(file => file.is_new == true);
  upload(isNewFiles, categoryName, categoryItemId, categoryId).then(res => {
    dispatch({
      type: 'FILES_UPLOADED',
      newFilesUploaded: res,
      categoryName,
      categoryItemId
    });
  });
};

export const removeFileItem = (
  category,
  categoryItemIndex,
  fileIndex
) => dispatch => {
  dispatch({
    type: 'REMOVE_FILE_ITEM',
    category,
    categoryItemIndex,
    fileIndex
  });
};

export const uploadImageServer = (categoryId, categoryItemId, files) => () => {
  const isNewFiles = files.filter(file => file.is_new == true);
  return Promise.all(
    isNewFiles.map(file =>
      uploadImageToServer(categoryId, categoryItemId, file)
    )
  )
    .then(res => {
      return res;
    })
    .catch(error => {
      return { success: false, error };
    });
};

export const setIsImportFileModalOpen = isOpen => dispatch => {
  dispatch({ type: 'SET_IS_IMPORT_FILE_MODAL_OPEN', isOpen });
};

export const batchUpdateContent = selectedData => dispatch => {
  return dispatch(batchUpdateContentAPI(selectedData)).then(res => {
    return res.data;
  });
};
