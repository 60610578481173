import React, { useState, useEffect } from 'react';
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Col,
  Row,
  Container
} from 'reactstrap';

import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import EditProfilePresenter from './EditProfilePresenter';
import RolesPresenter from './RolesPresenter';
import UserUsagePresenter from './UserUsagePresenter';
import UserGroupsPresenter from './UserGroupsPresenter';

const EditProfile = props => {
  const { t, isAccessControlV2Enabled } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('editProfile');

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded && (
        <Container fluid className="shadow mt-4 p-3 mb-5 rounded">
          <Row className="mt-2">
            <Col>
              <h4 className={'text-left text-muted'}>
                {t('editProfile:profile')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs>
                <NavItem key={`edit-profile-editProfile-tab`}>
                  <NavLink
                    className={classnames('', {
                      'font-weight-bold': 'editProfile' === activeTab
                    })}
                    active={'editProfile' === activeTab}
                    href="#"
                    onClick={() => {
                      setActiveTab('editProfile');
                    }}>
                    {t('editProfile:edit_profile')}
                  </NavLink>
                </NavItem>
                <NavItem key={`edit-profile-permission-tab`}>
                  <NavLink
                    className={classnames('', {
                      'font-weight-bold': 'permission' === activeTab
                    })}
                    active={'permission' === activeTab}
                    href="#"
                    onClick={() => {
                      setActiveTab('permission');
                    }}>
                    {t('editProfile:access_control')}
                  </NavLink>
                </NavItem>
                <NavItem key={`edit-profile-user-usage-tab`}>
                  <NavLink
                    className={classnames('', {
                      'font-weight-bold': 'userUsage' === activeTab
                    })}
                    active={'userUsage' === activeTab}
                    href="#"
                    onClick={() => {
                      setActiveTab('userUsage');
                    }}>
                    {t('editProfile:user_usage')}
                  </NavLink>
                </NavItem>
                {isAccessControlV2Enabled && (
                  <NavItem key={`user-group-tab`}>
                    <NavLink
                      className={classnames('', {
                        'font-weight-bold': 'user-group' === activeTab
                      })}
                      active={'user-group' === activeTab}
                      href="#"
                      onClick={() => {
                        setActiveTab('user-group');
                      }}>
                      {t('editProfile:my_user_groups')}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane key={`tab-pane-editProfile`} tabId={'editProfile'}>
                  <EditProfilePresenter {...props} />
                </TabPane>
                <TabPane
                  key={`tab-pane-permission`}
                  tabId={'permission'}
                  className="pt-3">
                  <RolesPresenter {...props} />
                </TabPane>
                <TabPane
                  key={`tab-pane-userUsage`}
                  tabId={'userUsage'}
                  className="pt-3">
                  <UserUsagePresenter {...props} />
                </TabPane>
                {isAccessControlV2Enabled && (
                  <TabPane
                    key={`tab-pane-user-group`}
                    tabId={'user-group'}
                    className="pt-3">
                    <UserGroupsPresenter {...props} />
                  </TabPane>
                )}
              </TabContent>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default withTranslation(['editProfile'])(EditProfile);
