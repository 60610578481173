import React, { Component } from 'react';
import {
  UncontrolledTooltip,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from 'draft-js-mention-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import { Map } from 'immutable';
import {
  fetchAllModelIssues,
  fetchAllModelIssuesPerProject,
  fetchProject,
  fetchReferenceDocumentsFileExplorer,
  fetchOrganizationStructure,
  fetchProjectRelatedGroups
} from '../../../../store/actions';
import { get } from 'lodash';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from 'draft-js-buttons';

//IMPORT CSS FOR THE PLUGINS
import editorStyles from './editorStyles.module.css';
import mentionsStyles from './mentionsStyles.module.css';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-undo-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

const undoPlugin = createUndoPlugin({
  theme: {
    undo: editorStyles.button,
    redo: editorStyles.button
  }
});
const { UndoButton, RedoButton } = undoPlugin;
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const getProjectMembers = members => {
  return members.map(member => {
    return {
      ...member,
      type: 'user'
    };
  });
};

const getModelIssues = (issues, project_id, stageName) => {
  return issues.map(issue => {
    const { issue_id, issue_topic, date_created, date_updated } =
      issue.properties;
    const imageSrc = stageName
      ? `${
          process.env.REACT_APP_API_ENDPOINT[
            process.env.REACT_APP_API_ENDPOINT.length - 1
          ] == '/'
            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
            : process.env.REACT_APP_API_ENDPOINT
        }/api/issue/${project_id}/${stageName}/image_issue/${issue_id}`
      : `${
          process.env.REACT_APP_API_ENDPOINT[
            process.env.REACT_APP_API_ENDPOINT.length - 1
          ] == '/'
            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
            : process.env.REACT_APP_API_ENDPOINT
        }/api/issue/${project_id}/image_issue/${issue_id}`;
    return {
      name: issue_topic,
      issueId: issue_id,
      link: `/project/${project_id}/issue/${stageName}/${issue_id}`,
      type: 'issue',
      imageSrc,
      dateCreated: date_created,
      dateUpdated: date_updated
    };
  });
};

const linkPlugin = createLinkPlugin({
  theme: editorStyles,
  placeholder: 'http://…'
});

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div>
        {buttons.map((Button, i) => (
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

class HeadlinesButton extends Component {
  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div className={editorStyles.headlineButtonWrapper}>
        <button onClick={this.onClick} className={editorStyles.headlineButton}>
          H
        </button>
      </div>
    );
  }
}

const positionSuggestions = ({ state, props }) => {
  let transform;
  let transition;
  let display;
  const width = '100%';

  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)';
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
    display = 'flex';
  } else if (state.isActive) {
    transform = 'scaleY(0)';
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)';
    display = 'flex';
  }

  return {
    transform,
    transition,
    display,
    width
  };
};

const EntryUserSuggestion = props => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  let group_type;

  if (mention.organization_id && !mention.department_id && !mention.team_id) {
    group_type = 'Organization';
    mention.group_type = 'organization';
  } else if (
    mention.organization_id &&
    mention.department_id &&
    !mention.team_id
  ) {
    mention.group_type = 'department';
    group_type = 'Department';
  } else if (
    mention.organization_id &&
    mention.department_id &&
    mention.team_id
  ) {
    group_type = 'Team';
    mention.group_type = 'team';
  }

  return (
    !!mention.name && (
      <div {...parentProps} key={`user-${mention.user_id}`}>
        <div className={theme.mentionSuggestionsEntryContainer}>
          {/* <div className={theme.mentionSuggestionsEntryContainerLeft}>
<img
src={mention.avatar}
className={theme.mentionSuggestionsEntryAvatar}
role="presentation"
/>
</div> */}

          <div className={theme.mentionSuggestionsEntryContainerRight}>
            <div className={theme.mentionSuggestionsEntryText}>
              {mention.name}
            </div>

            {group_type && (
              <div className={theme.mentionSuggestionsEntryTitle}>
                Group type: {group_type}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const EntryIssueSuggestion = props => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  return (
    <div {...parentProps} key={`issue-${mention.issue_id}`}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.imageSrc}
            className={theme.mentionSuggestionsEntrySnapshot}
            alt={mention.name}
          />
        </div>

        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className={theme.mentionSuggestionsEntryTitle}>
            {mention.title}
          </div>
        </div>
      </div>
    </div>
  );
};

const EntryProjectDocumentsSuggestion = props => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;
  return (
    <div {...parentProps} key={`doc-${mention.id}`}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className={theme.mentionSuggestionsEntryTitle}>
            Path: Documents/{mention.base_url_to_display_in_file_explorer}
          </div>
        </div>
      </div>
    </div>
  );
};

class CorrespondenceEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      suggestionType: null,
      userSuggestions: [],
      initialUserSuggestions: [],
      issueSuggestions: [],
      initialIssueSuggestions: [],
      projectDocumentsSuggestions: [],
      initialprojectDocumentsSuggestions: [],
      count: {
        members: null,
        issues: null,
        files: null
      }
    };

    this.userMentionPlugin = createMentionPlugin({
      mentions: this.state.userSuggestions,
      mutability: 'IMMUTABLE',
      mentionPrefix: '@',
      mentionTrigger: '@',
      theme: mentionsStyles,
      // Commented: disturb the trigger behavior
      // supportWhitespace: true,
      positionSuggestions,
      mentionComponent: mentionProps => {
        return (
          <>
            {mentionProps.mention.type === 'user' && (
              <>
                <span
                  id={`key-${mentionProps.entityKey}-${mentionProps.mention.user_id}`}
                  className={`${mentionProps.className}`}>
                  {mentionProps.children}
                </span>
              </>
            )}
            {!mentionProps.mention.type && (
              <>
                <span
                  id={`key-${mentionProps.entityKey}-${mentionProps.mention.organization_id}`}
                  className={`${mentionProps.className}`}>
                  {mentionProps.children}
                </span>
              </>
            )}
          </>
        );
      }
    });

    this.issueMentionPlugin = createMentionPlugin({
      mentions: this.state.issueSuggestions,
      mutability: 'IMMUTABLE',
      mentionPrefix: '#',
      mentionTrigger: '#',
      theme: mentionsStyles,
      // Commented: disturb the trigger behavior
      // supportWhitespace: true,
      positionSuggestions,
      mentionComponent: mentionProps => {
        return (
          <>
            {mentionProps.mention.type === 'issue' && (
              <span
                className={`rounded px-1 text-muted ${editorStyles.mentionIssueBGColor}`}>
                {mentionProps.children}
              </span>
            )}
          </>
        );
      }
    });

    this.projectDocumentsMentionPlugin = createMentionPlugin({
      mentions: this.state.projectDocumentsSuggestions,
      mutability: 'IMMUTABLE',
      mentionPrefix: '$',
      mentionTrigger: '$',
      type: 'mention',
      theme: mentionsStyles,
      // Commented: disturb the trigger behavior
      // supportWhitespace: true,
      positionSuggestions,
      mentionComponent: mentionProps => {
        return (
          <>
            {!mentionProps.mention.isfolder && (
              <span
                className={`rounded px-1 text-muted ${editorStyles.isfolderColors}`}>
                {mentionProps.children}
              </span>
            )}
          </>
        );
      }
    });
  }

  async componentDidMount() {
    const {
      projectId,
      stageName,
      submissionId,
      project,
      withUserMention = true,
      withIssueMention = true,
      withDocumentMention = true,
      disabled = false
    } = this.props;

    if (withDocumentMention && !disabled) {
      this.props
        .fetchAllProjectDocuments(projectId, stageName)
        .then(documents => {
          const filterDocuments = documents.filter(doc => {
            return !doc.isfolder;
          });

          this.setState({
            projectDocumentsSuggestions: filterDocuments,
            initialprojectDocumentsSuggestions: filterDocuments,
            count: {
              files: filterDocuments.length,
              issues: this.state.count.issues,
              members: this.state.count.members
            }
          });
        });
    }

    if (withUserMention && !disabled) {
      const organizationId = this.props.authUser.organizationId;
      // this.props.fetchOrganizationStructure(organizationId);

      let list_of_group = [];
      if (organizationId) {
        const org_data = await this.props.fetchProjectRelatedGroups(projectId);

        list_of_group = [
          org_data.details,
          ...org_data.departments,
          ...org_data.teams
        ].filter(info => info);
      }

      const projectInfo = await this.props.fetchProject(projectId);
      const members = getProjectMembers(projectInfo.members);
      const merge_member_and_groups = [...members, ...list_of_group];

      this.setState({
        userSuggestions: merge_member_and_groups,
        initialUserSuggestions: merge_member_and_groups
      });
    }
    if (withIssueMention && !disabled) {
      const fetchAllModelIssues = stageName
        ? this.props.fetchAllModelIssues(projectId, stageName)
        : this.props.fetchAllModelIssuesPerProject(projectId);
      fetchAllModelIssues.then(issues => {
        const issueSuggestions = getModelIssues(issues, projectId, stageName);
        this.setState({
          issueSuggestions,
          initialIssueSuggestions: issueSuggestions,
          count: {
            issues: issues.length,
            members: this.state.userSuggestions.filter(i => i.name).length
          }
        });
      });
    }

    if (this.props.onInit) {
      this.props.onInit(this.clearEditorState);
    }
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  clearEditorState = () => {
    this.setState({
      editorState: EditorState.push(
        this.state.editorState,
        ContentState.createFromText('')
      )
    });
  };

  onSearchChange = ({ value }, stateKey) => {
    const currentSuggestions = this.state[stateKey];
    if (currentSuggestions) {
      this.setState({
        [stateKey]: defaultSuggestionsFilter(value, currentSuggestions)
      });
    }
  };

  focus = () => {
    this.editor.focus();
  };

  insertMentionPrefix = type => {
    const { projectId, stageName } = this.props;
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const currentContentBlock = contentState.getBlockForKey(anchorKey);
    const selectedText = currentContentBlock.getText().slice(start, end);
    if (type === 'user') {
      const ncs = Modifier.replaceText(
        contentState,
        selection,
        ` @${selectedText}`
      );
      const es = EditorState.push(editorState, ncs);
      this.onSearchChange({ value: selectedText }, 'userSuggestions');
      this.setState({ editorState: es }, () =>
        setTimeout(() => this.focus(), 500)
      );
    } else if (type === 'issue') {
      const ncs = Modifier.replaceText(
        contentState,
        selection,
        ` #${selectedText}`
      );
      const es = EditorState.push(editorState, ncs);
      this.onSearchChange({ value: selectedText }, 'issueSuggestions');
      this.setState({ editorState: es }, () =>
        setTimeout(() => this.focus(), 500)
      );
    } else if (type === 'files') {
      const ncs = Modifier.replaceText(
        contentState,
        selection,
        ` $${selectedText}`
      );
      const es = EditorState.push(editorState, ncs);
      this.onSearchChange(
        { value: selectedText },
        'projectDocumentsSuggestions'
      );
      this.setState({ editorState: es }, () =>
        setTimeout(() => this.focus(), 500)
      );
    }
  };

  render() {
    const {
      isInvalid,
      isValid,
      t,
      onChange,
      onFocus,
      onBlur,
      invalidFeedback,
      withUserMention = true,
      withIssueMention = true,
      withDocumentMention = true,
      disabled = false
    } = this.props;

    const userMentionPluginInst = this.userMentionPlugin;
    const issueMentionPluginInst = this.issueMentionPlugin;
    const projectDocumentsMentionPluginInst =
      this.projectDocumentsMentionPlugin;

    const { MentionSuggestions: UserMentionSuggestions } =
      userMentionPluginInst;
    const { MentionSuggestions: IssueMentionSuggestions } =
      issueMentionPluginInst;

    const { MentionSuggestions: ProjectDocumentsMentionSuggestions } =
      projectDocumentsMentionPluginInst;

    const plugins = [linkPlugin, staticToolbarPlugin, undoPlugin, emojiPlugin];

    if (withUserMention) {
      plugins.push(userMentionPluginInst);
    }

    if (withIssueMention) {
      plugins.push(issueMentionPluginInst);
    }

    if (withDocumentMention) {
      plugins.push(projectDocumentsMentionPluginInst);
    }

    // const plugins = [
    //   userMentionPluginInst,
    //   issueMentionPluginInst,
    //   projectDocumentsMentionPluginInst,
    //   linkPlugin,
    //   staticToolbarPlugin,
    //   undoPlugin,
    //   emojiPlugin
    // ];

    const onChangeEditor = editorState => {
      this.setState({
        editorState
      });
      onChange(editorState);
    };

    return (
      <div className="d-flex flex-column w-100">
        <div
          className={classnames(
            `order-1 border rounded form-control h-100 w-auto ${editorStyles.editor}`,
            {
              'is-invalid border-danger': isInvalid,
              'is-valid border-success': isValid
            }
          )}>
          <Editor
            editorState={this.state.editorState}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            onEditorStateChange={this.onEditorStateChange}
            onChange={onChangeEditor}
            onFocus={onFocus}
            onBlur={onBlur}
            readOnly={disabled}
          />
        </div>

        {isInvalid && invalidFeedback && (
          <div className="invalid-feedback order-3">{invalidFeedback}</div>
        )}
        <div className="order-2">
          {!disabled && (
            <Toolbar>
              {externalProps => {
                return (
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <Separator {...externalProps} />
                      <HeadlinesButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                      <CodeBlockButton {...externalProps} />
                      <linkPlugin.LinkButton {...externalProps} />
                      <UndoButton />
                      <RedoButton />
                    </div>
                    <div>
                      {withUserMention && (
                        <>
                          <button
                            color="link"
                            className={`w-auto font-weight-normal ${editorStyles.button}`}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.insertMentionPrefix('user');
                            }}
                            id="mention-member">
                            <i className="fas fa-user-tag" />{' '}
                            <Badge color="secondary">
                              {this.state.count.members}
                            </Badge>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            fade={false}
                            target="mention-member">
                            {t('mention_member')}
                          </UncontrolledTooltip>
                        </>
                      )}
                      {withIssueMention && (
                        <>
                          <button
                            color="link"
                            className={`w-auto font-weight-normal ${editorStyles.button}`}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.insertMentionPrefix('issue');
                            }}
                            id="mention-issue">
                            <i className="fas fa-camera" />{' '}
                            <Badge color="secondary">
                              {this.state.count.issues}
                            </Badge>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target="mention-issue"
                            fade={false}>
                            {t('mention_issue')}
                          </UncontrolledTooltip>
                        </>
                      )}
                      {withDocumentMention && (
                        <>
                          {/* Mention files */}
                          <button
                            color="link"
                            className={`w-auto font-weight-normal ${editorStyles.button}`}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.insertMentionPrefix('files');
                            }}
                            id="mention-files">
                            <i className="fas fa-file" />{' '}
                            <Badge color="secondary">
                              {this.state.count.files}
                            </Badge>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target="mention-files"
                            fade={false}>
                            {t('mention_files')}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </div>
                );
              }}
            </Toolbar>
          )}
          {withUserMention && (
            <UserMentionSuggestions
              onSearchChange={event =>
                this.onSearchChange(event, 'userSuggestions')
              }
              onClose={() => {
                this.setState({
                  userSuggestions: this.state.initialUserSuggestions
                });
              }}
              suggestions={this.state.userSuggestions}
              entryComponent={EntryUserSuggestion}
            />
          )}
          {withIssueMention && (
            <IssueMentionSuggestions
              onSearchChange={event =>
                this.onSearchChange(event, 'issueSuggestions')
              }
              onClose={() => {
                this.setState({
                  issueSuggestions: this.state.initialIssueSuggestions
                });
              }}
              suggestions={this.state.issueSuggestions}
              entryComponent={EntryIssueSuggestion}
            />
          )}

          {withDocumentMention && (
            <ProjectDocumentsMentionSuggestions
              onSearchChange={event =>
                this.onSearchChange(event, 'projectDocumentsSuggestions')
              }
              onClose={() => {
                this.setState({
                  projectDocumentsSuggestions:
                    this.state.initialprojectDocumentsSuggestions
                });
              }}
              suggestions={this.state.projectDocumentsSuggestions}
              entryComponent={EntryProjectDocumentsSuggestion}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { projectId, project }) => {
  return {
    project: project || state.projects[projectId],
    authUser: state.authUser,
    groups:
      state.organization &&
      state.organization.current &&
      state.organization.current.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllModelIssuesPerProject: projectId =>
      dispatch(fetchAllModelIssuesPerProject(projectId)),
    fetchAllModelIssues: (projectId, stageName) =>
      dispatch(fetchAllModelIssues(projectId, stageName)),
    fetchProject: projectId => dispatch(fetchProject(projectId)),
    fetchAllProjectDocuments: (projectId, stageName) =>
      dispatch(fetchReferenceDocumentsFileExplorer(projectId, stageName)),
    fetchOrganizationStructure: organizationId =>
      dispatch(fetchOrganizationStructure(organizationId)),
    fetchProjectRelatedGroups: projectId =>
      dispatch(fetchProjectRelatedGroups(projectId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['submission'])
)(CorrespondenceEditor);
