import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  Container,
  Row,
  Col
} from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { compose } from 'recompose';

import CustomInput from '../../UI/Input/customInput';
import CustomFileInput from '../../UI/Input/customFileInput';
import ModelList from './ModelList';
import ModelList2D from './2DModelList';
import MemberList from './MemberList';
import Message from '../../UI/Message';
import Thumb from '../../UI/Thumbnail';
import ModelUploadBox from './ModelUploadBox';
import pathModule from 'path';
import './Project.css';
import UploadProgressBar from './UploadProgressBar';

const checkIfcVersion = (models, props) => {
  const readPromises = models.map(async file => {
    const { fileInput } = file;
    const fileStream = fileInput.stream().getReader();
    let gotIFCVersion = false;
    let endHeaderReached = false;
    let ifcVersion = null;
    const utf8Decoder = new TextDecoder('utf-8');
    while (!gotIFCVersion && !endHeaderReached) {
      let { value, done } = await fileStream.read();
      value = value ? utf8Decoder.decode(value) : '';
      const matchEndSec = value.match(/ENDSEC/);
      endHeaderReached = done || (!!matchEndSec && matchEndSec.length > 0);
      const match = value.match(/FILE_SCHEMA\s*\(\('(.{4,6})'\)\)/);
      if (match && match.length >= 2) {
        gotIFCVersion = true;
        ifcVersion = match[1];
      }
    }
    fileStream.cancel();
    const isAllowed = props.allowed_ifc_version.includes(ifcVersion);
    const model = { ...file, is_ifc_version_allowed: isAllowed };
    props.modelFileAction.replaceModelFile(model);
    return model;
  });

  return Promise.all(readPromises).then(models => {
    return models;
  });
};

const formikConfig = (props, stateProps) => {
  const hasThumbnailValidation = !!props.thumbnailSettings;
  return {
    initialValues: {
      name: '',
      members: [],
      models: props.models,
      models2d: props.models2D.filter(m => !m.removed),
      thumbnail_url: null,
      additional_information: {
        ...props.projectAdditionalFields.reduce((previous, current) => {
          return {
            ...previous,
            [current.name]: current.type !== 'number' ? '' : 0
          };
        }, {})
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .max(60, ' Project name must be at most 60 characters')
        .required('Required'),

      thumbnail_url: Yup.mixed()
        .test(
          'fileFormat',
          props.t('project:unsupported_thumbnail_format'),
          file => {
            if (!hasThumbnailValidation) {
              return true; //  has no thumbail validation settings return valid input
            } else {
              return (
                (file &&
                  props.thumbnailSettings.supported_file_format.includes(
                    file.type
                  )) ||
                !file
              );
            }
          }
        )
        .test(
          'fileSize',
          props.t('project:maximum_thumbnail_file_size'),
          file => {
            if (!hasThumbnailValidation) {
              return true; //  has no thumbail validation settings return valid input
            } else {
              return (
                (file &&
                  hasThumbnailValidation &&
                  file.size <= props.thumbnailSettings.max_file_size_in_mb) ||
                !file
              );
            }
          }
        ),

      models: Yup.array().min(
        0,
        props.t('project:minimum_model_list_item_error_message')
      ),
      models2d: Yup.array().of(
        Yup.object().shape({
          plan_type: Yup.string()
            // .oneOf(typeList.map(e => e.internal_name))
            .required('required')
        })
      ),
      additional_information: Yup.object().shape({
        ...props.projectAdditionalFields
          .filter(f => f.is_required)
          .reduce((previous, current) => {
            return {
              ...previous,
              [current.name]: (current.type !== 'number'
                ? Yup.string
                : Yup.number)().required('Required')
            };
          }, {})
      })
    }),
    onSubmit: async (values, { setSubmitting, setStatus, setFieldError }) => {
      setSubmitting(true);
      setStatus('submitted');
      const { t } = props;
      const { models, models2d, ...projectData } = values;
      const defaultStageName = props.default_stagename;
      let project_id;

      try {
        const checked_models = await checkIfcVersion(
          props.models.filter(m => m.dirty && !m.removed),
          props
        );

        const isNotAllowed = checked_models.some(
          i => !i.is_ifc_version_allowed
        );

        if (isNotAllowed) {
          setFieldError(
            'models',
            props.t('project:ifc_version_doesnt_fit_the_requirement')
          );
        } else {
          const projectId = await props.createProject({
            ...projectData,
            is_uploading: true,
            thumbnail_url: stateProps.croppedImageThumbnail
          });

          project_id = projectId;
          if (props.models.filter(m => m.dirty && !m.removed).length > 0) {
            await props.updateModelList(
              projectId,
              defaultStageName,
              props.models.filter(m => m.dirty && !m.removed),
              props.models.filter(m => m.removed && !m.dirty),
              'project_creation'
            );
          }
          if (props.models2D.filter(m => m.dirty && !m.removed).length > 0) {
            await props.update2DModelList(
              projectId,
              defaultStageName,
              props.models2D.filter(m => m.dirty && !m.removed),
              props.models2D.filter(m => m.removed),
              models2d
            );
          }

          Message.success(t('project:project_created_success'));
          props.updateProject(project_id, { is_uploading: false });
          props.modelFileAction.resetModelFile();
          props.model2DFileAction.reset2DModelFile();
          props.resetProjectStageStore();
          props.history.push('/dashboard');
        }
      } catch (err) {
        console.log(err);
        Message.error(t(`error:${err.message}`));
      } finally {
        setSubmitting(false);
        props.updateFileUploadProgress(0);
      }
    }
  };
};

class ProjectCreationPresenter extends Component {
  constructor(props) {
    super(props);
    props.modelFileAction.resetModelFile();
    this.state = {
      isLoaded: false,
      activeTab: '3D_models',
      is2DModelFileUploading: false,
      model3D_formik_props: null,
      model2D_formik_props: null,
      croppedImageThumbnail: null
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.fetchListProjectStageName(),
      this.props
        .fetchProjectAdditionalFields()
        .catch(err => console.log('Cannot fetch additional fields')),
      this.props
        .fetchAllowedIFcVersionList()
        .catch(err => console.log('Cannot fetch allowede ifc version list')),
      this.props
        .fetchProjectThumbailSettings()
        .catch(err => console.log('Cannot fetch project humbail settings'))
    ]).then(() => {
      this.setState({ isLoaded: true });
    });
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  setThumbnail = thumb => {
    this.setState({ croppedImageThumbnail: thumb });
  };

  render() {
    const {
      t,
      models2D,
      projectAdditionalFields,
      model2DFileAction,
      featureList
    } = this.props;

    const isAdminConfigEnabled = featureList.administrationConfig;
    const isProjectFieldConfigurationEnabled =
      featureList.projectFieldConfiguration;
    const is2DPlanEnabled = featureList['2DPlan'];

    const { isLoaded } = this.state;

    return (
      <>
        {isLoaded && (
          <Container>
            <div className="shadow p-3 mt-3 mb-5 rounded">
              <Row className="px-3">
                <h2 className="text-primary">
                  {t('project:create_form_title')}
                </h2>
              </Row>
              <Formik {...formikConfig(this.props, this.state)}>
                {formikProps => {
                  return (
                    <Form>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Field
                            name="name"
                            type="text"
                            label={t('project:project_name_placeholder')}
                            placeholder={t('project:project_name_placeholder')}
                            disabled={formikProps.isSubmitting}
                            component={CustomInput}
                          />
                          <Field
                            name="description"
                            type="textarea"
                            rows="5"
                            label={t('project:description')}
                            placeholder={t('project:placeholder_description')}
                            disabled={formikProps.isSubmitting}
                            component={CustomInput}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <Field
                            name="thumbnail_url"
                            label={t('project:thumbnail')}
                            placeholder={t('project:thumbnail')}
                            accept={this.props.thumbnailSettings.supported_file_format.toString()}
                            disabled={formikProps.isSubmitting}
                            component={CustomFileInput}
                          />

                          <Thumb
                            setThumbnail={this.setThumbnail}
                            type="file"
                            file={formikProps.values.thumbnail_url}
                            hasValidationError={
                              formikProps.errors.thumbnail_url
                            }
                          />
                        </Col>
                      </Row>
                      {isAdminConfigEnabled &&
                        isProjectFieldConfigurationEnabled &&
                        projectAdditionalFields.length > 0 && (
                          <>
                            <hr />
                            <div>
                              <h5 className="text-primary">
                                {t('additional_information')}
                              </h5>
                              <Container>
                                {projectAdditionalFields
                                  .sort(
                                    (fieldA, fieldB) =>
                                      fieldA.order - fieldB.order
                                  )
                                  .map(additionalField => {
                                    return (
                                      <Row
                                        key={`additional-field-${additionalField.name}`}>
                                        <Col>
                                          <Field
                                            name={`additional_information.${additionalField.name}`}
                                            label={additionalField.label}
                                            placeholder={additionalField.label}
                                            type={additionalField.type}
                                            disabled={formikProps.isSubmitting}
                                            component={CustomInput}
                                          />
                                        </Col>
                                      </Row>
                                    );
                                  })}
                              </Container>
                            </div>
                          </>
                        )}
                      <hr />

                      <Row
                        className={classnames('p-3', {
                          'border-danger border border-thick rounded':
                            !!formikProps.errors.models &&
                            (formikProps.submitCount > 0 ||
                              formikProps.status === 'dirty_model_list')
                        })}>
                        <Col xs={12} sm={8}>
                          <div className="modelListStatus">
                            <Nav
                              tabs
                              className={'flex-column flex-sm-row mb-3 mt-4'}>
                              <NavItem
                                className={classnames({
                                  'border-left border-primary rounded border-sz-3':
                                    this.state.activeTab === '3D_models',
                                  'border-danger':
                                    !!formikProps.errors.models &&
                                    (formikProps.submitCount > 0 ||
                                      formikProps.status === 'dirty_model_list')
                                })}>
                                <NavLink
                                  className={classnames('rounded', {
                                    active:
                                      this.state.activeTab === '3D_models',
                                    'active font-weight-bold': this.state
                                      .activeTab
                                      ? this.state.activeTab === '3D_models'
                                      : false,
                                    'text-danger border-danger':
                                      !!formikProps.errors.models &&
                                      (formikProps.submitCount > 0 ||
                                        formikProps.status ===
                                          'dirty_model_list')
                                  })}
                                  onClick={() => {
                                    this.toggleTab('3D_models');
                                  }}>
                                  <div className="hover-underline-animation">
                                    {t('project:model_3D')}
                                  </div>
                                </NavLink>
                              </NavItem>
                              {is2DPlanEnabled && (
                                <NavItem
                                  className={classnames({
                                    'border-left border-primary rounded border-sz-3':
                                      this.state.activeTab === '2D_models'
                                  })}>
                                  <NavLink
                                    className={classnames('rounded', {
                                      active:
                                        this.state.activeTab === '2D_models',
                                      'active font-weight-bold': this.state
                                        .activeTab
                                        ? this.state.activeTab === '2D_models'
                                        : false,
                                      'text-danger border-danger':
                                        !!formikProps.errors.models2d &&
                                        formikProps.submitCount > 0
                                    })}
                                    onClick={() => {
                                      this.toggleTab('2D_models');
                                    }}>
                                    <div className="hover-underline-animation">
                                      {t('project:model_2D')}
                                    </div>
                                  </NavLink>
                                </NavItem>
                              )}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId="3D_models">
                                <Row>
                                  <Col>
                                    <Container className="model-list-container px-0">
                                      <FieldArray
                                        name="models"
                                        component={props => {
                                          if (
                                            !this.state.model3D_formik_props
                                          ) {
                                            this.setState({
                                              model3D_formik_props: props
                                            });
                                          }

                                          const { modelFileAction } =
                                            this.props;
                                          return (
                                            <ModelList
                                              {...props}
                                              {...modelFileAction}
                                              asFormikField={true}
                                              disabled={
                                                formikProps.isSubmitting
                                              }
                                              models={this.props.models.filter(
                                                m => !m.removed
                                              )}
                                              isUploadingFile={
                                                this.props.isUploadingFile
                                              }
                                              fromCreateProjectPage={true}
                                            />
                                          );
                                        }}
                                      />
                                    </Container>
                                  </Col>
                                </Row>
                              </TabPane>
                              {is2DPlanEnabled && (
                                <TabPane tabId="2D_models">
                                  <Row>
                                    <Col>
                                      <FieldArray
                                        name="models2d"
                                        component={props => {
                                          if (
                                            !this.state.model2D_formik_props
                                          ) {
                                            this.setState({
                                              model2D_formik_props: props
                                            });
                                          }
                                          const { model2DFileAction } =
                                            this.props;

                                          return (
                                            <ModelList2D
                                              {...props}
                                              {...model2DFileAction}
                                              asFormikField={true}
                                              disabled={
                                                formikProps.isSubmitting
                                              }
                                              models={this.props.models2D.filter(
                                                m => !m.removed
                                              )}
                                              formikProps={formikProps}
                                              isFromProjectCreation={true}
                                            />
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </TabPane>
                              )}
                            </TabContent>
                          </div>
                          {this.props.isUploadingFile && <UploadProgressBar />}
                        </Col>
                        <Col xs={12} sm={4}>
                          <ModelUploadBox
                            {...this.props}
                            activetab={this.state.activeTab}
                            formikProps={formikProps}
                            asFormikField={true}
                            // progressVisible={formikProps.isSubmitting}
                            // progressBarValue={fileUploadProgress}
                            disabled={formikProps.isSubmitting}
                            models={this.props.models.filter(m => !m.removed)}
                            models2D={this.props.models2D.filter(
                              m => !m.removed
                            )}
                            model3D_formik_props={
                              this.state.model3D_formik_props
                            }
                            model2D_formik_props={
                              this.state.model2D_formik_props
                            }
                          />
                        </Col>
                      </Row>

                      {!!formikProps.errors.models &&
                        (formikProps.submitCount > 0 ||
                          formikProps.status === 'dirty_model_list') && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {formikProps.errors.models}
                            {this.props.models
                              .filter(m => {
                                return !m.removed && !m.is_ifc_version_allowed;
                              })
                              .map(model => {
                                return <li className="mb-0">{model.name}</li>;
                              })}
                          </div>
                        )}
                      <Row className={'mt-4 d-flex '}>
                        <Col xs={12} sm={6} className="text-center">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isLoaded && formikProps.isSubmitting}
                            onClick={formikProps.onSubmit}>
                            {t('main:submit')}
                          </Button>
                        </Col>
                        <Col xs={12} sm={6} className="text-center">
                          <Button
                            type="button"
                            color="secondary"
                            disabled={formikProps.isSubmitting}
                            onClick={() => this.props.history.goBack()}>
                            {t('main:cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Container>
        )}
      </>
    );
  }
}

export default compose(withTranslation(['project', 'main', 'error']))(
  ProjectCreationPresenter
);
