import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { dateFormatting, timeAgoFormatting } from '../../../locales/dateFormat';
import { Link } from 'react-router-dom';

const selectedNotification = (index, notification) => {
  return notification[index];
};

const NotificationsModal = props => {
  const { modal, toggle, t, selectedNotificationIndex, notifications } = props;
  const notificationTodisplay = selectedNotification(
    selectedNotificationIndex,
    notifications
  );

  return (
    <>
      {notificationTodisplay && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          className={props.className}
          backdrop={true}
          fade={false}
          size="lg">
          <ModalHeader toggle={toggle}>
            <strong>Notification</strong>
          </ModalHeader>
          <ModalBody>
            <div>
              <b>{notificationTodisplay.subject || 'Result Status'} </b>{' '}
              <small className="text-muted">
                {timeAgoFormatting(notificationTodisplay.date)}
              </small>
              <p
                dangerouslySetInnerHTML={{
                  __html: notificationTodisplay.content
                }}
              />
              {notificationTodisplay.expand_content ? (
                <>
                  <p>
                    <strong>Checking Result Details</strong>
                  </p>
                  <div className="d-flex justify-content-between">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: notificationTodisplay.expand_content
                      }}
                    />
                    {notificationTodisplay.url_link &&
                      notificationTodisplay.url_link !== '#' && (
                        <Link
                          className="mr-4 align-self-end"
                          to={`${notificationTodisplay.url_link}`}
                          onClick={toggle}>
                          <Button>
                            <i className="fas fa-arrow-alt-circle-right"></i>
                          </Button>
                        </Link>
                      )}
                  </div>
                </>
              ) : (
                notificationTodisplay.url_link &&
                notificationTodisplay.url_link !== '#' && (
                  <Link
                    to={`${notificationTodisplay.url_link}`}
                    onClick={toggle}>
                    <Button>
                      <i className="fas fa-arrow-alt-circle-right"></i>
                    </Button>
                  </Link>
                )
              )}
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default withTranslation(['notifications'])(NotificationsModal);
