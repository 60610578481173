import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ReactTable from '../../Shared/ReactTable/index';

const UserGroupsPresenter = props => {
  const { t, fetchUserFunction, userFunction, fetchUserGroups } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    fetchUserGroups().then(ug => {
      setUserGroups(ug);
      setIsLoaded(true);
    });
  }, []);

  const reactTableColumns = [
    {
      id: 'row_index',
      Header: '',
      width: 25,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return parseInt(row.index + 1);
      }
    },
    {
      id: 'name',
      accessor: 'name',
      Header: 'Name',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return t(`userPermission:${row.original.user_group.name}`);
      }
    },
    {
      id: 'type',
      accessor: 'type',
      Header: 'Group Type',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return t(`userPermission:${row.original.user_group.type}`) || 'default';
      }
    },
    {
      id: 'relation_type',
      accessor: 'relation_type',
      Header: 'Group Relation',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return t(`userPermission:${row.original.relation_type}`);
      }
    }
  ];

  return (
    <>
      {isLoaded && (
        <Container className="mb-3">
          <ReactTable
            data={userGroups}
            columns={reactTableColumns}
            bordered={false}
            hover={true}
            condensed={true}
          />
        </Container>
      )}
    </>
  );
};

export default withTranslation(['editProfile', 'userPermission'])(
  UserGroupsPresenter
);
