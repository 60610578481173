import { notificationsActionType } from '../../constants/actionType';
const initialState = [];

const {
  FETCH_NOTIFICATIONS,
  READ_NOTIFICATION,
  DISMISS_NOTIFICATION,
  FETCH_ALL_NOTIFICATIONS,
  RESET_NOTIFICATION
} = notificationsActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_ALL_NOTIFICATIONS: {
    const { notificationList } = action;
    return [
      ...state,
      ...notificationList.map(notif => {
        return notif;
      })
    ];
  }
  case FETCH_NOTIFICATIONS: {
    const { notifications } = action;
    return [...notifications, ...state];
  }
  case READ_NOTIFICATION: {
    const { notificationIndex } = action;
    return [
      ...state.slice(0, notificationIndex),
      { ...state[notificationIndex], is_read: true },
      ...state.slice(notificationIndex + 1)
    ];
  }
  case DISMISS_NOTIFICATION: {
    const { notificationIndex } = action;
    return [
      ...state.slice(0, notificationIndex),
      { ...state[notificationIndex], is_dismiss: true },
      ...state.slice(notificationIndex + 1)
    ];
  }
  case RESET_NOTIFICATION: {
    return initialState;
  }
  default:
    return state;
  }
};

export default reducer;
