import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import SubmissionDetailPage from '../../Submission/SubmissionDetailContainer';

class SubmissionDetailPresenter extends Component {
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <h5 className="text-primary">{t('submission')}</h5>
            <SubmissionDetailPage
              projectId={this.props.match.params.projectId}
              stageName={this.props.match.params.stageName}
              submissionId={this.props.match.params.submissionId}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation(['submission'])(SubmissionDetailPresenter);
