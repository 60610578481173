// ! Replaced by new password? TO DELETE?
import React, { Component } from 'react';
import { Card, Button, Container, FormGroup } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { withTranslation } from 'react-i18next';
import CustomInput from '../../UI/Input/customInput';
import * as Yup from 'yup';
import Message from '../../UI/Message';
import './style.css';

const formikConfig = props => ({
  initialValues: { email: '' },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email(props.t('validation:invalid_email'))
      .required('Required')
  }),
  onSubmit: (values, { setSubmitting }) => {
    setSubmitting(true);
    props.resetPassword(values).then(res => {
      if (res.success) {
        Message.success(res.message);
      } else {
        Message.error(res.message);
      }
    });
    setSubmitting(false);
  }
});

class ResetPassword extends Component {
  render() {
    const { t } = this.props;

    return (
      <Container className={'p-5'} id="resetPasswordContainer">
        <Card className={'p-4'}>
          <div className="text-center">
            <i className="fas fa-user-lock text-warning userLockIcon" />
          </div>
          <h3 className={'text-center text-primary p-4'}>
            {t('forgotPassword:forgot_your_password')}
          </h3>
          <div className="text-muted text-center">
            <p>{t('forgotPassword:instructions')}</p>
          </div>

          <Formik {...formikConfig(this.props)}>
            {props => {
              const { isSubmitting } = props;
              return (
                <Form>
                  <FormGroup>
                    <Field
                      name="email"
                      type="text"
                      label={t('login:email_placeholder')}
                      placeholder={t('login:email_placeholder')}
                      prefix={<i className="far fa-envelope" />}
                      component={CustomInput}
                    />
                  </FormGroup>

                  <FormGroup className="pt-4 text-center">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className="resetPasswordButton2">
                      {t('forgotPassword:reset_my_password')}
                    </Button>
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Container>
    );
  }
}

export default withTranslation(['forgotPassword', 'validation'])(ResetPassword);
