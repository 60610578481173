import { connect } from 'react-redux';
import { compose } from 'recompose';
import JobsPresenter from './JobsPresenter';
import { withTranslation } from 'react-i18next';
import {
  fetchJobListAsAdmin,
  requestAdminJobCancellation,
  fetchJobQueueStatusMetrics,
  updateJobStatus
} from '../../../../store/actions';
import withXLSExport from '../../../Shared/WithXLSExport';
const mapStateToProps = state => {
  return {
    userId: state.authUser.userId,
    jobs: state.jobs,
    featureList: state.app.featureList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchJobListAsAdmin: (filters, order, pagination, toDispatch) =>
      dispatch(fetchJobListAsAdmin(filters, order, pagination, toDispatch)),
    requestAdminJobCancellation: jobId =>
      dispatch(requestAdminJobCancellation(jobId)),
    fetchJobQueueStatusMetrics: filters =>
      dispatch(fetchJobQueueStatusMetrics(filters)),
      updateJobStatus: data =>
      dispatch(updateJobStatus(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withXLSExport,
  withTranslation(['adminJobManagement', 'main', 'results'])
)(JobsPresenter);
