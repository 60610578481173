import { connect } from 'react-redux';
import SubmissionPresenter from './SubmissionCreationPresenter';
import { createSubmission } from '../../../store/actions/submission';
import {
  getResultLast,
  resetResults,
  fetchProjectStage,
  fetchProject,
  resetAttachmentFiles
} from '../../../store/actions';

const mapStateToProps = (state, { projectId, stageName, stage, project }) => {
  const projectForSubmission = project || state.projects[projectId];
  const stageForSubmission =
    stage ||
    (function getStage() {
      if (state.project_stages[projectId]) {
        if (stageName) {
          return state.project_stages[projectId].stages[stageName];
        } else {
          const firstStage = Object.keys(
            state.project_stages[projectId].stages
          ).pop();
          if (firstStage) {
            return state.project_stages[projectId].stages[firstStage];
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    })();
  return {
    project: projectForSubmission,
    stage: stageForSubmission,
    authUser: state.authUser,
    results: state.results,
    models: state.modelFiles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: projectId => dispatch(fetchProject(projectId)),
    resetAttachmentFiles: () => dispatch(resetAttachmentFiles()),
    createSubmission: (projectId, stageName, data) =>
      dispatch(createSubmission(projectId, stageName, data)),
    resetResults: () => dispatch(resetResults()),
    getResultLast: projectId => dispatch(getResultLast(projectId)),
    fetchProjectStage: (projectId, stageName) =>
      dispatch(fetchProjectStage(projectId, stageName))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionPresenter);
