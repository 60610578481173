import { adminStatsChecksSummary } from '../../constants/actionType';

import { merge, union, unionBy, has } from 'lodash';
const { FETCH_STATS_SUMMARY, SET_STATS_SUMMARY } = adminStatsChecksSummary;
const initialState = {};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS_SUMMARY: {
      const { data } = action;
      return { ...state, ...data };
    }
    case SET_STATS_SUMMARY: {
      const { data } = action;

      const newState = state.checkStats.map(current =>
        current.key === data.checkStats.key ? data.checkStats : current
      );

      return { checkStats: newState, ...state };
    }

    default:
      return state;
  }
};

export default reducer;
