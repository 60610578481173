import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  FormGroup,
  CustomInput,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledCollapse
} from 'reactstrap';
import debounce from 'lodash/debounce';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import filter from 'lodash/filter';
import ConfirmationModal from './ConfirmationModal';
import RulesDropdown from './RulesDropdown';
import Message from '../../UI/Message';
import GoBackButton from '../../UI/BackButton';

import { withSocketIO } from '../../../api/sockets/withSocketIO';
import { socketAutocheckEvents } from '../../../api/sockets/constantEvents';

import { timeAgoFormatting } from '../../../locales/dateFormat';
import {
  fetchProject,
  fetchProjectStage,
  queryECheck,
  fetchProjectPreference,
  limitRunningJobs,
  fetchRules,
  queryRuleDataValidation,
  fetchJobWithRuleDataValidation,
  getEstimatedComputingUnit
} from '../../../store/actions';
import { getURLParameters } from '../../../utils/url';
import { RULES_KEY_SEPARTOR } from '../../../store/constants/misc';
import autocheckStyles from './autocheck.module.css';

const activatedChecksFunc = checkingType =>
  checkingType.reduce((returned, current) => {
    switch (current) {
      case 'REGULATORY':
        return [...returned, 'regulatory'];
      case 'BIM_DATA':
        return [...returned, 'bimdata'];
      case 'BIM_QUALITY':
        return [...returned, 'quality'];
      case 'CLASH_DETECTION':
        return [...returned, 'clash'];
      default:
        return returned;
    }
  }, []);

const getRuleDataValidationResultErrors = resultContent => {
  try {
    return (resultContent['PRECHECK_RESULTS'] || []).reduce(
      (previous, clauseItem) => {
        const clauseErrors = (clauseItem['QUERYRESULT'] || []).reduce(
          (previous, queryResult) => {
            if (queryResult['GROUPNAME'].match(/\(mandatory\)/)) {
              const queryResultErrors = queryResult['GROUPRESULTS'].reduce(
                (previous, groupResult) => {
                  if (groupResult['ELEMENTTYPECOUNT'] === 0) {
                    return [
                      ...previous,
                      {
                        elementType: groupResult['ELEMENTTYPE'],
                        message: groupResult['ELEMENTTYPEMESSAGE']
                      }
                    ];
                  } else {
                    return previous;
                  }
                },
                []
              );
              return {
                ...previous,
                [queryResult['GROUPNAME']]: queryResultErrors
              };
            } else {
              return previous;
            }
          },
          {}
        );
        if (Object.keys(clauseErrors).length > 0) {
          return {
            ...previous,
            [clauseItem['CLAUSEID']]: {
              ...(previous[clauseItem['CLAUSEID']] || {}),
              ...clauseErrors
            }
          };
        } else {
          return previous;
        }
      },
      {}
    );
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getRuleTreeKeyFromRuleIDList = (ruleTree, ruleIdList, errorMapping) => {
  return ruleTree.reduce((previous, current) => {
    if (current.children) {
      const next = getRuleTreeKeyFromRuleIDList(
        current.children,
        ruleIdList,
        errorMapping
      );
      return [...previous, ...next];
    } else {
      if (
        current.ruleId &&
        !errorMapping[current.ruleId] &&
        ruleIdList.includes(current.ruleId)
      ) {
        return [...previous, current.key];
      } else {
        return previous;
      }
    }
  }, []);
};

class Autochecker extends Component {
  constructor(props) {
    super(props);
    const activatedChecks = activatedChecksFunc(props.checkTypes);
    const urlParameters = !props.type && getURLParameters(window.location.href);
    const checkType =
      props.type && activatedChecks.includes(props.type)
        ? props.type
        : urlParameters['check-type'] &&
          activatedChecks.includes(urlParameters['check-type'])
        ? urlParameters['check-type']
        : activatedChecks[0];

    this.state = {
      isSubmitting: false,
      isLoaded: false,
      rulesChecked: [],
      isConfirmationModalOpen: false,
      checkType,
      addRulesToFavoriteCheckbox: false,
      isRequested: false,
      rulesKeyChecked: {},
      dataValidationInProgress: false,
      jobId: null,
      hasJob: false,
      isFetchingjobData: false,
      ruleErrorMapping: {},
      searchVal: '',
      filteredRulesTree: [],
      expandedKeys: [],
      estimatedCU: 0,
      estimatedNextCUBalance: 0,
      currentCUBalance: 0
    };
    this.debouncedSearch = debounce(this.handleSearch, 800, { maxWait: 1000 });
  }

  componentDidMount() {
    Promise.all([
      this.props.fetchProject(this.props.match.params.id),
      this.props.fetchProjectStage(
        this.props.match.params.id,
        this.props.match.params.stageName
      )
    ])
      .then(() => {
        return this.props.fetchRules(this.state.checkType);
      })
      .then(() => {
        const urlParameters = getURLParameters(window.location.href);
        const jobId = urlParameters && urlParameters['jobId'];
        this.setState({ isLoaded: true });
        if (jobId) {
          this.setState({
            hasJob: true,
            jobId,
            isFetchingjobData: true
          });
          this.initWithJobData(jobId);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const activatedChecks = activatedChecksFunc(this.props.checkTypes);
    const urlParameters = getURLParameters(window.location.href);

    const checkType =
      this.props.type && activatedChecks.includes(this.props.type)
        ? this.props.type
        : urlParameters['check-type'] &&
          activatedChecks.includes(urlParameters['check-type'])
        ? urlParameters['check-type']
        : activatedChecks[0];

    const jobId = urlParameters && urlParameters['jobId'];

    if (checkType !== prevState.checkType) {
      this.setState({ isLoaded: false });
      this.setState({ checkType });
      this.props.fetchRules(this.state.checkType).then(() => {
        this.setState({ isLoaded: true });
      });
    }

    if (jobId && jobId !== this.state.jobId) {
      this.setState({
        hasJob: true,
        jobId,
        isFetchingjobData: true
      });
      this.initWithJobData(jobId);
    }
    if (
      prevProps.rulesTree !== this.props.rulesTree ||
      prevState.searchVal !== this.state.searchVal
    ) {
      // this.debouncedSearch.cancel();
      this.debouncedSearch(this.state.searchVal, this.props.rulesTree);
    }
  }

  handleSearch = (searchVal, tree) => {
    const highlightClassname = 'bg-info';
    const expandedKeys = [];

    const highlightText = (searchVal, text) => {
      const regex = new RegExp(searchVal, 'gi');
      const result = [];
      let match;
      let end = 0;
      let lastIndexMatch = 0;
      while ((match = regex.exec(text)) !== null) {
        let start = match.index;
        if (start > end) {
          result.push(
            <span key={`text-${end}-${start}`}>
              {text.substring(end, start)}
            </span>
          );
        }
        end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          result.push(
            <span key={`text-${start}-${end}`} className={highlightClassname}>
              {text.substring(start, end)}
            </span>
          );
        }

        lastIndexMatch = end;
        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }
      if (lastIndexMatch < text.length) {
        result.push(
          <span key={`text-${lastIndexMatch}-end`}>
            {text.substring(lastIndexMatch)}
          </span>
        );
      }
      return result;
    };
    const regex = new RegExp(searchVal, 'gi');
    const filterRules = tree => {
      return tree.reduce((previous, item) => {
        if (item.children) {
          const filteredChildren = filterRules(item.children);
          if (item.title.match(regex) && searchVal !== '') {
            expandedKeys.push(item.key);
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                children: filteredChildren,
                show: true
              }
            ];
          } else if (filteredChildren.filter(c => c.show).length > 0) {
            expandedKeys.push(item.key);
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                children: filteredChildren,
                show: true
              }
            ];
          } else {
            return [...previous, { ...item, show: false }];
          }
        } else {
          if (item.title.match(regex)) {
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                show: true
              }
            ];
          } else {
            return [...previous, { ...item, show: false }];
          }
        }
      }, []);
    };
    this.setState({ expandedKeys });
    if (searchVal !== '') {
      this.setState({ filteredRulesTree: filterRules(tree) });
    } else {
      this.setState({ filteredRulesTree: tree });
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isConfirmationModalOpen: !prevState.isConfirmationModalOpen
    }));
  };

  getRuleDependencies = ruleKey => {
    const dependencies = this.props.rules[ruleKey].dependencies || [];
    return {
      ...dependencies.reduce((previous, dependency) => {
        if (
          !this.props.rules[dependency.value] ||
          !this.props.rules[dependency.value].index
        ) {
          console.error(`Missing dependency ${dependency.value} for rule ${ruleKey}`)
          return previous;
        } else {
          const childDependencies = this.getRuleDependencies(dependency.value);
          if (dependency.optional) {
            Object.keys(childDependencies).forEach(key => {
              childDependencies[key].optionalDependency = true;
            });
          }

          return {
            ...previous,
            ...childDependencies,
            [dependency.value]: {
              disable: true,
              optionalDependency: !!dependency.optional,
              name: this.props.rules[dependency.value].title,
              index: this.props.rules[dependency.value].index
            }
          };
        }
      }, {})
    };
  };

  onChecked = checkedKeys => {
    const rulesKeyChecked = checkedKeys.reduce((previous, currentKey) => {
      const ruleKey =
        currentKey.match(RULES_KEY_SEPARTOR) &&
        currentKey.split(RULES_KEY_SEPARTOR).pop();
      if (ruleKey) {
        const dependencies = this.getRuleDependencies(ruleKey);

        if (previous[ruleKey]) {
          previous[ruleKey] = {
            treeKey: currentKey,
            disable: false,
            isDependency: false,
            optionalDependency: false,
            name: this.props.rules[ruleKey].title,
            index: this.props.rules[ruleKey].index
          };

          return Object.keys(dependencies).reduce(
            (returned, currentDependencyKey) => {
              return {
                ...returned,
                [currentDependencyKey]: {
                  ...previous[currentDependencyKey],
                  ...dependencies[currentDependencyKey],
                  isDependency: previous[currentDependencyKey]
                    ? previous[currentDependencyKey].isDependency
                    : true,
                  disable:
                    (previous[currentDependencyKey]
                      ? previous[currentDependencyKey].disable
                      : true) && dependencies[currentDependencyKey].disable,
                  optionalDependency:
                    (previous[currentDependencyKey]
                      ? previous[currentDependencyKey].optionalDependency
                      : true) &&
                    dependencies[currentDependencyKey].optionalDependency
                }
              };
            },
            previous
          );
        }
        return {
          ...Object.keys(dependencies).reduce(
            (returned, currentDependencyKey) => {
              return {
                ...returned,
                [currentDependencyKey]: {
                  ...previous[currentDependencyKey],
                  ...dependencies[currentDependencyKey],
                  isDependency: previous[currentDependencyKey]
                    ? previous[currentDependencyKey].isDependency
                    : true,
                  disable:
                    (previous[currentDependencyKey]
                      ? previous[currentDependencyKey].disable
                      : true) && dependencies[currentDependencyKey].disable,
                  optionalDependency:
                    (previous[currentDependencyKey]
                      ? previous[currentDependencyKey].optionalDependency
                      : true) &&
                    dependencies[currentDependencyKey].optionalDependency
                }
              };
            },
            previous
          ),
          [ruleKey]: {
            treeKey: currentKey,
            name: this.props.rules[ruleKey].title,
            index: this.props.rules[ruleKey].index,
            disable: false,
            isDependency: false,
            optionalDependency: false
          }
        };
      } else {
        return previous;
      }
    }, {});
    const sortedRulesKeyCheckedTemp = Object.entries(rulesKeyChecked).sort(
      ([keyA, valueA], [keyB, valueB]) => {
        const indexA = valueA.index.split('-').map(n => parseInt(n));
        const indexB = valueB.index.split('-').map(n => parseInt(n));

        for (let i in indexA) {
          if (indexB[i] && indexA[i] > indexB[i]) {
            return 1;
          } else if (indexB[i] && indexA[i] < indexB[i]) {
            return -1;
          } 
        }
        return -1;
      }
    );
    const sortedRulesKeyChecked = sortedRulesKeyCheckedTemp.reduce(
      (ret, [key, value]) => ({ ...ret, [key]: value }),
      {}
    );
    this.setState({
      rulesChecked: checkedKeys,
      rulesKeyChecked: sortedRulesKeyChecked
    });
  };

  onSubmit = async () => {
    this.setState({ isSubmitting: true });
    try {
      const { allowedToRunJob, allowedToRunJobOnProject } =
        await this.props.limitRunningJobs(
          this.props.match.params.id,
          this.props.match.params.stageName
        );

      if (!allowedToRunJob) {
        Message.warning(this.props.t('autochecker:has_job_running_user'));
        this.setState({ isSubmitting: false });
      } else if (!allowedToRunJobOnProject) {
        Message.warning(this.props.t('autochecker:has_job_running_project'));
        this.setState({ isSubmitting: false });
      } else {
        if (
          this.state.rulesChecked.length !== 0 &&
          this.props.models.length !== 0 &&
          !this.state.dataValidationInProgress
        ) {
          this.props
            .queryECheck(
              this.props.match.params.id,
              this.props.match.params.stageName,
              Object.keys(this.state.rulesKeyChecked).filter(ruleId => {
                const rule = this.state.rulesKeyChecked[ruleId];
                return !rule.optionalDependency;
              }),
              Object.keys(this.state.rulesKeyChecked).filter(ruleId => {
                const rule = this.state.rulesKeyChecked[ruleId];
                return rule.isDependency;
              }),
              this.state.checkType,
              this.state.addRulesToFavoriteCheckbox
            )
            .then(() =>
              Message.success(this.props.t('autochecker:success_submit'))
            )
            .catch(err => {
              Message.error(this.props.t(err.message));
            })
            .then(() => {
              this.setState({ isSubmitting: false });
              this.props.fetchProjectPreference(
                this.props.match.params.id,
                'favorite_rules'
              );
              // this.props.history.push('/dashboard');
            });
        } else {
          this.setState({ isRequested: true });
        }
      }
    } catch (err) {
      Message.error(this.props.t(err));
      this.setState({ isSubmitting: false });
    }
  };

  onDataValidation = async () => {
    this.setState({ dataValidationInProgress: true });
    if (
      this.state.rulesChecked.length !== 0 &&
      this.props.models.length !== 0
    ) {
      this.props
        .queryRuleDataValidation(
          this.props.match.params.id,
          this.props.match.params.stageName,
          Object.keys(this.state.rulesKeyChecked).filter(ruleId => {
            const rule = this.state.rulesKeyChecked[ruleId];
            return !rule.optionalDependency;
          }),
          this.state.checkType,
          this.state.addRulesToFavoriteCheckbox
        )
        .then(jobId => {
          Message.warning(
            this.props.t('autochecker:success_submit_rule_data_validation'),
            10
          );
          this.subscribeToJobUpdate(jobId);
        })
        .catch(err => {
          Message.error(this.props.t(err.message));
          this.setState({ dataValidationInProgress: true });
        })
        .then(() => {
          this.setState({ isSubmitting: false });
          this.props.fetchProjectPreference(
            this.props.match.params.id,
            'favorite_rules'
          );
          // this.props.history.push('/dashboard');
        });
    } else {
      this.setState({ dataValidationInProgress: false });
    }
  };

  subscribeToJobUpdate = jobId => {
    const eventsToSubscribeTo = Object.keys(socketAutocheckEvents).map(
      eventName => ({
        eventName,
        handler: data => {
          if (data.job_id === jobId) {
            if (eventName === 'RULE_DATA_VALIDATION_DONE') {
              this.setState({ dataValidationInProgress: false });
              this.onJobUpdate(data);
            } else if (eventName === 'RULE_DATA_VALIDATION_ON_ERROR') {
              Message.error(
                this.props.t('error:rule_data_validation_process_failed')
              );
              this.setState({ dataValidationInProgress: false });
            }
          }
        }
      })
    );
    try {
      this.props.subscribeToEvents(eventsToSubscribeTo);
    } catch (err) {
      console.log(err);
    }
  };

  onJobUpdate = data => {
    const projectId = this.props.match.params.id;
    const stageName = this.props.match.params.stageName;

    this.props.history.replace(
      `/project/${projectId}/${stageName}/autocheck?check-type=${data.type}&jobId=${data.job_id}`
    );
  };

  initWithJobData = async jobId => {
    const projectId = this.props.match.params.id;
    const stageName = this.props.match.params.stageName;
    try {
      // Fetch data validation result
      const { resultContent: ruleDataValidationResult, job } =
        await this.props.fetchJobWithRuleDataValidation(
          projectId,
          stageName,
          jobId,
          false
        );
      const errorMapping = getRuleDataValidationResultErrors(
        ruleDataValidationResult
      );
      // init checked keys
      const previousRulesChecked = JSON.parse(job.rules_check);
      // Error mapping allow us to unselect rule with error
      const previousRuleTreeKeyChecked = getRuleTreeKeyFromRuleIDList(
        this.props.rulesTree,
        previousRulesChecked,
        errorMapping
      );

      // if any rule id is in  previousRulesChecked, add the ruleKey to the checkedKeys and previousRulesChecked and the ruleId to rulesKeyChecked
      this.onChecked(previousRuleTreeKeyChecked);
      this.setState({
        ruleErrorMapping: errorMapping,
        isFetchingjobData: false
      });
    } catch (err) {
      Message.error(this.props.t(err.message));
    }
  };

  downloadRuleDataValidationFile = async jobId => {
    const projectId = this.props.match.params.id;
    const stageName = this.props.match.params.stageName;
    await fetchJobWithRuleDataValidation(projectId, stageName, jobId, true);
  };

  onClickCheckNow = async () => {
    try {
      if (this.props.isPaymentModuleEnabled) {
        const projectId = this.props.match.params.id;
        const stageName = this.props.match.params.stageName;
        const rules = Object.keys(this.state.rulesKeyChecked).filter(ruleId => {
          const rule = this.state.rulesKeyChecked[ruleId];
          return !rule.optionalDependency;
        });
        const { estimatedCU, estimatedNextCUBalance, currentCUBalance } =
          await this.props.getEstimatedComputingUnit(
            projectId,
            stageName,
            rules
          );
        this.setState({
          estimatedCU,
          estimatedNextCUBalance,
          currentCUBalance
        });
        this.toggleModal();
      } else {
        this.toggleModal();
      }
    } catch (err) {
      Message.error(this.props.t(`error:${err.message}`));
    }
  };

  render() {
    const {
      isSubmitting,
      isLoaded,
      rulesChecked,
      checkType,
      isConfirmationModalOpen,
      rulesKeyChecked,
      dataValidationInProgress,
      isFetchingjobData,
      ruleErrorMapping,
      jobId,
      hasJob,
      searchVal,
      filteredRulesTree,
      expandedKeys,
      estimatedCU,
      estimatedNextCUBalance,
      currentCUBalance
    } = this.state;
    const {
      models,
      models2D,
      t,
      match,
      rules,
      rulesTree,
      isRuleDataValidationEnabled,
      isPaymentModuleEnabled
    } = this.props;

    const { id: projectId, stageName } = match.params;
    const filteredRulesChecked = filter(
      rulesKeyChecked,
      r => !r.optionalDependency
    );
    const models3d = models.filter(m => !m.removed);
    return (
      <>
        {isLoaded && (
          <Container>
            <div className="mt-3 mb-5 shadow rounded">
              <div className="p-3">
                {!this.props.type && (
                  <Row>
                    <GoBackButton
                      redirectTo={`/project/${projectId}/models/${stageName}`}
                    />
                  </Row>
                )}
              </div>
              <div className="position-relative">
                <div
                  id="loading-overlay"
                  className={`rounded justify-content-center align-items-center ${
                    dataValidationInProgress || isFetchingjobData
                      ? 'd-flex'
                      : 'd-none'
                  } ${autocheckStyles.allRulesContainer}`}>
                  <div className="d-flex flex-column text-white">
                    <div className="d-flex justify-content-center">
                      <span
                        className={`spinner-border spinner-border-sm ${autocheckStyles.spinner}`}
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                    {dataValidationInProgress && (
                      <div>
                        {t('autochecker:rule_data_validation_in_progress')}
                      </div>
                    )}
                    {isFetchingjobData && (
                      <div>{t('autochecker:fetching_job_data')}</div>
                    )}
                  </div>
                </div>
                <div className="p-3">
                  <Row className="mt-2">
                    <Col className="mr-3 d-flex justify-content-between">
                      <h4 className="text-primary">
                        {(function () {
                          switch (checkType) {
                            case 'regulatory':
                              return t('autochecker:regulatory_check');
                            case 'clash':
                              return t('autochecker:clash_check');
                            case 'quality':
                              return t('autochecker:quality_check');
                            case 'bimdata':
                              return t('autochecker:bim_data_check');
                          }
                        })()}
                      </h4>
                      <div>
                        {isRuleDataValidationEnabled && (
                          <>
                            {!hasJob || !jobId ? (
                              <Button
                                disabled={
                                  rulesChecked.length === 0 ||
                                  models.length === 0 ||
                                  isSubmitting ||
                                  dataValidationInProgress
                                }
                                onClick={this.onDataValidation}
                                size="sm"
                                className="px-4 font-weight-bold mr-2"
                                color="primary">
                                {t('autochecker:rule_data_validation')}
                              </Button>
                            ) : (
                              <Button
                                // onClick={this.downloadRuleDataValidationFile}
                                onClick={e => e.stopPropagation()}
                                tag="a"
                                download={`rule_data_validation_${jobId}.json`}
                                href={`${
                                  process.env.REACT_APP_API_ENDPOINT[
                                    process.env.REACT_APP_API_ENDPOINT.length -
                                      1
                                  ] == '/'
                                    ? process.env.REACT_APP_API_ENDPOINT.slice(
                                        0,
                                        -1
                                      )
                                    : process.env.REACT_APP_API_ENDPOINT
                                }/api/project/${projectId}/${stageName}/ruleDataValidationResult/${jobId}?isDownload=true`}
                                size="sm"
                                className="px-4 font-weight-bold mr-2"
                                color="primary">
                                {t(
                                  'autochecker:download_rule_data_validation_result'
                                )}
                              </Button>
                            )}
                          </>
                        )}
                        <Button
                          disabled={
                            rulesChecked.length === 0 ||
                            models.length === 0 ||
                            isSubmitting ||
                            dataValidationInProgress
                          }
                          onClick={this.onClickCheckNow}
                          size="sm"
                          className="px-4 font-weight-bold"
                          color="success">
                          {t('autochecker:check_now')}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="py-3">
                    <Col className="">
                      <div className="d-flex flex-column justify-content-start">
                        <ListGroup>
                          <ListGroupItem
                            className={`font-weight-bold shadow-sm text-primary ${autocheckStyles.modelListGroupItemTitle}`}>
                            {t('project:model_list')}
                          </ListGroupItem>

                          <div
                            className={
                              autocheckStyles.modelListGroupItemContainer
                            }>
                            {models3d.map(f => {
                              return (
                                <ListGroupItem
                                  key={f.name}
                                  className="shadow-sm">
                                  <Row className="d-flex justify-content-between px-2 ">
                                    <div className="d-flex flex-column justify-content-between">
                                      <div>
                                        {f.name} - {prettyBytes(f.size)}
                                      </div>
                                      {f.date && (
                                        <div className="text-secondary">
                                          last updated:{' '}
                                          {timeAgoFormatting(f.date)} ago
                                        </div>
                                      )}
                                    </div>
                                  </Row>
                                </ListGroupItem>
                              );
                            })}
                          </div>
                        </ListGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col className="">
                      <div className="flex-grow-1">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="search"
                            name="search_rule"
                            value={searchVal}
                            // id={`search-${packageType}`}
                            placeholder="Search"
                            onChange={e =>
                              this.setState({ searchVal: e.target.value })
                            }
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col className="">
                      <div className="d-flex flex-column justify-content-start">
                        {filteredRulesTree.filter(i => i.show !== false)
                          .length > 0 ? (
                          <div
                            className={
                              autocheckStyles.rulesDropdownComponentContainer
                            }>
                            <RulesDropdown
                              onChecked={this.onChecked}
                              checkedKeys={rulesChecked}
                              expandedKeys={expandedKeys}
                              disabled={isSubmitting}
                              direction="down"
                              caret
                              altLabelLink={t(
                                'autochecker:open_description_tooltip'
                              )}
                              ruleType={checkType}
                              rules={filteredRulesTree}
                              // rules={rulesTree}
                              ruleErrorMapping={ruleErrorMapping}
                            />
                            {rulesChecked.length === 0 &&
                              this.state.isRequested && (
                                <div className="alert alert-danger mt-2">
                                  {t('autochecker:minimum_rules_error')}
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="text-muted">
                            {t('autochecker:no_result')}
                          </div>
                        )}
                        <ConfirmationModal
                          filteredRulesChecked={filteredRulesChecked}
                          checkType={checkType}
                          submit={this.onSubmit}
                          isOpen={isConfirmationModalOpen}
                          toggle={this.toggleModal}
                          estimatedCU={estimatedCU}
                          estimatedNextCUBalance={estimatedNextCUBalance}
                          currentCUBalance={currentCUBalance}
                        />
                      </div>
                      {Object.keys(ruleErrorMapping).length > 0 && (
                        <div>
                          <span className="mr-1">
                            <i className="fas fa-exclamation-circle text-warning"></i>
                          </span>
                          <span>
                            {t('autochecker:has_rule_not_passing_validation')}
                          </span>
                        </div>
                      )}
                      <hr />
                      {rulesChecked.length > 0 && (
                        <>
                          <div
                            id="toggler"
                            className="pointer d-flex justify-content-between">
                            <div className="text-muted ">
                              {filteredRulesChecked.length}{' '}
                              {t('number_rules_checked')}
                            </div>
                            <div>
                              <i className="fas fa-chevron-down"></i>
                            </div>
                          </div>
                          <UncontrolledCollapse toggler="#toggler">
                            <div
                              className={`border ${autocheckStyles.rulesCheckedContainer}`}>
                              {filteredRulesChecked.map((r, index) => {
                                return (
                                  <CustomInput
                                    type="checkbox"
                                    key={`ruleChekcked-${index}`}
                                    id={`ruleChekcked-${index}`}
                                    label={`${r.name}${
                                      r.disable ? '(dependency)' : ''
                                    }`}
                                    disabled={!!r.disable}
                                    checked={true}
                                    onChange={e => {
                                      const packageKeys = r.treeKey
                                        .split(RULES_KEY_SEPARTOR)[0]
                                        .split('-')
                                        .reduce((prev, split, index) => {
                                          const next = [
                                            ...prev,
                                            [...prev, split].join('-')
                                          ];
                                          if (index > 0) {
                                            return next;
                                          } else {
                                            return [];
                                          }
                                        }, [])
                                        .map(k => `packageKey-${k}`);
                                      const nextRulesChecked =
                                        rulesChecked.filter(key => {
                                          return (
                                            key !== r.treeKey &&
                                            !packageKeys.includes(key)
                                          );
                                        });
                                      this.setState({
                                        rulesChecked: nextRulesChecked
                                      });
                                      this.onChecked(nextRulesChecked);
                                      // this.setState({
                                      //   addRulesToFavoriteCheckbox: e.target.checked
                                      // });
                                    }}
                                    // inline
                                  />
                                );
                              })}
                            </div>
                          </UncontrolledCollapse>

                          {/* <div>
                            <span className="font-weight-bold">OPTIONAL: </span>
                            <div>
                              <CustomInput
                                type="checkbox"
                                id="onAddToFavoritesCheckbox"
                                label="Save selected rules to favorites"
                                checked={this.state.addRulesToFavoriteCheckbox}
                                onChange={(e) => {
                                  this.setState({
                                    addRulesToFavoriteCheckbox:
                                      e.target.checked,
                                  });
                                }}
                                inline
                              />
                            </div>
                          </div> */}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, { match, ...rest }) => {
  const project = state.projects[match.params.id];
  return {
    project,
    models: state.modelFiles,
    models2D: state.model2DFiles,
    rulesTree: state.rules.rulesTree,
    rules: state.rules.rulesSet,
    userId: state.authUser.userId,
    isRuleDataValidationEnabled: state.app.featureList.ruleDataValidationCheck,
    isPaymentModuleEnabled: state.app.featureList.paymentModule,
    checkTypes: state.app.featureList.checkingType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: projectID => dispatch(fetchProject(projectID)),
    fetchProjectStage: (projectId, stageName) =>
      dispatch(fetchProjectStage(projectId, stageName)),
    queryECheck: (
      projectId,
      stageName,
      rules,
      dependencyRules,
      jobType,
      isAddRulesToFavorites
    ) =>
      dispatch(
        queryECheck(
          projectId,
          stageName,
          rules,
          dependencyRules,
          jobType,
          isAddRulesToFavorites
        )
      ),
    queryRuleDataValidation: (
      projectId,
      stageName,
      rules,
      jobType,
      isAddRulesToFavorites
    ) =>
      dispatch(
        queryRuleDataValidation(
          projectId,
          stageName,
          rules,
          jobType,
          isAddRulesToFavorites
        )
      ),
    fetchJobWithRuleDataValidation: (projectId, stageName, jobId, isDownload) =>
      dispatch(
        fetchJobWithRuleDataValidation(projectId, stageName, jobId, isDownload)
      ),
    getEstimatedComputingUnit: (projectId, stageName, rules) =>
      dispatch(getEstimatedComputingUnit(projectId, stageName, rules)),
    limitRunningJobs: (projectId, stageName) =>
      dispatch(limitRunningJobs(projectId, stageName)),
    fetchProjectPreference: (projectId, type) =>
      dispatch(fetchProjectPreference(projectId, type)),
    fetchRules: type => dispatch(fetchRules(type))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['autochecker', 'error']),
  withSocketIO
)(Autochecker);
