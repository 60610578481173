import React, { Component } from 'react';
import {
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Row,
  Col
} from 'reactstrap';

import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import CustomInput from '../../../../UI/Input/customInput';
import Message from '../../../../UI/Message';

const formikConfig = props => {
  return {
    initialValues: {
      email: props.userData.email,
      id: props.userData.id
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      props
        .verifyUserRegistrationAsAdmin(
          values.id,
          props.action === 'approve'
            ? { is_verified: true }
            : {
                is_verified: false,
                is_active: false
              }
        )
        .then(res => {
          if (res === 'success') Message.success('Verified');
          setSubmitting(false);
          props.toggle();
        })
        .catch(err => {
          Message.error(props.t(`error:${err.message}`));
        });
    }
  };
};

class UserCreation extends Component {
  render() {
    const {
      isLoaded,
      modal,
      toggle,
      t,
      modalHeader,
      buttonText,
      userData,
      action,
      buttonElement
    } = this.props;

    return (
      Object.entries(userData).length !== 0 && (
        <Container>
          <Formik {...formikConfig(this.props)}>
            {formikProps => {
              const { resetForm } = formikProps;
              const resetToggleModal = () => {
                toggle();
              };
              return (
                <Modal
                  isOpen={modal}
                  toggle={resetToggleModal}
                  className={this.props.className}
                  backdrop={'static'}
                  size="md">
                  <ModalHeader toggle={resetToggleModal}>
                    {modalHeader ? modalHeader : ''}
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Are you sure you want to {action} <b>{userData.email}</b>{' '}
                      registration ?
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      color={buttonElement.color}
                      disabled={formikProps.isSubmitting}
                      onClick={formikProps.handleSubmit}>
                      <i className={buttonElement.icon} />
                      <span className="ml-2">{buttonElement.text}</span>
                    </Button>{' '}
                    <Button color="secondary" onClick={resetToggleModal}>
                      {t('cancel')}
                    </Button>
                  </ModalFooter>
                </Modal>
              );
            }}
          </Formik>
        </Container>
      )
    );
  }
}
export default withTranslation(['admin_user'])(UserCreation);
