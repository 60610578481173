import { jobsActionType } from '../../constants/actionType';
import { clone } from 'lodash';

const initialState = [];

const { LIST_JOBS_AS_ADMIN, UPDATE_JOB } = jobsActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_JOBS_AS_ADMIN:
      return action.jobList;
    case UPDATE_JOB:
      const jobsArray = clone(state);

      const indexOfJobToUpdate = state.findIndex(
        obj => obj.job_id === action.jobData.job_id
      );
      if (indexOfJobToUpdate >= 0) {
        const nextJob = jobsArray[indexOfJobToUpdate];

        jobsArray[indexOfJobToUpdate] = {
          ...nextJob,
          ...action.jobData
        };
      }

      return jobsArray;
    default:
      return state;
  }
};

export default reducer;
