import React from 'react';
import { connect } from 'react-redux';

import RuleHistoryManagement from '../../../Shared/RuleHistoryManagement';
import {
  fetchRuleHistoryList,
  fetchRulesAdmin
} from '../../../../store/actions';

const AdminRuleManagement = props => {
  return <RuleHistoryManagement level="domain" {...props} />;
};
const mapStateToProps = (state, { match }) => {
  return {
    rule_history: state.rule_history_admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRuleHistoryList: ({ ruleLevel, ruleType, ruleId }) =>
      dispatch(fetchRuleHistoryList({ ruleLevel, ruleType, ruleId })),
    fetchRules: params => dispatch(fetchRulesAdmin({ ...params }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRuleManagement);
