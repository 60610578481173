import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import UserDetailPresenter from './UserDetailPresenter';
import {
  fetchUserAsAdmin,
  resetUserAsAdmin,
  editSelectedUserAsAdmin,
  fetchUserGroups
} from '../../../../store/actions/users';
import {
  getUserUsageAction,
  updateUserUsageAction
} from '../../../../store/actions/subscription';

const mapStateToProps = (state, { match }) => {
  const userId = match.params.id;
  return {
    userData: state.users[userId],
    authenticationMethod: state.app.featureList.authenticationMethod,
    isAccessControlV2Enabled: state.app.featureList.accessRightV2
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserAsAdmin: userId => dispatch(fetchUserAsAdmin(userId)),
    resetUser: () => dispatch(resetUserAsAdmin()),
    editSelectedUserAsAdmin: (userId, newData) =>
      dispatch(editSelectedUserAsAdmin(userId, newData)),
    getUserUsage: userId => dispatch(getUserUsageAction(userId)),
    updateUserUsage: (userId, newData) =>
      dispatch(updateUserUsageAction(userId, newData)),
    fetchUserGroups: userId => dispatch(fetchUserGroups(userId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['admin_user'])
)(UserDetailPresenter);
