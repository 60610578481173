// ! Replaced by new password? TO DELETE?
import { connect } from 'react-redux';
import { resetPassword } from '../../../store/actions/authUser';
import ResetPasswordPresenter from './ResetPasswordPresenter';

const mapStateToProps = state => {
  return {};
};

const dispatchStateToProps = dispatch => {
  return {
    resetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(ResetPasswordPresenter);
