import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const ProjectListPagination = props => {
  const {
    currentTile,
    handlePaginationClick,
    totalProjects,
    sizePerPage,
    currentPage
  } = props;

  const totalPagesCount = Math.ceil(totalProjects / sizePerPage);

  const generatePaginationItems = () => {
    return [...Array(totalPagesCount)].map((page, i) => {
      return (
        <PaginationItem active={i === currentPage - 1} key={i}>
          <PaginationLink
            onClick={e => {
              handlePaginationClick(e, i + 1);
            }}
            href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    });
  };

  return (
    <>
      <Pagination className="flex-wrap" aria-label="Page navigation example">
        <PaginationItem disabled={currentPage <= 1 || totalProjects === 0}>
          <PaginationLink
            first
            onClick={e => handlePaginationClick(e, 1)}
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage <= 1 || totalProjects === 0}>
          <PaginationLink
            onClick={e => handlePaginationClick(e, currentPage - 1)}
            href="#">
            Prev
          </PaginationLink>
        </PaginationItem>
        {currentTile.tileSize === 'quarter' ? '' : generatePaginationItems()}

        <PaginationItem
          disabled={currentPage == totalPagesCount || totalProjects === 0}>
          <PaginationLink
            onClick={e => handlePaginationClick(e, currentPage + 1)}
            href="#">
            Next
          </PaginationLink>
        </PaginationItem>
        <PaginationItem
          disabled={currentPage == totalPagesCount || totalProjects === 0}>
          <PaginationLink
            last
            onClick={e => handlePaginationClick(e, totalPagesCount)}
            href="#"
          />
        </PaginationItem>
      </Pagination>
    </>
  );
};

export default ProjectListPagination;
