import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Line,
  Tooltip
} from 'recharts';

import {
  Toast,
  ToastHeader,
  ToastBody,
  Card,
  CardTitle,
  CardText,
  Button
} from 'reactstrap';

import styles from './Statistics.module.css';

const color_style = [
  { name: 'Non-Compliant', color: '#ff0000' },
  { name: 'Compliant', color: '#4bb914' },
  { name: 'Attention', color: '#ffc000' },
  { name: 'Information', color: '#00518e' },
  { name: 'Non-Compliant', color: '#ff0000' },
  {
    name: 'No Changes in Geometry',
    color: '#92d050'
  },
  {
    name: 'With Geometry Differences',
    color: '#ff0000'
  },
  {
    name: 'Fulfilled Mandatory Requirements',
    color: '#92d050'
  },
  {
    name: 'Failed Mandatory Requirements',
    color: '#ff0000'
  }
];

const RADIAN = Math.PI / 180;

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const GraphComponent = props => {
  const activeCheckTab = props.activeCheckTab;
  const { t } = props;
  const legendLayout =
    activeCheckTab === 'regulatory_compliance'
      ? { layout: 'horizontal' }
      : {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          wrapperStyle: { left: '75%' }
        };

  const data =
    props.activeCheckData.length > 0 &&
    props.activeCheckData[0].statResult
      .map(data => {
        return {
          name: data.dataKey,
          value: data.count,
          data_key: data.data_key
        };
      })
      .sort((a, b) => {
        return (
          color_style.findIndex(i => {
            return i.name === a.name;
          }) -
          color_style.findIndex(i => {
            return i.name === b.name;
          })
        );
      });

  const [activeIndex, setActiveIndex] = useState(0);

  const [isAnimationEnd, setAnimationEnd] = useState(false);

  const onPieEnter = (data, index, dex) => {
    if (!!isAnimationEnd) {
      setActiveIndex(index);
    }
  };

  const isGraphHasData = !!(
    data.length > 0 &&
    data.some(key => {
      return key.value > 0;
    })
  );

  const CustomTooltip = data => {
    const { active, payload } = data;

    if (active) {
      const labelName = payload[0].name;
      const value = payload[0].value;
      return (
        <>
          <div className={styles.customTooltipMaxHeight}>
            <Card inverse className={styles.customTooltipCard}>
              <CardText>Total : {value}</CardText>
            </Card>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {!isGraphHasData && (
        <h1 className={`text-muted ${styles.noDataClass} text-center`}>
          {t('no_data')}
        </h1>
      )}

      {isGraphHasData && (
        <ResponsiveContainer width="95%" height="95%">
          <PieChart>
            <Tooltip content={<CustomTooltip />} />
            <Pie
              data={data}
              innerRadius="50%"
              outerRadius="90%"
              fill="#8884d8"
              paddingAngle={5}
              labelLine={false}
              label={renderCustomizedLabel}
              dataKey="value"
              onMouseEnter={onPieEnter}
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onAnimationStart={() => {
                setAnimationEnd(false);
              }}
              onAnimationEnd={() => {
                setAnimationEnd(true);
              }}>
              {data.map((entry, index) => {
                const color = color_style.find(
                  i => i.name === entry.name
                ).color;
                return <Cell key={`cell-${index}`} fill={color} />;
              })}
            </Pie>

            <Legend
              {...legendLayout}
              payload={data.map((entry, index) => {
                const color = color_style.find(
                  i => i.name === entry.name
                ).color;
                return {
                  id: index,
                  type: 'square',
                  value: `${entry.name} `,
                  color
                };
              })}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default compose(withTranslation(['statistics']))(GraphComponent);
