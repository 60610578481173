import React, { Component } from 'react';

import { UncontrolledTooltip, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import ReactTable from '../../Shared/ReactTable/index';
import PropTypes from 'prop-types';
import { dateFormatting } from '../../../locales/dateFormat';

const NoData = () => <h1>NO DATA</h1>;

const statusToIcon = ({ status, job_id }, t) => {
  switch (status) {
    case 'DONE':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {t('results:status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'IN_PROCESS':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {t('results:status_in_process')}
          </UncontrolledTooltip>
        </>
      );
    case 'PENDING':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {t('results:status_in_process')}
          </UncontrolledTooltip>
        </>
      );

    case 'ON_ERROR':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-exclamation-circle text-danger fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {t('results:status_on_error')}
          </UncontrolledTooltip>
        </>
      );
    case 'OBSOLETE':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-exclamation-triangle text-warning  fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {t('results:status_obsolete')}
          </UncontrolledTooltip>
        </>
      );
  }
};

// TODO translation on the table column header and others
// const columns = t => [
//   {
//     dataField: 'job_id',
//     text: t('checking_number')
//   },
//   {
//     dataField: 'date_created',
//     text: t('date'),
//     formatter: cell => {
//       return dateFormatting(cell, 'defaultHour');
//     }
//   },
//   {
//     dataField: 'files',
//     text: t('result_files')
//   },
//   {
//     dataField: 'status',
//     text: t('status')
//   }
// ];

class SubmissionResultListing extends Component {
  fileTypeFormatting = (row, file, index, t) => {
    const fileType = file.split('.').pop().toLowerCase();
    const formatConfig = (fileExtension => {
      switch (fileExtension) {
        case 'pdf': {
          return {
            colorButton: 'danger',
            fileIcon: 'fas fa-file-pdf',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'xlsx': {
          return {
            colorButton: 'success',
            fileIcon: 'fas fa-file-excel',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'docx': {
          return {
            colorButton: 'primary',
            fileIcon: 'fas fa-file-word',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'fxr': {
          return {
            colorButton: 'success',
            fileIcon: 'fas fa-building',
            label: t('results:open_result_in_viewer'),
            linkType: 'normal',
            isFXR: true
          };
        }
        case 'bcfzip': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-archive',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'zip': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-archive',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'html': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-code',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        default: {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-question',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
      }
    })(fileType);

    return formatConfig.isFXR ? (
      <div key={`${row.job_id}-${index}`}>
        <Button
          tag="a"
          href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.fxrViewerToken}`}
          // to={{
          //   pathname: `/project/${this.props.projectId}/${
          //     this.props.stageName
          //   }/viewer`,
          //   search: `viewerMode=3d&token=${row.fxrViewerToken}`
          // }}
          rel="noopener noreferrer"
          target="_blank"
          id={`fileLink-${row.job_id}-${fileType}`}
          onClick={e => {
            e.stopPropagation();
            e.stopPropagation();
          }}
          size="lg"
          color={formatConfig.colorButton}
          className="text-white"
          disabled={row.is_obsolete ? true : false}>
          <i className={formatConfig.fileIcon} />
        </Button>
        <UncontrolledTooltip
          fade={false}
          target={`fileLink-${row.job_id}-${fileType}`}
          delay={0}>
          {formatConfig.label}
        </UncontrolledTooltip>
      </div>
    ) : (
      <div key={`${row.job_id}-${index}`}>
        <Button
          tag="a"
          download={`${this.props.projectName}_report_${row.job_id.substring(
            row.job_id.length - 12
          )}.${fileType}`}
          onClick={e => e.stopPropagation()}
          href={`${
            process.env.REACT_APP_API_ENDPOINT[
              process.env.REACT_APP_API_ENDPOINT.length - 1
            ] == '/'
              ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
              : process.env.REACT_APP_API_ENDPOINT
          }/api/project/${this.props.projectId}/${this.props.stageName}/${
            row.job_id
          }/${file}/download`}
          id={`fileLink-${row.job_id}-${fileType}`}
          size="lg"
          color={formatConfig.colorButton}
          className="text-white">
          <i className={formatConfig.fileIcon} />
        </Button>
        <UncontrolledTooltip
          fade={false}
          target={`fileLink-${row.job_id}-${fileType}`}
          delay={0}>
          {formatConfig.label}
        </UncontrolledTooltip>
      </div>
    );
  };

  reactTableColumns = t => [
    {
      id: 'job_id',
      accessor: 'job_id',
      Header: t('serial_number'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return <div>{row.index + 1}</div>;
      }
    },
    {
      id: 'date_created',
      accessor: 'date_created',
      Header: t('date'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return dateFormatting(value, 'defaultHour');
      }
    },
    {
      id: 'fileavailable',
      accessor: 'fileavailable',
      Header: t('result_files'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <div className="d-flex flex-wrap">
            {value &&
              Array.isArray(value) &&
              value.map((file, index) => {
                return (
                  <div
                    key={`${row.original.job_id}-${index}`}
                    className={index > 0 ? 'ml-1' : ''}>
                    {this.fileTypeFormatting(row.original, file, index, t)}
                  </div>
                );
              })}
          </div>
        );
      }
    },
    {
      id: 'type',
      accessor: 'type',
      Header: t('results:type'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => (value ? t(`project:result_${value}`) : '')
    },
    {
      id: 'status',
      accessor: 'status',
      Header: t('status'),
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        if (row) {
          return statusToIcon(row.original, this.props.t);
        } else {
          return '';
        }
      }
    }
  ];
  render() {
    const { data, isValid, selectRow, t, formikProps } = this.props;
    return (
      <ReactTable
        data={data}
        idColumn="job_id"
        // isValid={isValid || true}
        columns={this.reactTableColumns(t)}
        // bordered={false}
        // hover
        // condensed={true}
        noDataIndication={<NoData />}
        selectRow={selectRow}
      />
    );
  }
}

SubmissionResultListing.propTypes = {
  data: PropTypes.array.isRequired,
  stageName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  selectRow: PropTypes.object,
  isValid: PropTypes.bool
};

export default withTranslation(['submission', 'results', 'project'])(
  SubmissionResultListing
);
