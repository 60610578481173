import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';

import UserCreation from './UserCreationModal';
import PendingRegistration from './PendingRegistration';
import UserListingPresenter from './UserListingPresenter';
import './User.module.css'

const userManagementTabs = ['all', 'pending_registration'];
const userFunction = (func, t) => {
  let role = '';
  switch (func) {
  case 'QP':
    role = t('admin_user:qualified_person');
    break;
  case 'PO':
    role = t('admin_user:processing_officer');
    break;
  default:
    role = t('admin_user:administrator');
    break;
  }
  return role;
};

class UsersPresenter extends Component {
  constructor(props) {
    super(props);
    const activeTab =
      {
        list: 'all',
        pending_registration: 'pending_registration'
      }[props.match.params.tab] || 'list';
    this.state = {
      createUserModal: false,
      activeTab,
      selectedUserIds: [],
      dropdownOpen: false
    };
  }

  toggleCreateUserModal = () => {
    this.setState({ createUserModal: !this.state.createUserModal });
  };

  toggle(tab) {
    const selectedTab = tab === 'all' ? 'list' : tab;
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.props.history.replace(`/admin/users/${selectedTab}`);
    }
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const { t } = this.props;

    return (
        <Container fluid className="mx-3">
          <h1 className="text-primary">{t('admin_user:user_list')}</h1>
          <Row>
            <Col lg={2}>
              <div id="userManagementTabs" ref={this.sidebarRef}>
                <Nav className="shadow mt-3 rounded" tabs vertical>
                  {userManagementTabs.map(tab => (
                    <NavItem
                      key={`${tab}`}
                      className={classnames({
                        'border-right border-primary rounded border-sz-3 ':
                          this.state.activeTab === tab
                      })}>
                      <NavLink
                        className={classnames('bg-light rounded', {
                          'active font-weight-bold':
                            this.state.activeTab === tab
                        }, 'borderNone')}
                        onClick={() => {
                          this.toggle(tab);
                        }}>
                        {t(`adminUserVerification:${tab}`)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </Col>
            <Col lg={10}>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="all">
                  {this.state.activeTab == 'all' && (
                    <Container fluid>
                      <Row>
                        <Col>
                          <UserCreation
                            {...this.props}
                            modal={this.state.createUserModal}
                            toggle={this.toggleCreateUserModal}
                          />
                          <UserListingPresenter
                            {...this.props}
                            toggleCreateUserModal={this.toggleCreateUserModal}
                          />
                        </Col>
                      </Row>
                    </Container>
                  )}
                </TabPane>
                <TabPane tabId="pending_registration">
                  {this.state.activeTab == 'pending_registration' && (
                    <Container>
                      <Row>
                        <Col>
                          <PendingRegistration
                            {...this.props}
                            userFunction={userFunction}
                            sidebarRef={this.sidebarRef}
                          />
                        </Col>
                      </Row>
                    </Container>
                  )}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default UsersPresenter;
