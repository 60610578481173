import React from 'react';

import {
  CustomInput,
} from 'reactstrap';

const modelist = [
  {
    internal_name: 'HIDE',
    display_name: 'hide'
  },
  {
    internal_name: 'SUMMARY',
    display_name: 'summary'
  },
  {
    internal_name: 'DETAILED',
    display_name: 'detailed'
  }
];

const Mode = ({
  t,
  setTouched,
  setValues,
  touched,
  values,
  asFormikField = false,
  setFieldValue,
  handleSubmit
}) => {
  return modelist.map((mode, index) => {
    return (
      <CustomInput
        type="radio"
        name="compliant_message_mode"
        label={t(`rules:${mode.display_name}`)}
        id={mode.internal_name}
        key={`${mode.internal_name}-${index}`}
        checked={values.compliant_message_mode === mode.internal_name}
        onChange={async e => {
          if (!!asFormikField) {
            await setValues({
              ...values,
              compliant_message_mode: mode.internal_name
            });
            handleSubmit();
          } else {
            setValues({
              ...values,
              compliant_message_mode: mode.internal_name
            });
            setTouched({ ...touched, compliant_message_mode: true });
          }
        }}
      />
    );
  });
};

export default Mode;
