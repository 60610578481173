import React from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';

import { getIn } from 'formik';

const CustomInputFileComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  id,
  noValidation,
  noSuccessValidation,
  noErrorValidation,
  label,
  row = false,
  labelClassName,
  ...props
}) => {
  const handleChange = event => {
    setFieldValue(field.name, event.currentTarget.files[0]);
  };
  const handleClick = () => {
    setFieldTouched(field.name, true);
  };
  const isValid =
    !noValidation &&
    !noSuccessValidation &&
    getIn(touched, field.name) &&
    !getIn(errors, field.name);
  const isInvalid =
    !noValidation &&
    !noErrorValidation &&
    !!(getIn(touched, field.name) && getIn(errors, field.name));

  // Destructure field props to extract value as it's not allowed to progammatically set the value of file input
  const { value, ...nextFieldProps } = field;
  return (
    <FormGroup>
      {!!label && (
        <Label className={labelClassName} for={id || field.name}>
          {label}
        </Label>
      )}
      <InputGroup>
        <div className="d-flex w-100">
          <div className="d-flex flex-column flex-grow-1">
            <Input
              {...nextFieldProps}
              {...props}
              type="file"
              id={id || field.name}
              onChange={handleChange}
              onClick={handleClick}
              className="custom-file-input"
              invalid={isInvalid}
              valid={isValid}
            />
            <label className="custom-file-label" htmlFor={id || field.name}>
              {label}
            </label>
            {getIn(touched, field.name) && getIn(errors, field.name) && (
              <div className="invalid-feedback">
                {getIn(errors, field.name)}
              </div>
            )}{' '}
          </div>
        </div>
      </InputGroup>
    </FormGroup>
  );
};

export default CustomInputFileComponent;
