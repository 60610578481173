import React from 'react';
import ProjectListView from './ProjectListView';
import ProjectGridView from './ProjectGridView';
import ProjectListPagination from './ProjectListPagination';
import { Row, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from './ProjectGridView.module.css';

const ProjectList = props => {
  const {
    projects,
    view,
    filteredProjectKeys,
    userFunction,
    totalProjects,
    from,
    to
  } = props;

  return (
    <div className="py-2 px-3 h-100">
      {view.isGrid ? (
        //GRID VIEW
        <Row>
          {filteredProjectKeys.map(key => {
            const project = projects[key];
            return (
              <ProjectGridView
                {...props}
                userFunction={userFunction}
                project={project}
                key={key}
                id={key}
              />
            );
          })}
        </Row>
      ) : (
        //LIST VIEW
        <Row>
          <Container className="mt-2 h-100">
            <ProjectListView {...props} />
          </Container>
        </Row>
      )}

      <div className="d-flex flex-row flex-wrap align-items-center py-4 mt-3 ">
        <span
          className={`text-secondary mr-4 mb-2 ${styles.footerText}`}
          >
          Showing{' '}
          <span className="font-weight-bold">
            {from > totalProjects ? totalProjects : from}
          </span>{' '}
          to{' '}
          <span className="font-weight-bold">
            {to > totalProjects ? totalProjects : to}
          </span>{' '}
          of <span className="font-weight-bold">{totalProjects}</span> projects
        </span>

        <div className="d-flex flex-row align-items-center">
          <ProjectListPagination {...props} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const tiles = state.userPreference.dashboard_defaults.tiles;
  return {
    tiles,
    currentTile: tiles && tiles.user_projects ? tiles.user_projects : {},
    featureList: state.app.featureList
  };
};

export default compose(
  withTranslation(['dashboard']),
  connect(mapStateToProps, null)
)(ProjectList);
