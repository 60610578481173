import { userFunctionActionType } from '../../constants/actionType';

const initialState = {};

const { MERGE_USER_FUNCTION, RESET_USER_FUNCTION } = userFunctionActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_USER_FUNCTION:
      return initialState;
    case MERGE_USER_FUNCTION:
      return { ...state, ...action.roles };
    default:
      return state;
  }
};

export default reducer;
