import React, { useRef, useState } from 'react';
import { Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';

export const TextColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter, id },
  ...props
}) => {
  const inputRef = useRef();

  const [value, setValue] = useState(props.filters[id] || '');

  const clear = () => {
    inputRef.current.value = '';
    setFilter();
    props.filterToState(id);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setFilter(inputRef.current.value);
      props.filterToState(id, inputRef.current.value);
    }
  };

  return (
    <>
      <InputGroup>
        <Input
          key="input"
          bsSize="sm"
          className={`filter-input-color-black`}
          value={value}
          onClick={e => e.stopPropagation()}
          innerRef={inputRef}
          type="text"
          placeholder={props.placeholder}
          onKeyPress={handleKeyPress}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <InputGroupAddon className="bg-white" addonType="append">
          <Button
            color="dark"
            outline
            size="sm"
            onClick={e => {
              e.stopPropagation();
              setFilter(inputRef.current.value); // Set undefined to remove the filter entirely
              props.filterToState(id, inputRef.current.value);
            }}>
            <i className="fas fa-level-down-alt fa-rotate-90"></i>
          </Button>
          <Button
            color="dark"
            outline
            size="sm"
            onClick={e => {
              e.stopPropagation();
              clear();
            }}>
            <i className="fas fa-times"></i>
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  ...props
}) => {
  return (
    <select
      className="form-control-sm"
      value={props.filters[id]}
      onChange={e => {
        setFilter(e.target.value);
        props.filterToState(id, e.target.value);
      }}
      onClick={e => {
        e.stopPropagation();
      }}>
      <option className="text-secondary font-italic" value="">
        Select status
      </option>

      {Object.keys(props.options).map((option, i) => (
        <option key={i} value={option}>
          {props.options[option]}
        </option>
      ))}
    </select>
  );
};

export const DateRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  ...props
}) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows[0] && preFilteredRows[0].values[id];
    let max = preFilteredRows[0] && preFilteredRows[0].values[id];
    preFilteredRows.forEach(row => {
      min = row.values[id] <= min ? row.values[id] : min;
      max = row.values[id] >= max ? row.values[id] : max;
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <div className="d-flex justify-content-center flex-column w-100">
        <div className="d-flex">
          <div className="font-weight-normal mr-2">From:</div>{' '}
          <input
            // min={!!min && new Date(min).toISOString().slice(0, 10)}
            className="overflow-hidden"
            value={props.filters[id] && props.filters[id].from}
            type="date"
            onChange={e => {
              const val = e.target.value;
              setFilter((old = []) => {
                props.filterToState(id, {
                  from: val ? val : undefined,
                  to: !!(props.filters[id] && props.filters[id].to) && old[1]
                });
                return [val ? val : undefined, old[1]];
              });
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </div>
        <div className="d-flex">
          <div className="font-weight-normal mr-2">To:</div>{' '}
          <input
            // max={!!max && new Date(max).toISOString().slice(0, 10)}
            className="overflow-hidden"
            value={props.filters[id] && props.filters[id].to}
            type="date"
            onChange={e => {
              const val = e.target.value;
              setFilter((old = []) => {
                props.filterToState(id, {
                  from:
                    !!(props.filters[id] && props.filters[id].from) && old[0],
                  to: val ? val : undefined
                });
                return [old[0], val ? val : undefined];
              });
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    </>
  );
};
