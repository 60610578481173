import { feedbackActionType } from '../../constants/actionType';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case feedbackActionType.ADD_ATTACHMENTS: {
    return [...state, action.files];
  }
  case feedbackActionType.RESET_ATTACHMENTS: {
    return initialState;
  }
  case feedbackActionType.REMOVE_ATTACHMENT: {
    const fileIndex = fileId => fileId === action.payload.fileId;
    state.splice(fileIndex, 1);
    return [...state];
  }

  default:
    return state;
  }
};

export default reducer;
