import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap';

import './style.css';

const CustomInput = ({ children, ...otherProps }) => {
  return (
    <>
      <div className="row no-gutters">
        <div className="col">
          <div className="position-absolute py-2 px-3">
            <i className="fa fa-search"></i>
          </div>
          <Input
            {...otherProps}
            className="form-control border-secondary rounded-pill searchbar"
            placeholder="How can we help you?"
          />
        </div>
      </div>
      {children}
    </>
  );
};

const SearchInput = ({ className, suggestions }) => {
  const [term, setTerm] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const onChange = e => {
    setTerm(e.target.value);
  };

  const autoScrollToSection = title => {
    document.location.hash = `${title
      .replace(/\s/g, '-')
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .toLowerCase()}`;
  };

  const filteredSuggestions = suggestions.filter(
    item => item.title.toLowerCase().indexOf(term.toLowerCase()) > -1
  );

  return (
    <div className={className}>
      <Dropdown isOpen={!!term || (dropdownOpen && !!term)} toggle={toggle}>
        <DropdownToggle
          caret
          tag={CustomInput}
          type="search"
          className="searchDropdownToggle"
          value={term}
          onChange={onChange}></DropdownToggle>

        <DropdownMenu className="bg-white border searchDropdownMenu">
          {filteredSuggestions.length ? (
            filteredSuggestions.map((suggestion, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  setTerm('');
                  autoScrollToSection(suggestion.title);
                }}>
                {suggestion.title}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem
              key={`no-result-header`}
              className="text-center"
              header>
              No results
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SearchInput;
