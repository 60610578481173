import React, { Component, connect, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Dropdown,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import { compose } from 'recompose';
import { dateFormatting } from '../../../locales/dateFormat';
import styles from './ruleCheckStatusReport.module.css';

const errorType = (reason, t) => {
  switch (reason) {
    case 'check_engine_error':
      return t('results:check_engine_error');
    case 'report_generator_error':
      return t('results:report_generator_error');
    case 'user_canceled':
      return t('results:user_canceled');
    default:
      return t('results:default_error');
  }
};
const display_status = ({ status, ...jobInfo }, t) => {
  switch (status) {
    case 'DONE': {
      return (
        <span className="text-success">
          <i className="fas fa-check-circle mr-2" />
          <span className="text-dark">{t('results:status_completed')}</span>
        </span>
      );
    }
    case 'PARTIALLY_COMPLETED': {
      return (
        <span className={styles.partiallyCompletedDisplayStatus}>
          <i className="fas fa-check-circle mr-2" />
          <span className="text-dark">{t('results:status_partial')}</span>
        </span>
      );
    }
    case 'IN_PROCESS': {
      return (
        <span className="text-info">
          <i className="fas fa-spinner fa-spin mr-2" />
          <span className="text-dark">{t('results:status_process')}</span>
        </span>
      );
    }
    case 'GENERATING_REPORT': {
      return (
        <span className="text-info">
          <i className="fas fa-sync-alt fa-spin mr-2" />
          <span className="text-dark">
            {t('results:status_generating_report')}
          </span>
        </span>
      );
    }
    case 'PENDING': {
      return (
        <span className={styles.pendingDisplayStatus}>
          <i className="fas fa-hourglass-start mr-2" />
          <span className="text-dark">{t('results:status_pending')}</span>
        </span>
      );
    }
    case 'QUEUING': {
      return (
        <span className={styles.queuingDisplayStatus}>
          <i className="fa fa-layer-group mr-2"></i>
          <span className="text-dark">{t('results:status_queuing')}</span>
        </span>
      );
    }
    case 'ON_ERROR': {
      return (
        <span className="text-danger">
          <i className="fas fa-exclamation-circle mr-2" />
          <span className="text-dark"> {t('results:status_error')}</span>
        </span>
      );
    }
    case 'ERROR': {
      return (
        <span className="text-danger">
          <i className="fas fa-exclamation-circle mr-2" />
          <span className="text-dark"> {t('results:status_error')}</span>
        </span>
      );
    }
    case 'DELETED': {
      return (
        <span className="text-warning">
          <i className="fas fa-exclamation-circle mr-2" />
          <span className="text-dark">{t('results:status_deleted')}</span>
        </span>
      );
    }
    case 'OBSOLETE': {
      return (
        <span className=" text-warning ">
          <i className="fas fa-exclamation-triangle" />
          <span className="text-dark">{t('results:obsolete')}</span>
        </span>
      );
    }
    case 'AVAILABLE': {
      return (
        <span className="text-success">
          <i className="fas fa-check-circle mr-2" />
          <span className="text-dark">{t('results:status_completed')}</span>
        </span>
      );
    }
    case 'USER_CANCELLED': {
      return (
        <span className="text-danger">
          <i className="fas fa-window-close mr-2" />
          <span className="text-dark">
            {t('results:status_user_cancelled')}
          </span>
        </span>
      );
    }
    default: {
      return status;
    }
  }
};
const pop_over_body = ({ status, ...jobInfo }, t) => {
  switch (status) {
    case 'DONE': {
      return t('results:status_completed');
    }
    case 'PARTIALLY_COMPLETED': {
      const all_rules = jobInfo.rules_check || [];
      const completed = jobInfo.rules_completed || [];
      return `${t('results:partial_result_count')} : ${completed.length}/${
        all_rules.length
      }`;
    }
    case 'IN_PROCESS': {
      return t('results:status_in_process');
    }
    case 'PENDING': {
      return t('results:status_pending');
    }
    case 'QUEUING': {
      return t('results:status_queuing');
    }
    case 'ON_ERROR': {
      const reason = jobInfo.error_reason;
      return errorType(reason, t);
    }
    case 'ERROR': {
      const reason = jobInfo.error_reason;
      return errorType(reason, t);
    }
    case 'DELETED': {
      return false;
    }
    case 'OBSOLETE': {
      return t('results:obsolete');
    }
    case 'GENERATING_REPORT': {
      return t('results:generating_report');
    }
    case 'AVAILABLE': {
      return t('results:status_completed');
    }
    case 'USER_CANCELLED': {
      console.log({ jobInfo });
      return (
        <div className="d-flex flex-column">
          <div>{t('results:status_user_cancelled')}</div>
          <div>
            <span className="font-weight-bold">
              {t('results:cancelled_by')} :{' '}
            </span>
            {jobInfo.cancel_user_name || jobInfo.cancelled_by || 'unknown'}
          </div>
          <div>
            <span className="font-weight-bold">
              {t('results:cancellation_date')} :{' '}
            </span>
            {dateFormatting(jobInfo.cancellation_date, 'defaultHour')}
          </div>
        </div>
      );
    }
    default: {
      return status;
    }
  }
};

const JobStatusPopUpInfo = ({ jobInfo, t }) => {
  const { job_id, rules_check, rules_completed, status } = jobInfo;
  const status_from_model_comparison =
    !!jobInfo.comparison_rel && jobInfo.comparison_rel.status;
  const partiallyCompleted =
    !!rules_completed &&
    Array.from(
      new Set(
        rules_completed
          // .filter(rules => rules && !!rules.completed)
          .map(rule => (rule && rule.rule_id ? rule.rule_id : rule))
      )
    );
  const isPartiallyCompleted =
    !!partiallyCompleted &&
    partiallyCompleted.length > 0 &&
    rules_check.length > partiallyCompleted.length;
  let modifiedStatus = status;
  if (status === 'IN_PROCESS' && isPartiallyCompleted) {
    modifiedStatus = 'PARTIALLY_COMPLETED';
  }
  if (status === 'IN_PROCESS' && !isPartiallyCompleted) {
    modifiedStatus = 'IN_PROCESS';
  }
  if (status_from_model_comparison) {
    // if  current tab is for model comparison then get the status from comparison_rel
    modifiedStatus = status_from_model_comparison;
  }
  return (
    <div>
      <div id={`jobId-${job_id}-status`}>
        <span className="pr-3">
          {display_status({ ...jobInfo, status: modifiedStatus }, t)}
        </span>
      </div>
      <UncontrolledPopover
        boundariesElement="window"
        trigger="hover"
        className="rule-popover-container"
        target={`jobId-${job_id}-status`}>
        <PopoverBody>
          {pop_over_body({ ...jobInfo, status: modifiedStatus }, t)}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
const JobStatusPopUpInfoContainer = props => {
  return <JobStatusPopUpInfo {...props} />;
};
// const mapStateToProps = (state, props) => {};
// const mapDispatchToProps = (dispatch, props) => {};
export default compose()(JobStatusPopUpInfoContainer);
