import React, { useState, useEffect, useRef } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink as BootstrapNavLink
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const HeaderProgressBar = ({ progressBarWidth, screenWidth }) => {
  const progressBarRef = useRef();
  useEffect(() => {
    progressBarRef.current.style['width'] = `${progressBarWidth}%`;
  }, [progressBarWidth]);
  return (
    <div className="custom-progress position-absolute">
      <div className="custom-progress--wpr">
        <div ref={progressBarRef} className="custom-progress--bar"></div>
      </div>
    </div>
  );
};

const Header = ({
  setActiveNavLink,
  navLinks,
  isOnTop,
  progressBarWidth,
  scrollToActiveRow,
  scrollY,
  landingPageSections,
  isLoggedIn,
  authUser,
  screenWidth,
  t
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const filteredNavLinks = navLinks.filter(
    link => !['login', 'register'].includes(link.id)
  );

  useEffect(() => {
    setActiveNavLink(null);
  }, []);

  return (
    <Navbar
      color="light"
      light
      expand="lg"
      color="none"
      fixed="top"
      className={`custom-navbar-width landing-page-main-nav-bar w-100 ${
        isOnTop
          ? 'navbar-nav-bg-transition custom-backcolor-primary'
          : 'bg-white'
      }`}>
      <HeaderProgressBar
        progressBarWidth={progressBarWidth}
        screenWidth={screenWidth}
      />
      <Container>
        <NavbarBrand
          onClick={() => {
            scrollToActiveRow();
            setActiveNavLink(null);
          }}
          tag={NavLink}
          className={`${
            isOnTop
              ? 'custom-brand-color-text-default'
              : 'custom-brand-color-text'
          }`}
          to="/">
          {t('front:navBrand')}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {filteredNavLinks.map((link, index) => {
              const nextLink = filteredNavLinks[index + 1];
              const offsetTop =
                landingPageSections[
                  link && link.id === 'reference'
                    ? 'projectReference'
                    : link.id || 'fornax'
                ] &&
                landingPageSections[
                  link && link.id === 'reference'
                    ? 'projectReference'
                    : link.id || 'fornax'
                ].element &&
                landingPageSections[
                  link && link.id === 'reference'
                    ? 'projectReference'
                    : link.id || 'fornax'
                ].element.offsetTop;

              const nextOffsetTop =
                landingPageSections[
                  nextLink && nextLink.id === 'reference'
                    ? 'projectReference'
                    : (nextLink && nextLink.id) || 'contact'
                ] &&
                landingPageSections[
                  nextLink && nextLink.id === 'reference'
                    ? 'projectReference'
                    : (nextLink && nextLink.id) || 'contact'
                ].element.offsetTop;

              if (link && !link.options) {
                return (
                  <div key={`nav-item-${index}`}>
                    {offsetTop && (
                      <NavItem key={`navlink-${index}`}>
                        <BootstrapNavLink
                          onClick={() => {
                            scrollToActiveRow();
                            setActiveNavLink(link.id);
                          }}
                          tag={NavLink}
                          to={link.to}>
                          <span
                            className={`${
                              (!nextLink && scrollY >= offsetTop) ||
                              (scrollY >= offsetTop && scrollY < nextOffsetTop)
                                ? 'navbar-nav-custom-link-item-selected'
                                : ''
                            } ${
                              isOnTop
                                ? 'navbar-nav-custom-link-item-default'
                                : 'navbar-nav-custom-link-item-scrolled'
                            }`}>
                            {link.label}
                          </span>
                        </BootstrapNavLink>
                      </NavItem>
                    )}
                  </div>
                );
              }

              return (
                <UncontrolledDropdown nav inNavbar key={`key-${index}`}>
                  <DropdownToggle
                    nav
                    caret
                    className={` ${
                      scrollY >= offsetTop && scrollY < nextOffsetTop
                        ? 'navbar-nav-custom-link-item-selected'
                        : ''
                    } ${
                      isOnTop
                        ? 'navbar-nav-custom-link-item-default'
                        : 'navbar-nav-custom-link-item-scrolled'
                    }`}>
                    {link.label}
                  </DropdownToggle>
                  <DropdownMenu
                    className={`${
                      isOnTop
                        ? 'reference-dropdownmenu-default'
                        : 'reference-dropdownmenu-scrolled'
                    } rounded-0 py-0 my-3`}>
                    <div className="reference-top-outline"></div>
                    {link.options.map((opt, index) => {
                      return (
                        <DropdownItem
                          key={`key-ref-${index}`}
                          className={`bg-transparent`}>
                          <BootstrapNavLink
                            onClick={() => {
                              scrollToActiveRow();
                              setActiveNavLink(opt.id);
                            }}
                            tag={NavLink}
                            to={opt.to}>
                            <div
                              className={`${
                                isOnTop
                                  ? 'reference-option-text-default-color'
                                  : 'reference-option-text-scrolled-color'
                              }
                                `}>
                              {opt.label}
                            </div>
                          </BootstrapNavLink>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            })}

            {navLinks.map((link, index) => {
              if (!['login'].includes(link.id)) return null;
              return (
                <NavItem key={`navlink-${index}`}>
                  <BootstrapNavLink
                    onClick={() => setActiveNavLink(link.id)}
                    className={`${isOnTop ? 'text-warning' : 'text-muted'}`}
                    tag={NavLink}
                    to={isLoggedIn ? '/dashboard' : link.to}>
                    {isLoggedIn ? (
                      <div>
                        {authUser.full_name.length > 60
                          ? `${authUser.full_name.slice(0, 60)}...`
                          : authUser.full_name}
                      </div>
                    ) : (
                      <span className="text-uppercase">{link.label}</span>
                    )}
                  </BootstrapNavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default withTranslation(['frontPage'])(Header);
