import { performRequest } from '../requests';
import { maintenanceAnnouncement } from '../../constants/actionType';
const {
  SET_MAINTENANCE_ANNOUNCEMENT,
  FETCH_MAINTENANCE_ANNOUNCEMENT,
  FETCH_ALL_MAINTENANCE_AS_ADMIN,
  DELETE_MAINTENANCE_ANNOUNCEMENT,
  UPDATE_MAINTENANCE_ANNOUNCEMENT
} = maintenanceAnnouncement;

export const createMaintenanceAnnouncement = jsonData => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/admin/maintenance_announcement/create',
      { jsonData },
      requestConfig,
      'SET_MAINTENANCE_ANNOUNCEMENT'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(announcement => {
      dispatch({
        type: SET_MAINTENANCE_ANNOUNCEMENT,
        announcement
      });
    });
};

export const fetchMaintenanceAnnouncement = () => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/admin/maintenance_announcement/currentMessage',
      {},
      requestConfig,
      'FETCH_MAINTENANCE_ANNOUNCEMENT'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(announcement => {
      dispatch({
        type: FETCH_MAINTENANCE_ANNOUNCEMENT,
        announcement
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const fetchAllMaintenanceMessage = (
  filters,
  order,
  pagination,
  toDispatch = true
) => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/admin/maintenance_announcement/list/all',
      { filters, order, pagination },
      requestConfig,
      'FETCH_MAINTENANCE_ANNOUNCEMENT'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(announcement => {
      const { data, total } = announcement;
      if (toDispatch) {
        dispatch({
          type: FETCH_ALL_MAINTENANCE_AS_ADMIN,
          announcement: data
        });
        return total;
      } else {
        return data;
      }
    });
};

export const deleteMaintenanceMessage = id => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'delete',
      `/api/admin/maintenance_announcement/${id}/delete`,
      {},
      requestConfig,
      'DELETE_MAINTENANCE_ANNOUNCEMENT'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(announcement => {
      dispatch({
        type: DELETE_MAINTENANCE_ANNOUNCEMENT,
        announcementId: id
      });

      return announcement;
    });
};

export const updateMaintenanceMessage = (id, jsonData) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/admin/maintenance_announcement/${id}/update`,
      { jsonData },
      requestConfig,
      'UPDATE_MAINTENANCE_ANNOUNCEMENT'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(announcement => {
      dispatch({
        type: UPDATE_MAINTENANCE_ANNOUNCEMENT,
        announcement
      });
    });
};
