import landing_page_hex_1 from '../../assets/images/landing_page_hex_1.png';
import landing_page_hex_2 from '../../assets/images/landing_page_hex_2.png';
import landing_page_hex_3 from '../../assets/images/landing_page_hex_3.png';
import landing_page_hex_4 from '../../assets/images/landing_page_hex_4.png';
import landing_page_hex_5 from '../../assets/images/landing_page_hex_5.png';

import landing_page_regulatory_compliance from '../../assets/images/landing_page_regulatory_compliance.gif';
import landing_page_bim_audit from '../../assets/images/landing_page_bim_audit.gif';
import landing_page_bim_review_and_collab from '../../assets/images/landing_page_bim_review_and_collab.gif';
import landing_page_bim_query from '../../assets/images/landing_page_bim_query.gif';
import landing_page_bim_analytics from '../../assets/images/landing_page_bim_analytics.gif';
import landing_page_bim_gis_integration from '../../assets/images/landing_page_bim_gis_integration.gif';

import landing_page_reference_1 from '../../assets/images/landing_page_reference_1.png';
import landing_page_reference_2 from '../../assets/images/landing_page_reference_2.jpg';
import landing_page_reference_3 from '../../assets/images/landing_page_reference_3.jpg';

export default {
  fornax: {
    defaultContent: {
      title: 'fornaxDefaultContentTitle',
      title1: 'fornaxDefaultContentTitle1',
      title2: 'fornaxDefaultContentTitle2',
      title3: 'fornaxDefaultContentTitle3',
      subTitle: 'fornaxDefaultContentSubTitle'
    },
    hexagonButtons: [
      {
        title: 'hexBtnContentTitle1',
        subTitle: 'hexBtnContentSubTitle1',
        buttonText: 'hexBtnText1',
        icon: 'fas fa-university',
        imageName: landing_page_hex_1
      },
      {
        title: 'hexBtnContentTitle3',
        subTitle: 'hexBtnContentSubTitle3',
        buttonText: 'hexBtnText3',
        icon: 'fas fa-list-ul',
        imageName: landing_page_hex_3
      },
      {
        title: 'hexBtnContentTitle2',
        subTitle: 'hexBtnContentSubTitle2',
        buttonText: 'hexBtnText2',
        icon: 'fas fa-users',
        imageName: landing_page_hex_2
      },
      {
        title: 'hexBtnContentTitle4',
        subTitle: 'hexBtnContentSubTitle4',
        buttonText: 'hexBtnText4',
        icon: 'fas fa-chart-pie',
        imageName: landing_page_hex_4
      },
      {
        title: 'hexBtnContentTitle5',
        subTitle: 'hexBtnContentSubTitle5',
        buttonText: 'hexBtnText5',
        icon: 'fas fa-globe-asia',
        imageName: landing_page_hex_5
      }
    ]
  },
  solutions: {
    solutionsContent: {
      headerText: 'solutionsHeaderText',
      solutionList: [
        {
          title: 'solutionTitle1',
          description: 'solutionsDescription1',
          icon: 'fas fa-users'
        },
        {
          title: 'solutionTitle2',
          description: 'solutionsDescription2',
          icon: 'fas fa-chart-pie'
        },
        {
          title: 'solutionTitle3',
          description: 'solutionsDescription3',
          icon: 'fas fa-globe-asia'
        },
        {
          title: 'solutionTitle4',
          description: 'solutionsDescription4',
          icon: 'fas fa-list-ul'
        },
        {
          title: 'solutionTitle5',
          description: 'solutionsDescription5',
          icon: 'fas fa-university'
        }
      ]
    },
    features: {
      headerText: 'featureHeaderText',
      gifImages: [
        {
          gifName: landing_page_regulatory_compliance,
          title: 'featureTitle1'
        },
        {
          gifName: landing_page_bim_audit,
          title: 'featureTitle2'
        },
        {
          gifName: landing_page_bim_review_and_collab,
          title: 'featureTitle3'
        },
        {
          gifName: landing_page_bim_query,
          title: 'featureTitle4'
        },
        {
          gifName: landing_page_bim_analytics,
          title: 'featureTitle5'
        },
        {
          gifName: landing_page_bim_gis_integration,
          title: 'featureTitle6'
        }
      ]
    }
  },
  projectReference: {
    headerText: 'projectReferenceHeaderText',
    references: [
      {
        headerText: 'referenceHeaderText1',
        imageName: landing_page_reference_1,
        paragraphs: [
          {
            tag: 'p',
            label: 'referenceParagraph-SPUB-1'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SPUB-2'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SPUB-3'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SPUB-4'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SPUB-5'
          }
        ]
      },
      {
        headerText: 'referenceHeaderText2',
        imageName: landing_page_reference_2,
        paragraphs: [
          {
            tag: 'p',
            label: 'referenceParagraph-SURA-1'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SURA-2'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SURA-3'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SURA-4'
          }
        ]
      },
      {
        headerText: 'referenceHeaderText3',
        imageName: landing_page_reference_3,
        paragraphs: [
          {
            tag: 'p',
            label: 'referenceParagraph-SA-1'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SA-2'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SA-3'
          },
          {
            tag: 'li',
            label: 'referenceParagraph-SA-4'
          },
          {
            tag: 'li',
            label: 'referenceParagraph-SA-5'
          },
          {
            tag: 'li',
            label: 'referenceParagraph-SA-6'
          },
          {
            tag: 'p',
            label: 'referenceParagraph-SA-7'
          }
        ]
      }
    ]
  },
  bimReference: {
    headerText: 'bimReferenceHeaderText',
    links: [
      {
        label: 'Singapore PUB BIM Checking System - Resources',
        href: `/pubResources`,
        target: '_blank',
        type: 'pubResources'
      },
      {
        label: 'Singapore PUB BIM Checking System - Self Help Videos',
        href: `/videotutorials`,
        target: '_blank',
        type: 'pubResources'
      },
      {
        label:
          'Singapore URA GFA AutoChecker System  – URA GFA BIM Modelling Guideline',
        href: 'https://www.ura.gov.sg/Corporate/Guidelines/Development-Control/Planning-Permission/using-CORENET-eSS/GFA-AutoChecker'
      },
      {
        label: 'Singapore Building Information Modeling (BIM) e-Submission',
        href: 'https://www.corenet.gov.sg/general/building-information-modeling-(bim)-e-submission.aspx'
      },
      {
        label: 'Singapore BIM Guide',
        href: 'https://corenet.gov.sg/general/bim-guides/singapore-bim-guide-version-20.aspx'
      },
      {
        label: 'Singapore BIM Essential Guide',
        href: 'https://corenet.gov.sg/general/bim-guides/bim-essential-guides.aspx'
      }
    ]
  },
  about: {
    headerText: 'aboutHeaderText',
    paragraphList: [
      {
        text: 'aboutParagraph1'
      },
      {
        text: 'aboutParagraph2'
      },
      {
        text: 'aboutParagraph3'
      },
      {
        text: 'aboutParagraph4'
      }
    ]
  },
  contact: {
    headerText: 'contactHeaderText',
    registrationHeaderText: 'contactRegistrationHeaderText',
    contacts: {
      primary: {
        subHeaderText: 'contactSubHeaderText',
        contactList: [
          {
            id: 'email',
            contact: 'FXC_support@nova-hub.com',
            icon: 'far fa-envelope',
            isLink: true,
            isEmail: true
          }
        ]
      },
      secondary: {
        subHeaderText: 'contactSubHeaderText2',
        contactList: [
          {
            id: 'email',
            contact: 'William.sng@nova-hub.com',
            icon: null,
            isLink: true,
            isEmail: true
          },
          {
            id: 'email',
            contact: 'Francis.aw@nova-hub.com',
            icon: null,
            isLink: true,
            isEmail: true
          }
        ]
      }
    }
  }
};
