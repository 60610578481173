// temporary store for the model file management
import { modelFilesActionType } from '../../constants/actionType';

const initialState = [];

const {
  RESET_MODELFILE,
  ADD_MODELFILE,
  REPLACE_MODELFILE,
  REMOVE_MODELFILE,
  DELETE_MODELFILE,
  SET_MODEL_DIRTY,
  SET_MODEL_ERROR,
  SET_MODEL_UPLOAD_STATE
} = modelFilesActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_MODELFILE:
    return [...state, action.model];
  case REPLACE_MODELFILE:
    return [
      ...state.filter(model => model.name !== action.model.name),
      action.model
    ];
    // "SOFT" delete the file => tag removed =true
  case REMOVE_MODELFILE: {
    const modelIndex = state.findIndex(
      model => model.name === action.model.name
    );
    return [
      ...state.slice(0, modelIndex),
      { ...state[modelIndex], removed: true },
      ...state.slice(modelIndex + 1)
    ];
  }
  // Remove the file from the store
  case DELETE_MODELFILE: {
    const modelIndex = state.findIndex(
      model => model.name === action.model.name
    );
    return [...state.slice(0, modelIndex), ...state.slice(modelIndex + 1)];
  }
  case SET_MODEL_DIRTY: {
    const modelIndex = state.findIndex(
      model => model.name === action.modelname
    );
    if (modelIndex >= 0) {
      const nextState = state;
      nextState[modelIndex].dirty = action.isDirty;
      return nextState;
    }
    return state;
  }
  case SET_MODEL_ERROR: {
    const modelIndex = state.findIndex(
      model => model.name === action.modelname
    );
    if (modelIndex >= 0) {
      const nextState = state;
      nextState[modelIndex].error = action.error;
      return nextState;
    }
    return state;
  }
  case SET_MODEL_UPLOAD_STATE: {
    const modelIndex = state.findIndex(
      model => model.name === action.modelname
    );
    if (modelIndex >= 0) {
      const nextState = state;
      nextState[modelIndex].uploadState = action.uploadState;
      return nextState;
    }
    return state;
  }
  case RESET_MODELFILE:
    return initialState;
  default:
    return state;
  }
};

export default reducer;
