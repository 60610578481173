import { projectActionType } from '../../constants/actionType';
import { performRequest, handleErrorMessageStatus } from '../requests';
import { addCompletedModelFile, resetModelFile } from '../modelFiles';
import { RULES_KEY_SEPARTOR } from '../../constants/misc';
import { fetchProjectStage } from '../project_stages';
const {
  MERGE_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  REPLACE_PROJECTS,
  RESET_PROJECTS
  // ADD_STAGE_AVAILABLE
} = projectActionType;

export const resetProjects = () => {
  return {
    type: RESET_PROJECTS
  };
};

// Fetch the project for a user, the user is determined with the token registered
export const fetchProjectsFromUser =
  (filters, order, pagination) => (dispatch /* , getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        'api/project/all',
        { filters, order, pagination },
        requestConfig,
        'FETCH_ALL_PROJECT',
        true
      )
    )
      .then(res => res.data)
      .then(data => {
        const { projectList, total } = data;
        const projectMap = projectList.reduce((map, currentProject) => {
          return { ...map, [currentProject.project_id]: currentProject };
        }, {});
        dispatch({
          type: REPLACE_PROJECTS,
          projectMap
        });
        return total;
      });
  };

// fetch a project with a specific id, the token user is used to determined if t he user is allowed to see this project
export const fetchProject = projectId => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/project/${projectId}`,
      {},
      requestConfig,
      'FETCH_PROJECT'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(projectInfo => {
      const { project, members, stagesavailable, relatedGroups } = projectInfo;
      dispatch({
        type: ADD_PROJECT,
        projectId,
        project: { members, stagesavailable, relatedGroups, ...project }
      });
      return projectInfo;
      // dispatch(resetModelFile());
      // models.filter(model => !!model).forEach(model =>
      //   dispatch(addCompletedModelFile({ ...model, name: model.modelName }))
      // );
    });
};

// Create a project on a backend and then add it to the store with id generated from the back end
export const createProject = projectData => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type':
        'multipart/form-data; boundary=---------------------------665987845113215641523165484'
    },
    withCredentials: true
  };
  const formData = new FormData();
  for (let key in projectData) {
    if (key !== 'thumbnail_url') {
      formData.append(key, JSON.stringify(projectData[key]));
    } else if (projectData[key]) {
      formData.append(key, projectData[key]);
    }
  }
  return dispatch(
    performRequest(
      'post',
      '/api/project',
      formData,
      requestConfig,
      'CREATE_PROJECT'
    )
  )
    .then(response => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.error || 'default_message');
      }
    })
    .then(data => {
      const projectId = data.project_id;
      dispatch({
        type: ADD_PROJECT,
        projectId,
        project: { ...projectData, models: [] }
      });
      return projectId;
    });
};

// Update a project on the backend and then in the store
export const updateProject =
  (projectId, projectData) => (dispatch, getState) => {
    const concurrencyControlVersionId =
      getState().projects[projectId].concurrency_control_version_id;
    const entityTargetId = projectId;

    projectData.entityTargetId = entityTargetId;
    projectData.concurrencyControlVersionId = concurrencyControlVersionId
      ? concurrencyControlVersionId
      : '';
    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type':
          'multipart/form-data; boundary=---------------------------655416451564878451313335845'
      },
      withCredentials: true
    };
    const formData = new FormData();

    for (let val in projectData) {
      if (val !== 'thumbnail_url') {
        formData.append(val, JSON.stringify(projectData[val]));
      } else {
        formData.append(val, projectData[val]);
      }
    }
    return dispatch(
      performRequest(
        'put',
        `api/project/${projectId}`,
        formData,
        requestConfig,
        'UPDATE_PROJECT'
      )
    )
      .then(res => res.data)
      .then(projectInfo => {
        const { project, members, stagesavailable, relatedGroups } =
          projectInfo;

        dispatch({
          type: UPDATE_PROJECT,
          projectId,
          project: { members, stagesavailable, relatedGroups, ...project }
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          throw (
            {
              message: 'not_authorised'
            } || 'default_message'
          );
        } else if (err.response && err.response.status === 409) {
          throw new Error(
            (err.response && err.response.data && err.response.data.error) ||
              'error.default_message'
          );
        } else {
          throw new Error(
            (err.response && err.response.data && err.response.data.error) ||
              err.message ||
              'error.default_message'
          );
        }
      });
  };

// export const fetchProjectStage = (projectId, stageName) => dispatch => {};

export const queryECheck =
  (
    projectId,
    stageName,
    rules,
    dependencyRules,
    jobType,
    isAddRulesToFavorites,
    isComingFromShorcutButton = false
  ) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/echeck`,
        { rules, jobType, isAddRulesToFavorites, dependencyRules },
        requestConfig,
        'ECHECK'
      )
    )
      .then(res => res.data)
      .then(({ success, error }) => {
        if (!success) {
          throw new Error(`error:${error}` || 'error:default_message');
        }
      });
  };

export const queryRuleDataValidation =
  (
    projectId,
    stageName,
    rules,
    jobType,
    isAddRulesToFavorites,
    isComingFromShorcutButton = false
  ) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/ruleDataValidation`,
        { rules, jobType, isAddRulesToFavorites },
        requestConfig,
        'RULE_DATA_VALIDATION'
      )
    )
      .then(res => res.data)
      .then(res => {
        // console.log({ res });
        if (res.success) {
          return res.jobId;
        } else {
          throw new Error('error:default_message');
        }
      });
  };

export const fetchJobWithRuleDataValidation =
  (projectId, stageName, jobId, isDownload = false) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: { isDownload },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'get',
        `api/project/${projectId}/${stageName}/ruleDataValidationResult/${jobId}`,
        {},
        requestConfig,
        'FETCH_RULE_DATA_VALIDATION_RESULTS'
      )
    )
      .then(res => res.data)
      .then(res => {
        // console.log({ res });
        if (!isDownload) {
          if (res.success) {
            return { resultContent: res.resultContent, job: res.job };
          } else {
            if (res.err) {
              throw new Error(res.err);
            } else {
              throw new Error('error:default_message');
            }
          }
        }
      });
  };

export const getEstimatedComputingUnit =
  (projectId, stageName, rules) => dispatch => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/getEstimatedComputingUnit`,
        { rules },
        requestConfig,
        'GET_ESTIMATED_COMPUTING_UNIT'
      )
    )
      .then(res => res.data)
      .then(res => {
        const {
          success,
          estimatedCU,
          estimatedNextCUBalance,
          currentCUBalance,
          message
        } = res;
        if (success) {
          return { estimatedCU, estimatedNextCUBalance, currentCUBalance };
        } else {
          console.log(message);
          return {
            estimatedCU: 'N/D',
            estimatedNextCUBalance: 'N/D',
            currentCUBalance: 'N/D'
          };
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const requestJobCancellation =
  (projectId, stageName, jobId) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/cancelJob/${jobId}`,
        {},
        requestConfig,
        'REQUEST_JOB_CANCELATION'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return dispatch(fetchProjectStage(projectId, stageName));
        } else {
          throw new Error(data.message || 'error:default_message');
        }
      });
  };

export const requestRuleCheckDeletion =
  (projectId, stageName, jobId) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/deleteJob/${jobId}`,
        {},
        requestConfig,
        'REQUEST_JOB_DELETION'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return dispatch(fetchProjectStage(projectId, stageName));
        } else {
          // throw new Error(data.message || 'error:default_message');
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const requestJobRerun =
  (projectId, stageName, jobId) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/rerun/echeck/${jobId}`,
        {},
        requestConfig,
        'REQUEST_JOB_RERUN'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return dispatch(fetchProjectStage(projectId, stageName));
        } else {
          //throw new Error(data.message || 'error:default_message');
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const requestPartialResult =
  (projectId, stageName, jobId) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/partialResultRequest/${jobId}`,
        {},
        requestConfig,
        'REQUEST_JOB_PARTIAL_RESULT'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return dispatch(fetchProjectStage(projectId, stageName));
        } else {
          // throw new Error(data.message || 'error:default_message');
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const limitRunningJobs =
  (projectId, stageName) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/limitRunningJobs`,
        {},
        requestConfig,
        'LIMIT_RUNNING_JOBS'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          const { allowedToRunJob, allowedToRunJobOnProject } = data;
          return {
            allowedToRunJob,
            allowedToRunJobOnProject
          };
        } else {
          throw data.error || 'default_error';
        }
      });
  };

export const getWebviewerToken =
  (projectId, stageName) => (dispatch /* ,getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'get',
        `api/project/${projectId}/${stageName}/viewerToken`,
        {},
        requestConfig,
        'VIEWER_TOKEN'
      )
    ).then(res => res.data.token);
  };

export const fetchProjectListAsAdmin =
  (filters, order, pagination, toDispatch = true) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        'api/admin/project/list',
        { filters, order, pagination },
        requestConfig,
        'FETCH_LIST_PROJECT_AS_ADMIN',
        true
      )
    )
      .then(res => res.data)
      .then(data => {
        const projectList = data.data;
        const totalResult = data.total;
        const projectMap = projectList.reduce((map, currentProject) => {
          return { ...map, [currentProject.project_id]: currentProject };
        }, {});
        if (toDispatch) {
          dispatch({
            type: REPLACE_PROJECTS,
            projectMap
          });
          return totalResult;
        } else {
          return projectMap;
        }
      });
  };

export const fetchProjectAsAdmin = projectId => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/admin/project/${projectId}`,
      {},
      requestConfig,
      'FETCH_PROJECT_AS_ADMIN'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(projectInfo => {
      const { project, members, models } = projectInfo;

      dispatch({
        type: ADD_PROJECT,
        projectId,
        project: { members, ...project }
      });
      dispatch(resetModelFile());
      models.forEach(model =>
        dispatch(addCompletedModelFile({ ...model, name: model.modelName }))
      );
    });
};

export const editProjectAsAdmin =
  (projectId, projectData) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type':
          'multipart/form-data; boundary=---------------------------655416451564878451313335845'
      },
      withCredentials: true
    };
    const formData = new FormData();
    for (let val in projectData) {
      if (val !== 'thumbnail_url') {
        formData.append(val, JSON.stringify(projectData[val]));
      } else {
        formData.append(val, projectData[val]);
      }
    }

    return dispatch(
      performRequest(
        'put',
        `api/admin/project/${projectId}`,
        formData,
        requestConfig,
        'UPDATE_PROJECT_AS_ADMIN'
      )
    ).then(project => {
      dispatch({
        type: UPDATE_PROJECT,
        projectId,
        project
      });
    });
  };

// Edit multiple project at once (this action cannot be used for file modification)
export const editMultipleProjectAsAdmin =
  (projectList, dataUpdated) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'put',
        'api/admin/project',
        { projectList, dataUpdated },
        requestConfig,
        'BATCH_UPDATE_PROJECT_AS_ADMIN'
      ).then(() => projectList)
    ).then(projectList => {
      projectList.map(projectId => {
        dispatch({
          type: UPDATE_PROJECT,
          projectId,
          dataUpdated
        });
      });
    });
  };

export const deleteProject =
  projectId =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'delete',
        `api/project/${projectId}`,
        {},
        requestConfig,
        'DELETE_PROJECT'
      )
    ).then(res => res.data);
  };

export const shareProjectToOrg =
  (projectId, groupId, groupType, includeSubGroup) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/share_to_org/${groupId}`,
        {
          groupType,
          includeSubGroup
        },
        requestConfig,
        'DELETE_PROJECT'
      )
    ).then(res => res.data);
  };

export const fetchProjectRelatedGroups =
  projectId =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/related_groups`,
        {},
        requestConfig,
        'DELETE_PROJECT'
      )
    ).then(res => res.data.data);
  };

export const reRunModelComparison =
  (projectId, stageName, comparedStageName, dateOfComparison) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/stageComparison`,
        {
          targetStageName: comparedStageName,
          dateOfComparison: dateOfComparison
        },
        requestConfig,
        'REQUEST_JOB_RERUN'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return dispatch(fetchProjectStage(projectId, stageName));
        } else {
          throw new Error(data.message || 'error:default_message');
        }
      });
  };

export const allowedToUseBimDataReportTemplate =
  (projectId, stageName, rules) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/allowedToUseBimDataReportTemplate`,
        { rules },
        requestConfig,
        'CHECK_BIMDATA_EXIST_AMONG_TEMPLATE'
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          const { allowedToUseBimDataReportTemplate } = data;
          return allowedToUseBimDataReportTemplate;
        } else {
          throw data.error || 'default_error';
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };
