import React from 'react';

const QueryUIGuide = props => {
  return (
    <>
      <div className="stackedit__html">
        <h1 id="query-user-interface-guide">
          FORNAX™ Query User Interface Guide
        </h1>
        <p>
          This guide is meant to assist users on how to use the Query tool to
          extract information of an element in the model based on multiple
          combination of BIM data references, relationships and properties.
        </p>
        <p>
          This UI-driven model query can perform BIM-oriented extraction of
          objects, properties and relationships and is integrated with the
          FORNAX™ Viewer where items found can also be viewed individually or in
          groups.
        </p>
        <h4 id="terminologies-to-note">Terminologies to note</h4>
        <blockquote>
          <p>
            AABB - Axis-Aligned Bounding Box
            <br />
            OBB - Oriented Bounding Box
            <br />
            BIMRL - BIM Rule Language
            <br />
            RuleDSL - Rule Domain Specific Language
          </p>
        </blockquote>
        <p>Query User Interface</p>
        <p>
          <img
            width="100%"
            src="assets/help_center/images/query-ui-img.jpg"
            alt="alt text"
            title="Query User Interface"
          />
        </p>
        <h2 id="objects">Objects</h2>
        <p>
          This section contains all the available objects serves as starting
          node to extract data. This is further subdivided into two parts; found
          in the model and others, respectively.
        </p>
        <p>
          Objects Interface
          <br />
          <img
            width="100%"
            src="assets/help_center/images/objects-img.jpg"
            alt="alt text"
            title="Objects Interface"
          />
        </p>
        <h3 id="found-in-the-model">Found in the model</h3>
        <p>
          This part contains all the elements found within the project stage.
        </p>
        <h3 id="others">Others</h3>
        <p>This contains the list of elements not found in the model.</p>
        <h2 id="container">Container</h2>
        <p>
          This section contains all spatial containers available to interconnect
          to an object.
        </p>
        <p>
          Container Interface
          <br />
          <img
            width="100%"
            src="assets/help_center/images/container-img.jpg"
            alt="alt text"
            title="Container Interface"
          />
        </p>
        <h3 id="ifcbuilding">IfcBuilding</h3>
        <p>
          Building Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/ifc-building-img.jpg"
            alt="alt text"
            title="Building Container"
          />
        </p>
        <h3 id="ifcbuildingstorey">IfcBuildingStorey</h3>
        <p>
          Building Storey Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/ifc-building-storey-img.jpg"
            alt="alt text"
            title="Building Storey Container"
          />
        </p>
        <h3 id="ifcsite">IfcSite</h3>
        <p>
          Site Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/ifc-site-img.jpg"
            alt="alt text"
            title="Site Container"
          />
        </p>
        <h3 id="ifcspace">IfcSpace</h3>
        <p>
          Space Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/ifc-space-img.jpg"
            alt="alt text"
            title="Space Container"
          />
        </p>
        <h3 id="ifczone">IfcZone</h3>
        <p>
          Zone Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/ifc-zone-img.jpg"
            alt="alt text"
            title="Zone Container"
          />
        </p>
        <h2 id="relationship">Relationship</h2>
        <p>
          This serves as the connector for the relationship of objects to all
          built-in functions defined within the logic embedded in BIMRL
          function.
        </p>
        <p>
          Relationship Interface
          <br />
          <img
            width="100%"
            src="assets/help_center/images/relationship-img.jpg"
            alt="alt text"
            title="Relationship Interface"
          />
        </p>
        <h3 id="aggregate-master">Aggregate Master</h3>
        <p>Finds the ‘whole’ of an object.</p>
        <p>
          Aggregate Master
          <br />
          <img
            width="100%"
            src="assets/help_center/images/aggregate-master-img.jpg"
            alt="alt text"
            title="Aggregate Master Relationship"
          />
        </p>
        <p>Sample use case of Aggregate Master</p>
        <br />
        <p>
          Image shown means that the railing forms part of the stair as a whole.
          <br />
          <img
            width="100%"
            src="assets/help_center/images/aggregate-master-sample-img.jpg"
            alt="alt text"
            title="Aggregate Master Sample"
          />
        </p>
        <h3 id="aggregate-of">Aggregate Of</h3>
        <p>Finds the ‘part/s’ of an element.</p>
        <p>
          Aggregate Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/aggregate-of-img.jpg"
            alt="alt text"
            title="Aggregate Of Relationship"
          />
        </p>
        <p>Sample use case of Aggregate Of</p>
        <br />
        <p>
          Image shown means that the landing forms part of the stair as a whole.
          <br />
          <img
            width="100%"
            src="assets/help_center/images/aggregate-of-sample-img.jpg"
            alt="alt text"
            title="Aggregate Of Sample"
          />
        </p>
        <h3 id="bottom">Bottom</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated bottom face.
        </p>
        <p>
          Bottom
          <br />
          <img
            width="100%"
            src="assets/help_center/images/bottom-img.jpg"
            alt="alt text"
            title="Bottom Relationship"
          />
        </p>
        <p>Sample use case of Bottom</p>
        <br />
        <p>
          Image shown means the bottom elevation of the slab with landing name
          on it.
          <br />
          <img
            width="100%"
            src="assets/help_center/images/bottom-sample-img.jpg"
            alt="alt text"
            title="Bottom Sample"
          />
          <p>
            Landing bottom elevation flagged out from the z-value of the sample
            model
            <br />
            <img
              width="100%"
              src="assets/help_center/images/bottom-sample-model-img.jpg"
              alt="alt text"
              title="Bottom Sample From Model"
            />
          </p>
        </p>
        <h3 id="bounded-space">Bounded Space</h3>
        <p>Returns information of space element an object bounds.</p>
        <p>
          Bounded Space
          <br />
          <img
            width="100%"
            src="assets/help_center/images/bounded-space-img.jpg"
            alt="alt text"
            title="Bounded Space Relationship"
          />
        </p>
        <h3 id="classification-of">Classification Of</h3>
        <p>Returns classification of an object.</p>
        <p>
          Classification Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/classification-of-img.jpg"
            alt="alt text"
            title="Classification Of Relationship"
          />
        </p>
        <h3 id="connected-to">Connected To</h3>
        <p>Returns information of an object connected to another object.</p>
        <p>
          Connected To
          <br />
          <img
            width="100%"
            src="assets/help_center/images/connected-to-img.jpg"
            alt="alt text"
            title="Connected To Relationship"
          />
        </p>
        <h3 id="container-1">Container</h3>
        <p>Returns information of a container element of an object.</p>
        <p>
          Container
          <br />
          <img
            width="100%"
            src="assets/help_center/images/container-img.jpg"
            alt="alt text"
            title="Container Relationship"
          />
        </p>
        <h3 id="contains">Contains</h3>
        <p>Checks whether a container contains the element.</p>
        <p>
          Contains
          <br />
          <img
            width="100%"
            src="assets/help_center/images/contains-img.jpg"
            alt="alt text"
            title="Contains Relationship"
          />
        </p>
        <h3 id="dependency">Dependency</h3>
        <p>Returns information if two or more elements are dependent.</p>
        <p>
          Dependency
          <br />
          <img
            width="100%"
            src="assets/help_center/images/dependency-img.jpg"
            alt="alt text"
            title="Dependency Relationship"
          />
        </p>
        <h3 id="dependent-to">Dependent To</h3>
        <p>Finds information where the object is dependent to.</p>
        <p>
          Dependent To
          <br />
          <img
            width="100%"
            src="assets/help_center/images/dependent-to-img.jpg"
            alt="alt text"
            title="Dependent To Relationship"
          />
        </p>
        <h3 id="element-type-of">Element Type Of</h3>
        <p>Returns element type of an object.</p>
        <p>
          Element Type Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/element-type-of-img.jpg"
            alt="alt text"
            title="Element Type Of Relationship"
          />
        </p>
        <h3 id="group-of">Group Of</h3>
        <p>Finds the group of an element it belongs to.</p>
        <p>
          Group Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/group-of-img.jpg"
            alt="alt text"
            title="Group Of Relationship"
          />
        </p>
        <h3 id="has-property">Has Property</h3>
        <p>
          Checks if an element contains a specific property or property set.
        </p>
        <p>
          Has Property
          <br />
          <img
            width="100%"
            src="assets/help_center/images/has-property-img.jpg"
            alt="alt text"
            title="Has Property Relationship"
          />
        </p>
        <h3 id="material-of">Material Of</h3>
        <p>Finds material information of an element.</p>
        <p>
          Material Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/material-of-img.jpg"
            alt="alt text"
            title="Material Of Relationship"
          />
        </p>
        <h3 id="model-info">Model Info</h3>
        <p>Returns information about the model.</p>
        <p>
          Model Info
          <br />
          <img
            width="100%"
            src="assets/help_center/images/model-info-img.jpg"
            alt="alt text"
            title="Model Info Relationship"
          />
        </p>
        <h3 id="obb-bottom">OBB Bottom</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated bottom face for OBB.
        </p>
        <p>
          OBB Bottom
          <br />
          <img
            width="100%"
            src="assets/help_center/images/obb-bottom-img.jpg"
            alt="alt text"
            title="OBB Bottom Relationship"
          />
        </p>
        <h3 id="obb-side">OBB Side</h3>
        <p>
          The set of the oriented faces that will return the reference to the
          associate side face for OBB.
        </p>
        <p>
          OBB side
          <br />
          <img
            width="100%"
            src="assets/help_center/images/obb-side-img.jpg"
            alt="alt text"
            title="OBB Side Relationship"
          />
        </p>
        <h3 id="obb-top">OBB Top</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated top face for OBB.
        </p>
        <p>
          OBB Top
          <br />
          <img
            width="100%"
            src="assets/help_center/images/obb-top-img.jpg"
            alt="alt text"
            title="OBB Top Relationship"
          />
        </p>
        <h3 id="owner-history">Owner History</h3>
        <p>Returns information about owner history.</p>
        <p>
          Owner History
          <br />
          <img
            width="100%"
            src="assets/help_center/images/owner-history-img.jpg"
            alt="alt text"
            title="Owner History Relationship"
          />
        </p>
        <h3 id="property">Property</h3>
        <p>
          Returns information on a specific property set and property value.
        </p>
        <p>
          Property
          <br />
          <img
            width="100%"
            src="assets/help_center/images/property-img.jpg"
            alt="alt text"
            title="Property Relationship"
          />
        </p>
        <h3 id="property-of">Property Of</h3>
        <p>Finds information present in element properties.</p>
        <p>
          Property Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/property-of-img.jpg"
            alt="alt text"
            title="Property Of Relationship"
          />
        </p>
        <h3 id="side">Side</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated side face.
        </p>
        <p>
          Side
          <br />
          <img
            width="100%"
            src="assets/help_center/images/side-img.jpg"
            alt="alt text"
            title="Side Relationship"
          />
        </p>
        <h3 id="system-of">System Of</h3>
        <p>Returns information on system element.</p>
        <p>
          System Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/system-of-img.jpg"
            alt="alt text"
            title="System Of Relationship"
          />
        </p>
        <h3 id="top">Top</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated top face.
        </p>
        <p>
          Top
          <br />
          <img
            width="100%"
            src="assets/help_center/images/top-img.jpg"
            alt="alt text"
            title="Top Relationship"
          />
        </p>
        <h3 id="top-side">Top Side</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated topside face.
        </p>
        <p>
          Top Side
          <br />
          <img
            width="100%"
            src="assets/help_center/images/top-side-img.jpg"
            alt="alt text"
            title="Top Side Relationship"
          />
        </p>
        <h3 id="type-of">Type Of</h3>
        <p>Finds specific information about a type.</p>
        <p>
          Type Of
          <br />
          <img
            width="100%"
            src="assets/help_center/images/type-of-img.jpg"
            alt="alt text"
            title="Type Of Relationship"
          />
        </p>
        <h3 id="under-side">Under Side</h3>
        <p>
          The set of oriented faces that will return the reference to the
          associated underside face.
        </p>
        <p>
          Under Side
          <br />
          <img
            width="100%"
            src="assets/help_center/images/under-side-img.jpg"
            alt="alt text"
            title="Under Side Relationship"
          />
        </p>
        <h2 id="gis">GIS</h2>
        <p>
          This is specified related to the GIS data available within the portal
          to interconnect with the project’s content.
        </p>
        <h2 id="my-unit-info">My Unit Info</h2>
        <p>
          This is meant for URA-related unit info. This is only available to
          users set as processing officer (PO) and is limited to URA officers.
        </p>
        <h2 id="charts">Charts</h2>
        <p>
          Well-defined interconnected nodes saved to reuse.
          <br />
          Default charts can be updated and modified according to user’s
          preference.
        </p>
        <p>
          Charts
          <br />
          <img
            width="100%"
            src="assets/help_center/images/charts-img.jpg"
            alt="alt text"
            title="Charts Interface"
          />
        </p>
        <h2 id="canvas">Canvas</h2>
        <p>
          This allows you to drag and drop nodes and connect with container and
          or a relationship or a combination of connected nodes to form part of
          the data query you are about to perform.
        </p>
        <p>
          Canvas
          <br />
          <img
            width="100%"
            src="assets/help_center/images/canvas-img.jpg"
            alt="alt text"
            title="Canvas Interface"
          />
        </p>
        <h3 id="results-panel">Results Panel</h3>
        <p>
          This pane contains results generated from the charts created. This can
          also be viewed and exported to excel file for external reference.
        </p>
        <p>
          Results Panel
          <br />
          <img
            width="100%"
            src="assets/help_center/images/results-panel-img.jpg"
            alt="alt text"
            title="Results Panel Interface"
          />
        </p>
        <h1 id="fornax-default-charts">FORNAX™ Default Charts</h1>
        <h2 id="area-data">Area Data</h2>
        <h4 id="area-classification">Area Classification</h4>
        <p>
          Sample Area Classification Info Chart
          <br />
          <img
            width="100%"
            src="assets/help_center/images/area-classification-chart-img.jpg"
            alt="alt text"
            title="Area Classification Chart"
          />
        </p>
        <h4 id="room-classification">Room Classification</h4>
        <p>
          Sample Room Classification Info Chart
          <br />
          <img
            width="100%"
            src="assets/help_center/images/room-classification-chart-img.jpg"
            alt="alt text"
            title="Room Classification Chart"
          />
        </p>
        <h4 id="update-note">Query UI Guide Updates</h4>
        <blockquote>
          <p>
            Updated as of Dec 2021. Images are correct at the time of the screen
            capture.
            <br />
            Always refer to the latest features of Query UI available at FORNAX™
            Viewer.
          </p>
        </blockquote>
      </div>
    </>
  );
};

export default QueryUIGuide;
