import { bimrlDataActionType } from '../../constants/actionType';
import { performRequest } from '../requests';

const {
  ADD_SAVED_QUERIES,
  ADD_RESULT_ENTRY,
  RESET_BIMRL_RESULT,
  ADD_LIST_RESULT_ENTRY
} = bimrlDataActionType;

const requestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
};

export const fetchBIMRLSavedQueries = () => dispatch => {
  return dispatch(performRequest('get', 'api/admin/bimrl/saved_queries'))
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.message || 'server_error');
      }
      return res.data.data;
    })
    .then(result => {
      dispatch({ type: ADD_SAVED_QUERIES, savedQueries: result });
    });
};

export const getSavedQueryBIMRLProjectResult = (
  projectId,
  stageName,
  values,
  savedQueryName
) => dispatch => {
  return dispatch(
    performRequest('post', 'api/admin/bimrl/runSavedQuery', {
      projectId,
      stageName,
      savedQueryName,
      values
    })
  )
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.message || 'server_error');
      }
      return res.data.data;
    })
    .then(result => {
      dispatch({
        type: ADD_RESULT_ENTRY,
        rootEntry: projectId,
        data: result
      });
    });
};

export const fetchSimilarStages = stageName => dispatch => {
  return dispatch(
    performRequest(
      'get',
      'api/admin/bimrl/get_similar_stages',
      {},
      { ...requestConfig, params: { stageName } }
    )
  )
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.message || 'server_error');
      }
      return res.data.data;
    })
    .then(result => {
      dispatch({
        type: ADD_LIST_RESULT_ENTRY,
        list: result
      });
    });
};

export const runAggregatedQuery = (stageName, savedQueryName, aggregationChoice) => dispatch => {
  return dispatch(
    performRequest(
      'get',
      'api/admin/bimrl/runAggregatedQuery',
      {},
      { ...requestConfig, params: { stageName, savedQueryName ,aggregationChoice} }
    )
  )
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.message || 'server_error');
      }
      return res.data.data;
    })
    .then(result => {
      dispatch({
        type: ADD_RESULT_ENTRY,
        rootEntry: 'aggregate',
        data: result
      });
    });
};
