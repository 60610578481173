import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  createCustomReportTemplate,
  addFileToCustomReport
} from '../../../../store/actions';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import prettyBytes from 'pretty-bytes';
import { dateFormatting } from '../../../../locales/dateFormat';
import CustomInput from '../../../UI/Input/customInput';
import customSelectInput from '../../../UI/Input/customSelectInput';
import classnames from 'classnames';
import {
  Input,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Button,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
  Progress
} from 'reactstrap';
import Message from '../../../UI/Message';

const removeExtension = filename => {
  const lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition === -1) {
    return filename;
  } else {
    return filename.substr(0, lastDotPosition);
  }
};

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploadProgresses: []
    };
  }

  setFileUploadProgresses = progressList => {
    this.setState({ fileUploadProgresses: progressList });
  };

  formikConfig = props => {
    return {
      initialValues: {
        custom_template_for: '',
        internal_name: '',
        display_name: '',
        reportTemplateFiles: []
      },
      validationSchema: Yup.object().shape({
        custom_template_for: Yup.string().required(
          props.t('message_name_required')
        ),
        internal_name: Yup.string()

          .test(
            'test_internal_name',
            props.t('message_name_exists'),
            function test(currentValue) {
              return !props.reportTemplateList.find(
                ({ name }) => name === currentValue
              );
            }
          )
          .max(60)
          .required(props.t('message_name_required')),
        display_name: Yup.string()
          .max(60)
          .required(props.t('message_display_name_required'))
          .test(
            'test_display_name',
            props.t('message_display_name_exists'),
            function test(currentValue) {
              return !props.reportTemplateList.find(
                ({ name, display_name }) => display_name === currentValue
              );
            }
          ),

        reportTemplateFiles: Yup.array()
          .min(2, props.t('minimum_file_list_item_error_message'))
          .test(
            'reportTemplateFiles',
            props.t('custom_report_template_file_error'),
            function test(currentValue) {
              const fileNameList = currentValue.map(({ name }) => name);
              const nextInternalName = this.parent.internal_name;
              const isValid = fileNameList.every(name => {
                return removeExtension(name) === nextInternalName;
              });

              return isValid;
            }
          )
      }),

      validateOnChange: false,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        this.setFileUploadProgresses(values.reportTemplateFiles.map(() => 0));

        try {
          const reportTemplateCreated = await props.createCustomReportTemplate(
            values
          );

          async function* generateAddFileSequence(
            files,
            customReportTemplatedId,
            fileUploadProgresses,
            setFileUploadProgresses,
            addFileToCustomReport
          ) {
            for (let i in files) {
              const file = files[i];
              const onUploadProgress = progressEvent => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                const newProgresses = fileUploadProgresses;
                newProgresses[i] = percentCompleted;
                setFileUploadProgresses(newProgresses);
              };
              const uploadedDocumentId = await addFileToCustomReport(
                customReportTemplatedId,
                file,
                onUploadProgress
              );
              yield uploadedDocumentId;
            }
          }
          let generator = generateAddFileSequence(
            values.reportTemplateFiles,
            reportTemplateCreated.custom_report_template_id,

            this.state.fileUploadProgresses,
            this.setFileUploadProgresses,
            props.addFileToCustomReport
          );
          let next = await generator.next();
          while (!next.done) {
            next = await generator.next();
          }

          Message.success(props.t('custom_report_template_created'));
        } catch (err) {
          Message.error(props.t(`error:${err.message}`));
        } finally {
          props.toogleModal(false);
          setSubmitting(false);
          resetForm();
        }
      }
    };
  };

  render() {
    const { t, reportTemplateList } = this.props;

    const systemDefault = reportTemplateList.filter(rt => {
      return !rt.custom_report_template_id && rt.name !== 'comparison';
    });

    const { fileUploadProgresses } = this.state;
    return (
      <Formik {...this.formikConfig(this.props)}>
        {formikProps => {
          const { values, setFieldValue, setFieldTouched, setFieldError } =
            formikProps;

          return (
            <Form>
              <Container>
                <h6 className="text-primary py-1">
                  {t('custom_template_for')}
                </h6>

                <Field
                  type="select"
                  size="sm"
                  name="custom_template_for"
                  placeholder={t('custom_template_for_select')}
                  options={systemDefault
                    .filter(rt => rt.name !== 'bimdata')
                    .map(({ name }) => {
                      return { value: name, label: t(`${name.toLowerCase()}`) };
                    })}
                  // onChange={this.onSavedQueryChange}
                  component={customSelectInput}
                />

                <h6 className="text-primary py-1">{t('name')}</h6>
                <Field
                  name="internal_name"
                  placeholder={t('name')}
                  component={CustomInput}
                />

                <h6 className="text-primary py-1">{t('display_name')}</h6>
                <Field
                  name="display_name"
                  placeholder={t('display_name')}
                  component={CustomInput}
                />

                <h6 className="text-primary py-2">{t('related_files')}</h6>

                <Row
                  className={classnames('', {
                    'border-danger border border-thick rounded':
                      !!formikProps.errors.reportTemplateFiles
                  })}>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <div className="custom-file">
                          <label
                            className="custom-file-label"
                            htmlFor="customFile">
                            {t('upload_details')}
                          </label>
                          <FieldArray
                            name="reportTemplateFiles"
                            render={() => {
                              return (
                                <Input
                                  type="file"
                                  id="customFile"
                                  className="custom-file-input"
                                  accept=".docx,.xlsx,.html,.dll"
                                  onChange={async e => {
                                    let files =
                                      formikProps.values.reportTemplateFiles;
                                    for (
                                      let index = 0;
                                      index < e.target.files.length;
                                      index++
                                    ) {
                                      files.push(e.target.files[index]);
                                    }

                                    await setFieldValue(
                                      'reportTemplateFiles',
                                      files
                                    );

                                    await setFieldTouched(
                                      'reportTemplateFiles',
                                      true
                                    );
                                  }}
                                  multiple={true}
                                />
                              );
                            }}
                          />
                        </div>
                      </InputGroup>
                      {formikProps.values.reportTemplateFiles.length > 0 && (
                        <ListGroup>
                          {/* <ListGroupItem color="info">
                            {t('files')}
                          </ListGroupItem> */}
                          {formikProps.values.reportTemplateFiles.map(
                            (file, index) => {
                              return (
                                <ListGroupItem key={`files-${index}`}>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <Row>
                                        {file.name}
                                        {file.size &&
                                          ` (${prettyBytes(file.size)})`}
                                      </Row>
                                      <Row>
                                        {file.lastModified && (
                                          <div className="text-secondary">
                                            {dateFormatting(file.lastModified)}
                                          </div>
                                        )}
                                      </Row>
                                    </div>
                                    <div>
                                      <Button
                                        id={`file-remove-${index}`}
                                        color="light"
                                        outline
                                        onClick={e => {
                                          e.stopPropagation();
                                          setFieldValue(
                                            'reportTemplateFiles',
                                            formikProps.values.reportTemplateFiles.filter(
                                              f => f.name !== file.name
                                            )
                                          );
                                        }}>
                                        <span>
                                          <i className="fas fa-trash text-danger" />
                                        </span>
                                      </Button>
                                      <UncontrolledTooltip
                                        fade={false}
                                        placement="auto"
                                        target={`file-remove-${index}`}>
                                        {t('task_management:remove')}
                                      </UncontrolledTooltip>
                                    </div>
                                  </div>
                                  {formikProps.isSubmitting && (
                                    <Progress
                                      className="mt-2 w-100"
                                      color={
                                        fileUploadProgresses[index] === 100
                                          ? 'success'
                                          : 'info'
                                      }
                                      value={fileUploadProgresses[index]}>
                                      {fileUploadProgresses[index]} %
                                    </Progress>
                                  )}
                                </ListGroupItem>
                              );
                            }
                          )}
                        </ListGroup>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {!!formikProps.errors.reportTemplateFiles &&
                  formikProps.submitCount > 0 && (
                    <Row>
                      <Col>
                        <div className="alert alert-danger mt-4" role="alert">
                          {formikProps.errors.reportTemplateFiles}
                        </div>
                      </Col>
                    </Row>
                  )}
                <div className="bg-white sticky-top d-flex justify-content-end mt-2">
                  <Button
                    color="success"
                    type="submit"
                    className="font-weight-bold"
                    // disabled={formikProps.isSubmitting}
                  >
                    {t('create')}
                  </Button>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    attachments: state.feedback,
    reportTemplateList: state.app.report_template_list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCustomReportTemplate: values =>
      dispatch(createCustomReportTemplate(values)),

    addFileToCustomReport: (customReportTemplatedId, file, onUploadProgress) =>
      dispatch(
        addFileToCustomReport(customReportTemplatedId, file, onUploadProgress)
      )
  };
};

export default compose(
  withTranslation(['rule_package_management']),
  connect(mapStateToProps, mapDispatchToProps)
)(Attachment);
