import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Row,
  Col,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  Label
} from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import CustomInput from '../../../UI/Input/customInput';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Message from '../../../UI/Message';

import { fetchUserListAsAdmin, resetNews } from '../../../../store/actions';

import { useDispatch, useSelector } from 'react-redux';
const displayForSelectAll = ['QP', 'PO', 'ADMIN', 'EVERYONE'];

const formikConfig = props => ({
  initialValues: {
    title: '',
    body: '',
    display_start_date: '',
    display_end_date: '',
    display_for: [],
    is_display_on_front: false,
    reviewer: ''
  },
  validateOnChange: true,
  validationSchema: Yup.object().shape(
    {
      title: Yup.string().required('Required'),
      body: Yup.string().required('Required'),
      display_for: Yup.array()
        .of(Yup.string())
        .min(
          1,
          'Please select at least one(1) user type or account type where you want to display the news'
        ),
      display_start_date: Yup.date()
        .required('Required')
        .when(
          'display_end_date',
          (endDate, schema) =>
            endDate &&
            schema.max(endDate, 'Start date must be earlier than End date')
        ),
      display_end_date: Yup.date()
        .required('Required')
        .when(
          'display_start_date',
          (startDate, schema) =>
            startDate &&
            schema.min(startDate, 'End date must be later than Start date')
        ),
      reviewer: Yup.string().required('Required')
    },
    ['display_start_date', 'display_end_date']
  ),
  onSubmit: (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    values.is_display_on_front = true;

    props.createNews(values);
    props.toggle();
    resetForm();
    Message.success(props.t('news_created_for_approval'));
    setSubmitting(false);
  }
});

const CreateNewsModal = props => {
  const { t } = props;
  const [term, setTerm] = useState('');
  const { users, authUser } = useSelector(state => state);
  const userMap = Object.keys(users).reduce((acc, currKey) => {
    if (authUser.userId === users[currKey].user_id) return acc;
    return {
      ...acc,
      [currKey]: {
        ...users[currKey],
        full_name: `${users[currKey].first_name} ${users[currKey].last_name}`
      }
    };
  }, {});
  const dispatch = useDispatch();
  const fetchUsersAdminFunc = () => {
    dispatch(
      fetchUserListAsAdmin(
        { function: 'ADMIN' },
        { field: 'created_at', order: 'asc' },
        { from: 0, to: -1 }
      )
    );
  };

  const filteredUsers = Object.values(userMap)
    .filter(
      user =>
        user.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
    )
    .sort((a, b) =>
      a.full_name.trim().toLowerCase() > b.full_name.trim().toLowerCase()
        ? 1
        : -1
    );

  const reviewerInputRef = useRef();

  const onSearchReviewer = e => {
    setTerm(e.target.value);
    reviewerInputRef.current.focus();
  };

  return (
    <div>
      <Formik {...formikConfig(props)}>
        {formikProps => {
          const {
            setFieldValue,
            isSubmitting,
            handleSubmit,
            values,
            resetForm
          } = formikProps;

          return (
            <Modal
              onOpened={fetchUsersAdminFunc}
              isOpen={props.modal}
              toggle={props.toggle}
              className={props.className}
              backdrop="static"
              size="lg">
              <ModalHeader
                toggle={() => {
                  resetForm();
                  props.toggle();
                }}>
                {t('create_news')}
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Field
                      name="title"
                      label={t('title')}
                      placeholder={t('title')}
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="body"
                      type="textarea"
                      label={t('body')}
                      placeholder={t('body')}
                      component={CustomInput}
                    />
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          name="display_start_date"
                          type="date"
                          label="Start Date"
                          placeholder={t('start_date')}
                          component={CustomInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field
                          name="display_end_date"
                          type="date"
                          label={t('end_date')}
                          component={CustomInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Field
                      name="display_for"
                      type="select"
                      label={t('display_for')}
                      component={CustomInput}
                      onChange={evt =>
                        setFieldValue(
                          'display_for',
                          [].slice
                            .call(evt.target.selectedOptions)
                            .map(option => option.value)
                        )
                      }
                      value={
                        values.display_for.includes('EVERYONE')
                          ? displayForSelectAll
                          : undefined
                      }
                      multiple={true}>
                      <option value="QP">{t('qp')}</option>
                      <option value="PO">{t('po')}</option>
                      <option value="ADMIN">{t('admin')}</option>
                      <option value="EVERYONE">{t('everyone')}</option>
                    </Field>
                  </FormGroup>
                  <Label for="news-reviewer">{t('reviewer')}</Label>
                  <FormGroup className="mb-0">
                    <div className="p-1 rounded border">
                      <div className="pb-3">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            {t('filter')}
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={onSearchReviewer}
                            placeholder={t('filterByNameOrEmail')}
                          />
                        </InputGroup>
                      </div>
                      <Field
                        innerRef={reviewerInputRef}
                        id="news-reviewer"
                        name="reviewer"
                        type="select"
                        component={CustomInput}
                        onChange={evt =>
                          setFieldValue(
                            'reviewer',
                            evt.target.value
                            // [].slice
                            //   .call(evt.target.selectedOptions)
                            //   .map(option => option.value)
                          )
                        }
                        value={values.reviewer}>
                        <option value="">--{t('select_reviewer')}--</option>
                        {Object.keys(filteredUsers).map(userKey => {
                          return (
                            <option
                              key={`option-key-${userKey}`}
                              value={filteredUsers[userKey].user_id}>
                              {filteredUsers[userKey].full_name} (
                              {filteredUsers[userKey].email})
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}>
                  {t('create')}
                </Button>{' '}
                <Button
                  color="secondary"
                  onClick={() => {
                    resetForm();
                    props.toggle();
                  }}>
                  {t('cancel')}
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};

export default withTranslation(['news'])(CreateNewsModal);
