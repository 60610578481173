import React from 'react';
import {
  Container,
  Nav,
  NavItem,
  NavLink as BootstrapNavLink
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '../Footer.css';

const footer = props => {
  const { t, isLoggedIn } = props;
  return (
    <div className={'footer bg-dark'}>
      <Container>
        <Nav className="d-flex justify-content-between">
          {/* LEFT */}
          <div className="d-flex">
            <NavItem>
              <BootstrapNavLink tag={NavLink} to="/privacy-statement">
                {t('privacy_statement')}
              </BootstrapNavLink>
            </NavItem>
            <NavItem>
              <BootstrapNavLink tag={NavLink} to="/term-of-use">
                {t('term_of_use')}
              </BootstrapNavLink>
            </NavItem>
            {/* <NavItem>
              <BootstrapNavLink
                tag="a"
                target="_blank"
                href={'https://www.tech.gov.sg/report_vulnerability'}>
                {t('report_vulnerability')}
              </BootstrapNavLink>
            </NavItem> */}
          </div>
          {/* RIGHT */}
          <div className="d-flex">
            {isLoggedIn && (
              <NavItem>
                <BootstrapNavLink
                  tag="a"
                  download
                  href={`${process.env.REACT_APP_API_ENDPOINT}/api/footer_documents/FORNAXCloud User Guide_v1.0.pdf`}>
                  {t('user_guide')}
                </BootstrapNavLink>
              </NavItem>
            )}
            {isLoggedIn && (
              <NavItem>
                <BootstrapNavLink tag={NavLink} to="/help_center">
                  {t('help_center')}
                </BootstrapNavLink>
              </NavItem>
            )}
            <NavItem>
              <BootstrapNavLink tag={NavLink} to="/#contact">
                {t('contact-us')}
              </BootstrapNavLink>
            </NavItem>
            <NavItem>
              <BootstrapNavLink tag={NavLink} to="/feedback">
                {t('feedback')}
              </BootstrapNavLink>
            </NavItem>
          </div>
        </Nav>
      </Container>
    </div>
  );
};

export default withTranslation(['front'])(footer);
