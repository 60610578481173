import React, { Component, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import {
  Container,
  Col,
  Row,
  ButtonGroup,
  Button,
  UncontrolledPopover,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { useLocation } from 'react-router-dom';
import uuid from 'uuid/v4';
import prettyBytes from 'pretty-bytes';

import DisplayMessage from '../Submission/Correspondence/DisplayMessage';

import { dateFormatting, timeAgoFormatting } from '../../../locales/dateFormat';
import { getTask } from '../../../store/actions';

import ActionDetail from './ActionDetail';
import styles from './taskManagement.module.css';

const PopoverDocuments = ({ document, ...props }) => {
  return (
    <>
      <Button
        className={styles.wordWrap}
        color="link"
        size="sm"
        id={`key-document-${document.id}`}
        // className={`${mentionProps.className}`}
        // style={{ backgroundColor: '#fffaf3', color: '#573a08' }}
      >
        {document.name}
      </Button>
      <UncontrolledPopover
        placement="bottom"
        className="popover-container"
        boundariesElement="window"
        trigger="legacy"
        fade={false}
        target={`key-document-${document.id}`}>
        <PopoverHeader>{document.name}</PopoverHeader>
        <PopoverBody>
          <div className="py-2">
            <div>
              <i className="far fa-calendar" />{' '}
              <span>
                Date uploaded :{' '}
                {dateFormatting(document.date_uploaded, 'defaultHour')}
              </span>
            </div>
          </div>
          <hr></hr>
          <Button
            tag="a"
            download
            href={`${
              process.env.REACT_APP_API_ENDPOINT[
                process.env.REACT_APP_API_ENDPOINT.length - 1
              ] == '/'
                ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                : process.env.REACT_APP_API_ENDPOINT
            }/${document.download_link}`}
            outline
            id={`key-document-${document.id}-download`}>
            <i className="fas fa-download" />
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

const PopoverUser = ({ user }) => (
  <>
    <Button
      color="link"
      size="sm"
      id={`key-ccuser-${user.user_id}`}
      // className={`${mentionProps.className}`}
    >
      {`${user.first_name} ${user.last_name}` !== ' '
        ? `${user.first_name} ${user.last_name}`
        : ''}
      ({user.email})
    </Button>
    <UncontrolledPopover
      // placement="bottom"
      className="popover-container"
      boundariesElement="window"
      trigger="legacy"
      fade={false}
      target={`key-ccuser-${user.user_id}`}>
      <PopoverHeader>
        {`${user.first_name} ${user.last_name}` !== ' '
          ? `${user.first_name} ${user.last_name}`
          : ''}
      </PopoverHeader>
      <PopoverBody>
        <div className="py-2">
          <div>
            <i className="far fa-envelope" /> <span>{user.email}</span>
          </div>
          <div>
            <i className="far fa-id-badge" /> <span>{user.role}</span>
          </div>
        </div>
      </PopoverBody>
    </UncontrolledPopover>
  </>
);

const PopoverGroup = ({ group }) => (
  <>
    <Button
      color="link"
      size="sm"
      id={`key-ccuser-${group.organization_id}`}
      // className={`${mentionProps.className}`}
    >
      {group.name}
    </Button>
    <UncontrolledPopover
      // placement="bottom"
      className="popover-container"
      boundariesElement="window"
      trigger="legacy"
      fade={false}
      target={`key-ccuser-${group.organization_id}`}>
      <PopoverHeader>{group.name}</PopoverHeader>
      <PopoverBody>
        <div className="py-2">
          <div>
            <i className="fa fa-users" /> Group Type:
            <span className="px-1">{group.type}</span>
          </div>
          <div>
            <i className="fa fa-calendar" /> Date created:
            <span className="px-1">
              {dateFormatting(group.created_date, 'defaultHour')}
            </span>
          </div>
          <div>
            <i className="fa fa-info-circle" /> Description:
            <span className="px-1">{group.description}</span>
          </div>
        </div>
      </PopoverBody>
    </UncontrolledPopover>
  </>
);

const PopoverIssues = ({ issue, ...props }) => {
  return (
    <>
      <Button
        color="link"
        size="sm"
        id={`key-issue-${issue.issue.issue_id}`}
        className={styles.wordWrap}
        // className={`${mentionProps.className}`}
        // style={{ backgroundColor: '#fffaf3', color: '#573a08' }}
      >
        {issue.issue.issue_topic}
      </Button>
      <UncontrolledPopover
        // placement="bottom"
        className="issue-popover-container"
        boundariesElement="window"
        trigger="legacy"
        fade={false}
        target={`key-issue-${issue.issue.issue_id}`}>
        <PopoverHeader>{issue.issue.issue_topic}</PopoverHeader>
        <div>
          <Row className=" m-0 px-0 py-2 d-flex justify-content-between">
            <Col sm={6}>
              <img
                className={styles.popoverIssuesImage}
                src={`${
                  process.env.REACT_APP_API_ENDPOINT[
                    process.env.REACT_APP_API_ENDPOINT.length - 1
                  ] == '/'
                    ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                    : process.env.REACT_APP_API_ENDPOINT
                }/api/issue/${props.projectId}/${issue.stageName}/image_issue/${
                  issue.issue.issue_id
                }`}
                alt={issue.issue.issue_topic}
              />
            </Col>

            <Col sm={6}>
              <div>
                <span>{props.t('task_management:topic')}: </span>
                <span className="font-weight-bold">
                  {issue.issue.issue_topic}
                </span>
              </div>
              <div>
                <span>{props.t('task_management:date_created')}: </span>
                <span className="font-weight-bold">
                  {dateFormatting(issue.issue.date_created, 'defaultHour')}
                </span>
              </div>
            </Col>
          </Row>
          <div
            className={`d-flex p-2 justify-content-end ${styles.popoverIssuesButtonGroupContainer}`}>
            <div>
              <ButtonGroup>
                <Button
                  tag={Link}
                  outline
                  id={`key-issue-${issue.issue.issue_id}-link`}
                  to={`/project/${props.projectId}/issue/${issue.stageName}/${issue.issue.issue_id}`}>
                  <i className="material-icons">link</i>
                </Button>
                <UncontrolledTooltip
                  fade={false}
                  target={`key-issue-${issue.issue.issue_id}-link`}
                  boundariesElement="window">
                  {props.t('submission:go_to_issue')}
                </UncontrolledTooltip>
                <Button
                  tag="a"
                  download
                  href={`${
                    process.env.REACT_APP_API_ENDPOINT[
                      process.env.REACT_APP_API_ENDPOINT.length - 1
                    ] == '/'
                      ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                      : process.env.REACT_APP_API_ENDPOINT
                  }/api/issue/${props.projectId}/${
                    issue.stageName
                  }/exportIssue?issueIdList=${issue.issue.issue_id}`}
                  outline
                  id={`key-issue-${issue.issue.issue_id}-export`}>
                  <i className="material-icons">archive</i>
                </Button>
                <UncontrolledTooltip
                  fade={false}
                  target={`key-issue-${issue.issue.issue_id}-export`}
                  boundariesElement="window">
                  {props.t('submission:export_to_bcf')}
                </UncontrolledTooltip>
                <Button
                  tag="a"
                  outline
                  href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${issue.viewerToken}`}
                  id={`key-issue-${issue.issue.issue_id}-viewer`}
                  // to={{
                  //   pathname: `/project/${this.props.projectId}/${
                  //     this.props.stageName
                  //   }/viewer`,
                  //   search: `viewerMode=3d&token=${row.fxrViewerToken}`
                  // }}
                  rel="noopener noreferrer"
                  target="_blank">
                  <span>
                    <i className="material-icons">arrow_forward</i>
                  </span>
                </Button>
                <UncontrolledTooltip
                  fade={false}
                  target={`key-issue-${issue.issue.issue_id}-viewer`}
                  boundariesElement="window">
                  {props.t('task_management:open_issue_in_viewer')}
                </UncontrolledTooltip>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </UncontrolledPopover>
    </>
  );
};

const PopoverResults = ({ result, ...props }) => {
  const featureList = useSelector(state => state.app.featureList);
  const isFXRDownloadEnabled = featureList.fxrDownload;

  const statusToIcon = ({ status, job_id }, translate) => {
    switch (status) {
      case 'DONE':
        return (
          <>
            <span id={`status-result-${job_id}`}>
              <i className="fas fa-check-circle text-success fs-2x" />
            </span>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`status-result-${job_id}`}
              delay={100}>
              {translate('results:status_done')}
            </UncontrolledTooltip>
          </>
        );
      case 'IN_PROCESS':
        return (
          <>
            <span id={`status-result-${job_id}`}>
              <i className="fas fa-spinner fa-spin text-info fs-2x" />
            </span>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`status-result-${job_id}`}
              delay={100}>
              {translate('results:status_in_process')}
            </UncontrolledTooltip>
          </>
        );
      case 'PENDING':
        return (
          <>
            <span id={`status-result-${job_id}`}>
              <i className="fas fa-spinner fa-spin text-info fs-2x" />
            </span>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`status-result-${job_id}`}
              delay={100}>
              {translate('results:status_in_process')}
            </UncontrolledTooltip>
          </>
        );

      case 'ON_ERROR':
        return (
          <>
            <span id={`status-result-${job_id}`}>
              <i className="fas fa-exclamation-circle text-danger fs-2x" />
            </span>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`status-result-${job_id}`}
              delay={100}>
              {translate('results:status_on_error')}
            </UncontrolledTooltip>
          </>
        );
      case 'OBSOLETE':
        return (
          <>
            <span id={`status-result-${job_id}`}>
              <i className="fas fa-exclamation-triangle text-warning  fs-2x" />
            </span>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`status-result-${job_id}`}
              delay={100}>
              {translate('results:status_obsolete')}
            </UncontrolledTooltip>
          </>
        );
    }
  };
  const fileTypeFormatting = (row, file, index, translate) => {
    const fileType = file.split('.').pop().toLowerCase();
    const formatConfig = (fileExtension => {
      switch (fileExtension) {
        case 'pdf': {
          return {
            colorButton: 'danger',
            fileIcon: 'fas fa-file-pdf',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'xlsx': {
          return {
            colorButton: 'success',
            fileIcon: 'fas fa-file-excel',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'docx': {
          return {
            colorButton: 'primary',
            fileIcon: 'fas fa-file-word',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'fxr': {
          return {
            colorButton: 'success',
            fileIcon: 'fas fa-building',
            label: translate('results:open_result_in_viewer'),
            linkType: 'normal',
            isFXR: true
          };
        }
        case 'bcfzip': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-archive',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'zip': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-archive',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        case 'html': {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-file-code',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
        default: {
          return {
            colorButton: 'info',
            fileIcon: 'fas fa-question',
            label: fileExtension,
            linkType: 'download',
            isFXR: false
          };
        }
      }
    })(fileType);

    if (formatConfig.isFXR) {
      let result = <></>;
      if (!row.is_obsolete) {
        result = (
          <a
            href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.viewerToken}`}
            // to={{
            //   pathname: `/project/${this.props.projectId}/${
            //     this.props.stageName
            //   }/viewer`,
            //   search: `viewerMode=3d&token=${row.fxrViewerToken}`
            // }}
            rel="noopener noreferrer"
            target="_blank"
            key={`${row.job_id}-${index}`}>
            <Button
              id={`fileLink-${row.job_id}-${fileType}`}
              size="md"
              color={formatConfig.colorButton}
              className="text-white"
              disabled={row.is_obsolete ? true : false}>
              <i className={formatConfig.fileIcon} />
            </Button>
            <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`fileLink-${row.job_id}-${fileType}`}
              delay={0}>
              {formatConfig.label}
            </UncontrolledTooltip>
          </a>
        );
      }

      if (isFXRDownloadEnabled) {
        result = (
          <>
            {result}
            <a
              download={`${props.projectName}_report_${row.job_id.substring(
                row.job_id.length - 12
              )}.fxr`}
              onClick={e => e.stopPropagation()}
              href={`${
                process.env.REACT_APP_API_ENDPOINT[
                  process.env.REACT_APP_API_ENDPOINT.length - 1
                ] == '/'
                  ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                  : process.env.REACT_APP_API_ENDPOINT
              }/api/project/${props.projectId}/${result.stageName}/${
                row.job_id
              }/${file}/download`}
              key={`${row.job_id}-${index}-fxr`}>
              <Button
                id={`fileLink-${row.job_id}-fxrdl`}
                size="md"
                color={formatConfig.colorButton}
                className="text-white font-weight-bold px-2 ml-1">
                FXR
              </Button>
              <UncontrolledTooltip
                boundariesElement="window"
                fade={false}
                target={`fileLink-${row.job_id}-fxrdl`}
                delay={0}>
                [DEBUG] Download fxr file
              </UncontrolledTooltip>
            </a>
          </>
        );
      }
      return result;
    } else {
      return (
        <a
          download={`report_${row.job_id.substring(
            row.job_id.length - 12
          )}.${fileType}`}
          onClick={e => e.stopPropagation()}
          href={`${
            process.env.REACT_APP_API_ENDPOINT[
              process.env.REACT_APP_API_ENDPOINT.length - 1
            ] == '/'
              ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
              : process.env.REACT_APP_API_ENDPOINT
          }/api/project/${props.projectId}/${result.stageName}/${
            row.job_id
          }/${file}/download`}
          key={`${row.job_id}-${index}`}>
          <Button
            id={`fileLink-${row.job_id}-${fileType}`}
            size="md"
            color={formatConfig.colorButton}
            className="text-white">
            <i className={formatConfig.fileIcon} />
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`fileLink-${row.job_id}-${fileType}`}
            delay={0}>
            {formatConfig.label}
          </UncontrolledTooltip>
        </a>
      );
    }
  };
  return (
    <>
      <Button
        color="link"
        size="sm"
        id={`key-result-${result.job_id}`}
        // className={`${mentionProps.className}`}
        // style={{ backgroundColor: '#fffaf3', color: '#573a08' }}
      >
        {`${props.t(result.type)} - ${dateFormatting(
          result.date_created,
          'defaultHour'
        )} ${result.is_obsolete ? '(Obsolete)' : ''} `}
      </Button>
      <UncontrolledPopover
        // placement="bottom"
        className="model-popover-container"
        boundariesElement="scrollParent"
        trigger="legacy"
        fade={false}
        target={`key-result-${result.job_id}`}>
        <PopoverHeader>{`${props.t(result.type)} - ${dateFormatting(
          result.date_created,
          'defaultHour'
        )} ${result.is_obsolete ? '(Obsolete)' : ''} `}</PopoverHeader>
        <Container>
          <Row className="p-2">
            {props.t('results:status')} : {statusToIcon(result, props.t)}
          </Row>
          <Row className="d-flex justify-content-end p-2">
            {result.fileavailable &&
              result.fileavailable.map((file, index) => {
                return (
                  <div
                    key={`${result.job_id}-${index}`}
                    className={index > 0 ? 'ml-1' : ''}>
                    {fileTypeFormatting(result, file, index, props.t)}
                  </div>
                );
              })}
          </Row>
        </Container>
      </UncontrolledPopover>
    </>
  );
};

const PopoverModels = ({ model, ...props }) => {
  const modelId = uuid();

  const modelFolder = model.type === '3D' ? 'models' : '2D_models';

  return (
    <>
      <Button
        className={styles.wordWrap}
        color="link"
        size="sm"
        id={`key-model-${modelId}`}
        // className={`${mentionProps.className}`}
        // style={{ backgroundColor: '#fffaf3', color: '#573a08' }}
      >
        {model.name}
      </Button>
      <UncontrolledPopover
        // placement="bottom"
        className="model-popover-container"
        boundariesElement="scrollParent"
        trigger="legacy"
        fade={false}
        target={`key-model-${modelId}`}>
        <PopoverHeader>{model.name}</PopoverHeader>
        <Container>
          <Row className="d-flex justify-content-between px-2 ">
            <div
              className={`d-flex flex-column flex-grow-1 justify-content-between ${styles.popoverModelsContainer}`}>
              <div>
                {model.name} - {prettyBytes(model.size)}
              </div>
              {model.date && (
                <div className="text-secondary">
                  last updated: {timeAgoFormatting(model.date)} ago
                </div>
              )}
            </div>
            <div className="ml-2 d-flex align-items-center justify-content-between">
              <div>
                <>
                  <a
                    download
                    id={`downloadModel-${modelId}`}
                    onClick={e => e.stopPropagation()}
                    className="pointer"
                    href={`${
                      process.env.REACT_APP_API_ENDPOINT[
                        process.env.REACT_APP_API_ENDPOINT.length - 1
                      ] == '/'
                        ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                        : process.env.REACT_APP_API_ENDPOINT
                    }/api/project/${props.projectId}/${
                      model.stageName
                    }/${modelFolder}/${model.name}`}>
                    <span>
                      <i className="fas fa-download fs-1-2x text-primary" />
                    </span>
                  </a>
                  <UncontrolledTooltip
                    fade={false}
                    boundariesElement="window"
                    target={`downloadModel-${modelId}`}>
                    {props.t('main:download')}
                  </UncontrolledTooltip>
                </>
              </div>
              <div className="ml-2">
                <>
                  <a
                    href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${model.viewerToken}`}
                    // to={{
                    //   pathname: `/project/${this.props.projectId}/${
                    //     this.props.stageName
                    //   }/viewer`,
                    //   search: `viewerMode=3d&token=${row.fxrViewerToken}`
                    // }}
                    id={`openInViewerModel-${modelId}`}
                    rel="noopener noreferrer"
                    target="_blank">
                    <span>
                      <i className="fas fa-arrow-circle-right text-success"></i>
                    </span>
                    {/* <UncontrolledTooltip
              boundariesElement="window"
              fade={false}
              target={`fileLink-${row.job_id}-${fileType}`}
              delay={0}>
              {formatConfig.label}
            </UncontrolledTooltip> */}
                  </a>
                  <UncontrolledTooltip
                    fade={false}
                    boundariesElement="window"
                    target={`openInViewerModel-${modelId}`}>
                    {props.t('task_management:open_model_in_viewer')}
                  </UncontrolledTooltip>
                </>
              </div>
            </div>
          </Row>
        </Container>
      </UncontrolledPopover>
    </>
  );
};

const TaskDetailNav = ({ taskDetailBlock, t, task, vertical }) => (
  <div className="sticky-top">
    <Nav
      tabs
      vertical={vertical}
      className={classnames('shadow mt-3 rounded mb-3 bg-light', {
        'p-3': !vertical
      })}>
      <NavItem
        className={classnames({
          // 'border-primary rounded border-sz-3':
          //   this.state.activeTab === 'details',
          // 'border-right': this.state.activeTab === 'details' && vertical,
          // 'border-left': this.state.activeTab === 'details' && !vertical
        })}>
        <NavLink
          // className={classnames('bg-light rounded', {
          // 'active font-weight-bold': this.state.activeTab === 'details'
          // })}

          className={`bg-light rounded ${styles.borderNone}`}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.getElementById('scroll-container').scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }}>
          {t('task_management:task_details')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          // className={classnames('bg-light rounded', {
          // 'active font-weight-bold': this.state.activeTab === 'result_files'
          // })}
          className={`bg-light rounded ${styles.borderNone}`}
          onClick={() => {
            document.getElementById('scroll-container').scrollTo({
              top:
                document.getElementById('message').getBoundingClientRect()
                  .offsetTop -
                (taskDetailBlock.current
                  ? taskDetailBlock.current.clientHeight
                  : 0),
              behavior: 'smooth'
            });
          }}>
          {t('task_management:message')}
        </NavLink>
      </NavItem>
      {task.actions.map((action, index) => {
        return (
          <NavItem key={`scroll-spy-action-${index}`}>
            <NavLink
              // className={classnames('bg-light rounded', {
              // 'active font-weight-bold': this.state.activeTab === 'model_files'
              // })}
              className={`bg-light rounded ${styles.borderNone} ${styles.actionName}`}
              onClick={() => {
                document.getElementById('scroll-container').scrollTo({
                  top:
                    document.getElementById(`action-${action.action_id}`)
                      .offsetTop -
                    (taskDetailBlock.current
                      ? taskDetailBlock.current.clientHeight
                      : 0),
                  behavior: 'smooth'
                });
              }}>
              {action.name}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  </div>
);

const TaskDetail = ({
  task,
  t,
  projectId,
  sidebarRef,
  loggedInUser,
  reloadTask,
  ...props
}) => {
  const itemRelatedTypes = Object.keys(task.items_related).filter(
    itemType => task.items_related[itemType].length > 0
  );
  const [itemRelatedTab, setItemRelatedTab] = useState(
    `rel-items-tab-${itemRelatedTypes[0] || ''}`
  );

  const itemToPopoverMap = {
    documents: PopoverDocuments,
    models: props => <PopoverModels {...props} />,
    issues: PopoverIssues,
    results: PopoverResults
  };

  const [isDuplicatedFilesModalOpen, setIsDuplicatedFilesModalOpen] =
    useState(false);
  const [duplicatedFiles, setDuplicatedFiles] = useState([]);
  const [messageContent, setMessageContent] = useState(
    ContentState.createFromText('')
  );

  const [isMessageLoaded, setMessageLoaded] = useState(false);
  useEffect(() => {
    try {
      const messageContentVar = convertFromRaw(
        JSON.parse(decodeURI(task.message))
      );
      setMessageContent(messageContentVar);
      setMessageLoaded(true);
    } catch (err) {
      setMessageLoaded(false);
    }
  }, [task.message]);

  const [actionActive, setActionActive] = useState('');
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.substr(1);
    if (task.actions.find(action => action.action_id === hash)) {
      setActionActive(hash);
      document.getElementById('scroll-container').scrollTo({
        top:
          document.getElementById(`action-${hash}`).offsetTop -
          document.getElementById('scroll-container').getBoundingClientRect()
            .top -
          (taskDetailBlock.current ? taskDetailBlock.current.clientHeight : 0),
        behavior: 'smooth'
      });
    }
  }, [location]);

  const toggleDuplicatedFilesModal = () => {
    setIsDuplicatedFilesModalOpen(!isDuplicatedFilesModalOpen);
  };

  // Used to get the height of the task detail static block
  const taskDetailBlock = useRef(null);

  const actionsCompleted = task.actions.filter(
    a => a.status === 'COMPLETED'
  ).length;
  return (
    <>
      {sidebarRef &&
        sidebarRef.current &&
        ReactDOM.createPortal(
          <TaskDetailNav
            vertical={true}
            task={task}
            t={t}
            taskDetailBlock={taskDetailBlock}
          />,
          sidebarRef.current
        )}
      <Container className="mb-4">
        <Row>
          <Col>
            <Container>
              {/* TASK DETAIL */}
              <div id="task-details-anchor" />
              <Row className="shadow bg-white rounded mt-4 py-2">
                <div id="task-details" className="w-100" ref={taskDetailBlock}>
                  <Container>
                    <Row>
                      <Col>
                        <Container>
                          <Row>
                            <Col className={styles.wordWrapBreakWord}>
                              <h4> {task.topic} </h4>
                            </Col>
                          </Row>
                          <Row>
                            {/* ITEMS RELATED */}
                            <Col>
                              <Nav className="" tabs>
                                {itemRelatedTypes.map(itemType => {
                                  return (
                                    <NavLink
                                      key={`rel-items-tab-${itemType}`}
                                      tag={Button}
                                      color="primary"
                                      size="xs"
                                      outline
                                      className={classnames(
                                        'p-1 rounded-bottom-0 rounded-top',
                                        {
                                          'active font-weight-bold':
                                            itemRelatedTab ===
                                            `rel-items-tab-${itemType}`
                                        }
                                      )}
                                      onClick={() =>
                                        setItemRelatedTab(
                                          `rel-items-tab-${itemType}`
                                        )
                                      }>
                                      {t(`task_management:${itemType}`)}
                                    </NavLink>
                                  );
                                })}
                              </Nav>
                              <TabContent activeTab={itemRelatedTab}>
                                {itemRelatedTypes.map(itemType => {
                                  return (
                                    <TabPane
                                      key={`rel-items-tab-${itemType}`}
                                      tabId={`rel-items-tab-${itemType}`}
                                      className="w-100 p-2 my-2">
                                      <ListGroup>
                                        {task.items_related[itemType]
                                          .filter(item => !!item)
                                          .map((item, index) => {
                                            const pluralMapping = {
                                              models: 'model',
                                              documents: 'document',
                                              issues: 'issue',
                                              results: 'result'
                                            };
                                            const RenderedComponent =
                                              itemToPopoverMap[itemType];

                                            return (
                                              <ListGroupItem
                                                className={
                                                  styles.wordWrapBreakWord
                                                }
                                                key={`item-${pluralMapping[itemType]}-${index}`}>
                                                {task.items_related[itemType][
                                                  index
                                                ].error ? (
                                                  <p className="text-danger">
                                                    {
                                                      task.items_related[
                                                        itemType
                                                      ][index].message
                                                    }
                                                  </p>
                                                ) : (
                                                  <RenderedComponent
                                                    className={
                                                      styles.wordWrapBreakWord
                                                    }
                                                    {...{
                                                      [pluralMapping[itemType]]:
                                                        item,
                                                      projectId,
                                                      t
                                                    }}
                                                  />
                                                )}
                                              </ListGroupItem>
                                            );
                                          })}
                                      </ListGroup>
                                    </TabPane>
                                  );
                                })}
                              </TabContent>
                            </Col>
                          </Row>

                          {/* {task.items_related} */}
                        </Container>
                      </Col>

                      {/* <Col sm={6} className="d-flex flex-column border-left">
                        <div className="d-flex flex-column"> */}
                      {/* USERs IN COPY */}
                      {/* <ListGroup flush className="rounded border">
                            <ListGroupItem
                              color="primary"
                              className=" rounded-top p-1">
                              {t('task_management:cc_users')}
                            </ListGroupItem>
                            {task.cc_users.map((user, index) => {
                              return (
                                <ListGroupItem
                                  key={`ccuser-${index}`}
                                  className="p-1 rounded-bottom">
                                  <PopoverUser user={user} />
                                </ListGroupItem>
                              );
                            })}
                            {task.cc_groups.map((group, index) => {
                              return (
                                <ListGroupItem
                                  key={`ccgroup-${index}`}
                                  className="p-1 rounded-bottom">
                                  <PopoverGroup group={group} />
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup> */}
                      {/* SCROLL SPY */}
                      {/* </div>
                      </Col> */}
                    </Row>
                    <Row className="px-2">
                      <Progress
                        className="mt-2 w-100"
                        color={
                          actionsCompleted === task.actions.length
                            ? 'success'
                            : 'info'
                        }
                        value={(actionsCompleted / task.actions.length) * 100}>
                        {actionsCompleted} /{task.actions.length}{' '}
                        {t('task_management:actions')}
                      </Progress>
                    </Row>
                  </Container>
                </div>
              </Row>
              <div id="message" />
              <Row
                className={`shadow rounded  p-2 mt-3 ${styles.messageContainer}`}>
                {/* <Col > */}
                <Container className="rounded my-3">
                  <h5 className="text-primary py-1">
                    {t('task_management:message')}
                  </h5>
                  {isMessageLoaded && (
                    <Row
                      className={`p-3 rounded ${styles.displayMessageContainer}`}>
                      <DisplayMessage
                        stageName={''}
                        projectId={projectId}
                        content={messageContent}
                      />
                    </Row>
                  )}
                </Container>
                {/* </Col> */}
              </Row>
              {/* LIST ACTIONS */}
              {task.actions &&
                task.actions.map((action, index) => {
                  const displayMode = {
                    ACTIVE: action.actors
                      .map(a => a.user_id)
                      .includes(loggedInUser.userId)
                      ? 'edit'
                      : 'disabled',
                    PENDING: 'disabled',
                    COMPLETED: 'readonly'
                  }[action.status];

                  return (
                    <div
                      key={`action-${index}`}
                      id={`action-${action.action_id}`}>
                      <ActionDetail
                        action={action}
                        projectId={projectId}
                        taskId={task.task_id}
                        isHighlighted={action.action_id === actionActive}
                        displayMode={displayMode}
                        onSubmitCallback={reloadTask}
                        actionList={task.actions}
                        taskDetailBlock={taskDetailBlock}
                        taskDocuments={task.items_related.documents}
                        setDuplicatedFiles={setDuplicatedFiles}
                        setIsDuplicatedFilesModalOpen={
                          setIsDuplicatedFilesModalOpen
                        }
                      />
                    </div>
                  );
                })}
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={isDuplicatedFilesModalOpen}
        toggle={toggleDuplicatedFilesModal}>
        <ModalHeader toggle={toggleDuplicatedFilesModal}>
          {t('task_management:duplicated_file_modal_header')}
        </ModalHeader>
        <ModalBody>
          {t('task_management:duplicated_files_modal_content_p1')}
          <ul>
            {duplicatedFiles.map((filename, index) => {
              return <li key={`duplicated-file-${index}`}> {filename}</li>;
            })}
          </ul>
          {t('task_management:duplicated_files_modal_content_p2')}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleDuplicatedFilesModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const TaskDetailContainer = props => {
  const { projectId, taskId, getTask, task } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);

  const reload = () => {
    (async () => {
      // setIsLoaded(false);
      await getTask(taskId)
        .then(result => {
          setIsLoaded(true);
        })
        .catch(err => {
          setOnError(true);
        });
    })();
  };
  useEffect(() => {
    setIsLoaded(false);
    reload();
  }, [taskId]);

  return (
    <>{isLoaded && task && <TaskDetail {...props} reloadTask={reload} />}</>
  );
};

const mapStateToProps = (state, props) => {
  const taskId = props.taskId;
  const task = state.tasks[taskId];
  if (task && task.actions) {
    task.actions.sort((a, b) => {
      if (a.order === b.order) {
        return a.name < b.name ? -1 : 1;
      } else {
        return a.order - b.order;
      }
    });
  }
  return {
    task,
    loggedInUser: state.authUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTask: taskId => dispatch(getTask(taskId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['main', 'task_management', 'submission'])
)(TaskDetailContainer);
