import React from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

const index = props => {
  const { title, description, trigger, ...others } = props;
  return (
    <UncontrolledPopover trigger={trigger || 'hover'} {...others}>
      <PopoverBody>
        <div className="font-weight-bold text-primary">{title}</div>
        <div>{description}</div>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default index;
