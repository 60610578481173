import { authUserActionType, appActionType } from '../../constants/actionType';
import { performRequest } from '../requests';
import { resetNotification } from '../../../store/actions';

export const Authenticate = values => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/auth/login',
      JSON.stringify(values),
      requestConfig,
      'LOGIN'
    )
  )
    .then(res => {
      if (res.data.result === 'Success') {
        dispatch(setMe(res.data.user));
        dispatch({
          type: appActionType.SET_AUTHENTICATED,
          isAuthenticated: true
        });
        return { result: true, messageKey: 'login:login_success' };
      } else {
        return { result: false, messageKey: res.data.messageKey };
      }
    })
    .catch(err => {
      // set the redirect state to login
      dispatch({
        type: appActionType.SET_REDIRECT,
        err
      });
      return {
        result: false,
        messageKey:
          (err.response && err.response.data && err.response.data.error) ||
          'error:server_error'
      };
    });
};

export const setMe = data => {
  const action = {
    type: authUserActionType.SET_ME,
    data: data
  };
  return action;
};

export const unsetMe = () => {
  const action = {
    type: authUserActionType.UNSET_ME
  };
  return action;
};

export const logout = () => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest('get', '/api/auth/logout', {}, requestConfig, 'LOGOUT')
  ).then(() => {
    dispatch({
      type: appActionType.SET_AUTHENTICATED,
      isAuthenticated: false
    });
    dispatch(unsetMe());
    dispatch(resetNotification());
  });
};

export const isAuthenticated = (userType = 'QP') => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      '/api/auth/authenticated',
      {},
      requestConfig,
      'IS_AUTHENTICATED'
    )
  )
    .then(res => {
      if (res.data.success) {
        dispatch(setMe(res.data.user));
        dispatch({
          type: appActionType.SET_AUTHENTICATED,
          isAuthenticated: true
        });
      } else {
        dispatch({
          type: appActionType.SET_AUTHENTICATED,
          isAuthenticated: false
        });
        dispatch(unsetMe());
      }
      const nextState = getState();
      if (userType === 'ANY') {
        return Promise.resolve(nextState.app.isAuthenticated);
      } else if (Array.isArray(userType)) {
        return Promise.resolve(
          nextState.app.isAuthenticated &&
            userType.find(v => v === nextState.authUser.function)
        );
      } else {
        return Promise.resolve(
          nextState.app.isAuthenticated &&
            nextState.authUser.function === userType
        );
      }
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: appActionType.SET_AUTHENTICATED,
        isAuthenticated: false
      });
    });
};

// REGISTRATION function
export const registerUser = userData => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return dispatch(
    performRequest(
      'post',
      '/api/user/register',
      JSON.stringify(userData),
      requestConfig,
      'REGISTER'
    )
  ).then(res => {
    dispatch({
      type: authUserActionType.REGISTER_USER
    });
    return res.data;
  });
};

// REGISTRATION for invited user function
export const registerInvitedUser = userData => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return dispatch(
    performRequest(
      'post',
      '/api/user/registerInvited',
      JSON.stringify(userData),
      requestConfig,
      'REGISTER'
    )
  ).then(res => {
    dispatch({
      type: authUserActionType.REGISTER_USER
    });
    return res.data;
  });
};

export const editUserProfile = (userId, updatedUserData) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'put',
      `/api/user/${userId}`,
      updatedUserData,
      requestConfig,
      'UPDATE_USER'
    )
  )
    .then(res => res.data)
    .then(res => {
      dispatch({
        type: authUserActionType.UPDATE_USER,
        updatedUserData
      });
      return res.result;
    });
};

export const sendConfirmation = token => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'get',
      `/api/user/confirmation/${token}/`,
      {},
      requestConfig,
      'CONFIRM_USER'
    )
  ).then(response => {
    if (response.data.result === 'success') {
      return {
        success: true,
        message: response.data.messageKey || 'user_confirmed'
      };
    } else {
      return {
        success: false,
        message: response.data.messageKey || 'default_message',
        data: {
          ...response.data
        }
      };
    }
  });
};

export const resetPassword = email => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'post',
      '/api/forgot_password',
      email,
      requestConfig,
      'PASSWORD_RESET'
    )
  )
    .then(response => response.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey
        };
      } else {
        return {
          success: false,
          message: res.messageKey
        };
      }
    });
};

export const createNewPassword = (newPassword, token) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'post',
      `/api/forgot_password/password_reset/${token}/`,
      newPassword,
      requestConfig,
      'PASSWORD_RESET'
    )
  )
    .then(response => response.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey
        };
      } else {
        return {
          success: false,
          message: res.messageKey
        };
      }
    });
};

export const checkResetPasswordToken = token => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'get',
      `/api/forgot_password/password_reset/${token}/`,
      {},
      requestConfig,
      'CHECK_TOKEN'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey
        };
      } else {
        return {
          success: false,
          message: res.messageKey,
          data: {
            ...res
          }
        };
      }
    });
};

//Check token if valid invitation token
export const checkTokenForInvitation = token => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'get',
      `/api/user/invitation_confirmation/${token}/`,
      {},
      requestConfig,
      'CHECK_INVITATION_TOKEN'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey,
          data: {
            ...res
          }
        };
      } else {
        return {
          success: false,
          message: res.messageKey,
          data: {
            ...res
          }
        };
      }
    });
};

export const setNewPassword = (newSetPassword, token) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'post',
      `/api/user/invitation_confirmation/${token}/`,
      newSetPassword,
      requestConfig,
      'SET_PASSWORD'
    )
  )
    .then(response => response.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey
        };
      } else {
        return {
          success: false,
          message: res.messageKey
        };
      }
    });
};

// Resend the confirmation/invitation token if expired
export const resendToken = (userId, isInvited, recaptcha) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return dispatch(
    performRequest(
      'post',
      `/api/user/resend_token/${userId}/`,
      { isInvited, recaptcha },
      requestConfig,
      'RESEND_TOKEN'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: res.result,
          message: res.messageKey
        };
      } else {
        return {
          success: res.result,
          message: res.messageKey
        };
      }
    });
};
