import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  CustomInput,
  Col
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import CustomInputFromUI from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import styles from './Styles.module.css';

import Message from '../../UI/Message';

const formikConfig = props => ({
  initialValues: {
    stageOrigin: props.defaultOriginStage,
    newStageName:
      props.availableStagesForCreation.length > 0
        ? props.availableStagesForCreation[0].name
        : '',
    isBlankStage: false
  },

  validationSchema: Yup.object().shape({
    stageOrigin: Yup.string().required('Required'),
    newStageName: Yup.string().required('Required')
  }),

  onSubmit: (values, { setSubmitting, setFieldError }) => {
    const { projectId } = props;
    setSubmitting(true);
    props
      .createStage(projectId, values)
      .then(stageData => {
        props.toggleModalStage(props.isDropdownStageListingEnable);
        props.onCreateSuccess(stageData);
      })
      .catch(error => {
        if (error.message === 'error_stage_exist') {
          setFieldError('newStageName', props.t(error.message));
        } else {
          Message.error(props.t(`error:${error.message}`));
        }
      })
      .then(() => setSubmitting(false));
  }
});

class ProjectStageCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stageOrigin: props.defaultOriginStage
    };
  }
  onChangeStageOrigin = e => {
    this.setState({ stageOrigin: e.currentTarget.textContent });
  };
  render() {
    const {
      isOpen,
      toggleModalStage,
      stagesAvailable,
      t,
      availableStagesForCreation,
      project
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModalStage}
        backdrop={'static'}
        className={this.props.className}>
        <Formik {...formikConfig(this.props)}>
          {props => {
            const { isSubmitting, handleSubmit, setFieldValue, values } = props;

            const isCreateStageButtonDisabled = () => {
              if (
                this.props.stage &&
                this.props.stage.name === values.stageOrigin
              ) {
                return this.props.stage.web_viewer_status !== 'AVAILABLE';
              } else {
                const currentStageOfAddStage = project.stagesavailable.find(
                  stage => stage.name === values.stageOrigin
                );
                return currentStageOfAddStage.web_viewer_status !== 'AVAILABLE';
              }
            };

            return (
              <Form>
                <ModalHeader toggle={toggleModalStage}>
                  {t('new_stage')}
                </ModalHeader>

                <ModalBody>
                  <FormGroup row>
                    <Col>
                      <Field
                        name="stageOrigin"
                        placeholder={'Current Stage:'}
                        label={t('current_stage')}
                        options={[
                          ...stagesAvailable.map(stage => ({
                            value: stage.name,
                            label: stage.display_name
                          }))
                        ]}
                        component={CustomSelectInput}
                        disabled={values.isBlankStage ? true : false}
                      />
                    </Col>
                    <Col className={styles.modalMarginStageCreation}>
                      <CustomInput
                        type="checkbox"
                        id="isBlankStageCheckBox"
                        name="isBlankStage"
                        onChange={el => {
                          const ischeck = el.target.checked;
                          setFieldValue('isBlankStage', ischeck);
                        }}
                        label={t('blank_stage')}
                      />
                    </Col>
                  </FormGroup>

                  <Field
                    name="newStageName"
                    placeholder={t('new_stage_name')}
                    label={t('new_stage_name')}
                    options={[
                      ...availableStagesForCreation.map(stage => ({
                        value: stage.name,
                        label: stage.display_name
                      }))
                    ]}
                    component={CustomSelectInput}
                  />
                  {/* <FormGroup>
                    <Field
                      name="newStageName"
                      type="text"
                      label="New Stage:"
                      placeholder="New Stage Name"
                      component={CustomInputFromUI}
                    />
                  </FormGroup> */}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting ||
                      (!values.isBlankStage && isCreateStageButtonDisabled())
                    }>
                    {t('create_stage')}
                  </Button>{' '}
                  <Button color="secondary" onClick={toggleModalStage}>
                    {t('cancel')}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}
export default withTranslation(['project'])(ProjectStageCreation);
