import React, { Component, useState } from 'react';
import { compose } from 'recompose';

import { socketProjectEvents } from '../../../../api/sockets/constantEvents';

import { isEqual } from 'lodash';

import { getURLParameters } from '../../../../utils/url';

import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container,
  Row,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';
import Message from '../../../UI/Message';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dateFormatting } from '../../../../locales/dateFormat';

import JobStatusPopUpInfo from '../../../Shared/RuleCheckStatusReport';
import { connect } from 'react-redux';

import styles from './../ProjectResultRules.module.css';

import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

const NoData = t => <h1>{t('no_data')}</h1>;

const selectOptionsStatus = t => {
  return {
    // DONE: t('results:status_completed'),
    AVAILABLE: t('status_completed'),
    PENDING: t('results:status_pending'),
    IN_PROCESS: t('results:status_process'),
    ON_ERROR: t('results:status_on_error'),
    OBSOLETE: t('status_obsolete')
  };
};

const statusToIcon = (status, index, translate) => {
  index++;
  switch (status) {
    case 'DONE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'AVAILABLE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'IN_PROCESS':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_in_process')}
          </UncontrolledTooltip>
        </>
      );
    case 'PENDING':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('pending_process')}
          </UncontrolledTooltip>
        </>
      );

    case 'ON_ERROR':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-exclamation-circle text-danger fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_on_error')}
          </UncontrolledTooltip>
        </>
      );
    case 'OBSOLETE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-exclamation-triangle text-warning  fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_obsolete')}
          </UncontrolledTooltip>
        </>
      );
  }
};

const fileTypeFormatting = (props, row, file, index, translate) => {
  const comparison_id = row.comparison_rel.comparison_id || '';
  const fileType = file.split('.').pop().toLowerCase();
  const formatConfig = (fileExtension => {
    switch (fileExtension) {
      case 'xlsx': {
        return {
          colorButton: 'success',
          fileIcon: 'fas fa-file-excel',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }

      case 'zip': {
        return {
          colorButton: 'info',
          fileIcon: 'fas fa-file-archive',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }

      default: {
        return {
          colorButton: 'info',
          fileIcon: 'fas fa-question',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
    }
  })(fileType);

  return (
    <DropdownItem
      tag="a"
      onClick={e => e.stopPropagation()}
      href={`${process.env.REACT_APP_API_ENDPOINT}/api/project/${props.projectId}/${props.stageName}/model_compare/result/${comparison_id}/${file}/download`}
      key={`result-${index}`}
      id={`fileLink-result-${fileType}`}
      size="md"
      color="light">
      <div>
        {formatConfig.label}
        <i className={`${formatConfig.fileIcon} ml-1`} />
      </div>
      <UncontrolledTooltip
        boundariesElement="window"
        fade={false}
        target={`fileLink-result-${fileType}`}
        delay={0}>
        {formatConfig.label}
      </UncontrolledTooltip>
    </DropdownItem>
  );
};

class StageComparisonResultPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      collapse: {},
      jobsData: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      filters: {},
      order: { field: 'date_created', direction: 'desc' }
    };
  }

  async fetchJobList() {
    this.setState({ isLoaded: false });
    let { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    const { projectId, stageName, activeJobType, t } = this.props;

    const modifiedFilter = { ...filters, type: activeJobType };

    try {
      await this.props
        .fetchProjectStageJobs(
          projectId,
          stageName,
          activeJobType,
          modifiedFilter,
          order,
          {
            from,
            to
          }
        )
        .then(res => {
          this.setState({
            isLoaded: true,
            jobsData: res.jobs || [],
            totalSize: res.total || 0
          });
        });
    } catch (err) {
      Message.error(t(err.message));
    }
  }

  componentDidMount() {
    this.fetchJobList();
    const { activeJobType, currentJobType, stageName, projectId } = this.props;
    // subscribe to socket events when job result is available
    // this will reset the filter and display the latest jobs
    if (this.props.userId) {
      const eventsToSubscribeTo = [
        {
          eventName: socketProjectEvents.COMPARISON_COMPLETED,
          handler: async data => {
            this.clearFilter();
            this.fetchJobList(true);
          }
        }
      ];
      try {
        this.props.subscribeToEvents(eventsToSubscribeTo);
      } catch (err) {
        console.log(err);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order, isLoaded } = this.state;

    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order) ||
      this.props.activeJobType !== prevProps.activeJobType
    ) {
      this.fetchJobList();
      // subscribe to socket events when table has filter and job result is available
      // this will reset the filter and display the latest jobs
      if (this.props.userId) {
        const eventsToSubscribeTo = [
          {
            eventName: socketProjectEvents.COMPARISON_COMPLETED,
            handler: async data => {
              this.clearFilter();
              this.fetchJobList(true);
            }
          }
        ];
        try {
          this.props.subscribeToEvents(eventsToSubscribeTo);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (
                cur === 'date_created' ||
                cur === 'status' ||
                cur === 'date_completed' ||
                cur === 'stage_compared'
              ) {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'date_created' || cur === 'date_completed') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              if (cur === 'status' || cur === 'stage_compared') {
                return { ...acc, [cur]: newState.filters[cur].filterVal };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };
  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };
  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };
  defaultSorted = () => {
    const { f, d } = getURLParameters(window.location.href);
    return [
      {
        dataField: f || 'date_created',
        order: d || 'desc'
      }
    ];
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    // this.tableRef.current.filterContext.currFilters = {};
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'date_created', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  render() {
    const {
      data,
      t,
      projectId,
      stageName,
      model_comparison_list,
      filteredTargetStages,
      projectStageResultList
    } = this.props;

    const {
      collapse,
      filters,
      page,
      sizePerPage,
      totalSize,
      customTotal,
      isLoaded,
      jobsData
    } = this.state;

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => [
      {
        id: '',
        Header: t('serial_number'),
        width: 25,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          let rowNumber = (page - 1) * sizePerPage + (row.index + 1);
          return <div>{rowNumber}</div>;
        }
      },
      {
        id: 'rules_check',
        accessor: 'rules_check',
        Header: t('project:rules'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const rulesChecked = Array.isArray(
            row.original.comparison_rel.rules_check
          )
            ? row.original.comparison_rel.rules_check
            : [];

          return (
            <>
              <div id={`modelComparison-${row.index}`} type="link">
                {`${t('total_rules_checked')}: `}
                <span className="font-weight-bold">{rulesChecked.length}</span>
              </div>

              <UncontrolledPopover
                boundariesElement="window"
                trigger="hover"
                className="rule-popover-container"
                target={`modelComparison-${row.index}`}>
                <PopoverHeader>{`${t('rules')} (${
                  rulesChecked.length
                })`}</PopoverHeader>
                <PopoverBody className={styles.scrollableStyles}>
                  {rulesChecked.map((entry, key) => {
                    const selectType = model_comparison_list.find(value => {
                      return value.key === entry;
                    });
                    const name =
                      selectType && selectType !== undefined
                        ? selectType.display_name
                        : entry;

                    return (
                      <div key={key} className="mb-2">
                        {name}
                      </div>
                    );
                  })}
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        }
      },
      {
        id: 'date_created',
        accessor: 'date_created',
        Header: t('date_submitted'),
        Cell: ({ row, value }) => {
          return (
            <Container className="d-flex">
              <div id={`model_compare_result-${row.index}-info`}>
                <Row>
                  <div>
                    {dateFormatting(
                      row.original.comparison_rel.date,
                      'defaultHour'
                    )}
                  </div>
                </Row>
              </div>
            </Container>
          );
        },

        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'stage_compared',
        accessor: 'stage_compared',
        Header: t('stage_compared'),
        Cell: ({ row, value }) => {
          return (
            <div className={styles.requested_by_font_size}>
              <span className="pr-3">
                {row.original.stage_compared.display_name}
              </span>
            </div>
          );
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={(filteredTargetStages || []).reduce((acc, current) => {
                return {
                  ...acc,
                  [current.name]: current.display_name
                };
              }, {})}
              filters={this.state.filters}
              filterToState={this.filterToState}
              className="form-control-sm"
              defaultValue={filters.stage_compared || ''}
            />
          );
        }
      },
      {
        id: 'status',
        accessor: 'status',
        Header: t('status'),
        Cell: ({ row, value }) => {
          const info = {
            ...row.original,
            job_id: row.index,
            rules_check: [],
            status: row.original.comparison_rel.status
          };

          return <JobStatusPopUpInfo jobInfo={info} t={t} />;
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={selectOptionsStatus(this.props.t)}
              filters={this.state.filters}
              filterToState={this.filterToState}
              className="form-control-sm"
              defaultValue={filters.stage_compared || ''}
            />
          );
        }
      },
      {
        id: 'comparison_rel.files_available',
        accessor: 'comparison_rel.files_available',
        Header: t('result_files'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const isAvailable =
            row.original.comparison_rel.status === 'AVAILABLE' ||
            row.original.comparison_rel.status === 'OBSOLETE';

          return (
            <>
              {isAvailable &&
                !!value &&
                Array.isArray(value) &&
                value.length > 0 && (
                  <UncontrolledDropdown>
                    <DropdownToggle outline color="primary" caret>
                      <i className="fas fa-download mr-1"></i>
                      {t('dl_files')}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Array.isArray(value) &&
                        value.map((file, index) => {
                          return (
                            <div key={`result-${index}`}>
                              {fileTypeFormatting(
                                this.props,
                                row.original,
                                file,
                                index,
                                t
                              )}
                            </div>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
            </>
          );
        }
      },

      {
        id: 'link',
        accessor: 'link',
        Header: t('actions'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const stage_compared = row.original.stage_compared.name;
          const dateOfComparison = row.original.comparison_rel.date;

          return (
            <>
              {row.original.comparison_rel.status === 'AVAILABLE' &&
                row.original.webviewerComparisonToken && (
                  <div className="d-flex justify-content-center">
                    <a
                      href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.original.webviewerComparisonToken}`}
                      // to={{
                      //   pathname: `/project/${projectId}/${stageName}/viewer`,
                      //   search: `viewerMode=3d&token=${
                      //     row.webviewerComparisonToken
                      //   }`
                      // }}
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fas fa-building"></i>
                    </a>
                  </div>
                )}
            </>
          );
        }
      }
    ];

    return (
      <div>
        <div className="text-right pb-2">
          <div className="d-flex flex-row-reverse">
            <Button
              hidden={!(Object.keys(filters).length > 0)}
              outline
              size="sm"
              color="secondary"
              onClick={() => this.clearFilter()}>
              <i className="fas fa-times mr-2"></i>
              {t('clear_filters')}
            </Button>
          </div>
        </div>

        <ReactTable
          columns={reactTableColumns(this.props.t)}
          data={projectStageResultList || jobsData}
          striped
          hover
          bordered
          pagination={paginationOptions}
          sortByFunc={setOrderBy}
          defaultSorted={this.defaultSorted()}
          noDataIndication={() => NoData(t)}
          loading={!isLoaded}
          remote={{ pagination: true, filter: true, sort: true }}
          overlay={loading =>
            ({ children }) => {
              return (
                <div className="position-relative">
                  {children}
                  {loading && (
                    <div
                      className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                      <div
                        className={`spinner-border text-primary ${styles.table_overlay_dimension}`}
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    data,
    t,
    project_stage_results,
    projectId,
    stageName,
    currentJobType,
    activeJobType
  } = ownProps;

  const project = state.project_stage_results[projectId];

  const stage = project && project.stages[stageName];

  const comparisons = project && stage && stage.comparisons;
  return {
    projectStageResultList: comparisons || []
  };
};

export default compose(
  withTranslation(['results']),
  connect(mapStateToProps)
)(StageComparisonResultPresenter);
