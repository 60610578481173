import { performRequest } from '../requests';
import { referenceDocumentsActionType } from '../../constants/actionType';

const {
  ADD_REFERENCE_DOCUMENTS,
  REMOVE_REFERENCE_DOCUMENT,
  UPLOAD_REFERENCE_DOCUMENTS,
  RESET_REFERENCE_DOCUMENT,
  FETCH_REFERENCE_DOCUMENTS,
  FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER,
  REFERENCE_DOCUMENTS_CREATE_FOLDER,
  REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER,
  REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER,
  REFERENCE_DOCUMENTS_DOWNLOAD_FILE_OR_FOLDER,
  SOFT_REMOVE_REFERENCE_DOCUMENT,
  ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT,
  REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER,
  REPLACE_REFERENCE_DOCUMENT,
  REFERENCE_DOCUMENTS_CUT_FILE_OR_FOLDER,
  SET_REFERENCE_DOCUMENT_ROOT
} = referenceDocumentsActionType;

export const addReferenceDocumentsFile = file => (dispatch, getState) => {
  if (getState().reference_documents.find(m => m.name === file.name)) {
    dispatch({
      type: REPLACE_REFERENCE_DOCUMENT,
      document: { ...file, progress: 0, dirty: true, removed: false }
    });
  } else {
    dispatch({
      type: ADD_REFERENCE_DOCUMENTS,
      document: { ...file, dirty: true }
    });
  }
  return;
};

export const replaceReferenceDocumentsFile = file => (dispatch, getState) => {
  dispatch({
    type: REPLACE_REFERENCE_DOCUMENT,
    document: { ...file, progress: 0, dirty: true, removed: false }
  });
};

export const setNewReferenceDocumentFiles = (
  newFiles,
  existingFiles
) => dispatch => {
  for (let i in existingFiles) {
    const fileToReplace = existingFiles[i];
    const { file, id } = fileToReplace;
    // replace in redux store

    dispatch(
      replaceReferenceDocumentsFile({
        name: file.name,
        size: file.size,
        fileInput: file,
        id: id
      })
    );
  }

  for (let index in newFiles) {
    const file = newFiles[index];
    // push model to the redux store
    dispatch(
      addReferenceDocumentsFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }
};

export const addReferenceDocuments = document => dispatch => {
  return dispatch({
    type: ADD_REFERENCE_DOCUMENTS,
    document: { ...document, dirty: true }
  });
};

export const resetReferenceDocument = () => dispatch => {
  return dispatch({
    type: RESET_REFERENCE_DOCUMENT
  });
};

export const uploadReferenceDocuments = (
  projectId,
  documentsAdded,
  documentsRemoved,
  activeDirectoryToUpload,
  origin_details,
  onUploadProgress
) => (dispatch, getState) => {
  let formData = new FormData();
  formData.append('activeDirectoryToUpload', activeDirectoryToUpload);
  formData.append('stage_name', origin_details.stage_name);
  formData.append('submission_id', origin_details.submission_id);
  formData.append('documentsAdded', documentsAdded.fileInput);

  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  formData.append(
    'concurrencyControlVersionId',
    findRootTreeDocument
      ? findRootTreeDocument.concurrency_control_version_id
      : ''
  );
  formData.append(
    'entityTargetId',
    findRootTreeDocument ? findRootTreeDocument.id : ''
  );

  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type':
        'multipart/form-data; boundary=---------------------------199619018751551354576510119'
    },
    onUploadProgress: onUploadProgress,
    params: {
      totalSizeAdded: documentsAdded.size,
      activeDirectoryToUpload: activeDirectoryToUpload
    }
  };

  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/upload`,
      formData,
      requestConfig,
      'UPLOAD_REFERENCE_DOCUMENTS_REQUEST'
    )
  )
    .then(res => {
      if (!res.data.success) {
        throw res.data.error || 'default_message';
      } else {
        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: findRootTreeDocument.id,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
        return res.data.files[0];
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: 'project_document_management_limit_reached'
          } || 'default_message'
        );
      }
    });
};

export const dispatchAllUploads = files => dispatch =>
  dispatch({
    type: UPLOAD_REFERENCE_DOCUMENTS,
    documents: files.map(file => file)
  });

export const fetchReferenceDocuments = (
  projectId,
  filters,
  order,
  pagination
) => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/list`,
      { filters, order, pagination },
      requestConfig,
      'FETCH_DOCUMENTS'
    )
  )
    .then(res => {
      const totalResult = res.data.reference_documents.countTotal;
      const documentList = res.data.reference_documents.data;

      dispatch({
        type: FETCH_REFERENCE_DOCUMENTS,
        documentList
      });

      return totalResult;
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 403') {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      }
      throw new Error(err.message || 'default_message');
    });
};

export const fetchReferenceDocumentsFileExplorer = projectId => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/list/file_explorer`,
      {},
      requestConfig,
      'FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER'
    )
  )
    .then(res => {
      const documentList = res.data.reference_documents;

      dispatch({
        type: FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER,
        documentList
      });
      return documentList;
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 403') {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      }
      throw new Error(err.message || 'default_message');
    });
};

export const addFolderAction = (
  projectId,
  name,
  activeItemToDisplay,
  replaceValidation = true
) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/folder/create`,
      {
        name,
        activeItemToDisplay,
        replaceValidation,
        concurrencyControlVersionId,
        entityTargetId
      },
      requestConfig,
      'REFERENCE_DOCUMENTS_CREATE_FOLDER'
    )
  )
    .then(res => {
      const document = res.data.reference_documents;
      if (res.data.success) {
        dispatch({
          type: ADD_REFERENCE_DOCUMENTS,
          document: document[0]
        });

        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
      } else {
        throw (
          {
            message: res.data.error,
            documents: res.data.documents || []
          } || 'default_message'
        );
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: err.message,
            documents: err.documents || []
          } || 'default_message'
        );
      }
    });
};

export const removeReferenceDocument = (projectId, fileId, path, isFolder) => (
  dispatch,
  getState
) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'delete',
      `/api/project/${projectId}/reference_documents/${fileId}/delete`,
      { path, isFolder, concurrencyControlVersionId, entityTargetId },
      requestConfig,
      'REMOVE_REFERENCE_DOCUMENT'
    )
  )
    .then(res => {
      dispatch({
        type: REMOVE_REFERENCE_DOCUMENT,
        id: fileId,
        isFolder,
        path,
        data: res.data.data
      });
      if (!res.data.success) {
        throw new Error(res.data.error || 'default_message');
      } else {
        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
      }
    })

    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: 'project_document_management_limit_reached'
          } || 'default_message'
        );
      }
    });
};

export const moveReferenceDocument = (
  projectId,
  itemToMove,
  destination,
  isFolder,
  id
) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      isFolder
        ? `/api/project/${projectId}/reference_documents/move_folder`
        : `/api/project/${projectId}/reference_documents/move_file`,
      {
        itemToMove,
        destination,
        isFolder,
        id,
        concurrencyControlVersionId,
        entityTargetId
      },
      requestConfig,
      'REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER'
    )
  )
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.error || 'default_message');
      } else {
        dispatch({
          type: REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER,
          isFolder,
          documentList: res.data.data,
          itemToMoveId: id
        });

        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: 'project_document_management_limit_reached'
          } || 'default_message'
        );
      }
    });
};

export const renameReferenceDocument = (
  projectId,
  oldName,
  newName,
  isFolder,
  id
) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      isFolder
        ? `/api/project/${projectId}/reference_documents/rename_folder`
        : `/api/project/${projectId}/reference_documents/rename_file`,
      {
        oldName,
        newName,
        isFolder,
        id,
        entityTargetId,
        concurrencyControlVersionId
      },
      requestConfig,
      'REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER'
    )
  )
    .then(res => {
      if (!res.data.success) {
        throw new Error(res.data.error || 'default_message');
      } else {
        dispatch({
          type: REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER,
          isFolder,
          documentList: res.data.data
        });

        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
        return res.data.data;
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: 'project_document_management_limit_reached'
          } || 'default_message'
        );
      }
    });
};

export const downloadReferenceDocument = (
  projectId,
  id,
  activeItemDownloadLink
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `/api/project/${projectId}/reference_document/download/${id}`,
      { id, activeItemDownloadLink },
      requestConfig,
      'REFERENCE_DOCUMENTS_DOWNLOAD_FILE_OR_FOLDER'
    )
  ).then(res => {
    //if (!res.data.success) throw new Error(res.data.error || 'default_message');
  });
};

export const softRemoveReferenceDocument = document => dispatch => {
  return dispatch({
    type: SOFT_REMOVE_REFERENCE_DOCUMENT,
    document
  });
};

export const addDescriptionOnReferenceDocuments = (
  projectId,
  documentId,
  value
) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/${documentId}/add_description`,
      { description: value, concurrencyControlVersionId, entityTargetId },
      requestConfig,
      'ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT'
    )
  )
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT,

          documentList: res.data.data
        });

        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });

        return res.data.data;
      } else {
        throw (
          {
            message: res.data.error
          } || 'default_message'
        );
      }
    })

    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: 'project_document_management_limit_reached'
          } || 'default_message'
        );
      }
    });
};

export const referenceDocumentPasteAction = (
  projectId,
  activeItemToPaste,
  activeFolderPathToPasteIn,
  shouldValidateConflicts = true
) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',

      activeItemToPaste.isFolder
        ? `/api/project/${projectId}/reference_documents/${activeItemToPaste.id}/copy_folder`
        : `/api/project/${projectId}/reference_documents/${activeItemToPaste.id}/copy_file`,

      {
        activeItemToPaste,
        activeFolderPathToPasteIn,
        shouldValidateConflicts,
        entityTargetId,
        concurrencyControlVersionId
      },
      requestConfig,
      'REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER'
    )
  )
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER,

          document: res.data.data
        });
        dispatch({
          type: SET_REFERENCE_DOCUMENT_ROOT,
          entityTargetId: entityTargetId,
          newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
        });
      } else {
        throw (
          {
            message: res.data.error,
            documents: res.data.documents || []
          } || 'default_message'
        );
      }

      return res.data.data;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: err.message,
            documents: err.documents || []
          } || 'default_message'
        );
      }
    });
};
export const multipleItemActions = ({
  projectId,
  items,
  operation,
  destination,
  shouldValidateConflicts = true
}) => (dispatch, getState) => {
  const findRootTreeDocument = getState().reference_documents.find(
    d => d.is_document_root_tree
  );

  const concurrencyControlVersionId = findRootTreeDocument
    ? findRootTreeDocument.concurrency_control_version_id
    : '';

  const entityTargetId = findRootTreeDocument ? findRootTreeDocument.id : '';

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/reference_documents/multiple_file_actions`,
      {
        items,
        operation,
        destination,
        shouldValidateConflicts,
        entityTargetId,
        concurrencyControlVersionId
      },
      requestConfig,
      'REFERENCE_DOCUMENTS_MULTIPLE_ACTION'
    )
  )
    .then(res => {
      if (!res.data.success)
        throw (
          {
            message: res.data.error,
            documents: res.data.documents || []
          } || 'default_message'
        );

      select_to_dispatch[operation](
        dispatch,
        res.data.data,
        res.data.delete_docs
      );

      dispatch({
        type: SET_REFERENCE_DOCUMENT_ROOT,
        entityTargetId: entityTargetId,
        newConcurrencyControlVersionId: res.data.concurrencyControlVersionId
      });

      return res.data.data;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw (
          {
            message: err.message,
            documents: err.documents || []
          } || 'default_message'
        );
      }
    });
};

const select_to_dispatch = {
  delete: (dispatch, data) => {
    dispatch({
      type: REMOVE_REFERENCE_DOCUMENT,
      data: data
    });
  },
  copy: (dispatch, data, delete_docs) => {
    dispatch({
      type: REMOVE_REFERENCE_DOCUMENT,
      data: delete_docs
    });

    dispatch({
      type: REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER,
      document: data
    });
  },
  cut: (dispatch, data, delete_docs) => {
    dispatch({
      type: REMOVE_REFERENCE_DOCUMENT,
      data: delete_docs
    });

    dispatch({
      type: REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER,
      document: data
    });
  }
};

export const getTotalDocumentSize = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `/api/project/${projectId}/reference_documents/getDocumentSize`,
      {},
      requestConfig,
      'FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER'
    )
  ).then(res => {
    if (!res.data.success) throw new Error(res.data.error || 'default_message');
    return res.data.data;
  });
};
