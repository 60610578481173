import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Row,
  Col
} from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import CustomInput from '../../UI/Input/customInput';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Message from '../../UI/Message';

const SessionControlModal = props => {
  const { t, formikProps } = props;

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className={props.className}
        backdrop="static"
        size="md">
        <ModalHeader
          toggle={() => {
            props.toggle();
          }}>
          {t('active_session_detected')}
        </ModalHeader>
        <ModalBody>
          <p>{t('currently_logged_in')}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={formikProps.isSubmitting}
            onClick={async () => {
              formikProps.setFieldValue('overRideActiveSession', true);
              await formikProps.handleSubmit();
              props.toggle();
            }}>
            {t('yes')}
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              props.toggle();
            }}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withTranslation(['login'])(SessionControlModal);
