import { RULES_KEY_SEPARTOR } from '../constants/misc';
// Return a formatted set of rules, the root of the object returned by this function is an array of packages .
// These packages contain the rules itself as children
// The key of the rules is prefixed by the key of the package
// The key of the rules itself is store in initialKey
export const buildRulesTree = (
  { rules, packages },
  parentKey = null,
  parentIndex = 0
) => {
  return Object.keys(packages).map((packageId, packageIndex) => {
    const ruleTreeId = packages[packageId].rule_tree_id;
    const title = packages[packageId].title;
    const packageKey = `${parentKey ? parentKey : 'packageKey'}-${packageId}`;
    const childRules = (packages[packageId].rules || [])
      .filter(ruleId => !!rules[ruleId])
      .map((ruleId, i) => {
        rules[ruleId].index = `${parentIndex}-${packageIndex}-${i}`;
        return {
          key: `${packageKey}${RULES_KEY_SEPARTOR}${ruleId}`,
          ...rules[ruleId],
          initialKey: ruleId
        };
      });
    if (packages[packageId].childPackage) {
      return {
        title,
        key: packageKey,
        readonly: !!packages[packageId].readonly,
        isDefault: !!packages[packageId].isDefault,
        // rules: ,
        children: [
          ...buildRulesTree(
            { rules, packages: packages[packageId].childPackage },
            packageKey,
            `${parentIndex}-${packageIndex}`
          ),
          ...childRules
        ],
        ruleTreeId
      };
    } else {
      return {
        title,
        key: packageKey,
        children: childRules,
        readonly: !!packages[packageId].readonly,
        isDefault: !!packages[packageId].isDefault
      };
    }
  });
};

export const formatRules = ruleTree => {
  const ret = ruleTree.reduce(
    (previous, current) => {
      if (current.children) {
        const next = formatRules(current.children);
        if (next.packages.length > 0) {
          const packages = previous.packages;
          let rules = previous.rules;
          if (!current.readonly) {
            packages.push({
              title: current.title,
              childPackage: next.packages,
              rules: Object.keys(next.directChildRules),
              isDefault: !!current.isDefault
            });
            rules = { ...rules, ...next.rules };
          }

          return {
            packages,
            rules,
            directChildRules: previous.directChildRules
          };
        } else {
          if (Object.keys(next.directChildRules).length > 0) {
            const packages = previous.packages;
            let rules = previous.rules;
            if (!current.readonly) {
              packages.push({
                title: current.title,
                rules: Object.keys(next.directChildRules),
                isDefault: !!current.isDefault
              });
              rules = { ...rules, ...next.rules };
            }
            return {
              packages,
              rules,
              directChildRules: previous.directChildRules
            };
          } else {
            return previous;
          }
        }
      } else {
        return {
          packages: previous.packages,
          rules: {
            ...previous.rules,
            [current.initialKey]: {
              title: current.title,
              descriptionLink: current.descriptionLink,
              params: current.params || {}
            }
          },
          directChildRules: {
            ...previous.directChildRules,
            [current.initialKey]: {
              title: current.title,
              descriptionLink: current.descriptionLink
            }
          }
        };
      }
    },
    { packages: [], rules: {}, directChildRules: {} }
  );
  return ret;
};
