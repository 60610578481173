import React from 'react';
import { connect } from 'react-redux';

import RuleManagement from '../../../Shared/RuleManagement/rulesGroup';
import {
  fetchRulesAdmin,
  setHiddenRules,
  saveRules,
  cloneMultipleRules,
  rulesBatchUpdate,
  deleteRules
} from '../../../../store/actions';

const AdminRuleManagement = props => {
  return <RuleManagement level="domain" {...props} />;
};
const mapStateToProps = (state, { match }) => {
  return {
    // rules_admin: state.rules_admin
    rules: state.rules_admin.rulesTree,
    defaultTree: state.rules.defaultRulesTree,
    treeConcurrencyControlVersionId:
      state.rules_admin.concurrencyControlVersionId

    // readOnlyRules: []
    // rules:
  };
};

const mapDispatchToProps = dispatch => {
  const destination = { type: 'domain' };
  return {
    fetchRules: params => dispatch(fetchRulesAdmin({ ...params })),
    saveRules: (rulesTree, type) =>
      dispatch(saveRules(rulesTree, type, destination)),
    setHiddenRules: hiddenRuleList => dispatch(setHiddenRules(hiddenRuleList)),
    cloneMultipleRules: (ruleList, ruleType) =>
      dispatch(cloneMultipleRules(ruleList, ruleType, 'domain')),
    updateRuleSet: rules => dispatch({ type: 'UPDATE_ADMIN_RULE_SET', rules }),
    rulesBatchUpdate: (rulesTree, type) =>
      dispatch(rulesBatchUpdate(rulesTree, type, destination)),
    deleteRules: (rules, ruleLevel) => dispatch(deleteRules(rules, ruleLevel))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRuleManagement);
