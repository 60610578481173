import React, { Component } from 'react';
import { Col, ListGroup, Row, ListGroupItem, Button } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { adminUpdateOneSubmissionWithPO } from '../../../../store/actions/submission';
import { fetchUserListAsAdmin } from '../../../../store/actions/users';

import AssignPOModal from './AssignPOModal';

const fetchPOUserOptions = {
  filters: { function: 'PO' },

  order: { direction: 'desc', field: 'name' },
  pagination: { from: 0, to: 50 }
};

const filterPOUsers = userData => {
  const users = Object.keys(userData).map(key => userData[key]);
  const filteredPOUsers = users.filter(user => {
    return user.function === 'PO';
  });
  return filteredPOUsers;
};

class AssignedPOPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignPOModal: false,
      isLoaded: false
    };
  }

  componentDidMount() {
    if (this.props.editable) {
      this.props.fetchPOUser().then(() => {
        this.setState({ isLoaded: true });
      });
    } else {
      this.setState({ isLoaded: true });
    }
  }

  toggleAssignPOModal = () => {
    this.setState({ assignPOModal: !this.state.assignPOModal });
  };

  render() {
    const { t, submission, editable } = this.props;
    const { isLoaded } = this.state;
    return (
      <div className="shadow p-3 rounded">
        <div className="d-flex justify-content-between mb-2">
          <h5 className="text-primary">{t('processing_officer_assigned')}</h5>
          {isLoaded && editable && (
            <div>
              <Button
                onClick={this.toggleAssignPOModal}
                color="primary"
                outline>
                <i className="fas fa-user-tag" /> {t('assign_po')}
              </Button>
              <AssignPOModal
                {...this.props}
                availablePOs={this.props.availablePOs}
                toggle={this.toggleAssignPOModal}
                modal={this.state.assignPOModal}
                submissions={[submission]}
                submissionIds={[submission.submission_id]}
                officerAssigned={submission.po_assigned_set}
                typeOfUpdate="SINGLE"
              />
            </div>
          )}
        </div>

        {submission.po_assigned_set.length > 0 ? (
          <ListGroup flush>
            <ListGroupItem className="font-weight-bold" disabled>
              <Row>
                <Col xs={1} />
                <Col>{t('name')}</Col>
                <Col>{t('email')}</Col>
              </Row>
            </ListGroupItem>
            {submission.po_assigned_set.map((po, key) => {
              return (
                <ListGroupItem key={key}>
                  <Row>
                    <Col>{po.full_name}</Col>
                    <Col>{po.email}</Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        ) : (
          <>
            <hr />
            <h6 className="text-secondary"> {t('no_officer_assigned')}</h6>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPOUser: () =>
      dispatch(
        fetchUserListAsAdmin(
          fetchPOUserOptions.filters,
          fetchPOUserOptions.order,
          fetchPOUserOptions.pagination
        )
      ),
    updateOneSubmission: (projectId, stageName, submissionId, POAssign) =>
      dispatch(
        adminUpdateOneSubmissionWithPO(
          projectId,
          stageName,
          submissionId,
          POAssign
        )
      )
  };
};

const mapStateToProps = state => {
  return {
    availablePOs: filterPOUsers(state.users),
    authUser: state.authUser
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AssignedPOPresenter);
