import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from 'draft-js-mention-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from 'draft-js-buttons';

//IMPORT CSS FOR THE PLUGINS
import editorStyles from './editorStyles.module.css';
import mentionsStyles from './mentionsStyles.module.css';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-undo-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';

import { format, compareAsc, parse, distanceInWords } from 'date-fns';
import { dateFormatting } from '../../../../../locales/dateFormat';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

const undoPlugin = createUndoPlugin({
  theme: {
    undo: editorStyles.button,
    redo: editorStyles.button
  }
});
const { UndoButton, RedoButton } = undoPlugin;
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const linkPlugin = createLinkPlugin({
  theme: editorStyles,
  placeholder: 'http://…'
});

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div>
        {buttons.map((Button, i) => (
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

class HeadlinesButton extends Component {
  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div className={editorStyles.headlineButtonWrapper}>
        <button onClick={this.onClick} className={editorStyles.headlineButton}>
          H
        </button>
      </div>
    );
  }
}
const defaultMessage = (timespan, duration = ``) => {
  return `
  <p>Please be advised that we will be performing important server maintenance ${timespan}, during which time the Fornax server will be unavailable for approximately ${duration}, if you are in the middle of something important at that time, please save your work or hold off any critical action until we are finished. Be careful, you may lose any unsaved data.</p>
  <p> Regards,</p>System Administrator
  `;
};

const formatDate = date => {
  return format(date, 'DD-MM-YYYY hh:mm A');
};

class CorrespondenceEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      suggestionType: null,
      editorChangingCount: 0,
      change: false
    };
  }

  componentDidMount() {
    const { editorState } = this.props;

    if (!editorState) {
      this.clearEditorState();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { maintenance_date_start_date, maintenance_date_end_date } =
      this.props.values;
    let fromString = ``;

    const formatted1 = new Date(maintenance_date_start_date);
    const formatted2 = new Date(maintenance_date_end_date);

    const duration = distanceInWords(formatted1, formatted2);
    if (maintenance_date_start_date && maintenance_date_end_date) {
      fromString = `from <b>${dateFormatting(
        Date.parse(formatted1),
        'defaultHour'
      )}</b> to <b>${dateFormatting(
        Date.parse(formatted2),
        'defaultHour'
      )}</b>`;
    } else {
      fromString = `from ${maintenance_date_start_date}`;
    }
    if (this.props.defaultMessage && this.state.change === false) {
      this.setState(
        {
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(defaultMessage(fromString, duration))
            )
          ),
          change: true
        },
        () => {
          this.props.setFieldValue(
            'body',
            convertToRaw(this.state.editorState.getCurrentContent())
          );
          this.props.setFieldTouched('body', true, true);
        }
      );
    }

    if (!this.props.defaultMessage && this.state.change) {
      this.setState(
        {
          editorState: EditorState.createEmpty(),
          change: false
        },
        () => {
          this.props.setFieldValue('body', '');
          this.props.setFieldTouched('body', true, true);
        }
      );
    }
  }
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  clearEditorState = () => {
    this.setState({
      editorState: EditorState.push(
        this.state.editorState,
        ContentState.createFromText('')
      )
    });
  };

  onSearchChange = ({ value }, stateKey) => {
    const currentSuggestions = this.state[stateKey];
    if (currentSuggestions) {
      this.setState({
        [stateKey]: defaultSuggestionsFilter(value, currentSuggestions)
      });
    }
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const {
      isInvalid,
      isValid,
      t,
      onChange,
      onFocus,
      onBlur,
      invalidFeedback,

      disabled = false
    } = this.props;

    const plugins = [linkPlugin, staticToolbarPlugin, undoPlugin, emojiPlugin];

    const onChangeEditor = editorState => {
      const currentContentState = this.state.editorState.getCurrentContent();
      const newContentState = editorState.getCurrentContent();

      this.setState({
        editorState
      });
      onChange(editorState);
    };

    return (
      <div className="d-flex flex-column w-100">
        <div
          className={classnames(
            `order-1 border rounded form-control h-100 w-auto ${editorStyles.editor}`,
            {
              'is-invalid border-danger': isInvalid,
              'is-valid border-success': isValid
            }
          )}>
          <Editor
            editorState={this.state.editorState}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            onEditorStateChange={this.onEditorStateChange}
            onChange={onChangeEditor}
            onFocus={onFocus}
            onBlur={onBlur}
            readOnly={disabled}
          />
        </div>

        {isInvalid && invalidFeedback && (
          <div className="invalid-feedback order-3">{invalidFeedback}</div>
        )}
        <div className="order-2">
          {!disabled && (
            <Toolbar>
              {externalProps => {
                return (
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <Separator {...externalProps} />
                      <HeadlinesButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                      <CodeBlockButton {...externalProps} />
                      <linkPlugin.LinkButton {...externalProps} />
                      <UndoButton />
                      <RedoButton />
                    </div>
                  </div>
                );
              }}
            </Toolbar>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { projectId, project }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['submission'])
)(CorrespondenceEditor);
