import { performRequest } from '../requests';
import { adminConfigurationActionType } from '../../constants/actionType';
const {
  FETCH_CONFIG_SETTINGS,
  SET_CONFIG_SETTINGS,
  SET_MAINTENANCE_ANNOUNCEMENT
} = adminConfigurationActionType;

export const fetchAdminConfigSettings = () => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/admin/config/fetch',
      requestConfig,
      'FETCH_CONFIG_SETTINGS'
    )
  )
    .then(res => res.data)
    .then(configList => {
      dispatch({
        type: FETCH_CONFIG_SETTINGS,
        configList: configList.data
      });
      return configList.success;
    });
};

export const editAdminConfigSettings = jsonData => (dispatch, getState) => {
  const concurrencyControlVersionId = getState().admin_configuration.app
    .concurrency_control_version_id;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      '/api/admin/config/edit',
      {
        ...jsonData,
        entityTargetId: 'admin_config',
        concurrencyControlVersionId
      },
      requestConfig,
      'SET_CONFIG_SETTINGS'
    )
  )
    .then(res => {
      return res.data.data;
    })
    .then(configList => {
      dispatch({
        type: SET_CONFIG_SETTINGS,
        configList
      });
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};
