import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ListGroupItem } from 'reactstrap';

import { Link /* , withRouter */ } from 'react-router-dom';

import ReactTable from '../../../Shared/ReactTable/index.jsx';

class UserProjectListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10
    };
  }

  render() {
    const { projectList, userId } = this.props;
    const { page, sizePerPage } = this.state;

    const customTotal = (from, to, size) => (
      <span className="ml-2">
        Showing {from} to {to} of {size} Results
      </span>
    );

    const list = projectList.projectList.map(info => {
      const role = info.members.filter(i => i.user_id === userId)[0].role;

      return {
        id: info.project_id,
        name: info.name,
        role: role
      };
    });

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize: list.length,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const reactTableColumns = () => [
      {
        id: 'id',
        accessor: 'id',
        Cell: ({ row, value }) => {
          return parseInt(row.index + 1);
        },
        width: 15,
        disableFilters: true,
        disableSortBy: true
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        Cell: ({ row, value }) => {
          return (
            <Link to={`/admin/project/${row.original.id}/details`}>
              {row.original.name}
            </Link>
          );
        },
        disableFilters: true,
        disableSortBy: true
      },
      {
        id: 'role',
        accessor: 'role',
        Header: 'Role',
        disableFilters: true,
        disableSortBy: true
      }
    ];

    return (
      <ListGroupItem>
        <ReactTable
          id="user-project-list-table"
          columns={reactTableColumns()}
          data={list}
          hover
          bordered={false}
          pagination={paginationOptions}
        />
      </ListGroupItem>
    );
  }
}
export default withTranslation(['admin_user'])(UserProjectListing);
