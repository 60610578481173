import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { throttle } from 'lodash';

import { withSocketIO } from '../../../api/sockets/withSocketIO';
import * as socketEvents from '../../../api/sockets/constantEvents';

import {
  fetchAllNotifications,
  readNotification,
  dismissNotification,
  fetchLimitedNotifications,
  fetchNotificationsForProjects,
  resetNotification,
  fetchProjectStage,
  readAllNotification,
  clearAllNotification,
  fetchNotificationCount
} from '../../../store/actions';

import NotificationPresenter from './NotificationPresenter';
import NotificationBlock from './NotificationBlock';

const notificationExemptedEvents = ['RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE'];

const eventFlattenedList = Object.keys(socketEvents).reduce(
  (eventList, eventSection) => [
    ...eventList,
    ...Object.keys(socketEvents[eventSection]).map(
      eventKey => socketEvents[eventSection][eventKey]
    )
  ],
  []
);
class NotificationContainer extends Component {
  constructor(props) {
    super(props);
    this.socketListener = [];
    // this.dismissListener = [];
  }
  componentDidMount() {
    if (this.props.userId) {
      const eventsToSubscribeTo = eventFlattenedList
        .filter(e => !notificationExemptedEvents.includes(e))
        .map(eventName => {
          return {
            eventName,
            handler: throttle(data => {
              const notificationNumber = this.props.notifications.length;
              if (
                this.props.entity &&
                this.props.entity.type &&
                this.props.entity.id
              ) {
                this.props
                  .fetchNotificationsForProjects(
                    this.props.entity.id,
                    0,
                    notificationNumber,
                    false
                  )
                  .then(unreadcount => {
                    if (this.props.updateUnreadNotificationCount) {
                      this.props.updateUnreadNotificationCount(unreadcount);
                    }
                  });
              } else {
                this.props
                  .fetchLimitedNotifications(0, notificationNumber, true)
                  .then(unreadcount => {
                    if (this.props.updateUnreadNotificationCount) {
                      this.props.updateUnreadNotificationCount(unreadcount);
                    }
                  });
              }
            }, 1000)
          };
        });
        try{
          this.props.subscribeToEvents(eventsToSubscribeTo);
        }catch(err){
          console.log(err)
        }
      this.props.fetchNotificationCount();
    }
    if (this.props.entity && this.props.entity.type && this.props.entity.id) {
      this.props.fetchNotificationCount(this.props.entity.id);
      this.props
        .fetchNotificationsForProjects(this.props.entity.id, 0, 10, false)
        .then(unreadcount => {
          if (this.props.updateUnreadNotificationCount) {
            this.props.updateUnreadNotificationCount(unreadcount);
          }
        });
    } else {
      this.props.fetchLimitedNotifications(0, 10, true).then(unreadcount => {
        if (this.props.updateUnreadNotificationCount) {
          this.props.updateUnreadNotificationCount(unreadcount);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      if (this.props.userId) {
        const eventsToSubscribeTo = eventFlattenedList.map(eventName => {
          return {
            eventName,
            handler: throttle(data => {
              const notificationNumber = this.props.notifications.length;
              if (
                this.props.entity &&
                this.props.entity.type &&
                this.props.entity.id
              ) {
                this.props
                  .fetchNotificationsForProjects(
                    this.props.entity.id,
                    0,
                    notificationNumber,
                    false
                  )
                  .then(unreadcount => {
                    if (this.props.updateUnreadNotificationCount) {
                      this.props.updateUnreadNotificationCount(unreadcount);
                    }
                  });
              } else {
                this.props
                  .fetchLimitedNotifications(0, notificationNumber, true)
                  .then(unreadcount => {
                    if (this.props.updateUnreadNotificationCount) {
                      this.props.updateUnreadNotificationCount(unreadcount);
                    }
                  });
              }
            }, 1000)
          };
        });
        try{
          this.props.subscribeToEvents(eventsToSubscribeTo);
        }catch(err){
          console.log(err)
        }
      }
    }
  }

  listenToEventList = (socket, events, callback) => {
    return events.map(eventType => {
      socket.on(eventType, data => {
        const notificationNumber = this.props.notifications.length;
        if (
          this.props.entity &&
          this.props.entity.type &&
          this.props.entity.id
        ) {
          this.props
            .fetchNotificationsForProjects(
              this.props.entity.id,
              0,
              notificationNumber,
              false
            )
            .then(unreadcount => {
              if (this.props.updateUnreadNotificationCount) {
                this.props.updateUnreadNotificationCount(unreadcount);
              }
            });
        } else {
          this.props
            .fetchLimitedNotifications(0, notificationNumber, true)
            .then(unreadcount => {
              if (this.props.updateUnreadNotificationCount) {
                this.props.updateUnreadNotificationCount(unreadcount);
              }
            });
        }
      });
      return eventType;
    });
  };

  fetchMore = () => {
    const notificationNumber = this.props.notifications.length;
    if (this.props.entity && this.props.entity.type && this.props.entity.id) {
      this.props
        .fetchNotificationsForProjects(
          this.props.entity.id,
          notificationNumber,
          10,
          false
        )
        .then(unreadcount => {
          if (this.props.updateUnreadNotificationCount) {
            this.props.updateUnreadNotificationCount(unreadcount);
          }
        });
    } else {
      this.props
        .fetchLimitedNotifications(notificationNumber, 10, false)
        .then(unreadcount => {
          if (this.props.updateUnreadNotificationCount) {
            this.props.updateUnreadNotificationCount(unreadcount);
          }
        });
    }
  };

  render() {
    const displayMode = this.props.displayMode || 'popover';
    if (displayMode === 'popover') {
      return (
        <NotificationPresenter {...this.props} fetchMore={this.fetchMore} />
      );
    } else if (displayMode === 'block') {
      return <NotificationBlock {...this.props} fetchMore={this.fetchMore} />;
    }
  }
}

const mapStateToProps = (state, props) => {
  if (props.entity && props.entity.type && props.entity.id) {
    return {
      notifications: (state.projects[props.entity.id].notifications || [])
        .filter(n => !n.is_dismiss)
        .filter(
          n =>
            n.entities_related &&
            n.entities_related.some(
              e => e.type === props.entity.type && e.id === props.entity.id
            )
        ),
      notificationCount: state.app.notificationCount
    };
  } else {
    return {
      notifications: state.notifications.filter(n => !n.is_dismiss),
      notificationCount: state.app.notificationCount
    };
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllNotifications: () => dispatch(fetchAllNotifications()),
    fetchLimitedNotifications: (offset, limit, reset) =>
      dispatch(fetchLimitedNotifications(offset, limit, reset)),
    fetchNotificationsForProjects: (projectId, offset, limit, reset) =>
      dispatch(
        fetchNotificationsForProjects({ projectId, offset, limit, reset })
      ),
    readNotification: notificationId =>
      dispatch(readNotification(notificationId)),
    dismissNotification: notificationId =>
      dispatch(dismissNotification(notificationId)),
    resetNotification: () => dispatch(resetNotification()),
    clearAllNotification: () => dispatch(clearAllNotification()),
    readAllNotification: () => dispatch(readAllNotification()),
    fetchNotificationCount: projectId =>
      dispatch(fetchNotificationCount({ projectId }))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSocketIO
)(NotificationContainer);
