import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import {
  Alert,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
  Label,
  Badge,
  CustomInput
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { DndProvider, useDrag, useDrop, DragPreviewImage } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import classnames from 'classnames';
import uuid from 'uuid/v4';
import { intersectionWith, isEqual, intersection } from 'lodash';
import * as Yup from 'yup';

import Message from '../../UI/Message';
// import { rulesAdminActionType } from '../../store/constants/actionType';
import RuleParameterModal from './ModalRuleParameter';
import PackageParameterModal from './ModalPackageParameter';

import CompliantMessageTag from './CompliantMessageLocking/TagLabel';

import { object } from 'prop-types';

import RuleVisibilty from './RuleVisibilty';
import './rulesGroup.css';
const isCompliantMesageVisibleInRuleTree = false;

import rulePackageReportTemplate from '../../../store/utils/rulePackageReportTemplate';

const mapActivatedChecksToActualType = {
  REGULATORY: 'regulatory',
  BIM_DATA: 'bimdata',
  CLASH_DETECTION: 'clash',
  BIM_QUALITY: 'quality'
};

const ReportTemplate = ({
  values,
  t,
  setValues,
  rulePerReportTemplate,
  allPackageRuleId,
  touched,
  setTouched,
  asFormikField,
  handleSubmit,
  ruleType
}) => {
  const featureList = useSelector(state => state.app.featureList);
  const reportTemplateList = useSelector(
    state => state.app.report_template_list
  );

  const checkTypes = featureList.checkingType;

  const checksAvailable =
    rulePackageReportTemplate.checksAvailableFunc(checkTypes);

  const availableReportTemplate = reportTemplateList
    .filter(rt => {
      return rt.name === ruleType || rt.custom_template_for === ruleType;
    })
    .map(rt => rt.name)
    .filter(i => i !== 'comparison');

  return availableReportTemplate.map((keyValue, index) => {
    const findInList = values.report_template.find(v => v.name === keyValue);
    return (
      <div>
        <CustomInput
          type="checkbox"
          checked={findInList && findInList.isSelected}
          name="report_template"
          label={keyValue}
          id={`id_${keyValue}_${index}`}
          key={`key_${keyValue}_${index}`}
          onChange={async e => {
            const isSelected = e.target.checked;

            // await setFilteredVisibility(nextVisibility);
            const prevValues = values.report_template.filter(rp => {
              return rp.name !== keyValue;
            });
            if (!!asFormikField) {
              setValues({
                ...values,
                report_template: [
                  ...prevValues,
                  {
                    ...findInList,
                    isSelected: isSelected,
                    rules: allPackageRuleId
                  }
                ]
              });
              !!handleSubmit && handleSubmit();
            } else {
              setValues({
                ...values,
                report_template: [
                  ...prevValues,
                  {
                    ...findInList,
                    isSelected: isSelected,
                    rules: allPackageRuleId
                  }
                ]
              });
              setTouched({
                ...touched,
                report_template: true
              });
            }
          }}
        />
      </div>
    );
  });
};

export default compose(
  withTranslation(['main', 'rules', 'error', 'user_preferences'])
)(ReportTemplate);
