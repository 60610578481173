import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}${
    process.env.REACT_APP_QUERYUI_BUILD === 'portal'
      ? ''
      : process.env.PUBLIC_URL
  }`,
  withCredentials: true
  //other
});

export const deleteCategoryAPI = categoryId => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`api/knowledgeHub/${categoryId}`, {})
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

export const fetchFavoriteCategoryItemAPI = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`api/knowledgeHub/getFavoriteCategoryItems`)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log({ err });
        reject();
      });
  });
};

export const categoryItemAddToFavoriteAPI = (
  categoryId,
  categoryItemId,
  isFavorite
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`api/knowledgeHub/${categoryId}/${categoryItemId}`, {
        isFavorite
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log({ err });
        reject();
      });
  });
};

export const updateCategoryAPI = (categoryId, newCategoryData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`api/knowledgeHub/${categoryId}`, {
        newCategoryData
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log({ err });
        reject();
      });
  });
};

export const updateCategoryItemAPI = (
  categoryId,
  categoryItemId,
  newCategoryItemData
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`api/knowledgeHub/${categoryId}/${categoryItemId}`, {
        newCategoryItemData
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log({ err });
        reject();
      });
  });
};

export const deleteCategoryItemAPI = (categoryId, categoryItemId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`api/knowledgeHub/${categoryId}/${categoryItemId}`, {})
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

export const saveCategoryItemsAPI = (
  categoryId,
  categoryItemData,
  groupname
) => {
  return new Promise(resolve => {
    axiosInstance
      .post('api/knowledgeHub/saveCategoryItems', {
        categoryId,
        categoryItemData,
        groupname
      })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log(err);
        // reject(err.response.data);
      });
  });
};

export const saveCategoryAPI = categoryName => {
  return new Promise(resolve => {
    axiosInstance
      .post('api/knowledgeHub/saveCategory', { categoryName })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log(err);
        // reject(err.response.data);
      });
  });
};

export const search = title => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('api/knowledgeHub/searchByTitle', { title })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject();
      });
  });
};

//Save images to database
export const upload = (files, categoryName, categoryItemId, categoryId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('api/knowledgeHub/uploadImage', {
        files,
        categoryName,
        categoryItemId,
        categoryId
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log(err);
        // reject(err.response.data);
      });
  });
};

//Save images to server
export const uploadImageToServer = (
  categoryId,
  categoryItemId,
  documentsAdded
) => {
  let formData = new FormData();
  formData.append('documentsAdded', documentsAdded.fileInput);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(
        `/api/knowledgeHub/${categoryId}/${categoryItemId}/upload`,
        formData
      )
      .then(res => {
        resolve();
        if (!res.data.success) {
          throw res.data.error || 'default_message';
        } else {
          return res.data;
        }
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 403') {
          throw (
            {
              message: 'not_authorised'
            } || 'default_message'
          );
        } else {
          throw (
            {
              message: 'limit_reached'
            } || 'default_message'
          );
        }
      });
  });
};

export const batchUpdateContentAPI = selectedData => dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/api/knowledgeHub/batchUpdateContent`, { selectedData })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log(err);
        // reject(err.response.data);
      });
  });
};
