import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Container,
  Col,
  Row
} from 'reactstrap';
import classnames from 'classnames';

import AdminRuleManagement from '../Admin/Rules';
import UserRuleManagement from '../UserPreference/ruleManagement';

import ReportTemplateManagement from '../RulePackageManagement/ReportTemplate';

import {
  fetchReportTemplateList,
  requestCustomReportTemplateDeletion
} from '../../../store/actions';

const RuleManagementIndex = props => {
  const { isAdmin, isRuleManager, match, t, fetchReportTemplateList } = props;
  const location = useLocation();
  const featureList = useSelector(state => state.app.featureList);

  const isUserRulesEnabled = featureList.userRule;
  const isRuleParameterEnabled = featureList.ruleParameter;
  const isRulePackageV2Enabled = featureList.isRulePackageV2Enabled;

  const ruleManagementLevel =
    match && match.params && match.params.ruleManagementLevel;

  const enabledTabs = {
    ...((isAdmin || isRuleManager) && isRuleParameterEnabled
      ? { admin: { label: 'rule_management_admin_tab' } }
      : {}),
    ...(isUserRulesEnabled
      ? { user: { label: 'rule_management_my_rules' } }
      : {}),
    ...(isRulePackageV2Enabled
      ? {
          report_template_management: {
            label: 'report_template_management_tab'
          }
        }
      : {})
  };

  const [activeTab, setActiveTab] = useState(
    ruleManagementLevel || Object.keys(enabledTabs)[0]
  );
  useEffect(() => {}, [ruleManagementLevel]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchReportTemplateList().then(() => {
      setIsLoaded(true);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col sm="12">
          <div className="shadow p-3 mt-3 mb-5 rounded">
            <h3 className="text-primary">
              {t('rules:rules_group_management')}
            </h3>

            {!!isAdmin || !!isRuleManager ? (
              <div>
                <Nav tabs>
                  {Object.keys(enabledTabs).map(tabKey => (
                    <NavItem key={`rule-management-${tabKey}-tab`}>
                      <NavLink
                        className={classnames('', {
                          'font-weight-bold': tabKey === activeTab
                          // 'bg-light': tabKey !== activeTab
                        })}
                        active={tabKey === activeTab}
                        href="#"
                        onClick={() => {
                          setActiveTab(tabKey);
                        }}>
                        {t(enabledTabs[tabKey].label)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {Object.keys(enabledTabs).map(tabKey => {
                    return (
                      <TabPane
                        key={`tab-pane-${tabKey}`}
                        tabId={tabKey}
                        className="pt-3">
                        {activeTab === tabKey && tabKey === 'admin' && (
                          <AdminRuleManagement {...props} />
                        )}
                        {(activeTab === tabKey && tabKey) === 'user' && (
                          <UserRuleManagement {...props} />
                        )}
                        {(activeTab === tabKey && tabKey) ===
                          'report_template_management' && (
                          <ReportTemplateManagement {...props} />
                        )}
                      </TabPane>
                    );
                  })}
                </TabContent>
              </div>
            ) : (
              <UserRuleManagement {...props} />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.authUser.function === 'ADMIN',
    isRuleManager: state.authUser.function === 'RM',
    userId: state.authUser.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchReportTemplateList: () => dispatch(fetchReportTemplateList()),
    requestCustomReportTemplateDeletion: id =>
      dispatch(requestCustomReportTemplateDeletion(id))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['rules', 'rule_package_management'])
)(RuleManagementIndex);
