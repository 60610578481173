import { performRequest } from '../requests';
import {
  organizationActionType,
  authUserActionType
} from '../../constants/actionType';
import { nest } from 'recompose';
import pick from 'lodash/pick';

const {
  ADD_CURRENT_ORGANIZATION,
  ADD_ORGANIZATION,
  ADD_GROUP_MEMBERS,
  UPDATE_GROUP,
  ADD_ORGANIZATION_MEMBERS,
  ADD_TEAM,
  ADD_DEPARTMENT
} = organizationActionType;

export const createOrganization = data => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/new/organization',
      { data },
      requestConfig,
      'CREATE_ORGANIZATION',
      true
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: authUserActionType.SET_MY_ORGANIZATION,
          organizationId: res.organization.organization_id
        });
        return res.organization;
      } else {
        throw new Error(res.error || 'error.default_message');
      }
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const createSubgroup = (groupType, data) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/organization/new/${groupType}`,
      {
        data,
        ...(groupType === 'team' ? { departmentId: data.parentGroupId } : {})
      },
      requestConfig,
      `CREATE_${groupType.toUpperCase()}`
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        groupType === 'team'
          ? dispatch({
            type: ADD_TEAM,
            team: res.team
          })
          : dispatch({ type: ADD_DEPARTMENT, department: res.department });
        return res;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const fetchOrganizationDetails = organizationId => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/organization/view/${organizationId}/details`,
      {},
      requestConfig,
      'GET_ORGANIZATION_DETAILS'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        dispatch({
          type: ADD_CURRENT_ORGANIZATION,
          details: data.organization
        });
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};


export const fetchOrganizationStructure = organizationId => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/organization/view/${organizationId}/structure`,
      {},
      requestConfig,
      'GET_ORGANIZATION_STRUCTURE'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        dispatch({
          type: ADD_CURRENT_ORGANIZATION,
          groupTree: data.organizationTree,
          groups: {
            [data.details.organization_id]: {
              ...data.details,
              type: 'organization'
            },
            ...data.teams.reduce(
              (previous, current) => ({
                ...previous,
                [current.team_id]: { ...current, type: 'team' }
              }),
              {}
            ),
            ...data.departments.reduce(
              (previous, current) => ({
                ...previous,
                [current.department_id]: { ...current, type: 'department' }
              }),
              {}
            )
          }
        });
        return data;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const getSubGroup = (groupType, groupId) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/organization/view/${groupType}/${groupId}`,
      {},
      requestConfig,
      `GET_${groupType.toUpperCase()}`
    )
  )
    .then(res => res.data)
    .then(data => {
      return data;
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const getGroupMembers = (groupType, groupId) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `api/organization/view/${groupType}/${groupId}/members`,
      {},
      requestConfig,
      `GET_${groupType.toUpperCase()}`
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        dispatch({
          type: ADD_GROUP_MEMBERS,
          groupId,
          members: data.members
        });
        return data.members;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const addGroupMember = (groupType, groupId, { memberId, role }) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/organization/addMember/${groupType}/${groupId}`,
      { memberToAdd: memberId, role },
      requestConfig,
      'ADD_MEMBER'
    )
  )
    .then(res => res.data)
    .then(data => {
      return data;
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const addMultipleGroupMember = (groupType, groupId, members) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/organization/addMultipleMember/${groupType}/${groupId}`,
      members,
      requestConfig,
      'ADD_MEMBER'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        dispatch({
          type: ADD_GROUP_MEMBERS,
          groupId,
          members: res.members
        });
      }
      return res;
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const updateGroup = (groupType, groupId, values) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  const editableFields = ['name', 'description', 'organization_detail'];
  const editableValues = pick(values, editableFields);

  return dispatch(
    performRequest(
      'put',
      `api/organization/update/group/${groupType}/${groupId}`,
      editableValues,
      requestConfig,
      'UPDATE_GROUP'
    )
  )
    .then(res => res.data)
    .then(data => {
      dispatch({
        type: UPDATE_GROUP,
        groupId,
        data: values
      });
      return data;
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const fetchOrganizationMembers = organizationId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/organization/view/${organizationId}/allMembers`,
      {},
      requestConfig,
      'FETCH_ORGANIZATION_MEMBERS'
    )
  )
    .then(res => res.data)
    .then(data => {
      dispatch({
        type: ADD_ORGANIZATION_MEMBERS,
        memberList: data.memberList
      });
      return data;
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const fetchOrganizationRoles = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/organization/roles',
      {},
      requestConfig,
      'FETCH_ORGANIZATION_ROLES'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        return res.roles;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const inviteUserToOrganization = (
  userEmails,
  organizationId
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `api/organization/invite/${organizationId}`,
      { userEmails },
      requestConfig,
      'INVITE_USER_TO_ORGANIZATION'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const fetchUserOrganizationInvitation = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/organization/getUserInvitation',
      {},
      requestConfig,
      'FETCH_USER_INVITATION_TO_ORGA'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        return res.invitations;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const fetchOrganizationInvitation = organizationId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/organization/${organizationId}/getInvitations`,
      {},
      requestConfig,
      'FETCH_USER_INVITATION_TO_ORGA'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        return res.invitations;
      } else {
        throw new Error('error:default_message');
      }
    })
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const rejectOrganizationInvitation = organizationId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/invitation/reject',
      { organizationId },
      requestConfig,
      'REJECT_INVITATION'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const rejectJoinRequest = (userId, organizationId) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/join_request/reject',
      { userId, organizationId },
      requestConfig,
      'REJECT_JOIN_REQUEST'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const acceptOrganizationInvitation = organizationId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/invitation/accept',
      { organizationId },
      requestConfig,
      'ACCEPT_INVITATION'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const acceptJoinRequest = (userId, organizationId) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/join_request/accept',
      { userId, organizationId },
      requestConfig,
      'ACCEPT_JOIN_REQUEST'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const cancelOrganizationInvitation = (
  userId,
  organizationId
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/invitation/cancel',
      { userId, organizationId },
      requestConfig,
      'CANCEL_INVITATION'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};
export const cancelJoinRequest = organizationId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/organization/join_request/cancel',
      { organizationId },
      requestConfig,
      'CANCEL_JOIN_REQUEST'
    )
  )
    .then(res => res.data)
    .catch(err => {
      // console.log({ err });
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
      );
    });
};

export const checkPermission = ({
  organizationId,
  groupType,
  groupId,
  targetEntity,
  action
}) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `api/organization/${organizationId}/check_permisssion`,
      { groupType, groupId, targetEntity, action },
      requestConfig,
      'FETCH_ORGANIZATION_ROLES'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.success) {
        return res.data;
      } else {
        throw new Error('error:default_message');
      }
    });
};




