import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavigationBar from './Navbar';
import { isAuthenticated } from '../../../store/actions';
import TopHeader from './dist/top_header';

import { logout } from '../../../store/actions';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    this.props.isAuthenticated();
  }

  render() {
    const { navLinks, authUser } = this.props;
    return (
      <>
        <TopHeader />

        <NavigationBar
          {...this.props}
          navLinks={navLinks}
          authUser={authUser}
        />
      </>
    );
  }
}

const filteredNotifications = notifications => {
  return notifications.filter(notif => {
    return notif.is_dismiss === false;
  });
};

const mapStateToProps = (state /* , ownprops */) => {
  return {
    userId: state.authUser.userId,
    isLoggedIn: state.app.isAuthenticated,
    navLinks:
      state.app.navLinks[
        state.app.isAuthenticated ? state.authUser.function : 'default'
      ],
    notifications: filteredNotifications(state.notifications),
    authUser: state.authUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    isAuthenticated: () => dispatch(isAuthenticated('ANY'))
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Header);
