import { userPreferenceActionType } from '../../constants/actionType';
const initialState = {};

const {
  MERGE_PREFERENCE,
  UPDATE_PREFERENCE,
  REMOVE_FAVORITE_RULES_PREFERENCE,
  SET_DASHBOARD_TILES
} = userPreferenceActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case MERGE_PREFERENCE:
    return { ...action.preferenceData };
  case UPDATE_PREFERENCE:
    return { ...action.preferenceData };
  case SET_DASHBOARD_TILES: {
    return {
      ...state,
      dashboard_defaults: { ...state.dashboard_defaults, tiles: action.tiles }
    };
  }
  // case REMOVE_FAVORITE_RULES_PREFERENCE: {
  //   const jobType = Object.keys(action.rule)[0];
  //   const rule = state.favorite_ruleset[jobType];
  //   const ruleIndex = rule.findIndex(obj => {
  //     return obj === action.rule[jobType][0];
  //   });
  //   if (ruleIndex > -1) {
  //     state.favorite_ruleset[jobType].splice(ruleIndex, 1);
  //     return {
  //       ...state
  //     };
  //   } else {
  //     return state;
  //   }
  // }
  default:
    return state;
  }
};

export default reducer;
