import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import UsersPresenter from './PendingUsersPresenter';
import {
  fetchPendingUserAsAdmin,
  verifyUserRegistrationAsAdmin,
  verifyOneUserRegistrationAsAdmin
} from '../../../../../store/actions/users';

const mapToTable = mapData => {
  const data = Object.keys(mapData)
    .filter(
      key =>
        mapData[key].is_locked === false &&
        mapData[key].is_verified === false &&
        mapData[key].is_confirmed === true
    )
    .map(key => {
      const data = mapData[key];
      const fullName = `${mapData[key].first_name} ${mapData[key].last_name}`;
      return { id: key, fullName, ...data };
    });

  return data;
};

const mapStateToProps = state => {
  return {
    users: mapToTable(state.users)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPendingUserAsAdmin: (userId, filters, order, pagination) =>
      dispatch(fetchPendingUserAsAdmin(userId, filters, order, pagination)),
    verifyUserRegistrationAsAdmin: (values, proceedWithConflicts) =>
      dispatch(verifyUserRegistrationAsAdmin(values, proceedWithConflicts)),
    verifyOneUserRegistrationAsAdmin: (userId, action) =>
      dispatch(verifyOneUserRegistrationAsAdmin(userId, action))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['adminUserVerification'])
)(UsersPresenter);
