import React, { Component } from 'react';

import { Formik, Field, Form } from 'formik';
import CommentDisplay from './CommentDisplay';
import { FormGroup, Button, Row, Col, Container } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {
  convertFromRaw,
  convertFromHTML,
  ContentState,
  EditorState
} from 'draft-js';

import { dateFormatting } from '../../../locales/dateFormat';

export default class CommentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      commentList: []
    };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  clearEditorState = () => {
    this.setState({
      editorState: EditorState.push(
        this.state.editorState,
        ContentState.createFromText('')
      )
    });
  };

  render() {
    const { t, comments } = this.props;

    const filtredComments = comments.filter(
      ({ author, date }) => author && date
    );
    return (
      <div className="shadow p-3 mb-5 rounded">
        <h6 className="text-primary">{t('comments')}</h6>
        <hr className="pb-3" />

        <>
          {filtredComments.length > 0 &&
            filtredComments.map((message, key) => {
              let messageContent;

              try {
                messageContent = convertFromRaw(message);
              } catch (err) {
                const blocksFromHTML = convertFromHTML(
                  decodeURIComponent(message.comment || '<p> Empty </p>')
                );

                try {
                  messageContent = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                  );
                } catch (err) {
                  const defaultContent = convertFromHTML(
                    decodeURIComponent('<p> Empty </p>')
                  );
                  messageContent = ContentState.createFromBlockArray(
                    defaultContent.contentBlocks,
                    defaultContent.entityMap
                  );
                }
              }

              return (
                <Container className="border rounded my-3" key={key}>
                  <Row className="p-3 bg-light border-bottom">
                    <Col>
                      <div className="text-muted text-right">
                        <small>
                          {t('submission:date')}:{' '}
                          {dateFormatting(message.date, 'defaultHour')}
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row className="p-3">
                    <CommentDisplay messageContent={messageContent} />
                  </Row>
                  <Row className="p-3 border-top">
                    <div className="py-2">
                      <i className="fas fa-feather-alt" />{' '}
                      {message.author && (
                        <strong className="text-muted">
                          {message.author.first_name} {message.author.last_name}
                        </strong>
                      )}
                    </div>
                  </Row>
                </Container>
              );
            })}
        </>
      </div>
    );
  }
}
