import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, renameProps } from 'recompose';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Collapse,
  Badge,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useSelector } from 'react-redux';

import CustomTemplateCreation from './CustomTemplateCreation';

import rulePackageReportTemplate from '../../../../store/utils/rulePackageReportTemplate';

import {
  fetchReportTemplateList,
  requestCustomReportTemplateDeletion
} from '../../../../store/actions';

import Message from '../../../UI/Message';

const fileTypeFormatting = (
  custom_report_template_id,
  file,
  index,
  translate
) => {
  const fileType = file.split('.').pop().toLowerCase();

  const formatConfig = (fileExtension => {
    switch (fileExtension) {
      case 'dll': {
        return {
          colorButton: 'danger',
          fileIcon: 'fas fa-code',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
      case 'xlsx': {
        return {
          colorButton: 'success',
          fileIcon: 'fas fa-file-excel',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
      case 'docx': {
        return {
          colorButton: 'primary',
          fileIcon: 'fas fa-file-word',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
      case 'html': {
        return {
          colorButton: 'info',
          fileIcon: 'fas fa-file-code',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
      default: {
        return {
          colorButton: 'info',
          fileIcon: 'fas fa-question',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
    }
  })(fileType);

  return (
    <DropdownItem
      tag="a"
      onClick={e => e.stopPropagation()}
      size="md"
      key={`${file}-${index}-dropdown`}
      href={`${process.env.REACT_APP_API_ENDPOINT}/api/rule_package/customReportTemplate/${custom_report_template_id}/${file}/download`}
      color="light">
      <div>
        {formatConfig.label}
        <i className={`${formatConfig.fileIcon} ml-2 `} />
      </div>
    </DropdownItem>
  );
};

const RulePackage = props => {
  const { fetchReportTemplateList, requestCustomReportTemplateDeletion, t } =
    props;
  const [isLoaded, setIsLoaded] = useState(false);

  const [isDeleteRunning, setIsDeleteRunning] = useState(false);

  useEffect(() => {
    fetchReportTemplateList().then(() => {
      setIsLoaded(true);
    });
  }, []);
  const [activeTab, setActiveTab] = useState('');

  const reportTemplateList = useSelector(
    state => state.app.report_template_list
  );

  const availableReportTemplate = reportTemplateList
    //.map(rt => rt.name)
    .filter(rt => rt.name !== 'comparison');

  const [isModalOpen, toogleModal] = useState(false);

  useEffect(() => {}, [activeTab]);

  const deleteReportTemplate = async id => {
    try {
      setIsDeleteRunning(true);

      await requestCustomReportTemplateDeletion(id);
      Message.success(t('succes_deleted'));
    } catch (err) {
      Message.error(t(`${err.message}`));
    } finally {
      setIsDeleteRunning(false);
    }
  };

  return (
    <>
      {!isLoaded && (
        <div className="d-flex w-100 justify-content-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {isLoaded && (
        <div>
          <Row className="d-flex justify-content-between px-3">
            <h5 className="text-primary">{t('report_template_list')}</h5>

            <div>
              <Button
                color="warning font-weight-bold"
                size="sm"
                onClick={() => {
                  toogleModal(true);
                }}>
                <i className="fas fa-plus" /> {t('add_custom_template')}
              </Button>
            </div>
          </Row>

          <hr />
          <ListGroup flush>
            <ListGroupItem className="font-weight-bold" disabled>
              <Row>
                <Col className="font-weight-bold text-center">
                  {t('internal_name')}
                </Col>

                <Col className="font-weight-bold text-center">
                  {t('display_name')}
                </Col>

                <Col className="font-weight-bold text-center">
                  {t('files_available')}
                </Col>
                <Col className="font-weight-bold text-center">
                  {t('action')}
                </Col>
              </Row>
            </ListGroupItem>
            {availableReportTemplate.map(
              ({
                name,
                display_name,
                related_files,
                custom_report_template_id
              }) => {
                return (
                  <ListGroupItem key={`${name}_index`}>
                    <Row>
                      <Col className="text-center">{name}</Col>

                      <Col className="text-center">
                        {custom_report_template_id && display_name}

                        {!custom_report_template_id &&
                          t(`${name.toLowerCase()}`)}
                      </Col>

                      <Col className="text-center">
                        {related_files && related_files.length > 0 && (
                          <UncontrolledDropdown>
                            <DropdownToggle outline color="primary" caret>
                              <i className="fas fa-download mr-1"></i>
                              {t('dl_files')}
                            </DropdownToggle>
                            <DropdownMenu
                              boundariesElement="window"
                              positionFixed={true}
                              modifiers={{
                                preventOverflow: {
                                  boundariesElement: 'window'
                                },
                                flip: { boundariesElement: 'window' }
                              }}>
                              {related_files &&
                                related_files.map((file, index) => {
                                  return (
                                    <div key={`${file}-${index}`}>
                                      {fileTypeFormatting(
                                        custom_report_template_id,
                                        file,
                                        index,
                                        t
                                      )}
                                    </div>
                                  );
                                })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </Col>

                      <Col className="text-center">
                        {custom_report_template_id && (
                          <div>
                            <Button
                              id={`delete-${custom_report_template_id}`}
                              disabled={isDeleteRunning}
                              color="link"
                              size="md"
                              onClick={async () => {
                                await deleteReportTemplate(
                                  custom_report_template_id
                                );
                              }}>
                              <i className="fas fa-trash text-danger"></i>
                            </Button>
                            <UncontrolledTooltip
                              boundariesElement="window"
                              fade={false}
                              target={`delete-${custom_report_template_id}`}
                              // delay={0}
                            >
                              {t('delete')}
                            </UncontrolledTooltip>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              }
            )}
          </ListGroup>

          <Modal backdrop="static" size="lg" isOpen={isModalOpen}>
            <ModalHeader
              toggle={() => {
                toogleModal(false);
              }}>
              {t('add_custom_template_title')}
            </ModalHeader>

            <ModalBody>
              <CustomTemplateCreation toogleModal={toogleModal} />
            </ModalBody>
          </Modal>
        </div>
      )}{' '}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchReportTemplateList: () => dispatch(fetchReportTemplateList()),
    requestCustomReportTemplateDeletion: id =>
      dispatch(requestCustomReportTemplateDeletion(id))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['rule_package_management'])
)(RulePackage);
