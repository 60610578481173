import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styles from './TermOfUse.module.css';
import dataContent from './content.json';

const lists = data => {
  return data.map(tree => {
    if (tree.child) {
      if (tree.content) {
        return (
          <>
            <h4 className="mt-4">
              <b>{tree.title}</b>
            </h4>
            <p>{tree.content}</p>
            <ol className={`${styles.textSize} text-justify`}>
              {loop(tree.child)}
            </ol>
          </>
        );
      }
      return (
        <>
          <h4 className="mt-4">
            <b>{tree.title}</b>
          </h4>
          <ol className={`${styles.textSize} text-justify`}>
            {loop(tree.child)}
          </ol>
        </>
      );
    }
    if (tree.content) {
      return (
        <>
          <h4 className="mt-4">
            <b>{tree.title}</b>
          </h4>
          <p>{tree.content}</p>
        </>
      );
    }
    return (
      <>
        <h4 className="mt-4">
          <b>{tree.title}</b>
        </h4>
      </>
    );
  });
};

const loop = data => {
  return data.map(tree => {
    if (tree.child) {
      if (tree.title) {
        return (
          <>
            <li className="mt-3">
              <div>
                <b>{tree.title}</b>
              </div>
              {tree.content}
              <ol className={`${styles.textSize} text-justify`}>
                {loop(tree.child)}
              </ol>
            </li>
          </>
        );
      }
      return (
        <>
          <li className={`${styles.textSize} mt-3`}>{tree.content}</li>
          <ol className={`${styles.textSize} text-justify`}>
            {loop(tree.child)}
          </ol>
        </>
      );
    }
    if (tree.title) {
      return (
        <>
          <li className={`${styles.textSize} mt-3`}>
            <div>
              <b>{tree.title}</b>
            </div>
            {tree.content}
          </li>
        </>
      );
    }
    return (
      <>
        <li className="mt-3">{tree.content}</li>
      </>
    );
  });
};

class TermOfUse extends Component {
  render() {
    const data = dataContent.termsAndCondition;
    return (
      <Container className="my-5">
        <Row>
          <Col>
            <h1 className="text-center mb-5">
              <b>{data.title}</b>
            </h1>
            <p>{lists(data.child)}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TermOfUse;
