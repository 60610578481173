import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CustomInputFromFile from '../../../UI/Input/customInput';
import EditableInput from '../../../UI/Input/editableInput';
import AddInput from './addInput';
import isDeepEqual from 'lodash.isequal';
import { isEqual, sortBy, get } from 'lodash';
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import prettyBytes from 'pretty-bytes';
import * as Yup from 'yup';
import Message from '../../../UI/Message';
import styles from './Styles.module.css';

const sanitizeString = param =>
  param
    .trim()
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase();

const formikConfig = props => {
  const { app } = props.admin_configuration;

  const { t } = props;
  const minimun_model_size_per_stage = 5000000000;
  const initialAppValue = app;

  initialAppValue.limit_number_of_model_per_stage = {
    enabled: app.limit_number_of_model_per_stage > 0,
    value: app.limit_number_of_model_per_stage
  };

  initialAppValue.limit_model_size_per_user = {
    enabled: app.limit_model_size_per_user > 0,
    value: app.limit_model_size_per_user
  };

  initialAppValue.limit_model_size_per_stage = {
    enabled: app.limit_model_size_per_stage > 0,
    value: app.limit_model_size_per_stage
  };

  return {
    initialValues: {
      // app: {
      //   feedback_email_to_notify: app.feedback_email_to_notify,
      //   limit_number_of_project: {
      //     enabled: app.limit_number_of_project.enabled,
      //     value: app.limit_number_of_project.value
      //   },
      //   limit_number_of_stage: {
      //     enabled: app.limit_number_of_stage.enabled,
      //     value: app.limit_number_of_stage.value
      //   },
      //   limit_number_of_model_per_stage: {
      //     enabled: app.limit_number_of_model_per_stage > 0,
      //     value: app.limit_number_of_model_per_stage
      //   },
      //   limit_model_size_per_stage: app.limit_model_size_per_stage,
      //   predefined_list_of_stages: {
      //     default_stage: {
      //       display_name:
      //         app.predefined_list_of_stages.default_stage.display_name,
      //       name: app.predefined_list_of_stages.default_stage.name
      //     },
      //     user_created: app.predefined_list_of_stages.user_created
      //   },

      // },
      app: initialAppValue,
      add_email_to_list: '',
      add_user_created_stage: '',
      add_email_to_list_isActive: false,
      add_user_created_stage_isActive: false,
      limit_number_of_model_per_stage_checkbox: false,
      add_emails_to_send_admin_activities_report: '',
      add_emails_to_send_admin_activities_report_isActive: false
    },

    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      setSubmitting(true);
      const { t, admin_configuration } = props;

      const added_new_stage = values.add_user_created_stage;
      const added_new_feedback_email = values.add_email_to_list;
      const added_new_emails_to_send_admin_activities_report =
        values.add_emails_to_send_admin_activities_report;

      if (
        !!added_new_stage &&
        values.app['predefined_list_of_stages'].user_created.findIndex(
          stage => stage.display_name === added_new_stage
        ) === -1
      ) {
        values.app['predefined_list_of_stages'].user_created.push({
          name: sanitizeString(added_new_stage),
          display_name: added_new_stage
        });
      }
      if (
        !!added_new_feedback_email &&
        values.app['feedback_email_to_notify'].indexOf(
          added_new_feedback_email
        ) === -1
      ) {
        values.app['feedback_email_to_notify'].push(added_new_feedback_email);
      }

      if (
        !!added_new_emails_to_send_admin_activities_report &&
        values.app['emails_to_send_admin_activities_report'].indexOf(
          added_new_emails_to_send_admin_activities_report
        ) === -1
      ) {
        values.app['emails_to_send_admin_activities_report'].push(
          added_new_emails_to_send_admin_activities_report
        );
      }

      //get the values changed
      const dirtyValues = Object.keys(values.app).reduce((acc, key) => {
        if (
          isDeepEqual(
            admin_configuration.app[key],
            [
              'limit_number_of_model_per_stage',
              'limit_model_size_per_user',
              'limit_model_size_per_stage',
              'predefined_list_of_stages',
              'feedback_email_to_notify',
              'emails_to_send_admin_activities_report'
            ].includes(key)
              ? values.app[key].value
              : values.app[key]
          )
        ) {
          return acc;
        } else {
          if (key === 'predefined_list_of_stages') {
            return {
              ...acc,
              [key]: {
                ['default_stage']: {
                  ...values.app[key].default_stage,
                  ['name']: sanitizeString(
                    values.app[key].default_stage.display_name
                  )
                },
                ['user_created']: values.app[key].user_created.map(element => {
                  return {
                    ...element,
                    name: sanitizeString(element.display_name)
                  };
                })
              }
            };
          } else if (
            [
              'limit_number_of_model_per_stage',
              'limit_model_size_per_user',
              'limit_model_size_per_stage'
            ].includes(key)
          ) {
            return {
              ...acc,
              [key]: values.app[key].value
            };
          } else {
            return { ...acc, [key]: values.app[key] };
          }
        }
      }, {});
      props
        .editAdminConfigSettings({ app: dirtyValues })
        .then(() => {
          Message.success(t('config_update_success'));
        })
        .catch(err => {
          Message.error(props.t(`error:${err.message}`));
        })
        .then(() => {
          setSubmitting(false);
          setFieldValue('add_user_created_stage_isActive', false);
          setFieldValue('add_email_to_list_isActive', false);
          setFieldValue(
            'add_emails_to_send_admin_activities_report_isActive',
            false
          );
        });
    },

    validationSchema: FormValues => {
      return Yup.object().shape({
        app: Yup.object().shape({
          feedback_email_to_notify: Yup.array().of(
            Yup.string()
              .email(t('invalid_email'))
              // .test('test_email', 'Email already on the list', function test(
              //   currentValue
              // ) {
              //   const merge = {
              //     ...props.admin_configuration.app.feedback_email_to_notify,
              //     ...this.parent
              //   };

              //   const arrayListofEmails = Object.values(merge);
              //   const checkIfExist = arrayListofEmails.some((item, index) => {
              //     return arrayListofEmails.indexOf(item) !== index;
              //   });

              //   // false here means it will return error; true means the field is valid
              //   // if checkIfExist === true ; then we will need to negate it to return error
              //   return !checkIfExist;
              // })
              .required(t('required'))
          ),
          emails_to_send_admin_activities_report: Yup.array().of(
            Yup.string().email(t('invalid_email')).required(t('required'))
          ),
          limit_number_of_project: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', {
              is: true,
              then: Yup.number()
                .typeError(t('invalid_value'))
                .min(1, t('minimum_value_for_number_of_projects'))
                .required(t('required'))
            })
          }),
          limit_number_of_stage: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', {
              is: true,
              then: Yup.number()
                .typeError(t('invalid_value'))
                .min(1, t('minimum_value_for_number_of_stage'))
                .required(t('required'))
            })
          }),
          limit_number_of_model_per_stage: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', {
              is: true,
              then: Yup.number()
                .typeError(t('invalid_value'))
                .min(1, t('minimum_value_for_number_of_model_per_stage'))
                .required(t('required'))
            })
          }),

          limit_model_size_per_stage: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', {
              is: true,
              then: Yup.number()
                .typeError(t('invalid_value'))
                .min(1, t('minimum_value_for_model_size_per_stage'))
                .required(t('required'))
            })
          }),

          limit_model_size_per_user: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', {
              is: true,
              then: Yup.number()
                .typeError(t('invalid_value'))
                .min(1, t('minimum_value_for_model_size_per_user'))
                .required(t('required'))
            })
          }),

          document_management_allowed_size: Yup.number()
            .typeError(t('invalid_value'))
            .min(1, t('minimum_value_for_document_management_allowed_size'))
            .required(t('required')),

          max_concurrent_job_per_user: Yup.number()
            .typeError(t('invalid_value'))
            .min(1, t('minimum_value_for_concurrent_job_per_user'))
            .required(t('required')),

          max_concurrent_job_per_project: Yup.number()
            .typeError(t('invalid_value'))
            .min(1, t('minimum_value_for_concurrent_job_per_project'))
            .required(t('required')),

          predefined_list_of_stages: Yup.object().shape({
            user_created: Yup.array().of(
              Yup.object().shape({
                display_name: Yup.string()
                  // .test(
                  //   'user_created',
                  //   'Stage name already on the list',
                  //   function test(currentValue) {
                  //     const value = get(props.admin_configuration, this.path);
                  //     if (value === currentValue) {
                  //       return true;
                  //     } else {
                  //       const stageList = props.admin_configuration.app.predefined_list_of_stages.user_created.map(
                  //         item => item.display_name
                  //       );

                  //       return !stageList.includes(currentValue);
                  //     }
                  //   }
                  // )
                  .required(t('required'))
              })
            ),
            default_stage: Yup.object().shape({
              display_name: Yup.string().required(t('required'))
            })
          })
        }),
        add_email_to_list: Yup.string().when('add_email_to_list_isActive', {
          is: true,
          then: Yup.string()
            .notOneOf(app.feedback_email_to_notify, t('is_email_on_list'))
            .email(t('invalid_email'))
            .test(
              'test_email',
              t('is_email_on_list'),
              function test(currentValue) {
                return !this.parent.app.feedback_email_to_notify.includes(
                  currentValue
                );
              }
            )
            .required(t('required'))
        }),
        add_emails_to_send_admin_activities_report: Yup.string().when(
          'add_emails_to_send_admin_activities_report_isActive',
          {
            is: true,
            then: Yup.string()
              .notOneOf(
                app.emails_to_send_admin_activities_report,
                t('is_email_on_list')
              )
              .email(t('invalid_email'))
              .test(
                'test_email',
                t('is_email_on_list'),
                function test(currentValue) {
                  return !this.parent.app.emails_to_send_admin_activities_report.includes(
                    currentValue
                  );
                }
              )
              .required(t('required'))
          }
        ),
        add_user_created_stage: Yup.string().when(
          'add_user_created_stage_isActive',
          {
            is: true,
            then: Yup.string()
              .notOneOf(
                app.predefined_list_of_stages.user_created.map(val => {
                  return val.display_name;
                }),
                t('stage_on_list')
              )
              .test(
                'test_user_created_stage',
                t('stage_on_list'),
                function test(currentValue) {
                  const arrayListofStage =
                    this.parent.app.predefined_list_of_stages.user_created.map(
                      val => {
                        return val.display_name;
                      }
                    );
                  return !arrayListofStage.includes(currentValue);
                }
              )
              .required(t('required'))
          }
        ),

        add_email_to_list_isActive: Yup.boolean(),
        add_user_created_stage_isActive: Yup.boolean(),
        add_emails_to_send_admin_activities_report_isActive: Yup.boolean()
      });
    }
  };
};
class ConfigPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      email_list_to_feedback: []
    };
  }
  componentDidMount() {
    const { fetchAdminConfigSettings, t } = this.props;
    fetchAdminConfigSettings()
      .then(() => {
        const { admin_configuration } = this.props;
        this.setState({
          isLoaded: true
        });
      })
      .catch(err => {
        console.log(err);
        Message.error(t('error:default_message'));
      });
  }

  hadleSwitchedAction = (
    el,
    setFieldValue,
    fieldNameSwitch,
    fieldNameInput,
    fieldValue
  ) => {
    const ischeck = el.target.checked;
    setFieldValue(fieldNameSwitch, ischeck);
    setFieldValue(fieldNameInput, !ischeck ? fieldValue : '');
  };

  render() {
    const { isLoaded, new_feedback_email_to_notify } = this.state;
    const { t } = this.props;
    return (
      <>
        {isLoaded && (
          <Formik {...formikConfig(this.props)}>
            {formikProps => {
              const {
                isSubmitting,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                onSubmit
              } = formikProps;

              return (
                <Form>
                  <Row className="justify-content-end p-3 ">
                    <div>
                      <Button
                        type="submit"
                        color="success"
                        outline={!formikProps.dirty}
                        className="mx-1"
                        disabled={
                          formikProps.isSubmitting || !formikProps.dirty
                        }
                        onClick={onSubmit}
                        id="formSaveButton">
                        <i className="fas fa-save fs-1-2x" />
                        <span className="ml-2"> {t('save_button_label')}</span>
                      </Button>
                    </div>
                  </Row>
                  <Container>
                    <FieldArray
                      name="app.feedback_email_to_notify"
                      render={arrayHelpers => (
                        <FormGroup row>
                          <Col>
                            <Label
                              for="exampleCustomSelect"
                              className="text-secondary font-weight-bold">
                              {t('feedback_emails_to_notify_label')}
                            </Label>
                          </Col>
                          <Col>
                            <ListGroup>
                              <ListGroupItem className="py-1 shadow-sm text-primary">
                                <AddInput
                                  values={values}
                                  errors={errors}
                                  arrayHelpers={arrayHelpers}
                                  name="add_email_to_list"
                                  inputType="email"
                                  setFieldValue={setFieldValue}
                                />
                              </ListGroupItem>

                              {values.app.feedback_email_to_notify.map(
                                (email, index) => {
                                  return (
                                    <ListGroupItem
                                      key={index}
                                      className="py-0 shadow-sm">
                                      <Row className={styles.centerAlignItems}>
                                        <Col sm="12">
                                          <Field
                                            name={`app.feedback_email_to_notify[${index}]`}
                                            type="text"
                                            disabled={formikProps.isSubmitting}
                                            component={EditableInput}
                                            className={styles.inputMargin}
                                            isremovable={{
                                              enable:
                                                values.app
                                                  .feedback_email_to_notify
                                                  .length > 1,
                                              functionToExecute: () => {
                                                arrayHelpers.remove(index);
                                              },
                                              index: index
                                            }}
                                            DisplayTag="div"
                                            autoFocus
                                          />
                                        </Col>
                                      </Row>
                                    </ListGroupItem>
                                  );
                                }
                              )}
                            </ListGroup>
                          </Col>
                        </FormGroup>
                      )}
                    />

                    <hr />

                    <FieldArray
                      name="app.emails_to_send_admin_activities_report"
                      render={arrayHelpers => (
                        <FormGroup row>
                          <Col>
                            <Label
                              for="exampleCustomSelect"
                              className="text-secondary font-weight-bold">
                              {t(
                                'emails_to_send_admin_activities_report_label'
                              )}
                            </Label>
                          </Col>
                          <Col>
                            <ListGroup>
                              <ListGroupItem className="py-1 shadow-sm text-primary">
                                <AddInput
                                  values={values}
                                  errors={errors}
                                  arrayHelpers={arrayHelpers}
                                  name="add_emails_to_send_admin_activities_report"
                                  inputType="email"
                                  setFieldValue={setFieldValue}
                                />
                              </ListGroupItem>

                              {values.app
                                .emails_to_send_admin_activities_report &&
                                values.app.emails_to_send_admin_activities_report.map(
                                  (email, index) => {
                                    return (
                                      <ListGroupItem
                                        key={index}
                                        className="py-0 shadow-sm">
                                        <Row
                                          className={styles.centerAlignItems}>
                                          <Col sm="12">
                                            <Field
                                              name={`app.emails_to_send_admin_activities_report[${index}]`}
                                              type="text"
                                              disabled={
                                                formikProps.isSubmitting
                                              }
                                              component={EditableInput}
                                              className={styles.inputMargin}
                                              isremovable={{
                                                enable:
                                                  values.app
                                                    .emails_to_send_admin_activities_report
                                                    .length > 1,
                                                functionToExecute: () => {
                                                  arrayHelpers.remove(index);
                                                },
                                                index: index
                                              }}
                                              DisplayTag="div"
                                              autoFocus
                                            />
                                          </Col>
                                        </Row>
                                      </ListGroupItem>
                                    );
                                  }
                                )}
                            </ListGroup>
                          </Col>
                        </FormGroup>
                      )}
                    />

                    {/* <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="sendAdminActivitiesReport"
                          className="text-secondary font-weight-bold">
                          {t('emails_to_send_admin_activities_report_label')}
                        </Label>
                      </Col>
                      <Col sm={2}>
                        <CustomInput
                          type="switch"
                          id="values.app.emails_to_send_admin_activities_report"
                          name="values.app.emails_to_send_admin_activities_report"
                          label="Enable"
                          onChange={el => {
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_number_of_project.enabled',
                              'app.limit_number_of_project.value',
                              0
                            );
                          }}
                          checked={values.app.limit_number_of_project.enabled}
                        />
                      </Col>
                    </FormGroup> */}

                    <hr />
                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('limit_number_of_projects_label')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          type="switch"
                          id="app.limit_number_of_project.enabled"
                          name="app.limit_number_of_project.enabled"
                          label="Enable"
                          onChange={el => {
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_number_of_project.enabled',
                              'app.limit_number_of_project.value',
                              0
                            );
                          }}
                          checked={values.app.limit_number_of_project.enabled}
                        />
                      </Col>
                      {values.app.limit_number_of_project.enabled && (
                        <Col>
                          <Field
                            name="app.limit_number_of_project.value"
                            type="number"
                            component={CustomInputFromFile}
                            prefix="Value"
                          />
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('limit_number_of_stage_label')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          type="switch"
                          id="limit_number_of_stage"
                          name=" values.app.limit_number_of_stage"
                          label="Enable"
                          onChange={el => {
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_number_of_stage.enabled',
                              'app.limit_number_of_stage.value',
                              0
                            );
                          }}
                          checked={values.app.limit_number_of_stage.enabled}
                        />
                      </Col>
                      {values.app.limit_number_of_stage.enabled && (
                        <Col>
                          <div>
                            <Field
                              name="app.limit_number_of_stage.value"
                              type="number"
                              component={CustomInputFromFile}
                              prefix="Value"
                            />
                          </div>
                        </Col>
                      )}
                    </FormGroup>

                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('limit_number_of_model_per_stage_label')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          type="switch"
                          id="values.app.limit_number_of_model_per_stage"
                          name="values.app.limit_number_of_model_per_stage"
                          label="Enable"
                          onChange={el => {
                            const ischeck = el.target.checked;
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_number_of_model_per_stage.enabled',
                              'app.limit_number_of_model_per_stage.value',
                              -1
                            );
                          }}
                          checked={
                            values.app.limit_number_of_model_per_stage.enabled
                          }
                        />
                      </Col>

                      {values.app.limit_number_of_model_per_stage.enabled && (
                        <Col>
                          <Field
                            name="app.limit_number_of_model_per_stage.value"
                            type="number"
                            component={CustomInputFromFile}
                            prefix="Value"
                          />
                        </Col>
                      )}
                    </FormGroup>

                    {/* <FormGroup row className={styles.centerAlignItems}>
                              <Label
                                for="exampleNumber"
                                sm={3}
                                className="text-secondary font-weight-bold">
                                {t(
                                  'limit_number_of_model_size_per_stage_label'
                                )}
                              </Label>
                              <Col sm={3}>
                                <Field
                                  name="app.limit_model_size_per_stage"
                                  type="number"
                                  component={CustomInputFromFile}
                                  prefix="Value"
                                />
                              </Col>
                            </FormGroup> */}

                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('limit_number_of_model_size_per_stage_label')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          type="switch"
                          id="values.app.limit_model_size_per_stage"
                          name="values.app.limit_model_size_per_stage"
                          label="Enable"
                          onChange={el => {
                            const ischeck = el.target.checked;
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_model_size_per_stage.enabled',
                              'app.limit_model_size_per_stage.value',
                              -1
                            );
                          }}
                          checked={
                            values.app.limit_model_size_per_stage.enabled
                          }
                        />
                      </Col>

                      {values.app.limit_model_size_per_stage.enabled && (
                        <Col>
                          <div className="d-flex flex-row">
                            <div>
                              <Field
                                name="app.limit_model_size_per_stage.value"
                                type="number"
                                component={CustomInputFromFile}
                                prefix="Value"
                              />
                            </div>
                            {values.app.limit_model_size_per_stage.value >
                              0 && (
                              <i className="ml-2 text-muted">
                                * equivalent to{' '}
                                {prettyBytes(
                                  parseInt(
                                    values.app.limit_model_size_per_stage.value
                                  )
                                )}
                              </i>
                            )}
                          </div>
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('document_management_allowed_size')}
                        </Label>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex flex-row">
                          <div>
                            <Field
                              name="app.document_management_allowed_size"
                              type="number"
                              component={CustomInputFromFile}
                              prefix="Value"
                            />
                          </div>
                          {values.app.document_management_allowed_size > 0 && (
                            <i className="ml-2 text-muted">
                              * equivalent to{' '}
                              {prettyBytes(
                                parseInt(
                                  values.app.document_management_allowed_size
                                )
                              )}
                            </i>
                          )}
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('max_concurrent_job_per_user')}
                        </Label>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex flex-row">
                          <div>
                            <Field
                              name="app.max_concurrent_job_per_user"
                              type="number"
                              component={CustomInputFromFile}
                              prefix="Value"
                            />
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('max_concurrent_job_per_project')}
                        </Label>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex flex-row">
                          <div>
                            <Field
                              name="app.max_concurrent_job_per_project"
                              type="number"
                              component={CustomInputFromFile}
                              prefix="Value"
                            />
                          </div>
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup row className={styles.centerAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('limit_model_size_per_user_label')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          type="switch"
                          id="values.app.limit_model_size_per_user"
                          name="values.app.limit_model_size_per_user"
                          label="Enable"
                          onChange={el => {
                            const ischeck = el.target.checked;
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.limit_model_size_per_user.enabled',
                              'app.limit_model_size_per_user.value',
                              -1
                            );
                          }}
                          checked={values.app.limit_model_size_per_user.enabled}
                        />
                      </Col>

                      {values.app.limit_model_size_per_user.enabled && (
                        <Col>
                          <div className="d-flex flex-row">
                            <div>
                              <Field
                                name="app.limit_model_size_per_user.value"
                                type="number"
                                component={CustomInputFromFile}
                                prefix="Value"
                              />
                            </div>
                            {values.app.limit_model_size_per_user.value > 0 && (
                              <i className="ml-2 text-muted">
                                * equivalent to{' '}
                                {prettyBytes(
                                  parseInt(
                                    values.app.limit_model_size_per_user.value
                                  )
                                )}
                              </i>
                            )}
                          </div>
                        </Col>
                      )}
                    </FormGroup>

                    <hr />
                    <FormGroup row className={styles.justifyAlignItems}>
                      <Col>
                        <Label
                          for="exampleNumber"
                          className="text-secondary font-weight-bold">
                          {t('default_stage_name_label')}
                        </Label>
                      </Col>
                      <Col>
                        <Field
                          name="app.predefined_list_of_stages.default_stage.display_name"
                          type="text"
                          component={CustomInputFromFile}
                        />
                      </Col>
                    </FormGroup>
                    <FieldArray
                      name="app.predefined_list_of_stages.user_created"
                      render={arrayHelpers => (
                        <FormGroup row>
                          <Label
                            for="exampleCustomSelect"
                            className="text-secondary font-weight-bold">
                            {t('predefine_list_of_stage_label')}
                          </Label>
                          <Col>
                            <ListGroup>
                              <ListGroupItem className="py-1 shadow-sm">
                                <AddInput
                                  values={values}
                                  errors={errors}
                                  arrayHelpers={arrayHelpers}
                                  name="add_user_created_stage"
                                  inputType="text"
                                  setFieldValue={setFieldValue}
                                />
                              </ListGroupItem>
                              {values.app.predefined_list_of_stages.user_created.map(
                                (val, index) => {
                                  return (
                                    <ListGroupItem
                                      key={index}
                                      className="py-0 shadow-sm">
                                      <Row className={styles.centerAlignItems}>
                                        <Col>
                                          <Field
                                            name={`app.predefined_list_of_stages.user_created[${index}]['display_name']`}
                                            type="text"
                                            disabled={formikProps.isSubmitting}
                                            component={EditableInput}
                                            className={styles.inputMargin}
                                            DisplayTag="div"
                                            isremovable={{
                                              enable:
                                                values.app
                                                  .predefined_list_of_stages
                                                  .user_created.length > 0,
                                              functionToExecute: () => {
                                                arrayHelpers.remove(index);
                                              },
                                              index: index
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </ListGroupItem>
                                  );
                                }
                              )}
                            </ListGroup>
                          </Col>
                        </FormGroup>
                      )}
                    />
                    <hr />
                    <FormGroup row>
                      <Col>
                        <Label className="text-secondary font-weight-bold">
                          {t('queryui_ruledsl_tab')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          className="w-100"
                          type="switch"
                          id="values.app.query_ui_tab"
                          name="values.app.query_ui_tab"
                          label="Enable"
                          onChange={el => {
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.query_ui.queryuiEnableRuleDSLTab',
                              -1
                            );
                          }}
                          checked={values.app.query_ui.queryuiEnableRuleDSLTab}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label className="text-secondary font-weight-bold">
                          {t('queryui_editable_ruledsl_window')}
                        </Label>
                      </Col>
                      <Col md={2}>
                        <CustomInput
                          disabled={
                            !values.app.query_ui.queryuiEnableRuleDSLTab
                          }
                          className="w-100"
                          type="switch"
                          id="values.app.query_ui"
                          name="values.app.query_ui"
                          label="Enable"
                          onChange={el => {
                            this.hadleSwitchedAction(
                              el,
                              setFieldValue,
                              'app.query_ui.queryuiEnableEditableRuleDSLQuery',
                              -1
                            );
                          }}
                          checked={
                            values.app.query_ui
                              .queryuiEnableEditableRuleDSLQuery
                          }
                        />
                      </Col>
                    </FormGroup>
                  </Container>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
}

export default withTranslation(['adminConfigurations'])(ConfigPresenter);
