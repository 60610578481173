import { projectStageActionType } from '../../constants/actionType';
const initialState = {};

const {
  ADD_STAGE,
  UPDATE_STAGE,
  REMOVE_STAGE,
  RESET_STAGES
} = projectStageActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STAGE: {
      const { projectId, stageName, stageData } = action;
      const previousStages = state[projectId] ? state[projectId].stages : {};
      return {
        ...state,
        [projectId]: { stages: { ...previousStages, [stageName]: stageData } }
      };
    }
    case UPDATE_STAGE: {
      const { projectId, stageName, stageData } = action;
      const previousStages = state[projectId] ? state[projectId].stages : {};
      return {
        ...state,
        [projectId]: {
          stages: {
            ...previousStages,
            [stageName]: { ...previousStages[stageName], ...stageData }
          }
        }
      };
    }
    case REMOVE_STAGE: {
      const { projectId, stageName } = action;
      const { [stageName]: stageToRemove, ...nextStages } = state[
        projectId
      ].stages;
      return { ...state, [projectId]: nextStages };
    }
    case RESET_STAGES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
