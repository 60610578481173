import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import 'rc-tree/assets/index.css';
import './Tree.css';

const DescriptionLinkHtml = ({ src, tooltip }) => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    href={src}
    className="descriptionLink"
    onClick={e => {
      e.stopPropagation();
    }}>
    <i className="fas fa-external-link-alt" title={tooltip} />
  </a>
);

class TreeComponent extends Component {
  constructor(props) {
    super(props);
    this.treeRef = React.createRef();
    this.state = {
      expandedKeys: props.expandedKeys || [],
      autoExpandParent: false,
      treeData: []
    };
  }
  static defaultProps = {
    visible: false,
    multiple: true
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expandedKeys !== this.props.expandedKeys) {
      this.setState({ expandedKeys: this.props.expandedKeys });
    }
  }

  // loopData = (data, expandedKeys) => {
  //   return data.reduce((acc, item) => {
  //     if (item.children && expandedKeys.find(val => val === item.key)) {
  //       return [
  //         ...acc,
  //         {
  //           key: item.key,
  //           label: item.title
  //         },
  //         ...this.loopData(item.children, expandedKeys)
  //       ];
  //     }
  //     return [
  //       ...acc,
  //       {
  //         key: item.key,
  //         label: item.title,
  //         description: item.descriptionLink,
  //         ruleId: item.ruleId,
  //         level: item.level
  //       }
  //     ];
  //   }, []);
  // };
  onExpand = expandedKeys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded chilren keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };
  renderIcon = ({ ruleId, level }) => {
    if (ruleId) {
      return (
        <DescriptionLinkHtml
          src={`${
            process.env.REACT_APP_API_ENDPOINT[
              process.env.REACT_APP_API_ENDPOINT.length - 1
            ] == '/'
              ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
              : process.env.REACT_APP_API_ENDPOINT
          }/api/rules/description/${ruleId}?level=${level}`}
          tooltip={this.props.altLabelLink}
        />
      );
    }
    return <></>;
  };

  render() {
    const gData = this.props.data;

    const renderSwitcherIcon = ({ isLeaf, expanded, loading }) => {
      if (isLeaf) {
        return null;
      }
      return (
        <div>
          <span className={`${expanded ? 'd-none' : ''}`}>
            <i className={`fas fa-angle-right  `} />
          </span>
          <span className={`${!expanded ? 'd-none' : ''}`}>
            <i className={`fas fa-angle-down`} />
          </span>
        </div>
      );
    };

    const loop = data => {
      return data.map(item => {
        if (item.children) {
          return (
            item.children.length > 0 && (
              <TreeNode
                key={item.key}
                className={item.show === false ? 'd-none' : 'has-children'}
                title={item.title}>
                {loop(item.children)}
              </TreeNode>
            )
          );
        }
        if (
          this.props.ruleErrorMapping &&
          this.props.ruleErrorMapping[item.ruleId]
        ) {
          const title = (
            <span>
              <span>{item.title}</span>
              <span id={`warning-${item.ruleId}`} className="ml-1">
                <i className="fas fa-exclamation-circle text-warning"></i>
              </span>
              <UncontrolledTooltip
                placement="right"
                target={`warning-${item.ruleId}`}>
                {this.props.t('autochecker:rule_not_passing_validation')}
              </UncontrolledTooltip>
            </span>
          );
          return (
            <TreeNode
              key={item.key}
              title={title}
              className={item.show === false ? 'd-none' : ''}
              description={item.descriptionLink}
              ruleId={item.ruleId}
              level={item.level}
            />
          );
        } else {
          return (
            <TreeNode
              key={item.key}
              title={item.title}
              className={item.show === false ? 'd-none' : ''}
              description={item.descriptionLink}
              ruleId={item.ruleId}
              level={item.level}
            />
          );
        }
      });
    };

    return (
      <Tree
        ref={this.treeRef}
        checkable={<span className="custom-tree-checkbox-inner" />}
        prefixCls="custom-tree"
        switcherIcon={renderSwitcherIcon}
        icon={this.renderIcon}
        onExpand={this.onExpand}
        expandedKeys={this.state.expandedKeys}
        autoExpandParent={false}
        onCheck={this.props.onChecked}
        checkedKeys={this.props.checkedKeys}>
        {loop(gData)}
      </Tree>
    );
  }
}

TreeComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  onChecked: PropTypes.func.isRequired,
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  checkedKeys: PropTypes.arrayOf(PropTypes.string)
};

export default TreeComponent;
