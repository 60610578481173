import React, { useEffect, useRef, useState } from 'react';
import { Table, Container, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { fetchFooterData } from '../../../../store/actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  for (var i = 0; i < sizes.length; i++) {
    if (bytes <= 1024) {
      return `${bytes}${sizes[i]}`;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return `${bytes}P`;
};

const flattenFooterData = (data, category) => {
  const { concurrencyControlVersionId, ...footerData } = data;

  return Object.keys(footerData).reduce((acc, currentKey) => {
    if (currentKey === 'relatedLinks') return acc;
    if (category && currentKey !== category) return acc;
    const mapVersion = footerData[currentKey].dataList.reduce(
      (accDt, currentDt) => {
        return [
          ...accDt,
          ...currentDt.versions.map(version => {
            return {
              ...version,
              title: currentDt.title,
              description: currentDt.description
            };
          })
        ];
      },
      []
    );
    return {
      ...acc,
      [currentKey]: { ...footerData[currentKey], dataList: mapVersion }
    };
  }, {});
};

const TableLinks = props => {
  const {
    t,
    footerData,
    fetchFooter,
    location: { hash },
    match
  } = props;
  useEffect(() => {
    fetchFooter();
  }, []);

  const category = match && match.params && match.params.category;

  const highlightedRowRef = useRef(null);
  const scrollToBottom = () => {
    if (highlightedRowRef.current)
      highlightedRowRef.current.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'smooth'
      });
  };

  const [filesData, setFilesData] = useState([]);
  useEffect(() => {
    setFilesData(flattenFooterData(footerData, category));
    scrollToBottom();
  }, [footerData, category]);

  return (
    <Container className="py-4">
      {Object.keys(filesData).map(categoryKey => {
        const tableData =
          filesData[categoryKey] && filesData[categoryKey].dataList;
        const categoryTitle =
          filesData[categoryKey] && filesData[categoryKey].categoryTitle;
        return (
          <div key={`table-${categoryKey}`}>
            <h3 className="text-primary">{categoryTitle || 'Downloads'}</h3>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t('front:filename')}</th>
                  <th>{t('front:version')}</th>
                  <th>{t('front:size')}</th>
                  <th>{t('project:description')}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((link, index) => {
                  const rowSpanCount =
                    index > 0 && tableData[index - 1].title === link.title
                      ? null
                      : tableData.filter(link => {
                          return link.title === tableData[index].title;
                        }).length;
                  const documentType = link.documentType;
                  return (
                    <tr
                      className={`${
                        link.title === hash.slice(1) ? 'bg-light' : null
                      }`}
                      ref={
                        link.title === hash.slice(1) ? highlightedRowRef : null
                      }
                      key={`link-row-${index}`}>
                      <td>
                        {link.isFileExist ? (
                          <Button
                            tag="a"
                            color="link"
                            className={'px-0 text-left'}
                            download
                            href={`${
                              process.env.REACT_APP_API_ENDPOINT[
                                process.env.REACT_APP_API_ENDPOINT.length - 1
                              ] == '/'
                                ? process.env.REACT_APP_API_ENDPOINT.slice(
                                    0,
                                    -1
                                  )
                                : process.env.REACT_APP_API_ENDPOINT
                            }/api/footer_documents/${link.filename}${
                              documentType
                                ? `?documentType=${documentType}`
                                : ''
                            }`}>
                            <span
                              className={`${
                                link.title === hash.slice(1)
                                  ? 'bg-warning rounded px-2'
                                  : null
                              }`}>
                              {t(`front:${link.filename}`)}
                            </span>
                          </Button>
                        ) : (
                          <span className="text-muted">
                            {link.filename}{' '}
                            <span className="text-danger">
                              ({t('front:notAvailable')})
                            </span>
                          </span>
                        )}
                      </td>
                      <td>{link.version || t('front:notApplicable')}</td>
                      <td>
                        {typeof link.size === 'number'
                          ? bytesToSize(link.size)
                          : t('front:notApplicable')}
                      </td>
                      {index > 0 &&
                      tableData[index - 1].title === link.title ? null : (
                        <td rowSpan={rowSpanCount}>{link.description}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        );
      })}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    footerData: state.app.footerData.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFooter: () => dispatch(fetchFooterData())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['front'])
)(TableLinks);
