import React from 'react';
import pageNotFound from '../../../assets/images/undraw_not_found_60pq.png';
import { withTranslation } from 'react-i18next';
import styles from './Styles.module.css';

const PageNotFound = props => {
  const { t } = props;
  return (
    <div className={`text-center ${styles.PNFDivParent}`}>
      <img
        src={pageNotFound}
        className={`text-center ${styles.PNFImg}`}
        alt="404"
        width="300"
        height="300"></img>

      <p className={styles.PNFText}>
        {t(`error:page_not_found`)}
      </p>
    </div>
  );
};
export default withTranslation(['project', 'main', 'error'])(PageNotFound);
