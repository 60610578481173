import React, { Component, useState } from 'react';
import {
  Toast,
  ToastBody,
  ToastHeader,
  Table,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from 'reactstrap';
import path from 'path';
import { dateFormatting } from '../../../../locales/dateFormat';
import { timeAgoFormatting } from '../../../../locales/dateFormat';
import classnames from 'classnames';
const Details = props => {
  const {
    filteredDocuments,
    activeItemToDisplay,
    displayItemDetails,
    showTextDescription,
    handleSaveDescription,
    toogleTextDescription,
    disabled,
    t
  } = props;

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let icon_error = 'far fa-times-circle text-danger';
  let icon_check = 'far fa-check-circle text-success';

  return (
    <>
      {filteredDocuments.length === 0 &&
        displayItemDetails &&
        activeItemToDisplay && (
          <div>
            <h6 className="text-primary">
              {t('reference_documents:document_info')}
            </h6>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}>
                  <i className="fas fa-info-circle mr-2 text-info"></i>
                  {t('reference_documents:details')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}>
                  <i className="fas fa-user-lock mr-2 text-info"></i>
                  {t('reference_documents:permission')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Card body>
                      <Table borderless size="sm" responsive>
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              {' '}
                              {t('reference_documents:name')}
                            </th>
                            <td>{activeItemToDisplay.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {' '}
                              {t('reference_documents:type')}
                            </th>
                            <td>
                              {path.extname(
                                activeItemToDisplay.key ||
                                  activeItemToDisplay.base_url_to_display_in_file_explorer
                              ) || 'Folder'}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {' '}
                              {t('reference_documents:date_created')}
                            </th>
                            <td>
                              {dateFormatting(
                                activeItemToDisplay.date_uploaded
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {t('reference_documents:file_origin')}
                            </th>
                            <td>
                              {t(
                                `reference_documents:${activeItemToDisplay.origin_of_the_file}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {t('reference_documents:last_modified')}
                            </th>
                            <td>
                              {timeAgoFormatting(
                                activeItemToDisplay.date_updated ||
                                  activeItemToDisplay.date_uploaded
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th> {t('reference_documents:description')}</th>
                            <td>
                              <div
                                className="pointer mr-2"
                                onClick={() => toogleTextDescription(true)}>
                                <i className="far fa-edit fs-1-2x text-info" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <td>
                              {!showTextDescription && (
                                <div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: activeItemToDisplay.description
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card>

                    {showTextDescription && (
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        autoFocus
                        onBlur={e => {
                          handleSaveDescription(
                            activeItemToDisplay.id,
                            e.target.value
                          );
                        }}
                        defaultValue={activeItemToDisplay.description}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <Card body>
                      <Table size="sm" responsive hover>
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">View</th>
                            <td>
                              <i
                                className={
                                  activeItemToDisplay &&
                                  activeItemToDisplay.allowedActions &&
                                  activeItemToDisplay.allowedActions.includes(
                                    'READ'
                                  )
                                    ? icon_check
                                    : icon_error
                                }></i>
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Modify</th>
                            <td>
                              <i
                                className={
                                  activeItemToDisplay &&
                                  activeItemToDisplay.allowedActions &&
                                  activeItemToDisplay.allowedActions.includes(
                                    'UPDATE'
                                  )
                                    ? icon_check
                                    : icon_error
                                }></i>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Delete</th>
                            <td>
                              <i
                                className={
                                  activeItemToDisplay &&
                                  activeItemToDisplay.allowedActions &&
                                  activeItemToDisplay.allowedActions.includes(
                                    'DELETE'
                                  )
                                    ? icon_check
                                    : icon_error
                                }></i>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        )}
    </>
  );
};

export default Details;
