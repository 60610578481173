import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
// import PropTypes from 'prop-types';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import './Style.css';

import pathModule from 'path';

// const MAX_UPLOAD_FILE = 2143648;

class UploadBox extends Component {
  constructor(props) {
    super(props);
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCancel = () => {
    this.setState({
      files: []
    });
  };

  setFormikValue = async files => {
    const { setFieldValue } = this.props.formikProps;

    const details = files.map(file => {
      return {
        file,
        name: file.name,
        size: file.size,
        data: file.date
      };
    });

    setFieldValue('files', details);
  };

  render() {
    const { t, formikProps } = this.props;

    return (
      <>
        <div className="dropzone bg-light dropdownCursor" >
          <Dropzone
            multiple={false}
            className="p-4 d-flex flex-column justify-content-center align-items-center dropzoneStyle"
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.setFormikValue}>
            <h2>
              <i className="fas fa-upload " />
            </h2>
            <div className="text-center">Upload Document File</div>
          </Dropzone>
        </div>
      </>
    );
  }
}

export default withTranslation(['project', 'main'])(UploadBox);
