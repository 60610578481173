import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';

const Projects = ({ projects, t }) => {
  return (
    <div>
      <ListGroupItem
        // disabled
        // color="secondary"
        className="bg-light justify-content-between font-weight-bold">
        <Row>
          <Col md={6}>{t('project_name')}</Col>
          <Col md={6}>{t('role')}</Col>
        </Row>
      </ListGroupItem>
      {projects.map((project, key) => {
        return (
          <ListGroupItem className="justify-content-between" key={key}>
            <Row>
              <Col md={6}>{project.name}</Col>
              <Col md={6}>{project.role}</Col>
            </Row>
          </ListGroupItem>
        );
      })}
    </div>
  );
};

export default withTranslation(['admin_user'])(Projects);
