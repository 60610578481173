import React, { Component } from 'react';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import './Style.css';

import { timeAgoFormatting } from '../../../../../locales/dateFormat';

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  for (var i = 0; i < sizes.length; i++) {
    if (bytes <= 1024) {
      return `${bytes}${sizes[i]}`;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return `${bytes}P`;
};

class ModelList extends Component {
  constructor(props) {
    super(props);
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t, formikProps } = this.props;

    const uploaded = formikProps.values.files;
    const {setValues, values} = formikProps

    return (
      <>
        <Row>
          <Col xs={12} sm={12} className={'order-first'}>
            <ListGroup className="border-bottom">
              {/* // header */}
              <ListGroupItem className="font-weight-bold shadow-sm text-primary zIndexUploadedFiles" >
                {t('admin_footer_management:details')}
              </ListGroupItem>

              <div className="uploadedFiles">
                {uploaded.length > 0 &&
                  uploaded.map((f, index) => {
                    return (
                      <ListGroupItem key={index} className="shadow-sm">
                        <Row className="d-flex justify-content-between px-2 ">
                          <div className="d-flex flex-column justify-content-between">
                            <div>
                              {f.name} - {bytesToSize(f.size)}
                            </div>

                            {f.date && (
                              <div className="text-secondary">
                                last updated: {timeAgoFormatting(f.date)} ago
                              </div>
                            )}
                          </div>
                          <Button
                            color="link"
                            id={`deleteFile-${index}`}
                            className="pointer mr-2 p-0"
                            onClick={() => {
                              setValues({...values, files: values.files.filter((nf, i) => i !== index)});
                            }}>
                            <i className={`fas fa-trash-alt fs-1-2x text-danger`} />
                          </Button>
                          <UncontrolledTooltip
                            fade={false}
                            boundariesElement="window"
                            target={`deleteFile-${index}`}>
                            {t('delete')}
                          </UncontrolledTooltip>
                     
                        </Row>
                      </ListGroupItem>
                    );
                  })}
              </div>
            </ListGroup>
          </Col>
        </Row>
      </>
    );
  }
}

// ModelList.propTypes = {

// }

export default withTranslation(['project', 'main'])(ModelList);
// export default ModelList;
