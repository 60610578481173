import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Message from '../UI/Message';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isAuthorized: false,
      redirectPath: props.redirectPath || '/login'
    };
  }
  componentDidMount() {
    this.props
      .accessFunction()
      .then(result => {
        if (!result) {
          this.displayMessage(this.props.messageText);
        }
        this.setState({ loaded: true, isAuthorized: result });
      })
      .catch(err => {
        this.displayMessage(this.props.t('server_error'));
        this.setState({ loaded: true, isAuthorized: false, redirectPath: '/' });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.props
        .accessFunction()
        .then(result => {
          if (!result) {
            this.displayMessage(this.props.messageText);
          }
          this.setState({ loaded: true, isAuthorized: result });
        })
        .catch(err => {
          this.displayMessage(this.props.t('server_error'));
          this.setState({
            loaded: true,
            isAuthorized: false,
            redirectPath: '/'
          });
        });
    }
  }

  displayMessage = messageText => {
    Message.error(messageText || this.props.t('must_be_loggedin'));
  };

  render() {
    const { component: Component, ...rest } = this.props;

    const { loaded, isAuthorized, redirectPath } = this.state;
    return (
      <>
        {loaded && (
          <Route {...rest}>
            {props =>
              isAuthorized ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: redirectPath,
                    state: { from: props.location }
                  }}
                />
              )
            }
          </Route>
        )}
      </>
    );
  }
}

export default withTranslation('error')(PrivateRoute);
