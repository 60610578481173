import React, { Component } from 'react';
import {
  Container,
  ListGroup,
  ListGroupItem,
  Collapse,
  Badge
} from 'reactstrap';

import Projects from './Projects';
import Members from './Members';
import Partners from './Partners';
import BasicInfo from './BasicInfo';

import UserProjectListing from './UserProjectListing';
import UserUsagePresenter from './UserUsagePresenter';
import UserGroupsPresenter from './../../EditProfile/UserGroupsPresenter';

class UserDetailPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toggleBasicInfo: true,
      toggleProject: false,
      toggleMember: false,
      toggleProjectPartner: false,
      toogleProjectList: true,
      toogleUserUsage: true,
      toogleUserGroupList: true
    };
  }

  componentDidMount() {
    this.props.fetchUserAsAdmin(this.props.match.params.id).then(() => {
      this.setState({
        isLoaded: true
      });
    });
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  handleToggle = e => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  handleToggleProjectList = e => {
    this.setState({ toogleProjectList: !this.state.toogleProjectList });
  };

  handleToggleUserGroupList = e => {
    this.setState({ toogleUserGroupList: !this.state.toogleUserGroupList });
  };

  render() {
    const { t, userData, isAccessControlV2Enabled } = this.props;

    const {
      isLoaded,
      toggleBasicInfo,
      toggleProject,
      toggleMember,
      toggleProjectPartner,
      toogleProjectList,
      toogleUserUsage,
      toogleUserGroupList
    } = this.state;
    return (
      <Container className="shadow mt-4 p-3 mb-5 bg-light rounded">
        <div className="mt-2">
          <h3 className="text-primary">{t('admin_user:user_detail')}</h3>
        </div>
        <div className="bg-light py-3 px-2">
          {isLoaded && (
            <Container>
              <ListGroup>
                <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    onClick={this.handleToggle}
                    id="toggleBasicInfo">
                    {t('basic_info')} <i className="fas fa-chevron-down" />
                  </ListGroupItem>
                  <Collapse isOpen={toggleBasicInfo}>
                    <BasicInfo {...this.props} userData={userData.user} />
                  </Collapse>
                </div>
                {/*  TODO  */}
                {/* <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    onClick={this.handleToggle}
                    id="toggleProject">
                    {t('projects_related')}{' '}
                    <i className="fas fa-chevron-down" />
                  </ListGroupItem>
                  <Collapse isOpen={toggleProject}>
                    <Projects projects={userData.projects} />
                  </Collapse>
                </div>
                <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    onClick={this.handleToggle}
                    id="toggleMember">
                    {t('company_member')} <i className="fas fa-chevron-down" />
                  </ListGroupItem>
                  <Collapse isOpen={toggleMember}>
                    <Members />
                  </Collapse>
                </div>
                <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    id="toggleProjectPartner">
                    {t('project_partners')}{' '}
                    <i className="fas fa-chevron-down" />
                  </ListGroupItem>
                  <Collapse isOpen={toggleProjectPartner}>
                    <Partners />
                  </Collapse>
                </div> */}
              </ListGroup>

              <ListGroup>
                <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    onClick={this.handleToggleProjectList}
                    id="projectList">
                    {t('project_listing')}
                    <Badge pill className="mr-4">
                      {(userData.projects &&
                        userData.projects.projectList &&
                        userData.projects.projectList.length) ||
                        0}
                    </Badge>{' '}
                    <i className="fas fa-chevron-down" />
                  </ListGroupItem>
                  <Collapse isOpen={toogleProjectList}>
                    <UserProjectListing
                      {...this.props}
                      projectList={userData.projects}
                      userId={userData.user.user_id}
                    />
                  </Collapse>
                </div>
              </ListGroup>

              {/* USER USAGE */}
              <ListGroup>
                <div className="mb-3">
                  <ListGroupItem
                    active
                    tag="button"
                    action
                    color="primary"
                    onClick={this.handleToggle}
                    id="toogleUserUsage">
                    {t('user_usage')}
                    <i className="fas fa-chevron-down px-2" />
                  </ListGroupItem>
                  <Collapse isOpen={toogleUserUsage}>
                    <UserUsagePresenter
                      {...this.props}
                      userId={userData.user.user_id}
                    />
                  </Collapse>
                </div>
              </ListGroup>
              {isAccessControlV2Enabled && (
                <ListGroup>
                  <div className="mb-3">
                    <ListGroupItem
                      active
                      tag="button"
                      action
                      color="primary"
                      onClick={this.handleToggleUserGroupList}
                      id="userGroupList">
                      {t('userPermission:user_groups')}
                      <i className="fas fa-chevron-down ml-4" />
                    </ListGroupItem>
                    <Collapse isOpen={toogleUserGroupList}>
                      <UserGroupsPresenter {...this.props} />
                    </Collapse>
                  </div>
                </ListGroup>
              )}
            </Container>
          )}
        </div>
      </Container>
    );
  }
}

export default UserDetailPresenter;
