import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import { compose } from 'recompose';

const socketUrl =
  process.env.REACT_APP_SOCKETIO_ENDPOINT || process.env.REACT_APP_API_ENDPOINT;
const path = process.env.REACT_APP_SOCKETIO_PATH || '/api/socket.io';

const SocketIOContext = React.createContext(null);
SocketIOContext.displayName = 'SocketIOContext';

const socketIoWrapper = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { events: [] };
    }
    componentWillUnmount() {
      // unsubscribe to events
      this.state.events.map(({ eventName, handler }) => {
        try {
          this.props.socket.off(eventName, handler);
        } catch (err) {
          console.log(`failed to stop listening to ${eventName}`);
        }
      });
    }

    subscribeToEvents = eventWithHandler => {
      const nextEventState = this.state.events;
      const socket = this.props.socket;

      eventWithHandler.map(({ eventName, handler }) => {
        const handlerWrapper = (...args) => {
          handler(...args);
        };
        try {
          const eventFromStateIndex = this.state.events.findIndex(e => {
            return e.eventName === eventName;
          });
          const eventFromState = this.state.events[eventFromStateIndex];
          if (eventFromState) {
            socket.off(eventName, eventFromState.handler);
            nextEventState.splice(eventFromStateIndex, 1);
          }
        } catch (err) {
          console.log(`Error subscribing to ${eventName}`);
        }
        socket.on(eventName, handlerWrapper);
        nextEventState.push({ eventName, handler: handlerWrapper });
      });
      this.setState({ events: nextEventState });
    };

    disconnect = () => {
      this.props.socket.disconnect();
    };

    render() {
      return (
        <WrappedComponent
          subscribeToEvents={this.subscribeToEvents}
          disconnectSocketIO={this.disconnect}
          {...this.props}
        />
      );
    }
  };
};

const withSocketIOContext = WrappedComponent => props =>
  (
    <SocketIOContext.Consumer>
      {value => <WrappedComponent socket={value} {...props} />}
    </SocketIOContext.Consumer>
  );

export const withSocketIO = compose(withSocketIOContext, socketIoWrapper);

export const SocketIoProvider = WrappedComponent =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.socket = {};
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.isAuthenticated !== prevProps.isAuthenticated ||
        this.props.authUser.userId !== prevProps.authUser.userId
      ) {
        if (!this.socket.connected) {
          const socket = new io(socketUrl, {
            path,
            reconnection: true,
            autoConnect: true,
            query: { userId: this.props.authUser.userId }
          });
          // socket.on('connect', () => {});
          this.socket = socket;
        }
      }
    }

    componentWillUnmount() {
      this.socket.close();
    }

    render() {
      const socket = this.socket;
      const {
        isAuthenticated,
        authUser: { usertId }
      } = this.props;
      const isSocketConnected = socket && socket.connected;
      return (
        <SocketIOContext.Provider value={socket}>
          <WrappedComponent {...this.props} />
        </SocketIOContext.Provider>
      );
    }
  };
