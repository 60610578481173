import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse
} from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  updateTilePreference,
  updateTileOrder,
  fetchAllUserPreference
} from '../../../store/actions/user_preference';

const DashboardCell = ({
  children,
  size,
  showing = true,
  defaultOrder,
  collapsible = true,
  resizable = true,
  tileName,
  title,
  tileLength,
  currentTile,
  actions,
  updateTilePreference,
  tiles,
  isMounted,
  updateTileOrder,
  hasTutoLink,
  tutoLinkLabel,
  tutoLinkTo
}) => {
  const [collapsed, setCollapsed] = useState(currentTile.collapsed || false);
  const [shown, setShown] = useState(showing);
  const [updatedSize, setUpdatedSize] = useState(currentTile.tileSize || size);
  const [order, setOrder] = useState(
    currentTile.order >= 0 ? currentTile.order : defaultOrder
  );

  useEffect(() => {
    (async () => {
      try {
        if (
          collapsed !== currentTile.collapsed ||
          currentTile.shown !== shown ||
          currentTile.tileSize !== updatedSize
        ) {
          await updateTilePreference({
            tileName,
            updatedSize,
            collapsed
          });
        }
        if (currentTile.order !== order) {
          await updateTileOrder({
            order,
            tileName
          });
        }
      } catch (err) {
        if (err.name !== 'request_canceled') {
          throw err;
        }
      }
    })();
  }, [collapsed, shown, updatedSize, order]);

  useEffect(() => {
    if (currentTile.order >= 0 && order !== currentTile.order) {
      setOrder(currentTile.order);
    }
  }, [currentTile.order]);

  const actionList = actions || [];
  if (actions && actions.length > 0 && (resizable || collapsible)) {
    actionList.push({ isDivider: true });
  }
  // ====================== RESIZING ACTIONS =======================
  if (resizable) {
    actionList.push({
      label: 'Resize',
      children: [
        {
          label: 'Full',
          action: () => {
            setUpdatedSize('full');
          }
        },
        {
          label: 'Large',
          action: () => {
            setUpdatedSize('threeQuarter');
          }
        },
        {
          label: 'Medium',
          action: () => {
            setUpdatedSize('half');
          }
        },
        {
          label: 'Small',
          action: () => {
            setUpdatedSize('quarter');
          }
        }
      ]
    });
  }
  // ====================== POSITIONING ACTIONS ======================
  if (defaultOrder >= 0) {
    actionList.push({
      label: 'Order',
      children: Array.apply(null, new Array(tileLength))
        .map((t, i) => {
          return {
            label: i,
            action: () => {
              setOrder(i);
            }
          };
        })
        .filter(({ label }) => label !== order)
    });
  }

  if (collapsible && collapsed) {
    actionList.push({
      label: 'Show content',
      action: () => {
        setCollapsed(false);
      }
    });
  }
  if (collapsible && !collapsed) {
    actionList.push({
      label: 'Hide content',
      action: () => {
        setCollapsed(true);
      }
    });
  }

  return (
    <>
      <Col
        lg={{
          size:
            { full: 12, half: 6, quarter: 3, threeQuarter: 9 }[updatedSize] ||
            6,
          order
        }}
        md={{
          size:
            { full: 12, half: 6, quarter: 3, threeQuarter: 9 }[updatedSize] ||
            6,
          order
        }}
        sm={{
          size:
            { full: 12, half: 6, quarter: 6, threeQuarter: 6 }[updatedSize] ||
            6,
          order
        }}
        xs={{
          size:
            { full: 12, half: 12, quarter: 12, threeQuarter: 12 }[
              updatedSize
            ] || 6,
          order
        }}
        className="p-3">
        <div className="shadow bg-light rounded flex-grow-0 p-3">
          <div className="d-flex justify-content-between">
            <h5 className="text-primary">{title}</h5>
            <div className="d-flex">
              {hasTutoLink && (
                <div className="pt-2">
                  <Link to={tutoLinkTo}>
                    {tutoLinkLabel} <i className="fas fa-video"></i>
                  </Link>
                </div>
              )}
              <UncontrolledDropdown direction="down">
                <DropdownToggle tag="div" className="nav-link pointer">
                  <i className="fas fa-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  {actionList.map((a, i) => {
                    if (a.isDivider) {
                      return (
                        <DropdownItem key={`${tileName}-action-${i}`} divider />
                      );
                    }
                    if (a.children) {
                      return (
                        <DropdownItem
                          key={`${tileName}-action-${i}`}
                          tag="div"
                          toggle={false}>
                          <UncontrolledDropdown direction="right">
                            <DropdownToggle tag="div" className="pointer">
                              <div className="d-flex justify-content-between align-content-center">
                                {a.label}
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>
                              </div>
                            </DropdownToggle>
                            <DropdownMenu>
                              {a.children.map((child, childIndex) => {
                                return (
                                  <DropdownItem
                                    key={`${tileName}-action-${i}-${childIndex}`}
                                    onClick={child.action}>
                                    {child.label}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DropdownItem>
                      );
                    } else {
                      return (
                        <DropdownItem
                          key={`${tileName}-action-${i}`}
                          onClick={a.action}>
                          {a.label}
                        </DropdownItem>
                      );
                    }
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <hr />
          <Collapse isOpen={!collapsed}>
            <Container fluid className="p-0">
              {children}
            </Container>
          </Collapse>
        </div>
      </Col>
    </>
  );
};

const mapStateToProps = (state, { tileName }) => {
  const tiles = state.userPreference.dashboard_defaults.tiles;
  return {
    tiles,
    currentTile: tiles && tileName && tiles[tileName] ? tiles[tileName] : {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTilePreference: data => dispatch(updateTilePreference(data)),
    updateTileOrder: data => dispatch(updateTileOrder(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCell);
