// temporary store for the model file management
import { model2DFilesActionType } from '../../constants/actionType';
import { merge, union, unionBy, has } from 'lodash';

const initialState = [];

const {
  RESET_2DMODELFILE,
  ADD_2DMODELFILE,
  REPLACE_2DMODELFILE,
  REMOVE_2DMODELFILE,
  DELETE_2DMODELFILE,
  MERGE_ALL_2DMODELFILE,
  SET_MODEL_PLAN_TYPE
} = model2DFilesActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_2DMODELFILE:
      return [...state, action.model];
    case REPLACE_2DMODELFILE:
      return [
        ...state.filter(model => model.name !== action.model.name),
        action.model
      ];
    // "SOFT" delete the file => tag removed =true
    case REMOVE_2DMODELFILE: {
      const modelIndex = state.findIndex(
        model => model.name === action.model.name
      );

      return [
        ...state.slice(0, modelIndex),
        { ...state[modelIndex], removed: true },
        ...state.slice(modelIndex + 1)
      ];
    }
    // Remove the file from the store
    case DELETE_2DMODELFILE: {
      const modelIndex = state.findIndex(
        model => model.name === action.model.name
      );
      return [...state.slice(0, modelIndex), ...state.slice(modelIndex + 1)];
    }
    case RESET_2DMODELFILE:
      return initialState;

    case MERGE_ALL_2DMODELFILE: {
      return unionBy(action.models, state, 'name');
    }

    case SET_MODEL_PLAN_TYPE: {
      const updatedItems = state.map(model =>
        model.name === action.model.name ? action.model : model
      );

      return updatedItems;
    }

    default:
      return state;
  }
};

export default reducer;
