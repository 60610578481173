import { projectStageResultActionType } from '../../constants/actionType';
const initialState = {};

const { FETCH_STAGE_JOBS } = projectStageResultActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAGE_JOBS: {
      const { projectId, stageName, jobType, stageData } = action;

      const previousStages = state[projectId] ? state[projectId].stages : {};

      const previousStagesJobs =
        (previousStages[stageName] && previousStages[stageName].jobs) || [];
      return {
        ...state,
        [projectId]: {
          stages: {
            [stageName]: {
              ...previousStages[stageName],
              ...(jobType === 'comparison'
                ? { comparisons: (jobType === 'comparison' && stageData) || [] }
                : {
                    jobs: {
                      ...(previousStagesJobs || []),
                      [jobType]: stageData
                    }
                  })
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;
