import React from 'react';
import { withTranslation } from 'react-i18next';
import { ListGroupItem, Row, Col } from 'reactstrap';

const Partner = ({ t }) => {
  return (
    <div>
      <ListGroupItem className="justify-content-between">
        <Row>
          <Col md={6} className="font-weight-bold">
            {t('email')}
          </Col>
          <Col md={6}>Email here</Col>
        </Row>
      </ListGroupItem>
    </div>
  );
};
export default withTranslation(['admin_user'])(Partner);
