import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import styles from './ContactUs.module.css';
import content from './content.json';

const ContactUs = () => {
  const email = content.email;
  const call = content.call.map((contact, key) => {
    return (
      <div key={key}>
        <h4 className="mt-5">{contact.subtitle}</h4>
        <p className={`${styles.textSize} my-2`}>{contact.contactNumber}</p>
      </div>
    );
  });
  const location = content.location.map((location, key) => {
    return (
      <div key={key}>
        <h4 className="mt-5">{location.subtitle}</h4>
        <p className={`${styles.textSize} my-2`}>{location.address}</p>
      </div>
    );
  });

  return (
    <Container>
      <h1 className="text-center my-5">Contact Us</h1>
      <Row>
        {call.length > 0 && (
          <Col xs="12" md="6" className="my-5">
            <div className="d-flex flex-column">
              <div>
                <span
                  className={`border border-primary rounded p-4 ${styles.fontSize}`}>
                  <i className="fas fa-phone text-primary" />
                </span>
              </div>
              {call}
            </div>
          </Col>
        )}
        {/* {location.length > 0 && (
          <Col xs="12"   className="text-center my-5">
            <span className={styles.icon}>
              <i className="fas fa-map-marked-alt" />
            </span>
            {location}
          </Col>
        )} */}
        <Col xs="12" md="6" className="my-5">
          <div className="d-flex flex-column">
            <div>
              <span
                className={`border border-primary rounded p-4 ${styles.fontSize}`}>
                <i className="far fa-comments text-primary" />
              </span>
            </div>
            <div className="d-flex flex-column">
              <h4 className="mt-5">Send us an enquiry</h4>
              <p className={`${styles.textSize} my-2`}>
                Your feedback on our service quality is important to us. For
                service-related matters, please email us at{' '}
                <span className="text-primary font-weight-bold font-italic">
                  {email}
                </span>
                .
              </p>
              <p className={`${styles.textSize} my-2`}>
                You can also submit your feedback via the online form{' '}
                <Link to="/feedback">
                  <strong className="text-primary font-weight-bold">
                    here
                  </strong>
                </Link>
                .
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
