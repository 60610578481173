import { element } from 'prop-types';
import React from 'react';
import { Button, Badge } from 'reactstrap';
import NewsModal from './News/NewsModal';
import styles from './styles.module.css';



const FixedButtons = ({
  newsModalIsOpened,
  setnewsModelIsOpened,
  fetchAllNews,
  resetNews,
  timeAgoFormatting
}) => {
  const buttonsList = [
    {
      name: 'news',
      label: 'news',
      icon: 'fas fa-newspaper',
      // color: '#5b9ad2',
      isLink: false,
      onClick: () => {
        setnewsModelIsOpened(!newsModalIsOpened);
      }
    }
  ];
  return(
    <div className="landing-page-fixed-buttons-container">
    <NewsModal
      timeAgoFormatting={timeAgoFormatting}
      fetchAllNews={fetchAllNews}
      resetNews={resetNews}
      newsModalIsOpened={newsModalIsOpened}
      setnewsModelIsOpened={setnewsModelIsOpened}
    />
    {buttonsList.map(btn => {
      return (
        <>
          {!btn.isLink ? (
            <div>
              <Badge color="warning" className={`${styles.newsButtonBadge}`} pill>
                1
              </Badge>
              <Button
                onClick={btn.onClick}
                size="lg"
                className={`${styles.newsButton} rounded-0 landing-page-fixed-button w-100 border-0 `}>
                <i className={`${btn.icon}`} />
              </Button>
            </div>
          ) : (
            <div>
              <a href={btn.href || '#'} target="_blank" className="w-100">
                <Button
                  size="lg"
                  className={`${styles.newsButton} rounded-0 landing-page-fixed-button w-100 border-0 `}>
                  <i className={`${btn.icon}`} />
                </Button>
              </a>
            </div>
          )}
        </>
      );
    })}
  </div>
  );
  // return (
  //   <div className="landing-page-fixed-buttons-container">
  //     <NewsModal
  //       timeAgoFormatting={timeAgoFormatting}
  //       fetchAllNews={fetchAllNews}
  //       resetNews={resetNews}
  //       newsModalIsOpened={newsModalIsOpened}
  //       setnewsModelIsOpened={setnewsModelIsOpened}
  //     />
  //     {buttonsList.map(btn => {
  //       return (
  //         <>
  //           {!btn.isLink ? (
  //             <div>
  //               <Badge color="warning" className={`${styles.newsButtonBadge}`} pill>
  //                 1
  //               </Badge>
  //               <Button
  //                 onClick={btn.onClick}
  //                 size="lg"
  //                 className={`${styles.newsButton} rounded-0 landing-page-fixed-button w-100 border-0 `}>
  //                 <i className={`${btn.icon}`} />
  //               </Button>
  //             </div>
  //           ) : (
  //             <div>
  //               <a href={btn.href || '#'} target="_blank" className="w-100">
  //                 <Button
  //                   size="lg"
  //                   className={`${styles.newsButton} rounded-0 landing-page-fixed-button w-100 border-0 `}>
  //                   <i className={`${btn.icon}`} />
  //                 </Button>
  //               </a>
  //             </div>
  //           )}
  //         </>
  //       );
  //     })}
  //   </div>
  // );
};

export default FixedButtons;
