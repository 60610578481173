import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import path from 'path';
import styles from './fileExplorer.module.css';
const BreadCrumbComponent = props => {
  let { item, activeItem } = props;
  const baseFolder = 'Documents';
  const activePath = path.basename(item);
  return (
    <div>
      <Breadcrumb>
        {item && item.length > 0 ? (
          `${baseFolder}/${item}`.split('/').map((val, index) => {
            const itemKey = `${baseFolder}/${item}`
              .split('/')
              .slice(0, index + 1)
              .join('/');
            return activePath === val ? (
              <BreadcrumbItem
                active
                className={`pointer ${styles.breadcrumbFonts}`}
                onClick={e => {
                  if (index > 0) {
                    // prevent reset selection
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  props.setSelectedKey(itemKey);
                  props.createBreadCrumbItem(itemKey.split('/').slice(1));
                  props.setActiveDirectoryToUpload(itemKey);
                  props.setActiveItem();
                }}
                key={`${index}-${val}-key`}>
                {val}
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem
                className="pointer"
                onClick={e => {
                  if (index > 0) {
                    // prevent reset selection
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  props.setSelectedKey(itemKey);
                  props.createBreadCrumbItem(itemKey.split('/').slice(1));
                  props.setActiveDirectoryToUpload(itemKey);
                  props.setActiveItem();
                }}
                key={`${val}-key`}>
                <a>{val}</a>
              </BreadcrumbItem>
            );
          })
        ) : (
          <BreadcrumbItem
            active
            className={`pointer ${styles.breadcrumbFonts}`}
            // onClick={e => {
            //   // e.preventDefault();
            //   // e.stopPropagation();
            //   props.setSelectedKey(itemKey);
            //   props.createBreadCrumbItem(itemKey.split('/').slice(1));
            //   props.setActiveDirectoryToUpload(itemKey);
            //   props.setActiveItem();
            // }}
          >{`${baseFolder} /`}</BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbComponent;
