import { performRequest, handleErrorMessageStatus } from '../requests';
import {
  rulePackageUpdateActionType,
  appActionType
} from '../../constants/actionType';

const { UPLOAD_ZIP_FILE, ADD_ZIP_FILE, REPLACE_ZIP_FILE, MERGE_PACKAGE } =
  rulePackageUpdateActionType;

const { MERGE_REPORT_TEMPLATE_LIST, ADD_FILE_TO_CUSTOM_REPORT_TEMPLATE } =
  appActionType;
export const uploadZIPFile =
  (ruleTreeType, file, onUplodaProgress) => (dispatch, getState) => {};

export const addZipFile = file => (dispatch, getState) => {
  if (getState().reference_documents.find(m => m.name === file.name)) {
    dispatch({
      type: REPLACE_ZIP_FILE,
      document: { ...file, progress: 0, dirty: true, removed: false }
    });
  } else {
    dispatch({
      type: ADD_ZIP_FILE,
      document: { ...file, dirty: true }
    });
  }
  return;
};

export const replaceZipFile = file => (dispatch, getState) => {
  dispatch({
    type: REPLACE_ZIP_FILE,
    document: { ...file, progress: 0, dirty: true, removed: false }
  });
};
export const setNewZipFile = (newFiles, existingFiles) => dispatch => {
  for (let i in existingFiles) {
    const fileToReplace = existingFiles[i];
    const { file, id } = fileToReplace;
    // replace in redux store

    dispatch(
      replaceZipFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }

  for (let index in newFiles) {
    const file = newFiles[index];
    // push model to the redux store
    dispatch(
      addZipFile({
        name: file.name,
        size: file.size,
        fileInput: file
      })
    );
  }
};

export const createRulePackage =
  (selectedRulePackageTreeId, data, actionType) => dispatch => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        actionType === 'create'
          ? `/api/rule_package/domain/createNewPackage`
          : `/api/rule_package/domain/${selectedRulePackageTreeId}/updateCurrentTree`,
        { ...data },
        requestConfig,
        ''
      )
    )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          return data.data;
        } else {
          throw new Error('default_message');
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const addZipFileToPackge =
  (rulePackageId, file, onUploadProgress) => dispatch => {
    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress,
      params: {
        totalSizeAdded: file.size
      }
    };
    const formData = new FormData();
    formData.append('shouldRunDiagnosticTest', true);
    formData.append('zip_file', file);
    return dispatch(
      performRequest(
        'post',
        `/api/rule_package/domain/${rulePackageId}/addZipFileToPackge`,
        formData,
        requestConfig,
        'UPLOAD_ZIP_FILE'
      )
    )
      .then(res => res.data)
      .then(({ success, data, error }) => {
        if (success) {
          return data;
        } else {
          throw new Error(error || 'default_message');
        }
      })
      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };

export const getPendingPackage = ruleTreeType => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/rule_package/domain/${ruleTreeType}/pending_package`,
      {},
      requestConfig,
      'pendingPackage'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        dispatch({
          type: MERGE_PACKAGE,
          rulePackageList: data
        });
        return data;
      } else {
        throw new Error('default_message');
      }
    });
};
export const generateNextRuleTree =
  ({ shouldRunDiagnosticTest, rulePackageId, isRemoveOriginalRules }) =>
  dispatch => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `/api/rule_package/domain/generateNextRuleTree`,
        { shouldRunDiagnosticTest, rulePackageId, isRemoveOriginalRules },
        requestConfig,
        'pendingPackage'
      )
    )
      .then(res => res.data)
      .then(({ success, data }) => {
        if (success) {
          console.log(data);
          return data;
        } else {
          throw new Error('default_message');
        }
      })
      .catch(err => {
        console.log({ err });
        handleErrorMessageStatus(err);
      });
  };

export const getPackageDetails = rulePackageId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/rule_package/domain/package/${rulePackageId}/details`,
      {},
      requestConfig,
      'pendingPackage'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        console.log(data);
        dispatch({
          type: MERGE_PACKAGE,
          rulePackageList: data
        });
        return data;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      console.log({ err });
      handleErrorMessageStatus(err);
    });
};

export const savePackage = rulePackageId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/rule_package/domain/savePackage`,
      { rulePackageId },
      requestConfig,
      'pendingPackage'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        return data;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      console.log({ err });
      handleErrorMessageStatus(err);
    });
};

export const fetchPackages = filters => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/rule_package/domain/package/list`,
      { filters },
      requestConfig,
      'FETCH_PACKAGE_LIST'
    )
  )
    .then(res => res.data)
    .then(({ success, data }) => {
      if (success) {
        return data;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      console.log({ err });
      handleErrorMessageStatus(err);
    });
};

export const createCustomReportTemplate = data => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `/api/rule_package/createCustomReportTemplate`,

      { ...data },
      requestConfig,
      ''
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        dispatch({
          type: MERGE_REPORT_TEMPLATE_LIST,
          payload: data.customReportTemplateCreated
        });

        return data.customReportTemplateCreated;
      } else {
        throw new Error('default_message');
      }
    })
    .catch(err => {
      handleErrorMessageStatus(err);
    });
};

export const addFileToCustomReport =
  (customReportTemplatedId, file, onUploadProgress) => dispatch => {
    const requestConfig = {
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress,
      params: {
        totalSizeAdded: file.size
      }
    };
    let formData = new FormData();

    formData.append('files_added', file);

    //const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);

    //  formData.append('isDLL', fileExtension === 'dll');
    return dispatch(
      performRequest(
        'post',
        `/api/rule_package/customReportTemplate/${customReportTemplatedId}/upload_files`,
        formData,
        requestConfig,
        'UPLOAD_ZIP_FILE'
      )
    )
      .then(res => res.data)
      .then(({ success, data, error }) => {
        if (success) {
          dispatch({
            type: ADD_FILE_TO_CUSTOM_REPORT_TEMPLATE,
            payload: {
              customReportTemplatedId,
              file: data
            }
          });

          return data;
        } else {
          throw new Error(error || 'default_message');
        }
      })
      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };
