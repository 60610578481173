import {
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { timeAgoFormatting } from '../../../../locales/dateFormat';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import style from './newsFront.module.css';

// TODO enchance UI
class AllNewsPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.fetchAllNews();
    }
  }
  render() {
    const { news, t } = this.props;
    return (
      <Container>
        <div className="mt-2">
          <h4 className="text-muted">{t('all_news')}</h4>
        </div>
        <div className="bg-light py-3 px-2">
          <ListGroup flush className={style.newsListGroup}>
            {news.map((n, i) => {
              return (
                <ListGroupItem key={i}>
                  <ListGroupItemHeading>
                    <Link to={'/'}>{n.title}</Link>
                  </ListGroupItemHeading>
                  {/* will only diplay few text of the news content */}
                  <ListGroupItemText>{n.body}</ListGroupItemText>{' '}
                  <small className="text-muted">
                    {timeAgoFormatting(n.created_at)}{' '}
                    <i className="far fa-clock" />
                  </small>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
      </Container>
    );
  }
}

export default withTranslation(['news'])(AllNewsPresenter);
