import React, { Component, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';

import CustomInput from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';

const ModalLeaveMember = props => {
  const {
    toggle,
    index,
    className,
    open,
    member,
    t,
    onSubmit,
    roles,
    mode,
    existingMemberEmail,
    authUser
  } = props;
  const [leaveRequested, setLeaveRequested] = useState(false);

  return (
    <Modal isOpen={open} className={className} toggle={toggle}>
      <Form>
        <ModalHeader>{t('project:leave_project_title')}</ModalHeader>
        <ModalBody>{t('project:leave_project_info')}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            disabled={leaveRequested}
            onClick={async () => {
              setLeaveRequested(true);
              await onSubmit(authUser);
              setLeaveRequested(false);
              toggle();
            }}>
            {leaveRequested && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              />
            )}

            {t('project:leave_project')}
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            {t('main:cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    featureList: state.app.featureList,
    authUser: state.authUser
  };
};

export default compose(
  connect(mapStateToProps),
  withTranslation(['project', 'main', 'error', 'validation'])
)(ModalLeaveMember);
