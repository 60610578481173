import { performRequest } from '../requests';
import { userPreferenceActionType } from '../../constants/actionType';

const {
  MERGE_PREFERENCE,
  ADD_PREFERENCE,
  RESET_PREFERENCE,
  REMOVE_FAVORITE_RULES_PREFERENCE,
  UPDATE_PREFERENCE,
  SET_DASHBOARD_TILES
} = userPreferenceActionType;

const defaultTiles = {
  user_projects: { order: 0 },
  notifications: { order: 1 }
};
// Add a list of preference to the store
export const mergeUserPreference = preferenceList => {
  const action = {
    type: MERGE_PREFERENCE,
    preferenceList
  };
  return action;
};

// Add a preference to the store
export const addUserPreference = preference => {
  const action = {
    type: ADD_PREFERENCE,
    preference
  };
  return action;
};

// export const resetUserPreference = () => {
//   const action = {
//     type: RESET_PREFERENCE
//   };
//   return action;
// };

export const removeFavoriteRulePreference = rule => (dispatch, getState) => {
  return dispatch({
    type: REMOVE_FAVORITE_RULES_PREFERENCE,
    rule
  });
};

// Fetch all the preference from the server api
// Async actions => Here we use the redux-thunk library to perform asynchronous actions
export const fetchAllUserPreference = settingsToFetch => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'GET',
      `api/user_preference/list/${settingsToFetch}`,
      {},
      requestConfig,
      'FETCH_LIST_PREFERENCE'
    )
  )
    .then(res => res.data)
    .then(res => {
      const { preferenceData } = res;
      dispatch({
        type: userPreferenceActionType.MERGE_PREFERENCE,
        preferenceData
      });
      return preferenceData;
    });
};

export const updateTilePreference = ({ tileName, updatedSize, collapsed }) => (
  dispatch,
  getState
) => {
  const prevTiles =
    getState().userPreference.dashboard_defaults.tiles || defaultTiles;
  const tiles = {
    ...prevTiles,
    [tileName]: {
      ...prevTiles[tileName],
      tileSize: updatedSize,
      collapsed
    }
  };

  dispatch({
    type: SET_DASHBOARD_TILES,
    tiles
  });

  return dispatch(
    updateUserPreference({
      settingsToSet: 'dashboard_defaults',
      newPreferenceData: {
        tiles
      },
      operation: 'update'
    })
  );
};

export const updateTileOrder = ({ order, tileName }) => (
  dispatch,
  getState
) => {
  const prevTiles =
    getState().userPreference.dashboard_defaults.tiles || defaultTiles;

  const previousOrder = prevTiles[tileName].order;
  const tiles = Object.keys(prevTiles).reduce((prev, tileKey) => {
    if (tileKey === tileName) {
      return { ...prev, [tileKey]: { ...prevTiles[tileKey], order } };
    } else if (
      prevTiles[tileKey].order > previousOrder &&
      prevTiles[tileKey].order <= order
    ) {
      return {
        ...prev,
        [tileKey]: {
          ...prevTiles[tileKey],
          order: prevTiles[tileKey].order - 1
        }
      };
    } else if (
      prevTiles[tileKey].order < previousOrder &&
      prevTiles[tileKey].order >= order
    ) {
      return {
        ...prev,
        [tileKey]: {
          ...prevTiles[tileKey],
          order: prevTiles[tileKey].order + 1
        }
      };
    } else {
      return { ...prev, [tileKey]: prevTiles[tileKey] };
    }
  }, {});

  dispatch({
    type: 'SET_DASHBOARD_TILES',
    tiles
  });

  return dispatch(
    updateUserPreference({
      settingsToSet: 'dashboard_defaults',
      newPreferenceData: {
        tiles
      },
      operation: 'update'
    })
  );
};

export const updateUserPreference = data => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  const { settingsToSet, newPreferenceData, operation } = data;
  return dispatch(
    performRequest(
      'POST',
      `api/user_preference/set/${settingsToSet}`,
      { newPreferenceData, operation },
      requestConfig,
      'UPDATE_PREFERENCE',
      true,
      true
    )
  )
    .then(res => res.data.data)
    .then(preferenceData => {
      dispatch({
        type: UPDATE_PREFERENCE,
        preferenceData
      });
    });
};

export const resetUserPreference = data => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  const { settingsToReset } = data;

  return dispatch(
    performRequest(
      'POST',
      `api/user_preference/reset/${settingsToReset}`,
      {},
      requestConfig,
      'RESET_PREFERENCE'
    )
  )
    .then(res => res.data.data)
    .then(preferenceData => {
      dispatch({
        type: MERGE_PREFERENCE,
        preferenceData
      });
    });
};
