import React, { Component } from 'react';
import ModelIssueDetail from './Detail';
import Table from './IssueList';
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Spinner
} from 'reactstrap';
import './style.css';

class ModelIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      stageDropDownValue:
        props.project.stagesavailable.find(s => s.name === props.stageName) ||
        props.project.stagesavailable[0],
      dropdownOpen: false,
      selectedIssueId: null,
      selectedIssue: {},
      mode: null,
      onError: false
    };
  }

  stageChangeValue = stageData => {
    const projectId = this.props.projectId;
    this.props.stageChangeValue(stageData).then(() => {
      this.props.history.push(`/project/${projectId}/issue/${stageData.name}`);
    });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  componentDidMount() {
    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    const issueId = this.props.issueId;

    this.setState({ isLoaded: false });
    if (issueId) {
      this.props.history.replace(
        `/project/${projectId}/issue/${stageName}/${issueId}`
      );
      this.props.modelIssueActions
        .fetchModelIssueDetails(projectId, stageName, issueId)
        .then(() => {
          const issuesMap = this.props.modelIssues;
          const issue = issuesMap[issueId];
          if (issue) {
            this.setState({
              isLoaded: true,
              mode: 'detail',
              selectedIssueId: issueId,
              selectedIssue: issue
            });
          } else {
            throw new Error('issue_not_in_state');
          }
        })
        .catch(err => {
          this.setState({
            isLoaded: true,
            onError: true,
            mode: null,
            selectedIssueId: null,
            selectedIssue: {}
          });
        });
    } else {
      const { fetchAllModelIssues } = this.props.modelIssueActions;
      this.props.history.replace(`/project/${projectId}/issue/${stageName}`);
      fetchAllModelIssues(projectId, stageName).then(() => {
        this.setState({
          mode: 'list',
          isLoaded: true,
          selectedIssueId: null,
          selectedIssue: {}
        });
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const prevIssueId = prevProps.issueId;
    const newIssueId = this.props.issueId;

    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    if (prevIssueId !== newIssueId) {
      this.setState({ isLoaded: false });
      if (newIssueId) {
        this.props.modelIssueActions
          .fetchModelIssueDetails(projectId, stageName, newIssueId)
          .then(() => {
            const issuesMap = this.props.modelIssues;
            const issue = issuesMap[newIssueId];
            if (issue) {
              this.setState({
                isLoaded: true,
                mode: 'detail',
                selectedIssueId: newIssueId,
                selectedIssue: issue
              });
            } else {
              throw new Error('issue_not_in_state');
            }
          })
          .catch(err => {
            this.setState({
              isLoaded: true,
              onError: true,
              mode: null,
              selectedIssueId: null,
              selectedIssue: {}
            });
          });
      } else {
        const { fetchAllModelIssues } = this.props.modelIssueActions;
        fetchAllModelIssues(projectId, stageName).then(() => {
          this.setState({
            mode: 'list',
            isLoaded: true,
            selectedIssueId: null,
            selectedIssue: {}
          });
        });
      }
    }
    if (this.state.onError && !prevState.onError) {
      this.setState({ isLoaded: false });
      const { fetchAllModelIssues } = this.props.modelIssueActions;

      fetchAllModelIssues(projectId, stageName).then(() => {
        this.setState({
          isLoaded: true,
          mode: 'list',
          selectedIssueId: null,
          selectedIssue: {}
        });
      });
    }
  }

  render() {
    const { t, project, stageDropDownValue, projectId, stageName } = this.props;
    const { dropdownOpen, isLoaded, mode } = this.state;
    const projectStages = project.stagesavailable || [];
    return (
      <>
        <Container>
          <div className="shadow p-3 mt-3 rounded">
            <Row className="justify-content-between px-3">
              <h5 className="text-primary">{t('model_issue_management')}</h5>
            </Row>
            <Row className="px-3 d-flex justify-content-between mt-1">
              <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle">
                  <em>{t('stage')}: </em>
                  {stageDropDownValue.display_name}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu">
                  <DropdownItem header>{t('stages')}</DropdownItem>
                  {projectStages.map((stage, key) => {
                    return (
                      <DropdownItem
                        key={`stage-${key}`}
                        onClick={() =>
                          this.stageChangeValue({
                            name: stage.name,
                            display_name: stage.display_name
                          })
                        }>
                        {stage.display_name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </Row>
          </div>
          {isLoaded && (
            <div>
              {mode === 'detail' && this.state.selectedIssueId && (
                <ModelIssueDetail
                  projectId={projectId}
                  stageName={stageName}
                  issueId={this.state.selectedIssueId}
                  issue={this.state.selectedIssue}
                  {...this.props}
                />
              )}
              {mode === 'list' && (
                <Table
                  {...this.props}
                  stageName={stageName}
                  projectId={projectId}
                />
              )}
            </div>
          )}
          {!isLoaded && (
            <div className="d-flex align-items-center justify-content-center loading-spinner-div">
              <Spinner
                className="loading-spinner"
                type="grow"
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}

export default ModelIssue;
