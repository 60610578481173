import React from 'react';
export default featureList => {
  const isSubmissionEnabled = featureList.officerSubmission;
  const isCheckAnalyticsEnabled = featureList.checkAnalytics;
  const isRuleParameterEnabled = featureList.ruleParameter;
  const isKnowledgehubEnabled = featureList.knowledgeHub;
  const isNewsEnabled = featureList.news;
  const isAdminConfigEnabled = featureList.administrationConfig;
  const isTaskManagementEnabled = featureList.taskManagement;

  const windowPushLocation = location => {
    if (window && window.history) {
      window.history.pushState({}, '', location);
      window.history.go();
    }
  };

  const dashboard = {
    id: 'dashboard',
    to: '/dashboard',
    label: 'Dashboard',
    icon: <i className="fas fa-home" />,
    isLink: true
  };

  const notification = {
    id: 'notifications',
    to: '',
    label: 'Notifications',
    icon: <i className="fas fa-envelope" />,
    isLink: false
  };
  const login = {
    id: 'login',
    to: '/login',
    label: 'Login',
    isLink: true

    // icon: <i className="fas fa-sign-in-alt" />
  };

  const register = {
    id: 'register',
    to: '/register',
    label: 'Register',
    isLink: true
  };

  const logout = {
    id: 'logout',
    to: '/',
    label: 'Logout',
    icon: <i className="fas fa-sign-out-alt" />,
    isLink: false,
    action: context => {
      context.event.stopPropagation();
      context.event.preventDefault();
      context.history && context.history.push
        ? context.history.push('/logout')
        : windowPushLocation('/logout');
    }
  };
  const profile = {
    id: 'profile',
    to: '/profile',
    label: 'Profile',
    icon: <i className="fas fa-user" />,
    isLink: true
  };
  const userManagement = {
    id: 'user_management',
    to: '/admin/users/list',
    label: 'User Management',
    icon: <i className="fas fa-users" />,
    isLink: true
  };
  const projectListing = {
    id: 'project_list',
    to: '/admin/projects/list',
    label: 'Project Management',
    icon: <i className="fas fa-folder-open" />,
    isLink: true
  };

  const newsManagement = {
    id: 'news_management',
    to: '/admin/news/list',
    label: 'News Management',
    icon: <i className="fas fa-newspaper" />,
    isLink: true
  };

  const submissionListing = {
    id: 'submissions',
    to: '/admin/consultations/list',
    label: 'Consultations',
    icon: <i className="fas fa-list-ul" />,
    isLink: true
  };

  const statisticsView = {
    id: 'statistics',
    to: '/admin/statistics',
    label: 'Statistics',
    icon: <i className="fas fa-chart-line" />,
    isLink: true
  };

  const ruleManagement = {
    id: 'rule_management',
    to: '/rulemanagement',
    label: 'Rule Management',
    icon: <i className="fas fa-wrench" />,
    isLink: true
  };

  const knowledgeHubSearch = {
    id: 'knowledge_hub',
    to: '/knowledgeHub',
    label: 'Knowledge Hub',
    icon: <div className="knowledgehub-icon d-inline-flex" />,
    isLink: true
  };

  const bimrlQuery = {
    id: 'bimrl_query',
    to: '/admin/bim_data',
    label: 'BIM Database',
    icon: <i className="fas fa-database" />,
    isLink: true
  };

  const configPanel = {
    id: 'admin_config',
    to: '/admin/configuration_panel',
    label: 'Configuration Panel',
    icon: <i className="fas fa-cogs" />,
    isLink: true
  };

  const userPreference = {
    id: 'user_preference',
    to: '/user_preference/Dashboard',
    label: 'User Preference',
    icon: <i className="fas fa-user-cog" />,
    isLink: true
  };

  const jobListing = {
    id: 'job_list',
    to: '/admin/jobs',
    label: 'Job Management',
    icon: <i className="fas fa-clipboard-check" />,
    isLink: true
  };

  const userTaskList = {
    id: 'user_task',
    to: '/userTaskList',
    label: 'Task Management',
    icon: <i className="fas fa-tasks"></i>,
    isLink: true
  };

  return {
    default: [login, register],
    QP: [
      dashboard,
      ...(isRuleParameterEnabled ? [ruleManagement] : []),
      profile,
      ...(isTaskManagementEnabled ? [userTaskList] : []),
      ...(isKnowledgehubEnabled ? [knowledgeHubSearch] : []),
      notification,
      logout
    ],
    PO: [
      dashboard,
      projectListing,
      ...(isSubmissionEnabled ? [submissionListing] : []),
      ...(isTaskManagementEnabled ? [userTaskList] : []),
      ...(isCheckAnalyticsEnabled ? [statisticsView] : []),
      ...(isRuleParameterEnabled ? [ruleManagement] : []),
      profile,
      ...(isKnowledgehubEnabled ? [knowledgeHubSearch] : []),
      notification,
      logout
    ],

    RM: [
      dashboard,
      projectListing,
      ...(isSubmissionEnabled ? [submissionListing] : []),
      ...(isTaskManagementEnabled ? [userTaskList] : []),
      ...(isCheckAnalyticsEnabled ? [statisticsView] : []),
      ...(isRuleParameterEnabled ? [ruleManagement] : []),
      profile,
      ...(isKnowledgehubEnabled ? [knowledgeHubSearch] : []),
      notification,
      logout
    ],

    ADMIN: [
      dashboard,
      userManagement,
      projectListing,
      jobListing,
      ...(isNewsEnabled ? [newsManagement] : []),
      ...(isSubmissionEnabled ? [submissionListing] : []),
      ...(isTaskManagementEnabled ? [userTaskList] : []),
      ...(isCheckAnalyticsEnabled ? [statisticsView] : []),
      ...(isRuleParameterEnabled ? [ruleManagement] : []),
      profile,
      ...(isKnowledgehubEnabled ? [knowledgeHubSearch] : []),
      // ...(isBIMDatabaseEnabled ? [bimrlQuery] : []),
      notification,
      ...(isAdminConfigEnabled ? [configPanel] : []),
      logout
    ]
  };
};
