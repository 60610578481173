import React, { Component, useState } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, withFormik, Form, Field, yupToFormErrors } from 'formik';
import uuid from 'uuid/v4';
import classnames from 'classnames';

import CustomInput from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';
import CustomTransfer from '../../UI/Input/customTransfer2';

const emptyAction = {
  actors: [],
  type: '',
  dueDate: '',
  children: []
};

const MAX_ACTION = 10;

const updateActionsDependencies = actions => {
  const getChildren = (actionItem, actionList) => {
    const nextActionList = actionList
      .filter(a => a.actionId !== actionItem.actionId)
      .sort((a, b) => a.order - b.order);
    return nextActionList.reduce((previous, a) => {
      if (a.dependencies.includes(actionItem.actionId)) {
        return new Set([
          ...previous,
          a.actionId,
          ...getChildren(a, nextActionList)
        ]);
      } else {
        return previous;
      }
    }, []);
  };

  const nextActions = actions.reduce((previous, a, index) => {
    const children = Array.from(getChildren(a, actions));
    previous.splice(index, 1, {
      ...a,
      children,
      order:
        Math.max(
          ...a.dependencies.map(key => {
            return previous.find(b => b.actionId === key).order || 0;
          }),
          -1
        ) + 1
    });
    return previous;
  }, actions);

  return nextActions;
};

const ActionCreation = props => {
  const [actionTab, setActionTab] = useState(0);

  const {
    form,
    push,
    remove,
    t,
    documentsItemsDataSource,
    modelsItemsDataSource,
    issuesItemsDataSource,
    resultsItemsDataSource,
    usersItemsDataSource
  } = props;
  const actions = form.values.actions;
  const groupType = ['organization', 'department', 'team'];

  const updatedusersItemsDataSource = usersItemsDataSource.filter(info => {
    return !groupType.includes(info.type);
  });

  return (
    <div
      className={classnames('mb-5 d-flex p-2', {
        'border border-danger rounded':
          form.errors.actions && form.errors.actions.length > 0
      })}>
      <Nav vertical className="mr-2 rounded border" tabs>
        {actions.map((action, index) => {
          return (
            <NavItem key={`action-tab-${index}`}>
              <NavLink
                tag={Button}
                color={
                  actionTab !== `action-tab-${index}` &&
                  form.errors.actions &&
                  form.errors.actions[index]
                    ? 'danger'
                    : 'primary'
                }
                outline
                className={classnames('rounded border-bottom', {
                  'active font-weight-bold':
                    actionTab === `action-tab-${index}`,
                  'border-danger':
                    actionTab !== `action-tab-${index}` &&
                    form.errors.actions &&
                    form.errors.actions[index]
                })}
                onClick={() => setActionTab(`action-tab-${index}`)}>
                Action {index + 1}
              </NavLink>
            </NavItem>
          );
        })}
        {actions.length < MAX_ACTION && (
          <NavItem key={'action-navitem-add'}>
            <NavLink
              // <Button
              tag={Button}
              color="primary"
              outline
              className="rounded w-100 border-bottom"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                form.setFieldValue(
                  'actions',
                  updateActionsDependencies([
                    ...actions,
                    {
                      actionId: uuid(),
                      name: `Action ${actions.length + 1}`,
                      ...emptyAction,
                      dependencies:
                        actions.length > 0
                          ? [actions[actions.length - 1].actionId]
                          : [],
                      order:
                        actions.length > 0
                          ? actions[actions.length - 1].order + 1
                          : 0
                    }
                  ])
                );
                setActionTab(`action-tab-${actions.length}`);
              }}>
              <i className="fas fa-plus"></i>
              {/* {t('new_action')} */}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent className="flex-grow-1" activeTab={actionTab}>
        {actions.map((action, index) => {
          return (
            <TabPane
              tabId={`action-tab-${index}`}
              key={`action-${action.actionId}`}
              className="border w-100 p-2">
              <Container>
                <Row className="justify-content-between">
                  <div>
                    <h5 className="text-primary py-3">Action {index + 1}</h5>
                  </div>
                  <div>
                    <Button
                      color="danger"
                      outline
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        remove(index);
                        setActionTab(
                          `action-tab-${
                            index < actions.length - 1 ? index : index - 1
                          }`
                        );
                      }}>
                      <i className="fas fa-minus"></i>
                    </Button>
                  </div>
                </Row>
                <Field
                  name={`actions.${index}.name`}
                  label={t('name')}
                  placeholder={t('name')}
                  component={CustomInput}
                />
                {/* TODO : PREDEFINED TYPE  */}
                {/* <Field
                  name={`actions.${index}.type`}
                  label={t('type')}
                  type="select"
                  options={[]}
                  component={CustomSelectInput}
                /> */}
                {/* ACTORS */}
                <Field
                  name={`actions.${index}.actors`}
                  render={() => {
                    const { values, setFieldValue, setFieldTouched } = form;
                    const handleChange = async (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      // add await
                      const nextValues =
                        await updatedusersItemsDataSource.filter(user =>
                          nextTargetKeys.includes(user.user_id)
                        );
                      await setFieldValue(
                        `actions.${index}.actors`,
                        nextValues
                      );
                      await setFieldTouched(`actions.${index}.actors`);
                    };
                    const errors =
                      form.errors &&
                      form.errors.actions &&
                      form.errors.actions[index] &&
                      form.errors.actions[index].actors;
                    const touched =
                      form.touched &&
                      form.touched.actions &&
                      form.touched.actions[index] &&
                      form.touched.actions[index].actors;

                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-3">
                            {t('task_management:actors')}
                          </h5>
                          <CustomTransfer
                            data={updatedusersItemsDataSource}
                            targetKeys={values.actions[index].actors}
                            // withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item =>
                              `${item.first_name || ''} ${
                                item.last_name || ''
                              } (${item.email})`
                            }
                            // renderHeader={item => item.header}
                            onChange={(
                              nextTargetKeys,
                              direction,
                              movedKeys
                            ) => {
                              handleChange(nextTargetKeys);
                            }}
                            // onSelectChange={}
                            errors={errors}
                            touched={touched}
                            setFieldTouched={() =>
                              setFieldTouched(`actions.${index}.actors`)
                            }
                          />
                          {errors && (
                            <div className="row">
                              <div className="col-6"></div>
                              <div className="col-6 text-danger ">
                                {form.errors.actions[index].actors}
                              </div>
                            </div>
                          )}
                        </FormGroup>
                      </>
                    );
                  }}
                />
                {/*  ISSUES */}
                {/* <Field
                  name={`actions.${index}.issues`}
                  render={() => {
                    const { values, setFieldValue } = form;
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      // this.setState({ targetKeys: nextTargetKeys });

                      console.log('targetKeys: ', nextTargetKeys);
                      console.log('direction: ', direction);
                      console.log('moveKeys: ', movedKeys);
                      setFieldValue(`actions.${index}.issues`, nextTargetKeys);
                    };
                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-3">{t('issues')}</h5>
                          <CustomTransfer
                            data={issuesItemsDataSource}
                            targetKeys={values.actions[index].issues}
                            withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item => item.title}
                            renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                /> */}
                {/* DOCUMENTS */}
                {/* <Field
                  name={`actions.${index}.documents`}
                  render={() => {
                    const { values, setFieldValue } = form;
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      // this.setState({ targetKeys: nextTargetKeys });

                      console.log('targetKeys: ', nextTargetKeys);
                      console.log('direction: ', direction);
                      console.log('moveKeys: ', movedKeys);
                      setFieldValue(
                        `actions.${index}.documents`,
                        nextTargetKeys
                      );
                    };
                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-3">
                            {t('documents')}
                          </h5>
                          <CustomTransfer
                            data={documentsItemsDataSource}
                            targetKeys={values.actions[index].documents}
                            // withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item => (
                              <div style={{ fontSize: '0.75rem' }}>
                                {item.baseurltodisplayinfileexplorer}
                              </div>
                            )}
                            // renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                /> */}
                {/* RESULTS */}
                {/* <Field
                  name={`actions.${index}.results`}
                  render={() => {
                    const { values, setFieldValue } = form;
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      // this.setState({ targetKeys: nextTargetKeys });

                      console.log('targetKeys: ', nextTargetKeys);
                      console.log('direction: ', direction);
                      console.log('moveKeys: ', movedKeys);
                      setFieldValue(`actions.${index}.results`, nextTargetKeys);
                    };
                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-3">{t('results')}</h5>
                          <CustomTransfer
                            data={resultsItemsDataSource}
                            targetKeys={values.actions[index].results}
                            withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item => item.title}
                            renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                /> */}
                {/* MODELS */}
                {/* <Field
                  name={`actions.${index}.models`}
                  render={() => {
                    const { values, setFieldValue } = form;
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      // this.setState({ targetKeys: nextTargetKeys });

                      console.log('targetKeys: ', nextTargetKeys);
                      console.log('direction: ', direction);
                      console.log('moveKeys: ', movedKeys);
                      setFieldValue(`actions.${index}.models`, nextTargetKeys);
                    };
                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-3">{t('models')}</h5>
                          <CustomTransfer
                            data={modelsItemsDataSource}
                            targetKeys={values.actions[index].models}
                            withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item => item.title}
                            renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                /> */}
                {/* DUE DATE */}

                {/* DEPENDENCIES */}
                <Field
                  name={`actions.${index}.dependencies`}
                  render={() => {
                    const { values, setFieldValue } = form;
                    const thisAction = values.actions[index];
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      thisAction.dependencies = nextTargetKeys;
                      thisAction.order =
                        Math.max(
                          ...nextTargetKeys.map(key => {
                            return (
                              values.actions.find(a => a.actionId === key)
                                .order || 0
                            );
                          }),
                          -1
                        ) + 1;

                      const nextActions = values.actions;
                      nextActions.splice(index, 1, thisAction);
                      setFieldValue(
                        'actions',
                        updateActionsDependencies(nextActions)
                      );
                    };
                    return (
                      <>
                        <FormGroup className="d-none">
                          Order: {action.order}
                          <h5 className="text-primary py-3">
                            {t('task_management:dependencies')}
                          </h5>
                          <CustomTransfer
                            data={values.actions
                              .map((a, index) => ({
                                title:
                                  a.name && a.name !== ''
                                    ? a.name
                                    : `action ${index + 1}`,
                                key: a.actionId,
                                order: a.order,
                                actionId: a.actionId
                              }))
                              .filter(
                                a =>
                                  a.actionId !== thisAction.actionId &&
                                  !thisAction.children.includes(a.actionId)
                              )}
                            targetKeys={thisAction.dependencies}
                            // withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item => item.title}
                            // renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                />
              </Container>
            </TabPane>
          );
        })}
      </TabContent>
      {/* <Row className="justify-content-end">
          <Button
            color="primary"
            className="rounded-circle"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              push({ actionId: uuid(), ...emptyAction });
            }}>
            <i className="fas fa-plus"></i>
          </Button>
        </Row> */}
    </div>
  );
};

export default ActionCreation;
