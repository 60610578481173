import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray } from 'formik';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import prettyBytes from 'pretty-bytes';

import { dateFormatting } from '../../../locales/dateFormat';
import {
  addResultAttachmentFile,
  removeAttachmentFile,
  resetAttachmentFiles,
  uploadAttachmentFiles,
  updateAttachmentList
} from '../../../store/actions';

import {
  Input,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from 'reactstrap';

import Message from '../../UI/Message';
import styles from './Styles.module.css';

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFileUploading: false
    };
  }

  handleFilesSelected = e => {
    const formData = new FormData();
    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      formData.append('file', file, file.name);
      this.props.addResultAttachmentFile(file);
    }
  };

  componentWillUnmount() {
    this.props.resetAttachmentFiles();
  }

  onUpdateDocumentList = async () => {
    this.setState({ isFileUploading: true });
    const { attachments } = this.props;

    const filesAdded = attachments.filter(m => m.dirty && !m.removed);
    const filesRemoved = attachments.filter(m => m.removed);

    const { projectId, stageName, submissionId, uploadAttachmentFiles } =
      this.props;

    try {
      if (filesAdded.length > 0 || filesRemoved.length > 0) {
        await this.props.updateAttachmentList(
          projectId,
          stageName,
          submissionId,
          filesAdded,
          filesRemoved
        );
      }

      this.setState({
        isFileUploading: false
      });

      Message.success(this.props.t('dcoument_list_updated'));
    } catch (error) {
      this.setState({ isFileUploading: false });
      Message.error(
        this.props.t(`error:${error.message}`, { entity: 'submission' })
      );
    }
  };

  render() {
    const { t, attachments, action, isFormikForm = true } = this.props;

    // Track if any change have been made to the model list
    const isAttachmentListDirty =
      attachments.filter(m => m.dirty || m.removed).length > 0;
    const { isFileUploading } = this.state;

    const notRemoveAttachments = attachments.filter(doc => {
      return !doc.removed;
    });

    return (
      <div>
        <h5 className="text-primary">{t('attachments')}</h5>
        <hr />
        <Row className="py-3">
          <Col>
            <FormGroup>
              <ListGroup className={styles.attachmentListHeight}>
                {notRemoveAttachments.length ? (
                  <div>
                    {notRemoveAttachments.map((file, key) => {
                      return (
                        <ListGroupItem key={key}>
                          <Row>
                            <Col sm={8}>
                              <Row>
                                {file.name}
                                {file.size && ` (${prettyBytes(file.size)})`}
                              </Row>
                              <Row>
                                {file.date && (
                                  <div className="text-secondary">
                                    {dateFormatting(file.date)}
                                  </div>
                                )}
                              </Row>
                            </Col>

                            <Col sm={4}>
                              <Row className="justify-content-end">
                                {file.downloadLink && (
                                  <div>
                                    <Button
                                      id={`attachment-${key}`}
                                      color="light"
                                      outline
                                      tag="a"
                                      download
                                      onClick={e => e.stopPropagation()}
                                      href={`${process.env.REACT_APP_API_ENDPOINT}/${file.downloadLink}`}>
                                      <span>
                                        <i className="fas fa-download text-primary" />
                                      </span>
                                    </Button>
                                    <UncontrolledTooltip
                                      fade={false}
                                      placement="left"
                                      target={`attachment-${key}`}>
                                      {t('download')}
                                    </UncontrolledTooltip>
                                  </div>
                                )}
                                <Button
                                  color="light"
                                  outline
                                  onClick={() => {
                                    this.props.removeAttachmentFile(key, file);
                                  }}>
                                  <i className="fas fa-trash-alt text-danger" />
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      );
                    })}
                  </div>
                ) : (
                  <h6 className="text-secondary"> {t('no_attachments')}</h6>
                )}
              </ListGroup>
            </FormGroup>
            <Row className="my-3">
              {/* <Col>
                {!!isAttachmentListDirty && (
                  <Button type="button" onClick={this.onUpdateDocumentList}>
                    {isFileUploading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                        <span className="sr-only">Updating...</span>{' '}
                        {t('update_in_progress')}
                      </>
                    ) : (
                      'Upload'
                    )}
                  </Button>
                )}
              </Col> */}
            </Row>
            {action === 'update_attachments' &&
              (!!isAttachmentListDirty || !!isFileUploading) && (
                <Button
                  type="button"
                  onClick={this.onUpdateDocumentList}
                  disabled={isFileUploading}>
                  {isFileUploading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                      <span className="sr-only">Updating...</span>{' '}
                      {t('update_in_progress')}
                    </>
                  ) : (
                    t('update_files')
                  )}
                </Button>
              )}
          </Col>

          <Col>
            <FormGroup>
              <InputGroup>
                <div className="custom-file">
                  <label className="custom-file-label" htmlFor="customFile">
                    {t('feedback:upload_file')}
                  </label>
                  {isFormikForm ? (
                    <FieldArray
                      name="attachments"
                      component={() => (
                        <Input
                          type="file"
                          id="customFile"
                          className="custom-file-input"
                          accept=".docx,.pdf"
                          onChange={this.handleFilesSelected}
                          multiple={true}
                        />
                      )}
                    />
                  ) : (
                    <Input
                      type="file"
                      id="customFile"
                      className="custom-file-input"
                      accept=".docx,.pdf"
                      onChange={this.handleFilesSelected}
                      multiple={true}
                    />
                  )}
                </div>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { attachments: state.attachment_files };
};

const mapDispatchToProps = dispatch => {
  return {
    addResultAttachmentFile: files => dispatch(addResultAttachmentFile(files)),
    removeAttachmentFile: (fileId, file) =>
      dispatch(removeAttachmentFile(fileId, file)),
    resetAttachmentFiles: () => dispatch(resetAttachmentFiles()),
    uploadAttachmentFiles: (projectId, stageName, filename, submissionId) =>
      dispatch(
        uploadAttachmentFiles(projectId, stageName, filename, submissionId)
      ),
    updateAttachmentList: (
      projectId,
      stageName,
      submissionId,
      filesAdded,
      filesRemoved
    ) =>
      dispatch(
        updateAttachmentList(
          projectId,
          stageName,
          submissionId,
          filesAdded,
          filesRemoved
        )
      )
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['feedback'])
)(Attachment);
