import React from 'react';
import config from '../config/landingPageConfig';
import { Container, NavLink as BootstrapNavLink } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const bimReference = config.bimReference


const BimReference = ({ t }) => {
  const { headerText, links } = bimReference;
  return (
    <div id="bimReference" className="bim-reference-container">
      <Container fluid className="p-5">
        <div className="d-flex justify-content-center text-white">
          <h2>{t(`front:${headerText}`)}</h2>
        </div>
        <hr className="about-custom-hr pb-3" />
        <div className="w-100 text-center">
          {links.map((link, index) => {
            return (
              <div className="p-2" key={`key-${index}`}>
                <a
                  target="_blank"
                  href={link.href}
                  className="bim-reference-anchor-link">
                  {link.label}
                </a>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default withTranslation(['front'])(BimReference);
