import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Badge,
  CustomInput as CustomInputReact
} from 'reactstrap';
import classnames from 'classnames';

import CustomInput from '../../UI/Input/customInput';
import Message from '../../UI/Message';
import styles from './Styles.module.css';

import { fetchOrganizationStructure } from '../../../store/actions';

import { Formik, withFormik, Form, Field, yupToFormErrors } from 'formik';
import { flattenDeep } from 'lodash';

const sharing_text = {
  organization: 'Subgroup(s) sharing',
  department: 'Subgroup(s) sharing'
};

const GroupTreeComponent = ({
  groupTree,
  groups,
  setCurrentGroupId,
  currentGroupId,
  setFieldValue,
  relatedGroups,
  toggleSharingOption
}) => {
  const CollapsableNode = ({ id, details, itemChildren }) => {
    const [collapsed, setCollapsed] = useState(false);
    const key = id;
    if (key === currentGroupId) {
      setFieldValue('selected_group', details);
    }
    const select = {
      organization: relatedGroups.details,
      department: relatedGroups.departments,
      team: relatedGroups.teams
    };

    const groupInfo = flattenDeep([select[`${details.type}`]]).find(i => {
      return !!i && i[`${details.type}_id`] === key;
    });
    return (
      <div key={key}>
        <div
          className={classnames('pointer d-flex p-2', {
            'border border-primary rounded border-thick': key === currentGroupId
          })}>
          <span onClick={() => setCollapsed(!collapsed)}>
            <i
              className={`fas fa-angle-right mr-1  ${
                !collapsed ? 'fa-rotate-90' : ''
              }`}
            />
          </span>
          <span
            className="pointer flex-grow-1"
            onClick={() => {
              setCurrentGroupId(key);

              toggleSharingOption(!(details.type === 'team'));
            }}>
            {details.name}
            {groupInfo && (
              <Badge className={styles.organizationBadgeFloat} color="success" >
                shared as {groupInfo.role}
              </Badge>
            )}
          </span>
        </div>
        <Collapse isOpen={!collapsed}>
          {!collapsed && (
            <div className="p-0 pl-2 ">
              <div className="pl-2 border-left border-thick">
                <GroupTreeComponent
                  groupTree={itemChildren}
                  groups={groups}
                  setCurrentGroupId={setCurrentGroupId}
                  currentGroupId={currentGroupId}
                  setFieldValue={setFieldValue}
                  relatedGroups={relatedGroups}
                  toggleSharingOption={toggleSharingOption}
                />
              </div>
            </div>
          )}
        </Collapse>
      </div>
    );
  };

  return (
    <>
      {groupTree.map(item => {
        const key = item.team_id || item.department_id || item.organization_id;
        const details = groups[key];
        const itemChildren = item.departments || item.teams;
        if (itemChildren && itemChildren.length > 0) {
          return (
            <CollapsableNode
              id={key}
              details={details}
              itemChildren={itemChildren}
            />
          );
        } else {
          const select = {
            organization: relatedGroups.details,
            department: relatedGroups.departments,
            team: relatedGroups.teams
          };

          const groupInfo = flattenDeep([select[`${details.type}`]]).find(i => {
            return !!i && i[`${details.type}_id`] === key;
          });
          if (key === currentGroupId) {
            setFieldValue('selected_group', details);
          }

          return (
            <div
              key={key}
              onClick={() => {
                setCurrentGroupId(key);
                setFieldValue('selected_group', details);
                if (details.type === 'team') {
                  toggleSharingOption(!(details.type === 'team'));
                }
              }}
              className={classnames('p-2 pointer', {
                'border border-primary rounded border-thick':
                  key === currentGroupId
              })}>
              {details.name}
              {groupInfo && (
                <Badge color="success" className={styles.organizationBadgeFloat}>
                  shared as {groupInfo.role}
                </Badge>
              )}
            </div>
          );
        }
      })}
    </>
  );
};

const ViewOrganizationStructureComponent = ({
  t,
  groupTree,
  groups,
  getGroupMembers,
  memberGroups,
  organizationMembers,
  fetchOrganizationMembers,
  updateGroup,
  organizationId,
  setFieldValue,
  relatedGroups,
  toggleSharingOption
}) => {
  const [currentGroupId, setCurrentGroupId] = useState(
    groupTree.root.organization_id
  );

  useEffect(() => {}, [currentGroupId, memberGroups]);

  // const test = memberGroups && memberGroups[currentGroupId];
  // useEffect(() => {
  //   console.log('MEMBER GROUP CHANGES');
  //   console.log({ memberGroups });
  //   if (memberGroups && memberGroups[currentGroupId]) {
  //     setCurrentGroup({
  //       details: currentGroup.details,
  //       members: memberGroups[currentGroupId]
  //     });
  //   }
  // }, [test ]);

  return (
    <div className="shadow p-1 mt-3 mb-5 rounded">
      <GroupTreeComponent
        groupTree={[groupTree.root]}
        groups={groups}
        setCurrentGroupId={setCurrentGroupId}
        currentGroupId={currentGroupId}
        setFieldValue={setFieldValue}
        relatedGroups={relatedGroups}
        toggleSharingOption={toggleSharingOption}
      />
    </div>
  );
};

const ViewOrganizationStructureContainer = props => {
  const {
    fetchOrganizationStructure,
    organizationId,
    setFieldValue,
    // toogleIncludeSubGroups,
    relatedGroups,
    toggleSharingOption
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        await fetchOrganizationStructure(organizationId);
        setIsLoaded(true);
      } catch (err) {
        setHasError(true);
      }
    })();
  }, [organizationId]);

  return (
    <>
      {!hasError && isLoaded && (
        <ViewOrganizationStructureComponent
          {...props}
          organizationId={organizationId}
          setFieldValue={setFieldValue}
          relatedGroups={relatedGroups}
          toggleSharingOption={toggleSharingOption}
        />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationStructure: organizationId =>
      dispatch(fetchOrganizationStructure(organizationId))
  };
};

const mapStateToProps = state => {
  return {
    groupTree:
      state.organization &&
      state.organization.current &&
      state.organization.current.groupTree,
    groups:
      state.organization &&
      state.organization.current &&
      state.organization.current.groups
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(ViewOrganizationStructureContainer);
