import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, renameProps } from 'recompose';
import * as Yup from 'yup';
import { Formik, withFormik, Form, Field, yupToFormErrors } from 'formik';
import { Container, Row, Col, Collapse, Button, CustomInput } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import CustomSelectInput from '../../../UI/Input/customSelectInput';
import AutocompleteInput from '../../../UI/Input/AutocompleteInput';
import './Bimrl_data.module.css';
import {
  fetchBIMRLSavedQueries,
  fetchSimilarStages,
  getSavedQueryBIMRLProjectResult,
  fetchProjectListAsAdmin,
  fetchProject,
  runAggregatedQuery
} from '../../../../store/actions';

const predefined_stages = [
  { display_name: 'Sandbox', name: 'sandbox' },
  { display_name: 'Work in progress', name: 'WIP' }
];

class QueryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      stages: []
    };
  }
  componentDidMount() {
    this.props.getSavedQueries().then(() => {
      this.setState({ isLoaded: true });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isValid, values, errors } = this.props;
    const { isValid: prevIsValid, values: prevValues } = prevProps;
    if (
      (prevIsValid !== isValid ||
        prevValues.search_project !== values.search_project) &&
      values.specific_project &&
      values.search_project !== ''
    ) {
      this.props
        .fetchProject(values.search_project)
        .then(result => {
          if (result && result.stagesavailable) {
            this.setState({
              stages: result.stagesavailable
            });
            this.props.setFieldError('search_project', null);
          } else {
            this.setState({
              stages: []
            });
            this.props.setFieldValue('project_stage', '');
            this.props.setFieldError(
              'search_project',
              this.props.t('no_project_with_id')
            );
          }
        })
        .catch(() => {
          this.setState({
            stages: []
          });
          this.props.setFieldValue('project_stage', '');
          this.props.setFieldError(
            'search_project',
            this.props.t('no_project_with_id')
          );
        });
    }
  }

  onSavedQueryChange = query => {
    this.props.setFieldValue('aggregate_results', false);
    // TODO pass the values if any
    this.props.setCurrentQuery(query, []);
  };

  fetchProjectName = nameFilterValue => {
    return this.props
      .fetchProjectListAsAdmin(
        nameFilterValue ? { name: nameFilterValue } : {},
        { field: 'name', direction: 'asc' },
        {}
      )
      .then(() => {
        return this.props.projects.map(p => p.name);
      });
  };

  render() {
    const {
      t,
      isSubmitting,
      setFieldValue,
      values,
      savedQueries,
      errors
    } = this.props;
    const { isLoaded, stages } = this.state;
    const queryObject = savedQueries.find(q => q.name === values.savedQuery);
    const aggregationDisabled =
      values.savedQuery === '' ||
      !queryObject ||
      !queryObject.aggregations ||
      queryObject.aggregations.length === 0;
    return (
      <Container>
        {isLoaded && (
          <Form>
            <Row>
              <Col>
                <Field
                  render={props => (
                    <CustomInput
                      name="specific_project"
                      type="switch"
                      id="specify-project-switch"
                      checked={props.form.values.specific_project}
                      onChange={e => {
                        props.form.setFieldValue(
                          'specific_project',
                          !props.form.values.specific_project
                        );
                      }}
                      label={t('specific_project')}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mt-3">
                <Field
                  type="select"
                  label={t('select_query_label')}
                  size="sm"
                  name="savedQuery"
                  placeholder={t('select_query_placeholder')}
                  options={savedQueries.map(query => query.name)}
                  onChange={this.onSavedQueryChange}
                  component={CustomSelectInput}
                />

                <Button
                  className=" ml-1 mb-3"
                  type="submit"
                  color="success"
                  disabled={isSubmitting}>
                  {isSubmitting ? (
                    <span
                      className="ml-2 spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <i className="material-icons md-36">play_arrow</i>
                  )}
                </Button>
              </Col>
              {!values.specific_project && (
                <Col className="mt-3">
                  <Field
                    type="select"
                    name="predefined_stages"
                    size="sm"
                    label={t('select_stage_label')}
                    placeholder={t('select_stage_placeholder')}
                    options={predefined_stages.map(s => ({
                      label: s.display_name,
                      value: s.name
                    }))}
                    component={CustomSelectInput}
                  />
                  <Field
                    render={props => (
                      <CustomInput
                        id="agregate-result-checkbox"
                        name="aggregate_results"
                        type="checkbox"
                        disabled={aggregationDisabled}
                        label={t('aggregate_results')}
                        checked={props.form.values.aggregate_results}
                        onChange={e => {
                          props.form.setFieldValue(
                            'aggregate_results',
                            e.target.checked
                          );
                        }}
                      />
                    )}
                  />
                  {values.aggregate_results &&
                    queryObject.aggregations &&
                    queryObject.aggregations.length > 0 && (
                    <Field
                      render={props => (
                        <div
                          className={classnames('mt-2 p-2', {
                            'border border-danger rounded':
                                errors.aggregation_choice
                          })}>
                          {queryObject.aggregations.map((aggr, i) => {
                            return (
                              <CustomInput
                                type="radio"
                                key={`aggregation-choice-${i}`}
                                id={`aggregation-choice-${i}`}
                                value={aggr.field}
                                name="aggregation_choice"
                                onChange={e => {
                                  setFieldValue(
                                    'aggregation_choice',
                                    e.target.value
                                  );
                                }}
                                label={aggr.description}
                              />
                            );
                          })}

                          {errors.aggregation_choice && (
                            <div
                              className="text-danger mt-0 aggregation_choice_font_size"
                              >
                              {errors.aggregation_choice}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  )}
                </Col>
              )}

              {values.specific_project && (
                <Col className="mt-3">
                  <Field
                    name="search_project"
                    type="text"
                    label={t('project:project_name_placeholder')}
                    validate={this.validateProjectSearch}
                    render={props => {
                      return (
                        <AutocompleteInput
                          {...props}
                          type="text"
                          id="project-autocomplete"
                          label={t('choose_project')}
                          placeholder={t('enter_project_name_or_id')}
                          className=""
                          objects={this.props.projects.map(p => p.name)}
                          onChange={e => {
                            setFieldValue('search_project', e.target.value);
                          }}
                          onSelectionChange={projectName => {
                            const project = this.props.projects.find(
                              p => p.name === projectName
                            );
                            setFieldValue(
                              'search_project',
                              project ? project.project_id : ''
                            );
                          }}
                          onFilter={value => {
                            return this.fetchProjectName(value);
                          }}
                          fetchObject={() =>
                            this.fetchProjectName().then(
                              () => this.props.projects
                            )
                          }
                        />
                      );
                    }}
                  />
                  <Field
                    type="select"
                    name="project_stage"
                    size="sm"
                    label={t('select_stage_label')}
                    placeholder={t('select_stage_placeholder')}
                    options={stages.map(s => ({
                      label: s.display_name,
                      value: s.name
                    }))}
                    component={CustomSelectInput}
                  />
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const projects = Object.keys(state.projects).map(project_id => {
    return { ...state.projects[project_id], project_id };
  });
  return {
    savedQueries: state.bimrl.savedQueries,
    projects
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSavedQueries: () => dispatch(fetchBIMRLSavedQueries()),
    fetchSimilarStages: stageName => dispatch(fetchSimilarStages(stageName)),
    resetResults: () => dispatch({ type: 'RESET_BIMRL_RESULT' }),
    executeSavedQuery: (projectId, stageName, values, savedQueryName) =>
      dispatch(
        getSavedQueryBIMRLProjectResult(
          projectId,
          stageName,
          values,
          savedQueryName
        )
      ),
    fetchProjectListAsAdmin: (filters, order, pagination) =>
      dispatch(fetchProjectListAsAdmin(filters, order, pagination)),
    fetchProject: projectId => dispatch(fetchProject(projectId)),
    runAggregatedQuery: (stageName, savedQueryName, aggregationChoice) =>
      dispatch(runAggregatedQuery(stageName, savedQueryName, aggregationChoice))
  };
};

const formikConfig = {
  mapPropsToValues: () => ({
    aggregate_results: false,
    aggregation_choice: '',
    specific_project: false,
    search_project: '',
    savedQuery: '',
    project_stage: '',
    predefined_stages: ''
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.setIsFetchingGlobalResult(true);
    props.resetCollapsedResult();
    props.resetLoadedResult();
    props.resetErroredResult();
    props.resetResults();
    if (values.specific_project) {
      const stageName = values.project_stage;
      const projectId = values.search_project;

      props
        .executeSavedQuery(projectId, stageName, [], values.savedQuery)
        .then(() => {
          setSubmitting(false);
          props.setIsFetchingGlobalResult(false);
        })
        .catch(err => {
          props.setIsFetchingGlobalResult(false);
          setSubmitting(false);
        });
    } else {
      if (!values.aggregate_results) {
        props
          .fetchSimilarStages(values.predefined_stages)
          .then(() => {
            props.setIsFetchingGlobalResult(false);
            setSubmitting(false);
          })
          .catch(err => {
            setSubmitting(false);
          });
      } else {
        props
          .runAggregatedQuery(
            values.predefined_stages,
            values.savedQuery,
            values.aggregation_choice
          )
          .then(() => {
            props.setIsFetchingGlobalResult(false);
            setSubmitting(false);
          })
          .catch(err => {
            props.setIsFetchingGlobalResult(false);
            setSubmitting(false);
          });
      }
    }
  },
  // validation on blur doesn't work well with the autocomplete compoenent as it is
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: props =>
    Yup.object().shape({
      savedQuery: Yup.string().required(props.t('required')),
      specific_project: Yup.boolean(),
      aggregate_results: Yup.boolean(),
      project_stage: Yup.string().when('specific_project', {
        is: true,
        then: Yup.string().required(props.t('required'))
      }),
      search_project: Yup.string().when('specific_project', {
        is: true,
        then: Yup.string().required(props.t('required'))
      }),
      predefined_stages: Yup.string().when('specific_project', {
        is: false,
        then: Yup.string().required(props.t('required'))
      }),
      aggregation_choice: Yup.string().when(
        ['specific_project', 'aggregate_results'],
        (specific_project, aggregate_results, schema) => {
          if (!specific_project && aggregate_results) {
            return schema.required('required');
          }
          return schema;
        }
      )
      // aggregation_choice: Yup.string().when(
      //   ['specific_project', 'aggregate_results'],
      //   {
      //     is: (specific_project, aggregate_results) =>
      //       !aggregate_results && aggregate_results,
      //     then: Yup.string().required(props.t('required'))
      //   }
      // )
    }),
  validate: (values, props) => {
    if (values.specific_project) {
      // verify the project id exist in DB
      return props
        .fetchProject(values.search_project)
        .then(result => {
          if (!result) {
            throw { search_project: props.t('no_project_with_id') };
          }
        })
        .catch(err => {
          throw { search_project: props.t('no_project_with_id') };
        });
    }
  },
  isInitialValid: false,
  displayName: 'BIMRLQueryForm'
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['bimrlData']),
  withFormik(formikConfig)
)(QueryForm);
