import { performRequest } from '../requests';
import { modelIssueActionType } from '../../constants/actionType';
const {
  FETCH_ALL_ISSUES,
  FETCH_ISSUE,
  RESET_ISSUE_LIST,
  FETCH_COMMENTS,
  ADD_COMMENT,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  RESET_ATTACHMENTS,
  REMOVE_ISSUE
} = modelIssueActionType;

export const resetModelIssueList = () => dispatch => {
  return dispatch({
    type: RESET_ISSUE_LIST
  });
};

export const fetchAllModelIssues = (projectId, stageName) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({
    type: RESET_ISSUE_LIST
  });
  return dispatch(
    performRequest(
      'get',
      `api/issue/${projectId}/${stageName}/all`,
      {},
      requestConfig,
      'FETCH_LIST_ISSUES'
    )
  )
    .then(res => res.data.data)
    .then(issueList => {
      dispatch({
        type: FETCH_ALL_ISSUES,
        issueList
      });
      return issueList;
    });
};

export const fetchAllModelIssuesPerProject = projectId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/issue/${projectId}/all`,
      {},
      requestConfig,
      'FETCH_LIST_ISSUES'
    )
  )
    .then(res => res.data.data)
    .then(issueList => {
      dispatch({
        type: FETCH_ALL_ISSUES,
        issueList
      });
      return issueList;
    });
};

export const fetchModelIssueDetails = (
  projectId,
  stageName,
  issueId
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/issue/${projectId}/${stageName}/${issueId}/detail`,
      {},
      requestConfig,
      'FETCH_ISSUE'
    )
  )
    .then(res => res.data.data)
    .then(issueData => {
      dispatch({
        type: FETCH_ISSUE,
        issueData,
        issueId
      });
    });
};

//TODO
export const addComment = ({
  projectId,
  stageName,
  issueId,
  commentData
}) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `api/project/${projectId}/${stageName}/issues/${issueId}/addComment`,
      { commentData },
      requestConfig,
      'ADD_COMMENT'
    )
  )
    .then(res => res.data)
    .then(commentData => {
      dispatch({
        type: ADD_COMMENT,
        commentData
      });
    });
};
// NOT USED
// export const fetchComments = (
//   projectId,
//   stageName,
//   issueId,
//   commentData
// ) => dispatch => {
//   const requestConfig = {
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     withCredentials: true
//   };
//   return dispatch(
//     performRequest(
//       'post',
//       `api/project/${projectId}/${stageName}/issues/${issueId}/commentList`,
//       { commentData },
//       requestConfig,
//       'FETCH_COMMENT_LIST'
//     )
//   )
//     .then(res => res.data)
//     .then(commentList => {
//       dispatch({
//         type: FETCH_COMMENTS,
//         commentList
//       });
//     });
// };

// ATTACHMENTS
export const addAttachments = file => dispatch => {
  return dispatch({
    type: ADD_ATTACHMENTS,
    file: { ...file, dirty: true }
  });
};

export const removeAttachment = file => dispatch => {
  return dispatch({
    type: REMOVE_ATTACHMENT,
    file
  });
};

export const resetAttachment = () => dispatch => {
  return dispatch({
    type: RESET_ATTACHMENTS
  });
};

export const uploadAttachments = () => {};

export const deleteModelIssue = (
  projectId,
  stageName,
  issueIds
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'delete',
      `api/issue/${projectId}/${stageName}/delete`,
      { issueIds },
      requestConfig,
      'REMOVE_ISSUE'
    )
  )
    .then(res => res.data)
    .then(issueData => {
      dispatch({
        type: REMOVE_ISSUE,
        issueData: issueData.data
      });
      return issueData;
    });
};
