import React, { Component } from 'react';
import {
  Container,
  Button,
  UncontrolledCollapse,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import CreateNewsModal from './CreateNewsModal';
import { Link /* , withRouter */ } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { dateFormatting } from '../../../../locales/dateFormat';

import { isEqual } from 'lodash';
import { getURLParameters } from '../../../../utils/url';

import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

import styles from './News.module.css';

import { createMarks } from '../exportToFileUtil';

import ReactTable from '../../../Shared/ReactTable';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'created_at',
      order: d || 'desc'
    }
  ];
};

class NewsCreationPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      modal: false,
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      collapse: {},
      filters: {},
      formats: ['xlsx', 'csv'],
      selectedNewsIds: [],
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFileRange: [0, 0],
      exportFormat: 'csv',
      order: { dataField: 'created_at', order: 'desc' },
      loadingItemToExport: false
    };
  }

  componentDidMount() {
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'created_at') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'created_at', direction: d || 'desc' }
      },
      () => {
        this.fetchNews();
      }
    );
  }

  componentWillUnmount() {
    this.props.resetNews();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchNews();
    }
  }

  fetchNews() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .fetchAllNews(filters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,
            exportRange: [from + 1, to >= totalSize ? totalSize : to],
            exportFileRange: [from, to >= totalSize ? totalSize : to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString(filters)}`
            );
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  filterToURLString(filters) {
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'created_at') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (cur === 'created_at') {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'created_at') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  exportToXLS = async (format, range) => {
    const filtered_by_selected = await this.props.fetchAllNews(
      this.state.filters,
      this.state.order,
      {
        from: range[0],
        to: range[1]
      },
      false
    );

    const finalData = filtered_by_selected.map(news => {
      return {
        Title: news.title,
        'Date Created': dateFormatting(news.created_at, 'defaultHour')
      };
    });

    this.props.exportToXLS(finalData, 'json', {
      fileName: `news_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'created_at', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      filters,
      collapse,
      modal,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport
    } = this.state;
    const { newsList, t } = this.props;

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => [
      {
        id: 'title',
        accessor: 'title',
        Header: 'Title',
        Cell: ({ row, value }) => {
          return (
            <Link to={`/admin/news/${row.original.news_id}/details`}>
              {value}
            </Link>
          );
        },
        Filter: ({ column }) => (
          <TextColumnFilter
            placeholder={'Title'}
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'created_at',
        accessor: 'created_at',
        Header: 'Date Created',
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'actions',
        Header: '',
        width: 10,
        Cell: ({ row, value }) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="muted" className="w-100">
                  <i className="fas fa-ellipsis-h" />
                </DropdownToggle>

                <DropdownMenu>
                  <Link
                    className="text-decoration-none"
                    to={`/admin/news/${row.original.news_id}/details`}>
                    <DropdownItem>{t('news:edit')}</DropdownItem>{' '}
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      }
    ];
    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };
    const NoData = () => {
      if (isLoaded) {
        return <h1 className="text-center">NO DATA</h1>;
      }
      return <></>;
    };

    return (
        <Container fluid className=" mx-3 shadow mt-4 p-3 mb-5 bg-light rounded">
          <CreateNewsModal
            {...this.props}
            modal={modal}
            toggle={this.toggleModal}
          />
          <div className="mt-2">
            <h3 className="text-primary">{t('news:news_management')}</h3>
          </div>
          <div className="text-right pb-2">
            <div>
              <Button
                className="mr-1"
                hidden={!(Object.keys(filters).length > 0)}
                outline
                size="md"
                color="secondary"
                onClick={() => this.clearFilter()}>
                <i className="fas fa-times"></i> Clear Filters
              </Button>

              <Button
                onClick={this.toggleModal}
                color="primary"
                outline
                className="mr-1">
                <i className="fas fa-plus" /> {t('news')}
              </Button>
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
                className="float-right"
                disabled={newsList.length === 0}>
                <DropdownToggle color="success" outline>
                  <i className="fas fa-file-export mr-1" />
                  Export to
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.formats.map((format, i) => {
                    return (
                      <DropdownItem
                        key={`export-option-${i}`}
                        onClick={() => {
                          this.openExportModal(format);
                        }}>
                        <i
                          className={`far fa-file-excel mr-2 ${styles.export_dropdown_color}`}
                          color="primary"
                        />
                        {format}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <ReactTable
            columns={reactTableColumns(t)}
            data={newsList}
            pagination={paginationOptions}
            hover
            bordered={false}
            sortByFunc={setOrderBy}
            defaultSorted={defaultSorted()}
            noDataIndication={NoData()}
            loading={!isLoaded}
            remote={{ pagination: true, filter: true, sort: true }}
            overlay={loading =>
              ({ children }) => {
                return (
                  <div className="position-relative">
                    {children}
                    {loading && (
                      <div
                        className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.table_overlay_position}`}>
                        <div
                          className={`spinner-border text-primary ${styles.table_overlay_dimension}`}
                          role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
          />
          <Modal
            isOpen={exportModalOpen}
            size="lg"
            toggle={() =>
              this.setState(prevState => ({
                exportModalOpen: !prevState.exportModalOpen
              }))
            }
            className="">
            <ModalHeader
              toggle={() =>
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }))
              }>
              {t('main:export_records')}
            </ModalHeader>
            <ModalBody>
              <div>{t('main:choose_records_to_export')}</div>
              <div className={styles.modal_size_and_margin}>
                <Range
                  min={1}
                  max={totalSize}
                  marks={createMarks(totalSize)}
                  // allowCross={false}
                  defaultValue={defaultExportRange}
                  value={exportRange}
                  pushable={1}
                  onChange={newRange => {
                    this.setState({
                      exportRange: newRange,
                      exportFileRange: [newRange[0] - 1, newRange[1]]
                    });
                  }}
                />
              </div>
              <div className="text-muted font-italic">
                *Note : only filtered items are included
              </div>
              <div>
                Export items {exportRange[0]} to {exportRange[1]} to a{' '}
                {exportFormat} file.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loadingItemToExport}
                onClick={async () => {
                  this.setState({ loadingItemToExport: true });
                  await this.exportToXLS(exportFormat, exportFileRange);
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }));
                  this.setState({ loadingItemToExport: false });
                }}>
                {loadingItemToExport && (
                  <span
                    className="mr-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t('main:export')}
              </Button>{' '}
              <Button
                color="secondary"
                disabled={loadingItemToExport}
                onClick={() =>
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }))
                }>
                {t('main:cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
    );
  }
}

export default withTranslation(['news', 'main'])(NewsCreationPresenter);
