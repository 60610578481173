import React, { Component } from 'react';
import {
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Row,
  Col
} from 'reactstrap';

import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

const formikConfig = props => {
  const validationSchemaShape = {
    first_name: Yup.string()
      .required(props.t('yup_required'))
      .matches(/^[a-zA-Z ]*$/, props.t('yup_not_allowed')),
    last_name: Yup.string()
      .required(props.t('yup_required'))
      .matches(/^[a-zA-Z ]*$/, props.t('yup_not_allowed')),
    company: Yup.string().required(props.t('yup_required')),
    email: Yup.string()
      .email(props.t('yup_email'))
      .required(props.t('yup_required')),
    function: Yup.string().required(props.t('yup_required'))
  };

  if (props.authenticationMethod === 'active_directory') {
    validationSchemaShape.adUsername = Yup.string()
      .typeError('Invalid')
      .required('Required');
  }

  return {
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      email: '',
      function: '' || 'QP',
      adUsername: ''
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape(validationSchemaShape),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { t, createUser, toggle } = props;
      createUser(values).then(res => {
        if (res.result === 'Success') {
          Message.success(props.t('success_created_account'));
          resetForm();
        } else {
          Message.error(props.t('error_email_exist'));
        }
        toggle();
      });
      setSubmitting(false);
    }
  };
};

class UserCreation extends Component {
  render() {
    const { modal, toggle, t, featureList } = this.props;
    const authenticationMethod = featureList.authenticationMethod;

    return (
      <Container>
        <Formik {...formikConfig({ ...this.props, authenticationMethod })}>
          {formikProps => {
            const { resetForm } = formikProps;
            const resetToggleModal = () => {
              resetForm();
              toggle();
            };
            return (
              <Modal
                isOpen={modal}
                toggle={resetToggleModal}
                className={this.props.className}
                backdrop={'static'}
                size="lg">
                <ModalHeader toggle={resetToggleModal}>
                  {t('create_new_user')}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field
                            name="function"
                            type="select"
                            label={t('function')}
                            component={CustomInput}>
                            <option value="QP">{t('qualified_person')}</option>
                            <option value="PO">
                              {t('processing_officer')}
                            </option>
                            <option value="ADMIN">{t('administrator')}</option>
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Field
                            name="first_name"
                            type="text"
                            label={t('first_name')}
                            placeholder={t('first_name')}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Field
                            name="last_name"
                            type="text"
                            label={t('last_name')}
                            placeholder={t('last_name')}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field
                            name="email"
                            type="text"
                            label={t('email')}
                            placeholder={t('email')}
                            prefix={<i className="far fa-envelope" />}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Field
                            name="company"
                            type="text"
                            label={t('company')}
                            placeholder={t('company')}
                            prefix={<i className="far fa-building" />}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          {authenticationMethod === 'active_directory' && (
                            <Field
                              name="adUsername"
                              type="text"
                              label={t('active_directory_username')}
                              placeholder={t('active_directory_username')}
                              prefix={<i className="far fa-building" />}
                              component={CustomInput}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}>
                    {t('add_user')}
                  </Button>{' '}
                  <Button color="secondary" onClick={resetToggleModal}>
                    {t('cancel')}
                  </Button>
                </ModalFooter>
              </Modal>
            );
          }}
        </Formik>
      </Container>
    );
  }
}
export default withTranslation(['admin_user'])(UserCreation);
