import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';

import FrontPage from './components/Main/FrontPage';
import AllNews from './components/Main/FrontPage/NewsUpdates/AllNewsContainer';
import Login from './components/Main/Login';
import Logout from './components/Main/Logout';
import Dashboard from './components/Main/Dashboard';
import Registration from './components/Main/Registration';
import RegisterSucceedMessage from './components/Main/Registration/SuccedMessage';
import RegistrationConfirmation from './components/Main/RegistrationConfirmation';
import ResendToken from './components/Main/ResendToken';
import PrivateRoute from './components/Shared/PrivateRoute';
import Autochecker from './components/Main/AutoCheck';
import Submission from './components/Main/Submission';
import EditProfile from './components/Main/EditProfile';
import Userprofile from './components/Main/UserProfile';

import {
  SubscriptionCreation,
  SubscriptionDetails,
  MainSubscription
} from './components/Main/Subscription/';

import {
  ProjectCreationPage,
  ProjectDetailPage
} from './components/Main/Project';
import RulesManagement from './components/Main/Admin/Rules';
import RulesManagementv2 from './components/Main/Admin/Rules';
import RuleManagementIndex from './components/Main/RuleManagement';
import RuleHistoryManagement from './components/Main/RuleHistoryManagement';
import TermOfUse from './components/UI/Footer/dist/TermOfUse';
import PrivacyStatement from './components/UI/Footer/dist/PrivacyStatement';
import ContactUs from './components/UI/Header/dist/ContactUs';
import Feedback from './components/UI/Header/dist/Feedback';

import PUBResources from './components/UI/Footer/dist/PUBResources';

import SubmissionDetail from './components/Main/Submission/SubmissionDetailContainer';

import {
  AdminNewsListPage,
  AdminNewsDetailPage
} from './components/Main/Admin/News';
import AdminProjectListing from './components/Main/Admin/Project/Listing';
import AdminProjectDetail from './components/Main/Admin/Project/Detail';
import AdminUserListing from './components/Main/Admin/User/UserListingContainer';
import AdminUserDetails from './components/Main/Admin/User/UserDetailContainer';

import StatisticsPage from './components/Main/Admin/Statistics/Graphs/PieGraphPresenter';
import knowledgeHubPage from './components/Main/KnowledgeHub';
import WebViewer from './components/Main/Webviewer';
import UniversalTemplate from './components/Main/Admin/UniversalTemplate';
import ModelIssueDetail from './components/Main/ModelIssue/Detail';
import BIMRLData from './components/Main/Admin/Bimrl_data';
import ConfigPanel from './components/Main/Admin/Configuration_Panel';
import Faq from './components/Main/faq';
import HelpCenter from './components/Main/help_center';

import UserPreference from './components/Main/UserPreference';
import JobListing from './components/Main/Admin/Jobs';
import { isAuthenticated, fetchAllNews, resetNews } from './store/actions';

import {
  NewPassword,
  ResetPassword,
  ForgotPasswordSucceedMessage
} from './components/Main/Password';

import AdminSubmissionList from './components/Main/Admin/Submission';
import AdminSubmissionDetail from './components/Main/Admin/Submission/SubmissionDetailPresenter';

import NotFoundPage from './components/Shared/NotFoundPage';
import PageNotFound from './components/Shared/PageNotFound';
import UserTaskList from './components/Main/TaskManagement/UserTaskList';

import TableLinks from './components/Main/FrontPage/Part2/TableLinks';

import {
  CreateOrganization,
  ViewOrganization
} from './components/Main/Organization';

import VideosTutorial from './components/Main/Tutorial/videos';

import { timeAgoFormatting } from './locales/dateFormat';

import QueryUI from './components/Main/QueryUIComponent';

class Routes extends Component {
  render() {
    const {
      isAuthenticatedAsAdmin,
      isAuthenticated,
      isAuthenticatedAsQP,
      isAuthenticatedAsRMPO,
      isAuthenticatedAsRM,
      isAuthenticatedAsPO,
      featureList
    } = this.props;

    const isSubmissionEnabled = featureList.officerSubmission;
    const isCheckAnalyticsEnabled = featureList.checkAnalytics;
    const isModelIssueEnabled = featureList.modelIssue;
    const isReferenceDocumentEnabled = featureList.documentReference;
    const isRuleParameterEnabled = featureList.ruleParameter;
    const isKnowledgehubEnabled = featureList.knowledgeHub;
    const isNewsEnabled = featureList.news;
    const isAdminConfigEnabled = featureList.administrationConfig;
    const isUserPreferenceEnabled = featureList.userPreference;
    const isTaskManagementEnabled = featureList.taskManagement;
    const isOrganizationEnabled = featureList.organization;
    const isUserRulesEnabled = featureList.userRule;
    const isVideoTutorialsEnabled = featureList.videoTutorial;
    const footerLinksNeedAuthentication =
      featureList.footerDocumentAuthRequired;
    const isStatisticsEnabled = featureList.projectStatistics;
    const isPaymentModuleEnabled = featureList.paymentModule;
    const checkTypes = featureList.checkingType;

    const projectMenuTabs = [
      'models',
      'details',
      ...(isReferenceDocumentEnabled ? ['documents'] : []),
      ...(isModelIssueEnabled ? ['issue'] : []),
      ...(isSubmissionEnabled ? ['submission'] : []),
      ...(isTaskManagementEnabled ? ['task'] : []),
      ...(isStatisticsEnabled ? ['statistics'] : [])
    ];

    const activatedChecks =
      !!checkTypes &&
      checkTypes.reduce((returned, current) => {
        switch (current) {
          case 'REGULATORY':
            return [...returned, 'regulatory'];
          case 'BIM_DATA':
            return [...returned, 'bimdata'];
          case 'BIM_QUALITY':
            return [...returned, 'quality'];
          case 'CLASH_DETECTION':
            return [...returned, 'clash'];
          default:
            return returned;
        }
      }, []);

    return (
      <Switch>
        <Route
          path={'/'}
          render={props => (
            <FrontPage
              {...props}
              fetchAllNews={fetchAllNews}
              resetNews={resetNews}
              timeAgoFormatting={timeAgoFormatting}
            />
          )}
          exact
        />
        <Route path={'/faq'} component={Faq} />
        <PrivateRoute
          path={'/help_center'}
          component={HelpCenter}
          accessFunction={isAuthenticated}
        />
        <PrivateRoute
          path={'/downloads/:category'}
          component={TableLinks}
          exact
          accessFunction={
            footerLinksNeedAuthentication
              ? isAuthenticated
              : () => Promise.resolve(true)
          }
        />

        <PrivateRoute
          path={'/project/:id/:stageName/queryui'}
          component={QueryUI}
          accessFunction={isAuthenticated}
        />

        <PrivateRoute
          path={'/downloads'}
          component={TableLinks}
          exact
          accessFunction={
            footerLinksNeedAuthentication
              ? isAuthenticated
              : () => Promise.resolve(true)
          }
        />
        {isVideoTutorialsEnabled && (
          <Route path={'/videotutorials'} exact component={VideosTutorial} />
        )}

        {isNewsEnabled && (
          <Route path={'/news/all'} component={AllNews} exact />
        )}

        <Route path={'/login'} component={Login} />
        <Route path={'/logout'} component={Logout} />
        <Route path={'/register'} exact component={Registration} />
        <Route
          path={'/register/succeed'}
          exact
          component={RegisterSucceedMessage}
        />

        <Route
          path={'/resend-token/succeed'}
          exact
          component={RegisterSucceedMessage}
        />

        <Route path={'/forgot-password'} component={ResetPassword} exact />

        <Route path={'/resend-token'} component={ResendToken} />
        <Route
          path={'/password_reset/:token/'}
          component={props => <NewPassword {...props} />}
        />
        <Route
          path={'/invitation_confirmation/:token/'}
          component={props => <Registration {...props} isInvitation={true} />}
        />
        <Route
          path={'/password_reset_succeed'}
          exact
          component={ForgotPasswordSucceedMessage}
        />

        <Route
          path={'/account_confirmation/:token/'}
          component={RegistrationConfirmation}
        />

        <Route path={'/term-of-use'} component={TermOfUse} />
        <Route path={'/privacy-statement'} component={PrivacyStatement} />
        <Route path={'/contactUs'} component={ContactUs} />
        <Route path={'/feedback'} component={Feedback} />

        <Route path={'/pubResources'} component={PUBResources} />

        <PrivateRoute
          path={'/profile'}
          exact
          component={Userprofile}
          accessFunction={isAuthenticated}
        />
        <PrivateRoute
          path={'/profile/:tab'}
          component={Userprofile}
          accessFunction={isAuthenticated}
        />
        <PrivateRoute
          path={'/edit-profile'}
          component={EditProfile}
          accessFunction={isAuthenticated}
        />

        {isPaymentModuleEnabled && (
          <PrivateRoute
            path={'/subscription'}
            component={MainSubscription}
            accessFunction={isAuthenticated}
            exact
          />
        )}
        {/* <PrivateRoute
          path={'/subscription/create'}
          component={SubscriptionCreation}
          accessFunction={isAuthenticated}
        /> */}
        {/* <PrivateRoute
          path={'/subscription/my-subscription'}
          component={SubscriptionDetails}
          accessFunction={isAuthenticated}
          exact
        /> */}

        {/* QP ROUTES */}
        <PrivateRoute
          path={'/dashboard'}
          component={props => (
            <Dashboard uploads={this.props.uploads} {...props} />
          )}
          accessFunction={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={'/project/create'}
          component={ProjectCreationPage}
          accessFunction={isAuthenticated}
        />

        <PrivateRoute
          exact
          path={'/project/:id/:stageName/autocheck'}
          component={Autochecker}
          accessFunction={isAuthenticated}
        />
        {/* <PrivateRoute
          exact
          path={'/project/:id/:stageName/viewer'}
          component={WebViewer}
          accessFunction={isAuthenticated}
        /> */}
        {isSubmissionEnabled && (
          <PrivateRoute
            path={
              '/project/:projectId/stage/:stageName/submission/:submissionId/:tab'
            }
            component={SubmissionDetail}
            accessFunction={isAuthenticated}
            exact
          />
        )}

        {/* <PrivateRoute
          path={'/project/:id/result/all'}
          component={Result}
          accessFunction={isAuthenticated}
        /> */}

        {isTaskManagementEnabled && (
          <PrivateRoute
            path={'/userTaskList'}
            component={UserTaskList}
            accessFunction={isAuthenticated}
            exact
          />
        )}

        <PrivateRoute
          exact
          path={'/project/:id'}
          component={ProjectDetailPage}
          accessFunction={isAuthenticated}
        />

        {isModelIssueEnabled && (
          <PrivateRoute
            exact
            path={'/project/:id/:tab(issue)/:stageName/:issueId'}
            component={ProjectDetailPage}
            accessFunction={isAuthenticated}
          />
        )}
        {isTaskManagementEnabled && (
          <PrivateRoute
            exact
            path={'/project/:id/:tab(task)/:task_section(detail|list)/:taskId'}
            component={ProjectDetailPage}
            accessFunction={isAuthenticated}
          />
        )}
        <PrivateRoute
          exact
          path={`/project/:id/:tab(${projectMenuTabs.join('|')})/:stageName`}
          component={ProjectDetailPage}
          accessFunction={isAuthenticated}
        />

        <PrivateRoute
          exact
          // pass a regex to the `tab` parameter so we can test the value
          path={`/project/:id/:tab(${projectMenuTabs.join('|')})`}
          component={ProjectDetailPage}
          accessFunction={isAuthenticated}
        />
        {/* <PrivateRoute
          exact
          path={'/organization/view'}
          component={ViewOrganization}
          accessFunction={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={'/organization/create'}
          component={CreateOrganization}
          accessFunction={isAuthenticated}
        /> */}

        {isKnowledgehubEnabled && (
          <PrivateRoute
            path={'/knowledgeHub'}
            component={knowledgeHubPage}
            accessFunction={isAuthenticated}
          />
        )}

        {isUserPreferenceEnabled && [
          <PrivateRoute
            key="/user_preference"
            exact
            path={'/user_preference'}
            component={UserPreference}
            accessFunction={isAuthenticated}
          />
        ]}
        {isOrganizationEnabled && [
          <PrivateRoute
            key="/organization/view"
            exact
            path={'/organization/view'}
            component={ViewOrganization}
            accessFunction={isAuthenticated}
          />,
          <PrivateRoute
            key="/organization/create"
            exact
            path={'/organization/create'}
            component={CreateOrganization}
            accessFunction={isAuthenticated}
          />
        ]}

        {/* ADMIN ROUTES: */}

        <PrivateRoute
          path={'/admin/projects/list'}
          component={AdminProjectListing}
          accessFunction={isAuthenticatedAsRMPO}
        />
        {isModelIssueEnabled && (
          <PrivateRoute
            exact
            path={'/admin/project/:id/:tab(issue)/:stageName/:issueId'}
            component={ProjectDetailPage}
            accessFunction={isAuthenticatedAsPO}
          />
        )}

        {isTaskManagementEnabled && (
          <PrivateRoute
            exact
            path={
              '/admin/project/:id/:tab(task)/:task_section(detail|list)/:taskId'
            }
            component={ProjectDetailPage}
            accessFunction={isAuthenticatedAsPO}
          />
        )}
        <PrivateRoute
          exact
          path={`/admin/project/:id/:tab(${projectMenuTabs.join(
            '|'
          )})/:stageName`}
          component={AdminProjectDetail}
          accessFunction={isAuthenticatedAsPO}
        />
        <PrivateRoute
          exact
          path={`/admin/project/:id/:tab(${projectMenuTabs.join('|')})`}
          component={ProjectDetailPage}
          accessFunction={isAuthenticatedAsPO}
        />

        <PrivateRoute
          path={'/admin/user/:tab/:id'}
          component={AdminUserDetails}
          accessFunction={isAuthenticatedAsAdmin}
        />
        <PrivateRoute
          exact
          path={'/admin/users/:tab'}
          component={AdminUserListing}
          accessFunction={isAuthenticatedAsAdmin}
        />
        <PrivateRoute
          exact
          path={'/admin/users/:tab/:userId'}
          component={AdminUserListing}
          accessFunction={isAuthenticatedAsAdmin}
        />
        <PrivateRoute
          exact
          path={'/admin/consultations/list'}
          component={AdminSubmissionList}
          accessFunction={isAuthenticatedAsRMPO}
        />
        <PrivateRoute
          exact
          path={
            '/admin/project/:projectId/stage/:stageName/submission/:submissionId/:tab'
          }
          component={AdminSubmissionDetail}
          accessFunction={isAuthenticatedAsPO}
        />
        {isNewsEnabled && (
          <PrivateRoute
            exact
            path={'/admin/news/list'}
            component={AdminNewsListPage}
            accessFunction={isAuthenticatedAsAdmin}
          />
        )}
        {isNewsEnabled && (
          <PrivateRoute
            exact
            path={'/admin/news/:newsId/details'}
            component={AdminNewsDetailPage}
            accessFunction={isAuthenticatedAsAdmin}
          />
        )}
        {isCheckAnalyticsEnabled && (
          <PrivateRoute
            exact
            path={'/admin/statistics'}
            component={StatisticsPage}
            accessFunction={isAuthenticatedAsRMPO}
          />
        )}
        {/* {isRuleParameterEnabled && (
          <PrivateRoute
            exact
            path={'/admin/rules'}
            component={RulesManagementv2}
            accessFunction={isAuthenticatedAsAdmin}
          />
        )} */}
        {isRuleParameterEnabled && [
          <PrivateRoute
            key="/rulemanagement"
            exact
            path={'/rulemanagement'}
            component={RuleManagementIndex}
            accessFunction={isAuthenticated}
          />,
          <PrivateRoute
            key="/rulemanagement/:ruleManagementLevel"
            exact
            path={'/rulemanagement/:ruleManagementLevel'}
            component={RuleManagementIndex}
            accessFunction={isAuthenticated}
          />
        ]}
        <PrivateRoute
          exact
          path={'/admin/rules/:cloneLevel/:ruleLevel/:ruleType/:ruleId'}
          component={UniversalTemplate}
          accessFunction={
            isUserRulesEnabled ? isAuthenticated : isAuthenticatedAsAdmin
          }
        />
        <PrivateRoute
          exact
          path={
            '/admin/rules/:cloneLevel/:ruleLevel/:ruleType/:ruleId/rule_history/:ruleHistoryId'
          }
          component={UniversalTemplate}
          accessFunction={
            isUserRulesEnabled ? isAuthenticated : isAuthenticatedAsAdmin
          }
        />
        <PrivateRoute
          exact
          path={
            '/admin/rules/:cloneLevel/:ruleLevel/:ruleType/:ruleId/rule_history'
          }
          component={RuleHistoryManagement}
          accessFunction={
            isUserRulesEnabled ? isAuthenticated : isAuthenticatedAsAdmin
          }
        />
        {/* {isBIMDatabaseEnabled && (
          <PrivateRoute
            exact
            path={'/admin/bim_data'}
            component={BIMRLData}
            accessFunction={isAuthenticatedAsAdmin}
          />
        )} */}
        {isAdminConfigEnabled && (
          <PrivateRoute
            exact
            path={'/admin/configuration_panel'}
            component={ConfigPanel}
            accessFunction={isAuthenticatedAsAdmin}
          />
        )}

        <PrivateRoute
          exact
          path={'/admin/jobs'}
          component={JobListing}
          accessFunction={isAuthenticatedAsAdmin}
        />

        {/* NOT FOUND ROUTE */}
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    authUser: state.authUser,
    featureList: state.app.featureList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isAuthenticated: () => dispatch(isAuthenticated('ANY')),
    isAuthenticatedAsQP: () =>
      dispatch(isAuthenticated(['QP', 'RM', 'ADMIN', 'PO'])),
    isAuthenticatedAsPO: () => dispatch(isAuthenticated(['ADMIN', 'PO'])),
    isAuthenticatedAsRMPO: () =>
      dispatch(isAuthenticated(['ADMIN', 'RM', 'PO'])),
    isAuthenticatedAsRM: () => dispatch(isAuthenticated(['ADMIN', 'RM'])),
    isAuthenticatedAsAdmin: () => dispatch(isAuthenticated(['ADMIN']))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Routes);
