import { requestActionType } from '../../constants/actionType';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
  case requestActionType.PUSH_REQUEST_IN_PROCESS:
    return pushRequestInProcess(state, action);
  case requestActionType.REMOVE_REQUEST_IN_PROCESS:
    return removeRequestInProcess(state, action);
  default:
    return state;
  }
}

const pushRequestInProcess = (state, action) => {
  const { requestType, dateStarted, promiseRequest } = action;
  const nextStateRequestType = state[requestType]
    ? [...state[requestType], {dateStarted, promiseRequest}]
    : [{dateStarted, promiseRequest}];
  return { ...state, [requestType]: nextStateRequestType };
};

const removeRequestInProcess = (state, action) => {
  const { requestType, dateStarted } = action;
  const nextStateRequestType = state[requestType]
    ? state[requestType].filter(v => v.dateStarted !== dateStarted)
    : [];
  return { ...state, [requestType]: nextStateRequestType };
};
