import React, { Component } from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Badge
} from 'reactstrap';

//WORK IN PROGRESS
//TODO LATER
//Prepare this for later task.....
export default class Attachments extends Component {
  render() {
    const { t, attachments, issueId } = this.props;

    return (
      <div className="shadow p-3 mb-5 rounded">
        <h6 className="text-primary">{t('attachments')}</h6>
        <hr className="pb-3" />

        <Row
          // className="py-4"
          className="pb-4">
          <Col>
            <ListGroup>
              <ListGroupItem className="font-weight-bold">
                {t('uploaded_attachments')}{' '}
                <Badge color="primary">{attachments.length}</Badge>
              </ListGroupItem>
              {attachments.map((file, key) => {
                return (
                  <div key={key}>
                    <ListGroupItem>
                      <Row>
                        <Col sm="10">{file.file_name}</Col>
                        <Col sm="2" className="text-right">
                          <a
                            download
                            href={`${
                              process.env.REACT_APP_API_ENDPOINT[
                                process.env.REACT_APP_API_ENDPOINT.length - 1
                              ] == '/'
                                ? process.env.REACT_APP_API_ENDPOINT.slice(
                                    0,
                                    -1
                                  )
                                : process.env.REACT_APP_API_ENDPOINT
                            }/${file.download_link}`}>
                            <Button
                              id={`download-attachment-${key}`}
                              color="light"
                              outline
                              className="px-2"
                              onClick={e => e.stopPropagation()}>
                              <i className="fas fa-download text-primary" />
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </div>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
