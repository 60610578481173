import React, { Component } from 'react';
import { Link /* , withRouter */ } from 'react-router-dom';
import { dateFormatting } from '../../../../../locales/dateFormat';
import { map, pick } from 'lodash';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Row,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import classnames from 'classnames';
import { Formik, Field, FieldArray, getIn } from 'formik';
import ActionModal from './actionModal';
import * as Yup from 'yup';
import Message from '../../../../UI/Message';
import { getURLParameters } from '../../../../../utils/url';
import { isEqual } from 'lodash';
import styles from './Styles.module.css';

import ReactTable from '../../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../../Shared/ReactTable/columnFilters';

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'created_at',
      order: d || 'desc'
    }
  ];
};

const formikConfig = (
  props,
  toggleActionModal,
  t,
  setConflictEmails,
  allowConflictEmailsToVerify
) => {
  return {
    initialValues: {
      userIds: props.match.params.userId ? [props.match.params.userId] : [],
      action: ''
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      props
        .verifyUserRegistrationAsAdmin(values, allowConflictEmailsToVerify)
        .then(res => {
          if (values.action === 'approve') {
            Message.success(
              t('approve_message', {
                total: res.length
              })
            );
          } else {
            Message.success(t('reject_message', { total: res.length }));
          }

          setSubmitting(false);
          toggleActionModal();
        })
        .catch(err => {
          if (allowConflictEmailsToVerify) {
          } else {
            setConflictEmails(err.conflictEmails);
          }
          setSubmitting(false);
        });
    }
  };
};

class ListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      actionModal: false,
      collapse: {},
      filters: {},
      modalHeader: '',
      buttonElement: {
        color: '',
        icon: '',
        text: ''
      },
      userData: {},
      action: '',
      selectRow: [],
      admin_verification_needed: true,
      isemailAlreadyProcessedOrNotFound: false,
      formats: ['xlsx', 'csv'],
      order: { dataField: 'created_at', order: 'desc' },
      dropdownOpen: false,
      userIds: [],
      conflictEmailsToVerify: [],
      allowConflictEmailsToVerify: false
    };
  }

  componentDidMount() {
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'created_at') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'created_at', direction: d || 'desc' }
      },
      () => {
        this.fetchPendingUsers();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order) ||
      prevProps.users.length !== this.props.users.length ||
      prevProps.match.params.userId !== this.props.match.params.userId
    ) {
      this.fetchPendingUsers();
    }
  }

  fetchPendingUsers() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .fetchPendingUserAsAdmin(this.props.match.params.userId, filters, order, {
        from,
        to
      })
      .then(result => {
        const { totalSize, admin_verification_needed } = result;
        this.setState(
          {
            isLoaded: true,
            totalSize,
            admin_verification_needed: true
            // exportRange: [from, to],
            // defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString()}`
            );
            const checkIfExist = this.props.users.findIndex(
              val => val.user_id === this.props.match.params.userId
            );
            this.setState({
              isemailAlreadyProcessedOrNotFound: checkIfExist === -1
            });
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  exportToXLS = format => {
    const filtered_by_selected =
      this.state.userIds.length > 0
        ? this.props.users.filter(elem =>
            this.state.userIds.find(id => elem.id === id)
          )
        : this.props.users;

    const finalData = filtered_by_selected.map(user => {
      return {
        Name: `${user.first_name} ${user.last_name}`,
        Email: user.email,
        Company: user.company,
        'Creation date': dateFormatting(user.created_at, 'defaultHour')
      };
    });

    this.props.exportToXLS(finalData, 'json', {
      fileName: `user_registration_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };

  filterToURLString() {
    const { filters } = this.state;
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'created_at') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (cur === 'created_at') {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'created_at') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }

              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'created_at', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleActionModal = () => {
    this.setState({
      actionModal: !this.state.actionModal
    });
  };

  onClickApproveButton(setFieldValue, t) {
    this.toggleActionModal();
    setFieldValue('action', 'approve');
    this.setState({
      modalHeader: t('modal_header_approve'),
      buttonElement: {
        color: 'primary',
        icon: 'fa fa-user-check',
        text: t('approve_button')
      },
      action: 'approve'
    });
  }
  onClickRejectButton(setFieldValue, t) {
    this.toggleActionModal();
    setFieldValue('action', 'reject');
    this.setState({
      modalHeader: t('modal_header_reject'),
      buttonElement: {
        color: 'danger',
        icon: 'fa fa-user-times',
        text: t('reject_button')
      },
      action: 'reject'
    });
  }

  onApproveOrRejectSingleUser(userId, action, t) {
    this.props
      .verifyOneUserRegistrationAsAdmin(userId, action)
      .then(res => {
        if (res === 'success') {
          if (action === 'approve') {
            Message.success(t('approve_message'));
          } else {
            Message.success(t('reject_message'));
          }
        }
      })
      .catch(err => {
        Message.error(t(`error:${err.message}`));
      });
  }

  selectRow(userIds) {
    return {
      onSelect: rows => {
        const rowIds = rows.map(r => r.original.user_id);

        userIds.splice(0);
        userIds.push(...rowIds);
        this.setState({
          userIds
        });
      }
    };
  }

  setConflictEmails = conflictEmails => {
    this.setState({
      conflictEmailsToVerify: conflictEmails,
      allowConflictEmailsToVerify: true
    });
  };

  render() {
    const { isLoaded, page, sizePerPage, totalSize, filters, collapse } =
      this.state;
    const { users, t } = this.props;

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => {
      const cols = [
        {
          id: 'name',
          accessor: 'name',
          Header: t('name'),
          Cell: ({ row, value }) => {
            return (
              <Link to={`/admin/user/detail/${row.original.user_id}`}>
                {row.original.first_name} {row.original.last_name}
              </Link>
            );
          },
          Filter: ({ column }) => (
            <TextColumnFilter
              placeholder={t('admin_user:name')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          )
        },
        {
          id: 'email',
          accessor: 'email',
          Header: t('email'),
          Cell: ({ row, value }) => {
            return row.original.email;
          },
          Filter: ({ column }) => (
            <TextColumnFilter
              placeholder={t('admin_user:email')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          )
        },
        {
          id: 'company',
          accessor: 'company',
          Header: t('company'),
          Filter: ({ column }) => (
            <TextColumnFilter
              placeholder={t('admin_user:company')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          )
        },
        {
          id: 'created_at',
          accessor: 'created_at',
          Header: t('creation_date'),
          Cell: ({ row, value }) => {
            return dateFormatting(value, 'defaultHour');
          },
          Filter: ({ column }) => (
            <DateRangeColumnFilter
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          )
        }
      ];

      if (this.state.admin_verification_needed) {
        cols.push({
          id: 'actions',
          Header: '',
          width: 50,
          Cell: ({ row, value }) => {
            return (
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="muted" className="w-100">
                  <i className="fas fa-ellipsis-h" />
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem
                    onClick={el => {
                      this.onApproveOrRejectSingleUser(
                        row.original.id,
                        'approve',
                        t
                      );
                    }}>
                    <i
                      className={`fa fa-user-check ${styles.approveIconColor}`}
                    />
                    <span className="ml-2">Approve</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={el => {
                      this.onApproveOrRejectSingleUser(
                        row.original.id,
                        'reject',
                        t
                      );
                    }}>
                    <i
                      className={`fa fa-user-times ${styles.rejectIconColor}`}
                    />
                    <span className="ml-2">Reject</span>
                  </DropdownItem>{' '}
                  <DropdownItem>
                    <a
                      href={`mailto: ${row.original.email}?Subject=Additional%20Information%20Required`}>
                      <i
                        className={`fa fa-envelope ${styles.sendEmailIconColor}`}
                      />
                      <span className="ml-2">Send Email</span>
                    </a>
                  </DropdownItem>{' '}
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          }
        });
      }

      return cols;
    };

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      }
      return <></>;
    };

    return (
      <>
        <Formik
          {...formikConfig(
            this.props,
            this.toggleActionModal,
            t,
            this.setConflictEmails,
            this.state.allowConflictEmailsToVerify
          )}>
          {formikProps => {
            const { values, setFieldValue } = formikProps;
            return (
              <div>
                {!this.state.admin_verification_needed && (
                  <Alert color="info">
                    <h4 className="alert-heading">
                      <i className="fa fa-info-circle" />
                      <span className="ml-2">
                        {t('admin_verification_turned_off')}
                      </span>
                    </h4>
                    <hr />
                    <p className="mb-0">
                      {t('admin_verification_turned_off_additional_message')}
                    </p>
                  </Alert>
                )}
                {this.state.isemailAlreadyProcessedOrNotFound && (
                  <Alert color="info">
                    <h4 className="alert-heading">
                      <i className="fa fa-info-circle" />
                      <span className="ml-2">
                        {' '}
                        {t('email_has_been_processed')}
                      </span>
                    </h4>
                    <hr />
                    <p className="mb-0">
                      {' '}
                      {t('email_has_been_processed_additional_message')}
                    </p>
                  </Alert>
                )}
                <Modal
                  isOpen={this.state.actionModal}
                  toggle={this.toggleActionModal}
                  className={this.props.className}
                  backdrop={'static'}
                  size="md">
                  <ModalHeader toggle={this.toggle}>
                    {this.state.modalHeader}
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Are you sure you want to {this.state.action} selected
                      request ?
                    </p>
                    {this.state.conflictEmailsToVerify &&
                      this.state.conflictEmailsToVerify.length > 0 && (
                        <div className={styles.conflictAlertMaxHeight}>
                          <Alert color="danger">
                            {t('error:outdated_entity_data_with_list', {
                              entity: 'emails'
                            })}
                          </Alert>

                          <ListGroup>
                            {this.state.conflictEmailsToVerify.map(user => {
                              return (
                                <ListGroupItem>{user.email}</ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        </div>
                      )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      color={this.state.buttonElement.color}
                      disabled={formikProps.isSubmitting}
                      onClick={formikProps.handleSubmit}>
                      <i className={this.state.buttonElement.icon} />
                      <span className="ml-2">
                        {this.state.buttonElement.text}
                      </span>
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggleActionModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                <div className="shadow p-3 mt-3 mb-5 rounded">
                  <Row className="justify-content-between px-3">
                    <h5 className="text-primary">
                      {t('pending_registration')}
                    </h5>

                    <div className="text-right pb-2">
                      <div>
                        <Button
                          className="mr-1"
                          hidden={!(Object.keys(filters).length > 0)}
                          outline
                          size="md"
                          color="secondary"
                          onClick={() => this.clearFilter()}>
                          <i className="fas fa-times"></i> Clear Filters
                        </Button>
                        {this.state.admin_verification_needed && (
                          <>
                            <Button
                              color="primary"
                              className="mr-1"
                              type="submit"
                              outline
                              onClick={() => {
                                this.onClickApproveButton(setFieldValue, t);
                              }}
                              disabled={values.userIds.length === 0}>
                              <i className="fa fa-user-check" />{' '}
                              {/* [{' '}
                              {values.userIds.length} ]  */}
                              {t('multiple_approval')}
                            </Button>
                            <Button
                              color="danger"
                              className="mr-1"
                              outline
                              disabled={values.userIds.length === 0}
                              onClick={() => {
                                this.onClickRejectButton(setFieldValue, t);
                              }}>
                              <i className="fa fa-user-times" />{' '}
                              {t('multiple_reject')}
                            </Button>
                            <Dropdown
                              isOpen={this.state.dropdownOpen}
                              toggle={this.toggleDropdown}
                              className="float-right">
                              <DropdownToggle color="success" outline>
                                <i className="fas fa-file-export mr-1" />
                                Export to
                              </DropdownToggle>
                              <DropdownMenu>
                                {this.state.formats.map((format, i) => {
                                  return (
                                    <DropdownItem
                                      key={`export-option-${i}`}
                                      onClick={() => {
                                        this.exportToXLS(format);
                                      }}>
                                      <i
                                        className={`far fa-file-excel mr-2 ${styles.exportDropdownColor}`}
                                        color="primary"
                                      />
                                      {format}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                  <ReactTable
                    columns={reactTableColumns(t)}
                    data={users}
                    pagination={paginationOptions}
                    hover
                    bordered={false}
                    sortByFunc={setOrderBy}
                    defaultSorted={defaultSorted()}
                    noDataIndication={NoData()}
                    loading={!isLoaded}
                    remote={{ pagination: true, filter: true, sort: true }}
                    selectRow={this.selectRow(values.userIds)}
                    overlay={loading =>
                      ({ children }) => {
                        return (
                          <div className="position-relative">
                            {children}
                            {loading && (
                              <div
                                className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                                <div
                                  className={`spinner-border text-primary ${styles.tableOverlayDimension}`}
                                  role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }}
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default ListingPresenter;
