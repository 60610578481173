import React from 'react';
import { withTranslation } from 'react-i18next';
import { getURLParameters } from '../../../utils/url';
import './styles.module.css';

const iframeComponent = props => {

  const urlParameters = getURLParameters(window.location.href)
  const token = urlParameters.token;
  const viewerMode = urlParameters.viewerMode || '3d';
  if (token) {
    return (
      <iframe
        title="Webviewer"
        className="viewerDimention"
        src={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=${viewerMode}&token=${token}`}
        allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      />
    );
  } else {
    return (
      <div className="alert alert-danger" role="alert">
        {props.t('webviewer_token_not_set')}
      </div>
    );
  }
};
export default withTranslation('error')(iframeComponent);
