import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Container, NavLink as BootstrapNavLink } from 'reactstrap';
import config from '../config/landingPageConfig';
import { withTranslation } from 'react-i18next';

const contact = config.contact;

const ContactSection = props => {
  const { contacts, headerText, registrationHeaderText } = contact;
  const { t } = props;
  return (
    <div className="contact-container">
      <div className="contact-header-container text-center text-white d-flex align-items-center">
        <Container fluid>
          {/* <h1 className="custom-h2">{t(`front:${registrationHeaderText}`)}</h1> */}
          <div className="d-flex justify-content-center py-4">
            <BootstrapNavLink
              className="contact-custom-btn rounded-pill py-3"
              tag={NavLink}
              to="/register">
              <strong>{t(`front:contactRegiter`)}</strong>
            </BootstrapNavLink>
          </div>
        </Container>
      </div>
      <div className="text-center contact-info">
        <Container fluid>
          <h2 className="custom-h2">{t(`front:${headerText}`)}</h2>
          <hr className="fornax-custom-hr" />
          {Object.keys(contacts).map(key => {
            const { subHeaderText, contactList } = contacts[key];
            return (
              <div className="py-3" key={`key-${key}`}>
                <div className="py-1">{t(`front:${subHeaderText}`)}</div>
                <Row>
                  <Col></Col>
                  {contactList.map((item, index) => {
                    return (
                      <Col lg={3} key={`key-${index}`} className="pt-3">
                        {item.icon ? (
                          <div
                            className={`my-2 ${
                              props.isElementInViewport
                                ? `animate-icon-${index}`
                                : ''
                            }`}>
                            <i className={`${item.icon} fa-3x text-muted`} />
                          </div>
                        ) : null}
                        {item.isLink ? (
                          <a
                            className="contact-anchor-email"
                            href={`${item.isEmail ? 'mailto:' : ''}${
                              item.contact
                            }`}>
                            {item.contact}
                          </a>
                        ) : (
                          <div className="contact-anchor-email">
                            {item.contact}
                          </div>
                        )}
                      </Col>
                    );
                  })}
                  <Col></Col>
                </Row>
              </div>
            );
          })}

          {/* <div>{t(`front:${subHeaderText}`)}</div>

          <Row className="py-2">
            {contacts.primary.map((item, index) => {
              return (
                <Col key={`key-${index}`}>
                  {item.icon ? (
                    <div
                      className={`my-2 ${
                        props.isElementInViewport ? `animate-icon-${index}` : ''
                      }`}>
                      <i className={`${item.icon} fa-3x text-dark`} />
                    </div>
                  ) : null}
                  {item.isLink ? (
                    <a
                      className="contact-anchor-email"
                      href={`${
                        item.isEmail ? 'mailto:' : ''
                      }your-email@your-domain.com`}>
                      {item.contact}
                    </a>
                  ) : (
                    <div>{item.contact}</div>
                  )}
                </Col>
              );
            })}
          </Row>
          <div className="mt-3">
            <div>{t(`front:${subHeaderText2}`)}</div>
            <Row>
              {contacts.secondary.map((item, index) => {
                return (
                  <Col lg={12} key={`key-${index}`}>
                    {item.icon ? (
                      <div
                        className={`mt-5 ${
                          props.isElementInViewport
                            ? `animate-icon-${index}`
                            : ''
                        }`}>
                        <i className={`${item.icon} fa-3x text-dark`} />
                      </div>
                    ) : null}

                    {item.isLink ? (
                      <a
                        className="contact-anchor-email"
                        href={`${
                          item.isEmail ? 'mailto:' : ''
                        }your-email@your-domain.com`}>
                        {item.contact}
                      </a>
                    ) : (
                      <div>{item.contact}</div>
                    )}
                  </Col>
                );
              })}
            </Row>
          </div> */}
        </Container>
      </div>
    </div>
  );
};

export default withTranslation(['front'])(ContactSection);
