import {
  projectStageActionType,
  projectActionType
} from '../../constants/actionType';
import { projectStageResultActionType } from '../../constants/actionType';
import { cloneDeep } from 'lodash';
import { performRequest, handleErrorMessageStatus } from '../requests';
import { addCompletedModelFile, resetModelFile } from '../modelFiles';
import { addCompleted2DModelFile, reset2DModelFile } from '../2DmodelFiles';

const { ADD_STAGE, UPDATE_STAGE, RESET_STAGES } =
  projectStageActionType;
const { ADD_STAGE_AVAILABLE, REMOVE_PROJECT_STAGE } = projectActionType;

const { FETCH_STAGE_JOBS } = projectStageResultActionType;

export const fetchProjectStage =
  (projectId, stageName) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'get',
        `api/project/${projectId}/${stageName}`,
        {},
        requestConfig,
        'FETCH_STAGE'
      )
    )
      .then(res => {
        return res.data;
      })
      .then(stageData => {
        const { models, models2D, ...otherStageProps } = stageData.data;

        dispatch(resetModelFile());
        dispatch(reset2DModelFile());
        return Promise.all([
          dispatch({
            type: ADD_STAGE,
            projectId,
            stageName: otherStageProps.name,
            stageData: otherStageProps
          }),
          ...models
            .filter(model => !!model)
            .map(model => dispatch(addCompletedModelFile({ ...model }))),

          ...models2D
            .filter(model2D => !!model2D)
            .map(model2D => dispatch(addCompleted2DModelFile({ ...model2D })))
        ]);
      });
  };

//Stage Creation
export const createStage =
  (projectId, stageData) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        timeout: 0
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/stage/create`,
        stageData,
        requestConfig,
        'CREATE_STAGE'
      )
    )
      .then(response => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(
            response.data.message || response.data.error || 'default_message'
          );
        }
      })
      .then(data => {
        dispatch({
          type: ADD_STAGE_AVAILABLE,
          projectId,
          newStage: {
            display_name: data.stage.display_name,
            name: data.stage.name
          }
        });
        return dispatch(fetchProjectStage(projectId, data.stage.name)).then(
          () => data.stage
        );
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const requestStageComparison =
  (projectId, stageName, targetStageName, checkedRulesForComparison) =>
  (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/stageComparison`,
        { targetStageName, checkedRulesForComparison },
        requestConfig,
        'RUN_AUTO_CHECK'
      )
    ).then(response => {
      return response.data;
    });
    // .then(data => {
    //   dispatch({
    //     type: ADD_STAGE_AVAILABLE,
    //     projectId,
    //     newStage: {
    //       display_name: data.stage.display_name,
    //       name: data.stage.name
    //     }
    //   });
    //   dispatch({
    //     type: ADD_STAGE,
    //     projectId,
    //     stageName: data.stage.name,
    //     stageData: data.stage
    //   });
    //   return data.stage;
    // });
  };

export const fetchStageComparison =
  (projectId, stageName) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'get',
        `api/project/${projectId}/${stageName}/stageComparison`,
        {},
        requestConfig,
        'RUN_AUTO_CHECK'
      )
    ).then(response => {
      console.log(response);
    });
    // .then(data => {
    //   dispatch({
    //     type: ADD_STAGE_AVAILABLE,
    //     projectId,
    //     newStage: {
    //       display_name: data.stage.display_name,
    //       name: data.stage.name
    //     }
    //   });
    //   dispatch({
    //     type: ADD_STAGE,
    //     projectId,
    //     stageName: data.stage.name,
    //     stageData: data.stage
    //   });
    //   return data.stage;
    // });
  };

export const deleteStage =
  (projectId, stageName) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    return dispatch(
      performRequest(
        'delete',
        `api/project/${projectId}/${stageName}`,
        {},
        requestConfig,
        'DELETE_STAGE'
      )
    )
      .then(response => {
        if (response.data.success) {
          return dispatch({
            type: REMOVE_PROJECT_STAGE,
            projectId,
            stageName
          });
        }
      })
      .catch(err => {
        handleErrorMessageStatus(err);
      });
  };

export const fetchProjectStageJobs =
  (projectId, stageName, jobType, filters, order, pagination) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        `api/project/${projectId}/${stageName}/job/list?type=${jobType}`,
        { filters, order, pagination },
        requestConfig,
        'LIST_JOBS_AS_ADMIN',
        true
      )
    )
      .then(res => res.data)
      .then(res => {
        if (res.success) {
          dispatch({
            type: FETCH_STAGE_JOBS,
            projectId,
            stageName,
            jobType,
            stageData: res.data.jobs
          });
          return res.data;
        }

        // if (toDispatch) {
        //   dispatch({
        //     type: LIST_JOBS_AS_ADMIN,
        //     jobList
        //   });
        //   return totalResult;
        // } else {
        //   return jobList;
        // }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          throw (
            {
              message: 'not_authorised'
            } || 'default_message'
          );
        } else if (err.response && err.response.status === 409) {
          throw new Error(
            (err.response && err.response.data && err.response.data.error) ||
              'error.default_message'
          );
        } else {
          throw new Error(
            (err.response && err.response.data && err.response.data.error) ||
              err.message ||
              'error.default_message'
          );
        }
      });
  };

export const updateJobRuleCompleted =
  ({ projectId, stageName, jobId, job, ruleId, completed }) =>
  (dispatch, getState) => {
    const globalState = getState();
    const stagesState = globalState.project_stages;

    const currentStage = cloneDeep(stagesState[projectId].stages[stageName]);
    const currentJobs = currentStage.jobs|| {};
    const currentJobIndex =
      currentJobs &&
      currentJobs[job.type] &&
      currentJobs[job.type].findIndex(j => j.job_id === jobId);
    const currentJob = currentJobIndex >= 0 && currentJobs[job.type][currentJobIndex]
    ;
    // const currentCompletedRules = currentJob.rules_completed || []
    if (!currentJob) {
      if (!currentJobs[job.type]) {
        currentJobs[job.type] = [
          { ...job, rules_completed: [{ rule_id: ruleId, completed }] }
        ];
      } else {
        currentJobs[job.type].push({
          ...job,
          rules_completed: [{ rule_id: ruleId, completed }]
        });
      }
      currentStage.jobs = currentJobs;
    } else {
      const currentRuleIndex =
        (currentJob &&
          currentJob.rules_completed &&
          currentJob.rules_completed.findIndex(r => r.rule_id === ruleId)) ||
        -1;
      const currentRule =
        currentRuleIndex >= 0 && currentJob.rules_completed[currentRuleIndex];
      if (currentRule) {
        const nextRulesCompleted = currentJob.rules_completed;
        nextRulesCompleted.splice(currentRuleIndex, 1, {
          rule_id: ruleId,
          completed
        });
        const nextJobs = { ...currentStage.jobs };
        nextJobs[job.type].splice(currentJobIndex, 1, {
          ...currentJob,
          rules_completed: nextRulesCompleted
        });
        currentStage.jobs = nextJobs;

      } else {
        const nextRulesCompleted = currentJob.rules_completed || [];
        nextRulesCompleted.push({
          rule_id: ruleId,
          completed
        });
        const nextJobs = { ...currentStage.jobs };
        nextJobs[job.type].splice(currentJobIndex, 1, {
          ...currentJob,
          rules_completed: nextRulesCompleted
        });
        currentStage.jobs = nextJobs;

      }
    }

    dispatch({
      type: ADD_STAGE,
      projectId,
      stageName,
      stageData: currentStage
    });
  };
