import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';

import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import 'draft-js-mention-plugin/lib/plugin.css';

import createLinkPlugin from 'draft-js-anchor-plugin';
import editorStyles from './editorStyles.module.css';

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank">
      {props.decoratedText}
    </a>
  );
};

const linkPlugin = createLinkPlugin({
  theme: editorStyles,
  placeholder: 'http://…',
  Link
});

class SimpleMentionEditor extends Component {
  constructor(props) {
    super(props);
    let editorState;
    editorState = EditorState.createWithContent(this.props.messageContent);

    this.state = {
      editorState
    };

    this.userMentionPlugin = createMentionPlugin({
      mutability: 'IMMUTABLE',
      mentionPrefix: '@',
      mentionComponent: mentionProps => {
        return (
          <>
            <>
              <span
                id={`key-${mentionProps.entityKey}-${mentionProps.mention.user_id}`}
                className={`${mentionProps.className}`}>
                {mentionProps.children}
              </span>
              <UncontrolledPopover
                placement="bottom"
                className="popover-container"
                boundariesElement="window"
                trigger="legacy"
                fade={false}
                target={`key-${mentionProps.entityKey}-${mentionProps.mention.user_id}`}>
                <PopoverHeader>{mentionProps.mention.name}</PopoverHeader>
                <PopoverBody>
                  <div className="py-2">
                    <div>
                      <i className="far fa-envelope" />{' '}
                      <span>{mentionProps.mention.email}</span>
                    </div>
                    <div>
                      <i className="far fa-id-badge" />{' '}
                      <span>{mentionProps.mention.role}</span>
                    </div>
                    <div>
                      <i className="fas fa-user-tie" />{' '}
                      <span>{mentionProps.mention.function}</span>
                    </div>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          </>
        );
      }
    });
  }

  onChange = editorState => {
    this.setState({
      editorState
    });
  };

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const plugins = [this.userMentionPlugin, linkPlugin];
    return (
      <div onClick={this.focus}>
        <div>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            readOnly={true}
          />
        </div>
      </div>
    );
  }
}

export default SimpleMentionEditor;
