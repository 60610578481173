import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import config from '../config/landingPageConfig';
import { withTranslation } from 'react-i18next';
import Features from './Features';

const solutions = config.solutions;

const SolutionsSection = props => {
  const { headerText, solutionList } = solutions.solutionsContent;
  return (
    <>
      <div id="landing-page">
        <div className="solutions-container">
          <Container className="text-center" fluid>
            <h2 className="custom-h2">{props.t(`front:${headerText}`)}</h2>
            <hr className="fornax-custom-hr pb-3" />
            <Row className="d-flex justify-content-center text-center solutions-row">
              {solutionList.map((item, index) => {
                return (
                  <Col
                    className="solutions-col"
                    key={`key-${item.title}-${index}`}
                    xl={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 6 }}
                    lg={{ size: 6 }}
                    xl={{ size: 2 }}>
                    <div
                      className={`p-3 solutions-icon-container ${
                        props.isElementInViewport ? `animate-icon-${index}` : ''
                      }`}>
                      <i className={`${item.icon} custom-text-primary fa-3x`} />
                    </div>
                    <h4 className="font-weight-light">
                      {props.t(`front:${item.title}`)}
                    </h4>
                    <div className="text-secondary">
                      <p>{props.t(`front:${item.description}`)}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>

      <Features {...props} />
    </>
  );
};

export default withTranslation(['front'])(SolutionsSection);
