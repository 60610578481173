import React, { Component } from 'react';
import { Row, Col, Container, Spinner } from 'reactstrap';
import { dateFormatting } from '../../../../locales/dateFormat';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { convertFromRaw, convertFromHTML, ContentState } from 'draft-js';

import { fetchCorrespondence } from '../../../../store/actions';
import DisplayEditorList from './DisplayMessage';
import styles from './Correspondence.module.css';

class CorrespondenceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }
  scrollToBottom = () => {
    if (this.messageListContainer) {
      this.messageListContainer.scroll(
        0,
        this.messageListContainer.scrollHeight
      );
    }
  };

  componentDidMount() {
    const { projectId, stageName, submissionId } = this.props;
    this.props
      .fetchCorrespondence({ projectId, stageName, submissionId })
      .then(() => {
        this.setState({ isLoaded: true });
        this.scrollToBottom();
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.messages &&
      this.props.messages &&
      prevProps.messages.length < this.props.messages.length
    ) {
      if (this.state.isLoaded) {
        this.scrollToBottom();
      }
    }
  }

  render() {
    const { translate, messages, stageName, projectId } = this.props;
    const { isLoaded } = this.state;

    return (
      <>
        {isLoaded && (
          <div
            ref={el => {
              this.messageListContainer = el;
            }}
            className={`mb-2 ${styles.overflowMaxheight}`}>
            {messages.map((message, key) => {
              let messageContent;

              try {
                messageContent = convertFromRaw(
                  JSON.parse(decodeURI(message.message_details.content))
                );
              } catch (err) {
                const blocksFromHTML = convertFromHTML(
                  decodeURIComponent(
                    message.message_details.content || '<p> Empty </p>'
                  )
                );
                try {
                  messageContent = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                  );
                } catch (err) {
                  const defaultContent = convertFromHTML(
                    decodeURIComponent('<p> Empty </p>')
                  );
                  messageContent = ContentState.createFromBlockArray(
                    defaultContent.contentBlocks,
                    defaultContent.entityMap
                  );
                }
              }

              return (
                <Container className="border rounded my-3" key={key}>
                  <Row className="p-3 bg-light border-bottom">
                    <Col className="py-1">
                      {!message.message_details.subject ? (
                        <div className="font-italic">
                          {translate('no_subject')}
                        </div>
                      ) : (
                        <div className="font-weight-bold text-break">
                          {message.message_details.subject}
                        </div>
                      )}
                    </Col>
                    <Col>
                      <div className="text-muted text-right">
                        <small>
                          {translate('date')}:{' '}
                          {dateFormatting(
                            message.message_details.date_created,
                            'defaultHour'
                          )}
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row className="p-3">
                    <DisplayEditorList
                      stageName={stageName}
                      projectId={projectId}
                      content={messageContent}
                    />
                  </Row>
                  <Row className="p-3 border-top">
                    <strong className="text-muted">
                      <i className="fas fa-feather-alt" />{' '}
                      {`${message.creator_info.first_name} ${message.creator_info.last_name}`}
                    </strong>
                  </Row>
                </Container>
              );
            })}
            <div
              ref={el => {
                this.messagesEnd = el;
              }}
            />
          </div>
        )}
        {!isLoaded && (
          <div
            className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
            <Spinner
              type="grow"
              className={styles.spinnerDimention}
              color="primary"
            />
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return { messages: state.submission.messageList };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCorrespondence: params => dispatch(fetchCorrespondence(params))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['submission'])
)(CorrespondenceList);
