import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  Container,
  Button,
  Row,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledTooltip,
  CustomInput as CustomInputReactstrap
} from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  fetchFooterData,
  createNewCategory,
  createNewEntry,
  uploadFooterDocuments,
  editEntry,
  deleteEntry,
  addNewVersion,
  deleteMainCategory,
  editRelatedLinks,
  editMainCategory
} from '../../../../../store/actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import classnames from 'classnames';

import { Formik, Field, Form, FieldArray, getIn } from 'formik';

import * as Yup from 'yup';

import CustomInput from '../../../../UI/Input/customInput';
import Message from '../../../../UI/Message';

import ReactTable from '../../../../Shared/ReactTable/index';

import Dropzone from 'react-dropzone';

import CustomFileInput from '../../../../UI/Input/customFileInput';

import FileList from './FileList';
import UploadBox from './Upload';
import { func } from 'prop-types';

import prettyBytes from 'pretty-bytes';
import { set } from 'date-fns';

import './Style.css';

const dropzoneStyle = {
  position: 'relative',
  borderWidth: '2px',
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: '5px',
  height: '118px'
};

const disableDropzoneStyle = {
  ...dropzoneStyle,
  pointerEvents: 'none',
  opacity: '0.5',
  background: '#CCC'
};

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  for (var i = 0; i < sizes.length; i++) {
    if (bytes <= 1024) {
      return `${bytes}${sizes[i]}`;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return `${bytes}P`;
};

const flattenFooterData = (data, category) => {
  const footerData = data;

  return (
    footerData &&
    Object.keys(footerData).reduce((acc, currentKey) => {
      if (currentKey === 'relatedLinks') {
        return {
          ...acc,
          [currentKey]: { ...footerData[currentKey] }
        };
      }
      if (category && currentKey !== category) return acc;

      const mapVersion =
        footerData[currentKey].dataList &&
        footerData[currentKey].dataList.reduce((accDt, currentDt, index) => {
          const check = currentDt.versions.length > 1;

          return [
            ...accDt,
            ...currentDt.versions.map((version, index) => {
              if (!version.version) {
                version.version = 'n/a';
              }

              let description = ``;
              let merge = false;

              if ((check && index === 0) || !check) {
                description = currentDt.description;
                // = true;
              }
              // else if (check && index > 0) {
              // }

              if (check && index < currentDt.versions.length - 1) {
                merge = true;
              }

              version.size =
                typeof version.size === 'number' ? version.size : 0;

              return {
                ...version,
                title: currentDt.title,
                description: description,
                merge,
                index: index
              };
            })
          ];
        }, []);
      return {
        ...acc,
        [currentKey]: {
          ...footerData[currentKey],
          dataList: mapVersion
        }
      };
    }, {})
  );
};

const FooterContainer = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    props
      .fetchFooter()
      .then(() => {
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const customProps = {
    ...props,
    footerData: props.footerData.categories
  };

  return isLoaded && <TableLinks {...customProps} />;
};

const TableLinks = props => {
  const {
    t,
    footerData,
    fetchFooter,
    match,
    createNewCategory,
    createNewEntry,
    uploadFooterDocuments,
    editEntry,
    deleteEntry,
    addNewVersion,
    deleteMainCategory,
    editRelatedLinks,
    editMainCategory
  } = props;

  const highlightedRowRef = useRef(null);
  const scrollToBottom = () => {
    if (highlightedRowRef.current)
      highlightedRowRef.current.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'smooth'
      });
  };

  const [activeTab, setactiveTab] = useState(Object.keys(footerData)[0]);

  const [filesData, setFilesData] = useState([]);

  useEffect(() => {
    setFilesData(flattenFooterData(footerData, activeTab));
    scrollToBottom();
  }, [footerData, activeTab]);

  const [modal, toogleModal] = useState(false);

  const [modalActiveAction, setModalActiveAction] = useState('not_open');

  const [versionIndex, setVersionIndex] = useState(0);

  const showModal = async (activeAction = false) => {
    if (!!activeAction) {
      await setModalActiveAction(activeAction);
      toogleModal(true);
    } else {
      await setModalActiveAction('not_open');
      toogleModal(false);
    }
  };

  const main_category_based_on_trans = Object.keys(footerData).map(
    categoryKey => `${t(`${categoryKey}`)}`
  );

  const form_field = {
    action: {
      create_new_list: {
        validate_fields: [
          'title',
          'version_date',
          'description',
          'files',
          'content_visibility'
        ],
        action: true
      },
      edit_list: {
        validate_fields: [
          'title',
          'version_date',
          'description',
          'files',
          'content_visibility'
        ],
        action: true
      },
      edit_list_related_links: {
        validate_fields: ['title', 'url_link'],
        action: true
      },
      add_main_category: {
        validate_fields: [
          'main_category_name',
          'title',
          'version_date',
          'description',
          'files',
          'content_visibility'
        ],
        action: true
      },
      add_new_version: {
        validate_fields: ['version_date', 'files', 'content_visibility'],
        action: true
      },
      delete_confirmation: {
        validate_fields: [],
        action: true
      },
      edit_main_category: {
        validate_fields: ['main_category_name', 'content_visibility'],
        action: true
      }
    }
  };

  const formikConfig = {
    initialValues: {
      main_category_name: '',
      title: '',
      version_date: '',
      description: '',
      files: [],
      active_action: 'none',
      sub_category_title: '',
      url_link: '',
      document_type: 'download',
      content_visibility: { ADMIN: true, QP: true, PO: true, NotLoggedIn: true }
    },
    validationSchema: Yup.object().shape({
      active_action: Yup.string(),
      main_category_name: Yup.string()
        .when('active_action', {
          is: val => {
            return (
              val !== 'none' &&
              form_field.action[val].validate_fields.includes(
                'main_category_name'
              )
            );
          },
          then: Yup.string().required('Required')
        })
        .test(
          'main_category_name',
          'exits on list',
          function test(currentValue, values) {
            const check = main_category_based_on_trans.findIndex(
              val => val === currentValue
            );
            ///t(activeTab) !== currentValue means if active tab ==== value it should pass
            if (check >= 0 && t(activeTab) !== currentValue) {
              return false;
            } else {
              return true;
            }
          }
        ),
      title: Yup.string().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes('title')
          );
        },
        then: Yup.string().required('Required')
      }),
      version_date: Yup.string().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes('version_date')
          );
        },
        then: Yup.string().required('Required')
      }),
      description: Yup.string().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes('description')
          );
        },
        then: Yup.string().required('Required')
      }),
      files: Yup.array().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes('files')
          );
        },
        then: Yup.array().min(1, 'File list must have at least 1 item')
      }),

      url_link: Yup.string().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes('url_link')
          );
        },
        then: Yup.string().required('Required')
      }),
      content_visibility: Yup.object().when('active_action', {
        is: val => {
          return (
            val !== 'none' &&
            form_field.action[val].validate_fields.includes(
              'content_visibility'
            )
          );
        },
        then: Yup.object().required('Required')
      })
    }),
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      const { main_category_name } = values;
      values.main_category_key = activeTab;

      const process = {
        add_main_category: async () => {
          await createNewCategory(values);
          await uploadFooterDocuments(
            values.main_category_key,
            values.files[0],
            values.document_type
          );
          Message.success(
            t('admin_footer_management:category_added_successfuly')
          );
        },
        create_new_list: async () => {
          await createNewEntry(values);
          await uploadFooterDocuments(
            values.main_category_key,
            values.files[0],
            values.document_type
          );
          Message.success(
            t('admin_footer_management:entry_added_successfully')
          );
        },
        edit_list: async () => {
          await editEntry(values);
          await uploadFooterDocuments(
            values.main_category_key,
            values.files[0],
            values.document_type
          );
          Message.success(
            t('admin_footer_management:entry_edited_successfully')
          );
        },
        add_new_version: async () => {
          await addNewVersion(values);
          await uploadFooterDocuments(
            values.main_category_key,
            values.files[0],
            values.document_type
          );
        },
        edit_list_related_links: async () => {
          await editRelatedLinks(values);
          Message.success(
            t('admin_footer_management:entry_edited_successfully')
          );
        },
        edit_main_category: async () => {
          await editMainCategory(values);
          Message.success(
            t('admin_footer_management:category_edited_successfully')
          );
        }
      };

      try {
        await process[modalActiveAction]();
        setactiveTab(!!main_category_name ? main_category_name : activeTab);
      } catch (err) {
        setactiveTab(activeTab);
        Message.error(t(`error:${err.message}`));
      } finally {
        setSubmitting(false);
        resetForm();
        toogleModal();
      }
    }
  };

  const listedRoles = t => {
    return {
      ADMIN: {
        id: 1,
        label: t('front:administrator')
      },
      PO: {
        id: 2,
        label: t('front:processing_officer')
      },
      QP: {
        id: 3,
        label: t('front:qualified_person')
      },
      NotLoggedIn: {
        id: 4,
        label: t('front:not_logged_in')
      }
    };
  };

  return (
    <Formik {...formikConfig}>
      {formikProps => {
        const {
          isSubmitting,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          onSubmit,
          validateForm,
          setFieldTouched,
          registerField,
          resetForm,
          submitForm,
          setValues
        } = formikProps;

        const form_format = withCreateMainCategory => {
          return (
            <Form>
              {!!withCreateMainCategory && (
                <div>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          name="main_category_name"
                          type="text"
                          label={t(
                            'admin_footer_management:main_category_name'
                          )}
                          placeholder={t(
                            'admin_footer_management:main_category_name'
                          )}
                          prefix={<i className="fa fa-list-alt" />}
                          component={CustomInput}
                        />
                      </FormGroup>
                    </Col>
                    {!!values.content_visibility && (
                      <Col>
                        <Label>{t('front:visibility')} </Label>
                        {Object.keys(values.content_visibility).map(
                          (role, index) => (
                            <Field
                              key={`create_category-${index}`}
                              disabled={role === 'ADMIN'}
                              name="content_visibility"
                              checked={!!values.content_visibility[role]}
                              id={listedRoles(t)[role].id}
                              type="checkbox"
                              label={listedRoles(t)[role].label}
                              component={CustomInputReactstrap}
                              onChange={async e => {
                                const isSelected = e.target.checked;

                                await setValues({
                                  ...values,
                                  content_visibility: {
                                    ...values.content_visibility,
                                    [role]: isSelected
                                  }
                                });
                              }}
                            />
                          )
                        )}
                      </Col>
                    )}
                  </Row>
                  <hr />
                  <h6 className="text-primary text-center">
                    Add entry to this category
                  </h6>
                  <hr />
                </div>
              )}
              <Row>
                <Col>
                  <Field
                    name="title"
                    type="text"
                    label={t('admin_footer_management:title')}
                    placeholder={t('admin_footer_management:title')}
                    component={CustomInput}
                    prefix={<i className="fas fa-info" />}
                  />
                </Col>
                <Col>
                  <Field
                    name="version_date"
                    type="text"
                    label={t('admin_footer_management:version')}
                    placeholder={t('admin_footer_management:version')}
                    component={CustomInput}
                    prefix={<i className="fas fa-calendar" />}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="description"
                    type="textarea"
                    label={t('admin_footer_management:description')}
                    placeholder={t('admin_footer_management:description')}
                    component={CustomInput}
                  />
                </Col>
              </Row>
              {!!values.content_visibility && !withCreateMainCategory && (
                <Row>
                  <Col>
                    <Label>{t('front:visibility')} </Label>
                    {Object.keys(values.content_visibility).map(
                      (role, index) => (
                        <Field
                          key={`create_category-${index}`}
                          disabled={role === 'ADMIN'}
                          name="content_visibility"
                          checked={!!values.content_visibility[role]}
                          id={listedRoles(t)[role].id}
                          type="checkbox"
                          label={listedRoles(t)[role].label}
                          component={CustomInputReactstrap}
                          onChange={async e => {
                            const isSelected = e.target.checked;

                            await setValues({
                              ...values,
                              content_visibility: {
                                ...values.content_visibility,
                                [role]: isSelected
                              }
                            });
                          }}
                        />
                      )
                    )}
                  </Col>
                </Row>
              )}

              <hr />

              <div id="document-type-radio-group">Document type</div>
              <div role="group" aria-labelledby="document-type-radio-group">
                <Field
                  id={'document-type-radio-download'}
                  type="radio"
                  label={t('download')}
                  checked={values.document_type === 'download'}
                  onChange={e => {
                    setFieldValue('document_type', 'download');
                  }}
                  component={CustomInputReactstrap}
                  name="document_type"
                  value="download"
                />
                <Field
                  id={'document-type-radio-video'}
                  type="radio"
                  label={t('video')}
                  checked={values.document_type === 'video'}
                  onChange={e => {
                    setFieldValue('document_type', 'video');
                  }}
                  component={CustomInputReactstrap}
                  name="document_type"
                  value="video"
                />
              </div>

              <hr />
              <Row
                className={classnames('p-3', {
                  'border-danger border border-thick rounded':
                    !!formikProps.errors.files && formikProps.submitCount > 0
                })}>
                <Col xs={12} sm={6}>
                  <UploadBox
                    {...props}
                    formikProps={formikProps}
                    asFormikField={true}
                  />
                  {!!formikProps.errors.files && formikProps.submitCount > 0 && (
                    <div className="alert alert-danger mt-4" role="alert">
                      {formikProps.errors.files}
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={6}>
                  <FieldArray
                    name="files"
                    component={props => {
                      return <FileList {...props} formikProps={formikProps} />;
                    }}
                  />
                </Col>
              </Row>
            </Form>
          );
        };

        const modalAction = {
          add_main_category: {
            size: 'lg',
            ModalHeaderText: t('admin_footer_management:create_category'),
            ModalBodyForm: function (params) {
              const form = form_format(true);
              return form;
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button color="secondary" onClick={() => showModal()}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          edit_main_category: {
            size: 'md',
            ModalHeaderText: t('admin_footer_management:edit_main_category'),
            ModalBodyForm: function ({ setValues, values }) {
              return (
                <Form>
                  <div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field
                            name="main_category_name"
                            type="text"
                            label={t(
                              'admin_footer_management:main_category_name'
                            )}
                            placeholder={t(
                              'admin_footer_management:main_category_name'
                            )}
                            prefix={<i className="fa fa-list-alt" />}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!!values.content_visibility && (
                      <Row>
                        <Col>
                          <FormGroup>
                            {Object.keys(values.content_visibility).map(
                              (role, index) => (
                                <Field
                                  key={`edit-main-category-${index}`}
                                  disabled={role === 'ADMIN'}
                                  name="content_visibility"
                                  checked={!!values.content_visibility[role]}
                                  id={listedRoles(t)[role].id}
                                  type="checkbox"
                                  label={listedRoles(t)[role].label}
                                  component={CustomInputReactstrap}
                                  onChange={async e => {
                                    const isSelected = e.target.checked;

                                    await setValues({
                                      ...values,
                                      content_visibility: {
                                        ...values.content_visibility,
                                        [role]: isSelected
                                      }
                                    });
                                  }}
                                />
                              )
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Form>
              );
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button
                    color="secondary"
                    onClick={() => {
                      showModal();
                    }}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          create_new_list: {
            size: 'lg',
            ModalHeaderText: t('admin_footer_management:create_new_list'),
            ModalBodyForm: function (dex) {
              return form_format();
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button
                    color="secondary"
                    onClick={() => {
                      showModal();
                    }}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          edit_list: {
            size: 'lg',
            ModalHeaderText: t('admin_footer_management:edit_list'),
            ModalBodyForm: function (formikProps) {
              return form_format();
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button color="secondary" onClick={() => showModal()}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          not_open: {
            size: 'lg',
            ModalHeaderText: '',
            ModalBodyForm: function (params) {
              return false;
            },
            ModalFooter: function () {
              return false;
            }
          },
          delete_confirmation: {
            size: 'md',
            ModalHeaderText: t('admin_footer_management:delete_confirmation'),
            ModalBodyForm: function (params) {
              return <p>{t('admin_footer_management:delete_message')}</p>;
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={async () => {
                      if (!!values.sub_category_title) {
                        await deleteEntry(
                          activeTab,
                          values.sub_category_title,
                          versionIndex
                        );
                        showModal();
                        Message.success(
                          t('admin_footer_management:deleted_successfully')
                        );
                        setactiveTab(activeTab);
                      } else {
                        await deleteMainCategory(activeTab).then(res =>
                          setactiveTab(Object.keys(res)[0])
                        );
                        showModal();
                        Message.success(
                          t('admin_footer_management:deleted_successfully')
                        );
                      }
                    }}>
                    {t('admin_footer_management:delete')}
                  </Button>{' '}
                  <Button color="secondary" onClick={() => showModal()}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          add_new_version: {
            size: 'lg',
            ModalHeaderText: t('admin_footer_management:add_new_version'),
            ModalBodyForm: function (formikProps) {
              return (
                <Form>
                  <Row>
                    <Col>
                      <Field
                        name="sub_category_title"
                        type="hidden"
                        component={CustomInput}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Field
                        name="version_date"
                        type="text"
                        label={t('admin_footer_management:version')}
                        placeholder={t('admin_footer_management:version')}
                        component={CustomInput}
                        prefix={<i className="fas fa-calendar" />}
                      />
                      <hr />

                      <div id="document-type-radio-group">Document type</div>
                      <div
                        role="group"
                        aria-labelledby="document-type-radio-group">
                        <Field
                          id={'document-type-radio-download'}
                          type="radio"
                          label={t('download')}
                          checked={values.document_type === 'download'}
                          onChange={e => {
                            setFieldValue('document_type', 'download');
                          }}
                          component={CustomInputReactstrap}
                          name="document_type"
                          value="download"
                        />
                        <Field
                          id={'document-type-radio-video'}
                          type="radio"
                          label={t('video')}
                          checked={values.document_type === 'video'}
                          onChange={e => {
                            setFieldValue('document_type', 'video');
                          }}
                          component={CustomInputReactstrap}
                          name="document_type"
                          value="video"
                        />
                      </div>
                    </Col>
                    {!!values.content_visibility && (
                      <Col>
                        <Label>{t('front:visibility')}</Label>
                        {Object.keys(values.content_visibility).map(
                          (role, index) => (
                            <Field
                              key={`add-version-${index}`}
                              disabled={role === 'ADMIN'}
                              name="content_visibility"
                              checked={!!values.content_visibility[role]}
                              id={listedRoles(t)[role].id}
                              type="checkbox"
                              label={listedRoles(t)[role].label}
                              component={CustomInputReactstrap}
                              onChange={async e => {
                                const isSelected = e.target.checked;

                                await setValues({
                                  ...values,
                                  content_visibility: {
                                    ...values.content_visibility,
                                    [role]: isSelected
                                  }
                                });
                              }}
                            />
                          )
                        )}
                      </Col>
                    )}
                  </Row>

                  <hr />
                  <Row
                    className={classnames('p-3', {
                      'border-danger border border-thick rounded':
                        !!formikProps.errors.files &&
                        formikProps.submitCount > 0
                    })}>
                    <Col xs={12} sm={6}>
                      <UploadBox
                        {...this.props}
                        formikProps={formikProps}
                        asFormikField={true}
                      />
                      {!!formikProps.errors.files &&
                        formikProps.submitCount > 0 && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {formikProps.errors.files}
                          </div>
                        )}
                    </Col>
                    <Col xs={12} sm={6}>
                      <FieldArray
                        name="files"
                        component={props => {
                          return (
                            <FileList {...props} formikProps={formikProps} />
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              );
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button color="secondary" onClick={() => showModal()}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          },
          edit_list_related_links: {
            size: 'lg',
            ModalHeaderText: t(
              'admin_footer_management:edit_list_related_links'
            ),
            ModalBodyForm: function (formikProps) {
              return (
                <Form>
                  <Row>
                    <Col>
                      <Field
                        name="sub_category_title"
                        type="hidden"
                        component={CustomInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        name="title"
                        type="text"
                        label={t('admin_footer_management:title')}
                        placeholder={t('admin_footer_management:title')}
                        component={CustomInput}
                        prefix={<i className="fas fa-info" />}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        name="url_link"
                        type="textarea"
                        label={t('admin_footer_management:url_link')}
                        placeholder={t('admin_footer_management:url_link')}
                        component={CustomInput}
                      />
                    </Col>
                  </Row>
                </Form>
              );
            },
            ModalFooter: function () {
              return (
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}>
                    {t('save')}
                  </Button>{' '}
                  <Button color="secondary" onClick={() => showModal()}>
                    {t('cancel')}
                  </Button>
                </div>
              );
            }
          }
        };

        const RelatedLinksColumns = [
          {
            id: 'title',
            accessor: 'title',
            Header: t('admin_footer_management:title'),
            hidden: activeTab !== 'relatedLinks',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              return <span>{value}</span>;
            }
          },
          {
            id: 'url',
            accessor: 'url',
            Header: t('admin_footer_management:url_link'),
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              return <span>{value}</span>;
            }
          },
          {
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle size="sm" color="muted" className="w-100">
                    <i className="fas fa-ellipsis-h" />
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem header>Action(s)</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        resetForm();

                        const {
                          title,
                          description,
                          version,
                          filename,
                          size,
                          visibility
                        } = row.original;

                        if (activeTab === 'relatedLinks') {
                          setFieldValue(
                            'active_action',
                            'edit_list_related_links'
                          );
                          showModal('edit_list_related_links');
                          setFieldValue('title', t(title));
                          setFieldValue('url_link', row.original.url);
                          setFieldValue('sub_category_title', title);
                        } else {
                          setFieldValue('active_action', 'edit_list');

                          setFieldValue('title', t(title));
                          setFieldValue('description', description);
                          setFieldValue('version_date', version);
                          setFieldValue('content_visibility', visibility);

                          setFieldValue('files', [
                            {
                              name: filename,
                              size: size
                            }
                          ]);
                          setFieldValue('sub_category_title', title);
                          showModal('edit_list');
                        }
                      }}>
                      <i className="fas info fa-edit mr-2" />

                      {t('admin_footer_management:Edit')}
                    </DropdownItem>

                    {activeTab !== 'relatedLinks' && (
                      <DropdownItem
                        onClick={() => {
                          resetForm();
                          setFieldValue('active_action', 'add_new_version');
                          setFieldValue('sub_category_title', row.original.title);
                          showModal('add_new_version');
                        }}>
                        <i className="fas fa-plus mr-2" />
                        {t('admin_footer_management:add_version')}
                      </DropdownItem>
                    )}

                    {!row.isPortalUserGuide && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={async () => {
                            await setVersionIndex(row.index);

                            setFieldValue(
                              'active_action',
                              'delete_confirmation'
                            );

                            setFieldValue('sub_category_title', row.original.title);
                            showModal('delete_confirmation');
                          }}>
                          <i className="fas fa-trash mr-2" />
                          Remove
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }
          }
        ];

        const columns = [
          {
            id: 'filename',
            accessor: 'filename',
            Header: t('admin_footer_management:filename'),
            hidden: activeTab === 'relatedLinks',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              return <span>{value}</span>;
            }
          },
          {
            id: 'version',
            accessor: 'version',
            Header: t('front:version'),
            hidden: activeTab === 'relatedLinks',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ value }) => {
              return <span>{value}</span>;
            }
          },
          {
            id: 'size',
            accessor: 'size',
            Header: t('front:size'),
            hidden: activeTab === 'relatedLinks',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ value }) => {
              return <span>{bytesToSize(value)}</span>;
            }
          },
          {
            id: 'visibility',
            accessor: 'visibility',
            Header: t('front:visibility'),
            hidden: activeTab === 'relatedLinks',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              if (value) {
                return Object.keys(value).map((role, i) => {
                  return (
                    <CustomInputReactstrap
                      key={`role-visibility-${i}`}
                      disabled
                      name="role_visibility"
                      checked={value[role]}
                      id={`role-visibility-${i}`}
                      type="checkbox"
                      label={role}
                    />
                  );
                });
              } else {
                return null;
              }
            }
          },

          // {
          //   id: 'url',
          //   accessor: 'url',
          //   text: t('admin_footer_management:url_link'),
          //   hidden: activeTab !== 'relatedLinks',
          //   disableFilters: true,
          //   disableSortBy: true,
          //   Cell: ({ row, value }) => {
          //     return <span>{bytesToSize(value)}</span>;
          //   }
          // },
          {
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row, value }) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle size="sm" color="muted" className="w-100">
                    <i className="fas fa-ellipsis-h" />
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem header>Action(s)</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        resetForm();

                        const {
                          title,
                          description,
                          version,
                          filename,
                          size,
                          visibility
                        } = row.original;

                        if (activeTab === 'relatedLinks') {
                          setFieldValue(
                            'active_action',
                            'edit_list_related_links'
                          );
                          showModal('edit_list_related_links');
                          setFieldValue('title', t(title));
                          setFieldValue('url_link', row.original.url);
                          setFieldValue('sub_category_title', title);
                        } else {
                          setFieldValue('active_action', 'edit_list');

                          setFieldValue('title', t(title));
                          setFieldValue('description', description);
                          setFieldValue('version_date', version);
                          setFieldValue('content_visibility', visibility);

                          setFieldValue('files', [
                            {
                              name: filename,
                              size: size
                            }
                          ]);
                          setFieldValue('sub_category_title', title);
                          showModal('edit_list');
                        }
                      }}>
                      <i className="fas info fa-edit mr-2" />

                      {t('admin_footer_management:Edit')}
                    </DropdownItem>

                    {activeTab !== 'relatedLinks' && (
                      <DropdownItem
                        onClick={() => {
                          resetForm();
                          setFieldValue('active_action', 'add_new_version');
                          setFieldValue('sub_category_title', row.original.title);
                          showModal('add_new_version');
                        }}>
                        <i className="fas fa-plus mr-2" />
                        {t('admin_footer_management:add_version')}
                      </DropdownItem>
                    )}

                    {!row.isPortalUserGuide && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={async () => {
                            await setVersionIndex(row.index);

                            setFieldValue(
                              'active_action',
                              'delete_confirmation'
                            );

                            setFieldValue('sub_category_title', row.original.title);
                            showModal('delete_confirmation');
                          }}>
                          <i className="fas fa-trash mr-2" />
                          Remove
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }
          }
        ];

        const NoData = () => {
          return <h1>NO DATA</h1>;
        };
        return (
          <div className="">
            <Row className="">
              <Col xs="4" sm="3">
                <Container className="sticky-top">
                  <h5 className="text-muted text-center">
                    {t('admin_footer_management:main_categories')}
                  </h5>
                  <hr />
                  <Nav className="shadow mt-3 rounded" tabs vertical>
                    {Object.keys(footerData).map(categoryKey => {
                      return (
                        <NavItem
                          key={`${categoryKey}`}
                          className={classnames({
                            'border-right border-primary rounded border-sz-3 ':
                              activeTab === categoryKey
                          })}>
                          <NavLink
                            className={classnames(
                              'bg-light rounded',
                              {
                                'active font-weight-bold': true
                              },
                              'borderNone'
                            )}
                            onClick={() => {
                              setactiveTab(categoryKey);
                            }}>
                            {t(`${categoryKey}`)}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <Nav className="shadow mt-3 rounded" tabs vertical>
                    <NavItem
                      key={`add_item`}
                      className={classnames({
                        'border-right border-primary rounded border-sz-3 ':
                          activeTab === 'add_item'
                      })}>
                      <UncontrolledTooltip
                        boundariesElement="window"
                        fade={false}
                        target={`add-cat`}
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('admin_footer_management:add_category')}
                      </UncontrolledTooltip>

                      <Button
                        id={`add-cat`}
                        color="success"
                        className="buttonWidth"
                        onClick={() => {
                          resetForm();
                          setFieldValue('active_action', 'add_main_category');
                          showModal('add_main_category');
                          setactiveTab('');
                        }}>
                        <div className="d-flex justify-content-center">
                          <i className="material-icons">note_add</i>
                          <span className="ml-1">
                            {t('admin_footer_management:add_category')}
                          </span>
                        </div>
                      </Button>
                    </NavItem>
                  </Nav>
                </Container>
              </Col>
              <Col xs="8" sm="9">
                <div className="shadow p-3 mt-3 mb-5 rounded">
                  <Row className="justify-content-between px-3 sticky-top">
                    <div>
                      <Breadcrumb>
                        <BreadcrumbItem>
                          <a href="#"> {t(activeTab)} </a>
                        </BreadcrumbItem>
                      </Breadcrumb>
                      <hr></hr>
                    </div>
                    <div className="text-right pb-2">
                      <UncontrolledTooltip
                        boundariesElement="window"
                        fade={false}
                        target={`add_entry`}
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('admin_footer_management:add_new_entry')}
                      </UncontrolledTooltip>

                      <Button
                        color="warning"
                        id="add_entry"
                        className="mr-1"
                        onClick={() => {
                          resetForm();
                          if (activeTab === 'relatedLinks') {
                            setFieldValue(
                              'active_action',
                              'edit_list_related_links'
                            );
                            showModal('edit_list_related_links');
                          } else {
                            setFieldValue('active_action', 'create_new_list');
                            showModal('create_new_list');
                          }
                        }}>
                        <div className="d-flex justify-content-center">
                          <i className="material-icons text-white">post_add</i>
                          {/* <span className="ml-1 text-muted">
                            {t('add_field')}
                          </span> */}
                        </div>
                      </Button>

                      <UncontrolledTooltip
                        boundariesElement="window"
                        fade={false}
                        target={`edit_main_category`}
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('admin_footer_management:edit_main_category')}
                      </UncontrolledTooltip>

                      <Button
                        color="info"
                        id="edit_main_category"
                        className="mr-1"
                        disabled={
                          !activeTab ||
                          activeTab === 'relatedLinks' ||
                          activeTab === 'generalGuide'
                        }
                        onClick={() => {
                          resetForm();
                          setFieldValue('active_action', 'edit_main_category');
                          setFieldValue('main_category_name', t(activeTab));
                          setFieldValue(
                            'content_visibility',
                            props.footerData[activeTab].visibility
                          );
                          showModal('edit_main_category');

                          // if (activeTab === 'relatedLinks') {
                          //   setFieldValue(
                          //     'active_action',
                          //     'edit_list_related_links'
                          //   );
                          //   showModal('edit_list_related_links');
                          // } else {
                          //   setFieldValue('active_action', 'create_new_list');
                          //   showModal('create_new_list');
                          // }
                        }}>
                        <i className="fas fa-edit" />{' '}
                      </Button>

                      <UncontrolledTooltip
                        boundariesElement="window"
                        fade={false}
                        target={`remove_all_entry`}
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('admin_footer_management:remove_entry')}
                      </UncontrolledTooltip>

                      <Button
                        color="danger"
                        disabled={!activeTab || activeTab === 'generalGuide'}
                        id="remove_all_entry"
                        className="mr-1"
                        onClick={async () => {
                          // await setVersionIndex(row.index);
                          // setFieldValue('active_action', 'delete_confirmation');
                          // setFieldValue('sub_category_title', row.title);
                          showModal('delete_confirmation');
                        }}>
                        <i className="fas fa-trash" />{' '}
                      </Button>
                    </div>
                  </Row>
                  <Row className="justify-content-between px-3 tableContainer">
                    {/* {Object.keys(filesData).map(categoryKey => {
                    const tableData =
                      filesData[categoryKey] && filesData[categoryKey].dataList;
                    const categoryTitle =
                      filesData[categoryKey] &&
                      filesData[categoryKey].categoryTitle;
                    return (
                      <div key={`table-${categoryKey}`}>
                        <h5 className="text-primary text-center">
                          {categoryTitle || 'Downloads'}
                        </h5>
                        <hr></hr>
                      </div>
                    );
                  })} */}
                    {activeTab !== 'relatedLinks' && (
                      <ReactTable
                        keyField="filename"
                        data={
                          (filesData[activeTab] &&
                            filesData[activeTab].dataList) ||
                          []
                        }
                        hover
                        condensed
                        bordered={false}
                        columns={columns}
                        noDataIndication={NoData}
                      />
                    )}

                    {activeTab === 'relatedLinks' && (
                      <ReactTable
                        keyField="filename"
                        data={
                          (filesData[activeTab] &&
                            filesData[activeTab].dataList) ||
                          []
                        }
                        hover
                        condensed
                        bordered={false}
                        columns={RelatedLinksColumns}
                        noDataIndication={NoData}
                      />
                    )}
                  </Row>
                </div>{' '}
              </Col>
            </Row>

            <Modal
              isOpen={!!modal}
              toggle={() => showModal()}
              backdrop="static"
              size={
                modalAction[modalActiveAction] &&
                modalAction[modalActiveAction].size
                  ? modalAction[modalActiveAction].size
                  : 'lg'
              }>
              <ModalHeader toggle={() => showModal()}>
                {(modalAction[modalActiveAction] &&
                  modalAction[modalActiveAction].ModalHeaderText) ||
                  ''}
              </ModalHeader>
              <ModalBody>
                {/* <h3 className="text-primary text-center">
                  <Badge color="secondary">
                    {t(activeTab)}{' '}
                   
                  </Badge>
                </h3> */}

                {modalActiveAction !== 'add_main_category' &&
                  modalActiveAction !== 'delete_confirmation' && (
                    <div>
                      <Breadcrumb>
                        <BreadcrumbItem>
                          <a href="#"> {t(activeTab)} </a>
                        </BreadcrumbItem>

                        {!!values.sub_category_title && (
                          <BreadcrumbItem>
                            <a href="#">{t(values.sub_category_title)}</a>
                          </BreadcrumbItem>
                        )}
                      </Breadcrumb>
                    </div>
                  )}

                <Field
                  name="active_action"
                  type="hidden"
                  component={CustomInput}
                />
                {(modalAction[modalActiveAction] &&
                  modalAction[modalActiveAction].ModalBodyForm(formikProps)) ||
                  ''}
              </ModalBody>
              <ModalFooter>
                {modalAction[modalActiveAction] &&
                  modalAction[modalActiveAction].ModalFooter()}
              </ModalFooter>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    footerData: state.app.footerData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFooter: () => dispatch(fetchFooterData()),
    createNewCategory: values => dispatch(createNewCategory(values)),
    createNewEntry: values => dispatch(createNewEntry(values)),
    uploadFooterDocuments: (main_category_key, documentsAdded, documentType) =>
      dispatch(
        uploadFooterDocuments(main_category_key, documentsAdded, documentType)
      ),
    editEntry: values => dispatch(editEntry(values)),
    addNewVersion: values => dispatch(addNewVersion(values)),
    deleteEntry: (main_category_key, sub_category_title, versionIndex) =>
      dispatch(
        deleteEntry(main_category_key, sub_category_title, versionIndex)
      ),
    deleteMainCategory: main_category_key =>
      dispatch(deleteMainCategory(main_category_key)),
    editRelatedLinks: values => dispatch(editRelatedLinks(values)),
    editMainCategory: values => dispatch(editMainCategory(values))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['front'])
)(FooterContainer);
