import React, { Component, useState } from 'react';
import {
  Card,
  Button,
  FormGroup,
  Col,
  Row,
  Container,
  Modal,
  ModalBody,
  Alert,
  ModalHeader,
  CustomInput as CustomReactstrapInput
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { withTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import CustomInput from '../../UI/Input/customInput';
import CustomInputCheckbox from '../../UI/Input/customInputCheckbox';
import Message from '../../UI/Message';
import TermAndCondition from '../../UI/Footer/dist/TermOfUse';
import styles from './registration.module.css';

const formikConfig = props => {
  const passwordValidationSchema = {
    password: Yup.string()
      .required('Required')
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .matches(/^[a-zA-Z0-9]*$/, 'Password must contain only alphanumeric')
      .min(8, 'Password must contain at least 8 characters.'),

    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], props.t('error:not_match'))
      .required('Required')
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .matches(/^[a-zA-Z0-9]*$/, 'Password must contain only alphanumeric')
      .min(8, 'Password must contain at least 8 characters.')
  };
  if (props.authenticationMethod === 'active_directory') {
    delete passwordValidationSchema.password;
    delete passwordValidationSchema.confirm_password;
    passwordValidationSchema.adUsername = Yup.string()
      .typeError('Invalid')
      .required('Required');
  }
  const { t } = props;
  return {
    initialValues: {
      first_name:
        props.userInvitedData && props.userInvitedData.firstName
          ? props.userInvitedData.firstName
          : '',
      last_name:
        props.userInvitedData && props.userInvitedData.lastName
          ? props.userInvitedData.lastName
          : '',
      company:
        props.userInvitedData && props.userInvitedData.company
          ? props.userInvitedData.company
          : '',
      email:
        props.userInvitedData && props.userInvitedData.email
          ? props.userInvitedData.email
          : '',
      profession: '',
      password: '',
      confirm_password: '',
      terms_and_conditions: false,
      recaptcha: '',
      adUsername: null
    },
    enableReinitialize: true,
    //errors from the users input
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .required('Required')
        .matches(/^[a-zA-Z ]*$/, 'Numbers/Special Characters are not allowed'),

      last_name: Yup.string()
        .trim()
        .required('Required')
        .matches(/^[a-zA-Z ]*$/, 'Numbers/Special Characters are not allowed'),

      company: Yup.string().trim().required('Required'),

      profession: Yup.string().required('Required'),

      email: Yup.string()
        .email(t('validation:invalid_email'))
        .required('Required'),

      terms_and_conditions: Yup.bool().oneOf(
        [true],
        'Must agree to terms and conditions'
      ),
      ...passwordValidationSchema,
      ...(props.isRecaptchaEnabled
        ? {
            recaptcha: Yup.string().required(
              'Please verify that you are a human.'
            )
          }
        : {})
    }),

    onSubmit: (values, { setSubmitting }) => {
      if (props.isInvitation) {
        props
          .registerInvitedUser({ ...values, token: props.match.params.token })
          .then(res => {
            if (res.result === 'success') {
              props.history.push(
                `/account_confirmation/${props.match.params.token}`
              );
            } else {
              Message.error('Error confirming account');
            }
            setSubmitting(false);
          })
          .catch(err => {
            // console.log(err);
            setSubmitting(false);
          });
      } else {
        props
          .registerUser(values)
          .then(res => {
            if (res.result === 'success') {
              props.history.push('/register/succeed');
            } else {
              Message.error('Email already exist');
            }
            setSubmitting(false);
          })
          .catch(err => {
            // console.log(err);
            setSubmitting(false);
          });
      }
    }
  };
};

const ModalTermAndCondition = (isOpen, toggle) => {
  return (
    <Modal scrollable isOpen={isOpen} toggle={toggle} className="modal-xl">
      <ModalBody className="">
        <button className="close" onClick={toggle}>
          &times;
        </button>
        <TermAndCondition />
      </ModalBody>
    </Modal>
  );
};

const checkboxLabel = toggle => {
  return (
    <Trans i18nKey="register:term_condition_label">
      I have read and agree the
      <Link
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          toggle();
        }}
        to="#">
        Terms and Conditions.
      </Link>
    </Trans>
  );
};

const professionDropdown = (profession, t) => {
  return profession.map((res, index) => {
    return (
      <option key={index} value={res.name}>
        {t(res.name)}
      </option>
    );
  });
};

const TermsAndConditionComponent = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModalTermsAndCondition = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <>
      <Field
        name="terms_and_conditions"
        type="checkbox"
        label={checkboxLabel(toggleModalTermsAndCondition)}
        component={CustomInputCheckbox}
      />
      <Modal
        scrollable
        isOpen={isModalOpen}
        toggle={toggleModalTermsAndCondition}
        className="modal-xl">
        <ModalHeader toggle={toggleModalTermsAndCondition}></ModalHeader>
        <ModalBody className="">
          <TermAndCondition />
        </ModalBody>
      </Modal>
    </>
  );
};

export class RegistrationPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInvitedData: {}
    };
  }

  handleVerify = (setFieldValue, name) => value => {
    setFieldValue(name, value);
  };

  componentDidMount() {
    const {
      isInvitation,
      checkTokenForInvitation,
      fetchProfession,
      resetProfession
    } = this.props;
    resetProfession();
    fetchProfession();
    if (isInvitation) {
      checkTokenForInvitation(this.props.match.params.token).then(res => {
        if (res.success) {
          this.setState({
            isLoaded: true,
            error: false,
            messageKey: res.message,
            userInvitedData: res.data
          });
        } else {
          this.setState({
            isLoaded: true,
            error: true,
            userInvitedData: res.data
          });
        }
      });
    } else {
      this.setState({
        isLoaded: true,
        error: false
      });
    }
  }

  render() {
    const { t, profession, featureList } = this.props;
    const isRecaptchaEnabled = featureList.recaptcha;
    const authenticationMethod = featureList.authenticationMethod;

    const { isLoaded, error, userInvitedData } = this.state;
    const ContentToDisplay = () => {
      if (isLoaded && !error) {
        return (
          <>
            <Formik
              {...formikConfig({
                ...this.props,
                userInvitedData,
                isRecaptchaEnabled,
                authenticationMethod
              })}>
              {props => {
                const { isSubmitting, touched, errors, setFieldValue } = props;
                return (
                  <Form onSubmit={props.handleSubmit}>
                    <h3
                      className={`text-center text-muted ${styles.registerSignupLabel}`}>
                      {t('register:signup_to_fornax')}
                    </h3>
                    <Row>
                      <Col sm={6}>
                        <Field
                          name="first_name"
                          type="text"
                          label={t('register:firstName_placeholder')}
                          placeholder={t('register:firstName_placeholder')}
                          component={CustomInput}
                        />
                      </Col>
                      <Col sm={6}>
                        <Field
                          name="last_name"
                          type="text"
                          label={t('register:lastName_placeholder')}
                          placeholder={t('register:lastName_placeholder')}
                          component={CustomInput}
                        />
                      </Col>
                    </Row>
                    <Field
                      name="company"
                      type="text"
                      label={t('register:company_placeholder')}
                      placeholder={t('register:company_placeholder')}
                      prefix={<i className="fas fa-building" />}
                      component={CustomInput}
                    />
                    <FormGroup>
                      <Field
                        name="profession"
                        type="select"
                        label={t('profession_placeholder')}
                        component={CustomInput}
                        prefix={<i className="fas fa-briefcase" />}>
                        <option className="d-none" value="" disabled selected>
                          {t('register:profession_placeholder')}
                        </option>
                        {professionDropdown(profession, t)}
                      </Field>
                    </FormGroup>
                    <Field
                      name="email"
                      type="text"
                      label={t('login:email_placeholder')}
                      placeholder={t('login:email_placeholder')}
                      prefix={<i className="fas fa-at" />}
                      component={CustomInput}
                      disabled={
                        this.state.userInvitedData &&
                        this.state.userInvitedData.email
                      }
                    />
                    {authenticationMethod !== 'active_directory' ? (
                      <>
                        <Field
                          name="password"
                          type="password"
                          label={t('login:password_placeholder')}
                          placeholder={t('login:password_placeholder')}
                          prefix={<i className="fas fa-lock" />}
                          component={CustomInput}
                        />
                        <Field
                          name="confirm_password"
                          type="password"
                          label={t('editProfile:confirm_password')}
                          placeholder={t('editProfile:confirm_password')}
                          prefix={<i className="fas fa-lock" />}
                          component={CustomInput}
                        />
                      </>
                    ) : (
                      <>
                        <Field
                          name="adUsername"
                          type="text"
                          label={
                            <>
                              <span>
                                {t('editProfile:active_directory_username')}
                              </span>
                              {' - '}
                              <span className="text-muted font-italic">
                                {t('editProfile:no_domain_name')}
                              </span>
                            </>
                          }
                          placeholder={t(
                            'editProfile:active_directory_username'
                          )}
                          prefix={<i className="fas fa-lock" />}
                          component={CustomInput}
                        />
                      </>
                    )}
                    <TermsAndConditionComponent />

                    {isRecaptchaEnabled && (
                      <div className="d-flex flex-column align-items-center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                          onChange={this.handleVerify(
                            setFieldValue,
                            'recaptcha'
                          )}
                          onExpired={() =>
                            this.handleVerify(setFieldValue, 'recaptcha')('')
                          }
                        />
                        {touched.recaptcha && errors.recaptcha && (
                          <div className="text-danger">{errors.recaptcha}</div>
                        )}
                      </div>
                    )}

                    <FormGroup className={styles.registerButton}>
                      <Button
                        type="submit"
                        color="success"
                        disabled={isSubmitting}
                        block>
                        {t('register:register')}
                      </Button>
                    </FormGroup>
                  </Form>
                );
              }}
            </Formik>
          </>
        );
      } else if (isLoaded && error) {
        return (
          <Alert color="danger" className="text-center flex p-4" role="alert">
            <h1
              className={`fas fa-exclamation-triangle ${styles.forgotPasswordAlertLogo}`}
            />
            <h3 className="my-4">{t('forgotPassword:failed')}</h3>
            {t('error:token_invalid')}
            <div>
              {this.state.userInvitedData.userId && (
                <Link
                  to={{
                    pathname: '/resend-token',
                    state: {
                      isInvited: this.props.isInvitation,
                      userId: this.state.userInvitedData.userId
                    }
                  }}>
                  {t('forgotPassword:try_again')}
                </Link>
              )}
            </div>
          </Alert>
        );
      } else {
        return (
          <>
            <h1 className={'text-center text-muted p-4'}>
              {t('forgotPassword:verification_in_process')}
            </h1>
            <hr className="my-4" />
            <div className="d-flex justify-content-center">
              <div
                className={`spinner-grow text-primary mt-3 ${styles.forgotPasswordVerificationSpinner}`}
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        );
      }
    };
    return (
      <Container className="d-flex justify-content-center">
        <Card
          className={`mt-5 mb-5 mx-4 p-4 shadow bg-light rounded ${styles.registerFormContainer}`}>
          <ContentToDisplay />
        </Card>
      </Container>
    );
  }
}
export default withTranslation([
  'register',
  'editProfile',
  'login',
  'forgotPassword',
  'validation'
])(RegistrationPresenter);
