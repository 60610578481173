import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import SubmissionList from './SubmissionListPresenter';
import {
  adminFetchSubmissionList,
  resetSubmission,
  adminUpdateOneSubmissionWithPO,
  adminUpdateMultipleSubmissionWithPO
} from '../../../../store/actions/submission';

import { fetchUserListAsAdmin } from '../../../../store/actions/users';
import withXLSExport from '../../../Shared/WithXLSExport';
const fetchPOUserOptions = {
  filters: { function: 'PO' },

  order: { direction: 'desc', field: 'name' },
  pagination: { from: 0, to: 50 }
};

const filterPOUsers = userData => {
  const users = Object.keys(userData).map(key => userData[key]);
  const filteredPOUsers = users.filter(user => {
    return user.function === 'PO';
  });
  return filteredPOUsers;
};

const mapToTable = mapData => {
  return Object.keys(mapData).map(key => {
    const data = mapData[key];
    return { id: key, ...data };
  });
};

const mapStateToProps = state => {
  return {
    submissions: mapToTable(state.submission),
    availablePOs: filterPOUsers(state.users),
    authUser: state.authUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    adminFetchSubmissionList: (filters, order, pagination, toDispatch) =>
      dispatch(
        adminFetchSubmissionList(filters, order, pagination, toDispatch)
      ),
    resetSubmission: () => dispatch(resetSubmission()),
    fetchPOUser: () =>
      dispatch(
        fetchUserListAsAdmin(
          fetchPOUserOptions.filters,
          fetchPOUserOptions.order,
          fetchPOUserOptions.pagination
        )
      ),
    updateMultipleSubmission: (submissions, excludeConflicts) =>
      dispatch(
        adminUpdateMultipleSubmissionWithPO(submissions, excludeConflicts)
      ),
    updateOneSubmission: (projectId, stageName, submissionId, POAssign) =>
      dispatch(
        adminUpdateOneSubmissionWithPO(
          projectId,
          stageName,
          submissionId,
          POAssign
        )
      )
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withXLSExport,
  withTranslation([''])
)(SubmissionList);
