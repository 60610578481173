import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Container,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Badge,
  Input,
  Alert
} from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldArray } from 'formik';
import { Link } from 'react-router-dom';

import Switch from '../../UI/Switch';
import Message from '../../UI/Message';

import {
  fetchRules,
  fetchRulesAdmin,
  saveRuleParameter
} from '../../../store/actions';

import RuleVisibilty from './RuleVisibilty';
import CompliantMessageLocking from './CompliantMessageLocking/ModeSelection';
import './ModalRuleParameter.css';

import ReportTemplate from './ReportTemplate';
import rulePackageReportTemplate from '../../../store/utils/rulePackageReportTemplate';

const RuleParameterModal = ({
  t,
  ruleId,
  rule,
  rules,
  open,
  toggle,
  ruleType,
  saveRuleParameter,
  level,
  fetchRulesAdmin,
  fetchRules
}) => {
  const featureList = useSelector(state => state.app.featureList);
  const reportTemplateList = useSelector(
    state => state.app.report_template_list
  );
  const isWaiverEnabled = featureList.ruleWaiving;
  const isPrecheckEnabled = featureList.rulePrecheck;

  const [checked, setChecked] = useState(false);
  const [iframeOnError, setIframeOnError] = useState(false);
  const [iframeContent, setIframeContent] = useState('');
  const [isOverDescription, setHoverDescription] = useState(false);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const params = (rule && rule.params) || {};

  const [concurrencyControlVersionId, setConcurrencyControlVersionId] =
    useState(params.concurrency_control_version_id || '');

  const availableReportTemplate = reportTemplateList
    .filter(rt => {
      return rt.name === ruleType || rt.custom_template_for === ruleType;
    })
    .map(rt => rt.name)
    .filter(i => i !== 'comparison');

  const rulePerReportTemplate = availableReportTemplate.map(keyType => {
    let isSelected = true;

    if (params.report_template && params.report_template.length > 0) {
      isSelected = params.report_template.includes(keyType);
    } else {
      isSelected = keyType === ruleType;
    }

    return {
      name: keyType,
      rules: [params.rule_id],
      isSelected: isSelected
    };
  });

  const formikConfig = {
    initialValues: {
      // default to true
      show_in_result: params.show_in_result !== false,
      // default to true
      published: params.published !== false,
      // default to false
      in_precheck: !!params.in_precheck,
      waivable: !!params.waivable,
      visibility: params.visibility,
      compliant_message_mode: params.compliant_message_mode,
      users_visibility: params.users_visibility || [],
      report_template: rulePerReportTemplate
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: {

    // },
    // validate: () => {}
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const { report_template, ...rest } = values;

        const mappedReportTemplateName = report_template
          .filter(rp => rp.isSelected)
          .map(rp => rp.name);

        if (
          featureList.isRulePackageV2Enabled &&
          mappedReportTemplateName &&
          mappedReportTemplateName.length > 1 &&
          mappedReportTemplateName.includes('bimdata')
        ) {
          throw Error('error_bimdata_included_on_list');
        } else if (mappedReportTemplateName.length === 0) {
          throw Error('minimum_report_template_error');
        }

        await saveRuleParameter(
          ruleId,
          {
            ...rest,
            report_template: report_template
              .filter(rp => rp.isSelected)
              .map(rp => rp.name)
          },
          concurrencyControlVersionId
        ).then(concurrencyControlVersionId => {
          setConcurrencyControlVersionId(concurrencyControlVersionId);

          if (level === 'domain') {
            fetchRulesAdmin({ type: ruleType });
          } else if (level === 'user') {
            fetchRules(ruleType);
          }
        });

        Message.success(t('user_preference:saved'));
      } catch (err) {
        resetForm();
        Message.error(t(`error:${err.message}` || 'error:default_message'));
      } finally {
        setSubmitting(false);
      }
    }
  };
  const fetchDescription = async () => {
    try {
      const content = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT[
            process.env.REACT_APP_API_ENDPOINT.length - 1
          ] == '/'
            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
            : process.env.REACT_APP_API_ENDPOINT
        }/api/rules/description/${rule.ruleId}?level=${level}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      ).then(res => res.text());
      setIframeContent(content);
    } catch (err) {
      setIframeOnError(true);
    }
  };
  const descriptionLink = rule && rule.descriptionLink;
  useEffect(() => {
    if (open) {
      fetchDescription();
    }
  }, [open, descriptionLink]);

  const { authUser } = useSelector(state => state);

  return (
    <Modal isOpen={open} toggle={toggle} size="xl">
      <>
        {open && (
          <>
            <ModalHeader toggle={toggle}>{rule.title}</ModalHeader>
            <ModalBody>
              <form>
                <div className="d-flex flex-row justify-content-between h-100 flex-grow-1">
                  <div
                    className={`w-100 border mr-2 ${
                      isDescriptionExpanded
                        ? 'overflow-auto'
                        : 'overflow-hidden'
                    }`}>
                    {rule.dependencies && rule.dependencies.length > 0 && (
                      <>
                        <h4 className="text-primary">
                          {t('rules:dependencies')}{' '}
                        </h4>
                        <ListGroup>
                          {rule.dependencies.map((dependency, index) => {
                            return (
                              <ListGroupItem key={`dependency-${index}`}>
                                <div className="d-flex">
                                  <div>
                                    {rules[dependency.value]
                                      ? `${
                                          rules[dependency.value].title
                                        } (id: ${dependency.value})`
                                      : dependency.value}
                                  </div>
                                  {dependency.optional && (
                                    <div className="ml-2 border rounded text-gray font-weight-bold border-gray border-very-thick py-1 px-2">
                                      {t('rules:optional')}
                                    </div>
                                  )}
                                </div>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </>
                    )}
                    {!iframeOnError && iframeContent !== '' && (
                      <div className="h-100">
                        <div
                          className={classnames('iframeContentContainer', {
                            'h-100 overflow-auto': isDescriptionExpanded,
                            'overflow-hidden': !isDescriptionExpanded
                          })}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: iframeContent
                            }}></div>

                          <div
                            className={
                              isDescriptionExpanded
                                ? 'iframeContentDescriptionExpanded'
                                : 'iframeContentDescriptionCollapsed'
                            }
                            onMouseEnter={() => setHoverDescription(true)}
                            onMouseLeave={() => setHoverDescription(false)}>
                            {isOverDescription && (
                              <div className="d-flex overDescriptionContainer">
                                <Link
                                  target="_blank"
                                  to={`/admin/rules/${level}/${rule.level}/${ruleType}/${rule.ruleId}`}
                                  className="overDescriptionLink"
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}>
                                  <i
                                    className="fas fa-external-link-alt fs-1-8x "
                                    title={`${t('main:edit')} ${rule.title}`}
                                  />
                                </Link>
                                <div
                                  className="ml-2 pointer"
                                  onClick={() =>
                                    setDescriptionExpanded(
                                      !isDescriptionExpanded
                                    )
                                  }>
                                  <i
                                    className={classnames('fs-1-8x fas', {
                                      'fa-expand': !isDescriptionExpanded,
                                      'fa-compress': isDescriptionExpanded
                                    })}
                                    title={t('mian:expand')}></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row justify-content-end align-items-start">
                    <Formik {...formikConfig}>
                      {formikProps => {
                        const {
                          values,
                          setFieldValue,
                          handleSubmit,
                          isSubmitting,
                          setValues,
                          setTouched,
                          touched
                        } = formikProps;

                        const emailArrayToString = values.users_visibility
                          .toString()
                          .replace(/,/g, '; ');

                        return (
                          <Container className="d-flex flex-column border p-3">
                            <FormGroup
                              row
                              className={`px-3 flex-nowrap ${
                                authUser.function !== 'ADMIN'
                                  ? 'border-bottom'
                                  : ''
                              }`}>
                              <Col xs="" className="px-0 py-3">
                                <Label className="text-secondary font-weight-bold flex-grow-1">
                                  {t('rules:visibility')}
                                </Label>
                              </Col>
                              <Col xs="6" className="py-3">
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <RuleVisibilty
                                      values={values}
                                      t={t}
                                      asFormikField={true}
                                      setValues={setValues}
                                      handleSubmit={handleSubmit}
                                      asFormikField={true}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>

                            {authUser.function === 'ADMIN' && (
                              <>
                                <Field
                                  name="users_visibility"
                                  render={() => (
                                    <FormGroup
                                      row
                                      className="px-3 pb-3 border-bottom">
                                      <Label className="text-secondary font-weight-bold flex-grow-1">
                                        {t('rules:specific_users')}
                                      </Label>
                                      <Input
                                        id="user-visibility-email"
                                        type="textarea"
                                        name="email"
                                        placeholder="Enter email"
                                        rows={4}
                                        defaultValue={emailArrayToString}
                                      />
                                      <Button
                                        className="mt-1"
                                        block
                                        size="sm"
                                        color="success"
                                        onClick={() => {
                                          const addItem =
                                            document.getElementById(
                                              'user-visibility-email'
                                            ).value;

                                          let emailSchema = Yup.array()
                                            .transform((value, original) => {
                                              return original
                                                .split(';')
                                                .map(email => email.trim());
                                            })
                                            .of(Yup.string().email());

                                          const validateEmail = emailSchema
                                            .isValid(addItem)
                                            .then(result => {
                                              if (result) {
                                                const splitEmails = addItem
                                                  .split(';')
                                                  .map(email =>
                                                    email.trim().toLowerCase()
                                                  )
                                                  .filter(email => !!email);

                                                if (splitEmails.length > 0) {
                                                  let uniqueEmails = [
                                                    ...new Set([
                                                      ...values.users_visibility,
                                                      ...splitEmails
                                                    ])
                                                  ];

                                                  setFieldValue(
                                                    'users_visibility',
                                                    [...uniqueEmails]
                                                  );

                                                  handleSubmit();

                                                  document.getElementById(
                                                    'user-visibility-email'
                                                  ).value = '';
                                                } else {
                                                  Message.error(
                                                    t(
                                                      'error:please_enter_email'
                                                    )
                                                  );
                                                }
                                              } else {
                                                Message.error(
                                                  t('error:invalid_email')
                                                );
                                              }
                                            });
                                        }}>
                                        <i className="fas fa-user-plus fa-lg"></i>
                                      </Button>
                                      <Alert color="light" className="mt-2">
                                        {t('rules:email_input_note')}
                                      </Alert>
                                      <div>
                                        {values.users_visibility.length !==
                                          0 && (
                                          <ListGroup>
                                            {values.users_visibility.map(
                                              (email, index) => (
                                                <ListGroupItem
                                                  key={`users-visibility-${index}`}
                                                  className="m-0 p-0 d-flex justify-content-between">
                                                  <div className="p-2">
                                                    {email}
                                                  </div>
                                                  <Button
                                                    size="sm"
                                                    color="danger"
                                                    onClick={() => {
                                                      const removeItem =
                                                        values.users_visibility.splice(
                                                          index,
                                                          1
                                                        );

                                                      setFieldValue(
                                                        'users_visibility',
                                                        values.users_visibility
                                                      );

                                                      handleSubmit();
                                                    }}>
                                                    <i className="fas fa-user-times"></i>
                                                  </Button>
                                                </ListGroupItem>
                                              )
                                            )}
                                          </ListGroup>
                                        )}
                                      </div>
                                    </FormGroup>
                                  )}></Field>
                              </>
                            )}

                            <Field
                              name="show_in_result"
                              render={() => (
                                <FormGroup
                                  row
                                  className="px-3 flex-nowrap border-bottom">
                                  <Label className="text-secondary font-weight-bold flex-grow-1">
                                    {t('rules:show_in_result')}{' '}
                                  </Label>
                                  <div className="mr-0">
                                    <Switch
                                      checked={values.show_in_result}
                                      onChange={val => {
                                        setFieldValue('show_in_result', val);
                                        handleSubmit();
                                      }}
                                      disabled={isSubmitting}
                                      checkedColor="customInfo"
                                      // unCheckedColor="var(--danger)"
                                      checkedChildren="Show"
                                      unCheckedChildren="Hide"
                                      className=""
                                    />
                                  </div>
                                </FormGroup>
                              )}
                            />
                            {isPrecheckEnabled && (
                              <Field
                                name="in_precheck"
                                render={() => (
                                  <FormGroup
                                    row
                                    className="px-3 flex-nowrap border-bottom">
                                    <Label className="text-secondary font-weight-bold flex-grow-1">
                                      {t('rules:include_to_precheck')}
                                    </Label>

                                    <div className="mr-0">
                                      <Switch
                                        checked={values.in_precheck}
                                        onChange={val => {
                                          setFieldValue('in_precheck', val);
                                          handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        checkedColor="customSuccess"
                                        unCheckedColor="customDanger"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        className=""
                                      />
                                    </div>
                                  </FormGroup>
                                )}
                              />
                            )}
                            {isWaiverEnabled && (
                              <Field
                                name="waivable"
                                render={() => (
                                  <FormGroup
                                    row
                                    className="px-3 flex-nowrap border-bottom">
                                    <Label className="text-secondary font-weight-bold flex-grow-1">
                                      {t('rules:rule_waivable')}
                                    </Label>
                                    <div className="mr-0">
                                      <Switch
                                        checked={values.waivable}
                                        onChange={val => {
                                          setFieldValue('waivable', val);
                                          handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        checkedColor="customSuccess"
                                        unCheckedColor="customDanger"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        className=""
                                      />
                                    </div>
                                  </FormGroup>
                                )}
                              />
                            )}
                            <Field
                              name="compliant_message_mode"
                              render={() => (
                                <FormGroup
                                  row
                                  className="px-3 flex-nowrap border-bottom">
                                  <Col xs="" className="px-0 py-3">
                                    <Label className="text-secondary font-weight-bold flex-grow-1">
                                      {t('rules:compliant_messages')}
                                    </Label>
                                  </Col>
                                  <Col xs="6" className="py-3">
                                    <div className="d-flex justify-content-end">
                                      <div>
                                        <CompliantMessageLocking
                                          t={t}
                                          values={values}
                                          asFormikField={true}
                                          setFieldValue={setFieldValue}
                                          setValues={setValues}
                                          handleSubmit={handleSubmit}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </FormGroup>
                              )}
                            />
                            <Field
                              name="report_template"
                              render={() => (
                                <FormGroup
                                  row
                                  className="px-3 flex-nowrap border-bottom">
                                  <Col xs="" className="px-0 py-3">
                                    <Label className="text-secondary font-weight-bold flex-grow-1">
                                      {t(
                                        'rule_package_management:set_report_template'
                                      )}
                                    </Label>
                                  </Col>
                                  <Col xs="6" className="py-3">
                                    <div className="d-flex justify-content-end">
                                      <div>
                                        <ReportTemplate
                                          values={values}
                                          t={t}
                                          setTouched={setTouched}
                                          touched={touched}
                                          setValues={setValues}
                                          asFormikField={true}
                                          handleSubmit={handleSubmit}
                                          ruleType={ruleType}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </FormGroup>
                              )}
                            />

                            <Link
                              target="_blank"
                              to={`/admin/rules/${level}/${rule.level}/${ruleType}/${rule.ruleId}/rule_history`}
                              className="ruleHistoryLink"
                              onClick={e => {
                                e.stopPropagation();
                              }}>
                              <Button
                                color="primary"
                                outline
                                className="ruleHistoryButton"
                                type="button">
                                Manage rule history{' '}
                              </Button>
                            </Link>
                          </Container>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </>
    </Modal>
  );
};

const RuleParameterModalContainer = ({ ...props }) => {
  return <RuleParameterModal {...props} />;
};

const mapStateToProps = (state, props) => {
  if (props.level === 'domain') {
    const rules = state.rules_admin.rules;
    const rule = rules && rules[props.ruleId];
    return {
      rules,
      rule
    };
  } else if (props.level === 'user') {
    const rules = state.rules.rulesSet;
    const rule = rules[props.ruleId];
    return {
      rules,
      rule
    };
  } else if (props.level === 'organization') {
    // TODO
  }
};

const mapDispatchToProps = (dispatch, props) => {
  const saveDestination = { type: props.level };
  return {
    saveRuleParameter: (ruleId, data, concurrencyControlVersionId) =>
      dispatch(
        saveRuleParameter(
          ruleId,
          props.ruleType,
          data,
          saveDestination,
          concurrencyControlVersionId
        )
      ),
    fetchRulesAdmin: ({ type, reset }) =>
      dispatch(fetchRulesAdmin({ type, reset })),
    fetchRules: type => fetchRules(type)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RuleParameterModalContainer
);
