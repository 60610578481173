import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const FeaturesEnabled = props => {
  const { t, featureList } = props;

  const reducedFeatureList = Object.keys(featureList).reduce((acc, curr) => {
    switch (curr) {
      case 'checkingType':
        return {
          ...acc,
          [curr]: {
            title: `${curr}_title`,
            component: featureList[curr].map((checkType, idx) => {
              return (
                <ul key={`checkType-${idx}`}>
                  <li> {t(`features_enabled:${checkType}`)}</li>
                </ul>
              );
            }),
            description: `${curr}_description`
          }
        };
      case 'ruleset':
        return {
          ...acc,
          [curr]: {
            title: `${curr}_title`,
            component: Object.keys(featureList[curr]).map((ruleType, idx) => {
              return (
                <div key={`ruleType-${idx}`}>
                  <p className="font-weight-bold">
                    {t(`features_enabled:${ruleType}`)}
                  </p>
                  {Array.isArray(featureList[curr][ruleType]) ? (
                    featureList[curr][ruleType].map((item, idx) => {
                      return (
                        <ul key={`item-${idx}`}>
                          <li>{item}</li>
                        </ul>
                      );
                    })
                  ) : (
                    <ul>
                      <li>{featureList[curr][ruleType]}</li>
                    </ul>
                  )}
                </div>
              );
            }),
            description: `${curr}_description`
          }
        };
      case 'authenticationMethod':
        return {
          ...acc,
          [curr]: {
            title: `${curr}_title`,
            component: <div> {featureList[curr]}</div>,
            description: `${curr}_description`
          }
        };

      default:
        return {
          ...acc,
          [curr]: { title: `${curr}_title`, description: `${curr}_description` }
        };
    }
  }, {});

  return (
    <Container className="">
      <Row className="px-3 pb-4 m-4 border-bottom">
        <Col className="font-weight-bold text-center">
          <h5 className="font-weight-bold text-primary">
            {t(`features_enabled:feature`)}
          </h5>
        </Col>

        <Col className="font-weight-bold text-center">
          <h5 className="font-weight-bold text-primary">
            {t(`features_enabled:description`)}
          </h5>
        </Col>
      </Row>
      {Object.keys(reducedFeatureList).map((feature, idx) => {
        return (
          !!featureList[feature] && (
            <Row
              key={`feature-${idx}`}
              className="px-3 pb-4 m-4  border-bottom">
              <Col className="font-weight-bold">
                {t(`features_enabled:${reducedFeatureList[feature].title}`)}
              </Col>
              <Col>
                {reducedFeatureList[feature].component
                  ? reducedFeatureList[feature].component
                  : ''}
              </Col>
              <Col>
                {t(
                  `features_enabled:${reducedFeatureList[feature].description}`
                )}
              </Col>
            </Row>
          )
        );
      })}
    </Container>
  );
};

export default withTranslation(['features_enabled'])(FeaturesEnabled);
