import React, { Component } from 'react';
import { Link /* , withRouter */ } from 'react-router-dom';
import { dateFormatting } from '../../../../locales/dateFormat';
import {
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledCollapse,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';

import AssignPOModal from './AssignPOModal';
import Message from '../../../UI/Message';
import { withTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

import { isEqual } from 'lodash';
import { getURLParameters } from '../../../../utils/url';
import styles from './Submission.module.css';

import { createMarks } from '../exportToFileUtil';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const selectOptionsStatus = translate => {
  return {
    open: translate('submission:status_label_open'),
    re_open: translate('submission:status_label_re_open'),
    in_progress: translate('submission:status_label_in_progress'),
    reviewed: translate('submission:status_label_reviewed'),
    completed: translate('submission:status_label_completed'),
    closed: translate('submission:status_label_closed')
  };
};

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

let submissionsToUpdate = [];

const selectRow = {
  mode: 'checkbox',
  onSelect: (rows, isSelect, i, e) => {
    submissionsToUpdate = rows.map(r => r.original.submission_id);
  },
  selected: submissionsToUpdate
};

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'date_created',
      order: d || 'desc'
    }
  ];
};

const mapToTable = mapData => {
  return Object.keys(mapData).map(key => {
    const data = mapData[key];
    return { id: key, ...data };
  });
};
class SubmissionPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      createUserModal: false,
      assignPOModal: false,
      collapse: {},
      filters: {},
      default_filter: {},
      dropdownOpen: false,
      formats: ['xlsx', 'csv'],
      selectedConsultationIds: [],
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFileRange: [0, 0],
      exportFormat: 'csv',
      order: { dataField: 'date_created', order: 'desc' },
      loadingItemToExport: false
    };
  }

  componentDidMount() {
    this.props.fetchPOUser();
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'date_created') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'date_created', direction: d || 'desc' }
      },
      () => {
        this.fetchSubmissionList();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchSubmissionList();
    }
  }

  fetchSubmissionList() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .adminFetchSubmissionList(filters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,

            exportRange: [from + 1, to >= totalSize ? totalSize : to],
            exportFileRange: [from, to >= totalSize ? totalSize : to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString(filters)}`
            );
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  filterToURLString(filters) {
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'date_created') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  componentWillUnmount() {
    this.props.resetSubmission();
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (cur === 'date_created' || cur === 'status') {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'date_created') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              if (cur === 'status') {
                return { ...acc, [cur]: newState.filters[cur].filterVal };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'date_created', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  toggleAssignPOModal = () => {
    submissionsToUpdate.length === 0
      ? Message.error(this.props.t('error_select_one_submission'))
      : this.setState({ assignPOModal: !this.state.assignPOModal });
  };

  exportToXLS = async (format, range) => {
    const filtered_by_selected = await this.props.adminFetchSubmissionList(
      this.state.filters,
      this.state.order,
      {
        from: range[0],
        to: range[1]
      },
      false
    );
    const finalData = mapToTable(filtered_by_selected).map(submission => {
      return {
        'Project Name': submission.project_name,
        'Submitted by': submission.created_by,
        'Assigned to': submission.po_assigned
          .map(user => {
            if (user.first_name || user.last_name) {
              return `${user.first_name} ${user.last_name}`;
            } else {
              return 'NO PO ASSIGNED';
            }
          })
          .join(','),
        status: this.props.t(`status_label_${submission.status}`),
        'Date Created': dateFormatting(submission.date_created)
      };
    });
    this.props.exportToXLS(finalData, 'json', {
      fileName: `submission_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };
  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };
  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      collapse,
      filters,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport
    } = this.state;

    const { submissions, t, authUser } = this.props;

    const reactTableColumns = t => [
      {
        id: 'project_name',
        accessor: 'project_name',
        Header: t('project_name'),
        Cell: ({ row, value, ...rest }) => {
          return (
            <Link
              to={`/admin/project/${row.original.project_id}/stage/${row.original.stage_name}/submission/${row.original.submission_id}/detail`}>
              {row.original.project_name}
            </Link>
          );
        },
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              placeholder={t('project_name')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'created_by',
        accessor: 'created_by',
        Header: t('submitted_by'),
        Cell: ({ row, value }) => {
          return value || '';
        },
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              placeholder={t('submitted_by')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },

      {
        id: 'po_assigned',
        accessor: 'po_assigned',
        Header: t('assigned_to'),
        Cell: ({ row, value }) => {
          return (
            <>
              {!value ||
              value.length === 0 ||
              Object.keys(value[0]).length === 0 ? (
                <div className="text-muted">{t('no_po_assigned')}</div>
              ) : (
                value.map((po, key) => (
                  <div key={key} className={styles.circleIconSize}>
                    <div>
                      <span
                        id={`po-${row.original.submission_id}-${po.user_id}`}>
                        <i className="far fa-user text-success" />{' '}
                        {po.full_name
                          ? po.full_name
                          : `${po.first_name} ${po.last_name}`}
                      </span>

                      <UncontrolledPopover
                        trigger="hover"
                        placement="right"
                        className="popover-container"
                        hideArrow={true}
                        target={`po-${row.original.submission_id}-${po.user_id}`}>
                        <PopoverHeader>
                          {po.full_name
                            ? po.full_name
                            : `${po.first_name} ${po.last_name}`}
                        </PopoverHeader>
                        <PopoverBody>
                          <div className="p-2">
                            <span className="font-weight-bold">
                              {t('email')}{' '}
                            </span>
                            {po.email}
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </div>
                ))
              )}
            </>
          );
        },
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              placeholder={t('assigned_to')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },

      {
        id: 'status',
        accessor: 'status',
        Header: 'Status',
        Cell: ({ row, value }) => {
          return t(`status_label_${value}`);
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={selectOptionsStatus(t)}
              filters={this.state.filters}
              filterToState={this.filterToState}
              className="form-control-sm"
              defaultValue={filters.status || ''}
            />
          );
        }
      },
      {
        id: 'date_created',
        accessor: 'date_created',
        Header: 'Date Created',
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => {
          return (
            <DateRangeColumnFilter
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'actions',
        Header: () => {
          return (
            <Button
              className="mr-1"
              hidden={!(Object.keys(this.state.filters).length > 0)}
              outline
              size="md"
              color="secondary"
              onClick={() => this.clearFilter()}>
              <i className="fas fa-times"></i> Clear Filters
            </Button>
          );
        },
        Cell: ({ row, value }) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="muted" className="w-100">
                  <i className="fas fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <Link
                    className="text-decoration-none"
                    to={`/admin/project/${row.original.project_id}/stage/${row.original.stage_name}/submission/${row.original.submission_id}/detail`}>
                    <DropdownItem>{t('submission:view_details')}</DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      }
    ];

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      }
      return <></>;
    };

    return (
        <Container fluid className="mx-3 mt-4">
          <AssignPOModal
            {...this.props}
            availablePOs={this.props.availablePOs}
            toggle={this.toggleAssignPOModal}
            modal={this.state.assignPOModal}
            submissionIds={submissionsToUpdate}
            typeOfUpdate={
              submissionsToUpdate.length === 1 ? 'SINGLE' : 'MULTIPLE'
            }
          />
          <div className="shadow p-3 mb-5 bg-light rounded">
            <div className="mt-2">
              <h3 className="text-primary">{t('admin_submission')}</h3>
            </div>
            <div className="text-right pb-2">
              {authUser.function === 'ADMIN' ? (
                <Button
                  id="assign-po-btn"
                  onClick={this.toggleAssignPOModal}
                  color="primary"
                  outline
                  className="mr-1">
                  <i className="fas fa-user-tag" /> {t('assign_po')}
                </Button>
              ) : null}
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
                className="float-right">
                <DropdownToggle
                  id="file-export-btn"
                  color="success"
                  outline
                  disabled={submissions.length === 0}>
                  <i className="fas fa-file-export mr-1" />
                  Export to
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.formats.map((format, i) => {
                    return (
                      <DropdownItem
                        id={`export-option-${format}`}
                        key={`export-option-${i}`}
                        onClick={() => {
                          this.openExportModal(format);
                        }}>
                        <i
                          className={`far fa-file-excel mr-2 ${styles.exportDropdownColor}`}
                          color="primary"
                        />
                        {format}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <ReactTable
              hover
              bordered={false}
              keyField="submission_id"
              data={submissions}
              columns={reactTableColumns(t)}
              defaultSorted={defaultSorted()}
              pagination={paginationOptions}
              loading={!isLoaded}
              overlay={loading =>
                ({ children }) => {
                  return (
                    <div className="position-relative">
                      {children}
                      {loading && (
                        <div
                          className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                          <div
                            className={`spinner-border text-primary ${styles.tableOverlayDimension}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
              noDataIndication={NoData}
              selectRow={selectRow}
              remote={{ pagination: true, filter: true, sort: true }}
              filters={filters}
            />
          </div>
          <Modal
            isOpen={exportModalOpen}
            size="lg"
            toggle={() =>
              this.setState(prevState => ({
                exportModalOpen: !prevState.exportModalOpen
              }))
            }
            className="">
            <ModalHeader
              toggle={() =>
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }))
              }>
              {t('main:export_records')}
            </ModalHeader>
            <ModalBody>
              <div>{t('main:choose_records_to_export')}</div>
              <div className={styles.modalBodyWidth}>
                <Range
                  min={1}
                  max={totalSize}
                  marks={createMarks(totalSize)}
                  // allowCross={false}
                  defaultValue={defaultExportRange}
                  value={exportRange}
                  pushable={1}
                  onChange={newRange => {
                    this.setState({
                      exportRange: newRange,
                      exportFileRange: [newRange[0] - 1, newRange[1]]
                    });
                  }}
                />
              </div>
              <div className="text-muted font-italic">
                *Note : only filtered items are included
              </div>
              <div>
                Export items {exportRange[0]} to {exportRange[1]} to a{' '}
                {exportFormat} file.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                id="export-modal-confirm-btn"
                color="primary"
                disabled={loadingItemToExport}
                onClick={async () => {
                  this.setState({ loadingItemToExport: true });
                  await this.exportToXLS(exportFormat, exportFileRange);
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }));
                  this.setState({ loadingItemToExport: false });
                }}>
                {loadingItemToExport && (
                  <span
                    className="mr-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t('main:export')}
              </Button>{' '}
              <Button
                id="export-modal-cancel-btn"
                color="secondary"
                disabled={loadingItemToExport}
                onClick={() =>
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }))
                }>
                {t('main:cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
    );
  }
}

export default withTranslation(['submission', 'main'])(SubmissionPresenter);
