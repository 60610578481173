import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Collapse,
  Badge
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import ProjectStagePresenter from './ProjectStagePresenter';
import ProjectSubmission from './ProjectSubmissionPresenter';
import ProjectDetails from './Details';
import ModelIssueList from '../ModelIssue/ModelIssueListContainer';

import './Project.css';
import ReferenceDocument from './ReferenceDocument';
import Notifications from '../Notifications';
import CommunicationSpace from './CommunicationSpace';
import ProjectStats from './ProjectStats';
import PageNotFound from './PageNotFound';
import CustomTooltip from '../../UI/CustomTooltip';
import styles from './Styles.module.css';

const projectMenuTabsFunc = featureList => {
  const isSubmissionEnabled = featureList.officerSubmission;
  const isModelIssueEnabled = featureList.modelIssue;
  const isReferenceDocumentEnabled = featureList.documentReference;
  const isTaskManagementEnabled = featureList.taskManagement;
  const isStatisticsEnabled = featureList.projectStatistics;

  return [
    'models',
    'details',
    ...(isReferenceDocumentEnabled ? ['documents'] : []),
    ...(isModelIssueEnabled ? ['issue'] : []),
    ...(isSubmissionEnabled ? ['submission'] : []),
    ...(isTaskManagementEnabled ? ['task'] : []),
    ...(isStatisticsEnabled ? ['statistics'] : [])
  ];
};

const tabMapFunc = tabs =>
  tabs.reduce(
    (returned, tab) => ({
      ...returned,
      [tab]: tab
    }),
    {}
  );
class ProjectDetailPresenter extends Component {
  constructor(props) {
    super(props);

    const projectMenuTabs = projectMenuTabsFunc(props.featureList);
    const tabMap = tabMapFunc(projectMenuTabs);
    const activeTab = tabMap[this.props.match.params.tab] || 'details';

    this.state = {
      isLoaded: false,
      activeTab,
      isNotificationOpen: false,
      unReadNotificationCount: 0,
      relatedGroups: {}
    };
    this.sidebarRef = React.createRef();
  }

  componentDidMount() {
    const projectId = this.props.match.params.id;

    Promise.all([
      this.props.fetchRoles(),
      this.props.fetchProject(projectId),
      this.props.fetchProjectRelatedGroups(projectId),
      this.props
        .fetchProjectAdditionalFields()
        .catch(err => console.log('Cannot fetch additional fields')),
      this.props
        .fetchProjectThumbailSettings()
        .catch(err => console.log('Cannot fetch project thumbnail settings')),

      this.props.fetchAllowedProjectSizeConfig(projectId).catch(() => false)
    ])
      .then(([role, proj, relatedGroups]) => {
        this.setState({
          isLoaded: true,
          stageDropDownValue:
            this.props.project.stagesavailable.find(
              s => s.name === this.props.match.params.stageName
            ) || this.props.project.stagesavailable[0],
          relatedGroups: relatedGroups
        });
      })
      .catch(() => {
        this.setState({ isLoaded: true });
      });
  }
  componentDidUpdate(prevProps) {
    const prevTab = prevProps.match.params.tab;
    const projectId = this.props.match.params.id;
    const tab = this.props.match.params.tab;
    if (prevTab !== tab) {
      const projectMenuTabs = projectMenuTabsFunc(this.props.featureList);
      const tabMap = tabMapFunc(projectMenuTabs);
      const activeTab = tabMap[tab] || 'details';
      this.setState({ activeTab });
    }
    if (prevProps.match.params.id && prevProps.match.params.id !== projectId) {
      this.setState({ isLoaded: false });
      Promise.all([this.props.fetchRoles(), this.props.fetchProject(projectId)])
        .then(() => {
          this.setState({
            isLoaded: true,
            stageDropDownValue:
              this.props.project.stagesavailable.find(
                s => s.name === this.props.match.params.stageName
              ) || this.props.project.stagesavailable[0]
          });
        })
        .catch(() => {
          this.setState({ isLoaded: true });
        });
    }
  }

  stageChangeValue = stageData => {
    const projectId = this.props.match.params.id;
    return this.props.fetchProjectStage(projectId, stageData.name).then(() => {
      this.setState({ stageDropDownValue: stageData });
    });
  };

  toggle(tab) {
    this.props.history.push({ search: '' });
    if (
      this.state.activeTab !== tab ||
      this.props.location.pathname !==
        `/project/${this.props.match.params.id}/${tab}`
    ) {
      this.setState({
        activeTab: tab
      });
      this.props.history.replace(
        `/project/${this.props.match.params.id}/${tab}`
      );
    }
  }
  toggleNotificationPanel = () => {
    this.setState(prevState => ({
      isNotificationOpen: !prevState.isNotificationOpen
    }));
  };

  updateUnreadNotificationCount = unReadNotificationCount => {
    this.setState({ unReadNotificationCount });
  };

  render() {
    const { t, project, featureList } = this.props;
    const { isNotificationOpen, isLoaded } = this.state;
    const projectId = this.props.match.params.id;
    const projectMenuTabs = projectMenuTabsFunc(featureList);
    const isNotificationPanelEnabled = featureList.notificationPanel;

    return (
      <>
        {isLoaded && project && (
          <Container fluid>
            <div className={styles.projectDetailContainer}>
              <h1 className="text-primary">{project.name}</h1>
            </div>
            <Row>
              <Col sm={9}>
                <Row>
                  <Col lg={2}>
                    <div
                      id="qwerty"
                      ref={this.sidebarRef}
                      className={styles.projectDetailDivHeight}>
                      <Nav className="shadow mt-3 rounded" tabs vertical>
                        {projectMenuTabs.map((tab, index) => (
                          <React.Fragment key={`project-tab-${tab}`}>
                            <NavItem
                              id={`tab-${tab}-${index}`}
                              className={classnames(
                                'rounded border border-white ',
                                {
                                  rounded: this.state.activeTab === tab
                                }
                              )}>
                              <NavLink
                                className={classnames(
                                  'rounded bg-primary text-white',
                                  {
                                    'active font-weight-bold bg-light text-primary':
                                      this.state.activeTab === tab
                                  },
                                  'projectDetailNavBorder'
                                )}
                                onClick={() => {
                                  this.toggle(tab);
                                }}>
                                {t(tab)}
                              </NavLink>
                            </NavItem>
                            <CustomTooltip
                              target={`tab-${tab}-${index}`}
                              title={t(tab)}
                              description={t(`${tab}_description`)}
                            />
                          </React.Fragment>
                        ))}
                      </Nav>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="models">
                        {this.state.activeTab === 'models' && (
                          <ProjectStagePresenter
                            {...this.props}
                            projectId={projectId}
                            stageName={
                              this.props.match.params.stageName ||
                              this.state.stageDropDownValue.name
                            }
                            sidebarRef={this.sidebarRef}
                            stageDropDownValue={this.state.stageDropDownValue}
                            stageChangeValue={this.stageChangeValue}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="details">
                        {this.state.activeTab === 'details' && (
                          <ProjectDetails
                            {...this.props}
                            relatedGroups={this.state.relatedGroups}
                            stageChangeValue={this.stageChangeValue}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="documents">
                        {this.state.activeTab === 'documents' && (
                          <ReferenceDocument
                            {...this.props}
                            projectId={projectId}
                            stageName={
                              this.props.match.params.stageName ||
                              this.state.stageDropDownValue.name
                            }
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="issue">
                        {this.state.activeTab === 'issue' && (
                          <ModelIssueList
                            {...this.props}
                            projectId={projectId}
                            stageName={
                              this.props.match.params.stageName ||
                              this.state.stageDropDownValue.name
                            }
                            sidebarRef={this.sidebarRef}
                            stageDropDownValue={this.state.stageDropDownValue}
                            stageChangeValue={this.stageChangeValue}
                            issueId={this.props.match.params.issueId}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="submission">
                        {this.state.activeTab === 'submission' && (
                          <ProjectSubmission
                            {...this.props}
                            projectId={projectId}
                            stageName={
                              this.props.match.params.stageName ||
                              this.state.stageDropDownValue.name
                            }
                            sidebarRef={this.sidebarRef}
                            stageDropDownValue={this.state.stageDropDownValue}
                            stageChangeValue={this.stageChangeValue}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="task">
                        {this.state.activeTab === 'task' && (
                          <CommunicationSpace
                            {...this.props}
                            projectId={projectId}
                            sidebarRef={this.sidebarRef}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="statistics">
                        {this.state.activeTab === 'statistics' && (
                          <ProjectStats
                            {...this.props}
                            projectId={projectId}
                            sidebarRef={this.sidebarRef}
                          />
                        )}
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </Col>
              {isNotificationPanelEnabled && (
                <Col sm={3}>
                  {/* <Col className="pr-4"></Col> */}
                  <Container fluid className="pr-4">
                    <Row>
                      <Col>
                        <div className="mt-3 shadow bg-light rounded flex-grow-0 p-3">
                          <div
                            onClick={() => this.toggleNotificationPanel()}
                            id={'toggler-notifications'}
                            className="d-flex justify-content-between mb-2 pointer">
                            <div className="d-flex">
                              <h5 className="text-primary">
                                {t('notifications')}
                              </h5>
                              <div className="ml-2">
                                <Badge color="primary">
                                  {this.state.unReadNotificationCount}{' '}
                                </Badge>
                              </div>
                            </div>
                            <div>
                              <i className="fas fa-chevron-down mr-2"></i>
                            </div>
                          </div>
                          <Collapse
                            isOpen={isNotificationOpen}
                            toggler={'toggler-notifications'}>
                            <div
                              className={`w-100 ${styles.projectDetailCollapse}`}>
                              <Notifications
                                displayMode="block"
                                entity={{ type: 'project', id: projectId }}
                                updateUnreadNotificationCount={
                                  this.updateUnreadNotificationCount
                                }
                              />
                            </div>
                          </Collapse>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              )}
            </Row>
          </Container>
        )}

        {isLoaded && !project && <PageNotFound></PageNotFound>}
      </>
    );
  }
}

export default withTranslation(['project', 'main', 'error'])(
  ProjectDetailPresenter
);
