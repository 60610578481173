import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Modal, Collapse } from 'reactstrap';
import classnames from 'classnames';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import CreateSubGroup from '../create/createSubGroup';
import AddGroupMember from '../modify/addGroupMember';

import {
  fetchOrganizationStructure,
  getGroupMembers,
  updateGroup,
  fetchOrganizationMembers
} from '../../../../store/actions';

import './style.css';

const GroupTreeComponent = ({
  groupTree,
  groups,
  setCurrentGroupId,
  currentGroupId
}) => {
  const CollapsableNode = ({ id, details, itemChildren }) => {
    const [collapsed, setCollapsed] = useState(false);
    const key = id;
    return (
      <div key={key}>
        <div
          className={classnames('pointer d-flex p-2', {
            'border border-primary rounded border-thick': key === currentGroupId
          })}>
          <span onClick={() => setCollapsed(!collapsed)}>
            <i
              className={`fas fa-angle-right mr-1  ${
                !collapsed ? 'fa-rotate-90' : ''
              }`}
            />
          </span>
          <span
            className="pointer flex-grow-1"
            onClick={() => setCurrentGroupId(key)}>
            {details.name}
          </span>
        </div>
        <Collapse isOpen={!collapsed}>
          {!collapsed && (
            <div className="p-0 pl-2 ">
              <div className="pl-2 border-left border-thick">
                <GroupTreeComponent
                  groupTree={itemChildren}
                  groups={groups}
                  setCurrentGroupId={setCurrentGroupId}
                  currentGroupId={currentGroupId}
                />
              </div>
            </div>
          )}
        </Collapse>
      </div>
    );
  };

  return (
    <>
      {groupTree.map(item => {
        const key = item.team_id || item.department_id || item.organization_id;
        const details = groups[key];
        const itemChildren = item.departments || item.teams;
        if (itemChildren && itemChildren.length > 0) {
          return (
            <CollapsableNode
              id={key}
              details={details}
              itemChildren={itemChildren}
            />
          );
        } else {
          return (
            <div
              key={key}
              onClick={() => setCurrentGroupId(key)}
              className={classnames('p-2 pointer', {
                'border border-primary rounded border-thick':
                  key === currentGroupId
              })}>
              {details.name}
            </div>
          );
        }
      })}
    </>
  );
};

const GroupDetailComponent = ({
  groupDetails,
  currentGroupId,
  organizationId,
  updateGroup,
  t
}) => {
  const [isAddGroupModelOpen, setAddGroupModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [editedDetails, setEditedDetails] = useState(groupDetails);
  const [details, setDetails] = useState(groupDetails);

  useEffect(() => {
    setEditedDetails(groupDetails);
    setDetails(groupDetails);
  }, [groupDetails]);

  const handleInput = (key, event) => {
    const value = event.target.textContent;
    setEditedDetails({ ...details, [key]: value.trim() });
  };

  const onSubmitChanges = async () => {
    try {
      setDetails(editedDetails);
      await updateGroup(groupDetails.type, currentGroupId, editedDetails);
      setIsEditing(false);
    } catch (err) {
      // setDetails(groupDetails);
      // console.log({ err });
    }
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="border-bottom p-2 d-flex justify-content-between">
          <h4 className="text-primary">
            {t('organization:details_section_title')}
          </h4>
          {groupDetails.type !== 'team' && (
            <Button
              color="primary"
              outline
              onClick={() => setAddGroupModalOpen(!isAddGroupModelOpen)}>
              {t('organization:add_subgroup')}
            </Button>
          )}
        </div>
        <div className="d-flex flex-column p-2">
          <dl className="row">
            <dt className="col-sm-4">Group name</dt>
            <dd
              contentEditable={isEditing}
              suppressContentEditableWarning={true}
              onInput={e => handleInput('name', e)}
              className="col-sm-8">
              {details.name}
            </dd>
            <dt className="col-sm-4">Description </dt>
            <dd
              contentEditable={isEditing}
              suppressContentEditableWarning={true}
              onInput={e => handleInput('description', e)}
              className="col-sm-8">
              {details.description}
            </dd>
          </dl>
        </div>
        <div className="d-flex justify-content-end">
          {isEditing ? (
            <>
              <Button
                color="danger"
                onClick={() => {
                  setDetails(groupDetails);
                  setIsEditing(false);
                }}>
                {t('main:cancel')}
              </Button>
              <Button
                color="success"
                onClick={() => {
                  onSubmitChanges();
                }}>
                {t('main:done')}
              </Button>
            </>
          ) : (
            <Button color="primary" outline onClick={() => setIsEditing(true)}>
              {t('main:edit')}
            </Button>
          )}
        </div>
      </div>
      <Modal
        isOpen={isAddGroupModelOpen}
        toggle={() => setAddGroupModalOpen(!isAddGroupModelOpen)}>
        <CreateSubGroup
          groupType={
            groupDetails.type === 'organization'
              ? 'department'
              : groupDetails.type === 'department'
              ? 'team'
              : null
          }
          parentGroupId={currentGroupId}
          organizationId={organizationId}
          onSubmitSuccess={() => setAddGroupModalOpen(!isAddGroupModelOpen)}
        />
      </Modal>
    </>
  );
};

const GroupMemberComponent = ({
  groupMembers,
  t,
  groupType,
  organizationId,
  groupId,
  organizationMembers,
  fetchOrganizationMembers
}) => {
  const [loadingMember, setLoadingMember] = useState(false);
  const [isAddMemberModelOpen, setAddMemberModalOpen] = useState(false);
  return (
    <>
      <div className="d-flex flex-column">
        <div className=" border-bottom p-2 d-flex justify-content-between">
          <h4 className="text-primary">
            {t('organization:member_section_title')}
          </h4>
          <Button
            color="primary"
            outline
            onClick={async () => {
              setLoadingMember(true);
              try {
                await fetchOrganizationMembers(organizationId);
                setAddMemberModalOpen(!isAddMemberModelOpen);
                setLoadingMember(false);
              } catch (err) {
                setLoadingMember(false);
              }
            }}>
            {t('add_member')}
          </Button>
        </div>
        <div className="p-2 groupMembersContainer">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="border-top-0" scope="col">
                  {t('main:email')}
                </th>
                <th className="border-top-0" scope="col">
                  {t('organization:role')}
                </th>
                <th className="border-top-0" scope="col">
                  {t('organization:action_member')}
                </th>
              </tr>
            </thead>
            <tbody>
              {groupMembers && groupMembers.length > 0 ? (
                groupMembers.map((member, index) => {
                  return (
                    <tr key={`group-member-${index}`}>
                      <td className="align-middle">{member.email}</td>
                      <td className="align-middle">{member.role}</td>
                      <td className="align-middle">
                        <Button color="link"> {t('main:edit')}</Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    NO MEMBERS
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isAddMemberModelOpen}
        toggle={() => setAddMemberModalOpen(!isAddMemberModelOpen)}
        size="lg">
        <AddGroupMember
          groupType={groupType}
          groupId={groupId}
          organizationId={organizationId}
          organizationMembers={(organizationMembers || []).filter(
            u => !groupMembers.map(m => m.user_id).includes(u.user_id)
          )}
          toggle={() => setAddMemberModalOpen(!isAddMemberModelOpen)}
          existingMembers={groupMembers.map(m => m.user_id)}
          onSubmitSuccess={() => setAddMemberModalOpen(!isAddMemberModelOpen)}
        />
      </Modal>
    </>
  );
};

const ViewOrganizationStructureComponent = ({
  t,
  groupTree,
  groups,
  getGroupMembers,
  memberGroups,
  organizationMembers,
  fetchOrganizationMembers,
  updateGroup,
  organizationId
}) => {
  // console.log({groupTree});
  const [currentGroupId, setCurrentGroupId] = useState(
    groupTree.root.organization_id
  );
  const [currentGroup, setCurrentGroup] = useState({
    details: groupTree.root,
    members: []
  });

  useEffect(() => {
    const nextGroupDetails = groups[currentGroupId];
    if (nextGroupDetails) {
      if (memberGroups && memberGroups[currentGroupId]) {
        setCurrentGroup({
          details: nextGroupDetails,
          members: memberGroups[currentGroupId]
        });
      } else {
        (async () => {
          const members = await getGroupMembers(
            nextGroupDetails.type,
            currentGroupId
          );
          setCurrentGroup({
            details: nextGroupDetails,
            members
          });
        })();
      }
    } else {
      // TODO
    }
  }, [currentGroupId, memberGroups]);
  // const test = memberGroups && memberGroups[currentGroupId];
  // useEffect(() => {
  //   console.log('MEMBER GROUP CHANGES');
  //   console.log({ memberGroups });
  //   if (memberGroups && memberGroups[currentGroupId]) {
  //     setCurrentGroup({
  //       details: currentGroup.details,
  //       members: memberGroups[currentGroupId]
  //     });
  //   }
  // }, [test ]);

  return (
    <Container fluid>
      <div className="shadow p-1 mt-3 mb-5 rounded">
        <Row className="d-flex align-items-stretch">
          <Col sm={7} className="">
            <div className="p-1 border-right rounded h-100 ">
              <h2 className="text-primary">{t('organization:structure')}</h2>
              <GroupTreeComponent
                groupTree={[groupTree.root]}
                groups={groups}
                setCurrentGroupId={setCurrentGroupId}
                currentGroupId={currentGroupId}
              />
            </div>
          </Col>
          <Col sm={5} className="d-flex flex-column ">
            <div className="d-flex flex-column sticky-top">
              <div className="border-bottom  rounded mb-3 p-1 flex-grow-1 ">
                <GroupDetailComponent
                  groupDetails={currentGroup.details}
                  currentGroupId={currentGroupId}
                  organizationId={organizationId}
                  updateGroup={updateGroup}
                  t={t}
                />
              </div>
              <div className=" rounded p-1 flex-grow-1 ">
                <GroupMemberComponent
                  t={t}
                  groupId={
                    currentGroup.details.team_id ||
                    currentGroup.details.department_id ||
                    currentGroup.details.organization_id
                  }
                  groupType={currentGroup.details.type}
                  organizationId={organizationId}
                  organizationMembers={organizationMembers}
                  fetchOrganizationMembers={fetchOrganizationMembers}
                  groupMembers={currentGroup.members}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const ViewOrganizationStructureContainer = props => {
  const { fetchOrganizationStructure, organizationId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        await fetchOrganizationStructure(organizationId);
        setIsLoaded(true);
      } catch (err) {
        setHasError(true);
      }
    })();
  }, [organizationId]);

  return (
    <>
      {!hasError && isLoaded && (
        <ViewOrganizationStructureComponent
          {...props}
          organizationId={organizationId}
        />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationStructure: organizationId =>
      dispatch(fetchOrganizationStructure(organizationId)),
    getGroupMembers: (groupType, groupId) =>
      dispatch(getGroupMembers(groupType, groupId)),
    updateGroup: (groupType, groupId, values) =>
      dispatch(updateGroup(groupType, groupId, values)),
    fetchOrganizationMembers: organizationId =>
      dispatch(fetchOrganizationMembers(organizationId))
  };
};

const mapStateToProps = state => {
  return {
    groupTree:
      state.organization &&
      state.organization.current &&
      state.organization.current.groupTree,
    groups:
      state.organization &&
      state.organization.current &&
      state.organization.current.groups,
    memberGroups:
      state.organization &&
      state.organization.current &&
      state.organization.current.memberGroups,
    organizationMembers:
      state.organization &&
      state.organization.current &&
      state.organization.current.memberList
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(ViewOrganizationStructureContainer);
