import { bimrlDataActionType } from '../../constants/actionType';

const {
  ADD_SAVED_QUERIES,
  ADD_RESULT_ENTRY,
  RESET_BIMRL_RESULT,
  ADD_LIST_RESULT_ENTRY
} = bimrlDataActionType;

const initialState = {
  savedQueries: [],
  results: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_SAVED_QUERIES:
    return { ...state, savedQueries: action.savedQueries };
  case ADD_RESULT_ENTRY: {
    const { rootEntry, data } = action;
    return {
      ...state,
      results: {
        ...state.results,
        [rootEntry]: { ...state.results[rootEntry], ...data }
      }
    };
  }
  case ADD_LIST_RESULT_ENTRY: {
    const dataList = action.list;
    const nextResultState = dataList.reduce(
      (next, current) => {
        const { project_id, ...rest } = current;
        return {
          ...next,
          [current.project_id]: { ...next[project_id], ...rest }
        };
      },
      { ...state.results }
    );
    return { ...state, results: nextResultState };
  }
  case RESET_BIMRL_RESULT: {
    return { ...state, results: initialState.results };
  }

  default:
    return state;
  }
};

export default reducer;
