import { subscriptionActionType } from '../../constants/actionType';
const initialState = {};

const {
  CREATE_SUBSCRIPTION,
  FETCH_SUBSCRIPTION,
  RESET_SUBSCRIPTION
} = subscriptionActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case CREATE_SUBSCRIPTION: {
    const { subscription, subscriptionId } = action;
    return {
      ...state,
      [subscriptionId]: { ...state[subscriptionId], ...subscription }
    };
  }
  case FETCH_SUBSCRIPTION: {
    const { subscription, subscriptionId } = action;
    return { ...state, [subscriptionId]: subscription };
  }
  case RESET_SUBSCRIPTION: {
    return {};
  }
  default:
    return state;
  }
};

export default reducer;
