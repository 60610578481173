import { organizationActionType } from '../../constants/actionType';
const initialState = {};

const {
  ADD_CURRENT_ORGANIZATION,
  ADD_ORGANIZATION,
  ADD_GROUP_MEMBERS,
  UPDATE_GROUP,
  ADD_ORGANIZATION_MEMBERS,
  ADD_DEPARTMENT,
  ADD_TEAM
} = organizationActionType;

const reducer = (state = initialState, action) => {
  const { type, organizationId, ...actionPayload } = action;
  switch (type) {
    case ADD_CURRENT_ORGANIZATION:
      return {
        ...state,
        current: { ...(state.current || {}), ...actionPayload }
      };
    case ADD_ORGANIZATION:
      return {
        ...state,
        [organizationId]: {
          ...(state[organizationId] || {}),
          ...actionPayload
        }
      };
    case ADD_GROUP_MEMBERS:
      return {
        ...state,
        current: {
          ...state.current,
          memberGroups: {
            ...state.current.memberGroups,
            [actionPayload.groupId]: actionPayload.members
          }
        }
      };
    case UPDATE_GROUP:
      return {
        ...state,
        current: {
          ...state.current,
          groups: {
            ...state.current.groups,
            [actionPayload.groupId]: actionPayload.data
          }
        }
      };
    case ADD_ORGANIZATION_MEMBERS:
      return {
        ...state,
        current: {
          ...state.current,
          memberList: actionPayload.memberList
        }
      };
    case ADD_TEAM: {
      const nextDepartmentsInTree = state.current.groupTree.root.departments;
      const dptIndex = nextDepartmentsInTree.findIndex(
        d => d.department_id === actionPayload.team.department_id
      );
      if (dptIndex >= 0) {
        const parentDpt = nextDepartmentsInTree[dptIndex];
        nextDepartmentsInTree.splice(dptIndex, 1, {
          ...parentDpt,
          teams: [...(parentDpt.teams || []), actionPayload.team]
        });
      }
      return {
        ...state,
        current: {
          ...state.current,
          groupTree: {
            root: {
              ...state.current.groupTree.root,
              departments: nextDepartmentsInTree
            }
          },
          groups: {
            ...state.current.groups,
            [actionPayload.team.team_id]: {
              ...actionPayload.team,
              type: 'team'
            }
          }
        }
      };
    }
    case ADD_DEPARTMENT:
      return {
        ...state,
        current: {
          ...state.current,
          groupTree: {
            root: {
              ...state.current.groupTree.root,
              departments: [
                ...state.current.groupTree.root.departments,
                actionPayload.department
              ]
            }
          },
          groups: {
            ...state.current.groups,
            [actionPayload.department.department_id]: {
              ...actionPayload.department,
              type: 'department'
            }
          }
        }
      };

    default:
      return state;
  }
};

export default reducer;
