import React from 'react';
import { connect } from 'react-redux';

import RuleManagement from '../../Shared/RuleManagement/rulesGroup';
import {
  fetchRules,
  setHiddenRules,
  saveRules,
  cloneMultipleRules,
  deleteRules
} from '../../../store/actions';

const UserRuleManagement = props => <RuleManagement level="user" {...props} />;

const mapStateToProps = (state, { match }) => {
  return {
    // rules_admin: state.rules_admin
    rules: state.rules.rulesTree,
    defaultTree: state.rules.defaultRulesTree
    // readOnlyRules: []
    // rules:
  };
};

const mapDispatchToProps = (dispatch, { userId }) => {
  const destination = { type: 'user', userId };
  return {
    fetchRules: params => dispatch(fetchRules(params.type)),
    saveRules: (rulesTree, type) =>
      dispatch(saveRules(rulesTree, type, destination)),
    setHiddenRules: hiddenRuleList => dispatch(setHiddenRules(hiddenRuleList)),
    cloneMultipleRules: (ruleIdList, ruleType) =>
      dispatch(cloneMultipleRules(ruleIdList, ruleType, 'user')),
    updateRuleSet: rules => dispatch({ type: 'UPDATE_RULE_SET', rules }),
    deleteRules: rules => dispatch(deleteRules(rules))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRuleManagement);
