import React, { Component } from 'react';

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { timeAgoFormatting } from '../../../locales/dateFormat';

class ModelList extends Component {
  render() {
    const { translate, models } = this.props;
    return (
      <ListGroup>
        {models.map((model, key) => {
          return (
            <ListGroupItem key={key}>
              <Row className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    {model.name} - {prettyBytes(model.size)}
                  </div>
                  {model.date && (
                    <div className="text-secondary">
                      last updated: {timeAgoFormatting(model.date)} ago
                    </div>
                  )}
                </div>
                <Row className="mr-2">
                  <div>
                    <a
                      download
                      id={`downloadModel-${key}`}
                      onClick={e => e.stopPropagation()}
                      className="pointer mr-2"
                      href={`${
                        process.env.REACT_APP_API_ENDPOINT[
                          process.env.REACT_APP_API_ENDPOINT.length - 1
                        ] == '/'
                          ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                          : process.env.REACT_APP_API_ENDPOINT
                      }/${model.downloadLink}`}>
                      <span>
                        <i className="fas fa-download fs-1-2x text-primary" />
                      </span>
                    </a>
                    <UncontrolledTooltip
                      fade={false}
                      boundariesElement="window"
                      target={`downloadModel-${key}`}>
                      {translate('download')}
                    </UncontrolledTooltip>
                  </div>
                </Row>
              </Row>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }
}

export default withTranslation(['submission'])(ModelList);
