import React, { Component, useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Row,
  Col,
  Input,
  Label,
  Button,
  ListGroupItem,
  ListGroup,
  FormGroup
} from 'reactstrap';
import {
  setFilestoUpload,
  filesUpload,
  removeFileItem,
  uploadImageServer,
  openComfirmDeleteModal,
  updateCategoryItem,
  categoryItemAddToFavorite,
  fetchFavoriteCategoryItem
} from '../store/actions';
import { useSelector } from 'react-redux';

import * as Yup from 'yup';

import ReactPaginate from 'react-paginate';
import Dropzone from 'react-dropzone';
import Message from './shared/Message';
import ConfirmDeleteCatItemModal from './ConfirmDeleteCatItemModal';
import CustomInput from './shared/Input/customInput';
import CustomInputCheckbox from './shared/Input/customInputCheckbox';
import knowledgehubContext from '../store/config/context';

import styles from './styles.module.css';
import './knowledgehub.css';

const postDataComponent = (sliceData, otherProps) => {
  const {
    modalBody,
    setModalBody,
    onToggleModal,
    getRowContentAsText,
    updatePostData,
    selectedCategoryInfo,
    renderCategoryItems
  } = otherProps;

  return (
    <ListGroup>
      {sliceData.map((list, categoryItemKey) => {
        return (
          <ListItem
            key={`item-key-${categoryItemKey}`}
            {...otherProps}
            list={list}
            modalBody={modalBody}
            setModalBody={setModalBody}
            onToggleModal={onToggleModal}
            categoryItemKey={categoryItemKey}
            getRowContentAsText={getRowContentAsText}
            updatePostData={updatePostData}
            selectedCategoryInfo={selectedCategoryInfo}
          />
        );
      })}
    </ListGroup>
  );
};

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      postData: [],
      perPage: 5,
      currentPage: 0
    };
    this.selectedCategoryInfo = React.createRef();
  }

  // update specific category item data
  updatePostData = (dataList, newData, propsToUpdate) => {
    const data = dataList;
    const sliceData = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    const index = sliceData.findIndex(
      dt =>
        dt.categoryId === newData.categoryId &&
        dt.category_item_id === newData.category_item_id
    );

    const newValues = propsToUpdate.reduce((acc, curr) => {
      return { ...acc, [curr]: newData[curr] };
    }, {});

    const newSliceData = [
      ...sliceData.slice(0, index),
      {
        ...sliceData[index],
        ...newValues
      },
      ...sliceData.slice(index + 1)
    ];

    const postData = postDataComponent(newSliceData, {
      ...this.props,
      updatePostData: this.updatePostData,
      selectedCategoryInfo: this.selectedCategoryInfo,
      renderCategoryItems: this.renderCategoryItems
    });
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      postData
    });
  };

  // set category item list per page
  setPageSize = perPage =>
    this.setState({ perPage, offset: 0, currentPage: 0 });

  //
  renderCategoryItems = lists => {
    const data = lists;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    const postData = postDataComponent(slice, {
      ...this.props,
      updatePostData: this.updatePostData,
      selectedCategoryInfo: this.selectedCategoryInfo,
      renderCategoryItems: this.renderCategoryItems
    });

    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      postData
    });
  };

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset
      },
      () => {
        this.renderCategoryItems(this.props.data);
      }
    );
  };

  componentDidMount() {
    this.renderCategoryItems(this.props.data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data.length !== prevProps.data.length ||
      this.state.perPage !== prevState.perPage
    ) {
      this.renderCategoryItems(this.props.data);
    }

    const index = this.props.data.findIndex(
      dt =>
        this.selectedCategoryInfo.current &&
        dt.categoryId === this.selectedCategoryInfo.current.categoryId &&
        dt.category_item_id === this.selectedCategoryInfo.current.categoryItemId
    );

    if (index !== -1) {
      const uploadedFiles = this.props.data[index].files.filter(f => f.is_new);
      const prevUploadFiles = prevProps.data[index].files.filter(f => f.is_new);
      if (
        this.props.data[index].files.length !==
          prevProps.data[index].files.length ||
        uploadedFiles.length !== prevUploadFiles.length
      )
        this.renderCategoryItems(this.props.data);
    }
  }

  render() {
    const options = [{ value: 5 }, { value: 10 }, { value: 15 }];
    return (
      <div className="py-5">
        {this.state.postData}
        <ReactPaginate
          previousLabel={'← Prev'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'kh-pagination'}
          subContainerClassName={'pages kh-pagination'}
          activeClassName={'active'}
        />
        <div>
          <Label for="kh-pagination-per-page">
            {this.props.t('number_of_items_per_page')}
          </Label>

          <Input
            className="w-25"
            type="select"
            name="select-kh-pagination-per-page"
            bsSize="sm"
            id="kh-pagination-per-page"
            onChange={e => this.setPageSize(parseInt(e.target.value))}>
            {options.map((opt, i) => {
              return (
                <option key={`opt-${i}`} value={opt.value}>
                  {opt.value}
                </option>
              );
            })}
          </Input>
        </div>
      </div>
    );
  }
}

const ListItem = ({
  t,
  list,
  modalBody,
  setModalBody,
  onToggleModal,

  getRowContentAsText,
  updatePostData,
  data,
  selectedCategoryInfo
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { authUser } = useSelector(state => state);
  const { state, dispatch, isWebviewerMode } = useContext(knowledgehubContext);

  const formikConfig = () => {
    const {
      categoryId,
      category_item_id,
      groupname,
      title,
      description,
      is_default
    } = list;
    return {
      initialValues: {
        category_item_title: title,
        category_item_description: description,
        category_item_is_default: !!is_default
      },
      validateOnClick: true,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        category_item_title: Yup.string().trim().required('Required'),
        category_item_description: Yup.string().trim().required('Required')
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        dispatch(
          updateCategoryItem(
            categoryId,
            category_item_id,
            {
              title: values.category_item_title,
              description: values.category_item_description,
              is_default: values.category_item_is_default
            },
            groupname,
            state.selectedCategoryKey
          )
        )
          .then(res => {
            if (res.success) {
              Message.success(t('updated_successfully'));
              dispatch(fetchFavoriteCategoryItem());
              setIsEditing(false);
              updatePostData(data, res.data, [
                'title',
                'description',
                'is_default'
              ]);
            } else if (!res.success && res.data.hasExistingTitle) {
              Message.error(
                t('title_already_exists', { title: values.category_item_title })
              );
            } else Message.error(t('failed_to_update'));
            setSubmitting(false);
            resetForm();
          })
          .catch(() => {
            Message.error(t('failed_to_update'));
            setSubmitting(false);
          });
      }
    };
  };

  const isFavorite = !!Object.keys(state.favoriteResultList).find(itemKey => {
    return state.favoriteResultList[itemKey].list.some(
      li => li.category_item_id === list.category_item_id
    );
  });

  return (
    <Formik {...formikConfig()}>
      {formikProps => {
        const { isSubmitting, dirty, submitForm } = formikProps;
        const handleSubmitForm = () => submitForm();
        return (
          <Form>
            <ListGroupItem>
              {isEditing && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        id={`category_item_is_default_${list.category_item_id}`}
                        bsSize="sm"
                        name={`category_item_is_default`}
                        label={t('category_item_is_default')}
                        component={CustomInputCheckbox}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="link"
                        disabled={isSubmitting}
                        onClick={() => setIsEditing(false)}>
                        <i className="fas fa-arrow-left text-secondary"></i>
                      </Button>
                      <Button
                        color="link"
                        disabled={isSubmitting || !dirty}
                        onClick={handleSubmitForm}>
                        <i className="fas fa-save text-secondary"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <div className="d-flex justify-content-start">
                {isEditing ? (
                  <FormGroup className="w-100 mr-auto">
                    <Field
                      name="category_item_title"
                      label={t('category_item_title')}
                      component={CustomInput}
                    />
                  </FormGroup>
                ) : (
                  <div className="ellipsis my-2 mr-auto font-weight-bold">
                    {list.title}
                  </div>
                )}

                {!isEditing && (
                  <>
                    <div>
                      <Button
                        color="link"
                        onClick={() =>
                          dispatch(
                            categoryItemAddToFavorite(
                              list.categoryId,
                              list.category_item_id,
                              !isFavorite,
                              list.groupname
                            )
                          )
                        }>
                        <i
                          className={`${
                            isFavorite
                              ? 'fas fa-star text-warning'
                              : 'far fa-star text-secondary'
                          }`}
                        />
                      </Button>
                    </div>
                    {authUser.function === 'ADMIN' && !isWebviewerMode ? (
                      <>
                        <div>
                          <Button
                            color="link"
                            onClick={() => {
                              setIsEditing(true);
                            }}>
                            <i className="fas fa-pen-alt text-secondary"></i>
                          </Button>
                        </div>

                        <div>
                          <Button
                            color="link"
                            onClick={() => {
                              dispatch(
                                openComfirmDeleteModal(
                                  !state.confirmDeleteCatItemData.isOpen,
                                  list.categoryId,
                                  list.category_item_id,
                                  list.title
                                )
                              );
                            }}>
                            <i className="far fa-trash-alt text-secondary"></i>
                          </Button>
                          <ConfirmDeleteCatItemModal />
                        </div>
                      </>
                    ) : null}
                    <div>
                      <Button
                        color="link"
                        onClick={() =>
                          getRowContentAsText({
                            groupname: list.groupname,
                            title: list.title,
                            description: list.description
                          })
                        }>
                        <i className="far fa-copy text-secondary"></i>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex justify-content-start">
                {isEditing ? (
                  <FormGroup className="w-100">
                    <Field
                      name="category_item_description"
                      type="textarea"
                      label={t('category_item_description')}
                      component={CustomInput}
                    />
                  </FormGroup>
                ) : (
                  <>
                    <div className="ellipsis my-2">{list.description}</div>{' '}
                    <Button
                      color="link"
                      size="sm"
                      className="read-more font-weight-bold"
                      onClick={() => {
                        setModalBody({
                          ...modalBody,
                          title: list.title,
                          description: list.description,
                          files: list.files
                        });
                        onToggleModal();
                      }}>
                      {t('read_more')}
                    </Button>
                  </>
                )}
              </div>
              {!isEditing && (
                <div>
                  {authUser.function === 'ADMIN' && !isWebviewerMode && (
                    <>
                      <Row className="my-2">
                        <>
                          <Col sm={5} md={4} lg={2}>
                            <Dropzone
                              className={`text-center ${styles.dropzoneStyle}`}
                              onDrop={files => {
                                selectedCategoryInfo.current = {
                                  categoryId: list.categoryId,
                                  categoryItemId: list.category_item_id
                                };
                                const index = data.findIndex(
                                  dt =>
                                    dt.categoryId === list.categoryId &&
                                    dt.category_item_id ===
                                      list.category_item_id
                                );
                                dispatch(
                                  setFilestoUpload(
                                    list.groupname,
                                    index,
                                    files,
                                    authUser.userId
                                  )
                                );
                              }}
                              accept={['.jpg', '.jpeg', '.png']}
                              multiple={true}>
                              <div className="p-2">
                                <div>{t('add_image')}</div>
                                <div>
                                  <small>{t('jpeg_or_png')}</small>
                                </div>
                              </div>
                            </Dropzone>
                          </Col>
                        </>
                      </Row>

                      <Row>
                        {list.files &&
                          list.files.length !== 0 &&
                          list.files.map(
                            (file, fileIndex) =>
                              file.is_new && (
                                <p
                                  key={`file-${fileIndex}`}
                                  className="ml-3 my-1 px-3 text-center border rounded-pill">
                                  {file.name}
                                  <i
                                    className="fas fa-times ml-3 my-1"
                                    onClick={() => {
                                      selectedCategoryInfo.current = {
                                        categoryId: list.categoryId,
                                        categoryItemId: list.category_item_id
                                      };
                                      const index = data.findIndex(
                                        dt =>
                                          dt.categoryId === list.categoryId &&
                                          dt.category_item_id ===
                                            list.category_item_id
                                      );
                                      dispatch(
                                        removeFileItem(
                                          list.groupname,
                                          index,
                                          fileIndex
                                        )
                                      );
                                    }}></i>
                                </p>
                              )
                          )}
                        {list.files &&
                          list.files.filter(file => file.is_new == true)
                            .length !== 0 && (
                            <>
                              <Button
                                color="link"
                                className="font-weight-bold"
                                onClick={async () => {
                                  dispatch(
                                    uploadImageServer(
                                      list.categoryId,
                                      list.category_item_id,
                                      list.files
                                    )
                                  ).then(res => {
                                    // selectedCategoryInfo.current = null;

                                    Message.success(t('upload_successful'));
                                  });

                                  dispatch(
                                    filesUpload(
                                      list.files,
                                      list.groupname,
                                      list.category_item_id,
                                      list.categoryId
                                    )
                                  );
                                }}>
                                {t('upload_image')}
                              </Button>
                            </>
                          )}
                      </Row>
                    </>
                  )}
                </div>
              )}
            </ListGroupItem>
          </Form>
        );
      }}
    </Formik>
  );
};
