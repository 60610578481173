import { attachmentFileActionType } from '../../constants/actionType';

import { unionBy } from 'lodash';

const {
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  RESET_ATTACHMENTS,
  FETCH_ATTACHMENT,
  MERGE_ATTACHMENTS
} = attachmentFileActionType;
const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTACHMENTS: {
      const { file } = action;

      // const final = [...state, file];

      // const index = final.findIndex(doc => doc.name === file.name);
      file.dirty = true;

      return [...state.filter(doc => doc.name !== file.name), file];

      // if (index >= 0) {
      //   const nextState = final;
      //   nextState[index].dirty = true;
      //   return nextState;
      // }
    }
    case FETCH_ATTACHMENT: {
      const { file } = action;

      return [...state, file]
        .filter(doc => !!doc.id)
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
    }
    case REMOVE_ATTACHMENT: {
      const index = state.findIndex(
        doc => doc.name === action.payload.file.name
      );

      const new_file = action.payload.file.id
        ? [
            {
              dirty: true,
              removed: true,
              fileInput: state[index]
            }
          ]
        : [];

      return [...state.slice(0, index), ...new_file, ...state.slice(index + 1)];
    }
    case RESET_ATTACHMENTS: {
      return initialState;
    }

    case MERGE_ATTACHMENTS: {
      const { payload } = action;

      const result = unionBy(payload.data, state, 'id')
        .filter(file => file.id && !file.removed)
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });

      return result;
    }
    default:
      return state;
  }
};

export default reducer;
