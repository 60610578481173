import React, { useEffect, useState, useRef } from 'react';

import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

const listedRoles = t => {
  return {
    ADMIN: {
      id: 1,
      label: t('rules:administrator')
    },
    PO: {
      id: 2,
      label: t('rules:processing_officer')
    },
    QP: {
      id: 3,
      label: t('rules:qualified_person')
    }
  };
};

const Roles = ({
  setVisibility,
  t,
  asFormikField,
  values,
  setValues,
  handleSubmit,

  asFilter = false,
  setFilterValues,
  filters
}) => {
  const [filteredVisibility, setFilteredVisibility] = useState(
    (filters && filters.visibility) || {
      ADMIN: false,
      PO: false,
      QP: false
    }
  );

  return Object.keys(listedRoles(t)).map((role, index) => {
    return !!asFilter ? (
      <CustomInput
        type="checkbox"
        checked={!!filteredVisibility[role]}
        name="visibility"
        label={listedRoles(t)[role].label}
        id={listedRoles(t)[role].id}
        key={`${role}-${index}`}
        onChange={async e => {
          const isSelected = e.target.checked;

          const nextVisibility = {
            ...filteredVisibility,
            [role]: isSelected
          };

          await setFilteredVisibility(nextVisibility);
          setFilterValues('visibility', nextVisibility);
        }}
      />
    ) : (
      <CustomInput
        disabled={role === 'ADMIN'}
        type="checkbox"
        name="visibility"
        label={listedRoles(t)[role].label}
        id={listedRoles(t)[role].id}
        key={`${role}-${index}`}
        checked={!!values.visibility ? !!values.visibility[role] : null}
        onChange={async e => {
          const isSelected = e.target.checked;
          if (!!asFormikField) {
            await setValues({
              ...values,
              visibility: {
                ...values.visibility,
                [role]: isSelected
              }
            });
            handleSubmit();
          } else {
            setVisibility(role, isSelected);
          }
        }}
      />
    );
  });
};

export default Roles;
