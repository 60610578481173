import {
  subscriptionActionType,
  authUserActionType,
  userActionType
} from '../../constants/actionType';
import { performRequest } from '../requests';
const { SET_USER_SUBSCRIPTION_ID, SET_ME } = authUserActionType;
const { MERGE_USER_USAGE } = userActionType;

// ####################### STRIPE ####################################
export const createCheckoutSessionAction = prices => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      'api/payment/create-checkout-session',
      { prices },
      requestConfig,
      'CREATE_CHECKOUT_SESSION'
    )
  )
    .then(res => res.data)
    .then(result => {
      return result;
    });
};

export const getProductsAction = (filters = {}) => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      'api/payment/product-list',
      { filters },
      requestConfig,
      'GET_PRODUCTS'
    )
  )
    .then(res => res.data)
    .then(result => {
      return result;
    });
};

export const fecthUserActiveSubscription = () => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      '/api/payment/active-user-subscription',
      {},
      requestConfig,
      'FETCH_USER_ACTIVE_SUBSCRIPTION'
    )
  )
    .then(res => res.data)
    .then(({ success, data, error, message }) => {
      if (success) {
        const { subscription, productPlan, usage } = data;
        return data;
      } else {
        throw new Error(message || 'error:default_message');
      }
    })
    .catch(err => {
      dispatch({
        type: SET_USER_SUBSCRIPTION_ID,
        currentActiveSubscriptionId: null,
        hasActiveSubscription: false
      });
    });
};

export const cancelSubscriptionAction = selectedAddon => (
  dispatch,
  getState
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      'api/payment/cancel-subscription',
      { selectedAddon },
      requestConfig,
      'CANCEL_SUBSCRIPTION'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, data, error, message } = result;
      if (success) {
        return { success, data };
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};

export const updateSubscriptionAction = ({
  updateType,
  currentSubscription,
  selectedPlan,
  selectedAddon,
  currentPlan
}) => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      'api/payment/update-subscription',
      {
        currentSubscription,
        selectedPlan,
        currentPlan,
        selectedAddon,
        updateType
      },
      requestConfig,
      'UPDATE_SUBSCRIPTION'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, data, error, message } = result;
      if (success) {
        return { success, data };
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};

export const addonUpgradeAction = ({
  currentSubscription,
  selectedAddon,
  currentPlan
}) => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/payment/addon-upgrade',
      { currentSubscription, selectedAddon },
      requestConfig,
      'ADDON_UPGRADE'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, sessionId, data, error, message } = result;
      if (success) {
        return { success, data };
      } else if (sessionId) {
        return { success, sessionId };
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};

export const getCustomerPortalAction = () => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/payment/customer-portal',
      {},
      requestConfig,
      'GET_CUSTOMER_PORTAL'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, error, message, url } = result;
      if (success) {
        return url;
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};

export const getUserUsageAction = (userId, isAuthUser = false) => (
  dispatch,
  getState
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/payment/user-usage',
      { userId },
      requestConfig,
      'GET_USER_USAGE'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, error, message, data } = result;

      if (success) {
        data.available_cu = data.total_cu - (data.used_cu + data.reserved_cu);
        data.available_model_storage =
          data.total_model_storage -
          (data.used_model_storage + data.reserved_model_storage);

        if (isAuthUser) {
          dispatch({ type: SET_ME, data: { userUsage: data } });
        } else {
          dispatch({ type: MERGE_USER_USAGE, userId, userUsageData: data });
        }

        return data;
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};

export const updateUserUsageAction = (userId, newData) => (
  dispatch,
  getState
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/payment/update-user-usage',
      { userId, newData },
      requestConfig,
      'SAVE_USER_USAGE'
    )
  )
    .then(res => res.data)
    .then(result => {
      const { success, error, message, data } = result;

      if (success) {
        data.available_cu = data.total_cu - (data.used_cu + data.reserved_cu);
        data.available_model_storage =
          data.total_model_storage -
          (data.used_model_storage + data.reserved_model_storage);

        dispatch({
          type: MERGE_USER_USAGE,
          userId,
          userUsageData: data
        });
        return result;
      } else {
        throw new Error(error || message || 'error:default_message');
      }
    });
};
