import React, { Component } from 'react';
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import styles from './Notifications.module.css';

import NotificationList from './NotificationList';
import NotificationActionModal from './NotificationActionModal';

export class NotificationPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalMessage: '',
      toggleModal: this.toggleActionModal,
      confirmAction: this.confirmClearAllNotification
    };
  }

  toggleActionModal = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };

  toggleClearAllNotificationModal = () => {
    this.toggleActionModal();
    this.setState({
      modalMessage: 'clear_notification_message',
      confirmAction: this.confirmClearAllNotification
    });
  };

  toggleMarkAllAsReadNotificationModal = () => {
    this.toggleActionModal();
    this.setState({
      modalMessage: 'mark_as_read_notification_message',
      confirmAction: this.confirmMarkAllasReadNotification
    });
  };

  confirmClearAllNotification = () => {
    this.props.clearAllNotification();
    this.toggleActionModal();
  };

  confirmMarkAllasReadNotification = () => {
    this.props.readAllNotification();
    this.toggleActionModal();
  };

  render() {
    const { t } = this.props;
    const { openModal, toggleModal, modalMessage, confirmAction } = this.state;
    return (
      <>
        <NotificationActionModal
          openModal={openModal}
          toggleModal={toggleModal}
          message={modalMessage}
          confirmAction={confirmAction}
        />
        <UncontrolledPopover
          boundariesElement="scrollParent"
          className="notification-popover-container"
          target={this.props.target}
          trigger="legacy"
          placement="bottom">
          <PopoverHeader className="py-1 px-3 d-flex justify-content-end bd-highlight">
            {' '}
            <Button
              className="p-0 mr-1"
              color="link"
              size="sm"
              onClick={() => this.toggleMarkAllAsReadNotificationModal()}>
              {t('mark_all_as_read')}
            </Button>
            <Button
              className="p-0 mr-1"
              color="link"
              size="sm"
              onClick={() => this.toggleClearAllNotificationModal()}>
              {t('clear_all_notification')}
            </Button>
          </PopoverHeader>
          <PopoverBody
            className={`p-0 mb-0 rounded-bottom ${styles.popOverMaxheight}`}>
            <div className={`${styles.scrollableStyles}`}>
              <NotificationList {...this.props} />
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  }
}

export default withTranslation(['notifications'])(NotificationPresenter);
