import React from 'react';
import { Container } from 'reactstrap';

import styles from './PrivacyStatement.module.css';
import dataContent from './content.json';

const PrivacyStatement = () => {
  const title = dataContent.privacyStatement.title;
  const contents = dataContent.privacyStatement.contents.map((details, key) => {
    const subContent = details.subContent;
    const items = subContent
      ? subContent.map((items, key) => {
        return (
          <li className={`${styles.textSize} text-justify my-3`} key={key}>
            {items.item}
          </li>
        );
      })
      : null;

    return (
      <li className={`${styles.textSize} text-justify my-3`} key={key}>
        {details.content}
        <ol type="a">{items}</ol>
      </li>
    );
  });

  return (
    <Container>
      <h1 className="text-center my-5">{title}</h1>
      <ol>{contents}</ol>
    </Container>
  );
};

export default PrivacyStatement;
