import {
  rulesActionType,
  rulesAdminActionType
} from '../../constants/actionType';
import { performRequest } from '../requests';
import { buildRulesTree, formatRules } from '../ruleUtils';
import { RULES_KEY_SEPARTOR } from '../../constants/misc';

export const fetchRules = type => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({ type: rulesActionType.RESET_RULES });
  return dispatch(
    performRequest(
      'get',
      `/api/rules?rule_type=${type}`,
      {},
      requestConfig,
      'RULES'
    )
  )
    .then(res => res.data)
    .then(
      ({
        packages,
        rules,
        domainDefinedPackages,
        entityTargetId,
        concurrencyControlVersionId
      }) => {
        const formattedData = buildRulesTree({ packages, rules });
        dispatch({
          type: rulesActionType.MERGE_RULES,
          rulesTree: formattedData,
          rules: rules,
          concurrencyControlVersionId,
          entityTargetId
        });
      }
    );
};

export const saveRules =
  (rulesTree, type, destination) => (dispatch, getState) => {
    const concurrencyControlVersionId =
      getState().rules_admin.concurrencyControlVersionId ||
      getState().rules.concurrencyControlVersionId;
    const entityTargetId =
      getState().rules_admin.entityTargetId || getState().rules.entityTargetId;

    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    const { packages, rules } = formatRules(rulesTree);

    const route = {
      domain: () => '/api/admin/rule_management/setDefault',
      group: params => `/api/rules/group/${params.groupId}/setDefault`,
      user: params => `api/rules/user/${params.userId}/setDefault`
    }[destination.type](destination);

    return dispatch(
      performRequest(
        'post',
        route,
        {
          packages,
          rules,
          ruleType: type,
          entityTargetId,
          concurrencyControlVersionId
        },
        requestConfig,
        'RULES'
      )
    )
      .then(data => {
        if (data.data.success) {
          dispatch({
            type: rulesActionType.SET_ENTITY_VERSION_ID,

            concurrencyControlVersionId: data.data.concurrencyControlVersionId
          });

          return data.data.concurrencyControlVersionId;
        }
      })
      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };

export const saveRuleParameter =
  (ruleId, type, data, destination, concurrencyControlVersionId) =>
  (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    const route = {
      domain: () => '/api/admin/rule_management/saveRuleParameter',
      group: params => `/api/rules/group/${params.groupId}/saveRuleParameter`,
      user: params => `api/rules/user/${params.userId}/saveRuleParameter`
    }[destination.type](destination);

    return dispatch(
      performRequest(
        'post',
        route,
        {
          ruleId,
          data,
          ruleType: type,
          entityTargetId: ruleId,
          concurrencyControlVersionId
        },
        requestConfig,
        'SAVE_RULE_PARAMETER'
      )
    )
      .then(res => res.data)
      .then(({ data, success, concurrencyControlVersionId }) => {
        if (destination.type === 'domain') {
          dispatch({
            type: rulesAdminActionType.UPDATE_ADMIN_RULE_PARAMETER,
            ruleId,
            data
          });
          // dispatch({type: })
        } else {
          // TODO
          dispatch({
            type: rulesActionType.UPDATE_RULE_PARAMETER,
            ruleId,
            data
          });
        }

        return concurrencyControlVersionId;
      })
      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };

export const savePackageRuleParameter =
  (listRuleId, type, data, destination) => (dispatch, getState) => {
    const concurrencyControlVersionId =
      getState().rules_admin.concurrencyControlVersionId;
    const entityTargetId = getState().rules_admin.entityTargetId;

    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    const route = {
      domain: () => '/api/admin/rule_management/savePackageRuleParameter',
      group: params =>
        `/api/rules/group/${params.groupId}/savePackageRuleParameter`,
      user: params => `api/rules/user/${params.userId}/savePackageRuleParameter`
    }[destination.type](destination);

    return dispatch(
      performRequest(
        'post',
        route,
        {
          listRuleId,
          data,
          ruleType: type,
          concurrencyControlVersionId,
          entityTargetId
        },
        requestConfig,
        'SAVE_PACKAGE_RULE_PARAMETER'
      )
    )
      .then(res => res.data)
      .then(res => {
        if (destination.type === 'domain') {
          dispatch({
            type: rulesAdminActionType.UPDATE_MULTIPLE_ADMIN_RULE_PARAMETER,
            listRuleId,
            data
          });
          // dispatch({type: })
        } else {
          // TODO
          dispatch({
            type: rulesActionType.UPDATE_MULTIPLE_RULE_PARAMETER,
            listRuleId,
            data
          });
        }

        dispatch({
          type: rulesActionType.SET_ENTITY_VERSION_ID,
          concurrencyControlVersionId: res.concurrencyControlVersionId
        });

        return res.concurrencyControlVersionId;
      })

      .catch(err => {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      });
  };
export const rulesBatchUpdate =
  (rules, type, destination) => (dispatch, getState) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    const route = {
      domain: () => '/api/admin/rule_management/rulesBatchUpdate',
      group: params => `/api/rules/group/${params.groupId}/rulesBatchUpdate`,
      user: params => `api/rules/user/${params.userId}/rulesBatchUpdate`
    }[destination.type](destination);

    return dispatch(
      performRequest(
        'post',
        route,
        { rules, ruleType: type },
        requestConfig,
        'RULES'
      )
    ).then(res => {
      if (destination.type === 'domain') {
        dispatch({
          type: rulesAdminActionType.UPDATE_ADMIN_BATCH_RULES,
          rules: res.data.data
        });
      }
    });
  };

// DELETE RULES IN THE DATABASE
export const deleteRules = (rules, ruleLevel) => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: {
      ruleLevel: ruleLevel
    }
  };

  return dispatch(
    performRequest(
      'post',
      `/api/rules/delete`,
      { rules },
      requestConfig,
      'DELETE_RULES'
    )
  ).then(res => res);
};

export const getConcurrencyControlVersionId =
  (entityType, entityTargetId) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'get',
        `/api/admin/rule_management/getConcurrencyControlVersionId?entityType=${entityType}&entityTargetId=${entityTargetId}`,
        {},
        requestConfig,
        'RULES'
      )
    )
      .then(res => res.data)
      .then(({ concurrencyControlVersionId }) => {
        return concurrencyControlVersionId;
      });
  };

export const getRuleById = ruleId => (dispatch /* getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'get',
      `/api/rules/getInfo/${ruleId}`,
      {},
      requestConfig,
      'RULES'
    )
  )
    .then(res => res.data)
    .then(ruleData => {
      return ruleData;
    });
};
