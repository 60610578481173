import React from 'react';
import config from '../config/landingPageConfig';
import { Label, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const projectReference = config.projectReference;

const ProjectReference = ({ t }) => {
  const { references, headerText } = projectReference;
  return (
    <Container fluid className="p-5">
      <div className="d-flex justify-content-center py-3">
        <h2>{t(`front:${headerText}`)}</h2>
      </div>
      {references.map((ref, index) => {
        const isEven = index % 2 === 0;
        return (
          <div className="p-4 reference-row-container" key={`key-${index}`}>
            <img
              className={`reference-image ${
                isEven ? 'float-right' : 'float-left'
              }`}
              src={ref.imageName}
              alt={ref.imageName}
            />
            <h5>{t(`front:${ref.headerText}`)}</h5>
            <span>
              {ref.paragraphs.map(par => {
                return (
                  <Label
                    key={`key-${par.label}`}
                    className={`${par.tag === 'li' ? 'mx-5 mb-2' : ''}`}
                    tag={par.tag}>
                    {t(`${par.label}`)}
                  </Label>
                );
              })}
            </span>
          </div>
        );
      })}
    </Container>
  );
};

export default withTranslation(['front'])(ProjectReference);
