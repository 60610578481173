import { performRequest } from '../requests';
import {
  attachmentFileActionType,
  appActionType,
  projectSubmissionType
} from '../../constants/actionType';

const { UPDATE_FILE_UPLOAD_PROGRESS } = appActionType;

const {
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  RESET_ATTACHMENTS,
  UPLOAD_ATTACHMENTS,
  MERGE_ATTACHMENTS
} = attachmentFileActionType;
//project sumbmission action

const { ADMIN_UPDATE_ONE_SUBMISSION } = projectSubmissionType;

export const addResultAttachmentFile = file => dispatch => {
  return dispatch({
    type: ADD_ATTACHMENTS,
    file
  });
};

export const removeAttachmentFile = (fileId, file) => dispatch => {
  return dispatch({
    type: REMOVE_ATTACHMENT,
    payload: { fileId, file }
  });
};

export const resetAttachmentFiles = () => ({
  type: RESET_ATTACHMENTS
});

export const uploadAttachmentFiles = (
  projectId,
  stageName,
  filename,
  submissionId
) => (dispatch, getState) => {
  const fileData = getState().attachment_files.find(
    file => file.name === filename
  );
  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data'
    }
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/stage/${stageName}/submission/${submissionId}/attachments/upload`,
      fileData.formData,
      requestConfig,
      'UPLOAD_FILE'
    )
  )
    .then(() => {
      dispatch({
        type: UPLOAD_ATTACHMENTS,
        payload: {
          name: fileData.name,
          size: fileData.size,
          progress: true,
          dirty: false
        }
      });
    })
    .catch(err => {
      dispatch({
        type: UPLOAD_ATTACHMENTS,
        payload: {
          name: fileData.name,
          size: fileData.size,
          progress: false,
          dirty: false
        }
      });
      throw err;
    });
};

export const updateAttachmentList = (
  projectId,
  stageName,
  submissionId,
  filesAdded,
  filesRemoved
) => (dispatch, getState) => {
  let totalSizeAdded = 0;

  const nbFilesAdded = filesAdded.length || 0;
  const nbFilesRemoved = filesRemoved.length || 0;

  let formData = new FormData();
  for (let i = 0; i < filesAdded.length; i++) {
    formData.append('filesAdded', filesAdded[i], filesAdded[i]['name']);
  }
  formData.append('filesRemoved', JSON.stringify(filesRemoved));

  filesAdded.forEach(file => {
    totalSizeAdded += file.size;
  });

  filesRemoved.forEach(file => {
    totalSizeAdded -= file.size;
  });

  formData.append('entityTargetId', submissionId);
  formData.append(
    'concurrencyControlVersionId',
    getState().submission.concurrency_control_version_id
  );

  const requestConfig = {
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: UPDATE_FILE_UPLOAD_PROGRESS,
        progressValue: percentCompleted
      });
    },

    params: { totalSizeAdded, nbFilesAdded, nbFilesRemoved }
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/stage/${stageName}/submission/${submissionId}/attachments/update`,
      formData,
      requestConfig,
      'UPLOAD_FILE'
    )
  )
    .then(fileData => {
      dispatch({
        type: MERGE_ATTACHMENTS,
        payload: {
          data: fileData.data.data
        }
      });

      dispatch({
        type: ADMIN_UPDATE_ONE_SUBMISSION,
        data: {
          concurrency_control_version_id:
            fileData.data.concurrencyControlVersionId
        },
        isFromAdminListing: false,
        submissionId
      });
    })
    .catch(err => {
      throw new Error(
        (err.response && err.response.data && err.response.data.error) ||
          'error.default_message'
      );
    });
};
