import React from 'react';
import Button from 'reactstrap/lib/Button';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

const GoBackButton = ({ redirectTo, t }) => {
  // console.log(object);
  return (
    <Link to={redirectTo}>
      <Button color="link">
          <i className="fas fa-chevron-left mr-1" />
        {t('go_back')}
      </Button>
    </Link>
  );
};

export default compose(withTranslation(['main']))(GoBackButton);
