import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  Toast,
  ToastHeader,
  ToastBody
} from 'reactstrap';

import {
  fetchAllStats,
  fetchPortalStats,
  fetchSelectedCheckType
} from '../../../../../store/actions';

import Message from '../../../../UI/Message';
import styles from './Statistics.module.css';

import PieGraph from './PieGraph';

import ProjectRulesChecked from './../DashboardV2/Listing/ProjectRulesCheckedPresenter';
import TopNC from './../DashboardV2/Listing/TopNC';
const foundTopNC = (portalUsageStats, activeCheckTab) => {
  const foundTopNC = portalUsageStats.checkStats.find(
    type => type.key === activeCheckTab
  );
  return foundTopNC ? foundTopNC.topNCRule : false;
};

const CheckStatComponent = props => {
  const { type, t, checkType, toogleGraph } = props;

  return (
    <div
      className={`col-lg col-md-4 d-flex align-items-stretch `}
      onClick={async () => {
        await toogleGraph(checkType);
      }}>
      <div
        className={`shadow higlight customBorderRadius w-100 ${styles[checkType]} bg-white`}>
        <div className="card-body h-40">
          <h6 className={`card-title ${styles[checkType]}TextColor`}>
            {t(`statistics:${checkType}`)}
          </h6>
          <p className="card-text text-muted">
            {t('number_of_checks')} :{' '}
            {type.card_menu_content.number_of_checks || 0}
          </p>
          <h4 className="py-2 fw-bolder">
            {type.card_menu_content.detected.value}
          </h4>
          <p className="card-text text-muted">
            {type.card_menu_content.detected.display_name}
          </p>
        </div>
      </div>
    </div>
  );
};
const NullCheckStatComponent = props => {
  return (
    <div className="shadow mb-4 mb-xl-0 higlight customBorderRadius card">
      <CardBody>
        <div className="text-center py-2">
          <Spinner type="grow" color="primary" />
        </div>
      </CardBody>
    </div>
  );
};

class PieGraphPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      activeCheckTab: 'regulatory_compliance'
    };
  }
  componentDidMount() {
    const activeCheckTab = this.state.activeCheckTab;
    const isClearCache = false;
    this.props
      .fetchPortalStats(activeCheckTab, isClearCache)
      .then(() => {
        this.setState({
          isLoaded: true
        });
      })
      .catch(err => {
        Message.error(this.props.t(`error:${err.message}`));
      });
  }
  componentDidUpdate(prevProps, prevState) {
    const { activeCheckTab } = this.state;
    if (prevState.activeCheckTab !== activeCheckTab) {
      this.setState({
        isLoaded: false
      });
      this.props.fetchSelectedCheckType(activeCheckTab).then(() => {
        this.setState({
          isLoaded: true
        });
      });
    }
  }

  toogleGraph = key => {
    this.setState({
      activeCheckTab: key
    });
  };
  render() {
    const { t, statistics_checks_summary } = this.props;
    const { activeCheckTab, isLoaded } = this.state;
    const topNC =
      !!statistics_checks_summary &&
      Object.keys(statistics_checks_summary).length > 0 &&
      foundTopNC(statistics_checks_summary, activeCheckTab);
    return (
      <>
        <Container fluid className={`${styles.containerBGColor}`}>
          <Row className="mt-3">
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <Row>
                <Col
                  sm="12"
                  // md={{ size: 8, offset: 2 }}
                >
                  <div className="shadow">
                    <Card
                      className={`shadow customBorderRadius ${styles.leftPannelCardBGColor}`}>
                      <CardTitle
                        className={`text-center py-2 customBorderRadius ${styles.leftPannelCardTitleBGColor}`}>
                        <h5
                          className={`text-center ${styles.leftPannelHeaderTextColor} `}>
                          {t('total_project_number')}
                        </h5>
                      </CardTitle>
                      <Col>
                        {!!statistics_checks_summary &&
                        statistics_checks_summary.totalProjects ? (
                          <h4
                            className={`text-center ${styles.headerTotalProjects}`}>
                            {statistics_checks_summary.totalProjects}
                          </h4>
                        ) : (
                          <div className="text-center py-4">
                            <Spinner color="light" type="grow" />
                          </div>
                        )}
                      </Col>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="6" xl="9">
              <Row>
                {!!statistics_checks_summary.checkStats &&
                !!statistics_checks_summary.checkStats.length > 0
                  ? statistics_checks_summary.checkStats.map(type => {
                      const { statResult, key } = type;

                      return (
                        <CheckStatComponent
                          t={t}
                          checkType={key}
                          type={type}
                          toogleGraph={this.toogleGraph}
                        />
                      );
                    })
                  : [...Array(5).keys()].map(() => {
                      return (
                        <Col lg="6" xl="">
                          <div className="higlight p-auto">
                            <NullCheckStatComponent />
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <div className="shadow">
                <Card
                  className={`shadow customBorderRadius ${styles.leftPannelCardBGColor}`}>
                  <CardTitle
                    className={`text-center py-2 customBorderRadius ${styles.leftPannelCardTitleBGColor}`}>
                    <h5
                      className={`text-center ${styles.leftPannelHeaderTextColor}`}>
                      {t(`statistics:project_rules_checked`)}
                    </h5>
                  </CardTitle>
                  <div>
                    {!!statistics_checks_summary.getTotalRulesCheckedPerProject ? (
                      <ProjectRulesChecked
                        t={t}
                        data={
                          statistics_checks_summary.getTotalRulesCheckedPerProject
                        }
                      />
                    ) : (
                      <div className="text-center py-4">
                        <Spinner color="light" type="grow" />
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg="6" xl="9">
              <div className={`shadow bg-light ${styles.tabContentCardDiv}`}>
                <Card className="">
                  <CardHeader>
                    <h6 className="text-muted">
                      {console.log(activeCheckTab)}
                      {t(`statistics:${activeCheckTab}`)}
                    </h6>
                  </CardHeader>
                  <CardBody>
                    {!!isLoaded &&
                    !!activeCheckTab &&
                    !!statistics_checks_summary.checkStats ? (
                      <Row className={`${styles.tabContentRow}`}>
                        <Col lg={!!topNC ? 7 : 12} className="mb-4 mb-xl-0">
                          <PieGraph
                            name="graph"
                            activeCheckTab={activeCheckTab}
                            activeCheckData={statistics_checks_summary.checkStats.filter(
                              type => type.key === activeCheckTab
                            )}
                          />
                        </Col>{' '}
                        {!!topNC && (
                          <Col lg="5">
                            <div>
                              <Toast className={`${styles.topNCToast}`}>
                                <ToastHeader>
                                  <h5 className="text-primary">
                                    {t(`statistics:top_nc_rule`)}
                                  </h5>
                                </ToastHeader>
                                <ToastBody className="topNCToastBody">
                                  <TopNC data={topNC} />
                                </ToastBody>
                              </Toast>
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <Row className={`tabContentRow`}>
                        <div className="spinnerParentDiv">
                          <Spinner
                            color="primary"
                            type="grow"
                            className="text-center"
                          />
                        </div>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { statistics_checks_summary: state.statistics_checks_summary };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPortalStats: (checkType, isClearCache) =>
      dispatch(fetchPortalStats(checkType, isClearCache)),
    fetchSelectedCheckType: checkType =>
      dispatch(fetchSelectedCheckType(checkType))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['statistics'])
)(PieGraphPresenter);
