import React, { Component } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Label
} from 'reactstrap';
import isDeepEqual from 'lodash.isequal';
import Thumb from '../../UI/Thumbnail';

import Message from '../../UI/Message';
import * as Yup from 'yup';
import EditableInput from '../../UI/Input/editableInput';
import MemberList from './MemberList';
import StageList from './StageList';
import ProjectDiskUsage from './ProjectDiskUsage';

import CustomFileInput from '../../UI/Input/customFileInput';
import { Formik, Field, Form, FieldArray } from 'formik';
import { withTranslation } from 'react-i18next';
import map from 'lodash/map';
import styles from './Styles.module.css';

import CustomTooltip from '../../UI/CustomTooltip';

import defaultThumbnail from '../../../assets/images/default_project_thumbnail.png';

const formikConfig = (props, formReset, stateProps) => {
  const hasThumbnailValidation = !!props.thumbnailSettings;
  return {
    initialValues: {
      name: props.project.name,
      description: props.project.description,
      members: props.project.members,
      thumbnail_url: props.project.thumbnail_url,
      additional_information: {
        ...props.project.additional_information
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .max(60, 'Project name must be at most 60 characters')
        .required('Required'),
      description: Yup.string(),
      thumbnail_url: Yup.mixed()
        .test(
          'fileFormat',
          props.t('project:unsupported_thumbnail_format'),
          file => {
            if (!hasThumbnailValidation) {
              return true; //  has no thumbail validation settings return valid input
            } else {
              if (!file) {
                return true; // no file proceed to default/saved thumbnail
              } else if (file.type) {
                // newly selected thumbail
                return props.thumbnailSettings.supported_file_format.includes(
                  file.type
                );
              } else {
                // thumbail saved from db / uploaded
                const ext =
                  file &&
                  file.substring(file.lastIndexOf('.') + 1).toLowerCase();
                return (
                  (file &&
                    props.thumbnailSettings.supported_file_format.includes(
                      `image/${ext}`
                    )) ||
                  !file
                );
              }
            }
          }
        )
        .test(
          'fileSize',
          props.t('project:maximum_thumbnail_file_size'),
          file => {
            if (!hasThumbnailValidation) {
              return true; //  has no thumbail validation settings return valid input
            } else {
              if (!file) {
                return true; // no file proceed to default/saved thumbnail
              } else if (file.size) {
                return file.size <= props.thumbnailSettings.max_file_size_in_mb;
              } else {
                // from exisiting/saved thumbnail
                return true;
              }
            }
          }
        )
    }),
    enableReinitialize: true,
    onSubmit: async (
      values,
      { setSubmitting, setStatus, resetForm, ...rest }
    ) => {
      setSubmitting(true);
      setStatus('submitted');
      const { t } = props;
      const projectId = props.match.params.id;
      // get the values changed
      const dirtyValues = Object.keys(values).reduce((acc, key) => {
        if (isDeepEqual(props.project[key], values[key])) {
          return acc;
        } else {
          if (key === 'thumbnail_url') {
            return { ...acc, [key]: stateProps.croppedImageThumbnail };
          } else {
            return { ...acc, [key]: values[key] };
          }
        }
      }, {});

      try {
        await props
          .updateProject(projectId, dirtyValues)
          .then(() => {
            Message.success(t('project:project_edit_success'));
            setSubmitting(false);
          })
          .catch(err => {
            Message.error(
              t(`error:${err.message}`, {
                entity: 'project'
              })
            );

            setSubmitting(false);
            resetForm();
          });
      } catch (err) {
        // TODO HANDLE ERR
        // console.log(err);
      } finally {
        formReset();
        // resetForm(values);
        setSubmitting(false);
      }
    }
  };
};

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      deleteDisabled: false,
      deleteRequested: false,
      formReset: false,
      sortMemberBy: '',
      croppedImageThumbnail: null
    };
  }

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
  };

  componentDidUpdate(prevProps, prevState) {
    // Tweak to reset state of the editable fields
    if (this.state.formReset) {
      this.setState({ formReset: false });
    }
  }

  formReset = () => {
    this.setState({ formReset: true });
  };

  setThumbnail = thumb => {
    this.setState({ croppedImageThumbnail: thumb });
  };

  render() {
    const {
      t,
      project,
      projectAdditionalFields,
      relatedGroups,
      authUser,
      featureList
    } = this.props;

    const isProjectMemberEnabled = featureList.projectMember;
    const isProjectFieldConfigurationEnabled =
      featureList.projectFieldConfiguration;

    const { deleteDisabled, deleteRequested, submitCount, sortMemberBy } =
      this.state;

    const SortMembers = (sortBy, list) => {
      return list.sort(function (a, b) {
        var itemA = a[sortBy].toUpperCase();
        var itemB = b[sortBy].toUpperCase();
        if (itemA < itemB) {
          return -1;
        }
        if (itemA > itemB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    };

    return (
      <Formik {...formikConfig(this.props, this.formReset, this.state)}>
        {formikProps => {
          const isThumbnailChanged =
            project &&
            formikProps.values.thumbnail_url !== project.thumbnail_url &&
            formikProps.touched.thumbnail_url;

          return (
            <Form>
              <Container>
                <div className="sticky-top position-sticky bg-white mt-3 pt-3 shadow rounded px-3">
                  <Row className="justify-content-between px-3 ">
                    <h5 className="text-primary">{t('details')}</h5>
                    <div>
                      {/* TO DO: Display delete button to qualified role only  */}
                      <Button
                        color="success"
                        type="submit"
                        outline={!formikProps.dirty}
                        className="mx-1"
                        disabled={formikProps.isSubmiting || !formikProps.dirty}
                        onClick={formikProps.onSubmit}
                        id="formSaveButton">
                        <i className="fas fa-save fs-1-2x" />
                      </Button>
                      <Button
                        id="project-btn-delete"
                        color="danger"
                        onClick={this.toggleDeleteModal}
                        outline>
                        <i className="fas fa-trash fs-1-2x" />
                      </Button>
                      <CustomTooltip
                        target="project-btn-delete"
                        title={t('project_delete_tooltip_title')}
                        description={t('project_delete_tooltip_description')}
                      />
                      <Modal
                        isOpen={this.state.isDeleteModalOpen}
                        toggle={this.toggleDeleteModal}
                        className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>
                          {t('delete_project_modal_header')}
                        </ModalHeader>
                        <ModalBody>
                          {t('delete_project_confirmation')}
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="primary"
                            disabled={deleteDisabled || deleteRequested}
                            onClick={() => {
                              this.setState({ deleteRequested: true });
                              this.props
                                .deleteProject(this.props.match.params.id)
                                .then(res => {
                                  if (res.result === 'success') {
                                    Message.success(
                                      t('project_deleted_success')
                                    );
                                  } else {
                                    Message.error(t('project_already_deleted'));
                                  }
                                  this.setState({
                                    deleteRequested: false
                                  });
                                  this.props.history.push('/dashboard');
                                })
                                .catch(() => {
                                  Message.error(t('error:not_authorised'));
                                  this.setState({
                                    deleteRequested: false
                                  });
                                });
                            }}>
                            {deleteRequested && (
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {t('main:yes')}
                          </Button>
                          <Button
                            color="secondary"
                            disabled={deleteDisabled}
                            onClick={this.toggleDeleteModal}>
                            {t('main:no')}
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <UncontrolledTooltip
                        fade={false}
                        target="formSaveButton"
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('main:save')}
                      </UncontrolledTooltip>
                    </div>
                  </Row>
                  <hr />
                </div>
                <Row>
                  <Col sm="12">
                    <div className="shadow pl-3 pr-3 pb-3 mt-1 mb-5 rounded">
                      <Row>
                        <Col>
                          <div className={styles.detailOverFlow}>
                            <Field
                              name="name"
                              type="text"
                              label={t('project:project_name_placeholder')}
                              placeholder={t(
                                'project:project_name_placeholder'
                              )}
                              disabled={formikProps.isSubmitting}
                              editable={this.state.formReset}
                              component={EditableInput}
                            />
                          </div>
                          <div className={styles.detailDescription}>
                            <Field
                              name="description"
                              type="textarea"
                              rows="5"
                              DisplayTag="p"
                              label={t('project:project_description')}
                              placeholder={t('project:project_description')}
                              disabled={formikProps.isSubmitting}
                              editable={this.state.formReset}
                              component={EditableInput}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Field
                            name="thumbnail_url"
                            label={t('project:thumbnail')}
                            placeholder={t('project:thumbnail')}
                            accept={this.props.thumbnailSettings.supported_file_format.toString()}
                            disabled={formikProps.isSubmitting}
                            component={CustomFileInput}
                          />
                          <Thumb
                            setThumbnail={this.setThumbnail}
                            type={isThumbnailChanged ? 'file' : 'URL'}
                            file={
                              isThumbnailChanged
                                ? formikProps.values.thumbnail_url
                                : `${
                                    process.env.REACT_APP_API_ENDPOINT
                                  }${decodeURI(
                                    formikProps.values.thumbnail_url
                                  )}`
                            }
                            defaultImage={defaultThumbnail}
                            hasValidationError={
                              formikProps.errors.thumbnail_url
                            }
                            // file={formikProps.values.thumbnail_url}
                          />
                        </Col>
                      </Row>
                    </div>
                    {isProjectFieldConfigurationEnabled &&
                      projectAdditionalFields.length > 0 && (
                        <div className="shadow p-3 mt-3 mb-5 rounded">
                          <h5 className="text-primary">
                            {t('additional_information')}
                          </h5>
                          {projectAdditionalFields.map(additionalField => {
                            return (
                              <Row
                                key={`additional-field-${additionalField.name}`}>
                                <Col className="text-break">
                                  <Field
                                    name={`additional_information.${additionalField.name}`}
                                    label={additionalField.label}
                                    placeholder={additionalField.label}
                                    type={additionalField.type}
                                    DisplayTag="div"
                                    tagClassName={
                                      formikProps.values.additional_information[
                                        additionalField.name
                                      ]
                                        ? ''
                                        : 'font-italic'
                                    }
                                    disabled={formikProps.isSubmitting}
                                    editable={this.state.formReset}
                                    component={EditableInput}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      )}

                    {isProjectMemberEnabled &&
                      !(project.is_demo && authUser.function !== 'ADMIN') && (
                        <div className="shadow p-3 mt-3 mb-5 rounded">
                          <Row>
                            <Col>
                              <h5 className="text-primary">{t('members')}</h5>
                            </Col>
                            <Label
                              for="sortBy"
                              className="py-1 font-weight-bold">
                              Sort by
                            </Label>
                            <Col xs={6} sm={5} md={4} lg={3}>
                              <Input
                                bsSize="sm"
                                type="select"
                                name="select"
                                id="sortBy"
                                onChange={e =>
                                  this.setState({
                                    sortMemberBy: e.target.value
                                  })
                                }>
                                <option value="" selected disabled hidden>
                                  Choose here
                                </option>
                                <option name="email" value="email">
                                  Email
                                </option>
                                <option name="role" value="role">
                                  Role
                                </option>
                              </Input>
                            </Col>
                          </Row>

                          <FieldArray
                            name="members"
                            render={props => (
                              <MemberList
                                {...props}
                                projectName={project.name}
                                match={this.props.match}
                                fetchRoles={this.props.fetchRoles}
                                disabled={formikProps.isSubmitting}
                                members={
                                  !!sortMemberBy
                                    ? SortMembers(
                                        sortMemberBy,
                                        formikProps.values.members
                                      )
                                    : formikProps.values.members
                                }
                                relatedGroups={project.relatedGroups}
                                {...this.props}
                              />
                            )}
                          />
                          <Field name={'user_group'} type="hidden" />
                        </div>
                      )}

                    <div className="shadow p-3 mt-3 mb-5 rounded">
                      <h5 className="text-primary">{t('stages')}</h5>
                      <StageList project={project} {...this.props} />
                    </div>

                    <div className="shadow p-3 mt-3 mb-5 rounded">
                      <h5 className="text-primary">{t('disk_usage')}</h5>
                      <ProjectDiskUsage
                        project={project}
                        {...this.props}
                        displayAlongProjectList={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withTranslation(['project', 'main', 'error'])(Details);
