import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { withSocketIO } from '../../../api/sockets/withSocketIO';

import { logout } from '../../../store/actions';
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }
  componentDidMount() {
    this.props.disconnectSocketIO();

    this.props.logout().then(() => {
      setTimeout(() => this.setState({ redirect: true }), 2000);
    });
  }

  render() {
    const { redirect } = this.state;
    return redirect ? (
      <Redirect to="/" />
    ) : (
      <div>LOG OUT, you will be redirected to the home page</div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { logout: () => dispatch(logout()) };
};
export default compose(connect(null, mapDispatchToProps), withSocketIO)(Logout);
