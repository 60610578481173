import React, { Component } from 'react';
import './styles.module.css';

class IndeterminateCheckbox extends Component {
  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
  }
  componentDidMount() {
    this.checkboxRef.current.indeterminate = this.props.indeterminate;
  }
  componentDidUpdate(prevProps, prevState) {
    this.checkboxRef.current.indeterminate = this.props.indeterminate;
  }

  render() {
    const { id, checked, label } = this.props;
    return (
      <div className="custom-control custom-checkbox parentDiv">
        <input
          ref={this.checkboxRef}
          type="checkbox"
          className={'custom-control-input'}
          id={id}
          checked={checked}
          onChange={() => ''}
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }
}

export default IndeterminateCheckbox;
