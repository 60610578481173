import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  UncontrolledCollapse
} from 'reactstrap';
import mergeHelpCenterMarkdown from './help_center.md';
import './style.css';
import SearchInput from './SearchInput';
import BimModellingGuideContent from './bim_modelling_guide.jsx';
import QueryUIGuide from './query_ui_guide';

const HelpCenter = props => {
  const helpCenterObj = {
    FAQs: {
      isDropdownOpen: false,
      children: [
        {
          title: 'How does FORNAX™ Cloud Portal works?',
          children: [
            { title: 'Using IFC in the portal' },
            { title: 'Report Files and FORNAX™ Results' }
          ]
        },
        {
          title: 'What IFC file can I upload?',
          children: []
        },
        {
          title: 'How long does it take for my model to upload?',
          children: []
        },
        {
          title: 'Can I set a different stage name?',
          children: []
        },
        {
          title: 'What is the file size limit I can upload?',
          children: []
        },
        {
          title:
            'What is the assurance that my model will not be used for other purpose?',
          children: []
        },
        {
          title: 'What will happen if I log out during a check?',
          children: []
        },
        {
          title: 'How will I know when a check is completed?',
          children: []
        },
        {
          title: 'How will I be able to view the checking results?',
          children: []
        },
        {
          title: 'Is there a limit to the number of checks that I can run?',
          children: []
        }
      ]
    },
    'Quick Guidelines': {
      isDropdownOpen: false,
      children: [
        {
          title: 'Getting Started',
          children: [
            { title: 'Log-in Page' },
            { title: 'Create a Project' },
            { title: 'Running an Automated BIM Check' },
            { title: 'Report files' }
          ]
        },
        {
          title: 'Project Information',
          children: [
            { title: 'I need to add members in the project. How do I do it?' },
            { title: 'I need to modify the project name. How do I do it?' }
          ]
        },
        {
          title: 'Running an Automated BIM Check',
          children: [
            { title: 'What are the rules under BIM Quality?' },
            { title: 'What are the rules under BIM Data?' },
            { title: 'What are the rules under Clash Detection?' },
            { title: 'What are the rules under Model Compare?' },
            {
              title:
                "After running a BIM check, I'm getting notifications that say it's 'On Error'. What should I do?"
            },
            { title: "What does 'Rule Data Validation' button do?" },
            {
              title:
                'I ran a data validation on different rule sets. How do I get the checking results?'
            },
            { title: 'Why do I get Obsolete Results?' }
          ]
        }
      ]
    },
    // 'Portal User Guides': {
    //   isDropdownOpen: false,
    //   children: []
    // },
    'Billing and Subscription': {
      isDropdownOpen: false,
      children: []
    },
    // 'Report a Problem': {
    //   isDropdownOpen: false,
    //   children: []
    // },
    // 'Suggest a Feature': {
    //   isDropdownOpen: false,
    //   children: []
    // },
    // 'Privacy & Cookies': {
    //   isDropdownOpen: false,
    //   children: []
    // },
    // 'Terms of Use': {
    //   isDropdownOpen: false,
    //   children: []
    // }
    'FORNAX™ BIM Modelling Guide': {
      isDropdownOpen: false,
      children: [
        {
          title: 'MODELLING IN GENERAL',
          children: [
            { title: 'Architectural Elements' },
            { title: 'Site Elements' },
            { title: 'Structural Elements' },
            { title: 'Piping Elements' },
            { title: 'ACMV Elements' }
          ]
        },
        {
          title: 'PROJECT',
          children: [{ title: 'Coordinate System' }]
        },

        {
          title: 'SITE',
          children: [
            { title: 'Proposed Site' },
            { title: 'Driveways' },
            { title: 'Traffic Flow' },
            { title: 'Parking Lots' },
            { title: 'Public Road' },
            {
              title: 'Building Name',
              children: [
                { title: 'Federated Model Sample' },
                { title: 'Model Breakdown Sample' }
              ]
            }
          ]
        },

        {
          title: 'Building storey',
          children: []
        },

        {
          title: 'ENTITIES',
          children: [{ title: 'Predefined Types' }]
        },
        {
          title: 'SPACES',
          children: [{ title: 'Space Demarcation' }]
        },

        {
          title: 'CLASSIFICATION CODE',
          children: []
        },

        {
          title: 'MATERIALS',
          children: []
        },

        {
          title: 'MEP SYSTEM',
          children: []
        },
        {
          title: 'FORNAX™ Special Connectivity Tracing Mechanism',
          children: []
        }
      ]
    },
    'FORNAX™ Query User Interface Guide': {
      isDropdownOpen: false,
      children: [
        {
          title: 'Objects',
          children: [{ title: 'Found in the model' }, { title: 'Others' }]
        },
        {
          title: 'Container',
          children: [
            { title: 'IfcBuilding' },
            { title: 'IfcBuildingStorey' },
            { title: 'IfcSite' },
            { title: 'IfcSpace' },
            { title: 'IfcZone' }
          ]
        },
        {
          title: 'Relationship',
          children: [
            { title: 'Aggregate Master' },
            { title: 'Aggregate Of' },
            { title: 'Bottom' },
            { title: 'Bounded Space' },
            { title: 'Classification Of' },
            { title: 'Connected To' },
            { title: 'Container' },
            { title: 'Contains' },
            { title: 'Dependency' },
            { title: 'Dependent To' },
            { title: 'Element Type Of' },
            { title: 'Group Of' },
            { title: 'Has Property' },
            { title: 'Material Of' },
            { title: 'Model Info' },
            { title: 'OBB Bottom' },
            { title: 'OBB Side' },
            { title: 'OBB Top' },
            { title: 'Owner History' },
            { title: 'Property' },
            { title: 'Property Of' },
            { title: 'Side' },
            { title: 'System Of' },
            { title: 'Top' },
            { title: 'Top Side' },
            { title: 'Type Of' },
            { title: 'Under Side' }
          ]
        },
        {
          title: 'GIS',
          children: []
        },
        {
          title: 'My Unit Info',
          children: []
        },
        {
          title: 'Charts',
          children: []
        },
        {
          title: 'Canvas',
          children: [{ title: 'Results Panel' }]
        },
        {
          title: 'FORNAX™ Default Charts',
          children: [
            {
              title: 'Area Data',
              children: [
                { title: 'Area Classification' },
                { title: 'Room Classification' }
              ]
            }
          ]
        }
      ]
    }
  };

  const autoScrollToSection = title => {
    window.location.hash = `${title
      .replace(/\s/g, '-')
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .toLowerCase()}`;
    setSelectedTopic(
      title
        .replace(/\s/g, '')
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
        .toLowerCase()
    );
  };

  const topicItem = title =>
    title
      .replace(/\s/g, '')
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .toLowerCase();

  const loop = data => {
    return data.map((tree, index) => {
      if (tree.children) {
        if (tree.title) {
          return (
            //looping of subTopic
            <div key={index}>
              {tree.children.length ? (
                <div className="py-2 sidebarItems">
                  <span id={topicItem(tree.title)}>{tree.title}</span>
                </div>
              ) : (
                <div
                  className="py-2 sidebarItems"
                  onClick={() => autoScrollToSection(tree.title)}>
                  <span
                    // id={topicItem(tree.title)}
                    className={
                      selectedTopic === topicItem(tree.title)
                        ? 'selectedTopic'
                        : ''
                    }>
                    {tree.title}
                  </span>
                </div>
              )}
              {tree.children.length ? (
                <UncontrolledCollapse toggler={topicItem(tree.title)}>
                  <div>{loop(tree.children)}</div>
                </UncontrolledCollapse>
              ) : (
                ''
              )}
            </div>
          );
        }
        return (
          <div key={index}>
            <div className="py-2">
              <span>{tree.title}</span>
            </div>
            <div>{loop(tree.children)}</div>
          </div>
        );
      }
      if (tree.title) {
        //looping of sub-subTopic
        return (
          <div
            key={index}
            onClick={() => autoScrollToSection(tree.title)}
            className={`pl-4 py-1 sidebarItems ${
              selectedTopic === topicItem(tree.title) ? 'selectedTopic' : ''
            }`}>
            <span>
              {' '}
              <i>{tree.title}</i>
            </span>
          </div>
        );
      }
      return <div key={index}>{tree.title}</div>;
    });
  };

  const [helpCenterSidebar, setHelpCenterSidebar] = useState(helpCenterObj);
  const [helpCenterContent, setHelpCenterContent] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const getAllChildrenKeys = () => {
    const keys = [];
    Object.keys(helpCenterObj).forEach(sidebarItemCategory => {
      loop(helpCenterObj[sidebarItemCategory]);
      function loop(data) {
        data &&
          data.children &&
          data.children.length > 0 &&
          data.children.map(item => {
            keys.push({ title: item.title, category: sidebarItemCategory });
            loop(item);
          });
      }
    });
    return keys;
  };

  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(getAllChildrenKeys());
  }, []);

  //load of help_center.md file
  useEffect(() => {
    fetch(mergeHelpCenterMarkdown)
      .then(response => response.text())
      .then(text => {
        setHelpCenterContent(marked(text));
        setIsLoaded(true);
      });
  });

  useEffect(() => {
    if (isLoaded) {
      // scroll to the anchor element from url
      const element_to_scroll_to =
        window.location.hash &&
        document.getElementById(window.location.hash.replace('#', ''));
      element_to_scroll_to && element_to_scroll_to.scrollIntoView();
    }
  }, [isLoaded]);

  return (
    isLoaded && (
      <>
        {/* -------------------- Help Center Header -------------------- */}
        <div className="border-bottom py-3  header">
          <div className="helpCenterHeader">
            <Container fluid>
              <Row>
                <Col>
                  <h1 className="font-weight-bold">Help Center</h1>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="8" sm="10" className="py-2 ">
                  <div>
                    <SearchInput suggestions={suggestions} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Container fluid>
          <Row>
            {/* -------------------- Sidebar -------------------- */}

            <Col sm="6" md="5" lg="4" className="sidebarBody">
              <div className="helpCenterSidebar">
                <Row>
                  <Col className="text-primary font-weight-bold mb-3 homeText">
                    <span>Home</span>
                  </Col>
                </Row>

                {/* looping og topic */}
                <Row>
                  <Col>
                    {Object.keys(helpCenterSidebar).map(title => (
                      <div key={title}>
                        <Row
                          className="text-primary py-2 cursorPointer"
                          id={helpCenterSidebar[title].id}
                          onClick={() =>
                            setHelpCenterSidebar({
                              ...helpCenterSidebar,
                              [title]: {
                                ...helpCenterSidebar[title],
                                isDropdownOpen:
                                  !helpCenterSidebar[title].isDropdownOpen
                              }
                            })
                          }>
                          <Col xs="10">
                            <div className="topicText">{title}</div>
                          </Col>

                          <Col xs="2" className="text-right">
                            {Object.keys(helpCenterSidebar[title].children)
                              .length ? (
                              helpCenterSidebar[title].isDropdownOpen ? (
                                <i className="fas fa-angle-down arrowIcon"></i>
                              ) : (
                                <i className="fas fa-angle-right arrowIcon"></i>
                              )
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Collapse
                          isOpen={helpCenterSidebar[title].isDropdownOpen}>
                          <div>
                            <span className="cursorPointer text-secondary">
                              {' '}
                              {loop(helpCenterSidebar[title].children)}{' '}
                            </span>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </Col>

            {/* -------------------- End Sidebar -------------------- */}

            {/* -------------------- Display Content Body -------------------- */}
            <Col className="contentBody" sm="6" md="7" lg="8">
              <Row className="helpCenterMarkdownDisplay">
                <Col>
                  <Container>
                    <div className="stackedit__html">
                      <h1 id="frequently-asked-questions">
                        Frequently Asked Questions (FAQ)
                      </h1>
                      <p>
                        The article under this topic explains most of the common
                        questions in using FORNAX™ Cloud. For a more detailed
                        guide, download the portal user guide{' '}
                        <a
                          download
                          href={`${
                            process.env.REACT_APP_API_ENDPOINT[
                              process.env.REACT_APP_API_ENDPOINT.length - 1
                            ] == '/'
                              ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                              : process.env.REACT_APP_API_ENDPOINT
                          }/api/footer_documents/FORNAXCloud User Guide_v1.0.pdf`}>
                          here
                        </a>
                        .
                      </p>
                    </div>

                    {/* --- DISPLAY MARKDOWN FILE CONTENT --- */}
                    <article
                      dangerouslySetInnerHTML={{
                        __html: helpCenterContent
                      }}></article>

                    {/* --- DISPLAY REACT COMPONENT CONTENT --- */}
                    <BimModellingGuideContent />
                    <QueryUIGuide />
                  </Container>
                </Col>
              </Row>
            </Col>
            {/* -------------------- End Display Content Body -------------------- */}
          </Row>
        </Container>
      </>
    )
  );
};

export default HelpCenter;
