import React, { Component } from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { getIn } from 'formik';
class EditableInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: !!props.editable,
      displayEditButton: false,
      prevValue: props.field.value
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.editable !== prevProps.editable &&
      this.props.editable === false &&
      this.state.editable
    ) {
      this.setState({
        editable: false
      });
    }
  }

  setPrevValue = val => {
    this.setState({
      prevValue: val
    });
  };
  setEditable = val => {
    this.setState({ editable: val });
  };
  toggleEditButton = val => {
    this.setState({
      displayEditButton: val
    });
  };

  render() {
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      prefix,
      label,
      id,
      DisplayTag = 'h4',
      tagClassName = '',
      labelClassName = 'text-secondary font-weight-bold',
      noValidation,
      noSuccessValidation,
      noErrorValidation,
      editable: ownerPropEditable,
      isremovable = { enable: false, functionToExecute: '', index: 0 },
      ...props
    } = this.props;
    const { editable, prevValue, displayEditButton } = this.state;
    const onClickEdit = () => {
      this.setPrevValue(field.value);
      this.setEditable(true);
    };
    const onClickCancel = () => {
      setFieldValue(field.name, prevValue);
      this.setEditable(false);
    };

    const onClickRemove = () => {};
    const isValid =
      !noValidation &&
      !noSuccessValidation &&
      getIn(touched, field.name) &&
      !getIn(errors, field.name);
    const isInvalid =
      !noValidation &&
      !noErrorValidation &&
      !!(getIn(touched, field.name) && getIn(errors, field.name));

    return (
      <>
        {editable && !props.disabled ? (
          <FormGroup>
            {!!label && (
              <Label className={labelClassName} for={id || field.name}>
                {label}
              </Label>
            )}
            <InputGroup>
              {!!prefix && (
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>{prefix}</InputGroupText>
                </InputGroupAddon>
              )}
              <Input
                {...field}
                {...props}
                id={id || field.name}
                invalid={isInvalid}
                valid={isValid}
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={() => onClickCancel()}>
                cancel
              </button>
              {getIn(touched, field.name) && getIn(errors, field.name) && (
                <div className="invalid-feedback">
                  {getIn(errors, field.name)}
                </div>
              )}
            </InputGroup>
          </FormGroup>
        ) : (
          <>
            {!!label && (
              <Label className={labelClassName} for={id || field.name}>
                {label}
              </Label>
            )}

            <div
              onMouseOver={() => this.toggleEditButton(true)}
              onMouseOut={() => this.toggleEditButton(false)}>
              <DisplayTag className={tagClassName}>
                {field.value || 'Not defined'}
                {
                  <button
                    type="button"
                    className={`btn btn-link ${
                      displayEditButton && !props.disabled
                        ? 'visible'
                        : 'invisible'
                    }`}
                    onMouseOver={() => this.toggleEditButton(true)}
                    onClick={() => onClickEdit()}
                  >
                    Edit
                  </button>
                }
                {isremovable.enable && (
                  <button
                    type="button"
                    className={`btn btn-link ${
                      displayEditButton && !props.disabled
                        ? 'visible'
                        : 'invisible'
                    }`}
                    onMouseOver={() => this.toggleEditButton(true)}
                    onClick={() => {
                      isremovable.functionToExecute();
                    }}
                  >
                    Remove
                  </button>
                )}
              </DisplayTag>
            </div>
          </>
        )}
      </>
    );
  }
}

export default EditableInputComponent;
