import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  UncontrolledDropdown,
  Modal,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withSocketIO } from '../../../api/sockets/withSocketIO';
import {
  socketProjectEvents,
  partialResultEvents
} from '../../../api/sockets/constantEvents';

import Message from '../../UI/Message';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

import { differenceWith, isEqual, throttle } from 'lodash';

import ModelList from './ModelList';
import ModelList2D from './2DModelList';
import ModalStageCreation from './ModalStageCreation';
import ProjectResult from './ProjectResultPresenter';
import ProjectResultWithFilters from './ResultTable/ProjectResultPresenter';

import StageComparisonResultPresenter from './StageComparisonResultPresenter';
import StageComparisonResultPresenterWithFilters from './ResultTable/StageComparisonResultPresenter';
import Autocheck from '../AutoCheck';
import { getURLParameters } from '../../../utils/url';
import ConfirmationModal from '../AutoCheck/ConfirmationModal';
import ModelUploadBox from './ModelUploadBox';
import CustomTooltip from '../../UI/CustomTooltip';
import ModalDeleteStageConfirmation from './StageDeletion/ModalDeleteStageConfirmation';
import UploadProgressBar from './UploadProgressBar';
import styles from './Styles.module.css';

import ModelComparisonOptionList from './ModelComparison/OptionSelection';
import prettyBytes from 'pretty-bytes';
const checksAvailableFunc = (activatedChecks, MODEL_COMPARISON_ENABLED) =>
  [
    ...(!!activatedChecks ? activatedChecks : []),
    MODEL_COMPARISON_ENABLED ? 'MODEL_COMPARISON_ENABLED' : ''
  ].reduce((returned, current) => {
    switch (current) {
      case 'REGULATORY':
        return { ...returned, ['isAvailable_check_eplan']: true };
      case 'BIM_DATA':
        return { ...returned, ['isAvailable_check_bim_data']: true };
      case 'BIM_QUALITY':
        return { ...returned, ['isAvailable_check_quality']: true };
      case 'CLASH_DETECTION':
        return { ...returned, ['isAvailable_check_clash']: true };
      case 'MODEL_COMPARISON_ENABLED':
        return { ...returned, ['isAvailableModelComparison']: true };
      default:
        return returned;
    }
  }, {});

const jobsTabFunc = availableChecks =>
  Object.keys(availableChecks)
    .filter(key => !!availableChecks[key])
    .reduce((prev, key) => {
      return [
        ...prev,
        {
          isAvailable_check_clash: 'clash',
          isAvailable_check_quality: 'quality',
          isAvailable_check_bim_data: 'bimdata',
          isAvailable_check_eplan: 'regulatory',
          isAvailableModelComparison: 'comparison'
        }[key]
      ];
    }, []);

const WebviewerButton = ({
  loadingViewer,
  viewerOnError,
  isViewerAvailable,
  stageViewerToken,
  projectId,
  stageName,
  viewerMode,
  disabled,
  t,
  id
}) => {
  return (
    <>
      <Button
        id={id}
        color="primary"
        className={styles.projectStagePointer}
        outline
        tag={'a'}
        href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=${viewerMode}&token=${stageViewerToken}`}
        // to={
        //   loadingViewer || viewerOnError || !isViewerAvailable
        //     ? '#'
        //     : {
        //       pathname: `/project/${projectId}/${stageName}/viewer`,

        //       search: `viewerMode=${viewerMode ||
        //           '3d'}&token=${stageViewerToken}`
        //     }
        // }
        disabled={disabled}
        target="_blank"
        onClick={e => {
          if (loadingViewer || viewerOnError || !isViewerAvailable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}>
        {/* <i className="far fa-eye fs-1-2x" /> */}
        {/* {t('main:link_viewer')} */}
        {loadingViewer && (
          <span
            className="ml-2 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
        <span className="font-weight-bold">
          {viewerMode === '2d' ? '2D' : '3D'}
        </span>
        {(viewerOnError || (!isViewerAvailable && !loadingViewer)) && (
          <span
            className={`position-relative ${styles.projectStageViewOnError}`}>
            <i className="text-danger fas fa-exclamation" />
          </span>
        )}

        <CustomTooltip
          placement="top"
          fade={false}
          target={id}
          modifiers={{
            computeStyle: {
              gpuAcceleration: false
            }
          }}
          description={(() => {
            if (loadingViewer) {
              return t('project:loading_viewer');
            } else if (viewerOnError) {
              return t('project:error_viewer', {
                email: 'FXC_support@nova-hub.com'
              });
            } else {
              return t('main:link_viewer');
            }
          })()}
        />

        {/* <UncontrolledTooltip
          fade={false}
          target={id}
          modifiers={{
            computeStyle: {
              gpuAcceleration: false
            }
          }}>
          {(() => {
            if (loadingViewer) {
              return t('project:loading_viewer');
            } else if (viewerOnError) {
              return t('project:error_viewer');
            } else {
              return t('main:link_viewer');
            }
          })()}
        </UncontrolledTooltip> */}
      </Button>
    </>
  );
};

const ModalStageComparison = ({
  isOpen,
  toggle,
  t,
  onSubmit,
  targetStageDropDownValue,
  filteredTargetStages,
  targetStageChangeValue,
  currentStage,
  modelComparisonList,
  setCheckedRulesForComparison,
  checkedRulesForComparison,
  isVideoTutorialsEnabled
}) => {
  const disabled =
    !targetStageDropDownValue || checkedRulesForComparison.length === 0;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader className="text-primary" toggle={toggle}>
        <Col className="d-flex justify-content-between">
          {isVideoTutorialsEnabled ? (
            <div className="py-1">
              <Link to="/videotutorials?v=video-4">
                <h4 className="text-primary">
                  {t('link_check_comparison')}
                  <i className="fas fa-video ml-2"></i>
                </h4>
              </Link>
            </div>
          ) : (
            <h4 className="text-primary"> {t('link_check_comparison')}</h4>
          )}
        </Col>
      </ModalHeader>
      <ModalBody>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="current_stage">{t('project:current_stage')}</Label>
              <Input type="text" disabled value={currentStage} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="target_stage">{t('project:compare_with')}</Label>
              <ButtonToolbar className={`mb-2 ${styles.width100}`}>
                <UncontrolledDropdown
                  setActiveFromChild
                  className={styles.width100}>
                  <DropdownToggle
                    color="primary"
                    className={`mr-2 ${styles.width100}`}
                    caret>
                    {targetStageDropDownValue
                      ? targetStageDropDownValue.display_name
                      : t('project:select_stage_to_compare')}
                  </DropdownToggle>
                  <DropdownMenu
                    className={`${styles.width100} ${styles.overflowAuto}`}>
                    <DropdownItem header>{t('stages')}</DropdownItem>
                    {filteredTargetStages.map((stage, key) => {
                      return (
                        <DropdownItem
                          key={`stage-${key}`}
                          onClick={() =>
                            targetStageChangeValue({
                              name: stage.name,
                              display_name: stage.display_name
                            })
                          }>
                          {stage.display_name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonToolbar>
            </FormGroup>
          </Col>
        </Row>
        <Row className="py-1">
          <Col>
            <Label for=""></Label>

            {!!modelComparisonList.length > 0 && (
              <ModelComparisonOptionList
                modelComparisonList={modelComparisonList}
                setCheckedRulesForComparison={setCheckedRulesForComparison}
              />
            )}
          </Col>
        </Row>

        {!!modelComparisonList.length > 0 && (
          <div className="text-muted d-flex justify-content-end">
            {checkedRulesForComparison.length > 0 ? (
              <div>
                {' '}
                {checkedRulesForComparison.length}{' '}
                {t('project:number_model_compare_list_checked')}
              </div>
            ) : (
              t('project:model_compare_no_option_selected')
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              onSubmit();
            }
          }}>
          {t('project:check')}
        </Button>
      </ModalFooter>
      {/* <div className="px-3 mt-3 mb-5 rounded">
      <div className="d-flex justify-content-end">
        {isVideoTutorialsEnabled && (
          <div>
            <Link to="/videotutorials?v=video-4">
              {t('video_tutorial_link_4')} <i className="fas fa-video"></i>
            </Link>
          </div>
        )}
      </div>
      <h5 className="text-primary">{t('comparison')}</h5>

      <ButtonToolbar className="mb-2">
        <UncontrolledDropdown setActiveFromChild>
          <DropdownToggle color="primary" className="mr-2" caret>
            {targetStageDropDownValue
              ? targetStageDropDownValue.display_name
              : t('project:select_stage_to_compare')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{t('stages')}</DropdownItem>
            {filteredTargetStages.map((stage, key) => {
              return (
                <DropdownItem
                  key={`stage-${key}`}
                  onClick={() =>
                    targetStageChangeValue({
                      name: stage.name,
                      display_name: stage.display_name
                    })
                  }>
                  {stage.display_name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          color="primary"
          disabled={!targetStageDropDownValue}
          onClick={() => onSubmit()}>
          {t('comparison_request')}
        </Button>
      </ButtonToolbar>
    </div> */}
    </Modal>
  );
};

class ProjectStagePresenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      targetStageDropDownValue: null,
      isModalStageOpen: false,
      projectStages: [],
      isFileUploading: false,
      viewerToken: null,
      filesAdded: [],
      filesRemoved: [],
      isDropdownStageListingEnable: true,
      dropdownOpen: false,
      isRequestComparisonModalOpen: false,
      activeResultTab: null,
      activeAutocheckTab: null,
      activeModelTypeTab: '3D_models',
      is2DModelFileUploading: false,
      model2DListCount: 0,
      model3DListCount: 0,
      filesAddedfor2D: [],
      filesRemovedfor2D: [],
      availableStagesForCreation: [],
      favorite_ruleset: {},
      isConfirmationModalOpen: {
        clash: false,
        bimdata: false,
        quality: false,
        regulatory: false
      },
      isEnableReinitialize: true,
      checkedRulesForComparison: [],
      isStageDeleting: false,
      isModalStageDeletionOpen: false,
      isModalDeleteStageConfirmationOpen: false,
      activeJobType: ''
    };
  }

  componentDidMount() {
    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    this.props.history.replace(`/project/${projectId}/models/${stageName}`);
    Promise.all([
      this.props.fetchListProjectStageName(),
      this.props.fetchProjectStage(projectId, stageName),
      this.props.fetchModelComparisonList()
    ]).then(() => {
      const createdProjectStages = this.props.project.stagesavailable;
      const availableStagesForCreation =
        this.props.predefined_stagename_list.filter(
          s =>
            !createdProjectStages.find(
              createdStage => createdStage.name === s.name
            )
        );

      this.setState({
        isLoaded: true,
        projectStages: createdProjectStages,
        availableStagesForCreation,
        viewerToken:
          this.props.stage && this.props.stage.viewerToken
            ? this.props.stage.viewerToken
            : null,
        model2DListCount: this.props.models2D.length,
        model3DListCount: this.props.models.length
      });
    });
    const urlParameters = getURLParameters(window.location.href);
    if (urlParameters['check-type']) {
      this.setState({
        activeAutocheckTab: urlParameters['check-type']
      });
    }
    this.props.projectPreferenceAction
      .fetchProjectPreference(projectId, 'favorite_rules')
      .then(() => {
        this.setState({
          isLoaded: true
        });
      });
    const eventsToSubscribeTo = Object.keys(socketProjectEvents).map(
      eventName => {
        return {
          eventName,
          handler: throttle(data => {
            if (
              data.projectId &&
              data.stageSafeName &&
              data.projectId === projectId &&
              data.stageSafeName === stageName
            ) {
              return this.props.fetchProjectStage(
                projectId,
                stageName || this.props.project.stagesavailable[0]
              );
            }
          }, 1000)
        };
      }
    );
    eventsToSubscribeTo.push(
      ...Object.keys(partialResultEvents).map(eventName => {
        return {
          eventName,
          handler: throttle(data => {
            if (
              data.projectId &&
              data.stageSafeName &&
              data.projectId === projectId &&
              data.stageSafeName === stageName
            ) {
              this.props.updateJobRuleCompleted(data);
            }
          }, 1000)
        };
      })
    );
    try {
      this.props.subscribeToEvents(eventsToSubscribeTo);
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps) {
    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    if (
      prevProps.stageName !== stageName ||
      prevProps.projectId !== projectId
    ) {
      const eventsToSubscribeTo = Object.keys(socketProjectEvents).map(
        eventName => {
          return {
            eventName,
            handler: throttle(data => {
              if (
                data.projectId &&
                data.stageSafeName &&
                data.projectId === projectId &&
                data.stageSafeName === stageName
              ) {
                return this.props.fetchProjectStage(
                  projectId,
                  stageName || this.props.project.stagesavailable[0]
                );
              }
            }, 1000)
          };
        }
      );
      eventsToSubscribeTo.push(
        ...Object.keys(partialResultEvents).map(eventName => {
          return {
            eventName,
            handler: throttle(data => {
              if (
                data.projectId &&
                data.stageSafeName &&
                data.projectId === projectId &&
                data.stageSafeName === stageName
              ) {
                return this.props.updateJobRuleCompleted(data);
              }
            }, 1000)
          };
        })
      );
      try {
        this.props.subscribeToEvents(eventsToSubscribeTo);
      } catch (err) {
        console.log(err);
      }
    }
    if (
      prevProps.location.pathname !==
      `/project/${projectId}/models/${stageName}`
    ) {
      this.props.history.replace(`/project/${projectId}/models/${stageName}`);
    }
    // Fetch data on change tab
    if (
      prevProps.stageName !== stageName ||
      prevProps.projectId !== projectId
    ) {
      this.setState({
        isLoaded: false
      });
      this.props
        .fetchProjectStage(
          projectId,
          stageName || this.props.project.stagesavailable[0]
        )
        .then(() => {
          const createdProjectStages = this.props.project.stagesavailable;
          const availableStagesForCreation =
            this.props.predefined_stagename_list.filter(
              s =>
                !createdProjectStages.find(
                  createdStage => createdStage.name === s.name
                )
            );

          this.setState({
            isLoaded: true,
            projectStages: createdProjectStages,
            availableStagesForCreation,
            viewerToken:
              this.props.stage && this.props.stage.viewerToken
                ? this.props.stage.viewerToken
                : null
          });
        });
    }
    if (
      this.props.stage &&
      (!prevProps.stage ||
        this.props.stage.viewerToken !== prevProps.stage.viewerToken)
    ) {
      this.setState({ viewerToken: this.props.stage.viewerToken });
    }
    if (this.props.location.search !== prevProps.location.search) {
      const urlParameters = getURLParameters(window.location.href);
      if (urlParameters['check-type']) {
        this.setState({
          activeAutocheckTab: urlParameters['check-type']
        });
      } else {
        this.setState({
          activeAutocheckTab: null
        });
      }
    }
    // for displaying model count in badge in tab
    const model2DCountTotal = this.props.models2D.filter(
      m => !m.removed
    ).length;
    const model3DCountTotal = this.props.models.filter(m => !m.removed).length;

    if (
      this.state.model2DListCount !== model2DCountTotal ||
      this.state.model3DListCount !== model3DCountTotal
    ) {
      this.setState({
        model2DListCount: model2DCountTotal,
        model3DListCount: model3DCountTotal
      });
    }
  }

  componentWillUnmount() {
    this.props.modelFileAction.resetModelFile();
    this.props.model2DFileAction.reset2DModelFile();
  }

  generateToggleConfirmationModal = checkType => () => {
    this.setState(prevState => ({
      isConfirmationModalOpen: {
        ...prevState.isConfirmationModalOpen,
        [checkType]: !prevState.isConfirmationModalOpen[checkType]
      }
    }));
  };

  toggleComparisonModal = () => {
    this.setState(prevState => ({
      isRequestComparisonModalOpen: !prevState.isRequestComparisonModalOpen
    }));
  };

  requestComparison = (
    projectId,
    stageName,
    stageToCompareTo,
    checkedRulesForComparison
  ) => {
    this.props
      .requestStageComparison(
        projectId,
        stageName,
        stageToCompareTo,
        checkedRulesForComparison
      )
      .then(res => {
        if (res.success) {
          Message.success(this.props.t('project:model_comparison_requested'));
          this.toggleComparisonModal();
          this.toggleResultTab('result-comparison', 'comparison');
          this.setState({
            isLoaded: false
          });
          this.props.fetchProjectStage(projectId, stageName).then(() => {
            this.setState({
              isLoaded: true
            });
          });
        } else {
          Message.error(this.props.t(`${res.message}`));
        }
      });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  stageChangeValue = stageData => {
    // const stageName = e.currentTarget.textContent;
    const projectId = this.props.projectId;
    this.props.history.replace(
      `/project/${projectId}/models/${stageData.name}`
    );
    this.props.stageChangeValue(stageData).then(() => {});
  };

  targetStageChangeValue = stageData => {
    this.setState({ targetStageDropDownValue: stageData });
  };

  toggleModalStage = enableDropdownStage => {
    this.setState({
      isModalStageOpen: !this.state.isModalStageOpen,
      isDropdownStageListingEnable: enableDropdownStage
    });
  };

  setCheckedRulesForComparison = list => {
    this.setState({ checkedRulesForComparison: list });
  };

  toggleResultTab = (tab, activeJobType) => {
    this.setState({ activeResultTab: tab, activeJobType: activeJobType });
  };
  toggleModelTypeTab = tab => {
    this.setState({ activeModelTypeTab: tab });
  };

  toggleCheckTab = tab => {
    this.setState({
      activeAutocheckTab: tab
    });
    this.props.history.push({ search: `check-type=${tab}` });
  };

  handleSubmitAutoCheckShorcut = checkType => {
    const rules = this.props.project_preference.favorite_rules;
    if (rules[checkType].length !== 0 && this.props.models.length !== 0) {
      this.setState({ isSubmitting: true });
      this.props
        .queryECheck(
          this.props.match.params.id,
          this.props.match.params.stageName,
          rules[checkType],
          [],
          checkType,
          false
        )
        .then(() => Message.success(this.props.t('autochecker:success_submit')))
        .then(() => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  toggleModalStageDeletion = isOpen => {
    this.setState({
      isModalStageDeletionOpen: !this.state.isModalStageDeletionOpen
    });
  };

  toggleModalDeleteStageConfirmation = isOpen => {
    this.setState({
      isModalDeleteStageConfirmationOpen:
        !this.state.isModalDeleteStageConfirmationOpen
    });
  };

  formikConfig = () => {
    return {
      enableReinitialize: true,
      initialValues: {
        models2d: this.props.models2D.filter(m => !m.removed)
      },
      validationSchema: Yup.object().shape({
        models2d: Yup.array().of(
          Yup.object().shape({
            plan_type: Yup.string().required('required')
          })
        )
      }),
      onSubmit: async (values, { setSubmitting, setStatus, setFieldError }) => {
        this.setState({ isFileUploading: true });
        const projectId = this.props.projectId;
        const stageName = this.props.stageName;

        const filesAdded = this.props.models.filter(m => m.dirty && !m.removed);
        const filesRemoved = this.props.models.filter(m => m.removed);

        const { models2d } = values;
        const filesAddedfor2D = this.props.models2D.filter(
          m => m.dirty && !m.removed && m.fileInput
        );
        const filesRemovedfor2D = this.props.models2D.filter(
          m => m.removed && !m.dirty
        );

        const models2dExcludeFile = this.props.models2D;

        try {
          if (filesAdded.length > 0 || filesRemoved.length > 0) {
            await this.props.updateModelList(
              projectId,
              stageName,
              filesAdded,
              filesRemoved,
              'project_update'
            );
          }

          if (
            filesAddedfor2D.length > 0 ||
            filesRemovedfor2D.length > 0 ||
            models2dExcludeFile.length > 0
          ) {
            if (this.props.featureList['2DPlan']) {
              await this.props.update2DModelList(
                projectId,
                stageName,
                filesAddedfor2D,
                filesRemovedfor2D,
                models2dExcludeFile
              );
            }
          }

          this.setState({
            isFileUploading: false,
            filesAdded,
            filesRemoved,
            filesAddedfor2D,
            filesRemovedfor2D
          });

          const is3DModified = filesAdded.length > 0 || filesRemoved.length > 0;
          const is2DModified =
            filesAddedfor2D.length > 0 || filesRemovedfor2D.length > 0;

          this.props.modelFileAction.resetModelFile();
          this.props.model2DFileAction.reset2DModelFile();
          if (is3DModified && is2DModified) {
            // both 3d and 2d modified

            Message.success(this.props.t('project:both_model_list_updated'));
          } else if (is3DModified) {
            Message.success(this.props.t('project:model_list_updated'));
          } else if (is2DModified) {
            Message.success(this.props.t('project:2d_pdf_updated'));
          }
          await this.props.fetchProjectStage(
            projectId,
            stageName || this.props.project.stagesavailable[0]
          );
        } catch (error) {
          this.setState({
            isFileUploading: false
          });
          Message.error(this.props.t(`error:${error.message}`));
        }
      }
    };
  };

  updateRouterHistory = newUrl => {
    this.props.history.replace(newUrl);
  };

  render() {
    const {
      t,
      stage,
      models,
      project,
      modelFileAction,
      model2DFileAction,
      createStage,
      stageDropDownValue,
      sidebarRef,
      models2D,
      userPreference,
      project_preference,
      userId,
      authUser,
      deleteStage,
      featureList
    } = this.props;

    const {
      isFileUploading,
      viewerToken,
      filesAdded,
      filesRemoved,
      dropdownOpen,
      projectStages,
      isDropdownStageListingEnable,
      targetStageDropDownValue,
      isModalStageOpen,
      isRequestComparisonModalOpen,
      activeResultTab,
      activeAutocheckTab,
      isLoaded,
      activeModelTypeTab,
      is2DModelFileUploading,
      model2DListCount,
      model3DListCount,
      filesAddedfor2D,
      filesRemovedfor2D,
      availableStagesForCreation,
      isConfirmationModalOpen,
      isPrecheckPopOverOpen,
      checkedRulesForComparison,
      isStageDeleting,
      isModalStageDeletionOpen,
      isModalDeleteStageConfirmationOpen,
      activeJobType
    } = this.state;

    const isVideoTutorialsEnabled = featureList.videoTutorial;
    const isFXRDownloadEnabled = featureList.fxrDownload;
    const isJobCancellationEnabled = featureList.jobCancellation;
    const partialResultButtonEnabled = featureList.partialResultButtonEnabled;
    const checkTypes = featureList.checkingType;
    const MODEL_COMPARISON_ENABLED = featureList.modelComparison;
    const is2DPlanEnabled = featureList['2DPlan'];
    const isPrecheckEnabled = featureList.rulePrecheck;
    const isProjectResultFilteringEnabled = featureList.projectResultFiltering;
    // QueryUI
    const isQueryUIComponentEnabled = featureList.queryUIEnabled && featureList.queryUIEnabled.portal;

    const sizePerProjectStage =
      (stage && stage.disk_usage && stage.disk_usage.sizePerProjectStage) || 0;
    
    const checksAvailable = checksAvailableFunc(
      checkTypes,
      MODEL_COMPARISON_ENABLED
    );

    const {
      isAvailable_check_eplan,
      isAvailable_check_clash,
      isAvailable_check_quality,
      isAvailable_check_bim_data,
      isAvailableModelComparison
    } = checksAvailable;

    const jobsTab = jobsTabFunc(checksAvailable);

    const is3DModel = activeModelTypeTab === '3D_models';

    const precheckStatus =
      stage && stage.precheck_completed && stage.precheck_status;
    const isPrecheckComplete =
      !isPrecheckEnabled ||
      (isPrecheckEnabled && stage && stage.precheck_completed);

    const loadingViewer = stage && stage.web_viewer_status === 'PENDING';
    const viewerOnError = stage && stage.web_viewer_status === 'ERROR';
    const isViewerAvailable = true;

    const isETLComplete =
      stage &&
      (stage.etl_status === undefined
        ? true
        : stage.etl_status === 'AVAILABLE');
    const isStageFrozen = stage && stage.status === 'FROZEN';
    const isNotOwner = project.members.some(
      res => res.user_id === userId && res.role !== 'owner'
    );
    const isAdmin =
      authUser && authUser.function && authUser.function === 'ADMIN';

    const has2DModels = this.props.models2D.length > 0;
    const isBlankStage =
      (stage && stage.web_viewer_status === 'NO_IFC_FILES') ||
      this.props.models.length === 0;

    const stageHasJobRunning = stage && stage.has_job_running;
    const isModelListDisabled =
      loadingViewer ||
      ((loadingViewer ||
        isStageFrozen ||
        !isETLComplete ||
        stageHasJobRunning ||
        isFileUploading ||
        !isPrecheckComplete) &&
        !isBlankStage &&
        !has2DModels &&
        (isNotOwner || !isAdmin));

    const projectId = this.props.projectId;
    const stageName = this.props.stageName;

    // Track if any change have been made to the model list
    const isModelListDirty =
      models.filter(m => m.dirty || m.removed).length > 0;
    const isModelListValid =
      models.filter(m => !m.removed).length > 0 ||
      has2DModels ||
      models2D.filter(m => !!m.dirty || m.removed).length > 0;

    // Track if any change have been made to the 2D model list
    const is2DModelListDirty =
      models2D.filter(m => !!m.dirty || m.removed).length > 0;
    const is2DModelListValid = true;

    const filteredTargetStages = projectStages.filter(
      stage => stage.name !== stageDropDownValue.name
    );

    const stagesCompared = stage && stage.comparisons;
    // will merge all action of 2D or 3D Tabs
    const modelType = {
      isModelListDirty: isModelListDirty || is2DModelListDirty,
      isModelListValid: isModelListValid,
      filesAdded: [...filesAdded, ...filesAddedfor2D],
      filesRemoved: [...filesRemoved, ...filesRemovedfor2D],
      onUpdateModelList: this.onUpdateModelList,
      buttonText: t('project:update_models')
    };

    const StageDetailNav = ({ vertical }) => (
      <>
        {!isStageFrozen &&
          (isAvailable_check_eplan ||
            isAvailable_check_clash ||
            isAvailable_check_quality ||
            isAvailable_check_bim_data ||
            isAvailableModelComparison) &&
          !isBlankStage && (
            <Nav tabs vertical={vertical} className="shadow mt-3 mb-3 rounded">
              <h6 className="text-primary bg-light p-2 rounded my-0">
                {t('project:checks_subtitle')}
              </h6>
              {/* ########  Model Compare  ########*/}
              {isAvailableModelComparison && (
                <NavItem>
                  <NavLink
                    id="modalComparisonTrigger"
                    className={`d-flex justify-content-between bg-light rounded px-2 ${styles.projectDetailNavBorder}`}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isViewerAvailable) {
                        this.toggleComparisonModal();
                      }
                    }}>
                    {t('link_check_comparison')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className={`position-absolute ${styles.projectStageNavItemPosition}`}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    <CustomTooltip
                      target="modalComparisonTrigger"
                      title={t('link_check_comparison')}
                      description={(() => {
                        if (isViewerAvailable) {
                          return t(
                            'project:comparison_modal_button_description'
                          );
                        } else {
                          return t('project:comparison_not_available');
                        }
                      })()}
                    />
                    {/* <UncontrolledTooltip
                      target="modalComparisonTrigger"
                      fade={false}
                      modifiers={{
                        computeStyle: {
                          gpuAcceleration: false
                        }
                      }}>
                      {(() => {
                        if (isViewerAvailable) {
                          return t('project:comparison_modal_button');
                        } else {
                          return t('project:comparison_not_available');
                        }
                      })()}
                    </UncontrolledTooltip> */}
                  </NavLink>
                </NavItem>
              )}
              {/* ########  Clash detection Check  ########*/}
              {isAvailable_check_clash && (
                <NavItem
                  className={classnames({
                    'border-right border-primary rounded border-sz-3':
                      this.state.activeAutocheckTab === 'clash'
                  })}>
                  <NavLink
                    id="goAutocheck-clash"
                    className={classnames(
                      'd-flex justify-content-between bg-light rounded px-2 text-body',
                      {
                        'active font-weight-bold':
                          this.state.activeAutocheckTab === 'clash'
                      }
                    )}
                    tag={Link}
                    to={
                      !isBlankStage
                        ? `/project/${projectId}/${stageName}/autocheck?check-type=clash`
                        : ''
                    }
                    onClick={() => {
                      this.toggleCheckTab('clash');
                    }}>
                    {t('link_check_clash')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className={`position-absolute ${styles.projectStageNavItemPosition}`}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    <CustomTooltip
                      target="goAutocheck-clash"
                      title={t('link_check_clash')}
                      description={(() => {
                        if (viewerOnError) {
                          return t('project:check_not_available_viewer_error');
                        } else if (isStageFrozen) {
                          return t('project:check_not_available_stage_frozen');
                        } else {
                          return t('project:link_check_clash_description');
                        }
                      })()}
                    />
                    {/* <UncontrolledTooltip
                      target="goAutocheck-clash"
                      fade={false}
                      modifiers={{
                        computeStyle: {
                          gpuAcceleration: false
                        }
                      }}>
                      {(() => {
                        if (viewerOnError) {
                          return (
                            t('project:link_check_clash') +
                            ' : ' +
                            t('project:check_not_available_viewer_error')
                          );
                        } else if (isStageFrozen) {
                          return (
                            t('project:link_check_clash') +
                            ' : ' +
                            t('project:check_not_available_stage_frozen')
                          );
                        } else {
                          return t('project:link_check_clash');
                        }
                      })()}
                    </UncontrolledTooltip> */}
                  </NavLink>
                  <ConfirmationModal
                    t={t}
                    checkType={'clash'}
                    submit={() => this.handleSubmitAutoCheckShorcut('clash')}
                    isOpen={isConfirmationModalOpen['clash']}
                    toggle={this.generateToggleConfirmationModal('clash')}
                  />
                </NavItem>
              )}
              {/* ########  BIM DATA Check  ########*/}
              {isAvailable_check_bim_data && (
                <NavItem
                  className={classnames({
                    'border-right border-primary rounded border-sz-3':
                      this.state.activeAutocheckTab === 'bimdata'
                  })}>
                  <NavLink
                    id="goAutocheck-bim-data"
                    className={classnames(
                      'd-flex justify-content-between bg-light rounded px-2 text-body',
                      {
                        'active font-weight-bold':
                          this.state.activeAutocheckTab === 'bimdata'
                      }
                    )}
                    tag={Link}
                    to={
                      !isBlankStage
                        ? `/project/${projectId}/${stageName}/autocheck?check-type=bimdata`
                        : ''
                    }
                    onClick={() => {
                      this.toggleCheckTab('bimdata');
                    }}>
                    {t('link_check_bim_data')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className={`position-absolute ${styles.projectStageNavItemPosition}`}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    <CustomTooltip
                      target="goAutocheck-bim-data"
                      title={t('link_check_bim_data')}
                      description={(() => {
                        if (viewerOnError) {
                          return t('project:check_not_available_viewer_error');
                        } else if (isStageFrozen) {
                          return t('project:check_not_available_stage_frozen');
                        } else {
                          return t('project:link_check_bim_data_description');
                        }
                      })()}
                    />
                    {/* <UncontrolledTooltip
                      target="goAutocheck-bim-data"
                      fade={false}
                      modifiers={{
                        computeStyle: {
                          gpuAcceleration: false
                        }
                      }}>
                      {(() => {
                        if (viewerOnError) {
                          return (
                            t('project:link_check_bim_data') +
                            ' : ' +
                            t('project:check_not_available_viewer_error')
                          );
                        } else if (isStageFrozen) {
                          return (
                            t('project:link_check_bim_data') +
                            ' : ' +
                            t('project:check_not_available_stage_frozen')
                          );
                        } else {
                          return t('project:link_check_bim_data');
                        }
                      })()}
                    </UncontrolledTooltip> */}
                  </NavLink>
                  <ConfirmationModal
                    t={t}
                    checkType={'bimdata'}
                    submit={() => this.handleSubmitAutoCheckShorcut('bimdata')}
                    isOpen={isConfirmationModalOpen['bimdata']}
                    toggle={this.generateToggleConfirmationModal('bimdata')}
                  />
                </NavItem>
              )}
              {/* ########  BIM QualityCheck  ########*/}
              {isAvailable_check_quality && (
                <NavItem
                  className={classnames({
                    'border-right border-primary rounded border-sz-3 ':
                      this.state.activeAutocheckTab === 'quality'
                  })}>
                  <NavLink
                    id="goAutocheck-quality"
                    className={classnames(
                      'd-flex justify-content-between bg-light rounded px-2 text-body',
                      {
                        'active font-weight-bold':
                          this.state.activeAutocheckTab === 'quality'
                      }
                    )}
                    tag={Link}
                    to={
                      !isBlankStage
                        ? `/project/${projectId}/${stageName}/autocheck?check-type=quality`
                        : ''
                    }
                    onClick={() => {
                      this.toggleCheckTab('quality');
                    }}>
                    {t('link_check_quality')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className={`position-absolute ${styles.projectStageNavItemPosition}`}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    <CustomTooltip
                      target="goAutocheck-quality"
                      title={t('link_check_quality')}
                      description={(() => {
                        if (viewerOnError) {
                          return t('project:check_not_available_viewer_error');
                        } else if (isStageFrozen) {
                          return t('project:check_not_available_stage_frozen');
                        } else {
                          return t('project:link_check_quality_description');
                        }
                      })()}
                    />
                    {/* <UncontrolledTooltip
                      target="goAutocheck-quality"
                      fade={false}
                      modifiers={{
                        computeStyle: {
                          gpuAcceleration: false
                        }
                      }}>
                      {(() => {
                        if (viewerOnError) {
                          return (
                            t('project:link_check_quality') +
                            ' : ' +
                            t('project:check_not_available_viewer_error')
                          );
                        } else if (isStageFrozen) {
                          return (
                            t('project:link_check_quality') +
                            ' : ' +
                            t('project:check_not_available_stage_frozen')
                          );
                        } else {
                          return t('project:link_check_quality');
                        }
                      })()}
                    </UncontrolledTooltip> */}
                  </NavLink>
                  <ConfirmationModal
                    t={t}
                    checkType={'clash'}
                    submit={() => this.handleSubmitAutoCheckShorcut('quality')}
                    isOpen={isConfirmationModalOpen['quality']}
                    toggle={this.generateToggleConfirmationModal('quality')}
                  />
                </NavItem>
              )}
              {/* ########  Regulatory Check  ########*/}
              {isAvailable_check_eplan && (
                <NavItem
                  className={classnames({
                    'border-right border-primary rounded border-sz-3':
                      this.state.activeAutocheckTab === 'regulatory'
                  })}>
                  <NavLink
                    id="goAutocheck-regulatory"
                    className={classnames(
                      'd-flex justify-content-between bg-light rounded px-2 text-body',
                      {
                        'active font-weight-bold':
                          this.state.activeAutocheckTab === 'regulatory'
                      }
                    )}
                    tag={Link}
                    to={
                      !isBlankStage
                        ? `/project/${projectId}/${stageName}/autocheck?check-type=regulatory`
                        : ''
                    }
                    onClick={() => {
                      this.toggleCheckTab('regulatory');
                    }}>
                    {t('link_check_regulatory')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className={`position-absolute ${styles.projectStageNavItemPosition}`}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    {/* {t('main:link_autocheck')} */}

                    <CustomTooltip
                      target="goAutocheck-regulatory"
                      title={t('link_check_regulatory')}
                      description={(() => {
                        if (viewerOnError) {
                          return t('project:check_not_available_viewer_error');
                        } else if (isStageFrozen) {
                          return t('project:check_not_available_stage_frozen');
                        } else {
                          return t('project:link_check_regulatory_description');
                        }
                      })()}
                    />
                    {/* <UncontrolledTooltip
                      target="goAutocheck-regulatory"
                      fade={false}
                      modifiers={{
                        computeStyle: {
                          gpuAcceleration: false
                        }
                      }}>
                      {(() => {
                        if (viewerOnError) {
                          return (
                            t('project:link_check_regulatory') +
                            ' : ' +
                            t('project:check_not_available_viewer_error')
                          );
                        } else if (isStageFrozen) {
                          return (
                            t('project:link_check_regulatory') +
                            ' : ' +
                            t('project:check_not_available_stage_frozen')
                          );
                        } else {
                          return t('project:link_check_regulatory');
                        }
                      })()}
                    </UncontrolledTooltip> */}
                  </NavLink>
                  <ConfirmationModal
                    t={t}
                    checkType={'regulatory'}
                    submit={() =>
                      this.handleSubmitAutoCheckShorcut('regulatory')
                    }
                    isOpen={isConfirmationModalOpen['regulatory']}
                    toggle={this.generateToggleConfirmationModal('regulatory')}
                  />
                </NavItem>
              )}

              {/* ######## QUERYUI ######## */}
              {isQueryUIComponentEnabled ? (
                <NavItem
                  className={classnames({
                    'border-right border-primary rounded border-sz-3':
                      this.state.activeAutocheckTab === 'regulatory'
                  })}>
                  <NavLink
                    id="goQueryUI"
                    className={classnames(
                      'd-flex justify-content-between bg-light rounded px-2 text-body',
                      {
                        'active font-weight-bold':
                          this.state.activeAutocheckTab === 'regulatory'
                      }
                    )}
                    tag={Link}
                    to={
                      !isBlankStage
                        ? `/project/${projectId}/${stageName}/queryui`
                        : ''
                    }
                    onClick={() => {
                      this.toggleCheckTab('queryui');
                    }}>
                    {t('queryUI:queryUITitle')}
                    <div className="position-relative">
                      {viewerOnError && (
                        <span
                          className="position-absolute"
                          style={{
                            right: '5px',
                            top: '-1px'
                          }}>
                          <i className="text-danger fas fa-exclamation" />
                        </span>
                      )}
                    </div>
                    <CustomTooltip
                      target="goQueryUI"
                      title={t('project:queryUI')}
                      description={(() => {
                        if (viewerOnError) {
                          return t('project:check_not_available_viewer_error');
                        } else if (isStageFrozen) {
                          return t('project:check_not_available_stage_frozen');
                        } else {
                          return t('project:queryUIDescription');
                        }
                      })()}
                    />
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          )}
      </>
    );

    const defaultStage = this.props.project.stagesavailable.find(
      stage => stage.is_default
    );

    const isStageDeletable = stageName !== defaultStage.name;
    return (
      <>
        {isLoaded && (
          <>
            {stage && (
              <>
                {sidebarRef &&
                  sidebarRef.current &&
                  ReactDOM.createPortal(
                    <StageDetailNav
                      vertical={true}
                      t={t}
                      isStageFrozen={isStageFrozen}
                      viewerOnError={viewerOnError}
                    />,
                    sidebarRef.current
                  )}
                {activeAutocheckTab ? (
                  <TabContent activeTab={this.state.activeAutocheckTab}>
                    {isAvailableModelComparison &&
                      this.state.activeAutocheckTab === 'comparison' && (
                        <TabPane tabId="comparison">
                          <Autocheck type="comparison" />
                        </TabPane>
                      )}
                    {isAvailable_check_eplan &&
                      this.state.activeAutocheckTab === 'regulatory' && (
                        <TabPane tabId="regulatory">
                          <Autocheck type="regulatory" />
                        </TabPane>
                      )}

                    {isAvailable_check_clash &&
                      this.state.activeAutocheckTab === 'clash' && (
                        <TabPane tabId="clash">
                          <Autocheck type="clash" />
                        </TabPane>
                      )}
                    {isAvailable_check_quality &&
                      this.state.activeAutocheckTab === 'quality' && (
                        <TabPane tabId="quality">
                          <Autocheck type="quality" />
                        </TabPane>
                      )}
                    {isAvailable_check_bim_data &&
                      this.state.activeAutocheckTab === 'bimdata' && (
                        <TabPane tabId="bimdata">
                          <Autocheck type="bimdata" />
                        </TabPane>
                      )}
                  </TabContent>
                ) : (
                  <Container>
                    <Row>
                      <Col>
                        <div className="shadow p-3 mt-3 mb-5 rounded">
                          <Row className="d-flex justify-content-between px-3">
                            <h5 className="text-primary">
                              {t('model_management')}
                            </h5>
                            {isVideoTutorialsEnabled && (
                              <div>
                                <Link to="/videotutorials?v=video-2">
                                  {t('video_tutorial_link_2')}{' '}
                                  <i className="fas fa-video"></i>{' '}
                                </Link>
                              </div>
                            )}
                          </Row>
                          <Row className="px-3 d-flex justify-content-between mt-1">
                            <div className="d-flex">
                              {/* LEFT */}
                              <Dropdown
                                isOpen={dropdownOpen}
                                toggle={this.toggleDropdown}>
                                <DropdownToggle
                                  caret
                                  className={`mr-2 ${styles.maxWidth400}`}
                                  color="primary">
                                  <em>{t('stage')}: </em>
                                  {stageDropDownValue.display_name}
                                </DropdownToggle>
                                <DropdownMenu
                                  className={`${styles.maxWidth400} ${styles.maxWidth400} ${styles.overflowAuto}`}>
                                  <DropdownItem header>
                                    {t('stages')}
                                  </DropdownItem>
                                  {projectStages.map((stage, key) => {
                                    return (
                                      <DropdownItem
                                        key={`stage-${key}`}
                                        onClick={() =>
                                          this.stageChangeValue({
                                            name: stage.name,
                                            display_name: stage.display_name
                                          })
                                        }>
                                        {stage.display_name}
                                      </DropdownItem>
                                    );
                                  })}
                                  <DropdownItem divider />
                                  <DropdownItem
                                    // disabled style, made like this to work with the tooltip
                                    className={
                                      !availableStagesForCreation.length > 0
                                        ? styles.availableStagesForCreation
                                        : ''
                                    }
                                    onClick={e => {
                                      if (
                                        !availableStagesForCreation.length > 0
                                      ) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      } else {
                                        this.toggleModalStage(true);
                                      }
                                    }}>
                                    <div id="addStageModelToggle">
                                      {t('add_stage')}{' '}
                                      <i className="fas fa-plus" />
                                    </div>
                                    {!availableStagesForCreation.length > 0 && (
                                      <UncontrolledTooltip
                                        target="addStageModelToggle"
                                        boundariesElement="window"
                                        fade={false}
                                        modifiers={{
                                          computeStyle: {
                                            gpuAcceleration: false
                                          }
                                        }}>
                                        {t('max_stage_reached')}
                                      </UncontrolledTooltip>
                                    )}
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                              {/* ########  Webviewer Button ########*/}

                              {is2DPlanEnabled ? (
                                <>
                                  <Button
                                    id="popoverViewerButton"
                                    className="mr-1"
                                    color="primary"
                                    disabled={isBlankStage && !has2DModels}>
                                    <i className="far fa-eye" />
                                  </Button>
                                  <UncontrolledPopover
                                    boundariesElement="window"
                                    trigger="hover"
                                    className="popover-container"
                                    target="popoverViewerButton"
                                    hideArrow
                                    fade={false}
                                    placement="bottom"
                                    delay={{ show: 0, hide: 600 }}>
                                    <div className="d-flex flex-column">
                                      <WebviewerButton
                                        loadingViewer={loadingViewer}
                                        viewerOnError={viewerOnError}
                                        isViewerAvailable={isViewerAvailable}
                                        stageViewerToken={viewerToken}
                                        projectId={projectId}
                                        stageName={stageName}
                                        disabled={isBlankStage}
                                        viewerMode="3d"
                                        t={t}
                                        id={`viewBrowser3d`}
                                      />
                                      <WebviewerButton
                                        loadingViewer={
                                          loadingViewer && !has2DModels
                                        }
                                        viewerOnError={viewerOnError}
                                        isViewerAvailable={isViewerAvailable}
                                        stageViewerToken={viewerToken}
                                        projectId={projectId}
                                        stageName={stageName}
                                        disabled={isBlankStage && !has2DModels}
                                        viewerMode="2d"
                                        t={t}
                                        id={`viewBrowser2d`}
                                      />
                                    </div>
                                  </UncontrolledPopover>
                                </>
                              ) : (
                                <WebviewerButton
                                  loadingViewer={loadingViewer}
                                  viewerOnError={viewerOnError}
                                  isViewerAvailable={isViewerAvailable}
                                  stageViewerToken={viewerToken}
                                  projectId={projectId}
                                  stageName={stageName}
                                  disabled={isBlankStage}
                                  viewerMode="3d"
                                  t={t}
                                  id={`viewBrowser3d`}
                                />
                              )}
                              {isPrecheckEnabled && !isBlankStage && (
                                <>
                                  <Button
                                    id="viewPrecheckPopUpButton"
                                    color="primary"
                                    outline
                                    className="ml-2"
                                    // to={
                                    //   loadingViewer || viewerOnError || !isViewerAvailable
                                    //     ? '#'
                                    //     : {
                                    //       pathname: `/project/${projectId}/${stageName}/viewer`,

                                    //       search: `viewerMode=${viewerMode ||
                                    //           '3d'}&token=${stageViewerToken}`
                                    //     }
                                    // }
                                    disabled={!isPrecheckComplete}
                                    onClick={e => {
                                      if (!isPrecheckComplete) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    }}>
                                    {!isPrecheckComplete && (
                                      <span
                                        className="mr-2 spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <span className="font-weight-bold">
                                      {t('precheck_button')}
                                    </span>
                                    {isPrecheckComplete && (
                                      <span
                                        className={`position-relative ${styles.projectStageViewOnError}`}>
                                        {['READY', 'PASSED', 'EMPTY'].includes(
                                          precheckStatus
                                        ) ? (
                                          <i className="text-success fas fa-check"></i>
                                        ) : (
                                          <i className="text-danger fas fa-exclamation" />
                                        )}
                                      </span>
                                    )}
                                  </Button>
                                  {isPrecheckComplete && (
                                    <UncontrolledPopover
                                      trigger="legacy"
                                      boundariesElement="scrollParent"
                                      className="precheck-popover-container"
                                      target="viewPrecheckPopUpButton"
                                      // hideArrow
                                      fade={false}
                                      placement="bottom"
                                      // delay={{
                                      //   show: 0,
                                      //   hide: 600
                                      // }}
                                    >
                                      <PopoverHeader>
                                        {t('precheck_report_popover_title')}
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <div className="d-flex flex-column">
                                          <div>
                                            {t(
                                              `precheck_status_${
                                                precheckStatus &&
                                                precheckStatus.toLowerCase()
                                              }_intro`
                                            )}
                                          </div>
                                          {![
                                            'READY',
                                            'PASSED',
                                            'EMPTY'
                                          ].includes(precheckStatus) && (
                                            <>
                                              <hr />
                                              <div className="d-flex justify-content-end">
                                                <a
                                                  download={`${this.props.projectName}_precheck_report.docx`}
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                  href={`${
                                                    process.env
                                                      .REACT_APP_API_ENDPOINT[
                                                      process.env
                                                        .REACT_APP_API_ENDPOINT
                                                        .length - 1
                                                    ] == '/'
                                                      ? process.env.REACT_APP_API_ENDPOINT.slice(
                                                          0,
                                                          -1
                                                        )
                                                      : process.env
                                                          .REACT_APP_API_ENDPOINT
                                                  }/api/project/${projectId}/${stageName}/${
                                                    stage.precheck_job_id
                                                  }/${
                                                    stage.precheck_display_name ||
                                                    'result'
                                                  }.docx/download`}
                                                  key="precheck-file-download">
                                                  <Button
                                                    id={'fileLink-precheck-dl'}
                                                    size="md"
                                                    color="warning"
                                                    // className="text-white"
                                                  >
                                                    <i className="fas fa-file-download"></i>
                                                  </Button>
                                                  <UncontrolledTooltip
                                                    boundariesElement="window"
                                                    fade={false}
                                                    target={
                                                      'fileLink-precheck-dl'
                                                    }
                                                    delay={0}>
                                                    {t(
                                                      'download_precheck_report'
                                                    )}
                                                  </UncontrolledTooltip>
                                                </a>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="d-flex">
                              <h6 className="text-muted font-weight-bold">
                                {t('project:stage_disk_usage')}{' '}
                                {prettyBytes(sizePerProjectStage)}
                              </h6>
                            </div>
                            <ModalStageCreation
                              {...this.props}
                              isOpen={isModalStageOpen}
                              projectId={projectId}
                              toggleModalStage={this.toggleModalStage}
                              createStage={createStage}
                              onCreateSuccess={this.stageChangeValue}
                              stagesAvailable={projectStages}
                              defaultOriginStage={stageName}
                              isDropdownStageListingEnable={
                                isDropdownStageListingEnable
                              }
                              availableStagesForCreation={
                                availableStagesForCreation
                              }
                            />
                            <ModalStageComparison
                              isVideoTutorialsEnabled={isVideoTutorialsEnabled}
                              isOpen={isRequestComparisonModalOpen}
                              toggle={this.toggleComparisonModal}
                              t={t}
                              targetStageChangeValue={
                                this.targetStageChangeValue
                              }
                              currentStage={stage.display_name}
                              onSubmit={async () => {
                                if (isViewerAvailable) {
                                  await this.requestComparison(
                                    projectId,
                                    stageDropDownValue.name,
                                    targetStageDropDownValue.name,
                                    checkedRulesForComparison
                                  );

                                  //this.setCheckedRulesForComparison([]);
                                } else {
                                  Message.error(
                                    'cannot_request_for_comparison'
                                  );
                                }
                              }}
                              targetStageDropDownValue={
                                targetStageDropDownValue
                              }
                              filteredTargetStages={filteredTargetStages}
                              modelComparisonList={
                                this.props.model_comparison_list
                              }
                              setCheckedRulesForComparison={
                                this.setCheckedRulesForComparison
                              }
                              checkedRulesForComparison={
                                checkedRulesForComparison
                              }
                            />

                            <ModalDeleteStageConfirmation
                              {...this.props}
                              runDeleteStage={this.runDeleteStage}
                              projectId={projectId}
                              stageToDelete={stageName}
                              isOpen={isModalDeleteStageConfirmationOpen}
                              toggleModalStageDeletion={
                                this.toggleModalStageDeletion
                              }
                              toggleModalDeleteStageConfirmation={
                                this.toggleModalDeleteStageConfirmation
                              }
                            />
                          </Row>
                          <hr />

                          {!!isLoaded && (
                            <Formik {...this.formikConfig(this.props)}>
                              {formikProps => {
                                const { values, handleSubmit } = formikProps;

                                return (
                                  <Form>
                                    <Row
                                      className={classnames('p-3', {
                                        'border-danger border border-thick rounded':
                                          !!formikProps.errors.models2d &&
                                          formikProps.submitCount > 0
                                      })}>
                                      <Col xs={12} sm={8}>
                                        <Nav tabs>
                                          <NavItem
                                            className={classnames({
                                              'border-left border-primary rounded border-sz-3':
                                                activeModelTypeTab ===
                                                '3D_models'
                                            })}>
                                            <NavLink
                                              className={classnames('rounded', {
                                                'active font-weight-bold':
                                                  activeModelTypeTab
                                                    ? activeModelTypeTab ===
                                                      '3D_models'
                                                    : false
                                              })}
                                              onClick={() => {
                                                this.toggleModelTypeTab(
                                                  '3D_models'
                                                );
                                              }}>
                                              <div className="hover-underline-animation">
                                                {t('project:model_3D')}
                                              </div>
                                              <Badge
                                                className="ml-2"
                                                color="primary">
                                                {model3DListCount}
                                              </Badge>
                                            </NavLink>
                                          </NavItem>
                                          {is2DPlanEnabled && (
                                            <NavItem
                                              className={classnames({
                                                'border-left border-primary rounded border-sz-3':
                                                  activeModelTypeTab ===
                                                  '2D_models'
                                              })}>
                                              <NavLink
                                                className={classnames(
                                                  'rounded',
                                                  {
                                                    'active font-weight-bold':
                                                      activeModelTypeTab
                                                        ? activeModelTypeTab ===
                                                          '2D_models'
                                                        : false,
                                                    'text-danger border-danger':
                                                      !!formikProps.errors
                                                        .models2d &&
                                                      formikProps.submitCount >
                                                        0
                                                  }
                                                )}
                                                onClick={() => {
                                                  this.toggleModelTypeTab(
                                                    '2D_models'
                                                  );
                                                }}>
                                                <div className="hover-underline-animation">
                                                  {t('project:model_2D')}
                                                </div>

                                                <Badge
                                                  className="ml-2"
                                                  color="primary">
                                                  {model2DListCount}
                                                </Badge>
                                              </NavLink>
                                            </NavItem>
                                          )}
                                        </Nav>
                                        <TabContent
                                          activeTab={activeModelTypeTab}>
                                          <TabPane tabId="3D_models">
                                            <Container
                                              fluid
                                              className="pt-4 px-0 model-list-container">
                                              <ModelList
                                                {...this.props}
                                                asFormikField={false}
                                                {...modelFileAction}
                                                disabled={isModelListDisabled}
                                                models={models.filter(
                                                  m => !m.removed
                                                )}
                                              />
                                            </Container>
                                          </TabPane>
                                          {is2DPlanEnabled && (
                                            <TabPane tabId="2D_models">
                                              <div className="pt-4">
                                                <FieldArray
                                                  name="models2d"
                                                  render={arrayHelpers => {
                                                    return (
                                                      <ModelList2D
                                                        {...this.props}
                                                        asFormikField={true}
                                                        {...model2DFileAction}
                                                        disabled={
                                                          isModelListDisabled &&
                                                          !has2DModels
                                                        }
                                                        models={models2D.filter(
                                                          m => !m.removed
                                                        )}
                                                        // onUpdateModelList={}
                                                        isFromProjectCreation={
                                                          false
                                                        }
                                                        formikProps={
                                                          formikProps
                                                        }
                                                        {...arrayHelpers}
                                                        setEnableReinitialize={
                                                          this
                                                            .setEnableReinitialize
                                                        }
                                                      />
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </TabPane>
                                          )}
                                        </TabContent>
                                        {this.props.isUploadingFile && (
                                          <UploadProgressBar />
                                        )}
                                      </Col>
                                      <Col xs={12} sm={4}>
                                        <ModelUploadBox
                                          {...this.props}
                                          activetab={activeModelTypeTab}
                                          models={models.filter(
                                            m => !m.removed
                                          )}
                                          models2D={models2D.filter(
                                            m => !m.removed
                                          )}
                                          disabled3D={isModelListDisabled}
                                          disabled2D={!is2DPlanEnabled}
                                        />
                                      </Col>
                                    </Row>

                                    {(modelType.isModelListDirty ||
                                      formikProps.dirty) && (
                                      <>
                                        {!modelType.isModelListValid && (
                                          <div
                                            className="alert alert-danger mt-4"
                                            role="alert">
                                            {t(
                                              'project:minimum_model_list_item_error_message'
                                            )}
                                          </div>
                                        )}
                                        <Row className="my-3">
                                          <Col>
                                            <Button
                                              type="submit"
                                              disabled={
                                                !modelType.isModelListValid ||
                                                isFileUploading
                                              }
                                              onClick={handleSubmit}>
                                              {isFileUploading ? (
                                                <>
                                                  <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"></span>
                                                  <span className="sr-only">
                                                    Updating...
                                                  </span>{' '}
                                                  {t('update_in_progress')}
                                                </>
                                              ) : (
                                                // t(modelType.buttonText)
                                                modelType.buttonText
                                              )}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </Form>
                                );
                              }}
                            </Formik>
                          )}

                          {(modelType.filesAdded.length > 0 ||
                            modelType.filesRemoved.length > 0) && (
                            <Row className="mt-3">
                              <Col>
                                <Alert color="info">
                                  <ul>
                                    {modelType.filesAdded.map((f, index) => (
                                      <li key={`file-added-${index}`}>
                                        {f.name} <strong>{t('added')}</strong>
                                      </li>
                                    ))}

                                    {modelType.filesRemoved.map((f, index) => (
                                      <li key={`file-removed-${index}`}>
                                        {f.name} <strong>{t('removed')}</strong>
                                      </li>
                                    ))}
                                  </ul>
                                </Alert>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {
                      <Row>
                        <Col>
                          <div className="shadow p-3 mb-5 rounded">
                            <div className="d-flex justify-content-between">
                              <h5 className="text-primary">{t('results')}</h5>
                              {isVideoTutorialsEnabled && (
                                <div>
                                  <Link to="/videotutorials?v=video-3">
                                    {t('video_tutorial_link_3')}{' '}
                                    <i className="fas fa-video"></i>
                                  </Link>
                                </div>
                              )}
                            </div>
                            {/* Results UI */}
                            <Nav tabs>
                              {jobsTab.map((jobType, index) => {
                                const stageJobs =
                                  stage.jobs[jobType] &&
                                  stage.jobs[jobType].length > 0;
                                const stageComparisons =
                                  stage.comparisons &&
                                  stage.comparisons.length > 0;

                                return (
                                  <NavItem
                                    className={classnames({
                                      'border-left border-primary rounded border-sz-3':
                                        activeResultTab
                                          ? activeResultTab ===
                                            `result-${jobType}`
                                          : index === 0
                                    })}
                                    key={`nav-result-${jobType}`}>
                                    <NavLink
                                      className={classnames('rounded', {
                                        'active font-weight-bold':
                                          activeResultTab
                                            ? activeResultTab ===
                                              `result-${jobType}`
                                            : index === 0
                                      })}
                                      onClick={() => {
                                        this.toggleResultTab(
                                          `result-${jobType}`,
                                          jobType
                                        );
                                      }}>
                                      {t(`result_${jobType.toLowerCase()}`)}
                                      {jobType !== 'comparison' ? (
                                        stageJobs ? (
                                          <Badge
                                            className="ml-2"
                                            color="primary">
                                            {stage.jobs[jobType].length}
                                          </Badge>
                                        ) : (
                                          <Badge
                                            className="ml-2"
                                            color="primary">
                                            0
                                          </Badge>
                                        )
                                      ) : stageComparisons ? (
                                        <Badge className="ml-2" color="primary">
                                          {stage.comparisons.length}
                                        </Badge>
                                      ) : (
                                        <Badge className="ml-2" color="primary">
                                          0
                                        </Badge>
                                      )}
                                    </NavLink>
                                  </NavItem>
                                );
                              })}
                            </Nav>
                            {/* TAB CONTENT */}
                            <TabContent
                              activeTab={
                                activeResultTab
                                  ? activeResultTab
                                  : `result-${jobsTab[0]}`
                              }>
                              {jobsTab.map(jobType => {
                                const jobList =
                                  typeof stage.jobs[jobType] != 'undefined'
                                    ? stage.jobs[jobType]
                                    : [];

                                return (
                                  jobType !== 'comparison' && (
                                    <TabPane
                                      key={`tab-pane-result-${jobType}`}
                                      className="pt-3"
                                      tabId={`result-${jobType}`}>
                                      {isProjectResultFilteringEnabled ? (
                                        <ProjectResultWithFilters
                                          isFXRDownloadEnabled={
                                            isFXRDownloadEnabled
                                          }
                                          isJobCancellationEnabled={
                                            isJobCancellationEnabled
                                          }
                                          partialResultButtonEnabled={
                                            partialResultButtonEnabled
                                          }
                                          projectName={project.name}
                                          projectId={projectId}
                                          stageName={stageName}
                                          data={jobList}
                                          isViewerEnabled={
                                            !loadingViewer &&
                                            !viewerOnError &&
                                            isViewerAvailable
                                          }
                                          updateRouterHistory={
                                            this.updateRouterHistory
                                          }
                                          activeJobType={
                                            activeJobType || jobsTab[0]
                                          }
                                          currentJobType={jobType}
                                          fetchProjectStageJobs={
                                            this.props.fetchProjectStageJobs
                                          }
                                          userId={this.props.userId}
                                          subscribeToEvents={
                                            this.props.subscribeToEvents
                                          }
                                        />
                                      ) : (
                                        <ProjectResult
                                          isFXRDownloadEnabled={
                                            isFXRDownloadEnabled
                                          }
                                          isJobCancellationEnabled={
                                            isJobCancellationEnabled
                                          }
                                          partialResultButtonEnabled={
                                            partialResultButtonEnabled
                                          }
                                          projectName={project.name}
                                          projectId={projectId}
                                          stageName={stageName}
                                          data={jobList}
                                          isViewerEnabled={
                                            !loadingViewer &&
                                            !viewerOnError &&
                                            isViewerAvailable
                                          }
                                          updateRouterHistory={
                                            this.updateRouterHistory
                                          }
                                          activeJobType={
                                            activeJobType || jobsTab[0]
                                          }
                                          currentJobType={jobType}
                                          fetchProjectStageJobs={
                                            this.props.fetchProjectStageJobs
                                          }
                                          userId={this.props.userId}
                                          subscribeToEvents={
                                            this.props.subscribeToEvents
                                          }
                                        />
                                      )}
                                    </TabPane>
                                  )
                                );
                              })}
                              {activeResultTab === 'result-comparison' && (
                                <TabPane
                                  tabId={'result-comparison'}
                                  className="pt-3">
                                  {isProjectResultFilteringEnabled ? (
                                    <StageComparisonResultPresenterWithFilters
                                      data={stagesCompared}
                                      projectId={projectId}
                                      stageName={stageName}
                                      model_comparison_list={
                                        this.props.model_comparison_list || []
                                      }
                                      updateRouterHistory={
                                        this.updateRouterHistory
                                      }
                                      activeJobType={
                                        activeJobType || jobsTab[0]
                                      }
                                      fetchProjectStageJobs={
                                        this.props.fetchProjectStageJobs
                                      }
                                      filteredTargetStages={
                                        filteredTargetStages
                                      }
                                      subscribeToEvents={
                                        this.props.subscribeToEvents
                                      }
                                      userId={this.props.userId}
                                    />
                                  ) : (
                                    <StageComparisonResultPresenter
                                      data={stagesCompared}
                                      projectId={projectId}
                                      stageName={stageName}
                                      model_comparison_list={
                                        this.props.model_comparison_list || []
                                      }
                                      updateRouterHistory={
                                        this.updateRouterHistory
                                      }
                                      activeJobType={
                                        activeJobType || jobsTab[0]
                                      }
                                      fetchProjectStageJobs={
                                        this.props.fetchProjectStageJobs
                                      }
                                      filteredTargetStages={
                                        filteredTargetStages
                                      }
                                      subscribeToEvents={
                                        this.props.subscribeToEvents
                                      }
                                      userId={this.props.userId}
                                    />
                                  )}
                                </TabPane>
                              )}
                            </TabContent>
                          </div>
                        </Col>
                      </Row>
                    }

                    {isStageDeletable && (
                      <Row>
                        <Col>
                          <div className="">
                            <div className="float-right shadow p-3 mb-5 rounded">
                              <div className="d-flex">
                                <Button
                                  id="project-stage-btn-delete"
                                  color="danger"
                                  onClick={
                                    this.toggleModalDeleteStageConfirmation
                                  }>
                                  {t(`project:delete_stage`)}
                                  <i className="fas fa-trash-alt ml-2" />
                                </Button>
                                <CustomTooltip
                                  target="project-stage-btn-delete"
                                  title={t(
                                    'project_stage_delete_tooltip_title'
                                  )}
                                  description={t(
                                    'project_stage_delete_tooltip_description'
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Container>
                )}
              </>
            )}
          </>
        )}
        {!isLoaded && (
          <div
            className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
            <Spinner
              className={styles.spinnerDimention}
              type="grow"
              color="primary"
            />
          </div>
        )}
      </>
    );
  }
}

export default compose(
  withTranslation(['project', 'main', 'error']),
  withSocketIO
)(ProjectStagePresenter);
