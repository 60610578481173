import React, { Component } from 'react';
import { Card, Button, Alert, FormGroup, Container } from 'reactstrap';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import CustomInput from '../../UI/Input/customInput';
import './style.css';

const formikConfig = props => ({
  initialValues: { new_password: '', confirm_password: '' },
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    new_password: Yup.string()
      .required('Required')
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .matches(/^[a-zA-Z0-9]*$/, 'Password must contain only alphanumeric')
      .min(8, 'Password must contain at least 8 characters.'),
    confirm_password: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('new_password'), null], props.t('error:not_match'))
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .matches(/^[a-zA-Z0-9]*$/, 'Password must contain only alphanumeric')
      .min(8, 'Password must contain at least 8 characters.')
  }),

  onSubmit: (values, { setSubmitting }) => {
    setSubmitting(true);
    const password = { password: values.new_password };
    const {
      createNewPassword,
      setNewPassword,
      passwordModificationType
    } = props;
    if (passwordModificationType === 'RESET') {
      createNewPassword(password, props.match.params.token);
    } else {
      setNewPassword(password, props.match.params.token);
    }

    props.history.push('/password_reset_succeed');
    setSubmitting(false);
  }
});

class NewPasswordPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: false,
      messageKey: ''
    };
  }
  componentDidMount() {
    const { checkResetPasswordToken } = this.props;
    checkResetPasswordToken(this.props.match.params.token).then(res => {
      if (res.success) {
        this.setState({
          isLoaded: true,
          error: false,
          messageKey: res.message
        });
      } else {
        this.setState({ isLoaded: true, error: !res.success && res.message });
      }
    });
  }

  render() {
    const { t } = this.props;
    const { isLoaded, error } = this.state;
    const ContentToDisplay = () => {
      if (isLoaded && !error) {
        return (
          <>
            <Formik {...formikConfig(this.props)}>
              {props => {
                const { isSubmitting } = props;
                return (
                  <Form>
                    <Container>
                      <h3 className={'text-center text-primary p-4'}>
                        {t('forgotPassword:create_new_password')}
                      </h3>
                      <FormGroup className="pl-3 pr-3">
                        <Field
                          name="new_password"
                          type="password"
                          label={t('forgotPassword:new_password')}
                          placeholder={t('forgotPassword:new_password')}
                          prefix={<i className="fas fa-lock" />}
                          component={CustomInput}
                        />
                      </FormGroup>
                      <FormGroup className="pl-3 pr-3">
                        <Field
                          name="confirm_password"
                          type="password"
                          label={t('forgotPassword:confirm_password')}
                          placeholder={t('forgotPassword:confirm_password')}
                          prefix={<i className="fas fa-lock" />}
                          component={CustomInput}
                        />
                      </FormGroup>
                      <FormGroup className="text-center pt-4">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                          className="resetPasswordButton">
                          {t('forgotPassword:reset_my_password')}
                        </Button>
                      </FormGroup>
                    </Container>
                  </Form>
                );
              }}
            </Formik>
          </>
        );
      } else if (isLoaded) {
        return (
          <Alert color="danger" className="text-center m-1" role="alert">
            <i className="fas fa-exclamation-triangle exclamationTriangleIcon" />
            <h3 className="p-3">{t('forgotPassword:failed')}</h3>
            {t('error:token_invalid')}
            <div>
              <Link to={'/forgot-password'}>
                {t('forgotPassword:try_again')}
              </Link>
            </div>
          </Alert>
        );
      } else {
        return (
          <>
            <h1 className={'text-center text-muted p-4'}>
              {t('forgotPassword:verification_in_process')}
            </h1>
            <hr className="my-2" />
            <div className="d-flex justify-content-center">
              <div
                className="spinner-grow text-primary mt-3 loadingSpinner"
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        );
      }
    };
    return (
      <Container className={'p-5'} id="newPasswordContainer">
        <Card>
          <ContentToDisplay />
        </Card>
      </Container>
    );
  }
}
export default withTranslation(['forgotPassword', 'error'])(
  NewPasswordPresenter
);
