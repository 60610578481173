import { authUserActionType } from '../../constants/actionType';
const initialState = {
  id: null,
  userType: null,
  company: null,
  hasActiveSubscription: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authUserActionType.SET_ME:
      if (action.data.first_name || action.data.last_name) {
        action.data.full_name =
          (action.data.first_name || '').trim() +
          ' ' +
          (action.data.last_name || '').trim();
      }
      return { ...state, ...action.data };
    case authUserActionType.UNSET_ME:
      return initialState;
    case authUserActionType.UPDATE_USER: {
      const { updatedUserData } = action;
      return { ...state, ...updatedUserData };
    }
    case authUserActionType.SET_MY_ORGANIZATION:
      return { ...state, organizationId: action.organizationId };
    case authUserActionType.SET_USER_SUBSCRIPTION_ID: {
      const { currentActiveSubscriptionId, hasActiveSubscription } = action;
      return {
        ...state,
        currentActiveSubscriptionId,
        hasActiveSubscription
      };
    }
    default:
      return state;
  }
};

export default reducer;
