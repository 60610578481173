import { combineReducers } from 'redux';
import app from './app';
import authUser from './authUser';
import news from './news';
import projects from './projects';
import requests from './requests';
import rules from './rules';
import rules_admin from './rules_admin';
import rule_history_admin from './rule_history_admin';
import users from './users';
import modelFiles from './modelFiles';
import feedback from './feedback';
import submission from './submission';
import roles from './roles';
import userFunction from './userFunction';
import results from './results';
import project_stages from './project_stages';
import attachment_files from './attachment_files';
import notifications from './notifications';
import profession from './profession';
import statistics from './statistics';
import reference_documents from './reference_documents';
import knowledgeHubResults from './knowledgeHub';
import modelIssues from './model_issue';
import model2DFiles from './2DmodelFiles';
import bimrl from './bimrl_data';
import admin_configuration from './admin_configuration';
import userPreference from './user_preference';
import project_preference from './project_preference';
import jobs from './jobs';
import tasks from './task_management';
import organization from './organization';
import maintenance_announcement from './maintenance_announcement';

import statistics_checks_summary from './statistics_v2';

import subscriptions from './subscription';

import project_stage_results from './project_stage_results';

import rule_package_update from './rule_package_update';

export default combineReducers({
  app,
  authUser,
  news,
  projects,
  requests,
  rules,
  rules_admin,
  rule_history_admin,
  users,
  modelFiles,
  feedback,
  submission,
  roles,
  userFunction,
  results,
  project_stages,
  attachment_files,
  notifications,
  profession,
  statistics,
  reference_documents,
  knowledgeHubResults,
  modelIssues,
  model2DFiles,
  bimrl,
  admin_configuration,
  userPreference,
  project_preference,
  jobs,
  tasks,
  organization,
  maintenance_announcement,
  statistics_checks_summary,
  subscriptions,
  project_stage_results,
  rule_package_update
});
