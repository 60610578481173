import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Alert
} from 'reactstrap';
import { Formik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import Message from '../../../UI/Message';
import CustomInput from '../../../UI/Input/customInput';
import * as Yup from 'yup';
import styles from './Submission.module.css';

const formikConfig = (props, setConflictSubmissions, excludeConflicts) => ({
  initialValues: {
    po_assigned_set: props.officerAssigned
      ? props.officerAssigned.map(officer => officer.user_id)
      : []
  },
  validateOnChange: false,
  // validationSchema: Yup.object().shape({
  //   po_assigned_set: Yup.string().min(1, props.t('error_select_one_po'))
  // }),
  onSubmit: async (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      updateOneSubmission,
      updateMultipleSubmission,
      submissions,
      submissionIds,
      typeOfUpdate,
      projectId,
      stageName
    } = props;

    if (typeOfUpdate === 'SINGLE') {
      const singleId = submissions.filter(submission => {
        return submission.submission_id === submissionIds[0];
      });

      const { project_id, submission_id, stage_name } = singleId[0];
      updateOneSubmission(
        projectId || project_id,
        stageName || stage_name,
        submission_id,
        values
      )
        .then(({ result, data }) => {
          if (result === 'success') {
            if (data.po_assigned_set.length > 0) {
              Message.success(props.t('success_assign_po'));
            } else {
              Message.success(props.t('success_remove_po'));
            }
          }

          props.toggle();
          setSubmitting(false);
        })
        .catch(err => {
          Message.error(
            props.t(`error:${err.message}`, {
              entity: 'BIM review'
            })
          );
        });
    } else {
      const submissionsToUpdate = {};
      submissionIds.map(id => {
        submissionsToUpdate[id] = values;
      });

      await updateMultipleSubmission(submissionsToUpdate, excludeConflicts)
        .then(({ conflictSubmissions, data }) => {
          if (data.filter(sub => sub).length > 0) {
            Message.success(props.t('success_assign_po'));
          }

          if (conflictSubmissions.length > 0) {
            Message.error(props.t('error:outdated_entity_data'));
          }
          props.toggle();
          setSubmitting(false);
        })
        .catch(err => {
          console.log(err);
          if (excludeConflicts) {
            props.toggle();
            Message.error(props.t(`error:${err.message}`));
          } else {
            setConflictSubmissions(err.conflictIds);
          }

          setSubmitting(false);
        });
    }
  }
});

class AssignPOPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conflictSubmissions: [],
      excludeConflicts: false
    };
  }

  setConflictSubmissions = conflictSubmissions => {
    this.setState({
      conflictSubmissions: conflictSubmissions,
      excludeConflicts: true
    });
  };
  render() {
    const { modal, toggle, availablePOs, t } = this.props;
    return (
      <Formik
        {...formikConfig(
          this.props,
          this.setConflictSubmissions,
          this.state.excludeConflicts
        )}>
        {formikProps => {
          const { setFieldValue, isSubmitting, handleSubmit } = formikProps;
          return (
            <Modal
              isOpen={modal}
              toggle={toggle}
              className={this.props.className}
              backdrop="static"
              size="lg">
              <ModalHeader toggle={toggle}>
                {t('processing_officer')}
              </ModalHeader>
              <ModalBody>
                <Field
                  name="po_assigned_set"
                  type="select"
                  component={CustomInput}
                  onChange={evt =>
                    setFieldValue(
                      'po_assigned_set',
                      [].slice
                        .call(evt.target.selectedOptions)
                        .map(option => option.value)
                    )
                  }
                  value={formikProps.values.po_assigned_set}
                  multiple={true}>
                  <option className="text-muted font-italic"> None</option>
                  {availablePOs.map((user, key) => {
                    return (
                      <option key={key} value={user.user_id}>
                        {`${user.first_name} ${user.last_name}`}
                      </option>
                    );
                  })}
                </Field>
                {this.state.conflictSubmissions &&
                  this.state.conflictSubmissions.length > 0 && (
                    <div className={styles.conflictAlertMaxHeight}>
                      <Alert color="danger">
                        {t('error:outdated_entity_data_with_list', {
                          entity: 'submissions'
                        })}
                      </Alert>

                      <ListGroup>
                        {this.state.conflictSubmissions.map(res => {
                          return (
                            <ListGroupItem>{res.project_name}</ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    </div>
                  )}
              </ModalBody>
              <ModalFooter>
                <Button
                  id="assign-po-modal-confirm-btn"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary">
                  {t('assign')}
                </Button>{' '}
                <Button
                  id="assign-po-modal-cancel-btn"
                  color="secondary"
                  onClick={toggle}>
                  {t('cancel')}
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

export default withTranslation(['submission'])(AssignPOPresenter);
