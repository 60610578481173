import React, { PureComponent } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ModalMember from './ModalMember';

import ModalLeaveProject from './ModalLeaveProject';

import ModelAddMemberToGroup from './ModelAddMemberToGroup';

import ModalAddProjectToOrg from './ModalAddProjectToOrg';

import Message from '../../UI/Message';

import {
  isEmailExist,
  inviteUser,
  leaveProject
} from '../../../store/actions/users';

import {
  fetchOrganizationRoles,
  shareProjectToOrg,
  checkPermission
} from '../../../store/actions';

class MemberDontExistModal extends PureComponent {
  render() {
    const { open, onCancel, onInvite, email, t } = this.props;

    const emailString =
      !!email &&
      email
        .toString()
        .replace(/,/g, ', ')
        .replace(/,(?=[^,]*$)/, ' and');

    // TODO TRANSLATION
    return (
      <Modal isOpen={open} toggle={onCancel} className={this.props.className}>
        <ModalHeader>{t('unknown_member', email)}</ModalHeader>
        <ModalBody>
          {t('unknown_member_message', { email: emailString })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onInvite}>
            {t('main:yes')}
          </Button>
          <Button color="secondary" onClick={onCancel}>
            {t('main:no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

class MemberList extends PureComponent {
  constructor(props) {
    super(props);
    this.textAreaRef = React.createRef();
    this.state = {
      modalList: props.members.reduce((acc, _, index) => {
        return { ...acc, [index]: false };
      }, {}),
      modalAddNew: false,
      unknownMember: {},
      indexUnknown: -1,
      modalUnknownMember: false,
      modalAddMemberToGroup: false,
      modalLeaveProject: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.members.length !== prevProps.members.length) {
      this.setState({
        modalList: this.props.members.reduce((acc, _, index) => {
          return { ...acc, [index]: false };
        }, {})
      });
    }
  }

  modalListToggle = key => {
    this.setState(prevState => ({
      modalList: { ...prevState.modalList, [key]: !prevState.modalList[key] }
    }));
  };

  addNewToggle = () => {
    this.setState(prevState => ({
      modalAddNew: !prevState.modalAddNew
    }));
  };

  addMemberToGroupToggle = () => {
    this.setState(prevState => ({
      modalAddMemberToGroup: !prevState.modalAddMemberToGroup
    }));
  };

  onAddNew = (values, index) => {
    Promise.all(
      values.multiple_emails.map(async email => {
        return this.props.isEmailExist(email).then(res => {
          return { isExist: res.data, email: email };
        });
      })
    ).then(res => {
      const unknownEmails = res
        .filter(data => !data.isExist)
        .map(data => data.email);

      const existingEmails = res
        .filter(data => data.isExist)
        .map(data => ({ email: data.email, role: values.role }));

      const nextIndex =
        Object.keys(this.state.modalList).length + existingEmails.length;

      if (unknownEmails.length > 0) {
        this.onUnknownMember(
          { email: unknownEmails, role: values.role },
          nextIndex
        );
      }

      if (existingEmails.length > 0) {
        existingEmails.map(data => {
          this.props.push(data);
        });
      }
    });
  };

  onEditModal = (values, index) => {
    if (this.props.form.values.members[index].email !== values.email) {
      this.props
        .isEmailExist(values.email)
        .then(res => res.data)
        .then(res => {
          if (!res) {
            this.onUnknownMember(values, index);
          } else {
            this.props.form.setFieldValue(
              `members.${index}.email`,
              values.email
            );
            this.props.form.setFieldValue(`members.${index}.role`, values.role);
          }
        });
    } else {
      this.props.form.setFieldValue(`members.${index}.role`, values.role);
    }
  };

  onUnknownMember = (unknownMember, index = -1) => {
    this.setState({
      modalAddNew: false,
      unknownMember,
      indexUnknown: index,
      modalUnknownMember: true
    });
  };
  closeUnknwonMemberModal = () => {
    this.setState({
      modalUnknownMember: false,
      unknownMember: {},
      indexUnknown: -1
    });
  };

  onMemberInvitation = () => {
    const { indexUnknown, unknownMember } = this.state;

    unknownMember.email.map((e, i) => {
      const index = indexUnknown + i;

      if (index > -1) {
        this.props.form.setFieldValue(`members.${index}.email`, e);
        this.props.form.setFieldValue(
          `members.${index}.role`,
          unknownMember.role
        );
      } else {
        this.props.push({ email: e, role: unknownMember.role });
      }
      this.setState({
        modalUnknownMember: false,
        unknownMember: {},
        indexUnknown: -1
      });

      this.props.inviteUser({
        email: e,
        role: unknownMember.role,
        projectId: this.props.match.params.id,
        projectName: this.props.projectName
      });
    });
  };

  onShareProjectToOrganization = values => {
    this.props.form.setFieldValue('user_group', values);
  };

  leaveProjectToggle = () => {
    this.setState(prevState => ({
      modalLeaveProject: !prevState.modalLeaveProject
    }));
  };

  onLeaveProject = async (authUser, index) => {
    const userId = authUser.userId;
    const email = authUser.email;

    const projectId = this.props.match.params.id;

    try {
      await this.props.leaveProject(projectId, email).then(data => {
        const { success } = data;

        if (success) {
          Message.success(
            this.props.t('project:succesfully_leave_the_project')
          );
          this.props.history.push('/dashboard');
        } else {
          Message.error(this.props.t(`project:${data.message}`));
        }
      });

      // this.props.history.push('/dashboard');
    } catch (error) {
      Message.error(this.props.t(`${error.message}`));
    }
  };

  componentDidMount() {}
  render() {
    const { t, remove, disabled, members, relatedGroups, featureList } =
      this.props;

    const isOrganizationEnabled = featureList.organization;

    const { modalList, modalAddNew, modalAddMemberToGroup } = this.state;
    const nextIndex = Object.keys(modalList).length;

    const membersEmailArrayToString = members
      .map(m => m.email)
      .toString()
      .replace(/,/g, '; ');

    const fallbackCopyTextToClipboard = () => {
      if (this.textAreaRef.current) {
        try {
          this.textAreaRef.current.select();
          document.execCommand('copy');
          Message.success('Project members email copied');
        } catch (err) {
          console.log(err);
        }
      }
    };

    const onCopyProjectMembers = () => {
      try {
        navigator.clipboard.writeText(membersEmailArrayToString).then(() => {
          Message.success('Project members email copied');
        });
      } catch (err) {
        fallbackCopyTextToClipboard();
      }
    };

    return (
      <ListGroup>
        <textarea
          ref={this.textAreaRef}
          className="position-absolute"
          value={membersEmailArrayToString}
          readOnly></textarea>
        {members.map((member, index) => (
          <ListGroupItem key={`member-${index}`}>
            <Row>
              <Col sm={8}>
                <p className="font-weight-bold">{member.email}</p>
              </Col>
              <Col>
                <p className="font-weight-bold text-center">{member.role}</p>
              </Col>
              <Col className="d-flex justify-content-end">
                <div
                  id={`editMember-${index}`}
                  className="pointer mr-2"
                  onClick={() => this.modalListToggle(index)}>
                  <i className="fas fa-edit fs-1-2x text-info" />
                </div>
                <UncontrolledTooltip
                  boundariesElement="window"
                  fade={false}
                  target={`editMember-${index}`}
                  modifiers={{
                    computeStyle: { gpuAcceleration: false }
                  }}>
                  {t('main:edit')}
                </UncontrolledTooltip>
                <div
                  id={`deleteMember-${index}`}
                  className="mr-2"
                  onClick={() => {
                    return !!member.is_removable ||
                      member.is_removable === undefined
                      ? remove(index)
                      : null;
                  }}>
                  <i
                    className={
                      !!member.is_removable || member.is_removable === undefined
                        ? 'pointer fas fa-trash-alt fs-1-2x text-danger'
                        : 'fas fa-trash-alt fs-1-2x text-secondary'
                    }
                  />
                </div>
                <UncontrolledTooltip
                  boundariesElement="window"
                  fade={false}
                  target={`deleteMember-${index}`}
                  modifiers={{
                    computeStyle: { gpuAcceleration: false }
                  }}>
                  {member.is_removable ? t('delete') : t('disabled')}
                </UncontrolledTooltip>
              </Col>
            </Row>
            <ModalMember
              open={!!modalList[index]}
              toggle={() => this.modalListToggle(index)}
              onSubmit={this.onEditModal}
              index={index}
              member={member}
              roles={this.props.roles}
              mode="edit"
              existingMemberEmail={members.map(m => m.email)}
            />
          </ListGroupItem>
        ))}
        <ListGroupItem className="d-flex justify-content-end">
          {/* remove for now the leave project within organization */}
          {!this.props.userOrganizationId && (
            <Button
              type="button"
              color="danger"
              className="mr-2"
              onClick={() => this.leaveProjectToggle()}>
              <i className="fas fa-user-times" />
              <span className="ml-2">{t('leave_project')}</span>
            </Button>
          )}

          {isOrganizationEnabled && this.props.userOrganizationId && (
            <Button
              type="button"
              color="success"
              className="mr-2"
              disabled={disabled}
              onClick={() => this.addMemberToGroupToggle()}>
              <i className="fas fa-users" />
              <span className="ml-2">{t('project_sharing')}</span>
            </Button>
          )}
          <Button
            type="button"
            color="success"
            className="mr-2"
            onClick={() => onCopyProjectMembers()}>
            <i className="fas fa-copy" />
            <span className="ml-2">{t('copy_members')}</span>
          </Button>

          <Button
            type="button"
            color="primary"
            disabled={disabled}
            onClick={() => this.addNewToggle()}>
            <i className="fas fa-plus" />
            <span className="ml-2">{t('add_member')}</span>
          </Button>

          <ModalMember
            open={modalAddNew}
            toggle={this.addNewToggle}
            index={nextIndex}
            onSubmit={this.onAddNew}
            roles={this.props.roles}
            mode="add"
            existingMemberEmail={members.map(m => m.email)}
          />

          <ModalLeaveProject
            open={this.state.modalLeaveProject}
            toggle={this.leaveProjectToggle}
            index={nextIndex}
            onSubmit={this.onLeaveProject}
          />

          <MemberDontExistModal
            {...this.props}
            open={this.state.modalUnknownMember}
            onInvite={this.onMemberInvitation}
            onCancel={this.closeUnknwonMemberModal}
            email={this.state.unknownMember.email}
            role={this.state.unknownMember.role}
            projectId={this.props.match.params.id}
          />

          {isOrganizationEnabled && this.props.userOrganizationId && (
            <ModalAddProjectToOrg
              open={modalAddMemberToGroup}
              toggle={this.addMemberToGroupToggle}
              index={nextIndex}
              onSubmit={this.onShareProjectToOrganization}
              roles={this.props.roles}
              mode="add"
              existingMemberEmail={members.map(m => m.email)}
              fetchOrganizationRoles={this.props.fetchOrganizationRoles}
              userOrganizationId={this.props.userOrganizationId}
              relatedGroups={this.props.relatedGroups}
              // allowToShare={allowToShare}
            />
          )}
        </ListGroupItem>
      </ListGroup>
    );
  }
}
MemberList.propTypes = {
  member: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      role: PropTypes.string
    }).isRequired
  ),
  t: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    roles: state.roles,
    userOrganizationId: state.authUser.organizationId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isEmailExist: email => dispatch(isEmailExist(email)),
    inviteUser: invitedUser => dispatch(inviteUser(invitedUser)),
    fetchOrganizationRoles: () => dispatch(fetchOrganizationRoles()),
    leaveProject: (projectId, email) => dispatch(leaveProject(projectId, email))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['project', 'main'])
)(MemberList);
