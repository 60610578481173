import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link /* , withRouter */ } from 'react-router-dom';
import { dateFormatting } from '../../../../../../locales/dateFormat';
import {
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  Row,
  Dropdown,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Badge
} from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { isEqual } from 'lodash';
import { getURLParameters } from '../../../../../../utils/url';

import styles from './Style.module.css';
import ReactTable from '../../../../../Shared/ReactTable/index.jsx';
import {
  fetchProjectRulesChecked,
  fetchUserListAsAdmin
} from '../../../../../../store/actions';

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'created_at',
      order: d || 'desc'
    }
  ];
};

class ListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 5,
      totalSize: 1,
      filters: {},
      collapse: {},

      order: { dataField: 'created_at', order: 'desc' },

      projectRuleCheckedList: []
    };
  }

  componentDidMount() {
    this.fetcProjectRulesCheckedList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetcProjectRulesCheckedList();
    }
  }

  fetcProjectRulesCheckedList() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;
    this.props
      .fetchProjectRulesChecked({
        pagination: {
          from,
          to
        }
      })

      .then(data => {
        this.setState(
          {
            isLoaded: true,
            totalSize: data.total,
            projectRuleCheckedList: data.data
          },
          () => {
            // this.props.history.replace(
            //   `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
            //     order.direction
            //   }${this.filterToURLString(filters)}`
            // );
          }
        );
      });
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {}
        });
        break;
    }
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'created_at', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      filters,
      collapse,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport,
      projectRuleCheckedList
    } = this.state;

    const { users, t, toggleCreateUserModal } = this.props;

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => [
      {
        id: 'rank',
        accessor: 'rank',
        Header: '',
        headerClasses: 'd-none',
        disableFilters: true,
        disableSortBy: true,
        width: 80,
        Cell: ({ row, value, index, id }) => {
          return <span className="font-weight-bold text-light">{value}.</span>;
        }
      },
      {
        id: 'name',
        accessor: 'name',
        Header: '',
        headerClasses: 'd-none',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value, index }) => {
          return (
            <div className={styles.table_header_overflow}>
              <span className="font-weight-bold text-light">{value}</span>
            </div>
          );
        }
      },
      {
        id: 'rules_check',
        accessor: 'rules_check',
        Header: '',
        headerClasses: 'd-none',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value, index }) => {
          return (
            <Badge color="light" className="text-center font-weight-bold">
              {value}
            </Badge>
          );
        }
      }
    ];

    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      }
      return <></>;
    };

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    return (
      <>
        <div className={`px-3 ${styles.tableOverflow}`}>
          <ReactTable
            columns={reactTableColumns(t)}
            data={projectRuleCheckedList || []}
            hover
            bordered={false}
            pagination={paginationOptions}
            sortByFunc={setOrderBy}
            defaultSorted={defaultSorted()}
            noDataIndication={NoData()}
            loading={!isLoaded}
            remote={{ pagination: true, filter: true, sort: true }}
            Overlay={({ children }) => {
              return (
                <div className="position-relative">
                  {children}

                  <div className="w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center loadingDivColor">
                    <div
                      className="spinner-border text-primary loadingDivDimention"
                      role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProjectRulesChecked: ({ pagination }) =>
      dispatch(fetchProjectRulesChecked({ pagination })),
    fetchUserList: (filters, order, pagination, toDispatch) =>
      dispatch(fetchUserListAsAdmin(filters, order, pagination, toDispatch))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['statistics'])
)(ListingPresenter);
