import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

import 'react-easy-crop/react-easy-crop.css';

// create the image with a src of the base64 string
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, crop, imageFile) => {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return {
    file: await new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, imageFile.type);
    }).then(res => {
      return new File([res], imageFile.name, {
        type: imageFile.type
      });
    }),
    dataUrl: canvas.toDataURL()
  };
};

const ImageCropper = props => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      props.thumb,
      croppedAreaPixels,
      props.imageFile
    );

    props.setCroppedImage(croppedImage.dataUrl);
    props.setThumbnail(croppedImage.file);
  };

  return (
    <>
      <Cropper
        image={props.thumb}
        crop={crop}
        zoom={zoom}
        aspect={16 / 9}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        disableAutomaticStylesInjection={true}
      />
    </>
  );
};

export default ImageCropper;
