import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Badge,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Input,
  Button,
  Spinner
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { dateFormatting } from '../../../locales/dateFormat';
import Correspondence from './Correspondence';
import Message from '../../UI/Message';

import AssignedPO from '../Admin/Submission/AssignedPOList';
import SubmissionResultList from './SubmissionResultList';
import SubmissionReOpenPresenter from './SubmissionReOpenPresenter';
import ModelList from './ModelList';
import Attachment from './AttachmentList';
import styles from './Styles.module.css';

const statusToPillColor = {
  open: 'info',
  re_open: 'info',
  in_progress: 'warning',
  reviewed: 'warning',
  completed: 'success',
  closed: 'danger'
};

const StatusPill = ({ status, label }) => (
  <h4>
    <Badge color={statusToPillColor[status] || 'secondary'}>{label}</Badge>
  </h4>
);

const StatusEditComponent = ({
  statusList,
  currStatus,
  selectedStatus,
  onSubmit,
  onChange,
  isDirty,
  buttonDisabled,
  t
}) => {
  const currStatusProps = statusList.find(s => s.name === currStatus);
  const availableNextStatus = [
    ...((currStatusProps && currStatusProps.nextStatus) || [])
  ];
  return (
    <Row>
      <Col>
        <Input
          bsSize="sm"
          type="select"
          // id={id || field.name}
          className={`custom-select ${styles.selectStyle}`}
          value={selectedStatus}
          onChange={e => {
            onChange(e.target.value);
          }}>
          {statusList.map((opt, index) => {
            const isDisabled =
              availableNextStatus.length > 0 &&
              !(
                availableNextStatus.includes(opt.name) ||
                opt.name === currStatus
              );
            const isCurrent = opt.name === currStatus;
            return (
              <option
                disabled={isDisabled}
                key={`status-opt-${index}`}
                value={opt.name}
                className={classnames({
                  'bg-light': isDisabled,
                  'bg-warning': !isCurrent && !isDisabled,
                  'bg-info text-white': isCurrent
                })}>
                {t(`status_label_${opt.name}`)}
              </option>
            );
          })}
        </Input>
      </Col>
      <Col>
        {isDirty && (
          <Button
            color="success"
            disabled={buttonDisabled}
            onClick={() => onSubmit(selectedStatus)}>
            {t('save_status_changes')}
          </Button>
        )}
      </Col>
    </Row>
  );
};

class SubmissionDetailPresenter extends Component {
  constructor(props) {
    super(props);
    const activeTab =
      (props.match &&
        {
          details: 'details',
          models: 'result_files',
          documents: 'model_files'
        }[props.match.params.tab]) ||
      'details';
    this.state = {
      isLoaded: false,
      activeTab,
      isStatusDirty: false,
      selectedStatus: null,
      needFetch: false,
      statusChangeDisabled: false
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };
  componentDidMount() {
    const {
      fetchProject,
      fetchSubmissionAsQP,
      fetchSubmissionAsAdminOrPO,
      fetchCorrespondence,
      fetchProjectStage,
      userFunction,
      projectId,
      stageName,
      submissionId
    } = this.props;
    this.props.resetAttachmentFiles();
    Promise.all([
      userFunction === 'QP'
        ? fetchSubmissionAsQP(projectId, stageName, submissionId)
        : fetchSubmissionAsAdminOrPO(projectId, stageName, submissionId),
      fetchCorrespondence({ projectId, stageName, submissionId }),
      fetchProject(projectId),
      fetchProjectStage(projectId, stageName)
    ]).then(() => {
      this.setState({
        isLoaded: true,
        selectedStatus: this.props.submission.status
      });
    });
  }

  componentWillUnmount() {
    this.props.resetSubmission();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activeTab !== prevState.activeTab ||
      (this.state.needFetch && !prevState.needFetch)
    ) {
      const {
        fetchProject,
        fetchSubmissionAsQP,
        fetchSubmissionAsAdminOrPO,
        fetchCorrespondence,
        fetchProjectStage,
        userFunction,
        projectId,
        stageName,
        submissionId
      } = this.props;
      this.setState({ isLoaded: false });
      this.props.resetAttachmentFiles();
      Promise.all([
        userFunction === 'QP'
          ? fetchSubmissionAsQP(projectId, stageName, submissionId)
          : fetchSubmissionAsAdminOrPO(projectId, stageName, submissionId),
        fetchCorrespondence({ projectId, stageName, submissionId }),
        fetchProject(projectId),
        fetchProjectStage(projectId, stageName)
      ]).then(() => {
        this.setState({
          isLoaded: true,
          selectedStatus: this.props.submission.status,
          needFetch: false
        });
      });
    }

    if (
      (prevProps.projectId && this.props.projectId !== prevProps.projectId) ||
      (prevProps.stageName && this.props.stageName !== prevProps.stageName)
    ) {
      const {
        fetchProject,
        fetchSubmissionAsQP,
        fetchSubmissionAsAdminOrPO,
        fetchCorrespondence,
        fetchProjectStage,
        userFunction,
        projectId,
        stageName,
        submissionId
      } = this.props;
      this.setState({ isLoaded: false });
      this.props.resetAttachmentFiles();
      Promise.all([
        userFunction === 'QP'
          ? fetchSubmissionAsQP(projectId, stageName, submissionId)
          : fetchSubmissionAsAdminOrPO(projectId, stageName, submissionId),
        fetchCorrespondence({ projectId, stageName, submissionId }),
        fetchProject(projectId),
        fetchProjectStage(projectId, stageName)
      ]).then(() => {
        this.setState({
          isLoaded: true,
          selectedStatus: this.props.submission.status
        });
      });
    }
  }

  requestForFetch = () => {
    this.setState({ needFetch: true });
  };

  render() {
    // const { stageName } = this.props.match.params;
    const { isLoaded } = this.state;
    const {
      submissionId,
      submission,
      models,
      t,
      projectId,
      project,
      userFunction,
      submittedResults,
      stageName,
      messages,
      sidebarRef = { sidebarRef }
    } = this.props;
    // Filter the obsolete Results
    const { obsoleteSubmittedResult, currentSubmittedResult } = submittedResults
      ? submittedResults.reduce(
          (returned, currentResult) => {
            if (currentResult.is_obsolete) {
              return {
                obsoleteSubmittedResult: [
                  ...returned.obsoleteSubmittedResult,
                  currentResult
                ],
                currentSubmittedResult: returned.currentSubmittedResult
              };
            } else {
              return {
                currentSubmittedResult: [
                  ...returned.currentSubmittedResult,
                  currentResult
                ],
                obsoleteSubmittedResult: returned.obsoleteSubmittedResult
              };
            }
          },
          {
            obsoleteSubmittedResult: [],
            currentSubmittedResult: []
          }
        )
      : {
          obsoleteSubmittedResult: [],
          currentSubmittedResult: []
        };

    const currStatusProps =
      submission &&
      submission.statusList &&
      submission.statusList.find(s => s.name === submission.status);
    const isReOpenable =
      currStatusProps &&
      currStatusProps.nextStatus &&
      currStatusProps.nextStatus.includes('re_open');

    const SubmissionDetailNav = ({ vertical }) => (
      <Nav
        tabs
        vertical={vertical}
        className={classnames('shadow mt-3 rounded mb-3 bg-light', {
          'p-3': !vertical
        })}>
        <NavItem
          className={classnames({
            'border-primary rounded border-sz-3':
              this.state.activeTab === 'details',
            'border-right': this.state.activeTab === 'details' && vertical,
            'border-left': this.state.activeTab === 'details' && !vertical
          })}>
          <NavLink
            className={classnames(
              'bg-light rounded',
              {
                'active font-weight-bold': this.state.activeTab === 'details'
              },
              'borderNone'
            )}
            onClick={() => {
              this.toggle('details');
            }}>
            {t('details')}
          </NavLink>
        </NavItem>
        <NavItem
          className={classnames({
            'border-primary rounded border-sz-3':
              this.state.activeTab === 'result_files',
            'border-right': this.state.activeTab === 'result_files' && vertical,
            'border-left': this.state.activeTab === 'result_files' && !vertical
          })}>
          <NavLink
            className={classnames(
              'bg-light rounded',
              {
                'active font-weight-bold':
                  this.state.activeTab === 'result_files'
              },
              'borderNone'
            )}
            onClick={() => {
              this.toggle('result_files');
            }}>
            {t('result_files')}
          </NavLink>
        </NavItem>
        <NavItem
          className={classnames({
            'border-primary rounded border-sz-3':
              this.state.activeTab === 'model_files',
            'border-right': this.state.activeTab === 'model_files' && vertical,
            'border-left': this.state.activeTab === 'model_files' && !vertical
          })}>
          <NavLink
            className={classnames(
              'bg-light rounded',
              {
                'active font-weight-bold':
                  this.state.activeTab === 'model_files'
              },
              'borderNone'
            )}
            onClick={() => {
              this.toggle('model_files');
            }}>
            {t('model_files')}
          </NavLink>
        </NavItem>
        {isReOpenable && (
          <>
            <div className="dropdown-divider" />
            <NavItem
              className={classnames({
                'border-primary rounded border-sz-3':
                  this.state.activeTab === 're_openning',
                'border-right':
                  this.state.activeTab === 're_openning' && vertical,
                'border-left':
                  this.state.activeTab === 're_openning' && !vertical
              })}>
              <NavLink
                className={classnames(
                  'bg-light rounded',
                  {
                    'active font-weight-bold':
                      this.state.activeTab === 're_openning'
                  },
                  'borderNone'
                )}
                onClick={() => {
                  this.toggle('re_openning');
                }}>
                {t('re_openning')}
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
    );
    return (
      <>
        {isLoaded && submission && project && (
          <>
            {sidebarRef && sidebarRef.current ? (
              ReactDOM.createPortal(
                <SubmissionDetailNav vertical={true} />,
                sidebarRef.current
              )
            ) : (
              <SubmissionDetailNav vertical={false} />
            )}

            <TabContent activeTab={this.state.activeTab}>
              {this.state.activeTab === 'details' && (
                <TabPane tabId="details">
                  <div className="pt-3 pb-3">
                    <div className="shadow p-3 rounded">
                      <div className="d-flex justify-content-between">
                        <h5 className="text-primary">{t('basic_info')}</h5>
                        <div>
                          <StatusPill
                            status={submission.status}
                            label={t(`status_label_${submission.status}`)}
                          />
                        </div>
                      </div>
                      <hr />
                      <Container className="pt-3 pb-3">
                        {(submission.mode.editableField.some(
                          v => v === 'reference' || v === '*'
                        ) ||
                          submission.mode.readableField.some(
                            v => v === 'reference' || v === '*'
                          )) && (
                          <Row>
                            <Col>{t('reference')}: </Col>
                            <Col className={styles.referenceDiv}>
                              <h6>
                                {submission.reference || t('undefined_ref')}
                              </h6>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>{t('project_name')}:</Col>
                          <Col className="d-flex">
                            {userFunction === 'PO' ? (
                              <h6>{project.name}</h6>
                            ) : (
                              <Link
                                to={
                                  userFunction === 'QP'
                                    ? `/project/${project.project_id}/models/${stageName}`
                                    : `/admin/project/${project.project_id}/models/${stageName}`
                                }>
                                <h6>{project.name}</h6>
                              </Link>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{t('stage_name')}:</Col>
                          <Col>
                            <h6>{submission.stage.display_name}</h6>
                          </Col>
                        </Row>
                        {(submission.mode.editableField.some(
                          v => v === 'status' || v === '*'
                        ) ||
                          submission.mode.readableField.some(
                            v => v === 'status' || v === '*'
                          )) && (
                          <Row>
                            <Col>{t('status')}: </Col>
                            <Col>
                              {submission.mode.editableField.some(
                                v => v === 'status' || v === '*'
                              ) ? (
                                <StatusEditComponent
                                  statusList={submission.statusList}
                                  currStatus={submission.status}
                                  selectedStatus={this.state.selectedStatus}
                                  onSubmit={changedStatus => {
                                    if (changedStatus !== submission.status) {
                                      this.setState({
                                        statusChangeDisabled: true
                                      });
                                      this.props
                                        .updateSubmission(
                                          project.project_id,
                                          stageName,
                                          submission.submission_id,
                                          { status: changedStatus }
                                        )
                                        .then(() => {
                                          Message.success(t('status_changed'));
                                          this.setState({
                                            needFetch: true,
                                            isStatusDirty: false,
                                            statusChangeDisabled: false
                                          });
                                        })
                                        .catch(err => {
                                          Message.error(
                                            t(`error:${err.message}`)
                                          );
                                        });
                                    }
                                  }}
                                  onChange={value => {
                                    this.setState({
                                      isStatusDirty:
                                        value !== submission.status,
                                      selectedStatus: value
                                    });
                                  }}
                                  isDirty={this.state.isStatusDirty}
                                  buttonDisabled={
                                    this.state.statusChangeDisabled
                                  }
                                  t={t}
                                />
                              ) : (
                                <h6>
                                  {t(`status_label_${submission.status}`)}
                                </h6>
                              )}
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>{t('date_created')}: </Col>
                          <Col>
                            <h6>
                              {dateFormatting(
                                submission.date_created,
                                'defaultHour'
                              )}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>{t('created_by')}: </Col>
                          <Col className="text-break">
                            <h6>{`${submission.created_by.first_name} ${submission.created_by.last_name}`}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>{t('email')}: </Col>
                          <Col>
                            <h6>{submission.created_by.email}</h6>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/* OFFICER ASSIGNATION */}
                    {(submission.mode.editableField.some(
                      v => v === 'officer' || v === '*'
                    ) ||
                      submission.mode.readableField.some(
                        v => v === 'officer' || v === '*'
                      )) && (
                      <Row>
                        <Col>
                          <div className="mt-4">
                            {(submission.mode.editableField.some(
                              v => v === 'officer' || v === '*'
                            ) ||
                              submission.mode.readableField.some(
                                v => v === 'officer' || v === '*'
                              )) && (
                              <AssignedPO
                                {...this.props}
                                editable={submission.mode.editableField.some(
                                  v => v === 'officer' || v === '*'
                                )}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {(submission.mode.editableField.some(
                      v => v === 'attachments' || v === '*'
                    ) ||
                      submission.mode.readableField.some(
                        v => v === 'attachments' || v === '*'
                      )) && (
                      <div id="attachments-section">
                        {submission.mode.editableField.some(
                          v => v === 'attachments' || v === '*'
                        ) ? (
                          <Row>
                            <Col>
                              <div className="shadow p-3 mt-4 rounded">
                                <Attachment
                                  {...this.props}
                                  action="update_attachments"
                                  isFormikForm={false}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <div className="shadow p-3 mt-4 rounded">
                                <h5 className="text-primary">
                                  {t('attachments')}
                                </h5>
                                <hr />
                                {submission.list_of_file_attachments.length >
                                0 ? (
                                  <ListGroup>
                                    11
                                    {submission.list_of_file_attachments.map(
                                      (attachment, key) => {
                                        return (
                                          <ListGroupItem
                                            key={key}
                                            className="border-0">
                                            <Row>
                                              <Col sm={11}>
                                                {attachment.file_name}
                                              </Col>
                                              <Col sm={1}>
                                                <a
                                                  download
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                  href={`${process.env.REACT_APP_API_ENDPOINT}/${attachment.download_link}`}>
                                                  <span
                                                    id={`attachment-${key}`}>
                                                    <i className="fas fa-download text-primary" />
                                                  </span>
                                                </a>
                                                <UncontrolledTooltip
                                                  fade={false}
                                                  placement="auto"
                                                  target={`attachment-${key}`}>
                                                  {t('download')}
                                                </UncontrolledTooltip>
                                              </Col>
                                            </Row>
                                          </ListGroupItem>
                                        );
                                      }
                                    )}
                                  </ListGroup>
                                ) : (
                                  // NO attachments
                                  <h6 className="text-secondary">
                                    {t('no_attachment')}
                                  </h6>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                    {(submission.mode.editableField.some(
                      v => v === 'correspondence' || v === '*'
                    ) ||
                      submission.mode.readableField.some(
                        v => v === 'correspondence' || v === '*'
                      )) && (
                      <div
                        id="correspondence-section"
                        className="mt-4 shadow p-3 mb-5 rounded">
                        <h5 className="text-primary pb-3">
                          {t('correspondence')}
                        </h5>
                        <Correspondence
                          {...this.props}
                          writeMode={submission.mode.editableField.some(
                            v => v === 'correspondence' || v === '*'
                          )}
                        />
                      </div>
                    )}
                  </div>
                </TabPane>
              )}

              {this.state.activeTab === 'result_files' && (
                <TabPane tabId="result_files">
                  <div className="shadow p-3 mt-3 mb-5 rounded">
                    <h5 className="text-primary mb-3">{t('results_title')}</h5>
                    <SubmissionResultList
                      projectName={project.name}
                      projectId={projectId}
                      stageName={stageName}
                      data={currentSubmittedResult}
                    />
                  </div>
                  {obsoleteSubmittedResult.length > 0 && (
                    <div className="shadow p-3 mt-3 mb-5 rounded">
                      <h5 className="text-primary mb-3">
                        {t('ARCHIVE_RESULT_FILE')}
                      </h5>
                      <SubmissionResultList
                        projectName={project.name}
                        projectId={projectId}
                        stageName={stageName}
                        data={obsoleteSubmittedResult}
                      />
                    </div>
                  )}
                </TabPane>
              )}
              {this.state.activeTab === 'model_files' && (
                <TabPane tabId="model_files">
                  <div className="shadow p-3 mt-3 mb-5 rounded">
                    <h5 className="text-primary mb-3">{t('model_name')}</h5>
                    <ModelList models={models} translate={t} />
                  </div>
                </TabPane>
              )}

              {this.state.activeTab === 're_openning' && (
                <TabPane tabId="re_openning">
                  <SubmissionReOpenPresenter
                    submissionId={submissionId}
                    messages={messages}
                    submission={submission}
                    userFunction={userFunction}
                    stageName={stageName}
                    projectId={projectId}
                    project={project}
                    afterSubmit={() => {
                      this.requestForFetch();
                      this.setState({ activeTab: 'details' });
                    }}
                  />
                </TabPane>
              )}
            </TabContent>
          </>
        )}
        {!isLoaded && (
          <div
            className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
            <Spinner
              type="grow"
              className={styles.spinnerDimention}
              color="primary"
            />
          </div>
        )}
      </>
    );
  }
}

export default compose(
  withTranslation(['submission']),
  withRouter
)(SubmissionDetailPresenter);
