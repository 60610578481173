import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import SubscriptionPresenter from './SubscriptionPresenter.jsx';

import {
  getProductsAction,
  fecthUserActiveSubscription
} from '../../../store/actions';

const mapStateToProps = state => {
  return {
    authUser: state.authUser,
    subscription: state.subscription
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCurrentSubscription: () => dispatch(fecthUserActiveSubscription()),
    getProducts: filters => dispatch(getProductsAction(filters))
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['subscription', 'error'])
)(SubscriptionPresenter);
