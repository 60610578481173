import React, { Component } from 'react';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import { timeAgoFormatting } from '../../../locales/dateFormat';

import Message from '../../UI/Message';

import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import styles from './Styles.module.css';

import CustomInput from '../../UI/Input/customInput';

import CustomSelectInput from '../../UI/Input/customSelectInput';

const typeList = [
  {
    display_key: 'floor_plan',
    internal_value: 'floor plan'
  },
  {
    display_key: 'section_plan',
    internal_value: 'section plan'
  }
];

class Model2DList extends Component {
  constructor(props) {
    super(props);
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false,
      modelCount: 0
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteAllModels = async () => {
    const { models2D, remove2DModelFile } = this.props;
    await models2D.map(model => {
      remove2DModelFile(model);
    });
  };

  downloadAllModels = async () => {
    const { projectId, stageName } = this.props;

    const dLink = `${
      process.env.REACT_APP_API_ENDPOINT[
        process.env.REACT_APP_API_ENDPOINT.length - 1
      ] == '/'
        ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
        : process.env.REACT_APP_API_ENDPOINT
    }/api/project/${projectId}/${stageName}/2D/download/all`;

    window.open(dLink, '_blank');
  };

  render() {
    const {
      t,
      disabled,
      models,
      projectId,
      stageName,
      formikProps,
      arrayHelpers,
      model2DFileAction
    } = this.props;
    const models2d = models;
    const isDownloadAllAvailable =
      models2d.filter(info => info.downloadLink).length > 0;

    const margin = models.length > 3 ? 'mr-4' : 'mr-2';

    return (
      <>
        <Row>
          <Col xs={12} sm={12} className={'order-first'}>
            <ListGroup className="border-bottom">
              {/* // header */}
              <ListGroupItem
                className={`font-weight-bold shadow-sm text-primary ${styles.zIndex2DModelList}`}>
                <Row className="d-flex justify-content-between px-2">
                  <div className="d-flex flex-column justify-content-between">
                    <div> {t('project:model_2D_list')}</div>
                  </div>

                  {isDownloadAllAvailable && (
                    <div
                      className={`d-flex justify-content-end flex-grow-1 ${margin}`}>
                      <div>
                        <>
                          <Button
                            color="link"
                            id={'downloadModel-2D'}
                            className="pointer mr-2 p-0"
                            onClick={this.downloadAllModels}>
                            <i
                              className={`fas fa-download fs-1-2x  text-primary
                              `}
                            />
                          </Button>

                          {this._isMounted && (
                            <UncontrolledTooltip
                              fade={false}
                              boundariesElement="window"
                              target={'downloadModel-2D'}>
                              {t('main:download_all')}
                            </UncontrolledTooltip>
                          )}
                        </>
                      </div>
                      <>
                        <div>
                          <Button
                            color="link"
                            disabled={disabled}
                            id={'deleteModel-2D'}
                            className="pointer mr-2 p-0"
                            onClick={this.deleteAllModels}>
                            <i
                              className={`fas fa-trash-alt fs-1-2x ${
                                disabled ? 'text-muted' : 'text-danger'
                              }`}
                            />
                          </Button>

                          {this._isMounted && (
                            <UncontrolledTooltip
                              fade={false}
                              boundariesElement="window"
                              target={'deleteModel-2D'}>
                              {t('main:delete_all')}
                            </UncontrolledTooltip>
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </Row>
              </ListGroupItem>
              <div className={styles.overFlow2DModelList}>
                {models2d.map((f, index) => {
                  return (
                    <ListGroupItem key={f.name} className="shadow-sm">
                      <Row className="d-flex justify-content-between px-2">
                        <Col
                          className="d-flex flex-column justify-content-between px-0"
                          lg="6">
                          <div>
                            {f.name} - {prettyBytes(f.size || 0)}
                          </div>
                          {f.date && (
                            <div className="text-secondary">
                              last updated: {timeAgoFormatting(f.date)} ago
                            </div>
                          )}
                        </Col>
                        <Col
                          className="d-flex justify-content-end flex-grow-1"
                          lg="4">
                          <div className="">
                            <Field
                              name={`models2d.${index}.plan_type`}
                              type="select"
                              size="md"
                              placeholder={t('select_type')}
                              component={CustomSelectInput}
                              options={typeList.map(type => ({
                                value: type.internal_value,
                                label: t(`project:${type.display_key}`)
                              }))}
                              onChange={async value => {
                                await formikProps.setFieldValue(
                                  `models2d.${index}.plan_type`,
                                  value
                                );
                                await formikProps.setFieldTouched(
                                  `models2d.${index}.plan_type`,
                                  true
                                );

                                const updateFloorPlanType =
                                  (model2DFileAction &&
                                    model2DFileAction.setPlanType) ||
                                  this.props.setPlanType;
                                updateFloorPlanType(f.name, value);
                              }}
                              value={f.plan_type}
                            />
                          </div>
                        </Col>

                        <Col
                          className="d-flex justify-content-end flex-grow-1"
                          lg="2">
                          <div>
                            {f.downloadLink && (
                              <>
                                <a
                                  download
                                  id={`downloadModel-${index}`}
                                  onClick={e => e.stopPropagation()}
                                  className="pointer mr-2"
                                  href={`${process.env.REACT_APP_API_ENDPOINT}/${f.downloadLink}`}>
                                  <span>
                                    <i className="fas fa-download fs-1-2x text-primary" />
                                  </span>
                                </a>
                                {this._isMounted && (
                                  <UncontrolledTooltip
                                    fade={false}
                                    boundariesElement="window"
                                    target={`downloadModel-${index}`}>
                                    {t('main:download')}
                                  </UncontrolledTooltip>
                                )}
                              </>
                            )}
                          </div>
                          {!disabled && (
                            <>
                              {disabled ? null : (
                                <div>
                                  <a
                                    id={`delete2DModel-${index}`}
                                    className="pointer mr-1"
                                    onClick={async () => {
                                      if (!disabled) {
                                        await this.props.remove2DModelFile(f);

                                        if (this.props.asFormikField) {
                                          this.props.remove(index);
                                        }
                                      }
                                    }}>
                                    <i className="fas fa-trash-alt fs-1-2x text-danger" />
                                  </a>
                                  {this._isMounted && (
                                    <UncontrolledTooltip
                                      fade={false}
                                      boundariesElement="window"
                                      target={`delete2DModel-${index}`}>
                                      {t('delete')}
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })}
              </div>
            </ListGroup>
          </Col>
        </Row>
        {/* Confirmation modal for the file replacement */}
        <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
          <ModalHeader> {t('project:want_to_replace_model')} </ModalHeader>
          <ModalBody>
            {this.state.fileToConfirm.file
              ? this.state.fileToConfirm.file.name
              : ''}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.acceptFileReplace}>
              {t('main:yes')}
            </Button>
            <Button type="button" color="secondary" onClick={this.closeModal}>
              {t('main:no')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

// ModelList.propTypes = {

// }

export default withTranslation(['project', 'main'])(Model2DList);
// export default ModelList;
