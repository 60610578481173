import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, ListGroupItem, Row, Col, Input, Button } from 'reactstrap';
import { dateFormatting } from '../../../../locales/dateFormat';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';
import CustomSelectInput from '../../../UI/Input/customSelectInput';
import styles from './User.module.css';

const userFunctionOptions = translate => [
  { label: translate('qualified_person'), value: translate('qp') },
  { label: translate('processing_officer'), value: translate('po') },
  { label: translate('rule_manager'), value: translate('rm') },
  { label: translate('administrator'), value: translate('admin') }
];

const userStatusSwitches = (values, translate) => {
  return [
    {
      name: 'is_confirmed',
      id: 'isConfirmedSwitch',
      label: translate('confirmed'),
      disabled: true,
      checked: values.is_confirmed
    },
    {
      name: 'is_invited',
      id: 'isInvitedSwitch',
      label: translate('invited'),
      disabled: true,
      checked: values.is_invited
    },
    {
      name: 'is_locked',
      id: 'isLockedSwitch',
      label: translate('locked'),
      disabled: false,
      checked: values.is_locked
    },
    {
      name: 'is_verified',
      id: 'isVerifiedSwitch',
      label: translate('verified'),
      disabled: false,
      checked: values.is_verified
    },
    {
      name: 'is_active',
      id: 'isActiveSwitch',
      label: translate('active'),
      disabled: false,
      checked: values.is_active
    }
  ];
};

const formikConfig = props => {
  return {
    initialValues: {
      function: props.userData.function,
      first_name: props.userData.first_name || '',
      last_name: props.userData.last_name || '',
      company: props.userData.company || '',
      email: props.userData.email,
      is_confirmed: props.userData.is_confirmed || false, //read-only
      is_invited: props.userData.is_invited || false, //read-only
      is_locked: props.userData.is_locked || false,
      is_verified: props.userData.is_verified || false,
      is_active: props.userData.is_active || false,
      ad_username: props.userData.ad_username
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .required('Required')
        .matches(/^[a-zA-Z ]*$/, 'Numbers/Special Characters are not allowed'),

      last_name: Yup.string()
        .trim()
        .required('Required')
        .matches(/^[a-zA-Z ]*$/, 'Numbers/Special Characters are not allowed'),

      company: Yup.string().trim().required('Required')
      // userFunction: Yup.string().required('Required')
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      props
        .editSelectedUserAsAdmin(props.userData.user_id, values)
        .then(res => {
          if (res === 'success') Message.success('Saved');
          setSubmitting(false);
        })
        .catch(err => {
          Message.error(props.t(`error:${err.message}`));
        });
    }
  };
};

class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldEdited: null
    };
  }

  displayUserFunction = (type, translate) => {
    switch (type) {
      case 'QP':
        return translate('qualified_person');
      case 'PO':
        return translate('processing_officer');
      case 'ADMIN':
        return translate('administrator');
      case 'RM':
        return translate('rule_manager');
    }
  };

  render() {
    const { userData, t, authenticationMethod } = this.props;
    const { fieldEdited } = this.state;
    const editName = fieldEdited === 'name';
    const editEmail = fieldEdited === 'email';
    const editCompany = fieldEdited === 'company';
    const editStatus = fieldEdited === 'status';
    const userFunction = fieldEdited === 'userFunction';
    const editADUsername = fieldEdited === 'ad_username';

    return (
      <div>
        <Formik {...formikConfig(this.props)}>
          {formikProps => {
            const { values, setFieldValue } = formikProps;
            const handleEditBasicInfo = fieldname => () => {
              formikProps.resetForm({
                values: {
                  function: userData.function,
                  first_name: userData.first_name || '',
                  last_name: userData.last_name || '',
                  company: userData.company || '',
                  email: userData.email,
                  is_confirmed: userData.is_confirmed || false, //read-only
                  is_invited: userData.is_invited || false, //read-only
                  is_locked: userData.is_locked || false,
                  is_verified: userData.is_verified || false,
                  is_active: userData.is_active || false,
                  ad_username: userData.ad_username
                }
              });

              this.setState({
                fieldEdited: fieldname
              });
            };

            return (
              <Form>
                <ListGroupItem className={userFunction ? 'bg-light' : ''}>
                  <Row>
                    <Col>
                      {userFunction ? (
                        <>
                          <Row>
                            <Col>
                              <Label
                                for="userFunction"
                                className="font-weight-bold">
                                {t('user_function')}
                              </Label>
                              <Field
                                type="select"
                                name="function"
                                options={[
                                  ...userFunctionOptions(t).map(userFunc => ({
                                    value: userFunc.value,
                                    label: userFunc.label
                                  }))
                                ]}
                                component={CustomSelectInput}
                              />
                            </Col>
                          </Row>
                          <Row className=" mt-2 ">
                            <Col className="d-flex align-items-end justify-content-end">
                              <Button
                                color="success"
                                size="sm"
                                type="button"
                                onClick={e => {
                                  formikProps.handleSubmit(e);
                                }}>
                                {t('save')}
                              </Button>
                              <Button
                                color="secondary"
                                size="sm"
                                type="button"
                                onClick={handleEditBasicInfo('')}>
                                {t('cancel')}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div>
                          <strong>{t('user_function')}: </strong>
                          {this.displayUserFunction(values.function, t)}
                        </div>
                      )}
                    </Col>
                    {!userFunction && (
                      <Col className="d-flex justify-content-end">
                        <Button
                          outline
                          color="secondary"
                          onClick={handleEditBasicInfo('userFunction')}>
                          <i className="fas fa-pencil-alt" />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </ListGroupItem>
                <ListGroupItem className={`${editName ? 'bg-light' : ''}`}>
                  {editName ? (
                    <>
                      <Row>
                        <Col>
                          <Field
                            name="first_name"
                            type="text"
                            label={t('fName')}
                            placeholder={t('fName')}
                            component={CustomInput}
                          />
                        </Col>
                        <Col>
                          <Field
                            name="last_name"
                            type="text"
                            label={t('lName')}
                            placeholder={t('lName')}
                            component={CustomInput}
                          />
                        </Col>
                      </Row>
                      <Row className=" mt-2 ">
                        <Col className="d-flex align-items-end justify-content-end">
                          <Button
                            color="success"
                            size="sm"
                            type="button"
                            onClick={e => {
                              formikProps.handleSubmit(e);
                            }}>
                            {t('save')}
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleEditBasicInfo('')}
                            size="sm">
                            {t('cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div className="d-flex">
                      <div
                        className={`flex-grow-1 ${styles.wordwrapBreakword}`}>
                        <strong>{t('name')}: </strong>
                        {values.first_name} {values.last_name}
                      </div>
                      <div className="ml-2">
                        <Button
                          outline
                          color="secondary"
                          type="button"
                          onClick={handleEditBasicInfo('name')}>
                          <i className="fas fa-pencil-alt" />
                        </Button>
                      </div>
                    </div>
                  )}
                </ListGroupItem>
                <ListGroupItem className={editCompany ? 'bg-light' : ''}>
                  <Row>
                    <Col>
                      {editCompany ? (
                        <>
                          <Row>
                            <Col>
                              <Field
                                name="company"
                                type="text"
                                label={t('company')}
                                placeholder={t('company')}
                                component={CustomInput}
                              />
                            </Col>
                          </Row>
                          <Row className=" mt-2 ">
                            <Col className="d-flex align-items-end justify-content-end">
                              <Button
                                color="success"
                                size="sm"
                                type="button"
                                onClick={e => {
                                  formikProps.handleSubmit(e);
                                }}>
                                {t('save')}
                              </Button>
                              <Button
                                color="secondary"
                                onClick={handleEditBasicInfo('')}
                                size="sm">
                                {t('cancel')}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div>
                          <strong>{t('company')}: </strong>
                          {values.company}
                        </div>
                      )}
                    </Col>
                    {!editCompany && (
                      <Col className="d-flex justify-content-end">
                        <Button
                          outline
                          color="secondary"
                          onClick={handleEditBasicInfo('company')}>
                          <i className={'fas fa-pencil-alt'} />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </ListGroupItem>
                {authenticationMethod === 'active_directory' && (
                  <ListGroupItem className={editADUsername ? 'bg-light' : ''}>
                    <Row>
                      <Col>
                        {editADUsername ? (
                          <>
                            <Row>
                              <Col>
                                <Field
                                  name="ad_username"
                                  type="text"
                                  label={t('ad_username')}
                                  placeholder={t('ad_username')}
                                  component={CustomInput}
                                />
                              </Col>
                            </Row>
                            <Row className=" mt-2 ">
                              <Col className="d-flex align-items-end justify-content-end">
                                <Button
                                  color="success"
                                  size="sm"
                                  type="button"
                                  onClick={e => {
                                    formikProps.handleSubmit(e);
                                  }}>
                                  {t('save')}
                                </Button>
                                <Button
                                  color="secondary"
                                  onClick={handleEditBasicInfo('')}
                                  size="sm">
                                  {t('cancel')}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <div>
                            <strong>{t('ad_username')}: </strong>
                            {values.ad_username}
                          </div>
                        )}
                      </Col>
                      {!editADUsername && (
                        <Col className="d-flex justify-content-end">
                          <Button
                            outline
                            color="secondary"
                            onClick={handleEditBasicInfo('ad_username')}>
                            <i className={'fas fa-pencil-alt'} />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </ListGroupItem>
                )}
                <ListGroupItem className={editEmail ? 'bg-light' : ''}>
                  <Row>
                    <Col>
                      {editEmail ? (
                        <>
                          <Row>
                            <Col>
                              <Field
                                name="email"
                                type="text"
                                label={t('email')}
                                placeholder={t('email')}
                                component={CustomInput}
                              />
                            </Col>
                          </Row>
                          <Row className=" mt-2 ">
                            <Col className="d-flex align-items-end justify-content-end">
                              <Button color="success" size="sm">
                                {t('save')}
                              </Button>
                              <Button
                                color="secondary"
                                onClick={handleEditBasicInfo('')}
                                size="sm">
                                {t('cancel')}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div>
                          <strong>{t('email')}: </strong>
                          {userData.email}
                        </div>
                      )}
                    </Col>
                    {!editEmail && (
                      <Col className="d-flex justify-content-end">
                        <Button
                          outline
                          color="secondary"
                          onClick={handleEditBasicInfo('email')}
                          disabled>
                          <i className={'fas fa-pencil-alt'} />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </ListGroupItem>
                <ListGroupItem className={editStatus ? 'bg-light' : ''}>
                  <Row>
                    <Col>
                      {editStatus ? (
                        <>
                          <Row>
                            <Col>
                              {userStatusSwitches(values, t).map(
                                (userStatus, key) => {
                                  return (
                                    <div
                                      key={`user-status-${key}`}
                                      className="custom-control custom-switch ml-5">
                                      <input
                                        name={userStatus.name}
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={userStatus.id}
                                        checked={userStatus.checked}
                                        onChange={() => {
                                          setFieldValue(
                                            userStatus.name,
                                            !userStatus.checked
                                          );
                                          return formikProps.handleChange;
                                        }}
                                        disabled={userStatus.disabled}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={userStatus.id}>
                                        {userStatus.label}{' '}
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </Col>
                          </Row>
                          <Row className=" mt-2 ">
                            <Col className="d-flex align-items-end justify-content-end">
                              <Button
                                color="success"
                                size="sm"
                                type="button"
                                onClick={e => {
                                  formikProps.handleSubmit(e);
                                }}>
                                {t('save')}
                              </Button>
                              <Button
                                color="secondary"
                                onClick={handleEditBasicInfo('')}
                                size="sm">
                                {t('cancel')}
                              </Button>{' '}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row>
                          <Col sm="10">
                            <strong className="mr-3">
                              {t('account_status')}:{' '}
                            </strong>
                            {userStatusSwitches(values, t).map(
                              (userStatus, key) => {
                                return (
                                  <span
                                    className={`mr-5 mb-1 ${styles.iconSize}`}
                                    key={key}>
                                    {userStatus.checked ? (
                                      <i className="fas fa-check-circle text-success text-success" />
                                    ) : (
                                      <i className="fas fa-times-circle text-danger" />
                                    )}{' '}
                                    {userStatus.label}
                                  </span>
                                );
                              }
                            )}
                          </Col>
                          <Col sm="2" className="d-flex justify-content-end">
                            {!editStatus && (
                              <span>
                                <Button
                                  outline
                                  color="secondary"
                                  onClick={handleEditBasicInfo('status')}>
                                  <i className={'fas fa-pencil-alt'} />
                                </Button>
                              </span>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Label>
                    <strong>{t('creation_date')}: </strong>
                    {dateFormatting(userData.created_at)}
                  </Label>
                </ListGroupItem>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default withTranslation(['admin_user'])(BasicInfo);
