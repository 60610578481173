import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(stripeAPIKey);

const WithStripe = WrappedComponent => props => {
  return (
    <Elements stripe={stripePromise}>
      <WrappedComponent {...props} />
    </Elements>
  );
};

export default WithStripe;
