import { performRequest } from '../requests';
import {
  notificationsActionType,
  projectActionType,
  appActionType
} from '../../constants/actionType';

const {
  FETCH_NOTIFICATIONS,
  READ_NOTIFICATION,
  DISMISS_NOTIFICATION,
  FETCH_ALL_NOTIFICATIONS,
  RESET_NOTIFICATION
} = notificationsActionType;
const { ADD_PROJECT_NOTIFICATIONS } = projectActionType;
const {
  SET_PROJECT_NOTIFICATION_COUNT,
  SET_LIST_NOTIFICATION_COUNT
} = appActionType;

export const resetNotification = () => dispatch => {
  dispatch({
    type: RESET_NOTIFICATION
  });
};

export const fetchNotificationsForProjects = ({
  projectId,
  offset,
  limit,
  reset = false
}) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: { entityType: 'project', entityId: projectId, offset, limit }
  };
  return dispatch(
    performRequest(
      'get',
      'api/user/notifications/list',
      {},
      requestConfig,
      `FETCH_PROJECT_NOTIFICATIONS_${projectId}`,
      true,
      true
    )
  )
    .then(res => res.data)
    .then(({ notificationList, unreadCount }) => {
      if (reset) {
        dispatch({
          type: RESET_NOTIFICATION
        });
      }
      dispatch({
        type: ADD_PROJECT_NOTIFICATIONS,
        projectId,
        notificationList
      });
      return unreadCount;
    })
    .catch(err => {
      if (!err.name || !err.name === 'request_canceled') {
        throw err;
      }
    });
};

export const fetchLimitedNotifications = (
  offset,
  limit,
  reset = false
) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    params: {
      offset,
      limit
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/user/notifications/list',
      {},
      requestConfig,
      'FETCH_ALL_NOTIFICATIONS',
      true,
      false
    )
  )
    .then(res => res.data)
    .then(({ notificationList, unreadCount }) => {
      if (reset) {
        dispatch({
          type: RESET_NOTIFICATION
        });
      }
      dispatch({
        type: FETCH_ALL_NOTIFICATIONS,
        notificationList
      });
      return unreadCount;
    })
    .catch(err => {
      if (!err.name || !err.name === 'request_canceled') {
        throw err;
      }
    });
};

export const fetchAllNotifications = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/user/notifications/list',
      {},
      requestConfig,
      'FETCH_ALL_NOTIFICATIONS',
      true,
      true
    )
  )
    .then(res => res.data)
    .then(notificationList => {
      dispatch({
        type: RESET_NOTIFICATION
      });
      dispatch({
        type: FETCH_ALL_NOTIFICATIONS,
        notificationList
      });
    })
    .catch(err => {
      if (!err.name || !err.name === 'request_canceled') {
        throw err;
      }
    });
};

export const readNotification = notificationId => (dispatch, getState) => {
  const notificationIndex = getState().notifications.findIndex(n => {
    return n.notification_id === notificationId;
  });
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      `api/user/notifications/${notificationId}/read`,
      {},
      requestConfig,
      `READ_NOTIFICATION_${notificationId}`,
      true
    )
  ).then(() => {
    dispatch({
      type: READ_NOTIFICATION,
      notificationIndex
    });
  });
};

export const dismissNotification = notificationId => (dispatch, getState) => {
  const notificationIndex = getState().notifications.findIndex(n => {
    return n.notification_id === notificationId;
  });
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch({
    type: DISMISS_NOTIFICATION,
    notificationIndex
  });
  return dispatch(
    performRequest(
      'put',
      `api/user/notifications/${notificationId}/dismiss`,
      {},
      requestConfig,
      `REMOVE_NOTIFICATION_${notificationId}`,
      true
    )
  ).then(res => {
    return { is_dismissed: true };
  });
};

export const clearAllNotification = () => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      'api/user/notifications/dismissAll',
      {},
      requestConfig,
      'DISMISS_ALL_NOTIFICATION',
      true
    )
  );
};

export const readAllNotification = () => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      'api/user/notifications/readAll',
      {},
      requestConfig,
      'MARK_ALL_AS_READ',
      true
    )
  );
};

export const fetchNotificationCount = ({ projectId }) => (
  dispatch,
  getState
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
    params: { entityType: 'project', entityId: projectId }
  };
  return dispatch(
    performRequest(
      'get',
      'api/user/notifications/fetchNotificationCount',
      {},
      requestConfig,
      'FETCH_NOTIFICATION_COUNT'
    )
  )
    .then(res => res.data)
    .then(count => {
      if (projectId) {
        dispatch({
          type: SET_PROJECT_NOTIFICATION_COUNT,
          count
        });
      } else {
        dispatch({
          type: SET_LIST_NOTIFICATION_COUNT,
          count
        });
      }
    });
};
