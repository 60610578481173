import React, { Component, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Alert,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  Progress,
  Spinner,
  UncontrolledCollapse
} from 'reactstrap';
import { convertFromRaw } from 'draft-js';
import { Link } from 'react-router-dom';
import filter from 'lodash/filter';
// import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { getUserTasks } from '../../../store/actions';
import { dateFormatting } from '../../../locales/dateFormat';
import DisplayMessage from '../Submission/Correspondence/DisplayMessage';
import styles from './taskManagement.module.css';

const PAGE_SIZE = 10;

const UserTaskList = props => {
  const { tasks, t } = props;
  return (
    <Container className="mt-4">
      <div className="shadow p-3 mb-5 rounded">
        <div className="my-2">
          <h3 className="text-primary">
            {t('task_management:task_management')}
          </h3>
        </div>
        <ListGroup className="w-100 p-2">
          {tasks.length > 0 &&
            tasks.map(task => {
              const actionsCompleted = task.actions.filter(
                a => a && a.status && a.status === 'COMPLETED'
              ).length;
              let messageContent;
              try {
                messageContent = convertFromRaw(
                  JSON.parse(decodeURI(task.message))
                );
              } catch (err) {
                console.log(err);
              }
              return (
                <ListGroupItem key={`task-item-${task.task_id}`}>
                  <Row>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <Col>
                          <Link
                            className={styles.wordWrapBreakWord}
                            to={`/project/${task.project_related}/task/detail/${task.task_id}`}>
                            {task.topic}
                            <i className="ml-1 fas fa-external-link-alt"></i>
                          </Link>
                        </Col>
                        <Col className=" d-flex justify-content-end">
                          <div className="flex-grow-1 ml-2 pl-2 ">
                            <span className="font-italic">
                              {t('task_management:project')}:{' '}
                            </span>
                            {task.project && task.project.name}
                          </div>

                          <div className="text-muted mr-2">
                            <span className="font-italic">
                              {t('task_management:date_created')}:{' '}
                            </span>
                            {dateFormatting(task.created_date, 'defaultHour')}
                          </div>
                        </Col>

                        <Button
                          color="link"
                          id={`toggler-${task.task_id}`}
                          to={`/project/${task.project_related}/task/detail/${task.task_id}`}>
                          <i className="fas fa-chevron-down"></i>
                        </Button>
                      </div>
                      <UncontrolledCollapse toggler={`toggler-${task.task_id}`}>
                        {messageContent && (
                          <div
                            className={`p-1 rounded ${styles.displayMessageContainer}`}>
                            <DisplayMessage
                              stageName={''}
                              projectId={
                                task.project && task.project.project_id
                              }
                              content={messageContent}
                            />
                          </div>
                        )}
                      </UncontrolledCollapse>
                    </div>
                  </Row>
                  <Row>
                    <Progress
                      className="mt-2 w-100"
                      color={
                        actionsCompleted === task.actions.length
                          ? 'success'
                          : 'info'
                      }
                      value={(actionsCompleted / task.actions.length) * 100}>
                      {actionsCompleted} /{task.actions.length}{' '}
                      {t('task_management:actions')}
                    </Progress>
                  </Row>
                </ListGroupItem>
              );
            })}
          {tasks.length === 0 && (
            <Alert color="info"> {t('task_management:no_task')}</Alert>
          )}
        </ListGroup>
      </div>
    </Container>
  );
};

const UserTaskListContainer = props => {
  const { getUserTasks } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);
  const [offset, setOffset] = useState(0);
  const [reachedEnd, setReachEnd] = useState(false);

  useEffect(() => {
    if (!reachedEnd) {
      setIsLoaded(false);
      getUserTasks(offset)
        .then(resultLength => {
          if (resultLength < PAGE_SIZE) {
            setReachEnd(true);
          }
          setIsLoaded(true);
        })
        .catch(err => {
          setOnError(true);
        });
    }
  }, [offset]);

  const onScroll = e => {
    if (!reachedEnd && isLoaded) {
      const scrollContainerHeight = e.target.getBoundingClientRect().height;
      const scrollPosition = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      const offsetBottom = 200;
      if (
        scrollPosition + scrollContainerHeight + offsetBottom >
        scrollHeight
      ) {
        debounceSetOffset(offset + PAGE_SIZE);
      }
    }
  };

  const debounceSetOffset = debounce(setOffset, 1000, {
    leading: true,
    trailing: false
  });

  document
    .getElementById('scroll-container')
    .addEventListener('scroll', onScroll);

  return (
    <>
      {!onError && <UserTaskList {...props} />}{' '}
      {!isLoaded && !onError && (
        <div
          className={`d-flex align-items-center justify-content-center ${styles.userTaskListSpinnerContainer}`}>
          <Spinner
            type="grow"
            className={styles.userTaskListSpinner}
            color="primary"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const tasks = filter(
    state.tasks,
    task => task.creator.user_id === state.authUser.userId
  );
  tasks.sort((taskA, taskB) => taskB.created_date - taskA.created_date);
  return {
    tasks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserTasks: offset => dispatch(getUserTasks(offset))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['main', 'task_management'])
)(UserTaskListContainer);
