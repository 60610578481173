import React, { Component } from 'react';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import styles from './Styles.module.css';

import { timeAgoFormatting } from '../../../locales/dateFormat';
import classNames from 'classnames';

class ModelList extends Component {
  constructor(props) {
    super(props);
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteAllModels = async () => {
    const { models, removeModelFile } = this.props;
    await models.map(model => {
      removeModelFile(model);
    });
  };

  downloadAllModels = async () => {
    const { projectId, stageName } = this.props;

    const dLink = `${
      process.env.REACT_APP_API_ENDPOINT[
        process.env.REACT_APP_API_ENDPOINT.length - 1
      ] == '/'
        ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
        : process.env.REACT_APP_API_ENDPOINT
    }/api/project/${projectId}/${stageName}/3D/download/all`;

    window.open(dLink, '_blank');
  };

  render() {
    const {
      t,
      disabled,
      fromCreateProjectPage,
      models,
      projectId,
      stageName,
      isUploadingFile,
      removeModelFile,
      asFormikField,
      remove
    } = this.props;
    const { isDisabled } = this.state;
    const models3d = models.filter(m => !m.removed);
    const isDownloadAllAvailable =
      models3d.filter(info => info.downloadLink).length > 0;

    return (
      <>
        <Row className="m-0">
          <Col xs={12} sm={12} className={'order-first px-0'}>
            <ListGroup className="border-bottom">
              {/* // header */}
              <ListGroupItem
                className={`font-weight-bold shadow-sm text-primary ${styles.zIndex2DModelList}`}>
                <Row className="d-flex justify-content-between px-2">
                  <div className="d-flex flex-column justify-content-between">
                    <div>{t('project:model_list')}</div>
                  </div>

                  {isDownloadAllAvailable && (
                    <div
                      className={`d-flex justify-content-end flex-grow-1 mr-2`}>
                      <div>
                        <>
                          <Button
                            color="link"
                            id={'downloadModel'}
                            className="pointer mr-2 p-0"
                            onClick={this.downloadAllModels}>
                            <i
                              className={
                                'text-primary fas fa-download fs-1-2x '
                              }
                            />
                          </Button>

                          {this._isMounted && (
                            <UncontrolledTooltip
                              fade={false}
                              boundariesElement="window"
                              target={'downloadModel'}>
                              {t('main:download_all')}
                            </UncontrolledTooltip>
                          )}
                        </>
                      </div>
                      <>
                        <div>
                          <Button
                            color="link"
                            disabled={disabled || isUploadingFile}
                            id={'deleteModel'}
                            className="pointer mr-2 p-0"
                            onClick={this.deleteAllModels}>
                            <i
                              className={`fas fa-trash-alt fs-1-2x ${
                                disabled || isUploadingFile
                                  ? 'text-muted'
                                  : 'text-danger'
                              }`}
                            />
                          </Button>

                          {this._isMounted && (
                            <UncontrolledTooltip
                              fade={false}
                              boundariesElement="window"
                              target={'deleteModel'}>
                              {t('main:delete_all')}
                            </UncontrolledTooltip>
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </Row>
              </ListGroupItem>
              <div>
                {models3d.map((f, index) => {
                  const deleteDisabled = !!f.downloadLink ? disabled : false;
                  return (
                    <ListGroupItem
                      key={f.name}
                      className={classNames('shadow-sm', {
                        'model-upload-done': f.uploadState === 'DONE',
                        'model-upload-in-progress':
                          f.uploadState === 'IN_PROGRESS',
                        'model-upload-pending': f.uploadState === 'PENDING',
                        'model-upload-error': f.uploadState === 'ERROR',
                        'upload-in-progress': isUploadingFile
                      })}>
                      <Row className="d-flex justify-content-between px-2 ">
                        <div className="d-flex flex-column justify-content-between">
                          <div>
                            {f.name} - {prettyBytes(f.size)}
                          </div>
                          {f.date && (
                            <div className="text-secondary">
                              last updated: {timeAgoFormatting(f.date)} ago
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-end flex-grow-1 mr-2">
                          {isUploadingFile && (
                            <div className="mr-2 pt-1">
                              {f.uploadState === 'DONE' && (
                                <i className="far fa-check-circle"></i>
                              )}
                              {f.uploadState === 'IN_PROGRESS' && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {f.uploadState === 'PENDING' && (
                                <i className="fas fa-pause"></i>
                              )}
                              {f.uploadState === 'ERROR' && (
                                <i className="fas fa-times-circle text-danger"></i>
                              )}
                            </div>
                          )}
                          <div>
                            {f.downloadLink && (
                              <>
                                <a
                                  download
                                  id={`downloadModel-${index}`}
                                  onClick={e => e.stopPropagation()}
                                  className="pointer mr-2"
                                  href={`${
                                    process.env.REACT_APP_API_ENDPOINT[
                                      process.env.REACT_APP_API_ENDPOINT
                                        .length - 1
                                    ] == '/'
                                      ? process.env.REACT_APP_API_ENDPOINT.slice(
                                          0,
                                          -1
                                        )
                                      : process.env.REACT_APP_API_ENDPOINT
                                  }/${f.downloadLink}`}>
                                  <span>
                                    <i className="fas fa-download fs-1-2x text-primary" />
                                  </span>
                                </a>
                                {this._isMounted && (
                                  <UncontrolledTooltip
                                    fade={false}
                                    boundariesElement="window"
                                    target={`downloadModel-${index}`}>
                                    {t('main:download')}
                                  </UncontrolledTooltip>
                                )}
                              </>
                            )}
                          </div>
                          <>
                            <div>
                              <Button
                                color="link"
                                disabled={deleteDisabled || isUploadingFile}
                                id={`deleteModel-${index}`}
                                className="pointer mr-2 p-0"
                                onClick={() => {
                                  if (!deleteDisabled && !isUploadingFile) {
                                    removeModelFile(f);
                                    if (asFormikField) {
                                      remove(index);
                                    }
                                  }
                                }}>
                                <i
                                  className={`fas fa-trash-alt fs-1-2x ${
                                    deleteDisabled || isUploadingFile
                                      ? 'text-muted'
                                      : 'text-danger'
                                  }`}
                                />
                              </Button>

                              {this._isMounted && (
                                <UncontrolledTooltip
                                  fade={false}
                                  boundariesElement="window"
                                  target={`deleteModel-${index}`}>
                                  {t('delete')}
                                </UncontrolledTooltip>
                              )}
                            </div>
                          </>
                        </div>
                      </Row>
                      {f.error && (
                        <Row className="text-danger">
                          {t(`error:${f.error}`)}
                        </Row>
                      )}
                    </ListGroupItem>
                  );
                })}
              </div>
            </ListGroup>
          </Col>
        </Row>
        {/* Confirmation modal for the file replacement */}
        <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
          <ModalHeader> {t('project:want_to_replace_model')} </ModalHeader>
          <ModalBody>
            {this.state.fileToConfirm.file
              ? this.state.fileToConfirm.file.name
              : ''}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.acceptFileReplace}>
              {t('main:yes')}
            </Button>
            <Button type="button" color="secondary" onClick={this.closeModal}>
              {t('main:no')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

// ModelList.propTypes = {

// }

export default withTranslation(['project', 'main', 'error'])(ModelList);
// export default ModelList;
