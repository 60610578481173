import React, { useRef } from 'react';

const VideoPLayer = ({
  width = 320,
  height = 240,
  src,
  poster,
  autoplay = false,
  innerRef
}) => {
  // const videoRef = ref || useRef();
  return (
    <video
      ref={innerRef}
      width={width}
      height={height}
      controls
      autoPlay={autoplay}
      disablePictureInPicture
      preload="auto"
      poster={poster}
      src={src}>
      {/* <source ref={innerRef} src={src} type="video/mp4" /> */}
    </video>
  );
};

export default VideoPLayer;
