import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  CustomInput as CustomReactstrapInput
} from 'reactstrap';

import {
  fetchAdminConfigSettings,
  editAdminConfigSettings
} from '../../../../store/actions';

import Message from '../../../UI/Message';
import CustomInput from '../../../UI/Input/customInput';

import CustomSelectInput from '../../../UI/Input/customSelectInput';

const AVAILABLE_FIELDS = [
  { label: 'Text', value: 'text' },
  { label: 'Text area', value: 'textarea' },
  { label: 'Number', value: 'number' },
  { label: 'Boolean', value: 'boolean' }
  // 'Choice - One value',
  // 'Choice - Multiple value'
];

const emptyField = {
  name: '',
  label: '',
  type: 'text',
  order: 0,
  is_required: false
};

const ProjectFromConfigPresenter = props => {
  const formikConfig = {
    initialValues: { project_form: props.project_form_config },
    validationSchema: Yup.object().shape({
      project_form: Yup.object().shape({
        additional_fields: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .trim()
              .matches(
                /^([a-zA-Z]||_)*$/,
                'Name must contain only letter or underscore'
              )
              .required(props.t('required')),
            type: Yup.string()
              .oneOf(AVAILABLE_FIELDS.map(e => e.value))
              .required(props.t('required')),
            label: Yup.string().required(props.t('required')),
            order: Yup.number()
              .transform(value => (isNaN(value) ? undefined : value))
              .typeError('Order must be a positive number')
              .positive('Order must be greater than zero')
              .required(props.t('required'))
          })
        )
      })
    }),
    validateOnChange: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      values.project_form.additional_fields = values.project_form.additional_fields.map(
        field => ({
          ...field,
          name: field.name.trim().toLowerCase()
        })
      );
      props
        .editAdminConfigSettings(values)
        .then(() => {
          Message.success(t('config_update_success'));
        })
        .catch(err => {
          Message.error(
            props.t(`error:${err.message}`, {
              entity: 'configuration'
            })
          );
        })
        .then(() => setSubmitting(false));
    }
  };

  const { t } = props;
  return (
    <Formik {...formikConfig}>
      {formikProps => {
        const {
          isSubmitting,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          onSubmit
        } = formikProps;

        return (
          <Form>
            <Container>
              <Row className="px-3 ">
                <FieldArray
                  name="project_form.additional_fields"
                  render={arrayHelpers => {
                    const additionalFields =
                      formikProps.values.project_form.additional_fields;
                    const { push, remove } = arrayHelpers;
                    return (
                      <>
                        <FormGroup row className="w-100">
                          <div>
                            <Container className="sticky-top">
                              <Label
                                for="exampleCustomSelect"
                                sm={3}
                                className="text-secondary font-weight-bold">
                                <Row className="w-100">
                                  {t('additional_fields')}
                                </Row>
                              </Label>
                              <Row className="w-100">
                                <ButtonGroup>
                                  <Button
                                    color="light"
                                    type="submit"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      formikProps.handleSubmit();
                                    }}
                                    disabled={!formikProps.dirty}>
                                    <div className="d-flex justify-content-center">
                                      {/* <i className="fa fa-user-plus text-warning" /> */}
                                      <i className="material-icons  text-success">
                                        save_alt
                                      </i>
                                      <span className="ml-1 text-muted">
                                        {t('save')}
                                      </span>
                                    </div>
                                  </Button>
                                  <Button
                                    color="light"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      push({ ...emptyField });
                                    }}>
                                    <div className="d-flex justify-content-center">
                                      {/* <i className="fa fa-user-plus text-warning" /> */}
                                      <i className="material-icons  text-warning">
                                        post_add
                                      </i>
                                      <span className="ml-1 text-muted">
                                        {t('add_field')}
                                      </span>
                                    </div>
                                  </Button>
                                </ButtonGroup>
                              </Row>
                            </Container>
                          </div>
                          <Col>
                            {additionalFields
                              .sort((a, b) => a.order - b.order)
                              .map((projectFormField, index) => {
                                return (
                                  <div
                                    key={`project-form-field-${index}`}
                                    className="border border-very-thick rounded w-100 p-2 my-2">
                                    <Container>
                                      <Row className="justify-content-between">
                                        <div>
                                          <h5 className="text-primary py-3">
                                            Field {index + 1}
                                          </h5>
                                        </div>
                                        <div>
                                          <Button
                                            color="danger"
                                            outline
                                            onClick={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              remove(index);
                                            }}>
                                            <i className="fas fa-minus"></i>{' '}
                                          </Button>
                                        </div>
                                      </Row>
                                      {/* FIELD NAME */}
                                      <Field
                                        name={`project_form.additional_fields.${index}.name`}
                                        label={t('field_name')}
                                        placeholder={t('field_name')}
                                        row
                                        component={CustomInput}
                                      />
                                      {/* FIELD TYPE */}
                                      <Field
                                        name={`project_form.additional_fields.${index}.type`}
                                        label={t('field_type')}
                                        type="select"
                                        size="sm"
                                        row
                                        options={AVAILABLE_FIELDS}
                                        component={CustomSelectInput}
                                      />
                                      {/* FIELD LABEL */}
                                      <Field
                                        name={`project_form.additional_fields.${index}.label`}
                                        placeholder={t('field_label')}
                                        label={t('field_label')}
                                        row
                                        component={CustomInput}
                                      />
                                      {/* FIELD ORDER */}
                                      <Field
                                        name={`project_form.additional_fields.${index}.order`}
                                        type="number"
                                        placeholder={t('field_order')}
                                        label={t('field_order')}
                                        min={0}
                                        row
                                        component={CustomInput}
                                      />
                                      {/* FIELD ORDER */}
                                      <Field
                                        name={`project_form.additional_fields.${index}.is_required`}
                                        type="checkbox"
                                        placeholder={t('field_is_required')}
                                        label={t('field_is_required')}
                                        row
                                        render={() => (
                                          <FormGroup row>
                                            <InputGroup>
                                              <CustomReactstrapInput
                                                type="checkbox"
                                                id={`project-form.additional_fields-${index}-is_required`}
                                                label={t('field_is_required')}
                                                onChange={e => {
                                                  formikProps.setFieldValue(
                                                    `project_form.additional_fields.${index}.is_required`,
                                                    e.target.checked
                                                  );
                                                }}
                                                checked={
                                                  formikProps.values
                                                    .project_form
                                                    .additional_fields[index]
                                                    .is_required
                                                }
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        )}
                                      />
                                    </Container>
                                  </div>
                                );
                              })}
                          </Col>
                        </FormGroup>
                      </>
                    );
                  }}
                />
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    project_form_config: state.admin_configuration.app.project_form
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminConfigSettings: () => dispatch(fetchAdminConfigSettings()),
    editAdminConfigSettings: data => dispatch(editAdminConfigSettings(data))
  };
};

const ProjectFromConfigContainer = props => {
  const { fetchAdminConfigSettings } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);
  useEffect(() => {
    // if (!isLoaded) {
    fetchAdminConfigSettings()
      .then(result => {
        // setRelatedItems(result);
        setIsLoaded(true);
      })
      .catch(err => {
        setOnError(true);
      });
    // }
  }, []);

  return (
    <>{isLoaded && !onError && <ProjectFromConfigPresenter {...props} />} </>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['adminConfigurations', 'error'])
)(ProjectFromConfigContainer);
