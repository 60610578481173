import { jobsActionType } from '../../constants/actionType';
import { performRequest } from '../requests';

const { LIST_JOBS_AS_ADMIN, FETCH_JOB_QUEUE_METRICS, UPDATE_JOB } =
  jobsActionType;

export const fetchJobListAsAdmin =
  (filters, order, pagination, toDispatch = true) =>
  (
    dispatch /* ,
  getState */
  ) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        'api/admin/job/list',
        { filters, order, pagination },
        requestConfig,
        'LIST_JOBS_AS_ADMIN',
        true
      )
    )
      .then(res => res.data)
      .then(data => {
        const jobList = data.data;
        const totalResult = data.total;
        if (toDispatch) {
          dispatch({
            type: LIST_JOBS_AS_ADMIN,
            jobList
          });
          return totalResult;
        } else {
          return jobList;
        }
      });
  };

export const requestAdminJobCancellation = jobId => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/admin/job/cancel/${jobId}`,
      {},
      requestConfig,
      'REQUEST_ADMIN_JOB_CANCELATION'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        dispatch({
          type: UPDATE_JOB,
          jobData: {
            ...data.job,
            rules_check: JSON.parse(data.job.rules_check)
          }
        });

        return data;
      } else {
        throw new Error(data.message || 'error:default_message');
      }
    });
};

export const fetchJobQueueStatusMetrics = filters => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'post',
      `api/admin/job_queue_monitoring/getJobQueueMetrics`,
      { filters },
      requestConfig,
      'FETCH_JOB_QUEUE_METRICS'
    )
  )
    .then(res => res.data)
    .then(data => {
      if (data.success) {
        return data;
      } else {
        throw new Error(data.message || 'error:default_message');
      }
    });
};

export const updateJobStatus =
  ({ job }) =>
  (dispatch, getState) => {
    dispatch({
      type: UPDATE_JOB,
      jobData: job
    });
  };
