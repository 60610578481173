import { performRequest } from '../requests';
import { newsActionType } from '../../constants/actionType';

// Add a list of news to the store
export const mergeNews = newsList => {
  const action = {
    type: newsActionType.MERGE_NEWS,
    newsList
  };
  return action;
};

// Add a news to the store
export const addNews = news => {
  const action = {
    type: newsActionType.ADD_NEWS,
    news
  };
  return action;
};

export const resetNews = () => {
  const action = {
    type: newsActionType.RESET_NEWS
  };
  return action;
};

export const expandNews = newsId => {
  return { type: newsActionType.EXPAND_NEWS, newsId };
};

// Fetch all the news from the server api
// Async actions => Here we use the redux-thunk library to perform asynchronous actions
export const fetchAllNews =
  (filters, order, pagination) => (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    // dispatch({
    //   type: newsActionType.RESET_NEWS
    // });
    return dispatch(
      performRequest(
        'post',
        'api/news/list',
        { filters, order, pagination },
        requestConfig,
        'FETCH_LIST_NEWS',
        true
      )
    )
      .then(res => res.data.data)
      .then(newsMap => {
        dispatch({
          type: newsActionType.FRONTPAGE_MERGE_NEWS,
          newsMap
        });
        return newsMap;
      })
      .catch(err => {
        console.log({ err });
        if (err.response && !err.response.status === 401) {
          console.log(err);
        }
      });
  };

export const editNews = (newsId, data) => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'put',
      `api/admin/news/${newsId}`,
      { data },
      requestConfig,
      'UPDATE_NEWS'
    )
  ).then(news => {
    dispatch({
      type: newsActionType.UPDATE_NEWS,
      newsId,
      news
    });
  });
};

export const deleteNews = newsId => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'delete',
      `api/admin/news/delete/${newsId}`,
      {},
      requestConfig,
      'DELETE_NEWS'
    ).then(() =>
      dispatch({
        type: newsActionType.REMOVE_NEWS,
        newsId
      })
    )
  );
};

export const fetchNews = newsId => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest('get', `api/news/${newsId}`, {}, requestConfig, 'FETCH_NEWS')
  )
    .then(res => {
      return res.data;
    })
    .then(news => {
      dispatch({
        type: newsActionType.MERGE_NEWS,
        newsMap: [news]
      });
    });
};

// [ADMIN] - ACTIONS
export const adminFetchAllNews =
  (filters, order, pagination, toDispatch = true) =>
  (dispatch /* getState */) => {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };

    return dispatch(
      performRequest(
        'post',
        'api/admin/news/list',
        { filters, order, pagination },
        requestConfig,
        'ADMIN_FETCH_LIST_NEWS'
      )
    )
      .then(res => res.data)
      .then(res => {
        const { data, total } = res;
        if (toDispatch) {
          dispatch({
            type: newsActionType.RESET_NEWS
          });
          dispatch({
            type: newsActionType.MERGE_NEWS,
            newsMap: data
          });
          return total;
        } else {
          return data;
        }
      });
  };

export const adminCreateNews = newsData => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/admin/news/create',
      newsData,
      requestConfig,
      'ADMIN_CREATE_NEWS'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(news => {
      dispatch({
        type: newsActionType.ADD_NEWS,
        news
      });
    });
};

export const adminFetchNews = newsId => (dispatch /* ,getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/admin/news/${newsId}/detail`,
      {},
      requestConfig,
      'ADMIN_FETCH_NEWS'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(news => {
      dispatch({
        type: newsActionType.MERGE_NEWS,
        newsMap: [news]
      });
    });
};

export const adminEditNews = (newsId, newsData) => (dispatch, getState) => {
  const concurrencyControlVersionId =
    getState().news[newsId].concurrency_control_version_id;
  const entityTargetId = newsId;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'put',
      `api/admin/news/${newsId}`,
      { ...newsData, concurrencyControlVersionId, entityTargetId },
      requestConfig,
      'UPDATE_NEWS'
    )
  )
    .then(res => res.data.data)
    .then(news => {
      dispatch({
        type: newsActionType.UPDATE_NEWS,
        newsId: news.news_id,
        news
      });
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      } else {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            err.message ||
            'error.default_message'
        );
      }
    });
};
