import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';

import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';

import CustomInput from '../../../UI/Input/customInput';
import CustomSelectInput from '../../../UI/Input/customSelectInput';
import Message from '../../../UI/Message';
import Transfer from '../../../UI/Input/customTransfer2';

import {
  addGroupMember,
  fetchOrganizationRoles,
  addMultipleGroupMember
} from '../../../../store/actions';

const AddGroupMemberComponent = ({
  addGroupMember,

  organizationMembers,
  existingMembers,
  roles,
  t,
  onSubmitSuccess,
  toggle,
  setFieldValue,
  values,
  errors,
  submitForm,
  isSubmitting
}) => {
  const [selectedMembers, setSelectedMembers] = useState( []);

  useEffect(() => {
    const nextFieldValue = selectedMembers.reduce((previous, current) => {
      return {
        ...previous,
        ...(values.selectedUsers[current]
          ? { [current]: values.selectedUsers[current] }
          : {
            [current]: {
              ...organizationMembers.find(u => u.user_id === current),
              role: ''
            }
          })
      };
    }, {});
    setFieldValue('selectedUsers', nextFieldValue);
  }, [selectedMembers]);
  return (
    <Container>
      <div className="p-3">
        <Row className="px-3">
          <h2 className="text-primary">{t('organization:add_member_title')}</h2>
        </Row>
        {/* <Formik {...modalFormikConfig}>
          {formikProps => { */}
        {/* return ( */}
        <Form>
          <Row>
            <Transfer
              data={organizationMembers.map(m => ({
                ...m,
                key: m.user_id
              }))}
              targetKeys={selectedMembers}
              // withHeader
              // disabled
              // selectedKeys={}
              renderItem={item =>
                `${item.first_name || ''} ${item.last_name || ''} (${
                  item.email
                })`
              }
              // renderHeader={item => item.header}
              onChange={keys => setSelectedMembers(keys)}
            />
          </Row>
          <FieldArray
            name="selectedUsers"
            render={arrayHelpers => (
              <>
                {Object.keys(values.selectedUsers).map((userKey, index) => {
                  return (
                    <Row key={`member-row-${index}`}>
                      <Col xs={12} sm={6}>
                        <Field
                          disabled
                          name={`selectedUsers.${userKey}.email`}
                          placeholder={t('main:email')}
                          label={t('main:email')}
                          component={CustomInput}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Field
                          name={`selectedUsers.${userKey}.role`}
                          size="md"
                          placeholder={t('project:role')}
                          label={t('project:role')}
                          options={roles.map(r => ({
                            value: r,
                            label: t(`organization:role_${r.toLowerCase()}`)
                          }))}
                          component={CustomSelectInput}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          />

          <Button
            color="primary"
            type="button"
            disabled={isSubmitting}
            onClick={() => {
              submitForm();
            }}>
            {t('project:add_member')}
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            {t('main:cancel')}
          </Button>
        </Form>
        {/* );
          }}
        </Formik> */}
      </div>
    </Container>
  );
};

const AddGroupMemberContainer = props => {
  const {
    t,
    addGroupMember,
    addMultipleGroupMember,
    groupId,
    fetchOrganizationRoles,
    toggle
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const organizationRoles = await fetchOrganizationRoles();
        setRoles(organizationRoles);
        setIsLoaded(true);
      } catch (err) {
        Message.error(t(err.message));
        setHasError(true);
      }
    })();
  }, [groupId]);

  const modalFormikConfig = {
    // validationSchema: Yup.object().shape({
    //   selectedUsers: Yup.object().shape({

    //   })

    // }),
    initialValues: { selectedUsers: {} },
    validate: ({ selectedUsers }) => {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Required'),
        // .test({
        //   name: 'unique_email',
        //   test: value => {
        //     if (props.mode && props.mode === 'edit') {
        //       if (
        //         value !== props.member.email &&
        //         props.existingMemberEmail.includes(value)
        //       ) {
        //         return false;
        //       }
        //     } else if (props.existingMemberEmail.includes(value)) {
        //       return false;
        //     }
        //     return true;
        //   },
        //   message: props.t('error:member_must_be_unique')
        // }),
        role: Yup.string().required('Required')
      });

      const errors = Object.keys(selectedUsers).reduce(
        (previous, currentKey) => {
          try {
            schema.validateSync(selectedUsers[currentKey]);
            return previous;
          } catch (err) {
            return { ...previous, [currentKey]: { [err.path]: err.message } };
          }
        },
        {}
      );

      if (Object.keys(errors).length > 0) {
        return { selectedUsers: errors };
      } else {
        return {};
      }
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await addMultipleGroupMember(
          Object.keys(values.selectedUsers).map(userKey => {
            const value = values.selectedUsers[userKey];
            const { role, ...rest } = value;
            return { role, user: rest };
          })
        );
        toggle();
        Message.success(t('organization:member_added'));
      } catch (err) {
        Message.error(t('error:default_message'));
      }
      setSubmitting(false);
    }
  };
  return (
    <>
      {isLoaded && !hasError && (
        <Formik {...modalFormikConfig}>
          {formikProps => {
            return (
              <AddGroupMemberComponent
                {...formikProps}
                {...props}
                roles={roles}
              />
            );
          }}
        </Formik>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch, { groupType, groupId }) => {
  return {
    addGroupMember: values =>
      dispatch(addGroupMember(groupType, groupId, values)),
    addMultipleGroupMember: values =>
      dispatch(addMultipleGroupMember(groupType, groupId, values)),
    fetchOrganizationRoles: () => dispatch(fetchOrganizationRoles())
  };
};

const mapStateToProps = () => {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(AddGroupMemberContainer);
