import { performRequest } from '../requests';

export const userFeedback = feedbackData => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return dispatch(
    performRequest(
      'post',
      '/api/user/feedback',
      JSON.stringify(feedbackData),
      requestConfig,
      'USER_FEEDBACK'
    )
  )
    .then(res => res.data)
    .then(res => {
      if (res.result === 'success') {
        return {
          success: true,
          message: res.messageKey
        };
      }
    });
};
