import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Home from './Fornax';
import About from './About';
import Solutions from './Solutions';
import Contact from './Contact';
import ProjectReference from './ProjectReference';
import BimReference from './BimReference';
import FixedButtons from './FixedButtons';

const sectionComponents = props => ({
  fornax: {
    component: <Home {...props} />
  },
  solutions: {
    component: <Solutions {...props} />
  },
  projectReference: {
    component: <ProjectReference {...props} />
  },
  bimReference: {
    component: <BimReference {...props} />
  },
  about: {
    component: <About {...props} />
  },
  contact: {
    component: <Contact {...props} />
  }
});

export default ({
  rowRef,
  landingPageSections,
  setLandingPageSetions,
  setActiveNavLink,
  activeNavLink,
  scrollY,
  scrollToActiveRow,
  fetchAllNews,
  resetNews,
  timeAgoFormatting
}) => {
  const sectionPositions = useRef(
    Object.keys(landingPageSections).reduce((prev, currentKey) => {
      return { ...prev, [currentKey]: { element: {} } };
    }, {})
  );
  useEffect(() => {
    setLandingPageSetions(
      Object.values(landingPageSections).reduce((prev, currentValue) => {
        const { element, isElementInViewport } = sectionPositions.current[
          currentValue.id
        ];

        return {
          ...prev,
          [currentValue.id]: {
            ...currentValue,
            element,
            isElementInViewport: !landingPageSections[currentValue.id]
              .isElementInViewport
              ? isElementInViewport
              : true
          }
        };
      }, {})
    );
  }, [scrollY]);

  const [newsModalIsOpened, setnewsModelIsOpened] = useState(false);
  return (
    <>
      <FixedButtons
        timeAgoFormatting={timeAgoFormatting}
        fetchAllNews={fetchAllNews}
        resetNews={resetNews}
        newsModalIsOpened={newsModalIsOpened}
        setnewsModelIsOpened={setnewsModelIsOpened}
      />
      {Object.values(landingPageSections).map((section, index) => {
        return (
          <Section
            {...section}
            key={`section-${index}`}
            rowRef={rowRef}
            sectionPositions={sectionPositions}
            setActiveNavLink={setActiveNavLink}
            activeNavLink={activeNavLink}
            scrollToActiveRow={scrollToActiveRow}
            scrollY={scrollY}
          />
        );
      })}
    </>
  );
};

const Section = ({
  id,
  isElementInViewport,
  rowRef,
  activeNavLink,
  setActiveNavLink,
  sectionPositions,
  scrollToActiveRow,
  scrollY
}) => {
  /**
   * @description Checked if the element is in viewport
   * @param {element} //div element
   * @return {boolean}
   */
  const isInViewport = element => {
    const rect = element && element.getBoundingClientRect();

    return rect && scrollY >= window.innerHeight && scrollY <= rect.bottom;
  };

  if (
    !sectionComponents({
      scrollToActiveRow,
      setActiveNavLink,
      isElementInViewport
    })[id]
  )
    return null;

  return (
    <div
      key={`section-key-${id}`}
      ref={ref => {
        sectionPositions.current[id].element = ref;
        sectionPositions.current[id].isElementInViewport = isInViewport(ref);
        return (!activeNavLink && id === 'fornax') || activeNavLink === id
          ? (rowRef.current = ref)
          : null;
      }}>
      <Row className="m-0">
        <Col className="p-0 h-100">
          {
            sectionComponents({
              scrollToActiveRow,
              setActiveNavLink,
              isElementInViewport
            })[id].component
          }
        </Col>
      </Row>
    </div>
  );
};
