import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import AppConfiguration from './ConfigContainer';
import ProjectFormConfig from './ProjectFormConfig';
import SecurityConfig from './SecurityConfig';
import Maintenance from './MaintenanceAnnouncement/MaintenanceAnnouncementCreation';
import FooterManagement from './FooterManagement';
import FeaturesEnabled from './FeaturesEnabled';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// export { default } from './ConfigContainer';

class AdminConfig extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 'app'
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { t, featureList } = this.props;
    const { activeTab } = this.state;

    const isProjectFieldConfigurationEnabled =
      featureList.projectFieldConfiguration;

    return (
      <Container>
        <Row>
          <Col sm="12">
            <div className="shadow p-3 mt-3 mb-5 rounded">
              <h3 className="text-primary">{t('page_title')}</h3>
              <Nav tabs>
                <NavItem
                  className={classnames({
                    'border-left border-primary rounded border-sz-3':
                      activeTab === 'app'
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold': activeTab
                        ? activeTab === 'app'
                        : false
                    })}
                    onClick={() => {
                      this.toggle('app');
                    }}>
                    {t('general')}
                  </NavLink>
                </NavItem>
                {isProjectFieldConfigurationEnabled && (
                  <NavItem
                    className={classnames({
                      'border-left border-primary rounded border-sz-3':
                        activeTab === 'project_field_config'
                    })}>
                    <NavLink
                      className={classnames('rounded', {
                        'active font-weight-bold':
                          activeTab && activeTab === 'project_field_config'
                      })}
                      onClick={() => {
                        this.toggle('project_field_config');
                      }}>
                      {t('project_field_config')}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem
                  className={classnames({
                    'border-left border-primary rounded border-sz-3':
                      activeTab === 'maintenance'
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold':
                        activeTab && activeTab === 'maintenance'
                    })}
                    onClick={() => {
                      this.toggle('maintenance');
                    }}>
                    {t('maintenance_announcement')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    'border-left border-primary rounded border-sz-3':
                      activeTab === 'features_enabled'
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold':
                        activeTab && activeTab === 'features_enabled'
                    })}
                    onClick={() => {
                      this.toggle('features_enabled');
                    }}>
                    {t('features_enabled')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    'border-left border-primary rounded border-sz-3':
                      activeTab === 'footer_management'
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold':
                        activeTab && activeTab === 'footer_management'
                    })}
                    onClick={() => {
                      this.toggle('footer_management');
                    }}>
                    {t('admin_footer_management:footer_management')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    'border-left border-primary rounded border-sz-3':
                      activeTab === 'security'
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold':
                        activeTab && activeTab === 'security'
                    })}
                    onClick={() => {
                      this.toggle('security');
                    }}>
                    {t('adminConfigurations:security')}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="app" className="pt-3">
                  {this.state.activeTab === 'app' && <AppConfiguration />}
                </TabPane>

                {isProjectFieldConfigurationEnabled && (
                  <TabPane tabId="project_field_config">
                    {this.state.activeTab === 'project_field_config' && (
                      <ProjectFormConfig />
                    )}
                  </TabPane>
                )}
                <TabPane tabId="maintenance" className="pt-3">
                  {this.state.activeTab === 'maintenance' && (
                    <Maintenance {...this.props} />
                  )}
                </TabPane>
                <TabPane tabId="footer_management" className="pt-3">
                  {this.state.activeTab === 'footer_management' && (
                    <div>
                      {' '}
                      <FooterManagement {...this.props} />{' '}
                    </div>
                  )}
                </TabPane>
                <TabPane tabId="security" className="pt-3">
                  {this.state.activeTab === 'security' && (
                    <SecurityConfig {...this.props} />
                  )}
                </TabPane>
                <TabPane tabId="features_enabled" className="pt-3">
                  {this.state.activeTab === 'features_enabled' && (
                    <FeaturesEnabled {...this.props} />
                  )}
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return { featureList: state.app.featureList };
};

export default compose(
  connect(mapStateToProps),
  withTranslation(['adminConfigurations'])
)(AdminConfig);
