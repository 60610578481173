import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';

import VideoPlayer from '../../UI/VideoPlayer';
import { getURLParameters } from '../../../utils/url';

import { fetchFooterPerDocumentType } from '../../../store/actions';

import i1 from './project_creation_thumbnail_2.PNG';
import i2 from './regulatory_check_thumbnail.png';
import i3 from './checking_results_thumbnail.png';
import i4 from './model_compare_thumbnail.png';
import i5 from './revit_classification_thumbnail.png';
import './style.css';

const generalVideos = [
  {
    id: 'video-1',
    title: 'Project Creation',
    file: 'Project Creation.mp4',
    poster: i1,
    description: 'How to create an account and setup project',
    category: 'general'
  },
  {
    id: 'video-2',
    title: 'Regulatory Compliance Check',
    file: 'Regulatory Compliance Check.mp4',
    poster: i2,
    description: 'How to use uploaded model to run rule checking'
  },
  {
    id: 'video-3',
    title: 'Checking Results',
    file: 'Checking Results.mp4',
    poster: i3,
    description: 'How to review results and rectify (using portal and BCF)',
    category: 'general'
  },
  {
    id: 'video-4',
    title: 'Model Compare',
    file: 'Model Compare.mp4',
    poster: i4,
    description: 'How to do a model comparison and review results',
    category: 'general'
  },
  {
    id: 'video-5',
    title: 'Revit Classification',
    file: 'Revit Classification.mp4',
    poster: i5,
    description:
      'How to apply classification to PUB required elements via Revit classification manager',
    category: 'general'
  }
];
const VideoList = ({ t, location, history }) => {
  const [selectedVideo, setSelectedVideo] = useState();
  const [category, setCategory] = useState('all');
  const [categories, setCategories] = useState([]);
  const [allVideos, setAllVideos] = useState(generalVideos);
  const [filteredVideos, setFilteredVideo] = useState(generalVideos);
  const videoPlayerRef = useRef();
  const dispatch = useDispatch();

  const isXs = useMediaQuery({ maxWidth: 575 });
  const isSm = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const isMd = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isLg = useMediaQuery({ minWidth: 992, maxWidth: 1279 });
  const isXl = useMediaQuery({ minWidth: 1280 });
  const playerSize = isXl
    ? { width: 840, height: 576 }
    : isLg
    ? { width: 720, height: 576 }
    : isMd
    ? { width: 540, height: 380 }
    : isSm
    ? { width: 480, height: 240 }
    : { width: 480, height: 240 };

  useEffect(() => {
    setFilteredVideo(
      allVideos.filter(v => {
        if (!category || category === 'all') {
          return true;
        } else {
          return v.category === category.replace(/\W+/g, '-');
        }
      })
    );
  }, [allVideos, category]);

  // load videos list from footer documents
  useEffect(() => {
    dispatch(fetchFooterPerDocumentType('video')).then(data => {
      const footerCategories = Object.keys(data);
      const videosPerCategory = footerCategories.reduce((acc, c) => {
        return [
          ...acc,
          ...data[c].dataList.map(item => {
            return {
              id: item.title.replace(/\W+/g, '-'),
              title: item.title,
              poster: '',
              file:
                item.versions.sort((a, b) => a.version > b.version).pop()
                  .filename || '',
              description: item.description,
              category: c.replace(/\W+/g, '-'),
              fromFooter: true
            };
          })
        ];
      }, generalVideos);
      setAllVideos(videosPerCategory);
      setCategories([
        { value: 'all', label: 'Select video category' },
        { value: 'general', label: 'General' },
        ...footerCategories.map(c => ({
          value: c.replace(/\W+/g, '-'),
          label: c
        }))
      ]);

      const videoId = getURLParameters(window.location.href).v;
      if (videoId) {
        const nextSelectedVideo = filteredVideos.find(v => v.id === videoId);
        if (
          nextSelectedVideo &&
          (!selectedVideo || nextSelectedVideo.id !== selectedVideo.id)
        ) {
          setSelectedVideo(nextSelectedVideo);
        }
      }
    });
  }, []);

  useEffect(() => {
    const urlParameters = getURLParameters(window.location.href);
    const videoId = urlParameters.v;
    const categoryURL = urlParameters.category;
    if (videoId) {
      const nextSelectedVideo = filteredVideos.find(v => v.id === videoId);
      if (
        nextSelectedVideo &&
        (!selectedVideo || nextSelectedVideo.id !== selectedVideo.id)
      ) {
        setSelectedVideo(nextSelectedVideo);
      }
    }
    if (categoryURL) {
      setCategory(categoryURL);
    }
  }, [location, filteredVideos, categories]);

  useEffect(() => {
    if (videoPlayerRef && videoPlayerRef.current) {
      // // videoPlayerRef.current.suspend();
      videoPlayerRef.current.setAttribute(
        'src',
        `${
          process.env.REACT_APP_API_ENDPOINT[
            process.env.REACT_APP_API_ENDPOINT.length - 1
          ] == '/'
            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
            : process.env.REACT_APP_API_ENDPOINT
        }/api/documents/video/${selectedVideo.file}`
      );
      videoPlayerRef.current.play();
      // videoPlayerRef.current.currentSrc=`${process.env.REACT_APP_API_ENDPOINT}/api/documents/video/${selectedVideo.file}` ;
      const urlParams = new URLSearchParams([
        ['category', category.replace(/\W+/g, '-')],
        ['v', selectedVideo && selectedVideo.id]
      ]);
      history.replace({ search: urlParams.toString() });
    }
  }, [selectedVideo]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams([
  //     ['category', category],
  //     ['v', selectedVideo && selectedVideo.id]
  //   ]);
  //   history.replace({ search: urlParams.toString() });
  // }, [category]);
  const changeCategory = category => {
    const urlParams = new URLSearchParams([
      ['category', category.replace(/\W+/g, '-')],
      ['v', selectedVideo && selectedVideo.id]
    ]);
    history.replace({ search: urlParams.toString() });
  };

  return (
    <Container fluid className="p-4">
      <div className="shadow mb-5 rounded">
        <Row className="p-3">
          <Col>
            <h2 className="text-primary">{t('main:tutorial_videos')}</h2>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{
              order: 1,
              size: 12
            }}
            md={{
              order: 0,
              size: 4
            }}
            className="border-right overflow-auto  h-100 ">
            <FormGroup>
              <Input
                type="select"
                name="select-category"
                placeholder="Select videos category"
                id="select-category"
                onChange={e => {
                  changeCategory(e.target.value);
                }}
                value={category}>
                {categories.map(c => (
                  <option key={`video-${c.value}`} value={c.value}>
                    {c.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <ListGroup flush>
              {filteredVideos.map(video => (
                <ListGroupItem
                  key={video.id}
                  active={selectedVideo && selectedVideo.id === video.id}
                  tag="button"
                  onClick={() => setSelectedVideo(video)}
                  action>
                  <div className="d-flex">
                    <div>
                      {video.poster && (
                        <img src={video.poster} height="162" width="238" />
                      )}
                      {!video.poster && (
                        <div className="video-placeholder d-flex justify-content-center align-items-center">
                          <i className="fas fa-play-circle  fa-3x text-white"></i>
                        </div>
                      )}
                    </div>
                    <div className="ml-2 d-flex flex-column">
                      <div className="font-weight-bold">{video.title}</div>
                      <div className="">{video.description}</div>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col
            xs={{
              order: 0,
              size: 12
            }}
            md={{
              order: 1,
              size: 8
            }}>
            {selectedVideo && selectedVideo.file && (
              <div className="d-flex justify-content-center">
                <VideoPlayer
                  width={playerSize.width}
                  height={playerSize.height}
                  src={`${
                    process.env.REACT_APP_API_ENDPOINT[
                      process.env.REACT_APP_API_ENDPOINT.length - 1
                    ] == '/'
                      ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                      : process.env.REACT_APP_API_ENDPOINT
                  }/api/documents/video/${selectedVideo.file}`}
                  innerRef={videoPlayerRef}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="p-3">
          <p className="font-italic">{t('note')}</p>
        </Row>
      </div>
    </Container>
  );
};

export default withTranslation(['front'])(VideoList);
