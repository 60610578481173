import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';

import CustomInput from '../../UI/Input/customInput';
import CustomSelectInput from '../../UI/Input/customSelectInput';

import OrganizationStructure from './OrganizationTreeForProjectDetail';

import { shareProjectToOrg } from '../../../store/actions';

class ModelAddMemberToGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: {
        groupId: props.userOrganizationId,
        groupType: 'organization',
        includeSubGroup: false
      }
    };
  }

  toogleIncludeSubGroups = (groupId, groupType, includeSubGroup) => {
    this.setState({
      selectedGroup: {
        groupId,
        groupType,
        includeSubGroup
      }
    });
  };

  shareProjectToOrg = () => {
    const { groupId, groupType, includeSubGroup } = this.state.selectedGroup;
    const projectId = this.props.projectId;

    this.props.shareProjectToOrg(
      projectId,
      groupId,
      groupType,
      includeSubGroup
    );
  };
  render() {
    const {
      toggle,
      index,
      className,
      open,
      t,
      onSubmit,
      mode,
      existingMemberEmail,
      userOrganizationId
    } = this.props;

    const { roles } = this.state;

    return (
      <Modal isOpen={open} className={className} toggle={toggle} size="md">
        <Form>
          <ModalHeader> {t('project:project_sharing')}</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <OrganizationStructure
                  organizationId={userOrganizationId}
                  toogleIncludeSubGroups={this.toogleIncludeSubGroups}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                this.shareProjectToOrg();
              }}>
              {t('organization:share_to_group')}
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              {t('main:cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default compose(withTranslation(['project', 'main', 'error']))(
  ModelAddMemberToGroup
);
