import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
// import PropTypes from 'prop-types';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Progress,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import styles from './Styles.module.css';

import pathModule from 'path';

// const MAX_UPLOAD_FILE = 2143648;
//disable non-pdf files for now since we don't have a means to view it directly on the portal.
//'.dwf', '.dwfx', '.dxf', '.dwg', '.dgn'
const twoDExtensionsFunc = is2DPlanEnabled => {
  return is2DPlanEnabled ? ['.pdf'] : [];
};
const extensionAvailableFunc = extensions => ['.ifc', ...extensions];

class ModelUploadBox extends Component {
  constructor(props) {
    super(props);
    this.gen = null;
    this.state = {
      modalOpen: false,
      filesToReplace: [],
      fileToConfirm: { file: null, index: null },
      isLast: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCancel = () => {
    this.setState({
      files: []
    });
  };

  onDropRejected = files => {
    // files.forEach(file => {
    //   if (file.size > MAX_UPLOAD_FILE) {
    //     Message.error(`${file.name} is exceeded maximum size`);
    //   }
    // });
  };

  closeModal = async () => {
    this.setState({
      modalOpen: false
    });
    await this.gen.next();
  };

  openModal = (file, index) => {
    if (this._isMounted) {
      this.setState(() => {
        return {
          modalOpen: true,
          fileToConfirm: {
            file,
            index
          }
        };
      });
    }
  };

  acceptFileReplace = () => {
    if (this._isMounted) {
      this.setState(
        prevState => ({
          fileToConfirm: {},
          filesToReplace: [...prevState.filesToReplace, prevState.fileToConfirm]
        }),
        () => this.closeModal()
      );
    }
  };

  // Generator for the modal, so once we close one the system open the next one
  modalOpeningGenerator = async function* (
    filesToPush,
    filesInProcessToReplace
  ) {
    for (let i in filesInProcessToReplace) {
      const { file, index } = filesInProcessToReplace[i];
      yield this.openModal(file, index);
    }
    // Once the last modal is closed we replace the formik values with the confirmed files
    const { filesToReplace } = this.state;
    const twoDExtensions = twoDExtensionsFunc(this.props.featureList['2DPlan']);

    for (let i in filesToReplace) {
      const fileToReplace = filesToReplace[i];
      const { file, index } = fileToReplace;
      if (this.props.asFormikField) {
        // replace in formik values
        if (pathModule.extname(file.name).toLowerCase() === '.ifc') {
          await this.props.model3D_formik_props.replace(index, {
            name: file.name
          });
        } else if (
          twoDExtensions.includes(pathModule.extname(file.name).toLowerCase())
        ) {
          await this.props.model2D_formik_props.replace(index, {
            name: file.name,
            size: file.size,
            plan_type: '',
            fileInput: file
          });
        }
      }
    }

    for (let index in filesToPush) {
      const file = filesToPush[index];

      if (this.props.asFormikField) {
        if (pathModule.extname(file.name).toLowerCase() === '.ifc') {
          await this.props.model3D_formik_props.push({ name: file.name });
        } else if (twoDExtensions.includes(pathModule.extname(file.name))) {
          await this.props.model2D_formik_props.push({
            name: file.name,
            size: file.size,
            plan_type: '',
            fileInput: file
          });
        }
      }
    }

    const to_push_3DModel = filesToPush.filter(
      i => pathModule.extname(i.name).toLowerCase() === '.ifc'
    );
    const to_replace_3DModel = filesToReplace.filter(
      i => pathModule.extname(i.file.name).toLowerCase() === '.ifc'
    );

    const to_push_2DModel = filesToPush.filter(i =>
      twoDExtensions.includes(pathModule.extname(i.name).toLowerCase())
    );
    const to_replace_2DModel = filesToReplace.filter(i =>
      twoDExtensions.includes(pathModule.extname(i.file.name).toLowerCase())
    );

    if (to_push_3DModel.length > 0 || to_replace_3DModel.length > 0) {
      this.props.modelFileAction.setNewModelFiles(
        to_push_3DModel,
        to_replace_3DModel
      );
    }

    if (
      this.props.featureList['2DPlan'] &&
      (to_push_2DModel.length > 0 || to_replace_2DModel.length > 0)
    ) {
      this.props.model2DFileAction.setNew2DModelFiles(
        to_push_2DModel,
        to_replace_2DModel
      );
    }
  };
  setFormikValue = async files => {
    const { filesToPush, filesInProcessToReplace } = files.reduce(
      (returnedValue, file) => {
        const filename = file.name;
        const indexFileFrom3DModels = this.props.models.findIndex(
          model => model.name === filename
        );

        const indexFileFrom2DModels = this.props.models2D.findIndex(
          model => model.name === filename
        );

        if (indexFileFrom3DModels !== -1) {
          returnedValue.filesInProcessToReplace.push({
            file,
            index: indexFileFrom3DModels
          });
        } else if (indexFileFrom2DModels !== -1) {
          returnedValue.filesInProcessToReplace.push({
            file,
            index: indexFileFrom2DModels
          });
        } else {
          returnedValue.filesToPush.push(file);
        }
        return returnedValue;
      },
      { filesToPush: [], filesInProcessToReplace: [] }
    );

    this.setState({ filesToPush });
    this.gen = this.modalOpeningGenerator(filesToPush, filesInProcessToReplace);
    this.gen.next();
  };

  render() {
    const {
      t,
      disabled3D,
      disabled2D,
      progressVisible,
      progressBarValue,
      formikProps,
      allowed_ifc_version,
      activetab
    } = this.props;
    const { isDisabled } = this.state;
    const twoDExtensions = twoDExtensionsFunc(this.props.featureList['2DPlan']);
    const extensionAvailable = extensionAvailableFunc(twoDExtensions);
    const disabled = activetab === '3D_models' && disabled3D || activetab === '2D_models' && disabled2D
    return (
      <>
        <div className={styles.uploadButton}>
          <Dropzone
            accept={extensionAvailable.join(', ')}
            // maxSize={MAX_UPLOAD_FILE}
            multiple={true}
            disabled={disabled}
            className={`p-4 d-flex flex-column justify-content-center align-items-center ${
              disabled ? styles.disableDropzoneStyle : styles.dropzoneStyle
            }`}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.setFormikValue}>
            <h2>
              <i className="fas fa-upload " />
            </h2>
            <Trans
              i18nKey={
                activetab === '2D_models'
                  ? 'project:upload_models_2D'
                  : 'project:upload_models_3D'
              }>
              <div className="text-center">Upload Model</div>
            </Trans>

            {!!allowed_ifc_version &&
              allowed_ifc_version.length > 0 &&
              activetab === '3D_models' && (
                <div className="text-center text-muted font-italic">
                  Supported IFC version : {allowed_ifc_version.join(',')}
                </div>
              )}
            {activetab === '2D_models' && (
              <div className="text-center text-muted font-italic">PDF</div>
            )}

            {/* <div className="text-center text-muted font-italic">
                  Maximum size {prettyBytes(MAX_UPLOAD_FILE)}
                </div> */}
          </Dropzone>
        </div>

        {/* Confirmation modal for the file replacement */}
        <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
          <ModalHeader> {t('project:want_to_replace_model')}</ModalHeader>
          <ModalBody>
            {this.state.fileToConfirm.file
              ? this.state.fileToConfirm.file.name
              : ''}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.acceptFileReplace}>
              {t('main:yes')}
            </Button>
            <Button type="button" color="secondary" onClick={this.closeModal}>
              {t('main:no')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

// ModelList.propTypes = {

// }

export default withTranslation(['project', 'main'])(ModelUploadBox);
// export default ModelList;
