import { connect } from 'react-redux';
import { compose } from 'recompose';
import ConfigPresenter from './ConfigPresenter';
import {
  fetchAdminConfigSettings,
  editAdminConfigSettings
} from '../../../../store/actions';

const mapStateToProps = state => {
  return {
    admin_configuration: state.admin_configuration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminConfigSettings: () => dispatch(fetchAdminConfigSettings()),
    editAdminConfigSettings: data => dispatch(editAdminConfigSettings(data))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ConfigPresenter);
