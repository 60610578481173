import { resultActionType } from '../../constants/actionType';
const {
  RESULT_ALL,
  RESULT_LAST,
  RESET_RESULTS,
  ADD_ATTACHMENTS
} = resultActionType;
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RESULT_ALL: {
    const { results, models } = action;
    return { ...state, jobs: results, models };
  }
  case RESULT_LAST: {
    const { results, models } = action;

    return { ...state, ...results };
  }
  case RESET_RESULTS: {
    return initialState;
  }
  default: {
    return state;
  }
  }
};

export default reducer;
