import { performRequest } from '../requests';
import { projectPreferenceActionType } from '../../constants/actionType';

const { MERGE_PROJECT_PREFERENCE } = projectPreferenceActionType;

export const fetchProjectPreference = (projectId, settingsToFetch) => (
  dispatch /* getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'GET',
      `api/project_preference/${projectId}/${settingsToFetch}/list`,
      {},
      requestConfig,
      'FETCH_PROJECT_PREFERENCE'
    )
  )
    .then(res => res.data)
    .then(res => {
      const { project_preference_data } = res;
      dispatch({
        type: MERGE_PROJECT_PREFERENCE,
        project_preference_data
      });
    });
};
