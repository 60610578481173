import React, { Component } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledCollapse
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Tree from '../../UI/customTree';

import { fetchRules } from '../../../store/actions';

import autocheckStyles from './autocheck.module.css';

const RulesDropdown = ({
  t,
  direction,
  caret,
  onChecked,
  checkedKeys,
  rules,
  disabled,
  altLabelLink,
  ruleErrorMapping,
  expandedKeys
}) => {
  return (
    <div
      // Fix blurry text on chrome:
      className={`border ${autocheckStyles.RulesDropdownContainer}`}
      modifiers={{ computeStyle: { gpuAcceleration: false } }}>
      <Tree
        data={rules}
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
        onChecked={onChecked}
        altLabelLink={altLabelLink}
        ruleErrorMapping={ruleErrorMapping}
        t={t}
      />
    </div>
  );
};

// export default withTranslation('autochecker')(RulesDropdown);
export default compose(
  // connect(
  //   mapStateToProps,
  //   mapDispatchToProps
  // ),
  withTranslation('autochecker')
)(RulesDropdown);
