const checksAvailableFunc = activatedChecks =>
  [...(!!activatedChecks ? activatedChecks : [])].reduce(
    (returned, current) => {
      switch (current) {
        case 'REGULATORY':
          return { ...returned, ['isAvailable_check_eplan']: true };
        case 'BIM_DATA':
          return { ...returned, ['isAvailable_check_bim_data']: true };
        case 'BIM_QUALITY':
          return { ...returned, ['isAvailable_check_quality']: true };
        case 'CLASH_DETECTION':
          return { ...returned, ['isAvailable_check_clash']: true };

        default:
          return { ...returned, [current]: true };
      }
    },
    {}
  );

const reportTemplateFunc = availableChecks =>
  Object.keys(availableChecks)
    .filter(key => !!availableChecks[key])
    .reduce((prev, key) => {
      return [
        ...prev,
        {
          isAvailable_check_clash: 'clash',
          isAvailable_check_quality: 'quality',
          isAvailable_check_bim_data: 'bimdata',
          isAvailable_check_eplan: 'regulatory',
          isAvailableModelComparison: 'comparison'
        }[key] || key
      ];
    }, []);

export default {
  checksAvailableFunc,
  reportTemplateFunc
};
