import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
// import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import WithStripe from './withStripe';
import Message from '../../../UI/Message';
import {
  createCheckoutSessionAction,
  cancelSubscriptionAction,
  updateSubscriptionAction,
  addonUpgradeAction
} from '../../../../store/actions';

import '../style.css';

const StripeSubscriptionCheckout = compose(
  // connect(null, mapDispatchToProps),
  withTranslation([]),
  WithStripe
)(({ prices, t, sessionType }) => {
  const [loaded, setLoaded] = useState(true);
  // const location = useLocation();
  const stripe = useStripe();

  const dispatch = useDispatch();
  const createCheckoutSession = prices =>
    dispatch(createCheckoutSessionAction(prices));

  const onClickCreateCheckout = () => {
    createCheckoutSession(prices, sessionType).then(data => {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(() => {
          console.log('handle checkout session result');
        });
    });
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <Button
        id="StripeSubscriptionCheckout-confirm-button"
        onClick={onClickCreateCheckout}
        color="primary"
        disabled={!loaded}
        outline
        block>
        <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
        {!loaded && (
          <span
            className="ml-2 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </div>
  );
});

const CancelButton = compose(
  withTranslation([]),
  WithStripe
)(({ t }) => {
  const dispatch = useDispatch();
  const cancelSubscription = () => dispatch(cancelSubscriptionAction());

  const onClickCancel = async () => {
    setLoading(true);
    await cancelSubscription()
      .then(success => {
        if (success) {
          Message.success(t('subscription:subscription_canceled'));
        } else {
          Message.error('error:default_message');
        }
      })
      .catch(err => {
        Message.error(t('error:default_message'));
      });
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  return (
    <div className="w-100 d-flex justify-content-center">
      <Button
        id="CancelButton-confirm-button"
        onClick={onClickCancel}
        color="primary"
        block
        outline
        disabled={loading}>
        {!loading && (
          <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
        )}
        {loading && (
          <span
            className="ml-2 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </div>
  );
});

const CancelAndPayButton = compose(
  withTranslation([]),
  WithStripe
)(
  ({
    t,
    selectedAddon,
    setCurrentSubscription,
    setCurrentProductPlan,
    setCurrentUsage
  }) => {
    const dispatch = useDispatch();
    const cancelSubscription = data => dispatch(cancelSubscriptionAction(data));

    const onClickCancel = async () => {
      setLoading(true);
      await cancelSubscription(selectedAddon)
        .then(({ success, data }) => {
          if (success) {
            setCurrentProductPlan(data.productPlan);
            setCurrentUsage(data.usage);
            Message.success(t('subscription:subscription_canceled'));
          } else {
            Message.error(t('error:default_message'));
          }
        })
        .catch(err => {
          Message.error(t('error:default_message'));
        });
      setLoading(false);
    };
    const [loading, setLoading] = useState(false);
    return (
      <div className="w-100 d-flex justify-content-center">
        <Button
          id="CancelAndPayButton-confirm-button"
          onClick={onClickCancel}
          color="primary"
          block
          outline
          disabled={loading}>
          {!loading && (
            <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
          )}
          {loading && (
            <span
              className="ml-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>
    );
  }
);

const DowngradeButton = compose(
  withTranslation([]),
  WithStripe
)(
  ({
    t,
    currentPlan,
    selectedPlan,
    currentSubscription,
    selectedAddon,
    setCurrentSubscription,
    setCurrentProductPlan,
    setCurrentUsage
  }) => {
    const dispatch = useDispatch();
    const updateSubscription = data => dispatch(updateSubscriptionAction(data));
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
      setLoading(true);
      await updateSubscription({
        updateType: 'downgrade',
        selectedPlan,
        selectedAddon,
        currentPlan,
        currentSubscription
      })
        .then(({ success, data }) => {
          if (success) {
            setCurrentSubscription(data.subscription);
            setCurrentProductPlan(data.productPlan);
            setCurrentUsage(data.usage);

            Message.success(t('subscription:subscription_updated'));
          } else {
            Message.error(t('error:default_message'));
          }
        })
        .catch(err => {
          Message.error(t('error:default_message'));
        });
      setLoading(false);
    };

    return (
      <div className="w-100 d-flex justify-content-center">
        <Button
          id="DowngradeButton-confirm-button"
          onClick={onClick}
          color="primary"
          block
          outline>
          {!loading && (
            <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
          )}
          {loading && (
            <span
              className="ml-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>
    );
  }
);
const UpgradeButton = compose(
  withTranslation([]),
  WithStripe
)(
  ({
    t,
    currentPlan,
    selectedPlan,
    currentSubscription,
    selectedAddon,
    setCurrentSubscription,
    setCurrentProductPlan,
    setCurrentUsage
  }) => {
    const dispatch = useDispatch();
    const updateSubscription = data => dispatch(updateSubscriptionAction(data));
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
      setLoading(true);
      await updateSubscription({
        updateType: 'upgrade',
        selectedPlan,
        selectedAddon,
        currentPlan,
        currentSubscription
      })
        .then(({ success, data }) => {
          if (success) {
            setCurrentSubscription(data.subscription);
            setCurrentProductPlan(data.productPlan);
            setCurrentUsage(data.usage);
            Message.success(t('subscription:subscription_updated'));
          } else {
            Message.error(t('error:default_message'));
          }
        })
        .catch(err => {
          Message.error(t('error:default_message'));
        });
      setLoading(false);
    };
    return (
      <div className="w-100 d-flex justify-content-center">
        <Button
          id="UpgradeButton-confirm-button"
          onClick={onClick}
          color="primary"
          block
          outline>
          {!loading && (
            <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
          )}
          {loading && (
            <span
              className="ml-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>
    );
  }
);

const PayButton = compose(
  withTranslation([]),
  WithStripe
)(({ t, selectedAddon, currentSubscription, currentPlan, setCurrentUsage }) => {
  const dispatch = useDispatch();
  const addonUpgrade = data => dispatch(addonUpgradeAction(data));
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();

  const onClick = async () => {
    setLoading(true);
    await addonUpgrade({
      selectedAddon,
      currentSubscription,
      currentPlan
    })
      .then(({ success, sessionId, data }) => {
        if (success) {
          setCurrentUsage(data.usage);
          Message.success(t('subscription:subscription_updated'));
        } else if (sessionId) {
          stripe.redirectToCheckout({ sessionId: sessionId });
        } else {
          Message.error(t('error:default_message'));
        }
      })
      .catch(err => {
        Message.error('error:default_message');
      });
    setLoading(false);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <Button
        id="PayButton-confirm-button"
        onClick={onClick}
        color="primary"
        block
        outline>
        {!loading && (
          <h4 className="font-weight-bold">{t('subscription:confirm')}</h4>
        )}
        {loading && (
          <span
            className="ml-2 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </div>
  );
});

const CheckoutButton = props => {
  const {
    selectedAddon,
    selectedPlan,
    currentPlan,
    currentSubscription
  } = props;
  if (
    (currentPlan.metadata.type === 'free' || !currentPlan) &&
    selectedPlan &&
    selectedPlan.metadata.type !== 'free'
  ) {
    // CASE NEW SUBSCRIPTION
    return (
      <StripeSubscriptionCheckout
        prices={[
          selectedPlan.prices[0].id,
          ...(selectedAddon ? [selectedAddon.prices[0].id] : [])
        ]}
      />
    );
  } else if (
    currentPlan.metadata.type !== 'free' &&
    selectedPlan &&
    selectedPlan.metadata.type === 'free' &&
    !selectedAddon
  ) {
    // CASE CANCEL
    return <CancelButton />;
  } else if (
    currentPlan.metadata.type !== 'free' &&
    selectedPlan &&
    selectedPlan.metadata.type !== 'free'
  ) {
    // CASE CHANGE PLAN
    if (
      currentPlan.prices[0].unit_amount > selectedPlan.prices[0].unit_amount
    ) {
      // DOWNGRADE
      return <DowngradeButton {...props} />;
    } else {
      // UPGRADE
      return <UpgradeButton {...props} />;
    }
  } else if (
    currentPlan.metadata.type !== 'free' &&
    selectedPlan &&
    selectedPlan.metadata.type === 'free' &&
    selectedAddon
  ) {
    // CASE CANCEL PLAN AND ADD ADDON
    return <CancelAndPayButton {...props} />;
  } else if (!selectedPlan && selectedAddon) {
    // CASE ADDON ONLY
    return <PayButton {...props} />;
  }
};

export default CheckoutButton;
