import React, { Component } from 'react';
import {
  Container,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  Label,
  Col,
  Row,
  Spinner,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  CustomInput,
  Collapse,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown
} from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import {
  fetchProjectsFromUser,
  fetchAllUserPreference,
  fetchRoles
} from '../../../store/actions';
import ProjectList from './ProjectList';
import Notifications from '../Notifications';
import { debounce } from 'lodash';
import styles from './ProjectGridView.module.css';

const filterByProjectisSharedORNot = (projects, sharedWithGroup) => {
  return Object.keys(projects).filter(key =>
    sharedWithGroup
      ? projects[key].sharedWithGroup
      : !projects[key].sharedWithGroup
  );
};

const sortIcon = (sortBy, { sortNameActive, isDateAsc, isNameAsc }) => {
  return (
    <div className={styles.sortIconParentDivFontSize}>
      {sortBy === 'name' ? (
        <>
          <div
            className={`text-${
              !sortNameActive ? 'white' : isNameAsc ? 'warning' : 'white'
            } ${styles.sortIconDivFontSize}`}>
            <i className={'fas fs-1-2x fa-arrow-up'} />
          </div>
          <div
            className={`text-${
              !sortNameActive ? 'white' : !isNameAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-down'} />
          </div>
        </>
      ) : (
        <>
          <div
            className={`text-${
              sortNameActive ? 'white' : isDateAsc ? 'warning' : 'white'
            } ${styles.sortIconDivFontSize}`}>
            <i className={'fas fs-1-2x fa-arrow-up'} />
          </div>
          <div
            className={`text-${
              sortNameActive ? 'white' : !isDateAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-down'} />
          </div>
        </>
      )}
    </div>
  );
};
class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: {
        isGrid: true,
        gridActive: true,
        listActive: false
      },
      sort: {
        isDateAsc: true,
        isNameAsc: true,
        sortNameActive: true,
        sortBy: 'name'
      },
      isLoaded: false,
      filteredProjectKeys: [],
      sizePerPage: 9,
      totalSize: 0,
      currentPage: 1,
      totalProjects: 0,
      pagesCount: 0,
      from: 0,
      to: 0,
      searchFieldValue: '',
      selectedRole: [],
      isFilterOpen: false
    };
    this.handleSearchProjects = debounce(this.handleSearchProjects, 500);
  }

  handlePaginationClick = (element, index) => {
    element.preventDefault();

    this.setState(
      {
        isLoaded: false,
        currentPage: index
      },
      () => {
        const { sizePerPage, currentPage, sort } = this.state;
        const from = (currentPage - 1) * sizePerPage;
        const to = currentPage * sizePerPage;
        const pagination = { from, to };

        let order = {};
        let filters = {};

        if (this.state.searchFieldValue) {
          filters.name = this.state.searchFieldValue;
        }

        if (this.state.selectedRole.length > 0) {
          filters.role = this.state.selectedRole;
        }

        if (this.state.sort.sortBy === 'date') {
          order.field = 'date_updated';
          order.direction = this.state.sort.isDateAsc
            ? 'ascending'
            : 'descending';
        } else {
          order.field = 'name';
          order.direction = this.state.sort.isNameAsc
            ? 'ascending'
            : 'descending';
        }

        this.props
          .fetchProjectsFromUser(filters, order, pagination)
          .then(total => {
            this.setState({
              isLoaded: true,
              filteredProjectKeys: Object.keys(this.props.projects),
              totalProjects: total,
              from: from + 1,
              to
            });
          });
      }
    );

    // this.fetchProjectAndSettings();
  };

  componentDidMount() {
    this._isMounted = true;

    const { sizePerPage, currentPage } = this.state;
    const from = (currentPage - 1) * sizePerPage;
    const to = currentPage * sizePerPage;

    this.props
      .fetchAllUserPreference('dashboard_defaults')
      .then(setting => {
        if (this._isMounted) {
          const { sort, view } = this.props.userPreference.dashboard_defaults;

          this.setState({
            view: {
              isGrid: view === 'grid',
              gridActive: view === 'grid',
              listActive: view === 'list'
            },
            sort: {
              isDateAsc: sort.direction === 'ascending',
              isNameAsc: sort.direction === 'ascending',
              sortNameActive: sort.field_name === 'project_name',
              sortBy: sort.field_name === 'project_name' ? 'name' : 'date'
            },

            from: from + 1,
            to
          });
          // if (sort.field_name === 'project_name') {
          //   this.handleSortProjectsByName({ initialLoad: true });
          // } else {
          //   this.handleSortProjectsByDate({ initialLoad: true });
          // }

          let order = {};

          if (this.state.sort.sortBy === 'date') {
            order.field = 'date_updated';
            order.direction = this.state.sort.isDateAsc
              ? 'ascending'
              : 'descending';
          } else {
            order.field = 'name';
            order.direction = this.state.sort.isNameAsc
              ? 'ascending'
              : 'descending';
          }

          this.props
            .fetchProjectsFromUser({}, order, {
              from,
              to
            })
            .then(total => {
              const { sharedWithGroup, projects } = this.props;
              this.setState({
                isLoaded: true,
                filteredProjectKeys: filterByProjectisSharedORNot(
                  projects,
                  sharedWithGroup
                ),
                totalProjects: total
              });
            })
            .then(() => {
              this.props.fetchRoles();
            });
        }
      })
      .catch(err => {
        console.log(err);
        if (this._isMounted) {
          this.setState({
            isLoaded: true
          });
        }
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleList = () => {
    this.setState({
      view: {
        isGrid: false,
        gridActive: false,
        listActive: true
      }
    });
  };
  handleGrid = () => {
    this.setState({
      view: {
        isGrid: true,
        listActive: false,
        gridActive: true
      }
    });
  };

  filterProject = (property, value) => {
    const { sharedWithGroup, projects } = this.props;
    const filteredProjects = filterByProjectisSharedORNot(
      projects,
      sharedWithGroup
    );
    return Object.keys(this.props.projects)
      .map(key => {
        return this.props.projects[key];
      })
      .filter(project => {
        // const project = this.props.projects[key];
        return project[property]
          ? project[property].toLowerCase().indexOf(value.toLowerCase()) !== -1
          : true;
      })
      .sort((a, b) => {
        const { isNameAsc, isDateAsc, sortBy } = this.state.sort;
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (sortBy === 'name') {
          if (!isNameAsc) return nameA > nameB ? -1 : 1;
          else return nameA < nameB ? -1 : 1;
        } else {
          return !isDateAsc
            ? new Date(b.date_created) - new Date(a.date_created)
            : new Date(a.date_created) - new Date(b.date_created);
        }
      })
      .map(p => p.project_id)
      .filter(projectId => filteredProjects.includes(projectId));
    // console.log(filteredProjects);
    // return Object.keys(this.props.projects).filter(key => {
    //   const project = this.props.projects[key];
    //   return project[property]
    //     ? project[property].toLowerCase().indexOf(value.toLowerCase()) !== -1
    //     : true;
    // });
  };
  handleSearch = e => {
    const textValue = e.target.value;
    this.setState({
      searchFieldValue: textValue
    });

    this.handleSearchProjects();
  };

  handleFilterByRole = selectRole => {
    this.setState(
      {
        selectedRole: selectRole
      },
      () => {
        this.fetchProjectList();
      }
    );
  };

  fetchProjectList = () => {
    const pagination = { from: 0, to: this.state.sizePerPage };
    let filters = {};
    if (this.state.searchFieldValue) {
      filters.name = this.state.searchFieldValue;
    }

    if (this.state.selectedRole.length > 0) {
      filters.role = this.state.selectedRole;
    }
    let order = {};

    if (this.state.sort.sortBy === 'date') {
      order.field = 'date_updated';
      order.direction = this.state.sort.isDateAsc ? 'ascending' : 'descending';
    } else {
      order.field = 'name';
      order.direction = this.state.sort.isNameAsc ? 'ascending' : 'descending';
    }
    this.setState({
      isLoaded: false
    });

    this.props.fetchProjectsFromUser(filters, order, pagination).then(total => {
      const { sizePerPage, currentPage } = this.state;
      const from = (currentPage - 1) * sizePerPage;
      const to = currentPage * sizePerPage;
      this.setState({
        isLoaded: true,
        filteredProjectKeys: Object.keys(this.props.projects),
        totalProjects: total,
        currentPage: 1,
        from: total > 0 ? from + 1 : total,
        to: total > 0 ? to : 0
      });
    });
  };

  handleSearchProjects = (textValue, i) => {
    this.fetchProjectList();
  };
  handleSortProjectsByName = ({ initialLoad = false }) => {
    this.setState(
      {
        sort: {
          sortBy: 'name',
          isNameAsc: initialLoad
            ? this.state.sort.isNameAsc
            : !this.state.sort.isNameAsc,
          sortNameActive: true
        }
      },
      () => {
        this.fetchProjectList();
      }
    );
  };
  handleSortProjectsByDate = ({ initialLoad = false }) => {
    this.setState(
      {
        sort: {
          sortBy: 'date',
          isDateAsc: initialLoad
            ? this.state.sort.isDateAsc
            : !this.state.sort.isDateAsc,
          sortNameActive: false
        }
      },
      () => {
        this.fetchProjectList();
      }
    );
  };
  sortProjectList = newState => {
    const filteredProject = Object.keys(this.props.projects)
      .map(key => {
        return newState.filter(id => {
          return this.props.projects[key].project_id === id;
        });
      })
      .filter(f => f[0] !== undefined);
    const sortedProjectList = [
      ...filteredProject.map(b => this.props.projects[b[0]])
    ];

    sortedProjectList.sort((a, b) => {
      const { isNameAsc, isDateAsc, sortBy } = this.state.sort;
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortBy === 'name') {
        if (isNameAsc) {
          // if nameA is greater than nameB then we assign the nameB to display before nameA
          return nameA > nameB ? 1 : -1;
        } else {
          // if descending
          // if nameA is greater than nameB then we assign the nameA to display before nameB
          return nameA > nameB ? -1 : 1;
        }
      } else {
        if (isDateAsc) {
          // date of b should display first before date of a
          return new Date(a.date_updated) - new Date(b.date_updated);
        } else {
          // if descending
          // date of a should display 1st before date of b
          return new Date(b.date_updated) - new Date(a.date_updated);
        }
      }
    });

    return sortedProjectList.map(project => project.project_id);
  };

  toggleFilters = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen
    });
  };
  render() {
    const { projects, t } = this.props;
    const {
      filteredProjectKeys,
      sort,
      isFilterOpen,
      selectedRole
    } = this.state;

    return (
      <>
        {/* {this.state.isLoaded && ( */}
        <Container>
          {/* {this.state.isLoaded &&  Object.keys(projects).length >= 0 && ( */}
          <div className="mb-2 pt-4">
            <Row className="px-4">
              <ButtonGroup>
                <Button
                  color="secondary"
                  id="gridViewButton"
                  onClick={this.handleGrid}
                  active={this.state.gridActive}>
                  <i className="fas fs-1-2x fa-th-large" />
                  <UncontrolledTooltip
                    fade={false}
                    target="gridViewButton"
                    delay={0}
                    modifiers={{
                      computeStyle: {
                        gpuAcceleration: false
                      }
                    }}>
                    {t('dashboard:grid')}
                  </UncontrolledTooltip>
                </Button>
                <Button
                  color="secondary"
                  id="listViewButton"
                  onClick={this.handleList}
                  active={this.state.listActive}>
                  <i className="fas fs-1-2x fa-list" />
                  <UncontrolledTooltip
                    fade={false}
                    target="listViewButton"
                    delay={0}
                    modifiers={{
                      computeStyle: {
                        gpuAcceleration: false
                      }
                    }}>
                    {t('dashboard:list')}
                  </UncontrolledTooltip>
                </Button>
                <Button
                  color="secondary"
                  id="sortByProjectNameButton"
                  onClick={this.handleSortProjectsByName}>
                  <Row>
                    <Col className="pr-1">
                      <i className="fas fs-1-2x fa-building" />
                    </Col>
                    <Col className="pl-0">{sortIcon('name', sort)}</Col>
                  </Row>
                  <UncontrolledTooltip
                    fade={false}
                    target="sortByProjectNameButton"
                    delay={0}
                    modifiers={{
                      computeStyle: {
                        gpuAcceleration: false
                      }
                    }}>
                    {t('dashboard:project_name_sort')}
                  </UncontrolledTooltip>
                </Button>
                <Button
                  color="secondary"
                  id="sortByDateUpdatedButton"
                  onClick={this.handleSortProjectsByDate}>
                  <Row>
                    <Col className="pr-1">
                      <i className="far fs-1-2x fa-calendar" />
                    </Col>
                    <Col className="pl-0">{sortIcon('date', sort)}</Col>
                  </Row>
                  <UncontrolledTooltip
                    fade={false}
                    target="sortByDateUpdatedButton"
                    delay={0}
                    modifiers={{
                      computeStyle: {
                        gpuAcceleration: false
                      }
                    }}>
                    {t('dashboard:sort_by_date_updated')}
                  </UncontrolledTooltip>
                </Button>
              </ButtonGroup>
              <div className={'ml-2 flex-grow-1'}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={styles.searchIconHeight}>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Project Name"
                    onChange={this.handleSearch}
                    value={this.state.searchFieldValue}
                    autoFocus={!!this.state.searchFieldValue}
                  />
                  <InputGroupButtonDropdown
                    addonType="append"
                    isOpen={isFilterOpen}
                    toggle={this.toggleFilters}>
                    <DropdownToggle
                      caret
                      id={styles.roleDropdownOption}
                      color="light">
                      <i className="fas fa-filter mr-2" />
                      {t('dashboard:filter_by_role')}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.props.roles.map(role => {
                        return (
                          <DropdownItem
                            key={`role-option-${role}`}
                            active={selectedRole[0] === role}
                            onClick={() => {
                              this.handleFilterByRole([role]);
                            }}>
                            {t(`main:role_${role}`)}
                          </DropdownItem>
                        );
                      })}
                      <DropdownItem
                        className="text-muted"
                        // tag={Button}
                        // color="light"
                        onClick={() => this.handleFilterByRole([])}>
                        <div className="d-flex align-content-start justify-content-start">
                          <i className="fas fa-times mr-3"></i>{' '}
                          <span>{t('clear')}</span>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                </InputGroup>
              </div>

              <Link
                to={'/project/create'}
                className={'linkTextDecoration ml-2'}>
                <Button color="warning">
                  <i className="fas fa-plus" /> {t('dashboard:create_project')}
                </Button>
              </Link>
            </Row>

            {this.state.isLoaded && Object.keys(projects).length >= 0 && (
              <ProjectList
                projects={projects}
                filteredProjectKeys={filteredProjectKeys}
                view={this.state.view}
                userFunction={this.props.authUser.function}
                currentPage={this.state.currentPage}
                totalProjects={this.state.totalProjects}
                sizePerPage={this.state.sizePerPage}
                pagesCount={this.state.pagesCount}
                from={this.state.from}
                to={this.state.to}
                handlePaginationClick={this.handlePaginationClick}
              />
            )}
            {!this.state.isLoaded && (
              <Container>
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.spinerParentHeight}`}>
                  <Spinner
                    type="grow"
                    className={styles.spinerDimention}
                    color="primary"
                  />
                </div>
              </Container>
            )}
          </div>
        </Container>
        {/* )} */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    authUser: state.authUser,
    userPreference: state.userPreference,
    roles: state.roles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchProjectsFromUser: (filters, order, pagination) =>
      dispatch(fetchProjectsFromUser(filters, order, pagination)),
    fetchAllUserPreference: type => dispatch(fetchAllUserPreference(type)),
    fetchRoles: () => dispatch(fetchRoles())
  };
};
export default compose(
  withTranslation(['dashboard']),
  connect(mapStateToProps, mapDispatchToProps)
)(ProjectDashboard);
