export default (state, action) => {
  switch (action.type) {
    case 'DELETE_CATEGORY': {
      const filteredKeys = Object.keys(state.resultList).filter(
        key => state.resultList[key].categoryId !== action.categoryId
      );
      return {
        ...state,
        resultList: filteredKeys.reduce((acc, catName) => {
          return { ...acc, [catName]: state.resultList[catName] };
        }, {})
      };
    }
    case 'TOGGLE_FAVORITE': {
      const index =
        state.resultList[action.categoryName] &&
        state.resultList[action.categoryName].list.findIndex(
          li => li.category_item_id === action.categoryItemId
        );
      return {
        ...state,
        favoriteResultList: {
          ...state.favoriteResultList,
          [action.categoryName]: {
            categoryId: action.categoryId,
            categoryName: action.categoryName,
            list: state.favoriteResultList[action.categoryName]
              ? action.isFavorite
                ? [
                    ...state.favoriteResultList[action.categoryName].list,
                    state.resultList[action.categoryName].list[index]
                  ]
                : state.favoriteResultList[action.categoryName].list.filter(
                    li => li.category_item_id !== action.categoryItemId
                  )
              : [state.resultList[action.categoryName].list[index]]
          }
        }
      };
    }
    case 'FETCH_FAVORITE_CATEGORY_ITEMS':
      return { ...state, favoriteResultList: action.favorites };
    case 'UPDATE_CATEGORY': {
      const foundKey = Object.keys(state.resultList).find(
        key =>
          state.resultList[key].categoryId === action.newCategoryData.categoryId
      );
      const filteredKeys = Object.keys(state.resultList).filter(
        key =>
          state.resultList[key].categoryId !== action.newCategoryData.categoryId
      );
      return {
        ...state,
        resultList: {
          [action.newCategoryData.name]: {
            ...state.resultList[foundKey],
            categoryName: action.newCategoryData.name,
            list: state.resultList[foundKey].list.map(item => ({
              ...item,
              groupname: action.newCategoryData.name
            }))
          },
          ...filteredKeys.reduce(
            (prev, currentKey) => ({
              ...prev,
              [currentKey]: state.resultList[currentKey]
            }),
            {}
          )
        }
      };
    }
    case 'UPDATE_CATEGORY_ITEM': {
      const index = state.resultList[action.groupname].list.findIndex(
        item =>
          item.category_item_id === action.newCategoryItemData.category_item_id
      );
      const { groupname, files } = state.resultList[action.groupname].list[
        index
      ];
      return {
        ...state,
        resultList: {
          ...state.resultList,
          [action.groupname]: {
            ...state.resultList[action.groupname],
            list: [
              ...state.resultList[action.groupname].list.slice(0, index),
              {
                ...action.newCategoryItemData,
                groupname,
                files
              },
              ...state.resultList[action.groupname].list.slice(index + 1)
            ]
          }
        }
      };
    }
    case 'OPEN_CONFIRM_DELETE_ITEM_MODAL':
      return {
        ...state,
        confirmDeleteCatItemData: {
          ...state.confirmDeleteCatItemData,
          isOpen: action.isOpen,
          categoryId: action.categoryId,
          categoryItemId: action.categoryItemId,
          title: action.title
        }
      };
    case 'DELETE_CATEGORY_ITEM': {
      const propertyKey = Object.keys(state.resultList).filter(itemKey =>
        state.resultList[itemKey].list.find(
          item =>
            item.categoryId === action.categoryId &&
            item.category_item_id === action.categoryItemId
        )
      );
      const favPropKey = Object.keys(state.favoriteResultList).filter(itemKey =>
        state.favoriteResultList[itemKey].list.find(
          item =>
            item.categoryId === action.categoryId &&
            item.category_item_id === action.categoryItemId
        )
      );
      return {
        ...state,
        resultList: {
          ...state.resultList,
          [propertyKey[0]]: {
            ...state.resultList[propertyKey[0]],
            list: state.resultList[propertyKey[0]].list.filter(item => {
              return item.category_item_id !== action.categoryItemId;
            })
          }
        },
        favoriteResultList: !!favPropKey.length
          ? {
              ...state.favoriteResultList,
              [favPropKey[0]]: {
                ...state.favoriteResultList[favPropKey[0]],
                list: state.favoriteResultList[favPropKey[0]].list.filter(
                  item => {
                    return item.category_item_id !== action.categoryItemId;
                  }
                )
              }
            }
          : state.favoriteResultList
      };
    }
    case 'REMOVE_FILE_FROM_NEW_CAT_ITEMS': {
      const index = state.newCategoryItems.findIndex(
        item => item.id === action.itemId
      );
      return {
        ...state,
        newCategoryItems: [
          ...state.newCategoryItems.slice(0, index),
          {
            ...state.newCategoryItems[index],
            files: state.newCategoryItems[index].files.filter(
              (_, i) => i !== action.fileIndex
            )
          },
          ...state.newCategoryItems.slice(index + 1)
        ]
      };
    }
    case 'SET_FILES_UPLOAD_FOR_NEW_CAT_ITEM': {
      const index = state.newCategoryItems.findIndex(
        item => item.id === action.itemId
      );
      return {
        ...state,
        newCategoryItems: [
          ...state.newCategoryItems.slice(0, index),
          {
            ...state.newCategoryItems[index],
            files: state.newCategoryItems[index].files
              ? [...state.newCategoryItems[index].files, ...action.files]
              : action.files
          },
          ...state.newCategoryItems.slice(index + 1)
        ]
      };
    }
    case 'SET_IS_ADDING_ITEMS_IN_EXISTING_CAT':
      return {
        ...state,
        isAddingItemsInExistingCategory: action.isAdding
      };
    case 'RESET_NEW_CATEGORY_ITEM_LIST':
      return {
        ...state,
        newCategoryItems: [],
        newCategoryItemsCount: 0
      };
    case 'ADD_CATEGORY_ITEMS':
      return {
        ...state,
        resultList: {
          ...state.resultList,
          [action.groupname]: {
            ...state.resultList[action.groupname],
            list: state.resultList[action.groupname].list
              ? [
                  ...action.newCategoryItems,
                  ...state.resultList[action.groupname].list
                ]
              : action.newCategoryItems
          }
        }
      };
    case 'ADD_CATEGORY':
      return {
        ...state,
        resultList: {
          ...state.resultList,
          [action.newCategoryName]: state.resultList[action.newCategoryName]
            ? {
                categoryId: action.categoryId,
                categoryName: action.newCategoryName,
                list: state.resultList[action.newCategoryName].list
              }
            : {
                categoryId: action.categoryId,
                categoryName: action.newCategoryName
              }
        }
      };
    case 'REMOVE_NEW_CATEGORY_ITEM': {
      return {
        ...state,
        newCategoryItems: state.newCategoryItems.filter(
          item => item.id !== action.itemId
        )
      };
    }
    case 'ADD_CATEGORY_ITEM':
      return {
        ...state,
        newCategoryItems: [
          ...state.newCategoryItems,
          { ...action.newItem, id: state.newCategoryItemsCount + 1 }
        ],
        newCategoryItemsCount: state.newCategoryItemsCount + 1
      };
    case 'SET_IS_CATEGORY_MODAL_OPEN':
      return {
        ...state,
        isAddCategoryModalOpen: action.isOpen
      };
    case 'SET_IS_SEARCHING':
      return { ...state, isSearching: action.isSearching };
    case 'SET_SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.category,
        selectedCategoryKey: action.selectedCategoryKey
      };
    case 'SET_TEXT_COPIED':
      return { ...state, textCopied: action.text };
    case 'SEARCH_BY_TITLE':
      return { ...state, resultList: action.newResultList };
    case 'SET_SEARCH_TEXT':
      return { ...state, searchText: action.search };
    default:
      return state;
    case 'SET_FILES_UPLOAD':
      return {
        ...state,
        resultList: state.resultList[action.category]
          ? {
              ...state.resultList,
              [action.category]: {
                ...state.resultList[action.category],
                list: state.resultList[action.category]
                  ? state.resultList[action.category].list.map((content, i) =>
                      i === action.key
                        ? {
                            ...content,
                            files: content.files
                              ? [
                                  ...content.files,
                                  ...action.files.map(file => ({
                                    name: file.name,
                                    size: `${file.size} bytes`,
                                    type: file.type,
                                    is_new: true,
                                    fileInput: file,
                                    uploaded_by: action.uploaded_by
                                  }))
                                ]
                              : [
                                  ...action.files.map(file => ({
                                    name: file.name,
                                    size: `${file.size / 1000} KB`,
                                    type: file.type,
                                    createdBy: action.createdBy,
                                    is_new: true,
                                    fileInput: file,
                                    uploaded_by: action.uploaded_by
                                  }))
                                ]
                          }
                        : content
                    )
                  : []
              }
            }
          : state.resultList,
        favoriteResultList: state.favoriteResultList[action.category]
          ? {
              ...state.favoriteResultList,
              [action.category]: {
                ...state.favoriteResultList[action.category],
                list: state.favoriteResultList[action.category].list.map(
                  (content, i) =>
                    i === action.key
                      ? {
                          ...content,
                          files: content.files
                            ? [
                                ...content.files,
                                ...action.files.map(file => ({
                                  name: file.name,
                                  size: `${file.size} bytes`,
                                  type: file.type,
                                  is_new: true,
                                  fileInput: file,
                                  uploaded_by: action.uploaded_by
                                }))
                              ]
                            : [
                                ...action.files.map(file => ({
                                  name: file.name,
                                  size: `${file.size / 1000} KB`,
                                  type: file.type,
                                  createdBy: action.createdBy,
                                  is_new: true,
                                  fileInput: file,
                                  uploaded_by: action.uploaded_by
                                }))
                              ]
                        }
                      : content
                )
              }
            }
          : state.favoriteResultList
      };
    case 'REMOVE_FILE_ITEM':
      const Files =
        state.resultList[action.category] &&
        state.resultList[action.category].list[action.categoryItemIndex].files;

      const FilesFav =
        state.favoriteResultList[action.category] &&
        state.favoriteResultList[action.category].list[action.categoryItemIndex]
          .files;
      const Clonefiles = Files && [...Files];
      Clonefiles && Clonefiles.splice(action.fileIndex, 1);

      const ClonefilesFav = FilesFav && [...FilesFav];
      ClonefilesFav && ClonefilesFav.splice(action.fileIndex, 1);
      return {
        ...state,
        resultList: state.resultList[action.category]
          ? {
              ...state.resultList,
              [action.category]: {
                ...state.resultList[action.category],
                list: state.resultList[action.category]
                  ? state.resultList[action.category].list.map((content, i) =>
                      i === action.categoryItemIndex
                        ? { ...content, files: Clonefiles }
                        : content
                    )
                  : []
              }
            }
          : state.resultList,
        favoriteResultList: state.favoriteResultList[action.category]
          ? {
              ...state.favoriteResultList,
              [action.category]: {
                ...state.favoriteResultList[action.category],
                list: state.favoriteResultList[
                  action.category
                ].list.map((content, i) =>
                  i === action.categoryItemIndex
                    ? { ...content, files: ClonefilesFav }
                    : content
                )
              }
            }
          : state.favoriteResultList
      };
    case 'FILES_UPLOADED':
      return {
        ...state,
        resultList: {
          ...state.resultList,
          [action.categoryName]: {
            ...state.resultList[action.categoryName],
            list: state.resultList[action.categoryName].list.map((content, i) =>
              content.category_item_id === action.categoryItemId
                ? {
                    ...content,
                    files: action.newFilesUploaded
                  }
                : content
            )
          }
        },
        favoriteResultList: state.favoriteResultList[action.categoryName]
          ? {
              ...state.favoriteResultList,
              [action.categoryName]: {
                ...state.favoriteResultList[action.categoryName],
                list: state.favoriteResultList[action.categoryName].list.map(
                  (content, i) =>
                    content.category_item_id === action.categoryItemId
                      ? {
                          ...content,
                          files: action.newFilesUploaded
                        }
                      : content
                )
              }
            }
          : state.favoriteResultList
      };
    case 'SET_IS_IMPORT_FILE_MODAL_OPEN':
      return {
        ...state,
        isImportFileModalOpen: action.isOpen
      };
  }
};
