import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import ModelIssueListPresenter from './ModelIssueListPresenter';
import {
  fetchAllModelIssues,
  resetModelIssueList,
  fetchModelIssueDetails,
  addAttachments,
  deleteModelIssue
} from '../../../store/actions';

const mapStateToProps = state => {
  return {
    modelIssues: state.modelIssues
  };
};
const mapDispatchToProps = dispatch => {
  return {
    modelIssueActions: {
      fetchAllModelIssues: (projectId, stageName) =>
        dispatch(fetchAllModelIssues(projectId, stageName)),
      resetModelIssueList: () => dispatch(resetModelIssueList()),
      fetchModelIssueDetails: (projectId, stageName, issueId) =>
        dispatch(fetchModelIssueDetails(projectId, stageName, issueId)),
      addAttachments: file => dispatch(addAttachments(file)),
      deleteModelIssue: (projectId, stageName, issueIds) =>
        dispatch(deleteModelIssue(projectId, stageName, issueIds))
    }
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ModelIssueListPresenter);
