import React, { Component } from 'react';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCollapse,
  ListGroupItem,
  ListGroup,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { dateFormatting } from '../../../locales/dateFormat';

class ProjectListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseSubmenu: false
    };
  }

  toggleSubmenu = () => {
    this.setState({ collapseSubmenu: !this.state.collapseSubmenu });
  };

  countMembers = (role, members) => {
    return members.filter(m => m.role === role).length;
  };

  render() {
    const {
      projects,
      t,
      filteredProjectKeys,
      userFunction,
      featureList,
      currentTile
    } = this.props;

    const isSubmissionEnabled = featureList.officerSubmission;
    const isModelIssueEnabled = featureList.modelIssue;
    const isReferenceDocumentEnabled = featureList.documentReference;
    const isTaskManagementEnabled = featureList.taskManagement;
    const isStatisticsEnabled = featureList.projectStatistics;

    const quickAccessDropdownItems = translate => {
      return [
        { label: translate('dashboard:go_to_details'), tab: 'details' },
        ...(isReferenceDocumentEnabled
          ? [
              {
                label: translate('dashboard:go_to_documents'),
                tab: 'documents'
              }
            ]
          : []),

        ...(isModelIssueEnabled
          ? [{ label: translate('dashboard:go_to_issue'), tab: 'issue' }]
          : []),

        ...(isSubmissionEnabled
          ? [
              {
                label: translate('dashboard:go_to_submission'),
                tab: 'submission'
              }
            ]
          : []),

        ...(isTaskManagementEnabled
          ? [{ label: translate('dashboard:go_to_task'), tab: 'task' }]
          : []),

        ...(isStatisticsEnabled
          ? [
              {
                label: translate('dashboard:go_to_statistics'),
                tab: 'statistics'
              }
            ]
          : [])
        //Add additional obj for another quick access to project
      ];
    };

    const isTableResponsive =
      currentTile.tileSize === 'half' || currentTile.tileSize === 'quarter';

    return (
      <Table
        hover
        bordered
        striped
        responsive={isTableResponsive}
        className="text-center table-responsive-lg">
        <thead>
          <tr>
            <th>{t('dashboard:project_name')}</th>
            <th>{t('dashboard:date_created')}</th>
            <th>{t('dashboard:date_updated')}</th>
            <th>{t('dashboard:members')}</th>
            <th>{t('dashboard:status')}</th>
            <th>{t('dashboard:quick_access')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredProjectKeys.map(key => {
            const project = projects[key];
            return (
              <tr key={key}>
                <td>
                  <span>{project.name}</span>
                </td>
                <td>{dateFormatting(project.date_created)}</td>
                <td>{dateFormatting(project.date_updated)}</td>
                <td>
                  <span>{t('owner')}: </span>
                  <span className="text-info font-weight-bold">
                    {this.countMembers('owner', project.members)}
                  </span>
                  , <span>{t('members')}: </span>
                  <span className="text-info font-weight-bold">
                    {this.countMembers('member', project.members)}
                  </span>
                </td>
                <td>
                  <span>
                    <span className="text-info font-weight-bold">
                      {project.stage_submitted_count || 0}
                    </span>{' '}
                    out of{' '}
                    <span className="text-info font-weight-bold">
                      {project.stagesavailable.length}
                    </span>{' '}
                    were submitted
                  </span>
                </td>
                <td>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="primary"
                      className="btn-sm w-100">
                      {t('go_to')}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem id="toggler" toggle={false}>
                        {t('stages')} <i className={'fas fa-caret-down'} />
                      </DropdownItem>
                      <UncontrolledCollapse
                        toggler="#toggler"
                        className="text-muted">
                        <ListGroup>
                          {project.stagesavailable.map((stage, index) => {
                            return (
                              <Link
                                to={`${
                                  userFunction === 'ADMIN' ? '/admin' : ''
                                }/project/${key}/models/${stage.name}`}
                                key={index}
                                className="text-decoration-none">
                                <ListGroupItem
                                  className="pt-1 pb-1 pl-5 border-0"
                                  action>
                                  {stage.display_name}
                                </ListGroupItem>
                              </Link>
                            );
                          })}
                        </ListGroup>
                      </UncontrolledCollapse>
                      {quickAccessDropdownItems(t).map((item, index) => {
                        return (
                          <Link
                            to={`${
                              userFunction === 'ADMIN' ? '/admin' : ''
                            }/project/${key}/${item.tab}`}
                            className="text-decoration-none"
                            key={index}>
                            <DropdownItem toggle={false}>
                              {item.label}
                            </DropdownItem>
                          </Link>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default ProjectListView;
