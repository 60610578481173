import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Container,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Badge,
  Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';

import Switch from '../../UI/Switch';
import Message from '../../UI/Message';

import {
  revertToRuleHistoryAction,
  getConcurrencyControlVersionId
} from '../../../store/actions';

import './styles.css';

const RuleParameterModal = ({
  t,
  ruleId,
  rule,
  rules,
  open,
  toggle,
  ruleType,
  revertToRuleHistoryAction,
  level,
  ruleHistoryId,
  ruleIdAsHistoryId,
  setContentIsLoaded,
  setRuleHistoryModal
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [iframeOnError, setIframeOnError] = useState(false);
  const [iframeContent, setIframeContent] = useState('');
  const [isOverDescription, setHoverDescription] = useState(true);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(true);

  const formikConfig = {
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema : {}
    // validate: () => {}
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setContentIsLoaded(false);
      try {
        await revertToRuleHistoryAction({
          rule_level: level,
          rule_id: ruleId,
          rule_type: ruleType,
          rule_history_id: ruleHistoryId
        });

        toggle(ruleHistoryId, true);
        Message.success(t('rule_history:rule_successfully_reverted'));
      } catch (err) {
        Message.error(
          t(`error:${err.message}` || 'error:default_message', {
            entity: 'rule'
          })
        );
      } finally {
        setContentIsLoaded(true);
        setSubmitting(false);
        // setContentIsLoaded(true);
        // setSubmitting(false);
        // // toggle(ruleHistoryId, true);
        // setRuleHistoryModal(false);
      }
    }
  };

  const fetchDescription = async () => {
    setIsLoaded(false);
    try {
      const content = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT[
            process.env.REACT_APP_API_ENDPOINT.length - 1
          ] == '/'
            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
            : process.env.REACT_APP_API_ENDPOINT
        }/api/rules/description/${rule.ruleId}?level=${level}&history_id=${
          ruleIdAsHistoryId ? '' : ruleHistoryId
        }`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      ).then(res => {
        return res.text();
      });

      setIframeContent(content);
      setIsLoaded(true);
    } catch (err) {
      setIframeOnError(true);
    }
  };
  const descriptionLink = rule && rule.descriptionLink;
  useEffect(() => {
    if (open) {
      fetchDescription();
    }
  }, [open, descriptionLink]);
  return (
    <div>
      <Formik {...formikConfig}>
        {formikProps => {
          const { values, setFieldValue, handleSubmit, isSubmitting } =
            formikProps;
          return (
            <Modal
              isOpen={open}
              toggle={() => {
                toggle();
              }}
              size="xl">
              <>
                {open && (
                  <>
                    <ModalHeader
                      toggle={() => {
                        toggle();
                      }}>
                      {rule.title}
                    </ModalHeader>
                    <ModalBody>
                      <form>
                        <div className="d-flex flex-row justify-content-between h-100 flex-grow-1">
                          <div
                            className={`w-100 border mr-2 ${
                              isDescriptionExpanded
                                ? 'overflow-auto'
                                : 'overflow-hidden'
                            }`}>
                            {rule.dependencies && rule.dependencies.length > 0 && (
                              <>
                                <h4 className="text-primary">
                                  {t('rules:dependencies')}{' '}
                                </h4>
                                <ListGroup>
                                  {rule.dependencies.map(
                                    (dependency, index) => {
                                      return (
                                        <ListGroupItem
                                          key={`dependency-${index}`}>
                                          <div className="d-flex">
                                            <div>
                                              {rules[dependency.value]
                                                ? `${
                                                    rules[dependency.value]
                                                      .title
                                                  } (id: ${dependency.value})`
                                                : dependency.value}
                                            </div>
                                            {dependency.optional && (
                                              <div className="ml-2 border rounded text-gray font-weight-bold border-gray border-very-thick py-1 px-2">
                                                {t('rules:optional')}
                                              </div>
                                            )}
                                          </div>
                                        </ListGroupItem>
                                      );
                                    }
                                  )}
                                </ListGroup>
                              </>
                            )}
                            {!iframeOnError && iframeContent !== '' && (
                              <div className="h-100">
                                <div
                                  className={classnames(
                                    'iframeContentContainer',
                                    {
                                      'h-100 overflow-auto':
                                        isDescriptionExpanded,
                                      'overflow-hidden': !isDescriptionExpanded
                                    }
                                  )}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: iframeContent
                                    }}></div>

                                  <div
                                    className={
                                      isDescriptionExpanded
                                        ? 'iframeContentDescriptionExpanded'
                                        : 'iframeContentDescriptionCollapsed'
                                    }
                                    // onMouseEnter={() =>
                                    //   setHoverDescription(true)
                                    // }
                                    // onMouseLeave={() =>
                                    //   setHoverDescription(false)
                                    // }
                                  >
                                    {isOverDescription && (
                                      <div className="d-flex overDescriptionContainer mt-4">
                                        <Link
                                          target="_blank"
                                          to={`/admin/rules/${level}/${rule.level}/${ruleType}/${rule.ruleId}/rule_history/${ruleHistoryId}`}
                                          className="overDescriptionLink"
                                          onClick={e => {
                                            e.stopPropagation();
                                          }}>
                                          <i
                                            className="fas fa-external-link-alt fs-1-8x "
                                            title={`${t('main:edit')} ${
                                              rule.title
                                            }`}
                                          />
                                        </Link>
                                        <div
                                          className="ml-2 pointer"
                                          onClick={() =>
                                            setDescriptionExpanded(
                                              !isDescriptionExpanded
                                            )
                                          }>
                                          <i
                                            className={classnames(
                                              'fs-1-8x fas',
                                              {
                                                'fa-expand':
                                                  !isDescriptionExpanded,
                                                'fa-compress':
                                                  isDescriptionExpanded
                                              }
                                            )}
                                            title={t('mian:expand')}></i>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-row justify-content-end align-items-start"></div>
                        </div>
                      </form>
                    </ModalBody>
                  </>
                )}
              </>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isLoaded}>
                  <i className="fas fa-undo mr-2" />
                  Revert to this version
                </Button>{' '}
                <Button
                  color="secondary"
                  onClick={() => {
                    toggle();
                  }}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};

const RuleParameterModalContainer = ({ ...props }) => {
  return <RuleParameterModal {...props} />;
};

const mapStateToProps = (state, props) => {
  if (props.level === 'domain') {
    const rules = state.rules_admin.rules;
    return {
      rules,
      rule: state.rule_history_admin[Object.keys(state.rule_history_admin)[0]]
    };
  } else if (props.level === 'user') {
    const rules = state.rules.rulesSet;
    return {
      rules,
      rule: state.rule_history_admin[Object.keys(state.rule_history_admin)[0]]
    };
  } else if (props.level === 'organization') {
    // TODO
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    revertToRuleHistoryAction: ({
      rule_level,
      rule_id,
      rule_type,
      rule_history_id
    }) =>
      dispatch(
        revertToRuleHistoryAction({
          rule_level,
          rule_id,
          rule_type,
          rule_history_id
        })
      ),
    getConcurrencyControlVersionId: (entityType, entityTargetId) =>
      dispatch(getConcurrencyControlVersionId(entityType, entityTargetId))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RuleParameterModalContainer
);
