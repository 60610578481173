import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from 'reactstrap';
import knowledgehubContext from '../store/config/context';
import { withTranslation } from 'react-i18next';
import { openComfirmDeleteModal, deleteCategoryItem } from '../store/actions';
import Message from '../components/shared/Message';

const ConfirmDeleteCatItemModal = props => {
  const { state, dispatch } = useContext(knowledgehubContext);
  const {
    isOpen,
    categoryId,
    categoryItemId,
    title
  } = state.confirmDeleteCatItemData;
  const handleOpenComfirmDeleteModal = () => {
    dispatch(openComfirmDeleteModal(!isOpen, null, null, null));
  };
  return (
    <Modal isOpen={isOpen} toggle={handleOpenComfirmDeleteModal}>
      <ModalHeader toggle={handleOpenComfirmDeleteModal}>
        {props.t('delete')}
      </ModalHeader>
      <ModalBody className="text-center">
        {props.t('would_like_to_delete')} <Badge color="white">{title}</Badge>?
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            dispatch(deleteCategoryItem(categoryId, categoryItemId)).then(
              res => {
                if (res.success) {
                  Message.success(
                    props.t('delete_successfully', {
                      categoryName: title
                    })
                  );
                } else {
                  Message.error(
                    props.t('failed_to_delete', {
                      categoryName: title
                    })
                  );
                }
              }
            );
            handleOpenComfirmDeleteModal();
          }}>
          {props.t('yes')}
        </Button>
        <Button color="secondary" onClick={handleOpenComfirmDeleteModal}>
          {props.t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation(['knowledgeHub'])(ConfirmDeleteCatItemModal);
