import React, { Component } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Progress
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import prettyBytes from 'pretty-bytes';
class DiskUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDocumentSize: 0,
      projectLimitSize: 0
    };
  }

  render() {
    const {
      t,
      project,
      deleteStage,
      displayAlongProjectList,
      projectLimitSize
    } = this.props;

    const { total_file_size } = project;

    const projectFileSizeProgressBar =
      (total_file_size * 100) / projectLimitSize;

    const proceedToRender = total_file_size && projectLimitSize;

    return proceedToRender ? (
      <div className="">
        {displayAlongProjectList && (
          <div className="d-flex">
            <Col sm={4}>
              <div className="text-center">
                {/* <i className="far fa-hdd text-info"></i> */}
                <Progress
                  className="mt-1"
                  color={projectFileSizeProgressBar >= 70 ? 'danger' : 'info'}
                  value={projectFileSizeProgressBar}
                />
              </div>
            </Col>
            <Col sm={9}>
              <span className="text-left text-muted  mt-3 font-weight-bold">
                {prettyBytes(total_file_size)} of{' '}
                {prettyBytes(projectLimitSize)} used
              </span>
            </Col>
          </div>
        )}
        {!displayAlongProjectList && (
          <Row className="mt-4">
            <Col sm={2}>
              <div className="text-center">
                <h2>
                  <i className="far fa-hdd text-info"></i>
                </h2>
              </div>
            </Col>
            <Col sm={10}>
              <Progress
                color={projectFileSizeProgressBar >= 70 ? 'danger' : 'info'}
                value={projectFileSizeProgressBar}
              />

              <h6 className="text-left text-muted font-weight-bold mt-3">
                {prettyBytes(total_file_size)} of{' '}
                {prettyBytes(projectLimitSize)} used
              </h6>
            </Col>{' '}
          </Row>
        )}
      </div>
    ) : (
      <div>{t('no_data')}</div>
    );
  }
}
const mapStateToProps = state => {
  const allowedSizePerProject = state.app.allowed_project_file_size;
  return {
    projectLimitSize:
      allowedSizePerProject ||
      (state.admin_configuration.app &&
        state.admin_configuration.app.document_management_allowed_size)
  };
};

const mapDispatchToProps = state => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['project', 'main'])
)(DiskUsage);
