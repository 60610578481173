export const createMarks = totalSize => {
  return new Array(11).fill(1).reduce((prev, v, i) => {
    let step = Math.floor((totalSize / 10) * i) + 1;
    if (step > totalSize) {
      step = totalSize;
    }
    return {
      ...prev,
      [step]: {
        label: step
      }
    };
  }, {});
};
