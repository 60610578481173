import { newsActionType } from '../../constants/actionType';
const initialState = {};

const {
  MERGE_NEWS,
  ADD_NEWS,
  UPDATE_NEWS,
  REMOVE_NEWS,
  RESET_NEWS,
  EXPAND_NEWS,
  ADMIN_MERGE_NEWS,
  FRONTPAGE_MERGE_NEWS
} = newsActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FRONTPAGE_MERGE_NEWS: {
      return {
        ...state,
        ...action.newsMap.reduce((acc, curr) => {
          return { ...acc, [curr.news_id]: curr };
        }, {})
      };
    }
    case MERGE_NEWS:
      return {
        ...state,
        ...action.newsMap.reduce((acc, curr) => {
          return { ...acc, [curr.news_id]: curr };
        }, {})
      };
    case UPDATE_NEWS:
      return { ...state, [action.news.news_id]: action.news };
    case ADD_NEWS:
      return { [action.news.news_id]: action.news, ...state };
    case REMOVE_NEWS: {
      const { [action.newsId]: _, ...nextState } = state;
      return nextState;
    }
    case EXPAND_NEWS: {
      const newsIndex = Object.keys(state).find(
        key => state[key].news_id === action.newsId
      );
      return {
        ...state,
        [newsIndex]: {
          ...[state[newsIndex]][0],
          expanded: ![state[newsIndex]][0].expanded
        }
      };
    }
    case RESET_NEWS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
