import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Todo properly log the error
    console.log(error, info);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError) {
      this.setState({ hasError: false });
    }
  }
  // TODO render something fancy
  render() {
    if (this.state.hasError) {
      const { t } = this.props;
      return (
        <div>
          <h1>{t('error:default_message')}</h1>
          <div className="d-flex flex-column">
            <Link to="/" replace>
              {t('main:back_home')}
            </Link>
            <a
              href="#"
            >
              {t('main:refresh')}
            </a>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }}>
              {t('main:back_previous')}
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default compose(
  withRouter,
  withTranslation(['error', 'main'])
)(ErrorBoundary);
