import React from 'react';
import './styles.css';

export default ({ setActiveHexButtonIndex, index, btnData }) => {
  return (
    <div
      className="cursor-pointer"
      onMouseOver={() => {
        setActiveHexButtonIndex(index);
      }}
      onMouseLeave={() => setActiveHexButtonIndex(null)}>
      <i className={`fs-2x ${btnData.icon} small-hex-icon-color`} />
    </div>
  );
};
