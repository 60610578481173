import { tasksActionType } from '../../constants/actionType';

const { RESET_TASKS, MERGE_TASKS, REPLACE_TASK } = tasksActionType;
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case MERGE_TASKS: {
    const { taskList } = action;
    return {
      ...state,
      ...taskList.reduce(
        (previous, task) => ({ ...previous, [task.task_id]: task }),
        {}
      )
    };
  }
  case REPLACE_TASK: {
    return { ...state, [action.task.task_id]: action.task };
  }
  case RESET_TASKS:
    return initialState;
  default:
    return state;
  }
};

export default reducer;
