import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import {
  Alert,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
  Label,
  Badge
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { DndProvider, useDrag, useDrop, DragPreviewImage } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import classnames from 'classnames';
import uuid from 'uuid/v4';
import { intersectionWith, isEqual, intersection } from 'lodash';
import * as Yup from 'yup';

import Message from '../../UI/Message';
// import { rulesAdminActionType } from '../../store/constants/actionType';
import RuleParameterModal from './ModalRuleParameter';
import PackageParameterModal from './ModalPackageParameter';

import CompliantMessageTag from './CompliantMessageLocking/TagLabel';

import { object } from 'prop-types';

import RuleVisibilty from './RuleVisibilty';
import './rulesGroup.css';
const isCompliantMesageVisibleInRuleTree = false;

const DragItemTypes = {
  RULES: 'rule',
  GROUPS: 'group'
};

const mapActivatedChecksToActualType = {
  REGULATORY: 'regulatory',
  BIM_DATA: 'bimdata',
  CLASH_DETECTION: 'clash',
  BIM_QUALITY: 'quality'
};

const IndeterminateCheckbox = ({
  indeterminate,
  checked,
  id,
  label,
  onChange
}) => {
  const checkboxRef = useRef();
  useEffect(() => {
    if (checkboxRef && checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);
  return (
    <div className="custom-control custom-checkbox d-inline-block">
      <input
        ref={checkboxRef}
        type="checkbox"
        className={'custom-control-input'}
        id={id}
        checked={checked}
        onChange={onChange}
        // onClick={onChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

const getItemAtPath = (tree, path, depth = 0) => {
  if (depth === path.length - 1) {
    return tree[path[depth]];
  } else {
    return getItemAtPath(tree[path[depth]].children, path, depth + 1);
  }
};

const getItemsAlongPath = (tree, path, depth = 0) => {
  if (depth === path.length - 1) {
    return [tree[path[depth]]];
  } else {
    return [
      tree[path[depth]],
      ...getItemsAlongPath(tree[path[depth]].children, path, depth + 1)
    ];
  }
};

const getChildrenPackages = item => {
  if (item.children) {
    const directChildPackage = item.children.filter(c => !c.ruleId);
    return [
      ...directChildPackage,
      ...directChildPackage.reduce((prev, p) => {
        return [...prev, ...getChildrenPackages(p)];
      }, [])
    ];
  } else {
    return [];
  }
};
const getChildren = item => {
  if (item.children) {
    return [
      ...item.children,
      ...item.children.reduce((prev, i) => [...prev, ...getChildren(i)], [])
    ];
  } else {
    return [];
  }
};

const alterRuleTree = (
  initialTree,
  packagePath,
  depth = 0,
  operation = { type: 'add', position: 0 },
  itemToOperate,
  path = []
) => {
  const nextTree = [...initialTree];
  if (depth === packagePath.length) {
    if (operation.type === 'add') {
      nextTree.splice(operation.position, 0, {
        ...itemToOperate
      });
      // RETURN A NEW ARRAY
      return nextTree;
    } else if (operation.type === 'remove') {
      nextTree.splice(operation.position, 1);
      // RETURN A NEW ARRAY
      return nextTree;
    } else if (
      operation.type === 'rename' &&
      nextTree[operation.position] &&
      nextTree[operation.position].title &&
      itemToOperate.title
    ) {
      // initialTree[operation.position].title = itemToOperate.title;
      nextTree.splice(operation.position, 1, {
        ...nextTree[operation.position],
        title: itemToOperate.title
      });
      return nextTree;
    }
  } else {
    return nextTree.map((curr, index) => {
      if (index !== packagePath[depth]) {
        return curr;
      } else {
        return {
          ...curr,
          children: alterRuleTree(
            curr.children,
            packagePath,
            depth + 1,
            operation,
            itemToOperate,
            [...path, index]
          )
        };
      }
    });
  }
};

const moveRule = (
  ruleItem,
  sourceGroupPath,
  targetGroupPath,
  fullRuleTree,
  setRules,
  positionInPackage
) => {
  console.log({ sourceGroupPath });
  const positionToRemove = ruleItem.ownIndex;
  let removeFirst = false;

  if (sourceGroupPath.length > targetGroupPath.length) {
    removeFirst = true;
  } else if (sourceGroupPath.length === targetGroupPath.length) {
    if (
      sourceGroupPath.every((val, index) => val === targetGroupPath[index]) &&
      positionToRemove >= positionInPackage
    ) {
      removeFirst = true;
    }
  }
  if (removeFirst) {
    const nextRuleTree = alterRuleTree(
      fullRuleTree,
      sourceGroupPath,
      0,
      { type: 'remove', position: positionToRemove },
      { ...ruleItem, type: 'rule' }
    );

    const nextRuleTree2 = alterRuleTree(
      nextRuleTree,
      targetGroupPath,
      0,
      { type: 'add', position: positionInPackage || 0 },
      { ...ruleItem, type: 'rule' }
    );
    setRules(nextRuleTree2);
    return nextRuleTree2;
  } else {
    const nextRuleTree = alterRuleTree(
      fullRuleTree,
      targetGroupPath,
      0,
      { type: 'add', position: positionInPackage || 0 },
      { ...ruleItem, type: 'rule' }
    );

    const nextRuleTree2 = alterRuleTree(
      nextRuleTree,
      sourceGroupPath,
      0,
      { type: 'remove', position: positionToRemove },
      { ...ruleItem, type: 'rule' }
    );
    setRules(nextRuleTree2);
    return nextRuleTree2;
  }
};

const moveGroup = (
  pathTargetGroup,
  pathSourceParentGroup,
  groupItem,
  fullRuleTree,
  setRules,
  positionInPackage
) => {
  let positionToRemove = groupItem.ownIndex;
  let positionToAdd = positionInPackage;
  let removeFirst = false;

  if (pathSourceParentGroup.length > pathTargetGroup.length) {
    removeFirst = true;
  } else if (pathSourceParentGroup.length === pathTargetGroup.length) {
    if (
      pathSourceParentGroup.every(
        (val, index) => val === pathTargetGroup[index]
      ) &&
      positionToRemove >= positionToAdd
    ) {
      removeFirst = true;
    }
  }
  if (removeFirst) {
    const nextRuleTree = alterRuleTree(
      fullRuleTree,
      pathSourceParentGroup,
      0,
      { type: 'remove', position: positionToRemove },
      { ...groupItem, type: 'group' }
    );
    const nextRuleTree2 = alterRuleTree(
      nextRuleTree,
      pathTargetGroup,
      0,
      { type: 'add', position: positionToAdd },
      { ...groupItem, type: 'group' }
    );

    setRules(nextRuleTree2);
    return nextRuleTree2;
  } else {
    const nextRuleTree = alterRuleTree(
      fullRuleTree,
      pathTargetGroup,
      0,
      { type: 'add', position: positionInPackage || 0 },
      { ...groupItem, type: 'group' }
    );
    const nextRuleTree2 = alterRuleTree(
      nextRuleTree,
      pathSourceParentGroup,
      0,
      { type: 'remove', position: positionToRemove },
      { ...groupItem, type: 'group' }
    );
    setRules(nextRuleTree2);
    return nextRuleTree2;
  }
};

const RulesRow = ({
  rule,
  show,
  ownIndex,
  fullRuleTree,
  setRules,
  path,
  t,
  ruleType,
  readonly,
  level,
  onSelectRule,
  selectedRules,
  saveRules,
  setTouched,
  allRulesCMMode,
  setAllRulesCMMode,
  onUpdateRuleCMMode,
  isAdmin,
  isRuleManager
}) => {
  const node = useRef(null);
  const [border, setBorder] = useState(null);
  const [isMouseHover, setMouseOver] = useState(false);
  const [isMouseHoverLink, setMouseOverLink] = useState(false);
  const [ruleModal, setRuleModal] = useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: DragItemTypes.RULES,
    canDrop: () => !readonly && (level === rule.level || level === 'domain'),
    hover: (item, mon) => {
      const nodeBoundingBox = node.current.getBoundingClientRect();
      const dragHeightPosition = mon.getClientOffset().y;
      const globalCenterTopPosition =
        nodeBoundingBox.top + nodeBoundingBox.height / 2;
      if (dragHeightPosition < globalCenterTopPosition) {
        setBorder('top');
      } else {
        setBorder('bottom');
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      } else {
        const nodeBoundingBox = node.current.getBoundingClientRect();
        const dragHeightPostion = monitor.getClientOffset().y;
        const globalCenterTopPosition =
          nodeBoundingBox.top + nodeBoundingBox.height / 2;
        const nextRules = moveRule(
          item.rule,
          item.parentPath,
          path.slice(0, -1),
          fullRuleTree,
          setRules,
          dragHeightPostion < globalCenterTopPosition ? ownIndex : ownIndex + 1
        );

        // drop rules
        saveRules(nextRules)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.RULES,
      rule: { ...rule, ownIndex },
      parentPath: path.slice(0, -1)
    },
    canDrag: () => !readonly && (level === rule.level || level === 'domain'),
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      };
    }
  });
  const toggleRuleModal = () => {
    setRuleModal(!ruleModal);
  };

  // Prevent to re-render the modal for each state changes
  const RuleModal = useMemo(
    () => (
      <RuleParameterModal
        ruleId={rule.initialKey}
        toggle={toggleRuleModal}
        open={ruleModal}
        t={t}
        ruleType={ruleType}
        level={level}
      />
    ),
    [rule.initialKey, ruleModal]
  );

  const link = rule.descriptionLink.replace('HTML\\', '');

  return (
    <>
      {show !== false && (
        <>
          {/* <DragPreviewImage connect={preview}/> */}
          <div ref={drag}>
            <div
              ref={node}
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              className="pointer">
              <div ref={drop}>
                <div
                  className={classnames(
                    'd-flex justify-content-between pl-3 py-2',
                    {
                      'border-top border-dark border-very-thick':
                        isOver && border === 'top',
                      'border-bottom border-dark border-very-thick':
                        isOver && border === 'bottom'
                    }
                  )}>
                  <div>
                    <IndeterminateCheckbox
                      checked={!!selectedRules[rule.key]}
                      id={`rule-checkbox-${rule.ruleId}`}
                      onChange={e => {
                        onSelectRule(rule, path, ownIndex, e.target.checked);
                      }}
                    />
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/admin/rules/${level}/${rule.level}/${ruleType}/${rule.ruleId}`
                        // search: `version_id=${rule.params.version_id}`
                      }}
                      className="rulesGroupRuleLink"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      onMouseEnter={() => setMouseOverLink(true)}
                      onMouseLeave={() => setMouseOverLink(false)}>
                      {rule.title}
                      <i
                        className={`ml-1 fas fa-external-link-alt ${
                          isMouseHoverLink ? 'visible' : 'invisible'
                        }`}
                        title={rule.title}
                      />
                      {(readonly ||
                        (level !== rule.level && level !== 'domain')) && (
                        <i className="ml-2 fas fa-lock text-muted"></i>
                      )}
                    </Link>
                  </div>
                  {/* <a
                target="_blank"
                rel="noreferrer noopener"
                href={
                  (rule.descriptionLink &&
                    `${process.env.REACT_APP_API_ENDPOINT}/api/rules/description/${rule.descriptionLink}`) ||
                  '#'
                }
                style={{ marginLeft: '5px', color: '#212529' }}
                onClick={e => {
                  e.stopPropagation();
                }}
                onMouseEnter={() => setMouseOverLink(true)}
                onMouseLeave={() => setMouseOverLink(false)}>
                {rule.title}
              </a> */}
                  {!readonly && (
                    <div className="d-flex text-muted justify-content-end align-items-center">
                      {(!!isAdmin || !!isRuleManager) &&
                        !!isCompliantMesageVisibleInRuleTree && (
                          <CompliantMessageTag
                            ruleId={rule.initialKey}
                            t={t}
                            ruleType={ruleType}
                            level={level}
                            setTouched={setTouched}
                            allRulesCMMode={allRulesCMMode}
                            setAllRulesCMMode={setAllRulesCMMode}
                            onUpdateRuleCMMode={onUpdateRuleCMMode}
                            path={path}
                            item={rule}
                            ownIndex={ownIndex}
                            isa
                          />
                        )}

                      {(isAdmin || isRuleManager) && (
                        <Button
                          color="light"
                          size="sm"
                          className="mr-2"
                          onClick={
                            isAdmin
                              ? toggleRuleModal
                              : () => {
                                  Message.error(t('rules:not_authorized'));
                                }
                          }>
                          <i className="fas fa-cog  text-muted"></i>
                        </Button>
                      )}

                      {rule.level === 'domain' &&
                        (!!isAdmin || !!isRuleManager) && (
                          <Link
                            target="_blank"
                            to={`/admin/rules/${level}/${rule.level}/${ruleType}/${rule.ruleId}/rule_history`}
                            className="rulesGroupRulesHistory"
                            onClick={e => {
                              e.stopPropagation();
                            }}>
                            <Button color="light" size="sm" className="mr-2">
                              <i className="fas fa-history text-muted"></i>
                            </Button>
                          </Link>
                        )}

                      <i
                        className={classnames('fas fa-arrows-alt', {
                          visible: isMouseHover && !isOver,
                          invisible: !isMouseHover || isOver
                        })}></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {RuleModal}
        </>
      )}
    </>
  );
};

const GroupContainer2 = ({
  title,
  expanded,
  ruleType,
  show,
  packageKey,
  parentPackageKey,
  ownIndex,
  setRules,
  path,
  fullRuleTree,
  treeChildren,
  children,
  t,
  readonly,
  level,
  onSelectPackage,
  item,
  selectedPackages,
  saveRules,
  setTouched,
  allRulesCMMode,
  setAllRulesCMMode,
  onUpdatePackageCMMode,
  onUpdateRuleCMMode,
  isAdmin,
  isRuleManager,
  isDefaultShown
}) => {
  const node = useRef(null);
  const [border, setBorder] = useState(false);
  const [isMouseOver, setMouseOver] = useState(false);
  const [isMouseHoverTitle, setIsMouseOverTitle] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState(title);
  const [isOverInside, setIsOverInside] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [collapsed, setCollapsed] = useState(!expanded);

  useEffect(() => setCollapsed(!expanded), [expanded]);
  const togglePackageModal = () => {
    setPackageModal(!packageModal);
  };

  const [{ isOver: isOverInsideShallow }, dropInside] = useDrop({
    accept: [DragItemTypes.RULES, DragItemTypes.GROUPS],
    canDrop: (item, mon) => {
      if (readonly) {
        return false;
      }
      if (node && node.current) {
        const nodeBoundingBox = node.current.getBoundingClientRect();
        const dragHeightPosition = mon.getClientOffset().y;
        const globalBottomHeaderPosition =
          nodeBoundingBox.top + nodeBoundingBox.height / 2;
        if (item.type === DragItemTypes.GROUPS) {
          if (dragHeightPosition > globalBottomHeaderPosition) {
            return true;
          } else {
            return false;
          }
        } else if (item.type === DragItemTypes.RULES) {
          return true;
        }
      } else {
        return false;
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      } else {
        if (item.type === DragItemTypes.RULES) {
          const nextRules = moveRule(
            item.rule,
            item.parentPath,
            path,
            fullRuleTree,
            setRules
          );

          saveRules(nextRules)
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        } else if (item.type === DragItemTypes.GROUPS) {
          const nextRules = moveGroup(
            path,
            item.parentPath,
            item.group,
            fullRuleTree,
            setRules,
            0
          );

          saveRules(nextRules)
            .then(() => true)
            .catch(() => false);
        }
        setIsOverInside(false);
      }
    },
    collect: mon => ({
      isOver: !!mon.isOver({ shallow: true }),
      canDrop: !!mon.canDrop()
    })
  });

  const [{ isOver }, dropAsSibling] = useDrop({
    accept: [DragItemTypes.GROUPS],
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      } else {
        const nextRules = moveGroup(
          path.slice(0, -1),
          item.parentPath,
          item.group,
          fullRuleTree,
          setRules,
          ownIndex
        );

        saveRules(nextRules)
          .then(() => true)
          .catch(() => false);
      }
    },
    canDrop: (item, mon) => {
      if (readonly) {
        return false;
      }
      const nodeBoundingBox = node.current.getBoundingClientRect();
      const dragHeightPosition = mon.getClientOffset().y;
      const globalBottomHeaderPosition =
        nodeBoundingBox.top + nodeBoundingBox.height / 2;
      if (
        item.type === DragItemTypes.GROUPS &&
        dragHeightPosition < globalBottomHeaderPosition
      ) {
        return true;
      } else {
        return false;
      }
    },
    hover: (item, mon) => {
      const nodeBoundingBox = node.current.getBoundingClientRect();
      const dragHeightPosition = mon.getClientOffset().y;
      const globalBottomHeaderPosition =
        nodeBoundingBox.top + nodeBoundingBox.height / 2;
      if (
        item.type === DragItemTypes.GROUPS &&
        dragHeightPosition < globalBottomHeaderPosition
      ) {
        setBorder(true);
      } else {
        setBorder(false);
      }
    },
    collect: mon => ({
      isOver: !!mon.isOver({ shallow: true }),
      canDrop: !!mon.canDrop()
    })
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.GROUPS,
      group: {
        title,
        key: packageKey,
        children: treeChildren,
        ownIndex
      },
      parentPath: path.slice(0, -1)
    },
    canDrag: monitor => !isRenaming && !readonly,
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      };
    }
  });

  const getChildRules = item => {
    if (item.children) {
      return item.children.reduce((previous, current) => {
        if (current.children) {
          return Array.from(new Set([...previous, ...getChildRules(current)]));
        } else if (current.initialKey) {
          return Array.from(new Set([...previous, current.initialKey]));
        } else {
          return previous;
        }
      }, []);
    } else if (item.initialKey && !item.hasOwnProperty('show')) {
      return [item.initialKey];
    } else if (item.initialKey && item.show) {
      return [item.initialKey];
    } else {
      return [];
    }
  };

  const allPackageRuleId = treeChildren.reduce((previous, current) => {
    return Array.from(new Set([...previous, ...getChildRules(current)]));
  }, []);

  return (
    <>
      {show !== false && (
        <div ref={drag}>
          <div
            className={classnames({
              'bg-primary text-white opacity-80 rounded': isOverInside
            })}>
            <div
              ref={dropInside}
              onDragOver={e => {
                if (node.current && e.clientY) {
                  const nodeBoundingBox = node.current.getBoundingClientRect();
                  const globalBottomHeaderPosition =
                    nodeBoundingBox.top + nodeBoundingBox.height / 2;
                  if (
                    (isOver || isOverInsideShallow) &&
                    globalBottomHeaderPosition < e.clientY
                  ) {
                    setIsOverInside(true);
                  } else {
                    setIsOverInside(false);
                  }
                } else {
                  setIsOverInside(false);
                }
              }}
              onDragLeave={() => setIsOverInside(false)}>
              <div ref={dropAsSibling}>
                <div
                  ref={node}
                  onMouseEnter={() => setMouseOver(true)}
                  onMouseLeave={() => setMouseOver(false)}>
                  <div
                    className={classnames(
                      'd-flex justify-content-between py-2',
                      {
                        'border-top border-primary opacity-80 border-very-thick':
                          isOver && border
                      }
                    )}>
                    <div className="d-flex w-100">
                      <div
                        className="pointer w-100"
                        // onClick={() => setCollapsed(!collapsed)}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex">
                            <i
                              onClick={() => setCollapsed(!collapsed)}
                              className={classnames('fas  mr-2', {
                                'fa-chevron-right': collapsed,
                                'fa-chevron-down': !collapsed
                                // 'fa-rotate-90': collapsed
                              })}></i>

                            <IndeterminateCheckbox
                              // checkbox for package
                              indeterminate={
                                selectedPackages[packageKey] === 'half'
                              }
                              checked={selectedPackages[packageKey] === true}
                              id={`checkbox-package-${packageKey}`}
                              onChange={e =>
                                onSelectPackage(
                                  item,
                                  path,
                                  ownIndex,
                                  e.target.checked
                                )
                              }
                            />
                            {!isRenaming && !readonly && (
                              <div
                                onClick={() => setCollapsed(!collapsed)}
                                onMouseEnter={() => setIsMouseOverTitle(true)}
                                onMouseLeave={() => setIsMouseOverTitle(false)}>
                                {title}
                                {/* append total number of rules under the package */}

                                <Badge color="warning" className="ml-2">
                                  {allPackageRuleId.length}
                                </Badge>

                                <i
                                  className={`ml-2 fas fa-i-cursor ${
                                    isMouseHoverTitle ? 'visible' : 'invisible'
                                  }`}
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsRenaming(true);
                                  }}></i>
                              </div>
                            )}
                            {readonly && (
                              <div onClick={() => setCollapsed(!collapsed)}>
                                {title}
                                <Badge color="warning" className="ml-2 mr-1">
                                  {allPackageRuleId.length}
                                </Badge>

                                <i className="ml-2 fas fa-lock text-muted"></i>
                              </div>
                            )}
                            {isRenaming && (
                              <InputGroup>
                                <Input
                                  bsSize="sm"
                                  type="text"
                                  maxLength="255"
                                  value={renameValue}
                                  label={t('rules:add_new_group')}
                                  onChange={e => setRenameValue(e.target.value)}
                                  // onDrag={(e) => {e. e.preventDefault(); e.stopPropagation()}}
                                  // onDragStart={(e) => {e.preventDefault(); e.stopPropagation()}}
                                  onClick={e => e.stopPropagation()}
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    size="sm"
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      if (renameValue.length > 0) {
                                        if (
                                          level == 'user' &&
                                          !isDefaultShown
                                        ) {
                                          const writableRules =
                                            fullRuleTree.filter(
                                              i => !i.readonly
                                            );

                                          const writableRulesKeys = new Set(
                                            writableRules.map(i => i.key)
                                          );

                                          const nextRuleTree = alterRuleTree(
                                            writableRules,
                                            path.slice(0, -1),
                                            0,
                                            {
                                              type: 'rename',
                                              position: ownIndex
                                            },
                                            { title: renameValue }
                                          );

                                          // combine 2 array of objects without duplicates
                                          const newFullRuleTree = [
                                            ...fullRuleTree.filter(
                                              i => !writableRulesKeys.has(i.key)
                                            ),
                                            ...nextRuleTree
                                          ];

                                          saveRules(newFullRuleTree)
                                            .then(() => {
                                              setRules(newFullRuleTree);
                                            })
                                            .catch(err => {
                                              return false;
                                            });
                                        } else {
                                          const nextRuleTree = alterRuleTree(
                                            fullRuleTree,
                                            path.slice(0, -1),
                                            0,
                                            {
                                              type: 'rename',
                                              position: ownIndex
                                            },
                                            { title: renameValue }
                                          );

                                          saveRules(nextRuleTree)
                                            .then(() => {
                                              setRules(nextRuleTree);
                                            })
                                            .catch(err => {
                                              return false;
                                            });
                                        }

                                        setIsRenaming(false);
                                      }
                                    }}>
                                    Rename
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            )}
                          </div>
                          {!readonly && (
                            <div className="d-flex text-muted justify-content-end  align-items-center">
                              <div className="mr-4">
                                {(!!isAdmin || !!isRuleManager) &&
                                  !!isCompliantMesageVisibleInRuleTree && (
                                    <CompliantMessageTag
                                      title={title}
                                      t={t}
                                      treeChildren={treeChildren}
                                      ruleType={ruleType}
                                      level={level}
                                      ruleId={false}
                                      setTouched={setTouched}
                                      allRulesCMMode={allRulesCMMode}
                                      setAllRulesCMMode={setAllRulesCMMode}
                                      onUpdatePackageCMMode={
                                        onUpdatePackageCMMode
                                      }
                                      item={item}
                                      path={path}
                                      ownIndex={ownIndex}
                                      selectedPackages={selectedPackages}
                                      onUpdateRuleCMMode={onUpdateRuleCMMode}
                                      packageKey={packageKey}
                                      isAdmin={isAdmin}
                                      isRuleManager={isRuleManager}
                                    />
                                  )}
                              </div>
                              <div className="rulesGroupRuleSetting">
                                {(isAdmin || isRuleManager) && (
                                  <Button
                                    color="light"
                                    size="sm"
                                    className="mr-2"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      togglePackageModal();
                                    }}>
                                    <i className="fas fa-cog  text-muted"></i>
                                  </Button>
                                )}

                                <i
                                  className={classnames('fas fa-arrows-alt', {
                                    visible:
                                      isMouseOver &&
                                      !isOverInside &&
                                      !isOver &&
                                      !isRenaming,
                                    invisible:
                                      !isMouseOver ||
                                      isOverInside ||
                                      isOver ||
                                      isRenaming
                                  })}></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Collapse isOpen={!collapsed}>
                {!collapsed && (
                  <div className="p-0 pl-1 ">
                    <div className="border-left border-thick pl-2 mb-1">
                      {children}
                    </div>
                  </div>
                )}
              </Collapse>
            </div>
          </div>
        </div>
      )}{' '}
      <PackageParameterModal
        toggle={togglePackageModal}
        open={packageModal}
        title={title}
        t={t}
        treeChildren={treeChildren}
        ruleType={ruleType}
        level={level}
      />
    </>
  );
};

// eslint-disable-next-line react/display-name
const Loop = React.memo(
  ({
    data,
    t,
    setRules,
    fullRuleTree,
    parentPackageKey = null,
    path = [],
    ruleType,
    readonly = false,
    level,
    onSelectPackage,
    onSelectRule,
    selectedItems,
    saveRules,
    setTouched,
    allRulesCMMode,
    setAllRulesCMMode,
    onUpdatePackageCMMode,
    onUpdateRuleCMMode,
    isAdmin,
    isRuleManager,
    isDefaultShown
  }) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <GroupContainer2
            key={item.key}
            packageKey={item.key}
            title={item.title}
            expanded={item.expanded}
            show={item.show}
            path={[...path, index]}
            parentPackageKey={parentPackageKey}
            treeChildren={item.children}
            t={t}
            // indexChain={indexChain}
            ruleType={ruleType}
            ownIndex={index}
            // rule_tree={rule_tree}
            fullRuleTree={fullRuleTree}
            setRules={setRules}
            readonly={readonly || item.readonly}
            level={level}
            onSelectPackage={onSelectPackage}
            item={item}
            selectedPackages={selectedItems.packages}
            saveRules={saveRules}
            setTouched={setTouched}
            allRulesCMMode={allRulesCMMode}
            setAllRulesCMMode={setAllRulesCMMode}
            onUpdatePackageCMMode={onUpdatePackageCMMode}
            onUpdateRuleCMMode={onUpdateRuleCMMode}
            isAdmin={isAdmin}
            isRuleManager={isRuleManager}
            isDefaultShown={isDefaultShown}>
            {item.children.length === 0 && (
              <Alert color="warning">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {t('rules:no_children')}
              </Alert>
            )}
            {item.children.length > 0 && (
              <Loop
                key={`${item.key}-${index}`}
                data={item.children}
                path={[...path, index]}
                t={t}
                fullRuleTree={fullRuleTree}
                setRules={setRules}
                parentPackageKey={item.key}
                ruleType={ruleType}
                readonly={readonly || item.readonly}
                level={level}
                onSelectPackage={onSelectPackage}
                onSelectRule={onSelectRule}
                selectedItems={selectedItems}
                saveRules={saveRules}
                setTouched={setTouched}
                allRulesCMMode={allRulesCMMode}
                setAllRulesCMMode={setAllRulesCMMode}
                onUpdatePackageCMMode={onUpdatePackageCMMode}
                onUpdateRuleCMMode={onUpdateRuleCMMode}
                isAdmin={isAdmin}
                isRuleManager={isRuleManager}
                isDefaultShown={isDefaultShown}
              />
            )}
          </GroupContainer2>
        );
      } else {
        return (
          <RulesRow
            key={item.key}
            rule={item}
            ownIndex={index}
            setRules={setRules}
            show={item.show}
            fullRuleTree={fullRuleTree}
            path={[...path, index]}
            t={t}
            ruleType={ruleType}
            readonly={readonly || item.readonly}
            level={level}
            onSelectRule={onSelectRule}
            selectedRules={selectedItems.rules}
            saveRules={saveRules}
            setTouched={setTouched}
            allRulesCMMode={allRulesCMMode}
            setAllRulesCMMode={setAllRulesCMMode}
            onUpdateRuleCMMode={onUpdateRuleCMMode}
            isAdmin={isAdmin}
            isRuleManager={isRuleManager}
          />
        );
      }
    });
  },
  (prevProps, nextProps) => {
    return (
      prevProps.data === nextProps.data &&
      prevProps.fullRuleTree === nextProps.fullRuleTree &&
      prevProps.selectedItems === nextProps.selectedItems &&
      prevProps.allRulesCMMode === nextProps.allRulesCMMode
    );
  }
);

const AddNewGroupComponent = ({ addNewGroup, t }) => {
  const [addPackageFieldVisible, setAddPackageFieldVisibility] =
    useState(false);
  const [inputValue, setInputValue] = useState('');
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <Button
          color="info"
          outline
          onClick={() => {
            setAddPackageFieldVisibility(!addPackageFieldVisible);
          }}>
          {t('rules:new_group')}
        </Button>
      </InputGroupAddon>
      {addPackageFieldVisible && (
        <>
          <Input
            type="text"
            maxLength="255"
            value={inputValue}
            label={t('add_new_group')}
            onChange={e => setInputValue(e.target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button
              color="warning"
              onClick={async () => {
                let packageNameSchema = Yup.string().max(60);
                const validatePackageName = await packageNameSchema.isValid(
                  inputValue
                );

                if (inputValue.length > 0) {
                  if (validatePackageName) {
                    addNewGroup(inputValue);
                    setInputValue('');
                    setAddPackageFieldVisibility(false);
                  } else {
                    Message.error(t('error:package_name_maximum_characters'));
                  }
                }
              }}>
              <i className="fas fa-plus"></i>
            </Button>
          </InputGroupAddon>
        </>
      )}
    </InputGroup>
  );
};

const RuleDeleteModal = ({
  level,
  t,
  deleteModalOpen,
  setDeleteModalOpen,
  setRules,
  saveRules,
  selectedItems,
  prevRulesRef,
  rules,
  unSelectAll,
  deleteRules
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggle = () => setDeleteModalOpen(!deleteModalOpen);
  const rulesToDelete = Object.entries(selectedItems.rules).reduce(
    (prev, [key, rule]) => {
      if (level === 'user') {
        if (!rule.isOriginal && rule.level === 'user') {
          return [...prev, rule];
        } else {
          return prev;
        }
      } else {
        if (!rule.isOriginal) {
          return [...prev, rule];
        } else {
          return prev;
        }
      }
    },
    []
  );
  const onSubmit = async () => {
    setIsSubmitting(true);
    const nextRuleTree = rulesToDelete
      .reverse()
      .reduce((previousRuleTree, rule, index) => {
        const [rulePosition, ...initialPackagePath] = rule.index
          .split('-')
          .map(i => parseInt(i))
          .reverse();
        return alterRuleTree(
          previousRuleTree,
          initialPackagePath.reverse().slice(1),
          0,
          { type: 'remove', position: rule.ownIndex },
          { ...rule, type: 'rule' }
        );

        // return moveRule(
        //   { ...rule, ownIndex: rulePosition },
        //   initialPackagePath.reverse().slice(1),
        //   currentSelectedPath,
        //   previousRuleTree,
        //   () => {},
        //   0
        // );
      }, rules);
    setRules(nextRuleTree);
    prevRulesRef.current = [...prevRulesRef.current, rules];
    unSelectAll();
    await saveRules(nextRuleTree, false);
    deleteRules(rulesToDelete, level);
    setIsSubmitting(false);
    setDeleteModalOpen(false);
    Message.success(t('rules:rules_deleted_success'));
  };
  return (
    <Modal
      isOpen={deleteModalOpen}
      toggle={toggle}
      // size="xl"
    >
      <ModalHeader toggle={toggle}>{t('rules:delete_modal_title')}</ModalHeader>
      <ModalBody>
        <Alert color="info">
          <i className="fas fa-exclamation-triangle"></i>{' '}
          {t('rules:helper_delete_modal')}
        </Alert>
        <div className="text-muted my-2">
          {rulesToDelete.length} {t('rules:nb_rules_to_delete')}
        </div>
        <div className="overflow-auto border p-2 rounded rulesToDeleteList">
          {rulesToDelete.map((r, i) => (
            <div
              key={`rule-to-deleted-${i}`}
              className="pb-2 mt-2 border-bottom">
              {r.title}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          // type="submit"
          color="danger"
          disabled={isSubmitting}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
          }}>
          {isSubmitting && (
            <span
              className="mr-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {t('rules:delete_action')}
        </Button>
        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={() => setDeleteModalOpen(false)}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const RuleGroupContainer = props => {
  const featureList = useSelector(state => state.app.featureList);
  const isWaiverEnabled = featureList.ruleWaiving;
  const isPrecheckEnabled = featureList.rulePrecheck;
  const checkTypes = featureList.checkingType;

  const { t, isAdmin, isRuleManager, level } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);
  const [rules, setRules] = useState([]);
  // const [filteredRules, setFilteredRules] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [majorTypeDropdown, setMajorTypeDropdown] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const location = useLocation();
  const [majorRuleType, setMajorRuleType] = useState('regulatory');
  const [copiedRules, setCopiedRules] = useState({});
  const [pasteModal, setPasteModal] = useState(false);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isDefaultShown, setDefaultShown] = useState(false);
  const [filteredRules, setFilteredRules] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState({
    rules: {},
    packages: {}
  });

  const [touched, setTouched] = useState({});
  const [allRulesCMMode, setAllRulesCMMode] = useState({
    rules: {},
    packages: {}
  });

  const activatedCheck =
    !!checkTypes &&
    checkTypes
      .map(checkType => mapActivatedChecksToActualType[checkType])
      .filter(t => !!t);

  const onUpdatePackageCMMode = (pck, path, index, compliant_message_mode) => {
    const parentPackages = getItemsAlongPath(rules, path).filter(
      p => p.show !== false
    );
    const childPackages = getChildrenPackages(pck).filter(
      p => p.show !== false
    );

    // add children ruleKey(=packageKey + ruleId)
    // add package path to selected package
    // const nextSelectedPackage = for each item in path => length += children
    const childRules = [...childPackages, pck].reduce((previous, p) => {
      return {
        ...previous,
        ...p.children
          .filter(c => !!c.ruleId && c.show !== false)
          .reduce((acc, rule) => {
            return {
              ...acc,
              [rule.key]: {
                ...rule,
                compliant_message_mode: compliant_message_mode
              }
            };
          }, {})
      };
    }, {});

    const nextSelectedRules = { ...allRulesCMMode.rules, ...childRules };

    const nextSelectedPackages = {
      ...allRulesCMMode.packages,
      ...childPackages.reduce((previous, p) => {
        const hasNotSelectedChildRules = getChildren(p)
          .filter(c => !!c.ruleId)
          .some(rule => !nextSelectedRules[rule.key]);
        return {
          ...previous,
          [p.key]: hasNotSelectedChildRules ? 'half' : compliant_message_mode
        };
      }, {}),
      // [pck.key]: true
      ...parentPackages.reduce((previous, p) => {
        const hasNotSelectedChildRules = getChildren(p)
          .filter(c => !!c.ruleId)
          .some(rule => !nextSelectedRules[rule.key]);
        if (hasNotSelectedChildRules) {
          return { ...previous, [p.key]: 'half' };
        } else {
          return { ...previous, [p.key]: compliant_message_mode };
        }
      }, {})
    };

    setAllRulesCMMode({
      rules: nextSelectedRules,
      packages: nextSelectedPackages
    });
  };

  const onUpdateRuleCMMode = (rule, path, index, compliant_message_mode) => {
    const parentPackages = getItemsAlongPath(rules, path).filter(
      p => p.show !== false
    );

    // add ruleKey(=pcakageKey + ruleId) to selectde rule list
    // add package path to selected package
    // const nextSelectedPackage = for each item in path => length += 1
    const nextSelectedRules = {
      ...allRulesCMMode.rules,
      [rule.key]: {
        ...rule,
        compliant_message_mode
      }
    };

    const nextSelectedPackages = {
      ...allRulesCMMode.packages,
      ...parentPackages.reduce((previous, p) => {
        const hasNotSelectedChildRules = getChildren(p)
          .filter(c => !!c.ruleId)
          .some(rule => !nextSelectedRules[rule.key]);
        if (hasNotSelectedChildRules) {
          return { ...previous, [p.key]: 'half' };
        } else {
          return { ...previous, [p.key]: true };
        }
      }, {})
    };

    setAllRulesCMMode({
      rules: nextSelectedRules,
      packages: nextSelectedPackages
    });
  };

  const prevRulesRef = React.useRef([]);

  const saveRules = async (nextRules, promptMessage = true) => {
    try {
      await props.saveRules(nextRules, majorRuleType);
      unSelectAll();
      if (promptMessage) {
        Message.success(props.t('saved'));
      }
    } catch (err) {
      Message.error(t(`error:${err.message}` || 'error:default_message'));
      throw new Error(err.message);
    }
  };

  const deleteRules = (rules, ruleLevel) => {
    props.deleteRules(rules, ruleLevel);
  };

  const rulesBatchUpdate = async (nextRules, promptMessage = true) => {
    await props.rulesBatchUpdate(nextRules, majorRuleType);
    // if (promptMessage) {
    //   Message.success(props.t('saved'));
    // }
  };

  const undo = () => {
    const nextRules = prevRulesRef.current.pop();
    setRules(nextRules);

    saveRules(nextRules)
      .then(() => true)
      .catch(() => false);
  };

  useEffect(() => {
    if (
      Object.keys(selectedItems.rules).length > 0 ||
      Object.keys(selectedItems.packages).length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems]);

  const onSelectRule = (rule, path, index, value) => {
    const parentPackages = getItemsAlongPath(rules, path).filter(
      p => p.show !== false
    );

    if (value) {
      // add ruleKey(=pcakageKey + ruleId) to selectde rule list
      // add package path to selected package
      // const nextSelectedPackage = for each item in path => length += 1
      const nextSelectedRules = {
        ...selectedItems.rules,
        [rule.key]: { ...rule, ownIndex: index }
      };

      const nextSelectedPackages = {
        ...selectedItems.packages,
        ...parentPackages.reduce((previous, p) => {
          const hasNotSelectedChildRules = getChildren(p)
            .filter(c => !!c.ruleId)
            .some(rule => !nextSelectedRules[rule.key]);
          if (hasNotSelectedChildRules) {
            return { ...previous, [p.key]: 'half' };
          } else {
            return { ...previous, [p.key]: true };
          }
        }, {})
      };

      setSelectedItems({
        rules: nextSelectedRules,
        packages: nextSelectedPackages
      });
    } else {
      // remove rule id to selected rule list
      // const nextSelectedPackage = for each item in path => length -= 1
      // remove children rules
      const nextSelectedRules = Object.keys(selectedItems.rules).reduce(
        (prev, ruleKey) => {
          if (ruleKey === rule.key) {
            return prev;
          } else {
            return { ...prev, [ruleKey]: selectedItems.rules[ruleKey] };
          }
        },
        {}
      );
      const parentPackageMap = parentPackages.reduce(
        (prev, p) => ({ ...prev, [p.key]: p }),
        {}
      );

      const nextSelectedPackages = Object.keys(selectedItems.packages).reduce(
        (prev, packageKey) => {
          // remove children packages
          // update parent => remove if it has no selected children, half selected otherwise
          if (parentPackageMap[packageKey]) {
            const selectedChildRules = getChildren(parentPackageMap[packageKey])
              .filter(c => !!c.ruleId)
              .filter(rule => !!nextSelectedRules[rule.key]);
            if (selectedChildRules.length > 0) {
              return { ...prev, [packageKey]: 'half' };
            } else {
              return prev;
            }
          } else {
            return {
              ...prev,
              [packageKey]: selectedItems.packages[packageKey]
            };
          }
        },
        {}
      );
      setSelectedItems({
        rules: nextSelectedRules,
        packages: nextSelectedPackages
      });
    }

    // const selectRule = (tree) =>

    // UPDATE PARENT

    // UPDATE CURRENT
  };

  const onSelectPackage = (pck, path, index, value) => {
    const parentPackages = getItemsAlongPath(rules, path).filter(
      p => p.show !== false
    );
    const childPackages = getChildrenPackages(pck).filter(
      p => p.show !== false
    );
    if (value === true) {
      // add children ruleKey(=packageKey + ruleId)
      // add package path to selected package
      // const nextSelectedPackage = for each item in path => length += children
      const childRules = [...childPackages, pck].reduce((previous, p) => {
        return {
          ...previous,
          ...p.children.reduce((acc, elem, index) => {
            if (!!elem.ruleId && elem.show !== false) {
              return {
                ...acc,
                [elem.key]: { ...elem, ownIndex: index }
              };
            }
          }, {})
        };
      }, {});

      const nextSelectedRules = { ...selectedItems.rules, ...childRules };

      const nextSelectedPackages = {
        ...selectedItems.packages,
        ...childPackages.reduce((previous, p) => {
          const hasNotSelectedChildRules = getChildren(p)
            .filter(c => !!c.ruleId)
            .some(rule => !nextSelectedRules[rule.key]);
          return {
            ...previous,
            [p.key]: hasNotSelectedChildRules ? 'half' : true
          };
        }, {}),
        // [pck.key]: true
        ...parentPackages.reduce((previous, p) => {
          const hasNotSelectedChildRules = getChildren(p)
            .filter(c => !!c.ruleId)
            .some(rule => !nextSelectedRules[rule.key]);
          if (hasNotSelectedChildRules) {
            return { ...previous, [p.key]: 'half' };
          } else {
            return { ...previous, [p.key]: true };
          }
        }, {})
      };

      setSelectedItems({
        rules: nextSelectedRules,
        packages: nextSelectedPackages
      });
    } else {
      const childRules = [...childPackages, pck].reduce((previous, p) => {
        return {
          ...previous,
          ...p.children
            .filter(c => !!c.ruleId)
            .reduce((acc, rule) => {
              return { ...acc, [rule.key]: rule };
            }, {})
        };
      }, {});
      // remove children rules
      const nextSelectedRules = Object.keys(selectedItems.rules).reduce(
        (prev, ruleKey) => {
          if (childRules[ruleKey]) {
            return prev;
          } else {
            return { ...prev, [ruleKey]: selectedItems.rules[ruleKey] };
          }
        },
        {}
      );
      const parentPackageMap = parentPackages.reduce(
        (prev, p) => ({ ...prev, [p.key]: p }),
        {}
      );
      const childPackageMap = childPackages.reduce(
        (prev, p) => ({ ...prev, [p.key]: p }),
        {}
      );
      const nextSelectedPackages = Object.keys(selectedItems.packages).reduce(
        (prev, packageKey) => {
          // remove children packages
          // update parent => remove if it has no selected children, half selected otherwise
          if (childPackageMap[packageKey] || packageKey === pck.key) {
            return prev;
          } else if (parentPackageMap[packageKey]) {
            const selectedChildRules = getChildren(parentPackageMap[packageKey])
              .filter(c => !!c.ruleId)
              .filter(rule => !!nextSelectedRules[rule.key]);
            if (selectedChildRules.length > 0) {
              return { ...prev, [packageKey]: 'half' };
            } else {
              return prev;
            }
          } else {
            return {
              ...prev,
              [packageKey]: selectedItems.packages[packageKey]
            };
          }
        },
        {}
      );
      setSelectedItems({
        rules: nextSelectedRules,
        packages: nextSelectedPackages
      });
    }
  };

  const selectAll = () => {
    setSelectAll(true);
    const { selectedRules, selectedPackages } = filteredRules
      .filter(pck => pck.show !== false)
      .reduce(
        (acc, pck) => {
          const childPackages = getChildrenPackages(pck).filter(
            p => p.show !== false
          );
          const childRules = [...childPackages, pck].reduce((previous, p) => {
            return {
              ...previous,
              ...p.children
                .filter(c => !!c.ruleId && c.show !== false)
                .reduce((acc, rule) => {
                  return { ...acc, [rule.key]: rule };
                }, {})
            };
          }, {});

          const nextSelectedRules = {
            ...acc.selectedRules,
            ...childRules
          };

          const nextSelectedPackages = {
            ...acc.selectedPackages,
            ...childPackages.reduce((previous, p) => {
              // const hasNotSelectedChildRules = getChildren(p)
              //   .filter(c => !!c.ruleId)
              //   .some(rule => !nextSelectedRules[rule.key]);
              return {
                ...previous,
                [p.key]: true
              };
            }, {}),
            [pck.key]: true
          };
          return {
            selectedRules: nextSelectedRules,
            selectedPackages: nextSelectedPackages
          };
        },
        { selectedRules: {}, selectedPackages: {} }
      );
    setSelectedItems({
      rules: selectedRules,
      packages: selectedPackages
    });
  };

  const unSelectAll = () => {
    setSelectAll(false);
    setSelectedItems({ rules: {}, packages: {} });
  };

  const handleSearch = (rules, searchVal, filters) => {
    const regex = new RegExp(searchVal, 'gi');
    const applyParamFilters = (item, filters) => {
      return Object.keys(filters).reduce((prev, filterKey) => {
        if (filterKey === 'visibility') {
          // compare the visibility setttings
          const ruleVisibilty = item.params[filterKey];
          const filterVisibility = filters[filterKey];
          let shouldShow = false;

          const noFilter = Object.keys(filterVisibility).every(
            role => filterVisibility[role] === false
          );

          if (noFilter) {
            return prev;
          } else {
            for (const role in filterVisibility) {
              // matches the visibility filter that is true to the rule visibility that is also true
              if (
                filterVisibility[role] === true &&
                ruleVisibilty[role] === true
              ) {
                shouldShow = true;
              }
            }
            if (shouldShow === true) {
              return prev;
            } else {
              return prev && false;
            }
          }

          // if (isEqual(ruleVisibilty, filterVisibility)) {
          //   return prev;
          // } else {
          //   return prev && false;
          // }
        } else if (filterKey === 'users_visibility') {
          const usersRuleVisibilty = item.params[filterKey];
          const usersFilterVisibility = filters[filterKey];

          //comparing the users visibility(emails) if it contains the emails in the filter
          if (!!usersFilterVisibility && usersFilterVisibility.length === 0) {
            return prev;
          } else {
            if (
              (
                !!usersRuleVisibilty &&
                usersRuleVisibilty.length > 0 &&
                usersRuleVisibilty.filter(element =>
                  usersFilterVisibility.includes(element)
                )
              ).length > 0
            ) {
              return prev;
            } else {
              return prev && false;
            }
          }
        } else {
          if (item.params && item.params[filterKey] === filters[filterKey]) {
            return prev;
          } else {
            return prev && false;
          }
        }
      }, true);
    };
    const highlightClassname = 'bg-info';
    const highlightText = (searchVal, text) => {
      const regex = new RegExp(searchVal, 'gi');
      const result = [];
      let match;
      let end = 0;
      let lastIndexMatch = 0;
      while ((match = regex.exec(text)) !== null) {
        let start = match.index;
        if (start > end) {
          result.push(
            <span key={`text-${end}-${start}`}>
              {text.substring(end, start)}
            </span>
          );
        }
        end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          result.push(
            <span key={`text-${start}-${end}`} className={highlightClassname}>
              {text.substring(start, end)}
            </span>
          );
        }

        lastIndexMatch = end;
        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }
      if (lastIndexMatch < text.length) {
        result.push(
          <span key={`text-${lastIndexMatch}-end`}>
            {text.substring(lastIndexMatch)}
          </span>
        );
      }
      return result;
    };

    const filterRules = tree => {
      return tree.reduce((previous, item) => {
        if (item.children) {
          const filteredChildren = filterRules(item.children);
          if (item.title.match(regex) && searchVal !== '') {
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                children: filteredChildren,
                show: true,
                expanded: true,
                highlighted: true
              }
            ];
          } else if (filteredChildren.filter(c => c.show).length > 0) {
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                children: filteredChildren,
                show: true,
                expanded: true,
                highlighted: true
              }
            ];
          } else {
            return [
              ...previous,
              { ...item, children: filteredChildren, show: false }
            ];
          }
        } else {
          if (item.title.match(regex) && applyParamFilters(item, filters)) {
            return [
              ...previous,
              {
                ...item,
                title: highlightText(searchVal, item.title),
                show: true,
                highlighted: true
              }
            ];
          } else {
            return [...previous, { ...item, show: false }];
          }
        }
      }, []);
    };
    if (searchVal !== '' || Object.keys(filters).length > 0) {
      setFilteredRules(filterRules(rules));
    } else {
      setFilteredRules(rules);
    }
  };

  const addNewGroup = name => {
    const key = uuid();
    const nextRules = [...rules, { title: name, children: [], key }];
    prevRulesRef.current = [...prevRulesRef.current, rules];

    saveRules(nextRules)
      .then(() => setRules(nextRules))
      .catch(() => false);
  };

  const [filters, setFilters] = useState({});
  const debouncedSearch = useCallback(
    debounce(handleSearch, 800, { maxWait: 1000 }),
    []
  );
  useEffect(() => {
    // debouncedSearch.cancel();
    if (level === 'user' && !isDefaultShown) {
      const rulesToFilter = rules.filter(i => !i.readonly);
      debouncedSearch(rulesToFilter, searchVal, filters);
    } else {
      // console.log(handleSearch(rules, searchVal, filters));
      debouncedSearch(rules, searchVal, filters);
    }
  }, [searchVal, rules, filters, isDefaultShown]);

  const setFilterValues = (field, value) => {
    if (value !== null) {
      setFilters({ ...filters, [field]: value });
    } else {
      if (field !== 'all') {
        const { [field]: _, ...nextFilters } = filters;
        setFilters(nextFilters);
      } else {
        setFilters({});
        setSearchVal('');
      }
    }
  };

  useEffect(() => {
    if (majorRuleType) {
      props
        .fetchRules({ type: majorRuleType, reset: true })
        .then(() => {
          setIsLoaded(true);
        })
        .catch(err => setOnError(true));
    }
  }, [location, majorRuleType]);

  useEffect(() => {
    setRules(props.rules);
    // props.saveRules(props.rules, majorRuleType);
  }, [props.rules]);

  const PackageSelection = ({ packageTree }) => {
    const [selectedPackage, setSelectedPackage] = useState();
    const [currentSelectedPath, setCurrentSelectedPath] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const onSubmit = async () => {
      if (!currentSelectedPath) {
        setValidationMessage(t('rules:no_target_package_selected'));
      } else {
        setSubmitting(true);
        try {
          // setIsLoaded(false);
          if (pasteModal.mode === 'move') {
            let ruleUnmovable = false;
            const nextRuleTree = Object.entries(selectedItems.rules)
              .reverse()
              .reduce((previousRuleTree, [key, rule], index) => {
                // Prevent moving portal rules when in 'user rule' mode
                if (level === 'user' && rule.isOriginal) {
                  ruleUnmovable = true;
                  return previousRuleTree;
                } else {
                  const [rulePosition, ...initialPackagePath] = rule.index
                    .split('-')
                    .map(i => parseInt(i))
                    .reverse();
                  return moveRule(
                    rule,
                    initialPackagePath.reverse().slice(1),
                    currentSelectedPath,
                    previousRuleTree,
                    () => {},
                    0
                  );
                }
              }, rules);
            await saveRules(nextRuleTree).then(() => {
              setRules(nextRuleTree);
              prevRulesRef.current = [...prevRulesRef.current, rules];
              Message.success(t('rules:rule_move_success'));
              if (ruleUnmovable) {
                Message.warning(t('rules:rule_unmovable'));
              }
              unSelectAll();
              setPasteModal({ ...pasteModal, open: false });
            });
          } else if (pasteModal.mode === 'clone') {
            let shouldSaveRules = false;

            const nextRuleTree = Object.entries(selectedItems.rules)
              .reverse()
              .reduce((previousRuleTree, [key, rule], index) => {
                const [rulePosition, ...initialPackagePath] = rule.index
                  .split('-')
                  .map(i => parseInt(i))
                  .reverse();

                if (
                  JSON.stringify(currentSelectedPath) ===
                  JSON.stringify(initialPackagePath.reverse().slice(1))
                ) {
                  shouldSaveRules = false;
                } else {
                  shouldSaveRules = true;
                }

                return alterRuleTree(
                  previousRuleTree,
                  currentSelectedPath,
                  0,
                  { type: 'add', position: 0 },
                  {
                    ...rule,
                    key: `${selectedPackage}##${rule.ruleId}`,
                    type: 'rule'
                  }
                );
              }, rules);

            setPasteModal({ ...pasteModal, open: false });
            unSelectAll();

            if (shouldSaveRules) {
              setRules(nextRuleTree);
              prevRulesRef.current = [...prevRulesRef.current, rules];

              await saveRules(nextRuleTree).then(() => {
                Message.success(t('rules:rule_clone_success'));
              });
            } else {
              Message.error(t('rules:rule_clone_failed'));
            }
          } else if (pasteModal.mode === 'copy') {
            const newRuleMapping = await props
              .cloneMultipleRules(selectedItems.rules, majorRuleType)
              .then(async newRuleMapping => {
                const nextRules = newRuleMapping
                  .reverse()
                  .reduce((ruleTree, current) => {
                    const originalRuleId = current[0],
                      newRule = current[1];
                    return alterRuleTree(
                      ruleTree,
                      currentSelectedPath,
                      0,
                      { type: 'add', position: 0 },
                      {
                        ...newRule,
                        key: `${selectedPackage}##${newRule.ruleId}`,
                        initialKey: newRule.ruleId,
                        type: 'rule'
                      }
                    );
                  }, rules);
                props.updateRuleSet(
                  newRuleMapping.reduce(
                    (previous, [key, value]) => ({
                      ...previous,
                      [value.ruleId]: value
                    }),
                    {}
                  )
                );
                setRules(nextRules);
                prevRulesRef.current = [...prevRulesRef.current, rules];
                // await props.fetchRules({ type: majorRuleType, reset: false });
                Message.success(t('rules:rule_copy_success'));
                unSelectAll();
                setPasteModal({ ...pasteModal, open: false });
                await saveRules(nextRules, false);
              })
              .catch(err => {
                Message.error(
                  t(`error:${err.message}` || 'error:default_message')
                );
              });
          }
        } catch (err) {
          // console.log(err);
          // Message.error(t(`error:${err.message}` || 'error:default_message'));
        } finally {
          // remove temporarily it cause reopening of modal
          // await props.fetchRules({ type: majorRuleType, reset: false });
          // setSubmitting(false);
        }
      }
    };

    const renderTree = (tree, path = []) => {
      return tree.map((item, index) => {
        if (item.children && !(props.level === 'user' && item.readonly)) {
          return (
            <div key={item.key}>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id={`package-selection-${item.key}`}
                  name="package-selection"
                  className="custom-control-input"
                  onChange={() => {
                    setValidationMessage('');
                    setCurrentSelectedPath([...path, index]);
                    setSelectedPackage(item.key);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`package-selection-${item.key}`}>
                  {item.title}
                </label>
              </div>
              <div className="pl-4">
                {renderTree(item.children, [...path, index])}
              </div>
            </div>
          );
        }
      });
    };
    return (
      <Modal
        isOpen={pasteModal.open}
        toggle={() => setPasteModal({ ...pasteModal, open: !pasteModal.open })}
        // size="xl"
      >
        <ModalHeader
          toggle={() =>
            setPasteModal({ ...pasteModal, open: !pasteModal.open })
          }>
          {t(`rules:${pasteModal.mode}_modal_title`)}
        </ModalHeader>
        <ModalBody>
          <Alert color="info">
            <i className="fas fa-exclamation-triangle"></i>{' '}
            {t(`rules:helper_${pasteModal.mode}_modal`)}
          </Alert>
          {renderTree(packageTree)}
          {validationMessage !== '' && (
            <div className="text-danger">{validationMessage}</div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            // type="submit"
            color="primary"
            disabled={isSubmitting}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onSubmit();
            }}>
            {isSubmitting && (
              <span
                className="mr-2 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {t(`rules:${pasteModal.mode}_action`)}
          </Button>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={() =>
              setPasteModal({ ...pasteModal, open: !pasteModal.open })
            }>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Container>
      <div className="d-flex align-items-start">
        <div className="sticky-top pt-3 mr-2 ">
          {/* <Nav className="rounded shadow " tabs vertical>
            {activatedCheck.map(ruleType => {
              return (
                <NavItem
                  key={`project-tab-${ruleType}`}
                  className={classnames({
                    '': majorRuleType === ruleType
                  })}>
                  <NavLink
                    className={classnames('rounded', {
                      'active font-weight-bold bg-primary text-white':
                        majorRuleType === ruleType,
                      'bg-light': majorRuleType !== ruleType
                    })}
                    style={{ border: 'none' }}
                    onClick={() => {
                      setMajorRuleType(ruleType);
                    }}>
                    {ruleType}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav> */}
        </div>
        {!isLoaded && !onError && (
          <div className="d-flex w-100 justify-content-center">
            <div
              className="spinner-grow text-primary rulesLoading"
              role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {isLoaded && !onError && (
          <DndProvider backend={HTML5Backend}>
            <Container>
              <div className="pt-3 bg-white">
                <div className="rounded bg-light ">
                  <Container>
                    {/* <Row className="p-3 ">
                      <div className="w-100 mt-2 d-flex justify-content-between">
                        <div className="justify-content-end">
                          
                           <Button
                            color="success"
                            className="mx-1"
                            disabled={props.rules === rules}
                            onClick={saveRules}
                            id="saveRuleTreeButton">
                            <i className="fas fa-save fs-1-2x" />
                          </Button>
                          <UncontrolledTooltip
                            fade={false}
                            target="saveRuleTreeButton"
                            modifiers={{
                              computeStyle: { gpuAcceleration: false }
                            }}>
                            {props.t('user_preferences:save')}
                          </UncontrolledTooltip> 
                        </div>
                      </div>
                      <hr />
                    </Row> */}
                    <Row className="p-2">
                      {/* <Col> */}
                      <div className="flex-grow-1">
                        <Dropdown
                          isOpen={majorTypeDropdown}
                          toggle={() =>
                            setMajorTypeDropdown(!majorTypeDropdown)
                          }>
                          <DropdownToggle
                            color="primary"
                            disabled={!(activatedCheck.length > 1)}
                            caret>
                            {t(`rules:${majorRuleType}_rule_type`)}
                          </DropdownToggle>
                          <DropdownMenu>
                            {activatedCheck.map(ruleType => {
                              return (
                                <DropdownItem
                                  active={ruleType === majorRuleType}
                                  key={`project-option-${ruleType}`}
                                  onClick={() => {
                                    setMajorRuleType(ruleType);
                                  }}>
                                  {t(`rules:${ruleType}_rule_type`)}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      {prevRulesRef.current && prevRulesRef.current.length > 0 && (
                        <div>
                          {/* UNDO BUTTON */}
                          <Button
                            color="info"
                            className="mx-1"
                            disabled={props.rules === rules}
                            onClick={undo}
                            id="undoButton">
                            <i className="fas fa-undo fs-1-2x" />
                            {/* {prevRulesRef.current.length} */}
                          </Button>
                          <UncontrolledTooltip
                            fade={false}
                            target="undoButton"
                            modifiers={{
                              computeStyle: { gpuAcceleration: false }
                            }}>
                            {props.t('user_preferences:undo')}
                          </UncontrolledTooltip>
                        </div>
                      )}
                      {/* </Col> */}
                    </Row>
                    <Row className="p-2 justify-content-between">
                      {/* MAJOR TYPE DROPDOWN */}

                      <div className="flex-grow-1">
                        <AddNewGroupComponent
                          t={props.t}
                          addNewGroup={addNewGroup}
                        />
                      </div>

                      {/* ACTION BUTTONS */}
                      <div className="d-flex">
                        {/* <Button
                          id="move-button"
                          className="ml-2"
                          color="primary"
                          disabled={
                            Object.keys(selectedItems.rules).length === 0
                          }
                          outline
                          onClick={() => {
                            setCopiedRules(selectedItems.rules);
                            setSelectedItems({ rules: {}, packages: {} });
                          }}>
                          <i className="fas fa-copy"></i>
                        </Button>
                        <UncontrolledTooltip
                          fade={false}
                          target="move-button"
                          modifiers={{
                            computeStyle: { gpuAcceleration: false }
                          }}>
                          {props.t('move_rule_tooltip')}
                        </UncontrolledTooltip> */}

                        {(!!props.isAdmin || !!props.isRuleManager) &&
                          !!isCompliantMesageVisibleInRuleTree && (
                            <div>
                              <Button
                                id="save-button"
                                className="ml-2"
                                color="primary"
                                disabled={Object.keys(touched).length === 0}
                                outline
                                onClick={async () => {
                                  const nextRules = Object.keys(
                                    allRulesCMMode.rules
                                  ).map(key => {
                                    return {
                                      rule_id: allRulesCMMode.rules[key].ruleId,
                                      compliant_message_mode:
                                        allRulesCMMode.rules[key]
                                          .compliant_message_mode
                                    };
                                  });

                                  try {
                                    await rulesBatchUpdate(nextRules);
                                    Message.success(t('main:saved'));
                                  } catch (err) {
                                    Message.error(
                                      t(err.message || 'error:default_message')
                                    );
                                  }
                                }}>
                                <i className="fas fa-save"></i>
                              </Button>
                              <UncontrolledTooltip
                                fade={false}
                                target="save-button"
                                modifiers={{
                                  computeStyle: { gpuAcceleration: false }
                                }}>
                                {props.t('rules:save_changes')}
                              </UncontrolledTooltip>
                            </div>
                          )}
                        <Button
                          id="move-button"
                          className="ml-2"
                          color="primary"
                          disabled={
                            Object.keys(selectedItems.rules).length === 0
                          }
                          outline
                          onClick={() => {
                            setCopiedRules(selectedItems.rules);
                            setPasteModal({ open: true, mode: 'move' });
                            // setSelectedItems({ rules: {}, packages: {} });
                          }}>
                          {t('rules:rules_move')}
                          {/* <i className="fas fa-copy"></i> */}
                        </Button>
                        <UncontrolledTooltip
                          fade={false}
                          target="move-button"
                          modifiers={{
                            computeStyle: { gpuAcceleration: false }
                          }}>
                          {props.t('rules:move_rule_tooltip')}
                        </UncontrolledTooltip>
                        <Button
                          id="copy-button"
                          className="ml-2"
                          color="primary"
                          disabled={
                            Object.keys(selectedItems.rules).length === 0
                          }
                          outline
                          onClick={() => {
                            setCopiedRules(selectedItems.rules);
                            setPasteModal({ open: true, mode: 'copy' });
                          }}>
                          {t('rules:rules_copy')}
                          {/* <i className="fas fa-paste"></i> */}
                        </Button>
                        <UncontrolledTooltip
                          fade={false}
                          target="copy-button"
                          modifiers={{
                            computeStyle: { gpuAcceleration: false }
                          }}>
                          {props.t('rules:copy_rule_tooltip')}
                        </UncontrolledTooltip>
                        <Button
                          id="clone-button"
                          className="ml-2"
                          color="primary"
                          disabled={
                            Object.keys(selectedItems.rules).length === 0
                          }
                          outline
                          onClick={() => {
                            setCopiedRules(selectedItems.rules);
                            setPasteModal({ open: true, mode: 'clone' });
                          }}>
                          {t('rules:rules_clone')}
                          {/* <i className="fas fa-clone"></i> */}
                        </Button>
                        <UncontrolledTooltip
                          fade={false}
                          target="clone-button"
                          modifiers={{
                            computeStyle: { gpuAcceleration: false }
                          }}>
                          {props.t('rules:clone_rule_tooltip')}
                        </UncontrolledTooltip>
                        {(!!props.isAdmin || !!props.isRuleManager) && (
                          <Button
                            id="filters-button"
                            className="ml-2"
                            color="primary"
                            outline={!isFilterOpen}
                            onClick={() => setFilterOpen(!isFilterOpen)}>
                            {t('rules:rules_filter')}
                            {/* <i className="fas fa-filter"></i> */}
                          </Button>
                        )}

                        <Button
                          id="delete-button"
                          className="ml-2"
                          color="danger"
                          disabled={
                            Object.keys(selectedItems.rules).length === 0 ||
                            Object.entries(selectedItems.rules).reduce(
                              (prev, [key, rule]) => {
                                if (level == 'user') {
                                  if (
                                    !rule.isOriginal &&
                                    rule.level == 'user'
                                  ) {
                                    return [...prev, rule];
                                  } else {
                                    return prev;
                                  }
                                } else {
                                  if (!rule.isOriginal) {
                                    return [...prev, rule];
                                  } else {
                                    return prev;
                                  }
                                }
                              },
                              []
                            ).length === 0
                          }
                          outline
                          onClick={() => {
                            setDeleteModalOpen(true);
                          }}>
                          {t('rules:rules_delete')}
                          {/* <i className="fas fa-trash"></i> */}
                        </Button>
                        <UncontrolledTooltip
                          fade={false}
                          target="delete-button"
                          modifiers={{
                            computeStyle: { gpuAcceleration: false }
                          }}>
                          {props.t('rules:delete_rule_tooltip')}
                        </UncontrolledTooltip>
                        {/* <UncontrolledDropdown>
                          <DropdownToggle
                            color="light"
                            size="md"
                            outline
                            // className="pointer nav-link"
                            caret>
                            {t('actions')}
                            <DropdownMenu>
                              <DropdownItem></DropdownItem>
                            </DropdownMenu>
                          </DropdownToggle> */}
                        {/* </UncontrolledDropdown> */}
                      </div>
                    </Row>

                    {/* FILTER COLLAPSE */}
                    <Collapse timeout={0} isOpen={isFilterOpen}>
                      <Row className="p-2">
                        <div className="flex-grow-1">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-search" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="search"
                              name="search"
                              value={searchVal}
                              // id={`search-${packageType}`}
                              placeholder="Search"
                              onChange={e => setSearchVal(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </Row>
                      <Row className="justify-content-end">
                        <div className="d-flex ">
                          <div>
                            <Button
                              id="PopoverLegacy"
                              type="button"
                              color="light"
                              className="dropdown">
                              {t('rules:filter_visibility')}
                            </Button>
                            <UncontrolledPopover
                              trigger="legacy"
                              placement="bottom"
                              target="PopoverLegacy">
                              <PopoverBody>
                                <RuleVisibilty
                                  asFilter={true}
                                  t={t}
                                  setFilterValues={setFilterValues}
                                  filters={filters}
                                />
                                <div className="mt-1">
                                  <Label className="text-secondary font-weight-bold flex-grow-1">
                                    {t('rules:specific_users')}
                                  </Label>
                                  <Input
                                    id="user-visibility-email"
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                  />
                                  <Button
                                    className="mt-1"
                                    block
                                    size="sm"
                                    color="success"
                                    onClick={() => {
                                      const addItem = document.getElementById(
                                        'user-visibility-email'
                                      ).value;

                                      if (!!addItem) {
                                        setFilterValues(
                                          'users_visibility',
                                          filters.users_visibility
                                            ? [
                                                ...filters.users_visibility,
                                                addItem
                                              ]
                                            : [addItem]
                                        );

                                        document.getElementById(
                                          'user-visibility-email'
                                        ).value = '';
                                      } else {
                                        Message.error(
                                          t('error:please_enter_email')
                                        );
                                      }
                                    }}>
                                    <i className="fas fa-user-plus fa-lg"></i>
                                  </Button>
                                  <div>
                                    {filters.users_visibility &&
                                      filters.users_visibility !== 0 && (
                                        <ListGroup className="mt-2">
                                          {filters.users_visibility.map(
                                            (email, index) => (
                                              <ListGroupItem
                                                key={`users-visibility-${index}`}
                                                className="m-0 p-0 d-flex justify-content-between">
                                                <div className="p-2">
                                                  {email}
                                                </div>
                                                <Button
                                                  size="sm"
                                                  color="danger"
                                                  onClick={() => {
                                                    const removeItem =
                                                      filters.users_visibility.splice(
                                                        index,
                                                        1
                                                      );

                                                    setFilterValues(
                                                      'users_visibility',
                                                      filters.users_visibility
                                                    );
                                                  }}>
                                                  <i className="fas fa-user-times"></i>
                                                </Button>
                                              </ListGroupItem>
                                            )
                                          )}
                                        </ListGroup>
                                      )}
                                  </div>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </div>

                          <UncontrolledDropdown>
                            <DropdownToggle
                              color="light"
                              size="md"
                              // className="pointer nav-link"
                              caret>
                              {t('rules:filter_in_result')}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className={classnames({
                                  'text-dark': !(
                                    filters.show_in_result &&
                                    filters.show_in_result === true
                                  ),
                                  'text-white':
                                    filters.show_in_result &&
                                    filters.show_in_result === true
                                })}
                                active={
                                  filters.show_in_result &&
                                  filters.show_in_result === true
                                }
                                onClick={() =>
                                  setFilterValues('show_in_result', true)
                                }>
                                <div className="d-flex justify-content-start">
                                  {t('yes')}
                                </div>
                              </DropdownItem>
                              <DropdownItem
                                className={classnames({
                                  'text-dark': !(
                                    !filters.show_in_result &&
                                    filters.show_in_result === false
                                  ),
                                  'text-white':
                                    filters.show_in_result &&
                                    filters.show_in_result === false
                                })}
                                active={
                                  !filters.show_in_result &&
                                  filters.show_in_result === false
                                }
                                onClick={() =>
                                  setFilterValues('show_in_result', false)
                                }>
                                <div className="d-flex justify-content-start">
                                  {t('no')}
                                </div>
                              </DropdownItem>
                              <DropdownItem
                                className="text-muted"
                                // tag={Button}
                                // color="light"
                                onClick={() =>
                                  setFilterValues('show_in_result', null)
                                }>
                                <div className="d-flex align-content-start justify-content-start">
                                  <i className="fas fa-times mr-3"></i>{' '}
                                  <span>{t('clear')}</span>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          {isPrecheckEnabled && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color="light"
                                size="md"
                                // className="pointer nav-link"
                                caret>
                                {t('rules:filter_precheck')}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  className={classnames({
                                    'text-dark': !(
                                      filters.in_precheck &&
                                      filters.in_precheck === true
                                    ),
                                    'text-white':
                                      filters.in_precheck &&
                                      filters.in_precheck === true
                                  })}
                                  active={
                                    filters.in_precheck &&
                                    filters.in_precheck === true
                                  }
                                  onClick={() =>
                                    setFilterValues('in_precheck', true)
                                  }>
                                  <div className="d-flex justify-content-start">
                                    {t('yes')}
                                  </div>
                                </DropdownItem>
                                <DropdownItem
                                  className={classnames({
                                    'text-dark': !(
                                      !filters.in_precheck &&
                                      filters.in_precheck === false
                                    ),
                                    'text-white':
                                      !filters.in_precheck &&
                                      filters.in_precheck === false
                                  })}
                                  active={
                                    !filters.in_precheck &&
                                    filters.in_precheck === false
                                  }
                                  onClick={() =>
                                    setFilterValues('in_precheck', false)
                                  }>
                                  <div className="d-flex justify-content-start">
                                    {t('no')}
                                  </div>
                                </DropdownItem>
                                <DropdownItem
                                  className="text-muted"
                                  // tag={Button}
                                  // color="light"
                                  onClick={() =>
                                    setFilterValues('in_precheck', null)
                                  }>
                                  <div className="d-flex align-content-start justify-content-start">
                                    <i className="fas fa-times mr-3"></i>
                                    <span>{t('clear')}</span>
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}

                          {isWaiverEnabled && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color="light"
                                size="md"
                                // className="pointer nav-link"
                                caret>
                                {t('rules:filter_waivable')}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  className={classnames({
                                    'text-dark': !(
                                      filters.waivable &&
                                      filters.waivable === true
                                    ),
                                    'text-white':
                                      filters.waivable &&
                                      filters.waivable === true
                                  })}
                                  active={
                                    filters.waivable &&
                                    filters.waivable === true
                                  }
                                  onClick={() =>
                                    setFilterValues('waivable', true)
                                  }>
                                  <div className="d-flex justify-content-start">
                                    {t('yes')}
                                  </div>
                                </DropdownItem>
                                <DropdownItem
                                  className={classnames({
                                    'text-dark': !(
                                      !filters.waivable &&
                                      filters.waivable === false
                                    ),
                                    'text-white':
                                      !filters.waivable &&
                                      filters.waivable === false
                                  })}
                                  active={
                                    !filters.waivable &&
                                    filters.waivable === false
                                  }
                                  onClick={() =>
                                    setFilterValues('waivable', false)
                                  }>
                                  <div className="d-flex justify-content-start">
                                    {t('no')}
                                  </div>
                                </DropdownItem>
                                <DropdownItem
                                  className="text-muted"
                                  // tag={Button}
                                  // color="light"
                                  onClick={() =>
                                    setFilterValues('waivable', null)
                                  }>
                                  <div className="d-flex align-content-start justify-content-start">
                                    <i className="fas fa-times mr-3"></i>
                                    <span>{t('clear')}</span>
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                          <Button
                            color="light"
                            size="md"
                            id="clear-all-filters"
                            // className="pointer nav-link"
                            onClick={() => setFilterValues('all', null)}>
                            <i className="fas fa-times"></i>
                          </Button>
                          <UncontrolledTooltip
                            fade={false}
                            target="clear-all-filters"
                            modifiers={{
                              computeStyle: { gpuAcceleration: false }
                            }}>
                            {props.t('rules:clear_all')}
                          </UncontrolledTooltip>
                        </div>
                      </Row>
                    </Collapse>
                    {/* 
                    <Row className="p-2 justify-content-between">
                      <div className="custom-control custom-checkbox d-inline-block">
                        <input
                          type="checkbox"
                          className={'custom-control-input'}
                          id="select-all-checkbox"
                          checked={isSelectAll}
                          onChange={isSelectAll ? unSelectAll : selectAll}
                          // onClick={onChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="select-all-checkbox">
                          {isSelectAll
                            ? t('rules:unselect_all')
                            : t('rules:select_all')}
                        </label>
                      </div>

                      {(!!props.isAdmin || !!props.isRuleManager) &&
                        !!isCompliantMesageVisibleInRuleTree && (
                          <div className="d-flex">
                            <div className="rulesCompliantMessage">
                              <Label for="" className="mr-2 font-weight-bold">
                                {t('rules:compliant_messages')}
                              </Label>
                            </div>
                          </div>
                        )}
                    </Row> */}
                  </Container>
                </div>
              </div>
              <div className="mt-3 rounded bg-light ">
                <Container>
                  <Row className="mb-4 ">
                    <Col className="">
                      <Loop
                        data={filteredRules}
                        ruleType={majorRuleType}
                        setRules={data => {
                          prevRulesRef.current = [
                            ...prevRulesRef.current,
                            rules
                          ];
                          setRules(data);
                        }}
                        fullRuleTree={rules}
                        t={props.t}
                        level={props.level}
                        onSelectRule={onSelectRule}
                        onSelectPackage={onSelectPackage}
                        selectedItems={selectedItems}
                        saveRules={saveRules}
                        setTouched={setTouched}
                        allRulesCMMode={allRulesCMMode}
                        setAllRulesCMMode={setAllRulesCMMode}
                        onUpdatePackageCMMode={onUpdatePackageCMMode}
                        onUpdateRuleCMMode={onUpdateRuleCMMode}
                        isAdmin={isAdmin}
                        isRuleManager={isRuleManager}
                        isDefaultShown={isDefaultShown}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              {props.level === 'user' && (
                <Container>
                  <Row className="p-2">
                    <div className="custom-control custom-checkbox d-inline-block">
                      <input
                        type="checkbox"
                        className={'custom-control-input'}
                        id="show-default-checkbox"
                        checked={isDefaultShown}
                        onChange={() => setDefaultShown(!isDefaultShown)}
                        // onClick={onChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="show-default-checkbox">
                        {t('rules:show_default')}
                      </label>
                    </div>
                  </Row>
                </Container>
              )}
            </Container>
          </DndProvider>
        )}
      </div>
      <PackageSelection packageTree={rules} />
      <RuleDeleteModal
        level={level}
        t={t}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        setRules={setRules}
        saveRules={saveRules}
        selectedItems={selectedItems}
        prevRulesRef={prevRulesRef}
        rules={rules}
        unSelectAll={unSelectAll}
        deleteRules={deleteRules}
      />
    </Container>
  );
};

export default compose(
  withTranslation(['main', 'rules', 'error', 'user_preferences'])
)(RuleGroupContainer);
