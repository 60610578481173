import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Badge } from 'reactstrap';
import classnames from 'classnames';

import { fetchTopNC } from '../../../../../../store/actions';

import ReactTable from '../../../../../Shared/ReactTable/index.jsx';

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size}
  </span>
);
const Listing = props => {
  const { fetchTopNC, data } = props;
  const [topNCData, setTopNCData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalSize, setTotalSize] = useState(100);

  const paginationOptions = {
    page,
    sizePerPage,
    totalSize,
    // paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Prev',
    nextPageText: 'Next',
    lastPageText: 'Last',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: '5',
        value: 5
      },
      {
        text: '10',
        value: 10
      },
      {
        text: '20',
        value: 20
      },
      {
        text: '100',
        value: 100
      }
    ],
    withFirstAndLast: true,
    onPageChange: (newPage, newSizePerPage) => {
      setPage(newPage);
      setSizePerPage(newSizePerPage);
    },
    onSizePerPageChange: (newSizePerPage, newPage) => {
      setPage(newPage);
      setSizePerPage(newSizePerPage);
    }
  };

  const reactTableColumns = [
    {
      id: 'rank',
      accessor: 'rank',
      Header: '',
      headerClasses: 'd-none',
      disableFilters: true,
      disableSortBy: true,
      width: 20,
      Cell: ({ row, value }) => {
        return <p className="text-muted">{value}.</p>;
      }
    },
    {
      id: 'clause_display_name',
      accessor: 'clause_display_name',
      Header: '',
      headerClasses: 'd-none',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return <p className="text-muted text-center">{value}</p>;
      }
    },
    {
      id: 'count',
      accessor: 'count',
      Header: '',
      headerClasses: 'd-none',
      width: 30,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <h6>
            <Badge color="primary" className="text-center font-weight-bold">
              {value}
            </Badge>
          </h6>
        );
      }
    }
  ];

  useEffect(() => {
    setTopNCData(data.data);
    setTotalSize(data.total);
    setIsLoaded(true);
  }, [data.data]);

  const onTableChange = async (type, newState) => {
    setIsLoaded(false);
    const from = (newState.page - 1) * newState.sizePerPage;
    const to = newState.page * newState.sizePerPage;

    await fetchTopNC({
      pagination: {
        from,
        to
      }
    }).then(data => {
      setTotalSize(data.total);
      setTopNCData(data.data);

      setIsLoaded(true);
    });
  };

  return !!isLoaded ? (
    <ReactTable
      hover
      bordered={false}
      data={topNCData}
      columns={reactTableColumns}
      noDataIndication={<h3 className="text-muted ">No data to display</h3>}
      pagination={paginationOptions}
      remote={{ pagination: true, filter: true, sort: true }}
      loading={!isLoaded}
      onTableChange={onTableChange}
    />
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center loadingDivColor">
      <div
        className="spinner-border text-primary loadingDivDimention"
        role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTopNC: ({ pagination }) => dispatch(fetchTopNC({ pagination }))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['statistics'])
)(Listing);
