import React, { Component } from 'react';

import { TaskList, TaskDetail } from '../TaskManagement';

class CommunicationSpace extends Component {
  render() {
    const { task_section, taskId } = this.props.match.params;

    return (
      <>
        {task_section === 'detail' && taskId ? (
          <TaskDetail {...this.props}  taskId={taskId} />
        ) : (
          <TaskList {...this.props} />
        )}
      </>
    );
  }
}

export default CommunicationSpace;
