import AllNewsPresenter from './AllNewsPresenter';
import { fetchAllNews } from '../../../../store/actions/news';
import { connect } from 'react-redux';

const filteredNewsForEveryone = news => {
  const users = Object.keys(news).map(key => news[key]);
  const filteredNews = users.filter(news => {
    return news.is_approved === true;
  });
  return filteredNews;
};

const mapStateToProps = state => {
  return {
    news: filteredNewsForEveryone(state.news),
    isAuthenticated: state.app.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllNews: () => {
      dispatch(fetchAllNews());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllNewsPresenter);
