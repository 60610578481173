import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { CustomInput } from 'reactstrap';
import { pick } from 'lodash';
import path from 'path';
import FileBrowser, { FolderRenderers } from 'react-keyed-file-browser';
import { element } from 'prop-types';
import styles from './fileExplorer.module.css';

const pickProperty = object => {
  return pick(
    object,
    'id',
    'name',
    'stage_name',
    'submission_id',
    'origin_of_the_file',
    'base_url_to_display_in_file_explorer',
    'date_updated',
    'date_uploaded',
    'description',
    'download_link',
    'path',
    'isFolder',
    'size',
    'allowedActions'
  );
};

class CustomFolderRenderer extends Component {
  constructor(props) {
    super(props);
    this.folderIndention = createRef()
    this.state = {
      showCheckbox: false,
      isCheckBoxChecked: false,
      width: 0,
      selectedItem: [],
      initialSelectFolder: null,
      isLoaded: false
    };
  }
  componentDidMount() {
    this.setCheckBoxPosition();
    const id = this.props.id;
    const is_exist = this.props.checkedItems.findIndex(e => e.id === id);
    const data = pickProperty(this.props);
    const isCheckBoxChecked = is_exist > -1;
    this.setState({
      isCheckBoxChecked: isCheckBoxChecked
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const id = this.props.id;

    const is_exist = this.props.checkedItems.findIndex(e => e.id === id);

    if (prevProps.checkedItems.length !== this.props.checkedItems.length) {
      const data = pickProperty(this.props);
      const isCheckBoxChecked = is_exist > -1;

      this.setState({
        isCheckBoxChecked: isCheckBoxChecked
      });
    }
    
    if(prevState.width !== this.state.width){
      this.folderIndention.current.style['margin-left'] = `${this.state.width}px` 
    }
  }
  toogleDisplayCheckBox = status => {
    this.setState({
      showCheckbox: status
    });
  };

  handleClick = event => {
    const data = pickProperty(this.props);
    const isChecked = event.target.checked;

    this.setState(
      {
        isCheckBoxChecked: isChecked,
        selectedItem: { ...data, isChecked }
      },
      () => {
        const { base_url_to_display_in_file_explorer } = this.props;
        this.props.collectcheckedItems(
          data,
          isChecked,
          base_url_to_display_in_file_explorer
        );
      }
    );
  };

  setCheckBoxPosition() {
    const dir = this.props.base_url_to_display_in_file_explorer;
    const chunks_path = dir ? dir.split('/').filter(with_val => with_val) : [];

    this.setState({
      width: chunks_path.length * 15
    });
  }

  toogleCheckBoxOnChange = (isCheckBoxChecked, data) => {
    this.setState(
      {
        isCheckBoxChecked: isCheckBoxChecked,
        selectedItem: { ...data, isChecked: isCheckBoxChecked }
      },
      () => {
        this.props.collectCheckedItems(this.state.selectedItem);
      }
    );
  };

  render() {
    const check_directory = path.dirname(
      this.props.base_url_to_display_in_file_explorer
    );
    const { showCheckbox, isCheckBoxChecked, selectedItem } = this.state;
    const { selection } = this.props.browserProps;

    return (
      <tr
        className="p-0 m-0 w-100 border-0"
        onMouseEnter={e => {
          this.setCheckBoxPosition();
          this.toogleDisplayCheckBox(true);
        }}
        onMouseLeave={e => {
          this.toogleDisplayCheckBox(false);
        }}

        // ref={element => {
        //   if (
        //     path.normalize(`${this.props.focusStage}/`) === this.props.fileKey
        //   ) {
        //     if (element) {
        //       console.log();
        //     }
        //   }
        // }}
      >
        <div 
          ref={this.folderIndention}
          className={`${showCheckbox || isCheckBoxChecked ? 'visible' : 'invisible'} ${styles.cFileRDivParent}`}>
          <CustomInput
            type="checkbox"
            id={this.props.id}
            onChange={this.handleClick}
            checked={isCheckBoxChecked} />
        </div>
        <td colSpan="3" className="p-0 m-0 w-100 border-0">
          <table className="p-0 m-0 w-100 border-0">
            <tbody
              className="p-0 m-0 w-100 border-0"
              onContextMenu={e => {
                e.preventDefault();
                const position = ReactDOM.findDOMNode(this);

                const activeItem = pickProperty(this.props);

                this.props.setContextMenu(
                  {
                    left: position.offsetLeft + 230,
                    top: position.offsetTop + 100
                  },
                  false,
                  activeItem
                );
              }}>
              <FolderRenderers.TableFolder
                {...this.props}
                isSelected={
                  path.normalize(`${this.props.selectedKey}/`) ===
                  `Documents/${this.props.fileKey}`
                }

                // isOpen={this.state.initialSelectFolder === this.props.fileKey}

                // isOpen={this.state.initialSelectFolder === this.props.fileKey}
                // startOpen={
                //   this.state.initialSelectFolder === this.props.fileKey
                // }
              />
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}
export default CustomFolderRenderer;
