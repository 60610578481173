import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Badge
} from 'reactstrap';

import { withTranslation } from 'react-i18next';

import Notifications from '../Notifications';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';

import Cell from './DahsboardCell';
import ProjectDashboard from '../ProjectDashboard';
import { fetchAllUserPreference } from '../../../store/actions/user_preference';

import './DashboardCell.css';

const TILE_LENGTH = 2;

const Dashboard = props => {
  const { t } = props;
  const featureList = useSelector(state => state.app.featureList);
  const isNotificationPanelEnabled = featureList.notificationPanel;
  const isVideoTutorialsEnabled = featureList.videoTutorial;

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    (async () => {
      if (!isMounted) {
        await props.fetchAllUserPreference().then(() => {
          setIsMounted(true);
        });
      }
    })();
  }, [isMounted]);
  return (
    <>
      {isMounted && (
        <Container fluid>
          <Row className="my-3 flex-wrap justify-content-center">
            <Cell
              title={t('dashboard')}
              tileName="user_projects"
              size="threeQuarter"
              defaultOrder={0}
              tileLength={TILE_LENGTH}
              isMounted={isMounted}
              hasTutoLink={isVideoTutorialsEnabled}
              tutoLinkLabel={t('project:video_tutorial_link_1')}
              tutoLinkTo={'/videotutorials?v=video-1'}>
              <div>
                <ProjectDashboard {...props} />
              </div>
            </Cell>
            {isNotificationPanelEnabled && (
              <Cell
                title={t('notifications')}
                tileName="notifications"
                size="quarter"
                defaultOrder={1}
                tileLength={TILE_LENGTH}
                isMounted={isMounted}>
                <div className="notificationPanel">
                  <Notifications displayMode="block" />
                </div>
              </Cell>
            )}

            {/* <Cell
              title={'project shared to you'}
              tileName="shared_project"
              defaultOrder={3}
              tileLength={TILE_LENGTH}
              size="full"
              isMounted={isMounted}>
              <div>
                <ProjectDashboard {...props} sharedWithGroup={true} />
              </div>
            </Cell> */}
          </Row>
          <Row className="mb-5"></Row>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllUserPreference: () =>
      dispatch(fetchAllUserPreference('dashboard_defaults'))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['dashboard'])
)(Dashboard);
