import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import ProjectCreationPresenter from './ProjectCreationPresenter';
import {
  createProject,
  updateModelList,
  resetModelFile,
  removeModelFile,
  replaceModelFile,
  addModelFile,
  setNewModelFiles,
  fetchRoles,
  setNew2DModelFiles,
  reset2DModelFile,
  add2DModelFile,
  remove2DModelFile,
  update2DModelList,
  fetchListProjectStageName,
  fetchProjectAdditionalFields,
  fetchAllowedIFcVersionList,
  updateProject,
  fetchProjectThumbailSettings,
  setPlanType
} from '../../../store/actions';

import { appActionType } from '../../../store/constants/actionType';

const { UPDATE_FILE_UPLOAD_PROGRESS } = appActionType;

const mapStateToProps = state => {
  return {
    models: state.modelFiles,
    models2D: state.model2DFiles,
    default_stagename: state.app.predefined_list_of_stages.default_stage
      ? state.app.predefined_list_of_stages.default_stage.name
      : null,
    projectAdditionalFields: state.app.projectAdditionalFields,
    allowed_ifc_version: state.app.allowed_ifc_version,
    featureList: state.app.featureList,
    isUploadingFile: state.app.isUploadingFile,
    thumbnailSettings: state.app.thumbnailSettings
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchRoles: () => dispatch(fetchRoles()),
    createProject: projectData => dispatch(createProject(projectData)),
    updateModelList: (
      projectId,
      stageName,
      filesAdded,
      filesRemoved,
      projectAction
    ) =>
      dispatch(
        updateModelList(
          projectId,
          stageName,
          filesAdded,
          filesRemoved,
          projectAction
        )
      ),
    update2DModelList: (
      projectId,
      stageName,
      filesAdded,
      filesRemoved,
      models2dExcludeFile
    ) =>
      dispatch(
        update2DModelList(
          projectId,
          stageName,
          filesAdded,
          filesRemoved,
          models2dExcludeFile
        )
      ),
    fetchListProjectStageName: () => dispatch(fetchListProjectStageName()),
    fetchAllowedIFcVersionList: () => dispatch(fetchAllowedIFcVersionList()),
    modelFileAction: {
      setNewModelFiles: (newFiles, existingFiles) =>
        dispatch(setNewModelFiles(newFiles, existingFiles)),
      resetModelFile: () => dispatch(resetModelFile()),
      removeModelFile: model => dispatch(removeModelFile(model)),
      replaceModelFile: model => dispatch(replaceModelFile(model)),
      addModelFile: model => dispatch(addModelFile(model))
    },
    model2DFileAction: {
      setNew2DModelFiles: (newFiles, existingFiles) =>
        dispatch(setNew2DModelFiles(newFiles, existingFiles)),
      add2DModelFile: model2D => dispatch(add2DModelFile(model2D)),
      reset2DModelFile: () => dispatch(reset2DModelFile()),
      remove2DModelFile: model2D => dispatch(remove2DModelFile(model2D)),
      setPlanType: (model, type) => dispatch(setPlanType(model, type))
    },
    fetchProjectAdditionalFields: () =>
      dispatch(fetchProjectAdditionalFields()),
    updateFileUploadProgress: value =>
      dispatch({ type: UPDATE_FILE_UPLOAD_PROGRESS, progressValue: value }),
    updateProject: (projectId, projectData) =>
      dispatch(updateProject(projectId, projectData)),
    resetProjectStageStore: () => dispatch({ type: 'RESET_STAGES' }),
    fetchProjectThumbailSettings: () => dispatch(fetchProjectThumbailSettings())
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProjectCreationPresenter);
