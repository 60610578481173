import React, { Component } from 'react';
import { Link /* , withRouter */ } from 'react-router-dom';
import { dateFormatting } from '../../../../locales/dateFormat';
import {
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  Row,
  Dropdown,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { isEqual } from 'lodash';
import { getURLParameters } from '../../../../utils/url';

import { createMarks } from '../exportToFileUtil';
import styles from './User.module.css';

import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const userFunction = (func, t) => {
  let role = '';
  switch (func) {
    case 'QP':
      role = t('admin_user:qualified_person');
      break;
    case 'PO':
      role = t('admin_user:processing_officer');
      break;
    case 'RM':
      role = t('admin_user:rule_manager');
      break;
    default:
      role = t('admin_user:administrator');
      break;
  }
  return role;
};

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectOptionsFunction = translate => {
  return {
    QP: translate('admin_user:qualified_person'),
    PO: translate('admin_user:processing_officer'),
    RM: translate('admin_user:rule_manager'),
    ADMIN: translate('admin_user:administrator')
  };
};

const selectOptionsStatus = translate => {
  return {
    0: translate('admin_user:enabled'),
    1: translate('admin_user:disabled')
  };
};

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'created_at',
      order: d || 'desc'
    }
  ];
};

const mapToTable = mapData => {
  return Object.keys(mapData).map(key => {
    const data = {
      ...mapData[key],
      created_at: dateFormatting(mapData[key].created_at, 'defaultHour'),
      updated_at: dateFormatting(mapData[key].updated_at, 'defaultHour'),
      last_connection: mapData[key].last_connection
        ? dateFormatting(mapData[key].last_connection, 'defaultHour')
        : ''
    };

    const fullName =
      mapData[key] && `${mapData[key].first_name} ${mapData[key].last_name}`;
    return { id: key, fullName, ...data };
  });
};

class ListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      filters: {},
      collapse: {},
      selectedUserIds: [],
      dropdownOpen: false,
      formats: ['xlsx', 'csv'],
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFileRange: [0, 0],
      exportFormat: 'csv',
      order: { dataField: 'created_at', order: 'desc' },
      loadingItemToExport: false
    };
  }

  componentDidMount() {
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'created_at') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'created_at', direction: d || 'desc' }
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchUsers();
    }
  }

  fetchUsers() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    this.props
      .fetchUserList(filters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,

            exportRange: [from + 1, to >= totalSize ? totalSize : to],
            exportFileRange: [from, to >= totalSize ? totalSize : to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString(filters)}`
            );
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  exportToXLS = async (format, range) => {
    const filtered_by_selected = await this.props
      .fetchUserList(
        this.state.filters,
        this.state.order,
        {
          from: range[0],
          to: range[1]
        },
        false
      )
      .then(res => mapToTable(res));

    this.props.exportToXLS(filtered_by_selected, 'json', {
      fileName: `user_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };

  filterToURLString(filters) {
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'created_at') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (
                cur === 'created_at' ||
                cur === 'function' ||
                cur === 'status'
              ) {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'created_at') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              if (cur === 'function' || cur === 'status') {
                return { ...acc, [cur]: newState.filters[cur].filterVal };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'created_at', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      filters,
      collapse,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport
    } = this.state;

    const { users, t, toggleCreateUserModal } = this.props;

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => [
      {
        id: 'name',
        accessor: 'name',
        Header: t('name'),
        Cell: ({ row, value }) => {
          return (
            <Link to={`/admin/user/detail/${row.original.user_id}`}>
              {(function () {
                if (
                  !row.original.first_name ||
                  row.original.first_name === 'undefined' ||
                  !row.original.last_name ||
                  row.original.last_name === 'undefined'
                ) {
                  return (
                    <span className="font-italic">
                      {t('admin_user:not_defined')}
                    </span>
                  );
                } else {
                  return `${row.original.first_name} ${row.original.last_name}`;
                }
              })()}
            </Link>
          );
        },

        Filter: ({ column }) => (
          <TextColumnFilter
            placeholder={t('admin_user:name')}
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'function',
        accessor: 'function',
        Header: t('admin_user:function'),
        Cell: ({ row, value }) => (
          <div className="text-secondary">{userFunction(value, t)}</div>
        ),
        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={selectOptionsFunction(t)}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'company',
        accessor: 'company',
        Header: t('admin_user:company'),
        Filter: ({ column }) => (
          <TextColumnFilter
            placeholder={t('admin_user:company')}
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'status',
        accessor: 'status',
        Header: t('status'),
        Filter: ({ column }) => (
          <SelectColumnFilter
            column={column}
            options={selectOptionsStatus(t)}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        ),
        Cell: ({ row, value }) => {
          return (
            <div className={styles.circleIconSize}>
              <span className="pr-3">
                <span
                  className={`text-${
                    !row.original.is_locked ? 'success' : 'danger'
                  }`}>
                  <i className="far fa-circle" />
                  <span className="text-secondary">
                    {' '}
                    {!row.original.is_locked ? 'Enabled' : 'Disabled'}
                  </span>
                </span>
              </span>
            </div>
          );
        }
      },
      {
        id: 'created_at',
        accessor: 'created_at',
        Header: t('creation_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'actions',
        Header: '',
        width: 40,
        Cell: ({ row, value }) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle size="sm" color="muted" className="w-100">
                <i className="fas fa-ellipsis-h" />
              </DropdownToggle>

              <DropdownMenu>
                <Link
                  className="text-decoration-none"
                  to={`/admin/user/detail/${row.original.user_id}`}>
                  <DropdownItem>{t('admin_user:edit')}</DropdownItem>{' '}
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      }
    ];

    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      }
      return <></>;
    };

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    return (
      <>
        <div className="shadow p-3 mt-3 mb-5 rounded">
          <Row className="justify-content-between px-3">
            <h5 className="text-primary">{t('All user')}</h5>

            <div className="text-right pb-2">
              <div>
                <Button
                  className="mr-1"
                  hidden={!(Object.keys(filters).length > 0)}
                  outline
                  size="md"
                  color="secondary"
                  onClick={() => this.clearFilter()}>
                  <i className="fas fa-times"></i> Clear Filters
                </Button>
                <Button
                  color="primary"
                  onClick={toggleCreateUserModal}
                  className="mr-1"
                  outline>
                  <i className="fas fa-plus" /> {t('admin_user:add_user')}
                </Button>

                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}
                  className="float-right">
                  <DropdownToggle
                    color="success"
                    outline
                    disabled={users.length === 0}>
                    <i className="fas fa-file-export mr-1" />
                    Export to
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.formats.map((format, i) => {
                      return (
                        <DropdownItem
                          key={`export-option-${i}`}
                          onClick={() => {
                            this.openExportModal(format);
                          }}>
                          <i
                            className={`far fa-file-excel mr-2 ${styles.exportDropdownColor}`}
                            color="primary"
                          />
                          {format}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </Row>

          <ReactTable
            columns={reactTableColumns(t)}
            data={users}
            hover
            bordered={false}
            pagination={paginationOptions}
            sortByFunc={setOrderBy}
            defaultSorted={defaultSorted()}
            noDataIndication={NoData()}
            loading={!isLoaded}
            remote={{ pagination: true, filter: true, sort: true }}
            overlay={loading =>
              ({ children }) => {
                return (
                  <div className="position-relative">
                    {children}
                    {loading && (
                      <div
                        className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                        <div
                          className={`spinner-border text-primary ${styles.tableOverlayDimension}`}
                          role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
          />
        </div>
        <Modal
          isOpen={exportModalOpen}
          size="lg"
          toggle={() =>
            this.setState(prevState => ({
              exportModalOpen: !prevState.exportModalOpen
            }))
          }
          className="">
          <ModalHeader
            toggle={() =>
              this.setState(prevState => ({
                exportModalOpen: !prevState.exportModalOpen
              }))
            }>
            {t('main:export_records')}
          </ModalHeader>
          <ModalBody>
            <div>{t('main:choose_records_to_export')}</div>
            <div className={styles.modalBodyWidth}>
              <Range
                min={1}
                max={totalSize}
                marks={createMarks(totalSize)}
                // allowCross={false}
                defaultValue={defaultExportRange}
                value={exportRange}
                pushable={1}
                onChange={newRange => {
                  this.setState({
                    exportRange: newRange,
                    exportFileRange: [newRange[0] - 1, newRange[1]]
                  });
                }}
              />
            </div>
            <div className="text-muted font-italic">
              *Note : only filtered items are included
            </div>
            <div>
              Export items {exportRange[0]} to {exportRange[1]} to a{' '}
              {exportFormat} file.
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={loadingItemToExport}
              onClick={async () => {
                this.setState({ loadingItemToExport: true });
                await this.exportToXLS(exportFormat, exportFileRange);
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }));
                this.setState({ loadingItemToExport: false });
              }}>
              {loadingItemToExport && (
                <span
                  className="mr-2 spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {t('main:export')}
            </Button>{' '}
            <Button
              color="secondary"
              disabled={loadingItemToExport}
              onClick={() =>
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }))
              }>
              {t('main:cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ListingPresenter;
