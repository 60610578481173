import React, { Component } from 'react';

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: this.props.type === 'URL' ? this.props.file : undefined
  };

  componentDidUpdate(prevProps) {
    if (this.props.type === 'file' && prevProps.file !== this.props.file) {
      if (!this.props.file) {
        return;
      }

      this.setState({ loading: true }, () => {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(this.props.file);
      });
    }
  }

  render() {
    const { file, type, defaultImage } = this.props;
    const { loading, thumb } = this.state;

    if (!file && type === 'file') {
      return null;
    }

    if (loading && type === 'file') {
      return <p>loading...</p>;
    }

    return (
      <img
        src={thumb}
        alt={file.name}
        onError={e => {
          e.target.onerror = null;
          e.target.src = defaultImage || '';
        }}
        className="mt-2"
        height="300"
        width="100%"
      />
    );
  }
}

export default Thumb;
