import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import CommentListing from './CommentListing';
import Attachments from './Attachments';
import ConfirmationModal from './ConfirmationModal';
import { dateFormatting } from '../../../locales/dateFormat';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import './style.css';

import Message from '../../UI/Message';
class ModelIssueDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      deleteDisabled: false,
      deleteRequested: false,
      formReset: false
    };
  }

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
  };
  render() {
    const { issueId, stageName, projectId, t, issue } = this.props;
    const { deleteDisabled, deleteRequested, submitCount } = this.state;

    return (
      <>
        <>
          <div className="shadow p-3 mt-3 rounded">
            <Button
              color="link"
              className="mb-2"
              tag={Link}
              to={`/project/${projectId}/issue/${stageName}`}>
              <i className="fas fa-chevron-left mr-1" />
              {t('go_to_issue_list')}
            </Button>
            <Button
              className="float-right"
              color="danger"
              onClick={this.toggleDeleteModal}
              id="deleteIssueButton"
              outline>
              <i className="fas fa-trash fs-1-2x" />
            </Button>

            <UncontrolledTooltip
              fade={false}
              target="deleteIssueButton"
              modifiers={{
                computeStyle: { gpuAcceleration: false }
              }}>
              {t('delete_issue')}
            </UncontrolledTooltip>

            <h6 className="text-primary">{t('detail')}: </h6>

            <hr />
            <Row>
              <Col>
                <div>
                  <span>{t('topic')}: </span>
                  <span className="font-weight-bold wordwrap-breakword">
                    {issue.issue_topic}
                  </span>
                </div>
                <div>
                  <span>{t('creator_id')}: </span>
                  <span className="font-weight-bold">{issue.creator_id}</span>
                </div>
                <div>
                  <span>{t('date_created')}: </span>
                  <span className="font-weight-bold">
                    {dateFormatting(issue.date_created, 'defaultHour')}
                  </span>
                </div>
                <div>
                  <span>{t('date_updated')}: </span>
                  <span className="font-weight-bold">
                    {dateFormatting(issue.date_updated, 'defaultHour')}
                  </span>
                </div>
              </Col>
              <Col>
                <span>{t('snapshot')}: </span>
                <div>
                  <img
                    width="100%"
                    height="200"
                    src={`${
                      process.env.REACT_APP_API_ENDPOINT[
                        process.env.REACT_APP_API_ENDPOINT.length - 1
                      ] == '/'
                        ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                        : process.env.REACT_APP_API_ENDPOINT
                    }/api/issue/${projectId}/${stageName}/image_issue/${issueId}`}
                    alt={issue.issue_topic}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Attachments
              t={t}
              issueId={issueId}
              attachments={issue.attachments || []}
            />
          </div>
          <div className="mt-4">
            <CommentListing
              comments={issue && issue.comments ? issue.comments : []}
              t={t}
            />
          </div>

          <ConfirmationModal
            {...this.props}
            isDeleteModalOpen={this.state.isDeleteModalOpen}
            toggleDeleteModal={this.toggleDeleteModal}
            deleteDisabled={deleteDisabled}
            deleteRequested={deleteRequested}
            submitCount={submitCount}
            issueIds={[issueId]}
          />
        </>
      </>
    );
  }
}

export default compose(withTranslation(['project']))(ModelIssueDetail);
