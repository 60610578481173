import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  CustomInput
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import styles from '../Notifications/Notifications.module.css';
import { RULES_KEY_SEPARTOR } from '../../../store/constants/misc';
import { useSelector } from 'react-redux';
import autocheckStyles from './autocheck.module.css';

const ConfirmationModal = props => {
  const featureList = useSelector(state => state.app.featureList);
  const isPaymentModuleEnabled = featureList.paymentModule;

  const { t, estimatedCU, estimatedNextCUBalance, currentCUBalance } = props;
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className={props.className}>
      <ModalHeader
        toggle={() => {
          props.toggle();
        }}>
        {(function () {
          switch (props.checkType) {
            case 'regulatory':
              return t('autochecker:regulatory_check');
            case 'clash':
              return t('autochecker:clash_check');
            case 'quality':
              return t('autochecker:quality_check');
            case 'bimdata':
              return t('autochecker:bim_data_check');
          }
        })()}
      </ModalHeader>
      <ModalBody>{t('confirmation_modal_content')}</ModalBody>
      <ModalBody>
        <Card>
          <CardHeader
            className={`p-1 text-center font-weight-bold ${autocheckStyles.confirmationModalCardHeader}`}>
            {t('autochecker:rules_checked')} (
            {props.filteredRulesChecked && props.filteredRulesChecked.length})
          </CardHeader>
          <CardBody
            className={`p-1 container overflow-auto" ${autocheckStyles.confirmationModalCardBody}`}>
            <div className="d-flex flex-row">
              <div
                className={`d-flex flex-column p-0 ${styles.scrollableStyles}`}>
                {props.filteredRulesChecked &&
                  props.filteredRulesChecked.map((r, index) => {
                    return (
                      <div
                        key={`ruleChekcked-${index}`}
                        className={`m-2 ${autocheckStyles.confirmationModalRuleList}`}>
                        <CustomInput
                          type="checkbox"
                          key={`ruleChekcked-${index}`}
                          id={`ruleChekcked-${index}`}
                          label={`${r.name}${r.disable ? '(dependency)' : ''}`}
                          disabled={!!r.disable}
                          checked={true}
                          onChange={e => {
                            const packageKeys = r.treeKey
                              .split(RULES_KEY_SEPARTOR)[0]
                              .split('-')
                              .reduce((prev, split, index) => {
                                const next = [
                                  ...prev,
                                  [...prev, split].join('-')
                                ];
                                if (index > 0) {
                                  return next;
                                } else {
                                  return [];
                                }
                              }, [])
                              .map(k => `packageKey-${k}`);
                            const nextRulesChecked = props.rulesChecked.filter(
                              key => {
                                return (
                                  key !== r.treeKey &&
                                  !packageKeys.includes(key)
                                );
                              }
                            );
                            this.setState({
                              rulesChecked: nextRulesChecked
                            });
                            this.onChecked(nextRulesChecked);
                            // this.setState({
                            //   addRulesToFavoriteCheckbox: e.target.checked
                            // });
                          }}
                          // inline
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </CardBody>
        </Card>
        {isPaymentModuleEnabled && (
          <>
            <hr />
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between">
                <div>{t('current_cu_balance')}</div>
                <div>{currentCUBalance}</div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div>{t('rule_check_estimated_cu')}</div>
                <div>{estimatedCU}</div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div>{t('estimated_next_cu_balance')}</div>
                <div>{estimatedNextCUBalance}</div>
              </div>
              <div className="text-muted font-italic">
                {t('disclaimer_estimation')}
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.toggle();
            props.submit();
          }}>
          {t('confirm')}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            props.toggle();
          }}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation(['autochecker'])(ConfirmationModal);
