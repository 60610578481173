import { performRequest } from '../requests';
import { professionType } from '../../constants/actionType';
const { RESET_PROFESSION, FETCH_ALL_PROFESSION } = professionType;

export const resetProfession = () => dispatch => {
  dispatch({
    type: RESET_PROFESSION
  });
};

export const fetchProfession = () => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      'api/user/profession',
      {},
      requestConfig,
      '',
      true,
      true
    )
  )
    .then(result => {
      dispatch({
        type: FETCH_ALL_PROFESSION,
        list: result.data
      });
    })
    .catch(err => {});
};
