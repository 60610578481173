import React from 'react';
import Switch from 'rc-switch';
import classnames from 'classnames';
import 'rc-switch/assets/index.css';
import './custom.css';
import styles from './styles.module.css';

const CustomSwitch = ({
  checkedColor,
  unCheckedColor,
  checked,
  onChange,
  ...props
}) => (
  <Switch
    {...props}
    checked={checked}
    onChange={onChange}
    onClick={(val, e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    className={classnames(props.className || '', 'custom-switch', checkedColor && checked
        ? styles[checkedColor]
        : unCheckedColor && !checked
        ? styles[unCheckedColor]
        : '')}

  />
);
export default CustomSwitch;
