export const socketNewsEvents = {
  CREATE_NEWS: 'CREATE_NEWS',
  GET_NEWS_FOR_EVERYONE: 'GET_NEWS_FOR_EVERYONE',
  UPDATE_NEWS: 'UPDATE_NEWS',
  GET_UPDATED_NEWS_FOR_EVERYONE: 'GET_UPDATED_NEWS_FOR_EVERYONE',
  NEWS_CREATED: 'NEWS_CREATED',
  EDIT_UNIVERSAL_TEMPLATE: 'EDIT_UNIVERSAL_TEMPLATE',
  DISMISS_ALL_NOTIFICATION: 'DISMISS_ALL_NOTIFICATION',
  DISMISS_ALL_READ_NOTIFICATION: 'DISMISS_ALL_READ_NOTIFICATION',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
  ASSIGNED_AS_REVIEWER: 'ASSIGNED_AS_REVIEWER',
  APPROVE_NEWS: 'APPROVE_NEWS'
};

export const socketProjectEvents = {
  SEND_NOTIFICATION_ON_PROJECT: 'SEND_NOTIFICATION_ON_PROJECT',
  WEBVIEWER_STATUS_UPDATED: 'WEBVIEWER_STATUS_UPDATED',
  RULE_CHECK_RESULT_STATUS_UPDATED: 'RULE_CHECK_RESULT_STATUS_UPDATED',
  ADD_FILE_TO_PROJECT: 'ADD_FILE_TO_PROJECT',
  ADD_MEMBER_TO_PROJECT: 'ADD_MEMBER_TO_PROJECT',
  ADD_YOU_TO_PROJECT: 'ADD_YOU_TO_PROJECT',
  COMMENT_ON_THE_PROJECT: 'COMMENT_ON_THE_PROJECT',
  EDIT_PROJECT_NAME: 'EDIT_PROJECT_NAME',
  EDIT_ROLE_TO_PROJECT: 'EDIT_ROLE_TO_PROJECT',
  EDIT_THUMBNAIL_OF_PROJECT: 'EDIT_THUMBNAIL_OF_PROJECT',
  EDIT_DESCRIPTION_OF_PROJECT: 'EDIT_DESCRIPTION_OF_PROJECT',
  MENTIONED_IN_PROJECT: 'MENTIONED_IN_PROJECT',
  REMOVED_YOU_FROM_THE_PROJECT: 'REMOVED_YOU_FROM_THE_PROJECT',
  PENDING_USER_REGISTRATION: 'PENDING_USER_REGISTRATION',
  WAIVER_RESULT_STATUS_UPDATED: 'WAIVER_RESULT_STATUS_UPDATED',
  SHARE_PROJECT_TO_GROUP: 'SHARE_PROJECT_TO_GROUP',
  COMPARISON_COMPLETED: 'COMPARISON_COMPLETED',
  LEAVE_THE_PROJECT: 'LEAVE_THE_PROJECT',
  DELETE_STAGE_FROM_PROJECT: 'DELETE_STAGE_FROM_PROJECT',
  PRECHECK_COMPLETED: 'PRECHECK_COMPLETED',
  WEBVIEWER_REGENERATE_REPORT_DONE: 'WEBVIEWER_REGENERATE_REPORT_DONE',
  WEBVIEWER_REGENERATE_REPORT_ERRfornnaxOR: 'WEBVIEWER_REGENERATE_REPORT_ERROR',
  RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE:
    'RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE'
};

export const partialResultEvents = {
  RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE:
    'RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE'
};

export const socketCommonEvents = {
  MENTIONED_IN_PROJECT: 'MENTIONED_IN_PROJECT'
};

export const notificationEvent = {
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION'
};

export const submissionEvents = {
  CONSULTATION_COMPLETED: 'CONSULTATION_COMPLETED',
  CONSULTATION_REVIEWED: 'CONSULTATION_REVIEWED',
  REOPEN_CONSULTATION: 'REOPEN_CONSULTATION',
  NEW_CONSULTATION: 'NEW_CONSULTATION',
  MENTIONED_IN_CORRESPONDENCE: 'MENTIONED_IN_CORRESPONDENCE'
};

export const taskManagementEvents = {
  ACTION_ACTIVE: 'ACTION_ACTIVE',
  TASK_COMPLETED: 'TASK_COMPLETED',
  ACTION_COMPLETED: 'ACTION_COMPLETED'
};

export const socketAutocheckEvents = {
  RULE_DATA_VALIDATION_DONE: 'RULE_DATA_VALIDATION_DONE',
  RULE_DATA_VALIDATION_ON_ERROR: 'RULE_DATA_VALIDATION_ON_ERROR'
};

export const stageDeletionEvents = {
  STAGE_BACKUP_DATA: 'STAGE_BACKUP_DATA',
  STAGE_MODEL_DELETION: 'STAGE_MODEL_DELETION',
  STAGE_REFERRENCE_DOCUMENT_DELETION: `STAGE_REFERRENCE_DOCUMENT_DELETION`,
  STAGE_JOB_DELETION: 'STAGE_JOB_DELETION',
  STAGE_ISSUE_DELETION: 'STAGE_ISSUE_DELETION',
  STAGE_SUBMISSION_DELETION: 'STAGE_SUBMISSION_DELETION',
  STAGE_PIN_AND_REFERENCE_ANNOTATION_DELETION:
    'STAGE_PIN_AND_REFERENCE_ANNOTATION_DELETION',
  STAGE_ARCHIVING_PROGRESS: 'STAGE_ARCHIVING_PROGRESS'
};

export const socketRuleManagementEvents = {
  ALTER_RULE_TREE: 'ALTER_RULE_TREE'
};
