import React, { useState, useEffect } from 'react';
import {
  Button,
  FormGroup,
  Col,
  Row,
  Container,
  CardText,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip
} from 'reactstrap';

import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import CustomInput from '../../UI/Input/customInput';
import Message from '../../UI/Message';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

const formikConfig = props => {
  const passwordValidationSchema = {
    current_password: Yup.string()
      // current password is required if the new and confirm password field are not empty
      .when(['new_password', 'confirm_password'], {
        is: (newPassword, confirmPassword) => newPassword || confirmPassword,
        then: Yup.string().required('Required')
      }),
    new_password: Yup.string()
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .matches(/^[a-zA-Z0-9]*$/, 'Password must contain only alphanumeric')
      .min(8, 'Password must contain at least 8 characters.')
      // new_password is required if the current and confirm password field are not empty
      .when(['current_password', 'confirm_password'], {
        is: (currentPassword, confirmPassword) =>
          currentPassword || confirmPassword,
        then: Yup.string().required('Required')
      }),
    confirm_password: Yup.string()
      .test('new_password', props.t('error:not_match'), function (value) {
        return this.parent.new_password === value;
      })
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/[0-9]/, 'Password must contain at least one numeral.')
      .min(8, 'Password must contain at least 8 characters.')
      // confirm_password is required if the current and new password field are not empty
      .when(['new_password', 'current_password'], {
        is: (newPassword, currentPassword) => newPassword || currentPassword,
        then: Yup.string().required('Required')
      })
  };

  if (props.authenticationMethod === 'active_directory') {
    delete passwordValidationSchema.current_password;
    delete passwordValidationSchema.new_password;
    delete passwordValidationSchema.confirm_password;
    passwordValidationSchema.adUsername = Yup.string()
      .typeError('Invalid')
      .required('Required');
  }

  return {
    initialValues: {
      first_name: props.authUser.first_name,
      last_name: props.authUser.last_name,
      email: props.authUser.email,
      company: props.authUser.company,
      current_password: '',
      new_password: '',
      confirm_password: '',
      adUsername: props.authUser.ad_username,
      profession: props.authUser.profession
    },
    validateOnClick: true,
    validationSchema: Yup.object().shape(
      {
        first_name: Yup.string()
          .trim()
          .required('Required')
          .matches(
            /^[a-zA-Z ]*$/,
            'Numbers and special characters are not allowed'
          ),
        last_name: Yup.string()
          .trim()
          .required('Required')
          .matches(
            /^[a-zA-Z ]*$/,
            'Numbers and special characters are not allowed'
          ),
        /*email: Yup.string()
      .email('It must be a valid email address (example@email.com)')
      .required('Required'),*/
        company: Yup.string().trim().required('Required'),
        profession: Yup.string().required('Required'),
        ...passwordValidationSchema
      },
      props.authenticationMethod === 'active_directory'
        ? []
        : [
            ['current_password', 'confirm_password'],
            ['current_password', 'new_password'],
            ['confirm_password', 'new_password']
          ]
    ),

    onSubmit: (values, { setSubmitting, resetForm }) => {
      const userDataToPush = {};
      for (let key in values) {
        if (values[key]) {
          userDataToPush[key] = values[key];
        }
      }
      setSubmitting(true);
      props
        .editUserProfile(props.authUser.userId, userDataToPush)
        .then(res => {
          if (res === 'success') {
            Message.success(props.t('editProfile:success'));
            resetForm(values);
          } else {
            Message.error(props.t('error:incorrect_current_password'));
          }
          setSubmitting(false);
        })
        .catch(err => {
          console.log(err);
          setSubmitting(false);
        });
    }
  };
};

const EditProfilePresenter = props => {
  const featureList = useSelector(state => state.app.featureList);
  const profession = useSelector(state => state.profession);
  const authenticationMethod = featureList.authenticationMethod;
  const isThirdPartyModeEnabled = featureList.thirdPartyMode;
  const { t } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiToken, setAPIToken] = useState('');
  const [copyButtonTooltip, setCopyButtonTooltip] = useState(
    t('editProfile:copy_to_clipboard')
  );
  const [generateTokenTooltip, setGenerateTokenTooltip] = useState(
    t('editProfile:generate_token')
  );

  useEffect(() => {
    props.fetchProfession();
    setIsLoaded(true);
  }, []);

  const generateAPIToken = async () => {
    const res = await fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT[
          process.env.REACT_APP_API_ENDPOINT.length - 1
        ] == '/'
          ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
          : process.env.REACT_APP_API_ENDPOINT
      }/api/user/generateAPIToken`
    ).then(res => res.json());
    setAPIToken(res.token);
    setGenerateTokenTooltip(t('editProfile:token_generated'));
  };

  const professionDropdown = (profession, t) => {
    return profession.map((res, index) => {
      return (
        <option key={index} value={res.name}>
          {t(res.name)}
        </option>
      );
    });
  };

  return (
    <>
      {isLoaded && (
        <div>
          <CardBody>
            <Formik {...formikConfig({ ...props, authenticationMethod })}>
              {props => {
                const { isSubmitting, dirty } = props;
                return (
                  <Form>
                    {' '}
                    <div className=" mb-3">
                      <FormGroup>
                        <CardText className="text-muted">
                          {t('editProfile:personal_info')}
                        </CardText>
                      </FormGroup>

                      <Container>
                        <Row>
                          <Col sm={12} lg={6}>
                            <FormGroup>
                              <Field
                                name="first_name"
                                label={t('register:firstName_placeholder')}
                                placeholder={t(
                                  'register:firstName_placeholder'
                                )}
                                component={CustomInput}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={12} lg={6}>
                            <FormGroup>
                              <Field
                                name="last_name"
                                label={t('register:lastName_placeholder')}
                                placeholder={t('register:lastName_placeholder')}
                                component={CustomInput}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={6}>
                            <FormGroup>
                              <Field
                                name="email"
                                label={t('login:email_placeholder')}
                                placeholder={t('login:email_placeholder')}
                                component={CustomInput}
                                disabled
                                noValidation
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={12} lg={6}>
                            <FormGroup>
                              <Field
                                name="company"
                                label={t('register:company_placeholder')}
                                placeholder={t('register:company_placeholder')}
                                component={CustomInput}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} lg={6}>
                            <FormGroup>
                              <Field
                                name="profession"
                                type="select"
                                label={t('register:profession_placeholder')}
                                component={CustomInput}
                                prefix={<i className="fas fa-briefcase" />}>
                                <option
                                  className="d-none"
                                  value=""
                                  disabled
                                  selected>
                                  {t('register:profession_placeholder')}
                                </option>
                                {professionDropdown(profession, t)}
                              </Field>
                            </FormGroup>
                          </Col>

                          <Col sm={12} lg={6}></Col>
                        </Row>
                      </Container>
                    </div>
                    {authenticationMethod !== 'active_directory' ? (
                      <div className="mt-3 mb-3 ">
                        <FormGroup>
                          <CardText className="text-muted">
                            {t('changePassword:change_password')}
                          </CardText>
                        </FormGroup>

                        <Container>
                          <Row>
                            <Col sm={12} lg={4}>
                              <FormGroup className="w-5">
                                <Field
                                  name="current_password"
                                  type="password"
                                  label={t('editProfile:current_password')}
                                  placeholder={t(
                                    'editProfile:current_password'
                                  )}
                                  component={CustomInput}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={12} lg={4}>
                              <FormGroup>
                                <Field
                                  name="new_password"
                                  type="password"
                                  label={t('editProfile:new_password')}
                                  placeholder={t('editProfile:new_password')}
                                  component={CustomInput}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={12} lg={4}>
                              <FormGroup>
                                <Field
                                  name="confirm_password"
                                  type="password"
                                  label={t('editProfile:confirm_password')}
                                  placeholder={t(
                                    'editProfile:confirm_password'
                                  )}
                                  component={CustomInput}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    ) : (
                      <Row>
                        <Col sm={12} lg={6}>
                          <FormGroup>
                            <Field
                              name="adUsername"
                              label={
                                <>
                                  <span>
                                    {t('editProfile:active_directory_username')}
                                  </span>
                                  {' - '}
                                  <span className="text-muted font-italic">
                                    {t('editProfile:no_domain_name')}
                                  </span>
                                </>
                              }
                              placeholder={t(
                                'editProfile:adUsername_placeholder'
                              )}
                              component={CustomInput}
                              disabled
                              noValidation
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {isThirdPartyModeEnabled && (
                      <div className="mb-3">
                        <FormGroup>
                          <CardText className="text-muted">
                            {t('editProfile:third_party_api')}
                          </CardText>
                        </FormGroup>

                        <Container>
                          <Row>
                            <FormGroup className="align-self-end mb-0 flex-grow-1">
                              <Label for="apiTokenInput">
                                {t('editProfile:api_token')}
                              </Label>

                              <InputGroup>
                                <Input
                                  id="apiTokenInput"
                                  placeholder={t(
                                    'editProfile:api_token_placeholder'
                                  )}
                                  type="text"
                                  value={apiToken}
                                  readonly=""
                                />
                                {apiToken !== '' && (
                                  <>
                                    <InputGroupAddon addonType="append">
                                      <Button
                                        id="copyButton"
                                        outline
                                        color="dark"
                                        onMouseEnter={() =>
                                          setCopyButtonTooltip(
                                            t('editProfile:copy_to_clipboard')
                                          )
                                        }
                                        onClick={() =>
                                          navigator.clipboard
                                            .writeText(apiToken)
                                            .then(() =>
                                              setCopyButtonTooltip(
                                                t('editProfile:copied')
                                              )
                                            )
                                        }>
                                        {t('editProfile:copy')}
                                      </Button>
                                    </InputGroupAddon>
                                    <UncontrolledTooltip
                                      fade={false}
                                      target={'copyButton'}
                                      boundariesElement="window">
                                      {copyButtonTooltip}
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              </InputGroup>
                            </FormGroup>
                            <div className="ml-3 align-self-end">
                              <Button
                                id="generateTokenButton"
                                color="dark"
                                outline
                                onClick={() => generateAPIToken()}
                                onMouseEnter={() =>
                                  setGenerateTokenTooltip(
                                    t('editProfile:generate_token')
                                  )
                                }
                                // disabled={isSubmitting}
                                block>
                                {t('editProfile:generate_api_token')}
                              </Button>
                              <UncontrolledTooltip
                                fade={false}
                                target={'generateTokenButton'}
                                boundariesElement="window">
                                {generateTokenTooltip}
                              </UncontrolledTooltip>
                            </div>
                          </Row>
                          {apiToken !== '' && (
                            <Row>
                              <div className="text-warning">
                                <i
                                  id="token-info"
                                  className="fas fa-info-circle text-warning mr-2"></i>
                                {t('editProfile:token_expiration')}
                              </div>
                            </Row>
                          )}
                        </Container>
                      </div>
                    )}
                    <FormGroup className="mt-5">
                      <Row>
                        <Col xs={12} md={6} lg={6} />
                        <Col xs={12} md={6} lg={6}>
                          <Row>
                            <Col xs={6}>
                              <Button
                                color="secondary"
                                onClick={() => props.history.goBack()}
                                // disabled={isSubmitting}
                                block>
                                {t('editProfile:cancel')}
                              </Button>
                            </Col>
                            <Col xs={6}>
                              <Button
                                type="submit"
                                color="success"
                                disabled={isSubmitting || !dirty}
                                block>
                                {t('editProfile:save')}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </div>
      )}
    </>
  );
};

export default withTranslation([
  'register',
  'editProfile',
  'changePassword',
  'error'
])(EditProfilePresenter);
