import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Button,
  Container,
  CustomInput,
  FormGroup,
  Label,
  Row,
  Col
} from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Message from '../../../UI/Message';
import {
  fetchAdminConfigSettings,
  editAdminConfigSettings
} from '../../../../store/actions';

const formikConfig = props => {
  const { t, admin_configuration } = props;
  const { featureList } = admin_configuration;
  const initialSecurityValue = { featureList };

  return {
    initialValues: initialSecurityValue,
    onSubmit: (values, { setSubmitting, setFieldValue, resetForm }) => {
      setSubmitting(true);
      props
        .editAdminConfigSettings(values)
        .then(() => {
          Message.success(t('config_update_success'));
        })
        .catch(err => {
          Message.error(props.t(`error:${err.message}`));
        })
        .then(() => {
          resetForm({ values });
          setSubmitting(false);
        });
    },
    validationSchema: FormValues => {
      return Yup.object().shape({
        featureList: Yup.object().shape({
          recaptcha: Yup.boolean(),
          CSRFprotection: Yup.boolean()
        })
      });
    }
  };
};

const SecurityConfig = props => {
  const { t, fetchAdminConfigSettings } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchAdminConfigSettings().then(success => {
      if (success) {
        setIsLoaded(true);
      }
    });
  }, []);

  const hadleSwitchedAction = (
    el,
    setFieldValue,
    fieldNameSwitch,
    fieldNameInput,
    fieldValue
  ) => {
    const ischeck = el.target.checked;
    setFieldValue(fieldNameSwitch, ischeck);
    setFieldValue(fieldNameInput, !ischeck ? fieldValue : '');
  };

  return (
    <>
      {isLoaded && (
        <Formik {...formikConfig(props)}>
          {formikProps => {
            const { setFieldValue, values, onSubmit } = formikProps;

            return (
              <Form>
                <Row className="p-3">
                  <Col className="d-flex">
                    <Button
                      type="submit"
                      color="success"
                      outline={!formikProps.dirty}
                      className="ml-auto"
                      disabled={formikProps.isSubmitting || !formikProps.dirty}
                      onClick={onSubmit}
                      id="formSaveButton">
                      <i className="fas fa-save fs-1-2x" />
                      <span className="ml-2"> {t('save_button_label')}</span>
                    </Button>
                  </Col>
                </Row>
                <Container>
                  <FormGroup row>
                    <Col>
                      <Label className="text-secondary font-weight-bold">
                        {t('CSRFprotection')}
                      </Label>
                    </Col>
                    <Col sm={2}>
                      <CustomInput
                        className="w-100"
                        type="switch"
                        id="values.featureList.CSRFprotection"
                        name="values.featureList.CSRFprotection"
                        label="Enable"
                        onChange={el => {
                          hadleSwitchedAction(
                            el,
                            setFieldValue,
                            'featureList.CSRFprotection',
                            -1
                          );
                        }}
                        checked={values.featureList.CSRFprotection}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col>
                      <Label className="text-secondary font-weight-bold">
                        {t('recaptcha')}
                      </Label>
                    </Col>
                    <Col sm={2}>
                      <CustomInput
                        className="w-100"
                        type="switch"
                        id="values.featureList.recaptcha"
                        name="values.featureList.recaptcha"
                        label="Enable"
                        onChange={el => {
                          hadleSwitchedAction(
                            el,
                            setFieldValue,
                            'featureList.recaptcha',
                            -1
                          );
                        }}
                        checked={values.featureList.recaptcha}
                      />
                    </Col>
                  </FormGroup>
                </Container>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    admin_configuration: state.admin_configuration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminConfigSettings: () => dispatch(fetchAdminConfigSettings()),
    editAdminConfigSettings: data => dispatch(editAdminConfigSettings(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['adminConfigurations'])
)(SecurityConfig);
