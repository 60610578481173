import React from 'react';

const TextOverflowElipsis = props => {
  const { length = 60, text } = props;
  return text.length > length ? (
    <span title={text}>{text.slice(0, length)}...</span>
  ) : (
    text
  );
};

export default TextOverflowElipsis;
