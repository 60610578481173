import React from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { getIn } from 'formik';

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  prefix,
  label,
  id,
  noValidation,
  noSuccessValidation,
  noErrorValidation,
  labelClassName,
  row = false,
  ...props
}) => {
  const isValid =
    !noValidation &&
    !noSuccessValidation &&
    getIn(touched, field.name) &&
    !getIn(errors, field.name);
  const isInvalid =
    !noValidation &&
    !noErrorValidation &&
    !!(getIn(touched, field.name) && getIn(errors, field.name));
  return (
    <FormGroup row={row}>
      {!!label && (
        <Label className={labelClassName} for={id || field.name}>
          {label}
        </Label>
      )}
      <InputGroup>
        <div className="d-flex w-100">
          {!!prefix && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{prefix}</InputGroupText>
            </InputGroupAddon>
          )}
          <div className="d-flex flex-column flex-grow-1">
            <Input
              {...field}
              {...props}
              id={id || field.name}
              invalid={isInvalid}
              valid={isValid}
            />
            {getIn(touched, field.name) && getIn(errors, field.name) && (
              <div className="invalid-feedback">
                {getIn(errors, field.name)}
              </div>
            )}
          </div>
        </div>
      </InputGroup>
    </FormGroup>
  );
};

export default CustomInputComponent;
