import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const NotificationActionModal = props => {
  const { openModal, toggleModal, message, confirmAction, t } = props;
  return (
    <Modal isOpen={openModal} toggle={toggleModal}>
      <ModalBody className="text-center my-4">
        <p className="mb-2">{t(message)}</p>
        <Button
          size="sm"
          color="primary"
          className="mx-2 px-3"
          onClick={() => confirmAction()}>
          {t('confirm')}
        </Button>
        <Button
          size="sm"
          color="secondary"
          className="mx-2 px-3"
          onClick={() => toggleModal()}>
          {t('cancel')}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation(['notifications'])(NotificationActionModal);
