import { rulesActionType } from '../../constants/actionType';

const initialState = { rulesTree: [], rulesSet: {} };

const {
  MERGE_RULES,
  RESET_RULES,
  SET_RULE_PARAMS,
  UPDATE_RULE_PARAMETER,
  UPDATE_MULTIPLE_RULE_PARAMETER,
  UPDATE_RULE_SET,
  SET_ENTITY_VERSION_ID
} = rulesActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_RULES:
      return initialState;
    case MERGE_RULES:
      return {
        ...state,
        rulesTree: action.rulesTree,
        rulesSet: action.rules,
        defaultRulesTree: action.defaultRulesTree || [],
        concurrencyControlVersionId: action.concurrencyControlVersionId,
        entityTargetId: action.entityTargetId
      };
    case UPDATE_RULE_SET:
      return {
        ...state,
        rulesSet: { ...state.rulesSet, ...action.rules }
      };
    case SET_RULE_PARAMS:
      return {
        ...state,
        defaultRulesTree: {
          ...state.rulesSet,
          [action.ruleId]: {
            ...(state.rulesSet[action.ruleId] || {}),
            ...action.data
          }
        }
      };
    case UPDATE_RULE_PARAMETER:
      return {
        ...state,
        rulesSet: {
          ...state.rulesSet,
          [action.ruleId]: {
            ...state.rulesSet[action.ruleId],
            params: {
              ...((state.rulesSet[action.ruleId] &&
                state.rulesSet[action.ruleId].params) ||
                {}),
              ...action.data
            }
          }
        }
      };
    case UPDATE_MULTIPLE_RULE_PARAMETER: {
      const nextRulesSet = action.listRuleId.reduce((previous, ruleId) => {
        return {
          ...previous,
          [ruleId]: {
            ...(previous[ruleId] || {}),
            params: {
              ...((previous[ruleId] && previous[ruleId].params) || {}),
              ...action.data
            }
          }
        };
      }, state.rulesSet);
      return { ...state, rulesSet: nextRulesSet };
    }

    case SET_ENTITY_VERSION_ID: {
      return {
        ...state,

        concurrencyControlVersionId: action.concurrencyControlVersionId
      };
    }

    default:
      return state;
  }
};

export default reducer;
