import React from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';

const UploadProgressBar = () => {
  const progressBarValue = useSelector(state => state.app.fileUploadProgress);
  return (
    <Progress
      striped
      className="mt-2 font-weight-bold"
      value={progressBarValue}>
      {parseInt(progressBarValue)}%{' '}
    </Progress>
  );
};

export default UploadProgressBar;
