import React, { useEffect } from 'react';
//import QueryUI from './dist/QueryUI';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectStage } from '../../../store/actions';
import { withTranslation } from 'react-i18next';
// import { getPortalInfoAPI } from './dist/QueryUI/queryUIContext/api';

const Index = props => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const { id, stageName } = props.match.params;

  const viewerToken =
    state.project_stages[id] &&
    state.project_stages[id].stages[stageName].viewerToken;

  useEffect(() => {
    dispatch(fetchProjectStage(id, stageName));
  }, []);

  useEffect(() => {
    //  if (viewerToken) getPortalInfoAPI(viewerToken);
  }, [viewerToken]);

  return (
    <div className="px-0">
      {/* {state.project_stages[id] &&
        state.project_stages[id].stages[stageName].viewer_geo_id && (
          <QueryUI
            t={props.t}
            projectInfo={{
              token:
                state.project_stages[id] &&
                state.project_stages[id].stages[stageName].viewerToken,
              projectId:
                state.project_stages[id] &&
                state.project_stages[id].stages[
                  stageName
                ].viewer_geo_id.toString(),
              userFunction: state.authUser.function
            }}
          />
        )} */}
    </div>
  );
};

export default withTranslation(['queryUI'])(Index);
