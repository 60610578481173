import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  useFlexLayout
} from 'react-table';

import {
  Table,
  Button,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  Collapse
} from 'reactstrap';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function ReactTable({
  columns,
  data,
  defaultSorted,
  pagination,
  remote,
  onTableChange,
  sortByFunc,
  noDataIndication,
  loading,
  selectRow,
  overlay,
  idColumn,
  ...tableProps
}) {
  const reactTableColumns = React.useMemo(() => columns, []);

  const getRowId = React.useMemo(() => (row, relativeIndex, parent) => {
    if (selectRow && idColumn) {
      return row && row[idColumn]
        ? row[idColumn]
        : parent
        ? [parent.id, relativeIndex].join('.')
        : relativeIndex;
    } else {
      // default
      return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
    }
  });

  const {
    // TABLE PROPERTIES
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    headers,

    // ROW SELECT
    isAllRowsSelected,
    selectedFlatRows,

    // PAGINATION PROPERTIES
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, selectedRowIds }
  } = useTable(
    {
      // TABLE OPTIONS
      columns: reactTableColumns,
      data,
      getRowId,
      disableSortRemove: true,
      manualSortBy: remote && !!remote.sort,
      manualFilters: remote && !!remote.filter,
      manualPagination: remote && !!remote.pagination,
      initialState: {
        ...(pagination && { pageSize: pagination.sizePerPage }),
        ...(pagination && { pageIndex: pagination.page - 1 }),
        sortBy: [
          ...(!!defaultSorted
            ? defaultSorted.map(sortItem => ({
                id: sortItem.dataField,
                desc: sortItem.order == 'desc'
              }))
            : [])
        ],
        ...(selectRow &&
          selectRow.selected && {
            selectedRowIds: selectRow.selected.reduce(
              (a, c) => ({ ...a, [c]: true }),
              {}
            )
          })
      },
      ...(pagination && {
        pageCount: Math.ceil(pagination.totalSize / pagination.sizePerPage)
      }),
      autoResetSelectedRows: false
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      selectRow &&
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            width: 30,
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
              return (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                  />
                </div>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const isCheckboxDisabled =
                selectRow &&
                selectRow.onNonSelectable &&
                selectRow.onNonSelectable(row.original);

              return (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    disabled={isCheckboxDisabled}
                  />
                </div>
              );
            }
          },

          ...columns
        ]);
    },
    useFlexLayout
  );

  const sortedColumns = headers.find(item => {
    return !!item.isSorted;
  });

  useEffect(() => {
    sortedColumns &&
      sortByFunc &&
      sortByFunc({
        id: sortedColumns.id,
        sortOrder: sortedColumns.isSortedDesc ? 'desc' : 'asc'
      });
  }, [sortedColumns?.id, sortedColumns?.isSortedDesc]);

  useEffect(() => {
    !!selectedFlatRows && selectRow && selectRow.onSelect(selectedFlatRows);
  }, [selectedFlatRows.length]);

  // PAGINATION NUMBERS
  const paginationNumberStart =
    pageIndex === 0 || pageIndex === 1
      ? 0
      : pageIndex + 2 >= pageCount
      ? pageCount - 5
      : pageIndex - 2;
  const paginationNumberEnd =
    pageIndex === 0 || pageIndex === 1
      ? 5
      : pageIndex + 2 >= pageCount
      ? pageCount
      : pageIndex + 3;

  const OverlayComponent = props => {
    if (overlay) {
      const Overlay = overlay(loading);
      return <Overlay>{props.children}</Overlay>;
    } else {
      return <div>{props.children}</div>;
    }
  };

  return (
    <>
      <div className="overflow-auto">
        <OverlayComponent>
          <Table {...getTableProps()} {...tableProps}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, idx) => {
                    // COLUMN PROPERTIES
                    return (
                      <th
                        className={!!column && column.headerClasses}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}>
                        {column.render('Header')}

                        {/* Add a sort direction indicator */}
                        {column.canSort && (
                          <Button
                            id={`sortToggler-${idx + 1}`}
                            color="muted"
                            size="md"
                            className="px-2 d-inline-block">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <>
                                  <i className="fas fa-long-arrow-alt-up fa-sm text-secondary"></i>
                                  <i className="fas fa-long-arrow-alt-down fa-sm"></i>
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-long-arrow-alt-up fa-sm"></i>
                                  <i className="fas fa-long-arrow-alt-down fa-sm text-secondary"></i>
                                </>
                              )
                            ) : (
                              <>
                                <i className="fas fa-long-arrow-alt-up fa-sm text-secondary text-secondary"></i>
                                <i className="fas fa-long-arrow-alt-down fa-sm text-secondary text-secondary"></i>
                              </>
                            )}
                          </Button>
                        )}

                        {/* Render the columns filter UI */}
                        {column.canFilter && (
                          <>
                            <Button
                              color="light"
                              size="sm"
                              id={`filterToggler-${idx + 1}`}
                              onClick={e => {
                                e.stopPropagation();
                                // setPopoverOpen(!isOpenPopover);
                              }}>
                              <i className="fas fa-filter"></i>
                            </Button>

                            {/* <Collapse isOpen={isOpenPopover}>
                              {column.render('Filter')}
                            </Collapse> */}

                            <UncontrolledCollapse
                              toggler={`filterToggler-${idx + 1}`}>
                              <div id={`filterInput-${idx + 1}`}>
                                {column.render('Filter')}
                              </div>
                            </UncontrolledCollapse>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0
                ? (noDataIndication && (
                    <tr className="text-center">
                      <td>
                        {typeof noDataIndication === 'function'
                          ? noDataIndication()
                          : noDataIndication}
                      </td>
                    </tr>
                  )) || <></>
                : page.map((row, i) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </OverlayComponent>
      </div>

      {/* =============================== PAGINATION ============================ */}
      <div className="pagination">
        {/* ============= NUMBER OF RECORDS PER PAGE (DROPDOWN) ===========*/}
        {pagination && (
          <div className="d-flex mr-auto align-items-center">
            <div className="mr-2">
              <UncontrolledDropdown>
                <DropdownToggle caret>{pageSize}</DropdownToggle>
                <DropdownMenu>
                  {pagination.sizePerPageList.map(size => (
                    <DropdownItem
                      key={size.value}
                      value={size.value}
                      onClick={e => {
                        setPageSize(Number(e.target.value));
                        pagination.onSizePerPageChange(
                          Number(e.target.value),
                          1
                        );
                        onTableChange &&
                          onTableChange(pagination, {
                            page: 1,
                            sizePerPage: Number(e.target.value)
                          });
                      }}>
                      {size.value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            {/* ======= RANGE OF RECORDS SHOWN =========*/}
            <div>
              <span>
                {pagination.paginationTotalRenderer && (
                  <small>
                    {' '}
                    {pagination.paginationTotalRenderer(
                      pagination.totalSize === 0 ? 0 : pageIndex * pageSize + 1,
                      (pageIndex + 1) * pageSize > pagination.totalSize
                        ? pagination.totalSize
                        : (pageIndex + 1) * pageSize,
                      pagination.totalSize
                    )}{' '}
                  </small>
                )}
              </span>
            </div>
          </div>
        )}

        {/* ======= PAGINATION ARROWS ======= */}
        {pagination && (
          <div>
            <Pagination size="md">
              {canPreviousPage && (
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      gotoPage(0);
                      pagination.onPageChange(1, pageSize);
                      onTableChange &&
                        onTableChange(pagination, {
                          page: 1,
                          sizePerPage: pageSize
                        });
                    }}>
                    {pagination.firstPageText}
                  </PaginationLink>
                </PaginationItem>
              )}
              {canPreviousPage && (
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      previousPage();
                      pagination.onPageChange(pageIndex, pageSize);
                      onTableChange &&
                        onTableChange(pagination, {
                          page: pageIndex,
                          sizePerPage: pageSize
                        });
                    }}>
                    {pagination.prePageText}
                  </PaginationLink>
                </PaginationItem>
              )}

              {pageOptions
                .slice(paginationNumberStart, paginationNumberEnd)
                .map(pageNumber => {
                  return pagination.page - 1 === pageNumber ? (
                    <PaginationItem key={`page-${pageNumber}`} active>
                      <PaginationLink>{pageNumber + 1}</PaginationLink>
                    </PaginationItem>
                  ) : (
                    <PaginationItem key={`page-${pageNumber}`}>
                      <PaginationLink
                        onClick={() => {
                          gotoPage(pageNumber);
                          pagination.onPageChange(pageNumber + 1, pageSize);
                          onTableChange &&
                            onTableChange(pagination, {
                              page: pageNumber + 1,
                              sizePerPage: pageSize
                            });
                        }}>
                        {pageNumber + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                })}

              {canNextPage && (
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      nextPage();
                      pagination.onPageChange(pageIndex + 2, pageSize);
                      onTableChange &&
                        onTableChange(pagination, {
                          page: pageIndex + 2,
                          sizePerPage: pageSize
                        });
                    }}>
                    {pagination.nextPageText}
                  </PaginationLink>
                </PaginationItem>
              )}
              {canNextPage && (
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      gotoPage(pageCount - 1);
                      pagination.onPageChange(pageCount, pageSize);
                      onTableChange &&
                        onTableChange(pagination, {
                          page: pageCount,
                          sizePerPage: pageSize
                        });
                    }}>
                    {pagination.lastPageText}
                  </PaginationLink>
                </PaginationItem>
              )}
            </Pagination>
          </div>
        )}
      </div>
      {/* ================================================================= */}
    </>
  );
}

export default ReactTable;
