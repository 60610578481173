import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  CustomInput,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Alert
} from 'reactstrap';
import Message from '../../../UI/Message';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { withSocketIO } from '../../../../api/sockets/withSocketIO';
import { stageDeletionEvents } from '../../../../api/sockets/constantEvents';
class ModalDeleteStageConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStageDeleting: false,
      stageDeletionTask: {
        STAGE_DATA_BACKUP: {
          order: 1,
          isCompleted: false,
          display_name: 'stage_archiving'
        },
        STAGE_MODEL_DELETION: {
          order: 2,
          isCompleted: false,
          display_name: 'stage_models'
        },
        STAGE_REFERRENCE_DOCUMENT_DELETION: {
          order: 3,
          isCompleted: false,
          display_name: 'stage_ref_docs'
        },
        STAGE_JOB_DELETION: {
          order: 4,
          isCompleted: false,
          display_name: 'stage_rule_checks'
        },
        STAGE_ISSUE_DELETION: {
          order: 5,
          isCompleted: false,
          display_name: 'stage_model_issues'
        },
        STAGE_SUBMISSION_DELETION: {
          order: 6,
          isCompleted: false,
          display_name: 'stage_consultations'
        },
        STAGE_PIN_AND_REFERENCE_ANNOTATION_DELETION: {
          order: 7,
          isCompleted: false,
          display_name: 'stage_pin_annotations'
        }
      },
      actionsCompleted: 0
    };
  }

  toogleStageDeleting = () => {
    this.setState({
      isStageDeleting: !this.state.isStageDeleting
    });
  };

  runDeleteStage = async (projectId, stageName) => {
    const {
      project,
      toggleModalStageDeletion,
      toggleModalDeleteStageConfirmation,
      history,
      isFromProjectDetailPage,
      t,
      stageChangeValue,
      isOpen,
      deleteStage
    } = this.props;

    try {
      this.toogleStageDeleting();
      await deleteStage(projectId, stageName).then(() => {
        const defaultStage = project.stagesavailable.find(
          stage => stage.is_default
        );

        if (!isFromProjectDetailPage) {
          history.replace(`/project/${projectId}/models/${defaultStage.name}`);
        }
        stageChangeValue(defaultStage);
        Message.success(t('project:delete_stage_successfull'));

        if (isOpen) {
          toggleModalDeleteStageConfirmation();
        }
        this.toogleStageDeleting();
      });
    } catch (error) {
      // toggleModalStageDeletion();
      // toggleModalDeleteStageConfirmation();f
      Message.error(t(`error:${error.message}`));
      if (isOpen) {
        toggleModalDeleteStageConfirmation();
        this.toogleStageDeleting();
      }
    }
  };

  subscribeToStageDeletion = () => {
    const eventsToSubscribeTo = Object.keys(stageDeletionEvents).map(
      eventName => {
        return {
          eventName,
          handler: data => {
            this.setState(
              {
                stageDeletionTask: {
                  ...this.state.stageDeletionTask,
                  [eventName]: {
                    isCompleted: true
                  }
                }
              },
              () => {
                this.setState({
                  actionsCompleted: this.state.actionsCompleted + 1
                });
              }
            );
          }
        };
      }
    );
    try {
      this.props.subscribeToEvents(eventsToSubscribeTo);
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    this.subscribeToStageDeletion();
  }

  render() {
    const {
      isOpen,
      toggleModalStageDeletion,
      toggleModalDeleteStageConfirmation,
      t,
      stageToDelete,
      projectId,
      project
    } = this.props;

    const { stageDeletionTask, actionsCompleted, isStageDeleting } = this.state;

    const taskListCount = Object.keys(stageDeletionTask).length;
    const currentTask = Object.keys(stageDeletionTask).find(task => {
      return stageDeletionTask[task].order === actionsCompleted + 1;
    });

    const stageDisplayName = project.stagesavailable.find(
      stage => stage.name === stageToDelete
    );

    const handleModalCancel = () => {
      if (!isStageDeleting) {
        toggleModalDeleteStageConfirmation();
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        toggle={handleModalCancel}
        backdrop={'static'}
        size="md">
        <ModalHeader className="text-primary" toggle={handleModalCancel}>
          {t('project:delete_stage')}
        </ModalHeader>

        <ModalBody>
          <Alert color="warning">
            {t('project:delete_stage_body', {
              stageName:
                (stageDisplayName && stageDisplayName.display_name) ||
                stageToDelete
            })}
          </Alert>

          <ListGroup className="mt-2">
            {Object.keys(stageDeletionTask).map((key, index) => {
              const name = stageDeletionTask[key].display_name;
              const isDeleted = stageDeletionTask[key].isCompleted;

              const isCurrentTask =
                isStageDeleting &&
                currentTask &&
                stageDeletionTask[currentTask].display_name === name;

              return (
                (key !== 'STAGE_DATA_BACKUP' || isStageDeleting) && (
                  <ListGroupItem key={index}>
                    <Row className="d-flex justify-content-between px-2 ">
                      <div className="d-flex flex-column justify-content-between">
                        <div>{t(`project:${name}`)}</div>
                      </div>
                      <div className="d-flex justify-content-end flex-grow-1 mr-2">
                        {isCurrentTask && (
                          <Spinner type="grow" color="primary" />
                        )}
                        {isDeleted && (
                          <i className="text-success fas fa-check"></i>
                        )}
                      </div>
                    </Row>
                  </ListGroupItem>
                )
              );
            })}
          </ListGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              if (!isStageDeleting) {
                await this.runDeleteStage(projectId, stageToDelete);
              }
            }}
            disabled={isStageDeleting}>
            {t('confirm')}
          </Button>
          <Button
            color="secondary"
            onClick={handleModalCancel}
            disabled={isStageDeleting}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default compose(
  withTranslation(['project']),
  withSocketIO
)(ModalDeleteStageConfirmation);
