import React from 'react';
import { Alert, Row } from 'reactstrap';
import styles from './styles.module.css'

const InformationAlert = ({ children, className }) => (
  <Alert color="info" className={className}>
    <Row className="p-2">
      <i className={`fas fa-exclamation-triangle m-3 ${styles.iconSize}`} />
      {children}
    </Row>
  </Alert>
);

export default InformationAlert;
