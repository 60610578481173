import React, { Component } from 'react';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  CustomInput,
  Button,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Container,
  Row,
  Col
} from 'reactstrap';
import InformationAlert from '../../UI/InformationAlert';
import { Trans } from 'react-i18next';
import { dateFormatting } from '../../../locales/dateFormat';
import { Link } from 'react-router-dom';
import Message from '../../UI/Message';
import ConfirmationModal from './ConfirmationModal';
import './style.css';

const sortIcon = (sortBy, { sortNameActive, isDateAsc, isNameAsc }) => {
  return (
    <div className="sort-icon-parent-div">
      {sortBy === 'name' ? (
        <>
          <div
            className={`sort-icon-div text-${
              !sortNameActive ? 'white' : isNameAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-up'} />
          </div>
          <div
            className={`text-${
              !sortNameActive ? 'white' : !isNameAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-down'} />
          </div>
        </>
      ) : (
        <>
          <div
            className={`sort-icon-div text-${
              sortNameActive ? 'white' : isDateAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-up'} />
          </div>
          <div
            className={`text-${
              sortNameActive ? 'white' : !isDateAsc ? 'warning' : 'white'
            }`}>
            <i className={'fas fs-1-2x fa-arrow-down'} />
          </div>
        </>
      )}
    </div>
  );
};
export default class ModelIssueTableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionMode: false,
      idSelected: [],
      isDeleteModalOpen: false,
      sort: {
        isDateAsc: true,
        isNameAsc: true,
        sortNameActive: true,
        sortBy: 'name'
      },
      issueList: [],
      sortedIssueList: []
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.stageName !== this.props.match.params.stageName
    ) {
      const projectId = this.props.projectId;
      const stageName = this.props.match.params.stageName;
      const { fetchAllModelIssues, resetModelIssueList } =
        this.props.modelIssueActions;
      resetModelIssueList();
      fetchAllModelIssues(projectId, stageName).then(() => {
        const modelIssues = this.props.modelIssues;
        const issueList = Object.keys(modelIssues).map(res => modelIssues[res]);
        this.setState({
          issueList,
          sortedIssueList: this.sortIssueList(issueList)
        });
      });
    }
  }
  componentDidMount() {
    const projectId = this.props.projectId;
    const stageName = this.props.match.params.stageName;
    const { fetchAllModelIssues } = this.props.modelIssueActions;
    fetchAllModelIssues(projectId, stageName);
    const modelIssues = this.props.modelIssues;
    const issueList = Object.keys(modelIssues).map(res => modelIssues[res]);
    this.setState({
      issueList,
      sortedIssueList: this.sortIssueList(issueList)
    });
  }
  componentWillUnmount() {
    this.props.modelIssueActions.resetModelIssueList();
  }
  toggleDeleteModal = () => {
    if (this.state.idSelected.length === 0) {
      Message.error(this.props.t('select_atleast_one_issue_to_delete'));
    } else {
      const modelIssues = this.props.modelIssues;
      const issueList = Object.keys(modelIssues).map(res => modelIssues[res]);
      this.setState({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        issueList,
        sortedIssueList: this.sortIssueList(issueList)
      });
    }
  };

  handleSortIssueByName = ({ initialLoad = false }) => {
    this.setState(
      {
        sort: {
          sortBy: 'name',
          isNameAsc: initialLoad
            ? this.state.sort.isNameAsc
            : !this.state.sort.isNameAsc,
          sortNameActive: true
        }
      },
      () => {
        this.setState({
          sortedIssueList: this.sortIssueList(this.state.issueList)
        });
      }
    );
  };
  handleSortIssueByDate = ({ initialLoad = false }) => {
    this.setState(
      {
        sort: {
          sortBy: 'date',
          isDateAsc: initialLoad
            ? this.state.sort.isDateAsc
            : !this.state.sort.isDateAsc,
          sortNameActive: false
        }
      },
      () => {
        this.setState({
          sortedIssueList: this.sortIssueList(this.state.issueList)
        });
      }
    );
  };

  handleSelectAllIssue = () => {
    const { idSelected, sortedIssueList } = this.state;
    if (idSelected.length === sortedIssueList.length) {
      this.setState({ idSelected: [] });
    } else {
      this.setState({ idSelected: sortedIssueList.map(res => res.issue_id) });
    }
  };

  sortIssueList = issueList => {
    return issueList.sort((a, b) => {
      const { isNameAsc, isDateAsc, sortBy } = this.state.sort;
      const nameA = a.issue_topic.toLowerCase();
      const nameB = b.issue_topic.toLowerCase();
      if (sortBy === 'name') {
        return isNameAsc ? (nameA > nameB ? 1 : -1) : nameA > nameB ? -1 : 1;
      } else {
        return isDateAsc
          ? new Date(a.date_updated) - new Date(b.date_updated)
          : new Date(b.date_updated) - new Date(a.date_updated);
      }
    });
  };

  render() {
    const { t, projectId, stageName } = this.props;
    const { selectionMode, idSelected, sort, sortedIssueList } = this.state;

    const hasIssues = sortedIssueList.length > 0;
    return (
      <>
        {hasIssues ? (
          <div className="shadow p-3 mt-3  mb-3 rounded">
            <Container>
              <Row className="justify-content-between pb-3">
                <div className="d-flex flex-row align-items-center">
                  <h6 className="text-primary ">{t('list_of_issues')}</h6>
                  <ButtonGroup className="ml-2">
                    <Button
                      color="secondary"
                      id="sortByProjectNameButton"
                      onClick={this.handleSortIssueByName}>
                      <Row>
                        <Col className="pr-1">
                          <i className="fas fs-1-2x fa-building" />
                        </Col>
                        <Col className="pl-0">{sortIcon('name', sort)}</Col>
                      </Row>
                      <UncontrolledTooltip
                        fade={false}
                        target="sortByProjectNameButton"
                        delay={0}
                        modifiers={{
                          computeStyle: {
                            gpuAcceleration: false
                          }
                        }}>
                        {t('project:issue_title_sort')}
                      </UncontrolledTooltip>
                    </Button>
                    <Button
                      color="secondary"
                      id="sortByDateUpdatedButton"
                      onClick={this.handleSortIssueByDate}>
                      <Row>
                        <Col className="pr-1">
                          <i className="far fs-1-2x fa-calendar" />
                        </Col>
                        <Col className="pl-0">{sortIcon('date', sort)}</Col>
                      </Row>
                      <UncontrolledTooltip
                        fade={false}
                        target="sortByDateUpdatedButton"
                        delay={0}
                        modifiers={{
                          computeStyle: {
                            gpuAcceleration: false
                          }
                        }}>
                        {t('project:sort_by_date_updated')}
                      </UncontrolledTooltip>
                    </Button>
                  </ButtonGroup>
                </div>

                <div>
                  {!selectionMode && (
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({ selectionMode: true });
                      }}>
                      {t('select_issue')}
                    </Button>
                  )}
                  {selectionMode && (
                    <>
                      <Button
                        outline
                        color="primary"
                        onClick={this.handleSelectAllIssue}>
                        {t('select_all')}
                      </Button>

                      <Button
                        id="export-to-bcf"
                        color="success"
                        className="ml-1"
                        outline
                        tag="a"
                        target="_blank"
                        download
                        onClick={e => {
                          if (idSelected.length === 0) {
                            e.preventDefault();
                            Message.error(t('select_atleast_one_issue'));
                          }
                        }}
                        href={`${
                          process.env.REACT_APP_API_ENDPOINT[
                            process.env.REACT_APP_API_ENDPOINT.length - 1
                          ] == '/'
                            ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                            : process.env.REACT_APP_API_ENDPOINT
                        }/api/issue/${projectId}/${stageName}/exportIssue?issueIdList=${idSelected.join(
                          ','
                        )}`}>
                        {t('export_to_bcf')}
                      </Button>

                      <Button
                        outline
                        className="ml-1"
                        color="danger"
                        onClick={this.toggleDeleteModal}>
                        {t('delete_issue')}
                      </Button>

                      <Button
                        id="cancel-selection"
                        className="ml-1"
                        color="danger"
                        onClick={() => {
                          this.setState({
                            selectionMode: false,
                            idSelected: []
                          });
                        }}>
                        {t('cancel_selection')}
                      </Button>
                    </>
                  )}
                </div>
              </Row>
            </Container>

            <ListGroup>
              {sortedIssueList.map((issue, i) => {
                const issueId = issue.issue_id;
                return (
                  <ListGroupItem key={i}>
                    <Row>
                      {selectionMode && (
                        <div>
                          <CustomInput
                            type="checkbox"
                            id={`select-issue-checkbox-${issueId}`}
                            checked={idSelected.includes(issueId)}
                            onClick={e => {
                              const isSelected = e.target.checked;
                              if (isSelected) {
                                this.setState(prevState => ({
                                  idSelected: [...prevState.idSelected, issueId]
                                }));
                              } else {
                                this.setState({
                                  idSelected: this.state.idSelected.filter(
                                    i => i != issueId
                                  )
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                      <Col className="wordwrap-breakword">
                        <Button
                          className="text-left wordwrap-breakword"
                          color="link"
                          tag={Link}
                          to={`/project/${projectId}/issue/${stageName}/${issueId}`}>
                          {issue.issue_topic}
                        </Button>
                        <div>
                          <small className="px-3 text-muted">
                            {t('date_created')}:{' '}
                            {dateFormatting(issue.date_created, 'defaultHour')}
                          </small>
                        </div>
                      </Col>
                      <Col xs="2" sm="2" md="2" lg="1">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            size="sm"
                            color="muted"
                            className="w-100">
                            <i className="fas fa-ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <Link
                                to={`/project/${projectId}/issue/${stageName}/${issueId}`}>
                                {t('detail')}
                              </Link>
                            </DropdownItem>
                            <DropdownItem>
                              <a
                                id="export-to-bcf"
                                color="success"
                                outline
                                target="_blank"
                                download
                                href={`${
                                  process.env.REACT_APP_API_ENDPOINT[
                                    process.env.REACT_APP_API_ENDPOINT.length -
                                      1
                                  ] == '/'
                                    ? process.env.REACT_APP_API_ENDPOINT.slice(
                                        0,
                                        -1
                                      )
                                    : process.env.REACT_APP_API_ENDPOINT
                                }/api/issue/${projectId}/${stageName}/exportIssue?issueIdList=${issueId}`}>
                                {t('export_to_bcf')}
                              </a>
                            </DropdownItem>
                            <DropdownItem>
                              <Button
                                className="p-0"
                                color="link"
                                onClick={() => {
                                  this.setState(
                                    { idSelected: [issueId] },
                                    () => {
                                      this.toggleDeleteModal();
                                    }
                                  );
                                }}>
                                {t('delete_issue')}
                              </Button>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </ListGroup>

            <ConfirmationModal
              {...this.props}
              isDeleteModalOpen={this.state.isDeleteModalOpen}
              toggleDeleteModal={this.toggleDeleteModal}
              issueIds={idSelected}
            />
          </div>
        ) : (
          <InformationAlert className="my-4">
            <Trans i18nKey="project:no_issue_alert_info">
              <h4 className="alert-heading">
                There is no issue with this model.
              </h4>
            </Trans>
          </InformationAlert>
        )}
      </>
    );
  }
}
