import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge
} from 'reactstrap';
import knowledgehubContext from '../store/config/context';
import {
  setIsCategoryModalOpen,
  addNewCategoryItem,
  removeNewCategoryItem,
  saveCategory,
  saveCategoryItems,
  resetNewCategoryItems,
  setFilestoUploadForNewCategoryItem,
  removeFileFromNewCatItems,
  uploadImageServerNewCatItems,
  filesUploadNewCatItems,
  setSelectedCategory
} from '../store/actions';
import CustomInput from './shared/Input/customInput';
import CustomInputCheckbox from './shared/Input/customInputCheckbox';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Message from './shared/Message';
import Dropzone from 'react-dropzone';
import './knowledgehub.css';

// const dropzoneStyle = {
//   position: 'relative',
//   borderWidth: '2px',
//   borderColor: 'rgb(102, 102, 102)',
//   borderStyle: 'dashed',
//   borderRadius: '5px'
// };

// const disableDropzoneStyle = {
//   ...dropzoneStyle,
//   pointerEvents: 'none',
//   opacity: '0.5',
//   background: '#CCC'
// };
import styles from './styles.module.css';

const formikConfig = props => {
  const {
    t,
    dispatch,
    newCategoryItems,
    selectedCategory,
    isAddingItemsInExistingCategory
  } = props;

  const additionalFields = newCategoryItems.reduce((prevData, currentItem) => {
    return { ...prevData, ...currentItem };
  }, {});

  return {
    initialValues: {
      category_name: isAddingItemsInExistingCategory ? selectedCategory : ''
    },
    validateOnClick: true,
    validationSchema: Yup.object().shape({
      category_name: Yup.string()
        .trim()
        .required('Required')
        .max(60, 'Category name must be at most 60 characters'),
      ...Object.keys(additionalFields).reduce((prevData, currentKey) => {
        if (['id', 'files'].includes(currentKey)) return prevData;
        const isPropTitle = currentKey.search('category_item_title_') >= 0;

        return {
          ...prevData,
          [currentKey]: isPropTitle
            ? Yup.string()
                .trim()
                .required('Required')
                .max(60, 'Title must be at most 60 characters')
                .test(
                  'isTitleUnique',
                  t('title_must_be_unique'),
                  function (value) {
                    const titleValues = Object.keys(this.parent)
                      .filter(
                        valKey =>
                          valKey &&
                          valKey !== currentKey &&
                          valKey.search('category_item_title_') >= 0
                      )
                      .reduce((acc, curr) => [...acc, this.parent[curr]], []);
                    return !titleValues.includes(value);
                  }
                )
            : Yup.string().trim().required('Required')
        };
      }, {})
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const categoryItemData = newCategoryItems.map(item => {
        return Object.keys(item).reduce(prev => {
          const titleKey = `category_item_title_${item.id}`;
          const descKey = `category_item_description_${item.id}`;
          const isDefaultKey = `category_item_is_default_${item.id}`;
          return {
            ...prev,
            title: values[titleKey],
            description: values[descKey],
            isDefault: values[isDefaultKey] || false,
            id: item.id
          };
        }, {});
      });
      const { data } = await dispatch(saveCategory(values.category_name));
      dispatch(saveCategoryItems(data.category_id, categoryItemData, data.name))
        .then(async result => {
          if (result.success) {
            result.data.forEach(dt => {
              const foundData = newCategoryItems.find(
                itm => itm.id === dt.referenceId
              );
              const fileList = foundData.files
                ? foundData.files.map(file => {
                    return {
                      name: file.name,
                      size: `${file.size / 1000} KB`,
                      type: file.type,
                      fileInput: file
                    };
                  })
                : [];
              dispatch(
                filesUploadNewCatItems(
                  fileList,
                  data.name,
                  dt.category_item_id,
                  data.category_id
                )
              );
              dispatch(
                uploadImageServerNewCatItems(
                  data.category_id,
                  dt.category_item_id,
                  fileList
                )
              );
            });

            dispatch(setSelectedCategory(data.name, 'resultList'));
            dispatch(resetNewCategoryItems());
            resetForm();
            dispatch(setIsCategoryModalOpen(false));
            Message.success(t('saved_successfully'));
          } else if (!result.success && result.hasExistingTitle) {
            const uniqFoundCatItems = [
              ...new Set(result.foundCategoryItem.map(item => item.title))
            ];

            uniqFoundCatItems.forEach(title => {
              Message.error(t('title_already_exists', { title }));
            });
          } else Message.error(t('failed'));
          setSubmitting(false);
        })
        .catch(errorData => {
          Message.error(t(errorData.error));
          setSubmitting(false);
        });
    }
  };
};

const AddNewCategoryModal = props => {
  const { state, dispatch } = useContext(knowledgehubContext);
  const { newCategoryItems, newCategoryItemsCount } = state;
  const handleAddCategory = () =>
    dispatch(setIsCategoryModalOpen(!state.isAddCategoryModalOpen));
  return (
    <Modal backdrop="static" size="lg" isOpen={state.isAddCategoryModalOpen}>
      <Formik {...formikConfig({ ...props, ...state, dispatch })}>
        {formikProps => {
          const { isSubmitting, dirty, submitForm, values, setValues } =
            formikProps;
          const handleSubmitForm = () => {
            submitForm();
          };

          const handleAddNewCategoryItem = () => {
            const newItem = {
              [`category_item_title_${newCategoryItemsCount + 1}`]: '',
              [`category_item_description_${newCategoryItemsCount + 1}`]: ''
            };
            dispatch(addNewCategoryItem(newItem));
            setValues({
              ...values,
              ...newItem
            });
          };

          const handleRemoveNewCategoryItem = itemId => {
            const { category_name, ...others } = values;
            dispatch(removeNewCategoryItem(itemId));
            setValues({
              category_name,
              ...Object.keys(others)
                .filter(
                  fieldKey =>
                    ![
                      `category_item_title_${itemId}`,
                      `category_item_description_${itemId}`
                    ].includes(fieldKey)
                )
                .reduce((prev, fKey) => ({ ...prev, [fKey]: others[fKey] }), {})
            });
          };
          return (
            <Form>
              <ModalHeader toggle={handleAddCategory}>
                {props.t('new')}
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Field
                    disabled={state.isAddingItemsInExistingCategory}
                    name="category_name"
                    label={props.t('category_name')}
                    component={CustomInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    disabled={isSubmitting}
                    color="success"
                    onClick={handleAddNewCategoryItem}>
                    <i className="fas fa-plus"></i>{' '}
                    {props.t('add_category_item')}
                  </Button>
                </FormGroup>
                <FormGroup>
                  <ListGroup>
                    {!!newCategoryItems.length ? (
                      <>
                        {newCategoryItems.map((item, index) => {
                          return (
                            <NewCategoryItem
                              {...props}
                              {...state}
                              {...formikProps}
                              item={item}
                              values={values}
                              dispatch={dispatch}
                              handleRemoveNewCategoryItem={
                                handleRemoveNewCategoryItem
                              }
                              key={`key-category-item-${index}`}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <ListGroupItem className="font-italic text-center text-muted">
                        {props.t('atleast_one_item')}
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={
                    isSubmitting || !dirty || !!!newCategoryItems.length
                  }
                  color="primary"
                  onClick={handleSubmitForm}>
                  {props.t('save')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const NewCategoryItem = ({
  t,
  item,
  dispatch,
  isSubmitting,
  handleRemoveNewCategoryItem
}) => {
  return (
    <ListGroupItem>
      <Row noGutters>
        <Col>
          <FormGroup>
            <Field
              type="checkbox"
              bsSize="sm"
              name={`category_item_is_default_${item.id}`}
              label={t('category_item_is_default')}
              component={CustomInputCheckbox}
            />
          </FormGroup>
        </Col>
        <Col className="text-right">
          <Button
            disabled={isSubmitting}
            onClick={() => handleRemoveNewCategoryItem(item.id)}
            color="link"
            size="sm">
            <i className="far fa-trash-alt text-danger"></i>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Field
              bsSize="sm"
              name={`category_item_title_${item.id}`}
              label={t('category_item_title')}
              component={CustomInput}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Field
              bsSize="sm"
              type="textarea"
              name={`category_item_description_${item.id}`}
              label={t('category_item_description')}
              component={CustomInput}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <div className="d-flex">
            <Dropzone
              disabled={isSubmitting}
              className={`d-flex align-items-center ${
                isSubmitting ? 'disableDropzoneStyle' : 'dropzoneStyle'
              }`}
              onDrop={files => {
                dispatch(setFilestoUploadForNewCategoryItem(files, item.id));
              }}
              accept={['.jpg', '.jpeg', '.png']}
              multiple={true}>
              <div className="p-2">
                <div>{t('add_image')}</div>
                <div>
                  <small>{t('jpeg_or_png')}</small>
                </div>
              </div>
            </Dropzone>
            <div className="px-2">
              {item.files &&
                item.files.map((file, fileIndex) => {
                  return (
                    <span className="px-1" key={`key-${fileIndex}`}>
                      <Badge color="white" className="border" pill>
                        {file.name}{' '}
                        <Button
                          color="link"
                          className="p-0 m-0"
                          disabled={isSubmitting}
                          onClick={() => {
                            dispatch(
                              removeFileFromNewCatItems(item.id, fileIndex)
                            );
                          }}>
                          <i className="fas fa-times text-muted" />
                        </Button>
                      </Badge>
                    </span>
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default withTranslation(['knowledgeHub'])(AddNewCategoryModal);
