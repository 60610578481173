import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import Body from './Body';
import { useSelector } from 'react-redux';
import navigatorLinks from './config/navLinks';
import landingPageConfig from './config/landingPageConfig';
import './styles.css';

const sections = Object.keys(landingPageConfig).reduce((acc, currentKey) => {
  return { ...acc, [currentKey]: { id: currentKey, element: {} } };
}, {});

const LandingPage = props => {
  const [activeNavLink, setActiveNavLink] = useState(null);
  const rowRef = useRef(null);
  const landingPageDivRef = useRef(null);
  const navLinks = navigatorLinks.default;
  const { app, authUser } = useSelector(state => state);

  useEffect(() => {
    if (props.history.location.hash === '#contact')
      setActiveNavLink(props.history.location.hash.slice(1));
  }, [props.history.location.hash]);

  /**
   * @description To scroll to the active link section || fornax/home is the default
   */
  const scrollToActiveRow = () => {
    if (rowRef.current) {
      // const top =
      //   rowRef.current.getBoundingClientRect().top + window.pageYOffset;
      //   rowRef.current.scrollTo({ top, behavior: 'smooth' });
      rowRef.current.scrollIntoView({
        block: 'start',
        inline: 'center',
        behavior: 'smooth'
      });
    }
  };

  const [isOnTop, setIsonTop] = useState(true);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    scrollToActiveRow();
  }, [activeNavLink]);

  const [landingPageSections, setLandingPageSetions] = useState(sections);
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const [screenWidth, setScreenWidth] = useState('100%');

  useEffect(() => {
    const handleScroll = () => {
      if (landingPageDivRef.current) {
        const {
          width,
          top,
          height
        } = landingPageDivRef.current.getBoundingClientRect();
        const scrollY = Math.round(Math.abs(top));
        const remaining = height - document.documentElement.clientHeight;
        const footerHeight = document.getElementsByClassName('footer')[0]
          .scrollHeight;

        const progressBarWidth =
          scrollY <= footerHeight
            ? document.documentElement.scrollTop
            : ((scrollY - footerHeight) / remaining) * 100;

        setScrollY(scrollY);
        setIsonTop(scrollY < 50);

        //set the progress bar on the of the nav bar
        setProgressBarWidth(progressBarWidth);

        setScreenWidth(width || '100%');
      }
    };
    document.addEventListener('scroll', handleScroll, true);

    //remove event lesterner when the component unmount
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div ref={landingPageDivRef} className="main-landing-page">
      <Header
        navLinks={navLinks}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        isOnTop={isOnTop}
        scrollToActiveRow={scrollToActiveRow}
        landingPageSections={landingPageSections}
        scrollY={scrollY}
        progressBarWidth={progressBarWidth}
        isLoggedIn={app.isAuthenticated}
        authUser={authUser}
        screenWidth={screenWidth}
      />

      <Body
        landingPageSections={landingPageSections}
        setLandingPageSetions={setLandingPageSetions}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        scrollToActiveRow={scrollToActiveRow}
        rowRef={rowRef}
        scrollY={scrollY}
        fetchAllNews={props.fetchAllNews}
        resetNews={props.resetNews}
        timeAgoFormatting={props.timeAgoFormatting}
      />
    </div>
  );
};

export default LandingPage;
