import React, { Component } from 'react';
import {
  Row,
  Col,
  CardHeader,
  Card,
  Button,
  CardTitle,
  CardText,
  UncontrolledTooltip,
  Form,
  Container
} from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import Message from '../../UI/Message';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styles from './userPreference.module.css'

const formikConfig = props => {
  const { userPreference } = props;
  return {
    initialValues: {
      favoriteRules: userPreference.favorite_ruleset
    },
    onSubmit: (values, { setSubmitting }) => {
      // setSubmitting(true);
      console.log(values);
    }
  };
};

class favoriteRulesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handleListUpdate: false,
      rules: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  onUpdateFavoriteRule = () => {
    const { t } = this.props;
    const newPreferenceData = this.props.userPreference.favorite_ruleset;
    this.props
      .updateUserPreference({
        settingsToSet: 'favorite_ruleset',
        newPreferenceData,
        operation: 'remove'
      })
      .then(() => {
        this.setState({
          handleListUpdate: !this.state.handleListUpdate
        });
        Message.success(t('user_preferences:reference_edit_success'));
      })
      .catch(err => {
        Message.error(this.props.t(`error:${err.message}`));
      });
  };

  onResetRuleToDefaults = () => {
    const { t } = this.props;
    this.props
      .resetUserPreference({
        settingsToReset: 'favorite_ruleset'
      })
      .then(() => {
        Message.success(t('user_preferences:reference_edit_success'));
      })
      .catch(err => {
        Message.error(this.props.t(`error:${err.message}`));
      });
  };

  render() {
    const {
      t,
      userPreference,
      updateUserPreference,
      removeFavoriteRulePreference
    } = this.props;
    const { handleListUpdate } = this.state;

    // const filteredRules = userPreference.favorite_ruleset.filter(rule => {
    //   return !rule.removed && rule.dirty;
    // });
    return (
      <Formik {...formikConfig(this.props)}>
        {formikProps => {
          const { isSubmitting } = formikProps;
          return (
            <Form>
              <Container>
                <div className="p-2">
                  <Row className="justify-content-between px-3 ">
                    <h5 className="text-primary">
                      {/* {t('user_preferences:favorite_rules')} */}
                    </h5>
                    <div>
                      <Button
                        type="button"
                        color="success"
                        className="mx-1"
                        disabled={!handleListUpdate}
                        id="formSaveButton"
                        onClick={this.onUpdateFavoriteRule}>
                        <i className="fas fa-save fs-1-2x" />
                      </Button>
                      <Button
                        color="info"
                        type="button"
                        className="mx-1"
                        id="formResetButton"
                        onClick={this.onResetRuleToDefaults}>
                        <i className="fas fa-undo fs-1-2x" />
                      </Button>

                      <UncontrolledTooltip
                        fade={false}
                        target="formSaveButton"
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('user_preferences:save')}
                      </UncontrolledTooltip>

                      <UncontrolledTooltip
                        fade={false}
                        target="formResetButton"
                        modifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}>
                        {t('user_preferences:reset')}
                      </UncontrolledTooltip>
                    </div>
                  </Row>
                </div>

                <Row>
                  {userPreference &&
                    userPreference.favorite_ruleset &&
                    Object.keys(userPreference.favorite_ruleset).map(key => {
                      const rules = userPreference.favorite_ruleset[key];
                      return (
                        <Col xs="6" className="py-2 px-2">
                          <CardHeader>{key}</CardHeader>
                          <Card
                            body
                            className={styles.favoriteRuleCardBody}>
                            {(() => {
                              if (rules.length > 0) {
                                return rules.map((val, index) => {
                                  return (
                                    <ul className="list-group list-group-flush">
                                      <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {val}

                                        <a
                                          id={`delete-${index}`}
                                          className="pointer mr-2"
                                          onClick={() => {
                                            this.setState({
                                              handleListUpdate: true
                                            });
                                            removeFavoriteRulePreference({
                                              [key]: [val]
                                            });
                                          }}>
                                          <i className="fas fa-trash-alt fs-1-2x text-danger" />
                                        </a>
                                        {this._isMounted && (
                                          <UncontrolledTooltip
                                            fade={false}
                                            boundariesElement="window"
                                            target={`delete-${index}`}>
                                            {t(
                                              'user_preferences:remove_from_favorites'
                                            )}
                                          </UncontrolledTooltip>
                                        )}
                                      </li>
                                    </ul>
                                  );
                                });
                              } else {
                                return (
                                  <h3 className="text-center">
                                    {t('user_preferences:empty_list')}
                                  </h3>
                                );
                              }
                            })()}
                          </Card>
                        </Col>
                      );
                    })}

                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withTranslation(['user_preferences'])(favoriteRulesList);
