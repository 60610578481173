import React from 'react';
import pageNotFound from '../../assets/images/undraw_not_found_60pq.png';
import { withTranslation } from 'react-i18next';
import './PageNotFound.css';

const PageNotFound = props => {
  const { t } = props;
  return (
    <div className="text-center m-auto">
      <img
        src={pageNotFound}
        className="text-center pageNotFoundImage"
        alt="404"
        width="300"
        height="300"></img>

      <p className="pageNotFoundText">{t(`error:page_not_found`)}</p>
    </div>
  );
};
export default withTranslation(['main', 'error'])(PageNotFound);
