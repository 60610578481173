import React, { Component, useState, useEffect } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledCollapse,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Tree from '../../../UI/modelComparisonTree';

import { reRunModelComparison } from '../../../../store/actions';
import Message from '../../../UI/Message';

const ComparisonDropdown = ({
  projectId,
  stageName,
  comparedStageName,
  dateOfComparison,
  rowIndex,
  t,
  reRunModelComparison
}) => {
  const [isRequestRunning, setRequestRunning] = useState(false);
  const handleClick = async () => {
    try {
      setRequestRunning(true);
      await reRunModelComparison(
        projectId,
        stageName,
        comparedStageName,
        dateOfComparison
      );

      Message.success(t('operation_successful'));
      setRequestRunning(false);
    } catch (err) {
      Message.error(t(err.message));
      setRequestRunning(false);
    }
  };
  return (
    <>
      {!isRequestRunning ? (
        <>
          <Button
            id={`rerun-${rowIndex}`}
            color="link"
            size="md"
            onClick={handleClick}>
            <i className="fas fa-redo"></i>
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`rerun-${rowIndex}`}
            // delay={0}
          >
            {t('rerun')}
          </UncontrolledTooltip>
        </>
      ) : (
        <div className="px-3 py-2">
          <span
            className="text-primary spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state, { match }) => {};

const mapDispatchToProps = dispatch => {
  return {
    reRunModelComparison: (
      projectId,
      stageName,
      comparedStageName,
      dateOfComparison
    ) =>
      dispatch(
        reRunModelComparison(
          projectId,
          stageName,
          comparedStageName,
          dateOfComparison
        )
      )
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['results'])
)(ComparisonDropdown);
