import { userActionType } from '../../constants/actionType';
import { performRequest } from '../requests';

const {
  MERGE_USERS,
  UPDATE_USER,
  ADD_USER,
  REPLACE_USER,
  RESET_USER,
  UPDATE_USER_AS_ADMIN,
  VERIFY_USER_AS_ADMIN,
  VERIFY_MULTIPLE_USER_AS_ADMIN
} = userActionType;

export const resetUserAsAdmin = () => ({
  type: RESET_USER
});

export const fetchUserListAsAdmin = (
  filters,
  order,
  pagination,
  toDispatch = true
) => (dispatch /* ,
  getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/admin/user/list',
      { filters, order, pagination },
      requestConfig,
      'FETCH_LIST_USER_AS_ADMIN',
      true
    )
  )
    .then(res => res.data)
    .then(data => {
      const totalResult = data.countTotal;
      const userMap = data.data.reduce((map, currentUser) => {
        return { ...map, [currentUser.user_id]: currentUser };
      }, {});
      if (toDispatch) {
        dispatch({
          type: REPLACE_USER,
          userMap
        });
        return totalResult;
      } else {
        return userMap;
      }
    });
};

export const fetchUserAsAdmin = userId => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/admin/user/${userId}/detail`,
      {},
      requestConfig,
      'FETCH_USER_AS_ADMIN'
    )
  )
    .then(res => res.data)
    .then(user => {
      const userData = JSON.parse(JSON.stringify(user));
      dispatch({
        type: MERGE_USERS,
        userMap: { [userId]: userData }
      });
    });
};

export const fetchPendingUserAsAdmin = (userId, filters, order, pagination) => (
  dispatch /* ,
  getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/admin/user/list/pending_users',
      { userId, filters, order, pagination },
      requestConfig,
      'FETCH_LIST_USER_AS_ADMIN'
    )
  )
    .then(res => res.data)
    .then(data => {
      const totalSize = data.countTotal;
      const userMap = data.data.reduce((map, currentUser) => {
        return { ...map, [currentUser.user_id]: currentUser };
      }, {});

      dispatch({
        type: REPLACE_USER,
        userMap
      });

      return {
        totalSize,
        admin_verification_needed: data.admin_verification_needed
      };
    });
};

export const editUserAsAdmin = (userId, data) => (
  dispatch /* , getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'put',
      `api/admin/user/${userId}`,
      { data },
      requestConfig,
      'UPDATE_USER_AS_ADMIN'
    )
  )
    .then(user => {
      dispatch({
        type: UPDATE_USER,
        userId,
        user
      });
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};

// edit multiple user at once (this action cannot be used to edit files)
export const editMultipleUserAsAdmin = (userList, dataUpdated) => (
  dispatch /* ,
  getState */
) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  return dispatch(
    performRequest(
      'put',
      'api/admin/user',
      { userList, dataUpdated },
      requestConfig,
      'BATCH_UPDATE_USER_AS_ADMIN'
    ).then(() => userList)
  ).then(userList => {
    userList.map(userId => {
      dispatch({
        type: UPDATE_USER,
        userId,
        dataUpdated
      });
    });
  });
};

export const createUserAsAdmin = userData => (dispatch /* , getState */) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      'api/admin/user/create',
      userData,
      requestConfig,
      'CREATE_USER_AS_ADMIN'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(res => {
      if (res.result === 'Success') {
        dispatch({
          type: ADD_USER,
          user: res.data,
          userId: res.data.user_id
        });
      }
      return res;
    });
};

export const editSelectedUserAsAdmin = (userId, newData) => (
  dispatch,
  getState
) => {
  const concurrencyControlVersionId = getState().users[userId].user
    .concurrency_control_version_id;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      `api/admin/user/${userId}`,
      { ...newData, concurrencyControlVersionId, entityTargetId: userId },
      requestConfig,
      'EDIT_SELECTED_USER_INFORMATION'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(newData => {
      dispatch({
        type: UPDATE_USER_AS_ADMIN,
        userId,
        newUserData: newData.data
      });
      return newData.result;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};

// return the user with the matching id, if this one is not in the store fetch it from the API
export const getOrFetchUser = userId => (dispatch, getState) => {
  const userFromStore = getState().users[userId];
  if (userFromStore) {
    return Promise.resolve(userFromStore);
  } else {
    return dispatch(fetchUserAsAdmin(userId)).then(
      () => getState().users[userId]
    );
  }
};

export const isEmailExist = email => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `/api/user/exist?email=${email}`,
      {},
      requestConfig,
      'IS_EMAIL_EXIST'
    )
  );
};

export const inviteUser = invitedUser => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  dispatch(
    performRequest(
      'post',
      'api/user/invitation',
      invitedUser,
      requestConfig,
      'INVITE_USER'
    )
  );
};

export const verifyUserRegistrationAsAdmin = (
  values,
  proceedWithConflicts = false
) => (dispatch, getState) => {
  const entityList = values.userIds.reduce((returnedObj, id, index) => {
    return {
      ...returnedObj,
      [id]: {
        concurrency_control_version_id:
          (getState().users[id] &&
            getState().users[id].concurrency_control_version_id) ||
          ''
      }
    };
  }, {});

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      'api/admin/user/verify/multiple',
      { entityList, excludeConflicts: proceedWithConflicts, ...values },
      requestConfig,
      'VERIFY_MULTIPLE_USER_AS_ADMIN'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(newData => {
      dispatch({
        type: VERIFY_MULTIPLE_USER_AS_ADMIN,
        newUserData: newData.data
      });
      return newData.data;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw {
          conflictEmails: err.response.data.conflictIds.map(id => {
            const { email } = getState().users[id];
            return {
              email
            };
          }),
          error: err.response && err.response.data && err.response.data.error,
          message: err.response && err.response.data && err.response.data.error
        };
      } else {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};

export const verifyOneUserRegistrationAsAdmin = (userId, action) => (
  dispatch,
  getState
) => {
  const concurrencyControlVersionId = getState().users[userId]
    .concurrency_control_version_id;

  const entityTargetId = userId;

  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'put',
      `api/admin/user/${userId}/verify`,
      {
        action,
        concurrencyControlVersionId,
        entityTargetId
      },
      requestConfig,
      'VERIFY_USER_AS_ADMIN'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(newData => {
      dispatch({
        type: VERIFY_USER_AS_ADMIN,
        userId,
        newUserData: newData.data
      });
      return newData.result;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};

export const leaveProject = (projectId, email) => dispatch => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'post',
      `/api/project/${projectId}/leave`,
      { email },
      requestConfig,
      'DELETE_PROJECT'
    )
  ).then(res => {
    return res.data;
  });
};

export const fetchUserGroups = userId => (dispatch, getState) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };
  return dispatch(
    performRequest(
      'get',
      `api/accessControlV2/view/user_group/my_groups`,
      {},
      requestConfig,
      'VERIFY_USER_AS_ADMIN'
    )
  )
    .then(res => {
      return res.data;
    })
    .then(result => {
      return result.user_groups;
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        throw (
          {
            message: 'not_authorised'
          } || 'default_message'
        );
      } else if (err.response && err.response.status === 409) {
        throw new Error(
          (err.response && err.response.data && err.response.data.error) ||
            'error.default_message'
        );
      }
    });
};
