import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  FormGroup,
  Row,
  Col,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';

import CheckStatsComponent from '../Admin/Statistics/checkStats';
import { fetchProjectStats } from '../../../store/actions';
import STATCONFIG from '../Admin/Statistics/settings';

const ProjectStats = props => {
  const { t, data, project, fetchStats } = props;
  const statType = 'project_criteria';
  const flatStatType = 'project_flat_criteria';

  const [isLoaded, setIsLoaded] = useState(false);
  const [statSettings, setStatSettings] = useState({
    criteria: STATCONFIG[statType].default,
    count: STATCONFIG.count.default,
    minDate: null,
    maxDate: null,
    graph:
      STATCONFIG[flatStatType][STATCONFIG[statType].default].recommendedGraph,
    time_evolution: STATCONFIG.time_evolution.default,
    projectId: project.project_id
  });

  useEffect(() => {
    fetchStats(statSettings).then(() => {
      setIsLoaded(true);
    });
  }, [statSettings]);

  return (
    <>
      {' '}
      {isLoaded && (
        <Row>
          <Col>
            <div className="shadow p-3 mt-3 rounded">
              <Row className="justify-content-between px-3">
                <h5 className="text-primary">{t('statistics')}</h5>
              </Row>
              <hr className="mt-1" />
              <CheckStatsComponent
                data={data}
                statSettings={statSettings}
                fetchStats={fetchStats}
                setStatSettings={setStatSettings}
                statType={statType}
                flatStatType={flatStatType}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return { data: state.statistics };
};

const mapDispatchToProps = dispatch => {
  return { fetchStats: filterData => dispatch(fetchProjectStats(filterData)) };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['statistics'])
)(ProjectStats);
