import React, { useContext, useRef, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import {
  setTextCopied,
  searchByTitle,
  setSelectedCategory,
  setIsAddingItemsInExistingCat,
  setIsCategoryModalOpen,
  updateCategory,
  fetchFavoriteCategoryItem
} from '../store/actions';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import * as Yup from 'yup';

import knowledgehubContext from '../store/config/context';
import Message from './shared/Message';
import Thumb from './shared/Thumbnail';

import EditableInput from './shared/Input/editableInput';
import CustomTooltip from './shared/CustomTooltip';
import ReactPaginate from './Pagination';

import './knowledgehub.css';

const ResultListItems = props => {
  const { state, dispatch, copyTextFromKnowledHub, isWebviewerMode, history } =
    useContext(knowledgehubContext);

  const { t } = props;
  const textAreRef = useRef(null);
  const { authUser } = useSelector(state => state);
  const copyTextToClipboard = text => {
    if (copyTextFromKnowledHub) copyTextFromKnowledHub(text);
    dispatch(setTextCopied(text));
  };
  const getRowContentAsText = data => {
    const text = `${data.groupname} ${data.title} - ${data.description}`;
    copyTextToClipboard(text);
    Message.success(t('text_copied'));
  };
  const onSearch = debounce(text => {
    if (!isWebviewerMode) history.replace(`?search=${text}`);
    dispatch(searchByTitle(text));
    setSearchText(text);
  }, 1000);
  const [modal, setModal] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [searchText, setSearchText] = useState('');
  const onToggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(fetchFavoriteCategoryItem());
  }, []);

  useEffect(() => {
    if (textAreRef.current) {
      textAreRef.current.select();
      document.execCommand('copy');
    }
  }, [state.textCopied]);

  useEffect(() => {
    if (!searchText) {
      if (!isWebviewerMode) history.replace(`?search=`);
      dispatch(searchByTitle(null));
    }
    dispatch(setSelectedCategory(null));
  }, [searchText]);

  const handleIsAddingItemsInExistingCat = isAdding => {
    dispatch(setIsAddingItemsInExistingCat(isAdding));
    dispatch(setIsCategoryModalOpen(!state.isAddCategoryModalOpen));
  };

  return (
    <Container fluid>
      <textarea
        className="textAreaRef"
        ref={ref => {
          textAreRef.current = ref;
        }}
        value={state.textCopied || ''}
        readOnly></textarea>
      {/* Modal for read more */}
      <Modal size="lg" isOpen={modal} toggle={onToggleModal}>
        <ModalHeader toggle={onToggleModal}>{modalBody.title}</ModalHeader>
        <ModalBody>
          <p className="text-break">{modalBody.description}</p>
          {modalBody.files &&
            modalBody.files.map((file, key) =>
              file.files ? (
                <Container key={`image-${key}`} className="my-5">
                  <p className="text-center">{file.files.name}</p>
                  <Thumb
                    type="URL"
                    file={`${
                      process.env.REACT_APP_API_ENDPOINT[
                        process.env.REACT_APP_API_ENDPOINT.length - 1
                      ] == '/'
                        ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                        : process.env.REACT_APP_API_ENDPOINT
                    }/api/knowledgehub/${file.category_id}/${
                      file.category_item_id
                    }/${file.files.name}`}
                  />
                </Container>
              ) : null
            )}
        </ModalBody>
      </Modal>

      {/* Popover for how to use*/}
      <div
        className={`sticky-top mb-1 bg-white ${
          state.selectedCategory &&
          state[state.selectedCategoryKey][state.selectedCategory] &&
          !!state[state.selectedCategoryKey][state.selectedCategory].list.length
            ? 'border-bottom'
            : ''
        }`}>
        {/* <Popover
          trigger="hover"
          placement="bottom"
          isOpen={popoverOpen}
          target="help"
          toggle={togglePopover}>
          <PopoverHeader>{t('how_to_use')}</PopoverHeader>
          <PopoverBody>{t('how_to_use_description')}</PopoverBody>
        </Popover> */}

        {/* Seachbar*/}
        <Row className="justify-content-end">
          <Col xl={7} lg={8} md={9} sm={12} className="my-2 ">
            <div className="row no-gutters">
              <div className="col">
                {state.isSearching ? (
                  <i className="fas fa-spinner fa-spin position-absolute py-2 px-3" />
                ) : (
                  <i className="fa fa-search py-2 px-3 position-absolute"></i>
                )}
                <input
                  className="form-control border-secondary rounded-pill searchbar"
                  type="search"
                  placeholder={t('search')}
                  onChange={e => onSearch(e.target.value)}
                />
              </div>
              <i
                className="fa fa-question-circle py-1 px-2 text-primary"
                aria-hidden="true"
                id="knowledgehub-help"></i>
              <CustomTooltip
                placement="bottom-end"
                target="knowledgehub-help"
                title={t('how_to_use')}
                description={
                  <div>
                    <div>{t('how_to_use_description')}</div>
                    {authUser.function === 'ADMIN' && !isWebviewerMode && (
                      <div className="pt-2 font-italic">
                        {t('how_to_use_description_note')}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </Col>
        </Row>

        {state.selectedCategory && (
          <div className="d-flex my-2">
            <div className="mr-auto">
              {state[state.selectedCategoryKey][state.selectedCategory] &&
                !!state[state.selectedCategoryKey][state.selectedCategory].list
                  .length && (
                  <>
                    {authUser.function === 'ADMIN' && !isWebviewerMode ? (
                      <CategoryName
                        t={t}
                        category={
                          state[state.selectedCategoryKey][
                            state.selectedCategory
                          ]
                        }
                      />
                    ) : (
                      <h3 className="m-2">{state.selectedCategory}</h3>
                    )}
                  </>
                )}
            </div>
            {authUser.function === 'ADMIN' && !isWebviewerMode ? (
              <>
                {(state[state.selectedCategoryKey][state.selectedCategory] &&
                  !!state[state.selectedCategoryKey][state.selectedCategory]
                    .list.length &&
                  state.selectedCategoryKey === 'favoriteResultList') ||
                (state[state.selectedCategoryKey][state.selectedCategory] &&
                  !!state[state.selectedCategoryKey][state.selectedCategory]
                    .list.length &&
                  state.selectedCategoryKey === 'resultList') ? (
                  <div>
                    <Button
                      color="success"
                      outline
                      onClick={() => handleIsAddingItemsInExistingCat(true)}>
                      <i className="fas fa-plus-circle p-2"></i>{' '}
                      {t('add_category_items')}
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        )}
      </div>

      {!!!Object.keys(state.resultList).length ? (
        <Container className="p-5 m-5 text-center text-muted">
          {t('no_result_found')}
        </Container>
      ) : (
        <>
          {state.selectedCategory &&
            state[state.selectedCategoryKey][state.selectedCategory] &&
            !!state[state.selectedCategoryKey][state.selectedCategory].list
              .length && (
              <>
                {/* Pagination*/}
                {Object.keys(state[state.selectedCategoryKey]).map((key, i) => {
                  if (state.selectedCategory !== key) return null;
                  return (
                    <ReactPaginate
                      key={`react-paginate-component-${i}`}
                      {...props}
                      data={
                        state[state.selectedCategoryKey][state.selectedCategory]
                          .list
                      }
                      modalBody={modalBody}
                      setModalBody={setModalBody}
                      onToggleModal={onToggleModal}
                      getRowContentAsText={getRowContentAsText}
                      selectedCategoryKey={state.selectedCategory}
                    />
                  );
                })}
              </>
            )}
        </>
      )}
    </Container>
  );
};

const CategoryName = ({ t, category }) => {
  const { state, dispatch } = useContext(knowledgehubContext);
  const [formReset, setFormReset] = useState(false);
  useEffect(() => {
    if (formReset) setFormReset(false);
    return setFormReset(false);
  }, [formReset]);

  const formikConfig = () => {
    return {
      initialValues: {
        category_name: (category && category.categoryName) || ''
      },
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        category_name: Yup.string()
          .trim()
          .required('Required')
          .max(60, 'Category name must be at most 60 characters')
      }),
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        dispatch(
          updateCategory(
            category.categoryId,
            {
              categoryName: values.category_name
            },
            state.selectedCategoryKey
          )
        )
          .then(res => {
            if (res.success) {
              Message.success(t('updated_successfully'));
              dispatch(fetchFavoriteCategoryItem());
            } else if (!res.success && res.data.hasExistingTitle) {
              Message.error(t('category_name_already_exists'));
            } else Message.error(t('failed_to_update'));
            setSubmitting(false);
          })
          .catch(() => {
            Message.error(t('failed_to_update'));
            setSubmitting(false);
          });
      }
    };
  };

  return (
    <>
      {category && (
        <Formik {...formikConfig()}>
          {formikProps => {
            const { isSubmitting, dirty, submitForm } = formikProps;
            const handleSubmitForm = () => submitForm();
            return (
              <Form>
                <div className="d-flex">
                  <div className="mr-auto">
                    <Field
                      name="category_name"
                      type="text"
                      placeholder={t('category_name')}
                      disabled={isSubmitting}
                      editable={formReset}
                      component={EditableInput}
                    />
                  </div>
                  <div>
                    {dirty && (
                      <Button
                        color="link"
                        disabled={isSubmitting || !dirty}
                        onClick={handleSubmitForm}
                        className="mb-3">
                        <i className="fas fa-save text-secondary" />
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return { AuthUser: state.authUser };
};

export default compose(
  connect(mapStateToProps, null),
  withTranslation(['knowledgeHub'])
)(ResultListItems);
