import React from 'react';
import Sidebar from './sidebar';
import ResultListItems from './resultListItems';

import { Container, Row, Col } from 'reactstrap';

export default () => {
  return (
    <div className="h-100">
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col xs="12" sm="6" md="4" lg="3" className="m-0 p-0">
            <Sidebar></Sidebar>
          </Col>
          <Col xs="12" sm="6" md="8" lg="9" className="m-0 p-0">
            <ResultListItems></ResultListItems>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
