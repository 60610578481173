// Import custom instance of axios
import axios from '../../utils/axios';
import { requestActionType } from '../../constants/actionType';

// Axios Types :

// export interface AxiosRequestConfig {
//   url?: string;
//   method?: string;
//   baseURL?: string;
//   transformRequest?: AxiosTransformer | AxiosTransformer[];
//   transformResponse?: AxiosTransformer | AxiosTransformer[];
//   headers?: any;
//   params?: any;
//   paramsSerializer?: (params: any) => string;
//   data?: any;
//   timeout?: number;
//   withCredentials?: boolean;
//   adapter?: AxiosAdapter;
//   auth?: AxiosBasicCredentials;
//   responseType?: string;
//   xsrfCookieName?: string;
//   xsrfHeaderName?: string;
//   onUploadProgress?: (progressEvent: any) => void;
//   onDownloadProgress?: (progressEvent: any) => void;
//   maxContentLength?: number;
//   validateStatus?: (status: number) => boolean;
//   maxRedirects?: number;
//   httpAgent?: any;
//   httpsAgent?: any;
//   proxy?: AxiosProxyConfig | false;
//   cancelToken?: CancelToken;
// }

// request<T = any>(config: AxiosRequestConfig): AxiosPromise<T>;
// get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>;
// delete(url: string, config?: AxiosRequestConfig): AxiosPromise;
// head(url: string, config?: AxiosRequestConfig): AxiosPromise;
// post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;
// put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;
// patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;

// export interface AxiosResponse<T = any>  {
//   data: T;
//   status: number;
//   statusText: string;
//   headers: any;
//   config: AxiosRequestConfig;
//   request?: any;
// }

export const pushRequestInProcess = (
  requestType,
  dateStarted,
  promiseRequest
) => {
  return {
    type: requestActionType.PUSH_REQUEST_IN_PROCESS,
    requestType,
    dateStarted,
    promiseRequest
  };
};
export const removeRequestInProcess = (requestType, dateStarted) => {
  return {
    type: requestActionType.REMOVE_REQUEST_IN_PROCESS,
    requestType,
    dateStarted
  };
};

export const performRequest = (
  method,
  url,
  data,
  config,
  requestName,
  isUnique = false,
  cancelRunning = false
) => (dispatch, getState) => {
  const timestamp = Date.now();
  const state = getState();
  // If isUnique === true we return the promise of the request running (if any) with that requestName
  if (
    isUnique &&
    !cancelRunning &&
    state.requests[requestName] &&
    state.requests[requestName].length > 0
  ) {
    return state.requests[requestName][0].promiseRequest;
  } else {
    const promiseRequest = axios
      .request({ ...config, data: data ? data : null, url, method })
      .then(res => {
        const latestState = getState();
        if (
          isUnique &&
          cancelRunning &&
          latestState.requests[requestName] &&
          latestState.requests[requestName].length > 1
        ) {
          const error = new Error();
          error.name = 'request_canceled';
          throw error;
        }
        return res;
      })
      .then(_checkStatus)
      .then(response => {
        dispatch(removeRequestInProcess(requestName, timestamp));
        return response;
      })
      .catch(err => {
        dispatch(removeRequestInProcess(requestName, timestamp));
        throw err;
      });
    dispatch(pushRequestInProcess(requestName, timestamp, promiseRequest));
    return promiseRequest;
  }
};

const _checkStatus = response => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

export const handleErrorMessageStatus = err => {
  if (err.response && err.response.status === 403) {
    throw new Error(
       'not_authorised'
    );
  } else if (err.response && err.response.status === 401) {
    throw new Error (
      'error:not_authorized_or_authenticated'
    )
  }
   else if (err.response && err.response.status === 409) {
    throw new Error(
      (err.response && err.response.data && err.response.data.error) ||
        'error.default_message'
    );
  } else {
    throw new Error(
      (err.response && err.response.data && err.response.data.error) ||
        err.message ||
        'error.default_message'
    );
  }
};
