import React, { Component } from 'react';
import { Container, Card, Button, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';

import { sendConfirmation } from '../../../store/actions';
import styles from './registrationConfirmation.module.css';

class RegistrationConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoaded: false, error: false, messageKey: '', userId: '' };
  }
  componentDidMount() {
    const token = this.props.match.params.token;
    this.props.sendConfirmation(token).then(result => {
      console.log(result.success);
      setTimeout(() => {
        if (result.success) {
          this.setState({
            isLoaded: true,
            error: false,
            messageKey: result.message
          });
        } else {
          this.setState({
            isLoaded: true,
            error: result.message,
            userId: result.data.userId
          });
        }
      }, 1500);
    });
  }
  render() {
    const { t } = this.props;
    const { isLoaded, error, messageKey } = this.state;
    const CardContent = () => {
      if (isLoaded && !error) {
        return (
          <>
            <h1>{t(`registration_confirmation:${messageKey}`)}</h1>
            <div className="mt-2">
              <Link to="/login">
                <Button color="primary">
                  {t('registration_confirmation:button_redirect')}
                </Button>
              </Link>
            </div>
          </>
        );
      } else if (isLoaded) {
        return (
          <Alert color="danger" className="text-center m-1" role="alert">
            <i
              className={`fas fa-exclamation-triangle ${styles.forgotPasswordAlertLogo}`}
            />
            <h3 className="p-3">{t('forgotPassword:failed')}</h3>
            {t('error:token_invalid')}
            <div>
              {this.state.userId && (
                <Link
                  to={{
                    pathname: '/resend-token',
                    state: {
                      isInvited: false,
                      userId: this.state.userId
                    }
                  }}>
                  {t('forgotPassword:try_again')}
                </Link>
              )}
            </div>
          </Alert>
        );
      } else {
        return (
          <>
            <h1>{t('registration_confirmation:confirmation_in_process')}</h1>
            <hr className="my-2" />
            <div className="d-flex justify-content-center">
              <div
                className={`spinner-grow text-primary mt-3 ${styles.registrationConfirmationSpinner}`}
                role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        );
      }
    };
    return (
      <Container>
        <Card className={'mt-3 p-4'}>
          <CardContent />
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendConfirmation: token => dispatch(sendConfirmation(token))
  };
};
export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withTranslation(['registration_confirmation', 'error'])
)(RegistrationConfirmation);
