import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import NewsItem from './NewsItem';
import IBEWNewsItem from './ibewNewsItem';
import { withTranslation } from 'react-i18next';

const NewsModal = ({
  newsModalIsOpened,
  setnewsModelIsOpened,
  fetchAllNews,
  resetNews,
  timeAgoFormatting,
  t
}) => {
  const toggleModal = () => setnewsModelIsOpened(!newsModalIsOpened);
  const { news } = useSelector(state => state);
  const dispatch = useDispatch();

  const [newsIsLoaded, setNewsIsLoaded] = useState(false);

  const newNodeBodyRef = useRef();

  const [isFetchingAdditionalNews, setIsFetchingAdditionalNews] =
    useState(false);

  let from = useRef(0);
  let to = useRef(5);

  useEffect(() => {
    if (
      newsIsLoaded &&
      newNodeBodyRef.current &&
      newNodeBodyRef.current.scrollHeight !==
        newNodeBodyRef.current.scrollTop + newNodeBodyRef.current.clientHeight
    ) {
      setIsFetchingAdditionalNews(true);
    }
  }, [
    from,
    to,
    newsIsLoaded,
    newNodeBodyRef.current && newNodeBodyRef.current.scrollHeight
  ]);

  const newsKeys = Object.keys(news);

  const newsToDisplay = !!newsKeys.length ? (
    newsKeys.map(newsKey => {
      return (
        <NewsItem
          key={`news-item-${newsKey}`}
          news={news[newsKey]}
          timeAgoFormatting={timeAgoFormatting}
        />
      );
    })
  ) : (
    <div className="p-5 text-center font-weight-light">No news</div>
  );

  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  const parseDateToday = Date.parse(
    `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`
  );

  const onScroll = () => {
    if (
      newNodeBodyRef.current.scrollTop + newNodeBodyRef.current.clientHeight ===
      newNodeBodyRef.current.scrollHeight
    ) {
      from.current = to.current;
      to.current += 5;

      dispatch(
        fetchAllNews(
          {
            display_start_date: {
              date: parseDateToday,
              comparator: '<='
            },
            display_end_date: { date: parseDateToday, comparator: '>=' },
            is_approved: true
          },
          { field: 'created_at', order: 'desc' },
          { from: from.current, to: to.current }
        )
      )
        .then(res => {
          setIsFetchingAdditionalNews(false);
          if (!!!res.length) to.current -= 10;
        })
        .catch(() => {
          setIsFetchingAdditionalNews(false);
          to.current -= 10;
        });
    }
  };

  const fetchNews = () => {
    const newsModalBodyElement = document.getElementById('news-modal-body');
    newsModalBodyElement.addEventListener('scroll', onScroll);
    newNodeBodyRef.current = newsModalBodyElement;
    dispatch(resetNews());
    dispatch(
      fetchAllNews(
        {
          display_start_date: {
            date: parseDateToday,
            comparator: '<='
          },
          display_end_date: { date: parseDateToday, comparator: '>=' },
          is_approved: true
        },
        { field: 'created_at', order: 'asc' },
        { from: from.current, to: to.current }
      )
    ).then(() => {
      setNewsIsLoaded(true);
    });
  };

  return (
    <Modal
      scrollable
      backdrop={false}
      size="lg"
      isOpen={newsModalIsOpened}
      toggle={toggleModal}
      backdropClassName="news-modal"
      className="news-modal news-modal-content"
      contentClassName="news-modal"
      onOpened={fetchNews}>
      <ModalHeader
        toggle={toggleModal}
        className="border-secondary news-modal-background text-white">
        {t('fornaxNews')}
      </ModalHeader>
      <ModalBody
        className="rounded news-modal-background text-white rounded-0 ews-modal-body-overflow-scroll"
        id="news-modal-body">
        {newsIsLoaded ? (
          newsToDisplay
        ) : (
          <div className="p-5 text-center">
            <Spinner type="grow" color="light" />
          </div>
        )}
        {isFetchingAdditionalNews && (
          <div className="p-2 text-center">
            <Spinner color="light" />
          </div>
        )}
      </ModalBody>

      {/* <ModalBody
        className="rounded news-modal-background text-white rounded-0 ews-modal-body-overflow-scroll"
        id="news-modal-body">
        <IBEWNewsItem />
      </ModalBody> */}
    </Modal>
  );
};

export default withTranslation(['front'])(NewsModal);
