import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Badge,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Alert,
  Spinner
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, FieldArray, getIn } from 'formik';
import classnames from 'classnames';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { compose, createEventHandlerWithConfig } from 'recompose';
import { connect } from 'react-redux';

import CustomInput from '../../UI/Input/customInput';
import { dateFormatting } from '../../../locales/dateFormat';
import Message from '../../UI/Message';

import Attachment from './AttachmentList';
import SubmissionResultList from './SubmissionResultList';
import CorrespondenceList from './Correspondence/CorrespondenceList';
import CorrespondenceEditor from './Correspondence/Editor';
import styles from './Styles.module.css';

import {
  fetchProjectStage,
  fetchProject,
  getResultLast,
  resetResults,
  resetAttachmentFiles,
  updateSubmission,
  fetchCorrespondence
} from '../../../store/actions';

import prettyBytes from 'pretty-bytes';
import { timeAgoFormatting } from '../../../locales/dateFormat';
import InfoAlert from '../../UI/InformationAlert';

const statusToPillColor = {
  open: 'info',
  re_open: 'info',
  in_progress: 'warning',
  reviewed: 'warning',
  completed: 'success',
  closed: 'danger'
};

const StatusPill = ({ status, label }) => (
  <h4>
    <Badge color={statusToPillColor[status] || 'secondary'}>{label}</Badge>
  </h4>
);

const formikConfig = props => {
  const currentStageJobs = Object.keys(props.stage.jobs)
    .reduce(
      (jobList, jobType) => [...jobList, ...props.stage.jobs[jobType]],
      []
    )
    .filter(job => job.status === 'DONE' && !job.is_obsolete);
  const selectedJobs = currentStageJobs
    .filter(currentJob => {
      return props.submission.job_related
        .filter(j => j.status === 'DONE' && !j.is_obsolete)
        .map(j => j.job_id)
        .includes(currentJob.job_id);
    })
    .map(j => j.job_id);
  const initialValues = {
    reference: '',
    correspondence: { subject: '', content: '' },
    results: selectedJobs
  };
  return {
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      results: Yup.array().min(1, props.t('error_select_result')),
      correspondence: Yup.object().shape({
        subject: Yup.string(props.t('error:message_subject_required')).required(
          props.t('error:message_subject_required')
        )
      })
    }),
    validate: values => {
      const editorState = values.correspondence.content;
      if (
        !editorState.blocks ||
        editorState.blocks.filter(b => b.text !== '').length === 0
      ) {
        return {
          correspondence: { content: props.t('error:message_content_required') }
        };
      }
    },
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, touched }) => {
      setSubmitting(true);
      const { stageName, projectId } = props;
      try {
        const submissionId = props.submission.submission_id;

        const changedValues = Object.keys(values).reduce(
          (returned, currentKey) => {
            const currentValue = values[currentKey];
            if (
              initialValues[currentKey] === undefined ||
              currentValue !== initialValues[currentKey]
            ) {
              return { ...returned, [currentKey]: currentValue };
            } else {
              return returned;
            }
          },
          {}
        );

        // Changes the status to re_open
        changedValues.status = 're_open';

        const formattedValues = {
          ...changedValues,
          correspondence: {
            subject: changedValues.correspondence.subject,
            content: JSON.stringify(changedValues.correspondence.content)
          }
        };
        await props.updateSubmission(
          projectId,
          stageName,
          submissionId,
          formattedValues
        );
        props.resetAttachmentFiles();
        // props.history.replace(`/project/${projectId}/submission/${stageName}`);
        props.afterSubmit();
      } catch (err) {
        // TODO HANDLE ERR
        Message.error(props.t(`error:${err.message}`));
      } finally {
        setSubmitting(false);
      }
    }
  };
};

const selectRow = resultsToSubmit => ({
  mode: 'checkbox',
  clickToSelect: true,
  onSelect: (rows, isSelect) => {
    resultsToSubmit = rows.map(r => r.original.job_id);
  },
  selected: resultsToSubmit
});

class SubmissionReOpenPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      editorState: EditorState.createEmpty(),
      isDisabledSubmit: false
    };
  }

  componentDidMount() {
    this.props
      .fetchProjectStage(this.props.projectId, this.props.stageName)
      .then(() => {
        this.setState({ isLoaded: true });
      });
  }
  closeSubmission = () => {
    this.setState({ isDisabledSubmit: true });
    const { projectId, stageName, submissionId, t } = this.props;
    this.props
      .updateSubmission(projectId, stageName, submissionId, {
        status: 'closed'
      })
      .then(() => {
        this.setState({ isDisabledSubmit: false });
        this.props.afterSubmit();
      })
      .catch(err => {
        Message.error(t(`error:${err.message}`));
      });
  };

  render() {
    const {
      submission,
      userFunction,
      stageName,
      project,
      t,
      stage,
      models,
      projectId,
      submissionId
    } = this.props;
    const { isLoaded, isDisabledSubmit } = this.state;
    return (
      <>
        {isLoaded && (
          <Formik {...formikConfig(this.props)}>
            {formikProps => {
              return (
                <div>
                  {/* <div className="shadow p-3 mt-3 mb-5 rounded">
                  <h5 className="text-primary mb-3">{t('re_openning')}</h5>
                </div> */}

                  {/* TODO: TRANSLATION */}
                  <InfoAlert className="mt-4 shadow">
                    <Row>
                      <h4 className="mt-3 ml-2 alert-heading">
                        Select the action to perform:
                      </h4>
                    </Row>
                    <Row className="pl-5 pr-2">
                      <h5>If you choose to re-open the consultation</h5>
                      <ul>
                        <li>
                          The latest Model Files will be included in this
                          consultation and the old ones removed.
                        </li>
                        <li>
                          You can add new Check Result to the consultation if
                          they are related to the current Model Files.
                        </li>
                      </ul>
                    </Row>
                    <Row className="pl-5 pr-2">
                      <h5>If you choose to close the consultation</h5>
                      <ul>
                        <li>
                          {
                            'The consultation will be "frozen" which means that you won\'t be able to perform any other changes to it'
                          }
                        </li>
                        <li>
                          The consultation will still be accessible in a
                          read-only mode
                        </li>
                        <li>
                          You can request the administrator to re-open a closed
                          consultation, if needed.
                        </li>
                      </ul>
                    </Row>
                  </InfoAlert>
                  <div className="sticky-top bg-white shadow p-3 rounded mt-4 position-sticky d-flex justify-content-between">
                    <Button
                      color="success"
                      type="submit"
                      className="align-self-start font-weight-bold"
                      disabled={formikProps.isSubmitting || isDisabledSubmit}
                      onClick={() => {
                        formikProps.setTouched({
                          reference: true,
                          correspondence: { subject: true, content: true },
                          results: true
                        });
                        formikProps.validateForm().then(errors => {
                          const errorsTab = Object.values(errors);
                          if (errorsTab.length > 0) {
                            Message.error(this.props.t('error:form_not_valid'));
                          } else {
                            formikProps.handleSubmit();
                          }
                        });
                      }}>
                      {t('re_openning')}
                    </Button>
                    <div className="align-self-center text-muted font-weight-bold">
                      {t('or').toUpperCase()}{' '}
                    </div>
                    <Button
                      color="danger"
                      type="submit"
                      className="align-self-end font-weight-bold"
                      disabled={formikProps.isSubmitting || isDisabledSubmit}
                      onClick={this.closeSubmission}>
                      {t('close_consultation')}
                    </Button>
                  </div>
                  <div className="shadow p-3 rounded mt-4">
                    <div className="d-flex justify-content-between">
                      <h5 className="text-primary">{t('basic_info')}</h5>
                      <div>
                        <StatusPill
                          status={submission.status}
                          label={t(`status_label_${submission.status}`)}
                        />
                      </div>
                    </div>
                    <hr />
                    <Container className="pt-3 pb-3">
                      {(submission.mode.editableField.some(
                        v => v === 'reference' || v === '*'
                      ) ||
                        submission.mode.readableField.some(
                          v => v === 'reference' || v === '*'
                        )) && (
                        <Row>
                          <Col>{t('reference')}: </Col>
                          <Col>
                            <h6>
                              {submission.reference || t('undefined_ref')}
                            </h6>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>{t('project_name')}:</Col>
                        <Col className="d-flex">
                          {userFunction === 'PO' ? (
                            <h6>{project.name}</h6>
                          ) : (
                            <Link
                              to={
                                userFunction === 'QP'
                                  ? `/project/${project.project_id}/models/${stageName}`
                                  : `/admin/project/${project.project_id}/models/${stageName}`
                              }>
                              <h6>{project.name}</h6>
                            </Link>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>{t('stage_name')}:</Col>
                        <Col>
                          <h6>{stageName}</h6>
                        </Col>
                      </Row>
                      {(submission.mode.editableField.some(
                        v => v === 'status' || v === '*'
                      ) ||
                        submission.mode.readableField.some(
                          v => v === 'status' || v === '*'
                        )) && (
                        <Row>
                          <Col>{t('status')}: </Col>
                          <Col>
                            <h6>{t(`status_label_${submission.status}`)}</h6>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>{t('date_created')}: </Col>
                        <Col>
                          <h6>
                            {dateFormatting(
                              submission.date_created,
                              'defaultHour'
                            )}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{t('created_by')}: </Col>
                        <Col>
                          <h6>{`${submission.created_by.first_name} ${submission.created_by.last_name}`}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{t('email')}: </Col>
                        <Col>
                          <h6>{submission.created_by.email}</h6>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  {(submission.mode.editableField.some(
                    v => v === 'attachments' || v === '*'
                  ) ||
                    submission.mode.readableField.some(
                      v => v === 'attachments' || v === '*'
                    )) && (
                    <>
                      {submission.mode.editableField.some(
                        v => v === 'attachments' || v === '*'
                      ) ? (
                        <Row>
                          <Col>
                            <div className="shadow p-3 mt-4 rounded">
                              <Attachment
                                {...this.props}
                                action="update_attachments"
                                isFormikForm
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <div className="shadow p-3 mt-4 rounded">
                              <h5 className="text-primary">
                                {t('attachments')}
                              </h5>
                              <hr />
                              {submission.list_of_file_attachments.length >
                              0 ? (
                                <ListGroup>
                                  {submission.list_of_file_attachments.map(
                                    (attachment, key) => {
                                      return (
                                        <ListGroupItem
                                          key={key}
                                          className="border-0">
                                          <Row>
                                            <Col sm={11}>
                                              {attachment.file_name}
                                            </Col>
                                            <Col sm={1}>
                                              <a
                                                download
                                                onClick={e =>
                                                  e.stopPropagation()
                                                }
                                                href={`${process.env.REACT_APP_API_ENDPOINT}/${attachment.download_link}`}>
                                                <span id={`attachment-${key}`}>
                                                  <i className="fas fa-download text-primary" />
                                                </span>
                                              </a>
                                              <UncontrolledTooltip
                                                fade={false}
                                                placement="auto"
                                                target={`attachment-${key}`}>
                                                {t('download')}
                                              </UncontrolledTooltip>
                                            </Col>
                                          </Row>
                                        </ListGroupItem>
                                      );
                                    }
                                  )}
                                </ListGroup>
                              ) : (
                                // NO attachements
                                <h6 className="text-secondary">
                                  {t('no_attachment')}
                                </h6>
                              )}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  <div
                    className={classnames('shadow p-3 rounded mt-4', {
                      'border-danger border-thick': formikProps.errors.results
                    })}>
                    <h5 className="text-primary mb-3">{t('results_title')}</h5>
                    <FieldArray
                      name="results"
                      component={() => (
                        <SubmissionResultList
                          data={Object.keys(stage.jobs)
                            .reduce(
                              (jobList, jobType) => [
                                ...jobList,
                                ...stage.jobs[jobType]
                              ],
                              []
                            )
                            .filter(
                              job => job.status === 'DONE' && !job.is_obsolete
                            )}
                          isValid={!formikProps.errors.results}
                          selectRow={selectRow(
                            formikProps.values.results,
                            formikProps.initialValues.results
                          )}
                          projectName={project.name}
                          projectId={projectId}
                          stageName={stageName}
                        />
                      )}
                    />
                    <p>{t('note_select_result')}</p>
                    {formikProps.errors.results && (
                      <Alert color="danger">{formikProps.errors.results}</Alert>
                    )}
                  </div>

                  <div className={'shadow p-3 rounded mt-4'}>
                    <ListGroup>
                      <h5 className="text-primary mb-3">{t('model_list')}</h5>
                      {models.map((m, key) => {
                        return (
                          <ListGroupItem key={key}>
                            <Row className="d-flex justify-content-between">
                              <div className="d-flex flex-column justify-content-between">
                                <div>
                                  {m.name} - {prettyBytes(m.size)}
                                </div>
                                {m.date && (
                                  <div className="text-secondary">
                                    last updated: {timeAgoFormatting(m.date)}{' '}
                                    ago
                                  </div>
                                )}
                              </div>
                            </Row>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  </div>

                  {(submission.mode.editableField.some(
                    v => v === 'correspondence' || v === '*'
                  ) ||
                    submission.mode.readableField.some(
                      v => v === 'correspondence' || v === '*'
                    )) && (
                    <div className="mt-4 shadow p-3 rounded">
                      <div className="border p-3 mb-3 bg-white rounded">
                        <h5 className="text-primary py-3">
                          {t('correspondence')}
                        </h5>

                        <CorrespondenceList
                          projectId={projectId}
                          stageName={stageName}
                          translate={this.props.t}
                          submissionId={submissionId}
                        />

                        <Field
                          name="correspondence.subject"
                          placeholder="Subject"
                          component={CustomInput}
                        />
                        <Field
                          name="correspondence.content"
                          render={() => {
                            const {
                              touched,
                              errors,
                              setFieldValue,
                              setFieldTouched
                            } = formikProps;
                            const isValid =
                              getIn(touched, 'correspondence.content') &&
                              !getIn(errors, 'correspondence.content');
                            const isInvalid = !!(
                              getIn(touched, 'correspondence.content') &&
                              getIn(errors, 'correspondence.content')
                            );
                            const onChange = editorState => {
                              setFieldValue(
                                'correspondence.content',
                                convertToRaw(editorState.getCurrentContent())
                              );
                            };

                            const onBlur = () => {
                              setFieldTouched('correspondence.content', true);
                              formikProps.handleBlur('correspondence.content');
                            };
                            return (
                              <>
                                <CorrespondenceEditor
                                  onInit={this.onInit}
                                  isValid={isValid}
                                  isInvalid={isInvalid}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  projectId={projectId}
                                  stageName={stageName}
                                  invalidFeedback={getIn(
                                    errors,
                                    'correspondence.content'
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </Formik>
        )}
        {!isLoaded && (
          <div
            className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
            <Spinner
              type="grow"
              className={styles.spinnerDimention}
              color="primary"
            />
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state, { projectId, stageName }) => {
  const stage = (() => {
    if (state.project_stages[projectId]) {
      if (stageName) {
        return state.project_stages[projectId].stages[stageName];
      } else {
        const firstStage = Object.keys(
          state.project_stages[projectId].stages
        ).pop();
        if (firstStage) {
          return state.project_stages[projectId].stages[firstStage];
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  })();
  return { stage, models: state.modelFiles };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: projectId => dispatch(fetchProject(projectId)),
    fetchProjectStage: (projectId, stageName) =>
      dispatch(fetchProjectStage(projectId, stageName)),
    resetAttachmentFiles: () => dispatch(resetAttachmentFiles()),
    resetResults: () => dispatch(resetResults()),
    getResultLast: projectId => dispatch(getResultLast(projectId)),
    fetchCorrespondence: params => dispatch(fetchCorrespondence(params)),
    updateSubmission: (projectId, stageName, submissionId, changedData) =>
      dispatch(
        updateSubmission(projectId, stageName, submissionId, changedData)
      )
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['submission'])
)(SubmissionReOpenPresenter);
