import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const RolesPresenter = props => {
  const { t, fetchUserFunction, userFunction } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchUserFunction().then(() => setIsLoaded(true));
  }, []);

  return (
    <>
      {isLoaded && (
        <Container className="mb-3">
          {Object.keys(userFunction).map(entityType => {
            return (
              <>
                <Row className="px-3 mb-2">
                  <Col className="mt-3 border-bottom">
                    <h5>{t(`userPermission:entity_type_${entityType}`)}</h5>
                  </Col>
                </Row>
                <Row className="px-3">
                  {userFunction[entityType].map(action => {
                    return (
                      <Col sm={6}>
                        {t(`userPermission:${action}`)}
                      </Col>
                    );
                  })}
                </Row>
              </>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default withTranslation(['editProfile', 'userPermission'])(
  RolesPresenter
);
