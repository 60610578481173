import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Button } from 'reactstrap';
import classnames from 'classnames';

import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import { createOrganization } from '../../../../store/actions';

const CreateOrganizationComponent = ({ t, createOrganization }) => {
  const formikConfig = {
    initialValues: {
      name:'',
      website:'',
      address1:'',
      address2:'',
      description:''
      
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required()
    }),
    // validate: values => {},
    // validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await createOrganization(values);
        Message.success(t('organization:organization_created'));
      } catch (err) {
        Message.error(t(err.message));
      }
      setSubmitting(false);
    }
  };
  return (
    <Container>
      <div className="shadow p-3 mt-3 mb-5 rounded">
        <Row className="px-3">
          <h2 className="text-primary">
            {t('organization:create_form_title')}
          </h2>
        </Row>
        <Formik {...formikConfig}>
          {formikProps => {
            const { isSubmitting,errors } = formikProps;
            return (
              <Form>
                <Field
                  name="name"
                  placeholder={t('organization:name')}
                  component={CustomInput}
                />
                <Field
                  name="website"
                  placeholder={t('organization:website')}
                  component={CustomInput}
                />
                <Field
                  name="address1"
                  placeholder={t('organization:address_line1')}
                  component={CustomInput}
                />
                <Field
                  name="address2"
                  placeholder={t('organization:address_line2')}
                  component={CustomInput}
                />
                <Field
                  name="description"
                  type="textarea"
                  placeholder={t('organization:description')}
                  component={CustomInput}
                />
                <Button color="primary" type="submit"  disabled={isSubmitting}>
                  {t('main:submit')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

const CreateOrganizationContainer = props => {
  return <CreateOrganizationComponent {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(createOrganization(values))
  };
};

const mapStateToProps = () => {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error'])
)(CreateOrganizationContainer);
