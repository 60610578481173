import { referenceDocumentsActionType } from '../../constants/actionType';
import { merge, union, unionBy, has } from 'lodash';
const {
  ADD_REFERENCE_DOCUMENTS,
  REMOVE_REFERENCE_DOCUMENT,
  RESET_REFERENCE_DOCUMENT,
  FETCH_REFERENCE_DOCUMENTS,
  UPLOAD_REFERENCE_DOCUMENTS,
  FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER,
  REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER,
  REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER,
  SOFT_REMOVE_REFERENCE_DOCUMENT,
  ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT,
  REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER,
  REPLACE_REFERENCE_DOCUMENT,
  REFERENCE_DOCUMENTS_CUT_FILE_OR_FOLDER,
  SET_REFERENCE_DOCUMENT_ROOT
} = referenceDocumentsActionType;
const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFERENCE_DOCUMENTS: {
      const { documentList } = action;
      return [...documentList];
    }
    case ADD_REFERENCE_DOCUMENTS: {
      const { document } = action;
      return [...state, document];
    }
    //SOFT remove the file
    case SOFT_REMOVE_REFERENCE_DOCUMENT: {
      const newState = state.map(document => {
        return document.name === action.document.name && document.dirty
          ? { ...document, dirty: true, removed: true }
          : document;
      });
      return newState;
    }

    case REMOVE_REFERENCE_DOCUMENT: {
      const { data } = action;
      const new_data = data.map(doc => {
        return { ...doc, dirty: true, removed: true };
      });
      const newState = unionBy(new_data, state, 'id');
      return newState;
    }
    case UPLOAD_REFERENCE_DOCUMENTS: {
      const { documents } = action;

      const newState = unionBy(
        documents
          .filter(d => d.path)
          .map(doc => {
            return { ...doc, dirty: false };
          }),
        state.filter(doc => {
          return !doc.dirty;
        }),
        'id'
      );
      return newState;
    }
    case RESET_REFERENCE_DOCUMENT:
      return initialState;

    case FETCH_REFERENCE_DOCUMENTS_FILE_EXPLORER: {
      const { documentList } = action;

      return [...documentList];
    }

    case REFERENCE_DOCUMENTS_MOVE_FILE_OR_FOLDER: {
      const { documentList, itemToMoveId, isFolder } = action;
      const newState = state.map(document => {
        return document.id === itemToMoveId
          ? { ...document, dirty: true, removed: true }
          : document;
      });
      const unionByProperty =
        isFolder === true ? 'id' : 'base_url_to_display_in_file_explorer';

      const result = unionBy(documentList, newState, unionByProperty);
      return result;
    }

    case REFERENCE_DOCUMENTS_RENAME_FILE_OR_FOLDER: {
      const { documentList } = action;

      const result = unionBy(documentList, state, 'id');
      return result;
    }
    case ADD_DESCRIPTION_ON_REFERENCE_DOCUMENT: {
      const { documentList } = action;

      const result = unionBy(documentList, state, 'id');
      return result;
    }
    case REFERENCE_DOCUMENTS_PASTE_FILE_OR_FOLDER: {
      const { document } = action;
      const result = unionBy(document, state, 'id');
      return result;
    }
    case REPLACE_REFERENCE_DOCUMENT: {
      const { document } = action;
      const index = state.findIndex(
        item => item.name === document.name && item.dirty
      );
      if (index === -1) {
        state.push(document);
      } else {
        state[index] = document;
      }
    }
    case SET_REFERENCE_DOCUMENT_ROOT: {
      const { entityTargetId, newConcurrencyControlVersionId } = action;
      const index = state.findIndex(item => item.id === entityTargetId);

      return state.map(doc => {
        if (doc.id === entityTargetId) {
          doc.concurrency_control_version_id = newConcurrencyControlVersionId;
        }
        return {
          ...doc
        };
      });
    }

    default:
      return state;
  }
};

export default reducer;
