import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import ProjectDetailPresenter from './ProjectDetailPresenter';
import {
  updateModelList,
  resetModelFile,
  removeModelFile,
  replaceModelFile,
  addModelFile,
  updateProject,
  fetchProject,
  fetchProjectStage,
  fetchStageComparison,
  requestStageComparison,
  setNewModelFiles,
  fetchRoles,
  createStage,
  getWebviewerToken,
  addReferenceDocuments,
  removeReferenceDocument,
  uploadReferenceDocuments,
  resetReferenceDocument,
  fetchReferenceDocuments,
  deleteProject,
  setNew2DModelFiles,
  updateModelfile,
  reset2DModelFile,
  add2DModelFile,
  remove2DModelFile,
  update2DModelList,
  setPlanType,
  fetchListProjectStageName,
  queryECheck,
  fetchAllUserPreference,
  removeFavoriteRulePreference,
  resetUserPreference,
  updateUserPreference,
  fetchProjectPreference,
  fetchReferenceDocumentsFileExplorer,
  addFolderAction,
  moveReferenceDocument,
  renameReferenceDocument,
  downloadReferenceDocument,
  softRemoveReferenceDocument,
  fetchProjectAdditionalFields,
  addDescriptionOnReferenceDocuments,
  referenceDocumentPasteAction,
  setNewReferenceDocumentFiles,
  multipleItemActions,
  dispatchAllUploads,
  getTotalDocumentSize,
  fetchProjectRelatedGroups,
  fetchAllowedIFcVersionList,
  fetchModelComparisonList,
  deleteStage,
  fetchProjectStageJobs,
  fetchProjectThumbailSettings,
  updateJobRuleCompleted,
  fetchReportTemplateList,
  fetchAdminConfigSettings,
  fetchAllowedProjectSizeConfig
} from '../../../store/actions';

const mapStateToProps = (state, { match }) => {
  const project = state.projects[match.params.id];
  const stage = (() => {
    if (state.project_stages[match.params.id]) {
      if (match.params.stageName) {
        return state.project_stages[match.params.id].stages[
          match.params.stageName
        ];
      } else {
        const firstStage = Object.keys(
          state.project_stages[match.params.id].stages
        ).pop();
        if (firstStage) {
          return state.project_stages[match.params.id].stages[firstStage];
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  })();
  return {
    project,
    stage,
    models: state.modelFiles,
    models2D: state.model2DFiles,
    referenceDocuments: state.reference_documents,
    userId: state.authUser.userId,
    authUser: state.authUser,
    predefined_stagename_list: state.app.predefined_list_of_stages.user_created
      ? state.app.predefined_list_of_stages.user_created
      : [],
    userPreference: state.userPreference,
    project_preference: state.project_preference,
    projectAdditionalFields: state.app.projectAdditionalFields,
    allowed_ifc_version: state.app.allowed_ifc_version,
    model_comparison_list: state.app.model_comparison_list,
    featureList: state.app.featureList,
    isUploadingFile: state.app.isUploadingFile,
    thumbnailSettings: state.app.thumbnailSettings,
    reportTemplateList: state.app.report_template_list
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createStage: (projectId, stageData) =>
      dispatch(createStage(projectId, stageData)),
    fetchRoles: () => dispatch(fetchRoles()),
    fetchProject: projectID => dispatch(fetchProject(projectID)),
    fetchProjectStage: (projectId, stageName) =>
      dispatch(fetchProjectStage(projectId, stageName)),
    fetchStageComparison: (projectId, stageName) =>
      dispatch(fetchStageComparison(projectId, stageName)),
    requestStageComparison: (
      projectId,
      stageName,
      targetStageName,
      checkedRulesForComparison
    ) =>
      dispatch(
        requestStageComparison(
          projectId,
          stageName,
          targetStageName,
          checkedRulesForComparison
        )
      ),
    updateProject: (projectId, projectData) =>
      dispatch(updateProject(projectId, projectData)),
    updateModelList: (
      projectId,
      stageName,
      filesAdded,
      filesRemoved,
      projectAction
    ) =>
      dispatch(
        updateModelList(
          projectId,
          stageName,
          filesAdded,
          filesRemoved,
          projectAction
        )
      ),
    getWebviewerToken: (projectId, stageName) =>
      dispatch(getWebviewerToken(projectId, stageName)),
    update2DModelList: (
      projectId,
      stageName,
      filesAdded,
      filesRemoved,
      models2dExcludeFile
    ) =>
      dispatch(
        update2DModelList(
          projectId,
          stageName,
          filesAdded,
          filesRemoved,
          models2dExcludeFile
        )
      ),
    queryECheck: (
      projectId,
      stageName,
      rules,
      dependencyRules,
      jobType,
      isAddRulesToFavorites,
      isComingFromShorcutButton = true
    ) =>
      dispatch(
        queryECheck(
          projectId,
          stageName,
          rules,
          dependencyRules,
          jobType,
          isAddRulesToFavorites,
          isComingFromShorcutButton
        )
      ),

    fetchListProjectStageName: () => dispatch(fetchListProjectStageName()),
    fetchAllowedIFcVersionList: () => dispatch(fetchAllowedIFcVersionList()),

    referenceDocumentActions: {
      setNewReferenceDocumentFiles: (newFiles, existingFiles) =>
        dispatch(setNewReferenceDocumentFiles(newFiles, existingFiles)),
      addReferenceDocuments: document =>
        dispatch(addReferenceDocuments(document)),
      removeReferenceDocument: (projectId, id, path, isFolder, activeid) =>
        dispatch(
          removeReferenceDocument(projectId, id, path, isFolder, activeid)
        ),

      uploadReferenceDocuments: (
        projectId,
        documentsAdded,
        documentsRemoved,
        activeDirectoryToUpload,
        origin_details,
        onUploadProgress
      ) =>
        dispatch(
          uploadReferenceDocuments(
            projectId,
            documentsAdded,
            documentsRemoved,
            activeDirectoryToUpload,
            origin_details,
            onUploadProgress
          )
        ),
      resetReferenceDocument: () => dispatch(resetReferenceDocument()),
      fetchReferenceDocuments: (projectId, filters, order, pagination) =>
        dispatch(
          fetchReferenceDocuments(projectId, filters, order, pagination)
        ),
      fetchReferenceDocumentsFileExplorer: projectId =>
        dispatch(fetchReferenceDocumentsFileExplorer(projectId)),
      addFolderAction: (
        projectId,
        name,
        activeItemToDisplay,
        replaceValidation
      ) =>
        dispatch(
          addFolderAction(
            projectId,
            name,
            activeItemToDisplay,
            replaceValidation
          )
        ),
      moveReferenceDocument: (
        projectId,
        itemToMove,
        destination,
        isFolder,
        id
      ) =>
        dispatch(
          moveReferenceDocument(
            projectId,
            itemToMove,
            destination,
            isFolder,
            id
          )
        ),
      renameReferenceDocument: (projectId, oldName, newName, isFolder, id) =>
        dispatch(
          renameReferenceDocument(projectId, oldName, newName, isFolder, id)
        ),
      downloadReferenceDocument: (projectId, id, activeItemDownloadLink) =>
        dispatch(
          downloadReferenceDocument(projectId, id, activeItemDownloadLink)
        ),
      softRemoveReferenceDocument: document =>
        dispatch(softRemoveReferenceDocument(document)),
      addDescriptionOnReferenceDocuments: (projectId, documentId, value) =>
        dispatch(
          addDescriptionOnReferenceDocuments(projectId, documentId, value)
        ),
      referenceDocumentPasteAction: (
        projectId,
        activeItemToPaste,
        activeFolderPathToPasteIn,
        shouldValidateConflicts
      ) =>
        dispatch(
          referenceDocumentPasteAction(
            projectId,
            activeItemToPaste,
            activeFolderPathToPasteIn,
            shouldValidateConflicts
          )
        ),

      multipleItemActions: ({
        projectId,
        items,
        operation,
        destination,
        shouldValidateConflicts
      }) =>
        dispatch(
          multipleItemActions({
            projectId,
            items,
            operation,
            destination,
            shouldValidateConflicts
          })
        ),
      dispatchAllUploads: files => dispatch(dispatchAllUploads(files)),
      getTotalDocumentSize: projectId =>
        dispatch(getTotalDocumentSize(projectId))
    },
    modelFileAction: {
      setNewModelFiles: (newFiles, existingFiles) =>
        dispatch(setNewModelFiles(newFiles, existingFiles)),
      resetModelFile: () => dispatch(resetModelFile()),
      removeModelFile: model => dispatch(removeModelFile(model)),
      replaceModelFile: model => dispatch(replaceModelFile(model)),
      addModelFile: model => dispatch(addModelFile(model))
    },
    deleteProject: projectId => dispatch(deleteProject(projectId)),
    model2DFileAction: {
      setNew2DModelFiles: (newFiles, existingFiles) =>
        dispatch(setNew2DModelFiles(newFiles, existingFiles)),
      add2DModelFile: model2D => dispatch(add2DModelFile(model2D)),
      reset2DModelFile: () => dispatch(reset2DModelFile()),
      remove2DModelFile: model2D => dispatch(remove2DModelFile(model2D)),
      setPlanType: (model, type) => dispatch(setPlanType(model, type))
    },
    userPreferenceAction: {
      fetchAllUserPreference: () =>
        dispatch(fetchAllUserPreference('favorite_ruleset'))
    },
    projectPreferenceAction: {
      fetchProjectPreference: (projectId, type) =>
        dispatch(fetchProjectPreference(projectId, type))
      // removeFavoriteRule: rule => dispatch(removeFavoriteRulePreference(rule)),
      // resetFavoriteRuleToDefaults: rule => dispatch(resetUserPreference(rule)),
      // updateFavoriteRule: data => dispatch(updateUserPreference(data)),
      // resetFavoriteRule: rule => dispatch(resetUserPreference(rule))
    },
    fetchProjectAdditionalFields: () =>
      dispatch(fetchProjectAdditionalFields()),
    fetchProjectRelatedGroups: projectId =>
      dispatch(fetchProjectRelatedGroups(projectId)),
    fetchModelComparisonList: () => dispatch(fetchModelComparisonList()),
    fetchReportTemplateList: () => dispatch(fetchReportTemplateList()),
    deleteStage: (projectId, stageName) =>
      dispatch(deleteStage(projectId, stageName)),
    fetchProjectStageJobs: (
      projectId,
      stageName,
      jobType,
      filters,
      order,
      pagination
    ) =>
      dispatch(
        fetchProjectStageJobs(
          projectId,
          stageName,
          jobType,
          filters,
          order,
          pagination
        )
      ),
    fetchProjectThumbailSettings: () =>
      dispatch(fetchProjectThumbailSettings()),
    updateJobRuleCompleted: data => dispatch(updateJobRuleCompleted(data)),
    fetchAdminConfigSettings: () => dispatch(fetchAdminConfigSettings()),
    fetchAllowedProjectSizeConfig: projectId =>
      dispatch(fetchAllowedProjectSizeConfig(projectId))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProjectDetailPresenter);
