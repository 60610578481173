import React, { useEffect, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Alert,
  CardBody,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Button,
  CustomInput as ReactstrapCustomInput
} from 'reactstrap';
import { decode as decodeHTMLEntities } from 'he';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import prettyBytes from 'pretty-bytes';
import { connect, useDispatch } from 'react-redux';

import { dateFormatting } from '../../../locales/dateFormat';
import CustomInput from '../../UI/Input/customInput';
import Message from '../../UI/Message';

import CheckoutButton from './SharedUI/CheckoutButton';

import { currencyCodeToSign, defaultFeatureList } from './paymentUtils';

import './style.css';
import { getCustomerPortalAction } from '../../../store/actions';

const freePlan = {
  name: 'freePlan',
  metadata: {
    type: 'free',
    display_name: 'Free Plan',
    model_storage: 50000000
  },
  prices: [{ unit_amount: 0 }]
};

const SubscriptionActions = ({ t, currentSubscription }) => {
  // USEDISPATCH
  const dispatch = useDispatch();
  const getCustomerPortal = () => dispatch(getCustomerPortalAction());

  const redirectToCustomerPortal = async () => {
    try {
      const url = await getCustomerPortal();
      window.open(url, '_blank');
    } catch (err) {
      Message.error(err.message);
    }
  };

  return (
    <>
      {currentSubscription && (
        <div className="position-absolute bg-light w-100 current-subscription-actions billing-history-link-container">
          <Button color="link" onClick={redirectToCustomerPortal}>
            {t('subscription:see_billing_history')}
          </Button>
          {currentSubscription &&
            currentSubscription.latest_invoice &&
            currentSubscription.latest_invoice.invoice_pdf && (
              <Button
                color="link"
                tag="a"
                href={currentSubscription.latest_invoice.invoice_pdf}>
                {t('subscription:download_latest_invoice')}
              </Button>
            )}
        </div>
      )}
    </>
  );
};

const RenderFreePlanCard = ({
  product,
  t,
  selectPlan,
  selectedPlanName,
  currentPlanName,
  disabled,
  isCurrentPlan,
  currentSubscription,
  withActions = false
}) => {
  const {
    name,
    display_name,
    model_storage,
    computing_unit_monthly,
    price,
    priceId
  } = product;
  const isSelected = selectedPlanName === name;
  return (
    <Card
      onClick={() => {
        if (!disabled) {
          selectPlan(product);
        }
      }}
      className={classnames(
        'product shadow mb-4 mb-xl-0 customBorderRadius current-plan-card',
        {
          selected: isSelected,
          active: !disabled && !isCurrentPlan,
          'bg-primary text-white': isCurrentPlan,
          'text-muted': disabled
        }
      )}>
      <CardHeader className="card-header">
        <h4 className="font-weight-bold text-center ">
          {product.metadata.display_name}
        </h4>
      </CardHeader>
      <CardBody className="p-2 d-flex flex-column justify-content-center">
        <h5 className="font-weight-bold text-center">
          {prettyBytes(parseInt(product.metadata.model_storage))}
        </h5>
        {/* <hr />
            <h5 className="font-weight-bold text-center">
              {decodeHTMLEntities(currencyCodeToSign['SGD'])}
              {product.prices[0].unit_amount / 100}
            </h5>
            <div className="text-center">{t('subscription:per_month')}</div> */}
      </CardBody>
      {withActions && (
        <SubscriptionActions t={t} currentSubscription={currentSubscription} />
      )}
    </Card>
  );
};
const RenderDAPProductCard = ({
  product,
  t,
  selectPlan,
  selectedPlanName,
  currentPlanName,
  disabled,
  isCurrentPlan,
  currentSubscription,
  withActions = false
}) => {
  const {
    name,
    display_name,
    model_storage,
    computing_unit_monthly,
    price,
    priceId
  } = product;
  const isSelected = selectedPlanName === name;
  return (
    <Card
      onClick={() => {
        if (!disabled) {
          selectPlan(product);
        }
      }}
      className={classnames('product shadow mb-4 mb-xl-0 customBorderRadius', {
        selected: isSelected,
        active: !disabled && !isCurrentPlan,
        'bg-primary text-white': isCurrentPlan,
        'text-muted': disabled
      })}>
      <CardHeader className="card-header">
        <h4 className="font-weight-bold text-center ">
          {product.metadata.display_name}
        </h4>
      </CardHeader>
      <CardBody className="p-2">
        <div className="d-flex flex-column">
          <h5 className="font-weight-bold text-center">
            {prettyBytes(parseInt(product.metadata.model_storage))}
          </h5>
          <hr />
          <h5 className="font-weight-bold text-center">
            {decodeHTMLEntities(currencyCodeToSign['SGD'])}
            {product.prices[0].unit_amount / 100}
          </h5>
          <div className="text-center">{t('subscription:per_month')}</div>
        </div>
      </CardBody>
      {withActions && (
        <SubscriptionActions t={t} currentSubscription={currentSubscription} />
      )}
    </Card>
  );
};
const RenderDCPProductCard = ({
  product,
  t,
  selectPlan,
  selectedPlanName,
  currentPlanName,
  disabled,
  isCurrentPlan,
  currentSubscription,
  withActions = false
}) => {
  const {
    name,
    display_name,
    model_storage,
    computing_unit_monthly,
    price,
    priceId
  } = product;
  const isSelected = name === selectedPlanName;
  return (
    <Card
      onClick={() => {
        if (!disabled) {
          selectPlan(product);
        }
      }}
      className={classnames('product shadow mb-4 mb-xl-0 customBorderRadius', {
        selected: isSelected,
        active: !disabled && !isCurrentPlan,
        'bg-primary text-white': isCurrentPlan,
        'text-muted': disabled
      })}>
      <CardHeader className="card-header">
        <h4 className="font-weight-bold text-center ">
          {product.metadata.display_name}
        </h4>
      </CardHeader>
      <CardBody className={classnames('pt-1 p-2')}>
        <div className="d-flex flex-column">
          <h5 className="font-weight-bold text-center m-0">
            {prettyBytes(parseInt(product.metadata.model_storage))}
          </h5>
          <h5 className="font-weight-bold text-center m-0">+</h5>
          <h5 className="font-weight-bold text-center m-0">
            {product.metadata.computing_unit_monthly}
          </h5>
          <div className="text-center">{t('subscription:added_monthly')}</div>
          {/* <hr /> */}
          <h5 className="font-weight-bold text-center mt-4 mb-0">
            {decodeHTMLEntities(currencyCodeToSign['SGD'])}
            {product.prices[0].unit_amount / 100}
          </h5>
          <div className="text-center">{t('subscription:per_month')}</div>
        </div>
      </CardBody>
      {withActions && (
        <SubscriptionActions t={t} currentSubscription={currentSubscription} />
      )}
    </Card>
  );
};
const RenderCUAddonProductCard = ({
  product,
  t,
  selectAddon,
  selectedAddonName,
  disabled
}) => {
  const { name, display_name, price, priceId } = product;
  const isSelected = name === selectedAddonName;

  return (
    <Card
      onClick={() => selectAddon(product)}
      className={classnames('product shadow mb-4 mb-xl-0 customBorderRadius', {
        selected: isSelected,
        active: !disabled,
        'text-muted': disabled
      })}>
      <CardHeader className="card-header">
        <h4 className="font-weight-bold text-center ">
          {product.metadata.display_name}
        </h4>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-column">
          <hr />
          <h5 className="font-weight-bold text-center">
            {decodeHTMLEntities(currencyCodeToSign['SGD'])}
            {product.prices[0].unit_amount / 100}
          </h5>
        </div>
      </CardBody>
    </Card>
  );
};

const NewSubscriptionSetting = props => {
  // PROPS
  const {
    t,
    setSteps,
    currentStepIndex,
    steps,
    defaultCurrencyCode,

    subscriptionPlans,
    setCurrentPlan,
    setSubscriberInfo,
    setCurrentAddons,
    addons,
    plan,
    subscriberInfo,
    setCurrentStepIndex,
    getProducts,
    getCurrentSubscription,
    productGroups
  } = props;

  // LOCAL STATE
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState(null);
  const [addonVisible, setAddonVisible] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentProductPlan, setCurrentProductPlan] = useState(null);
  const [currentUsage, setCurrentUsage] = useState(null);

  // USEEFFECTS
  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        const [
          products,
          { subscription, usage, productPlan }
        ] = await Promise.all([getProducts(), getCurrentSubscription()]);
        setProducts(products);
        setCurrentSubscription(subscription);
        setCurrentProductPlan(productPlan);
        setCurrentUsage(usage);
        setIsLoaded(true);
      })();
    }
  }, [isLoaded]);

  // FORMIK CONFIG
  const formikConfig = props => {
    return {
      initialValues: {
        subscriber: subscriberInfo,
        plan: {},
        addons: {},
        selectedPlanName: null,
        selectedAddon: null
      },
      // TODO
      // validationSchema: '11',
      onSubmit: (values, { setSubmitting }) => {
        setSubscriberInfo(values.subscriber);
        setCurrentPlan(values.plan);
        setCurrentAddons(values.addons);
        const nextSteps = steps;
        if (nextSteps[currentStepIndex + 1]) {
          nextSteps[currentStepIndex + 1].isEnable = true;
          setSteps(nextSteps);
          setCurrentStepIndex(currentStepIndex + 1);
        }
      }
    };
  };

  const scrollToPlan = () =>
    document
      .getElementById('plan-selection')
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  const scrollToAddon = () => {
    const elementToSrollTo = document.getElementById('addon-selection');
    if (elementToSrollTo) {
      elementToSrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const scrollToSummary = () => {
    const elementToSrollTo = document.getElementById('summary');
    if (elementToSrollTo) {
      elementToSrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };
  const currentPlanName = currentProductPlan && currentProductPlan.name;
  return (
    !!isLoaded && (
      <Container fluid className="my-4 p-3 mb-5 rounded">
        <Formik
          enableReinitialize={false}
          {...formikConfig(props)}
          // validationSchema={currentValidationSchema}
        >
          {formikProps => {
            const { values, errors, setFieldValue, setValues } = formikProps;

            const selectPlan = plan => {
              if (
                values.selectedPlan &&
                plan.name === values.selectedPlan.name
              ) {
                setFieldValue('selectedPlan', null);
              } else {
                setFieldValue('selectedPlan', plan);
              }

              const isDowngrade = false;
              if (!isDowngrade) {
                setAddonVisible(true);
                scrollToAddon();
              } else {
                setAddonVisible(false);
              }
            };
            const selectAddon = addon => {
              if (
                values.selectedAddon &&
                addon.name === values.selectedAddon.name
              ) {
                setFieldValue('selectedAddon', null);
              } else {
                setFieldValue('selectedAddon', addon);
              }
              scrollToSummary();
            };
            const selectedPlanName =
              values.selectedPlan && values.selectedPlan.name;
            const selectedAddonName =
              values.selectedAddon && values.selectedAddon.name;

            // const selectedPlan = selectedPlanName &&

            const FreePlanGroup = () => {
              const eligible = true;
              return (
                <Container fluid>
                  <Row
                  // className="border border-thick border-primary p-3 w-100 h-100 justify-content-center"
                  // style={{ borderRadius: '2rem' }}
                  >
                    <Col>
                      <Container fluid>
                        <Row className="w-100 justify-content-center">
                          <Col sm={12} lg={4}>
                            <RenderFreePlanCard
                              product={freePlan}
                              selectPlan={selectPlan}
                              selectedPlanName={selectedPlanName}
                              t={t}
                              disabled={
                                currentPlanName === freePlan.name || !eligible
                              }
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Col>

                    <Col>
                      <div className="w-100 text-primary font-weight-bold mb-2">
                        {t('subscription:free_plan_group')}
                      </div>
                      <ul>
                        {defaultFeatureList.freePlan.map((entry, index) => {
                          return <li key={index}>{entry}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              );
            };
            const DAPGroup = () => {
              return (
                <Container fluid>
                  <Row
                  // className="border border-thick border-primary p-3 w-100 h-100 justify-content-center"
                  // style={{ borderRadius: '2rem' }}
                  >
                    <Col>
                      <Container fluid>
                        <Row className="w-100 justify-content-center">
                          {products
                            .filter(p => p.metadata.type === 'dap')
                            .sort((a, b) => a.metadata.order - b.metadata.order)
                            .map(product => {
                              const eligible =
                                product.metadata &&
                                !(
                                  parseInt(product.metadata.model_storage) <=
                                  currentUsage.used_model_storage +
                                    currentUsage.reserved_model_storage
                                );
                              return (
                                <Col
                                  sm={12}
                                  lg={4}
                                  key={`dap-product-${product.name}`}>
                                  <RenderDAPProductCard
                                    product={product}
                                    selectPlan={selectPlan}
                                    selectedPlanName={selectedPlanName}
                                    t={t}
                                    disabled={
                                      currentPlanName === product.name ||
                                      !eligible
                                    }
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </Container>
                    </Col>
                    <Col>
                      <div className="w-100 text-primary font-weight-bold mb-2">
                        {t('subscription:data_access_plan_group')}
                      </div>
                      <ul>
                        {defaultFeatureList.dap.map((entry, index) => {
                          return <li key={index}>{entry}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              );
            };
            const DCPGroup = () => {
              return (
                <Container fluid>
                  <Row
                  // className="border border-thick border-primary p-3 w-100 h-100 justify-content-center"
                  // style={{ borderRadius: '2rem' }}
                  >
                    <Col>
                      <Container fluid>
                        <Row className="w-100 justify-content-center">
                          {products
                            .filter(p => p.metadata.type === 'dcp')
                            .sort((a, b) => a.metadata.order - b.metadata.order)
                            .map(product => {
                              const eligible =
                                product.metadata &&
                                !(
                                  parseInt(product.metadata.model_storage) <=
                                  currentUsage.used_model_storage +
                                    currentUsage.reserved_model_storage
                                );
                              return (
                                <Col
                                  sm={12}
                                  lg={4}
                                  key={`dcp-product-${product.name}`}>
                                  <RenderDCPProductCard
                                    product={product}
                                    selectPlan={selectPlan}
                                    selectedPlanName={selectedPlanName}
                                    t={t}
                                    disabled={
                                      currentPlanName === product.name ||
                                      !eligible
                                    }
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </Container>
                    </Col>

                    <Col>
                      <div className="w-100 text-primary font-weight-bold mb-2">
                        {t('subscription:data_access_and_computing_plan_group')}
                      </div>
                      <ul>
                        {defaultFeatureList.dcp.map((entry, index) => {
                          return <li key={index}>{entry}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              );
            };
            const CUADdonGroup = () => {
              return (
                <Container fluid>
                  <Row
                    // className="border border-thick border-primary p-3 w-100 justify-content-center"
                    className="row-border-radius">
                    <Col>
                      <Container fluid>
                        <Row className="w-100 justify-content-center">
                          {products
                            .filter(p => p.metadata.type === 'cu_addon')
                            .sort((a, b) => a.metadata.order - b.metadata.order)
                            .map(product => {
                              return (
                                <Col
                                  sm={12}
                                  lg={4}
                                  key={`cu-addon-product-${product.name}`}>
                                  <RenderCUAddonProductCard
                                    product={product}
                                    selectAddon={selectAddon}
                                    selectedAddonName={selectedAddonName}
                                    t={t}
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </Container>
                    </Col>
                    <Col>
                      <div className="w-100 text-primary font-weight-bold mb-2">
                        {t('subscription:computing_unit_addon_group')}
                      </div>
                      <ul>
                        {defaultFeatureList.cu_addon.map((entry, index) => {
                          return <li key={index}>{entry}</li>;
                        })}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              );
            };

            return (
              <Form>
                <div className="py-2 px-2">
                  {/* ##################### CURRENT SUBSCRIPTION ################################# */}
                  <Row className="border border-thick border-primary px-3 pb-3 pt-1 w-100 justify-content-start mb-2 row-border-radius">
                    <Row>
                      <Col>
                        <h5 className="text-primary font-weight-bold">
                          {t('subscription:current_subscription')}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="w-100">
                      <Col sm={6} md={4} lg={2}>
                        {currentProductPlan &&
                          currentProductPlan.metadata.type === 'dap' && (
                            <RenderDAPProductCard
                              product={currentProductPlan}
                              selectedPlanName={selectedPlanName}
                              isCurrentPlan
                              disabled={false}
                              t={t}
                              selectPlan={() => {}}
                              currentSubscription={currentSubscription}
                              withActions
                            />
                          )}
                        {currentProductPlan &&
                          currentProductPlan.metadata.type === 'dcp' && (
                            <RenderDCPProductCard
                              product={currentProductPlan}
                              selectedPlanName={selectedPlanName}
                              isCurrentPlan
                              disabled={false}
                              t={t}
                              selectPlan={() => {}}
                              currentSubscription={currentSubscription}
                              withActions
                            />
                          )}
                        {currentProductPlan &&
                          currentProductPlan.metadata.type === 'free' && (
                            <RenderFreePlanCard
                              product={currentProductPlan}
                              selectedPlanName={selectedPlanName}
                              iCurrentPlan
                              disabled={false}
                              t={t}
                              selectPlan={() => {}}
                              currentSubscription={currentSubscription}
                              withActions
                            />
                          )}
                      </Col>
                      <Col>
                        <Container fluid>
                          <Row className="pt-4">
                            <Col>
                              <h6 className="d-flex flex-direction-row justify-content-center">
                                <div className="flex-grow-1">
                                  {t('subscription:data_available_by_plan')}
                                </div>
                                <div className="font-weight-bold flex-grow-0">
                                  {prettyBytes(
                                    currentUsage.total_model_storage
                                  )}
                                </div>
                              </h6>
                              <hr />
                              <h6 className="d-flex flex-direction-row justify-content-center">
                                <div className=" flex-grow-1">
                                  {t('subscription:data_used')}
                                </div>
                                <div className="font-weight-bold">
                                  {prettyBytes(currentUsage.used_model_storage)}
                                </div>
                              </h6>
                              <hr />
                              <h6 className="d-flex flex-direction-row justify-content-center">
                                <div className="flex-grow-1">
                                  {t('subscription:data_current_balance')}
                                </div>
                                <div className="font-weight-bold flex-grow-0">
                                  {prettyBytes(
                                    currentUsage.available_model_storage
                                  )}
                                </div>
                              </h6>
                            </Col>
                            <Col className="justify-content-center">
                              <h6 className="d-flex flex-direction-row justify-content-center">
                                <div className="flex-grow-1">
                                  {t(
                                    'subscription:computing_unit_added_monthly'
                                  )}
                                </div>
                                <div className="font-weight-bold">
                                  {currentProductPlan.metadata
                                    .computing_unit_monthly || 0}
                                </div>
                              </h6>
                              <hr />
                              <div className="d-flex flex-direction-row justify-content-center">
                                <div className="flex-grow-1">
                                  {t('subscription:computing_unit_balance')}
                                </div>
                                <div className="font-weight-bold">
                                  {currentUsage.available_cu}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {currentSubscription &&
                            currentSubscription.cancel_at_period_end && (
                              <Row className="pt-2">
                                <Col>
                                  <div className="d-flex justify-content-center text-danger">
                                    {t('subscription:subscription_will_end')}:{' '}
                                    {dateFormatting(
                                      currentSubscription.cancel_at * 1000
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            )}
                        </Container>
                      </Col>
                    </Row>
                  </Row>

                  {/* ##################### CHANGE PLAN ################################# */}
                  <Row className="border border-thick border-primary border-bottom-0 px-3 pb-3 pt-1 w-100 justify-content-center change-plan-container">
                    <div id="plan-selection"> </div>
                    <Row className="w-100">
                      <Col>
                        <h5 className="text-primary font-weight-bold">
                          {t('subscription:change_plan')}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="w-100">
                      <Col>
                        <FreePlanGroup />
                        <hr className="border-primary" />
                        <DAPGroup />
                        <hr className="border-primary" />
                        <DCPGroup />
                      </Col>
                    </Row>
                  </Row>
                  <Row
                    className={classnames(
                      'border border-thick border-primary p-3 w-100 justify-content-center mt-2 addon-section row-border-radius',
                      { 'addon-visible': addonVisible }
                    )}>
                    <div id="addon-selection"> </div>
                    <Row className="w-100">
                      <Col>
                        <h5 className="text-primary font-weight-bold">
                          {t('subscription:add_computing_units')}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="w-100">
                      <Col>
                        <CUADdonGroup />
                      </Col>
                    </Row>
                  </Row>
                </div>
                {/* <hr /> */}
                {/* ##################### SUMMARY  ################################# */}
                {(values.selectedPlan || values.selectedAddon) && (
                  <>
                    <Row className="justify-content-center w-100">
                      <Col xl={6}>
                        <Container fluid>
                          <div id="summary"> </div>
                          {/* <Row
                            className="border border-thick border-primary p-3 mb-3 w-100 customBorderRadius"
                            // style={{ borderRadius: '2rem' }}
                          >
                            <Col>
                              Congratulations, this is your new subscription
                              plan effective: 28 October 2020
                            </Col>
                            <Col sm={12} lg={4}>
                              {values.selectedPlan &&
                                values.selectedPlan.metadata.type === 'dap' && (
                                  <RenderDAPProductCard
                                    product={values.selectedPlan}
                                    selectedPlanName={selectedPlanName}
                                    t={t}
                                    selectPlan={() => {}}
                                  />
                                )}
                              {values.selectedPlan &&
                                values.selectedPlan.metadata.type === 'dcp' && (
                                  <RenderDCPProductCard
                                    product={values.selectedPlan}
                                    selectedPlanName={selectedPlanName}
                                    t={t}
                                    selectPlan={() => {}}
                                  />
                                )}
                              {values.selectedPlan &&
                                values.selectedPlan.metadata.type ===
                                  'free' && (
                                  <RenderFreePlanCard
                                    product={values.selectedPlan}
                                    selectedPlanName={selectedPlanName}
                                    t={t}
                                    selectPlan={() => {}}
                                  />
                                )}
                            </Col>
                          </Row> */}
                          <Row
                            className="bg-primary text-white p-3 mb-3 w-100 font-weight-bold customBorderRadius"
                            // style={{ borderRadius: '2rem' }}
                          >
                            <Col>
                              {values.selectedPlan && (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      {
                                        values.selectedPlan.metadata
                                          .display_name
                                      }{' '}
                                      ({t('subscription:recurring')})
                                    </div>
                                    <div>
                                      SG$
                                      {(
                                        values.selectedPlan.prices[0]
                                          .unit_amount / 100
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                  <hr className="border-light" />
                                </>
                              )}
                              {values.selectedAddon && (
                                <div className="d-flex justify-content-between">
                                  <div>
                                    {values.selectedAddon.metadata.display_name}{' '}
                                  </div>
                                  <div>
                                    SG$
                                    {(
                                      values.selectedAddon.prices[0]
                                        .unit_amount / 100
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              )}
                              {/* 
                              <div className="d-flex justify-content-between">
                                <h3 className="font-weight-bold">
                                  {t('subscription:total_cost_due')}
                                </h3>
                                <h3 className="font-weight-bold">
                                  SG$
                                  {(
                                    values.selectedPlan.prices[0].unit_amount /
                                      100 +
                                    (values.selectedAddon &&
                                    values.selectedAddon.prices[0].unit_amount
                                      ? values.selectedAddon.prices[0]
                                          .unit_amount / 100
                                      : 0)
                                  ).toFixed(2)}
                                </h3>
                              </div> */}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row className="justify-content-center w-100 mb-3">
                      <Col xl={6}>
                        <Container fluid>
                          <Row className="w-100">
                            <Col className="p-0">
                              <CheckoutButton
                                selectedAddon={values.selectedAddon}
                                selectedPlan={values.selectedPlan}
                                currentPlan={currentProductPlan}
                                currentSubscription={currentSubscription}
                                setCurrentSubscription={setCurrentSubscription}
                                setCurrentUsage={setCurrentUsage}
                                setCurrentProductPlan={setCurrentProductPlan}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Container>
    )
  );
};

export default NewSubscriptionSetting;
