import { ruleHistoryAdminActionType } from '../../constants/actionType';
import { merge } from 'lodash';
const initialState = {};

const { MERGE_RULE_HISTORY_ADMIN } = ruleHistoryAdminActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MERGE_RULE_HISTORY_ADMIN:
      // console.log(merge(state, action.rule));
      // console.log({
      //   ...state,
      //   rule: action.rule
      // });
      return merge(state, action.rule);
    default:
      return state;
  }
};

export default reducer;
