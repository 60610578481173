import React, { useState, useRef, useEffect } from 'react';
import { NavLink as BootstrapNavLink, Row, Col } from 'reactstrap';
import backgroundImage from '../../assets/images/FXC_MAP_HD.jpg';
// import backgroundImage from '../../assets/images/FXC_MAP_HD.jpg';
import HexagonBtn from './HexagonBtn';
import SmallBtn from './SmallBtn';
import { NavLink } from 'react-router-dom';
import config from '../config/landingPageConfig';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

const fornax = config.fornax;

const FornaxSection = ({ scrollToActiveRow, setActiveNavLink, t }) => {
  const [activeHexButtonIndex, setActiveHexButtonIndex] = useState(null);
  const { hexagonButtons, defaultContent } = fornax;
  const divRef = useRef();
  useEffect(() => {
    divRef.current.style.backgroundImage = `url(${backgroundImage})`;
  }, []);
  return (
    <div className="fornax-background-image text-white" ref={divRef}>
      <Row className="h-100">
        <Col xs={12} xl={2} lg={12} md={12} className="fornax-col-1">
          <div className="hex-container">
            <div className="hex-image-container">
              {hexagonButtons.map((button, index) => {
                return (
                  <HexagonBtn
                    {...button}
                    hexIndex={index}
                    setActiveHexButtonIndex={setActiveHexButtonIndex}
                    key={`hexagon-btn-${index}`}
                    activeHexButtonIndex={activeHexButtonIndex}
                    t={t}
                  />
                );
              })}
            </div>
          </div>
        </Col>
        <Col xs={12} xl={8} lg={12} md={12} className="fornax-col-2">
          <ContentContainer
            activeHexButtonIndex={activeHexButtonIndex}
            title={
              (hexagonButtons[activeHexButtonIndex] &&
                hexagonButtons[activeHexButtonIndex].title) ||
              defaultContent.title
            }
            subTitle={
              (hexagonButtons[activeHexButtonIndex] &&
                hexagonButtons[activeHexButtonIndex].subTitle) ||
              defaultContent.subTitle
            }
            scrollToActiveRow={scrollToActiveRow}
            setActiveNavLink={setActiveNavLink}
            t={t}
            scrollToActiveRow={scrollToActiveRow}
            setActiveNavLink={setActiveNavLink}
          />
        </Col>

        <Col xs={12} xl={2} lg={12} md={12} className="fornax-col-3">
          <div className="small-hex-container">
            <div className="d-flex justify-content-center text-center p-2">
              <BootstrapNavLink
                onClick={() => {
                  scrollToActiveRow();
                  setActiveNavLink('solutions');
                }}
                className="custom-btn text-white rounded-pill py-3"
                tag={NavLink}
                to="/">
                {t(`front:findOutMore`)}
              </BootstrapNavLink>
            </div>
            <div className="d-flex justify-content-center align-items-end">
              <Row>
                {hexagonButtons.map((btn, index) => {
                  return (
                    <Col className="text-center">
                      <SmallBtn
                        setActiveHexButtonIndex={setActiveHexButtonIndex}
                        index={index}
                        btnData={btn}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ContentContainer = ({
  activeHexButtonIndex,
  title,
  subTitle,
  t,
  scrollToActiveRow,
  setActiveNavLink
}) => {
  const {
    defaultContent: { title1, title2, title3 }
  } = fornax;

  return (
    <Row className="h-100">
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="content-container-col-1">
        <div
          className={`h-100 ${
            activeHexButtonIndex === null
              ? 'fornax-content-fade-in-default'
              : ''
          }`}>
          <div className="d-flex align-items-end justify-content-center h-100">
            <div
              className={`text-center fornax-content-fade-in-${activeHexButtonIndex}`}>
              {activeHexButtonIndex !== null ? (
                <h4 className="fornax-content-header">
                  <strong>{t(`front:${title}`)}</strong>
                </h4>
              ) : (
                <h4 className="fornax-content-header">
                  <div>
                    <strong>{t(`front:${title1}`)}</strong>
                  </div>
                  <div>
                    <strong>{t(`front:${title2}`)}</strong>
                  </div>
                  <div>
                    <strong>{t(`front:${title3}`)}</strong>
                  </div>
                </h4>
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="content-container-col-2">
        <div
          className={`h-100 ${
            activeHexButtonIndex === null
              ? 'fornax-content-fade-in-default'
              : ''
          }`}>
          <hr className="fornax-custom-hr" />
          <div className="text-center landing-page-subtitle-text">
            <p className="text-faded mx-2">{t(`front:${subTitle}`)}</p>
          </div>
        </div>
      </Col>

      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="content-container-col-3 d-flex justify-content-center text-center">
        <div className="custom-btn-find-more">
          <BootstrapNavLink
            onClick={() => {
              scrollToActiveRow();
              setActiveNavLink('solutions');
            }}
            className="custom-btn text-white rounded-pill py-3"
            tag={NavLink}
            to="/">
            {t(`front:findOutMore`)}
          </BootstrapNavLink>
        </div>
      </Col>
    </Row>
  );
};
export default withTranslation(['front'])(FornaxSection);
