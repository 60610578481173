import React, { useEffect, useState } from 'react';
import marked from 'marked';
import { Container } from 'reactstrap';
import markdownFile from './dist/faq.md';

import './style.css';


const Faq = () => {
  console.log(faqContent);
  const [faqContent, setFaqContent] = useState('');

  useEffect(() => {
    fetch(markdownFile)
      .then(response => response.text())
      .then(text => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        console.log(text);
        setFaqContent(marked(text));
      });
  });
  // return <ReactMarkdown source={faqContent}
  //   renderers={{'heading': HeadingRenderer}}/>;

  return (
    <Container>
      <article dangerouslySetInnerHTML={{ __html: faqContent }}></article>
    </Container>
  );
};

export default Faq;
