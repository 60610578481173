import React, { Component, useRef, useEffect } from 'react';
import {
  Container,
  Row,
  CustomInput,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import uuid from 'uuid/v4';
import classnames from 'classnames';
import styles from './styles.module.css';

const OptionSourceComponent = (props) => {
  const optionRef = useRef()
  const {renderItem, item, optionStyle, disabled} = props;

  useEffect(() => {
      Object.keys(optionStyle).forEach((res) => {
        optionRef.current.style[res] = optionStyle[res];
      })
  }, [disabled])
  
  return (
    <option
      ref={optionRef}
      key={`source-item-${item.key}`}
      id={`source-item-${item.key}`}
      disabled={disabled}
      value={item.key}
      style={{ ...optionStyle }}
      label={renderItem(item)}>
      {renderItem(item)}
    </option>
  );
}

const OptionTargetComponent = (props) => {
  const optionRef = useRef()
  const {renderItem, item, optionStyle, disabled} = props;
  useEffect(() => {
    Object.keys(optionStyle).forEach((res) => {
      optionRef.current.style[res] = optionStyle[res];
    })
  }, [disabled])
  return (
    <option
      ref={optionRef}
      key={`target-item-${item.key}`}
      id={`target-item-${item.key}`}
      disabled={disabled}
      value={item.key}
      style={{ ...optionStyle }}
      label={renderItem(item)}>
      {renderItem(item)}
    </option>
  );
}

const CheckboxSourceComponent = (props) => {
  const optionRef = useRef()
  const {renderItem, item, optionStyle, disabled} = props;

  useEffect(() => {
    Object.keys(optionStyle).forEach((res) => {
      optionRef.current.style[res] = optionStyle[res];
    })
  }, [disabled])

  return (
    <option
      ref={optionRef}
      key={`source-item-${item.key}`}
      id={`source-item-${item.key}`}
      type="checkbox"
      style={{ ...optionStyle }}
      disabled={disabled}
      value={item.key}
      label={renderItem(item)}>
      asd{renderItem(item)}
    </option>
  );
}

const CheckboxTargetComponent = (props) => {
  const optionRef = useRef()
  const {renderItem, item, optionStyle, disabled} = props;

  useEffect(() => {
    Object.keys(optionStyle).forEach((res) => {
      optionRef.current.style[res] = optionStyle[res];
    })
  }, [disabled])

  return (
    <option
      ref={optionRef}
      key={`target-item-${item.key}`}
      id={`target-item-${item.key}`}
      type="checkbox"
      disabled={disabled}
      style={{ ...optionStyle }}
      value={item.key}
      label={renderItem(item)}>
      asd{renderItem(item)}
    </option>
  );
}

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: props.data
        .map(s => s.key)
        .filter(k => !props.targetKeys.includes(k)),
      target: props.targetKeys,
      selectedKeys: props.selectedKeys || { source: [], target: [] },
      inputValue: '',
      zone: ''
    };
    this.namespace = uuid();
  }
  onSelectChange = (zone, value) => {
    this.setState(prevState => ({
      selectedKeys: {
        ...prevState.selectedKeys,
        [zone]: value
      }
    }));
  };
  // onSelectChange = (zone, key, value) => {
  //   if (value) {
  //     this.setState(prevState => ({
  //       selectedKeys: {
  //         ...prevState.selectedKeys,
  //         [zone]: Array.from(new Set(prevState.selectedKeys[zone]).add(key))
  //       }
  //     }));
  //   } else {
  //     this.setState(prevState => {
  //       const nextState = new Set(prevState.selectedKeys[zone]);
  //       nextState.delete(key);
  //       return {
  //         selectedKeys: {
  //           ...prevState.selectedKeys,
  //           [zone]: Array.from(nextState)
  //         }
  //       };
  //     });
  //   }
  // };

  onChange = direction => {
    if (direction === 'left') {
      const movedKeys = this.state.selectedKeys.target;
      const targetSet = new Set(this.state.target);

      movedKeys.forEach(key => targetSet.delete(key));
      const nextTargetKeys = Array.from(targetSet);
      const nextSourceKeys = Array.from([...this.state.source, ...movedKeys]);
      this.setState(prevState => ({
        source: nextSourceKeys,
        target: nextTargetKeys,
        selectedKeys: { target: [], source: prevState.selectedKeys.source }
      }));
      this.props.onChange(nextTargetKeys, direction, movedKeys);
    } else {
      const movedKeys = this.state.selectedKeys.source;
      const sourceSet = new Set(this.state.source);

      movedKeys.forEach(key => sourceSet.delete(key));
      const nextSourceKeys = Array.from(sourceSet);
      const nextTargetKeys = Array.from([...this.state.target, ...movedKeys]);
      this.setState(prevState => ({
        source: nextSourceKeys,
        target: nextTargetKeys,
        selectedKeys: { target: prevState.selectedKeys.target, source: [] }
      }));
      this.props.onChange(nextTargetKeys, direction, movedKeys);
    }
  };

  filterData = (event, zone) => {
    this.setState({ inputValue: event.target.value, zone: zone });
  };

  CheckBox = ({ id, label, onChange, value, disabled }) => (
    <InputGroup>
      <CustomInput
        type="checkbox"
        id={`${this.namespace}-${id}`}
        onChange={onChange}
        checked={value}
        label={label}
        disabled={disabled}
      />
    </InputGroup>
  );

  render() {
    const {
      data,
      disabled,
      renderItem,
      withHeader,
      maxHeight = '300px',
      // onSelectChange
      touched,
      errors,
      setFieldTouched,
      optionStyle = {}
    } = this.props;

    const { source, target, selectedKeys, inputValue, zone } = this.state;
    const CheckBox = this.CheckBox;

    const { sourceItems, targetItems } =
      inputValue === ''
        ? data.reduce(
          (previous, item) => {
            return !target.includes(item.key)
              ? {
                sourceItems: [...previous.sourceItems, item],
                targetItems: previous.targetItems
              }
              : {
                targetItems: [...previous.targetItems, item],
                sourceItems: previous.sourceItems
              };
          },
          { sourceItems: [], targetItems: [] }
        )
        : data.reduce(
          (previous, item) => {
            return !target.includes(item.key)
              ? {
                sourceItems:
                      zone === 'source'
                        ? [...previous.sourceItems, item].filter(
                          item =>
                            renderItem(item) &&
                              renderItem(item)
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                        )
                        : [...previous.sourceItems, item],
                targetItems: previous.targetItems
              }
              : {
                targetItems:
                      zone === 'target'
                        ? [...previous.targetItems, item].filter(
                          item =>
                            renderItem(item) &&
                              renderItem(item)
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                        )
                        : [...previous.targetItems, item],
                sourceItems: previous.sourceItems
              };
          },
          { sourceItems: [], targetItems: [] }
        );

    if (withHeader) {
      const headers = data.filter(d => d.header);
      const sourceHeaders = sourceItems.reduce((previous, item) => {
        if (item.headerKey) {
          return Array.from(new Set([...previous, item.headerKey]));
        }
        return previous;
      }, []);
      const targetHeaders = targetItems.reduce((previous, item) => {
        if (item.headerKey) {
          return Array.from(new Set([...previous, item.headerKey]));
        }
        return previous;
      }, []);

      return (
        <Container>
          <Row className="">
            <div
              className={`rounded flex-grow-1 ${styles.transferDivParent}`}>
              <div className="h-100 d-flex flex-column border rounded">
                <InputGroup className="mb-2 p-1 w-100">
                  {/* <InputGroupAddon addonType="prepend" />
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText> */}
                  <Input
                    type="search"
                    onChange={event => this.filterData(event, 'source')}
                    placeholder="Filter"
                    size="sm"
                  />
                </InputGroup>
                <Input
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti"
                  className={`flex-grow-1 ${styles.transferInputShadow}`}
                  multiple
                  onChange={e => {
                    const options = e.target.options;
                    const value = [];
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    this.onSelectChange('source', value);
                  }}>
                  {sourceHeaders.map(headerKey => {
                    return (
                      <optgroup
                        key={`source-header-${headerKey}`}
                        label={`${
                          headers.find(h => h.key === headerKey).header
                        }:`}>
                        {sourceItems
                          .filter(item => item.headerKey === headerKey)
                          .map(item => {
                            return (
                              <OptionSourceComponent
                                item={item}
                                disabled={disabled}
                                renderItem={renderItem}
                                optionStyle={optionStyle}
                              />
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </Input>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center mx-2">
              <Button
                color="primary"
                outline
                className="mb-2"
                disabled={disabled || selectedKeys.source.length === 0}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onChange('right');
                  if (setFieldTouched) {
                    setFieldTouched();
                  }
                }}>
                <i className="fas fa-chevron-right"></i>
              </Button>
              <Button
                color="primary"
                outline
                disabled={disabled || selectedKeys.target.length === 0}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onChange('left');
                  if (setFieldTouched) {
                    setFieldTouched();
                  }
                }}>
                <i className="fas fa-chevron-left"></i>
              </Button>
            </div>
            <div
              className={classnames('flex-grow-1', {
                'border border-danger': errors ? true : false
              }, styles.transferDivParent)}
              >
              <div className="h-100 d-flex flex-column border rounded">
                <InputGroup className="mb-2 p-1 w-100">
                  {/* <InputGroupAddon addonType="prepend" />
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText> */}
                  <Input
                    type="search"
                    onChange={event => this.filterData(event, 'target')}
                    placeholder="Filter"
                    size="sm"
                  />
                </InputGroup>
                <Input
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti"
                  className={`flex-grow-1 ${styles.transferInputShadow}`}
                  multiple
                  onChange={e => {
                    const options = e.target.options;
                    const value = [];
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    this.onSelectChange('target', value);
                  }}>
                  {targetHeaders.map(headerKey => {
                    return (
                      <optgroup
                        key={`target-header-${headerKey}`}
                        label={`${
                          headers.find(h => h.key === headerKey).header
                        }:`}>
                        {targetItems
                          .filter(item => item.headerKey === headerKey)
                          .map(item => {
                            return (
                              <OptionTargetComponent
                                item={item}
                                disabled={disabled}
                                renderItem={renderItem}
                                optionStyle={optionStyle} />
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </Input>
              </div>
            </div>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row className="">
            <div
              className={`flex-grow-1 ${styles.transferDivParent}`}>
              <div className="h-100 d-flex flex-column border rounded">
                <InputGroup className="mb-2 p-1 w-100">
                  {/* <InputGroupAddon addonType="prepend" />
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText> */}
                  <Input
                    type="search"
                    onChange={event => this.filterData(event, 'source')}
                    placeholder="Filter"
                    size="sm"
                  />
                </InputGroup>
                <Input
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti"
                  className={`flex-grow-1 ${styles.transferInputShadow}`}
                  multiple
                  onChange={e => {
                    const options = e.target.options;
                    const value = [];
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    this.onSelectChange('source', value);
                  }}>
                  {sourceItems.map(item => {
                    return (
                      <CheckboxSourceComponent
                        item={item}
                        disabled={disabled}
                        renderItem={renderItem}
                        optionStyle={optionStyle} />
                      
                    );
                  })}
                </Input>

              </div>
            </div>
            <div className="d-flex flex-column justify-content-center mx-2">
              <Button
                color="primary"
                outline
                className="mb-2"
                disabled={disabled || selectedKeys.source.length === 0}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onChange('right');
                  if (setFieldTouched) {
                    setFieldTouched();
                  }
                }}>
                <i className="fas fa-chevron-right"></i>
              </Button>
              <Button
                color="primary"
                outline
                disabled={disabled || selectedKeys.target.length === 0}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onChange('left');
                  if (setFieldTouched) {
                    setFieldTouched();
                  }
                }}>
                <i className="fas fa-chevron-left"></i>
              </Button>
            </div>
            <div
              className={classnames('flex-grow-1', {
                'border border-danger': touched && errors ? true : false
              }, styles.transferDivParent)}
              >
              <div className="h-100 d-flex flex-column border rounded">
                <InputGroup className="mb-2 p-1 w-100">
                  {/* <InputGroupAddon addonType="prepend" />
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText> */}
                  <Input
                    type="search"
                    onChange={event => this.filterData(event, 'target')}
                    placeholder="Filter"
                    size="sm"
                  />
                </InputGroup>
                <Input
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti"
                  className={`flex-grow-1 ${styles.transferInputShadow}`}
                  multiple
                  onChange={e => {
                    const options = e.target.options;
                    const value = [];
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    this.onSelectChange('target', value);
                  }}>
                  {targetItems.map(item => {
                    return (
                      <CheckboxTargetComponent
                        item={item}
                        disabled={disabled}
                        renderItem={renderItem}
                        optionStyle={optionStyle} />
                    );
                  })}
                </Input>
              </div>
            </div>
          </Row>
        </Container>
      );
    }
  }
}

export default Transfer;
