import React, { PureComponent } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Message from '../../UI/Message';
import ModalDeleteStageConfirmation from './StageDeletion/ModalDeleteStageConfirmation';

class stageList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isStageDeleting: false,
      isModalDeleteStageConfirmationOpen: false,
      isModalStageDeletionOpen: false,
      activeStageToDelete: ''
    };
  }

  setStageToDelete = stageName => {
    this.setState({
      activeStageToDelete: stageName
    });
  };

  toggleModalStageDeletion = value => {
    this.setState({
      isModalStageDeletionOpen: !this.state.isModalStageDeletionOpen
    });
  };

  toggleModalDeleteStageConfirmation = value => {
    this.setState({
      isModalDeleteStageConfirmationOpen: !this.state
        .isModalDeleteStageConfirmationOpen
    });
  };

  render() {
    const { t, project, deleteStage } = this.props;

    const {
      isStageDeleting,
      activeStageToDelete,
      isModalDeleteStageConfirmationOpen,
      isModalStageDeletionOpen
    } = this.state;

    return (
      <div>
        <ListGroup>
          {project.stagesavailable.map((stage, index) => {
            const isStageDeletable = !stage.is_default;
            return (
              <ListGroupItem key={`stage-${index}`}>
                <Row>
                  <Col sm={8}>
                    <p className="font-weight-bold">{stage.display_name}</p>
                  </Col>

                  <Col className="d-flex justify-content-end">
                    <div
                      id={`deleteStage-${index}`}
                      className="mr-2"
                      onClick={async () => {
                        if (isStageDeletable && !isStageDeleting) {
                          this.setStageToDelete(stage.name);
                          this.toggleModalDeleteStageConfirmation();
                        }
                      }}>
                      <i
                        className={
                          isStageDeletable && !isStageDeleting
                            ? 'pointer fas fa-trash-alt fs-1-2x text-danger'
                            : 'fas fa-trash-alt fs-1-2x text-secondary'
                        }
                      />
                    </div>
                    <UncontrolledTooltip
                      boundariesElement="window"
                      fade={false}
                      target={`deleteStage-${index}`}
                      modifiers={{
                        computeStyle: { gpuAcceleration: false }
                      }}>
                      {isStageDeletable && !isStageDeleting
                        ? t('delete')
                        : t('disabled')}
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
        <ModalDeleteStageConfirmation
          {...this.props}
          projectId={project.project_id}
          stageToDelete={activeStageToDelete}
          isOpen={isModalDeleteStageConfirmationOpen}
          toggleModalStageDeletion={this.toggleModalStageDeletion}
          toggleModalDeleteStageConfirmation={
            this.toggleModalDeleteStageConfirmation
          }
        />
      </div>
    );
  }
}

export default compose(withTranslation(['project', 'main']))(stageList);
