import React from 'react';
import { Alert, Container, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.css';

const SuccedMessage = props => {
  const { t } = props;
  return (
    <Container>
      <div className="mt-5">
        <Alert color="light" className="text-center pt-5">
          <i className="far fa-check-circle text-success checkCircleIcon" />
          <h1 className="alert-heading text-primary pt-4">{t('success')}</h1>
          <hr />
          <Link to={'/login'}>
            <Button>{t('go_to_login')}</Button>
          </Link>
        </Alert>
      </div>
    </Container>
  );
};

export default withTranslation(['forgotPassword'])(SuccedMessage);
