import { projectActionType } from '../../constants/actionType';
const initialState = {};

const {
  MERGE_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  REPLACE_PROJECTS,
  ADD_STAGE_AVAILABLE,
  RESET_PROJECTS,
  ADD_PROJECT_NOTIFICATIONS,
  REMOVE_PROJECT_STAGE
} = projectActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MERGE_PROJECTS:
      return { ...state, ...action.projectMap };
    case ADD_PROJECT: {
      const { project, projectId } = action;
      return { ...state, [projectId]: { ...state[projectId], ...project } };
    }
    case UPDATE_PROJECT: {
      const { project, projectId } = action;
      return { ...state, [projectId]: project };
    }
    case REPLACE_PROJECTS:
      return action.projectMap;
    case ADD_STAGE_AVAILABLE: {
      const { projectId, newStage } = action;
      state[projectId].stagesavailable.push(newStage);
      return { ...state };
    }
    case ADD_PROJECT_NOTIFICATIONS: {
      const { projectId, notificationList } = action;
      if (state[projectId]) {
        const nextNotif = [
          ...(state[projectId].notifications || []),
          ...notificationList
        ];
        return {
          ...state,
          [projectId]: { ...state[projectId], notifications: nextNotif }
        };
      }
      return { ...state };
    }
    case RESET_PROJECTS: {
      return initialState;
    }
    case REMOVE_PROJECT_STAGE: {
      const { projectId, stageName } = action;
      const nextStages = state[projectId].stagesavailable.filter(stage => {
        return stage.name !== stageName;
      });

      return {
        ...state,
        [projectId]: { ...state[projectId], stagesavailable: nextStages }
      };
    }
    default:
      return state;
  }
};

export default reducer;
