import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';

import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';

import CustomInput from '../../../UI/Input/customInput';
import Message from '../../../UI/Message';

import { inviteUserToOrganization } from '../../../../store/actions';

const InviteToJoinComponent = ({
  t,
  toggle,
  inviteUserToOrganization,
  onSubmitSuccess
}) => {
  const modalFormikConfig = {
    initialValues: { emails: '' },
    // validationSchema:,

    validate: values => {
      const schema = Yup.object().shape({
        emails: Yup.array()
          .transform((value, original) => {
            return original.split(';').map(email => email.trim());
          })
          .of(
            Yup.string()
              // .required()
              .email(t('validation:invalid_email'))
          )
      });
      try {
        schema.validateSync(values, { abortEarly: true });
      } catch (err) {
        if (err.path.startsWith('emails')) {
          return { emails: err.message };
        }
      }
    },

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const emails = values.emails
        .split(';')
        .map(e => e.trim().toLowerCase())
        .filter(e => !!e);
      try {
        await inviteUserToOrganization(emails);
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
      } catch (err) {
        // console.log({ err });
      }
      setSubmitting(false);
    }
  };
  return (
    <Container>
      <div className="p-3">
        <Row className="px-3">
          <h2 className="text-primary">{t('organization:invite_to_join')}</h2>
        </Row>
        <Formik {...modalFormikConfig}>
          {formikProps => {
            return (
              <Form>
                {/* <Row>
                  <Container> */}
                <Field
                  row
                  type="textarea"
                  name="emails"
                  placeholder={t('organization:semicolon_split')}
                  label={t('organization:emails')}
                  className="w-100"
                  component={CustomInput}
                />
                {/* <div className="text-muted italic">
                  {t('organization:semicolon_split')}
                </div> */}
                {/* </Container>
                </Row> */}
                <Button
                  color="primary"
                  type="submit"
                  // disabled={isSubmitting}
                  // onClick={() => {
                  //   submitForm();
                  // }}
                >
                  {t('project:add_member')}
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  {t('main:cancel')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch, { organizationId }) => {
  return {
    inviteUserToOrganization: userEmails =>
      dispatch(inviteUserToOrganization(userEmails, organizationId))
  };
};

const mapStateToProps = () => {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['organization', 'main', 'error', 'validation'])
)(InviteToJoinComponent);
