import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  FormGroup,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Label
} from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import CustomInput from '../../../UI/Input/customInput';
import * as Yup from 'yup';
import { dateInputTypeFormatting } from '../../../../locales/dateFormat';
import { withTranslation } from 'react-i18next';
import Message from '../../../UI/Message';

import { socketNewsEvents } from '../../../../api/sockets/constantEvents';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUserListAsAdmin } from '../../../../store/actions';

const displayForSelectAll = ['QP', 'PO', 'ADMIN', 'EVERYONE'];

const formikConfig = props => {
  //intial values from the props.news
  const {
    title,
    body,
    display_start_date,
    display_end_date,
    display_for,
    full_name,
    email,
    reviewer
  } = props.news && props.news[props.match.params.newsId];

  return {
    initialValues: {
      title,
      body,
      display_start_date: dateInputTypeFormatting(display_start_date), // date format YYYY-MM-DD
      display_end_date: dateInputTypeFormatting(display_end_date), // date format YYYY-MM-DD
      display_for: display_for || display_for.split(','),
      published_by: full_name + ' (' + email + ')',
      is_display_on_front: false,
      reviewer
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape(
      {
        title: Yup.string().required('Required'),
        body: Yup.string().required('Required'),
        display_for: Yup.array()
          .of(Yup.string())
          .min(
            1,
            'Please select at least one(1) user type or account type where you want to display the news'
          ),
        display_start_date: Yup.date()
          .required('Required')
          .when(
            'display_end_date',
            (endDate, schema) =>
              endDate &&
              schema.max(endDate, 'Start date must be earlier than End date')
          ),
        display_end_date: Yup.date()
          .required('Required')
          .when(
            'display_start_date',
            (startDate, schema) =>
              startDate &&
              schema.min(startDate, 'End date must be later than Start date')
          ),
        reviewer: Yup.string().required('Required')
      },
      ['display_start_date', 'display_end_date']
    ),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const newsId = props.news[props.match.params.newsId].news_id;
      values.is_display_on_front = true;

      props
        .editNews(newsId, values)
        .then(() => {
          Message.success(props.t('success_update_news'));
          setTimeout(() => props.history.push('/admin/news/list'), 500);
        })
        .catch(err => {
          Message.error(
            props.t(`error:${err.message}`, {
              entity: 'news'
            })
          );
        });

      setSubmitting(false);
    }
  };
};

const NewsDetailPresenter = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { resetNews, fetchNews, match, t } = props;

  const [term, setTerm] = useState('');
  const { users, authUser } = useSelector(state => state);
  const userMap = Object.keys(users).reduce((acc, currKey) => {
    if (authUser.userId === users[currKey].user_id) return acc;
    return {
      ...acc,
      [currKey]: {
        ...users[currKey],
        full_name: `${users[currKey].first_name} ${users[currKey].last_name}`
      }
    };
  }, {});
  const dispatch = useDispatch();
  const fetchUsersAdminFunc = () => {
    dispatch(
      fetchUserListAsAdmin(
        { function: 'ADMIN' },
        { field: 'created_at', order: 'asc' },
        { from: 0, to: -1 }
      )
    );
  };

  const handleFetchNews = () => {
    fetchUsersAdminFunc();
    Promise.all([resetNews(), fetchNews(match.params.newsId)]).then(() => {
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    handleFetchNews();
  }, [match.params.newsId]);

  useEffect(() => {
    handleFetchNews();
    return () => props.resetNews();
  }, []);

  const filteredUsers = Object.values(userMap)
    .filter(
      user =>
        user.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        user.email.toLowerCase().indexOf(term.toLowerCase()) > -1
    )
    .sort((a, b) =>
      a.full_name.trim().toLowerCase() > b.full_name.trim().toLowerCase()
        ? 1
        : -1
    );

  const onSearchReviewer = e => setTerm(e.target.value);
  return (
    <>
      {isLoaded && props.news[props.match.params.newsId] && (
        <Container className="shadow mt-4 p-3 mb-5 bg-light rounded">
          <h1>{t('news_details')}</h1>
          <div className="bg-light py-3 px-2">
            <Formik {...formikConfig(props)}>
              {formikProps => {
                const { setFieldValue, isSubmiting, dirty, onSubmit, values } =
                  formikProps;
                return (
                  <Form>
                    <FormGroup>
                      <Field
                        name="title"
                        label={t('title')}
                        placeholder="Title"
                        component={CustomInput}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        name="body"
                        type="textarea"
                        label={t('body')}
                        placeholder="News content"
                        component={CustomInput}
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field
                            name="display_start_date"
                            type="date"
                            label={t('start_date')}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Field
                            name="display_end_date"
                            type="date"
                            label={t('end_date')}
                            component={CustomInput}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Field
                            name="display_for"
                            type="select"
                            label={t('display_for')}
                            component={CustomInput}
                            onChange={evt =>
                              setFieldValue(
                                'display_for',
                                [].slice
                                  .call(evt.target.selectedOptions)
                                  .map(option => option.value)
                              )
                            }
                            value={
                              !dirty
                                ? values.display_for.includes('EVERYONE')
                                  ? displayForSelectAll
                                  : Array.of(values.display_for) ||
                                    values.display_for.split(',')
                                : values.display_for.includes('EVERYONE')
                                ? displayForSelectAll
                                : undefined
                            }
                            multiple={true}>
                            <option value="QP">{t('qp')}</option>
                            <option value="PO">{t('po')}</option>
                            <option value="ADMIN">{t('admin')}</option>
                            <option value="EVERYONE">{t('everyone')}</option>
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Field
                            name="published_by"
                            label={
                              props.news[props.match.params.newsId].is_approved
                                ? t('published_by')
                                : t('created_by')
                            }
                            placeholder="Full Name (email address)"
                            component={CustomInput}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Label for="news-reviewer">{t('reviewer')}</Label>
                    <FormGroup className="mb-0">
                      <div className="p-1 rounded border">
                        {props.news[props.match.params.newsId].is_approved ? (
                          <div>
                            <div className="p-3">
                              <span className="font-weight-bold">
                                {t('approved_by')}:
                              </span>{' '}
                              {users[values.reviewer] &&
                                users[values.reviewer].first_name}{' '}
                              {users[values.reviewer] &&
                                users[values.reviewer].last_name}{' '}
                              <i className="fas fa-check-circle text-success"></i>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="pb-3">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  {t('filter')}
                                </InputGroupAddon>
                                <Input
                                  type="text"
                                  onChange={onSearchReviewer}
                                  placeholder={t('filterByNameOrEmail')}
                                />
                              </InputGroup>
                            </div>
                            <Field
                              disabled={
                                props.news[props.match.params.newsId]
                                  .is_approved ||
                                (values.reviewer === authUser.userId &&
                                  !props.news[props.match.params.newsId]
                                    .is_approved)
                              }
                              id="news-reviewer"
                              name="reviewer"
                              type="select"
                              component={CustomInput}
                              onChange={evt =>
                                setFieldValue(
                                  'reviewer',
                                  evt.target.value
                                  // [].slice
                                  //   .call(evt.target.selectedOptions)
                                  //   .map(option => option.value)
                                )
                              }>
                              <option value="">
                                --{t('select_reviewer')}--
                              </option>
                              {values.reviewer === authUser.userId
                                ? Object.keys(users).map(userKey => {
                                    return (
                                      <option
                                        key={`option-key-${userKey}`}
                                        value={users[userKey].user_id}>
                                        {users[userKey].first_name}{' '}
                                        {users[userKey].last_name} (
                                        {users[userKey].email})
                                      </option>
                                    );
                                  })
                                : Object.keys(filteredUsers).map(userKey => {
                                    return (
                                      <option
                                        key={`option-key-${userKey}`}
                                        value={filteredUsers[userKey].user_id}>
                                        {filteredUsers[userKey].full_name} (
                                        {filteredUsers[userKey].email})
                                      </option>
                                    );
                                  })}
                            </Field>
                            <div className="text-right">
                              {!props.news[props.match.params.newsId]
                                .is_approved &&
                              values.reviewer === authUser.userId ? (
                                <Button
                                  color="success"
                                  className="mx-1"
                                  disabled={isSubmiting}
                                  onClick={() => {
                                    props
                                      .editNews(props.match.params.newsId, {
                                        ...values,
                                        is_approved:
                                          !props.news[props.match.params.newsId]
                                            .is_approved
                                      })
                                      .then(() => {
                                        Message.success(props.t('approved'));
                                      });
                                  }}
                                  id="formApprovedButton">
                                  {t('approve')}
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                    </FormGroup>

                    <FormGroup className="py-3 d-flex">
                      <Button
                        color="primary"
                        type="submit"
                        className="mx-1"
                        disabled={isSubmiting || !dirty}
                        onClick={onSubmit}
                        id="formSaveButton">
                        {t('save_changes')}
                      </Button>
                      <Button
                        color="secondary"
                        className="mx-1"
                        disabled={isSubmiting}
                        onClick={() => props.history.goBack()}
                        id="formSaveButton">
                        {t('cancel')}
                      </Button>
                    </FormGroup>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
      )}
    </>
  );
};

export default withTranslation(['news'])(NewsDetailPresenter);
