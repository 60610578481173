import React, { Component, useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  FormGroup,
  Button,
  Nav,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledTooltip,
  Progress,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import { compose } from 'recompose';
import reduce from 'lodash/reduce';
import classnames from 'classnames';
import prettyBytes from 'pretty-bytes';

import { dateFormatting } from '../../../locales/dateFormat';
import Message from '../../UI/Message';

import ActionCreation from './ActionCreation';

import CustomInput from '../../UI/Input/customInput';
import CustomTransfer from '../../UI/Input/customTransfer2';
import CorrespondenceEditor from '../Submission/Correspondence/Editor';

import {
  getProjectRelatedItems,
  createTask,
  addFileToTask
} from '../../../store/actions';

const KEY_SEPARATOR = '##';

class TaskCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      itemRelatedTab: 'rel-items-tab-documents',
      fileUploadProgresses: []
    };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  setItemRelatedTab = tab => this.setState({ itemRelatedTab: tab });

  setFileUploadProgresses = progressList => {
    this.setState({ fileUploadProgresses: progressList });
  };

  formikConfig = {
    initialValues: {
      topic: '',
      message: '',
      ccUsers: [],
      issues: [],
      models: [],
      results: [],
      documents: [],
      actions: [],
      additionalFiles: []
    },
    validationSchema: Yup.object().shape({
      topic: Yup.string().max(60).required(this.props.t('error:topic')),
      actions: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(this.props.t('error:action_name')),
            actors: Yup.array().min(1, this.props.t('error:action_actors'))
          })
        )
        .min(1)
        .max(10)
    }),
    // validate: values => {
    //   const editorState = values.message;
    //   if (
    //     !editorState.blocks ||
    //     editorState.blocks.filter(b => b.text !== '').length === 0
    //   ) {
    //     return {
    //       message: this.props.t('error:message_content_required')
    //     };
    //   }
    // },
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const formattedValues = {
        ...values,
        message: JSON.stringify(values.message)
      };

      this.setFileUploadProgresses(
        formattedValues.additionalFiles.map(() => 0)
      );
      // We have to add the file sequencially due to concurent update in the db otherwise
      async function* generateAddFileSequence(
        files,
        taskId,
        projectId,
        fileUploadProgresses,
        setFileUploadProgresses,
        addFileToTask
      ) {
        for (let i in files) {
          const file = files[i];
          const onUploadProgress = progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const newProgresses = fileUploadProgresses;
            newProgresses[i] = percentCompleted;
            setFileUploadProgresses(newProgresses);
          };
          const documentId = await addFileToTask(
            taskId,
            projectId,
            file,
            onUploadProgress
          );
          yield documentId;
        }
      }
      try {
        const taskCreated = await this.props.createTask(
          this.props.projectId,
          formattedValues
        );

        let generator = generateAddFileSequence(
          formattedValues.additionalFiles,
          taskCreated.task_id,
          this.props.projectId,
          this.state.fileUploadProgresses,
          this.setFileUploadProgresses,
          this.props.addFileToTask
        );
        let next = await generator.next();
        while (!next.done) {
          next = await generator.next();
        }

        // for await ... of not supported on edge
        // for await (let documentId of generator) {
        // }
        Message.success(this.props.t('task_management:task_created'));
        setSubmitting(false);
        if (this.props.onSubmitSuccess) {
          this.props.onSubmitSuccess();
        }
      } catch (err) {
        Message.error(this.props.t(`error:${err.message}`));
        // console.log(err);
        setSubmitting(false);
        //throw err;
      }
    }
  };

  render() {
    const { projectId, project, t, relatedItems } = this.props;
    const { itemRelatedTab, fileUploadProgresses } = this.state;
    // console.log({ projectId, project });
    // console.log('RENDER');
    // console.log({ relatedItems });

    // const modelsStageMap = {};
    const modelsItemsDataSource = reduce(
      relatedItems.models,
      (previous, { stage_name, stage_display_name, items }) => {
        // modelsStageMap[stage_name] = items.reduce((previous, item) => ({
        //   ...previous,
        //   [item.name]: { ...item }
        // }));
        return [
          ...previous,
          { header: stage_display_name, key: stage_name },
          ...items.map(item => ({
            title: item.name,
            key: `${stage_name}${KEY_SEPARATOR}${item.name}`,
            headerKey: stage_name
          }))
        ];
      },
      []
    );

    // const issuesStageMap = {};
    const issuesItemsDataSource = reduce(
      relatedItems.issues,
      (previous, { stage_name, stage_display_name, items }) => {
        // issuesStageMap[stage_name] = items.reduce((previous, item) => ({
        //   ...previous,
        //   [item.issue_id]: { ...item }
        // }));
        return [
          ...previous,
          { header: stage_display_name, key: stage_name },
          ...items.map(item => ({
            title: item.issue_topic,
            key: `${stage_name}${KEY_SEPARATOR}${item.issue_id}`,
            headerKey: stage_name
          }))
        ];
      },
      []
    );

    // const resultsStageMap = {};
    const resultsItemsDataSource = reduce(
      relatedItems.results,
      (previous, { stage_name, stage_display_name, items }) => {
        // resultsStageMap[stage_name] = items.reduce((previous, item) => ({
        //   ...previous,
        //   [item.job_id]: { ...item }
        // }));
        return [
          ...previous,
          { header: stage_display_name, key: stage_name },
          ...items.map(item => ({
            title: `${t(item.type)} - ${dateFormatting(
              item.date_created,
              'defaultHour'
            )} ${item.is_obsolete ? '(Obsolete)' : ''} `,
            key: `${stage_name}${KEY_SEPARATOR}${item.job_id}`,
            headerKey: stage_name
          }))
        ];
      },
      []
    );

    const documentsItemsDataSource = relatedItems.documents.map(doc => ({
      key: doc.id,
      ...doc
    }));

    let usersItemsDataSource = relatedItems.users.map(user => ({
      key: user.user_id,
      ...user
    }));

    let projectGroupDataSource = relatedItems.groups
      .filter(info => info)
      .map(group => {
        if (group.organization_id && !group.department_id && !group.team_id) {
          return {
            key: group.organization_id,
            type: 'organization',
            ...group
          };
        } else if (
          group.organization_id &&
          group.department_id &&
          !group.team_id
        ) {
          return {
            key: group.department_id,
            type: 'department',
            ...group
          };
        } else {
          return {
            key: group.team_id,
            type: 'team',
            ...group
          };
        }
      });

    usersItemsDataSource = [...usersItemsDataSource, ...projectGroupDataSource];

    return (
      <Formik {...this.formikConfig}>
        {formikProps => {
          const { values, setFieldValue } = formikProps;

          return (
            <Form>
              <Container>
                {/* Topic */}
                <h5 className="text-primary py-1">
                  {t('task_management:topic')}
                </h5>
                <Field
                  name="topic"
                  placeholder={t('task_management:topic')}
                  component={CustomInput}
                />
                {/* List of person concerned */}
                {/* CC */}
                {/* <Field
                  name="ccUsers"
                  render={() => {
                    const handleChange = (
                      nextTargetKeys,
                      direction,
                      movedKeys
                    ) => {
                      const nextValue = usersItemsDataSource.filter(
                        user_and_group =>
                          nextTargetKeys.includes(user_and_group.user_id) ||
                          nextTargetKeys.includes(
                            user_and_group[`${user_and_group.type}_id`]
                          )
                      );

                      setFieldValue('ccUsers', nextValue);
                    };

                    return (
                      <>
                        <FormGroup>
                          <h5 className="text-primary py-1">
                            {t('task_management:cc')}
                          </h5>
                          <CustomTransfer
                            data={usersItemsDataSource}
                            targetKeys={values.ccUsers}
                            // withHeader
                            // disabled
                            // selectedKeys={}
                            renderItem={item =>
                              `${item.first_name || ''} ${item.last_name ||
                                ''}  ${item.name || ''}
                                
                                ${item.email ? `(${item.email})` : ''}`
                            }
                            // renderHeader={item => item.header}
                            onChange={handleChange}
                            // onSelectChange={}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                /> */}
                {/* Message */}
                <Field
                  name="message"
                  render={() => {
                    const {
                      touched,
                      errors,
                      setFieldValue,
                      setFieldTouched
                    } = formikProps;
                    const fieldName = 'message';
                    const isValid =
                      getIn(touched, fieldName) && !getIn(errors, fieldName);
                    const isInvalid = !!(
                      getIn(touched, fieldName) && getIn(errors, fieldName)
                    );
                    const onChange = editorState => {
                      setFieldValue(
                        fieldName,
                        convertToRaw(editorState.getCurrentContent())
                      );
                    };
                    const onFocus = () => {};
                    const onBlur = () => {
                      setFieldTouched(fieldName, true);
                      formikProps.handleBlur(fieldName);
                    };

                    return (
                      <>
                        <h5 className="text-primary py-1">
                          {t('task_management:message')}
                        </h5>
                        <FormGroup>
                          <CorrespondenceEditor
                            onInit={this.onInit}
                            isValid={isValid}
                            isInvalid={isInvalid}
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            projectId={projectId}
                            project={project}
                            withUserMention={false}
                            withIssueMention={false}
                            // stageName={stageName}
                            invalidFeedback={getIn(errors, fieldName)}
                          />
                        </FormGroup>
                      </>
                    );
                  }}
                />
                <hr />
                {/* List of actions */}
                <h5 className="text-primary py-2">
                  {t('task_management:actions')}
                </h5>
                <FieldArray
                  name="actions"
                  render={arrayHelpers => {
                    return (
                      <ActionCreation
                        {...this.props}
                        {...arrayHelpers}
                        {...{
                          relatedItems,
                          documentsItemsDataSource,
                          modelsItemsDataSource,
                          issuesItemsDataSource,
                          resultsItemsDataSource,
                          usersItemsDataSource
                        }}
                      />
                    );
                  }}
                />
                <hr />
                {/* List of items related */}
                <h5 className="text-primary py-2">
                  {t('task_management:items_related')}
                </h5>
                <Row>
                  <Col className="mb-5">
                    <Row className="">
                      <Col lg={3} md={4} sm={5} className="px-2 py-0">
                        <Nav className="" tabs vertical>
                          <NavLink
                            tag={Button}
                            color="primary"
                            outline
                            className={classnames('rounded border', {
                              'active font-weight-bold':
                                itemRelatedTab === 'rel-items-tab-documents'
                            })}
                            onClick={() =>
                              this.setItemRelatedTab('rel-items-tab-documents')
                            }>
                            {t('task_management:documents')}
                          </NavLink>
                          <NavLink
                            tag={Button}
                            color="primary"
                            outline
                            className={classnames('rounded border', {
                              'active font-weight-bold':
                                itemRelatedTab === 'rel-items-tab-issues'
                            })}
                            onClick={() =>
                              this.setItemRelatedTab('rel-items-tab-issues')
                            }>
                            {t('task_management:issues')}
                          </NavLink>
                          <NavLink
                            tag={Button}
                            color="primary"
                            outline
                            className={classnames('rounded border', {
                              'active font-weight-bold':
                                itemRelatedTab === 'rel-items-tab-models'
                            })}
                            onClick={() =>
                              this.setItemRelatedTab('rel-items-tab-models')
                            }>
                            {t('task_management:models')}
                          </NavLink>
                          <NavLink
                            tag={Button}
                            color="primary"
                            outline
                            className={classnames('rounded border', {
                              'active font-weight-bold':
                                itemRelatedTab === 'rel-items-tab-results'
                            })}
                            onClick={() =>
                              this.setItemRelatedTab('rel-items-tab-results')
                            }>
                            {t('task_management:results')}
                          </NavLink>
                        </Nav>
                      </Col>
                      <Col lg={9} md={8} sm={7} className="px-2 py-0">
                        <TabContent activeTab={itemRelatedTab}>
                          <TabPane
                            tabId={'rel-items-tab-documents'}
                            className="w-100 p-2 mt-2">
                            {/* -- List of Documents */}
                            <Field
                              name="documents"
                              render={() => {
                                const handleChange = (
                                  nextTargetKeys,
                                  direction,
                                  movedKeys
                                ) => {
                                  setFieldValue('documents', nextTargetKeys);
                                };
                                return (
                                  <>
                                    <FormGroup>
                                      <CustomTransfer
                                        data={documentsItemsDataSource}
                                        targetKeys={values.documents}
                                        // withHeader
                                        // disabled
                                        // selectedKeys={}
                                        optionStyle={{ fontSize: '0.75rem' }}
                                        renderItem={item =>
                                          item.base_url_to_display_in_file_explorer
                                        }
                                        // renderHeader={item => item.header}
                                        onChange={handleChange}
                                        // onSelectChange={}
                                      />
                                    </FormGroup>
                                  </>
                                );
                              }}
                            />
                          </TabPane>

                          <TabPane
                            tabId={'rel-items-tab-issues'}
                            className="w-100 p-2 my-2">
                            {/* -- List of issues */}
                            <Field
                              name="issues"
                              render={() => {
                                const handleChange = (
                                  nextTargetKeys,
                                  direction,
                                  movedKeys
                                ) => {
                                  setFieldValue('issues', nextTargetKeys);
                                };
                                return (
                                  <>
                                    <FormGroup>
                                      <CustomTransfer
                                        data={issuesItemsDataSource}
                                        targetKeys={values.issues}
                                        withHeader
                                        // disabled
                                        // selectedKeys={}
                                        renderItem={item => item.title}
                                        renderHeader={item => item.header}
                                        onChange={handleChange}
                                        // onSelectChange={}
                                      />
                                    </FormGroup>
                                  </>
                                );
                              }}
                            />
                          </TabPane>

                          <TabPane
                            tabId={'rel-items-tab-models'}
                            className="w-100 p-2 my-2">
                            {/* -- List of Models */}
                            <Field
                              name="models"
                              render={() => {
                                const handleChange = (
                                  nextTargetKeys,
                                  direction,
                                  movedKeys
                                ) => {
                                  setFieldValue('models', nextTargetKeys);
                                };
                                return (
                                  <>
                                    <FormGroup>
                                      <CustomTransfer
                                        data={modelsItemsDataSource}
                                        targetKeys={values.models}
                                        withHeader
                                        // disabled
                                        // selectedKeys={}
                                        renderItem={item => item.title}
                                        renderHeader={item => item.header}
                                        onChange={handleChange}
                                        // onSelectChange={}
                                      />
                                    </FormGroup>
                                  </>
                                );
                              }}
                            />
                          </TabPane>
                          <TabPane
                            tabId={'rel-items-tab-results'}
                            className="w-100 p-2 my-2">
                            {/* -- List of Jobs */}
                            <Field
                              name="results"
                              render={() => {
                                const handleChange = (
                                  nextTargetKeys,
                                  direction,
                                  movedKeys
                                ) => {
                                  setFieldValue('results', nextTargetKeys);
                                };
                                return (
                                  <>
                                    <FormGroup>
                                      <CustomTransfer
                                        data={resultsItemsDataSource}
                                        targetKeys={values.results}
                                        withHeader
                                        // disabled
                                        // selectedKeys={}
                                        renderItem={item => item.title}
                                        renderHeader={item => item.header}
                                        onChange={handleChange}
                                        // onSelectChange={}
                                      />
                                    </FormGroup>
                                  </>
                                );
                              }}
                            />
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    {itemRelatedTab === 'rel-items-tab-documents' && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <InputGroup>
                              <div className="custom-file">
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile">
                                  {t('task_management:upload_file')}
                                </label>
                                <FieldArray
                                  name="additionalFiles"
                                  render={() => {
                                    return (
                                      <Input
                                        type="file"
                                        id="customFile"
                                        className="custom-file-input"
                                        // accept=".docx,.pdf"
                                        onChange={e => {
                                          let files =
                                            formikProps.values.additionalFiles;
                                          for (
                                            let index = 0;
                                            index < e.target.files.length;
                                            index++
                                          ) {
                                            files.push(e.target.files[index]);
                                          }
                                          setFieldValue('files', files);
                                        }}
                                        multiple={true}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </InputGroup>
                            {formikProps.values.additionalFiles.length > 0 && (
                              <ListGroup>
                                <ListGroupItem color="info">
                                  {t('task_management:additional_files')}
                                </ListGroupItem>
                                {formikProps.values.additionalFiles.map(
                                  (file, index) => {
                                    return (
                                      <ListGroupItem key={`files-${index}`}>
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <Row>
                                              {file.name}
                                              {file.size &&
                                                ` (${prettyBytes(file.size)})`}
                                            </Row>
                                            <Row>
                                              {file.lastModified && (
                                                <div className="text-secondary">
                                                  {dateFormatting(
                                                    file.lastModified
                                                  )}
                                                </div>
                                              )}
                                            </Row>
                                          </div>
                                          <div>
                                            <Button
                                              id={`file-remove-${index}`}
                                              color="light"
                                              outline
                                              onClick={e => {
                                                e.stopPropagation();
                                                setFieldValue(
                                                  'additionalFiles',
                                                  formikProps.values.additionalFiles.filter(
                                                    f => f.name !== file.name
                                                  )
                                                );
                                              }}>
                                              <span>
                                                <i className="fas fa-trash text-danger" />
                                              </span>
                                            </Button>
                                            <UncontrolledTooltip
                                              fade={false}
                                              placement="auto"
                                              target={`file-remove-${index}`}>
                                              {t('task_management:remove')}
                                            </UncontrolledTooltip>
                                          </div>
                                        </div>
                                        {formikProps.isSubmitting && (
                                          <Progress
                                            className="mt-2 w-100"
                                            color={
                                              fileUploadProgresses[index] ===
                                              100
                                                ? 'success'
                                                : 'info'
                                            }
                                            value={fileUploadProgresses[index]}>
                                            {fileUploadProgresses[index]} %
                                          </Progress>
                                        )}
                                      </ListGroupItem>
                                    );
                                  }
                                )}
                              </ListGroup>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <div className="p-2 bg-white sticky-top d-flex justify-content-end">
                  {/* <Button
                    color="danger"
                    className="font-weight-bold mr-2"
                    onClick={this.props.toggle}>
                    {t('task_management:cancel')}
                  </Button> */}
                  <Button
                    color="success"
                    type="submit"
                    className="font-weight-bold"
                    // onClick={() => {
                    //   !formikProps.error &&
                    //     Message.error(this.props.t('error:fill_fields'));
                    // }}
                    disabled={formikProps.isSubmitting}>
                    {t('task_management:create_task')}
                  </Button>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProjectRelatedItems: projectId =>
      dispatch(getProjectRelatedItems(projectId)),
    createTask: (projectId, data) => dispatch(createTask(projectId, data)),
    addFileToTask: (taskId, projectId, file, onUplodaProgress) =>
      dispatch(addFileToTask(taskId, projectId, file, onUplodaProgress))
  };
};

const TaskCreationContainer = props => {
  const { getProjectRelatedItems, projectId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [onError, setOnError] = useState(false);
  const [relatedItems, setRelatedItems] = useState({});
  useEffect(() => {
    // if (!isLoaded) {
    getProjectRelatedItems(projectId)
      .then(result => {
        setRelatedItems(result);
        setIsLoaded(true);
      })
      .catch(err => {
        setOnError(true);
      });
    // }
  }, [projectId]);

  return (
    <> {isLoaded && <TaskCreation {...props} relatedItems={relatedItems} />} </>
  );
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['main', 'task_management'])
)(TaskCreationContainer);
