import React, { Component, useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Container
} from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { dateFormatting } from '../../../../locales/dateFormat';
import Message from '../../../UI/Message';

import JobStatusPopUpInfo from '../../../Shared/RuleCheckStatusReport';

import { getURLParameters } from '../../../../utils/url';
import { isEqual } from 'lodash';
import {
  requestJobRerun,
  requestRuleCheckDeletion,
  requestJobCancellation as requestJobCancellationAction,
  requestPartialResult
} from '../../../../store/actions';

import { socketProjectEvents } from '../../../../api/sockets/constantEvents';

import styles from './../ProjectResultRules.module.css';

import ModalHTMLReport from './ModalHTMLReport';

import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

const NoData = t => <h1>{t('no_data')}</h1>;
const selectOptionsStatus = t => {
  return {
    PARTIALLY_COMPLETED: t('results:status_partial'),
    PENDING: t('results:status_pending'),
    IN_PROCESS: t('results:status_process'),
    DONE: t('results:status_completed'),
    GENERATING_REPORT: t('results:status_generating_report'),
    ERROR: t('results:status_error'),
    USER_CANCELLED: t('results:status_user_cancelled')
  };
};

const statusToIcon = ({ status, job_id, error_reason }, translate) => {
  switch (status) {
    case 'DONE':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {translate('status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'IN_PROCESS':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {translate('status_in_process')}
          </UncontrolledTooltip>
        </>
      );
    case 'GENERATING_REPORT':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-sync-alt fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {translate('status_generating_report')}
          </UncontrolledTooltip>
        </>
      );
    case 'PENDING':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {translate('status_prending')}
          </UncontrolledTooltip>
        </>
      );

    case 'ON_ERROR':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-exclamation-circle text-danger fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {error_reason
              ? translate(`${error_reason}`)
              : translate('status_on_error')}
          </UncontrolledTooltip>
        </>
      );
    case 'OBSOLETE':
      return (
        <>
          <span id={`status-result-${job_id}`}>
            <i className="fas fa-exclamation-triangle text-warning  fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${job_id}`}
            delay={100}>
            {translate('status_obsolete')}
          </UncontrolledTooltip>
        </>
      );
  }
};

const CancelButton = connect(null, dispatch => ({
  requestJobCancellation: (projectId, stageName, jobId) =>
    dispatch(requestJobCancellationAction(projectId, stageName, jobId))
}))(({ projectId, stageName, jobId, translate, requestJobCancellation }) => {
  const [isRequestRunning, setRequestRunning] = useState(false);
  const handleClick = async () => {
    try {
      setRequestRunning(true);
      await requestJobCancellation(projectId, stageName, jobId);
      Message.success(translate('successfully_cancelled'));
      setRequestRunning(false);
    } catch (err) {
      Message.error(translate(err.message));
      setRequestRunning(false);
    }
  };
  return (
    <>
      {!isRequestRunning ? (
        <>
          <Button
            id={`cancel-${jobId}`}
            color="link"
            size="md"
            onClick={handleClick}>
            <i className="fas fa-ban text-danger"></i>
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`cancel-${jobId}`}
            // delay={0}
          >
            {translate('cancel')}
          </UncontrolledTooltip>
        </>
      ) : (
        <div className="px-3 py-2">
          <span
            className="text-danger  spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
});
const DeleteButton = connect(null, dispatch => ({
  requestRuleCheckDeletion: (projectId, stageName, jobId) =>
    dispatch(requestRuleCheckDeletion(projectId, stageName, jobId))
}))(({ projectId, stageName, jobId, translate, requestRuleCheckDeletion }) => {
  const [isRequestRunning, setRequestRunning] = useState(false);
  const handleClick = async () => {
    try {
      setRequestRunning(true);
      await requestRuleCheckDeletion(projectId, stageName, jobId);
      Message.success(translate('successfully_deleted'));
      setRequestRunning(false);
    } catch (err) {
      Message.error(translate(`error:${err.message}`));

      setRequestRunning(false);
    }
  };
  return (
    <>
      {!isRequestRunning ? (
        <>
          <Button
            id={`delete-${jobId}`}
            color="link"
            size="md"
            onClick={handleClick}>
            <i className="fas fa-trash text-danger"></i>
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`delete-${jobId}`}
            // delay={0}
          >
            {translate('delete')}
          </UncontrolledTooltip>
        </>
      ) : (
        <div className="px-3 py-2">
          <span
            className="text-danger spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
});
const RerunButton = connect(null, dispatch => ({
  requestJobRerun: (projectId, stageName, jobId) =>
    dispatch(requestJobRerun(projectId, stageName, jobId))
}))(({ projectId, stageName, jobId, translate, requestJobRerun }) => {
  const [isRequestRunning, setRequestRunning] = useState(false);
  const handleClick = async () => {
    try {
      setRequestRunning(true);
      await requestJobRerun(projectId, stageName, jobId);
      Message.success(translate('operation_successful'));
      setRequestRunning(false);
    } catch (err) {
      Message.error(translate(`error:${err.message}`));
      setRequestRunning(false);
    }
  };
  return (
    <>
      {!isRequestRunning ? (
        <>
          <Button
            id={`rerun-${jobId}`}
            color="link"
            size="md"
            onClick={handleClick}>
            <i className="fas fa-redo"></i>
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`rerun-${jobId}`}
            // delay={0}
          >
            {translate('rerun')}
          </UncontrolledTooltip>
        </>
      ) : (
        <div className="px-3 py-2">
          <span
            className="text-primary spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
});

const RequestPartialResultButton = connect(null, dispatch => ({
  requestPartialResult: (projectId, stageName, jobId) =>
    dispatch(requestPartialResult(projectId, stageName, jobId))
}))(({ projectId, stageName, jobId, translate, requestPartialResult }) => {
  const [isRequestRunning, setRequestRunning] = useState(false);
  const handleClick = async () => {
    try {
      setRequestRunning(true);
      await requestPartialResult(projectId, stageName, jobId);
      Message.success(translate('partial_result_success'));
      setRequestRunning(false);
    } catch (err) {
      Message.error(translate(err.message));
      setRequestRunning(false);
    }
  };
  return (
    <>
      {!isRequestRunning ? (
        <>
          <Button
            id={`partial-result-${jobId}`}
            color="link"
            size="md"
            onClick={handleClick}>
            <i className="fas fa-file-import text-info"></i>
          </Button>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`partial-result-${jobId}`}
            // delay={0}
          >
            {translate('request_partial_result')}
          </UncontrolledTooltip>
        </>
      ) : (
        <div className="px-3 py-2">
          <span
            className="text-danger spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
});

const RulesCheck = ({ row }) => {
  const progressBarRef = useRef();
  const rulesCompleted = row.rules_completed || [];
  const rulesChecked = row.rules_check || [];

  useEffect(() => {
    progressBarRef.current.style['width'] = `${
      (rulesCompleted.length / rulesChecked.length) * 100
    }%`;
  }, [rulesCompleted, rulesChecked]);

  return (
    <div className="progress position-relative">
      <div
        ref={progressBarRef}
        className={`progress-bar ${
          row.status === 'IN_PROCESS' ? 'bg-info' : 'bg-danger'
        } progress-bar-striped ${
          row.status === 'IN_PROCESS' ? 'progress-bar-animated' : ''
        }`}
        role="progressbar"
        aria-valuenow={Math.floor(
          (rulesCompleted.length / rulesChecked.length) * 100
        )}
        aria-valuemin="0"
        aria-valuemax="100">
        <div className="justify-content-center d-flex position-absolute w-100 text-dark">
          {rulesCompleted.length} / {rulesChecked.length}
        </div>
      </div>
    </div>
  );
};

class ProjectResultPresenter extends Component {
  constructor(props) {
    super(props);
    // this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      collapse: {},
      jobsData: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      filters: {},
      order: { field: 'date_created', direction: 'desc' },
      isModalHTMLReportOpen: false,
      activeResultData: {}
    };
  }

  toggleModal = (rowData, fileName) => {
    const { project_id, stage_name, job_id } = rowData;
    this.setState(
      {
        isModalHTMLReportOpen: !this.state.isModalHTMLReportOpen,
        activeResultData: { project_id, stage_name, job_id, fileName }
      },
      () => {
        console.log(this.state.activeResultData);
      }
    );
  };

  async fetchJobList(reloadFromSocket = false) {
    const { currentJobType, activeJobType } = this.props;
    if (activeJobType === currentJobType || !!reloadFromSocket) {
      this.setState({ isLoaded: false });
      let { filters, page, order, sizePerPage } = this.state;
      const from = (page - 1) * sizePerPage;
      const to = page * sizePerPage;

      const { projectId, stageName, activeJobType, t } = this.props;

      const modifiedFilter = { ...filters, type: activeJobType };
      try {
        await this.props
          .fetchProjectStageJobs(
            projectId,
            stageName,
            activeJobType,
            modifiedFilter,
            order,
            {
              from,
              to
            }
          )
          .then(res => {
            this.setState({
              isLoaded: true,
              jobsData: res.jobs || [],
              totalSize: res.total || 0
            });
          });
      } catch (err) {
        Message.error(t(err.message));
      }
    }
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  componentDidMount() {
    this.fetchJobList();
    const { activeJobType, currentJobType, stageName, projectId } = this.props;
    // subscribe to socket events when job result is available
    // this will reset the filter and display the latest jobs
    if (this.props.userId) {
      const eventsToSubscribeTo = [
        {
          eventName: socketProjectEvents.RULE_CHECK_RESULT_STATUS_UPDATED,
          handler: async data => {
            this.clearFilter();
            this.fetchJobList(true);
          }
        },
        {
          eventName:
            socketProjectEvents.RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE,
          handler: async data => {
            this.clearFilter();
            this.fetchJobList(true);
          }
        }
      ];
      try {
        this.props.subscribeToEvents(eventsToSubscribeTo);
      } catch (err) {
        console.log(err);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order, isLoaded } = this.state;
    const { currentJobType, activeJobType, projectId, stageName } = this.props;

    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order) ||
      activeJobType !== prevProps.activeJobType
    ) {
      this.fetchJobList();
      // subscribe to socket events when table has filter and job result is available
      // this will reset the filter and display the latest jobs
      if (this.props.userId) {
        const eventsToSubscribeTo = [
          {
            eventName: socketProjectEvents.RULE_CHECK_RESULT_STATUS_UPDATED,
            handler: async data => {
              this.clearFilter();
              this.fetchJobList(true);
            }
          },
          {
            eventName:
              socketProjectEvents.RULE_CHECK_RESULT_STATUS_PARTIAL_UPDATE,
            handler: async data => {
              this.clearFilter();
              this.fetchJobList(true);
            }
          }
        ];
        try {
          this.props.subscribeToEvents(eventsToSubscribeTo);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        this.setState({
          page: newState.page,
          sizePerPage: newState.sizePerPage
        });
        break;
      case 'sort':
        this.setState({
          order: {
            field: newState.sortField,
            direction: newState.sortOrder
          }
        });
        break;
      case 'filter':
        this.setState({
          filters: {
            ...Object.keys(this.state.filters).reduce((acc, cur) => {
              if (
                cur === 'date_created' ||
                cur === 'status' ||
                cur === 'date_completed'
              ) {
                return { ...acc };
              }
              return { ...acc, [cur]: this.state.filters[cur] };
            }, {}),
            ...Object.keys(newState.filters).reduce((acc, cur) => {
              if (cur === 'date_created' || cur === 'date_completed') {
                return newState.filters[cur].filterVal.date
                  ? { ...acc, [cur]: newState.filters[cur].filterVal }
                  : { ...acc };
              }
              if (cur === 'status') {
                return { ...acc, [cur]: newState.filters[cur].filterVal };
              }
              return { ...acc };
            }, {})
          }
        });
        break;
    }
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    // if (this.tableRef.current) {
    //   this.tableRef.current.filterContext.currFilters = {};
    // }
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'date_created', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  render() {
    const {
      data,
      t,
      project_stage_results,
      projectId,
      stageName,
      currentJobType,
      activeJobType,
      projectStageResultList
    } = this.props;

    const {
      collapse,
      filters,
      page,
      sizePerPage,
      totalSize,
      customTotal,
      isLoaded,
      jobsData
    } = this.state;

    const defaultSorted = () => {
      const { f, d } = getURLParameters(window.location.href);
      return [
        {
          dataField: f || 'date_created',
          order: d || 'desc'
        }
      ];
    };

    const fileTypeFormatting = (row, file, index, translate) => {
      const fileType = file.split('.').pop().toLowerCase();
      const formatConfig = (fileExtension => {
        switch (fileExtension) {
          case 'pdf': {
            return {
              colorButton: 'danger',
              fileIcon: 'fas fa-file-pdf',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
          case 'xlsx': {
            return {
              colorButton: 'success',
              fileIcon: 'fas fa-file-excel',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
          case 'docx': {
            return {
              colorButton: 'primary',
              fileIcon: 'fas fa-file-word',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
          case 'fxr': {
            return {
              colorButton: 'success',
              fileIcon: 'fas fa-building',
              label: translate('open_result_in_viewer'),
              linkType: 'normal',
              isFXR: true
            };
          }
          case 'csv': {
            return {
              colorButton: 'primary',
              fileIcon: 'fas fa-file-csv',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
          case 'bcfzip': {
            return {
              colorButton: 'info',
              fileIcon: 'fas fa-file-archive',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
          case 'html': {
            return {
              colorButton: 'info',
              fileIcon: 'fas fa-file-code',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }

          case 'zip': {
            return {
              colorButton: 'info',
              fileIcon: 'fas fa-file-archive',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }

          default: {
            return {
              colorButton: 'info',
              fileIcon: 'fas fa-question',
              label: fileExtension,
              linkType: 'download',
              isFXR: false
            };
          }
        }
      })(fileType);

      if (formatConfig.isFXR) {
        const isViewerEnabled = this.props.isViewerEnabled;
        let result = <></>;
        // if (!row.is_obsolete && isViewerEnabled) {
        //   result = (
        //     <DropdownItem
        //       id={`fileLink-${row.job_id}-${fileType}`}
        //       key={`${row.job_id}-${index}`}
        //       size="md"
        //       tag="a"
        //       href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.fxrViewerToken}`}
        //       rel="noopener noreferrer"
        //       target="_blank"
        //       // color={formatConfig.colorButton}
        //       // className="text-white"
        //       color="light"
        //       disabled={row.is_obsolete ? true : false}>
        //       <div>
        //         {formatConfig.label}
        //         <i className={`${formatConfig.fileIcon} ml-1`} />
        //         {row.is_amended && <span className="text-muted">({translate('amended')})</span>}
        //       </div>
        //       <UncontrolledTooltip
        //         boundariesElement="window"
        //         fade={false}
        //         target={`fileLink-${row.job_id}-${fileType}`}
        //         delay={0}>
        //         {formatConfig.label}
        //       </UncontrolledTooltip>
        //     </DropdownItem>
        //   );
        // }

        if (this.props.isFXRDownloadEnabled) {
          result = (
            <>
              {/* {result} */}
              <DropdownItem
                tag="a"
                download={`${
                  this.props.projectName
                }_report_${row.job_id.substring(row.job_id.length - 12)}.fxr`}
                onClick={e => e.stopPropagation()}
                href={`${
                  process.env.REACT_APP_API_ENDPOINT[
                    process.env.REACT_APP_API_ENDPOINT.length - 1
                  ] == '/'
                    ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                    : process.env.REACT_APP_API_ENDPOINT
                }/api/project/${this.props.projectId}/${this.props.stageName}/${
                  row.job_id
                }/${file}/download`}
                key={`${row.job_id}-${index}-fxr`}
                id={`fileLink-${row.job_id}-fxrdl`}
                size="md"
                // color={formatConfig.colorButton}
                // className="text-white font-weight-bold px-2 ml-1"
                color="light">
                FXR
                {row.is_amended && (
                  <span className="text-muted ml-1">
                    ({translate('amended')})
                  </span>
                )}
                <UncontrolledTooltip
                  boundariesElement="window"
                  fade={false}
                  target={`fileLink-${row.job_id}-fxrdl`}
                  delay={0}>
                  [DEBUG] Download fxr file
                </UncontrolledTooltip>
              </DropdownItem>
            </>
          );
        }
        return result;
      } else {
        if (formatConfig.label === 'html') {
          return (
            <DropdownItem
              tag="button"
              download={`${
                this.props.projectName
              }_report_${row.job_id.substring(
                row.job_id.length - 12
              )}.${fileType}`}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.toggleModal(row, file);
              }}
              // href={`${process.env.REACT_APP_API_ENDPOINT}/api/project/${this.props.projectId}/${this.props.stageName}/${row.job_id}/${file}/download`}
              key={`${row.job_id}-${index}`}
              id={`fileLink-${row.job_id}-${fileType}`}
              size="md"
              // color={formatConfig.colorButton}
              // className="text-white"
              color="light">
              <div>
                {formatConfig.label}
                <i className={`${formatConfig.fileIcon} ml-1`} />
                {row.is_amended && (
                  <span className="text-muted ml-1">
                    ({translate('amended')})
                  </span>
                )}
              </div>
              <UncontrolledTooltip
                boundariesElement="window"
                fade={false}
                target={`fileLink-${row.job_id}-${fileType}`}
                delay={0}>
                {formatConfig.label}
              </UncontrolledTooltip>
            </DropdownItem>
          );
        } else {
          return (
            <DropdownItem
              tag="a"
              download={`${
                this.props.projectName
              }_report_${row.job_id.substring(
                row.job_id.length - 12
              )}.${fileType}`}
              onClick={e => e.stopPropagation()}
              href={`${
                process.env.REACT_APP_API_ENDPOINT[
                  process.env.REACT_APP_API_ENDPOINT.length - 1
                ] == '/'
                  ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                  : process.env.REACT_APP_API_ENDPOINT
              }/api/project/${this.props.projectId}/${this.props.stageName}/${
                row.job_id
              }/${file}/download`}
              key={`${row.job_id}-${index}`}
              id={`fileLink-${row.job_id}-${fileType}`}
              size="md"
              // color={formatConfig.colorButton}
              // className="text-white"
              color="light">
              <div>
                {formatConfig.label}
                <i className={`${formatConfig.fileIcon} ml-1`} />
                {row.is_amended && (
                  <span className="text-muted ml-1">
                    ({translate('amended')})
                  </span>
                )}
              </div>
              <UncontrolledTooltip
                boundariesElement="window"
                fade={false}
                target={`fileLink-${row.job_id}-${fileType}`}
                delay={0}>
                {formatConfig.label}
              </UncontrolledTooltip>
            </DropdownItem>
          );
        }
      }
    };

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = translate => [
      {
        id: 'job_id',
        accessor: 'job_id',
        Header: translate('serial_number'),
        width: 15,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          let rowNumber = (page - 1) * sizePerPage + (row.index + 1);

          return <div>{rowNumber}</div>;
        }
      },
      {
        id: 'rules_check',
        accessor: 'rules_check',
        Header: translate('rules'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const rulesCompleted = row.original.rules_completed || [];
          const rulesChecked = row.original.rules_check || [];
          return (
            <>
              <div id={`jobId-${row.original.job_id}`} type="link">
                {`${translate('total_rules_checked')}: `}
                <span className="font-weight-bold">{rulesChecked.length}</span>
                {!['PENDING', 'DONE'].includes(row.original.status) &&
                  row.original.rules_completed && (
                    <RulesCheck row={row.original} />
                  )}
              </div>
              <UncontrolledPopover
                boundariesElement="window"
                trigger="hover"
                className="rule-popover-container"
                target={`jobId-${row.original.job_id}`}>
                <PopoverHeader>{`${translate('rules')} (${
                  rulesChecked.length
                })`}</PopoverHeader>
                <PopoverBody className={styles.scrollableStyles}>
                  {rulesChecked.map((entry, key) => {
                    const ruleId = Object.keys(entry)[0];
                    const ruleName = Object.values(entry)[0];
                    const ruleCompleted = rulesCompleted.find(
                      r => r.rule_id === ruleId
                    );
                    return (
                      <div key={key} className="mb-2">
                        {(ruleCompleted && ruleCompleted.completed) ||
                        row.original.status === 'DONE' ? (
                          <i className="fas fa-check text-success" />
                        ) : (ruleCompleted &&
                            ruleCompleted.completed === false) ||
                          ['ON_ERROR', 'USER_CANCELLED'].includes(
                            row.original.status
                          ) ? (
                          <i className="fas fa-times text-danger" />
                        ) : (
                          <i className="fas fa-spinner fa-spin mr-2" />
                        )}

                        {ruleName}
                      </div>
                    );
                  })}
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        }
      },
      {
        id: 'date_created',
        accessor: 'date_created',
        Header: translate('date_submitted'),
        Cell: ({ row, value }) => {
          // TODO add more information
          return (
            <Container className="d-flex">
              <div id={`jobId-${row.original.job_id}-info`}>
                <Row>
                  <div>
                    {dateFormatting(row.original.date_created, 'defaultHour')}
                  </div>
                </Row>
              </div>

              <UncontrolledPopover
                boundariesElement="window"
                trigger="hover"
                className="rulecheck-info-popover-container"
                target={`jobId-${row.original.job_id}-info`}>
                <PopoverBody className={styles.scrollableStyles}>
                  {row.original.date_created && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('date_submitted')}:{' '}
                      </div>
                      <div>
                        {dateFormatting(
                          row.original.date_created,
                          'defaultHour'
                        )}
                      </div>
                    </div>
                  )}
                  {row.original.date_completed && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('date_completed')}:{' '}
                      </div>
                      <div>
                        {dateFormatting(
                          row.original.date_completed,
                          'defaultHour'
                        )}
                      </div>
                    </div>
                  )}
                  {row.original.type && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('type')}:{' '}
                      </div>
                      <div>{translate(row.original.type)}</div>
                    </div>
                  )}
                  {row.original.requested_by && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('requested_by')}{' '}
                      </div>
                      <div>{row.original.requested_by}</div>
                    </div>
                  )}

                  {/* TODO get result interpretation */}
                  {/* {row.result_entries && (
                  <div className="d-flex">
                    <div className="font-weight-bold mr-1">
                      {translate('date_created')}:{' '}
                    </div>
                    <div>{dateFormatting(row.date_created, 'defaultHour')}</div>
                  </div>
                )} */}
                </PopoverBody>
              </UncontrolledPopover>
            </Container>
          );
          // return dateFormatting(cell, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'date_completed',
        accessor: 'date_completed',
        Header: translate('date_completed'),
        Cell: ({ row, value }) => {
          // TODO add more information
          return (
            <Container className="d-flex">
              <div id={`jobId-${row.original.job_id}-info`}>
                {!!row.original.date_completed && (
                  <Row>
                    <div>
                      {dateFormatting(
                        row.original.date_completed,
                        'defaultHour'
                      )}
                    </div>
                  </Row>
                )}
              </div>

              <UncontrolledPopover
                boundariesElement="window"
                trigger="hover"
                className="rulecheck-info-popover-container"
                target={`jobId-${row.original.job_id}-info`}>
                <PopoverBody className={styles.scrollableStyles}>
                  {row.original.date_created && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('date_submitted')}:{' '}
                      </div>
                      <div>
                        {dateFormatting(
                          row.original.date_created,
                          'defaultHour'
                        )}
                      </div>
                    </div>
                  )}
                  {row.original.date_completed && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('date_completed')}:{' '}
                      </div>
                      <div>
                        {dateFormatting(
                          row.original.date_completed,
                          'defaultHour'
                        )}
                      </div>
                    </div>
                  )}
                  {row.original.type && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('type')}:{' '}
                      </div>
                      <div>{translate(row.original.type)}</div>
                    </div>
                  )}
                  {row.original.requested_by && (
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('requested_by')}{' '}
                      </div>
                      <div>{row.original.requested_by}</div>
                    </div>
                  )}

                  {/* TODO get result interpretation */}
                  {/* {row.result_entries && (
                  <div className="d-flex">
                    <div className="font-weight-bold mr-1">
                      {translate('date_created')}:{' '}
                    </div>
                    <div>{dateFormatting(row.date_created, 'defaultHour')}</div>
                  </div>
                )} */}
                </PopoverBody>
              </UncontrolledPopover>
            </Container>
          );
          // return dateFormatting(cell, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'requested_by',
        accessor: 'requested_by',
        Header: translate('submitted_by'),

        Cell: ({ row, value }) => {
          return (
            <Container className="d-flex">
              <div
                className={styles.requested_by_font_size}
                id={`jobId-${row.original.job_id}-requestedBy`}>
                <span className="pr-3">
                  {<i className="far fa-user text-success mr-2" />}
                  {row.original.requested_by}
                </span>
              </div>

              {row.original.requested_by_email && (
                <UncontrolledPopover
                  boundariesElement="window"
                  trigger="hover"
                  className="rulecheck-info-popover-container"
                  target={`jobId-${row.original.job_id}-requestedBy`}>
                  <PopoverBody className={styles.scrollableStyles}>
                    <div className="d-flex">
                      <div className="font-weight-bold mr-1">
                        {translate('email')}{' '}
                      </div>
                      <div>{row.original.requested_by_email}</div>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </Container>
          );
        },
        Filter: ({ column }) => (
          <TextColumnFilter
            placeholder={translate('requested_by')}
            column={column}
            filters={filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'status',
        accessor: 'status',
        Header: translate('status'),
        Cell: ({ row, value }) => {
          return <JobStatusPopUpInfo jobInfo={row.original} t={this.props.t} />;
          // return (
          //   <JobStatusPopUpInfo jobId={row} t={this.props.t} />
          //   // <>
          //   //   {statusToIcon(row, this.props.t)}
          //   //   <span className="text-secondary px-3">
          //   //     {row.is_obsolete ? translate('obsolete') : ''}
          //   //   </span>
          //   // </>
          // );
        },

        Filter: ({ column }) => {
          return (
            <SelectColumnFilter
              column={column}
              options={selectOptionsStatus(this.props.t)}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'fileavailable',
        accessor: 'fileavailable',
        Header: translate('result_files'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <>
              {value && (
                <UncontrolledDropdown>
                  <DropdownToggle outline color="primary" caret>
                    <i className="fas fa-download mr-1"></i>
                    {translate('dl_files')}
                  </DropdownToggle>
                  <DropdownMenu
                    boundariesElement="window"
                    positionFixed={true}
                    modifiers={{
                      preventOverflow: { boundariesElement: 'window' },
                      flip: { boundariesElement: 'window' }
                    }}>
                    {Array.isArray(value) &&
                      value.map((file, index) => {
                        return (
                          <div key={`${row.original.job_id}-${index}`}>
                            {fileTypeFormatting(
                              row.original,
                              file,
                              index,
                              translate
                            )}
                          </div>
                        );
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </>
          );
        }
      },
      {
        id: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Header: translate('actions'),
        Cell: ({ row, value }) => {
          switch (row.original.status) {
            case 'DONE':
              if (row.original.type !== 'bimdata') {
                return (
                  <div className="d-flex justify-content-center">
                    {!row.original.is_obsolete && (
                      <>
                        {row.original.fxrViewerTokenList &&
                        row.original.fxrViewerTokenList.length > 0 ? (
                          <div>
                            <UncontrolledTooltip
                              boundariesElement="window"
                              fade={false}
                              target={`dropdown-open-viewer-${row.original.job_id}`}
                              // delay={0}
                            >
                              {translate('open_result_in_viewer')}
                            </UncontrolledTooltip>
                            <UncontrolledDropdown
                              id={`dropdown-open-viewer-${row.original.job_id}`}>
                              <DropdownToggle outline color="primary" caret>
                                <i className="fas fa-building"></i>
                              </DropdownToggle>
                              <DropdownMenu
                                boundariesElement="window"
                                positionFixed={true}
                                modifiers={{
                                  preventOverflow: {
                                    boundariesElement: 'window'
                                  },
                                  flip: { boundariesElement: 'window' }
                                }}>
                                {row.original.fxrViewerTokenList.map(
                                  (current, index) => {
                                    return (
                                      <Button
                                        id={`open-viewer-${row.original.job_id}`}
                                        color="link"
                                        size="md"
                                        tag="a"
                                        href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${current.fxrViewerToken}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        // color={formatConfig.colorButton}
                                        // className="text-white"
                                        disabled={
                                          row.original.is_obsolete
                                            ? true
                                            : false
                                        }>
                                        {current.display_name}
                                      </Button>
                                    );
                                  }
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        ) : (
                          <div>
                            <Button
                              id={`open-viewer-${row.original.job_id}`}
                              color="link"
                              size="md"
                              tag="a"
                              href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.original.fxrViewerToken}`}
                              rel="noopener noreferrer"
                              target="_blank"
                              // color={formatConfig.colorButton}
                              // className="text-white"
                              disabled={
                                row.original.is_obsolete ? true : false
                              }>
                              <i className="fas fa-building"></i>
                            </Button>
                            <UncontrolledTooltip
                              boundariesElement="window"
                              fade={false}
                              target={`open-viewer-${row.original.job_id}`}
                              // delay={0}
                            >
                              {translate('open_result_in_viewer')}
                            </UncontrolledTooltip>
                          </div>
                        )}
                      </>
                    )}

                    {this.props.isJobCancellationEnabled && (
                      <>
                        <RerunButton
                          projectId={this.props.projectId}
                          stageName={this.props.stageName}
                          jobId={row.original.job_id}
                          translate={translate}
                        />

                        <DeleteButton
                          projectId={this.props.projectId}
                          stageName={this.props.stageName}
                          jobId={row.original.job_id}
                          translate={translate}
                        />
                      </>
                    )}
                  </div>
                );
              } else {
                return (
                  <div>
                    {this.props.isJobCancellationEnabled && (
                      <>
                        <RerunButton
                          projectId={this.props.projectId}
                          stageName={this.props.stageName}
                          jobId={row.original.job_id}
                          translate={translate}
                        />

                        <DeleteButton
                          projectId={this.props.projectId}
                          stageName={this.props.stageName}
                          jobId={row.original.job_id}
                          translate={translate}
                        />
                      </>
                    )}
                  </div>
                );
              }

            case 'ON_ERROR':
              return (
                <div className="d-flex justify-content-center">
                  {this.props.isJobCancellationEnabled && (
                    <>
                      <RerunButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                      <DeleteButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                    </>
                  )}
                </div>
              );
            case 'IN_PROCESS':
              return (
                <div className="d-flex justify-content-center">
                  {this.props.isJobCancellationEnabled && (
                    <>
                      <CancelButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                      <DeleteButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                    </>
                  )}
                  {this.props.partialResultButtonEnabled &&
                    row.original.rules_completed &&
                    row.original.rules_completed.length > 0 && (
                      <RequestPartialResultButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                    )}
                </div>
              );
            case 'PENDING':
              return (
                <div className="d-flex justify-content-center">
                  {this.props.isJobCancellationEnabled && (
                    <>
                      <CancelButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                      <DeleteButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                    </>
                  )}
                </div>
              );
            case 'USER_CANCELLED':
              return (
                <div className="d-flex justify-content-center">
                  {this.props.isJobCancellationEnabled && (
                    <>
                      <DeleteButton
                        projectId={this.props.projectId}
                        stageName={this.props.stageName}
                        jobId={row.original.job_id}
                        translate={translate}
                      />
                    </>
                  )}
                </div>
              );
            default:
              return <> </>;
          }
        }
      }
    ];

    return (
      <div>
        <div className="text-right pb-2">
          <div className="d-flex flex-row-reverse">
            <Button
              hidden={!(Object.keys(filters).length > 0)}
              outline
              size="sm"
              color="secondary"
              onClick={() => this.clearFilter()}>
              <i className="fas fa-times mr-2"></i>
              {t('clear_filters')}
            </Button>
          </div>
        </div>

        <ReactTable
          columns={reactTableColumns(t)}
          data={projectStageResultList || jobsData}
          striped
          hover
          bordered
          pagination={paginationOptions}
          sortByFunc={setOrderBy}
          defaultSorted={defaultSorted()}
          noDataIndication={() => NoData(t)}
          loading={!isLoaded}
          remote={{ pagination: true, filter: true, sort: true }}
          overlay={loading =>
            ({ children }) => {
              return (
                <div className="position-relative">
                  {children}
                  {loading && (
                    <div
                      className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.tableOverlayPosition}`}>
                      <div
                        className={`spinner-border text-primary ${styles.table_overlay_dimension}`}
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
        />

        <ModalHTMLReport
          {...this.props}
          isOpen={this.state.isModalHTMLReportOpen}
          projectId={projectId}
          toggleModal={this.toggleModal}
          activeResultData={this.state.activeResultData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    data,
    t,
    project_stage_results,
    projectId,
    stageName,
    currentJobType,
    activeJobType
  } = ownProps;

  const project = state.project_stage_results[projectId];

  const stage = project && project.stages[stageName];

  const jobs = project && stage && stage.jobs;

  const jobsPerType = project && stage && jobs && jobs[activeJobType];
  return {
    projectStageResultList: (project && stage && jobs && jobsPerType) || [],
    partialResultButtonEnabled:
      state.app &&
      state.app.featureList &&
      state.app.featureList.partialResultButtonEnabled
  };
};

export default compose(
  withTranslation(['results']),
  connect(mapStateToProps)
)(ProjectResultPresenter);
