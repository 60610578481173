import React, { Component } from 'react';
import {
  Container,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Link /* , withRouter */ } from 'react-router-dom';
import { getURLParameters } from '../../../../utils/url';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isEqual } from 'lodash';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import styles from './Project.module.css';

import { dateFormatting } from '../../../../locales/dateFormat';
import withXLSExport from '../../../Shared/WithXLSExport';
import {
  editMultipleProjectAsAdmin,
  fetchProjectListAsAdmin,
  resetProjects,
  fetchAllowedProjectSizeConfig
} from '../../../../store/actions';

import { createMarks } from '../exportToFileUtil';
import prettyBytes from 'pretty-bytes';
import ReactTable from '../../../Shared/ReactTable/index.jsx';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateRangeColumnFilter
} from '../../../Shared/ReactTable/columnFilters';

import ProjectDiskUsage from '../../Project/ProjectDiskUsage';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const customTotal = (from, to, size) => (
  <span className="ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: 'checkbox'
};

const mapToTable = mapData => {
  return Object.keys(mapData).map(key => {
    const data = mapData[key];
    return { id: key, ...data };
  });
};

const defaultSorted = () => {
  const { f, d } = getURLParameters(window.location.href);
  return [
    {
      dataField: f || 'date_updated',
      order: d || 'desc'
    }
  ];
};

class ListingPresenter extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      filters: {},
      collapse: {},
      selectedProjectIds: [],
      formats: ['xlsx', 'csv'],
      dropdownOpen: false,
      exportModalOpen: false,
      defaultExportRange: [0, 0],
      exportRange: [0, 0],
      exportFileRange: [0, 0],
      exportFormat: 'csv',
      order: { field: 'date_updated', direction: 'desc' },
      loadingItemToExport: false
    };
  }

  componentDidMount() {
    this.props.fetchAllowedProjectSizeConfig().catch(() => false);
    const { p, spp, f, d, ...rest } = getURLParameters(window.location.href);
    const filters = Object.keys(rest).reduce((acc, cur) => {
      if (cur === 'date_created' || cur === 'date_updated') {
        return { ...acc, [cur]: JSON.parse(decodeURIComponent(rest[cur])) };
      }
      return { ...acc, [cur]: decodeURIComponent(rest[cur]) };
    }, {});
    this.setState(
      {
        page: parseInt(p) || 1,
        sizePerPage: parseInt(spp) || 10,
        filters: { ...filters },
        order: { field: f || 'date_updated', direction: d || 'desc' }
      },
      () => {
        this.fetchProjects();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, sizePerPage, filters, order } = this.state;
    if (
      page !== prevState.page ||
      sizePerPage !== prevState.sizePerPage ||
      !isEqual(filters, prevState.filters) ||
      !isEqual(order, prevState.order)
    ) {
      this.fetchProjects();
    }
  }

  componentWillUnmount() {
    this.props.resetProjects();
  }

  fetchProjects() {
    this.setState({ isLoaded: false });
    const { filters, page, order, sizePerPage } = this.state;
    const from = (page - 1) * sizePerPage;
    const to = page * sizePerPage;

    const newfilters = Object.keys(filters).reduce((result, filterKey) => {
      const newKey = filterKey === 'members' ? 'memberNameOrEmail' : filterKey;
      return {
        ...result,
        [newKey]: filters[filterKey]
      };
    }, {});
    this.props
      .fetchProjectList(newfilters, order, {
        from,
        to
      })
      .then(totalSize => {
        this.setState(
          {
            isLoaded: true,
            totalSize,
            exportRange: [from + 1, to >= totalSize ? totalSize : to],
            exportFileRange: [from, to >= totalSize ? totalSize : to],
            defaultExportRange: [from, to]
          },
          () => {
            this.props.history.replace(
              `?p=${page}&spp=${sizePerPage}&f=${order.field}&d=${
                order.direction
              }${this.filterToURLString(newfilters)}`
            );
          }
        );
      });
  }

  filterToState = (column, value) => {
    const { filters } = this.state;
    if (value) {
      this.setState({
        filters: { ...filters, [column]: value }
      });
    } else {
      this.setState({
        filters: {
          ...Object.keys(filters).reduce((acc, cur) => {
            if (column === cur) {
              return { ...acc };
            }
            return { ...acc, [cur]: filters[cur] };
          }, {})
        }
      });
    }
  };

  filterToURLString(filters) {
    const URLArray = Object.keys(filters).map(res => {
      if (res === 'date_created' || res === 'date_updated') {
        return `${res}=${JSON.stringify(filters[res])}`;
      }
      return `${res}=${filters[res]}`;
    });
    return URLArray.length > 0 ? `&${URLArray.join('&')}` : '';
  }

  exportToXLS = async (format, range) => {
    // // TODO: improve by filtering column and adding line selection
    // const filtered_by_selected =
    //   this.state.selectedProjectIds.length > 0
    //     ? this.props.projects.filter(elem =>
    //         this.state.selectedProjectIds.find(id => elem.id === id)
    //       )
    //     : this.props.projects;
    const { filters, order } = this.state;
    const newfilters = Object.keys(filters).reduce((result, filterKey) => {
      const newKey = filterKey === 'members' ? 'memberNameOrEmail' : filterKey;
      return {
        ...result,
        [newKey]: filters[filterKey]
      };
    }, {});

    const filtered_by_selected = await this.props.fetchProjectList(
      newfilters,
      order,
      {
        from: range[0],
        to: range[1]
      },
      false
    );

    const finalData = mapToTable(filtered_by_selected).map(project => {
      const format_owners = project.members
        .filter(m => m.role === 'owner')
        .map(owner => {
          return `${owner.name} (${owner.email})`;
        })
        .join(', ');

      return {
        Name: project.name,
        'Updated date': dateFormatting(project.date_updated, 'defaultHour'),
        'Creation date': dateFormatting(project.date_created, 'defaultHour'),
        Owners: format_owners
      };
    });

    this.props.exportToXLS(finalData, 'json', {
      fileName: `projects_export.${Date.now()}.${format}`,
      bookType: format,
      type: format
    });
  };

  openExportModal = format =>
    this.setState({ exportModalOpen: true, exportFormat: format });

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleCollapse = field => {
    const { collapse } = this.state;
    this.setState({ collapse: { ...collapse, [field]: !collapse[field] } });
  };

  closeCollapse = () => {
    this.setState({ collapse: {} });
  };

  clearFilter = () => {
    this.setState({
      isLoaded: false,
      filters: {},
      order: { field: 'date_updated', direction: 'desc' },
      page: 1,
      sizePerPage: 10
    });
    this.closeCollapse();
  };

  render() {
    const {
      isLoaded,
      page,
      sizePerPage,
      totalSize,
      collapse,
      filters,
      exportModalOpen,
      defaultExportRange,
      exportRange,
      exportFileRange,
      exportFormat,
      loadingItemToExport
    } = this.state;
    const { projects, t } = this.props;

    const setOrderBy = data => {
      this.setState({
        order: {
          field: data.id,
          direction: data.sortOrder
        }
      });
    };

    const reactTableColumns = t => [
      {
        id: 'name',
        accessor: 'name',
        Header: t('name'),
        Cell: ({ row, value }) => {
          return (
            <div className={styles.listingOverflow}>
              <Link to={`/admin/project/${row.original.project_id}/details`}>
                {value}
              </Link>
            </div>
          );
        },
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              placeholder={t('project_name')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'date_updated',
        accessor: 'date_updated',
        Header: t('updated_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      {
        id: 'date_created',
        accessor: 'date_created',
        Header: t('creation_date'),
        Cell: ({ row, value }) => {
          return dateFormatting(value, 'defaultHour');
        },
        Filter: ({ column }) => (
          <DateRangeColumnFilter
            column={column}
            filters={this.state.filters}
            filterToState={this.filterToState}
          />
        )
      },
      // { dataField: 'status', text: t('status'), headerClasses: 'align-top' },
      {
        id: 'members',
        accessor: 'members',
        Header: t('owners'),
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const owners = value.filter(m => m.role === 'owner');
          if (owners.length === 1) {
            return `${owners[0].name} (${owners[0].email})`;
          } else if (owners.length > 1) {
            return (
              <>
                <div id={`project-owner-${row.original.project_id}`}>
                  {owners.length} {t('owners')}
                </div>
                <UncontrolledPopover
                  boundariesElement="window"
                  trigger="hover"
                  className="owner-project--container"
                  target={`project-owner-${row.original.project_id}`}>
                  <PopoverHeader>{t('owners')}</PopoverHeader>
                  <PopoverBody className="d-flex flex-column">
                    {owners.map((owner, index) => {
                      return (
                        <div
                          key={`owner-${index}`}>{`${owner.name} (${owner.email})`}</div>
                      );
                    })}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            );
          } else {
            return <></>;
          }
        },
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              placeholder={t('project_name')}
              column={column}
              filters={this.state.filters}
              filterToState={this.filterToState}
            />
          );
        }
      },
      {
        id: 'actions',
        Header: '',
        width: 25,
        Cell: ({ row, value }) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="muted" className="w-100">
                  <i className="fas fa-ellipsis-h" />
                </DropdownToggle>

                <DropdownMenu>
                  <Link
                    className="text-decoration-none"
                    to={`/admin/project/${row.original.project_id}/details`}>
                    <DropdownItem>{t('view_details')}</DropdownItem>{' '}
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      },
      {
        id: 'total_file_size',
        // accessor: 'total_file_size',
        Header: t('total_file_size'),
        Cell: ({ row, value }) => {
          return (
            <div className={styles.listingOverflow}>
              <ProjectDiskUsage
                project={row.original}
                {...this.props}
                displayAlongProjectList={true}
              />
            </div>
          );
        }
      }
    ];

    const paginationOptions = {
      page,
      sizePerPage,
      totalSize,
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Prev',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'First page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: (newPage, newSizePerPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      },
      onSizePerPageChange: (newSizePerPage, newPage) => {
        this.setState({ page: newPage, sizePerPage: newSizePerPage });
      }
    };

    const NoData = () => {
      if (isLoaded) {
        return <h1>NO DATA</h1>;
      }
      return <></>;
    };

    return (
        <Container fluid className="mx-3 mt-4">
          <div className="shadow p-3 mb-5 bg-light rounded">
            <div className="mt-2">
              <h3 className="text-primary">{t('admin_user:project_list')}</h3>
            </div>
            <div className="text-right pb-2">
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-1"
                  hidden={!(Object.keys(filters).length > 0)}
                  outline
                  size="md"
                  color="secondary"
                  onClick={() => this.clearFilter()}>
                  <i className="fas fa-times"></i> Clear Filters
                </Button>
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}>
                  <DropdownToggle
                    color="success"
                    outline
                    disabled={projects.length === 0}>
                    <i className="fas fa-file-export mr-1" />
                    Export to
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.formats.map((format, i) => {
                      return (
                        <DropdownItem
                          key={`export-format-${i}`}
                          onClick={() => {
                            // this.exportToXLS(format);
                            this.openExportModal(format);
                          }}>
                          <i
                            className={`far fa-file-excel mr-2 ${styles.export_dropdown_color}`}
                            color="primary"
                          />
                          {format}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <ReactTable
              columns={reactTableColumns(t)}
              data={projects}
              hover
              bordered={false}
              pagination={paginationOptions}
              sortByFunc={setOrderBy}
              defaultSorted={defaultSorted()}
              noDataIndication={NoData()}
              loading={!isLoaded}
              remote={{ pagination: true, filter: true, sort: true }}
              overlay={loading =>
                ({ children }) => {
                  return (
                    <div className="position-relative">
                      {children}
                      {loading && (
                        <div
                          className={`w-100 h-100 d-flex justify-content-center position-absolute rounded align-items-center ${styles.table_overlay_position}`}>
                          <div
                            className={`spinner-border text-primary ${styles.table_overlay_dimension}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
            />
          </div>
          <Modal
            isOpen={exportModalOpen}
            size="lg"
            toggle={() =>
              this.setState(prevState => ({
                exportModalOpen: !prevState.exportModalOpen
              }))
            }
            className="">
            <ModalHeader
              toggle={() =>
                this.setState(prevState => ({
                  exportModalOpen: !prevState.exportModalOpen
                }))
              }>
              {t('main:export_records')}
            </ModalHeader>
            <ModalBody>
              <div>{t('main:choose_records_to_export')}</div>
              <div className={styles.modal_size_and_margin}>
                <Range
                  min={1}
                  max={totalSize}
                  marks={createMarks(totalSize)}
                  // allowCross={false}
                  defaultValue={defaultExportRange}
                  value={exportRange}
                  pushable={1}
                  onChange={newRange => {
                    this.setState({
                      exportRange: newRange,
                      exportFileRange: [newRange[0] - 1, newRange[1]]
                    });
                  }}
                />
              </div>
              <div className="text-muted font-italic">
                *Note : only filtered items are included
              </div>
              <div>
                Export items {exportRange[0]} to {exportRange[1]} to a{' '}
                {exportFormat} file.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loadingItemToExport}
                onClick={async () => {
                  this.setState({ loadingItemToExport: true });
                  await this.exportToXLS(exportFormat, exportFileRange);
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }));
                  this.setState({ loadingItemToExport: false });
                }}>
                {loadingItemToExport && (
                  <span
                    className="mr-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t('main:export')}
              </Button>{' '}
              <Button
                color="secondary"
                disabled={loadingItemToExport}
                onClick={() =>
                  this.setState(prevState => ({
                    exportModalOpen: !prevState.exportModalOpen
                  }))
                }>
                {t('main:cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: mapToTable(state.projects)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editProjects: (projectList, dataUpdated) =>
      dispatch(editMultipleProjectAsAdmin(projectList, dataUpdated)),
    fetchProjectList: (filters, order, pagination, toDispatch) =>
      dispatch(fetchProjectListAsAdmin(filters, order, pagination, toDispatch)),
    resetProjects: () => dispatch(resetProjects()),
    fetchAllowedProjectSizeConfig: () =>
      dispatch(fetchAllowedProjectSizeConfig())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['admin_project', 'main']),
  withXLSExport
)(ListingPresenter);
