import React, { useState } from 'react';
import styles from './styles.module.css';

const NewsItem = ({ news, timeAgoFormatting }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggle = () => setIsExpanded(!isExpanded);
  return (
    <div className="border border-secondary rounded p-3 my-2">
      <h4 className="news-title-color">{news.title}</h4>
      <div className={`font-weight-light ${styles.newsInformation}`}>
        <small>
          <span>{news.full_name}</span> |{' '}
          <span>{timeAgoFormatting(news.created_at)}</span>
        </small>
      </div>

      <div
        className={`font-weight-light py-3 ${
          isExpanded ? styles.wrapNewsText : styles.newsBodyEllipsis
        }`}>
        {news.body}
      </div>

      <div className="d-flex justify-content-end">
        <div
          onClick={toggle}
          className={`${styles.newsToggleBtn} p-3 m-3`}>
          <i
            className={`news-toggle-btn-icon-color fas fa-chevron-${
              isExpanded ? 'up' : 'down'
            } text-light`}></i>
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
