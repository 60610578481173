import React, {useEffect, useRef} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { Card, CardTitle, CardText, Row, Col } from 'reactstrap';

import STATCONFIG from './settings';

const COLORS = STATCONFIG.colors;

const graphSize = statResult => {
  const count = statResult ? statResult.length : 0;
  switch (true) {
    case count <= 5:
      return 350;
    case count <= 10:
      return 700;
    case count <= 15:
      return 1050;
    case count <= 20:
      return 1400;
    case count <= 25:
      return 1750;
    case count <= 30:
      return 2100;
    case count <= 40:
      return 2800;
    case count <= 50:
      return 3500;
    case count <= 75:
      return 5250;
    case count <= 100:
      return 7000;
    default:
      return 700;
  }
};

const HorizontalBarGraph = props => {
  const { t, data, flatStatType, statSettings } = props;
  const { statResult, nbCheck, axisLabel } = data;
  const { count, criteria } = statSettings;

  return (
    <>
      <ResponsiveContainer width="100%" height={graphSize(statResult)}>
        <BarChart height={250} data={statResult} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis
            width={200}
            label={{
              value: `${t(axisLabel)}`,
              position: 'insideTop'
            }}
            dataKey="dataKey"
            type="category"
          />
          <Tooltip />
          {STATCONFIG[flatStatType][statSettings.criteria].countKey.map(
            (res, index) => {
              return (
                <Bar
                  background
                  barSize={20}
                  animationDuration={2000}
                  label={{ fill: 'white', fontSize: 15 }}
                  dataKey={res}
                  fill={COLORS[index]}
                />
              );
            }
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const VerticalBarGraph = props => {
  const { t, data, flatStatType, statSettings } = props;
  const { statResult, nbCheck, axisLabel } = data;
  const { count, criteria } = statSettings;

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          height={250}
          data={statResult}
          margin={{ top: 15, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={50}
            label={{
              value: `${t(axisLabel)}`,
              position: 'insideBottom'
            }}
            dataKey="dataKey"
            scaleToFit={true}
          />
          <YAxis type="number" />
          <Tooltip />
          {STATCONFIG[flatStatType][statSettings.criteria].countKey.map(
            (res, index) => {
              return (
                <Bar
                  background
                  animationDuration={2000}
                  label={{ fill: 'white', fontSize: 15 }}
                  dataKey={res}
                  fill={COLORS[index]}>
                  <LabelList
                    dataKey="dataKey"
                    offset={0}
                    fontSize={15}
                    position="top"
                  />
                </Bar>
              );
            }
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const LineGraph = props => {
  const { t, data, flatStatType, statSettings } = props;
  const { statResult, nbCheck, axisLabel } = data;
  const { count, criteria } = statSettings;

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          height={250}
          data={statResult}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={50}
            label={{
              value: `${t(axisLabel)}`,
              position: 'insideBottom'
            }}
            dataKey="dataKey"
            scaleToFit={true}
          />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          {STATCONFIG[flatStatType][statSettings.criteria].countKey.map(
            (res, index) => {
              return (
                <Line
                  type="monotone"
                  animationDuration={2000}
                  dataKey={res}
                  stroke={COLORS[index]}
                />
              );
            }
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

const AreaGraph = props => {
  const { t, data, flatStatType, statSettings } = props;
  const { statResult, nbCheck, axisLabel } = data;
  const { count, criteria } = statSettings;

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart
          height={250}
          data={statResult}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={50}
            label={{
              value: `${t(axisLabel)}`,
              position: 'insideBottom'
            }}
            dataKey="dataKey"
            scaleToFit={true}
          />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          {STATCONFIG[flatStatType][statSettings.criteria].countKey.map(
            (res, index) => {
              return (
                <Area
                  type="monotone"
                  animationDuration={2000}
                  fillOpacity={0.2}
                  dataKey={res}
                  stroke={COLORS[index]}
                  fill={COLORS[index]}
                />
              );
            }
          )}
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

const PieGraph = props => {
  const { t, data, flatStatType, statSettings } = props;
  const { statResult, nbCheck, axisLabel } = data;
  const { count, criteria } = statSettings;

  const CustomLabel = data => {
    const { cx, cy, midAngle, innerRadius, outerRadius, index, payload } = data;
    const { dataKey, count } = payload;
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const color = COLORS[index % COLORS.length];
    return (
      <text
        x={x}
        y={y}
        fill={color}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {dataKey} ({count})
      </text>
    );
  };

  const CustomTooltip = data => {
    const { active, payload } = data;
    const cardTextRef = useRef()
    const dataKey = payload[0].payload.dataKey;
    const countKey = payload[0].dataKey;
    const value = payload[0].value;

    useEffect(() => {
      if(active){
        const index = statResult.findIndex(res => res.dataKey === dataKey);
        const color = COLORS[index % COLORS.length];
        cardTextRef.current.style['color'] = color;
      }
    },[active])

    if (active) {
      return (
        <>
          <Card body>
            <CardTitle className="m-0 p-0">{dataKey}</CardTitle>
            <p
              ref={cardTextRef}
              className="card-text m-0 p-0">
                {`${countKey} : ${value}`}
            </p>
          </Card>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <PieChart margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <Tooltip content={<CustomTooltip />} />

          {STATCONFIG[flatStatType][statSettings.criteria].countKey.map(
            (res, index) => {
              return (
                <Pie
                  data={statResult}
                  dataKey={res}
                  label={CustomLabel}
                  key={index}>
                  {statResult
                    ? statResult.map((ret, index1) => (
                        <Cell
                          key={index1}
                          fill={COLORS[index1 % COLORS.length]}
                        />
                      ))
                    : ''}
                </Pie>
              );
            }
          )}
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

const AutoCheckGraphs = props => {
  const { graph } = props;

  switch (graph) {
    case 'bar_horizontal':
      return <>{HorizontalBarGraph(props)}</>;
    case 'bar_vertical':
      return <>{VerticalBarGraph(props)}</>;
    case 'line_graph':
      return <>{LineGraph(props)}</>;
    case 'pie_chart':
      return <>{PieGraph(props)}</>;
    case 'area_graph':
      return <>{AreaGraph(props)}</>;
    default:
      return <>{HorizontalBarGraph(props)}</>;
  }
};

export default withTranslation(['statistics'])(AutoCheckGraphs);
