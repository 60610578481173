import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  CustomInput,
  Col
} from 'reactstrap';

import { withTranslation } from 'react-i18next';

class ProjectStageCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isOpen,
      toggleModal,
      t,
      projectId,
      stageName,
      job_id,
      file,
      activeResultData
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop={'static'}>
        <ModalHeader toggle={toggleModal}>HTML Report </ModalHeader>
        <ModalBody> {t('selection_portal')}</ModalBody>
        <ModalFooter>
          <Button
            tag="a"
            rel="noopener noreferrer"
            target="_blank"
            href={`${
              process.env.REACT_APP_API_ENDPOINT[
                process.env.REACT_APP_API_ENDPOINT.length - 1
              ] == '/'
                ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                : process.env.REACT_APP_API_ENDPOINT
            }/api/webviewer/project/${projectId}/${stageName}/${
              activeResultData.job_id
            }/${activeResultData.fileName}/view`}
            color="primary">
            {t('open_in_portal')}
          </Button>{' '}
          <Button
            color="secondary"
            tag="a"
            href={`${
              process.env.REACT_APP_API_ENDPOINT[
                process.env.REACT_APP_API_ENDPOINT.length - 1
              ] == '/'
                ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                : process.env.REACT_APP_API_ENDPOINT
            }/api/project/${projectId}/${stageName}/${
              activeResultData.job_id
            }/${activeResultData.fileName}/download`}>
            {t('download_only')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default withTranslation(['results'])(ProjectStageCreation);
