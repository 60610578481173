import React, { useEffect, useState, useRef } from 'react';

import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem,
  Badge
} from 'reactstrap';

import { compose } from 'recompose';
import { connect } from 'react-redux';

import { Formik, Form, Field } from 'formik';

import CustomSelectInput from '../../../UI/Input/customSelectInput';

import filter from 'lodash/filter';

const settings = t => {
  return {
    HIDE: {
      id: 1,
      label: t('rules:hide')
    },
    SUMMARY: {
      id: 2,
      label: t('rules:summary')
    },
    DETAILED: {
      id: 3,
      label: t('rules:detailed')
    }
  };
};

const getChildRules = item => {
  if (item.children) {
    return item.children.reduce((previous, current) => {
      if (current.children) {
        return Array.from(new Set([...previous, ...getChildRules(current)]));
      } else if (current.initialKey) {
        return Array.from(new Set([...previous, current.initialKey]));
      } else {
        return previous;
      }
    }, []);
  } else if (item.initialKey) {
    return [item.initialKey];
  } else {
    return [];
  }
};

const Mode = ({
  ruleId,
  title,
  treeChildren,
  rule,
  rules,
  t,
  setTouched,
  onUpdatePackageCMMode,
  onUpdateRuleCMMode,
  item,
  path,
  ownIndex,
  allRulesCMMode,
  packageKey
}) => {
  let compliant_message_mode = '';
  const params = (rule && rule.params) || {};
  if (!ruleId && !!treeChildren) {
    const allPackageRuleId = treeChildren.reduce((previous, current) => {
      return Array.from(new Set([...previous, ...getChildRules(current)]));
    }, []);

    const allPackageRules = allPackageRuleId.reduce((previous, ruleId) => {
      if (rules && rules[ruleId]) {
        return { ...previous, [ruleId]: { ...rules[ruleId] } };
      } else {
        return previous;
      }
    }, {});

    const allCAHideMode = filter(
      allPackageRules,
      rule => rule.params && rule.params.compliant_message_mode === 'HIDE'
    );

    const allCASUMMARYMode = filter(
      allPackageRules,
      rule => rule.params && rule.params.compliant_message_mode === 'SUMMARY'
    );

    const allCADETAILEDMode = filter(
      allPackageRules,
      rule => rule.params && rule.params.compliant_message_mode === 'DETAILED'
    );

    if (allCAHideMode.length === allPackageRuleId.length) {
      compliant_message_mode = 'HIDE';
    } else if (allCASUMMARYMode.length === allPackageRuleId.length) {
      compliant_message_mode = 'SUMMARY';
    } else if (allCADETAILEDMode.length === allPackageRuleId.length) {
      compliant_message_mode = 'DETAILED';
    } else {
      compliant_message_mode = '';
    }
  } else {
    compliant_message_mode = params.compliant_message_mode;
  }

  const initialValues = {
    compliant_message_locking: compliant_message_mode
  };

  return (
    <Badge color="light" className="mr-4 mt-0 text-primary">
      {compliant_message_mode}
    </Badge>

    // <Formik initialValues={initialValues} enableReinitialize={true}>
    //   {formikProps => {
    //     const { values, setFieldValue } = formikProps;

    //     return (
    //       <Form>
    //         <div className="mr-4 mt-0">
    //           <Field
    //             name="compliant_message_locking"
    //             type="select"
    //             component={CustomInput}
    //             value={values.compliant_message_locking}
    //             id={ruleId || title}
    //             onChange={async e => {
    //               const compliant_message_mode = e.target.value;
    //               setFieldValue(
    //                 'compliant_message_locking',
    //                 compliant_message_mode
    //               );
    //               setTouched({
    //                 compliant_message_locking: true
    //               });
    //               if (!ruleId && !!treeChildren) {
    //                 await onUpdatePackageCMMode(
    //                   item,
    //                   path,
    //                   ownIndex,
    //                   compliant_message_mode
    //                 );
    //               } else {
    //                 await onUpdateRuleCMMode(
    //                   item,
    //                   path,
    //                   ownIndex,
    //                   compliant_message_mode
    //                 );
    //               }
    //             }}>
    //             <option defaultValue>Select</option>
    //             {Object.keys(settings(t)).map(mode => {
    //               return (
    //                 <option value={mode}>{settings(t)[mode].label}</option>
    //               );
    //             })}
    //           </Field>
    //         </div>
    //       </Form>
    //     );
    //   }}
    // </Formik>
  );
};

const mapStateToProps = (state, props) => {
  if (props.level === 'domain') {
    const rules = state.rules_admin.rules;
    const rule = rules && rules[props.ruleId];
    return {
      rules,
      rule
    };
  } else if (props.level === 'user') {
    const rules = state.rules.rulesSet;
    const rule = rules[props.ruleId];
    return {
      rules,
      rule
    };
  } else if (props.level === 'organization') {
    // TODO
  }
};

export default compose(connect(mapStateToProps))(Mode);
