import React, { useEffect, useRef, useState } from 'react';
import { Table, Container, Button, Row } from 'reactstrap';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { fetchFooterData } from '../../../../store/actions';

import prettyBytes from 'pretty-bytes';

const bytesToSize = size => {
  return prettyBytes(size);
};

const flattenFooterData = (data, category) => {
  const { ...footerData } = data;

  return Object.keys(footerData).reduce((acc, currentKey) => {
    if (currentKey === 'relatedLinks') {
      return acc;
    } else if (category && currentKey !== category) {
      return acc;
    } else {
      const mapVersion = footerData[currentKey].dataList.reduce(
        (accDt, currentDt) => {
          return [
            ...accDt,
            ...((currentDt && currentDt.versions) || []).map(version => {
              return {
                ...version,
                title: currentDt.title,
                description: currentDt.description
              };
            })
          ];
        },
        []
      );
      return {
        ...acc,
        [currentKey]: { ...footerData[currentKey], dataList: mapVersion }
      };
    }
  }, {});
};

const PUBResources = props => {
  const {
    t,
    footerData,
    fetchFooter,
    location: { hash }
  } = props;

  const highlightedRowRef = useRef(null);

  const [filesData, setFilesData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchFooter();
  }, []);

  useEffect(() => {
    setFilesData(flattenFooterData(footerData, ''));

    setIsLoaded(true);
  }, [footerData]);

  return (
    <Container className="">
      <Row className="p-3">
        {' '}
        {isLoaded &&
          Object.keys(filesData).map(categoryKey => {
            const tableData =
              filesData[categoryKey] && filesData[categoryKey].dataList;
            const categoryTitle =
              filesData[categoryKey] && filesData[categoryKey].categoryTitle;
            return (
              <div key={`table-${categoryKey}`}>
                <h4 className="mt-2 text-primary mt-4">
                  {categoryTitle || 'Downloads'}
                </h4>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>{t('front:filename')}</th>
                      <th>{t('front:version')}</th>
                      <th>{t('front:size')}</th>
                      <th>{t('project:description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((link, index) => {
                      const rowSpanCount =
                        index > 0 && tableData[index - 1].title === link.title
                          ? null
                          : tableData.filter(link => {
                              return link.title === tableData[index].title;
                            }).length;
                      const documentType = link.documentType;
                      return (
                        <tr
                          className={`${
                            link.title === hash.slice(1) ? 'bg-light' : null
                          }`}
                          ref={
                            link.title === hash.slice(1)
                              ? highlightedRowRef
                              : null
                          }
                          key={`link-row-${index}`}>
                          <td>
                            <Button
                              tag="a"
                              color="link"
                              className={'px-0 text-left'}
                              download
                              href={`${
                                process.env.REACT_APP_API_ENDPOINT[
                                  process.env.REACT_APP_API_ENDPOINT.length - 1
                                ] == '/'
                                  ? process.env.REACT_APP_API_ENDPOINT.slice(
                                      0,
                                      -1
                                    )
                                  : process.env.REACT_APP_API_ENDPOINT
                              }/api/footer_documents/${link.filename}${
                                documentType
                                  ? `?documentType=${documentType}`
                                  : ''
                              }`}>
                              <span
                                className={`${
                                  link.title === hash.slice(1)
                                    ? 'bg-warning rounded px-2'
                                    : null
                                }`}>
                                {t(`front:${link.filename}`)}
                              </span>
                            </Button>
                          </td>
                          <td>{link.version || t('front:notApplicable')}</td>
                          <td>
                            {typeof link.size === 'number'
                              ? bytesToSize(link.size)
                              : t('front:notApplicable')}
                          </td>
                          {index > 0 &&
                          tableData[index - 1].title === link.title ? null : (
                            <td rowSpan={rowSpanCount}>{link.description}</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            );
          })}
      </Row>
      <Row className="p-3">
        <p className="font-italic">{t('note')}</p>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    footerData: state.app.footerData.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFooter: () => dispatch(fetchFooterData())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['front'])
)(PUBResources);
