export default {
  searchText: null,
  selectedCategory: null,
  selectedCategoryKey: null,
  textCopied: null,
  resultList: {},
  isSearching: false,
  isAddCategoryModalOpen: false,
  newCategoryItems: [],
  newCategoryItemsCount: 0,
  isAddingItemsInExistingCategory: false,
  confirmDeleteCatItemData: {
    isOpen: false,
    categoryId: null,
    categoryItemId: null,
    title: null
  },
  favoriteResultList: {},
  isImportFileModalOpen: false
};
