import { userActionType } from '../../constants/actionType';
const initialState = {};

const {
  MERGE_USERS,
  ADD_USER,
  UPDATE_USER,
  REPLACE_USER,
  RESET_USER,
  UPDATE_USER_AS_ADMIN,
  VERIFY_USER_AS_ADMIN,
  VERIFY_MULTIPLE_USER_AS_ADMIN,
  MERGE_USER_USAGE
} = userActionType;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MERGE_USERS:
      return { ...state, ...action.userMap };
    case ADD_USER: {
      const { user, userId } = action;
      return { ...state, [userId]: user };
    }
    case UPDATE_USER: {
      const { user, userId } = action;
      return { ...state, [userId]: user };
    }
    case REPLACE_USER: {
      return action.userMap;
    }
    case RESET_USER: {
      return initialState;
    }
    case UPDATE_USER_AS_ADMIN: {
      const { newUserData, userId } = action;
      const mergedUserDetailsAndProjects = {
        ...state[userId],
        projects: state[userId].projects,
        user: newUserData
      };

      return { ...state, [userId]: mergedUserDetailsAndProjects };
    }
    case VERIFY_USER_AS_ADMIN: {
      const { newUserData, userId } = action;
      return { ...state, [userId]: newUserData };
    }
    case VERIFY_MULTIPLE_USER_AS_ADMIN: {
      const { newUserData } = action;
      const data = newUserData.reduce((obj, item) => {
        return {
          ...obj,
          [item.user_id]: item
        };
      }, {});
      return {
        ...state,
        ...data
      };
    }
    case MERGE_USER_USAGE: {
      const { userUsageData, userId } = action;

      return {
        ...state,
        [userId]: {
          ...state[userId],
          userUsage: { ...state[userId].userUsage, ...userUsageData }
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
