import React from 'react';

const BimModellingGuide = props => {
  return (
    <>
      <div className="stackedit__html">
        <h1 id="bim-modelling-guide">FORNAX™ BIM Modelling Guide</h1>
        <p>
          This guide is meant to provide users a BIM-related instruction in
          preparing their models to suit the automation requirements available
          within FORNAX™ BIM auto checks.
        </p>
        <p>
          This aims to outline the different approach in handling models
          following the hierarchical structure of IFC.{' '}
        </p>
        {/* <!-- Terminologies start here --> */}
        <h4 id="terminologies-to-note">Terminologies to note:</h4>
        <blockquote>
          <p>
            BIM - Building Information Modelling
            <br />
            IFC - Industry Foundation Classes
            <br />
            FXC - FORNAX™ CLOUD
            <br />
            BCF - BIM Collaboration Format
          </p>
        </blockquote>
        {/* <!-- Terminologies end here --> */}
        {/* <!-- MODELLING IN GENERAL starts here --> */}
        <h2 id="modelling-in-general">MODELLING IN GENERAL</h2>
        <h3 id="architectural-elements">Architectural Elements</h3>
        <img
          width="100%"
          src="assets/help_center/images/architectural-elements-img.jpg"
          alt="architectural-elements"
          title="Architectural Elements"
        />{' '}
        <br />
        <ul>
          <li>
            Use <strong>Wall</strong> tool to model architectural walls, parapet
            and other non-load bearing wall types
          </li>
          <li>
            Use <strong>Door</strong> tool to model external and internal doors
          </li>
          <li>
            Use <strong>Window</strong> tool to model windows
          </li>
          <li>
            Use <strong>Architectural Column</strong> tool to model
            architectural columns (non-load bearing columns)
          </li>
          <li>
            Use <strong>Roof</strong> tool to model roofs
          </li>
          <li>
            Use <strong>Ceiling</strong> tool to model ceilings
          </li>
          <li>
            Use <strong>Floor</strong> tool to model floors
          </li>
          <li>
            Use <strong>Curtain Wall</strong> tool to model curtain system
          </li>
          <li>
            Use <strong>Railing</strong> tool to model railings, fences and
            related barrier to protect from falling
          </li>
          <li>
            Use <strong>Ramp</strong> tool to model ramps for vehicle,
            accessibility or for any change in level. Sloping floors can also be
            an alternative way; however, specifications must follow ramp
            properties to be recognized
          </li>
          <li>
            Use <strong>Stairs</strong> tool to model external and internal
            stairs associated with landing and flight
          </li>
          <li>
            Use <strong>Parking Component</strong> to model internal parking
            lots
          </li>
        </ul>
        <h3 id="site-elements">Site Elements</h3>
        <img
          width="100%"
          src="assets/help_center/images/site-elements-img.jpg"
          alt="site-elements"
          title="Site Elements"
        />
        <ul>
          <br />
          <li>
            Use <strong>Toposurface</strong> tool to model geographical element
            i.e., terrain
          </li>
          <li>
            Use <strong>Floor</strong> tool to model roads, apron slab,
            driveways and other accessways
          </li>
          <li>
            Use <strong>Subregion</strong> tool of toposurface as a substitute
            to model roads and other accessways
          </li>
          <li>
            Use <strong>Parking Component</strong> to model external parking
            lots for site-related components
          </li>
        </ul>
        <h3 id="structural-elements">Structural Elements</h3>
        <img
          width="100%"
          src="assets/help_center/images/structural-elements-img.jpg"
          alt="structural-elements"
          title="Structural Elements"
        />
        <ul>
          {' '}
          <br />
          <li>
            Use <strong>Structural Framing</strong> tool to model beams{' '}
          </li>
          <li>
            Use <strong>Structural Column</strong> tool to model structural
            bearing columns
          </li>
          <li>
            Use <strong>Structural Floor</strong> tool to model structural
            bearing floors
          </li>
          <li>
            Use <strong>Structural Walls</strong> tool to model structural
            bearing walls
          </li>
          <li>
            Use <strong>Structural Foundation</strong> to model footings or pile
            caps
          </li>
        </ul>
        <h3 id="piping-elements">Piping Elements</h3>
        <img
          width="100%"
          src="assets/help_center/images/piping-elements-img.jpg"
          alt="piping-elements"
          title="Piping Elements"
        />
        <ul>
          {' '}
          <br />
          <li>
            {' '}
            Use <strong>Pipe</strong> tool to model rigid pipe segments{' '}
          </li>
          <li>
            {' '}
            Use <strong>Pipe Fitting</strong> tool to model pipe fittings such
            as elbows, tees, wyes, crosses, unions and other type of fittings{' '}
          </li>
          <li>
            {' '}
            Use <strong>Pipe Accessory</strong> tool to model accessories like
            connectors, valves and inline water heaters{' '}
          </li>
          <li>
            {' '}
            Use <strong>Flexible Pipe</strong> tool to model flexible pipe
            segments{' '}
          </li>
          <li>
            {' '}
            Use <strong>Plumbing Fixture</strong> to model fixture like sink,
            water closet, tubs, drains and various appliances{' '}
          </li>
          <li>
            {' '}
            Use <strong>Sprinkler</strong> to model dry or wet sprinklers{' '}
          </li>
        </ul>
        <h3 id="acmv-elements">ACMV Elements</h3>
        <img
          width="100%"
          src="assets/help_center/images/acmv-elements-img.jpg"
          alt="acmv-elements"
          title="ACMV Elements"
        />
        <ul>
          <li>
            Use <strong>Duct</strong> tool to model rigid duct segments
          </li>
          <li>
            Use <strong>Duct Fitting</strong> tool to model duct fittings such
            as elbows, tees, wyes, crosses and other type of fittings
          </li>
          <li>
            Use <strong>Duct Accessory</strong> tool to model accessories like
            dampers, filters and smoke detectors
          </li>
          <li>
            Use <strong>Flex Duct</strong> tool to model flexible duct segments
          </li>
          <li>
            Use <strong>Air Terminal</strong> to model register, grille or
            diffuser
          </li>
        </ul>
        {/* <!-- MODELLING IN GENERAL ends here --> */}
        {/* <!-- NOTE starts here --> */}
        <h4 id="note">
          <strong>Note:</strong>
        </h4>
        <blockquote>
          <p>
            For unique elements that require out-of-the-box modelling technique
            due to the limitation of the software can be modelled in other
            categories following agreed specification to the entities where this
            item will fall in.
          </p>
        </blockquote>
        {/* <!-- NOTE ends here --> */}
        {/* <!-- PROJECT starts here --> */}
        <h2 id="project">PROJECT</h2>
        <p>
          This section contains project-related information that is vital to
          kick-start with the project. This has a great impact in terms of
          positioning of the overall project and co-locate it with other
          connected models or in a federated approach model organization.{' '}
        </p>
        <img
          width="100%"
          src="assets/help_center/images/project-coordinate-system-img.jpg"
          alt="alt text"
          title="Sample Project Orientation"
        />
        <h3 id="coordinate-system">Coordinate System</h3>
        <p>
          {`Orientation of the project shall be set to True North at all times.
          This can done thru acquiring the information for the survey data and
          specifying the coordinate's value. Ensure that the positioning and the
          values are applied consistently across files.`}
        </p>
        <img
          width="100%"
          src="assets/help_center/images/project-epsg-img.jpg"
          alt="alt text"
          title="Sample EPSG Value"
        />
        {/* <!-- PROJECT ends here --> */}
        {/* <!-- SITE starts here --> */}
        <h2 id="site">SITE</h2>
        <p>
          {' '}
          There is no specific equivalent category that represents site in 3D
          form, thus, if other categories are used, ensure to follow the
          specification to identify site. <br />
          Project development types shall be defined from the site information
          to know the intent of the development and to also filter out rules
          that relates only to this type of development.
        </p>
        <h3 id="proposed-site">Proposed Site</h3>
        <p>
          Proposed site extent serves as the constraint for those checks that is
          limited within the proposed site. This is essential part of the
          project where it is required to indicate this extent of the property.
        </p>{' '}
        <br />
        <img
          width="100%"
          src="assets/help_center/images/site-proposed-site-img.jpg"
          alt="alt text"
          title="Proposed Site Specification"
        />
        <h3 id="driveways">Driveways</h3>
        <p>
          Driveways are required to set to be an indicator of presence of
          accessway. In order to determine the driveway, information related to
          driveway must be followed.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/site-driveway-img.jpg"
          alt="driveway"
          title="Driveway Sample"
        />
        <h3 id="traffic-flow">Traffic Flow</h3>
        <p>
          This object is essential part of driveways and or roads as this will
          give an indication of the direction and traffic flow. In order to
          detect such object, it has to have a geometry, with at least a
          noticeable extrusion i.e., 10mm thickness and not just a combination
          of lines. Sample of this objects are shown in below.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/site-traffic-flow-img.jpg"
          alt="alt text"
          title="Traffic Flow Sample Objects"
        />
        <h3 id="parking-lots">Parking Lots</h3>
        <p>
          Dedicated parking lots such as lots for heavy vehicle or normal car
          parking lot shall be added in the model. It needs a geometry in order
          to export as 3D objects and shall follow the naming convention
          stipulated in the agreed naming convention.
        </p>{' '}
        <br />
        <p>
          Modelling-wise, this parking lot requires to have a geometry, with at
          least a noticeable extrusion i.e., 10mm thickness as shown in the
          image below and not just a combination of 2D or 3D lines.{' '}
        </p>
        <img
          width="100%"
          src="assets/help_center/images/site-parking-lots-img.jpg"
          alt="parking-lots"
          title="Parking Lots"
        />
        <h3 id="public-road">Public Road</h3>
        <p>
          This is an optional item to indicate. It only requires this
          information if there are rule related to public road access, if in
          case there is no GIS data available.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/site-public-road-img.jpg"
          alt="public-road"
          title="Public Road Sample"
        />
        {/* <!-- SITE ends here --> */}
        {/* <!-- BUILDING starts here --> */}
        <h3 id="public-road">Building Name</h3>
        <p>
          Organizing and labeling building names according to its intention is a
          vital information that makes up the proper federation of models. Below
          image is a sample building names to be followed in order to federate
          the model according to its parts.
        </p>
        <h4 id="federated-model-sample">Federated Model Sample</h4>
        <img
          width="100%"
          src="assets/help_center/images/building-name-img.jpg"
          alt="building-name"
          title="Sample Building Name"
        />
        <h4 id="model-breakdown-sample">Model Breakdown Sample</h4>
        <img
          width="100%"
          src="assets/help_center/images/model-breakdown-img.jpg"
          alt="model-breakdown"
          title="Sample Model Breakdown"
        />
        {/* <!-- BUILDING ends here --> */}
        {/* <!-- BUILDING STOREY starts here --> */}
        <h2 id="building-storey">Building storey</h2>
        <p>
          Building storey or level name shall follow the following naming
          convention
        </p>
        <table>
          <thead>
            <tr>
              <th>Building Storey Intent</th>
              <th>Level Codes</th>
              <th>Recognized Names</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1st Storey</td>
              <td>01</td>
              <td>1st Storey, Level 01</td>
            </tr>
            <tr>
              <td>Basement</td>
              <td>B1</td>
              <td>Basement 1</td>
            </tr>
            <tr>
              <td>Mezzanine</td>
              <td>03M</td>
              <td>3rd Storey Mezzanine</td>
            </tr>
            <tr>
              <td>Roof Level</td>
              <td>RF</td>
              <td>Roof 1</td>
            </tr>
            <tr>
              <td>Level 00/ Main entry level</td>
              <td>00</td>
              <td>Level 00</td>
            </tr>
            <tr>
              <td>Upper Roof Level</td>
              <td>URF</td>
              <td>Upper Roof Level</td>
            </tr>
            <tr>
              <td>Lower roof level</td>
              <td>LRF</td>
              <td>Lower Roof Level</td>
            </tr>
          </tbody>
        </table>
        {/* <!-- BUILDING STOREY ends here --> */}
        {/* <!-- ENTITIES start here --> */}
        <h2>ENTITIES</h2>
        <p></p>
        <h3 id="predefined-types">Predefined Types</h3>
        <p></p>
        <img
          width="100%"
          src="assets/help_center/images/entities-predefined-types-img.jpg"
          alt="predefined-types"
          title="Predefined Types Sample"
        />
        {/* <!-- ENTITIES end here --> */}
        {/* <!-- SPACES start here --> */}
        <h2>SPACES</h2>
        <p>
          Space such as area, room or space category in Revit has to bound to a
          bounding element in order to create a proper space. As such, bounding
          elements are essential part of defining space. If the area has an
          invalid value such as “not placed” or “not enclosed” will not be
          exported properly due to the primary info that space requires – which
          is valid area value.
        </p>
        <br />
        <img
          width="100%"
          src="assets/help_center/images/spaces-error-sample-img.jpg"
          alt="spaces-error-sample-img"
          title="Unenclosed Space Error"
        />
        <p>
          <strong>Note:</strong> Spaces intended to be a room shall have a
          volume. For space as area may have zero height unless otherwise
          specified.
        </p>
        <h3 id="space-demarcation">Space Demarcation</h3>
        <p>
          Spaces must be enclosed to get a valid area value. Check the room
          bounding of the recognized room-bounding elements such as walls,
          columns, ceiling, roof and space separator lines to enclose the area.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/spaces-error-sample-02-img.jpg"
          alt="spaces-error-sample-02-img"
          title="Unenclosed Space Error"
        />
        {/* <!-- SPACES ends here --> */}
        {/* <!-- CLASSIFICATION CODE starts here --> */}
        <h2 id="classification-code">CLASSIFICATION CODE</h2>
        <p>
          Classification is one of the commonly used standards all over the
          world, hence, to adopt this classification, the elements must follow
          certain classification code item and title to be able to extract the
          classification assigned to an item.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/classification-code-sample-img.jpg"
          alt="classification-code-sample-img"
          title="Classification Code Sample"
        />
        {/* <!-- CLASSIFICATION CODE ends here --> */}
        {/* <!-- MATERIALS start here --> */}
        <h2 id="materials">MATERIALS</h2>
        <p>
          Each of the entity requires specific material specification as part of
          the design compliance. Proper naming convention for materials shall be
          observed especially for those rules that requires specific materials
          in order to comply to a requirement.
        </p>
        {/* <!-- MATERIALS ends here --> */}
        {/* <!-- MEP SYSTEM starts here --> */}
        <h2 id="mep-system">MEP SYSTEM</h2>
        <p>
          Pipe shall belong to a system following naming convention below to be
          recognized.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/mep-system-sample-img.jpg"
          alt="mep-system-sample-img"
          title="MEP System List"
        />
        <p>
          System as per IFC definition is an organized combination of related
          parts within AEC product, composed for a common purpose or function or
          to provide a service. A system is essentially related to aggregation
          of products. The grouping relationship to one or several instances of
          IfcProduct (the system members) is handled by IfcRelAssignsToGroup.{' '}
          <br />
        </p>
        <p>
          This often applies to the representation of building services related
          systems, such as piping system, cold water system with which system is
          looking for in getting connections between elements within a defined
          network.
          <br />
          Members within such a system may or may not be connected using
          connectivity relation entities through IfcDistributionPort.
        </p>
        {/* <!-- MEP SYSTEM ends here --> */}
        <h2 id="fornax™-special-connectivity-tracing-mechanism">
          FORNAX™ Special Connectivity Tracing Mechanism
        </h2>
        <p>
          Where situation may be tough to achieve the correct connectivity due
          to the difficulty in modelling, current system has a mechanism to
          trace the system through determination of certain entities connected
          following a required minimum penetration of the pipe within the
          terminal to get the potential inlet and or outlet depending on its
          geometry positioning, e.g., getting slope to get the inlet and outlet.
        </p>
        <img
          width="100%"
          src="assets/help_center/images/fornax-special-tracing-mechanism-img.jpg"
          alt="fornax-special-tracing-mechanism"
          title="Tracing Mechanism"
        />
        <p>
          To achieve the best results, system requires a range of 10mm to 50mm
          penetration on the terminal to be recognized as potential connector.
          This approach is applied to connection between inspection chamber and
          manhole.
        </p>
        <p>
          {' '}
          FORNAX™ will enhance system connectivity by checking the actual
          geometry intersection between x objects and y objects. This is
          particularly useful due to difficulty to model the exact connections
          between sloping pipe and the IC or Manhole.
        </p>
        <p>The other use cases are:</p>
        <ol>
          <li>
            Connection between subsystem (pipe/duct segment - pipe/duct fitting){' '}
          </li>
          <li>
            Connection between MEP flow segment with the flow terminal objects
            that are often modelled in a separate model i.e.Architecture
          </li>
        </ol>
        <p>
          {`For all the above enhancements to work, the minimum condition that
          must be fulfilled is that the flow segment must have an open end (an
          open port that is not connected to any object, but is "inside" or
          slightly overlap/penetrate the intended object to be connected).`}
        </p>
        {/* <!-- UPDATE NOTE starts here --> */}
        <h4 id="update-note">BIM Modelling Guide Updates</h4>
        <blockquote>
          <p>
            Updated as of Dec 2021. Images are correct at the time of the screen
            capture.
            <br />
            Always refer to the latest features of the respective BIM software
            if mentioned.
          </p>
        </blockquote>
        {/* <!-- UPDATE NOTE ends here --> */}
      </div>

      {/* <!-- Updated 15 Dec 2021 by Rochelle --> */}
    </>
  );
};

export default BimModellingGuide;
