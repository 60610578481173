import React, { PureComponent } from 'react';
import { Card, Button, FormGroup, Container, Alert } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Message from '../../UI/Message';

import { resendToken } from '../../../store/actions';

import './style.css';

const recaptchaRef = React.createRef();
class ResendToken extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      captchaCompleted: props.isRecaptchaEnabled ? false : true,
      recaptcha: props.isRecaptchaEnabled ? '' : 'DISABLED',
      isInvited: '',
      userId: '',
      error: false
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { isInvited, userId } = this.props.location.state;
      if (!userId) {
        this.setState({ error: true });
      } else {
        this.setState({ isInvited: isInvited, userId: userId, error: false });
      }
    } else {
      this.setState({ error: true });
    }
  }

  onComplete = val => {
    if (val) {
      this.setState({ captchaCompleted: true, recaptcha: val });
    }
  };

  onExpire = () => {
    this.setState({ captchaCompleted: false, recaptcha: '' });
  };

  onSubmit = () => {
    this.props
      .resendToken(
        this.state.userId,
        this.state.isInvited,
        this.state.recaptcha
      )
      .then(res => {
        if (res.success === 'success') {
          this.props.history.push('/resend-token/succeed');
        } else {
          Message.error('Error resending token');
        }
      })
      .catch(err => {
        Message.error(err);
      });
  };

  render() {
    const { t, isRecaptchaEnabled } = this.props;

    const { isInvited, userId } = this.state;

    return (
      <Container className="d-flex justify-content-center">
        <Card
          className={
            'mt-5 mb-5 mx-4 p-4 shadow bg-light rounded resendTokenCard'
          }>
          {userId && isInvited !== null && isInvited !== undefined ? (
            <>
              <h3 className={'text-center text-muted resendTokenCardTitle'}>
                {t('resendToken:resend_verification_token')}
              </h3>
              {isRecaptchaEnabled && (
                <div className="d-flex flex-column align-items-center">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                    ref={recaptchaRef}
                    onChange={this.onComplete}
                    onExpired={this.onExpire}
                  />
                </div>
              )}
              <FormGroup className="resendTokenCardFormGroup">
                <Button
                  type="submit"
                  color="success"
                  onClick={this.onSubmit}
                  disabled={!this.state.captchaCompleted}
                  block>
                  {t('resendToken:resend_token')}
                </Button>
              </FormGroup>
            </>
          ) : (
            <Alert color="danger" className="text-center flex p-4" role="alert">
              <h1 className="fas fa-exclamation-triangle resendTokenCardAlertIcon" />
              <h3 className="my-4">{t('resendToken:invalid_token')}</h3>
              {t('error:token_invalid')}
              <div>
                {<Link to={'/'}>{t('resendToken:return_to_dashboard')}</Link>}
              </div>
            </Alert>
          )}
        </Card>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    isRecaptchaEnabled: state.app.featureList.recaptcha
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resendToken: (userId, isInvited, recaptcha) =>
      dispatch(resendToken(userId, isInvited, recaptcha))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['resendToken'])
)(ResendToken);
