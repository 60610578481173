import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  Badge,
  CardBody,
  CardTitle,
  CardFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledCollapse,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import defaultThumbnail from '../../../assets/images/default_project_thumbnail.png';

import { dateFormatting } from '../../../locales/dateFormat';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './ProjectGridView.module.css';

class ProjectGridView extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { project, id, userFunction, currentTile, featureList } = this.props;
    const { t, authUser } = this.props;

    const isSubmissionEnabled = featureList.officerSubmission;
    const isModelIssueEnabled = featureList.modelIssue;
    const isReferenceDocumentEnabled = featureList.documentReference;
    const isOrganizationEnabled = featureList.organization;
    const isTaskManagementEnabled = featureList.taskManagement;
    const isStatisticsEnabled = featureList.projectStatistics;

    const quickAccessDropdownItems = translate => {
      return [
        { label: translate('dashboard:go_to_details'), tab: 'details' },
        ...(isReferenceDocumentEnabled
          ? [
              {
                label: translate('dashboard:go_to_documents'),
                tab: 'documents'
              }
            ]
          : []),

        ...(isModelIssueEnabled
          ? [{ label: translate('dashboard:go_to_issue'), tab: 'issue' }]
          : []),

        ...(isSubmissionEnabled
          ? [
              {
                label: translate('dashboard:go_to_submission'),
                tab: 'submission'
              }
            ]
          : []),

        ...(isTaskManagementEnabled
          ? [{ label: translate('dashboard:go_to_task'), tab: 'task' }]
          : []),

        ...(isStatisticsEnabled
          ? [
              {
                label: translate('dashboard:go_to_statistics'),
                tab: 'statistics'
              }
            ]
          : [])
        //Add additional obj for another quick access to project
      ];
    };

    const ProjectBadge = ({ project }) => {
      const { members } = project;

      const findRole = (members || []).find(
        member => member.user_id === authUser.userId
      );

      const myRole = !!project.user_direct_role
        ? project.user_direct_role
        : findRole && findRole.role;

      if (members && myRole) {
        return (
          <Badge pill color="success">
            {t(`main:role_${myRole}`)}
          </Badge>
        );
      } else {
        return <></>;
      }
    };

    return (
      <Col
        lg={{
          size:
            { full: 4, half: 6, quarter: 12, threeQuarter: 4 }[
              currentTile.tileSize
            ] || 4
        }}
        md={{
          size:
            { full: 6, half: 6, quarter: 12, threeQuarter: 6 }[
              currentTile.tileSize
            ] || 6
        }}
        sm={{
          size:
            { full: 12, half: 12, quarter: 12, threeQuarter: 12 }[
              currentTile.tileSize
            ] || 12
        }}
        xs={{
          size:
            { full: 12, half: 12, quarter: 12, threeQuarter: 12 }[
              currentTile.tileSize
            ] || 12
        }}
        className="py-2 px-2">
        <Card>
          <Link
            to={`${
              userFunction === 'ADMIN' ? '/admin' : ''
            }/project/${id}/models`}>
            <img
              width="100%"
              height="200"
              src={`${
                process.env.REACT_APP_API_ENDPOINT[
                  process.env.REACT_APP_API_ENDPOINT.length - 1
                ] == '/'
                  ? process.env.REACT_APP_API_ENDPOINT.slice(0, -1)
                  : process.env.REACT_APP_API_ENDPOINT
              }${decodeURI(project.thumbnail_url)}`}
              onError={e => {
                e.target.onerror = null;
                e.target.src = defaultThumbnail;
              }}
              alt="No thumbnail to display"
            />
          </Link>
          {isOrganizationEnabled && (
            <div className={styles.badgeParentDiv}>
              <ProjectBadge project={project} />
            </div>
          )}
          <CardBody className="py-3">
            <CardTitle className={`text-center ${styles.cardTitleColor}`}>
              <Link
                to={`${
                  userFunction === 'ADMIN' ? '/admin' : ''
                }/project/${id}/models`}
                className="text-decoration-none text-muted">
                <h5>{project.name}</h5>
              </Link>
              {/* display the envelope if there is/are messages on the project */}
              {project.messages && project.messages.length > 0 ? (
                <span className={styles.messageIconColor}>
                  <i className="far fa-envelope" />
                </span>
              ) : null}
            </CardTitle>
          </CardBody>
          <CardFooter>
            <Row>
              <Col sm="8">
                <small>
                  <div>
                    {t('dashboard:date_created')}:{' '}
                    <span className="text-info">
                      {dateFormatting(project.date_created)}
                    </span>
                  </div>
                  <div>
                    {t('dashboard:last_updated')}:{' '}
                    <span className="text-info">
                      {dateFormatting(project.date_updated)}
                    </span>
                  </div>
                </small>
              </Col>
              <Col sm="4">
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    color="primary"
                    className="btn-sm w-100">
                    {t('go_to')}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem id="toggler" toggle={false}>
                      {t('stages')}{' '}
                      <i
                        className={`fas fa-caret-${
                          this.state.collapseSubmenu ? 'right' : 'down'
                        }`}
                      />
                    </DropdownItem>
                    <UncontrolledCollapse
                      toggler="#toggler"
                      className="text-muted">
                      <ListGroup>
                        {project.stagesavailable.map((stage, index) => {
                          return (
                            <Link
                              to={`${
                                userFunction === 'ADMIN' ? '/admin' : ''
                              }/project/${project.project_id}/models/${
                                stage.name
                              }`}
                              key={index}
                              className="text-decoration-none">
                              <ListGroupItem
                                className="pt-1 pb-1 pl-5 border-0"
                                action>
                                {stage.display_name}
                              </ListGroupItem>
                            </Link>
                          );
                        })}
                      </ListGroup>
                    </UncontrolledCollapse>
                    {quickAccessDropdownItems(t).map((item, index) => {
                      return (
                        <Link
                          to={`${
                            userFunction === 'ADMIN' ? '/admin' : ''
                          }/project/${project.project_id}/${item.tab}`}
                          className="text-decoration-none"
                          key={index}>
                          <DropdownItem toggle={false}>
                            {item.label}
                          </DropdownItem>
                        </Link>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return { authUser: state.authUser, featureList: state.app.featureList };
};

export default compose(
  connect(mapStateToProps, null),
  withTranslation(['dashboard', 'main'])
)(ProjectGridView);
