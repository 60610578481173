import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import UsersPresenter from './UsersPresenter';
import {
  fetchUserListAsAdmin,
  createUserAsAdmin
} from '../../../../store/actions/users';
import withXLSExport from '../../../Shared/WithXLSExport';

const mapToTable = mapData => {
  return Object.keys(mapData).map(key => {
    const data = mapData[key];
    const fullName =
      mapData[key] && `${mapData[key].first_name} ${mapData[key].last_name}`;
    return { id: key, fullName, ...data };
  });
};

const mapStateToProps = state => {
  return {
    users: mapToTable(state.users),
    featureList: state.app.featureList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserList: (filters, order, pagination, toDispatch) =>
      dispatch(fetchUserListAsAdmin(filters, order, pagination, toDispatch)),
    createUser: userData => dispatch(createUserAsAdmin(userData))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['admin_project', 'adminUserVerification', 'main']),
  withXLSExport
)(UsersPresenter);
