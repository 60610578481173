import React, { Component } from 'react';
import { Card, Button, CardText, FormGroup, Container } from 'reactstrap';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import ReCAPTCHA from 'react-google-recaptcha';
import CustomInput from '../../UI/Input/customInput';
import Message from '../../UI/Message';
import './Login.css';
import SessionControlModal from './sessionControlModal';

export class LoginPresenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  handleVerify = (setFieldValue, name) => value => {
    setFieldValue(name, value);
  };

  componentDidMount() {
    if (this.props.isLoggedIn) this.props.history.push('/dashboard');
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  render() {
    const { t, featureList } = this.props;
    const isRecaptchaEnabled = featureList.recaptcha;
    const captcha = this.captcha;

    const formikConfig = (props, toggleModal, captcha) => {
      const { t } = props;
      return {
        initialValues: {
          email: '',
          password: '',
          recaptcha: '',
          overRideActiveSession: false
        },
        validateOnChange: false,
        validationSchema: Yup.object().shape({
          username: Yup.string().required('Required'),
          password: Yup.string()
            .required('Required')
            .min(8, 'Password must contain at least 8 characters.'),
          ...(isRecaptchaEnabled
            ? {
                recaptcha: Yup.string().required(
                  'Please verify that you are a human.'
                )
              }
            : {})
        }),

        onSubmit: (values, { setSubmitting, setFieldError }) => {
          const { t, location } = props;
          // call the store action to perform the login request
          props.login(values).then(({ result, messageKey }) => {
            if (result) {
              Message.success(t(messageKey));
              props.history.push(
                (location.state && location.state.from) || '/dashboard'
              );
            } else {
              if (messageKey === 'another_session_detected') {
                toggleModal();
              } else if (
                messageKey === 'timeout-or-duplicate' &&
                isRecaptchaEnabled
              ) {
                this.captcha && this.captcha.reset();
                setFieldError(
                  'recaptcha',
                  'Please verify that you are a human.'
                );
              } else {
                this.captcha && this.captcha.reset();
                Message.error(t(messageKey) || 'An error occured', 3);
                props.history.replace('/login');
              }
            }
            //call the setSubmitting(false) to finish the cycle.
            setSubmitting(false);
          });
        }
      };
    };
    return (
      <Container className="d-flex justify-content-center align-items-start">
        <Card
          className={'mt-5 mb-5 p-4 shadow bg-light rounded card-container'}>
          <Formik {...formikConfig(this.props, this.toggleModal, captcha)}>
            {props => {
              const { isSubmitting, setFieldValue, errors, touched } = props;
              return (
                <Form>
                  <h3 className={'text-center text-muted login-form'}>
                    {t('login:login_to_fornax')}
                  </h3>
                  <Field
                    name="username"
                    type="text"
                    noSuccessValidation={true}
                    label={
                      <>
                        <div>{t('login:active_directory_username')}</div>
                        <div className="text-muted font-italic">
                          {t('login:active_directory_username_example')}
                        </div>
                      </>
                    }
                    placeholder={t('login:active_directory_username')}
                    prefix={<i className="fas fa-user-alt" />}
                    component={CustomInput}
                  />
                  <Field
                    name="password"
                    type="password"
                    noSuccessValidation={true}
                    label={t('login:password_placeholder')}
                    placeholder={t('login:password_placeholder')}
                    prefix={<i className="fas fa-lock" />}
                    component={CustomInput}
                  />
                  {isRecaptchaEnabled && (
                    <div className="d-flex flex-column align-items-center">
                      <ReCAPTCHA
                        ref={e => (this.captcha = e)}
                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                        onChange={this.handleVerify(setFieldValue, 'recaptcha')}
                        onExpired={() =>
                          this.handleVerify(setFieldValue, 'recaptcha')('')
                        }
                      />
                      {touched.recaptcha && errors.recaptcha && (
                        <div className="text-danger">{errors.recaptcha}</div>
                      )}
                    </div>
                  )}
                  <div className="text-right">
                    <Link to={'/forgot-password'} className={'text-muted'}>
                      {t('login:forgot_password')}
                    </Link>
                  </div>
                  <div className={'pt-2'}>
                    <Button
                      type="submit"
                      color="primary"
                      // outline
                      block
                      disabled={isSubmitting}
                      className="login-button">
                      {t('login:login')}
                    </Button>
                  </div>
                  <div>
                    <CardText className={'text-center text-muted card-text'}>
                      {t('login:dont_have_account')}
                      <Link to={'/register'} className="register-button-link">
                        <Button color="link" block>
                          {t('login:register')}
                        </Button>
                      </Link>
                    </CardText>
                  </div>
                  <SessionControlModal
                    {...this.props}
                    modal={this.state.modal}
                    toggle={this.toggleModal}
                    formikProps={props}
                  />
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Container>
    );
  }
}

export default withTranslation(['error', 'login', 'validation'])(
  LoginPresenter
);
