import React from 'react';
import Knowledgehub from './components';
import knowledgehubReducer from './store/reducers';
import initialState from './store/config/initialState';
import KnowledgehubContext from './store/config/context';
import useMiddlewareReducer from './store/config/useMiddlewareReducer';

const KnowledgehubIndex = props => {
  const [state, dispatch] = useMiddlewareReducer(
    knowledgehubReducer,
    initialState
  );
  return (
    <KnowledgehubContext.Provider value={{ state, dispatch, ...props }}>
      <Knowledgehub />
    </KnowledgehubContext.Provider>
  );
};

export default KnowledgehubIndex;
