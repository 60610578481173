import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  Card,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  Spinner
} from 'reactstrap';

import Style from './Style.css';

import Chart from './Chart';

const AzureQueueMonitoringModal = props => {
  const { isOpen, toggle, t, fetchJobQueueStatusMetrics } = props;

  const [metrics, setMetrics] = useState({});
  const [chartData, setChardata] = useState([{}]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeFilteredDate, setActiveFilteredDate] = useState('last7Days');

  const metricsToShow = {
    active_message: {
      key: 'activeMessageCount'
    }
    // outgoing_message: {
    //   key: 'transferMessageCount'
    // },
    // deadLetterMessageCount: {
    //   key: 'deadLetterMessageCount'
    // }
  };

  const prepareData = data => {
    const findKey = (data.metrics.value || []).find(
      i => i.name.value === 'IncomingMessages'
    );

    const newchartData =
      findKey && findKey.timeseries && findKey.timeseries.length > 0
        ? findKey.timeseries[0].data
        : [{ total: 0, timeStamp: Date.now() }];

    setMetrics(data);
    setChardata(newchartData);
  };
  useEffect(() => {
    fetchJobQueueStatusMetrics().then(({ data }) => {
      if (data) {
        setIsLoaded(true);
        prepareData(data);
      }
    });
  }, []);

  const handleDateRangeFilter = async key => {
    const selectedKey = key || activeFilteredDate || 'last7Days';
    setIsLoaded(false);
    setActiveFilteredDate(selectedKey);
    try {
      await fetchJobQueueStatusMetrics({
        dateRange: selectedKey
      }).then(({ data }) => {
        if (data) {
          setIsLoaded(true);
          prepareData(data);
        }
      });
    } catch (error) {}
  };

  const timeRange = [
    'last1Hour',
    'last6Hour',
    'last12Hour',
    'last1Day',
    'last7Days',
    'last30Days'
  ];

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        className="azureMonitoringModal">
        <ModalHeader toggle={toggle}>{t('job_queue_status')}</ModalHeader>
        <ModalBody>
          {isLoaded ? (
            <div>
              <Row className="justify-content-between px-4">
                <h5 className="text-primary ml-1">{t('request_count')}</h5>

                <div className="text-right pb-2">
                  <Button
                    color="success"
                    className="mr-1"
                    outline
                    onClick={async () => {
                      await handleDateRangeFilter();
                    }}>
                    <i className="fas fa-sync-alt" /> {t('refresh')}
                  </Button>
                </div>
              </Row>
              <Row className="justify-content-between px-4">
                {Object.keys(metricsToShow).map(key => {
                  const current = metricsToShow[key];
                  const count =
                    (metrics &&
                      metrics.messageCount &&
                      metrics.messageCount[current.key]) ||
                    0;

                  return (
                    <Col lg={{}} md={{}} sm="4" key={key}>
                      <div
                        // class="card text-center"
                        className={`shadow higlight customBorderRadius h-100 ${key} text-center`}>
                        <div className="card-body">
                          <h6 className="card-title text-muted">{t(key)}</h6>
                          <h5>{count}</h5>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="justify-content-between px-4 mt-3">
                <h5 className="text-primary ml-1">{t('metrics')}</h5>

                <div className="text-right pb-2">
                  <a className="text-muted mr-2"> Show data for the last: </a>

                  <ButtonGroup color="primary">
                    {timeRange.map(key => {
                      return (
                        <Button
                          key={key}
                          outline={true}
                          size="sm"
                          active={activeFilteredDate === key}
                          onClick={async () => {
                            await handleDateRangeFilter(key);
                          }}>
                          {t(`${key}`)}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>
              </Row>

              <Row>
                <Col sm="12">
                  <div className="text-right pb-2"></div>
                  <Chart chartData={chartData} t={t} />
                </Col>
              </Row>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center loading-spinner-div">
              <Spinner
                className="loading-spinner"
                type="grow"
                color="primary"
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AzureQueueMonitoringModal;
