import React, { Component, useState } from 'react';
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container,
  Row,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dateFormatting } from '../../../locales/dateFormat';

import JobStatusPopUpInfo from '../../Shared/RuleCheckStatusReport';
import { connect } from 'react-redux';

import { reRunModelComparison } from '../../../store/actions';

import Message from '../../UI/Message';

import ReRun from './ModelComparison/ReRun';

import styles from './ProjectResultRules.module.css';

import ReactTable from '../../Shared/ReactTable/index.jsx';

const NoData = t => <h1>{t('no_data')}</h1>;

const statusToIcon = (status, index, translate) => {
  index++;
  switch (status) {
    case 'DONE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'AVAILABLE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-check-circle text-success fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_done')}
          </UncontrolledTooltip>
        </>
      );
    case 'IN_PROCESS':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_in_process')}
          </UncontrolledTooltip>
        </>
      );
    case 'PENDING':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-spinner fa-spin text-info fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('pending_process')}
          </UncontrolledTooltip>
        </>
      );

    case 'ON_ERROR':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-exclamation-circle text-danger fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_on_error')}
          </UncontrolledTooltip>
        </>
      );
    case 'OBSOLETE':
      return (
        <>
          <span id={`status-result-${index}`}>
            <i className="fas fa-exclamation-triangle text-warning  fs-2x" />
          </span>
          <UncontrolledTooltip
            boundariesElement="window"
            fade={false}
            target={`status-result-${index}`}
            delay={100}>
            {translate('status_obsolete')}
          </UncontrolledTooltip>
        </>
      );
  }
};

const fileTypeFormatting = (props, row, file, index, translate) => {
  const comparison_id = row.comparison_rel.comparison_id || '';
  const fileType = file.split('.').pop().toLowerCase();
  const formatConfig = (fileExtension => {
    switch (fileExtension) {
      case 'xlsx': {
        return {
          colorButton: 'success',
          fileIcon: 'fas fa-file-excel',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }

      default: {
        return {
          colorButton: 'info',
          fileIcon: 'fas fa-question',
          label: fileExtension,
          linkType: 'download',
          isFXR: false
        };
      }
    }
  })(fileType);

  return (
    <DropdownItem
      tag="a"
      onClick={e => e.stopPropagation()}
      href={`${process.env.REACT_APP_API_ENDPOINT}/api/project/${props.projectId}/${props.stageName}/model_compare/result/${comparison_id}/${file}/download`}
      key={`result-${index}`}
      id={`fileLink-result-${fileType}`}
      size="md"
      color="light">
      <div>
        {formatConfig.label}
        <i className={`${formatConfig.fileIcon} ml-1`} />
      </div>
      <UncontrolledTooltip
        boundariesElement="window"
        fade={false}
        target={`fileLink-result-${fileType}`}
        delay={0}>
        {formatConfig.label}
      </UncontrolledTooltip>
    </DropdownItem>
  );
};

class StageComparisonResultPresenter extends Component {
  render() {
    const { data, t, projectId, stageName, model_comparison_list } = this.props;

    const reactTableColumns = [
      {
        id: '',
        Header: t('serial_number'),
        width: 20,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <div>{row.index + 1}</div>;
        }
      },
      {
        id: 'rules_check',
        accessor: 'rules_check',
        Header: t('project:rules'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const rulesChecked = Array.isArray(
            row.original.comparison_rel.rules_check
          )
            ? row.original.comparison_rel.rules_check
            : [];

          return (
            <>
              <div id={`modelComparison-${row.original.index}`} type="link">
                {`${t('total_rules_checked')}: `}
                <span className="font-weight-bold">{rulesChecked.length}</span>
              </div>

              <UncontrolledPopover
                boundariesElement="window"
                trigger="hover"
                className="rule-popover-container"
                target={`modelComparison-${row.original.index}`}>
                <PopoverHeader>{`${t('rules')} (${
                  rulesChecked.length
                })`}</PopoverHeader>
                <PopoverBody className={styles.scrollableStyles}>
                  {rulesChecked.map((entry, key) => {
                    const selectType = model_comparison_list.find(value => {
                      return value.key === entry;
                    });
                    const name =
                      selectType && selectType !== undefined
                        ? selectType.display_name
                        : entry;

                    return (
                      <div key={key} className="mb-2">
                        {name}
                      </div>
                    );
                  })}
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        }
      },
      {
        id: '',
        Header: t('information'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <Container className="d-flex">
              <div id={`model_compare_result-${row.original.index}-info`}>
                <Row>
                  {' '}
                  <div className="font-weight-bold mr-1">
                    {t('date_submitted')}:{' '}
                  </div>
                  <div>
                    {dateFormatting(
                      row.original.comparison_rel.date,
                      'defaultHour'
                    )}
                  </div>
                </Row>
                <Row>
                  {' '}
                  <div className="font-weight-bold mr-1">
                    {t('stage_compared')}:{' '}
                  </div>
                  <div>{row.original.stage_compared.display_name}</div>
                </Row>
              </div>
            </Container>
          );
        }
      },
      {
        id: 'comparison_rel.files_available',
        accessor: 'comparison_rel.files_available',
        Header: t('result_files'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const isAvailable =
            row.original.comparison_rel.status === 'AVAILABLE' ||
            row.original.comparison_rel.status === 'OBSOLETE';

          return (
            <>
              {isAvailable &&
                !!value &&
                Array.isArray(value) &&
                value.length > 0 && (
                  <UncontrolledDropdown>
                    <DropdownToggle outline color="primary" caret>
                      <i className="fas fa-download mr-1"></i>
                      {t('dl_files')}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Array.isArray(value) &&
                        value.map((file, index) => {
                          return (
                            <div key={`result-${index}`}>
                              {fileTypeFormatting(
                                this.props,
                                row.original,
                                file,
                                index,
                                t
                              )}
                            </div>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
            </>
          );
        }
      },
      {
        id: 'comparison_rel.status',
        accessor: 'comparison_rel.status',
        Header: t('status'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const info = {
            ...row.original,
            job_id: row.index,
            rules_check: [],
            status: row.original.comparison_rel.status
          };

          return <JobStatusPopUpInfo jobInfo={info} t={t} />;
        }
      },
      {
        id: 'link',
        accessor: 'link',
        Header: t('actions'),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          const stage_compared = row.original.stage_compared.name;
          const dateOfComparison = row.original.comparison_rel.date;

          return (
            <>
              {row.original.comparison_rel.status === 'AVAILABLE' &&
                row.original.webviewerComparisonToken && (
                  <div className="d-flex justify-content-center">
                    <a
                      href={`${process.env.REACT_APP_WEBVIEWER_URI}/webviewer?viewerMode=3d&token=${row.original.webviewerComparisonToken}`}
                      // to={{
                      //   pathname: `/project/${projectId}/${stageName}/viewer`,
                      //   search: `viewerMode=3d&token=${
                      //     row.webviewerComparisonToken
                      //   }`
                      // }}
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fas fa-building"></i>
                    </a>
                  </div>
                )}
            </>
          );
        }
      }
    ];

    return (
      <ReactTable
        columns={reactTableColumns}
        data={data}
        striped
        bordered
        hover
        noDataIndication={() => NoData(t)}
      />
    );
  }
}

export default withTranslation(['results'])(StageComparisonResultPresenter);
