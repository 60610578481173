import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import QueryForm from './queryForm';
import ResultTab from './resultTab';

class BIMRLQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuery: null,
      hasResult: false,
      isFetchingGlobalResult: false,
      isResultEntryUnique: null,
      collapsedResults: {},
      loadedResults: {},
      erroredResults: {},
      activeResult: null
    };
  }

  componentDidMount() {}

  setCurrentQuery = (queryName, values) => {
    this.setState({
      currentQuery: { queryName, values }
    });
  };
  setIsFetchingGlobalResult = value => {
    this.setState({
      isFetchingGlobalResult: value
    });
  };
  setLoadedResults = projectId => {
    this.setState(prevState => ({
      loadedResults: {
        ...prevState.loadedResults,
        [projectId]: true
      }
    }));
  };

  setCollapsedResults = projectId => {
    return this.setState(prevState => ({
      collapsedResults: {
        ...prevState.collapsedResults,
        [projectId]: !prevState.collapsedResults[projectId]
      }
    }));
  };

  setErrorResults = (projectId, message = 'error:default_message') => {
    this.setState(prevState => ({
      erroredResults: {
        ...prevState.erroredResults,
        [projectId]: message
      }
    }));
  };

  resetCollapsedResult = () => {
    this.setState({
      collapsedResults: {}
    });
  };

  resetLoadedResult = () => {
    this.setState({
      loadedResults: {}
    });
  };

  resetErroredResult = () => {
    this.setState({
      erroredResults: {}
    });
  };

  render() {
    const { t } = this.props;
    const {
      isResultEntryUnique,
      currentQuery,
      hasResult,
      isFetchingGlobalResult,
      loadedResults,
      collapsedResults,
      erroredResults
    } = this.state;

    return (
      <Container className="mt-4">
        <div className="shadow p-3 mb-4 bg-light rounded">
          <div className="mt-2">
            <h3 className="text-primary">{t('bimrlData:page_title')}</h3>
          </div>
        </div>
        <div className="shadow p-3 mb-4 bg-light rounded">
          <div className="mt-2">
            <h5 className="text-primary">{t('bimrlData:query_form')}</h5>
          </div>
          <QueryForm
            setCurrentQuery={this.setCurrentQuery}
            setIsFetchingGlobalResult={this.setIsFetchingGlobalResult}
            resetCollapsedResult={this.resetCollapsedResult}
            resetLoadedResult={this.resetLoadedResult}
            resetErroredResult={this.resetErroredResult}
          />
        </div>

        <ResultTab
          isResultEntryUnique={isResultEntryUnique}
          isFetchingGlobalResult={isFetchingGlobalResult}
          currentQuery={currentQuery}
          loadedResults={loadedResults}
          collapsedResults={collapsedResults}
          erroredResults={erroredResults}
          setLoadedResults={this.setLoadedResults}
          setCollapsedResults={this.setCollapsedResults}
          setErrorResults={this.setErrorResults}
        />
      </Container>
    );
  }
}

export default withTranslation(['bimrlData'])(BIMRLQuery);
