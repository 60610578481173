import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import {
  Alert,
  Spinner,
  Container,
  Col,
  Row,
  Collapse,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from 'reactstrap';

import styles from './Bimrl_data.module.css';

import Message from '../../../UI/Message';
import { getSavedQueryBIMRLProjectResult } from '../../../../store/actions';

class ResultTab extends Component {
  renderTabResult = (fields, rows) => (
    <table className="table">
      <thead>
        <tr>
          {fields.map((f, i) => (
            <th key={`tab-header-${i}`}>{f.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((r, rowIndex) => {
          return (
            <tr key={`row-${rowIndex}`}>
              {fields.map((f, i) => (
                <td key={`tab-header-${i}`}>{r[f.name]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  renderCollapse = results => {
    return (
      <>
        {Object.keys(results).map(projectId => {
          const projectData = results[projectId];
          const isResultLoaded = this.props.loadedResults[projectId];
          const resultOnError = this.props.erroredResults[projectId];
          const onExpand = async () => {
            await this.props.setCollapsedResults(projectId);
            if (!isResultLoaded) {
              this.props
                .executeSavedQuery(
                  projectId,
                  projectData.stage_name,
                  this.props.currentQuery.values,
                  this.props.currentQuery.queryName
                )
                .then(() => {
                  this.props.setLoadedResults(projectId);
                })
                .catch(err => {
                  Message.error(this.props.t('error:default_message'));
                  this.props.setErrorResults(projectId);
                });
            }
          };
          return (
            <ListGroupItem
              key={`result-${projectId}`}
              className={classnames('border-info')}>
              <ListGroupItemHeading onClick={onExpand}>
                {projectData.project_name}
              </ListGroupItemHeading>
              <Collapse isOpen={this.props.collapsedResults[projectId]}>
                {resultOnError ? (
                  <Alert color="danger"> {this.props.t(resultOnError)}</Alert>
                ) : (
                  <>
                    {isResultLoaded ? (
                      <div className={styles.div_tab_result}>
                        {this.renderTabResult(
                          projectData.fields,
                          projectData.rows
                        )}
                      </div>
                    ) : (
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.div_spinner_min_height}`}
                        >
                        <Spinner
                          type="grow"
                          className={styles.spinner_size}
                          color="primary"
                        />
                      </div>
                    )}
                  </>
                )}
              </Collapse>
            </ListGroupItem>
          );
        })}
      </>
    );
  };

  renderUniqueResult = results => {
    const res = results[Object.keys(results).pop()];
    if (res.fields && res.rows) {
      return <>{this.renderTabResult(res.fields, res.rows)}</>;
    } else {
      return <></>;
    }
  };

  render() {
    const { results, isFetchingGlobalResult, t } = this.props;
    const isResultEntryUnique = Object.keys(results).length === 1;
    const hasResult = Object.keys(results).length > 0;
    return (
      <>
        {hasResult && !isFetchingGlobalResult && (
          <div className="shadow p-3 mb-5 bg-light rounded">
            <div className="mt-2 mb-3">
              <h5 className="text-primary">{t('bimrlData:result_tab')}</h5>
              {isResultEntryUnique &&
                results[Object.keys(results).pop()].queriedStages !==
                  undefined && (
                <div className="text-right text-secondary">
                  {results[Object.keys(results).pop()].queriedStages}{' '}
                    instances found
                </div>
              )}
            </div>
            <Container>
              {hasResult && (
                <>
                  {isResultEntryUnique ? (
                    this.renderUniqueResult(results)
                  ) : (
                    <ListGroup>{this.renderCollapse(results)}</ListGroup>
                  )}
                </>
              )}

              {isFetchingGlobalResult && (
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.div_spinner_min_height}`}
                  >
                  <Spinner
                    type="grow"
                    className={styles.spinner_size}
                    color="primary"
                  />
                </div>
              )}
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    results: state.bimrl.results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    executeSavedQuery: (projectId, stageName, values, savedQueryName) =>
      dispatch(
        getSavedQueryBIMRLProjectResult(
          projectId,
          stageName,
          values,
          savedQueryName
        )
      )
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(['bimrlData', 'error'])
)(ResultTab);
