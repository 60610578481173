const login = {
  id: 'login',
  to: '/login',
  label: 'Login',
  isLink: true
};

const register = {
  id: 'register',
  to: '/register',
  label: 'Register',
  isLink: true
};

const about = {
  id: 'about',
  to: '/',
  label: 'ABOUT',
  isLink: true
};

const solutions = {
  id: 'solutions',
  to: '/',
  label: 'SOLUTIONS',
  isLink: true
};

const reference = {
  id: 'reference',
  to: '/',
  label: 'REFERENCE',
  isLink: true,
  options: [
    {
      id: 'projectReference',
      to: '/',
      label: 'Project Reference',
      isLink: true
    },
    { id: 'bimReference', to: '/', label: 'BIM Reference', isLink: true }
  ]
};

const contact = {
  id: 'contact',
  to: '/',
  label: 'CONTACT',
  isLink: true
};

export default {
  default: [solutions, reference, about, contact, login, register]
};
