import { knowledgeHubActionType } from '../../constants/actionType';

const initialState = {};

const { SEARCH_KNOWLEDGEHUB } = knowledgeHubActionType;

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SEARCH_KNOWLEDGEHUB:
    return { ...action.data };
  default:
    return state;
  }
};

export default reducer;
