import { useState, useRef, useMemo } from 'react';
import thunk from 'redux-thunk';

const middlewares = [thunk];

// same API as useReducer, with middlewares as first argument
function useMiddlewareReducer(reducer, initState, initializer = s => s) {
  const [state, setState] = useState(initializer(initState));
  const stateRef = useRef(state); // stores most recent state
  const dispatch = useMemo(
    () =>
      enhanceDispatch({
        getState: () => stateRef.current, // access most recent state
        stateDispatch: action => {
          stateRef.current = reducer(stateRef.current, action); // makes getState() possible
          setState(stateRef.current); // trigger re-render
          return action;
        }
      })(...middlewares),
    [middlewares, reducer]
  );

  return [state, dispatch];
}

// A middleware has type (dispatch, getState) => nextMw => action => action
function enhanceDispatch({ getState, stateDispatch }) {
  return (...middlewares) => {
    let dispatch;
    const middlewareAPI = {
      getState,
      dispatch: action => dispatch(action)
    };
    dispatch = middlewares
      .map(m => m(middlewareAPI))
      .reduceRight((next, mw) => mw(next), stateDispatch);
    return dispatch;
  };
}

export default useMiddlewareReducer;
