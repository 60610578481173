import React, { Component } from 'react';
import { dateFormatting } from '../../../locales/dateFormat';
import { timeAgoFormatting } from '../../../locales/dateFormat';
import Message from '../../UI/Message';
import ReferenceDocumentsFileExplorer from './ReferenceDocuments';
import BreadCrumbComponent from './ReferenceDocuments/Breadcrumb';
import { DragDropContext } from 'react-dnd';

import DocumentList from './ReferenceDocuments/DocumentList';
import { Row, Col, Container, Button, Spinner, Progress } from 'reactstrap';

import Dropzone from 'react-dropzone';
import EditableInput from '../../UI/Input/editableInput';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import prettyBytes from 'pretty-bytes';
import path from 'path';
import { throws } from 'assert';
import styles from './Styles.module.css';

const buttonAction = ({ isReferenceDocumentsDirty, filteredDocuments }, t) => {
  return `${t('upload_documents')}`;
};

const formikConfig = props => {
  return {
    initialValues: {
      referenceDocuments: props.referenceDocuments.filter(
        d => d.dirty && !d.removed
      )
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      // setStatus('submitted');
      const { referenceDocuments } = values;
    }
  };
};

class ReferenceDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 1,
      filters: {},
      filesAdded: [],
      filesRemoved: [],
      displayItemDetails: false,
      activeItem: {},
      selectedKey: `Documents/${this.props.t(
        `reference_documents:${props.stageName}`
      )}/`,

      stage_name: null,
      submission_id: null,

      activeDirectoryToUpload: '',
      BreadCrumbItem: [],
      isActionDirectlyFromFileExplorer: false,
      formReset: false,
      showTextDescription: false,

      focus_stage: '',
      isRefreshLoading: false,
      fileUploadProgresses: [],
      isSubmitting: false,
      totalDocumentSize: '',
      config_limit_size: ''
    };
  }

  setFileUploadProgresses = progressList => {
    this.setState({ fileUploadProgresses: progressList });
  };

  setActiveItem = item => {
    this.setState({ activeItem: item });
  };
  onUpdateDocuments = async () => {
    this.setState({
      isSubmitting: true
    });
    const {
      uploadReferenceDocuments,
      dispatchAllUploads
    } = this.props.referenceDocumentActions;
    const { referenceDocuments } = this.props;
    const { activeDirectoryToUpload, stage_name, submission_id } = this.state;

    const uploaded_documents = referenceDocuments.filter(
      d => d.dirty && !d.removed
    );
    const remove_documents = referenceDocuments.filter(d => d.removed);

    // We have to add the file sequencially due to concurent update in the db otherwise
    async function* generateAddFileSequence(
      projectId,
      files,
      remove_documents,
      activeDirectoryToUpload,
      stage_related,
      fileUploadProgresses,
      setFileUploadProgresses
    ) {
      for (let i in files) {
        const file = files[i];
        const onUploadProgress = progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          const newProgresses = fileUploadProgresses;
          newProgresses[i] = percentCompleted;
          setFileUploadProgresses(newProgresses);
        };
        const document = await uploadReferenceDocuments(
          projectId,
          file,
          remove_documents,
          activeDirectoryToUpload,
          stage_related,
          onUploadProgress
        );

        yield document;
      }
    }

    try {
      this.setFileUploadProgresses(uploaded_documents.map(() => 0));

      let generator = generateAddFileSequence(
        this.props.match.params.id,
        uploaded_documents,
        remove_documents,
        activeDirectoryToUpload,
        {
          stage_name: stage_name,
          submission_id: submission_id
        },
        this.state.fileUploadProgresses,
        this.setFileUploadProgresses
      );

      let next = await generator.next();
      const documents = [];
      while (!next.done) {
        const document = next.value;
        documents.push(document);
        next = await generator.next();
      }
      dispatchAllUploads(documents);

      this.getDocumentSize();

      Message.success(this.props.t('reference_documents:upload_success'));
    } catch (err) {
      // Message.error(
      //   this.props.t('error:project_document_management_limit_reached')
      // );

      Message.error(this.props.t(`error:${err.message}`));
    } finally {
      const filesAdded = referenceDocuments.filter(m => m.dirty && !m.removed);
      this.setState({ filesAdded });
      this.setState({
        isSubmitting: false
      });
    }
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    const {
      fetchReferenceDocumentsFileExplorer,
      getTotalDocumentSize
    } = this.props.referenceDocumentActions;
    const { projectId, stageName } = this.props;
    this.props.fetchProjectStage(projectId, stageName).then(data => {
      this.setState({
        focus_stage: data[0].stageData.name,
        BreadCrumbItem: `${this.props.t(
          `reference_documents:${data[0].stageData.name}`
        )}/`
      });
    });

    Promise.all([
      this.getDocumentSize(),
      fetchReferenceDocumentsFileExplorer(this.props.match.params.id)
    ]).then(result => {
      this.setState({
        isLoaded: true,
        isRefreshLoading: true
      });
    });
  };

  getDocumentSize = () => {
    return this.props.referenceDocumentActions
      .getTotalDocumentSize(this.props.match.params.id)
      .then(data => {
        this.setState({
          totalDocumentSize: data.totalDocumentSize,
          config_limit_size: data.config_limit_size
        });
      });
  };

  toggledisplayItemDetails = (value, item) => {
    this.setState({
      displayItemDetails: value,
      activeItemToDisplay: item
    });
  };

  setActiveDirectoryToUpload = directory => {
    this.setState({
      activeDirectoryToUpload: directory
    });
  };

  createBreadCrumbItem = path => {
    this.setState({
      BreadCrumbItem: path
    });
  };
  toogleFocusState = stageName => {
    this.setState({
      focus_stage: stageName
    });
  };

  setisActionDirectlyFromFileExplorer = set => {
    this.setState({
      isActionDirectlyFromFileExplorer: set
    });
  };
  render() {
    const {
      referenceDocuments,
      t,
      referenceDocumentActions,
      fileUploadProgress
    } = this.props;

    const {
      removeReferenceDocument,
      softRemoveReferenceDocument
    } = referenceDocumentActions;
    const { activeItemToDisplay, isLoaded, fileUploadProgresses } = this.state;
    let disabled = false;

    if (activeItemToDisplay) {
      // disabled = activeItemToDisplay.origin_of_the_file === 'submission';
    }

    const filteredDocuments = referenceDocuments.filter(document => {
      return !document.removed && document.dirty;
    });

    const uploadedDocuments = referenceDocuments.filter(document => {
      return !document.dirty && !document.operation;
    });

    const isReferenceDocumentsDirty =
      referenceDocuments.filter(r => (r.removed || r.dirty) && r.path).length >
      0;
    return (
      <Container>
        <Row className="justify-content-between px-3 ">
          <Col>
            <div className="shadow p-3 mt-3 mb-5 rounded">
              <Row className="px-3 justify-content-between">
                <h5 className="text-primary">{t('reference_documents')}</h5>
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      this.setState({
                        isRefreshLoading: false
                      });
                      this.initialize();
                    }}>
                    {!this.state.isRefreshLoading && (
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        <span>Loading...</span>
                      </div>
                    )}
                    {this.state.isRefreshLoading && (
                      <div>
                        {''}
                        <i className="fas fa-sync" /> Refresh
                      </div>
                    )}
                  </Button>
                </div>
              </Row>

              <hr></hr>
              <Formik {...formikConfig(this.props)}>
                {formikProps => {
                  const { isSubmitting } = formikProps;
                  return (
                    <>
                      <Container>
                        {isLoaded && (
                          <div>
                            <BreadCrumbComponent
                              item={this.state.BreadCrumbItem}
                              activeItem={this.state.activeItem}
                              setActiveItem={this.setActiveItem}
                              createBreadCrumbItem={this.createBreadCrumbItem}
                              setActiveDirectoryToUpload={
                                this.setActiveDirectoryToUpload
                              }
                              setSelectedKey={key => {
                                this.setState({ selectedKey: key });
                              }}
                            />
                            <ReferenceDocumentsFileExplorer
                              {...this.props}
                              uploadedDocuments={uploadedDocuments}
                              toggledisplayItemDetails={
                                this.toggledisplayItemDetails
                              }
                              setActiveDirectoryToUpload={
                                this.setActiveDirectoryToUpload
                              }
                              setSelectedKey={key => {
                                this.setState({ selectedKey: key });
                              }}
                              selectedKey={this.state.selectedKey}
                              onDeleteDocument={this.onDeleteDocument}
                              isLoaded={this.state.isLoaded}
                              createBreadCrumbItem={this.createBreadCrumbItem}
                              setisActionDirectlyFromFileExplorer={
                                this.setisActionDirectlyFromFileExplorer
                              }
                              reference_documents={referenceDocuments.filter(
                                document => {
                                  return !document.removed;
                                }
                              )}
                              activeDirectoryToUpload={
                                this.state.activeDirectoryToUpload
                              }
                              activeItem={this.state.activeItem}
                              setActiveItem={this.setActiveItem}
                              onUpdateDocuments={this.onUpdateDocuments}
                              focus_stage={this.state.focus_stage}
                              toogleFocusState={this.toogleFocusState}
                              fileUploadProgresses={
                                this.state.fileUploadProgresses
                              }
                              isSubmitting={this.state.isSubmitting}
                              totalDocumentSize={this.state.totalDocumentSize}
                              config_limit_size={this.state.config_limit_size}
                              getDocumentSize={this.getDocumentSize}
                            />
                          </div>
                        )}
                        {!isLoaded && (
                          <div className={`d-flex align-items-center justify-content-center ${styles.spinnerDivParent}`}>
                            <Spinner
                              type="grow"
                              className={styles.spinnerDimention}
                              color="primary"
                            />
                          </div>
                        )}
                      </Container>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ReferenceDocument;
