import React, { Component, useState, useEffect } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledCollapse
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './Styles.module.css';

import Tree from '../../../UI/modelComparisonTree';

const ComparisonDropdown = ({
  modelComparisonList,
  setCheckedRulesForComparison
}) => {
  const [checkedKeys, setCheckedKeys] = useState(false);
  // const [filteredListTree, setFilteredListTree] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const filteredListTree = [
    {
      title: 'Comparison List',
      key: 'root',
      children: modelComparisonList.reduce((acc, current) => {
        return [
          ...acc,
          {
            title: current.display_name,
            key: current.key
          }
        ];
      }, [])
    }
  ];

  const onChecked = checkedKeys => {
    const filtered = checkedKeys.filter(val => val !== 'root');
    setCheckedKeys(filtered);
    setCheckedRulesForComparison(filtered);
  };

  return (
    <div
      className={`border ${styles.optionTree}`}
      modifiers={{ computeStyle: { gpuAcceleration: false } }}>
      <Tree
        data={filteredListTree}
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
        onChecked={onChecked}
      />
    </div>
  );
};

export default compose(
  // connect(
  //   mapStateToProps,
  //   mapDispatchToProps
  // ),
  withTranslation('')
)(ComparisonDropdown);
