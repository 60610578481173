import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';

import Attachments from './Attachments';
import { dateFormatting } from '../../../locales/dateFormat';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import Message from '../../UI/Message';
class ModelIssueDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteRequested: false
    };
  }

  render() {
    const {
      t,
      projectId,
      stageName,
      issueIds,
      toggleDeleteModal,
      isDeleteModalOpen,
      deleteDisabled,
      formReset,
      className
    } = this.props;
    const { deleteRequested } = this.state;

    return (
      <Modal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        className={className}>
        <ModalHeader toggle={this.toggle}>
          {t('project:delete_project_stage_issue_modal_header')}
        </ModalHeader>
        <ModalBody>
          {t('project:delete_project_stage_issue_confirmation')}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.setState({ deleteRequested: true });
              this.props.modelIssueActions
                .deleteModelIssue(projectId, stageName, issueIds)
                .then(res => {
                  if (res.success) {
                    Message.success(t('project:issue_deleted_success'));
                    toggleDeleteModal();
                  } else {
                    Message.error(t('project:issue_already_deleted'));
                  }
                  this.setState({
                    deleteRequested: false
                  });
                  this.props.history.push(
                    `/project/${projectId}/issue/${stageName}`
                  );
                })
                .catch(() => {
                  Message.error(t('error:not_authorised'));
                  this.setState({
                    deleteRequested: false
                  });
                  toggleDeleteModal();
                });
            }}>
            {deleteRequested && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {t('main:yes')}
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            {t('main:no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default compose(withTranslation(['project, error']))(ModelIssueDetail);
